import { combineReducers } from "redux";
import reducer from "./reducer";
import ldap from "./ldap";
import delegadoMantencion from "./delegadoMantencion";
import AuthReducer from "./AuthReducer"

export default combineReducers({
  reducer: reducer,
  delegado: delegadoMantencion,
  ldap: ldap,
  auth: AuthReducer
});
