import React, { useState } from "react";
import { useEffect } from "react";
const iconNegocio = require("../../../assets/images/img-warning.png");

export const CatalogoPregunta = ({ catalogoPregunta, chkPregunta }) => {
  const [catalogo, setCatalogoPregunta] = useState({
    ...catalogoPregunta,
    estadoTexto: true,
    lstTextoPregunta:
      catalogoPregunta.textoOpcional &&
      catalogoPregunta.textoOpcional.split("."),
  });

  const actualizarEstadoCatalogo = () => {
    setCatalogoPregunta({ ...catalogo, estadoTexto: !catalogo.estadoTexto });
  };

  useEffect(() => {
    if (chkPregunta === false)
      setCatalogoPregunta((prevCatalogo) => ({
        ...prevCatalogo,
        estadoTexto: true,
      }));
  }, [chkPregunta]);

  return (
    <div
      className="p-3"
      style={{
        backgroundColor: "#fcf4d8",
        cursor: "pointer",
        borderLeft: "5px solid #efba00",
        marginTop: "4px",
        borderRadius: "10px",
      }}
      onClick={() => {
        actualizarEstadoCatalogo();
      }}
    >
      <div className="row text-white font-weight-bold ">
        <div className="col-md-1 text-center">
          <img
            src={iconNegocio}
            alt="catalogoPregunta"
            style={{
              marginTop: catalogo.estadoTexto ? "125px" : "12px",
            }}
          />
        </div>
        <div
          className="col"
          style={{
            height: catalogo.estadoTexto ? "250px" : "50px",
          }}
        >
          {catalogo.lstTextoPregunta.map((textPregunta, indiceTexto) => {
            return indiceTexto === 0 ? (
              <p
                style={{
                  margin: "2px 2px 10px 2px",
                  fontSize: "12.5px",
                  padding: "3px",
                  color: "#212529",
                  fontWeight: "normal",
                }}
                key={indiceTexto}
              >
                {textPregunta}
              </p>
            ) : (
              indiceTexto > 0 && catalogo.estadoTexto && (
                <p
                  style={{
                    margin: "2px 2px 10px 2px",
                    fontSize: "12.5px",
                    padding: "3px",
                    color: "#212529",
                    fontWeight: "normal",
                  }}
                  key={indiceTexto}
                >
                  {textPregunta}
                </p>
              )
            );
          })}
        </div>

        <div className="col-1">
          <div className="float-right">
            {catalogo.estadoTexto ? (
              <i
                className="fa fa-chevron-down d-block text-right animated fadeIn"
                aria-hidden="true"
                style={{
                  color: "#212529",
                  marginTop: catalogo.estadoTexto ? "125px" : "12px",
                }}
              ></i>
            ) : (
              <i
                className="fa fa-chevron-right d-block text-right animated fadeIn"
                aria-hidden="true"
                style={{
                  color: "#212529",
                  marginTop: catalogo.estadoTexto ? "125px" : "12px",
                }}
              ></i>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
