import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { guardar_negocio } from "../../../../../actions/actions";
import Authorized from "../../../../../utils/Authorized";
import ModalAsignarEjecutivo from "./ModalAsignarEjecutivo";
import moment from "moment";
import { notificacion } from "../../../transversales/Notificaciones";
import {
  ALERT_ULT_COTI,
  COD_CORREDOR,
  ESTADO_NEGOCIO_EN_PROCESO,
  RUTA_COTIZACION_CORREDOR_ESTADO_ENROLAMIENTO,
  TIPO_CANAL_VENTA_DIGITAL,
  VALOR_ANUAL,
} from "../../../../../utils/Constantes";

const { format } = require("rut.js");
const iconNegocio = require("../../../../assets/images/maletin-icon.svg");

class Negocio extends Component {
  constructor(props) {
    super(props);

    this.state = {
      negocio: this.props.negocio,
      tipoNegocio: this.props.tipoNegocio,
      dataModal: {
        value: 0,
        action: "",
      },
      modalAsignacion: false,
      blnSesionCorredor:
        this.props.store.auth.usuarioSesion.rol.codigo === COD_CORREDOR,
    };
  }

  componentWillMount() {
    if (sessionStorage.getItem("_nNegVC")) {
      let idNegocio = sessionStorage.getItem("_nNegVC");
      this.verDetalleNegocio(idNegocio);
    }
  }

  modalAsignacion = () => {
    let negocio = this.state.negocio;
    if (!negocio.ultimaCotizacion) return notificacion("info", ALERT_ULT_COTI);

    let dataModal = {
      value: negocio.idPersonaEjecutivo,
      action:
        negocio.ejecutivoAsignado === "Por asignar" ? "Asignar" : "Reasignar",
    };
    this.setState({
      dataModal,
      modalAsignacion: true,
    });
  };

  mostrarModalAsignacion = (boolean) => {
    this.setState({ modalAsignacion: boolean });
  };

  actualizarEjecutivoAsignado = (ejecutivo) => {
    let negocio = Object.assign({}, this.state.negocio);

    negocio.ejecutivoAsignado = `${ejecutivo.nombre} ${ejecutivo.apellidoPaterno}`;
    negocio.emailEjecutivoAsignado = ejecutivo.email;
    negocio.idEjecutivoAsignado = ejecutivo.idPersona;

    this.setState({ negocio });
  };

  verDetalleNegocio(idNegocio) {
    this.props.guardar_negocio(this.state.negocio);
    const { codigo } = this.props.store.auth.usuarioSesion.rol;
    if (codigo === COD_CORREDOR) {
      this.props.history.push({
        pathname: RUTA_COTIZACION_CORREDOR_ESTADO_ENROLAMIENTO,
        state: { idNegocio },
      });
    } else {
      this.props.history.push({
        pathname: "/cotizaciones/inicio",
        state: { idNegocio },
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.state.negocio.hasOwnProperty("ejecutivoAsignado") &&
          this.state.modalAsignacion && (
            <ModalAsignarEjecutivo
              negocio={this.state.negocio}
              ultimaCotizacion={this.state.negocio.ultimaCotizacion}
              tipoNegocio={this.state.tipoNegocio}
              value={this.state.dataModal.value}
              action={this.state.dataModal.action}
              idAsignado={this.state.negocio.idPersonaEjecutivo}
              modalAsignacion={this.state.modalAsignacion}
              actualizarEjecutivoAsignado={this.actualizarEjecutivoAsignado}
              mostrarModalAsignacion={this.mostrarModalAsignacion}
            />
          )}
        <div className="col-12 col-sm-6 col-md-6 col-lg-4 pb-2 pt-2">
          <div
            className={`${this.state.tipoNegocio === "activos" ? "shadow-active" : "shadow"
              } card tarjeta-negocio border-card`}
          >
            <div className="card-body">
              <Authorized roles={[4]}>
                <div
                  className="row"
                  style={{
                    height: 48,
                    overflow: "visible",
                    textOverflow: "ellipsis",
                  }}
                >
                  <Authorized roles={[4]}>
                    <div
                      className={`col-6 text-right ${this.state.negocio.ejecutivoAsignado ===
                        "Por asignar" && "text-muted"
                        } ml-auto`}
                    >
                      <i className="fa fa-user mr-1" aria-hidden="true" />
                      {this.state.negocio.ejecutivoAsignado}
                    </div>
                  </Authorized>
                </div>
              </Authorized>
              <div className="row">
                <div className="col-12 text-center pt-2">
                  <div className="img-icon">
                    <img alt="img-icon" src={iconNegocio} />
                  </div>
                </div>
              </div>

              <div className="row pt-4">
                <div className="col-12 text-center">
                  <h6
                    className="font-weight-bold"
                    style={{
                      height: 38,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {this.state.negocio.razonSocial}
                  </h6>
                  <p>
                    {format(
                      this.state.negocio.rutEmpresa +
                      this.state.negocio.digitoVerificador
                    )}
                  </p>
                  <button
                    id="verNegocio"
                    onClick={() => {
                      this.verDetalleNegocio(this.state.negocio.idNegocio);
                    }}
                    className="btn btn-principal btn-sm"
                    disabled={this.state.blnSesionCorredor && this.state.negocio.estadoNegocio.idEstadoNegocio < ESTADO_NEGOCIO_EN_PROCESO}
                  >
                    Ver
                  </button>
                </div>
              </div>
              <hr />

              <div className="row">
                <div className="col-4 text-center text-truncate">
                  <i className="fa fa-calendar-o" aria-hidden="true"></i>
                </div>
                <div className="col-4 text-center text-truncate">
                  <i className="fa fa-globe" aria-hidden="true"></i>
                </div>
                <div className="col-4 text-center text-truncate font-weight-bold text-primary">
                  UFA
                </div>
              </div>
              <div className="row">
                <div className="col-4 text-center text-truncate">
                  {this.state.negocio.fechaTerminoEnrolamiento
                    ? moment(
                      this.state.negocio.fechaTerminoEnrolamiento
                    ).format("DD-MM-YYYY")
                    : "-"}
                </div>
                <div className="col-4 text-center text-truncate">
                  {this.state.negocio.region.glosa}
                </div>
                <div className="col-4 text-center text-truncate">
                  {this.state.negocio.canalVenta.idCanalVenta ===
                    TIPO_CANAL_VENTA_DIGITAL
                    ? this.state.negocio.ultimaCotizacion &&
                    (this.state.negocio.ultimaCotizacion.primaUfa
                      ? this.state.negocio.ultimaCotizacion.primaUfa
                      : "-")
                    : this.state.negocio.valorUfa
                      ? this.state.negocio.valorUfa * VALOR_ANUAL
                      : "-"}
                </div>
              </div>

              <div className="row">
                <div className="col-8" />
                <div className="col-4">
                  <Authorized roles={[4]}>
                    {this.state.tipoNegocio === "activos" && (
                      <button
                        className="btn-foot-card bg-danger text-white"
                        onClick={() => this.modalAsignacion()}
                      >
                        {this.state.negocio.ejecutivoAsignado === "Por asignar"
                          ? "Asignar"
                          : "Reasignar"}
                      </button>
                    )}
                  </Authorized>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state,
});

export default withRouter(
  connect(mapStateToProps, { guardar_negocio })(Negocio)
);
