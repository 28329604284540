import React from "react";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import { POSICION_INICIAL } from "../../../../../utils/Constantes";
const { format } = require("rut.js");

const Beneficiarios = (props) => {
  const { beneficiarios } = useSelector(
    (store) => store.reducer.dataWizardAsegurado
  );
  const parentescos = useSelector((store) => store.reducer.parentescos_beneficiarios);
  const generos = useSelector((store) => store.reducer.generos);

  const findParentesco = (id) => {
    let parentesco = parentescos.filter((parentesco) => {
      return parentesco.idParentescoBeneficiario === parseInt(id);
    })[POSICION_INICIAL];

    return parentesco !== undefined ? parentesco : { glosa: "" };
  };

  const findGenero = (id) => {
    let genero = generos.filter((a) => {
      return a.idGenero === parseInt(id);
    })[0];

    return genero !== undefined ? genero : { descripcion: "" };
  };

  if (!beneficiarios) return;

  return (
    <React.Fragment>
      {beneficiarios.length ? (
        <div>
          <div>
            <label><strong>Beneficiarios</strong></label>

            {beneficiarios.map((item, i) => (
              <div className="row mt-2" key={i}>
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="card-text">
                        <div className="row">
                          <div className="col-12 col-md-3 col-lg-3">
                            <div className="row">
                              <div className="col-6 col-md-12 font-weight-bold text-muted">
                                RUT
                              </div>
                              <div className="col-6 col-md-12">
                                {format(
                                  `${item.rut}-${item.digitoVerificador}`
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="col-12 col-md-6 col-lg-6">
                            <div className="row">
                              <div className="col-6 col-md-12 font-weight-bold text-muted">
                                Nombre completo
                              </div>
                              <div className="col-6 col-md-12">
                                {`${item.nombre} ${item.apellidoPaterno} ${item.apellidoMaterno}`}
                              </div>
                            </div>
                          </div>

                          <div className="col-12 col-md-3 col-lg-3">
                            <div className="row">
                              <div className="col-6 col-md-12 font-weight-bold text-muted">
                                Parentesco
                              </div>
                              <div className="col-6 col-md-12">
                                {findParentesco(
                                  item.parentesco.idParentesco
                                ).glosa.toUpperCase()}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row mt-2">
                          <div className="col-12 col-md-3 col-lg-3">
                            <div className="row">
                              <div className="col-6 col-md-12 font-weight-bold text-muted">
                                Género
                              </div>
                              <div className="col-6 col-md-12">
                                {findGenero(
                                  item.genero.idGenero
                                ).descripcion.toUpperCase()}
                              </div>
                            </div>
                          </div>

                          <div className="col-12 col-md-4 col-lg-4">
                            <div className="row">
                              <div className="col-6 col-md-12 font-weight-bold text-muted">
                                Porcentaje beneficio
                              </div>
                              <div className="col-6 col-md-12">
                                {item.porcentajeBeneficio} %
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <Fragment>
          <h6 className="font-weight-bold">Beneficiarios (0)</h6>
          <p className="text-center mt-3">No se declaran beneficiarios</p>
        </Fragment>
      )}
    </React.Fragment>
  );
};

export default Beneficiarios;
