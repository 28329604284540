import React from "react";
import ModalEditarAsegurable from "../../../jefatura-ejecutivo/ModalEditarAsegurable";

export const ModalCantidadAsegurables = (props) => {
  return (
    <ModalEditarAsegurable
      idSubmit="submitCantidadAsegurables"
      type="small"
      show={props.modalCantidadAsegurables}
      title="Se ha superado el máximo de asegurables"
      action="Cerrar"
      onSubmit={() => {
        props.mostrarModalCantidadAsegurables();
      }}
      onCancel={() => {
        props.mostrarModalCantidadAsegurables();
      }}
      onClose={() => {
        props.mostrarModalCantidadAsegurables();
      }}
      showCancelButton={false}
      showCloseButton={true}
      showLeftColumn={false}
      showSubmitButton={true}
    >
      <div className="row">
        <h5 className="col-12 col-md-8 offset-md-2">
          Ha ingresado {props.maximoAsegurables} asegurables, lo que es el máximo permitido para esta cotización.
        </h5>
      </div>
    </ModalEditarAsegurable>
  );
};
