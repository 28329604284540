import { WIZARD_NEGOCIO_VENTA_DIRECTA } from "../utils/ConstantesVentaDirecta";
import { VALOR_UNO } from "../utils/Constantes";

/*************************
 *  TYPES                *
 *************************/
const GUARDAR_CANAL_VENTA_BUSQUEDA = "GUARDAR_CANAL_VENTA_BUSQUEDA";
const GUARDAR_CANAL_VENTA = "GUARDAR_CANAL_VENTA";
const GUARDAR_NEGOCIO_LDAP_CONSULTA = "GUARDAR_NEGOCIO_LDAP_CONSULTA";
const GUARDAR_WIZARD_NEGOCIO_LDAP = "GUARDAR_WIZARD_NEGOCIO_LDAP";
const GUARDAR_SUCURSAL_LDAP_CONSULTA = "GUARDAR_SUCURSAL_LDAP_CONSULTA";
const CAMBIAR_WIZARD_ACTUAL = "CAMBIAR_WIZARD_ACTUAL";

/*************************
 *  REDUCER              *
 *************************/
const initialState = {
  canalVentaBusqueda: null, // seleccion canal venta -> listar negocios 
  canalVenta: null, // seleccion canal venta -> crear negocio 
  negocioConsulta: null,
  sucursalConsulta: null,
  wizard: WIZARD_NEGOCIO_VENTA_DIRECTA,
  wizardActual: VALOR_UNO,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GUARDAR_CANAL_VENTA_BUSQUEDA:
      return {
        ...state,
        canalVentaBusqueda: action.payload,
      };
    case GUARDAR_CANAL_VENTA:
      return {
        ...state,
        canalVenta: action.payload,
      };
    case GUARDAR_NEGOCIO_LDAP_CONSULTA:
      return {
        ...state,
        negocioConsulta: action.payload,
      };
    case GUARDAR_SUCURSAL_LDAP_CONSULTA:
      return {
        ...state,
        sucursalConsulta: action.payload,
      };
    case GUARDAR_WIZARD_NEGOCIO_LDAP:
      return {
        ...state,
        wizard: action.payload,
      };
    case CAMBIAR_WIZARD_ACTUAL:
        return {
          ...state,
          wizardActual: action.payload,
        };

    default:
      return state;
  }
}

/*************************
 *  ACTIONS              *
 *************************/
export const guardar_canal_venta_busqueda = (data) => (dispatch) => {
  dispatch({
    type: GUARDAR_CANAL_VENTA_BUSQUEDA,
    payload: data,
  });
};

export const guardar_canal_venta = (data) => (dispatch) => {
  dispatch({
    type: GUARDAR_CANAL_VENTA,
    payload: data,
  });
};

export const guardar_negocio_ldap_consulta = (data) => (dispatch, getState) => {
  dispatch({
    type: GUARDAR_NEGOCIO_LDAP_CONSULTA,
    payload: data,
  });
};

export const guardar_sucursal_ldap_consulta = (data) => (
  dispatch,
  getState
) => {
  dispatch({
    type: GUARDAR_SUCURSAL_LDAP_CONSULTA,
    payload: data,
  });
};

export const guardar_wizard_negocio_ldap = (data) => (dispatch, getState) => {
  dispatch({
    type: GUARDAR_WIZARD_NEGOCIO_LDAP,
    payload: data,
  });
};

export const cambiar_wizard_actual = (data) => (dispatch, getState) => {
  dispatch({
    type: CAMBIAR_WIZARD_ACTUAL,
    payload: data,
  });
}
