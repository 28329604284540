import { 
	ESCOGER_INCORPORACION,
	ESCOGER_PERFIL, 
	FORM_INICIO_SESION, 
	PERFILES_INICIO_SESION, 
	TIPO_INCORPORACION, 
	TIPO_INCORPORACION_PREVIO, 
	TIPO_USUARIO, 
	USUARIO_AUTENTICADO, 
	USUARIO_PERFILES, 
	USUARIO_SESION
} from "./typesAuth";

export const guardarTipoUsuario = (objContenido) => {
	return {
		type: TIPO_USUARIO,
		contenido: objContenido,
	};
};

export const guardarPerfilesInicioSesion = (objContenido) => {
	return {
		type: PERFILES_INICIO_SESION,
		contenido: objContenido,
	};
};

export const guardarEscogerPerfil = (objContenido) => {
	return {
		type: ESCOGER_PERFIL,
		contenido: objContenido,
	};
};

export const guardarFormulario = (objContenido) => {
	return {
		type: FORM_INICIO_SESION,
		contenido: objContenido,
	};
};

export const guardarUsuarioSesion = (objContenido) => {
	return {
		type: USUARIO_SESION,
		contenido: objContenido,
	};
};

export const guardarUsuarioPerfiles = (objContenido) => {
	return {
		type: USUARIO_PERFILES,
		contenido: objContenido,
	};
};

export const guardarUsuarioAutenticado = (objContenido) => {
	return {
		type: USUARIO_AUTENTICADO,
		contenido: objContenido,
	};
};

export const guardarEscogerIncorporacion = (objContenido) => {
	return {
		type: ESCOGER_INCORPORACION,
		contenido: objContenido,
	};
};

export const guardarTipoIncorporacion = (objContenido) => {
	return {
		type: TIPO_INCORPORACION,
		contenido: objContenido,
	};
};

export const guardarTipoIncorporacionPrevio = (objContenido) => {
	return {
		type: TIPO_INCORPORACION_PREVIO,
		contenido: objContenido,
	};
};