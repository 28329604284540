import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import ReactDOM from "react-dom";
import { withRouter } from "react-router-dom";
import {
    RUTA_NEGOCIOS,
    VALOR_UNO,
    VALOR_DOS,
} from "../../../../../utils/Constantes";
import ImgGestionarEjecutivo from "../../../../assets/images/gestionarEjecutivo.png";
import ImgNuevoNegocio from "../../../../assets/images/nuevo-negocio.svg";
import {
    guardar_opt_gestionar_ejecutivo,
} from "../../../../../actions/actions";

const ModalGestionarEjecutivos = (props) => {
    const [mostrarDialogo, setMostrarDialogo] = useState(true);

    useEffect(() => {
        iniciarMostrarDialogo(true);
        // eslint-disable-next-line
    }, []);

    const cerrarDialogo = async () => {
        setMostrarDialogo(false);
        props.history.push(RUTA_NEGOCIOS);
    };

    const modificarOpcionGestionarEjecutivo = async (opcion) => {
        props.guardar_opt_gestionar_ejecutivo(opcion)
        setMostrarDialogo(false);
    };

    const iniciarMostrarDialogo = async (valor) => {
        setMostrarDialogo(valor);
    }

    return ReactDOM.createPortal(
        <div
            className={`${mostrarDialogo ? "modal_overlay show lbg" : "modal_overlay"}`}
        >
            <div className="container h-100">
                <div className="align-self-center w-100">
                    <div
                        className="main_modal animated bounceInLeft center-2 large"
                        style={{ maxWidth: "750px" }}
                    >
                        <div className="row">
                            <div className="col-md-12">
                                <div className="modal_title">
                                    <div className="title title font-weight-bold text-primary text-left">
                                        {props.titulo}
                                    </div>
                                    {true ? (
                                        <div
                                            className="button_close cursor"
                                            onClick={() => {
                                                cerrarDialogo();
                                            }}
                                        >
                                            <svg
                                                stroke="currentColor"
                                                fill="currentColor"
                                                viewBox="0 0 352 512"
                                                height="1em"
                                                width="1em"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z" />
                                            </svg>
                                        </div>
                                    ) : null}
                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="container mt-3">
                                            <div className="row mx-3">
                                                {/* AGREGAR EJECUTIVO */}
                                                <div className="col-md-6">
                                                    <div
                                                        id="seleccionarAgregarEjecutivo"
                                                        className="card shadow text-primary  cursor"
                                                        onClick={() => {
                                                            modificarOpcionGestionarEjecutivo(VALOR_UNO);
                                                        }}
                                                    >
                                                        <div className="card-body py-md-5">
                                                            <img
                                                                src={ImgGestionarEjecutivo}
                                                                width="140"
                                                                height="200"
                                                                className="img-fluid d-block mx-auto mb-2"
                                                                alt=""
                                                            ></img>
                                                            <h5 className="text-center">
                                                                {" "}Agregar ejecutivos
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </div>
                                                { /* EDITAR EJECUTIVO */}
                                                <div className="col-md-6">
                                                    <div
                                                        id="seleccionarEditarEjecutivo"
                                                        className="card shadow text-primary  cursor"
                                                        onClick={() => {
                                                            modificarOpcionGestionarEjecutivo(VALOR_DOS);
                                                        }}
                                                    >
                                                        <div className="card-body py-md-5">
                                                            <img
                                                                src={ImgNuevoNegocio}
                                                                height="200"
                                                                className="img-fluid d-block mx-auto mb-2"
                                                                alt=""
                                                            ></img>
                                                            <h5 className="text-center">
                                                                {" "}Mover Ejecutivo
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="container mt-3 col-11">
                                            <button
                                                type="button"
                                                className="btn btn-principal mt-4 d-block ml-auto"
                                                onClick={() => cerrarDialogo()}
                                            >
                                                Cerrar
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className="modal_foot"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>,
        document.querySelector(`#spinnerNaranjo`)
    );
};

const mapStateToProps = (state) => ({
    store: state,
});

export default withRouter(
    connect(mapStateToProps, {
        guardar_opt_gestionar_ejecutivo,
    })(ModalGestionarEjecutivos)
);
