import React from "react";
import { useSelector } from "react-redux";
const { format } = require("rut.js");

const DatosEmpresa = () => {
  const { razonSocial, rutEmpresa, digitoVerificadorEmpresa } = useSelector(
    (store) => store.reducer.data_asegurable
  );

  return (
    <div>
      <h6 className="bg-secondary text-white p-2 linea-subtitulo font-weight-bold">
        DATOS EMPRESA CONTRATANTE
      </h6>
      <div className="row">
        <div className="col-12 col-md-6">
          <div className="row">
            <div className="col-6 col-md-12 font-weight-bold text-muted">
              RUT
            </div>
            <div className="col-6 col-md-12">
              {format(rutEmpresa+""+digitoVerificadorEmpresa)}
            </div>
          </div>
        </div>

        <div className="col-12 col-md-6">
          <div className="row">
            <div className="col-6 col-md-12 font-weight-bold text-muted">
              Razón Social
            </div>
            <div className="col-6 col-md-12">{razonSocial}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DatosEmpresa;
