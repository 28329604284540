import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  componente_wizard_ejecutivo,
  guardar_datos_sucursal,
  guardar_negocio,
  guardar_regiones,
  guardar_ciudades,
  guardar_comunas,
} from "../../../../../../actions/actions";
import { formatNumber, formatTel, validacionDireccion } from "../../../../../../utils/Functions";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  obtenerRegiones,
  obtenerCiudades,
  obtenerComunas,
} from "../../../../../../services/transversales.service";
import {
  actualizarConfiguracionSucursal,
  enviarOrganizacionSucursalEjecutivo,
} from "../../../../../../services/negocios.service";
import { notificacion } from "../../../../transversales/Notificaciones";
import {
  CAMPO_REQUERIDO,
  VALIDACION_MAIL,
  VALIDACION_TELEFONO,
  VALIDACION_DIRECCION,
  VALIDACION_REGION,
  VALIDACION_COMUNA,
  ERROR_ACTUALIZAR_SUCURSAL,
  EXITO_ACTUALIZAR_SUCURSAL,
  VALOR_UNO,
  VALOR_DOS,
  CAMPO_REQUERIDO_TELEFONO,
} from "../../../../../../utils/Constantes";

const { format } = require("rut.js");

class DatosSucursal extends Component {
  negocio;
  constructor(props) {
    super(props);

    this.state = {
      cargando: true,
      loadingData: false,
      negocio: this.props.negocio,
      disableForm: false,
      datosSucursal: {
        idOrganizacionSucursal: 0,
        idSucursal: null,
        rutEmpresa: null,
        direccion: null,
        numero: null,
        complemento: null,
        region: null,
        comuna: null,
        telefono: null,
        email: null,
        glosa: null,
      },
      catalogo: {
        generos: [],
        regiones: [],
        ciudades: [],
        comunas: [],
        comunasRegion: [],
      },
      cambioRegion: false,
    };
  }

  async componentWillMount() {
    await this.obtenerRegiones();
    await this.obtenerCiudades();
    await this.obtenerComunas();

    //Llenar state sucursal
    if (this.props.sucursal) {
      let datosSucursal = {
        idOrganizacion: this.props.sucursal.idOrganizacion,
        idOrganizacionSucursal: this.props.sucursal.idOrganizacionSucursal,
        idSucursal: this.props.sucursal.idSucursal,
        rutEmpresa: this.props.negocio.rut,
        direccion: this.props.sucursal.calle,
        numero: this.props.sucursal.numero,
        complemento: this.props.sucursal.complemento,
        region: null,
        comuna: this.props.sucursal.comuna.idComuna,
        telefono: this.props.sucursal.telefonoFijo1,
        email: this.props.sucursal.email,
        glosa: this.props.sucursal.glosa,
      };

      //Obtener region datosSucursal
      const regionSucursal = this.obtenerRegionDeUnaCiudad(
        this.props.sucursal.comuna.idComuna
      );
      datosSucursal.region = regionSucursal;
      this.setState({ datosSucursal }, () => {
        this.obtenerComunasDeUnaRegion(regionSucursal);
      });
    }

    // Validar tipo canal venta negocio
    const negocio_ = this.props.store.reducer.negocio;
    if (
      negocio_.canalVenta.idCanalVenta !== VALOR_UNO ||
      negocio_.estadoNegocio.idEstadoNegocio > VALOR_DOS
    ) {
      this.setState({ disabledForm: true });
    }

    this.setState({ cargando: false });
  }

  /*************************
   *  PETICIONES SERVIDOR  *
   *************************/
  async enviarDatosSucursal(values) {
    this.setState({ loadingData: true });

    const dataEnvio = {
      digitoVerificador: this.state.negocio.rut.split("-")[1],
      rutEmpresa: Number(this.state.negocio.rut.split("-")[0]),
      idNegocio: this.state.negocio.idNegocio,
      idOrganizacionNoEnrolada: this.state.negocio.idOrganizacionNoEnrolada,
      sucursales: [
        {
          glosa: this.state.negocio.razonSocial,
          idUsuarioCrea: this.props.store.auth.usuarioSesion.id,
          calle: values.direccion,
          ciudad: {
            idCiudad: this.obtenerCiudadDeUnaComuna(values.comuna),
          },
          complemento: values.complemento,
          comuna: {
            idComuna: Number(values.comuna),
          },
          email: values.email,
          idOrganizacion: this.state.datosSucursal.idOrganizacion,
          idOrganizacionSucursal:
            this.state.datosSucursal.idOrganizacionSucursal,
          numero: values.numero,
          telefonoFijo1: values.telefono,
        },
      ],
    };

    if (this.props.sucursal) {
      if (this.validarActualizacionFormulario(dataEnvio)) {
        this.props.wizardChange(this.props.wizard + 1);
        return;
      }
    }

    // Validar envio, con tipo canal venta y estado negocio negocio
    const negocio_ = this.props.store.reducer.negocio;
    if (
      negocio_.canalVenta.idCanalVenta === VALOR_UNO &&
      negocio_.estadoNegocio.idEstadoNegocio <= VALOR_DOS
    ) {
      const idOrganizacionSucursal = await actualizarConfiguracionSucursal(
        dataEnvio
      );
      if (!idOrganizacionSucursal) {
        notificacion("danger", ERROR_ACTUALIZAR_SUCURSAL);
      } else {
        // Crear/Actualiza sucursal ejecutivo
        const idEjecutivo = this.props.store.reducer.negocio.idPersonaEjecutivo;
        const sucursal = this.props.store.reducer.negocio.sucursales[0];
        const idUsuario = this.props.store.auth.usuarioSesion.id;

        let dataEnvio = {
          idPersona: idEjecutivo,
          idUsuarioModifica: idUsuario,
          idOrganizacionSucursal,
        };

        if (sucursal) {
          if (sucursal.organizacionSucursalEjecutivo) {
            dataEnvio.idOrganizacionSucursalEjecutivo =
              sucursal.organizacionSucursalEjecutivo.idOrganizacionSucursalEjecutivo;
          }
        }

        let idOrganizacionSucursalEjecutivo =
          await enviarOrganizacionSucursalEjecutivo(dataEnvio);

        if (idOrganizacionSucursalEjecutivo) {
          let negocio = this.props.store.reducer.negocio;
          if (negocio.sucursales[0]) {
            negocio.sucursales[0].organizacionSucursalEjecutivo = {
              idOrganizacionSucursalEjecutivo,
            };
          } else {
            negocio.sucursales = [
              {
                organizacionSucursalEjecutivo: {
                  idOrganizacionSucursalEjecutivo,
                },
              },
            ];
          }
          await this.props.guardar_datos_sucursal(dataEnvio.sucursales);
          await this.props.guardar_negocio(negocio);
        }

        notificacion("success", EXITO_ACTUALIZAR_SUCURSAL);
      }
      this.props.wizardChange(this.props.wizard + 1);
    }

    this.setState({ loadingData: false });
  }

  async obtenerRegiones() {
    if (this.props.store.reducer.regiones.length < 1) {
      let catalogo = Object.assign({}, this.state.catalogo);
      let regiones = await obtenerRegiones();
      this.props.guardar_regiones(regiones);
      catalogo.regiones = regiones;
      this.setState({ catalogo });
    } else {
      let catalogo = Object.assign({}, this.state.catalogo);
      let regiones = this.props.store.reducer.regiones;

      catalogo.regiones = regiones;

      this.setState({ catalogo });
    }
  }

  async obtenerCiudades() {
    if (this.props.store.reducer.ciudades.length < 1) {
      let catalogo = Object.assign({}, this.state.catalogo);
      let ciudades = await obtenerCiudades();
      this.props.guardar_ciudades(ciudades);
      catalogo.ciudades = ciudades;
      this.setState({ catalogo });
    } else {
      let catalogo = Object.assign({}, this.state.catalogo);
      let ciudades = this.props.store.reducer.ciudades;
      catalogo.ciudades = ciudades;
      this.setState({ catalogo });
    }
  }

  async obtenerComunas() {
    if (this.props.store.reducer.comunas.length < 1) {
      let catalogo = Object.assign({}, this.state.catalogo);
      let comunas = await obtenerComunas();
      this.props.guardar_comunas(comunas);
      catalogo.comunas = comunas;
      this.setState({ catalogo });
    } else {
      let catalogo = Object.assign({}, this.state.catalogo);
      let comunas = this.props.store.reducer.comunas;
      catalogo.comunas = comunas;
      this.setState({ catalogo });
    }
  }

  /*************************
   * * * VALIDACIONES      *
   *************************/
  validarActualizacionFormulario(dataForm) {
    const entrada = this.props.sucursal;
    const envio = dataForm.sucursales[0];

    const direccion = entrada.calle === envio.calle;
    const numero = entrada.numero === envio.numero;
    const complemento = entrada.complemento === envio.complemento;
    const comuna = entrada.comuna.idComuna === envio.comuna.idComuna;
    const email = entrada.email === envio.email;
    const telefono = entrada.telefonoFijo1 === envio.telefonoFijo1;

    const validacion =
      direccion && numero && complemento && comuna && email && telefono;

    return validacion;
  }

  obtenerComunasDeUnaRegion(idRegion) {
    let ciudades = this.state.catalogo.ciudades.filter((c) => {
      return Number(idRegion) === Number(c.idRegion);
    });

    let comunasRegion = [];
    ciudades.forEach((c) => {
      this.state.catalogo.comunas.forEach((co) => {
        if (Number(co.idCiudad) === Number(c.idCiudad)) {
          return comunasRegion.push(co);
        }
      });
    });

    // Eliminar repetidos
    let flag = {};
    comunasRegion = comunasRegion.filter((e) => {
      let exists = !flag[e.glosaComuna] || false;
      flag[e.glosaComuna] = true;
      return exists;
    });

    let catalogo = Object.assign({}, this.state.catalogo);
    catalogo.comunasRegion = comunasRegion;
    this.setState({ catalogo });
  }

  obtenerCiudadDeUnaComuna(idComuna) {
    if (!idComuna) return;
    let comuna = this.state.catalogo.comunas.find((co) => {
      return Number(idComuna) === Number(co.idComuna);
    });

    let ciudad = this.state.catalogo.ciudades.find((ci) => {
      return Number(comuna.idCiudad) === Number(ci.idCiudad);
    });

    return ciudad.idCiudad;
  }

  obtenerRegionDeUnaCiudad(idComuna) {
    if (!idComuna) return;
    let comuna = this.state.catalogo.comunas.find((co) => {
      return Number(idComuna) === Number(co.idComuna);
    });

    let ciudad = this.state.catalogo.ciudades.find((ci) => {
      return Number(comuna.idCiudad) === Number(ci.idCiudad);
    });

    if (ciudad === undefined) return 0;

    return ciudad.idRegion;
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    return (
      <React.Fragment>
        {!this.state.cargando ? (
          <Formik
            initialValues={{
              rutEmpresa: this.state.negocio.rut,
              direccion: this.state.datosSucursal.direccion
                ? this.state.datosSucursal.direccion
                : "",
              numero: this.state.datosSucursal.numero
                ? this.state.datosSucursal.numero
                : "",
              complemento: this.state.datosSucursal.complemento
                ? this.state.datosSucursal.complemento
                : "",
              region: this.state.datosSucursal.region
                ? this.state.datosSucursal.region
                : "",
              comuna: this.state.datosSucursal.comuna
                ? this.state.datosSucursal.comuna
                : "",
              telefono: this.state.datosSucursal.telefono
                ? this.state.datosSucursal.telefono
                : " ",
              email: this.state.datosSucursal.email
                ? this.state.datosSucursal.email
                : "",
            }}
            validationSchema={Yup.object().shape({
              rutEmpresa: Yup.string(),
              direccion: Yup.string()
                .min(3, VALIDACION_DIRECCION)
                .required(CAMPO_REQUERIDO),
              numero: Yup.string().required(CAMPO_REQUERIDO),
              complemento: Yup.string(),
              region: Yup.string()
                .required(CAMPO_REQUERIDO)
                .test("select", VALIDACION_REGION, (value) => {
                  return Number(value) !== 0 ? true : false;
                }),
              comuna: Yup.string()
                .required(CAMPO_REQUERIDO)
                .test("select", VALIDACION_COMUNA, (value) => {
                  let cambioRegion = this.state.cambioRegion;
                  if (cambioRegion) {
                    value = 0;
                    this.setState({ cambioRegion: false });
                  }
                  return Number(value) !== 0 ? true : false;
                }),
              telefono: Yup.string()
                .required(CAMPO_REQUERIDO_TELEFONO)
                .test("validaTelefono", VALIDACION_TELEFONO, (value) => {
                  return formatTel(value).length === 13 ||
                    formatTel(value).length === 3
                    ? true
                    : false;
                }),
              email: Yup.string()
                .email(VALIDACION_MAIL)
                .required(CAMPO_REQUERIDO),
            })}
            onSubmit={async (values, { setSubmitting }) => {
              this.enviarDatosSucursal(values);
            }}
          >
            {({ values, errors, touched, handleChange, handleSubmit }) => {
              return (
                <div className="container">
                  <Form
                    className="mx-5"
                    onSubmit={handleSubmit}
                    onKeyDown={(e) => {
                      if ((e.charCode || e.keyCode) === 13) {
                        e.preventDefault();
                      }
                    }}
                  >
                    <div className="row animated fadeInRight">
                      {/* rut empresa */}
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>RUT Empresa</label>
                          <Field
                            disabled
                            className="form-control"
                            id="rutEmpresa"
                            name="rutEmpresa"
                            type="text"
                            placeholder="ej. 12345678-9"
                            maxLength="12"
                            minLength="11"
                            value={format(values.rutEmpresa)}
                          />
                        </div>
                      </div>

                      {/* dirección */}
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Dirección <span className="text-danger">*</span>
                          </label>

                          <Field
                            className="form-control"
                            id="direccion"
                            name="direccion"
                            type="text"
                            placeholder="Ingrese dirección"
                            disabled={this.state.disabledForm}
                            value={validacionDireccion(values.direccion)}
                          />
                          {errors.direccion && touched.direccion && (
                            <small className="text-danger animated fadeIn">
                              {errors.direccion}
                            </small>
                          )}
                        </div>
                      </div>

                      {/* número */}
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>
                            Número <span className="text-danger">*</span>
                          </label>

                          <Field
                            className="form-control"
                            id="numero"
                            name="numero"
                            type="text"
                            placeholder="Ingrese número"
                            maxLength="25"
                            disabled={this.state.disabledForm}
                            value={formatNumber(values.numero)}
                          />
                          {errors.numero && touched.numero && (
                            <small className="text-danger">
                              {errors.numero}
                            </small>
                          )}
                        </div>
                      </div>

                      {/* complemento */}
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Depto/Casa/Oficina </label>

                          <Field
                            className="form-control"
                            id="complemento"
                            name="complemento"
                            type="text"
                            placeholder=""
                            maxLength="50"
                            disabled={this.state.disabledForm}
                            value={values.complemento}
                          />
                          {errors.complemento && touched.complemento && (
                            <small className="text-danger">
                              {errors.complemento}
                            </small>
                          )}
                        </div>
                      </div>

                      {/* región */}
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Región <span className="text-danger">*</span>
                          </label>
                          <select
                            id="region"
                            name="region"
                            className="form-control"
                            style={{ display: "block" }}
                            disabled={this.state.disabledForm}
                            value={values.region ? values.region : 0}
                            onChange={(e) => {
                              handleChange(e);
                              this.setState({ cambioRegion: true });
                              this.obtenerComunasDeUnaRegion(e.target.value);
                            }}
                          >
                            <option value="0"> Seleccione región</option>
                            {this.state.catalogo.regiones.length > 0 &&
                              this.state.catalogo.regiones.map((item, i) => (
                                <option key={i} value={item.idRegion}>
                                  {item.glosaRegion}
                                </option>
                              ))}
                          </select>

                          {errors.region && touched.region && (
                            <small className="text-danger">
                              {errors.region}
                            </small>
                          )}
                        </div>
                      </div>

                      {/* comuna */}
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Comuna <span className="text-danger">*</span>
                          </label>
                          <select
                            id="comuna"
                            name="comuna"
                            className="form-control"
                            style={{ display: "block" }}
                            value={values.comuna ? values.comuna : 0}
                            onChange={handleChange}
                            disabled={
                              values.region === 0 ||
                              values.region === "0" ||
                              this.state.disabledForm
                            }
                          >
                            <option value="0"> Seleccione comuna</option>
                            {this.state.catalogo.comunasRegion.length > 0 &&
                              this.state.catalogo.comunasRegion.map(
                                (item, i) => (
                                  <option key={i} value={item.idComuna}>
                                    {item.glosaComuna}
                                  </option>
                                )
                              )}
                          </select>
                          {errors.comuna &&
                            touched.comuna &&
                            (values.region !== 0 || values.region !== "0") && (
                              <small className="text-danger">
                                {errors.comuna}
                              </small>
                            )}
                        </div>
                      </div>

                      {/* teléfono */}
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Teléfono <span className="text-danger">*</span>
                          </label>
                          <Field
                            id="telefono"
                            name="telefono"
                            className="form-control"
                            maxLength="13"
                            disabled={this.state.disabledForm}
                            value={formatTel(values.telefono)}
                          />
                          {errors.telefono && touched.telefono && (
                            <small className="text-danger animated fadeIn">
                              {errors.telefono}
                            </small>
                          )}
                        </div>
                      </div>

                      {/* email */}
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Email <span className="text-danger">*</span>
                          </label>
                          <Field
                            className="form-control"
                            id="email"
                            name="email"
                            type="text"
                            placeholder="test@gmail.com"
                            maxLength="50"
                            disabled={this.state.disabledForm}
                            value={values.email}
                          />
                          {errors.email && touched.email && (
                            <small className="text-danger animated fadeIn">
                              {errors.email}
                            </small>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="button-zone text-right pt-3 pb-3">
                      <button
                        className="btn btn-principal color-secundario"
                        type="button"
                        onClick={() =>
                          this.props.wizardChange(this.props.wizard - 1)
                        }
                      >
                        Atrás
                      </button>

                      <button
                        id="botonSiguiente"
                        className="btn btn-principal mr-3"
                        type="submit"
                        disabled={this.state.loadingData}
                      >
                        {this.state.loadingData ? (
                          <Fragment>
                            <i className="fa fa-spinner fa-pulse fa-fw"></i>
                            <span className="sr-only">Loading...</span>
                          </Fragment>
                        ) : (
                          <span>Siguiente</span>
                        )}
                      </button>
                    </div>
                  </Form>
                </div>
              );
            }}
          </Formik>
        ) : (
          <div className="d-flex justify-content-center animated fadeIn my-5">
            <div className="spinner-border text-primary mb-5" role="status">
              <span className="sr-only">Cargando...</span>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state,
});

export default withRouter(
  connect(mapStateToProps, {
    componente_wizard_ejecutivo,
    guardar_datos_sucursal,
    guardar_negocio,
    guardar_regiones,
    guardar_ciudades,
    guardar_comunas,
  })(DatosSucursal)
);
