import React from "react";
import { store } from "react-notifications-component";


function MyNotification(type, message) {
  let icon;
  if (type === "success") {
    icon = "fa fa-check-circle fa-2x";
  } else if (type === "info") {
    icon = "fa fa-info-circle fa-2x";
  } else if (type === "warning") {
    icon = "fa fa-exclamation-circle fa-2x";
  } else if (type === "danger") {
    icon = "fa fa-times-circle fa-2x";
  }
  return (
    <div
      className={`animated fadeIn notification-item notification-${type} n-child mb-0`}
    >
      <div className="notification-content">
        <div className="notification-close"></div>
        <div className="row">
          <div className="col-2 align-self-center">
            <i className={"text-white " + icon}></i>
          </div>
          <div className="col-10 align-self-center">
            <p className="notification-message">{message}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export function notificacion(type, message) {
  return store.addNotification({
    content: MyNotification(type, message),
    container: "top-right",
    animationIn: ["animated", "fadeIn"],
    animationOut: ["animated", "fadeOut"],
    dismiss: {
      duration: 5000
    }
  });
}
