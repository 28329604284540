import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  VALIDACION_DIRECCION,
  CAMPO_REQUERIDO,
  VALIDACION_REGION,
  VALIDACION_MAIL,
  VALIDACION_TELEFONO,
  VALIDACION_SUCURSAL,
  DONE,
  VALOR_ZERO,
  PERFIL_LDAP,
  VALOR_UNO,
  VALOR_NEGATIVO,
  CAMPO_REQUERIDO_NUMERO,
  VALIDACION_COMUNA,
  CAMPO_REQUERIDO_DIRECCION,
  CAMPO_REQUERIDO_NOMBRE_SUCURSAL,
  CAMPO_REQUERIDO_TELEFONO,
} from "../../../../../../utils/Constantes";
import {
  INITIAL_STATE_SUCURSAL,
  SIN_DELEGADO_ASIGNADO,
  STORAGE_SUCURSAL,
  LABEL_NUEVA_SUCURSAL,
} from "../../../../../../utils/ConstantesVentaDirecta";
import { formatNumber, formatTel, soloLetrasNumerosYAmpersand, validacionDireccion } from "../../../../../../utils/Functions";
import { useSelector, useDispatch } from "react-redux";
import { MySelect } from "../../../../transversales/Select";
import {
  obtenerPersonaPorRut,
  obtenerConfiguracionDelegado,
} from "../../../../../../services/negocios.service";
import {
  obtenerRegiones,
  obtenerCiudades,
  obtenerComunas,
} from "../../../../../../services/transversales.service";
import { getGeneros } from "../../../../../../services/asegurable.services";
import {
  guardar_regiones,
  guardar_ciudades,
  guardar_comunas,
  get_generos,
} from "../../../../../../actions/actions";
import CryptoJS from "react-native-crypto-js";
import {
  obtenerComunasDeUnaRegion,
  obtenerCiudadDeUnaComuna,
} from "../../../../../../utils/Territorial";
import {
  guardar_sucursal_ldap_consulta,
  guardar_wizard_negocio_ldap,
  cambiar_wizard_actual,
} from "../../../../../../reducer/ldap";
const { format } = require("rut.js");

const CDatosSucursal = ({ step }) => {
  const { negocioConsulta } = useSelector((store) => store.ldap);
  const { sucursalConsulta } = useSelector((store) => store.ldap);

  const { regiones, ciudades, comunas, generos } = useSelector(
    (store) => store.reducer
  );
  const { wizard, wizardActual } = useSelector((store) => store.ldap);

  const initialState = {
    rutEmpresa: negocioConsulta.rut,
    ...INITIAL_STATE_SUCURSAL,
  };

  const [nuevaSucursal, setNuevaSucursal] = useState(true);
  const [disableForm, setDisableForm] = useState(false);
  const [sucursales, setSucursales] = useState([]);
  const [errorSucursal, setErrorSucursal] = useState(false);
  const [sucursal, setSucursal] = useState(
    sucursalConsulta ? sucursalConsulta : initialState
  );
  const [delegadosSucursal, setDelegadosSucursal] = useState([]);
  const [delegadoSucursal, setDelegadoSucursal] = useState({});
  const [comunasRegion, setComunasRegion] = useState([]);

  const dispatch = useDispatch();

  /****************************
   *  [INICIAL] DATA SUCURSAL *
   ****************************/
  useEffect(() => {
    const obtenerDelegadosSucursales = async () => {
      let rutDelegados = negocioConsulta.sucursales.map((e) => e.rutDelegado);
      rutDelegados = [...new Set(rutDelegados)];

      rutDelegados = rutDelegados.filter((rut) => {
        return rut !== null;
      });

      let delegados = await obtenerDelegadoPersona(rutDelegados);
      let sucursales = agregarDelegadosSucursales(
        negocioConsulta.sucursales,
        delegados
      );

      let flag = {};
      sucursales = sucursales.filter((e) => {
        let exists = !flag[e.idOrganizacionSucursal] || false;
        flag[e.idOrganizacionSucursal] = true;
        return exists;
      });
      if (sucursales.length === VALOR_ZERO && !sucursalConsulta) {
        setDisableForm(false);
        let initialState_ = initialState;
        initialState_.sucursal = {
          value: VALOR_ZERO,
          label: LABEL_NUEVA_SUCURSAL,
        };
        setSucursal(initialState_);
      }

      setSucursales(sucursales);
    };
    if (sucursal.sucursal) setDisableForm(true);
    obtenerDelegadosSucursales();

    // eslint-disable-next-line
  }, []);

  const obtenerDatosSucursal = async (t, v) => {
    // Validar opción Nueva Sucursal
    if (v.value === VALOR_ZERO || v.value === VALOR_NEGATIVO) {
      setNuevaSucursal(true);
      let estadoInicial = initialState;
      if (v.value === VALOR_ZERO) {
        estadoInicial.sucursal = {
          value: VALOR_ZERO,
          label: LABEL_NUEVA_SUCURSAL,
        };
        setErrorSucursal(false);
      }
      setSucursal(estadoInicial);
      setDisableForm(false);
      return;
    }

    setNuevaSucursal(false);
    setErrorSucursal(false);
    setDisableForm(true);

    const idOrganizacionSucursal = Number(v.value);

    let dataSucursal = sucursales.find(
      (e) => e.idOrganizacionSucursal === idOrganizacionSucursal
    );

    if (dataSucursal.rutDelegado) {
      obtenerDataDelegado(dataSucursal);
    }

    const comunasRegion = obtenerComunasDeUnaRegion(
      dataSucursal.comuna.region.idRegion,
      ciudades,
      comunas
    );
    setComunasRegion(comunasRegion);

    setSucursal({
      sucursal: {
        value: `${dataSucursal.idOrganizacionSucursal}/${dataSucursal.rutDelegado}`,
        label: `» ${dataSucursal.nombreSucursal} / ${dataSucursal.direccion} #${dataSucursal.numero} / ${dataSucursal.nombreCompleto}`,
      },
      idSucursal: dataSucursal.idSucursal,
      idOrganizacionSucursal: dataSucursal.idOrganizacionSucursal,
      rutEmpresa: negocioConsulta.rut,
      nombreSucursal: dataSucursal.nombreSucursal,
      direccion: dataSucursal.direccion,
      numero: dataSucursal.numero,
      complemento: dataSucursal.complementoDireccion,
      region: dataSucursal.comuna.region.idRegion,
      comuna: dataSucursal.comuna.idComuna,
      telefono: dataSucursal.telefono,
      email: dataSucursal.correoElectronico,
      idPersonaDelegado: dataSucursal.sucursalContacto[0]
        ? dataSucursal.sucursalContacto[0].idPersona
        : null,
    });
  };

  // Validar sucursal
  useEffect(() => {
    const value = sucursal.sucursal.value;
    if (value === VALOR_ZERO) setDisableForm(false);
    else setDisableForm(true);
  }, [sucursal]);

  /**************************
   *  SERVICIOS CATALOGO    *
   **************************/
  useEffect(() => {
    const obtenerRegiones_ = async () => {
      if (regiones.length < VALOR_UNO) {
        let resRegiones = await obtenerRegiones();
        dispatch(guardar_regiones(resRegiones));
      }
    };
    obtenerRegiones_();
  }, [regiones, dispatch]);

  useEffect(() => {
    const obtenerCiudades_ = async () => {
      if (ciudades.length < VALOR_UNO) {
        let resCiudades = await obtenerCiudades();
        dispatch(guardar_ciudades(resCiudades));
      }
    };
    obtenerCiudades_();
  }, [ciudades, dispatch]);

  useEffect(() => {
    const obtenerComunas_ = async () => {
      if (comunas.length < VALOR_UNO) {
        let resComunas = await obtenerComunas();
        dispatch(guardar_comunas(resComunas));
      }
    };
    obtenerComunas_();
  }, [comunas, dispatch]);

  useEffect(() => {
    if (
      sucursal.region &&
      ciudades.length > VALOR_ZERO &&
      comunas.length > VALOR_ZERO
    ) {
      const comunasRegion = obtenerComunasDeUnaRegion(
        sucursal.region,
        ciudades,
        comunas
      );
      setComunasRegion(comunasRegion);
    }
  }, [ciudades, comunas, sucursal.region]);

  useEffect(() => {
    const obtenerGeneros_ = async () => {
      if (generos.length < VALOR_UNO) {
        let resComunas = await getGeneros();
        dispatch(get_generos(resComunas));
      }
    };
    obtenerGeneros_();
  }, [dispatch, generos]);

  /**************************
   *  VALIDAR DATA DELEGADO *
   **************************/
  const obtenerDataDelegado = async (dataSucursal) => {
    let delegadoSucursal;
    if (dataSucursal.idOrganizacionSucursal) {
      let respuestaDelegado = await obtenerConfiguracionDelegado(
        dataSucursal.idOrganizacionSucursal,
        PERFIL_LDAP
      );

      if (respuestaDelegado.persona) {
        delegadoSucursal = {
          idSucursal: dataSucursal.idSucursal,
          rutDelegado: dataSucursal.rutDelegado,
          nombreCompleto: `${
            respuestaDelegado.persona.nombre
              ? respuestaDelegado.persona.nombre
              : ""
          } ${
            respuestaDelegado.persona.apellidoPaterno
              ? respuestaDelegado.persona.apellidoPaterno
              : ""
          } ${
            respuestaDelegado.persona.apellidoMaterno
              ? respuestaDelegado.persona.apellidoMaterno
              : ""
          }`,
          nombre: respuestaDelegado.persona.nombre,
          apellidoPaterno: respuestaDelegado.persona.apellidoPaterno,
          apellidoMaterno: respuestaDelegado.persona.apellidoMaterno,
          genero: respuestaDelegado.persona.genero,
          fechaNacimiento: respuestaDelegado.persona.fechaNacimiento,
          telefonoFijo: null,
          telefonoMovil: respuestaDelegado.telefono,
          correoElectronico: respuestaDelegado.email,
        };
      } else {
        delegadoSucursal = delegadosSucursal.find(
          (e) => e.rutDelegado === dataSucursal.rutDelegado
        );
      }
    } else {
      delegadoSucursal = delegadosSucursal.find(
        (e) => e.rutDelegado === dataSucursal.rutDelegado
      );
    }

    setDelegadoSucursal(delegadoSucursal);
  };

  const agregarDelegadosSucursales = (sucursales, delegados) => {
    let sucursalesConDelegado = [];
    sucursales.forEach((e) => {
      delegados.forEach((e1) => {
        if (e.rutDelegado === e1.rutDelegado) {
          let sucursal = {
            ...e,
            nombreCompleto: e1.nombreCompleto,
          };
          sucursalesConDelegado.push(sucursal);
        } else if (!e.rutDelegado) {
          let sucursal = {
            ...e,
            nombreCompleto: SIN_DELEGADO_ASIGNADO,
          };
          sucursalesConDelegado.push(sucursal);
        }
      });
    });

    return sucursalesConDelegado;
  };

  const obtenerDelegadoPersona = async (rutDelegados) => {
    let delegados = [];

    for (let e of rutDelegados) {
      const persona = await obtenerPersonaPorRut(e, PERFIL_LDAP);
      if (persona) {
        let delegado = {
          rutDelegado: e,
          nombreCompleto: `${persona.nombre ? persona.nombre : ""} ${
            persona.apellidoPaterno ? persona.apellidoPaterno : ""
          } ${persona.apellidoMaterno ? persona.apellidoMaterno : ""}`,
          nombre: persona.nombre,
          apellidoPaterno: persona.apellidoPaterno,
          apellidoMaterno: persona.apellidoMaterno,
          genero: persona.genero,
          fechaNacimiento: persona.fechaNacimiento,
          telefonoFijo: persona.telefonoFijo,
          telefonoMovil: persona.telefonoMovil,
          correoElectronico: persona.correoElectronico,
        };
        delegados.push(delegado);
      }
    }
    setDelegadosSucursal(delegados);

    return delegados;
  };

  /**************************
   *  DATA DE ENVIO         *
   **************************/
  const enviarDatos = (values) => {
    if (values.sucursal.value === VALOR_NEGATIVO) {
      setErrorSucursal(true);
      return;
    }

    const sucursalConsulta = {
      ...values,
      ciudad: obtenerCiudadDeUnaComuna(values.comuna, comunas, ciudades),
      delegado: delegadoSucursal,
      nuevaSucursal,
    };

    // Guardar datos sucursal
    dispatch(guardar_sucursal_ldap_consulta(sucursalConsulta));
    guardarStorageSucursal(sucursalConsulta);

    // Guarda el estado del wizard
    guardarEstadoWizard();
  };

  const guardarStorageSucursal = (sucursal) => {
    let sucursalEcript = CryptoJS.AES.encrypt(
      JSON.stringify(sucursal),
      process.env.REACT_APP_HAS_CRYPT
    ).toString();

    sessionStorage.setItem(STORAGE_SUCURSAL, JSON.stringify(sucursalEcript));
  };

  const guardarEstadoWizard = () => {
    step.estado = DONE;
    let wizard_ = Object.assign([], wizard);
    wizard_.forEach((e) => {
      if (e.paso === step.paso) e = step;
    });
    dispatch(guardar_wizard_negocio_ldap(wizard_));
    dispatch(cambiar_wizard_actual(wizardActual + 1));

    let wizardEcript = CryptoJS.AES.encrypt(
      JSON.stringify(wizard_),
      process.env.REACT_APP_HAS_CRYPT
    ).toString();

    sessionStorage.setItem("_cnWzVC", JSON.stringify(wizardEcript));
    sessionStorage.setItem("_cnWzAcVC", wizardActual + 1);
  };

  const volver = () => {
    dispatch(cambiar_wizard_actual(wizardActual - 1));
    sessionStorage.setItem("_cnWzAcVC", wizardActual - 1);
  };

  const validacionFormulario = {
    rutEmpresa: Yup.string(),
    nombreSucursal: Yup.string().required(CAMPO_REQUERIDO_NOMBRE_SUCURSAL),
    direccion: Yup.string()
      .min(3, VALIDACION_DIRECCION)
      .required(CAMPO_REQUERIDO_DIRECCION),
    numero: Yup.string().required(CAMPO_REQUERIDO_NUMERO),
    complemento: Yup.string(),
    region: Yup.string()
      .required(VALIDACION_REGION)
      .test("select", VALIDACION_REGION, (value) => {
        return Number(value) !== 0 ? true : false;
      }),
    comuna: Yup.string().required(VALIDACION_COMUNA),
    telefono: Yup.string()
      .required(CAMPO_REQUERIDO_TELEFONO)
      .test("validaTelefono", VALIDACION_TELEFONO, (value) => {
        return formatTel(value).length === 13 || formatTel(value).length === 3
          ? true
          : false;
      }),
    email: Yup.string().email(VALIDACION_MAIL).required(CAMPO_REQUERIDO),
  };

  return (
    <React.Fragment>
      <Formik
        enableReinitialize={true}
        initialValues={sucursal}
        validationSchema={Yup.object().shape(
          sucursal.sucursal.value === 0 ? validacionFormulario : null
        )}
        onSubmit={async (values, { setSubmitting }) => {
          enviarDatos(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
        }) => {
          return (
            <Form
              className="container animated fadeInRight"
              onSubmit={handleSubmit}
              onKeyDown={(e) => {
                if ((e.charCode || e.keyCode) === 13) {
                  e.preventDefault();
                }
              }}
            >
              <div className="row">
                {/* sucursales */}
                {sucursales.length > VALOR_ZERO && (
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>Sucursales</label>
                      <MySelect
                        id="sucursal"
                        name="sucursal"
                        tipoData="sucursal"
                        value={values.sucursal}
                        onChange={(setFieldValue, obtenerDatosSucursal)}
                        onBlur={setFieldTouched}
                        error={errors.sucursal}
                        touched={touched.sucursal}
                        data={sucursales}
                        titulo={"sucursal"}
                      />

                      {errorSucursal && (
                        <small className="text-danger animated fadeIn">
                          {VALIDACION_SUCURSAL}
                        </small>
                      )}
                    </div>
                  </div>
                )}

                {/* rut empresa */}
                <div
                  className={
                    sucursales.length > VALOR_ZERO ? "col-lg-6" : "col-lg-12"
                  }
                >
                  <div className="form-group">
                    <label>RUT Empresaa</label>
                    <Field
                      disabled
                      className="form-control"
                      id="rutEmpresa"
                      name="rutEmpresa"
                      type="text"
                      placeholder="ej. 12345678-9"
                      maxLength="12"
                      minLength="11"
                      value={format(values.rutEmpresa)}
                    />
                  </div>
                </div>

                {/* nombre sucursal */}
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>
                      Nombre sucursal <span className="text-danger">*</span>
                    </label>
                    <Field
                      className="form-control"
                      id="nombreSucursal"
                      name="nombreSucursal"
                      type="text"
                      placeholder="Ingrese nombre sucursal"
                      maxLength="50"
                      minLength="1"
                      value={soloLetrasNumerosYAmpersand(values.nombreSucursal)}
                      disabled={disableForm}
                    />
                    {errors.nombreSucursal && touched.nombreSucursal && (
                      <small className="text-danger animated fadeIn">
                        {errors.nombreSucursal}
                      </small>
                    )}
                  </div>
                </div>

                {/* dirección */}
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      Dirección <span className="text-danger">*</span>
                    </label>

                    <Field
                      className="form-control"
                      id="direccion"
                      name="direccion"
                      type="text"
                      placeholder="Ingrese dirección"
                      value={validacionDireccion(values.direccion)}
                      disabled={disableForm}
                    />
                    {errors.direccion && touched.direccion && (
                      <small className="text-danger animated fadeIn">
                        {errors.direccion}
                      </small>
                    )}
                  </div>
                </div>

                {/* número */}
                <div className="col-md-3">
                  <div className="form-group">
                    <label>
                      Número <span className="text-danger">*</span>
                    </label>

                    <Field
                      className="form-control"
                      id="numero"
                      name="numero"
                      type="text"
                      placeholder="Ingrese número"
                      maxLength="25"
                      value={formatNumber(values.numero)}
                      disabled={disableForm}
                    />
                    {errors.numero && touched.numero && (
                      <small className="text-danger">{errors.numero}</small>
                    )}
                  </div>
                </div>

                {/* complemento */}
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Depto/Casa/Oficina </label>

                    <Field
                      className="form-control"
                      id="complemento"
                      name="complemento"
                      type="text"
                      placeholder="Ingrese Depto/Casa/Oficina..."
                      maxLength="50"
                      value={values.complemento}
                      disabled={disableForm}
                    />
                    {errors.complemento && touched.complemento && (
                      <small className="text-danger">
                        {errors.complemento}
                      </small>
                    )}
                  </div>
                </div>

                {/* región */}
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      Región <span className="text-danger">*</span>
                    </label>
                    <select
                      id="region"
                      name="region"
                      className="form-control"
                      style={{ display: "block" }}
                      value={values.region ? values.region : 0}
                      disabled={disableForm}
                      onChange={(e) => {
                        handleChange(e);
                        const comunasRegion = obtenerComunasDeUnaRegion(
                          e.target.value,
                          ciudades,
                          comunas
                        );
                        setComunasRegion(comunasRegion);
                      }}
                    >
                      <option value="0"> Seleccione región</option>
                      {regiones.length > 0 &&
                        regiones.map((item, i) => (
                          <option key={i} value={item.idRegion}>
                            {item.glosaRegion}
                          </option>
                        ))}
                    </select>

                    {errors.region && touched.region && (
                      <small className="text-danger">{errors.region}</small>
                    )}
                  </div>
                </div>

                {/* comuna */}
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      Comuna <span className="text-danger">*</span>
                    </label>
                    <select
                      id="comuna"
                      name="comuna"
                      className="form-control"
                      style={{ display: "block" }}
                      value={values.comuna ? values.comuna : 0}
                      onChange={handleChange}
                      disabled={
                        values.region === 0 ||
                        values.region === "0" ||
                        disableForm
                      }
                    >
                      <option value="0"> Seleccione comuna</option>
                      {comunasRegion.length > 0 &&
                        comunasRegion.map((item, i) => (
                          <option key={i} value={item.idComuna}>
                            {item.glosaComuna}
                          </option>
                        ))}
                    </select>
                    {errors.comuna &&
                      touched.comuna &&
                      (values.region !== 0 || values.region !== "0") && (
                        <small className="text-danger">{errors.comuna}</small>
                      )}
                  </div>
                </div>

                {/* teléfono */}
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      Teléfono <span className="text-danger">*</span>
                    </label>
                    <Field
                      id="telefono"
                      name="telefono"
                      className="form-control"
                      maxLength="13"
                      placeholder="Ingrese teléfono"
                      value={formatTel(values.telefono)}
                      disabled={disableForm}
                    />
                    {errors.telefono && touched.telefono && (
                      <small className="text-danger animated fadeIn">
                        {errors.telefono}
                      </small>
                    )}
                  </div>
                </div>

                {/* email */}
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      Email <span className="text-danger">*</span>
                    </label>
                    <Field
                      className="form-control"
                      id="email"
                      name="email"
                      type="text"
                      placeholder="test@gmail.com"
                      maxLength="50"
                      value={values.email}
                      disabled={disableForm}
                    />
                    {errors.email && touched.email && (
                      <small className="text-danger animated fadeIn">
                        {errors.email}
                      </small>
                    )}
                  </div>
                </div>
              </div>
              <div className="button-zone text-right pt-3 pb-3">
                <button
                  className="btn btn-principal color-secundario"
                  type="button"
                  onClick={() => volver()}
                >
                  Atrás
                </button>

                <button
                  id="botonSiguiente"
                  className="btn btn-principal"
                  type="submit"
                >
                  Siguiente
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </React.Fragment>
  );
};

export default CDatosSucursal;
