import React, { Component, Fragment } from "react";
import {
  guardar_data_wizard_asegurado,
  wizard,
} from "../../../../../../actions/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { notificacion } from "../../../../transversales/Notificaciones";
import "moment/locale/es";
import moment from "moment";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  eliminarDataWizardMongo,
  finalizarSolicitud,
  guardarDataWizard,
  validarRequisitosAsegurabilidad,
} from "../../../../../../services/asegurable.services";
import {
  EXITO_MIS_DATOS,
  ERROR_MIS_DATOS,
  CAMPO_REQUERIDO_NOMBRE,
  CAMPO_REQUERIDO_APELLIDO_PATERNO,
  CAMPO_REQUERIDO_GENERO,
  CAMPO_REQUERIDO_ESTADO_CIVIL,
  CAMPO_REQUERIDO_FECHA,
  CAMPO_REQUERIDO_SISTEMA_PREVISIONAL,
  CAMPO_REQUERIDO_PESO,
  CAMPO_REQUERIDO_ESTATURA,
  CAMPO_REQUERIDO_ACTIVIDAD,
  CAMPO_REQUERIDO_RENTA,
  MASK_DATE,
  TYPEOF_NUMBER,
  INVALID_DATE,
  VALOR_UNO,
  VALOR_ZERO,
  MAYORIA_EDAD,
  MINIMA_EDAD,
  CAMPO_REQUERIDO_APELLIDO_MATERNO,
  NOTIFICACION_SIN_OPCIONES,
  ID_SOLICITUD_FINALIZADA_POR_ASEGURABLE,
  FLUJO_VENTA_DIGITAL,
  FINALIZAR_PROCESO,
  SOLICITAR_REEVALUACION,
  SECCION_DATOS_PERSONALES,
  INFORMATIVO_FECHA_INGRESO_EMPRESA,
  GA_CATEGORIA_FORMULARIO_ENROLAMIENTO,
  GA_LABEL_DATOS_PERSONALES,
  VALIDACION_APELLIDO_M,
  VALIDACION_APELLIDO_M_MAXIMO,
  VALIDACION_APELLIDO_P_MAXIMO,
  VALIDACION_APELLIDO_P,
  VALIDACION_NOMBRE,
  VALIDACION_NOMBRE_MAXIMO,
  FLUJO_VENTA_DIRECTA,
} from "../../../../../../utils/Constantes";
import {
  formatNumber,
  formatMilesInput,
} from "../../../../../../utils/Functions";

import es from "date-fns/locale/es";
import DatePicker, { registerLocale } from "react-datepicker";
import { soloLetras } from "../../../../../../utils/Functions";

import MaskedTextInput from "react-text-mask";
import "react-datepicker/dist/react-datepicker.css";
import ModalRequisitosAsegurabilidad from "./ModalRequisitosAsegurabilidad";
import { crearEventoTraza } from "../../../../../../utils/analitica";
registerLocale("es", es);

const { format, clean } = require("rut.js");

class MisDatos extends Component {
  constructor(props) {
    super(props);
    const misDatos = this.props.store.reducer.dataWizardAsegurado;
    let fechaNacimiento = "";
    let fechaNacimientoDate = "";
    let fechaIngresoEmpresa = "";
    let fechaIngresoEmpresaDate = "";

    if (misDatos && misDatos.fechaNacimiento) {
      fechaNacimientoDate = moment(misDatos.fechaNacimiento).toDate();
      fechaNacimientoDate.setHours(23, 59, 59);
      fechaNacimiento = moment(fechaNacimientoDate).format("YYYY-MM-DD");
    }

    if (misDatos && misDatos.fechaIngresoEmpresa) {
      fechaIngresoEmpresaDate = moment(misDatos.fechaIngresoEmpresa).toDate();
      fechaIngresoEmpresaDate.setHours(23, 59, 59);
      fechaIngresoEmpresa = moment(fechaIngresoEmpresaDate).format(
        "YYYY-MM-DD"
      );
    }

    this.state = {
      misDatos: misDatos,
      nombreCompleto: "",
      solicitud: this.props.store.reducer.data_asegurable.idSolicitud,
      formulario: {
        fechaNacimiento: {
          fechaSelected: fechaNacimientoDate,
          fecha: fechaNacimiento,
          valida: true,
        },
        fechaIngresoEmpresa: {
          fechaSelected: fechaIngresoEmpresaDate,
          fecha: fechaIngresoEmpresa,
          valida: true,
        },
      },
      dataFormulario: {
        rut: this.props.store.auth.usuarioSesion.rut,
        nombre:
          misDatos && misDatos.nombre
            ? misDatos.nombre
            : this.props.store.reducer.data_asegurable.nombre,
        apellidoMaterno:
          misDatos && misDatos.apellidoMaterno
            ? misDatos.apellidoMaterno
            : this.props.store.reducer.data_asegurable.apellidoMaterno,
        apellidoPaterno:
          misDatos && misDatos.apellidoPaterno
            ? misDatos.apellidoPaterno
            : this.props.store.reducer.data_asegurable.apellidoPaterno,
        genero:
          misDatos && misDatos.genero ? misDatos.genero.idGenero : VALOR_ZERO,
        estadoCivil:
          misDatos && misDatos.estadoCivil
            ? misDatos.estadoCivil.idEstadoCivil
            : VALOR_ZERO,
        fechaNacimiento: "",
        prevision:
          misDatos && misDatos.sistemaPrevisionSalud
            ? misDatos.sistemaPrevisionSalud.idSistemaPrevisionSalud
            : "",
        fechaIngresoEmpresa: "",
        peso: misDatos && misDatos.peso ? misDatos.peso : VALOR_ZERO,
        estatura:
          misDatos && misDatos.estatura ? misDatos.estatura : VALOR_ZERO,
        actividad:
          misDatos && misDatos.actividadLaboral
            ? misDatos.actividadLaboral
            : "",
        renta: misDatos && misDatos.renta ? misDatos.renta : "",
      },
      catalogo: {
        generos: this.props.store.reducer.generos,
        estadoCivil: this.props.store.reducer.estadoCivil,
        previsiones: this.props.store.reducer.previsionSalud,
      },
      modalRequisitosAsegurabilidad: false,
      datosActualesFormulario: undefined,
      tipoPolizas: undefined,
      loadingData: false,
      loadingDataRequisitos: false,
    };
  }

  componentDidMount() {
    crearEventoTraza(
      GA_CATEGORIA_FORMULARIO_ENROLAMIENTO,
      GA_LABEL_DATOS_PERSONALES
    );
  }

  /*************************
   *  VALIDACIONES         *
   *************************/
  notificarSinOpciones = (catalogo) => {
    if (!catalogo.length) {
      notificacion("info", NOTIFICACION_SIN_OPCIONES);
    }
  };

  validacionFechaIngresoEmpresa(fechaIngresoEmpresa, fechaNacimiento) {
    let formulario = this.state.formulario;
    if (fechaNacimiento === "") {
      formulario.fechaNacimiento.valida = false;
      this.setState({ formulario });
      return true;
    } else {
      let fecNac = this.fechaNacimientoEmpresa(fechaNacimiento);
      fecNac = moment(fecNac).format("DD/MM/YYYY");

      if (
        new Date(fecNac).getTime() > new Date(fechaIngresoEmpresa).getTime()
      ) {
        formulario.fechaIngresoEmpresa.valida = false;
        this.setState({ formulario });
        return true;
      }
    }
    formulario.fechaIngresoEmpresa.valida = true;
    formulario.fechaNacimiento.valida = true;
    this.setState({ formulario });

    return false;
  }

  fechaNacimientoEmpresa(fechaNac) {
    let fecha = new Date(fechaNac);
    return new Date(
      fecha.getFullYear() +
        MAYORIA_EDAD +
        "/" +
        (fecha.getMonth() + VALOR_UNO) +
        "/" +
        fecha.getDate()
    );
  }

  fechaMayoriaEdad() {
    let fecha = new Date();
    return new Date(
      fecha.getFullYear() -
        MAYORIA_EDAD +
        "/" +
        (fecha.getMonth() + VALOR_UNO) +
        "/" +
        fecha.getDate()
    );
  }

  fechaMinima() {
    let fecha = new Date();
    return new Date(
      fecha.getFullYear() -
        MINIMA_EDAD +
        "/" +
        (fecha.getMonth() + VALOR_UNO) +
        "/" +
        fecha.getDate()
    );
  }

  fechaMinIngresoEmpresa(fechaNacimiento) {
    let fecha = fechaNacimiento.split("/");
    let fechaNac = new Date(`${fecha[2]}/${fecha[1]}/${fecha[0]}`);

    return new Date(
      fechaNac.getFullYear() +
        MAYORIA_EDAD +
        "/" +
        (fechaNac.getMonth() + VALOR_UNO) +
        "/" +
        fechaNac.getDate()
    );
  }

  fechaMaxIngresoEmpresa() {
    let fecha = new Date();
    return new Date(
      fecha.getFullYear() +
        "/" +
        (fecha.getMonth() + VALOR_UNO) +
        "/" +
        fecha.getDate()
    );
  }

  separarApellidos(apellidos) {
    apellidos = apellidos.split(" ");

    let materno = "";
    for (let i = 1; i < apellidos.length; i++) {
      materno += ` ${apellidos[i]}`;
    }
    return materno;
  }

  /*************************
   *  PETICIONES SERVIDOR  *
   *************************/

  async validarRequsitosAsegurabilidad(objTitular) {
    
    this.setState({ loadingData: true });

    const { tipoFlujo } = this.props.store.reducer.data_asegurable;

    if (tipoFlujo === FLUJO_VENTA_DIRECTA){
      
      await this.enviarMisDatos(objTitular);
    }
    else{
      const { dataWizardAsegurado, informacionPoliza } = this.props.store.reducer;
      let lstCoberturas    = tipoFlujo === FLUJO_VENTA_DIGITAL ? dataWizardAsegurado.coberturas : informacionPoliza.coberturas;
      let objReqValidacion = await validarRequisitosAsegurabilidad(lstCoberturas,objTitular);

      if (!objReqValidacion.mostrarError) {
        if (objReqValidacion.modalRequisitosAsegurabilidad) {
          this.setState({...objReqValidacion});
        }
        else{
          await this.enviarMisDatos(objTitular);
        }
      }
    }
    this.setState({ loadingData: false });
  }

  cerrarModalRequisitosAsegurabilidad = () => {
    this.setState({ modalRequisitosAsegurabilidad: false });
  };

  obtenerSeleccionRequisitosAsegurabilidad = async (opcion) => {
    this.setState({ loadingDataRequisitos: true });

    const idSolicitud = this.props.store.reducer.data_asegurable.idSolicitud;

    if (opcion === FINALIZAR_PROCESO) {
      const data = {
        idSolicitud: idSolicitud,
        rutTitular: this.props.store.auth.usuarioSesion.rut,
        solicitudEstado: {
          idSolicitudEstado: ID_SOLICITUD_FINALIZADA_POR_ASEGURABLE,
        },
      };

      const respuesta = await finalizarSolicitud(data);
      if (respuesta != null) {
        await eliminarDataWizardMongo(idSolicitud);
        setTimeout(() => {
          return window.location.replace("");
        }, 100);
      }
    } else if (opcion === SOLICITAR_REEVALUACION) {
      await this.enviarMisDatos(this.state.datosActualesFormulario);
    }
  };

  async enviarMisDatos(values) {
    const dataEnvio = this.state.misDatos;
    const { reducer, auth } = this.props.store;

    let fNac = values.fechaNacimiento.split("/");
    let fEmp = values.fechaIngresoEmpresa.split("/");

    let fechaNacimiento = `${fNac[2]}-${fNac[1]}-${fNac[0]}`;
    let fechaIngresoEmpresa = `${fEmp[2]}-${fEmp[1]}-${fEmp[0]}`;

    if (typeof values.renta !== TYPEOF_NUMBER)
      values.renta = Number(values.renta.replace(/[^0-9]/g, ""));

    if (reducer.dataWizardAsegurado.coberturasCargas) {
      dataEnvio.coberturasCargas = [...reducer.dataWizardAsegurado.coberturasCargas];
    }

    // Datos del usuario
    dataEnvio.rut = auth.usuarioSesion.rut.split("-")[0];
    dataEnvio.digitoVerificador = auth.usuarioSesion.rut.split("-")[1];
    dataEnvio.nombre = soloLetras(values.nombre);
    dataEnvio.apellidoMaterno = soloLetras(values.apellidoMaterno);
    dataEnvio.apellidoPaterno = soloLetras(values.apellidoPaterno);

    // Datos de glosa de catalogos (importante si es que servicios de catálogo fallan)
    const glosaEstadoCivil =
      reducer.estadoCivil && reducer.estadoCivil.length
        ? reducer.estadoCivil.find(
            ({ idEstadoCivil }) => idEstadoCivil === Number(values.estadoCivil)
          ).glosa
        : reducer.dataWizardAsegurado.estadoCivil &&
          reducer.dataWizardAsegurado.estadoCivil.glosa;

    const glosaGenero =
      reducer.generos && reducer.generos.length
        ? reducer.generos.find(
            ({ idGenero }) => idGenero === Number(values.genero)
          ).descripcion
        : reducer.dataWizardAsegurado.genero &&
          reducer.dataWizardAsegurado.genero.descripcion;

    const glosaPrevision =
      reducer.previsionSalud && reducer.previsionSalud.length
        ? reducer.previsionSalud.find(
            ({ idIsapre }) => idIsapre === Number(values.prevision)
          ).glosa
        : reducer.dataWizardAsegurado.sistemaPrevisionSalud &&
          reducer.dataWizardAsegurado.sistemaPrevisionSalud.glosa;

    // Formulario
    dataEnvio.actividadLaboral = values.actividad;
    dataEnvio.estadoCivil = {
      idEstadoCivil: Number(values.estadoCivil),
      glosa: glosaEstadoCivil,
    };
    dataEnvio.estatura = values.estatura;
    dataEnvio.fechaIngresoEmpresa = fechaIngresoEmpresa;
    dataEnvio.fechaNacimiento = fechaNacimiento;
    dataEnvio.genero = {
      idGenero: Number(values.genero),
      descripcion: glosaGenero,
    };
    dataEnvio.peso = values.peso;
    dataEnvio.renta = values.renta;
    dataEnvio.sistemaPrevisionSalud = {
      idSistemaPrevisionSalud: Number(values.prevision),
      glosa: glosaPrevision,
    };
    dataEnvio.solicitud.idSolicitud = this.props.store.reducer.data_asegurable.idSolicitud;
    dataEnvio.solicitud.numeroRenta = values.renta;

    await this.props.guardar_data_wizard_asegurado(dataEnvio);

    const respuesta = await guardarDataWizard(dataEnvio);
    if (respuesta) {
      notificacion("success", EXITO_MIS_DATOS);
      this.props.wizard({ tab: 2, type: "tab" });
    } else {
      notificacion("warning", ERROR_MIS_DATOS);
    }
  }

  render() {
    return (
      <React.Fragment>
        <Formik
          enableReinitialize
          initialValues={{
            rut: this.state.dataFormulario.rut,
            nombre: this.state.dataFormulario.nombre,
            apellidoMaterno: this.state.dataFormulario.apellidoMaterno,
            apellidoPaterno: this.state.dataFormulario.apellidoPaterno,
            genero: this.state.dataFormulario.genero,
            estadoCivil: this.state.dataFormulario.estadoCivil,
            fechaNacimiento: moment(
              this.state.formulario.fechaNacimiento.fecha
            ).format("DD/MM/YYYY"),
            prevision: this.state.dataFormulario.prevision,
            fechaIngresoEmpresa: moment(
              this.state.formulario.fechaIngresoEmpresa.fecha
            ).format("DD/MM/YYYY"),
            peso: this.state.dataFormulario.peso,
            estatura: this.state.dataFormulario.estatura,
            actividad: this.state.dataFormulario.actividad,
            renta: this.state.dataFormulario.renta,
          }}
          validationSchema={Yup.object().shape({
            nombre: Yup.string()
              .required(CAMPO_REQUERIDO_NOMBRE)
              .test("validarVacios", CAMPO_REQUERIDO_NOMBRE, (value) =>
                value ? value.trim() !== "" : false
              )
              .min(1, VALIDACION_NOMBRE)
              .max(25, VALIDACION_NOMBRE_MAXIMO),

            apellidoMaterno: Yup.string()
              .required(CAMPO_REQUERIDO_APELLIDO_MATERNO)
              .test(
                "validarVacios",
                CAMPO_REQUERIDO_APELLIDO_MATERNO,
                (value) => (value ? value.trim() !== "" : false)
              )
              .min(1, VALIDACION_APELLIDO_M)
              .max(25, VALIDACION_APELLIDO_M_MAXIMO),

            apellidoPaterno: Yup.string()
              .required(CAMPO_REQUERIDO_APELLIDO_PATERNO)
              .test(
                "validarVacios",
                CAMPO_REQUERIDO_APELLIDO_PATERNO,
                (value) => (value ? value.trim() !== "" : false)
              )
              .min(1, VALIDACION_APELLIDO_P)
              .max(25, VALIDACION_APELLIDO_P_MAXIMO),

            genero: Yup.string()
              .required(CAMPO_REQUERIDO_GENERO)
              .test("validarSelect", CAMPO_REQUERIDO_GENERO, (value) => {
                return Number(value) !== 0 ? true : false;
              }),
            estadoCivil: Yup.string()
              .required(CAMPO_REQUERIDO_ESTADO_CIVIL)
              .test("validarSelect", CAMPO_REQUERIDO_ESTADO_CIVIL, (value) => {
                return Number(value) !== 0 ? true : false;
              }),
            fechaNacimiento: Yup.string()
              .required(CAMPO_REQUERIDO_FECHA)
              .test("fecha", "validacion fecha", (value) => {
                return value !== INVALID_DATE ? true : false;
              }),
            prevision: Yup.string()
              .required(CAMPO_REQUERIDO_SISTEMA_PREVISIONAL)
              .test(
                "validarSelect",
                CAMPO_REQUERIDO_SISTEMA_PREVISIONAL,
                (value) => {
                  return Number(value) !== 0 ? true : false;
                }
              ),
            fechaIngresoEmpresa: Yup.string().test(
              "validacion existencia fecha nacimiento",
              CAMPO_REQUERIDO_FECHA,
              (value) => {
                return value !== INVALID_DATE && value !== undefined
                  ? true
                  : false;
              }
            ),
            peso: Yup.string()
              .required(CAMPO_REQUERIDO_PESO)
              .test("validaPeso", CAMPO_REQUERIDO_PESO, (value) => {
                return Number(value) && Number(value) !== 0;
              }),
            estatura: Yup.string()
              .required(CAMPO_REQUERIDO_ESTATURA)
              .test("validaAltura", CAMPO_REQUERIDO_ESTATURA, (value) => {
                return Number(value) && Number(value) !== 0;
              }),
            actividad: Yup.string()
              .required(CAMPO_REQUERIDO_ACTIVIDAD)
              .test("validarVacios", CAMPO_REQUERIDO_ACTIVIDAD, (value) =>
                value ? value.trim() !== "" : false
              ),
            renta: Yup.string()
              .required(CAMPO_REQUERIDO_RENTA)
              .test("rentaObligatoria", CAMPO_REQUERIDO_RENTA, (value) => {
                return Number(clean(value)) >= 1 ? true : false;
              }),
          })}
          onSubmit={async (values, { setSubmitting }) => {
            this.validarRequsitosAsegurabilidad(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
            setFieldTouched,
          }) => {
            return (
              <React.Fragment>
                <fieldset
                  disabled={
                    !this.props.store.reducer.informacionPoliza
                      .solicitudIncorporacion &&
                    !this.props.store.reducer.informacionPoliza.asegurable_5_50
                  }
                >
                  <Form
                    id="formularioMisDatos"
                    name="mis-datos-form"
                    onKeyDown={(e) => {
                      if ((e.charCode || e.keyCode) === 13) {
                        e.preventDefault();
                      }
                    }}
                  >
                    <div
                      id="misDatos"
                      className="cuerpo-wizard pt-3 pb-5 o-white container"
                    >
                      <div className="row mx-5 animated fadeInRight">
                        <div className="col-12">
                          <h5 className="font-weight-bold text-primary">
                            Datos personales
                          </h5>
                          <hr className="mt-1" />
                        </div>

                        {/* rut */}
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>RUT</label>
                            <Field
                              className="form-control form-control-sm"
                              disabled
                              id="rut"
                              name="rut"
                              type="text"
                              value={format(values.rut)}
                            />
                          </div>
                        </div>

                        {/* nombre */}
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>
                              Nombre <span className="text-danger">*</span>
                            </label>
                            <Field
                              className="form-control form-control-sm"
                              id="nombre"
                              name="nombre"
                              type="text"
                              maxLength="50"
                              value={soloLetras(values.nombre)}
                            />
                            {errors.nombre && touched.nombre && (
                              <small className="text-danger animated fadeIn animated fadeIn">
                                {errors.nombre}
                              </small>
                            )}
                          </div>
                        </div>

                        {/* apellidoPaterno */}
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              Apellido paterno{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <Field
                              className="form-control form-control-sm"
                              id="apellidoPaterno"
                              name="apellidoPaterno"
                              type="text"
                              maxLength="50"
                              value={soloLetras(values.apellidoPaterno)}
                            />
                            {errors.apellidoPaterno &&
                              touched.apellidoPaterno && (
                                <small className="text-danger animated fadeIn animated fadeIn">
                                  {errors.apellidoPaterno}
                                </small>
                              )}
                          </div>
                        </div>

                        {/* apellidoMaterno */}
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              Apellido materno{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <Field
                              className="form-control form-control-sm"
                              id="apellidoMaterno"
                              name="apellidoMaterno"
                              type="text"
                              maxLength="50"
                              value={soloLetras(values.apellidoMaterno)}
                            />
                            {errors.apellidoMaterno &&
                              touched.apellidoMaterno && (
                                <small className="text-danger animated fadeIn animated fadeIn">
                                  {errors.apellidoMaterno}
                                </small>
                              )}
                          </div>
                        </div>

                        {/* genero */}
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              Genero <span className="text-danger">*</span>
                            </label>
                            <select
                              id="genero"
                              name="genero"
                              className="form-control form-control-sm"
                              style={{ display: "block" }}
                              value={values.genero}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              onClick={() =>
                                this.notificarSinOpciones(
                                  this.state.catalogo.generos
                                )
                              }
                            >
                              {this.state.catalogo.generos &&
                              this.state.catalogo.generos.length ? (
                                <Fragment>
                                  <option value="0"> Seleccione género</option>
                                  {this.state.catalogo.generos.map(
                                    (item, i) => (
                                      <option
                                        key={`opcion_parentesco_${i}`}
                                        value={item.idGenero}
                                      >
                                        {item.descripcion}
                                      </option>
                                    )
                                  )}
                                </Fragment>
                              ) : this.state.misDatos &&
                                this.state.misDatos.genero &&
                                this.state.misDatos.genero.idGenero ? (
                                <option
                                  value={this.state.misDatos.genero.idGenero}
                                >
                                  {this.state.misDatos.genero.descripcion}
                                </option>
                              ) : (
                                <option value="0"> Seleccione género</option>
                              )}
                            </select>
                            {errors.genero && touched.genero && (
                              <small className="text-danger animated fadeIn animated fadeIn">
                                {errors.genero}
                              </small>
                            )}
                          </div>
                        </div>

                        {/* estadoCivil */}
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              Estado civil{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <select
                              id="estadoCivil"
                              name="estadoCivil"
                              className="form-control form-control-sm"
                              style={{ display: "block" }}
                              value={values.estadoCivil}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              onClick={() =>
                                this.notificarSinOpciones(
                                  this.state.catalogo.estadoCivil
                                )
                              }
                            >
                              {this.state.catalogo.estadoCivil &&
                              this.state.catalogo.estadoCivil.length ? (
                                <Fragment>
                                  <option value="0">
                                    {" "}
                                    Seleccione estado civil
                                  </option>
                                  {this.state.catalogo.estadoCivil.map(
                                    (item, i) => (
                                      <option
                                        key={`opcion_parentesco_${i}`}
                                        value={item.idEstadoCivil}
                                      >
                                        {item.glosa}
                                      </option>
                                    )
                                  )}
                                </Fragment>
                              ) : this.state.misDatos &&
                                this.state.misDatos.estadoCivil &&
                                this.state.misDatos.estadoCivil
                                  .idEstadoCivil ? (
                                <option
                                  value={
                                    this.state.misDatos.estadoCivil
                                      .idEstadoCivil
                                  }
                                >
                                  {this.state.misDatos.estadoCivil.glosa}
                                </option>
                              ) : (
                                <option value="0">
                                  {" "}
                                  Seleccione estado civil
                                </option>
                              )}
                            </select>
                            {errors.estadoCivil && touched.estadoCivil && (
                              <small className="text-danger animated fadeIn animated fadeIn">
                                {errors.estadoCivil}
                              </small>
                            )}
                          </div>
                        </div>

                        {/* fechaNacimiento */}
                        <div className="col-md-6">
                          <div className="form-group up-calendar">
                            <label>
                              Fecha de nacimiento
                              <span className="text-danger">*</span>
                            </label>
                            <DatePicker
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              autoComplete="off"
                              dropdownMode="select"
                              id="fechaNacimiento"
                              name="fechaNacimiento"
                              value={values.fechaNacimiento}
                              selected={
                                values.fechaNacimiento.includes(INVALID_DATE)
                                  ? ""
                                  : moment(
                                      values.fechaNacimiento,
                                      "DD/MM/YYYY"
                                    ).toDate()
                              }
                              dateFormat="dd/MM/yyyy"
                              placeholderText="DD/MM/YYYY"
                              locale="es"
                              onChange={(value) => {
                                if (value) value.setHours(23, 59, 59);
                                value = moment(value).format("DD/MM/YYYY");
                                setFieldTouched("fechaNacimiento", true);
                                setFieldValue("fechaNacimiento", value);
                              }}
                              minDate={this.fechaMinima()}
                              maxDate={this.fechaMayoriaEdad()}
                              customInput={
                                <MaskedTextInput
                                  className="form-control form-control-sm"
                                  placeholder="DD/MM/YYYY"
                                  mask={MASK_DATE}
                                />
                              }
                            />

                            {errors.fechaNacimiento &&
                              touched.fechaNacimiento && (
                                <small className="text-danger animated fadeIn">
                                  {errors.fechaNacimiento}
                                </small>
                              )}
                          </div>
                        </div>

                        {/* previsionSalud */}
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              Prevision de Salud{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <select
                              id="prevision"
                              name="prevision"
                              className="form-control form-control-sm"
                              style={{ display: "block" }}
                              value={values.prevision}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              onClick={() =>
                                this.notificarSinOpciones(
                                  this.state.catalogo.previsiones
                                )
                              }
                            >
                              {this.state.catalogo.previsiones &&
                              this.state.catalogo.previsiones.length ? (
                                <Fragment>
                                  <option value="0">
                                    {" "}
                                    Seleccione prevision
                                  </option>
                                  {this.state.catalogo.previsiones.map(
                                    (item, i) => (
                                      <option
                                        key={`opcion_parentesco_${i}`}
                                        value={item.idIsapre}
                                      >
                                        {item.glosa}
                                      </option>
                                    )
                                  )}
                                </Fragment>
                              ) : this.state.misDatos &&
                                this.state.misDatos.sistemaPrevisionSalud &&
                                this.state.misDatos.sistemaPrevisionSalud
                                  .idSistemaPrevisionSalud ? (
                                <option
                                  value={
                                    this.state.misDatos.sistemaPrevisionSalud
                                      .idSistemaPrevisionSalud
                                  }
                                >
                                  {
                                    this.state.misDatos.sistemaPrevisionSalud
                                      .glosa
                                  }
                                </option>
                              ) : (
                                <option value="0"> Seleccione prevision</option>
                              )}
                            </select>
                            {errors.prevision && touched.prevision && (
                              <small className="text-danger animated fadeIn animated fadeIn">
                                {errors.prevision}
                              </small>
                            )}
                          </div>
                        </div>

                        {/* fechaIngresoEmpresa */}
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              Fecha ingreso empresa{" "}
                              <span className="text-danger">*</span>
                            </label>

                            <DatePicker
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              autoComplete="off"
                              id="fechaIngresoEmpresa"
                              name="fechaIngresoEmpresa"
                              value={values.fechaIngresoEmpresa}
                              dateFormat="dd/MM/yyyy"
                              selected={
                                values.fechaIngresoEmpresa.includes(
                                  INVALID_DATE
                                )
                                  ? ""
                                  : moment(
                                      values.fechaIngresoEmpresa,
                                      "DD/MM/YYYY"
                                    ).toDate()
                              }
                              placeholderText="DD/MM/YYYY"
                              locale="es"
                              onChange={(value) => {
                                if (
                                  values.fechaNacimiento === "" ||
                                  values.fechaNacimiento === INVALID_DATE
                                ) {
                                  value = "";
                                  setFieldTouched("fechaIngresoEmpresa", true);
                                  setFieldValue("fechaIngresoEmpresa", value);
                                  return;
                                }
                                value.setHours(23, 59, 59);
                                value = moment(value).format("DD/MM/YYYY");
                                setFieldTouched("fechaIngresoEmpresa", true);
                                setFieldValue("fechaIngresoEmpresa", value);
                              }}
                              maxDate={this.fechaMaxIngresoEmpresa()}
                              minDate={this.fechaMinIngresoEmpresa(
                                values.fechaNacimiento
                              )}
                              disabled={values.fechaNacimiento === INVALID_DATE}
                              customInput={
                                <MaskedTextInput
                                  id="maskedDate"
                                  className="form-control form-control-sm"
                                  placeholder="DD/MM/YYYY"
                                  mask={MASK_DATE}
                                />
                              }
                            />

                            {errors.fechaIngresoEmpresa &&
                              touched.fechaIngresoEmpresa && (
                                <small className="text-danger animated fadeIn">
                                  {errors.fechaIngresoEmpresa}
                                </small>
                              )}
                          </div>
                        </div>

                        {/* actividad */}
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              Actividad <span className="text-danger">*</span>
                            </label>
                            <Field
                              className="form-control form-control-sm"
                              id="actividad"
                              name="actividad"
                              type="text"
                              maxLength="150"
                              placeholder="Ingrese actividad"
                              value={soloLetras(values.actividad)}
                            />
                            {errors.actividad && touched.actividad && (
                              <small className="text-danger animated fadeIn">
                                {errors.actividad}
                              </small>
                            )}
                          </div>
                        </div>

                        {/* peso */}
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              Peso (kg) <span className="text-danger">*</span>
                            </label>
                            <div className="d-flex">
                              <button
                                id="menosPeso"
                                type="button"
                                className="btn-plus btn-sm btn-arrow text-center"
                                onClickCapture={() => {
                                  values.peso <= 1
                                    ? (values.peso = 1)
                                    : --values.peso;

                                  this.setState({
                                    dataFormulario: {
                                      ...this.state.dataFormulario,
                                      values,
                                    },
                                  });
                                }}
                              >
                                <i
                                  className="fa fa-chevron-left"
                                  aria-hidden="true"
                                ></i>
                              </button>
                              <Field
                                className="form-control form-control-sm mx-1 text-center"
                                id="peso"
                                name="peso"
                                type="text"
                                maxLength="3"
                                placeholder="Ingrese peso"
                                value={formatNumber(values.peso)}
                              />
                              <button
                                id="masPeso"
                                type="button"
                                onClick={() => {
                                  values.peso >= 999
                                    ? (values.peso = 999)
                                    : ++values.peso;

                                  this.setState({
                                    dataFormulario: {
                                      ...this.state.dataFormulario,
                                      values,
                                    },
                                  });
                                }}
                                className="btn-plus btn-sm btn-arrow text-center"
                              >
                                <i
                                  className="fa fa-chevron-right"
                                  aria-hidden="true"
                                ></i>
                              </button>
                            </div>

                            {errors.peso && touched.peso && (
                              <small className="text-danger animated fadeIn">
                                {errors.peso}
                              </small>
                            )}
                          </div>
                        </div>

                        {/* estatura */}
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              Estatura (cm){" "}
                              <span className="text-danger">*</span>
                            </label>
                            <div className="d-flex">
                              <button
                                id="menosEstatura"
                                type="button"
                                className="btn-plus btn-sm btn-arrow text-center"
                                onClickCapture={() => {
                                  values.estatura <= 1
                                    ? (values.estatura = 1)
                                    : --values.estatura;

                                  this.setState({
                                    dataFormulario: {
                                      ...this.state.dataFormulario,
                                      values,
                                    },
                                  });
                                }}
                              >
                                <i
                                  className="fa fa-chevron-left"
                                  aria-hidden="true"
                                ></i>
                              </button>
                              <Field
                                className="form-control form-control-sm mx-1 text-center"
                                id="estatura"
                                name="estatura"
                                type="text"
                                maxLength="3"
                                placeholder="Ingrese estatura"
                                value={formatNumber(values.estatura)}
                              />
                              <button
                                id="masEstatura"
                                type="button"
                                className="btn-plus btn-sm btn-arrow text-center"
                                onClickCapture={() => {
                                  values.estatura >= 999
                                    ? (values.estatura = 999)
                                    : ++values.estatura;

                                  this.setState({
                                    dataFormulario: {
                                      ...this.state.dataFormulario,
                                      values,
                                    },
                                  });
                                }}
                              >
                                <i
                                  className="fa fa-chevron-right"
                                  aria-hidden="true"
                                ></i>
                              </button>
                            </div>

                            {errors.estatura && touched.estatura && (
                              <small className="text-danger animated fadeIn">
                                {errors.estatura}
                              </small>
                            )}
                          </div>
                        </div>

                        {/* renta */}
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>
                              Renta <span className="text-danger">*</span>
                            </label>
                            <Field
                              className="form-control form-control-sm"
                              id="renta"
                              name="renta"
                              type="text"
                              maxLength="13"
                              placeholder="Ingrese renta"
                              value={formatMilesInput(values.renta)}
                            />
                            {errors.renta && touched.renta && (
                              <small className="text-danger animated fadeIn">
                                {errors.renta}
                              </small>
                            )}
                          </div>
                        </div>

                        {/* Informacion Fecha Ingreso Empresa */}
                        <div className="col-md-12">
                          <div className="p-3 bg-primary text-white text-justify rounded animated fadeIn">
                            {INFORMATIVO_FECHA_INGRESO_EMPRESA}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Botonera */}
                    <div className="button-zone text-right pt-3 pb-3 ml-auto">
                      <button
                        id="botonSiguiente"
                        className="btn btn-principal btn-lg"
                        type="submit"
                        disabled={this.state.loadingData}
                      >
                        {this.state.loadingData ? (
                          <Fragment>
                            <i className="fa fa-spinner fa-pulse fa-fw"></i>
                            <span className="sr-only">Loading...</span>
                          </Fragment>
                        ) : (
                          <span>Siguiente</span>
                        )}
                      </button>
                    </div>
                  </Form>
                </fieldset>
              </React.Fragment>
            );
          }}
        </Formik>
        <ModalRequisitosAsegurabilidad
          seccion={SECCION_DATOS_PERSONALES}
          mostrarModal={this.state.modalRequisitosAsegurabilidad}
          cerrarModal={this.cerrarModalRequisitosAsegurabilidad}
          obtenerSeleccion={this.obtenerSeleccionRequisitosAsegurabilidad}
          textoPolizas={this.state.tipoPolizas}
          loadingDataRequisitos={this.state.loadingDataRequisitos}
          messages={[
            {
              message: "Finalizar el proceso de suscripción",
              helpMessage:
                "Usted no ha completado su solicitud, si selecciona esta opción finalizará el proceso sin terminar su incorporación a la póliza.",
              option: FINALIZAR_PROCESO,
            },
            {
              message: "Solicitar Reevaluación",
              helpMessage:
                "Su petición será sometida a un proceso especial de evaluación por parte de la compañía, no asegurando esto su aceptación. Deberá continuar completando la solicitud de incorporación.",
              option: SOLICITAR_REEVALUACION,
            },
          ]}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state,
});

export default withRouter(
  connect(mapStateToProps, { guardar_data_wizard_asegurado, wizard })(MisDatos)
);
