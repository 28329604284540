import React, { useEffect, useState, Fragment } from "react";
import RadioSelection from "../../../../transversales/RadioSelection";
import MultiSelect from "../../../../transversales/MultiSelect";
import ModalCarga from "../../negocios/crear-negocio/ModalCarga";
import {
  listarSubProductos,
  listarCondicionesSubProducto,
  crearConfigurarProducto,
  actualizarConfigurarProducto,
} from "../../../../../../services/producto.service";
import { listarTiposCapital } from "../../../../../../services/transversales.service";
import {
  VALOR_ZERO,
  MASK_DATE,
  VALOR_DOS,
  VALOR_UNO,
  VALOR_TRES,
  ID_PRODUCTO_VIDA_1,
  ID_PRODUCTO_VIDA_10,
  ID_NEGOCIO_EN_PROCESO,
  ID_NEGOCIO_CERRADO,
  VALOR_CINCO,
  STRING_VACIO,
  T_NEGOCIO_INACTIVO,
  SIGNO_GUION,
  DIA_UNO,
  MES_UNO,
  POSICION_INICIAL,
  GA_CATEGORIA_NEGOCIOS,
  GA_LABEL_AGREGAR_COTIZACION,
} from "../../../../../../utils/Constantes";
import {
  PRODUCTO_SALUD_PYME,
  RUTA_COTIZACIONES_INICIO,
  STORAGE_RUTA,
  EXITO_CREAR_PRODUCTO,
  EXITO_ACTUALIZAR_PRODUCTO,
  ERROR_ENVIO_PRODUCTO,
  PRODUCTO_VIDA,
  PRODUCTO_VIDA_PYME,
  COBERTURA_INVALIDEZ_23,
  COBERTURA_INVALIDEZ_80,
  VARIABLE_UF,
  VARIABLE_RENTA,
  PRODUCTO_SALUD,
} from "../../../../../../utils/ConstantesVentaDirecta";
import {
  formatNumber,
  formatNumberDecimalUF,
} from "../../../../../../utils/Functions";
import { Form, Formik, Field } from "formik";
import { useHistory } from "react-router-dom";
import { obtenerValidacionesFormulario } from "./ValidacionesFormProducto";
import MaskedTextInput from "react-text-mask";

import moment from "moment";
import "moment/locale/es";
import es from "date-fns/locale/es";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector, useDispatch } from "react-redux";
import { formatDateBackEnd } from "../../../../../../utils/DateUtils";
import { notificacion } from "../../../../transversales/Notificaciones";
import { INITIAL_VALUES } from "./ValidacionesFormProducto";
import Modal from "../../../../transversales/Modal";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { guardar_cotizacion } from "../../../../../../actions/actions";
import {
  validarCotizacionrepetida,
  listarPorCodigoCotizacion,
} from "../../../../../../services/cotizacion.services";
import { crearEventoTraza } from "../../../../../../utils/analitica";

registerLocale("es", es);

const ConfiguracionProducto = () => {
  useEffect(() => {
    crearEventoTraza(GA_CATEGORIA_NEGOCIOS, GA_LABEL_AGREGAR_COTIZACION);
  }, []);

  const history = useHistory();

  const { reducer, auth } = useSelector((store) => store);
  const dispatch = useDispatch();

  const [cargando, setCargando] = useState(true);
  const [editar, setEditar] = useState(false);
  const [dataFormularioOriginal, setDataFormularioOriginal] =
    useState(INITIAL_VALUES);
  const [dataFormulario, setDataFormulario] = useState(INITIAL_VALUES);

  const [modalVolver, setModalVolver] = useState(false);

  const [llamadaHttp, setLlamadaHttp] = useState(false);

  // Se usa para quitar o agregar validaciones de capital/valorCapital/preexistencia
  const [validacionTipoProceso, setValidacionTipoProceso] = useState(false);

  const [validacionCapital, setvalidacionCapital] = useState(false);

  const [subProductos, setSubProductos] = useState([]);
  const [coberturas, setCoberturas] = useState([]);
  const [tiposCapital, setTiposCapital] = useState([]);
  const [validarFolioCotizacion, setValidarFolioCotizacion] = useState(false);

  const [capitalesVariables, setCapitalesVariables] = useState([]);
  const [folioValidacionTimer, setFolioValidacionTimer] = useState(null);
  const [
    validarCambioContinuidadCobertura,
    setValidarCambioContinuidadCobertura,
  ] = useState(false);

  const [mostrarModalInfo, setMostrarModalInfo] = useState(false);

  const [fechaPrimeroDelMes, setFechaPrimeroDelMes] = useState("");

  const esNegocioEnProcesoSinContratoGrupo = [
    ID_NEGOCIO_EN_PROCESO,
    ID_NEGOCIO_CERRADO,
  ].includes(reducer.negocio.estadoNegocio.idEstadoNegocio);

  const [continuidadCobertura, setContinuidadCobertura] = useState(false);
  const [editarCapitalAnterior, setEditarCapitalAnterior] = useState(false);
  const [capitalContinuidadAnterior, setCapitalContinuidadAnterior] = useState({
    capitalAnterior: "",
    valorCapitalAnterior: "",
  });
  const handleInput = (event) => {
    if (String([event.target.name]) === "valorCapitalAnterior") {
      if ([Number(event.target.value) > 0]) {
        setCapitalContinuidadAnterior({
          ...capitalContinuidadAnterior,
          [event.target.name]: formatNumberDecimalUF(
            event.target.value,
            VALOR_CINCO
          ),
        });
      }
    } else {
      setCapitalContinuidadAnterior({
        ...capitalContinuidadAnterior,
        [event.target.name]: event.target.value,
      });
    }
  };
  const anyCheckbox = () => {
    var inputElements = document.getElementsByName(
      "FormularioContinuidadCobertura"
    );
    for (var i = 0; i < inputElements.length; i++)
      if (inputElements[i].checked) return true;
    return false;
  };
  const { cotizacion } = useSelector((store) => store.reducer);
  // (inicio) Sprint17 : Continuidad de Cobertura
  const onCheckboxClicked = (idCobertura, estContinuidadCobertura) => {
    setContinuidadCobertura(anyCheckbox());
    if (!editar) {
      coberturas.map(function (item) {
        if (item.idCobertura === idCobertura) {
          item.cobertura.cobertura.cotizacionCobertura = {
            continuidadCobertura: estContinuidadCobertura,
          };
        }
        return item;
      });
    } else {
      setContinuidadCobertura(anyCheckbox());
      coberturas.forEach(function (cobertura) {
        if (cobertura.idCobertura === idCobertura) {
          if (cobertura.cobertura.cobertura.cotizacionCobertura) {
            cobertura.cobertura.cobertura.cotizacionCobertura.continuidadCobertura =
              estContinuidadCobertura;
            if (!estContinuidadCobertura) {
              cobertura.cobertura.cobertura.cotizacionCobertura.capitalesAnteriores =
                null;
            }
          } else {
            cobertura.cobertura.cobertura.cotizacionCobertura = {
              continuidadCobertura: estContinuidadCobertura,
            };
            if (!estContinuidadCobertura) {
              cobertura.cobertura.cobertura.cotizacionCobertura.capitalesAnteriores =
                null;
            }
          }
        }
      });
      setValidarCambioContinuidadCobertura(true);
    }
  };

  const Checkbox = ({ id, value, estadoInicial, onChange }) => {
    let initialState = editar ? false : estadoInicial;
    if (editar) {
      if (coberturas.length > 0) {
        coberturas.forEach((c) => {
          const cotizacion = c.cobertura.cotizacionCobertura
            ? c.cobertura.cotizacionCobertura
            : c.cobertura.cobertura
            ? c.cobertura.cobertura.cotizacionCobertura
            : null;
          if (!cotizacion) {
            reducer.cotizacion.coberturas.forEach((cfo) => {
              if (c.idCobertura === cfo.idProductoCobertura) {
                c.cobertura.cobertura.cotizacionCobertura =
                  cfo.cotizacionCobertura;
              }
            });
          }
        });
        const coberturasProducto = coberturas.find(
          (el) => el.idCobertura === id
        );
        if (coberturasProducto) {
          const cobertura = coberturasProducto.cobertura.cobertura;
          initialState = cobertura
            ? cobertura.cotizacionCobertura
              ? cobertura.cotizacionCobertura.continuidadCobertura
              : false
            : coberturasProducto.cobertura.cotizacionCobertura
            ? coberturasProducto.cobertura.cotizacionCobertura
                .continuidadCobertura
            : false;
        } else {
          initialState = false;
        }
      } else {
        const cobertura = cotizacion.coberturas.find(
          (el) => el.idProductoCobertura === id
        );
        if (cobertura) {
          initialState = cobertura.cotizacionCobertura.continuidadCobertura;
        } else {
          initialState = false;
        }
      }
    }
    const [estadoContinuidadCobertura, setEstadoContinuidadCobertura] =
      useState(initialState);
    const onClick = (estadoContinuidadCobertura) => {
      setEstadoContinuidadCobertura(estadoContinuidadCobertura);
      onChange(id, estadoContinuidadCobertura);
    };

    return (
      <div>
        <label className="switch mt-2 mr-2">
          <input
            id={id}
            type="checkbox"
            onClick={(e) => onClick(e.target.checked)}
            defaultChecked={estadoContinuidadCobertura}
            name="FormularioContinuidadCobertura"
            disabled={
              esNegocioEnProcesoSinContratoGrupo ||
              (reducer.tipoNegocio === T_NEGOCIO_INACTIVO && editar) ||
              validarFolioCotizacion
            }
          />
          <span className="slider round" />
        </label>
        Continuidad de Cobertura para{" "}
        <strong>
          {" "}
          {value.label} -{" "}
          {value.cobertura.condicionado && value.cobertura.condicionado.glosa}{" "}
        </strong>
      </div>
    );
  };

  const obtenerGlosaTipoProducto = (idTipoProducto) => {
    let glosaSubProducto = "";
    subProductos.map((item) =>
      item.idSubProducto === Number(idTipoProducto)
        ? (glosaSubProducto = item.glosaSubProducto)
        : null
    );
    return glosaSubProducto;
  };
  //(fin) Sprint17 : Continuidad de Cobertura}

  /*************************
   *  DATA FORMULARIO      *
   *************************/
  const obtenerDataFormulario = () => {
    // Validar valorCapital productos VIDA
    let valorCapital = "";
    reducer.cotizacion.coberturas.map((item) => {
      if (item.cotizacionCobertura.continuidadCobertura) {
        setCapitalContinuidadAnterior({
          capitalAnterior:
            reducer.cotizacion.tipoCapital.glosaCapitalAnterior.toUpperCase(),
          valorCapitalAnterior:
            item.cotizacionCobertura.capitales[0].valorCapitalAnterior,
        });
      }
      return null;
    });
    if (
      reducer.cotizacion.tipoCapital &&
      reducer.cotizacion.coberturas[0].cotizacionCobertura
    ) {
      if (reducer.cotizacion.tipoCapital.idTipoCapital > VALOR_DOS) {
        obtenerValoresCapitalesVaribles(
          reducer.cotizacion.coberturas[0].cotizacionCobertura.capitales
        );
      } else {
        valorCapital =
          reducer.cotizacion.coberturas[0].cotizacionCobertura.capitales[0]
            .valorCapital;
      }
    }
    reducer.cotizacion.coberturas.map((items) => {
      if (items.cotizacionCobertura.continuidadCobertura) {
        setContinuidadCobertura(true);
      }
      return null;
    });
    const dataFormulario = {
      tipoDeProducto: reducer.cotizacion.subProducto.idSubProducto,
      coberturas: homologarCoberturasFormulario(reducer.cotizacion.coberturas),
      seleccionCobertura: reducer.cotizacion.coberturaAsegurable,
      capital: reducer.cotizacion.tipoCapital
        ? reducer.cotizacion.tipoCapital.glosa.toUpperCase()
        : VALOR_ZERO,
      valorCapital,
      preexistencia: !reducer.cotizacion.evaluacionPreexistencia,
      folioCotizacion: reducer.cotizacion.codigoCotizacion,
      primaNegocio: reducer.cotizacion.valorPrimaNeta,
      fechaCotizacion: moment(reducer.cotizacion.fechaCotizacion).format(
        "DD/MM/YYYY"
      ),
      fechaInicioVigencia: moment(reducer.negocio.fechaInicioVigencia).format(
        "DD/MM/YYYY"
      ),
      numeroTrabajadores: reducer.cotizacion.numeroDotacion,
    };

    obtenerCoberturas(reducer.cotizacion.subProducto.idSubProducto);
    setDataFormularioOriginal(dataFormulario);
    setDataFormulario(dataFormulario);
    setCargando(false);
  };

  const obtenerValoresCapitalesVaribles = (capitales) => {
    capitales = capitales.map((item) => item.valorCapital);
    setCapitalesVariables(capitales);
  };

  /*************************
   *  CATALOGO             *
   *************************/
  const obtenerSubProductos = async () => {
    const subProductos = await listarSubProductos();
    setSubProductos(subProductos);
  };

  const obtenerTiposCapital = async () => {
    let tiposCapital = await listarTiposCapital();
    tiposCapital = tiposCapital.filter((item) => item.idTipoCapital < 3);
    setTiposCapital(tiposCapital);
  };

  const obtenerFechaPrimeroDelMes = async () => {
    let fechaHoy = new Date();
    let mes = fechaHoy.getMonth() + MES_UNO;
    let año = fechaHoy.getFullYear();

    setFechaPrimeroDelMes(
      new Date(año + SIGNO_GUION + mes + SIGNO_GUION + DIA_UNO)
    );
  };

  useEffect(() => {
    obtenerSubProductos();
    obtenerTiposCapital();
    obtenerFechaPrimeroDelMes();
    if (history.location.state) {
      obtenerDataFormulario();
      setEditar(history.location.state.editar);
    } else {
      setCargando(false);
      dispatch(guardar_cotizacion({}));
    }
    // eslint-disable-next-line
  }, []);
  const validaCapital = async (idSubProducto) => {
    if (
      idSubProducto === PRODUCTO_VIDA ||
      idSubProducto === PRODUCTO_VIDA_PYME ||
      idSubProducto === PRODUCTO_SALUD ||
      idSubProducto === PRODUCTO_SALUD_PYME
    )
      setvalidacionCapital(true);
    else setvalidacionCapital(false);
  };

  const validaPreexistencia = async (idSubProducto) => {
    if (idSubProducto === PRODUCTO_SALUD_PYME) setValidacionTipoProceso(true);
    else setValidacionTipoProceso(false);
  };

  const obtenerCoberturas = async (value, setValue) => {
    if (setValue) setValue("coberturas", []);
    let idSubProducto = Number(value);
    let coberturas = await listarCondicionesSubProducto(idSubProducto);
    await validaPreexistencia(idSubProducto);
    await validaCapital(idSubProducto);
    coberturas = coberturas.map((item) => {
      return (item = {
        principal: item.principal,
        cobertura: item,
        idCobertura: item.idSubProdCondicionadoCobertura,
        value: item.idSubProdCondicionadoCobertura,
        label: `${item.cobertura.glosaCobertura}`,
        dataTooltip: `${item.cobertura.glosaCobertura}`,
      });
    });
    const coberturaPrincipal = coberturas.filter((item) => item.principal);
    setCoberturas(coberturas);
    if (setValue) setValue("coberturas", coberturaPrincipal);
  };

  /*****************************
   *  VALIDACIONES COBERTURAS  *
   *****************************/
  const homologarCoberturasFormulario = (coberturas) => {
    return (coberturas = coberturas.map((item) => {
      return {
        cobertura: item,
        dataTooltip: item.glosaCobertura,
        idCobertura: item.idProductoCobertura,
        label: item.glosaCobertura,
        principal: item.principal,
        value: item.idProductoCobertura,
      };
    }));
  };

  //seleccionada
  const validarCoberturasSeleccionadas = (
    coberturasSeleccionadas,
    setValue
  ) => {
    // Validar coberturas principales
    const coberturaPrincipal = coberturas.filter((item) => item.principal);
    if (!coberturasSeleccionadas) {
      coberturasSeleccionadas = coberturaPrincipal;
    } else {
      const existeCoberturaPrincipal = coberturasSeleccionadas.find(
        (item) => item.label === coberturaPrincipal[0].label
      );
      if (!existeCoberturaPrincipal) {
        coberturasSeleccionadas.unshift(...coberturaPrincipal);
      }
    }

    // Validar cobertura [invalidez 2/3] | [invalidez 80%]
    const ultimaCobertura =
      coberturasSeleccionadas[coberturasSeleccionadas.length - 1];

    const coberturaInvalidez23 = coberturasSeleccionadas.find(
      (item) => item.idCobertura === COBERTURA_INVALIDEZ_23
    );
    const coberturaInvalidez80 = coberturasSeleccionadas.find(
      (item) => item.idCobertura === COBERTURA_INVALIDEZ_80
    );

    if (
      ultimaCobertura.idCobertura === COBERTURA_INVALIDEZ_23 &&
      coberturaInvalidez80
    ) {
      const titulo = `Cobertura ${ultimaCobertura.label} no puede estar seleccionada a la vez con ${coberturaInvalidez80.label}`;

      reemplazarCoberturaInvalidez(
        titulo,
        coberturasSeleccionadas,
        COBERTURA_INVALIDEZ_80,
        setValue
      );
    } else if (
      ultimaCobertura.idCobertura === COBERTURA_INVALIDEZ_80 &&
      coberturaInvalidez23
    ) {
      const titulo = `Cobertura ${ultimaCobertura.label} no pueden esta seleccionada a la vez con ${coberturaInvalidez23.label}`;

      reemplazarCoberturaInvalidez(
        titulo,
        coberturasSeleccionadas,
        COBERTURA_INVALIDEZ_23,
        setValue
      );
    } else {
      setValue("coberturas", coberturasSeleccionadas);
    }
  };

  const reemplazarCoberturaInvalidez = (
    titulo,
    coberturas,
    idCobertura,
    setValue
  ) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="modal-confirm-container">
            <div className="">
              <p className="h4 d-block">{titulo}</p>
              <p className="h5 mt-3 d-block">
                {"¿Desea reemplazar esta cobertura?"}
              </p>
            </div>

            <div className="row mt-4">
              <button
                className="btn btn-principal color-secundario btn-sm d-block ml-auto mr-2"
                onClick={() => {
                  onClose();
                }}
              >
                No
              </button>
              <button
                className="btn btn-principal btn-sm"
                onClick={() => {
                  coberturas = coberturas.filter(
                    (item) => item.idCobertura !== idCobertura
                  );
                  setValue("coberturas", coberturas);
                  onClose();
                }}
              >
                Sí
              </button>
            </div>
          </div>
        );
      },
    });
  };

  /*************************
   *  DATA ENVÍO           *
   *************************/
  const obtenerArregloObjetoCobertura = (data) => {
    // Armar objeto capitales variables
    const capitalesVariablesEnvio = capitalesVariables.map((capital) => {
      return { valorCapital: Number(capital) };
    });
    let corregirData;
    data.coberturas.forEach((e) => {
      if (e.cobertura.cobertura) {
        corregirData = e.cobertura.cobertura;
        e.cobertura = corregirData;
      }
    });
    let coberturasEnvio = [];

    data.coberturas.forEach((e) => {
      let objCobertura = {
        idProductoCobertura: !validarFolioCotizacion
          ? e.idCobertura
          : e.cobertura.cotizacionCobertura.idSubProdCondicionadoCobertura,
        cotizacionCobertura: {
          capitales:
            !data.valorCapital && capitalesVariablesEnvio.length === VALOR_ZERO
              ? null
              : capitalesVariables.length > VALOR_ZERO
              ? capitalesVariablesEnvio
              : e.cobertura.cotizacionCobertura !== null
              ? e.cobertura.cotizacionCobertura.continuidadCobertura
                ? [
                    {
                      valorCapital: Number(data.valorCapital),
                      valorCapitalAnterior: Number(
                        capitalContinuidadAnterior.valorCapitalAnterior
                      ),
                    },
                  ]
                : [
                    {
                      valorCapital: Number(data.valorCapital),
                      valorCapitalAnterior: null,
                    },
                  ]
              : [
                  {
                    valorCapital: Number(data.valorCapital),
                    valorCapitalAnterior: null,
                  },
                ],

          continuidadCobertura: e.cobertura.cotizacionCobertura
            ? e.cobertura.cotizacionCobertura.continuidadCobertura
            : false,
        },
      };
      coberturasEnvio.push(objCobertura);
    });

    return coberturasEnvio;
  };

  const enviarFormulario = async (data) => {
    setLlamadaHttp(true);
    // Validar cambios en el formulario

    if (data === dataFormularioOriginal && !validarCambioContinuidadCobertura) {
      if (!editarCapitalAnterior) {
        return volver();
      }
    }

    const formularioEnvio = esNegocioEnProcesoSinContratoGrupo
      ? { ...dataFormularioOriginal }
      : { ...data };
    // Obtener idCapital
    let idTipoCapital;
    let idTipoCapitalAnterior;
    if (formularioEnvio.capital) {
      if (continuidadCobertura) {
        idTipoCapital = tiposCapital.find(
          (e) => e.glosa.toLowerCase() === formularioEnvio.capital.toLowerCase()
        ).idTipoCapital;
        idTipoCapitalAnterior = tiposCapital.find(
          (e) =>
            e.glosa.toLowerCase() ===
            capitalContinuidadAnterior.capitalAnterior.toLowerCase()
        ).idTipoCapital;
      } else {
        idTipoCapital = tiposCapital.find(
          (e) => e.glosa.toLowerCase() === formularioEnvio.capital.toLowerCase()
        ).idTipoCapital;
        idTipoCapitalAnterior = null;
      }
    }

    // Validar primera cotización para editarla
    let idCotizacion = VALOR_ZERO;

    if (
      reducer.cotizaciones.length === VALOR_UNO &&
      !reducer.cotizaciones[0].codigoCotizacion
    ) {
      idCotizacion = reducer.cotizaciones[0].idCotizacion;
    }
    if (reducer.cotizacion.idCotizacion) {
      idCotizacion = reducer.cotizacion.idCotizacion
        ? reducer.cotizacion.idCotizacion
        : VALOR_ZERO;
    }

    let evaluacionPreexistencia = validacionTipoProceso
      ? !formularioEnvio.preexistencia //Se niega valor, por temas de cambio de pregunta
      : Number(formularioEnvio.tipoDeProducto) === ID_PRODUCTO_VIDA_10
      ? false
      : Number(formularioEnvio.tipoDeProducto) === ID_PRODUCTO_VIDA_1;

    let dataEnvio = {
      idCotizacion,
      idUsuarioModifica: auth.usuarioSesion.id,
      idUsuarioCrea: auth.usuarioSesion.id,
      coberturaAsegurable: formularioEnvio.seleccionCobertura,
      coberturas: obtenerArregloObjetoCobertura(formularioEnvio),
      evaluacionPreexistencia,
      fechaCotizacion: formatDateBackEnd(formularioEnvio.fechaCotizacion),
      fechaInicioVigencia: formatDateBackEnd(
        formularioEnvio.fechaInicioVigencia
      ),
      idNegocio: reducer.negocio.idNegocio,
      numeroDotacion: Number(formularioEnvio.numeroTrabajadores),
      codigoCotizacion: data.folioCotizacion,
      tipoCapital: validacionCapital
        ? { idTipoCapital, idTipoCapitalAnterior }
        : null,
      subProducto: {
        idSubProducto: Number(formularioEnvio.tipoDeProducto),
      },
      valorPrimaNeta: Number(formularioEnvio.primaNegocio),
    };
    // Enviar data
    if (editar) {
      const resActualizarProducto = await actualizarConfigurarProducto(
        dataEnvio
      );
      if (resActualizarProducto)
        notificacion("success", EXITO_ACTUALIZAR_PRODUCTO);
      else {
        notificacion("danger", ERROR_ENVIO_PRODUCTO);
        setLlamadaHttp(false);
      }
    } else {
      const resCrearProducto = await crearConfigurarProducto(dataEnvio);
      if (resCrearProducto) notificacion("success", EXITO_CREAR_PRODUCTO);
      else {
        notificacion("danger", ERROR_ENVIO_PRODUCTO);
        setLlamadaHttp(false);
      }
    }
    volver();
  };

  const validaFolioCotizacion = async (folioCotizacion, errors) => {
    //valida duplicidad de producto en un mismo negocio
    const cotizaciones = reducer.cotizaciones;
    if (cotizaciones.codigoCotizacion) {
      cotizaciones.find((item) => item.codigoCotizacion === folioCotizacion)
        ? setMostrarModalInfo(true)
        : setMostrarModalInfo(false);
    }
    //limpia form si el folio cotizacion es menor igual a 3
    if (
      folioCotizacion.length < VALOR_TRES &&
      reducer.tipoNegocio !== T_NEGOCIO_INACTIVO &&
      !editar
    ) {
      setDataFormularioLimpio(folioCotizacion);
    }
    if (
      folioCotizacion.length > VALOR_TRES &&
      reducer.cotizacion.codigoCotizacion !== folioCotizacion
    ) {
      const cotizacionRepetida = await validarCotizacionrepetida(
        folioCotizacion
      );
      if (
        cotizacionRepetida &&
        editar &&
        reducer.tipoNegocio === T_NEGOCIO_INACTIVO
      ) {
        setMostrarModalInfo(true);
      }
      setValidarFolioCotizacion(cotizacionRepetida);
      obtieneDataFormularioPrecargado(folioCotizacion, cotizacionRepetida);
    } else {
      setValidarFolioCotizacion(false);
    }
    setLlamadaHttp(false);
  };

  const volver = () => {
    sessionStorage.removeItem(STORAGE_RUTA);
    history.push(RUTA_COTIZACIONES_INICIO);
  };

  //obteniendo data para formulario precargado
  const obtieneDataFormularioPrecargado = async (
    folioCotizacion,
    cotizacionExiste
  ) => {
    let requestData = {
      folioCotizacion: folioCotizacion,
      idNegocio: 0,
    };

    if (cotizacionExiste && !editar) {
      let coberturasIguales = false;
      let cotizacionUnicoRegistro = false;
      let cotizacionesExistentes = [];
      cotizacionesExistentes = await listarPorCodigoCotizacion(requestData);
      if (cotizacionesExistentes && cotizacionesExistentes.length === 1) {
        cotizacionUnicoRegistro = true;
      } else {
        //valida coberturas iguales
        coberturasIguales = await comparaCotizaciones(cotizacionesExistentes);
      }

      //si las coberturas y subproductos son iguales completa el formulario envio
      if (cotizacionUnicoRegistro || coberturasIguales) {
        let cotizacionPrecargada = cotizacionesExistentes[0];
        let valorCapital = "";

        if (
          cotizacionPrecargada.tipoCapital &&
          cotizacionPrecargada.coberturas[0].cotizacionCobertura
        ) {
          if (cotizacionPrecargada.tipoCapital.idTipoCapital > VALOR_DOS) {
            obtenerValoresCapitalesVaribles(
              cotizacionPrecargada.coberturas[0].cotizacionCobertura.capitales
            );
          } else {
            valorCapital =
              cotizacionPrecargada.coberturas[0].cotizacionCobertura
                .capitales[0].valorCapital;
          }
        }

        cotizacionPrecargada.coberturas.map((objCobertura) => {
          if (objCobertura.cotizacionCobertura.continuidadCobertura) {
            setContinuidadCobertura(true);
            setCapitalContinuidadAnterior({
              capitalAnterior:
                cotizacionPrecargada.tipoCapital.glosaCapitalAnterior.toUpperCase(),
              valorCapitalAnterior:
                objCobertura.cotizacionCobertura.capitales[POSICION_INICIAL]
                  .valorCapitalAnterior,
            });
          }
          return null;
        });

        const dataFormulario = {
          idCotizacion: cotizacionPrecargada.idCotizacion,
          tipoDeProducto: cotizacionPrecargada.subProducto.idSubProducto,
          coberturas: homologarCoberturasFormulario(
            cotizacionPrecargada.coberturas
          ),
          seleccionCobertura: cotizacionPrecargada.coberturaAsegurable,
          capital: cotizacionPrecargada.tipoCapital
            ? cotizacionPrecargada.tipoCapital.glosa.toUpperCase()
            : VALOR_ZERO,
          valorCapital,
          preexistencia: cotizacionPrecargada.evaluacionPreexistencia,
          folioCotizacion: requestData.folioCotizacion,
          primaNegocio: cotizacionPrecargada.valorPrimaNeta,
          fechaCotizacion: moment(cotizacionPrecargada.fechaCotizacion).format(
            "DD/MM/YYYY"
          ),
          fechaInicioVigencia: moment(
            cotizacionPrecargada.fechaInicioVigencia
          ).format("DD/MM/YYYY"),
          numeroTrabajadores: "",
        };
        obtenerCoberturas(cotizacionPrecargada.subProducto.idSubProducto);
        setDataFormularioOriginal(dataFormulario);
        setDataFormulario(dataFormulario);
        setCargando(false);
      } else {
        setMostrarModalInfo(true);
      }
    } else {
      if (!editar) {
        setContinuidadCobertura(false);
        setCapitalContinuidadAnterior({
          capitalAnterior: "",
          valorCapitalAnterior: "",
        });
        setDataFormularioLimpio(folioCotizacion);
      }
      if (editar && cotizacionExiste) {
        setMostrarModalInfo(true);
      }
    }
  };

  const setDataFormularioLimpio = (folioCotizacion) => {
    let dataFormularioLimpio = {
      tipoDeProducto: VALOR_ZERO,
      coberturas: null,
      seleccionCobertura: false,
      capital: VALOR_ZERO,
      valorCapital: STRING_VACIO,
      preexistencia: VALOR_ZERO,
      folioCotizacion: folioCotizacion,
      primaNegocio: STRING_VACIO,
      fechaCotizacion: STRING_VACIO,
      fechaInicioVigencia: STRING_VACIO,
      numeroTrabajadores: STRING_VACIO,
    };
    setDataFormularioOriginal(dataFormularioLimpio);
    setDataFormulario(dataFormularioLimpio);
    setCargando(false);

    return dataFormularioLimpio;
  };

  //comparar cotizaciones
  const comparaCotizaciones = (arrCotizacionesExistentes) => {
    if (arrCotizacionesExistentes !== null) {
      let sonIguales = true;
      for (let index = 1; index < arrCotizacionesExistentes.length; index++) {
        const element1 = arrCotizacionesExistentes[index];

        if (
          arrCotizacionesExistentes[0].coberturas.length !==
            element1.coberturas.length ||
          arrCotizacionesExistentes[0].subProducto.idSubProducto !==
            element1.subProducto.idSubProducto
        ) {
          sonIguales = false;
          break;
        }

        for (let index = 0; index < element1.coberturas.length; index++) {
          const cobertura = element1.coberturas[index];
          if (
            arrCotizacionesExistentes[0].coberturas[index].cotizacionCobertura
              .idSubProdCondicionadoCobertura !==
            cobertura.cotizacionCobertura.idSubProdCondicionadoCobertura
          ) {
            sonIguales = false;
            break;
          }
        }
      }
      return sonIguales;
    }

    return false;
  };

  return (
    <React.Fragment>
      {mostrarModalInfo && (
        <Modal
          type="small"
          show={mostrarModalInfo}
          title="Folio de cotización"
          titleButton="Cerrar"
          onClose={() => {
            setMostrarModalInfo(!mostrarModalInfo);
            setLlamadaHttp(true);
          }}
          onSubmit={() => {
            setMostrarModalInfo(!mostrarModalInfo);
            setLlamadaHttp(true);
          }}
          showCancelButton={false}
          showCloseButton={true}
          showLeftColumn={false}
        >
          <h5 className="offset">
            El código de cotización ingresado corresponde a otra configuración
            de Productos–Coberturas.
            <br />
            <br />
            Favor ingresar un nuevo código de cotización.
          </h5>
        </Modal>
      )}
      {cargando ? (
        <ModalCarga show={cargando} mensaje={"Cargando"} />
      ) : (
        <Fragment>
          {/* BOTÓN VOLVER */}
          <Modal
            type="small"
            show={modalVolver}
            title="¿Está seguro que desea volver?"
            titleButton="Volver"
            onClose={() => {
              setModalVolver(false);
            }}
            onCancel={() => {
              setModalVolver(false);
            }}
            onSubmit={() => {
              setModalVolver(false);
              volver();
            }}
            showCancelButton={true}
            showCloseButton={true}
            showLeftColumn={false}
          >
            <h5 className="col-12 col-md-8 offset-md-2">
              Al volver no se guardaran los cambios realizados
            </h5>
          </Modal>
          {/* FORMULARIO PRODUCTO */}
          <div className="card shadow my-3">
            <div
              className="card-body container"
              style={{ paddingLeft: "150px", paddingRight: "150px" }}
            >
              <h3 className="font-weight-bold my-3 text-primary">
                {!editar
                  ? "Agregar datos del producto"
                  : "Editar datos del producto"}
              </h3>
              <Formik
                enableReinitialize={true}
                initialValues={dataFormulario}
                validationSchema={obtenerValidacionesFormulario(
                  validacionTipoProceso,
                  capitalesVariables,
                  validacionCapital,
                  continuidadCobertura,
                  capitalContinuidadAnterior
                )}
                onSubmit={(values, { resetForm }) => {
                  enviarFormulario(values, resetForm);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  setFieldValue,
                  setFieldTouched,
                  handleChange,
                  handleBlur,
                }) => {
                  return (
                    <Form
                      className="row mt-3"
                      id="formularioConfiguracionProducto"
                      name="formularioConfiguracionProducto"
                      onKeyDown={(e) => {
                        if ((e.charCode || e.keyCode) === 13) {
                          e.preventDefault();
                        }
                      }}
                    >
                      {/* 1. folioCotizacion */}
                      <div className="col-md-12">
                        <div className="form-group mb-2">
                          <label className="font-weight-bold">
                            Folio cotización{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <Field
                            className="form-control"
                            id="folioCotizacion"
                            name="folioCotizacion"
                            type="text"
                            placeholder="Ingrese folio de cotización"
                            value={values.folioCotizacion.trim()}
                            onChange={(e) => {
                              let nuevoValorFolioCotizacion =
                                e.currentTarget.value;
                              setFieldValue(
                                "folioCotizacion",
                                nuevoValorFolioCotizacion
                              );

                              clearTimeout(folioValidacionTimer);
                              setLlamadaHttp(true);
                              setFolioValidacionTimer(
                                setTimeout(() => {
                                  validaFolioCotizacion(
                                    nuevoValorFolioCotizacion,
                                    errors
                                  );
                                }, 1000)
                              );
                            }}
                          />
                          {errors.folioCotizacion &&
                            touched.folioCotizacion && (
                              <small className="text-danger animated fadeIn">
                                {errors.folioCotizacion}
                              </small>
                            )}
                        </div>
                      </div>

                      {/* 2. tipoDeProducto */}
                      <div className="col-md-12">
                        <div className="form-group mb-2">
                          <label className="font-weight-bold">
                            Tipo de producto{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <select
                            id="tipoDeProducto"
                            name="tipoDeProducto"
                            value={values.tipoDeProducto}
                            onChange={(e) => {
                              handleChange(e);
                              obtenerCoberturas(e.target.value, setFieldValue);
                            }}
                            disabled={
                              esNegocioEnProcesoSinContratoGrupo ||
                              validarFolioCotizacion ||
                              (reducer.tipoNegocio === T_NEGOCIO_INACTIVO &&
                                editar)
                            }
                            onBlur={handleBlur}
                            className="form-control"
                          >
                            <option value="0">Selecciona una opción</option>
                            {subProductos.map((item, i) => (
                              <option
                                key={`subProducto_${i}`}
                                value={item.idSubProducto}
                              >
                                {item.glosaSubProducto.toUpperCase()}
                              </option>
                            ))}
                          </select>
                          {errors.tipoDeProducto && touched.tipoDeProducto && (
                            <small className="text-danger animated fadeIn">
                              {errors.tipoDeProducto}
                            </small>
                          )}
                        </div>
                      </div>

                      {/* 3. cobertura */}
                      <div className="col-md-12">
                        <div className="form-group mb-2">
                          <label className="font-weight-bold">
                            Cobertura/s <span className="text-danger">*</span>
                          </label>
                          <MultiSelect
                            onChange={(values) => {
                              setFieldTouched("coberturas", true);
                              validarCoberturasSeleccionadas(
                                values,
                                setFieldValue
                              );
                              setTimeout(function () {
                                setContinuidadCobertura(anyCheckbox());
                              }, 10);
                            }}
                            tooltip={true}
                            id="coberturas"
                            name="coberturas"
                            options={
                              esNegocioEnProcesoSinContratoGrupo
                                ? []
                                : coberturas
                            }
                            noOptionsMessage={() => "No existen más coberturas"}
                            placeholder={"Seleccione cobertura/s"}
                            value={values.coberturas}
                            disabled={
                              Number(values.tipoDeProducto) === VALOR_ZERO ||
                              esNegocioEnProcesoSinContratoGrupo ||
                              validarFolioCotizacion ||
                              (reducer.tipoNegocio === T_NEGOCIO_INACTIVO &&
                                editar)
                            }
                            key={values.folioCotizacion}
                            errors={errors.cobertura}
                          />
                          {errors.coberturas &&
                            touched.coberturas &&
                            values.tipoDeProducto !== VALOR_ZERO && (
                              <small className="text-danger animated fadeIn">
                                {errors.coberturas}
                              </small>
                            )}
                        </div>
                      </div>
                      {Number(values.tipoDeProducto) > 0 && (
                        <Fragment>
                          {/* 4. continuidad de cobertura */}
                          <div className="col-md-12">
                            <div className="border-dashed form-group mb-2">
                              <label className="font-weight-bold">
                                {obtenerGlosaTipoProducto(
                                  values.tipoDeProducto
                                )}
                                <span className="text-danger"> *</span>
                              </label>
                              <div>
                                {values.coberturas.map((item, indice) => (
                                  <Checkbox
                                    id={item.idCobertura}
                                    value={item}
                                    key={indice}
                                    estadoInicial={
                                      item.cobertura.cobertura
                                        ? item.cobertura.cobertura
                                            .cotizacionCobertura
                                          ? item.cobertura.cobertura
                                              .cotizacionCobertura
                                              .continuidadCobertura
                                          : false
                                        : item.cobertura.cotizacionCobertura &&
                                          item.cobertura.cotizacionCobertura
                                            .continuidadCobertura
                                        ? item.cobertura.cotizacionCobertura
                                            .continuidadCobertura
                                        : false
                                    }
                                    onChange={onCheckboxClicked}
                                  />
                                ))}
                              </div>
                              {errors.continuidadCobertura &&
                                touched.continuidadCobertura && (
                                  <small className="text-danger animated fadeIn">
                                    {errors.continuidadCobertura}
                                  </small>
                                )}
                            </div>
                          </div>
                        </Fragment>
                      )}
                      {/* 5. capital compañia anterior */}
                      {continuidadCobertura === true && (
                        <Fragment>
                          <div className="col-md-6 animated fadeIn">
                            <div className="form-group mb-2">
                              <label className="font-weight-bold">
                                Capital compañía anterior{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <select
                                id="capitalAnterior"
                                name="capitalAnterior"
                                className="form-control"
                                onChange={(e) => {
                                  handleInput(e);
                                  setEditarCapitalAnterior(true);
                                }}
                                onBlur={handleBlur}
                                value={
                                  capitalContinuidadAnterior.capitalAnterior
                                }
                                disabled={
                                  esNegocioEnProcesoSinContratoGrupo ||
                                  validarFolioCotizacion ||
                                  (reducer.tipoNegocio === T_NEGOCIO_INACTIVO &&
                                    editar)
                                }
                              >
                                <option value={0}>Selecciona una opción</option>
                                {tiposCapital.map((item, i) => (
                                  <option
                                    key={`idTipoCapitalAnterior_${i}`}
                                    value={item.idCapital}
                                    onChange={(e) => {
                                      handleInput(e);
                                      handleChange(e);
                                    }}
                                    name="idCapitalAnterior"
                                  >
                                    {item.glosa.toUpperCase()}
                                  </option>
                                ))}
                              </select>
                              {(capitalContinuidadAnterior.capitalAnterior ===
                                0 &&
                                touched.capitalAnterior) ||
                                (errors.capitalAnterior && (
                                  <p className="text-danger animated fadeIn">
                                    Debe seleccionar tipo de capital
                                  </p>
                                ))}
                            </div>
                          </div>
                          <div className="col-md-6 animated fadeIn">
                            <div className="form-group mb-2">
                              <label className="font-weight-bold">
                                {capitalContinuidadAnterior.capitalAnterior ===
                                  "FIJO UF" ||
                                capitalContinuidadAnterior.capitalAnterior === 0
                                  ? "Valor capital compañía anterior"
                                  : "Cantidad de renta compañía anterior"}
                                <span className="text-danger"> *</span>
                              </label>
                              <div className="d-flex">
                                <Field
                                  className="form-control"
                                  id="valorCapitalAnterior"
                                  name="valorCapitalAnterior"
                                  type="text"
                                  maxLength="8"
                                  placeholder={`Ingrese ${
                                    capitalContinuidadAnterior.capitalAnterior ===
                                      "FIJO UF" ||
                                    capitalContinuidadAnterior.capitalAnterior ===
                                      0
                                      ? "valor capital"
                                      : "cantidad de renta"
                                  }`}
                                  value={formatNumberDecimalUF(
                                    capitalContinuidadAnterior.valorCapitalAnterior,
                                    VALOR_CINCO
                                  )}
                                  disabled={
                                    esNegocioEnProcesoSinContratoGrupo ||
                                    !capitalContinuidadAnterior.capitalAnterior ||
                                    (reducer.tipoNegocio ===
                                      T_NEGOCIO_INACTIVO &&
                                      editar)
                                  }
                                  onChange={(e) => {
                                    handleInput(e);
                                    handleChange(e);
                                  }}
                                  onBlur={handleBlur}
                                />
                              </div>

                              {(capitalContinuidadAnterior.valorCapitalAnterior ===
                                0 &&
                                touched.valorCapitalAnterior) ||
                                (errors.valorCapitalAnterior && (
                                  <p className="text-danger animated fadeIn">
                                    El valor es obligatorio
                                  </p>
                                ))}
                              {Number(
                                capitalContinuidadAnterior.valorCapitalAnterior
                              ) === 0 &&
                                touched.valorCapitalAnterior && (
                                  <p className="text-danger animated fadeIn">
                                    El valor debe ser mayor a 0
                                  </p>
                                )}
                            </div>
                          </div>
                        </Fragment>
                      )}
                      {/* 6. seleccionCobertura */}
                      <div className="col-md-12">
                        <div className="form-group mb-2">
                          <label className="font-weight-bold">
                            Selección de cobertura por parte del asegurable
                            <span className="text-danger"> *</span>
                          </label>

                          <RadioSelection
                            id="seleccionCobertura"
                            name="seleccionCobertura"
                            value={values.seleccionCobertura}
                            obtenerCheckValue={(value, tipo) => {
                              setFieldValue(tipo, value);
                            }}
                            disabled={
                              true ||
                              (reducer.tipoNegocio === T_NEGOCIO_INACTIVO &&
                                editar)
                            }
                          />

                          {errors.seleccionCobertura &&
                            touched.seleccionCobertura && (
                              <small className="text-danger animated fadeIn">
                                {errors.seleccionCobertura}
                              </small>
                            )}
                        </div>
                      </div>
                      {/* 7. preexistencia */}
                      {Number(values.tipoDeProducto) ===
                        PRODUCTO_SALUD_PYME && (
                        <Fragment>
                          <div className="col-md-12">
                            <div className="form-group mb-2">
                              <label className="font-weight-bold">
                                Cubre preexistencia
                                <span className="text-danger"> *</span>
                              </label>

                              <RadioSelection
                                id="preexistencia"
                                name="preexistencia"
                                value={values.preexistencia}
                                obtenerCheckValue={(value, tipo) => {
                                  setFieldValue(tipo, value);
                                }}
                                disabled={
                                  esNegocioEnProcesoSinContratoGrupo ||
                                  (reducer.tipoNegocio === T_NEGOCIO_INACTIVO &&
                                    editar)
                                }
                              />

                              {errors.preexistencia &&
                                touched.preexistencia && (
                                  <small className="text-danger animated fadeIn">
                                    {errors.preexistencia}
                                  </small>
                                )}
                            </div>
                          </div>
                        </Fragment>
                      )}

                      {/* 8. capital / 9.valorCapital */}
                      {(Number(values.tipoDeProducto) === PRODUCTO_VIDA ||
                        Number(values.tipoDeProducto) === PRODUCTO_VIDA_PYME ||
                        Number(values.tipoDeProducto) === PRODUCTO_SALUD ||
                        Number(values.tipoDeProducto) ===
                          PRODUCTO_SALUD_PYME) && (
                        <Fragment>
                          {/* 8. capital */}
                          <div className="col-md-6 animated fadeIn">
                            <div className="form-group mb-2">
                              <label className="font-weight-bold">
                                Capital <span className="text-danger">*</span>
                              </label>
                              <select
                                id="capital"
                                name="capital"
                                className="form-control"
                                onChange={(e) => {
                                  handleChange(e);
                                  setCapitalesVariables([]);
                                }}
                                onBlur={handleBlur}
                                value={values.capital}
                                disabled={
                                  esNegocioEnProcesoSinContratoGrupo ||
                                  validarFolioCotizacion ||
                                  (reducer.tipoNegocio === T_NEGOCIO_INACTIVO &&
                                    editar)
                                }
                              >
                                <option value={0}>Selecciona una opción</option>
                                {tiposCapital.map((item, i) => (
                                  <option
                                    key={`tipoCapital_${i}`}
                                    value={item.idCapital}
                                  >
                                    {item.glosa.toUpperCase()}
                                  </option>
                                ))}
                              </select>
                              {errors.capital && touched.capital && (
                                <small className="text-danger animated fadeIn">
                                  {errors.capital}
                                </small>
                              )}
                            </div>
                          </div>

                          {/* 9. valorCapital */}
                          <div className="col-md-6 animated fadeIn">
                            <div className="form-group mb-2">
                              <label className="font-weight-bold">
                                {values.capital === "FIJO UF" ||
                                values.capital === 0
                                  ? "Valor capital"
                                  : "Cantidad de renta"}
                                <span className="text-danger"> *</span>
                              </label>
                              <div className="d-flex">
                                <Field
                                  className="form-control"
                                  id="valorCapital"
                                  name="valorCapital"
                                  type="text"
                                  maxLength="8"
                                  placeholder={`Ingrese ${
                                    values.capital === "FIJO UF" ||
                                    values.capital === 0
                                      ? "valor capital"
                                      : "cantidad de renta"
                                  }`}
                                  value={formatNumberDecimalUF(
                                    values.valorCapital,
                                    VALOR_CINCO
                                  )}
                                  disabled={
                                    esNegocioEnProcesoSinContratoGrupo ||
                                    values.capital === VALOR_ZERO ||
                                    validarFolioCotizacion ||
                                    (reducer.tipoNegocio ===
                                      T_NEGOCIO_INACTIVO &&
                                      editar)
                                  }
                                />
                                {(values.capital === VARIABLE_UF ||
                                  values.capital === VARIABLE_RENTA) && (
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-primary py-0 px-3 ml-2 animated fadeIn"
                                    alt="Agregar valor capital"
                                    onClick={() => {
                                      if (errors.valorCapital) return;

                                      let capitales = capitalesVariables;
                                      capitales.push(
                                        Number(values.valorCapital)
                                      );
                                      let capitalesUnicos = [
                                        ...new Set(capitales),
                                      ];
                                      setFieldValue("valorCapital", "");
                                      setFieldTouched("valorCapital", true);
                                      setCapitalesVariables(capitalesUnicos);
                                    }}
                                  >
                                    <i
                                      className="fa fa-plus"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                )}
                              </div>
                              {errors.valorCapital &&
                                touched.valorCapital &&
                                values.capital !== VALOR_ZERO && (
                                  <small className="text-danger animated fadeIn">
                                    {errors.valorCapital}
                                  </small>
                                )}
                            </div>
                          </div>

                          {/* 10. valores variables */}
                          {(values.capital === VARIABLE_UF ||
                            values.capital === VARIABLE_RENTA) &&
                            capitalesVariables.length > VALOR_ZERO && (
                              <div className="col-md-12 animated fadeIn">
                                <div className="form-group mb-3">
                                  <label className="text-muted d-block mb-2">
                                    Valores capitales variables seleccionados
                                  </label>
                                  {capitalesVariables.map((capital, indice) => {
                                    return (
                                      <div
                                        key={indice}
                                        className="d-inline animated fadeIn ml-1"
                                      >
                                        <div className="d-inline bg-primary text-white py-1 px-2">
                                          {values.capital === VARIABLE_UF
                                            ? `${capital} UF`
                                            : `$ ${capital}`}
                                        </div>
                                        <div
                                          className="d-inline bg-primary-2 text-white py-1 px-2 cursor"
                                          onClick={() => {
                                            const capitales =
                                              capitalesVariables.filter(
                                                (e) => e !== capital
                                              );
                                            setCapitalesVariables(capitales);
                                          }}
                                        >
                                          <i
                                            className="fa fa-times"
                                            aria-hidden="true"
                                          ></i>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                        </Fragment>
                      )}

                      {/* 11. folioCotizacion */}
                      <div className="col-md-6">
                        <div className="form-group mb-2">
                          <label className="font-weight-bold">
                            Fecha cotización{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <DatePicker
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            id="fechaCotizacion"
                            name="fechaCotizacion"
                            autoComplete="off"
                            disabled={
                              esNegocioEnProcesoSinContratoGrupo ||
                              validarFolioCotizacion ||
                              (reducer.tipoNegocio === T_NEGOCIO_INACTIVO &&
                                editar)
                            }
                            value={values.fechaCotizacion}
                            dateFormat="dd/MM/yyyy"
                            placeholderText="DD/MM/YYYY"
                            locale="es"
                            maxDate={new Date()}
                            onChange={(value) => {
                              value = moment(value).format("DD/MM/YYYY");
                              setFieldTouched("fechaCotizacion", true);
                              setFieldValue("fechaCotizacion", value);
                            }}
                            customInput={
                              <MaskedTextInput
                                className="form-control"
                                placeholder="DD/MM/YYYY"
                                mask={MASK_DATE}
                              />
                            }
                          />
                          {errors.fechaCotizacion &&
                            touched.fechaCotizacion && (
                              <small className="text-danger animated fadeIn">
                                {errors.fechaCotizacion}
                              </small>
                            )}
                        </div>
                      </div>

                      {/* 12. fechaInicioVigencia */}
                      <div className="col-md-6">
                        <div className="form-group mb-2">
                          <label className="font-weight-bold">
                            Fecha inicio vigencia{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <DatePicker
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            id="fechaInicioVigencia"
                            name="fechaInicioVigencia"
                            autoComplete="off"
                            value={values.fechaInicioVigencia}
                            dateFormat="dd/MM/yyyy"
                            placeholderText="DD/MM/YYYY"
                            locale="es"
                            disabled={
                              esNegocioEnProcesoSinContratoGrupo ||
                              validarFolioCotizacion ||
                              (reducer.tipoNegocio === T_NEGOCIO_INACTIVO &&
                                editar)
                            }
                            minDate={new Date(fechaPrimeroDelMes)}
                            onChange={(value) => {
                              value = moment(value).format("DD/MM/YYYY");
                              setFieldTouched("fechaInicioVigencia", true);
                              setFieldValue("fechaInicioVigencia", value);
                            }}
                            customInput={
                              <MaskedTextInput
                                className="form-control"
                                placeholder="DD/MM/YYYY"
                                mask={MASK_DATE}
                              />
                            }
                          />
                          {errors.fechaInicioVigencia &&
                            touched.fechaInicioVigencia && (
                              <small className="text-danger animated fadeIn">
                                {errors.fechaInicioVigencia}
                              </small>
                            )}
                        </div>
                      </div>

                      {/* 13. numeroTrabajadores */}
                      <div className="col-md-6">
                        <div className="form-group mb-2">
                          <label className="font-weight-bold">
                            Número de trabajadores ingresados
                            <span className="text-danger"> *</span>
                          </label>
                          <Field
                            className="form-control"
                            autoComplete="off"
                            id="numeroTrabajadores"
                            name="numeroTrabajadores"
                            type="text"
                            maxLength="10"
                            placeholder="Ingrese número de trabajadores"
                            disabled={
                              esNegocioEnProcesoSinContratoGrupo ||
                              (reducer.tipoNegocio === T_NEGOCIO_INACTIVO &&
                                editar)
                            }
                            value={formatNumber(values.numeroTrabajadores)}
                          />
                          {errors.numeroTrabajadores &&
                            touched.numeroTrabajadores && (
                              <small className="text-danger animated fadeIn">
                                {errors.numeroTrabajadores}
                              </small>
                            )}
                        </div>
                      </div>

                      {/* 14. primaNegocio */}
                      <div className="col-md-6">
                        <div className="form-group mb-2">
                          <label className="font-weight-bold">
                            Prima de negocio (UF bruto){" "}
                            <span className="text-danger">*</span>
                          </label>
                          <Field
                            className="form-control"
                            id="primaNegocio"
                            name="primaNegocio"
                            type="text"
                            placeholder="Ingrese prima de negocio"
                            value={formatNumberDecimalUF(
                              values.primaNegocio,
                              VALOR_CINCO
                            )}
                            maxLength="10"
                            disabled={
                              esNegocioEnProcesoSinContratoGrupo ||
                              validarFolioCotizacion ||
                              (reducer.tipoNegocio === T_NEGOCIO_INACTIVO &&
                                editar)
                            }
                          />
                          {errors.primaNegocio && touched.primaNegocio && (
                            <small className="text-danger animated fadeIn">
                              {errors.primaNegocio}
                            </small>
                          )}
                        </div>
                      </div>

                      {/* 15. botonera */}
                      <div className="col-md-12 my-3 text-right">
                        <button
                          id="btnVolver"
                          type="button"
                          className="btn btn-secondary mr-2"
                          onClick={() => {
                            if (values === dataFormularioOriginal)
                              return volver();
                            setModalVolver(true);
                          }}
                        >
                          Volver
                        </button>
                        <button
                          id="btnSubmit"
                          type="submit"
                          className="btn btn-primary"
                          disabled={llamadaHttp}
                        >
                          {llamadaHttp ? (
                            <Fragment>
                              <i className="fa fa-spinner fa-pulse fa-fw"></i>
                              <span className="sr-only">Loading...</span>
                            </Fragment>
                          ) : !editar ? (
                            "Crear"
                          ) : (
                            "Guardar"
                          )}
                        </button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </Fragment>
      )}
    </React.Fragment>
  );
};

export default ConfiguracionProducto;
