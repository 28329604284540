import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ModalEditarAsegurable from "../../ModalEditarAsegurable";
import {
  iniciarEnrolamientoEjecutivo,
  obtenerConfiguracionDelegado,
  obtenerPersonaPorRut,
  actualizarNegocio,
} from "../../../../../../services/negocios.service";
import { getArchivosCotizacion } from "../../../../../../services/cotizacion.services";
import { enviarPerfilDelegado } from "../../../../../../services/auth.services";
import { notificacion } from "../../../../transversales/Notificaciones";
import {
  guardar_negocio,
  guardar_datos_empresa,
  guardar_datos_sucursal,
  guardar_datos_delegado,
  fecha_termino_enrolamiento,
} from "../../../../../../actions/actions";
import {
  ALERTA_INI_ENROLAMIENTO_1,
  ALERTA_INI_ENROLAMIENTO_2,
  ALERTA_INI_ENROLAMIENTO_4,
  ALERTA_INI_ENROLAMIENTO_5,
  ERROR_SOLICITUD,
  INICIO_PROC_ENROLAMIETO,
  EXITO_PROC_ENROLAMIETO,
  VAL_ENROLAMIENTO_WIZARD,
  VAL_ENROLAMIENTO_GESTIO,
  VAL_ENROLAMIENTO_CARTA,
  VAL_ENROLAMIENTO_OK,
  VAL_ENROLAMIENTO_FECHA,
  VAL_ENROLAMIENTO_PRODUCTO,
  SIN_DOCUMENTOS,
  PERFIL_LDAP,
  PLAZO_ENROLAMIENTO,
  ID_UNO,
  ALERTA_INI_ENROLAMIENTO_3_1,
  ALERTA_INI_ENROLAMIENTO_3_2,
  LARGO_CERO_COTIZACION,
} from "../../../../../../utils/Constantes";
import moment from "moment";

class InicioEnrolamientoEjecutivo extends Component {
  state = {
    validacionInicioEnrolamiento: null,
    servidorDelegado: null,
    loadingData: false,
    lstCotizacionSinDocumento: [],
  };

  componentDidMount() {
    if (this.props.modalInicioEnrolamiento) {
      this.obtenerDatosNegocio();
    }
  }

  obtenerDatosNegocio = async () => {
    const {
      datosEmpresa,
      datosSucursal,
      datosDelegado,
    } = this.props.store.reducer.ejecutivo.configuracionNegocio;

    if (
      datosEmpresa === null ||
      datosSucursal === null ||
      datosDelegado === null
    ) {
      this.obtenerDatosEmpresaSucursal();
    } else {
      this.setState({ servidorDelegado: true }, this.validacion);
    }
  };

  validacion = async () => {
    if (
      !this.props.store.ldap.canalVentaBusqueda ||
      this.props.store.ldap.canalVentaBusqueda === ID_UNO
    ) {
      let strArchivosCotizacion = SIN_DOCUMENTOS;
      let blSinDocumentos = false;
      let lstCotizacionesSinDocumentos = [];
      Promise.all(
        await this.props.cotizacionesGestionadas.map(async (objCotizacion) => {
          let objData = {
            metadataDocto: {
              folio: objCotizacion.codigoCotizacion,
            },
          };
          const archivoCotizacion = await getArchivosCotizacion(objData);
          if (archivoCotizacion !== SIN_DOCUMENTOS && !blSinDocumentos) {
            strArchivosCotizacion = archivoCotizacion;
          } else {
            blSinDocumentos = true;
          }

          if (archivoCotizacion === SIN_DOCUMENTOS) {
            lstCotizacionesSinDocumentos = [...lstCotizacionesSinDocumentos, objCotizacion.codigoCotizacion]
          }

        })
      ).then((objValor) => {
        this.setState({ lstCotizacionSinDocumento: lstCotizacionesSinDocumentos })
        if (this.state.servidorDelegado && strArchivosCotizacion !== null) {
          const sinCartaAceptacion =
            strArchivosCotizacion !== SIN_DOCUMENTOS
              ? strArchivosCotizacion.filter((item) =>
                item.nombreDocto.endsWith(".pdf")
              ).length === 0
              : true;
          const {
            datosEmpresa,
            datosSucursal,
            datosDelegado,
          } = this.props.store.reducer.ejecutivo.configuracionNegocio;

          const datos = [datosEmpresa, datosSucursal, datosDelegado];

          const datosIncompletos = datos.map((item) => {
            if (item === null || item === undefined) {
              return true;
            } else {
              return Object.values(item).every(
                (value) =>
                  value === undefined ||
                  value === null ||
                  value === "" ||
                  value === {} ||
                  value === [] ||
                  value === 0
              );
            }
          });

          if (datosIncompletos.includes(true)) {
            this.setState({
              validacionInicioEnrolamiento: VAL_ENROLAMIENTO_WIZARD,
            });
          } else if (this.props.cotizacionesGestionadas.length === LARGO_CERO_COTIZACION) {
            this.setState({
              validacionInicioEnrolamiento: VAL_ENROLAMIENTO_GESTIO,
            });
          } else if (sinCartaAceptacion) {
            this.setState({
              validacionInicioEnrolamiento: VAL_ENROLAMIENTO_CARTA,
            });
          } else {
            this.setState({
              validacionInicioEnrolamiento: VAL_ENROLAMIENTO_OK,
            });
          }
        } else {
          notificacion("danger", ERROR_SOLICITUD);
          this.props.mostrarModalInicioEnrolamiento();
        }
      });
    } else {
      await this.validacionVentaDirecta();
    }
  };

  validacionVentaDirecta = async () => {
    if (!this.props.store.reducer.cotizaciones.length || !this.props.store.reducer.cotizaciones[0].codigoCotizacion) {
      this.setState({
        validacionInicioEnrolamiento: VAL_ENROLAMIENTO_PRODUCTO,
      });
    } else if (!this.props.store.reducer.negocio.fechaTerminoEnrolamiento) {
      this.setState({
        validacionInicioEnrolamiento: VAL_ENROLAMIENTO_FECHA,
      });
    } else {
      this.setState({
        validacionInicioEnrolamiento: VAL_ENROLAMIENTO_OK,
      });
    }
  };

  async obtenerDatosEmpresaSucursal() {
    let sucursal;

    const datosEmpresa = this.props.negocio;

    const negocio = {
      idNegocio: this.props.idNegocio,
      idPersonaDelegado: datosEmpresa.idPersonaDelegado,
      rut: `${datosEmpresa.rutEmpresa}-${datosEmpresa.digitoVerificador}`,
      razonSocial: datosEmpresa.razonSocial,
      giro: datosEmpresa.giro,
    };

    if (datosEmpresa.sucursales.length > 0) {
      sucursal = datosEmpresa.sucursales[0];
      this.setState({ sucursal }, async () => {
        await this.obtenerDatosDelegado();
      });
    } else {
      this.setState({
        validacionInicioEnrolamiento: VAL_ENROLAMIENTO_WIZARD,
      });
    }

    this.props.guardar_datos_empresa(negocio);
    this.props.guardar_datos_sucursal(sucursal);
  }

  async obtenerDatosDelegado() {
    const idSucursal = this.state.sucursal.idOrganizacionSucursal;

    if (idSucursal) {
      let delegado = await obtenerConfiguracionDelegado(
        idSucursal,
        PERFIL_LDAP
      );

      if (delegado !== null) {
        delegado = {
          idOrganizacionSucursal: delegado.idOrganizacionSucursal,
          idOrganizacionSucursalContacto:
            delegado.idOrganizacionSucursalContacto,
          rut: delegado.persona.identificador,
          nombre: delegado.persona.nombre,
          apellidoMaterno: delegado.persona.apellidoMaterno,
          apellidoPaterno: delegado.persona.apellidoPaterno,
          sexo: delegado.persona.genero,
          fechaNacimiento: delegado.persona.fechaNacimiento,
          telefono: delegado.telefono,
          email: delegado.email,
          idPersonaDelegado: delegado.idPersonaDelegado,
        };
        this.setState({ delegado, servidorDelegado: true }, this.validacion);
        await this.props.guardar_datos_delegado(delegado);
      } else {
        this.setState({ servidorDelegado: false }, this.validacion);
      }
    }
  }

  async enviarFechaEnrolamiento() {
    let fecha = new Date();

    //nueva fecha sumada
    fecha.setDate(fecha.getDate() + PLAZO_ENROLAMIENTO);
    fecha = moment(fecha).format("YYYY-MM-DD");

    const dataEnvio = {
      fechaTerminoEnrolamiento: fecha,
      idNegocio: this.props.negocio.idNegocio,
      perfil: PERFIL_LDAP,
    };

    const res = await actualizarNegocio(dataEnvio);

    if (res === 1) {
      fecha = moment(fecha).format("MM/DD/YYYY");
      fecha = new Date(fecha).getTime();
      this.props.fecha_termino_enrolamiento(fecha);
    }
  }

  submit = async () => {
    //Si se ingresa un nuevo contacto delegado, se debe generar la clave
    //Gatillar el envío del mail al Delegado

    this.setState({ loadingData: true });

    const fechaInicioProceso = new Date();
    const copiaFechaInicio = new Date(fechaInicioProceso.getTime());
    const fechaTerminoEnrolamiento = copiaFechaInicio.setDate(
      copiaFechaInicio.getDate() + PLAZO_ENROLAMIENTO
    );

    let cotizaciones;

    if (this.props.cotizacionesGestionadas) {
      cotizaciones = this.props.cotizacionesGestionadas.map((item) => {
        return { idCotizacion: item.idCotizacion };
      });
    } else {
      cotizaciones = this.props.store.reducer.cotizaciones.map((item) => {
        return { idCotizacion: item.idCotizacion };
      });
    }

    const { delegadoNegocio } = this.props.store.reducer;

    const dataPerfil = {
      perfilDto: [
        {
          codAplicacion: Number(process.env.REACT_APP_COD_SITIO),
          codPerfil: Number(process.env.REACT_APP_COD_DELEGADO),
        },
      ],
      usuario: {
        apellidoMaterno: delegadoNegocio.apellidoMaterno,
        apellidoPaterno: delegadoNegocio.apellidoPaterno,
        email: delegadoNegocio.email,
        nombre: delegadoNegocio.nombre,
        rut: delegadoNegocio.rutDelegado,
      },
    };

    let idPersonaDelegado = await obtenerPersonaPorRut(
      delegadoNegocio.rutDelegado,
      PERFIL_LDAP
    );

    const perfilDelegado = await enviarPerfilDelegado(dataPerfil);

    if (perfilDelegado === null) {
      notificacion("danger", ERROR_SOLICITUD);
      this.props.mostrarModalInicioEnrolamiento();
    } else {
      let data = {
        cotizaciones,
        emailDelegado: delegadoNegocio.email,
        nombreDelegado: delegadoNegocio.nombre,
        apellidoPaternoDelegado: delegadoNegocio.apellidoPaterno,
        fechaInicioProceso,
        idNegocio: this.props.negocio.idNegocio,
        idPersonaDelegado: idPersonaDelegado.idPersona,
      };

      if (this.props.store.reducer.negocio.canalVenta.idCanalVenta === ID_UNO) {
        data = {
          ...data,
          fechaTerminoEnrolamiento,
        }
      }

      const iniciarEnrolamiento = await iniciarEnrolamientoEjecutivo(data);
      if (iniciarEnrolamiento === null) {
        notificacion("danger", ERROR_SOLICITUD);
      } else {
        const negocio = {
          ...this.props.store.reducer.negocio,
          estadoNegocio: {
            ...this.props.store.reducer.negocio.estadoNegocio,
            idEstadoNegocio: 3,
          },
        };
        if (this.props.store.reducer.negocio.canalVenta.idCanalVenta === ID_UNO) {
          this.enviarFechaEnrolamiento();
        }
        this.props.guardar_negocio(negocio);
        notificacion("success", EXITO_PROC_ENROLAMIETO);
      }
      setTimeout(() => {
        this.props.mostrarModalInicioEnrolamiento(false, true);
      }, 300);
    }

    this.setState({ loadingData: false });
  };

  render() {
    const validacion = this.state.validacionInicioEnrolamiento;
    let mensaje, showSubmitButton;
    if (validacion === VAL_ENROLAMIENTO_WIZARD) {
      mensaje = ALERTA_INI_ENROLAMIENTO_1;
      showSubmitButton = false;
    } else if (validacion === VAL_ENROLAMIENTO_GESTIO) {
      mensaje = ALERTA_INI_ENROLAMIENTO_2;
      showSubmitButton = false;
    } else if (validacion === VAL_ENROLAMIENTO_CARTA) {
      mensaje = ALERTA_INI_ENROLAMIENTO_3_1 + this.state.lstCotizacionSinDocumento + ALERTA_INI_ENROLAMIENTO_3_2;
      showSubmitButton = false;
    } else if (validacion === VAL_ENROLAMIENTO_PRODUCTO) {
      mensaje = ALERTA_INI_ENROLAMIENTO_5;
      showSubmitButton = false;
    } else if (validacion === VAL_ENROLAMIENTO_FECHA) {
      mensaje = ALERTA_INI_ENROLAMIENTO_4;
      showSubmitButton = false;
    } else if (validacion === VAL_ENROLAMIENTO_OK) {
      mensaje = INICIO_PROC_ENROLAMIETO;
      showSubmitButton = true;
    }

    return (
      <ModalEditarAsegurable
        idSubmit="submitInicioEnrolamientoEjecutivo"
        type="small"
        show={this.state.validacionInicioEnrolamiento && true}
        title="Inicio de Enrolamiento"
        action="Iniciar"
        onSubmit={this.submit}
        onCancel={() => {
          this.props.mostrarModalInicioEnrolamiento();
        }}
        onClose={() => {
          this.props.mostrarModalInicioEnrolamiento();
        }}
        showCancelButton={true}
        showCloseButton={true}
        showLeftColumn={false}
        showSubmitButton={showSubmitButton}
        loadingData={this.state.loadingData}
      >
        <div className="row">
          <h5 className="col-12 col-md-8 offset-md-2">{mensaje}</h5>
        </div>
      </ModalEditarAsegurable>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state,
});

export default withRouter(
  connect(mapStateToProps, {
    guardar_negocio,
    guardar_datos_empresa,
    guardar_datos_sucursal,
    guardar_datos_delegado,
    fecha_termino_enrolamiento,
  })(InicioEnrolamientoEjecutivo)
);
