import React, { Component, Fragment } from "react";
import {
  listarDetallePorRut,
  crearUsuarioRevisor,
  actualizarUsuarioRevisor,
} from "../../../services/bus.poliza.services";
import Deslizador from "../../components/transversales/Deslizador";
import moment from "moment";
import { Dropdown, Badge, DropdownButton } from "react-bootstrap";
import {
  CODIGO_ESTATUS_ERROR,
  COD_CORREDOR,
  COD_DELEGADO,
  COD_EJECUTIVO_COMPLETO,
  COD_EJECUTIVO_MANTENCION,
  FECHA_HOY,
  GA_CATEGORIA_NEGOCIOS,
  GA_LABEL_RESUMEN_SOLICITUDES,
  NEGOCIO_USUARIO_REVISOR_ID_INICIAL,
  NOTIFICACION_INTENTELO_MAS_TARDE,
  NOTIFICACION_PELIGRO,
  PERFIL_CORREDOR,
  PERFIL_DELEGADO_MANTENCION,
  POSICION_INICIAL,
  RUTA_EJECUTIVO_VER_SOLICITUDES,
  RUTA_VER_SOLICITUDES,
  RUTA_VER_SOLICITUDES_CORREDOR,
} from "../../../utils/Constantes";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import {
  guardar_negocios,
  guardar_ejecutivos,
  guardar_menu_ejecutivo_completo,
  guardar_filtro_solicitudes,
  limpiar_filtro_solicitudes,
} from "../../../actions/actions";
import NoData from "./NoData";
import { NegociosCuadriculados } from "./NegociosCuadriculados";
import { ListaNegocio } from "./ListaNegocio";
import { notificacion } from "./Notificaciones";
import { buscarPorRut } from "../../../services/bus.empresa.services";
import { EjecutarSeleccionIncorporacion } from "../autenticacion/Incorporacion";
import { crearEventoTraza } from "../../../utils/analitica";
const modoCuadriculaPng = require("../../assets/images/modocuadricula.png");
const modoListaPng = require("../../assets/images/modolista.png");
const avatarInicioAmarillo = require("../../assets/images/avatarcardinicioamarillo.png");
const avatarInicioNegro = require("../../assets/images/avatarcardinicionegro.png");
const tickNegocioRevisado = require("../../assets/images/tickNegocioRevisado.png");

class Inicio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buscando: false,
      cargando: false,
      dropdown: FECHA_HOY,
      fechaInicio: moment().format("YYYY-MM-DD"),
      fechaTermino: moment().format("YYYY-MM-DD"),
      filtros: [],
      mostrarFiltros: false,
      negocios: [],
      negociosRevisor: [],
      negociosFiltros: [],
      negociosBusqueda: [],
      solicitudes: [],
      modoCuadricula: true,
      negocioRevisado: false,
    };
  }
  async componentWillMount() {
    this.obtenerNegocios(this.state.fechaInicio);
  }

  componentDidMount() {
    crearEventoTraza(GA_CATEGORIA_NEGOCIOS, GA_LABEL_RESUMEN_SOLICITUDES);
  }

  obtenerNegocios = async (fechaBusqueda) => {
    const role = this.props.store.auth.usuarioSesion.rol.codigo;
    let codigoCorredor;
    if (role === COD_CORREDOR) {
      let corredor = await buscarPorRut(
        this.props.store.auth.usuarioSesion.rut
      );
      codigoCorredor = corredor[0].idOrganizacion;
    }
    let data = {
      fechaInicio: fechaBusqueda,
      rutEjecutivo:
        (role === COD_EJECUTIVO_MANTENCION || role === COD_EJECUTIVO_COMPLETO)
          ? this.props.store.auth.usuarioSesion.rut
          : null,
      rutDelegado:
        role === COD_DELEGADO
          ? this.props.store.auth.usuarioSesion.rut
          : null,
      usuCodCorredor: role === COD_CORREDOR ? codigoCorredor : null,
      codUsuario: this.props.store.auth.usuarioSesion.id,
    };
    let negociosData = await listarDetallePorRut(data);
    if (negociosData === CODIGO_ESTATUS_ERROR) {
      notificacion(
        NOTIFICACION_PELIGRO,
        NOTIFICACION_INTENTELO_MAS_TARDE
      );
      this.setState({
        negocios: [],
      });
    } else if (negociosData) {
      this.setState({
        negocios: negociosData,
      });
      this.filtrarSolicitudes(negociosData);
    }
  };
  filtrarSolicitudes(negocio) {
    let solicitudesData = [];
    negocio.forEach((n) => {
      solicitudesData.push({
        cantidadSolicitud: n.cantidadSolicitud,
        cantidadSolcitudCreada: n.cantidadSolcitudCreada,
        cantidadSolcitudEnIngreso: n.cantidadSolicitudIngreso,
        cantidadSolicitudEnrolada: n.cantidadSolicitudEnrolada,
        cantidadSolicitudProcesada: n.cantidadSolicitudProcesada,
        cantidadSolicitudRechazada: n.cantidadSolicitudRechazada,
        cantidadSolicitudFinalizada: n.cantidadSolicitudFinalizada
      });
    });
    this.setState({ solicitudes: solicitudesData });
  }
  busquedaReactivaNegocios(bsq) {
    if (bsq.target.value.trim() === "") {
      this.setState({ buscando: false });
    } else {
      let negocios = Object.assign([], this.state.negocios);
      let busqueda = bsq.target.value.toLowerCase();
      let data = negocios.filter((e) => {
        let rutEmpresa = String(e.rutEmpresa);
        let razonSocial = e.razonSocial.toLowerCase().replace(/' '/g, "");
        return (
          razonSocial.indexOf(busqueda) > -1 ||
          rutEmpresa.indexOf(busqueda.replace(/[.-]/g, "").replace(/k$/, "")) >
          -1
        );
      });
      this.handleSearch(data, "busqueda");
    }
  }
  handleSearch = (negocios, tipo) => {
    this.setState({ buscando: true });
    if (tipo === "busqueda") {
      this.setState({ negociosBusqueda: negocios });
    }
  };
  handleSelect = async (e) => {
    switch (e) {
      case "1":
        this.setState({
          dropdown: "Hoy",
          fechaInicio: moment().format("YYYY-MM-DD"),
        });
        this.filtrarNegociosPorFecha(moment().format("YYYY-MM-DD"));
        break;
      case "2":
        this.setState({
          dropdown: "Semana",
          fechaInicio: moment().subtract(1, "week").format("YYYY-MM-DD"),
        });
        this.filtrarNegociosPorFecha(
          moment().subtract(1, "week").format("YYYY-MM-DD")
        );
        break;
      case "3":
        this.setState({
          dropdown: "Últimos 30 días",
          fechaInicio: moment().subtract(1, "months").format("YYYY-MM-DD"),
        });
        this.filtrarNegociosPorFecha(
          moment().subtract(1, "months").format("YYYY-MM-DD")
        );
        break;
      default:
        return null;
    }
  };
  filtrarNegociosPorFecha = async (fechaInicio) => {
    this.obtenerNegocios(fechaInicio);
  };

  verDetalle = async (negocio) => {
    const codUsuario = this.props.store.auth.usuarioSesion.id;

    if (negocio.negocioUsuarioRevisor[POSICION_INICIAL].idNegocioUsuarioRevisor > NEGOCIO_USUARIO_REVISOR_ID_INICIAL) {
      this.actualizaUsuarioRevisor(negocio, codUsuario, true);
    } else {
      this.creaUsuarioRevisor(negocio, codUsuario);
    }

    const { usuarioSesion } = this.props.store.auth;
    const rutContratante = `${negocio.rutEmpresa}-${negocio.digitoVerificador}`;
    let filtroSolicitudes = {
      idNegocio: negocio.idNegocio,
      rutContratante,
      FechaModificacionSolicitud: new Date(this.state.fechaInicio),
      idMovimientoSeleccionado: 1,
    };

    await this.props.guardar_filtro_solicitudes(filtroSolicitudes);
    usuarioSesion.rol.perfil === PERFIL_DELEGADO_MANTENCION
      ? this.props.history.push(RUTA_VER_SOLICITUDES)
      : usuarioSesion.rol.perfil === PERFIL_CORREDOR
        ? this.props.history.push(RUTA_VER_SOLICITUDES_CORREDOR)
        : this.props.history.push(RUTA_EJECUTIVO_VER_SOLICITUDES);
  };

  creaUsuarioRevisor = async (negocio, codUsuario) => {
    const dataEnvio = {
      idNegocio: negocio.idNegocio,
      codUsuario,
      revisado: true,
      usuarioCrea: codUsuario,
      vigente: true,
    };
    await crearUsuarioRevisor(dataEnvio);
  };

  actualizaUsuarioRevisor = async (negocio, codUsuario, nuevoEstado) => {
    const dataEnvio = {
      idNegocio: negocio.idNegocio,
      codUsuario,
      revisado: nuevoEstado,
    };
    await actualizarUsuarioRevisor(dataEnvio);
  };

  render() {
    const {escogerIncorporacion} = this.props.store.auth;
    return (
      <Fragment>
        <div className="animated fadeIn">
          <div className="row">
            <div className="col-md-7 mb-3 text-muted">
              <h4 className="font-weight-bold">Listado de notificaciones</h4>
            </div>
            <div className="col-md-5 mb-3 align-self-center">
              {escogerIncorporacion && <EjecutarSeleccionIncorporacion />}
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-sm-6 col-md-5 col-xl-4 mb-3 text-muted">
              <Deslizador
                solicitudes={this.state.solicitudes}
                periodo={this.state.dropdown}
              />
            </div>
            <div className="col-6 col-sm-3 col-md-4 col-xl-4 mb-3 text-muted align-self-center">
              <input
                className="search-input w-100"
                type="text"
                id="bsq"
                onChange={(e) => {
                  this.busquedaReactivaNegocios(e);
                }}
                placeholder="Buscar..."
              />
              <div className="text-left mb-2 ">
                {this.state.filtros.map((f, i) => (
                  <h5 key={i} className="d-inline animated fadeIn">
                    <Badge variant="secondary font-weight-light text-white mx-1">
                      <span className="text-capitalize">
                        {f.tipo.charAt(0).toUpperCase() +
                          f.tipo.slice(1) +
                          ": "}{" "}
                        {f.filtro}
                      </span>
                      <i
                        className="fa fa-times ml-1 cursor"
                        aria-hidden="true"
                        onClick={() => this.quitarFiltroSeleccionado(f)}
                      ></i>
                    </Badge>
                  </h5>
                ))}
              </div>
            </div>
            <div className="col-6 col-sm-3 col-md-3 col-xl-4 mb-3 text-muted align-self-center">
              <DropdownButton
                onSelect={this.handleSelect}
                variant="dark"
                title={this.state.dropdown}
                className="btnNotificaciones"
                id="DropDownDate"
              >
                <Dropdown.Item eventKey="1" className="w-100">
                  Hoy
                </Dropdown.Item>

                <Dropdown.Item eventKey="2" className="w-100">
                  Semana
                </Dropdown.Item>
                <Dropdown.Item eventKey="3" className="w-100">
                  Últimos 30 días
                </Dropdown.Item>
              </DropdownButton>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 mb-3 text-muted d-none">
              <i
                className="fa fa-file-excel-o exportarExcelAsegurable"
                style={{ color: "orange", fontSize: "20px", marginTop: 10 }}
                aria-hidden="true"
              >
                {"  "}
                <span
                  style={{
                    color: "black important",
                    fontSize: "20px",
                    marginTop: 10,
                  }}
                >
                  {" "}
                  Descargar reporte{" "}
                </span>
              </i>
            </div>
            <div className="col-12 col-md-4 offset-md-8 mb-5 text-muted">
              <div
                className="row "
                style={{ position: "absolute", right: "27px" }}
              >
                <h6 className="font-weight-bold align-self-center">
                  Tipo de vista
                </h6>

                <img
                  src={modoCuadriculaPng}
                  className="d-block mx-1 mb-2"
                  alt="No hay resultados"
                  height="23px"
                  style={{ cursor: "pointer" }}
                  onClick={() => this.setState({ modoCuadricula: true })}
                />

                <img
                  src={modoListaPng}
                  className="d-block mx-1 mb-2"
                  alt="No hay resultados"
                  height="23px"
                  style={{ cursor: "pointer" }}
                  onClick={() => this.setState({ modoCuadricula: false })}
                />
              </div>
            </div>
          </div>
        </div>
        {/* BODY INICIO */}
        <div className="row animated fadeIn">
          {this.state.buscando ? (
            this.state.negociosBusqueda.length > 0 ? (
              this.state.negociosBusqueda.map((e) => {
                return this.state.modoCuadricula ? (
                  <NegociosCuadriculados
                    negocio={e}
                    verDetalle={this.verDetalle}
                    tickNegocioRevisado={tickNegocioRevisado}
                    avatarInicioAmarillo={avatarInicioAmarillo}
                    avatarInicioNegro={avatarInicioNegro}
                    razonSocial={e.razonSocial}
                    negocioRevisado={this.state.negocioRevisado}
                    key={e.idNegocio}
                  />
                ) : (
                  <ListaNegocio
                    negocio={e}
                    verDetalle={this.verDetalle}
                    tickNegocioRevisado={tickNegocioRevisado}
                    avatarInicioAmarillo={avatarInicioAmarillo}
                    avatarInicioNegro={avatarInicioNegro}
                    razonSocial={e.razonSocial}
                    negocioRevisado={this.state.negocioRevisado}
                    key={e.idNegocio}
                  />
                );
              })
            ) : (
              <NoData titulo={"No se han encontrado negocios"} />
            )
          ) : this.state.negocios.length > 0 ? (
            this.state.negocios.map((e) => {
              return this.state.modoCuadricula ? (
                <NegociosCuadriculados
                  negocio={e}
                  verDetalle={this.verDetalle}
                  tickNegocioRevisado={tickNegocioRevisado}
                  avatarInicioAmarillo={avatarInicioAmarillo}
                  avatarInicioNegro={avatarInicioNegro}
                  razonSocial={e.razonSocial}
                  negocioRevisado={this.state.negocioRevisado}
                  key={e.idNegocio}
                />
              ) : (
                <ListaNegocio
                  negocio={e}
                  verDetalle={this.verDetalle}
                  tickNegocioRevisado={tickNegocioRevisado}
                  avatarInicioAmarillo={avatarInicioAmarillo}
                  avatarInicioNegro={avatarInicioNegro}
                  razonSocial={e.razonSocial}
                  negocioRevisado={this.state.negocioRevisado}
                  key={e.idNegocio}
                />
              );
            })
          ) : (
            <NoData titulo={"No hay negocios disponibles"} />
          )}
        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  store: state,
});
export default withRouter(
  connect(mapStateToProps, {
    guardar_negocios,
    guardar_ejecutivos,
    guardar_menu_ejecutivo_completo,
    guardar_filtro_solicitudes,
    limpiar_filtro_solicitudes,
  })(Inicio)
);
