import { ALMACENAMIENTO_LOCAL, ALMACENAMIENTO_SESION } from "../utils/Constantes";
import { ejecutarEncriptacion, ejecutarDesencriptacion } from "./encriptacion";

export const guardarEnNavegador = (strNombre, objData, strAlamcenamiento) => {

    let strData = ejecutarEncriptacion(objData);

    if (strAlamcenamiento === ALMACENAMIENTO_SESION) {
        sessionStorage.setItem(strNombre, JSON.stringify(strData));
    }
    if (strAlamcenamiento === ALMACENAMIENTO_LOCAL) {
        localStorage.setItem(strNombre, JSON.stringify(strData));
    }
}

export const buscarEnNavegador = (strNombre, strAlamcenamiento) => {

    let strData;
    let objData = null;

    if (strAlamcenamiento === ALMACENAMIENTO_SESION && sessionStorage.getItem(strNombre)) {
        strData = sessionStorage.getItem(strNombre);
    }
    if (strAlamcenamiento === ALMACENAMIENTO_LOCAL && localStorage.getItem(strNombre)) {
        strData = localStorage.getItem(strNombre);
    }

    if(strData){
        objData = ejecutarDesencriptacion(strData);
    }
    return objData;
}

export const eliminarEnNavegador = ({strNombre, strAlamcenamiento}) => {

    if (strAlamcenamiento === ALMACENAMIENTO_SESION && sessionStorage.getItem(strNombre)) {
        sessionStorage.removeItem(strNombre);
    }
    if (strAlamcenamiento === ALMACENAMIENTO_LOCAL && localStorage.getItem(strNombre)) {
        localStorage.removeItem(strNombre);
    }
}

export const eliminarTodoEnNavegador = () => {

    sessionStorage.clear();
    localStorage.clear();
}