import React from "react";
import moment from "moment";
import imgNegAsegurable from "../../../../assets/images/negocio-asegurable.png";

const { format } = require("rut.js");

const CabeceraNegocioDelegadoVD = (props) => {
  const { datosContacto } = props;

  return (
    <div className="card shadow mb-3 py-4">
      <div className="row">
        <div className="col-md-3 text-center">
          <img width="85" height="85" src={imgNegAsegurable} alt="" />
        </div>
        <div className="col-md-9">
          <div className="row">
            <div className="col-md-5">
              <span
                className="font-weight-bold mb-2 d-block h6"
                style={{ color: "#e89b03" }}
              >
                Datos de empresa
              </span>
              <span className="font-weight-bold text-muted mr-1">
                Razón social:
              </span>
              {props.negocio.razonSocial} <br />
              <span className="font-weight-bold text-muted mr-1">RUT:</span>
              {format(
                `${props.negocio.rutEmpresa}-${props.negocio.digitoVerificador}`
              )}{" "}
              <br />
              <span className="font-weight-bold text-muted mr-1">
                Periodo Enrolamiento:
              </span>
              {moment(props.negocio.fechaTerminoEnrolamiento).format(
                "DD/MM/YYYY"
              )}
              <br />
            </div>

            <div
              className="col-md-1 "
              style={{ borderLeft: "1px solid #e89b03" }}
            ></div>

            <div className="col-md-6">
              <span
                className="font-weight-bold mb-2 d-block h6"
                style={{ color: "#e89b03" }}
              >
                Datos de contacto
              </span>
              <span className="font-weight-bold text-muted mr-1">Nombre:</span>
              {datosContacto.nombre +
                " " +
                datosContacto.apellidoPaterno +
                " " +
                datosContacto.apellidoMaterno}
              <br />
              <span className="font-weight-bold text-muted mr-1">
                Telefono:
              </span>
              {datosContacto.telefono} <br />
              <span className="font-weight-bold text-muted mr-1">Email:</span>
              {datosContacto.email}
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CabeceraNegocioDelegadoVD;
