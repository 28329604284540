import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  COD_CORREDOR,
  LARGO_CERO_COTIZACION,
  LARGO_CERO_SUCURSALES,
  LARGO_UNO_COTIZACION,
  PERFIL_LDAP,
  RUTA_COTIZACION_CORREDOR_ESTADO_ENROLAMIENTO,
  RUTA_NEGOCIOS,
  SECCION_INGRESAR_ASEGURABLES,
  SECCION_VER_ASEGURABLES,
  SECCION_VER_INDICADORES,
  STORAGE_ITEM_FILTRO_CANAL_VENTA,
  STORAGE_ITEM_NEGOCIO,
  STORAGE_ITEM_RUTA,
  TIPO_CANAL_VENTA_DIGITAL,
  T_GENERADAS,
  T_GESTIONADAS,
  VALOR_UNO,
  VALOR_ZERO,
} from "../../../../../../utils/Constantes";
import CabeceraNegocioDelegado from "../../../delegado/infoEnrolamiento/CabeceraNegocioDelegado";
import CabeceraNegocioDelegadoVD from "../../../delegado/infoEnrolamiento/CabeceraNegocioDelegadoVD";
import { Fragment } from "react";
import MisProcesos from "../../../delegado/misProcesos/MisProcesos";
import IngresarAsegurables from "../../../delegado/ingresarAsegurables/IngresarAsegurables";
import InfoEnrolamiento from "../../../delegado/infoEnrolamiento/InfoEnrolamiento";
import { obtenerCotizacionesNegocio } from "../../../../../../services/cotizacion.services";
import { guardar_canal_venta_busqueda } from "../../../../../../reducer/ldap";
import {
  guardar_cotizaciones,
  guardar_negocio,
  guardar_datos_delegado_negocio,
} from "../../../../../../actions/actions";
import { obtenerConfiguracionDelegado } from "../../../../../../services/negocios.service";
import Spinner from "../../../../transversales/Spinner";
import { EjecutarSeleccionIncorporacion } from "../../../../autenticacion/Incorporacion";

class EstadoEnrolamientoCorredor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      intCanalVenta: this.props.store.reducer.negocio.canalVenta.idCanalVenta,
      strTipoNegocio: this.props.store.reducer.tipoNegocio,
      objDelegadoNegocio: this.props.store.reducer.delegadoNegocio,
      blnCargando: true,
      objNegocio: null,
      objUltimaCotizacion: null,
      dtFechaTerminoEnrolamiento: null,
      lstCotizaciones: [],
      lstCotizacionesBusqueda: [],
      strTipoCotizacion: T_GESTIONADAS,
      blnMostrarDetalleCotizacion: false,
      strSeccionEnrolamiento: SECCION_VER_INDICADORES,
    };
    sessionStorage.setItem(
      STORAGE_ITEM_RUTA,
      RUTA_COTIZACION_CORREDOR_ESTADO_ENROLAMIENTO
    );
    if (sessionStorage.getItem(STORAGE_ITEM_NEGOCIO)) {
      this.idNegocio = sessionStorage.getItem(STORAGE_ITEM_NEGOCIO);
    } else if (this.props.location.state) {
      this.idNegocio = this.props.location.state.idNegocio;
      if (this.idNegocio) {
        sessionStorage.setItem(STORAGE_ITEM_NEGOCIO, this.idNegocio);
      }
    } else {
      sessionStorage.removeItem(STORAGE_ITEM_NEGOCIO);
      return this.props.history.push(RUTA_NEGOCIOS);
    }
  }

  componentDidMount() {
    const intIdNegocio = sessionStorage.getItem(STORAGE_ITEM_NEGOCIO);
    this.obtenerDetalleNegocio(intIdNegocio);

    // Obtener canal venta storage
    const intCanalVentaBusqueda = Number(sessionStorage.getItem(STORAGE_ITEM_FILTRO_CANAL_VENTA));
    if (intCanalVentaBusqueda) {
      this.props.guardar_canal_venta_busqueda(intCanalVentaBusqueda);
    }

    this.setState({
      strTipoNegocio: sessionStorage.getItem("_nTnVC")
        ? sessionStorage.getItem("_nTnVC")
        : this.props.store.reducer.tipoNegocio,
    });
  }

  configuracionDelegado = async () => {
    let objUltimaCotizacion = this.props.ultimaCotizacion;
    if (
      objUltimaCotizacion === null &&
      objUltimaCotizacion.length === VALOR_ZERO
    )
      objUltimaCotizacion = null;
    if (this.props.store.reducer.negocio.sucursales[LARGO_CERO_SUCURSALES]) {
      const intIdSucursal =
        this.props.store.reducer.negocio.sucursales[LARGO_CERO_SUCURSALES]
          .idOrganizacionSucursal;
      const { usuarioSesion } = this.props.store.auth;
      let strPerfil =
        usuarioSesion.rol.codigo === COD_CORREDOR
          ? usuarioSesion.rol.perfil
          : PERFIL_LDAP;
      let objDelegado = await obtenerConfiguracionDelegado(
        intIdSucursal,
        strPerfil
      );

      if (objDelegado && objDelegado.persona) {
        let objUltimaCotizacion = Object.assign(
          {},
          this.state.objUltimaCotizacion
        );
        objUltimaCotizacion.nombre = objDelegado.persona.nombre;
        objUltimaCotizacion.apellidoMaterno =
          objDelegado.persona.apellidoMaterno;
        objUltimaCotizacion.apellidoPaterno =
          objDelegado.persona.apellidoPaterno;
        objUltimaCotizacion.telefono = objDelegado.telefono
          ? objDelegado.telefono
          : objUltimaCotizacion.telefono;
        objUltimaCotizacion.email = objDelegado.email
          ? objDelegado.email
          : objUltimaCotizacion.email;
        objUltimaCotizacion.rutDelegado = objDelegado.persona.identificador;
        this.setState({
          objUltimaCotizacion,
          blnCargando: false,
          objDelegadoNegocio: objUltimaCotizacion,
        });
        this.props.guardar_datos_delegado_negocio(objUltimaCotizacion);
      } else {
        this.setState({
          objUltimaCotizacion,
          blnCargando: false,
        });
        this.props.guardar_datos_delegado_negocio(objUltimaCotizacion);
      }
    } else {
      this.setState({
        objUltimaCotizacion,
        blnCargando: false,
      });
      this.props.guardar_datos_delegado_negocio(objUltimaCotizacion);
    }
  };

  obtenerDetalleNegocio = async (idNegocio) => {
    this.setState({ blnCargando: true });
    const { usuarioSesion } = this.props.store.auth;
    let strPerfil =
      usuarioSesion.rol.codigo === COD_CORREDOR
        ? usuarioSesion.rol.perfil
        : PERFIL_LDAP;
    const objDataEnvio = {
      idNegocio: Number(idNegocio),
      perfil: strPerfil,
    };
    const resNegocio = await obtenerCotizacionesNegocio(objDataEnvio);

    if (resNegocio !== null) {
      // Data negocio y cofiguracion negocio
      const objNegocio = Object.assign({}, resNegocio);
      delete objNegocio.cotizaciones;
      this.props.guardar_negocio(objNegocio);
      this.setState(
        {
          objNegocio,
          objUltimaCotizacion: objNegocio.ultimaCotizacion
            ? objNegocio.ultimaCotizacion
            : [],
          dtFechaTerminoEnrolamiento: objNegocio.fechaTerminoEnrolamiento,
        },
        () => {
          this.setState({ blnCargando: false });
        }
      );
      // Cotizaciones
      const lstCotizaciones = resNegocio.cotizaciones;
      if (
        lstCotizaciones !== null &&
        Object.keys(lstCotizaciones).length !== VALOR_ZERO
      ) {
        let strTipoCotizacion;

        if (resNegocio.canalVenta.idCanalVenta === VALOR_UNO)
          strTipoCotizacion =
            lstCotizaciones.gestionadas.length > LARGO_CERO_COTIZACION
              ? T_GESTIONADAS
              : T_GENERADAS;

        this.setState({
          lstCotizaciones,
          lstCotizacionesBusqueda: lstCotizaciones,
          strTipoCotizacion,
        });
        this.props.guardar_cotizaciones(lstCotizaciones);
      } else return;

      if (lstCotizaciones.length === LARGO_UNO_COTIZACION)
        this.setState({ blnMostrarDetalleCotizacion: true });
    }
    this.setState({ blnCargando: false });
    this.configuracionDelegado();
  };

  cambioSeccionEnrolamiento = async (strSeccionEnrolamiento) => {
    this.setState({
      strSeccionEnrolamiento,
    });
  };

  volverListaNegocios = () => {
    sessionStorage.removeItem("_nTiVC");
    sessionStorage.removeItem("_nRuVC");
    sessionStorage.removeItem("_nNegVC");
    this.props.history.push({
      pathname: "/negocios",
    });
  };

  render() {
    let { escogerIncorporacion } = this.props.store.auth;
    return (
      <React.Fragment>
        {this.state.objNegocio &&
        this.state.intCanalVenta &&
        this.state.objDelegadoNegocio &&
        !this.state.blnCargando ? (
          <div className="row animated fadeIn">
            <div className="col-md-7 mb-3 text-muted">
              <h4 className="font-weight-bold mb-0">Estado de enrolamiento</h4>
            </div>
            {escogerIncorporacion && <EjecutarSeleccionIncorporacion />}
            <div className="col-md-12 mb-3 align-self-center">
              {/* VOLVER ATRÁS */}
              <button
                id="volverDetalleNegocio"
                onClick={() => {
                  this.volverListaNegocios();
                }}
                className="btn btn-principal btn-sm d-block ml-auto"
              >
                <i className="fa fa-chevron-left mr-2" aria-hidden="true"></i>
                Volver a la lista de negocios
              </button>
            </div>

            {/* CABECERA INFO EMPRESA */}
            {this.state.intCanalVenta === TIPO_CANAL_VENTA_DIGITAL ? (
              <div className="col-12 mb-3">
                <CabeceraNegocioDelegado
                  negocio={this.state.objNegocio}
                  cotizacion={this.props.store.reducer.cotizaciones.gestionadas}
                />
              </div>
            ) : (
              <div className="col-12 mb-3">
                <CabeceraNegocioDelegadoVD
                  datosContacto={this.state.objDelegadoNegocio}
                  negocio={this.state.objNegocio}
                />
              </div>
            )}

            {/* INFO ENROLAMIENTO */}

            {this.state.strSeccionEnrolamiento === SECCION_VER_ASEGURABLES && (
              <Fragment>
                <div className="col-md-12 button-zone text-right pb-4">
                  <button
                    id="botonIndicadores"
                    className="btn btn-principal btn-sm"
                    type="button"
                    onClick={() => {
                      this.setState({
                        strSeccionEnrolamiento: SECCION_VER_INDICADORES,
                      });
                    }}
                  >
                    <i className="fa fa-pie-chart mr-1" aria-hidden="true"></i>{" "}
                    <span>Ver indicadores</span>
                  </button>
                </div>

                <div className="col-12 mb-3">
                  <div className="card shadow my-3">
                    <div className="row">
                      <div className="col-12">
                        <h4
                          className="ml-4 mt-4 mb-3"
                          style={{ color: "#e89b03", fontWeight: 650 }}
                        >
                          Lista de Asegurables
                        </h4>
                      </div>
                    </div>

                    <MisProcesos
                      setSeccionEnrolamiento={this.cambioSeccionEnrolamiento}
                    />
                  </div>
                </div>
              </Fragment>
            )}

            {this.state.strSeccionEnrolamiento ===
              SECCION_INGRESAR_ASEGURABLES && (
              <Fragment>
                <div className="col-10 offset-sm-1">
                  <span
                    className="font-weight-bold mt-3 ml-3 puntero-general float-right text-muted"
                    onClick={() => {
                      this.setState({
                        strSeccionEnrolamiento: SECCION_VER_ASEGURABLES,
                      });
                    }}
                  >
                    {
                      <i
                        className="fa fa-chevron-left mr-1"
                        aria-hidden="true"
                      ></i>
                    }{" "}
                    Volver al listado de asegurables
                  </span>
                </div>
                <div className="col-12">
                  <IngresarAsegurables />
                </div>
              </Fragment>
            )}

            {this.state.strSeccionEnrolamiento === SECCION_VER_INDICADORES && (
              <Fragment>
                {/* INDICADORES */}
                <div className="col-md-12">
                  <InfoEnrolamiento
                    setSeccionEnrolamiento={this.cambioSeccionEnrolamiento}
                  />
                </div>
              </Fragment>
            )}
          </div>
        ) : (
          <Spinner
            claseColor="transparente"
            idSpinner="spinnerTransparente"
            posicionLogo="posicionLogo align-self-center w-100"
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state,
});

export default withRouter(
  connect(mapStateToProps, {
    guardar_canal_venta_busqueda,
    guardar_negocio,
    guardar_cotizaciones,
    guardar_datos_delegado_negocio,
  })(EstadoEnrolamientoCorredor)
);
