// AUTHENTICATION
export const SESION_USUARIO = "SESION_USUARIO";
export const USER_LOGOUT = "USER_LOGOUT";

export const DATA_ASEGURABLE = "DATA_ASEGURABLE";
export const ADD_NEGOCIO_ASEGURABLE = "GET_NEGOCIOS_ASEGURABLE";

// TABLAS MAESTRAS
export const PREVISION_SALUD = "PREVISION_SALUD";
export const GET_GENEROS = "GET_GENEROS";
export const GET_PARENTESCO = "GET_PARENTESCO";
export const OBTENER_PARENTESCO_BENEFICIARIOS = "OBTENER_PARENTESCO_BENEFICIARIOS";
export const GET_ESTADO_CIVIL = "GET_ESTADO_CIVIL";
export const GET_REGIONES = "GET_REGIONES";
export const GET_COMUNAS = "GET_COMUNAS";
export const GET_CIUDADES = "GET_CIUDADES";
export const GET_FORMA_PAGO = "GET_FORMA_PAGO";
export const GET_BANCO = "GET_BANCO";
export const GET_TIPO_CUENTA = "GET_TIPO_CUENTA";
export const GET_LISTA_DECLARACIONES = "GET_LISTA_DECLARACIONES";
export const GET_LISTA_PREGUNTAS_COBERTURA = "GET_LISTA_PREGUNTAS_COBERTURA";

// WIZARD ASEGURABLE
export const UPDATE_ID_SOLICITUD = "UPDATE_ID_SOLICITUD";
export const UPDATE_DATOS_PERSONALES = "UPDATE_DATOS_PERSONALES";
export const UPDATE_DIRECCION = "UPDATE_DIRECCION";
export const UPDATE_CONTACTO = "UPDATE_CONTACTO";
export const UPDATE_DATOS_BANCARIOS = "UPDATE_DATOS_BANCARIOS";
export const ADD_CARGA = "ADD_CARGA";
export const ADD_DETALLE = "ADD_DETALLE";
export const UPDATE_DETALLE = "UPDATE_DETALLE";
export const UPDATE_CARGA = "UPDATE_CARGA";
export const UPDATE_BENEFICIARIO = "UPDATE_BENEFICIARIO";
export const CAMBIO_DETALLE = "CAMBIO_DETALLE";
export const WIZARD = "WIZARD";
export const ADD_DECLARACION = "ADD_DECLARACION";
export const ACTUALIZAR_PROGRESO_ASEGURABLE = "ACTUALIZAR_PROGRESO_ASEGURABLE";
export const DECLARAR_CARGA = "DECLARAR_CARGA";
export const DECLARAR_DECLARACION = "DECLARAR_DECLARACION";
export const ACTUALIZAR_BENEFICIARIOS = "ACTUALIZAR_BENEFICIARIOS";
export const ACEPTAR_DECLARO_BENEFICIARIO = "ACEPTAR_DECLARO_BENEFICIARIO";
export const GUARDAR_ESTRUCTURA_FORMULARIO = "GUARDAR_ESTRUCTURA_FORMULARIO";
export const GUARDAR_OPT_GESTIONAR_EJECUTIVO =
  "GUARDAR_OPT_GESTIONAR_EJECUTIVO";

// NEGOCIOS ASEGURABLES
export const UPDATE_MODAL_COTIZACIONES_NEGOCIO =
  "UPDATE_MODAL_COTIZACIONES_NEGOCIO";
export const GET_NEGOCIOS = "GET_NEGOCIOS";
export const GET_EJECUTIVOS = "GET_EJECUTIVOS";

//GRUPOS ASEGURABLES
export const ACTUALIZAR_ASEGURABLES = "ACTUALIZAR_ASEGURABLES";

// OTHERS
export const SHOW_MENU = "SHOW_MENU";
export const PASSWORD_CHANGED = "PASSWORD_CHANGED";
export const MENU_EJECUTIVO_COMPLETO = "MENU_EJECUTIVO_COMPLETO";

export const WIZARD_STEPS = "WIZARD_STEPS";
export const UPDATE_HEIGHT = "UPDATE_HEIGHT";
export const MOSTRAR_SPINNER = "MOSTRAR_SPINNER";
export const COMPONENTE_WIZARD_EJECUTIVO = "COMPONENTE_WIZARD_EJECUTIVO";

// CATALOGO
export const GUARDAR_REGIONES = "GUARDAR_REGIONES";
export const GUARDAR_CIUDADES = "GUARDAR_CIUDADES";
export const GUARDAR_COMUNAS = "GUARDAR_COMUNAS";
export const GUARDAR_GENERO = "GUARDAR_GENERO";
export const GUARDAR_CANALES_VENTA = "GUARDAR_CANALES_VENTA";
export const GUARDAR_CATALOGO_CONSENTIMIENTO =
  "GUARDAR_CATALOGO_CONSENTIMIENTO";

// NEGOCIOS
export const GUARDAR_NEGOCIOS = "GUARDAR_NEGOCIOS";
export const GUARDAR_NEGOCIO = "GUARDAR_NEGOCIO";
export const GUARDAR_EJECUTIVOS = "GUARDAR_EJECUTIVOS";
export const ESTADOS_NEGOCIOS = "ESTADOS_NEGOCIOS";
export const TIPO_NEGOCIO = "TIPO_NEGOCIO";

export const MOSTRAR_MODAL_ASIGNACION = "MOSTRAR_MODAL_ASIGNACION";
export const PERIODO_NEGOCIO = "PERIODO_NEGOCIO";
export const FECHA_TERMINO_ENROLAMIENTO = "FECHA_TERMINO_ENROLAMIENTO";
export const GUARDAR_GIROS = "GUARDAR_GIROS";
export const GUARDAR_DATOS_EMPRESA = "GUARDAR_DATOS_EMPRESA";
export const GUARDAR_DATOS_SUCURSAL = "GUARDAR_DATOS_SUCURSAL";
export const GUARDAR_DATOS_DELEGADO = "GUARDAR_DATOS_DELEGADO";
export const GUARDAR_DATOS_DELEGADO_NEGOCIO = "GUARDAR_DATOS_DELEGADO_NEGOCIO";
export const GUARDAR_FILTRO_SOLICITUDES = "GUARDAR_FECHA_INICIO_RANGO";
export const LIMPIAR_FILTRO_SOLICITUDES = "LIMPIAR_FILTRO_SOLICITUDES";


// COTIZACIONES
export const GUARDAR_COTIZACIONES = "GUARDAR_COTIZACIONES";
export const GUARDAR_COTIZACION = "GUARDAR_COTIZACION";
export const MOSTRAR_MODAL_ARCHIVOS = "MOSTRAR_MODAL_ARCHIVOS";
export const MOSTRAR_MODAL_FECHA_ENROLAMIENTO =
  "MOSTRAR_MODAL_FECHA_ENROLAMIENTO";

// DELEGADO
export const GUARDAR_ASEGURADOS_DELEGADO = "GUARDAR_ASEGURADOS_DELEGADO";
export const GUARDAR_ERRORES_CARGA_MASIVA = "GUARDAR_ERRORES_CARGA_MASIVA";
export const PERFILES_USUARIO = "PERFILES_USUARIO";

// ASEGURADO
export const DATA_WIZARD_ASEGURADO = "DATA_WIZARD_ASEGURADO";
export const OBTENER_INFO_POLIZA = "OBTENER_INFO_POLIZA";
export const GUARDAR_CHECKS_SECCION = "GUARDAR_CHECKS_SECCION";
export const CARGANDO = "CARGANDO";

export const TIPO_ALMACENAMIENTO = "TIPO_ALMACENAMIENTO";
