import {
  CAMPO_REQUERIDO,
  CORREO,
  LARGO_TELEFONO_VACIO,
  LARGO_TELEFONO_VALIDO,
  RUT_DUPLICADO,
  TELEFONO_MOVIL,
  TYPEOF_NUMBER,
  VALIDACION_APELLIDO_M,
  VALIDACION_APELLIDO_M_MAXIMO,
  VALIDACION_APELLIDO_P,
  VALIDACION_APELLIDO_P_MAXIMO,
  VALIDACION_MAIL,
  VALIDACION_NOMBRE,
  VALIDACION_NOMBRE_MAXIMO,
  VALIDACION_RUT,
  VALIDACION_SIN_CORREO,
  VALIDACION_SIN_TELEFONO,
  VALIDACION_TELEFONO,
  VALIDA_TELEFONO,
} from "../../../../../../../utils/Constantes";
import * as Yup from "yup";
import { formatTel } from "../../../../../../../utils/Functions";
const { format, validate } = require("rut.js");

export const validacionRut = (delegado) => {
  const rutUnico = (value) => {
    if (value !== undefined) {
      const { aseguradosDelegado } = delegado;
      value = Number(value.split("-")[0]);

      const rutsAsegurables = aseguradosDelegado.map(({ rut }) => {
        if (typeof rut !== TYPEOF_NUMBER) return Number(rut.split("-")[0]);
        else return rut;
      });

      return rutsAsegurables.includes(value) ? false : true;
    } else {
      return true;
    }
  };
  return Yup.string()
    .required(CAMPO_REQUERIDO)
    .test("validarRut", VALIDACION_RUT, (value) => validate(format(value)))
    .test("rutDuplicado", RUT_DUPLICADO, rutUnico);
};
export const validacionNombre = () => {
  return Yup.string()
    .min(1, VALIDACION_NOMBRE)
    .max(25, VALIDACION_NOMBRE_MAXIMO)
    .required(CAMPO_REQUERIDO)
    .trim();
};

export const validacionPaterno = () => {
  return Yup.string()
    .min(1, VALIDACION_APELLIDO_P)
    .max(25, VALIDACION_APELLIDO_P_MAXIMO)
    .required(CAMPO_REQUERIDO)
    .trim();
};

export const validacionMaterno = () => {
  return Yup.string()
    .min(1, VALIDACION_APELLIDO_M)
    .max(25, VALIDACION_APELLIDO_M_MAXIMO)
    .trim();
};
export const validacionEmail = () => {
  return Yup.string()
    .ensure()
    .when(TELEFONO_MOVIL, {
      is: "",
      then: Yup.string().email(VALIDACION_MAIL).required(VALIDACION_SIN_CORREO),
    })
    .email(VALIDACION_MAIL);
};
export const validacionTelefono = () => {
  return Yup.string()
    .ensure()
    .when(CORREO, {
      is: "",
      then: Yup.string()
        .test(VALIDA_TELEFONO, VALIDACION_TELEFONO, (value) => {
          return formatTel(value).length === LARGO_TELEFONO_VALIDO ||
            formatTel(value).length === LARGO_TELEFONO_VACIO
            ? true
            : false;
        })
        .required(VALIDACION_SIN_TELEFONO),
    })
    .test(VALIDA_TELEFONO, VALIDACION_TELEFONO, (value) => {
      return formatTel(value).length === LARGO_TELEFONO_VALIDO ||
        formatTel(value).length === LARGO_TELEFONO_VACIO
        ? true
        : false;
    });
};
export const validacionSubProducto = () => {
  return Yup.array().required(CAMPO_REQUERIDO).nullable();
};
