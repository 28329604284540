import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { notificacion } from "../../transversales/Notificaciones";
import { crearSolicitud } from "../../../../services/asegurable.services";
import { actualizarUsuarioRevisor } from "../../../../services/bus.poliza.services";
import { listarCotizacionConPrimaFija } from "../../../../services/cotizacion.services"
import {
  update_id_solicitud,
  mostrar_spinner,
  guardar_data_wizard_asegurado,
  guardar_data_asegurable,
} from "../../../../actions/actions";
import {
  ERROR_SOLICITUD,
  ACEPTAR_TERMINOS,
  URL_PDF_EXCLUSIONES_GENERAL,
  VALOR_UNO,
  DOCUMENTOS_NO_ENCONTRADOS,
  VALOR_DOS,
  VALOR_ZERO,
  STRING_Y,
  TIPO_CANAL_VENTA_CORREDOR,
  ERROR_EDICION,
  CODIGO_ESTATUS_ERROR,
  NOTIFICACION_PELIGRO,
  DECLARACION_CONDICIONES_SEGURO,
  TIPO_CANAL_VENTA_DIGITAL,
  TITULO_MODAL_ACEPTAR_DOCUMENTOS_COBERTURAS,
  TITULO_MODAL_ACEPTAR_DOCUMENTOS_COBERTURAS_EXCLUSIONES,
  TEXTO_MODAL_ACEPTAR_DOCUMENTOS_COBERTURAS,
  TEXTO_MODAL_ACEPTAR_DOCUMENTOS_COBERTURAS_EXCLUSIONES,
  ID_PRODUCTO_COMPL_SALUD,
  PATROCINADORES_CONFIGURACION,
  ID_PRODUCTO_VIDA,
  GA_CATEGORIA_FORMULARIO_ENROLAMIENTO,
  GA_LABEL_TERMINOS,
} from "../../../../utils/Constantes";
import { obtenerUrlBase64 } from "../../../../utils/Functions";
import ModalEditarAsegurable from "../jefatura-ejecutivo/ModalEditarAsegurable";
import Spinner from "../../transversales/Spinner";
import {
  buscarSubProducto,
  obtenerDocumentoCondicionado,
  obtenerDocumentoPoliza,
} from "../../../../services/producto.service";
import { BarraNavegacion } from "../../transversales/Navbar";
import { crearEventoTraza } from "../../../../utils/analitica";

class Terminos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tiposPolizas: [],
      aceptacion: false,
      clickLinkCoberturas: false,
      clickLinkExclusiones: false,
      urlCoberturas: "",
      mostrarModalEnlaces: false,
      linksSubrayados: false,
      arregloCondicionados: [],
      arregloPolizas: [],
      arregloPreexistencias: [],
      cubrePreexistencias: true,
      asegurable_5_50: null,
      bloquearAceptar: false,
      preexistenciaVentaDirecta: false,
      cargando: true,
      arregloPol: [],
      arregloCad: [],
      loadingData: false,
      canalVenta: this.props.store.reducer.data_asegurable.canalVenta,
      informacionPoliza: this.props.store.reducer.informacionPoliza,
      cubrePreexistenciass:
        this.props.store.reducer.informacionPoliza.cubrePreexistencias,
      muestraAceptacionPreexistencia: false,
    };
  }

  async componentDidMount() {
    crearEventoTraza(GA_CATEGORIA_FORMULARIO_ENROLAMIENTO, GA_LABEL_TERMINOS);
    
    const canalVenta = this.state.canalVenta;
    const { asegurable_5_50 } = this.state.informacionPoliza ? this.state.informacionPoliza : null;
    this.setState({ asegurable_5_50 });

    let urlCoberturas, urlExclusiones;

    if (asegurable_5_50) {

      if (this.props.store.reducer.data_asegurable.descripcion !== null) {
        
        const { cotizaciones, descripcion } = this.props.store.reducer.data_asegurable;
        let intIdPatrocinador;
        cotizaciones.forEach(objCotizacion => { intIdPatrocinador = objCotizacion.idPatrocinador})
        const {token} = PATROCINADORES_CONFIGURACION.find( (objPatrocinador) => objPatrocinador.id === intIdPatrocinador);
  
        await new Promise(async function(response) { 
          response(await listarCotizacionConPrimaFija(token))
        }).then(async lstPlantillaSaludDigital => {
            
            lstPlantillaSaludDigital.forEach( objPlantilla =>{
              objPlantilla.forEach(objPlan => {
                objPlan.forEach( objSubplan => {
                  if (objSubplan.descripcion === descripcion) {
                    urlCoberturas = objSubplan.rutaArchivo
                  }
                });
              });
            });
        });

      }

      urlExclusiones = URL_PDF_EXCLUSIONES_GENERAL;
      if (canalVenta && (canalVenta.idCanalVenta === VALOR_DOS || canalVenta.idCanalVenta === TIPO_CANAL_VENTA_DIGITAL)) {
        this.props.store.reducer.data_asegurable.cotizaciones.forEach((cot) => {
          if (
            cot.subProducto.producto.idProducto === ID_PRODUCTO_COMPL_SALUD || cot.subProducto.producto.idProducto === ID_PRODUCTO_VIDA 
          ) {
            this.setState({ muestraAceptacionPreexistencia: true });
            this.setState({preexistenciaVentaDirecta: !cot.evaluacionPreexistencia});
          }
        });
      }

      this.setState({ urlCoberturas, urlExclusiones });
    } else {
      this.obtenerCondicionesDocumentos();
    }

    this.setState({ cargando: !asegurable_5_50 });
  }

  obtenerCondicionesDocumentos = async () => {
    const informacionPoliza = this.state.informacionPoliza;

    const rutEmpresa = `${informacionPoliza.rutEmpresa}-${informacionPoliza.digitoVerificadorEmpresa}`;

    const tiposPolizas = informacionPoliza.grupos.map(
      (grupo) => grupo.poliza.tipoPoliza.glosa
    );

    this.setState({ tiposPolizas });

    informacionPoliza.grupos.forEach(async (grupo, indiceGrupo) => {
      //Obtener documentos pólizas
      const polizaBase64 = await obtenerDocumentoPoliza(
        rutEmpresa,
        grupo.nroPoliza,
        grupo.nroRenovacion
      );
      if (polizaBase64) {
        const urlPoliza = obtenerUrlBase64(polizaBase64, "application/pdf");
        const objetoPoliza = {
          tipoPoliza: grupo.poliza.tipoPoliza.glosa,
          nroPoliza: grupo.nroPoliza,
          nroRenovacion: grupo.nroRenovacion,
          urlDocumento: urlPoliza,
          clickLink: false,
        };
        this.setState({
          arregloPolizas: [...this.state.arregloPolizas, objetoPoliza],
        });
      } else {
        this.setState({
          bloquearLinkPoliza: true,
          cargando: false,
          bloquearAceptar: true,
        });
        notificacion("danger", DOCUMENTOS_NO_ENCONTRADOS);
        return;
      }

      //Obtener condiciones
      grupo.coberturas.forEach(async (cobertura, indiceCobertura) => {
        const subProducto = await buscarSubProducto(cobertura.idCobertura);

        if (subProducto) {
          //Obtener documentos exclusiones/condicionados
          const glosaCondicionado =
            subProducto.condicionadoCobertura[0].condicionado.glosa;
          const condicionadoBase64 = await obtenerDocumentoCondicionado(
            glosaCondicionado
          );
          if (condicionadoBase64) {
            const urlCondicionado = obtenerUrlBase64(
              condicionadoBase64,
              "application/pdf"
            );
            const objetoCondicionado = {
              principal: cobertura.principal,
              glosaCobertura: cobertura.glosa,
              glosaDocumento: glosaCondicionado,
              urlDocumento: urlCondicionado,
              nroPoliza: grupo.nroPoliza,
              nroRenovacion: grupo.nroRenovacion,
              tipoPoliza: grupo.poliza.tipoPoliza.glosa,
              clickLink: false,
            };

            const arregloCondicionados = [...this.state.arregloCondicionados];

            if (objetoCondicionado.principal) {
              arregloCondicionados.unshift(objetoCondicionado);
            } else {
              arregloCondicionados.push(objetoCondicionado);
            }

            if (
              indiceGrupo === informacionPoliza.grupos.length - VALOR_UNO &&
              indiceCobertura === grupo.coberturas.length - VALOR_UNO
            ) {
              this.setState({ cargando: false });
            }

            this.setState({ arregloCondicionados });
          } else {
            this.setState({ cargando: false, bloquearAceptar: true });
            notificacion("danger", DOCUMENTOS_NO_ENCONTRADOS);
            return;
          }
        } else {
          this.setState({ cargando: false, bloquearAceptar: true });
          notificacion("danger", DOCUMENTOS_NO_ENCONTRADOS);
          return;
        }
      });
    });
  };

  onSubmit = async () => {

    this.setState({ loadingData: true });
    const canalVenta = this.state.canalVenta;
    let blnAceptacion = this.state.aceptacion;

    if (canalVenta && canalVenta.idCanalVenta === VALOR_DOS) {
      blnAceptacion = true;
      this.setState({ aceptacion: true });
    } 

    if (blnAceptacion) {
      const asegurado = this.props.store.reducer.data_asegurable;
      let dataEnvio   = {
        asegurable: {
          idAsegurable: asegurado.idAsegurable,
          solicitud: {
            tipoSolicitud: {
              idTipoSolicitud: asegurado.idTipoSolicitud,
            },
            aceptacionTerminos: blnAceptacion,
          },
        },
      };

      let res = await crearSolicitud(dataEnvio);

      if (res) {
        this.props.update_id_solicitud(res.objeto);
        this.props.history.push("/asegurable");
        this.actualizaRevisor();
      } else {
        notificacion("warning", ERROR_SOLICITUD);
        this.setState({ loadingData: false });
      }
    } else {
      notificacion("warning", ACEPTAR_TERMINOS);
      this.setState({ loadingData: false });
      return;
    }
  };

  actualizaRevisor = async () => {
    const dataEnvio = {
      idNegocio: this.props.store.reducer.data_asegurable.idNegocio,
      revisado: false,
    };
    let actualizarRevisor = await actualizarUsuarioRevisor(dataEnvio);
    if (actualizarRevisor === CODIGO_ESTATUS_ERROR) {
      notificacion(NOTIFICACION_PELIGRO, ERROR_EDICION);
    }
  };

  mostrarModalEnlaces = () => {
    this.setState({ mostrarModalEnlaces: !this.state.mostrarModalEnlaces });
  };

  clickLinkCoberturas = (e) => {
    if (this.state.bloquearLinkPoliza) {
      e.preventDefault();
    } else {
      this.setState({ clickLinkCoberturas: true });
    }
  };

  clickLinkExclusiones = () => {
    this.setState({ clickLinkExclusiones: true });
  };

  titleSalud = () => {
    return (
      <p className="font-weight-bold">
        Al contratar o incorporarse a este seguro de salud, usted debe tener
        presente lo siguiente:
      </p>
    );
  };

  bodySalud = () => {
    const canalVenta = this.state.canalVenta;
    return (
      <>
        <li>
          {" "}
          Este es un seguro voluntario, que reembolsa sólo los gastos médicos
          cubiertos por esta póliza y de cargo del asegurado.
        </li>
        <li>
          {" "}
          Este seguro, no sustituye la cobertura que otorga la ISAPRE o FONASA y
          no necesariamente cubre las mismas prestaciones.
        </li>
        <li>
          Antes de contratar este seguro es importante que usted se informe y
          tenga claridad sobre los siguientes aspectos:
          <ul>
            <li style={{ listStyleType: "circle" }}>
              Duración de este seguro.
            </li>
            <li style={{ listStyleType: "circle" }}>
              Cómo y bajo qué condiciones se renueva este seguro.
            </li>
            <li style={{ listStyleType: "circle" }}>
              De qué manera se va a reajustar el costo de este seguro, en caso
              de renovación.
            </li>
            <li style={{ listStyleType: "circle" }}>
              En qué casos{" "}
              <span className="font-weight-bold">
                NO SE PAGARÁ ESTE SEGURO.
              </span>
            </li>
            <li style={{ listStyleType: "circle" }}>
              Los requisitos para cobrar el seguro.
            </li>
          </ul>
        </li>
        <li>
          En el siguiente cuadro se resumen algunos antecedentes importantes que
          usted debe considerar, al momento de contratar este seguro.
          <div className="alert alert-light mt-3" role="alert">
            Este seguro:
            <ul>
              <li style={{ listStyleType: "circle" }}>
                <span className="font-weight-bold">NO</span> contempla
                renovación garantizada.
              </li>
              <li style={{ listStyleType: "circle" }}>
                <span className="font-weight-bold">SI</span> podrá aumentar la
                prima (precio) en caso de renovación de la Póliza.
              </li>
              <li style={{ listStyleType: "circle" }}>
                <span className="font-weight-bold">NO</span> considera la
                siniestralidad individual para el aumento de la prima en caso de
                renovación.
              </li>
              {canalVenta && canalVenta.idCanalVenta === VALOR_DOS ? (
                <li style={{ listStyleType: "circle" }}>
                  <span className="font-weight-bold">
                    {this.state.preexistenciaVentaDirecta ? "SI " : "NO "}
                  </span>
                  cubre preexistencias en el caso de seguro de salud.
                </li>
              ) : (
                <li style={{ listStyleType: "circle" }}>
                  <span className="font-weight-bold">
                    {this.state.cubrePreexistenciass ? "SI " : "NO "}
                  </span>
                  cubre preexistencias en el caso de seguro de salud.
                </li>
              )}
            </ul>
          </div>
        </li>
      </>
    );
  };

  render() {
    const canalVenta = this.state.canalVenta;
    const informacionPoliza = this.props.store.reducer.informacionPoliza;
    return this.state.cargando ? (
      <Spinner
        claseColor="naranjo"
        idSpinner="spinnerNaranjo"
        posicionLogo="align-self-center w-100"
      />
    ) : (
      <Fragment>
        <ModalEditarAsegurable
          idSubmit="submitTerminos"
          type="small"
          show={this.state.mostrarModalEnlaces}
          title={informacionPoliza.asegurable_5_50 ? TITULO_MODAL_ACEPTAR_DOCUMENTOS_COBERTURAS : TITULO_MODAL_ACEPTAR_DOCUMENTOS_COBERTURAS_EXCLUSIONES}
          action="Entendido"
          onSubmit={() => {
            this.mostrarModalEnlaces(false);
          }}
          onClose={() => this.mostrarModalEnlaces(false)}
          showCancelButton={false}
          showCloseButton={true}
          showLeftColumn={false}
          showSubmitButton={true}
        >
          <div className="row mx-auto">
            <h5 className="text-muted">
              {informacionPoliza.asegurable_5_50 ? TEXTO_MODAL_ACEPTAR_DOCUMENTOS_COBERTURAS : TEXTO_MODAL_ACEPTAR_DOCUMENTOS_COBERTURAS_EXCLUSIONES}
            </h5>
          </div>
        </ModalEditarAsegurable>
        <BarraNavegacion />
        <div className="container animated fadeIn">
          <div className="row pt-4 mb-4">
            <div className="col-12">
              <div className="card shadow">
                <div className="card-body p-5">
                  {canalVenta.idCanalVenta !== TIPO_CANAL_VENTA_CORREDOR
                    ? this.state.muestraAceptacionPreexistencia &&
                    this.titleSalud()
                    : informacionPoliza &&
                    !informacionPoliza.soloProductoVida &&
                    this.titleSalud()}
                  <ol>
                    {canalVenta.idCanalVenta !== TIPO_CANAL_VENTA_CORREDOR
                      ? this.state.muestraAceptacionPreexistencia &&
                      this.bodySalud()
                      : informacionPoliza &&
                      !informacionPoliza.soloProductoVida &&
                      this.bodySalud()}
                    <li>
                      {informacionPoliza && (
                        <>
                          Las Condiciones Generales de este seguro se encuentran
                          depositadas con
                          {this.state.arregloCondicionados.length >
                            VALOR_ZERO &&
                            this.state.arregloCondicionados.find(
                              (datoAsegurado) => !datoAsegurado.principal
                            ) !== undefined
                            ? informacionPoliza.infoPolCad
                            : informacionPoliza.infoPolCad !== undefined
                              ? informacionPoliza.infoPolCad.split(
                                ` ${STRING_Y} POL`
                              )[VALOR_ZERO]
                              : null}
                          , según corresponda, en la Comisión para el Mercado
                          Financiero.
                          {!informacionPoliza.soloProductoVida && (
                            <span>
                              {" "}
                              Usted puede revisar este texto en{" "}
                              <a
                                className="text-primary"
                                href="http://www.cmfchile.cl/"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                www.cmfchile.cl
                              </a>
                              .
                            </span>
                          )}
                        </>
                      )}
                    </li>
                    {canalVenta && canalVenta.idCanalVenta !== VALOR_DOS && (
                      <div>
                        <li
                          className={`font-weight-bold mt-2 ${this.state.linksSubrayados && "underlined"
                            }`}
                        >
                          Descargue y lea toda la información de Cobertura
                          haciendo click{" "}
                          {this.state.asegurable_5_50 ||
                            this.state.tiposPolizas.length === 1 ? (
                            <span>
                              <a
                                id="linkCoberturas"
                                className={
                                  this.state.bloquearLinkPoliza
                                    ? "anchor-deshabilitado text-dark"
                                    : this.state.clickLinkCoberturas
                                      ? "text-blue"
                                      : "text-primary"
                                }
                                href={
                                  this.state.asegurable_5_50
                                    ? this.state.urlCoberturas
                                    : this.state.arregloPolizas[0]
                                      ? this.state.arregloPolizas[0].urlDocumento
                                      : ""
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={this.clickLinkCoberturas}
                                onMouseDown={this.clickLinkCoberturas}
                              >
                                aquí
                              </a>
                              .
                            </span>
                          ) : (
                            <Fragment>
                              <span>en los siguientes enlaces:</span>
                              <ul>
                                {this.state.arregloPolizas.map(
                                  (poliza, index) => (
                                    <li key={index}>
                                      <a
                                        id={`linkCoberturas${index}`}
                                        className={
                                          this.state.arregloPolizas[index]
                                            .clickLink
                                            ? "text-blue"
                                            : "text-primary"
                                        }
                                        href={poliza.urlDocumento}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        onClick={() => {
                                          const arregloPolizas = [
                                            ...this.state.arregloPolizas,
                                          ];
                                          arregloPolizas[
                                            index
                                          ].clickLink = true;
                                          this.setState({
                                            arregloPolizas,
                                          });
                                        }}
                                        onMouseDown={() => {
                                          const arregloPolizas = [
                                            ...this.state.arregloPolizas,
                                          ];
                                          arregloPolizas[
                                            index
                                          ].clickLink = true;
                                          this.setState({
                                            arregloPolizas,
                                          });
                                        }}
                                      >
                                        {poliza.tipoPoliza}
                                      </a>
                                    </li>
                                  )
                                )}
                              </ul>
                            </Fragment>
                          )}
                        </li>
                        {canalVenta.idCanalVenta !== TIPO_CANAL_VENTA_DIGITAL && (
                          <li
                            className={`font-weight-bold mt-2 ${this.state.linksSubrayados && "underlined"
                              }`}
                          >
                            Descargue y lea todas las exclusiones{" "}
                            {this.state.asegurable_5_50 ? "de" : "por"} Cobertura
                            haciendo click{" "}
                            {this.state.asegurable_5_50 ? (
                              <Fragment>
                                <a
                                  id="linkExclusiones"
                                  className={
                                    this.state.clickLinkExclusiones
                                      ? "text-blue"
                                      : "text-primary"
                                  }
                                  href={this.state.urlExclusiones}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  onClick={this.clickLinkExclusiones}
                                  onMouseDown={this.clickLinkExclusiones}
                                >
                                  aquí
                                </a>
                                .
                              </Fragment>
                            ) : (
                              <Fragment>
                                <span> en los siguientes enlaces: </span>
                                {this.state.tiposPolizas
                                  .slice(0)
                                  .reverse()
                                  .map((tipoPoliza, index) => (
                                    <Fragment key={index}>
                                      <ul className="pt-2">
                                        <li>Seguro de {tipoPoliza}:</li>
                                        <ul>
                                          {this.state.arregloCondicionados
                                            .filter(
                                              (condicionado) =>
                                                condicionado.tipoPoliza ===
                                                tipoPoliza
                                            )
                                            .map((item, index) => {
                                              const arregloCondicionados = [
                                                ...this.state
                                                  .arregloCondicionados,
                                              ];
                                              const indexOriginal =
                                                arregloCondicionados.indexOf(
                                                  item
                                                );
                                              return (
                                                <li
                                                  key={`${item.glosaCobertura}${index}`}
                                                >
                                                  <a
                                                    id={`${item.glosaCobertura}${index}`}
                                                    className={
                                                      this.state
                                                        .arregloCondicionados[
                                                        indexOriginal
                                                      ].clickLink
                                                        ? "text-blue"
                                                        : "text-primary"
                                                    }
                                                    href={item.urlDocumento}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    onClick={() => {
                                                      arregloCondicionados[
                                                        indexOriginal
                                                      ].clickLink = true;
                                                      this.setState({
                                                        arregloCondicionados,
                                                      });
                                                    }}
                                                    onMouseDown={() => {
                                                      arregloCondicionados[
                                                        indexOriginal
                                                      ].clickLink = true;
                                                      this.setState({
                                                        arregloCondicionados,
                                                      });
                                                    }}
                                                  >
                                                    {item.glosaCobertura}{" "}
                                                    {item.principal && (
                                                      <span>(PRINCIPAL)</span>
                                                    )}
                                                  </a>
                                                </li>
                                              );
                                            })}
                                        </ul>
                                      </ul>
                                    </Fragment>
                                  ))}
                              </Fragment>
                            )}
                          </li>
                        )}
                      </div>
                    )}
                  </ol>
                  {canalVenta && canalVenta.idCanalVenta !== VALOR_DOS && (
                    <div>
                      <label className="switch">
                        <input
                          id="checkboxDeclaroConocimiento"
                          checked={this.state.aceptacion}
                          onChange={() => {
                            this.setState({
                              aceptacion: !this.state.aceptacion,
                            });
                          }}
                          type="checkbox"
                        />
                        <span className="slider round" />
                      </label>{" "}
                      <span
                        id="textDeclaroConocimiento"
                        onClick={() => {
                          this.setState({
                            aceptacion: !this.state.aceptacion,
                          });
                        }}
                      >
                        {DECLARACION_CONDICIONES_SEGURO}
                      </span>
                    </div>
                  )}
                  <div className="button_zone text-center mt-2">
                    <button
                      id="botonAceptar"
                      className="btn btn-principal btn-sm"
                      type="button"
                      onClick={this.onSubmit}
                    >
                      {this.state.loadingData ? (
                        <Fragment>
                          <i className="fa fa-spinner fa-pulse fa-fw"></i>
                          <span className="sr-only">Loading...</span>
                        </Fragment>
                      ) : (
                        <span>Aceptar</span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  store: state,
});
export default withRouter(
  connect(mapStateToProps, {
    guardar_data_asegurable,
    update_id_solicitud,
    mostrar_spinner,
    guardar_data_wizard_asegurado,
  })(Terminos)
);
