import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Files from "react-files";
import {
  setArchivosCotizacion,
  getArchivosCotizacion,
  eliminarArchivoCotizacion,
  actualizarCotizacion,
} from "../../../../services/cotizacion.services";
import { confirmAlert } from "react-confirm-alert";
import { notificacion } from "../../transversales/Notificaciones";
import Authorized from "../../../../utils/Authorized";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from "react-day-picker/moment";
import "moment/locale/es";
import moment from "moment";
import {
  ARCHIVO_COTIZ,
  ARCHIVO_CARTA,
  EXITO_BORRAR_ARCHIVO,
  ERROR_BORRAR_ARCHIVO,
  EXITO_CARGAR_ARCHIVO_COTIZACION,
  EXITO_CARGAR_ARCHIVO_CARTA,
  ERROR_CARGAR_ARCHIVO,
  VALIDACION_PESO_ARCHIVO,
  VALIDACION_TIPO_ARCHIVO,
  EXITO_CARGAR_FECHA_ACEP,
  ERROR_CARGAR_FECHA_ACEP,
  SIN_DOCUMENTOS,
  PERFIL_LDAP,
  PDF,
  _XLSX,
  XLSX,
  _XLS,
  XLS,
  MAX_FILE,
  CARTA_ACEPTACION_COTIZ,
  ARCHIVO_CARTA_EV,
  TIPO_DATA_DOC_PDF,
  TIPO_DATA_DOC_XLS,
  TIPO_DATA_DOC_XLSX,
  ESTADO_NEGOCIO_3,
  VALOR_ZERO,
  VALOR_UNO,
  VALOR_TRES,
  VALOR_SIETE
} from "../../../../utils/Constantes";
import { guardar_cotizacion } from "../../../../actions/actions";

const iconExcel = require("../../../assets/images/excel.svg");
const iconPDF = require("../../../assets/images/pdf.svg");

class ModalArchivosCotizacion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      archivosAdjuntos: {},
      perfilUsuario: this.props.store.auth.usuarioSesion.rol.codigo,
      fechaAceptacion: this.props.store.reducer.cotizacion.fechaAceptacion,
      fechaAceptacionOr: this.props.store.reducer.cotizacion.fechaAceptacion,
      codigoCotizacion: null,
      archivoCartaAceptacion: null,
      cargandoCartaAceptacion: false,
      archivoCotizacion: null,
      cargandoCotizacion: false,
      fechaValida: true,
      dataEnvio: {
        fechaAceptacion: null,
        idCotizacion: this.props.store.reducer.cotizacion.idCotizacion,
        perfil: PERFIL_LDAP,
      },
    };
  }

  componentWillMount() {
    this.obtenerArchivos(this.props.cotizacion.codigoCotizacion);

    if (this.state.fechaAceptacion) {
      let dataEnvio = this.state.dataEnvio;
      dataEnvio.fechaAceptacion = this.state.fechaAceptacion;
      this.setState({
        dataEnvio: dataEnvio,
      });
    }
  }

  async obtenerArchivos(codigoCotizacion, tipo = null) {
    let data = {
      metadataDocto: { folio: codigoCotizacion },
    };

    if (tipo !== null) {
      if (tipo === ARCHIVO_COTIZ) this.setState({ cargandoCotizacion: true });
      else this.setState({ cargandoCartaAceptacion: true });
    } else {
      this.setState({
        cargandoCartaAceptacion: true,
        cargandoCotizacion: true,
      });
    }

    const res = await getArchivosCotizacion(data);

    if (res !== null && res !== SIN_DOCUMENTOS) {
      for (let doc of res) {
        let dataDoc = {
          idDocto: doc.idDocto,
          nombreDocto: doc.nombreDocto,
          archivo: doc.streamBase64,
        };

        if (doc.nombreDocto.indexOf(PDF) > -1) {
          this.setState({ archivoCartaAceptacion: dataDoc });
        } else if (
          doc.nombreDocto.indexOf(XLS) > -1 ||
          doc.nombreDocto.indexOf(XLSX) > -1
        ) {
          this.setState({ archivoCotizacion: dataDoc });
        }
      }
    }

    this.setState({
      cargandoCartaAceptacion: false,
      cargandoCotizacion: false,
    });
  }

  async enviarArchivo(data, tipo) {

    if (tipo === ARCHIVO_COTIZ) this.setState({ cargandoCotizacion: true });
    else this.setState({ cargandoCartaAceptacion: true });

    let res = await setArchivosCotizacion(data);

    if (res === null) notificacion("danger", ERROR_CARGAR_ARCHIVO);
    else {
      this.obtenerArchivos(data.metadataDocto.folio, tipo);
      if (tipo === ARCHIVO_COTIZ) notificacion("success", EXITO_CARGAR_ARCHIVO_COTIZACION);
      if (tipo === ARCHIVO_CARTA_EV) notificacion("success", EXITO_CARGAR_ARCHIVO_CARTA);
    }

    setTimeout(() => {
      if (tipo === ARCHIVO_COTIZ) this.setState({ cargandoCotizacion: false });
      else this.setState({ cargandoCartaAceptacion: false });
    }, 100);
  }

  async eliminarArchivo(idDocto, tipoDocto) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="modal-confirm-container">
            <div className="d-flex">
              <p className="h4">¿Está seguro de eliminar esta cotización?</p>
            </div>

            <div className="row mt-4">
              <button
                className="btn btn-principal color-cancel btn-sm d-block ml-auto mr-2"
                onClick={() => {
                  onClose();
                }}
              >
                Cancelar
              </button>
              <button
                className="btn btn-principal color-danger btn-sm"
                onClick={async () => {
                  if (idDocto) {
                    let res = await eliminarArchivoCotizacion(idDocto);
                    if (res === null)
                      notificacion("danger", ERROR_BORRAR_ARCHIVO);
                    else {
                      notificacion("success", EXITO_BORRAR_ARCHIVO);
                      if (tipoDocto === ARCHIVO_CARTA)
                        this.setState({ archivoCartaAceptacion: null });
                      if (tipoDocto === ARCHIVO_COTIZ)
                        this.setState({ archivoCotizacion: null });
                    }
                  } else {
                    let archivosAdjuntos = this.state.archivosAdjuntos;
                    if (tipoDocto === ARCHIVO_CARTA) {
                      archivosAdjuntos.cotizacion = {};
                      this.setState({
                        archivoCartaAceptacion: null,
                        archivosAdjuntos,
                      });
                    }
                    if (tipoDocto === ARCHIVO_COTIZ) {
                      archivosAdjuntos.cartaAceptacion = {};
                      this.setState({
                        archivoCotizacion: null,
                        archivosAdjuntos,
                      });
                    }
                  }
                  onClose();
                }}
              >
                Eliminar
              </button>
            </div>
          </div>
        );
      },
    });
  }

  descargarArchivo(documento) {
    let archivo = documento.archivo;
    let nombreDocto = documento.nombreDocto;
    let tipo;
    let linkSource;

    if (documento.idDocto) {
      archivo = archivo.split("base64")[1];
      if (nombreDocto.indexOf(PDF) > -1) {
        tipo = TIPO_DATA_DOC_PDF;
        archivo = archivo + "=";
      } else if (nombreDocto.indexOf(XLSX) > -1)
        tipo = TIPO_DATA_DOC_XLSX;
      else if (nombreDocto.indexOf(XLS) > -1)
        tipo = TIPO_DATA_DOC_XLS;

      linkSource = `${tipo}${archivo}`;
    } else
      linkSource = archivo;


    const downloadLink = document.createElement("a");

    downloadLink.href = linkSource;
    downloadLink.download = nombreDocto;
    downloadLink.click();
  }

  onFilesChange(file, coidgoCotizacion) {
    if (file[0]) {
      let tipoCarga;
      let nombreArchivo;
      if (file[0].extension === PDF) {
        tipoCarga = CARTA_ACEPTACION_COTIZ;
        nombreArchivo = `COTIZ_CARTA_ACEPTACION_${coidgoCotizacion}.${file[0].extension}`;
      } else {
        tipoCarga = ARCHIVO_COTIZ;
        nombreArchivo = `COTIZACION_${coidgoCotizacion}.${file[0].extension}`;
      }

      let dataEnvio;
      let reader = new FileReader();
      reader.readAsDataURL(file[0]);
      reader.onload = () => {
        dataEnvio = {
          listaDoctos: [
            {
              nombreDocto: nombreArchivo,
              streamBase64: reader.result,
            },
          ],
          metadataDocto: { folio: coidgoCotizacion },
          tipoDocto: tipoCarga,
        };

        let dataDoc = {
          nombreDocto: nombreArchivo,
          archivo: reader.result,
        };

        let archivos = this.state.archivosAdjuntos;

        if (tipoCarga === ARCHIVO_COTIZ) {
          this.setState({ archivoCotizacion: dataDoc });
          archivos.cotizacion = { dataEnvio, tipoCarga };
        } else {
          this.setState({ archivoCartaAceptacion: dataDoc });
          archivos.cartaAceptacion = { dataEnvio, tipoCarga };
        }

        this.setState({ archivosAdjuntos: archivos });
      };
    }
  }

  onFilesError(error, file) {
    if (error.code === 2) notificacion("warning", VALIDACION_PESO_ARCHIVO);
    else if (error.code === 1) notificacion("warning", VALIDACION_TIPO_ARCHIVO);
  }

  obtenerFecha(fecha) {
    if (typeof fecha === "undefined") this.setState({ fechaValida: false });
    else {
      this.setState({ fechaValida: true });
      let fechaAceptacion = fecha;
      let dataEnvio = {
        fechaAceptacion: moment(fecha).format("YYYY-MM-DD"),
        idCotizacion: this.props.store.reducer.cotizacion.idCotizacion,
        perfil: PERFIL_LDAP
      };
      this.setState({ fechaAceptacion, dataEnvio });
    }
  }

  async actualizarFechaAceptacionCotizacion() {
    let res = await actualizarCotizacion(this.state.dataEnvio);

    let fechaOr = this.state.fechaAceptacionOr;
    let fecha = this.state.dataEnvio.fechaAceptacion;

    if (fechaOr === fecha) return this.props.onCancel();;

    if (res !== null) {
      fecha = moment(fecha).format("MM/DD/YYYY");
      fecha = new Date(fecha).getTime();
      let cotizacion = this.props.store.reducer.cotizacion;
      cotizacion.fechaAceptacion = fecha;
      await this.props.guardar_cotizacion(cotizacion);

      notificacion("success", EXITO_CARGAR_FECHA_ACEP);
    } else notificacion("danger", ERROR_CARGAR_FECHA_ACEP);

    this.props.onCancel();
  }

  render() {
    const date = moment(
      this.props.store.reducer.cotizacion.fechaCotizacion
    ).format("DD-MM-YYYY");
    const dia = Number(date.split("-")[0]);
    const mes = Number(date.split("-")[1]) - 1;
    const anio = Number(date.split("-")[2]);
    return (
      <div
        className={`${!this.props.show ? "modal_overlay " : "modal_overlay show "
          } ${this.props.overlay === undefined
            ? ""
            : this.props.overlay
              ? ""
              : "light"
          }`}
        id={this.props.id}
      >
        <div
          className={`main_modal animated bounceInLeft ${this.props.centered ? "center" : ""
            } ${this.props.type === undefined ? "large" : this.props.type}`}
        >
          <div className="row">
            <div className="col-12">
              <div className="modal_title">
                <div className="title font-weight-bold">
                  {this.props.title}
                  {this.props.cotizacion &&
                    this.props.cotizacion.codigoCotizacion}
                </div>
                {this.props.showCloseButton === undefined ||
                  this.props.showCloseButton ? (
                  <div
                    className="button_close cursor"
                    onClick={() => {
                      this.props.onClose();
                    }}
                  >
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      viewBox="0 0 352 512"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z" />
                    </svg>
                  </div>
                ) : null}
              </div>

              <div id="formulario">
                <div className="form-group main_modal">
                  <div className="files row mx-0 mx-md-5">
                    {/* FECHA CARTA DE ACEPTACIÓN */}
                    {this.props.store.reducer.negocio.estadoNegocio
                      .idEstadoNegocio < ESTADO_NEGOCIO_3 && (
                        <div className="col-md-12">
                          <label className="d-block mt-3 mt-md-0">
                            Fecha carta de aceptación{" "}
                          </label>
                          <div className="">
                            <DayPickerInput
                              id="fechaCartaAceptacion"
                              onDayChange={this.obtenerFecha.bind(this)}
                              inputProps={{
                                className: "form-control",
                                disabled: this.state.perfilUsuario === 4,
                              }}
                              formatDate={formatDate}
                              parseDate={parseDate}
                              value={
                                this.state.fechaAceptacion
                                  ? moment(this.state.fechaAceptacion).format(
                                    "DD/MM/YYYY"
                                  )
                                  : ""
                              }
                              placeholder="DD/MM/YYYY"
                              dayPickerProps={{
                                localeUtils: MomentLocaleUtils,
                                locale: "es",
                                disabledDays: {
                                  after: new Date(),
                                  before: new Date(anio, mes, dia),
                                },
                              }}
                            />
                            {!this.state.fechaValida && (
                              <small className="animated fadeIn text-danger">
                                Fecha inválida
                              </small>
                            )}
                          </div>
                        </div>
                      )}

                    {/* CARTA DE ACEPTACIÓN */}
                    <div className="col-md-12">
                      <label className="d-block mt-3">
                        Carta de aceptación
                      </label>
                      {!this.state.cargandoCartaAceptacion &&
                        this.state.archivoCartaAceptacion === null ? (
                        <React.Fragment>
                          {this.props.store.reducer.negocio.estadoNegocio
                            .idEstadoNegocio < ESTADO_NEGOCIO_3 && (
                              <Authorized roles={[VALOR_TRES, VALOR_SIETE]}>
                                <Files
                                  className="input-file py-2 animated fadeIn"
                                  id="subirCartaAceptacion"
                                  onChange={(file) => {
                                    this.onFilesChange(
                                      file,
                                      this.props.cotizacion.codigoCotizacion
                                    );
                                  }}
                                  onError={this.onFilesError}
                                  accepts={[".pdf"]}
                                  multiple={false}
                                  maxFiles={1}
                                  maxFileSize={2000000}
                                  minFileSize={0}
                                  clickable
                                >
                                  <div className="row cursor text-center">
                                    <div className="my-auto ml-auto">
                                      <i
                                        title="Adjuntar pdf"
                                        className="fa fa-file-pdf-o text-pdf d-block text-right"
                                        aria-hidden="true"
                                      ></i>
                                    </div>
                                    <div className="my-auto mr-auto ml-2">
                                      <span className="d-block">
                                        Subir carta de aceptación
                                      </span>
                                    </div>
                                  </div>
                                </Files>
                              </Authorized>
                            )}
                          <Authorized roles={[4]}>
                            <span className="d-block font-weight-bold text-center text-primary animated fadeIn">
                              No hay archivo disponible
                            </span>
                          </Authorized>
                        </React.Fragment>
                      ) : (
                        !this.state.cargandoCartaAceptacion && (
                          <div className="row animated fadeIn">
                            <div className="col-3">
                              <div className="img-icon text-center">
                                <img
                                  className="cursor"
                                  alt="img-icon"
                                  src={iconPDF}
                                  onClick={() =>
                                    this.descargarArchivo(
                                      this.state.archivoCartaAceptacion
                                    )
                                  }
                                />
                              </div>
                            </div>
                            <div
                              className="col-6 pl-0 my-auto"
                              onClick={() =>
                                this.descargarArchivo(
                                  this.state.archivoCartaAceptacion
                                )
                              }
                            >
                              <span
                                className="d-block cursor"
                                style={{ wordBreak: "break-word" }}
                              >
                                {this.state.archivoCartaAceptacion.nombreDocto}
                              </span>
                            </div>
                            {this.props.store.reducer.negocio.estadoNegocio
                              .idEstadoNegocio < ESTADO_NEGOCIO_3 && (
                                <Authorized roles={[VALOR_TRES, VALOR_SIETE]}>
                                  <div className="col-3 my-auto">
                                    <i
                                      className="fa fa-trash text-danger fa-2x cursor"
                                      aria-hidden="true"
                                      onClick={() =>
                                        this.eliminarArchivo(
                                          this.state.archivoCartaAceptacion
                                            .idDocto
                                            ? this.state.archivoCartaAceptacion.idDocto.split(
                                              ";"
                                            )[0]
                                            : null,
                                          "cartaAceptacion"
                                        )
                                      }
                                    ></i>
                                  </div>
                                </Authorized>
                              )}
                          </div>
                        )
                      )}
                      {this.state.cargandoCartaAceptacion && (
                        <i
                          className="fa fa-circle-o-notch fa-spin fa-3x fa-fw d-block mx-auto"
                          style={{ color: "#ed8d00" }}
                        ></i>
                      )}
                    </div>

                    {/* COTIZACIÓN */}
                    <div className="col-md-12 mt-3">
                      <label className="d-block mt-3">Cotización</label>
                      {!this.state.cargandoCotizacion &&
                        this.state.archivoCotizacion === null ? (
                        <React.Fragment>
                          {this.props.store.reducer.negocio.estadoNegocio
                            .idEstadoNegocio < ESTADO_NEGOCIO_3 ? (
                            <React.Fragment>
                              <Authorized roles={[VALOR_TRES, VALOR_SIETE]}>
                                <Files
                                  className="input-file py-2 animated fadeIn"
                                  onChange={(file) => {
                                    this.onFilesChange(
                                      file,
                                      this.props.cotizacion.codigoCotizacion
                                    );
                                  }}
                                  onError={this.onFilesError}
                                  accepts={[_XLS, _XLSX]}
                                  multiple={false}
                                  maxFiles={VALOR_UNO}
                                  maxFileSize={MAX_FILE}
                                  minFileSize={VALOR_ZERO}
                                  clickable
                                >
                                  <div className="row cursor text-center">
                                    <div className="my-auto ml-auto">
                                      <i
                                        title="Adjuntar excel"
                                        className="fa fa-file-excel-o text-excel d-block text-right"
                                        aria-hidden="true"
                                      ></i>
                                    </div>
                                    <div className="my-auto mr-auto ml-2">
                                      <span className="d-block">
                                        Subir cotización
                                      </span>
                                    </div>
                                  </div>
                                </Files>
                              </Authorized>
                              <Authorized roles={[4]}>
                                <span className="d-block font-weight-bold text-center text-primary animated fadeIn">
                                  No hay archivo disponible
                                </span>
                              </Authorized>
                            </React.Fragment>
                          ) : (
                            <h6 className="text-muted text-center mt-1 mb-5">Este archivo no fue cargado</h6>
                          )}
                        </React.Fragment>
                      ) : (
                        !this.state.cargandoCotizacion && (
                          <div className="col-md-12 px-0">
                            <div className="row animated fadeIn">
                              <div className="col-3">
                                <div className="img-icon text-center">
                                  <img
                                    className="cursor"
                                    alt="img-icon"
                                    src={iconExcel}
                                    onClick={() =>
                                      this.descargarArchivo(
                                        this.state.archivoCotizacion
                                      )
                                    }
                                  />
                                </div>
                              </div>
                              <div
                                className="col-6 pl-0 my-auto"
                                onClick={() =>
                                  this.descargarArchivo(
                                    this.state.archivoCotizacion
                                  )
                                }
                              >
                                <span
                                  className="d-block cursor"
                                  style={{ wordBreak: "break-word" }}
                                >
                                  {this.state.archivoCotizacion.nombreDocto}
                                </span>
                              </div>
                              {this.props.store.reducer.negocio.estadoNegocio
                                .idEstadoNegocio < ESTADO_NEGOCIO_3 && (
                                  <Authorized roles={[VALOR_TRES, VALOR_SIETE]}>
                                    <div className="col-3 my-auto">
                                      <i
                                        className="fa fa-trash text-danger fa-2x cursor"
                                        aria-hidden="true"
                                        onClick={() =>
                                          this.eliminarArchivo(
                                            this.state.archivoCotizacion.idDocto
                                              ? this.state.archivoCotizacion.idDocto.split(
                                                ";"
                                              )[0]
                                              : null,
                                            "cotizacion"
                                          )
                                        }
                                      ></i>
                                    </div>
                                  </Authorized>
                                )}
                            </div>
                          </div>
                        )
                      )}
                      {this.state.cargandoCotizacion && (
                        <i
                          className="fa fa-circle-o-notch fa-spin fa-3x fa-fw d-block mx-auto"
                          style={{ color: "#ed8d00" }}
                        ></i>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {this.props.store.reducer.negocio.estadoNegocio.idEstadoNegocio <
                ESTADO_NEGOCIO_3 && (
                  <Authorized roles={[VALOR_TRES, VALOR_SIETE]}>
                    <div className="modal_foot mt-3">
                      <div className="button_zone">
                        {this.props.showCancelButton === undefined ||
                          this.props.showCancelButton ? (
                          <button
                            className="btn btn-principal color-cancel btn-sm cursor"
                            type="button"
                            onClick={() => {
                              this.props.onCancel();
                            }}
                          >
                            Cancelar
                          </button>
                        ) : null}

                        <button
                          className="btn btn-principal btn-sm"
                          type="button"
                          disabled={
                            this.props.disableSubmitButton ||
                            !this.state.fechaValida || this.state.loadingData
                          }
                          id="guardarArchivos"
                          onClick={async () => {
                            this.setState({ loadingData: true });

                            let cotizacion = this.state.archivosAdjuntos.cotizacion;
                            let cartaAceptacion = this.state.archivosAdjuntos.cartaAceptacion;

                            if (cotizacion) await this.enviarArchivo(cotizacion.dataEnvio, cotizacion.tipoCarga)
                            if (cartaAceptacion) await this.enviarArchivo(cartaAceptacion.dataEnvio, cartaAceptacion.tipoCarga)

                            await this.actualizarFechaAceptacionCotizacion();
                            this.setState({ loadingData: false });
                          }}
                        >
                          {this.state.loadingData ? (
                            <Fragment>
                              <i className="fa fa-spinner fa-pulse fa-fw"></i>
                              <span className="sr-only">Loading...</span>
                            </Fragment>
                          ) : (
                            <span>Guardar</span>
                          )
                          }
                        </button>
                      </div>
                    </div>
                  </Authorized>
                )}

              <Authorized roles={[4]}>
                <div className="modal_foot mt-3">
                  <div className="button_zone">
                    <button
                      className="btn btn-principal color-cancel btn-sm"
                      type="button"
                      onClick={() => {
                        this.props.onCancel();
                      }}
                    >
                      Continuar
                    </button>
                  </div>
                </div>
              </Authorized>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state,
});

export default withRouter(
  connect(mapStateToProps, { guardar_cotizacion })(ModalArchivosCotizacion)
);
