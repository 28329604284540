import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// import moment from "moment";

import Table from "../../../../transversales/DataTable";
import ModalEliminarAsegurableRRHH from "./ModalEliminarAsegurableRRHH";
import ModalEditarAsegurableRRHH from "./ModalEditarAsegurableRRHH";
import {
  agregarPolizaSoftwareAlianza,
  guardarAsegurableSeleccionadosPorSoftwareAlianza,
  guardarAsegurablesFiltroFecha,
  guardarAsegurablesFiltroOriginal,
  guardarAsegurablesFinalesSoftwareAlianza,
  guardarExistenEmpleadosSoftwareAlianza,
  guardarFechaVigenciaPoliza,
  guardarPolizasSeleccionadas,
  guardarSiEsPrimerRenderizadoTabla,
} from "../../../../../../reducer/delegadoMantencion";

class CargaAsegurablesRRHH extends Component {
  state = {
    atras: false,
    asegurables: [],
    asegurablesSeleccionadosPorPoliza: [],
    modalEditar: false,
    datosModalEditar: {},
    modalEliminar: false,
    datosModalEliminar: {},
    temaTabla: {
      title: {
        backgroundColor: "#262E33",
        height: "0px",
      },
      header: {
        fontSize: "12px",
        backgroundColor: "#262E33",
        fontColor: "white",
        fontWeight: "600",
      },
      rows: {
        backgroundColor: "white",
        borderWidth: "0px",
        height: "40px",
      },
    },
    columnDefs: [
      {
        name: "RUT",
        selector: "rutCompleto",
      },
      {
        name: "Nombres",
        selector: "nombres",
      },
      {
        name: "Apellido Paterno",
        selector: "apellidoPaterno",
      },
      {
        name: "Apellido Materno",
        selector: "apellidoMaterno",
      },
      {
        name: "Correo",
        selector: "correoElectronico",
      },
      {
        name: "Teléfono móvil",
        selector: "telefonoMovil",
      },
      {
        name: "Contratante",
        selector: "contratante",
      },
      {
        name: "Poliza",
        selector: "polizas",
      },
      {
        name: "Fecha Inicio Vigencia Solicitada",
        selector: "fechaInicioVigencia",
      },
      {
        name: "Opciones",
        selector: "opciones",
        cell: (row) => (
          <Fragment>
            <button
              className="btn btn-warning btn-sm btn-table mr-2"
              type="button"
              title="Editar"
              raised="true"
              primary="true"
              onClick={() => this.mostrarModalEditar(row)}
            >
              <i className="fa fa-pencil" aria-hidden="true"></i>
            </button>

            <button
              className="btn btn-danger btn-sm btn-table mr-2"
              type="button"
              title="Eliminar"
              raised="true"
              primary="true"
              onClick={() => this.mostrarModalEliminar(row)}
            >
              <i className="fa fa-trash" aria-hidden="true"></i>
            </button>
          </Fragment>
        ),
      },
    ],
  };

  componentDidMount() {
    this.setState({
      asegurables: this.props.store.delegado.softwareAlianza.asegurablesFinales,
    });
  }

  // Busqueda de filtrado
  busquedaReactivaAsegurados = (value) => {
    if (value) {
      const asegurables = Object.assign(
        [],
        this.props.store.delegado.softwareAlianza.asegurablesFinales
      );
      const busqueda = value.toLowerCase();
      const data = asegurables.filter((e) => {
        let rutCompleto = e.rutCompleto;
        let nombres = e.nombres.toLowerCase();
        let apellidoPaterno = e.apellidoPaterno.toLowerCase();
        let apellidoMaterno = e.apellidoMaterno.toLowerCase();
        return (
          nombres.indexOf(busqueda) > -1 ||
          rutCompleto.indexOf(busqueda) > -1 ||
          apellidoPaterno.indexOf(busqueda) > -1 ||
          apellidoMaterno.indexOf(busqueda) > -1
        );
      });
      this.setState({ asegurables: data });
    } else {
      this.setState({
        asegurables:
          this.props.store.delegado.softwareAlianza.asegurablesFinales,
      });
    }
  };

  // Levanta/cierra modal editar
  mostrarModalEditar = (rowData) => {
    this.setState({
      modalEditar: !this.state.modalEditar,
      datosModalEditar: rowData,
    });
  };

  // Ejecuta la accion de edicion el asegurado seleccionado (solo a nivel front)
  submitEditarAsegurable = async (values) => {
    const asegurableEditar = await values;
    const listadoAsegurables = await this.state.asegurables;
    const indexAsegurable = await listadoAsegurables.findIndex(
      (asegurable) => asegurable.id === asegurableEditar.id
    );
    const polizasEditadas = await asegurableEditar.polizas.reduce(
      (acumulador, { poliza }, index) => {
        return (
          acumulador +
          `${poliza.numeroPoliza}-${poliza.numeroRenovacion}${
            index !== asegurableEditar.polizas.length - 1 ? "," : ""
          }`
        );
      },
      ""
    );
    const nuevoAsegurable = {
      ...listadoAsegurables[indexAsegurable],
      polizas: await polizasEditadas,
      rutCompleto: await asegurableEditar.rutCompleto,
      nombres: await asegurableEditar.nombres,
      apellidoPaterno: await asegurableEditar.apellidoPaterno,
      apellidoMaterno: await asegurableEditar.apellidoMaterno,
      correoElectronico: await asegurableEditar.correoElectronico,
      telefonoMovil: (await asegurableEditar.telefonoMovil)
        ? await asegurableEditar.telefonoMovil
        : "",
      fechaInicioVigencia: await asegurableEditar.fechaInicioVigencia,
    };
    await listadoAsegurables.splice(indexAsegurable, 1, nuevoAsegurable);
    this.props.guardarAsegurablesFinalesSoftwareAlianza([
      ...listadoAsegurables,
    ]);
    this.setState({ asegurables: [...listadoAsegurables] });
  };

  // Levanta/cierra confirmacion eliminar
  mostrarModalEliminar = (rowData) => {
    this.setState({
      modalEliminar: !this.state.modalEliminar,
      datosModalEliminar: rowData,
    });
  };

  // Ejecuta la accion de eliminar el asegurado seleccionado (solo a nivel front)
  submitEliminarAsegurable = async (values) => {
    const asegurableEliminar = await values;
    const {
      asegurablesFinales,
      respuestaAsegurablesFiltroFecha,
      respuestaAsegurablesFiltroFechaOriginal,
    } = await this.props.store.delegado.softwareAlianza;
    this.mostrarModalEliminar({}); // Ayuda a cerrar correctamente la confirmacion de eliminar
    const asegurablesFinalesActualizado = await asegurablesFinales.filter(
      (asegurable) => asegurable.id !== asegurableEliminar.id
    );

    const indexAsegurableFiltro = respuestaAsegurablesFiltroFecha.findIndex(
      ({ id }) => id === asegurableEliminar.id
    );
    const indexAsegurableOriginal =
      respuestaAsegurablesFiltroFechaOriginal.findIndex(
        ({ id }) => id === asegurableEliminar.id
      );

    respuestaAsegurablesFiltroFecha[
      indexAsegurableFiltro
    ].preSeleccionado = false;
    respuestaAsegurablesFiltroFecha[
      indexAsegurableFiltro
    ].deshabilitado = false;
    respuestaAsegurablesFiltroFecha[indexAsegurableFiltro].polizas = "--------";
    respuestaAsegurablesFiltroFecha[indexAsegurableFiltro].fechaInicioVigencia =
      "--------";

    respuestaAsegurablesFiltroFechaOriginal[
      indexAsegurableOriginal
    ].preSeleccionado = false;
    respuestaAsegurablesFiltroFechaOriginal[
      indexAsegurableOriginal
    ].deshabilitado = false;
    respuestaAsegurablesFiltroFechaOriginal[indexAsegurableOriginal].polizas =
      "--------";
    respuestaAsegurablesFiltroFechaOriginal[
      indexAsegurableOriginal
    ].fechaInicioVigencia = "--------";

    document.getElementById("bsqReactiva").value = "";
    this.props.guardarAsegurablesFinalesSoftwareAlianza(
      asegurablesFinalesActualizado
    );
    this.props.guardarAsegurablesFiltroFecha([
      ...respuestaAsegurablesFiltroFecha,
    ]);
    this.props.guardarAsegurablesFiltroOriginal([
      ...respuestaAsegurablesFiltroFechaOriginal,
    ]);
    this.setState({ asegurables: asegurablesFinalesActualizado });
  };

  redireccionAtras = () => {
    this.setState({ atras: true });

    this.props.history.push({
      pathname: "/delegado/incorporacion",
    });
  };

  // onSubmit para cargar asegurables seleccionados
  cargarAsegurables = () => {};

  limpiarProps() {
    this.props.guardarFechaVigenciaPoliza(null);
    this.props.guardarPolizasSeleccionadas([]);
    this.props.guardarAsegurablesFiltroFecha([]);
    this.props.guardarAsegurablesFiltroOriginal([]);
    this.props.guardarAsegurablesFinalesSoftwareAlianza([]);
    this.props.guardarAsegurableSeleccionadosPorSoftwareAlianza([]);
    this.props.agregarPolizaSoftwareAlianza(false);
    this.props.guardarExistenEmpleadosSoftwareAlianza(false);
    this.props.guardarSiEsPrimerRenderizadoTabla(true);
  }

  componentWillUnmount() {
    if (!this.state.atras) {
      this.limpiarProps();
    }
  }

  render() {
    return (
      <Fragment>
        {/* Modal editar asegurable */}
        {this.state.modalEditar && (
          <ModalEditarAsegurableRRHH
            mostrarEditar={this.state.modalEditar}
            datosAsegurable={this.state.datosModalEditar}
            handleMostrarEditar={this.mostrarModalEditar}
            handleSubmitEditar={this.submitEditarAsegurable}
          />
        )}

        {/* Modal eliminar asegurable */}
        {this.state.modalEliminar && (
          <ModalEliminarAsegurableRRHH
            handleMostrarEliminar={this.mostrarModalEliminar}
            datosAsegurable={this.state.datosModalEliminar}
            handleSubmitDelete={this.submitEliminarAsegurable}
          />
        )}

        <div className="col-10 offset-sm-1">
          <div className="row">
            <div className="mb-4">
              <h2 className="text-muted font-weight-bold">
                Personas Cargadas a la poliza
              </h2>
            </div>
          </div>

          <div className="row">
            <div className="col-5 d-flex bg-white">
              <div className="col-8 d-flex align-items-start justify-content-center flex-column">
                <span className="ml-2 software-avatar-label">
                  Numero de trabajadores
                </span>
                <label className="ml-2 my-2 font-weight-bold">
                  {this.state.asegurables.length} Trabajadores
                </label>
              </div>
              <div className="col-4 d-flex justify-content-center align-items-center">
                <i className="fa fa-user-o software-avatar my-3"></i>
              </div>
            </div>

            <div className="col-5 offset-2">
              <input
                className="search-input w-100"
                type="text"
                id="bsqReactiva"
                onChange={(e) => {
                  this.busquedaReactivaAsegurados(e.target.value);
                }}
                placeholder="Buscar"
              />
            </div>
          </div>

          <div className="row shadow mt-3">
            <Table
              datos={this.state.asegurables}
              columnas={this.state.columnDefs}
              tema={this.state.temaTabla}
              campoOrden={"fila"}
              paginacion={true}
              paginacionPorPagina={10}
              paginacionOpciones={{
                rowsPerPageText: "Filas por página:",
                rangeSeparatorText: "de",
                noRowsPerPage: true,
              }}
              mostrarCheckBox={false}
            />
          </div>

          <div className="row mt-3">
            <div className="col-12 p-0 d-flex justify-content-end">
              <button
                className="btn btn-principal color-secundario mt-3"
                onClick={this.redireccionAtras}
              >
                Atrás
              </button>

              <button
                id="botonEnviar"
                type="button"
                className="btn btn-principal mt-3 ml-4"
                onClick={this.cargarAsegurables}
              >
                Enviar
              </button>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state,
});

export default withRouter(
  connect(mapStateToProps, {
    guardarFechaVigenciaPoliza,
    guardarPolizasSeleccionadas,
    guardarAsegurablesFiltroFecha,
    guardarAsegurablesFiltroOriginal,
    guardarAsegurablesFinalesSoftwareAlianza,
    guardarAsegurableSeleccionadosPorSoftwareAlianza,
    agregarPolizaSoftwareAlianza,
    guardarExistenEmpleadosSoftwareAlianza,
    guardarSiEsPrimerRenderizadoTabla,
  })(CargaAsegurablesRRHH)
);
