import React from "react";
import ReactDOM from "react-dom";

const logoVC = require("../../../../../assets/images/logo-white.png");

const ModalCarga = ({show, mensaje}) => {
  return ReactDOM.createPortal(
    <div className={`${show ? "modal_overlay show" : "modal_overlay"} animated fadeIn fadeOut`}>
      <div className="modal-carga">
        <div className="container d-flex h-100">
          <div className="align-self-center w-100">
            <img src={logoVC} height="60px" alt="logo_vida_camara_blanco" />
            <span className="d-block text-center">
              {mensaje ? mensaje : ""}
            </span>
          </div>
        </div>
      </div>
    </div>,
    document.querySelector(`#spinnerNaranjo`)
  );
};

export default ModalCarga;
