import React from "react";
import {
    COBERTURA_SEGURO_VIDA,
    CONSENTIMIENTO_SEGURO_DE_VIDA,
    ACEPTACION,
    ID_TIPO_POLIZA_VIDA,
    VALOR_VACIO,
    ID_PRODUCTO_VIDA,
} from "../../../../../utils/Constantes";

const SeguroVida = (props) => {
    
    let { coberturas }        = props.dataWizardAsegurado;
    let objCobertura          = coberturas.find(objCobertura => objCobertura.idProducto === ID_PRODUCTO_VIDA || ( objCobertura.tipoPoliza && objCobertura.tipoPoliza.idTipoPoliza === ID_TIPO_POLIZA_VIDA) );
    const segurosContratados  = props.informacionPoliza.segurosContratados;

    return (
        <React.Fragment>
            {
                segurosContratados !== "Salud" ?
                    <div>
                        <h6 className="bg-secondary text-white mt-3 p-2 linea-subtitulo font-weight-bold">
                            {COBERTURA_SEGURO_VIDA.toUpperCase()}
                        </h6>
                        <h6>
                            <strong>{CONSENTIMIENTO_SEGURO_DE_VIDA.toUpperCase()}</strong>
                        </h6>
                        <hr />
                        <div>
                            <p className="text-justify">
                                <strong>{ACEPTACION}</strong>
                                <br />
                                {objCobertura.consentimiento.catalogoConsentimiento.glosa}.
                                <br />
                            </p>
                            { objCobertura.consentimiento.negocioMantencion  && (
                            <table className="table table-sm md-8">
                                <thead>
                                    <tr>
                                        <th className="text-center">Nro Póliza</th>
                                        <th className="text-center">
                                            { objCobertura.consentimiento.capital > VALOR_VACIO && ( "Capital Asegurado" )}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="text-center">{objCobertura.consentimiento.poliza}</td>
                                        <td className="text-center">
                                            { objCobertura.consentimiento.capital > VALOR_VACIO && (
                                                objCobertura.consentimiento.capital
                                            )}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            )}
                        </div>
                        <br />
                    </div>
                    : null
            }
        </React.Fragment>
    );
};

export default SeguroVida;
