import React, { Component } from "react";
import { withRouter, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import {
  showMenu,
  mostrar_spinner,
  guardar_opt_gestionar_ejecutivo,
} from "../../../actions/actions";
import Collapse from "react-bootstrap/Collapse";
import {
  SUBMENU_GLOSA_NEGOCIOS,
  RUTA_COTIZACIONES,
  COLLAPSE,
  TIPO_INCORPORACION_MANTENCION,
  VALOR_ZERO,
  SUBMENU_GLOSA_GESTIONAR_EJECUTIVOS,
  STRING_VACIO,
  ACTIVE,
  SUBMENU_GLOSA_BAJAS,
  COD_EJECUTIVO_COMPLETO,
  T_NEGOCIO_ACTIVO,
  RUTA_INICIO_MANTENCION,
  PRIMERA_POSICION_LISTA,
  EVENTO_CLICK,
  CLASE_CERRADO,
  CLASE_ESCONDER_SUBMENU,
  CLASE_MOSTRAR_SUBMENU,
  SELECTOR_SUBMENU,
  PRIMERA_POSICION,
  VERDADERO,
  VALOR_NUMERAL,
  LARGO_LISTA_VACIO,
  FALSO,
  MENU_BAJAS_GLOSA,
  MENU_GLOSA_NEGOCIOS,
  STORAGE_ITEM_RUTA,
  STORAGE_ITEM_NEGOCIO_ACTIVO,
  STORAGE_ITEM_NEGOCIO,
  TRANSPARENTE,
  ANCHO_MONTRAR_MENU,
  TAMANO_UNO,
} from "../../../utils/Constantes";
import { Fragment } from "react";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      colapsar: FALSO,
      mostrarSubmenu: FALSO,
      colapsos: [],
    };
    if (this.props.store.auth.usuarioSesion.rol.codigo === COD_EJECUTIVO_COMPLETO) {
      this.state.colapsos.push(VERDADERO);
      for (let intIndice = PRIMERA_POSICION_LISTA; intIndice < this.props.store.auth.usuarioSesion.menu.length; intIndice++) {
        this.state.colapsos.push(FALSO);
      }
    }

    this.mostrarSubmenu = this.mostratSubmenu.bind(this);
    this.cerrarSubmenu = this.cerrarSubmenu.bind(this);
  }

  componentDidMount() {
    const lstsubmenu = document.querySelectorAll(SELECTOR_SUBMENU);

    lstsubmenu.forEach((objElemento) => {
      objElemento.addEventListener(EVENTO_CLICK, (objEvento) => {
        if (objEvento.currentTarget.classList.contains(CLASE_CERRADO)) {
          objEvento.currentTarget.children[PRIMERA_POSICION_LISTA].classList.remove(CLASE_ESCONDER_SUBMENU);
          objEvento.currentTarget.children[PRIMERA_POSICION_LISTA].classList.add(CLASE_MOSTRAR_SUBMENU);
        } else {
          objEvento.currentTarget.classList.add(CLASE_CERRADO);
          objEvento.currentTarget.children[PRIMERA_POSICION_LISTA].classList.remove(CLASE_MOSTRAR_SUBMENU);
          objEvento.currentTarget.children[PRIMERA_POSICION_LISTA].classList.add(CLASE_ESCONDER_SUBMENU);
        }
      });
    });
  }
  // mantencion ? this.state.colapsos[i] : this.state.colapsar
  expandirMenu(blnMantencion, intIndice) {
    if (blnMantencion) {
      return this.props.store.auth.usuarioSesion.rol.codigo === COD_EJECUTIVO_COMPLETO ? this.state.colapsos[intIndice] : VERDADERO;
    } 
    else {
      return this.state.colapsar;
    }
  }

  mostrarMenu(intIndice) {
    let lstColapsos        = this.state.colapsos;
    lstColapsos[intIndice] = !lstColapsos[intIndice];
    this.setState({ colapsos: lstColapsos });
  }

  activarMenu(objItem) {
    const { usuarioSesion } = this.props.store.auth;
    if (usuarioSesion.menu[PRIMERA_POSICION].glosa === objItem.glosa) {
      return ACTIVE;
    }
    return STRING_VACIO;
  }

  mostratSubmenu(objEvento) {
    this.setState({ mostrarSubmenu: VERDADERO }, () => {
      document.addEventListener(EVENTO_CLICK, this.cerrarSubmenu);
    });
  }

  cerrarSubmenu(objEvento) {
    if (!this.mostrarSubmenuBorrar.contains(objEvento.target)) {
      this.setState({ mostrarSubmenu: FALSO }, () => {
        document.removeEventListener(EVENTO_CLICK, this.cerrarSubmenu);
      });
    }
  }

  render() {
    const { usuarioSesion } = this.props.store.auth;
    const { tipoIncorporacion } = this.props.store.reducer;
    const blnMantencion = tipoIncorporacion === TIPO_INCORPORACION_MANTENCION;

    return (
      <div id="sidebar-menu">
        <div className="row pt-3 pb-3">
          <div className="col-12 text-center">
            <p className="font-weight-bold">{usuarioSesion.rol.glosa.toUpperCase()}</p>
            <div className="avatar mt-3">
              <i className="fa fa-user-circle-o" aria-hidden="true" />
            </div>
            <div className="font-weight-bold mt-4">{`${usuarioSesion.nombre.toUpperCase()} `}</div>
            <div className="mt-2">{usuarioSesion.descripcion && usuarioSesion.descripcion} </div>
          </div>
        </div>

        <ul className="mt-4">
          {usuarioSesion.menu && usuarioSesion.menu.map((objMenu, intIndice) => {
            let blnExisteSubmenu = objMenu.submenu && objMenu.submenu.length !== LARGO_LISTA_VACIO ? VERDADERO : FALSO;
            return (
              <li key={`sidebar_item_${objMenu.glosa}_${intIndice}`} className={blnExisteSubmenu ? "menu submenu closed" : "menu"}>
                <NavLink
                  onClick={() => {
                    if (window.innerWidth < ANCHO_MONTRAR_MENU)
                      this.props.showMenu(!this.props.store.reducer.show_menu);

                    if (blnExisteSubmenu) {
                      this.setState({ colapsar: !this.state.colapsar });
                    }
                    this.mostrarMenu(intIndice);
                  }}
                  exact={VERDADERO}
                  activeClassName={blnMantencion ? STRING_VACIO : this.activarMenu(objMenu)}
                  to={(usuarioSesion.menu.length > TAMANO_UNO && objMenu.link !== COLLAPSE) ||
                      (usuarioSesion.menu.length > LARGO_LISTA_VACIO && objMenu.link !== COLLAPSE && objMenu.link === RUTA_INICIO_MANTENCION)
                      ? objMenu.link : VALOR_NUMERAL
                  }
                >
                  <i className={objMenu.icon} aria-hidden="true" /> {objMenu.glosa}
                  {blnExisteSubmenu && (<span className="menu-arrow" />)}
                </NavLink>
                {blnExisteSubmenu && (
                  <Collapse in={this.expandirMenu(blnMantencion, intIndice)}>
                    <ul className="list-unstyled show-submenu">
                      {objMenu.submenu.map((objSubmenu, intIndice) => {
                        return (
                          <li key={`submenu_item_${objSubmenu.glosa}_${intIndice}`}>
                            <NavLink
                              id={`submenu${objSubmenu.glosa}`}
                              name="submenuSideBar"
                              exact={VERDADERO}
                              activeClassName="bg-submenu"
                              to={() => { return (objSubmenu.glosa !== MENU_BAJAS_GLOSA) ? objSubmenu.link : {} }}
                              onClick={() => {
                                if (objSubmenu.glosa === SUBMENU_GLOSA_NEGOCIOS) {

                                  if (sessionStorage.getItem(STORAGE_ITEM_RUTA) === RUTA_COTIZACIONES) {
                                    this.props.mostrar_spinner(TRANSPARENTE);
                                  }
                                    
                                  sessionStorage.setItem(STORAGE_ITEM_RUTA, MENU_GLOSA_NEGOCIOS);
                                  sessionStorage.setItem(STORAGE_ITEM_NEGOCIO_ACTIVO,T_NEGOCIO_ACTIVO);
                                  sessionStorage.removeItem(STORAGE_ITEM_NEGOCIO);
                                }
                                if (objSubmenu.glosa === SUBMENU_GLOSA_GESTIONAR_EJECUTIVOS) {
                                  this.props.guardar_opt_gestionar_ejecutivo(VALOR_ZERO);
                                }
                                if (objSubmenu.glosa === SUBMENU_GLOSA_BAJAS) {
                                  this.mostratSubmenu();
                                }
                              }}
                            >
                              <span className="menu-arrow-l" />
                              <span >{objSubmenu.glosa}</span>

                              {this.state.mostrarSubmenu ? objSubmenu.subMenu && (
                                <Fragment>
                                  <div className="mb-2" />
                                  <ul className="mostrarSubmenuBorrar" ref={(objElemento) => {this.mostrarSubmenuBorrar = objElemento; }}>
                                    {objSubmenu.subMenu.map((subMenuHijo) => {
                                      return (
                                        <li>
                                          <NavLink
                                            className="pl-4"
                                            id={`submenu${subMenuHijo.glosa}`}
                                            name="submenu"
                                            exact={VERDADERO}
                                            activeClassName="bg-submenu"
                                            to={subMenuHijo.link}
                                          >
                                            <span className="menu-arrow-l" />
                                            <span >{subMenuHijo.glosa} </span>
                                          </NavLink>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </Fragment>
                              )
                              : null}
                            </NavLink>
                          </li>
                        );
                      })}
                    </ul>
                  </Collapse>
                )}
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state,
});

export default withRouter(
  connect(mapStateToProps, {
    showMenu,
    mostrar_spinner,
    guardar_opt_gestionar_ejecutivo,
  })(Sidebar)
);
