import React, { useState, Fragment } from "react";
import { connect } from "react-redux";
import ReactDOM from "react-dom";
import { withRouter } from "react-router-dom";
import BlockRadioSelection from "../../../../transversales/BlockRadioSelection";
import { SECCION_ASEGURABLES_ADICIONALES } from "../../../../../../utils/Constantes";



const ModalRequisitosAsegurabilidad = (props) => {


    const [deshabilitarBotonAceptar, setDeshabilitarBotonAceptar] = useState(true);
    const [opcion, setOpcion] = useState(null);

    const seleccionaOpcion = (opcion) => {
        setDeshabilitarBotonAceptar(false);
        setOpcion(opcion);
    }

    return ReactDOM.createPortal(
        <div
            className={`${props.mostrarModal ? "modal_overlay show lbg" : "modal_overlay"}`}
        >
            <div className="container h-100">
                <div className="align-self-center w-100">
                    <div
                        className="main_modal animated bounceInLeft center-2 large"
                        style={{ maxWidth: "750px" }}
                    >
                        <div className="row">
                            <div className="col-md-12">
                                <div className="modal_title">
                                    <div className="title title font-weight-bold text-primary text-left">
                                        {props.titulo}
                                    </div>
                                    {true ? (
                                        <div
                                            className="button_close cursor"
                                            onClick={() => {
                                                props.cerrarModal();
                                            }}
                                        >
                                            <svg
                                                stroke="currentColor"
                                                fill="currentColor"
                                                viewBox="0 0 352 512"
                                                height="1em"
                                                width="1em"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z" />
                                            </svg>
                                        </div>
                                    ) : null}
                                </div>

                                <div className="modal_body py-2">    
                                    <div className="cuerpoRequisitoAsegurabilidad">
                                        <div><i className="fa fa-exclamation-triangle fa-4x mb-2"></i></div>
                                        LAMENTABLEMENTE NOS HEMOS PERCATADO DE QUE 
                                        {props.seccion === SECCION_ASEGURABLES_ADICIONALES ? 
                                        " EL ASEGURABLE DEPENDIENTE QUE DESEAS AGREGAR NO CUMPLE " : " NO CUMPLES "} 
                                        CON LOS REQUISITOS DE ASEGURABILIDAD DE EDAD DE LA PÓLIZA DE {props.textoPolizas}.
                                    </div>
                                    <div className="opcionesRequisitosAsegurabilidad">
                                        <div className="mb-4">
                                            Seleccione una de estas opciones
                                        </div>

                                        <BlockRadioSelection
                                            check={undefined}
                                            obtenerCheckValue={props.obtenerSeleccion}
                                            onClick={seleccionaOpcion}
                                            messages={props.messages}
                                        />

                                    </div>

                                </div>
                                <div className="modal_foot">

                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="button-zone text-right pt-3 pb-3 mr-5">
                                                    <button
                                                        id="cancelar"
                                                        onClick={() => {
                                                            props.cerrarModal();
                                                        }}
                                                        className="btn btn-principal color-cancel btn-sm"
                                                        type="button"
                                                    >
                                                        Cancelar
                                                    </button>
                                                <button
                                                    id="aceptar"
                                                    onClick={() => {
                                                        props.obtenerSeleccion(opcion);
                                                    }}
                                                    className="btn btn-principal btn-sm"
                                                    disabled={deshabilitarBotonAceptar || props.loadingDataRequisitos}
                                                >
                                                    {props.loadingDataRequisitos ? (
                                                        <Fragment>
                                                        <i className="fa fa-spinner fa-pulse fa-fw"></i>
                                                        <span className="sr-only">Loading...</span>
                                                        </Fragment>
                                                    ) : (
                                                        <span>Aceptar</span>
                                                    )}
                                                    
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>,
        document.querySelector(`#spinnerNaranjo`)
    );
};

const mapStateToProps = (state) => ({
    store: state,
});

export default withRouter(
    connect(mapStateToProps, {
    })(ModalRequisitosAsegurabilidad)
);
