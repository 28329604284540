import React, { Component } from "react";

export default class Footer extends Component {
  
  render() {
    return (
      <footer className="mt-auto">
        <div className="font-weight-bold">
          Compañia de Seguros Vida Cámara S.A
        </div>
        <p>Telefono: 600 320 0700 | www.vidacamara.cl</p>
      </footer>
    );
  }
}
