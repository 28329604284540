import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Table from "../../../../transversales/DataTable";
import {
  CAMPOS_TABLA_ERRORES,
  ERROR_CAMPO_VACIO,
  ERROR_USUARIO_AGREGADO,
  ERROR_USUARIO_DUPLICADO,
  ERROR_FORMATO_INCORRECTO,
  SUBSTRING_ASEGURABLE,
  VALOR_ZERO,
  TIPO_INCORPORACION_MANTENCION,
  ERROR_FECHA_INVALIDA,
  FECHA_INVALIDA,
  COLUMNA_FECHA_INICIO_VIGENCIA,
  ERROR_RANGO_VIGENCIA,
  ERROR_FECHA_VIGENCIA_RETROACTIVA,
  TELEFONO_MOVIL,
  ERROR_RUT_INVALIDO,
  ERROR_DUPLICIDAD_ASEGURABLE_POLIZA,
  ERROR_DUPLICIDAD_ASEGURABLE_NEGOCIO
} from "../../../../../../utils/Constantes";
import moment from "moment";

const iconoVacio = require("../../../../../assets/images/empty.svg");
const iconoUsuarioDuplicado = require("../../../../../assets/images/asegurable.svg");
const iconoFormatoErroneo = require("../../../../../assets/images/formato-erroneo.svg");
const iconoFechaVigenciaPoliza = require("../../../../../assets/images/fecha-vigencia-poliza.svg");
const iconoFechaVigenciaRetroactiva = require("../../../../../assets/images/fecha-vigencia-poliza.svg");

class TablaErrores extends Component {
  state = {
    errores: null,
    temaTabla: {
      title: {
        backgroundColor: "#262E33",
        height: "0px",
      },
      header: {
        fontSize: "14px",
        backgroundColor: "#262E33",
        fontColor: "white",
        fontWeight: "600",
      },
      rows: {
        backgroundColor: "white",
        borderWidth: "0px",
        height: "70px",
      },
    },
    columnDefs: [
      {
        name: "Fila",
        selector: "numeroLinea",
        maxWidth: "10px",
      },
      {
        name: "Nombre",
        selector: "nombre",
        maxWidth: "30px",
      },
      {
        name: "Apellido Paterno",
        selector: "apellidoPaterno",
        maxWidth: "30px",
      },
      {
        name: "Apellido Materno",
        selector: "apellidoMaterno",
        maxWidth: "30px",
      },
      {
        name: "Rut Colaborador",
        selector: "rutCompleto",
        maxWidth: "30px",
      },
      {
        name: "Correo",
        selector: "correoElectronico",
        maxWidth: "40px",
      },
      {
        name: "Teléfono",
        selector: TELEFONO_MOVIL,
        maxWidth: "40px",
      },
      {
        name: "Fecha Inicio Vigencia Propuesta",
        selector: "fechaInicioVigencia",
        maxWidth: "30px",
      },
      {
        name: "Errores",
        selector: "error",
        cell: (row) => this.mostrarCeldaErrores(row),
      },
    ],
    iconosErrores: [
      {
        icono: iconoVacio,
        descripcion: ERROR_CAMPO_VACIO,
      },
      {
        icono: iconoUsuarioDuplicado,
        descripcion: ERROR_USUARIO_AGREGADO,
      },
      {
        icono: iconoUsuarioDuplicado,
        descripcion: ERROR_DUPLICIDAD_ASEGURABLE_NEGOCIO,
      },
      {
        icono: iconoUsuarioDuplicado,
        descripcion: ERROR_DUPLICIDAD_ASEGURABLE_POLIZA,
      },
      {
        icono: iconoUsuarioDuplicado,
        descripcion: ERROR_USUARIO_DUPLICADO,
      },
      {
        icono: iconoFormatoErroneo,
        descripcion: ERROR_FORMATO_INCORRECTO,
      },
      {
        icono: iconoFormatoErroneo,
        descripcion: ERROR_FECHA_INVALIDA,
      },
      {
        icono: iconoFechaVigenciaPoliza,
        descripcion: ERROR_RANGO_VIGENCIA,
      },
      {
        icono: iconoFechaVigenciaRetroactiva,
        descripcion: ERROR_FECHA_VIGENCIA_RETROACTIVA,
      },
      {
        icono: iconoFormatoErroneo,
        descripcion: ERROR_RUT_INVALIDO,
      },
    ],
  };

  componentDidMount() {
    const errores =
      this.props.store.reducer.delegado.erroresCargaMasiva.personas;

    errores.forEach((error) => {
      if (error.rut !== VALOR_ZERO) {
        error.rutCompleto = `${error.rut}-${error.digitoVerificador}`;
      } else if (error.rut === VALOR_ZERO && error.digitoVerificador !== null) {
        error.rutCompleto = error.digitoVerificador;
      }

      if (error.fechaInicioVigencia) {
        if (moment(error.fechaInicioVigencia).isValid())
          error.fechaInicioVigencia = moment(error.fechaInicioVigencia).format(
            "DD/MM/YYYY"
          );
        else error.fechaInicioVigencia = FECHA_INVALIDA;
      }
    });

    this.setState({ errores });

    if (this.props.tipoIncorporacion !== TIPO_INCORPORACION_MANTENCION) {
      const columnas = this.state.columnDefs.filter(
        (col) => col.selector !== COLUMNA_FECHA_INICIO_VIGENCIA
      );
      this.setState({
        columnDefs: columnas,
      });
    }
  }

  mostrarCeldaErrores(row) {
    let sinDuplicados = [];

    row.errores.forEach((error) => {
      if (
        !(
          error.includes(SUBSTRING_ASEGURABLE) &&
          sinDuplicados.join(" ").includes(SUBSTRING_ASEGURABLE)
        )
      ) {
        sinDuplicados.push(error);
      }
    });
    
    return (
      <div
        className="row py-1"
        style={{
          minWidth:
            sinDuplicados.length > 6
              ? "440px"
              : sinDuplicados.length > 4
              ? "330px"
              : sinDuplicados.length > 2
              ? "220px"
              : "110px",
        }}
      >
        
        {sinDuplicados.map((error, i) => {
          const iconoError = this.state.iconosErrores.find(({ descripcion }) =>
            error.includes(descripcion)
          ).icono;

          let campoIncorrecto;

          if (
            iconoError === iconoFechaVigenciaPoliza ||
            iconoError === iconoFechaVigenciaRetroactiva
          ) {
            let arregloMensaje = error.split("(")[1];
            if (arregloMensaje)
              campoIncorrecto = arregloMensaje.substring(
                0,
                arregloMensaje.length - 1
              );
            arregloMensaje = error.split(",");
            if (arregloMensaje) campoIncorrecto = "Mes Actual";
          } else {
            campoIncorrecto = CAMPOS_TABLA_ERRORES.find((campo) =>
              error.toLowerCase().includes(campo.toLowerCase())
            );
          }

          return (
            <div
              key={i}
              className="col text-center"
              style={{ maxWidth: "55px" }}
            >
              <img alt="img-icon" width="20px" src={iconoError} />
              <div
                className="text-wrap"
                style={{
                  fontSize: "10px",
                }}
              >
                {campoIncorrecto && campoIncorrecto}
              </div>
              
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    return (
      this.state.errores && (
        <div className="shadow">
          <Table
            datos={this.state.errores}
            columnas={this.state.columnDefs}
            tema={this.state.temaTabla}
            campoOrden={"fila"}
            paginacion={true}
            paginacionPorPagina={10}
            paginacionOpciones={{
              rowsPerPageText: "Filas por página:",
              rangeSeparatorText: "de",
              noRowsPerPage: true,
            }}
          />
        </div>
      )
    );
  }
}

const mapStateToProps = (state) => ({
  store: state,
});

export default withRouter(connect(mapStateToProps, {})(TablaErrores));
