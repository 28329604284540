import {
  COLLAPSE,
  VALOR_ZERO,
  MENU_GLOSA_NEGOCIOS,
  SUBMENU_GLOSA_NEGOCIOS,
  RUTA_NEGOCIOS,
  MENU_GLOSA_ASEGURABLES,
  MENU_GLOSA_INICIO,
  SUBMENU_GLOSA_VER_ASEGURABLES,
  SUBMENU_GLOSA_INGRESAR_ASEGURABLES,
  RUTA_DELEGADO_INICIO,
  RUTA_VER_ASEGURABLES,
  RUTA_INGRESAR_ASEGURABLES,
  COD_MENU_NOMINA,
  ICON_HOME,
  ICON_USERS,
  SUBMENU_GLOSA_CREAR_NEGOCIO,
  RUTA_CREAR_NEGOCIO,
  MENU_GLOSA_MANTENCION,
  RUTA_INCORPORACION_CORREDOR,
  SUBMENU_GLOSA_INCORPORACION,
  SUBMENU_GLOSA_VER_SOLICITUDES,
  SUBMENU_GLOSA_PERMISOS,
  RUTA_VER_SOLICITUDES,
  RUTA_VER_SOLICITUDES_CORREDOR,
  COD_CORREDOR,
  SUBMENU_GLOSA_GESTIONAR_EJECUTIVOS,
  RUTA_GESTIONAR_EJECUTIVO,
  RUTA_EJECUTIVO_INCORPORACION,
  COD_EJECUTIVO_MANTENCION,
  COD_EJECUTIVO_COMPLETO,
  COD_DELEGADO,
  RUTA_EJECUTIVO_VER_SOLICITUDES,
  RUTA_EJECUTIVO_PERMISOS,
  SUBMENU_GLOSA_ELIMINACION,
  SUBMENU_GLOSA_EXCLUSION,
  RUTA_EJECUTIVO_ELIMINACION,
  RUTA_EJECUTIVO_EXCLUSION,
  RUTA_DELEGADO_INCORPORACION,
  RUTA_CORREDOR_EXCLUSION,
  RUTA_DELEGADO_EXCLUSION,
  RUTA_CORREDOR_ELIMINACION,
  RUTA_DELEGADO_ELIMINACION,
  RUTA_INICIO,
  RUTA_INICIO_MANTENCION,
  COD_MENU_INICIO_MANTENCION,
  MENU_BAJAS_CODIGO
} from "../utils/Constantes";

export function procesarMenu(lstMenu, intCodigoPerfil) {
  
  let objMenuResp = {};

  lstMenu.forEach( objMenu => {
    objMenu.menuItem = validarMenusFuncionales(objMenu.menuItem); // Quitar opciones aún no desarrolladas
    objMenuResp = obtenerElementosPadres(objMenu.menuItem, intCodigoPerfil);// Obtener los elementos padres
    objMenuResp = obtenerElementosHijos(objMenuResp, objMenu.menuItem, intCodigoPerfil);// Agregar elementos hijos a los padres
  })

  return objMenuResp;
}

function validarMenusFuncionales(lstMenu) {
  return lstMenu.filter((objElemento) => objElemento.itemCod !== COD_MENU_NOMINA);
}

function obtenerElementosPadres(lstMenu, intCodigoPerfil) {

  let objMenuPadre      = [];
  let objPrimerElemento = lstMenu.find((objElemento) => objElemento.itemCod === COD_MENU_INICIO_MANTENCION);

  if (objPrimerElemento) {
    let objMenuPrimerElemento = {
      idMenu: objPrimerElemento.itemCod,
      glosa: objPrimerElemento.itemDescripcion,
      link: obtenerLinkElementos(objPrimerElemento.itemDescripcion,intCodigoPerfil, objPrimerElemento.itemCod),
      icon: obtenerIconosMenu(objPrimerElemento.itemDescripcion),
      submenu: [],
    };
    objMenuPadre.push(objMenuPrimerElemento);
  }

  lstMenu.forEach((objMenu) => {
    if (objMenu.itemPadre === VALOR_ZERO && objMenu.itemCod !== COD_MENU_INICIO_MANTENCION) {
      let objMenuPrincipal = {
        idMenu: objMenu.itemCod,
        glosa: objMenu.itemDescripcion,
        link: obtenerLinkElementos(objMenu.itemDescripcion, intCodigoPerfil, objMenu.itemCod),
        icon: obtenerIconosMenu(objMenu.itemDescripcion),
        submenu: [],
      };
      objMenuPadre.push(objMenuPrincipal);
    }
  });

  return objMenuPadre;
}

function obtenerElementosHijos(lstMenuPadre, lstMenuHijo, intCodigoPerfil) {

  lstMenuPadre.forEach((objMenu) => {
    lstMenuHijo.forEach((objMenuHijo) => {

      if (objMenu.idMenu === objMenuHijo.itemPadre) {
        let objSubSubMenu;

        if (objMenuHijo.itemCod === MENU_BAJAS_CODIGO) {
          objSubSubMenu = lstMenuHijo.filter((objHijo) => objHijo.itemPadre === 13).map((objHijo) => {
            return {
              idMenu: objHijo.itemCod,
              glosa: objHijo.itemDescripcion,
              icon: objMenu.icon,
              link: obtenerLinkElementos(objHijo.itemDescripcion, intCodigoPerfil),
            };
          });
        }
        let objSubMenu = {
          idMenu: objMenuHijo.itemCod,
          glosa: objMenuHijo.itemDescripcion,
          icon: objMenu.icon,
          link: obtenerLinkElementos(objMenuHijo.itemDescripcion, intCodigoPerfil),
          subMenu: objSubSubMenu,
        };
        objMenu.submenu.push(objSubMenu);
      }
    });
  });

  return lstMenuPadre;
}

function obtenerLinkElementos(strItem, intCodigoPerfil, intCodigoItem) {

  switch (true) {
    case strItem === SUBMENU_GLOSA_NEGOCIOS:
      return RUTA_NEGOCIOS;

    case strItem === MENU_GLOSA_INICIO && intCodigoItem !== COD_MENU_INICIO_MANTENCION:
      return RUTA_INICIO;

    case strItem === MENU_GLOSA_INICIO && intCodigoItem === COD_MENU_INICIO_MANTENCION:
      return RUTA_INICIO_MANTENCION;

    case strItem === SUBMENU_GLOSA_CREAR_NEGOCIO:
      return RUTA_CREAR_NEGOCIO;

    case strItem === MENU_GLOSA_INICIO && intCodigoPerfil === COD_DELEGADO:
      return RUTA_DELEGADO_INICIO;

    case strItem === SUBMENU_GLOSA_VER_ASEGURABLES:
      return RUTA_VER_ASEGURABLES;

    case strItem === SUBMENU_GLOSA_INGRESAR_ASEGURABLES:
      return RUTA_INGRESAR_ASEGURABLES;

    case strItem === MENU_GLOSA_MANTENCION && intCodigoPerfil === COD_DELEGADO:
      return RUTA_INICIO_MANTENCION;

    case strItem === SUBMENU_GLOSA_INCORPORACION && intCodigoPerfil === COD_DELEGADO:
      return RUTA_DELEGADO_INCORPORACION;

    case strItem === SUBMENU_GLOSA_VER_SOLICITUDES && intCodigoPerfil === COD_DELEGADO:
      return RUTA_VER_SOLICITUDES;

    case strItem === MENU_GLOSA_MANTENCION && intCodigoPerfil === COD_CORREDOR:
      return RUTA_INICIO_MANTENCION;

    case strItem === SUBMENU_GLOSA_INCORPORACION && intCodigoPerfil === COD_CORREDOR:
      return RUTA_INCORPORACION_CORREDOR;

    case strItem === SUBMENU_GLOSA_VER_SOLICITUDES && intCodigoPerfil === COD_CORREDOR:
      return RUTA_VER_SOLICITUDES_CORREDOR;

    case strItem === SUBMENU_GLOSA_GESTIONAR_EJECUTIVOS:
      return RUTA_GESTIONAR_EJECUTIVO;

    case strItem === MENU_GLOSA_MANTENCION && (intCodigoPerfil === COD_EJECUTIVO_MANTENCION || intCodigoPerfil === COD_EJECUTIVO_COMPLETO):
      return RUTA_INICIO_MANTENCION;

    case strItem === SUBMENU_GLOSA_INCORPORACION && (intCodigoPerfil === COD_EJECUTIVO_MANTENCION || intCodigoPerfil === COD_EJECUTIVO_COMPLETO):
      return RUTA_EJECUTIVO_INCORPORACION;

    case strItem === SUBMENU_GLOSA_VER_SOLICITUDES && (intCodigoPerfil === COD_EJECUTIVO_MANTENCION || intCodigoPerfil === COD_EJECUTIVO_COMPLETO):
      return RUTA_EJECUTIVO_VER_SOLICITUDES;

    case strItem === SUBMENU_GLOSA_PERMISOS && (intCodigoPerfil === COD_EJECUTIVO_MANTENCION || intCodigoPerfil === COD_EJECUTIVO_COMPLETO):
      return RUTA_EJECUTIVO_PERMISOS;

    case strItem === SUBMENU_GLOSA_EXCLUSION:
      return subMenuBajas(intCodigoPerfil, strItem);

    case strItem === SUBMENU_GLOSA_ELIMINACION:
      return subMenuBajas(intCodigoPerfil, strItem);

    default:
      return COLLAPSE;
  }
}

function subMenuBajas(intCodigoPerfil, strElemento) {

  switch (intCodigoPerfil) {

    case COD_EJECUTIVO_MANTENCION:
      return strElemento === SUBMENU_GLOSA_EXCLUSION ? RUTA_EJECUTIVO_EXCLUSION : RUTA_EJECUTIVO_ELIMINACION;

    case COD_EJECUTIVO_COMPLETO:
      return strElemento === SUBMENU_GLOSA_EXCLUSION ? RUTA_EJECUTIVO_EXCLUSION : RUTA_EJECUTIVO_ELIMINACION;

    case COD_CORREDOR:
      return strElemento === SUBMENU_GLOSA_EXCLUSION ? RUTA_CORREDOR_EXCLUSION : RUTA_CORREDOR_ELIMINACION;

    case COD_DELEGADO:
      return strElemento === SUBMENU_GLOSA_EXCLUSION ? RUTA_DELEGADO_EXCLUSION : RUTA_DELEGADO_ELIMINACION;

    default:
      return null;
  }
}

function obtenerIconosMenu(strElemento) {

  switch (strElemento) {
    case MENU_GLOSA_NEGOCIOS:
      return ICON_HOME;

    case MENU_GLOSA_MANTENCION:
      return ICON_HOME;

    case MENU_GLOSA_INICIO:
      return ICON_HOME;

    case MENU_GLOSA_ASEGURABLES:
      return ICON_USERS;

    default:
      return "";
  }
}
