import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { notificacion } from "../../../../transversales/Notificaciones";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  CAMPO_REQUERIDO,
  VALIDACION_RUT,
  MASK_DATE,
  ID_ZERO,
  VALOR_ZERO,
  FECHA_FUERA_VIGENCIA,
  CHAR_S,
  VALOR_UNO,
  STRING_VACIO,
  NUMERO,
  RUT_SOLICITUD_DUPLICADO,
  VALIDACION_TIPO_ASEGURADO,
  VALIDACION_TIPO_EXCLUSION,
  VALOR_TITULAR,
  EXITO_SOLICITUD_EXCLUSION,
  OBJETO_PERFILES,
  VALOR_NUEVE,
  VALOR_CARGA,
  VALOR_DUPLICADO,
  ERROR_CREAR_SOLICITUD_EXCLUSION,
} from "../../../../../../utils/Constantes";
import {
  arrayToFormattedString,
} from "../../../../../../utils/Functions";
import {
  guardar_asegurados_delegado,
  guardar_cotizacion,
  actualizar_asegurables,
  guardar_negocios,
} from "../../../../../../actions/actions";
import {
  getMotivos
} from "../../../../../../services/asegurable.services";
import es from "date-fns/locale/es";
import DatePicker, { registerLocale } from "react-datepicker";
import MaskedTextInput from "react-text-mask";
import "react-datepicker/dist/react-datepicker.css";
import SelectContratante from "../SelectContratante";
import MultiSelect from "../../../../transversales/MultiSelect";
import moment from "moment";
import Spinner from "../../../../transversales/Spinner";
import { loadingAction } from "../../../../../../reducer/delegadoMantencion";
import DatosPersonalesAsegurado from "../../../../transversales/DatosPersonalesAsegurado";
import {
  buscarDatosPersonalesYPoliza,
  crearSolicitud,
  getTiposAsegurados
} from "../../../../../../services/asegurado.services";
import { errorBuilder } from "../../../../../../utils/ErrorBuilder";

registerLocale("es", es);

const { format, validate } = require("rut.js");

class CargaIndividualMantencionExclusion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idNegocio: 0,
      polizas: [],
      polizasSeleccionadas: [],
      fechaFinVigenciaNueva: null,
      idMotivoExclusion: null,
      solicitudes: [],
      data: {
        rutContratante: "",
        polizas: [],
        tipoAsegurado: undefined,
        rutAsegurado: "",
        fechaFinVigenciaNueva: undefined,
        idMotivoExclusion: undefined,
      },
      dataAsegurado: {
        mensajeError: "",
        polizas: []
      },
      formulario: {
        tipoAsegurado: "",
        rutAsegurado: "",
        polizas: [],
        msgFinVigencia: ''
      },
      polizasFechaErronea: [],
      stringPolizasFechaErronea: "",
      cargandoAsegurable: false,
      catalogo: {
        tiposAsegurados: [],
        motivos: []
      },
      mostrarModalDatosAsegurado: false,
      buscarDatosAseguradosTimer: undefined,
      perfil: "",
      idUsuario: undefined,
      resetForm: undefined,
    };
  }


  async componentDidMount() {
    const catalogo = {
      tiposAsegurados: await getTiposAsegurados(),
      motivos: await getMotivos()
    }
    this.setState({ catalogo })
  }

  obtenerPolizas = async (
    idNegocio,
    polizas,
    polizasFiltro,
    rutContratante
  ) => {
    const { usuarioSesion } = this.props.store.auth;
    this.setState({
      idNegocio,
      polizas,
      rutContratante: Number(rutContratante.split("-")[0]),
      perfil: OBJETO_PERFILES.find(perfil => perfil.codigo === usuarioSesion.rol.codigo).nombre,
      idUsuario: usuarioSesion.id,
      mostrarModalDatosAsegurado: false
    });

    //Limpiar campos del formik
    if (this.state.resetForm) {
      this.state.resetForm({});
    }
  };

  async enviarDatosAsegurado(values) {


    const { polizas, tipoAsegurado, fechaFinVigenciaNueva, idMotivoExclusion } = values;

    const polizasAseguradoIncorporado = this.state.dataAsegurado.polizas.filter(({ poliza }) => !poliza.mensajeError);

    const polizasAsegurado = tipoAsegurado === VALOR_TITULAR ?
      polizasAseguradoIncorporado.map(polizaAsegurado => polizaAsegurado.poliza) :
      polizasAseguradoIncorporado.map(polizaAsegurado => polizaAsegurado.cargas[0].poliza);

    const requests = polizas.map(({ poliza }) => {

      const polizaAsegurado = polizasAsegurado.find(
        ({ nroRenovacion, nroPoliza }) => poliza.numeroRenovacion === nroRenovacion &&
          poliza.numeroPoliza === nroPoliza);
      return polizaAsegurado
    })
      .filter(polizaAsegurado => !!polizaAsegurado)
      .map(polizaAsegurado => {
        const objIdAseguradoPoliza = tipoAsegurado === VALOR_TITULAR ?
          { idAseguradoPoliza: polizaAsegurado.idAseguradoPoliza } :
          { idAseguradoPolizaCarga: polizaAsegurado.idAseguradoPolizaCarga }



        return {
          aseguradoPoliza: {
            motivo: {
              id: Number(idMotivoExclusion),
            },
            idUsuarioCrea: this.state.idUsuario,
            fechaFinVigencia: moment(fechaFinVigenciaNueva, "DD/MM/YYYY").format("YYYY-MM-DD"),
            ...objIdAseguradoPoliza
          }
        }
      })
    this.props.loadingAction(true);
    const request = {
      aseguradoPolizas: requests
    }
    const response = await crearSolicitud(request, this.state.perfil);
    const statusResponse = new errorBuilder(response);
    this.props.loadingAction(false)

    if (!statusResponse.error) {
      notificacion(
        "success",
        EXITO_SOLICITUD_EXCLUSION
      );
    } else {
      let msjError = statusResponse.data;
      if (msjError.includes(VALOR_DUPLICADO)) {
        msjError = ERROR_CREAR_SOLICITUD_EXCLUSION;
      }
      notificacion("danger", msjError);
    }

    this.setState({ mostrarModalDatosAsegurado: false });
  }

  validarFechaInicioYFinPoliza = (poliza, nuevaFechaFinVigencia) => {
    return this.validarFechaFinPoliza(poliza, nuevaFechaFinVigencia) ||
      nuevaFechaFinVigencia < moment(poliza.fechaInicioVigencia).format("YYYY-MM-DD")

  }

  validarFechaFinPoliza = (poliza, nuevaFechaFinVigencia) => {
    return nuevaFechaFinVigencia > moment(poliza.fechaFinVigencia).format("YYYY-MM-DD");
  }

  validarFechaPoliza = (polizas, nuevaFechaFinVigencia) => {

    const validarFechaInicioVigencia = !!polizas.find(({ poliza }) => poliza.fechaInicioVigencia);

    return polizas
      .filter(
        ({ poliza }) => {
          if (validarFechaInicioVigencia) {
            return this.validarFechaInicioYFinPoliza(poliza, nuevaFechaFinVigencia)
          }
          const validar = this.validarFechaFinPoliza(poliza, nuevaFechaFinVigencia);
          return validar;
        }
      )
      .map(({ poliza }) => poliza.numeroPoliza);
  }

  validarFechaVigencia = (value) => {
    if (value) {
      const fecha = value.split("/");
      const fechaFinVigencia = `${fecha[2]}-${fecha[1]}-${fecha[0]}`;


      //Polizas contratante
      let polizasFechaErronea = this.validarFechaPoliza(this.state.polizasSeleccionadas, fechaFinVigencia);

      const msgValidaciones = {
        contratante: true,
        titular: false,
        carga: false,
      }

      const polizasAseguradoIncorporado = this.state.dataAsegurado.polizas.filter(({ poliza }) => !poliza.mensajeError);

      if (polizasFechaErronea.length) {
        msgValidaciones.contratante = true;
      } else if (this.validarFechaPoliza(polizasAseguradoIncorporado, fechaFinVigencia).length) {
        msgValidaciones.titular = true;
        polizasFechaErronea = this.validarFechaPoliza(polizasAseguradoIncorporado, fechaFinVigencia);
      } else if (this.state.formulario.tipoAsegurado === VALOR_CARGA) {
        const polizasCarga = polizasAseguradoIncorporado.map(poliza => poliza.cargas[0]);
        polizasFechaErronea = this.validarFechaPoliza(polizasCarga, fechaFinVigencia);
        msgValidaciones.carga = !!polizasFechaErronea.length
      }

      const stringPolizasFechaErronea = arrayToFormattedString(
        polizasFechaErronea
      );

      //Polizas titular

      const validacionFecha = `${FECHA_FUERA_VIGENCIA}${polizasFechaErronea.length > VALOR_UNO ? CHAR_S : STRING_VACIO
        } ${NUMERO} ${stringPolizasFechaErronea} ${msgValidaciones.titular ? ' del titular' : STRING_VACIO} 
        ${msgValidaciones.carga ? ' de la carga' : STRING_VACIO}.`;


      this.setState({
        formulario: {
          ...this.state.formulario,
          msgFinVigencia: polizasFechaErronea.length ? validacionFecha : '',
        }
      });

      return polizasFechaErronea.length === VALOR_ZERO;
    }
  };

  rutUnico = (value) => {
    if (this.state.solicitudes && value) {
      const noEnrolados = this.state.solicitudes.filter(
        (item) => item.solicitud.fechaRecepcion === null
      );
      const asegurablesGrupo = noEnrolados.filter((asegurable) => {
        const incluyeGrupo = this.state.polizasSeleccionadas.find((poliza) => {
          const seleccionadoIncluyeGrupo = asegurable.grupos.find(
            (grupoS) =>
              grupoS.idContratoSeguroGrupo === poliza.idContratoSeguroGrupo
          );
          return seleccionadoIncluyeGrupo !== undefined;
        });
        return incluyeGrupo !== undefined;
      });

      const rutAsegurables = asegurablesGrupo.map((item) =>
        format(`${item.rut}-${item.digitoVerificador}`)
      );

      return !rutAsegurables.includes(format(value));
    }
  };

  obtenerDatosAsegurado = async (formikValues) => {

    const { polizas, rutAsegurado, tipoAsegurado } = formikValues;

    const existenCamposRequeridosParaObtenerDatosAsegurado = !!(polizas && validate(format(rutAsegurado)) && tipoAsegurado);

    const rutFormateado = format(rutAsegurado).replaceAll(".", "")

    this.setState({
      formulario: {
        tipoAsegurado: tipoAsegurado,
        rutAsegurado: rutFormateado,
        polizas
      }
    })

    if (existenCamposRequeridosParaObtenerDatosAsegurado && rutFormateado.length >= VALOR_NUEVE) {

      const camposDatosAseguradoHaCambiado = !(this.state.formulario.tipoAsegurado === tipoAsegurado &&
        this.state.formulario.rutAsegurado === rutFormateado &&
        this.state.formulario.polizas &&
        polizas &&
        this.state.formulario.polizas.length === polizas.length)

      if (camposDatosAseguradoHaCambiado) {
        this.setState({ mostrarModalDatosAsegurado: false });
        clearTimeout(this.state.buscarDatosAseguradosTimer);
        this.setState({
          buscarDatosAseguradosTimer: setTimeout(async () => {
            const request = {
              tipo: tipoAsegurado,
              rut: rutFormateado,
              polizas: polizas.map(({ poliza }) => ({ nroPoliza: poliza.numeroPoliza, nroRenovacion: poliza.numeroRenovacion }))
            }

            this.props.loadingAction(true);
            const respuesta = errorBuilder(await buscarDatosPersonalesYPoliza(request, this.state.perfil));
            this.props.loadingAction(false);

            if (!respuesta.error) {

              let polizasAseguradoNoIncoporado = [];

              //La cantidad de polizas del request no son la mismas que las respuestas del servicio
              if (respuesta.data.length !== request.polizas.length) {
                polizasAseguradoNoIncoporado = request.polizas.map(poliza => ({ ...poliza }))
                for (const data of respuesta.data) {
                  const polizaRespuesta = data.poliza;
                  polizasAseguradoNoIncoporado = polizasAseguradoNoIncoporado.filter(({ nroPoliza, nroRenovacion }) =>
                    polizaRespuesta.nroPoliza !== nroPoliza || polizaRespuesta.nroRenovacion !== nroRenovacion
                  )
                }
              }

              for (const polizaAseguradoNoIncorporado of polizasAseguradoNoIncoporado) {
                const { nroPoliza, nroRenovacion } = polizaAseguradoNoIncorporado;
                respuesta.data.push({
                  poliza: {
                    mensajeError: `Asegurado no se encuentra en la poliza ${nroPoliza}-${nroRenovacion}`
                  }
                })
              }
              this.setState({
                mostrarModalDatosAsegurado: true,
                dataAsegurado: {
                  mensajeError: "",
                  polizas: respuesta.data
                }
              });

            } else {

              this.setState({
                mostrarModalDatosAsegurado: true,
                dataAsegurado: {
                  mensajeError: respuesta.data
                }
              });
            }

          }, 500)
        })
      }
    } else {
      this.setState({
        mostrarModalDatosAsegurado: false,
        mensajeError: "",
      })
    }
  }


  render() {
    const cargandoAsegurable = this.props.store.delegado.loading;

    return (
      <React.Fragment>
        {cargandoAsegurable && (
          <Spinner
            claseColor="transparente"
            idSpinner="spinnerTransparente"
            posicionLogo="posicionLogo align-self-center w-100"
          />
        )}

        {!this.state.cargando ? (
          <div className="container">
            <div className="mx-5">
              <div className="row animated fadeInRight">
                <SelectContratante obtenerPolizas={this.obtenerPolizas} />

                {this.state.idNegocio !== ID_ZERO && (
                  <Formik
                    enableReinitialize={true}
                    initialValues={{
                      polizas: [],
                      tipoAsegurado: "",
                      rutAsegurado: "",
                      fechaFinVigenciaNueva: undefined,
                      idMotivoExclusion: "",
                    }}
                    validationSchema={Yup.object().shape({
                      polizas: Yup.array().required(CAMPO_REQUERIDO).nullable(),
                      tipoAsegurado: Yup.string()
                        .required(CAMPO_REQUERIDO)
                        .test("tipoAsegurado", VALIDACION_TIPO_ASEGURADO, (value) => {
                          return !!value && !!value.trim();
                        }),
                      rutAsegurado: Yup.string()
                        .required(CAMPO_REQUERIDO)
                        .test("validarRut", VALIDACION_RUT, (value) => {
                          return validate(format(value));
                        })
                        .test(
                          "rutDuplicado",
                          RUT_SOLICITUD_DUPLICADO,
                          this.rutUnico
                        ),
                      fechaFinVigenciaNueva: Yup.string()
                        .required(CAMPO_REQUERIDO)
                        .test(
                          "validarFecha",
                          this.state.formulario.msgFinVigencia,
                          this.validarFechaVigencia
                        ),
                      idMotivoExclusion: Yup.number()
                        .required(CAMPO_REQUERIDO)
                        .test("idMotivoExclusion", VALIDACION_TIPO_EXCLUSION, (value) => {
                          return Number(value) !== 0;
                        }),
                    })}
                    onSubmit={async (values, { resetForm }) => {
                      this.props.loadingAction(true);
                      this.enviarDatosAsegurado(values).then(() =>
                        this.props.loadingAction(false)
                      );
                      resetForm({});
                    }}
                    validate={(values) => this.obtenerDatosAsegurado(values)}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      setFieldValue,
                      setFieldTouched,
                      handleChange,
                      handleBlur,
                      resetForm
                    }) => {

                      if (!this.state.resetForm) {
                        this.setState({
                          resetForm: resetForm
                        })
                      }
                      return (
                        <Form
                          id="idFormIndividualMantencionExclusion"
                          className="col-md-12"
                          onKeyDown={(e) => {
                            if ((e.charCode || e.keyCode) === 13) {
                              e.preventDefault();
                            }
                          }}
                        >
                          <div className="row">
                            {/* póliza */}
                            <div className="col-md-12">
                              <div className="form-group">
                                <label>Póliza</label>
                                <MultiSelect
                                  value={values.polizas}
                                  onChange={(value) => {
                                    this.setState({
                                      polizasSeleccionadas: value,
                                    });
                                    setFieldValue("polizas", value);
                                  }}
                                  tooltip={true}
                                  onBlur={setFieldTouched}
                                  name="polizas"
                                  options={this.state.polizas}
                                  noOptionsMessage={() =>
                                    "No existen más pólizas para este contratante"
                                  }
                                  placeholder={"Ingrese póliza/s"}
                                  errors={errors.polizas}
                                  touched={touched.polizas}
                                />
                              </div>
                            </div>


                            {/* Tipo Asegurado */}
                            <div className="col-md-12">
                              <div className="form-group up-calendar">
                                <label>Tipo de Asegurado</label>

                                <select
                                  id="tipoAsegurado"
                                  name="tipoAsegurado"
                                  value={values.tipoAsegurado}
                                  onChange={(event) => {
                                    handleChange(event);
                                  }}
                                  onBlur={handleBlur}
                                  className="form-control"
                                >
                                  {<option value="">Seleccione tipo de asegurado</option>}
                                  {this.state.catalogo.tiposAsegurados.map(
                                    (item, index) => (
                                      <option
                                        key={`tipoAsegurado_${index}`}
                                        value={item.glosa}
                                        id={`opcion${item.glosa}`}
                                      >
                                        {item.glosa}
                                      </option>
                                    )
                                  )}
                                </select>

                                {errors.tipoAsegurado &&
                                  touched.tipoAsegurado && (
                                    <small className="text-danger animated fadeIn">
                                      {errors.tipoAsegurado}
                                    </small>
                                  )}
                              </div>
                            </div>

                            {/* rutAsegurado */}
                            <div className="col-md-12">
                              <div className="form-group">
                                <label>Rut asegurado</label>
                                <Field
                                  className="form-control"
                                  id="rutAsegurado"
                                  name="rutAsegurado"
                                  type="text"
                                  placeholder="ej. 12345678-9"
                                  maxLength="12"
                                  minLength="11"
                                  value={
                                    values.rutAsegurado.length > 1
                                      ? format(values.rutAsegurado)
                                      : values.rutAsegurado
                                  }
                                />
                                {errors.rutAsegurado && touched.rutAsegurado && (
                                  <small className="text-danger animated fadeIn">
                                    {errors.rutAsegurado}
                                  </small>
                                )}
                              </div>
                            </div>

                            {
                              this.state.mostrarModalDatosAsegurado &&
                              <React.Fragment>
                                {/* Datos personales del asegurado */}
                                <div className="col-md-12">
                                  <h5 className="font-weight-bold text-primary">Información actual asegurado</h5>
                                  <DatosPersonalesAsegurado errorDataAsegurado={this.state.dataAsegurado.mensajeError}
                                    polizas={this.state.dataAsegurado.polizas}
                                    tipoAsegurado={this.state.formulario.tipoAsegurado} />
                                </div>

                                {!this.state.dataAsegurado.mensajeError &&
                                  <React.Fragment>

                                    <div className="col-md-12">
                                      <div className="form-group up-calendar">
                                        <label>Fecha fin vigencia nueva</label>

                                        <DatePicker
                                          autoComplete="off"
                                          peekNextMonth
                                          showMonthDropdown
                                          showYearDropdown
                                          dropdownMode="select"
                                          id="fechaFinVigenciaNueva"
                                          name="fechaFinVigenciaNueva"
                                          value={values.fechaFinVigenciaNueva}
                                          selected={!values.fechaFinVigenciaNueva ? '' : moment(values.fechaFinVigenciaNueva, 'DD/MM/YYYY').toDate()}
                                          dateFormat="dd/MM/yyyy"
                                          placeholderText="DD/MM/YYYY"
                                          locale="es"
                                          onBlur={handleBlur}
                                          onChange={(value) => {
                                            value = moment(value).format("DD/MM/YYYY");
                                            this.setState({
                                              fechaFinVigenciaNueva: value,
                                            });
                                            setFieldValue("fechaFinVigenciaNueva", value);
                                          }}
                                          customInput={
                                            <MaskedTextInput
                                              className="form-control"
                                              placeholder="DD/MM/YYYY"
                                              mask={MASK_DATE}
                                            />
                                          }
                                        />

                                        {(errors.fechaFinVigenciaNueva || this.state.formulario.msgFinVigencia) &&
                                          touched.fechaFinVigenciaNueva && (
                                            <small className="text-danger animated fadeIn">
                                              {this.state.formulario.msgFinVigencia ? this.state.formulario.msgFinVigencia : errors.fechaFinVigenciaNueva}
                                            </small>
                                          )}
                                      </div>
                                    </div>

                                    {/* Motivo exclusión */}
                                    <div className="col-md-12">
                                      <div className="form-group up-calendar">
                                        <label>Motivo exclusión</label>

                                        <select
                                          id="idMotivoExclusion"
                                          name="idMotivoExclusion"
                                          value={values.idMotivoExclusion}
                                          onChange={(event) => {
                                            handleChange(event);
                                          }}
                                          onBlur={handleBlur}
                                          className="form-control"
                                        >
                                          {<option value="0" >Seleccione motivo de exclusión</option>}
                                          {this.state.catalogo.motivos.map(
                                            (item, index) => (
                                              <option
                                                key={`motivo_${index}`}
                                                value={item.id}
                                                id={`opcion${item.glosa}`}
                                              >
                                                {item.glosa}
                                              </option>
                                            )
                                          )}
                                        </select>

                                        {errors.idMotivoExclusion &&
                                          touched.idMotivoExclusion && (
                                            <small className="text-danger animated fadeIn">
                                              {errors.idMotivoExclusion}
                                            </small>
                                          )}
                                      </div>
                                    </div>
                                  </React.Fragment>
                                }
                              </React.Fragment>
                            }
                          </div>
                          <div className="button-zone text-right pt-3 pb-3">
                            <button
                              id="botonLimpiar"
                              className="btn btn-principal mr-3"
                              onClick={() => resetForm({})}
                            >
                              Cancelar
                            </button>
                            <button
                              id="botonGuardar"
                              className="btn btn-principal mr-3"
                              type="submit"
                            >
                              Guardar
                            </button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="d-flex justify-content-center animated fadeIn my-5">
            <div className="spinner-border text-primary mb-5" role="status">
              <span className="sr-only">Cargando...</span>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state,
});

export default withRouter(
  connect(mapStateToProps, {
    guardar_asegurados_delegado,
    guardar_cotizacion,
    actualizar_asegurables,
    guardar_negocios,
    loadingAction,
  })(CargaIndividualMantencionExclusion)
);
