import React, { Fragment, useEffect } from "react";
import { Form } from "react-bootstrap";
import { useAutenticacion } from "../../../eventos/useAutenticacion";
import { crearEventoTraza } from "../../../utils/analitica";
import {
  GA_CATEGORIA_LOGIN,
  GA_LABEL_ESCOGER_PERFIL,
} from "../../../utils/Constantes";

const BotonPerfil = ({ objPerfil, guardarSeleccionPerfil }) => {
  return (
    <button
      id={`botonInicioSesion${objPerfil.perfil}`}
      className={`btn btn-login w-100 mt-3`}
      onClick={() => guardarSeleccionPerfil(objPerfil)}
    >
      <i
        className={`fa fa-heart-o animated fadeIn mr-2`}
        aria-hidden="true"
      ></i>
      {objPerfil.perfil}
    </button>
  );
};

export const EscogerPerfil = () => {
  const { perfiles, guardarSeleccionPerfil } = useAutenticacion();

  useEffect(() => {
    crearEventoTraza(GA_CATEGORIA_LOGIN, GA_LABEL_ESCOGER_PERFIL);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <p className="font-weight-bold text-muted mt-3">Iniciar sesión</p>
      {perfiles.map((objPerfil, intIndice) => (
        <Form.Group key={intIndice} controlId={`formBasic${intIndice}`}>
          <BotonPerfil
            objPerfil={objPerfil}
            guardarSeleccionPerfil={guardarSeleccionPerfil}
          />
        </Form.Group>
      ))}
    </Fragment>
  );
};
