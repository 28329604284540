import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import uuid from "uuid";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  eliminarDataWizardMongo,
  finalizarSolicitud,
  guardarDataWizard,
  obtenerConfiguracionParentesco,
  validarRequisitosAsegurabilidad,
} from "../../../../../services/asegurable.services";
import { animateScroll as scroll } from "react-scroll";
import {
  add_carga,
  update_carga,
  wizard,
  actualizar_progreso_asegurable,
  declarar_carga,
  update_detalle,
  guardar_data_asegurable,
  get_generos,
  guardar_data_wizard_asegurado,
  actualizar_beneficiarios,
} from "../../../../../actions/actions";
import Table from "../../../transversales/DataTable";
import { notificacion } from "../../../transversales/Notificaciones";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Modal from "../../../transversales/Modal";
import "moment/locale/es";
import {
  CAMPO_REQUERIDO_NOMBRE,
  CAMPO_REQUERIDO_RUT,
  CAMPO_REQUERIDO_APELLIDO_PATERNO,
  CAMPO_REQUERIDO_GENERO,
  CAMPO_REQUERIDO_PARENTESCO,
  CAMPO_REQUERIDO_PESO,
  CAMPO_REQUERIDO_ESTATURA,
  CAMPO_REQUERIDO_FECHA,
  VALIDACION_RUT,
  RUT_DUPLICADO_CARGAS,
  RUT_TITULAR,
  CONYUGUE_UNICO,
  PADRE_UNICO,
  MADRE_UNICA,
  VALIDACION_GENERO,
  VALIDACION_PARENTESCO,
  ERROR_SOLICITUD,
  VALIDACION_SELECT,
  EXITO_CARGAS,
  EXITO_UPDATE_CARGA,
  ALERTA_NO_CARGAS,
  MAX_E_A,
  PARENTESCO_MADRE,
  PARENTESCO_PADRE,
  MASK_DATE,
  TYPEOF_STRING,
  SIN_INDEX,
  ID_ZERO,
  PARENTESCO_HIJO,
  PARENTESCO_HIJO_ESTUDIANTE,
  DATA_FECHA_MIN_DEFAULT,
  CAMPO_REQUERIDO_SISTEMA_PREVISIONAL,
  STRING_VACIO,
  STRING_RUT,
  DIGITO_VERIFICADOR,
  TIPO_PAGINA,
  TIPO_TAB,
  STRING_ON,
  VALOR_ZERO,
  PAGINA_3,
  VALOR_UNO,
  PAGINA_1,
  PARENTESCO_CONYUGUE,
  PARENTESCO_CONVIVIENTE_CIVIL,
  PARENTESCO_CONVIVIENTE_NO_CIVIL,
  MAYORIA_EDAD,
  QUINCE_YEARS,
  CONYUGUE_DISTINTO_POLIZA_ANTERIOR,
  PADRE_DISTINTO_POLIZA_ANTERIOR,
  MADRE_DISTINTO_POLIZA_ANTERIOR,
  NOTIFICACION_SIN_OPCIONES,
  ID_SOLICITUD_FINALIZADA_POR_ASEGURABLE,
  FINALIZAR_PROCESO,
  SOLICITAR_REEVALUACION,
  CONTINUAR_SIN_CARGA,
  SECCION_ASEGURABLES_ADICIONALES,
  FLUJO_VENTA_DIGITAL,
  FLUJO_MANTENCION,
  DOCUMENT_TYPE_TEMPORAL,
  VALIDACION_DOCUMENTO_CARGA,
  TIPO_CANAL_VENTA_CORREDOR,
  TIPO_CANAL_VENTA_DIRECTA,
  FLUJO_VENTA_DIRECTA,
  SIN_PESO,
  SIN_ALTURA,
  TIPO_INCORPORACION_CARGA,
  POSICION_INICIAL,
  LARGO_CERO_AGRUPACIONES,
  TIPO_CANAL_VENTA_DIGITAL,
  SIN_VALOR_PLAN,
  ID_PRODUCTO_COBERTURA,
  ID_PRODUCTO_COMPL_SALUD,
  VALIDACION_APELLIDO_M_MAXIMO,
  VALIDACION_APELLIDO_M,
  VALIDACION_APELLIDO_P,
  VALIDACION_APELLIDO_P_MAXIMO,
  VALIDACION_NOMBRE,
  VALIDACION_NOMBRE_MAXIMO,
  GA_LABEL_CARGAS,
  GA_CATEGORIA_FORMULARIO_ENROLAMIENTO,
} from "../../../../../utils/Constantes";
import {
  formatNumber,
  soloLetras,
  verificarSiExisteObjetoEnArreglo,
} from "../../../../../utils/Functions";

import es from "date-fns/locale/es";
import DatePicker, { registerLocale } from "react-datepicker";

import MaskedTextInput from "react-text-mask";
import "react-datepicker/dist/react-datepicker.css";
import { Fragment } from "react";
import ModalRequisitosAsegurabilidad from "../datosPersonales/misDatos/ModalRequisitosAsegurabilidad";
import {
  documentoRequeridoPorParentesco,
  obtenerDocumentosDeCargas,
  setearDocumentosCargas,
} from "./CargaValidator";
import CargarArchivoParentesco, {
  enviarArchivos,
} from "../cargas/CargarArchivoParentesco";
import { listarPorNroPolizaNroRenovacion } from "../../../../../services/bus.poliza.services";
import { crearEventoTraza } from "../../../../../utils/analitica";
registerLocale("es", es);

const { format, clean, validate } = require("rut.js");

class Cargas extends Component {
  constructor(props) {
    super(props);
    this.textInput = React.createRef();
    this.formRef = React.createRef();
    const dataWizard = this.props.store.reducer.dataWizardAsegurado;
    this.state = {
      validacionWizardMisDatos: false,
      edit: 0,
      animacion: "formInit",
      documentosRequeridos: [],
      mostrarModalDocumentos: false,
      checked:
        dataWizard.solicitud.declaroCarga === null ||
        dataWizard.solicitud.declaroCarga === undefined
          ? !this.props.store.reducer.informacionPoliza
              .solicitudIncorporacion &&
            !this.props.store.reducer.informacionPoliza.asegurable_5_50
          : dataWizard.solicitud.declaroCarga,
      agregar: true,
      showModalDetalle: false,
      showModalEliminar: false,
      cargas: null,
      row: null,
      cambioCargas: false,
      datosEnviados: null,
      declaracionAsociada: false,
      parentescos: this.props.store.reducer.parentescos,
      rutCargaActual: "",
      glosaParentescoInconsistente: "",
      loadingData: false,
      loadingDataRequisitos: false,
      loadingBtnSiguiente: false,
      incorporacionCarga: false,
      data: this.setearEstadoInicial(),
      columnDefs: [
        {
          name: "Nombre completo",
          selector: "nombreCompleto",
        },
        {
          name: "RUT",
          selector: "rutAsegurable",
        },
        {
          name: "Parentesco",
          selector: "parentescoGlosa",
        },
        {
          cell: (row) => (
            <React.Fragment>
              <button
                id="mostrarDetalle"
                className="btn btn-dark btn-sm btn-table mr-2"
                type="button"
                title="Detalle"
                raised="true"
                primary="true"
                onClick={() => this.showDetalle(row)}
              >
                <i className="fa fa-search" aria-hidden="true"></i>
              </button>
              <button
                id="editar"
                className="btn btn-primary btn-sm btn-table mr-2"
                type="button"
                title="Editar"
                raised="true"
                primary="true"
                onClick={() => this.editarCarga(row)}
              >
                <i className="fa fa-pencil" aria-hidden="true"></i>
              </button>
              <button
                id="eliminar"
                className="btn btn-danger btn-sm btn-table"
                type="button"
                title="Eliminar"
                raised="true"
                primary="true"
                onClick={() => this.showModalEliminar(row)}
              >
                <i className="fa fa-trash" aria-hidden="true"></i>
              </button>
            </React.Fragment>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
      ],
      canalVentaPoliza: {},
    };
  }

  async componentWillMount() {
    crearEventoTraza(GA_CATEGORIA_FORMULARIO_ENROLAMIENTO, GA_LABEL_CARGAS);

    let fechaNacimientoTitular =
      this.props.store.reducer.dataWizardAsegurado.fechaNacimiento;
    if (fechaNacimientoTitular === undefined) {
      this.setState({ validacionWizardMisDatos: true });
    } else this.setState({ validacionWizardMisDatos: false });

    const { dataWizardAsegurado, data_asegurable } =
      this.props.store.reducer;

    let data = this.state.data;
    if (data.peso && data.estatura) {
      let peso = data.peso;
      let estatura = data.estatura;
      peso >= 0 ? (peso = data.peso) : (peso = 1);
      estatura >= 0 ? (estatura = data.estatura) : (estatura = 1);
      this.setState({ data });
    }

    if (
      dataWizardAsegurado !== null &&
      dataWizardAsegurado.cargas !== undefined
    ) {
      let { cargas } = dataWizardAsegurado;

      if (cargas !== null && cargas.length > 0) {
        cargas = cargas.filter((dato) => dato !== undefined);
        cargas.forEach((element) => {
          element.rutAsegurable = format(
            element.rut + "-" + element.digitoVerificador
          );
          element.nombreCompleto = `${element.nombre} ${element.apellidoPaterno} ${element.apellidoMaterno}`;
          element.parentescoGlosa = element.parentescoGlosa
            ? element.parentescoGlosa
            : element.parentesco.glosa;
          element.idParentesco = element.idParentesco
            ? element.idParentesco
            : element.parentesco.idParentesco;
          element.idGenero = element.idGenero
            ? element.idGenero
            : element.genero.idGenero;
          element.idSistemaPrevisionSalud = element.idSistemaPrevisionSalud
            ? element.idSistemaPrevisionSalud
            : element.sistemaPrevisionSalud.idSistemaPrevisionSalud;
          element.mostrarFechaNacimiento =
            typeof element.mostrarFechaNacimiento === "string"
              ? element.mostrarFechaNacimiento
              : moment(element.fechaNacimiento).format("DD/MM/YYYY");
          element.fechaNacimiento =
            typeof element.mostrarFechaNacimiento === "string"
              ? element.mostrarFechaNacimiento
              : moment(element.fechaNacimiento).format("DD/MM/YYYY");
          element.key = uuid();
        });

        this.props.update_carga(cargas);
        this.setState({ cargas });
      }
    }

    const listaGrupos = data_asegurable.grupos;
    if (listaGrupos) {
      let grupoPoliza = listaGrupos.map((grupo) => {
        return {
          nroPoliza: grupo.nroPoliza,
          nroRenovacion: grupo.nroRenovacion,
        };
      });
      if (grupoPoliza && grupoPoliza.length > 0) {
        Promise.all(
          grupoPoliza.map(async (poliza) => {
            const prueba = await listarPorNroPolizaNroRenovacion(
              poliza.nroPoliza,
              poliza.nroRenovacion
            );
            return prueba;
          })
        ).then(async (respuesta) => {
          if (respuesta && respuesta[0] !== null) {
            const canalVenta = respuesta[0].canalVenta;
            await this.setState((state) => {
              state.canalVentaPoliza = canalVenta;
            });
            if (
              respuesta[POSICION_INICIAL].agrupaciones.length >
              LARGO_CERO_AGRUPACIONES
            ) {
              let parentesco = await obtenerConfiguracionParentesco(
                respuesta[POSICION_INICIAL].numeroPoliza,
                respuesta[POSICION_INICIAL].numeroRenovacion
              );
              this.setState({ parentescos: parentesco });
            }
          }
        });
      }
    }

    setTimeout(() => {
      if (this.props.store.reducer.wizard.pagina === 2) {
        this.setState({ animacion: "movingIn" });
      }
    }, 100);
  }

  componentDidMount = async () => {
    let { cargas } = this.props.store.reducer.dataWizardAsegurado;
    if (
      (this.state.cargas === null || this.state.cargas.length === 0) &&
      this.props.store.reducer.data_asegurable.declaroCarga === true
    ) {
      this.refs.noDeclaroCarga.checked = true;
      this.setState({
        checked: false,
      });
    }

    if (cargas !== null && cargas.length > 0) {
      this.refs.siDeclaroCarga.checked = true;
      this.setState({
        checked: true,
      });
    }

    const incorporacionCarga =
      !this.props.store.reducer.informacionPoliza.solicitudIncorporacion &&
      !this.props.store.reducer.informacionPoliza.asegurable_5_50;

    if (incorporacionCarga) {
      this.setState({ incorporacionCarga });
    }
  };

  componentDidUpdate(nextProps, nextState) {
    if (this.state.cargas !== nextState.cargas) {
      this.setState({ cambioCargas: true }, function () {});
    }
  }

  setearEstadoInicial() {
    return {
      key: null,
      rut: "",
      rutAsegurable: "",
      digitoVerificador: "",
      nombre: "",
      apellidoMaterno: "",
      apellidoPaterno: "",
      fechaNacimiento: "",
      mostrarFechaNacimiento: "",
      idParentesco: "0",
      idSistemaPrevisionSalud: "0",
      idGrupoAsegurable:
        this.props.store.reducer.data_asegurable.idGrupoAsegurable,
      idTipoAsegurable: 0,
      idGenero: 0,
      peso: 0,
      estatura: 0,
      idSolicitud: this.props.store.reducer.data_asegurable.idSolicitud,
      idAsegurable: 0,
      vigente: true,
      documentos: [],
    };
  }

  enviarCargas = async () => {
    const { dataWizardAsegurado, informacionPoliza } = this.props.store.reducer;
    const { data_asegurable } = this.props.store.reducer;
    let codCotizacion = "";
    if (
      data_asegurable.canalVenta.idCanalVenta === TIPO_CANAL_VENTA_DIRECTA ||
      data_asegurable.canalVenta.idCanalVenta === TIPO_CANAL_VENTA_DIGITAL
    ) {
      let cotizacionTemporal = data_asegurable.cotizaciones.filter(
        (cotizacion) =>
          cotizacion.subProducto.producto.idProducto === ID_PRODUCTO_COMPL_SALUD
      );
      codCotizacion = cotizacionTemporal[VALOR_ZERO].codigoCotizacion;
    }

    const cargas =
      this.props.store.reducer.dataWizardAsegurado.cargas.length > 0
        ? this.props.store.reducer.dataWizardAsegurado.cargas.map((item) => {
            let fechaNacimiento;
            if (typeof item.fechaNacimiento === TYPEOF_STRING) {
              if (item.fechaNacimiento.includes("/")) {
                let fNac = item.fechaNacimiento.split("/");
                fechaNacimiento = `${fNac[2]}-${fNac[1]}-${fNac[0]}`;
              } else {
                let fNac = item.fechaNacimiento.split("-");
                fechaNacimiento = ` ${fNac[0]}-${fNac[1]}-${fNac[2]}`;
              }
            } else {
              fechaNacimiento = moment(item.fechaNacimiento).format(
                "YYYY-MM-DD"
              );
            }

            let data = {
              apellidoMaterno: item.apellidoMaterno,
              apellidoPaterno: item.apellidoPaterno,
              digitoVerificador: item.digitoVerificador,
              rut: item.rut,
              rutAsegurable: format(item.rut + "-" + item.digitoVerificador),
              estatura: Number(item.estatura),
              fechaNacimiento: fechaNacimiento,
              nombre: item.nombre,
              parentesco: {...item.parentesco},
              parentescoGlosa: item.parentesco.glosa,
              peso: Number(item.peso),
              sistemaPrevisionSalud: {
                glosa:
                  item.sistemaPrevisionSaludGlosa ||
                  item.sistemaPrevisionSalud.glosa,
                idSistemaPrevisionSalud: item.idSistemaPrevisionSalud
                  ? Number(item.idSistemaPrevisionSalud)
                  : Number(item.sistemaPrevisionSalud.idSistemaPrevisionSalud),
              },
              genero: {
                descripcion: item.generoDescripcion || item.genero.descripcion,
                idGenero: item.idGenero
                  ? Number(item.idGenero)
                  : item.genero.idGenero,
              },
              titularAsociado:
                this.props.store.reducer.data_asegurable.idAsegurable,
              vigente: true,
              coberturas: item.coberturas,
              grupos:
                data_asegurable.tipoFlujo === FLUJO_VENTA_DIRECTA
                  ? this.props.store.reducer.data_asegurable.grupos.filter(
                      (grupo) => grupo.codigoCotizacion === codCotizacion
                    )
                  : data_asegurable.tipoFlujo === FLUJO_MANTENCION
                  ? informacionPoliza.grupos.filter(
                      (grupo) =>
                        grupo.poliza.tipoPoliza.idTipoPoliza === VALOR_UNO
                    )
                  : this.props.store.reducer.data_asegurable.grupos,
              documentos: item.documentos,
            };

            return data;
          })
        : [];

    dataWizardAsegurado.cargas = cargas;
    dataWizardAsegurado.solicitud.declaroCarga =
      this.state.checked === STRING_ON;

    const beneficiarios = this.actualizarBeneficiariosSiSonCargas(cargas);

    dataWizardAsegurado.beneficiarios = beneficiarios || [];

    const documentosCargas = obtenerDocumentosDeCargas(dataWizardAsegurado);

    this.props.actualizar_beneficiarios(beneficiarios);
    this.props.guardar_data_wizard_asegurado(dataWizardAsegurado);

    if (
      dataWizardAsegurado.solicitud &&
      dataWizardAsegurado.solicitud.tipoSolicitud &&
      dataWizardAsegurado.solicitud.tipoSolicitud.idTipoSolicitud ===
        TIPO_INCORPORACION_CARGA &&
      isNaN(dataWizardAsegurado.peso) &&
      isNaN(dataWizardAsegurado.estatura)
    ) {
      dataWizardAsegurado.peso = SIN_PESO;
      dataWizardAsegurado.estatura = SIN_ALTURA;
    }

    let resDataWizard = await guardarDataWizard(dataWizardAsegurado);

    if (resDataWizard) {
      enviarArchivos(
        documentosCargas.filter((documento) => documento.newFile),
        {
          key: "idAsegurable",
          value: this.props.store.reducer.data_asegurable.idAsegurable,
        },
        DOCUMENT_TYPE_TEMPORAL
      );
    }

    setearDocumentosCargas(dataWizardAsegurado, documentosCargas);

    return resDataWizard;
  };

  actualizarBeneficiariosSiSonCargas = (cargas) => {
    const { beneficiarios } = this.props.store.reducer.dataWizardAsegurado;
    const catalogoBeneficiario =
      this.props.store.reducer.parentescos_beneficiarios;

    if (beneficiarios && beneficiarios.length > VALOR_ZERO) {
      for (const carga of cargas) {
        const cargaYBeneficiario = verificarSiExisteObjetoEnArreglo(
          beneficiarios,
          [
            {
              nombrePropiedad: STRING_RUT,
              valorPropiedad: carga.rut,
            },
            {
              nombrePropiedad: DIGITO_VERIFICADOR,
              valorPropiedad: carga.digitoVerificador,
            },
          ]
        );

        if (cargaYBeneficiario) {
          const parentescoCatalogo = catalogoBeneficiario.filter(
            (parentesco) => {
              return (
                parentesco.parentesco.idParentesco ===
                  carga.parentesco.idParentesco && parentesco
              );
            }
          );
          const parentesco = {
            idParentesco:
              parentescoCatalogo[POSICION_INICIAL].idParentescoBeneficiario,
            glosa: parentescoCatalogo[POSICION_INICIAL].parentesco.glosa,
          };

          const indiceBeneficiarioModificado = beneficiarios.findIndex(
            ({ rut, digitoVerificador }) =>
              rut === cargaYBeneficiario.rut &&
              digitoVerificador === cargaYBeneficiario.digitoVerificador
          );

          beneficiarios[indiceBeneficiarioModificado].nombre = carga.nombre;
          beneficiarios[indiceBeneficiarioModificado].apellidoPaterno =
            carga.apellidoPaterno;
          beneficiarios[indiceBeneficiarioModificado].apellidoMaterno =
            carga.apellidoMaterno;
          beneficiarios[indiceBeneficiarioModificado].idGenero = carga.idGenero;
          beneficiarios[indiceBeneficiarioModificado].idParentesco =
            parentescoCatalogo[POSICION_INICIAL].idParentescoBeneficiario;
          beneficiarios[indiceBeneficiarioModificado].parentesco = parentesco;
          beneficiarios[indiceBeneficiarioModificado].parentescoGlosa =
            carga.parentesco.descripcion;
          beneficiarios[indiceBeneficiarioModificado].genero = carga.genero;
        }
      }
    }
    return beneficiarios;
  };

  obtenerDocumentos = (documentos) => {
    this.setState({
      data: {
        ...this.state.data,
        documentos,
      },
    });
  };

  obtenerTiposDocumentosRequeridos = async (idParentesco) => {
    const documentosRequeridos = documentoRequeridoPorParentesco(
      this.props.store.reducer.parentescos,
      idParentesco,
      this.state.incorporacionCarga,
      this.props.store.reducer.data_asegurable.cotizacionesConCondicionado
    );
    await this.setState({
      documentosRequeridos: documentosRequeridos,
    });
  };

  siguientePagina = async () => {

    this.props.declarar_carga(true);
    this.setState({ loadingBtnSiguiente: true });

    if (this.state.checked !== null) {
      if (
        this.state.checked &&
        (!this.props.store.reducer.dataWizardAsegurado.cargas ||
          this.props.store.reducer.dataWizardAsegurado.cargas.length < 1)
      ) {
        return notificacion("warning", ALERTA_NO_CARGAS);
      }
      const respuesta = await this.enviarCargas();
      if (respuesta !== null) {
        notificacion("success", EXITO_CARGAS);

        scroll.scrollToTop();
        this.setState({
          animacion: "formOut",
          cargando: false,
          cambioCargas: false,
        });

        setTimeout(() => {
          this.props.wizard({ pagina: PAGINA_3, type: TIPO_PAGINA });
          this.props.wizard({ tab: VALOR_UNO, type: TIPO_TAB });
        }, 100);
      } else {
        notificacion("danger", ERROR_SOLICITUD);
        this.setState({ cargando: false });
      }
    } else {
      notificacion("warning", VALIDACION_SELECT);
    }
    this.setState({ loadingBtnSiguiente: false });
  };

  anteriorPagina = () => {
    scroll.scrollToTop();
    this.props.wizard({ pagina: PAGINA_1, type: TIPO_PAGINA });
  };

  showModalDetalle = () => {
    this.setState({
      showModalDetalle: !this.state.showModalDetalle,
    });
  };

  showDetalle = (row) => {
    this.showModalDetalle();

    if (
      typeof row.fechaNacimiento === TYPEOF_STRING &&
      row.fechaNacimiento.includes("/")
    ) {
      row.mostrarFechaNacimiento = row.fechaNacimiento;
    }

    this.setState({ row });
  };

  showModalEliminar = (row) => {
    this.setState({
      showModalEliminar: !this.state.showModalEliminar,
      row,
      declaracionAsociada:
        row !== undefined &&
        row.coberturas[0].declaraciones !== null &&
        row.coberturas[0].declaraciones !== false &&
        row.coberturas[0].declaraciones.length > 0
          ? true
          : false,
    });
  };

  validarRequisitosAsegurabilidad = async (objCarga) => {

    this.setState({ loadingData: true });
    const lstParentescos     = this.state.parentescos;
    const objParentesco      = lstParentescos.find(objParentesco => objParentesco.idParentesco === Number(objCarga.idParentesco));
    objCarga.parentesco      = {...objParentesco};
    objCarga.parentescoGlosa = objParentesco.glosa;
    const { tipoFlujo } = this.props.store.reducer.data_asegurable;

    if (tipoFlujo === FLUJO_VENTA_DIRECTA){
      
      await this.handleSubmit(objCarga);
    }
    else{
      const { dataWizardAsegurado, informacionPoliza } = this.props.store.reducer;
      const lstIdCoberturas = objParentesco.coberturas.map(objCobertura => objCobertura.idCobertura);
      let lstCoberturas     = tipoFlujo === FLUJO_VENTA_DIGITAL ? dataWizardAsegurado.coberturasCargas : informacionPoliza.coberturasCargas;
      lstCoberturas         = lstCoberturas.filter(objCobertura => lstIdCoberturas.includes(objCobertura.configuracion.idCobertura));

      let objReqValidacion  = await validarRequisitosAsegurabilidad(lstCoberturas,objCarga,true,objParentesco);
      if (!objReqValidacion.mostrarError) {
        if (objReqValidacion.modalRequisitosAsegurabilidad) {
          this.setState({...objReqValidacion});
        }
        else{
          await this.handleSubmit(objCarga);
        }
      }
    }
    this.setState({ loadingData: false });
  };

  cerrarModalRequisitosAsegurabilidad = () => {
    this.setState({
      modalRequisitosAsegurabilidad: false,
      loadingDataRequisitos: false,
    });
  };

  obtenerSeleccionRequisitosAsegurabilidad = async (opcion) => {
    this.setState({ loadingDataRequisitos: true });

    const idSolicitud = this.props.store.reducer.data_asegurable.idSolicitud;

    if (opcion === FINALIZAR_PROCESO) {
      const data = {
        idSolicitud: idSolicitud,
        rutTitular: this.props.store.auth.usuarioSesion.rut,
        solicitudEstado: {
          idSolicitudEstado: ID_SOLICITUD_FINALIZADA_POR_ASEGURABLE,
        },
      };

      const respuesta = await finalizarSolicitud(data);
      if (respuesta != null) {
        await eliminarDataWizardMongo(idSolicitud);
        setTimeout(() => {
          return window.location.replace("");
        }, 100);
      }
    } else if (opcion === SOLICITAR_REEVALUACION) {
      this.setState({
        modalRequisitosAsegurabilidad: false,
        loadingDataRequisitos: false,
      });
      this.handleSubmit(this.state.datosActualesFormulario);
    } else if (opcion === CONTINUAR_SIN_CARGA) {
      this.setState({
        modalRequisitosAsegurabilidad: false,
        loadingDataRequisitos: false,
        data: this.setearEstadoInicial(),
        documentosRequeridos: [],
      });

      this.reset();

      if (this.state.cargas === null || !this.state.cargas.length) {
        this.setState({
          checked: !this.state.checked,
        });
      }

      //eliminar carga si es que es edición
      if (!this.state.agregar) {
        const cargas = this.state.cargas.filter(
          (carga) => carga.key !== this.state.data.key
        );
        this.setState({ cargas, agregar: true }, () => {
          if (!this.state.cargas.length) {
            this.setState({
              checked: !this.state.checked,
            });
          }
        });
        this.props.update_carga(cargas);
      }
    }
  };

  validarCargaRut(listaCargas, objCarga) {
    return listaCargas.find((item) =>
      item.rut === objCarga.rut ? true : false
    );
  }

  validarCargaParentesco(listaCargas, objCarga) {
    return listaCargas.find((item) =>
      item.parentescoGlosa === objCarga.parentescoGlosa ? true : false
    );
  }

  handleSubmit = async (values) => {

    values.nombre = soloLetras(values.nombre);
    values.apellidoPaterno = soloLetras(values.apellidoPaterno);
    values.apellidoMaterno = soloLetras(values.apellidoMaterno);

    values.nombreCompleto = `${values.nombre} ${values.apellidoPaterno} ${values.apellidoMaterno}`;
    values.generoDescripcion = this.props.store.reducer.generos.length
      ? this.props.store.reducer.generos.find(
          (item) => item.idGenero === Number(values.idGenero)
        ).descripcion
      : this.state.data.genero && this.state.data.genero.descripcion;
    values.sistemaPrevisionSaludGlosa = this.props.store.reducer.previsionSalud
      .length
      ? this.props.store.reducer.previsionSalud.find(
          (item) => item.idIsapre === Number(values.idSistemaPrevisionSalud)
        ).glosa
      : this.state.data.sistemaPrevisionSalud &&
        this.state.data.sistemaPrevisionSalud.glosa;

    values.rut = Number(
      clean(format(clean(values.rutAsegurable)).split("-")[0])
    );
    values.digitoVerificador = format(clean(values.rutAsegurable)).split(
      "-"
    )[1];
    values.rutAsegurable = format(`${values.rut}-${values.digitoVerificador}`);

    if (
      values.idSistemaPrevisionSalud === "0" ||
      values.idSistemaPrevisionSalud === 0 ||
      values.idSistemaPrevisionSalud === null ||
      values.idSistemaPrevisionSalud === ""
    ) {
      values.idSistemaPrevisionSalud =
        this.props.store.reducer.dataWizardAsegurado.sistemaPrevisionSalud.idSistemaPrevisionSalud;
    }

    this.setState({ cambioCargas: false });

    if (this.state.agregar) {
      const key = uuid();
      const data = { ...values, key, documentos: this.state.data.documentos };
      const { informacionPoliza } = this.props.store.reducer;
      const { cotizaciones } = this.props.store.reducer.data_asegurable;

      data.coberturas = informacionPoliza.coberturasCargas
        ? informacionPoliza.coberturasCargas.map((item) => {
            return {
              idProductoCobertura: item.idProductoCobertura,
              capitalAsegurado: STRING_VACIO,
              idSubProdCondicionadoCobertura: item.idCobertura,
              declaraciones: null,
            };
          })
        : cotizaciones && this.coberturasVentaDirecta(cotizaciones);

      const dataCopy = { ...data };
      const cargas =
        this.state.cargas === null || this.state.cargas.length === 0
          ? [data]
          : [...this.state.cargas, data];

      this.setState({
        documentosRequeridos: [],
        data: this.setearEstadoInicial(),
        cargas,
      });
      this.props.add_carga(dataCopy);

      this.reset();

      if (cargas) {
        if (cargas.length > 0) {
          let res = await this.enviarCargas();
          if (res) {
            notificacion("success", EXITO_UPDATE_CARGA);
          } else {
            notificacion("danger", ERROR_SOLICITUD);
          }
        }
      }
    } else {
      // Encontrar index de carga editada en detalle (se busca antes de editar por si se cambia RUT)
      const { detalle } = this.props.store.reducer.configuracionDeclaraciones;
      const cargaDetalle = detalle.find(
        (declaracion) => Number(declaracion.rut) === Number(this.state.data.rut)
      );
      const cargadetalleIndex = detalle.indexOf(cargaDetalle);

      //Guardar Actualizacion de carga

      const dataCopy = {
        ...this.state.data,
        ...values,
        ...{
          rutAnterior: this.state.data.rut,
          digitoVerificadorAnterior: this.state.data.digitoVerificador,
        },
      };

      let update = this.state.cargas.find((item) => item.key === dataCopy.key);

      let pos = this.state.cargas.indexOf(update);
      let newCargas = [...this.state.cargas];

      newCargas[pos] = dataCopy;

      this.setState({
        documentosRequeridos: [],
        data: this.setearEstadoInicial(),
        cargas: newCargas,
        agregar: true,
      });

      this.props.update_carga(newCargas);

      const { cargas } = this.props.store.reducer.dataWizardAsegurado;

      if (cargas) {
        if (cargas.length > 0) {
          let resCargas = await this.enviarCargas();
          if (resCargas) {
            notificacion("success", EXITO_UPDATE_CARGA);
          } else {
            notificacion("danger", ERROR_SOLICITUD);
          }
        }
      }

      //Guardar Nombre en declaraciones
      if (cargadetalleIndex !== SIN_INDEX) {
        detalle[
          cargadetalleIndex
        ].nombre = `${dataCopy.nombre} ${dataCopy.apellidoPaterno}`;
        this.props.update_detalle(detalle);
      }
    }
    if (!this.state.incorporacionCarga) {
      this.refs.siDeclaroCarga.checked = true;
    }
  };

  coberturasVentaDirecta(cotizacion) {
    const { data_asegurable } = this.props.store.reducer;
    let codCotizacion = "";
    if (
      data_asegurable.canalVenta.idCanalVenta === TIPO_CANAL_VENTA_DIRECTA ||
      data_asegurable.canalVenta.idCanalVenta === TIPO_CANAL_VENTA_DIGITAL
    ) {
      let cotizacionTemporal = data_asegurable.cotizaciones.filter(
        (cotizacion) =>
          cotizacion.subProducto.producto.idProducto === ID_PRODUCTO_COMPL_SALUD
      );
      codCotizacion = cotizacionTemporal[VALOR_ZERO].codigoCotizacion;
    }

    let arregloCotizacion = [];
    cotizacion.forEach((cot) => {
      if (cot.codigoCotizacion === codCotizacion) {
        cot.coberturas.forEach((cob) => {
          let objPlan = SIN_VALOR_PLAN;
          if (data_asegurable.tipoFlujo === FLUJO_VENTA_DIGITAL) {
            objPlan = data_asegurable.planCotizado.find((objPlanCotizado) => {
              return (
                objPlanCotizado.idSubProdCondicionadoCobertura ===
                cob.cotizacionCobertura.idSubProdCondicionadoCobertura
              );
            });
          }
          arregloCotizacion.push({
            idProductoCobertura:
              cob.idProductoCobertura !== ID_PRODUCTO_COBERTURA
                ? cob.idProductoCobertura
                : objPlan.idProductoCobertura,
            capitalAsegurado: STRING_VACIO,
            idSubProdCondicionadoCobertura:
              cob.cotizacionCobertura.idSubProdCondicionadoCobertura,
            declaraciones: null,
            idProducto: cot.subProducto.producto.idProducto,
            cubrePreexistencia: !cot.evaluacionPreexistencia,
          });
        });
      }
    });
    return arregloCotizacion;
  }

  reset = () => {
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        rut: "",
        rutAsegurable: "",
        digitoVerificador: "",
        nombre: "",
        apellidoMaterno: "",
        apellidoPaterno: "",
        fechaNacimiento: "",
        mostrarFechaNacimiento: " ",
        idGenero: 0,
        idParentesco: 0,
        idSistemaPrevisionSalud: 0,
        peso: "",
        estatura: "",
      },
      edit: 0,
    }));
  };

  editarCarga = async (row) => {
    await this.setState(
      {
        agregar: false,
        data: row,
      },
      await this.obtenerTiposDocumentosRequeridos(
        row.parentesco ? row.parentesco.idParentesco : row.idParentesco
      )
    );
    this.textInput.current.focus();
  };

  handleActionDelete = (row) => {
    const cargas = this.state.cargas;
    const rowDelete = cargas.find((item) => item.key === row.key);

    if (this.state.declaracionAsociada) {
      const declaracionesEliminar = rowDelete.coberturas[0].declaraciones.map(
        (declaracion) => declaracion
      );
      const detalleRedux =
        this.props.store.reducer.configuracionDeclaraciones.detalle.filter(
          (declaracion) => !declaracionesEliminar.includes(declaracion)
        );
      this.props.update_detalle(detalleRedux);
    }

    const nuevoCargas = cargas.filter((item) => item.key !== rowDelete.key);

    this.props.update_carga(nuevoCargas);
    this.setState({
      cargas: nuevoCargas,
      agregar: true,
      declaracionAsociada: false,
    });
  };

  confirmarNo = (e) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="modal-confirm-container">
            <div className="d-flex">
              <p className="h4">
                Ha declarado {this.state.cargas.length}{" "}
                {this.state.cargas.length === 1 ? "carga" : "cargas"}. ¿Confirma
                que desea eliminar cargas?
              </p>
            </div>

            <div className="row mt-4">
              <button
                id="botonCerrar"
                className="btn btn-principal color-secundario btn-sm d-block ml-auto mr-2"
                onClick={() => {
                  this.refs.siDeclaroCarga.checked = true;
                  this.refs.noDeclaroCarga.checked = false;
                  this.setState({ checked: true });
                  onClose();
                }}
              >
                No
              </button>
              <button
                id="borrarCargas"
                className="btn btn-principal btn-sm"
                onClick={(e) => {
                  this.refs.siDeclaroCarga.checked = false;
                  this.refs.noDeclaroCarga.checked = true;
                  this.setState({
                    checked: false,
                  });
                  this.borrarCargas();
                  onClose();
                }}
              >
                Sí
              </button>
            </div>
          </div>
        );
      },
    });
  };

  borrarCargas = () => {
    this.setState({
      cargas: [],
    });
    this.props.update_carga([]);
  };

  obtenerFecha = (fechaNacimiento) => {
    this.setState({
      data: {
        ...this.state.data,
        fechaNacimiento,
        mostrarFechaNacimiento: moment(fechaNacimiento).format("DD/MM/YYYY"),
      },
    });
  };

  guardarRut = (value) => {
    this.setState({ rutCargaActual: value });
  };

  notificarSinOpciones = (catalogo) => {
    if (!catalogo.length) {
      notificacion("info", NOTIFICACION_SIN_OPCIONES);
    }
  };

  //VALIDACIONES FORMIK//
  rutUnico = (value) => {
    const { cargas } = this.props.store.reducer.dataWizardAsegurado;

    if (cargas !== null && cargas.length > 0) {
      const rutsCargas = cargas
        .map(({ rutAsegurable }) => rutAsegurable)
        .filter((item) => item !== format(this.state.data.rutAsegurable));

      return rutsCargas.includes(format(value)) ? false : true;
    } else {
      return true;
    }
  };

  rutTitular = (value) => {
    const rutTitular = this.props.store.auth.usuarioSesion.rut;
    return format(value) !== format(rutTitular);
  };

  conyugueUnico = (value) => {
    const { cargas } = this.props.store.reducer.dataWizardAsegurado;

    if (
      (Number(value) === 1 || Number(value) === 22) &&
      cargas !== null &&
      cargas.length > 0
    ) {
      const parentescos = cargas
        .map(({ parentesco }) => {
          return Number(parentesco.idParentesco);
        })
        .filter((item) => item !== Number(this.state.data.idParentesco));

      return !(parentescos.includes(1) || parentescos.includes(22));
    } else {
      return true;
    }
  };

  parentescosUnicosPolizaAnterior = (parentescosId) => {
    const { cargasTitularIncorporacionCarga } =
      this.props.store.reducer.dataWizardAsegurado;

    const { rutAsegurable, idParentesco } = this.formRef.current.values;

    const actualRutCarga = clean(rutAsegurable);

    const existenCargasFlujoIncorporacionCarga =
      cargasTitularIncorporacionCarga && cargasTitularIncorporacionCarga.length;
    const existenParentescosUnicos = parentescosId.includes(
      Number(idParentesco)
    );
    let cargaValida = true;

    if (
      existenCargasFlujoIncorporacionCarga &&
      existenParentescosUnicos &&
      actualRutCarga
    ) {
      const cargaAnterior = cargasTitularIncorporacionCarga.find(
        (cargaAnterior) =>
          parentescosId.includes(cargaAnterior.parentesco.idParentesco)
      );

      if (cargaAnterior) {
        cargaValida =
          cargaAnterior.rutCarga.replace("-", "") === actualRutCarga;
        return cargaValida;
      }
    }
    return cargaValida;
  };

  edadHijo = (value) => {
    if (Number(value) === 4 || Number(value) === 19) {
      const nacimientoTitular = new Date(
        moment(
          this.props.store.reducer.dataWizardAsegurado.fechaNacimiento
        ).format("YYYY/MM/DD")
      );
      const nacimientoHijo = new Date(
        moment(this.state.data.fechaNacimiento).format("YYYY/MM/DD")
      );
      const diferencia =
        nacimientoHijo.getFullYear() - nacimientoTitular.getFullYear();

      return diferencia >= 15;
    }
    return true;
  };

  padreUnico = (value, id) => {
    const { cargas } = this.props.store.reducer.dataWizardAsegurado;

    if (Number(value) === id && cargas !== null && cargas.length > 0) {
      const parentescos = cargas
        .map(({ idParentesco }) => Number(idParentesco))
        .filter((item) => item !== Number(this.state.data.idParentesco));

      return !parentescos.includes(id);
    } else {
      return true;
    }
  };

  consistenciaParentesco = (value) => {
    const { reducer } = this.props.store;
    const { parentescos } = reducer;
    const { beneficiarios } = reducer.dataWizardAsegurado;
    const parentescoBeneficiario = reducer.parentescos_beneficiarios;

    const rutSinDv = Number(
      clean(format(this.state.rutCargaActual).split("-")[0])
    );

    const idsParentescosUnicos = [
      PARENTESCO_CONYUGUE,
      PARENTESCO_PADRE,
      PARENTESCO_MADRE,
      PARENTESCO_CONVIVIENTE_CIVIL,
    ];

    if (
      idsParentescosUnicos.includes(Number(value)) &&
      beneficiarios !== undefined &&
      beneficiarios !== null &&
      beneficiarios.length > VALOR_ZERO
    ) {
      const parentesco = parentescoBeneficiario.filter((parentesco) => {
        return (
          parentesco.parentesco.idParentesco === Number(value) &&
          parentesco.parentesco.idParentesco
        );
      });
      const idParentesco =
        parentesco[POSICION_INICIAL].idParentescoBeneficiario;
      const parentescoInconsistente = beneficiarios.find(
        (beneficiario) =>
          Number(beneficiario.parentesco.idParentesco) === idParentesco &&
          rutSinDv !== beneficiario.rut
      );

      if (parentescoInconsistente) {
        const glosaParentescoInconsistente = parentescos.find(
          (parentesco) => parentesco.idParentesco === Number(value)
        ).glosa;

        this.setState({ glosaParentescoInconsistente });
      }

      return !parentescoInconsistente;
    } else {
      return true;
    }
  };

  consistenciaPareja = (value) => {
    const { beneficiarios } = this.props.store.reducer.dataWizardAsegurado;

    const yaExistePareja =
      beneficiarios &&
      beneficiarios.find(
        (beneficiario) =>
          Number(beneficiario.parentesco.idParentesco) ===
            PARENTESCO_CONYUGUE ||
          Number(beneficiario.parentesco.idParentesco) ===
            PARENTESCO_CONVIVIENTE_CIVIL
      );

    const valueEsPareja =
      Number(value) === PARENTESCO_CONYUGUE ||
      Number(value) === PARENTESCO_CONVIVIENTE_CIVIL;

    if (yaExistePareja && valueEsPareja) {
      const parejaConsistente = beneficiarios.find(
        (beneficiario) =>
          Number(beneficiario.parentesco.idParentesco) === Number(value)
      );

      return parejaConsistente;
    } else {
      return true;
    }
  };

  documentosCargados = () => {
    if (
      this.state.canalVentaPoliza &&
      this.state.canalVentaPoliza.idCanalVenta !== TIPO_CANAL_VENTA_CORREDOR
    ) {
      const cantidadDocumentosRequerido =
        this.state.documentosRequeridos.filter(
          (documento) => documento.requerido
        ).length;
      if (cantidadDocumentosRequerido > 0) {
        let cantidadDocRequeridosAsegurable = this.state.data.documentos.filter(
          (doc) => doc.requerido
        ).length;
        return cantidadDocRequeridosAsegurable === cantidadDocumentosRequerido;
      }
      return true;
    } else {
      return true;
    }
  };

  obtenerFormatoFechaForm(fecha) {
    if (fecha === "" || fecha === undefined) return fecha;
    let fechaNacimiento;
    typeof fecha === TYPEOF_STRING
      ? (fechaNacimiento = fecha)
      : (fechaNacimiento = moment(fecha).format("DD/MM/YYYY"));
    return fechaNacimiento;
  }

  fechaMinima(parentesco) {
    parentesco = Number(parentesco);

    let fechaNacimientoTitular =
      this.props.store.reducer.dataWizardAsegurado.fechaNacimiento;

    if (
      parentesco === PARENTESCO_HIJO ||
      parentesco === PARENTESCO_HIJO_ESTUDIANTE
    ) {
      let fechaNac = new Date(fechaNacimientoTitular);
      return new Date(
        fechaNac.getFullYear() +
          QUINCE_YEARS +
          "/" +
          (fechaNac.getMonth() + VALOR_UNO) +
          "/" +
          fechaNac.getDate()
      );
    }

    return new Date(DATA_FECHA_MIN_DEFAULT);
  }

  fechaMaxima = (parentesco) => {
    parentesco = Number(parentesco);

    let fechaNacimientoTitular =
      this.props.store.reducer.dataWizardAsegurado.fechaNacimiento;

    if (
      parentesco === PARENTESCO_CONYUGUE ||
      parentesco === PARENTESCO_CONVIVIENTE_CIVIL ||
      parentesco === PARENTESCO_CONVIVIENTE_NO_CIVIL
    ) {
      let fecha = new Date();

      return new Date(
        fecha.getFullYear() -
          MAYORIA_EDAD +
          "/" +
          (fecha.getMonth() + VALOR_UNO) +
          "/" +
          fecha.getDate()
      );
    }

    if (parentesco === PARENTESCO_MADRE || parentesco === PARENTESCO_PADRE) {
      let fechaNac = new Date(fechaNacimientoTitular);
      return new Date(
        fechaNac.getFullYear() -
          QUINCE_YEARS +
          "/" +
          (fechaNac.getMonth() + VALOR_UNO) +
          "/" +
          fechaNac.getDate()
      );
    }

    return new Date();
  };

  render() {
    const tipoDocReq = this.state.documentosRequeridos;
    const listaDocumentos = this.state.data.documentos;

    return (
      <React.Fragment>
        <Modal
          type="small"
          show={this.state.showModalDetalle}
          title="Detalle del asegurable"
          onSubmit={this.showModalDetalle}
          titleButton="Aceptar"
          onClose={() => {
            this.showModalDetalle();
          }}
          showCancelButton={false}
          showCloseButton={true}
          showLeftColumn={false}
        >
          <div className="row mx-auto">
            <div className="col-6">
              <div className="font-weight-bold text-muted">RUT</div>
            </div>
            <div className="col-6">
              <div>
                {this.state.row ? format(this.state.row.rutAsegurable) : null}
              </div>
            </div>

            <div className="col-6">
              <div className="font-weight-bold text-muted mr-2">
                Nombre completo
              </div>
            </div>
            <div className="col-6">
              <div>{this.state.row ? this.state.row.nombreCompleto : null}</div>
            </div>

            <div className="col-6">
              <div className="font-weight-bold text-muted mr-2">Género</div>
            </div>
            <div className="col-6">
              <div>
                {this.state.row
                  ? this.props.store.reducer.generos &&
                    this.props.store.reducer.generos.length
                    ? this.props.store.reducer.generos.find(
                        (p) => p.idGenero === Number(this.state.row.idGenero)
                      ).descripcion
                    : this.state.row.genero && this.state.row.genero.descripcion
                  : null}
              </div>
            </div>

            <div className="col-6">
              <div className="font-weight-bold text-muted mr-2">
                Fecha Nacimiento
              </div>
            </div>
            <div className="col-6">
              <div>
                {this.state.row && this.state.row.mostrarFechaNacimiento}
              </div>
            </div>

            <div className="col-6">
              <div className="font-weight-bold text-muted mr-2">Parentesco</div>
            </div>
            <div className="col-6">
              {this.state.row
                ? this.props.store.reducer.parentescos &&
                  this.props.store.reducer.parentescos.length
                  ? this.props.store.reducer.parentescos.find(
                      (p) =>
                        p.idParentesco === Number(this.state.row.idParentesco)
                    ).glosa
                  : this.state.row.parentesco && this.state.row.parentesco.glosa
                : null}
            </div>

            <div className="col-6">
              <div className="font-weight-bold text-muted mr-2">
                Salud Previsional
              </div>
            </div>
            <div className="col-6">
              <div>
                {this.state.row
                  ? this.props.store.reducer.previsionSalud &&
                    this.props.store.reducer.previsionSalud.length
                    ? this.props.store.reducer.previsionSalud.find(
                        (i) =>
                          i.idIsapre ===
                          Number(this.state.row.idSistemaPrevisionSalud)
                      ).glosa
                    : this.state.row.sistemaPrevisionSalud &&
                      this.state.row.sistemaPrevisionSalud.glosa
                  : null}
              </div>
            </div>

            <div className="col-6">
              <div className="font-weight-bold text-muted mr-2">Peso (kg)</div>
            </div>
            <div className="col-6">
              <div>{this.state.row ? this.state.row.peso : null}</div>
            </div>

            <div className="col-6">
              <div className="font-weight-bold text-muted mr-2">
                Estatura (cm)
              </div>
            </div>
            <div className="col-6">
              <div>{this.state.row ? this.state.row.estatura : null}</div>
            </div>
          </div>
        </Modal>
        <Modal
          type="small"
          show={this.state.showModalEliminar}
          title="Eliminar asegurable adicional"
          titleButton="Eliminar"
          onClose={() => {
            this.showModalEliminar();
          }}
          onCancel={() => {
            this.showModalEliminar();
          }}
          onSubmit={() => {
            this.handleActionDelete(this.state.row);
            this.showModalEliminar();
          }}
          showCancelButton={true}
          showCloseButton={true}
          showLeftColumn={false}
        >
          <h5 className="col-12 col-md-8 offset-md-2">
            {this.state.declaracionAsociada === true
              ? "Este asegurable adicional tiene declaraciones asociadas. Si lo elimina también se eliminarán sus declaraciones. ¿Está seguro de eliminar a este asegurable adicional?"
              : "¿Está seguro de eliminar a este asegurable adicional?"}
          </h5>
        </Modal>
        <ModalRequisitosAsegurabilidad
          seccion={SECCION_ASEGURABLES_ADICIONALES}
          mostrarModal={this.state.modalRequisitosAsegurabilidad}
          cerrarModal={this.cerrarModalRequisitosAsegurabilidad}
          obtenerSeleccion={this.obtenerSeleccionRequisitosAsegurabilidad}
          textoPolizas={this.state.tipoPolizas}
          loadingDataRequisitos={this.state.loadingDataRequisitos}
          messages={[
            {
              message: "Finalizar el proceso de suscripción",
              helpMessage:
                "Si selecciona finalizar el proceso no tendrá acceso al formulario de enrolamiento nuevamente",
              option: FINALIZAR_PROCESO,
            },
            {
              message: "Solicitar Reevaluación",
              helpMessage:
                "Si acepta solicitar reevaluación se enviará su petición, debe terminar de completar el formulario de enrolamiento",
              option: SOLICITAR_REEVALUACION,
            },
            {
              message: "Continuar sin carga",
              helpMessage:
                "Continuará su solicitud de incorporación sin el asegurable dependiente escogido. Tiene la opción de agregar un nuevo asegurable dependiente y continuar o seguir el proceso sin otro asegurable dependiente.",
              option: CONTINUAR_SIN_CARGA,
            },
          ]}
        />
        <div>
          <div className="card wizard o-white">
            <div
              id="cargas"
              className="cuerpo-wizard pt-3 o-white container mt-3"
            >
              <div className="row mx-5 animated fadeInRight">
                <div className="col-md-12">
                  <h5 className="font-weight-bold text-primary mb-0">
                    {this.state.incorporacionCarga
                      ? "Asegurables adicionales"
                      : "¿Desea declarar asegurables adicionales?"}
                  </h5>
                  <hr className="mt-1" />
                </div>

                {!this.state.incorporacionCarga && (
                  <div className="col-md-12">
                    <div className="check-group">
                      <label className="custom-checkbox p-1">
                        <input
                          id="siDeclaroCarga"
                          type="radio"
                          name="radio"
                          checked={this.state.checked}
                          ref="siDeclaroCarga"
                          onChange={async (e) => {
                            await this.setState({
                              checked: e.target.value,
                            });
                          }}
                        />
                        <div className="check"></div>
                        <div className="option">Si</div>
                      </label>
                      <label className="custom-checkbox p-1">
                        <input
                          id="noDeclaroCarga"
                          type="radio"
                          name="radio"
                          checked={
                            this.state.checked === null
                              ? null
                              : !this.state.checked
                          }
                          ref="noDeclaroCarga"
                          onChange={async (e) => {
                            if (
                              this.state.cargas !== null &&
                              this.state.cargas.length > 0
                            ) {
                              await this.confirmarNo(e);
                            } else {
                              await this.setState({
                                checked: !e.target.value,
                              });
                            }
                          }}
                        />
                        <div className="check"></div>
                        <div className="option">No</div>
                      </label>
                    </div>
                  </div>
                )}

                {(this.state.checked || this.state.incorporacionCarga) && (
                  <div className="col-md-12">
                    <Formik
                      innerRef={this.formRef}
                      enableReinitialize={true}
                      initialValues={{
                        nombre: this.state.data.nombre,
                        apellidoPaterno: this.state.data.apellidoPaterno,
                        apellidoMaterno: this.state.data.apellidoMaterno,
                        rutAsegurable: this.state.data.rutAsegurable,
                        fechaNacimiento: this.obtenerFormatoFechaForm(
                          this.state.data.fechaNacimiento
                        ),
                        idGenero: this.state.data.idGenero,
                        idParentesco: this.state.data.idParentesco,
                        idSistemaPrevisionSalud:
                          this.state.data.idSistemaPrevisionSalud,
                        peso: this.state.data.peso,
                        estatura: this.state.data.estatura,
                      }}
                      validationSchema={Yup.object().shape({
                        nombre: Yup.string()
                          .required(CAMPO_REQUERIDO_NOMBRE)
                          .test(
                            "validarVacios",
                            CAMPO_REQUERIDO_NOMBRE,
                            (value) => (value ? value.trim() !== "" : false)
                          )
                          .min(1, VALIDACION_NOMBRE)
                          .max(25, VALIDACION_NOMBRE_MAXIMO),

                        apellidoPaterno: Yup.string()
                          .required(CAMPO_REQUERIDO_APELLIDO_PATERNO)
                          .test(
                            "validarVacios",
                            CAMPO_REQUERIDO_APELLIDO_PATERNO,
                            (value) => (value ? value.trim() !== "" : false)
                          )
                          .min(1, VALIDACION_APELLIDO_P)
                          .max(25, VALIDACION_APELLIDO_P_MAXIMO),
                        apellidoMaterno: Yup.string()
                          .min(1, VALIDACION_APELLIDO_M)
                          .max(25, VALIDACION_APELLIDO_M_MAXIMO),
                        rutAsegurable: Yup.string()
                          .required(CAMPO_REQUERIDO_RUT)
                          .test("validarRut", VALIDACION_RUT, (value) => {
                            return validate(clean(value));
                          })
                          .test("rutTitular", RUT_TITULAR, this.rutTitular)
                          .test(
                            "rutDuplicado",
                            RUT_DUPLICADO_CARGAS,
                            this.rutUnico
                          ),
                        fechaNacimiento: Yup.string()
                          .required(CAMPO_REQUERIDO_FECHA)
                          .test("fecha", "validacion fecha", (value) => {
                            return value !== "Invalid date" ? true : false;
                          }),
                        documentosCargados: Yup.array().test(
                          "documentosCargados",
                          VALIDACION_DOCUMENTO_CARGA,
                          this.documentosCargados
                        ),
                        idGenero: Yup.string()
                          .required(CAMPO_REQUERIDO_GENERO)
                          .test("validaGenero", VALIDACION_GENERO, (value) => {
                            return Number(value) !== 0 ? true : false;
                          }),
                        idParentesco: Yup.string()
                          .required(CAMPO_REQUERIDO_PARENTESCO)
                          .test(
                            "validaParentesco",
                            VALIDACION_PARENTESCO,
                            (value) => {
                              return Number(value) !== 0 ? true : false;
                            }
                          )
                          .test(
                            "conyugueUnico",
                            CONYUGUE_UNICO,
                            this.conyugueUnico
                          )
                          .test(
                            "conyugueUnicoPolizaAnterior",
                            CONYUGUE_DISTINTO_POLIZA_ANTERIOR,
                            () => this.parentescosUnicosPolizaAnterior([1, 22])
                          )
                          .test(
                            "padreUnicoPolizaAnterior",
                            PADRE_DISTINTO_POLIZA_ANTERIOR,
                            (value) => this.parentescosUnicosPolizaAnterior([2])
                          )
                          .test(
                            "madreUnicaPolizaAnterior",
                            MADRE_DISTINTO_POLIZA_ANTERIOR,
                            (value) => this.parentescosUnicosPolizaAnterior([3])
                          )
                          .test("padreUnico", PADRE_UNICO, (value) =>
                            this.padreUnico(value, 2)
                          )
                          .test("madreUnica", MADRE_UNICA, (value) =>
                            this.padreUnico(value, 3)
                          )
                          .test(
                            "consistenciaParentesco",
                            `El Rut ingresado como ${this.state.glosaParentescoInconsistente} debe ser el mismo para Asegurable Adicional y Beneficiario`,
                            (value) => this.consistenciaParentesco(value)
                          )
                          .test(
                            "consistenciaPareja",
                            `No puede ingresar un asegurable adicional como CONYUGUE y un beneficiario como CONVIVIENTE CIVIL y viceversa`,
                            (value) => this.consistenciaPareja(value)
                          ),
                        idSistemaPrevisionSalud: Yup.string()
                          .required(CAMPO_REQUERIDO_SISTEMA_PREVISIONAL)
                          .test(
                            "validarSelect",
                            CAMPO_REQUERIDO_SISTEMA_PREVISIONAL,
                            (value) => {
                              return Number(value) !== 0 ? true : false;
                            }
                          ),
                        peso: Yup.string()
                          .required(CAMPO_REQUERIDO_PESO)
                          .test("validaPeso", CAMPO_REQUERIDO_PESO, (value) => {
                            return Number(value) && Number(value) !== 0;
                          }),
                        estatura: Yup.string()
                          .required(CAMPO_REQUERIDO_ESTATURA)
                          .test(
                            "validaAltura",
                            CAMPO_REQUERIDO_ESTATURA,
                            (value) => {
                              return Number(value) && Number(value) !== 0;
                            }
                          ),
                      })}
                      onSubmit={async (
                        values,
                        { setSubmitting, resetForm, initialValues }
                      ) => {
                        this.validarRequisitosAsegurabilidad(values);

                        resetForm(initialValues);
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        setFieldValue,
                        setFieldTouched,
                        handleChange,
                        handleSubmit,
                        handleBlur,
                      }) => {
                        return (
                          <Form
                            id="formularioCargas"
                            name="cargas-form"
                            onSubmit={handleSubmit}
                            onKeyDown={(e) => {
                              if ((e.charCode || e.keyCode) === 13) {
                                e.preventDefault();
                              }
                            }}
                          >
                            <div className="row">
                              {/* Nombres */}
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label>
                                    Nombres{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <Field
                                    value={soloLetras(values.nombre)}
                                    type="text"
                                    innerRef={this.textInput}
                                    className="form-control form-control-sm"
                                    id="nombre"
                                    name="nombre"
                                    maxLength="50"
                                    placeholder="Ingrese nombres"
                                  />
                                  {errors.nombre && touched.nombre && (
                                    <small className="text-danger animated fadeIn">
                                      {errors.nombre}
                                    </small>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              {/* Apellido paterno */}
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    Apellido Paterno{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <Field
                                    type="text"
                                    className="form-control form-control-sm"
                                    id="apellidoPaterno"
                                    name="apellidoPaterno"
                                    maxLength="50"
                                    placeholder="Ingrese apellido paterno"
                                    value={soloLetras(values.apellidoPaterno)}
                                  />
                                  {errors.apellidoPaterno &&
                                    touched.apellidoPaterno && (
                                      <small className="text-danger animated fadeIn">
                                        {errors.apellidoPaterno}
                                      </small>
                                    )}
                                </div>
                              </div>

                              {/* Apellido materno */}
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>Apellido Materno</label>
                                  <Field
                                    type="text"
                                    className="form-control form-control-sm"
                                    id="apellidoMaterno"
                                    name="apellidoMaterno"
                                    maxLength="50"
                                    placeholder="Ingrese apellido materno"
                                    value={soloLetras(values.apellidoMaterno)}
                                  />
                                  {errors.apellidoMaterno &&
                                    touched.apellidoMaterno && (
                                      <small className="text-danger animated fadeIn">
                                        {errors.apellidoMaterno}
                                      </small>
                                    )}
                                </div>
                              </div>

                              {/* Rut */}
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    RUT <span className="text-danger">*</span>
                                  </label>
                                  <Field
                                    id="rutAsegurable"
                                    name="rutAsegurable"
                                    placeholder="ej. 12345678-9"
                                    maxLength="12"
                                    minLength="11"
                                    value={format(values.rutAsegurable)}
                                    type="text"
                                    validate={(value) => {
                                      this.guardarRut(value);
                                    }}
                                    className="form-control form-control-sm"
                                  />
                                  {errors.rutAsegurable &&
                                    touched.rutAsegurable && (
                                      <small className="text-danger animated fadeIn">
                                        {errors.rutAsegurable}
                                      </small>
                                    )}
                                </div>
                              </div>

                              {/* Parentesco */}

                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    Parentesco{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <select
                                    id="idParentesco"
                                    name="idParentesco"
                                    onChange={(value) => {
                                      setFieldValue("fechaNacimiento", "");
                                      handleChange(value);

                                      this.obtenerTiposDocumentosRequeridos(
                                        value.currentTarget.value
                                      );
                                      this.setState({
                                        data: {
                                          ...this.state.data,
                                          documentos: [],
                                        },
                                      });
                                    }}
                                    onBlur={handleBlur}
                                    value={values.idParentesco}
                                    className="form-control form-control-sm"
                                    onClick={() =>
                                      this.notificarSinOpciones(
                                        this.state.parentescos
                                      )
                                    }
                                  >
                                    {this.state.parentescos &&
                                    this.state.parentescos.length ? (
                                      <Fragment>
                                        <option value="0">
                                          Selecciona una opción
                                        </option>
                                        {this.state.parentescos.map(
                                          (item, i) => (
                                            <option
                                              key={`parentesco_${i}`}
                                              value={item.idParentesco}
                                            >
                                              {item.glosa.toUpperCase()}
                                            </option>
                                          )
                                        )}
                                      </Fragment>
                                    ) : this.state.data.parentesco &&
                                      !this.state.agregar ? (
                                      <option
                                        value={
                                          this.state.data.parentesco
                                            .idParentesco
                                        }
                                      >
                                        {this.state.data.parentesco.glosa.toUpperCase()}
                                      </option>
                                    ) : (
                                      <option value="0">
                                        Selecciona una opción
                                      </option>
                                    )}
                                  </select>
                                  {errors.idParentesco &&
                                  touched.idParentesco ? (
                                    <small className="text-danger animated fadeIn animated fadeIn">
                                      {errors.idParentesco}
                                    </small>
                                  ) : (
                                    errors.idParentesco &&
                                    !this.state.agregar && (
                                      <small className="text-danger animated fadeIn animated fadeIn">
                                        {errors.idParentesco}
                                      </small>
                                    )
                                  )}
                                </div>
                              </div>
                            </div>

                            {/* Mensaje documentos parentescos */}

                            <div className="row">
                              <div className="col-md-12">
                                <div className="form-group">
                                  <CargarArchivoParentesco
                                    tipoDocReq={tipoDocReq}
                                    prevFiles={listaDocumentos}
                                    fromCargas={true}
                                    cleanFiles={
                                      listaDocumentos !== undefined &&
                                      !listaDocumentos.length
                                    }
                                    maxFiles={2}
                                    minFiles={2}
                                    files={(documentos) => {
                                      this.obtenerDocumentos(documentos);
                                      setFieldValue(
                                        "documentosCargados",
                                        documentos
                                      );
                                    }}
                                    obtenerArchivoEliminado={(docEliminado) => {
                                      let nombreDocumentoEliminado =
                                        docEliminado.nombreDocto;
                                      let indiceNombreDocEliminado =
                                        listaDocumentos
                                          .map(
                                            (documento) => documento.nombreDocto
                                          )
                                          .indexOf(nombreDocumentoEliminado);

                                      let arrDocumentosActualizados =
                                        listaDocumentos.filter(
                                          (elem, index) =>
                                            index !== indiceNombreDocEliminado
                                        );

                                      this.setState({
                                        data: {
                                          ...this.state.data,
                                          documentos: arrDocumentosActualizados,
                                        },
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            </div>

                            {errors.documentosCargados && (
                              <small className="text-danger animated fadeIn">
                                {errors.documentosCargados}
                              </small>
                            )}

                            {/* Género */}
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    Genero{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <select
                                    id="idGenero"
                                    name="idGenero"
                                    className="form-control form-control-sm"
                                    style={{ display: "block" }}
                                    value={values.idGenero}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    onClick={() =>
                                      this.notificarSinOpciones(
                                        this.props.store.reducer.generos
                                      )
                                    }
                                  >
                                    {this.props.store.reducer.generos &&
                                    this.props.store.reducer.generos.length ? (
                                      <Fragment>
                                        <option value="0">
                                          {" "}
                                          Seleccione género
                                        </option>
                                        {this.props.store.reducer.generos.map(
                                          (item, i) => (
                                            <option
                                              key={`opcion_parentesco_${i}`}
                                              value={item.idGenero}
                                            >
                                              {item.descripcion}
                                            </option>
                                          )
                                        )}
                                      </Fragment>
                                    ) : this.state.data.genero &&
                                      !this.state.agregar ? (
                                      <option
                                        value={this.state.data.genero.idGenero}
                                      >
                                        {this.state.data.genero.descripcion.toUpperCase()}
                                      </option>
                                    ) : (
                                      <option value="0">
                                        {" "}
                                        Seleccione género
                                      </option>
                                    )}
                                  </select>
                                  {errors.idGenero && touched.idGenero && (
                                    <small className="text-danger animated fadeIn animated fadeIn">
                                      {errors.idGenero}
                                    </small>
                                  )}
                                </div>
                              </div>

                              {/* Fecha nacimiento */}
                              <div className="col-md-6">
                                <div className="form-group">
                                  <div className="w-100 d-block">
                                    <label>
                                      Fecha de nacimiento{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <DatePicker
                                      peekNextMonth
                                      showMonthDropdown
                                      showYearDropdown
                                      autoComplete="off"
                                      dropdownMode="select"
                                      id="fechaNacimiento"
                                      name="fechaNacimiento"
                                      value={values.fechaNacimiento}
                                      dateFormat="dd/MM/yyyy"
                                      placeholderText="DD/MM/YYYY"
                                      locale="es"
                                      onChange={(value) => {
                                        value =
                                          moment(value).format("DD/MM/YYYY");
                                        setFieldTouched(
                                          "fechaNacimiento",
                                          true
                                        );
                                        setFieldValue("fechaNacimiento", value);
                                      }}
                                      minDate={this.fechaMinima(
                                        values.idParentesco
                                      )}
                                      maxDate={this.fechaMaxima(
                                        values.idParentesco
                                      )}
                                      disabled={
                                        Number(values.idParentesco) === ID_ZERO
                                      }
                                      customInput={
                                        <MaskedTextInput
                                          className="form-control form-control-sm"
                                          placeholder="DD/MM/YYYY"
                                          mask={MASK_DATE}
                                        />
                                      }
                                    />

                                    {errors.fechaNacimiento &&
                                      touched.fechaNacimiento && (
                                        <small className="text-danger animated fadeIn">
                                          {errors.fechaNacimiento}
                                        </small>
                                      )}
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/*  Previsión salud */}
                            <div className="row">
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label>
                                    Nombre de sistema previsional de salud{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <select
                                    id="idSistemaPrevisionSalud"
                                    name="idSistemaPrevisionSalud"
                                    value={values.idSistemaPrevisionSalud}
                                    className="form-control form-control-sm"
                                    onChange={handleChange}
                                    onClick={() =>
                                      this.notificarSinOpciones(
                                        this.props.store.reducer.previsionSalud
                                      )
                                    }
                                  >
                                    {this.props.store.reducer.previsionSalud &&
                                    this.props.store.reducer.previsionSalud
                                      .length ? (
                                      <Fragment>
                                        <option value="0">
                                          Selecciona una opción
                                        </option>
                                        {this.props.store.reducer.previsionSalud.map(
                                          (item, i) => (
                                            <option
                                              name="prevision"
                                              key={`isapre_${i}`}
                                              value={item.idIsapre}
                                            >
                                              {item.glosa}
                                            </option>
                                          )
                                        )}
                                      </Fragment>
                                    ) : this.state.data.sistemaPrevisionSalud &&
                                      !this.state.agregar ? (
                                      <option
                                        value={
                                          this.state.data.sistemaPrevisionSalud
                                            .idsistemaPrevisionSalud
                                        }
                                      >
                                        {this.state.data.sistemaPrevisionSalud.glosa.toUpperCase()}
                                      </option>
                                    ) : (
                                      <option value="0">
                                        Selecciona una opción
                                      </option>
                                    )}
                                  </select>
                                  {errors.idSistemaPrevisionSalud &&
                                    touched.idSistemaPrevisionSalud && (
                                      <small className="text-danger animated fadeIn animated fadeIn">
                                        {errors.idSistemaPrevisionSalud}
                                      </small>
                                    )}
                                </div>
                              </div>
                            </div>

                            {/*  Peso */}
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    Peso (kg){" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="d-flex">
                                    <button
                                      id="menosPeso"
                                      type="button"
                                      className="btn-plus btn-sm btn-arrow text-center"
                                      onClickCapture={() => {
                                        values.documentos = listaDocumentos;
                                        values.peso <= 1
                                          ? (values.peso = 1)
                                          : --values.peso;

                                        this.setState({ data: values });
                                      }}
                                    >
                                      <i
                                        className="fa fa-chevron-left"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                    <Field
                                      className="form-control form-control-sm mx-1 text-center"
                                      id="peso"
                                      name="peso"
                                      type="text"
                                      maxLength="3"
                                      placeholder="Ingrese peso"
                                      value={formatNumber(values.peso)}
                                    />
                                    <button
                                      id="masPeso"
                                      type="button"
                                      onClick={() => {
                                        values.documentos = listaDocumentos;
                                        values.peso >= MAX_E_A
                                          ? (values.peso = MAX_E_A)
                                          : ++values.peso;
                                        this.setState({ data: values });
                                      }}
                                      className="btn-plus btn-sm btn-arrow text-center"
                                    >
                                      <i
                                        className="fa fa-chevron-right"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  </div>
                                  {errors.peso && touched.peso && (
                                    <small className="text-danger animated fadeIn animated fadeIn">
                                      {errors.peso}
                                    </small>
                                  )}
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    Estatura (cm){" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="d-flex">
                                    <button
                                      id="menosEstatura"
                                      type="button"
                                      className="btn-plus btn-sm btn-arrow text-center"
                                      onClickCapture={() => {
                                        values.documentos = listaDocumentos;
                                        values.estatura <= 1
                                          ? (values.estatura = 1)
                                          : --values.estatura;

                                        this.setState({ data: values });
                                      }}
                                    >
                                      <i
                                        className="fa fa-chevron-left"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                    <Field
                                      className="form-control form-control-sm mx-1 text-center"
                                      id="estatura"
                                      name="estatura"
                                      type="text"
                                      maxLength="3"
                                      placeholder="Ingrese estatura"
                                      value={formatNumber(values.estatura)}
                                    />
                                    <button
                                      id="masEstatura"
                                      type="button"
                                      className="btn-plus btn-sm btn-arrow text-center"
                                      onClickCapture={() => {
                                        values.documentos = listaDocumentos;
                                        values.estatura >= MAX_E_A
                                          ? (values.estatura = MAX_E_A)
                                          : ++values.estatura;

                                        this.setState({ data: values });
                                      }}
                                    >
                                      <i
                                        className="fa fa-chevron-right"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  </div>
                                  {errors.estatura && touched.estatura && (
                                    <small className="text-danger animated fadeIn">
                                      {errors.estatura}
                                    </small>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-12">
                                <hr
                                  style={{ border: ".05px solid lightGrey" }}
                                />
                                <p style={{ color: "#ed8d00" }}>
                                  Acá puedes agregar a todas tus cargas
                                </p>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-12">
                                <div className="button-zone text-right pt-3 pb-3">
                                  {this.state.edit !== 0 ? (
                                    <button
                                      id="cancelar"
                                      onClick={this.reset}
                                      className="btn btn-principal color-cancel btn-sm"
                                      type="button"
                                    >
                                      Cancelar
                                    </button>
                                  ) : null}
                                  <button
                                    id="agregar"
                                    className="btn btn-principal color-secundario btn-sm"
                                    type="submit"
                                    disabled={this.state.loadingData}
                                  >
                                    {this.state.loadingData ? (
                                      <Fragment>
                                        <i className="fa fa-spinner fa-pulse fa-fw"></i>
                                        <span className="sr-only">
                                          Loading...
                                        </span>
                                      </Fragment>
                                    ) : (
                                      <span>
                                        {" "}
                                        {this.state.agregar
                                          ? "Agregar"
                                          : "Actualizar"}
                                      </span>
                                    )}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Form>
                        );
                      }}
                    </Formik>
                  </div>
                )}
              </div>
            </div>

            <div className="container pb-5">
              <div className="mx-5">
                {this.state.cargas !== null && this.state.cargas.length > 0 ? (
                  <Table
                    titulo="Cargas"
                    datos={this.state.cargas}
                    columnas={this.state.columnDefs}
                  />
                ) : null}
              </div>
            </div>
          </div>
          <div className="button-zone text-right pt-3 pb-3">
            <button
              id="paginaAnterior"
              className="btn btn-principal color-secundario btn-lg"
              type="button"
              onClick={this.anteriorPagina}
            >
              Atrás
            </button>

            <button
              id="paginaSiguiente"
              className="btn btn-principal btn-lg"
              type="button"
              onClick={this.siguientePagina}
              disabled={this.state.loadingBtnSiguiente}
            >
              {this.state.loadingData ? (
                  <Fragment>
                    <i className="fa fa-spinner fa-pulse fa-fw"></i>
                    <span className="sr-only">Loading...</span>
                  </Fragment>
                ) : (
                  <span>Siguiente</span>
                )}
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state,
});

export default withRouter(
  connect(mapStateToProps, {
    add_carga,
    update_carga,
    wizard,
    actualizar_progreso_asegurable,
    declarar_carga,
    update_detalle,
    guardar_data_asegurable,
    actualizar_beneficiarios,
    get_generos,
    guardar_data_wizard_asegurado,
  })(Cargas)
);
