import React, { Component } from "react";
import {
  guardar_data_wizard_asegurado,
  wizard,
} from "../../../../../../actions/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { notificacion } from "../../../../transversales/Notificaciones";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { guardarDataWizard } from "../../../../../../services/asegurable.services";
import {
  EXITO_MIS_DATOS,
  ERROR_MIS_DATOS,
  CAMPO_REQUERIDO_FORMA_PAGO,
  CAMPO_REQUERIDO_BANCO,
  CAMPO_REQUERIDO_TIPO_CUENTA,
  CAMPO_REQUERIDO_NUMERO_CUENTA,
  VALOR_ZERO,
  PAGINA_2,
  TIPO_PAGINA,
  STRING_VACIO,
  NOTIFICACION_SIN_OPCIONES,
  ZERO_STRING,
  PAGINA_3,
  TIPO_TAB,
  POSICION_INICIAL,
  BANCO_RIPLEY_PLAN_INICIAL,
  TAB_UNO,
  LARGO_CERO_GRUPOS,
  NUMERO_RENOVACION_CERO,
  NOTIFICACION_TIPO_EXITOSA,
  GA_CATEGORIA_FORMULARIO_ENROLAMIENTO,
  GA_LABEL_FORMA_PAGO,
} from "../../../../../../utils/Constantes";
import { formatNumber } from "../../../../../../utils/Functions";
import { Fragment } from "react";
import { listarPorNroPolizaNroRenovacion } from "../../../../../../services/bus.poliza.services";
import { crearEventoTraza } from "../../../../../../utils/analitica";

class PagoReembolso extends Component {
  constructor(props) {
    super(props);
    this.state = {
      misDatos: this.props.store.reducer.dataWizardAsegurado,
      solicitud: this.props.store.reducer.data_asegurable.idSolicitud,
      validacionFormaPago: "d-none",
      catalogo: {
        formasDePago: this.props.store.reducer.forma_pago,
        bancos: this.props.store.reducer.bancos,
        tipoCuenta: this.props.store.reducer.tipo_cuenta,
      },
      loadingData: false,
    };
  }

  componentDidMount() {
    crearEventoTraza(GA_CATEGORIA_FORMULARIO_ENROLAMIENTO, GA_LABEL_FORMA_PAGO);
  }

  componentWillMount() {
    if (this.state.misDatos) {
      if (this.state.misDatos.formaPago) {
        if (this.state.misDatos.formaPago.idFormaPago === 3) {
          this.setState({ validacionFormaPago: "" });
        }
      }
    }
  }

  notificarSinOpciones = (catalogo) => {
    if (!catalogo.length) {
      notificacion("info", NOTIFICACION_SIN_OPCIONES)
    }
  }

  /*************************
   *  PETICIONES SERVIDOR  *
   *************************/
  async enviarMisDatos(values) {
    this.setState({ loadingData: true });
    const dataEnvio = this.state.misDatos;
    const { reducer } = this.props.store;

    if (reducer.dataWizardAsegurado.coberturasCargas) {
      dataEnvio.coberturasCargas = [...reducer.dataWizardAsegurado.coberturasCargas];
    }

    const glosaFormaPago = reducer.forma_pago &&
      reducer.forma_pago.length ?
      reducer.forma_pago
        .find(({ id }) =>
          id === Number(values.formaPago)
        ).nombre
      :
      reducer.dataWizardAsegurado.formaPago &&
      reducer.dataWizardAsegurado.formaPago.glosa;



    dataEnvio.formaPago = {
      idFormaPago: Number(values.formaPago),
      glosa: glosaFormaPago
    };

    let glosaBanco = STRING_VACIO;
    let glosaTipoCuenta = STRING_VACIO;

    if (Number(values.banco) !== VALOR_ZERO) {
      glosaBanco =
        reducer.bancos &&
          reducer.bancos.length ?
          reducer.bancos
            .find(({ id }) =>
              id === Number(values.banco)
            ).nombre
          :
          reducer.dataWizardAsegurado.institucionFinanciera &&
          reducer.dataWizardAsegurado.institucionFinanciera.glosa;

      glosaTipoCuenta =
        reducer.tipo_cuenta &&
          reducer.tipo_cuenta.length ?
          reducer.tipo_cuenta
            .find(({ id }) =>
              id === Number(values.tipoCuenta)
            ).nombre
          :
          reducer.dataWizardAsegurado.tipoCuentaBancaria &&
          reducer.dataWizardAsegurado.tipoCuentaBancaria.glosa;
    }

    dataEnvio.institucionFinanciera = {
      idInstitucionFinanciera: Number(values.banco),
      glosa: glosaBanco,
    };

    dataEnvio.tipoCuentaBancaria = {
      idTipoCuentaBancaria: Number(values.tipoCuenta),
      glosa: glosaTipoCuenta,
    };

    dataEnvio.numeroCuenta = values.numeroCuenta
      ? values.numeroCuenta
      : ZERO_STRING;

    await this.props.guardar_data_wizard_asegurado(dataEnvio);
    const res = await guardarDataWizard(dataEnvio);

    if (res) {
      const { informacionPoliza } = this.props.store.reducer;
      let planBase = true
      if (informacionPoliza && informacionPoliza.grupos && informacionPoliza.grupos.length > LARGO_CERO_GRUPOS) {
        Promise.all(
          informacionPoliza.grupos.map(async (grupo) => {
            if (grupo && grupo.poliza && grupo.poliza.numeroPoliza && grupo.numeroRenovacion !== NUMERO_RENOVACION_CERO) {
              let poliza = await listarPorNroPolizaNroRenovacion(grupo.poliza.numeroPoliza, grupo.poliza.numeroRenovacion);
              if (await poliza.agrupaciones && poliza.agrupaciones[POSICION_INICIAL] && poliza.agrupaciones[POSICION_INICIAL].glosa
                && poliza.agrupaciones[POSICION_INICIAL].glosa === BANCO_RIPLEY_PLAN_INICIAL) {
                planBase = false;
              }
            }
          })
        ).then((respuesta) => {
          if (!planBase) {
            notificacion(NOTIFICACION_TIPO_EXITOSA, EXITO_MIS_DATOS);
            this.props.wizard({ pagina: PAGINA_3, type: TIPO_PAGINA });
            this.props.wizard({
              tab: TAB_UNO,
              type: TIPO_TAB,
            });
          } else {
            notificacion(NOTIFICACION_TIPO_EXITOSA, EXITO_MIS_DATOS);
            this.props.wizard({ pagina: PAGINA_2, type: TIPO_PAGINA });
          }
        });
      } else {
        notificacion(NOTIFICACION_TIPO_EXITOSA, EXITO_MIS_DATOS);
        this.props.wizard({ pagina: PAGINA_2, type: TIPO_PAGINA });
      }

    } else {
      notificacion("warning", ERROR_MIS_DATOS);
    }
    this.setState({ loadingData: false });
  }

  render() {
    const misDatos = this.state.misDatos;

    return (
      <React.Fragment>
        <Formik
          initialValues={{
            formaPago:
              misDatos && misDatos.formaPago
                ? misDatos.formaPago.idFormaPago
                : 0,
            banco:
              misDatos && misDatos.institucionFinanciera
                ? misDatos.institucionFinanciera.idInstitucionFinanciera
                : 0,
            tipoCuenta:
              misDatos && misDatos.tipoCuentaBancaria
                ? misDatos.tipoCuentaBancaria.idTipoCuentaBancaria
                : 0,
            numeroCuenta:
              misDatos && misDatos.numeroCuenta ? misDatos.numeroCuenta : "",
          }}
          validationSchema={Yup.object().shape({
            formaPago: Yup.string()
              .required(CAMPO_REQUERIDO_FORMA_PAGO)
              .test("validarSelect", CAMPO_REQUERIDO_FORMA_PAGO, (value) => {
                return Number(value) !== 0 ? true : false;
              }),
            banco:
              this.state.validacionFormaPago === "" &&
              Yup.string()
                .required(CAMPO_REQUERIDO_BANCO)
                .test("validarSelect", CAMPO_REQUERIDO_BANCO, (value) => {
                  return Number(value) !== 0 ? true : false;
                }),
            tipoCuenta:
              this.state.validacionFormaPago === "" &&
              Yup.string()
                .required(CAMPO_REQUERIDO_TIPO_CUENTA)
                .test("validarSelect", CAMPO_REQUERIDO_TIPO_CUENTA, (value) => {
                  return Number(value) !== 0 ? true : false;
                }),
            numeroCuenta:
              this.state.validacionFormaPago === "" &&
              Yup.string()
                .required(CAMPO_REQUERIDO_NUMERO_CUENTA)
                .test(
                  "validarSelect",
                  CAMPO_REQUERIDO_NUMERO_CUENTA,
                  (value) => {
                    return Number(value) !== 0 ? true : false;
                  }
                ),
          })}
          onSubmit={async (values, { setSubmitting }) => {
            this.enviarMisDatos(values);
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur }) => {
            return (
              <fieldset
                disabled={
                  !this.props.store.reducer.informacionPoliza
                    .solicitudIncorporacion &&
                  !this.props.store.reducer.informacionPoliza.asegurable_5_50
                }
              >
                <Form
                  id="formularioReembolso"
                  name="mi-direccion-form"
                  onKeyDown={(e) => {
                    if ((e.charCode || e.keyCode) === 13) {
                      e.preventDefault();
                    }
                  }}
                >
                  <div
                    id="miDireccion"
                    className="cuerpo-wizard pt-3 pb-5 o-white container"
                  >
                    <div className="row mx-5 animated fadeInRight">
                      <div className="col-12">
                        <h5 className="font-weight-bold text-primary">
                          Forma de Pago Reembolso Seguro Complementario de Salud
                        </h5>
                        <hr className="mt-1" />
                      </div>

                      {/* formaPago */}
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>
                            Tipos de pago <span className="text-danger">*</span>
                          </label>
                          <select
                            id="formaPago"
                            name="formaPago"
                            className="form-control form-control-sm"
                            style={{ display: "block" }}
                            value={values.formaPago}
                            onChange={(e) => {
                              handleChange(e);
                              let validacionFormaPago = "";
                              if (Number(e.target.value) === 4) {
                                validacionFormaPago = "d-none";
                              }
                              this.setState({ validacionFormaPago });
                            }}
                            onBlur={handleBlur}
                            onClick={() => this.notificarSinOpciones(this.state.catalogo.formasDePago)}

                          >
                            {this.state.catalogo.formasDePago && this.state.catalogo.formasDePago.length ?
                              <Fragment>
                                <option value="0"> Seleccione forma de pago</option>
                                {this.state.catalogo.formasDePago.map(
                                  (item, i) => (
                                    <option
                                      key={`opcion_parentesco_${i}`}
                                      value={item.id}
                                    >
                                      {item.nombre}
                                    </option>
                                  )
                                )}
                              </Fragment>
                              :
                              this.state.misDatos.formaPago && this.state.misDatos.formaPago.idFormaPago ?
                                <option
                                  value={this.state.misDatos.formaPago.idFormaPago}
                                >
                                  {this.state.misDatos.formaPago.glosa}
                                </option>
                                :
                                <option value="0"> Seleccione forma de pago</option>
                            }
                          </select>
                          {errors.formaPago && touched.formaPago && (
                            <small className="text-danger animated fadeIn animated fadeIn">
                              {errors.formaPago}
                            </small>
                          )}
                        </div>
                      </div>

                      {/* banco */}
                      <div
                        className={
                          "col-md-12 " + this.state.validacionFormaPago
                        }
                      >
                        <div className="form-group">
                          <label>
                            Nombre de banco{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <select
                            id="banco"
                            name="banco"
                            disabled={this.state.catalogo.bancos.length === 0}
                            className="form-control form-control-sm"
                            style={{ display: "block" }}
                            value={values.banco}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            onClick={() => this.notificarSinOpciones(this.state.catalogo.bancos)}
                          >
                            {this.state.catalogo.bancos && this.state.catalogo.bancos.length ?
                              <Fragment>
                                <option value="0"> Seleccione banco</option>
                                {this.state.catalogo.bancos.map((item, i) => (
                                  <option
                                    key={`opcion_parentesco_${i}`}
                                    value={item.id}
                                  >
                                    {item.nombre}
                                  </option>
                                ))}
                              </Fragment>
                              :
                              this.state.misDatos.institucionFinanciera && this.state.misDatos.institucionFinanciera.idInstitucionFinanciera ?
                                <option
                                  value={this.state.misDatos.institucionFinanciera.idInstitucionFinanciera}
                                >
                                  {this.state.misDatos.institucionFinanciera.glosa}
                                </option>
                                :
                                <option value="0"> Seleccione banco</option>
                            }
                          </select>
                          {errors.banco && touched.banco && (
                            <small className="text-danger animated fadeIn animated fadeIn">
                              {errors.banco}
                            </small>
                          )}
                        </div>
                      </div>

                      {/* tipoCuenta */}
                      <div
                        className={
                          "col-md-12 " + this.state.validacionFormaPago
                        }
                      >
                        <div className="form-group">
                          <label>
                            Tipo de cuenta{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <select
                            id="tipoCuenta"
                            name="tipoCuenta"
                            disabled={
                              this.state.catalogo.tipoCuenta.length === 0
                            }
                            className="form-control form-control-sm"
                            style={{ display: "block" }}
                            value={values.tipoCuenta}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            onClick={() => this.notificarSinOpciones(this.state.catalogo.tipoCuenta)}
                          >
                            {this.state.catalogo.tipoCuenta && this.state.catalogo.tipoCuenta ?
                              <Fragment>
                                <option value="0">
                                  {" "}
                                  Seleccione tipo de cuenta
                                </option>
                                {this.state.catalogo.tipoCuenta.map((item, i) => (
                                  <option
                                    key={`opcion_parentesco_${i}`}
                                    value={item.id}
                                  >
                                    {item.nombre}
                                  </option>
                                ))}
                              </Fragment>
                              :
                              this.state.tipoCuentaBancaria && this.state.misDatos.tipoCuentaBancaria.idTipoCuentaBancaria ?
                                <option
                                  value={this.state.misDatos.tipoCuentaBancaria.idTipoCuentaBancaria}
                                >
                                  {this.state.misDatos.tipoCuentaBancaria.glosa}
                                </option>
                                :
                                <option value="0">
                                  {" "}
                                  Seleccione tipo de cuenta
                                </option>
                            }
                          </select>
                          {errors.tipoCuenta && touched.tipoCuenta && (
                            <small className="text-danger animated fadeIn animated fadeIn">
                              {errors.tipoCuenta}
                            </small>
                          )}
                        </div>
                      </div>

                      {/* numeroCuenta */}
                      <div
                        className={
                          "col-md-12 " + this.state.validacionFormaPago
                        }
                      >
                        <div className="form-group">
                          <label>
                            Número de cuenta{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <Field
                            className="form-control form-control-sm"
                            id="numeroCuenta"
                            name="numeroCuenta"
                            type="text"
                            placeholder="Ingrese número de cuenta"
                            maxLength="50"
                            value={formatNumber(values.numeroCuenta)}
                          />
                          {errors.numeroCuenta && touched.numeroCuenta && (
                            <small className="text-danger animated fadeIn">
                              {errors.numeroCuenta}
                            </small>
                          )}
                        </div>
                        <div className="p-3 mt-5 bg-primary text-white text-justify rounded animated fadeIn">
                          Recuerda que los datos bancarios deben corresponder al
                          asegurado titular del seguro. En caso contrario el
                          reembolso no podrá ser depositado.
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Botonera */}
                  <div className="button-zone text-right pt-3 pb-3 ml-auto">
                    <button
                      id="botonSiguiente"
                      className="btn btn-principal btn-lg"
                      type="submit"
                    >
                      {this.state.loadingData ? (
                        <Fragment>
                          <i className="fa fa-spinner fa-pulse fa-fw"></i>
                          <span className="sr-only">Loading...</span>
                        </Fragment>
                      ) : (
                        <span>Siguiente</span>
                      )}
                    </button>
                  </div>
                </Form>
              </fieldset>
            );
          }}
        </Formik>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state,
});

export default withRouter(
  connect(mapStateToProps, { guardar_data_wizard_asegurado, wizard })(
    PagoReembolso
  )
);
