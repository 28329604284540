import React, { Component } from "react";
import ModalEditarAsegurable from "../../../jefatura-ejecutivo/ModalEditarAsegurable";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  CAMPO_REQUERIDO,
  VALIDACION_RUT,
  VALIDACION_NOMBRE,
  VALIDACION_APELLIDO_P,
  VALIDACION_APELLIDO_M,
  VALIDACION_MAIL,
  CAMPO_REQUERIDO_FECHA,
  MASK_DATE,
} from "../../../../../../utils/Constantes";
import SelectContratante from "../SelectContratante";
import MultiSelect from "../../../../transversales/MultiSelect";
import DatePicker from "react-datepicker";
import MaskedTextInput from "react-text-mask";
import moment from "moment";
const { format, validate } = require("rut.js");

class ModalEditarAsegurableRRHH extends Component {
  state = {
    idNegocio: this.props.datosAsegurable.idNegocio,
    idAsegurable: this.props.datosAsegurable.id,
    polizas: this.props.datosAsegurable.polizasTotales,
    polizasSeleccionadas: [],
    datosAsegurable: { ...this.props.datosAsegurable },
  };

  componentDidMount() {
    this.setState(
      {
        datosAsegurable: { ...this.props.datosAsegurable },
        idNegocio: this.props.datosAsegurable.idNegocio,
      },
      () => {
        this.filtrarPolizasSeleccionadas();
      }
    );
    this.desactivarAutocomplete();
    this.desactivarContratante();
  }

  // Obtiene las polizas totales disponibles onChange de contratante
  obtenerPolizas = (idNegocio, polizas) => {
    this.setState({ idNegocio, polizas });
  };

  // Filtra las polizas seleccionadas desde el listado de polizas totales disponibles
  filtrarPolizasSeleccionadas = async () => {
    const polizasSeleccionadas = await this.state.datosAsegurable.polizas.split(
      ","
    ); // Ej.: ["9474-0", "9474-0"]
    const polizasTotales = await this.state.polizas; // Ej.: [{...poliza}, {...poliza}]
    const polizasFiltradas = [];

    await polizasSeleccionadas.forEach((polizaSeleccionada) => {
      polizasTotales.forEach((polizaDisponible) => {
        const nombrePoliza = polizaDisponible.label.split(" ");
        if (nombrePoliza[0] === polizaSeleccionada) {
          polizasFiltradas.push(polizaDisponible);
        }
      });
    });

    this.setState({ polizasSeleccionadas: polizasFiltradas });
  };

  // Desactiva elementos del DOM
  desactivarAutocomplete = () =>
    (document.getElementById("fechaInicioVigencia").autocomplete = "off");
  desactivarContratante = () =>
    (document.getElementById("idNegocio").disabled = true);

  render() {
    return (
      <ModalEditarAsegurable
        idSubmit="submitMisProcesos"
        type="medium"
        show={this.props.mostrarEditar}
        title="Editar Asegurable"
        action="Editar"
        onCancel={() => {
          this.props.handleMostrarEditar();
        }}
        onClose={() => {
          this.props.handleMostrarEditar();
        }}
        showCancelButton={false}
        showCloseButton={true}
        showLeftColumn={false}
        showSubmitButton={false}
      >
        <div className="container software-edit-container">
          <div className="mx-5">
            <div className="row animated fadeInRight">
              {/* Contratante (bloqueado) */}
              <SelectContratante
                obtenerPolizas={this.obtenerPolizas}
                idNegocio={this.state.idNegocio}
              />

              <Formik
                enableReinitialize={true}
                initialValues={{
                  polizas: this.state.polizasSeleccionadas || [],
                  rutCompleto: this.state.datosAsegurable.rutCompleto || "",
                  nombres: this.state.datosAsegurable.nombres || "",
                  apellidoPaterno:
                    this.state.datosAsegurable.apellidoPaterno || "",
                  apellidoMaterno:
                    this.state.datosAsegurable.apellidoMaterno || "",
                  correoElectronico:
                    this.state.datosAsegurable.correoElectronico || "",
                  fechaInicioVigencia:
                    this.state.datosAsegurable.fechaInicioVigencia || "",
                }}
                validationSchema={Yup.object().shape({
                  polizas: Yup.array().required(CAMPO_REQUERIDO).nullable(),
                  rutCompleto: Yup.string()
                    .required(CAMPO_REQUERIDO)
                    .test("validarRut", VALIDACION_RUT, (value) => {
                      return validate(value);
                    }),
                  nombres: Yup.string()
                    .required(CAMPO_REQUERIDO)
                    .min(3, VALIDACION_NOMBRE),
                  apellidoPaterno: Yup.string()
                    .required(CAMPO_REQUERIDO)
                    .min(3, VALIDACION_APELLIDO_P),
                  apellidoMaterno: Yup.string()
                    .required(CAMPO_REQUERIDO)
                    .min(3, VALIDACION_APELLIDO_M),
                  correoElectronico: Yup.string()
                    .required(CAMPO_REQUERIDO)
                    .email(VALIDACION_MAIL),
                  fechaInicioVigencia: Yup.string().required(
                    CAMPO_REQUERIDO_FECHA
                  ),
                })}
                onSubmit={(values, { resetForm }) => {
                  const asegurable = {
                    ...values,
                    idNegocio: this.state.idNegocio,
                    id: this.state.idAsegurable,
                  };
                  this.props.handleSubmitEditar(asegurable);
                  this.props.handleMostrarEditar();
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  setFieldValue,
                  setFieldTouched,
                }) => {
                  return (
                    <Form
                      className="col-md-12"
                      onKeyDown={(e) => {
                        if ((e.charCode || e.keyCode) === 13) {
                          e.preventDefault();
                        }
                      }}
                    >
                      <div className="row">
                        {/* Póliza */}
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>Póliza</label>
                            <MultiSelect
                              value={values.polizas}
                              onChange={(value) => {
                                this.setState({ polizasSeleccionadas: value });
                                setFieldValue("polizas", value);
                              }}
                              tooltip={true}
                              onBlur={() => {
                                setFieldTouched("polizas", true);
                              }}
                              name="polizas"
                              options={this.state.polizas}
                              noOptionsMessage={() =>
                                "No existen más pólizas para este contratante"
                              }
                              placeholder={"Ingrese póliza/s"}
                              errors={errors.polizas}
                              touched={touched.polizas}
                            />
                          </div>
                        </div>

                        {/* Rut */}
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>RUT</label>
                            <Field
                              className="form-control"
                              id="rutCompleto"
                              name="rutCompleto"
                              type="text"
                              placeholder="ej. 12345678-9"
                              maxLength="12"
                              minLength="11"
                              value={
                                values.rutCompleto.length > 1
                                  ? format(values.rutCompleto)
                                  : values.rutCompleto
                              }
                            />
                            {errors.rutCompleto && touched.rutCompleto && (
                              <small className="text-danger animated fadeIn">
                                {errors.rutCompleto}
                              </small>
                            )}
                          </div>
                        </div>

                        {/* Nombres */}
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>Nombres</label>
                            <Field
                              className="form-control"
                              id="nombres"
                              name="nombres"
                              type="text"
                              placeholder="Ingrese nombres"
                              value={values.nombres}
                            />
                            {errors.nombres && touched.nombres && (
                              <small className="text-danger animated fadeIn">
                                {errors.nombres}
                              </small>
                            )}
                          </div>
                        </div>

                        {/* Apellido paterno */}
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Apellido paterno</label>
                            <Field
                              className="form-control"
                              id="apellidoPaterno"
                              name="apellidoPaterno"
                              type="text"
                              placeholder="Ingrese apellido paterno"
                              value={values.apellidoPaterno}
                            />
                            {errors.apellidoPaterno &&
                              touched.apellidoPaterno && (
                                <small className="text-danger animated fadeIn">
                                  {errors.apellidoPaterno}
                                </small>
                              )}
                          </div>
                        </div>

                        {/* Apellido materno */}
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Apellido materno</label>
                            <Field
                              className="form-control"
                              id="apellidoMaterno"
                              name="apellidoMaterno"
                              type="text"
                              placeholder="Ingrese apellido materno"
                              value={values.apellidoMaterno}
                            />
                            {errors.apellidoMaterno &&
                              touched.apellidoMaterno && (
                                <small className="text-danger animated fadeIn">
                                  {errors.apellidoMaterno}
                                </small>
                              )}
                          </div>
                        </div>

                        {/* Email */}
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>Email</label>
                            <Field
                              className="form-control"
                              id="correoElectronico"
                              name="correoElectronico"
                              type="text"
                              placeholder="test@gmail.com"
                              maxLength="50"
                              value={values.correoElectronico}
                            />
                            {errors.correoElectronico &&
                              touched.correoElectronico && (
                                <small className="text-danger animated fadeIn">
                                  {errors.correoElectronico}
                                </small>
                              )}
                          </div>
                        </div>

                        {/* Fecha Inicio Vigencia */}
                        <div className="col-md-12">
                          <div className="form-group up-calendar">
                            <label>Fecha Inicio Vigencia</label>
                            <DatePicker
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              popperPlacement="top-start"
                              dropdownMode="select"
                              id="fechaInicioVigencia"
                              name="fechaInicioVigencia"
                              value={values.fechaInicioVigencia}
                              dateFormat="dd/MM/yyyy"
                              placeholderText="DD/MM/YYYY"
                              locale="es"
                              onChange={async (value) => {
                                const fecha =
                                  moment(value).format("DD/MM/YYYY");
                                this.setState({ fechaInicioVigencia: fecha });
                                setFieldValue("fechaInicioVigencia", fecha);
                                setFieldTouched("fechaInicioVigencia", true);
                              }}
                              onBlur={handleBlur}
                              customInput={
                                <MaskedTextInput
                                  className="form-control"
                                  placeholder="DD/MM/YYYY"
                                  mask={MASK_DATE}
                                />
                              }
                            />
                            {errors.fechaInicioVigencia &&
                              touched.fechaInicioVigencia && (
                                <small className="text-danger animated fadeIn">
                                  {errors.fechaInicioVigencia}
                                </small>
                              )}
                          </div>
                        </div>
                      </div>

                      <div className="button-zone text-right pt-3 pb-3">
                        {/* Cancelar edicion */}
                        <button
                          id="cancelarEditar"
                          className="btn btn-principal color-cancel color-secundario btn-sm"
                          type="button"
                          onClick={() => {
                            this.props.handleMostrarEditar();
                          }}
                        >
                          Cancelar
                        </button>
                        {/* Aceptar edicion */}
                        <button
                          id="submitEditar"
                          className="btn btn-principal btn_sm"
                          type="submit"
                        >
                          Editar
                        </button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </ModalEditarAsegurable>
    );
  }
}

export default ModalEditarAsegurableRRHH;
