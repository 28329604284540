import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import ReactDOM from "react-dom";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import {
    VALIDACION_PESO_ARCHIVO,
    VALIDACION_TIPO_ARCHIVO,
    PDF,
    PNG,
    JPEG,
    JPG,
    MSG,
    ERROR_BORRAR_ARCHIVO,
    EXITO_BORRAR_ARCHIVO,
    TIPO_DATA_DOC_PDF,
    NOMINA_PRELIMINAR_DELEGADO,
    ERROR_CARGAR_ARCHIVO,
    EXITO_CARGAR_ARCHIVO_NOMINA_PRELIMINAR_DELEGADO,
    TIPO_DATA_PNG,
    TIPO_DATA_MSG,
    TIPO_DATA_JPEG,
    VALOR_ZERO,
    VALOR_UNO,
    VALOR_DOS,
    ERROR_FORMATO_ARCHIVO_NOMINA_PRELIMINAR,
    VALOR_NEGATIVO,
    SIGNO_IGUAL,
    SIGNO_GUION_BAJO,
    SIGNO_PUNTO,
    BASE64,
    SIGNO_GUION,
    ERROR_ARCHIVO_NO_CARGADO,
    SIN_DOCUMENTOS,
    CIEN_POR_CIENTO,
    UNIDADES_PROGRESO,
    PORCENTAJE_LIMITE_PROGRESO,
} from "../../../../../../utils/Constantes";
import { notificacion } from "../../../../transversales/Notificaciones";
import Files from "react-files";
import { confirmAlert } from "react-confirm-alert";
import cruzRoja from "../../../../../assets/images/cruzRed.png";
import checkVerde from "../../../../../assets/images/checkGreen.svg";
import {
    setArchivosCotizacion,
    getArchivosCotizacion,
    eliminarArchivoCotizacion,
} from "../../../../../../services/cotizacion.services";
import Spinner from "../../../../transversales/Spinner";

const ModalNominaPreliminarDelegado = (props) => {
    const [mostrarDialogo, setMostrarDialogo] = useState(false);
    const [cargarDatos, setCargarDatos] = useState(false);
    const [archivoDocumentoNominaPreliminar, setArchivoDocumentoNominaPreliminar] = useState({});
    const [archivosAdjuntos, setArchivosAdjuntos] = useState({});
    const [errorGuardarArchivo, setErrorGuardarArchivo] = useState("")

    const [listaDocumentoNominaPreliminar, setListaDocumentoNominaPreliminar] = useState([]);
    const [mostrarBarraProgreso, setMostrarBarraProgreso] = useState(false);
    const [porcentajeProgreso, setPorcentajeProgreso] = useState(VALOR_ZERO);
    const [guardado, setGuardado] = useState(false);
    const [claveArchivo, setClaveArchivo] = useState(VALOR_ZERO);
    const [cargandoArchivos, setCargandoArchivos] = useState(true);
    const [indiceMaximo, setIndiceMaximo] = useState(VALOR_UNO);

    const { negocio } = useSelector((store) => store.reducer);

    useEffect(() => {
        if (props.show > VALOR_ZERO) {
            obtenerArchivos()
            setMostrarDialogo(true)
        }
        // eslint-disable-next-line
    }, [props.show]);

    const cerrarDialogo = async () => {
        setMostrarDialogo(false)
        setErrorGuardarArchivo("");
        setArchivoDocumentoNominaPreliminar({})
        setArchivosAdjuntos({})
    };

    const onFilesError = async (error) => {
        if (error.code === VALOR_DOS) {
            notificacion("warning", VALIDACION_PESO_ARCHIVO);
            setArchivoDocumentoNominaPreliminar({})
            setErrorGuardarArchivo(ERROR_FORMATO_ARCHIVO_NOMINA_PRELIMINAR)
        } else if (error.code === VALOR_UNO) {
            notificacion("warning", VALIDACION_TIPO_ARCHIVO);
            setArchivoDocumentoNominaPreliminar({})
            setErrorGuardarArchivo(ERROR_FORMATO_ARCHIVO_NOMINA_PRELIMINAR)
        }
    }

    const onFilesChange = async (file) => {
        setPorcentajeProgreso(VALOR_ZERO)
        setMostrarBarraProgreso(false)
        if (file[VALOR_ZERO]) {
            setErrorGuardarArchivo("")
            let tipoCarga;
            let nombreArchivo;

            tipoCarga = NOMINA_PRELIMINAR_DELEGADO.toLowerCase();
            nombreArchivo = NOMINA_PRELIMINAR_DELEGADO.toUpperCase() + SIGNO_GUION_BAJO + indiceMaximo
                + SIGNO_PUNTO + file[VALOR_ZERO].extension;

            let dataEnvio;
            let reader = new FileReader();
            reader.readAsDataURL(file[VALOR_ZERO]);
            reader.onload = () => {
                dataEnvio = {
                    listaDoctos: [
                        {
                            nombreDocto: nombreArchivo,
                            streamBase64: reader.result,
                        },
                    ],
                    metadataDocto: { idNegocio: negocio.idNegocio, rutContratante: negocio.rutEmpresa + "-" + negocio.digitoVerificador },
                    tipoDocto: tipoCarga,
                };

                let dataDoc = {
                    nombreDocto: nombreArchivo,
                    archivo: reader.result,
                };
                setArchivoDocumentoNominaPreliminar(dataDoc);

                let archivos = { dataEnvio, tipoCarga };
                setArchivosAdjuntos(archivos);
            };
        }
    }

    const eliminarArchivo = async (idDocto) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="modal-confirm-container">
                        <div className="d-flex">
                            <p className="h4">¿Está seguro de eliminar el archivo?</p>
                        </div>

                        <div className="row mt-4">
                            <button
                                className="btn btn-principal color-cancel btn-sm d-block ml-auto mr-2"
                                onClick={() => {
                                    onClose();
                                }}
                            >
                                Cancelar
                            </button>
                            <button
                                className="btn btn-principal color-danger btn-sm"
                                onClick={async () => {
                                    if (idDocto) {
                                        let res = await eliminarArchivoCotizacion(idDocto);
                                        if (res === null)
                                            notificacion("danger", ERROR_BORRAR_ARCHIVO);
                                        else {
                                            notificacion("success", EXITO_BORRAR_ARCHIVO);
                                            obtenerArchivos()
                                        }
                                    }
                                    onClose();
                                }}
                            >
                                Eliminar
                            </button>
                        </div>
                    </div>
                );
            },
        });
    }

    const descargarArchivo = async (documento) => {
        let archivo = documento.streamBase64;
        let nombreDocto = documento.nombreDocto;
        let tipo;
        let linkSource;

        if (documento.idDocto) {
            archivo = archivo.split(BASE64)[VALOR_UNO];

            if (nombreDocto.indexOf(PDF) > VALOR_NEGATIVO) {
                tipo = TIPO_DATA_DOC_PDF;
                archivo = archivo + SIGNO_IGUAL;
            } else if (nombreDocto.indexOf(PNG) > VALOR_NEGATIVO) {
                tipo = TIPO_DATA_PNG;
                archivo = archivo.substring(VALOR_ZERO, archivo.length - VALOR_UNO);
            }
            else if (nombreDocto.indexOf(MSG) > VALOR_NEGATIVO) {
                tipo = TIPO_DATA_MSG;
            } else if (nombreDocto.indexOf(JPG) > VALOR_NEGATIVO || nombreDocto.indexOf(JPEG) > VALOR_NEGATIVO) {
                tipo = TIPO_DATA_JPEG;
            }

            linkSource = `${tipo}${archivo}`;
        } else {
            linkSource = archivo;
        }

        linkSource = `${tipo}${archivo}`;

        const downloadLink = document.createElement('a');
        downloadLink.href = linkSource;
        downloadLink.download = nombreDocto;
        downloadLink.click();
    }

    const guardarArchivo = async () => {
        setPorcentajeProgreso(VALOR_UNO)
        setCargarDatos(true)
        setMostrarBarraProgreso(true)
        if (Object.keys(archivosAdjuntos).length !== VALOR_ZERO) {
            let res = await setArchivosCotizacion(archivosAdjuntos.dataEnvio);

            if (res === null) {
                notificacion("danger", ERROR_CARGAR_ARCHIVO);
                setCargarDatos(false)
                setMostrarBarraProgreso(false)
            } else {
                obtenerArchivos()
                setArchivoDocumentoNominaPreliminar({})
                setArchivosAdjuntos({})
                notificacion("success", EXITO_CARGAR_ARCHIVO_NOMINA_PRELIMINAR_DELEGADO);
                setCargarDatos(false)
                setPorcentajeProgreso(CIEN_POR_CIENTO)
                setGuardado(true);
                setIndiceMaximo(indiceMaximo + VALOR_UNO)
            }
        } else {
            setErrorGuardarArchivo(ERROR_ARCHIVO_NO_CARGADO)
            setCargarDatos(false)
            setMostrarBarraProgreso(false)
        }
    }

    useEffect(() => {
        if (porcentajeProgreso > VALOR_ZERO && !guardado && porcentajeProgreso < PORCENTAJE_LIMITE_PROGRESO) {
            setPorcentajeProgreso(porcentajeProgreso + UNIDADES_PROGRESO)
        } else {
            setPorcentajeProgreso(CIEN_POR_CIENTO)
        }
        // eslint-disable-next-line
    }, [porcentajeProgreso]);


    const obtenerArchivos = async () => {
        let data = {
            metadataDocto: {
                idNegocio: negocio.idNegocio, rutContratante: negocio.rutEmpresa + SIGNO_GUION + negocio.digitoVerificador,
                tipoDocumento: NOMINA_PRELIMINAR_DELEGADO
            }
        };

        const res = await getArchivosCotizacion(data);

        if (res !== SIN_DOCUMENTOS) {
            setListaDocumentoNominaPreliminar(res)
            if (indiceMaximo <= VALOR_UNO) {
                indiceMaximoArchivos(res)
            }
            setCargandoArchivos(false)
        } else {
            setListaDocumentoNominaPreliminar([])
            setCargandoArchivos(false)
        }
    }

    const indiceMaximoArchivos = async (archivos) => {
        let valorIndice = VALOR_ZERO;
        archivos.forEach((archivo) => {
            let indiceTemporal = archivo.nombreDocto.split(SIGNO_GUION_BAJO)[VALOR_UNO];
            indiceTemporal = indiceTemporal.split(SIGNO_PUNTO)[VALOR_ZERO];
            if (valorIndice < parseInt(indiceTemporal)) {
                valorIndice = indiceTemporal;
            }
        })
        setIndiceMaximo((parseInt(valorIndice) + VALOR_UNO))
    }

    return ReactDOM.createPortal(
        <div
            className={`${mostrarDialogo ? "modal_overlay show lbg" : "modal_overlay"}`}
        >
            {mostrarDialogo && cargandoArchivos ? <Spinner claseColor="transparente"
                idSpinner="spinnerTransparente"
                posicionLogo="posicionLogo align-self-center w-100" />
                :
                <div className="container h-100">
                    <div className="align-self-center w-100">
                        <div
                            className="main_modal animated bounceInLeft center-2 large"
                            style={{ maxWidth: "700px" }}
                        >
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="modal_title">
                                        <div
                                            className="button_close cursor"
                                            onClick={() => {
                                                cerrarDialogo();
                                            }}
                                        >
                                            <svg
                                                stroke="currentColor"
                                                fill="currentColor"
                                                viewBox="0 0 352 512"
                                                height="1em"
                                                width="1em"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z" />
                                            </svg>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="container font-weight-bold text-muted" style={{ marginBottom: 30 }}>
                                                <h4>Adjuntar nómina preliminar delegado</h4>
                                            </div>

                                            {/* DOCUMENTO NOMINA PRELIMINAR DELEGADO */}
                                            <div className="col-md-12">
                                                <React.Fragment>
                                                    <Files
                                                        className="input-file py-2 animated fadeIn"
                                                        id="subirNominaPreliminar"
                                                        onChange={(file) => {
                                                            setClaveArchivo(claveArchivo + 1)
                                                            onFilesChange(
                                                                file
                                                            );
                                                        }}
                                                        accepts={[".pdf", ".jpeg", ".png", ".msg", ".jpg"]}
                                                        onError={onFilesError}
                                                        multiple={false}
                                                        maxFiles={1}
                                                        maxFileSize={2000000}
                                                        minFileSize={0}
                                                        clickable
                                                        key={claveArchivo}
                                                    >
                                                        <div className="row cursor text-center" style={{ marginTop: 5, marginBottom: 5 }}>
                                                            <div className="my-auto ml-auto">
                                                                <i
                                                                    title="Adjuntar pdf"
                                                                    className="fa fa-file-pdf-o text-pdf d-block text-right"
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </div>
                                                            <div className="my-auto mr-auto ml-2">
                                                                {Object.keys(archivoDocumentoNominaPreliminar).length === VALOR_ZERO ? <span className="d-block">
                                                                    Subir documento de nómina preliminar delegado
                                                                            </span>
                                                                    :
                                                                    <div className="font-weight-bold">{archivoDocumentoNominaPreliminar.nombreDocto}</div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </Files>
                                                    {errorGuardarArchivo === "" ? <span
                                                        className="font-weight-bold mb-2 d-block"
                                                        style={{ color: "#e89b03" }}
                                                    >
                                                        Formatos permitidos (PDF, PNG, JPEG, MSG)
                                                                </span>
                                                        :
                                                        <span
                                                            className="font-weight-bold mb-2 d-block"
                                                            style={{ color: "red" }}
                                                        >
                                                            <img
                                                                src={cruzRoja}
                                                                width="30"
                                                                height="28"
                                                                alt=""
                                                            ></img>
                                                            {errorGuardarArchivo}
                                                        </span>
                                                    }

                                                    {mostrarBarraProgreso && (
                                                        <div className="row">
                                                            <small className="col-12">
                                                                <strong className="pull-right">
                                                                    {porcentajeProgreso.toString() + "%"}
                                                                </strong>
                                                            </small>
                                                            <div className="col-12">
                                                                <div className="progress">
                                                                    <div
                                                                        className={`progress-bar ${porcentajeProgreso < CIEN_POR_CIENTO &&
                                                                            "progress-bar-striped progress-bar-animated"
                                                                            } bg-warning`}
                                                                        role="progressbar"
                                                                        aria-valuenow={porcentajeProgreso}
                                                                        aria-valuemin="0"
                                                                        aria-valuemax="100"
                                                                        style={{
                                                                            width:
                                                                                porcentajeProgreso.toString() + "%",
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </React.Fragment>

                                                <div className="row">
                                                    {Object.keys(listaDocumentoNominaPreliminar).length !== VALOR_ZERO &&
                                                        <div className="container font-weight-bold text-muted" style={{ marginTop: 25 }}>
                                                            <span>Documentos Cargados</span>
                                                        </div>
                                                    }
                                                </div>
                                                <div style={{ overflowY: 'scroll', maxHeight: 300, overflowX: 'hidden' }}>
                                                    {Object.keys(listaDocumentoNominaPreliminar).length !== VALOR_ZERO && listaDocumentoNominaPreliminar &&
                                                        listaDocumentoNominaPreliminar.map((item, i) => (
                                                            <div className="row animated fadeIn" style={{ paddingTop: 15 }} key={NOMINA_PRELIMINAR_DELEGADO + SIGNO_GUION_BAJO + i}>
                                                                <div className="col-2">
                                                                    <img
                                                                        src={checkVerde}
                                                                        width="30"
                                                                        height="30"
                                                                        className="img-fluid mx-auto mb-2"
                                                                        id="imgCheckAgregarEjecutivo"
                                                                        alt=""
                                                                    ></img>
                                                                </div>
                                                                <div className="col-8 my-auto" style={{ padding: 0, marginLeft: '-30px' }}>
                                                                    <span
                                                                        className="d-block"
                                                                        style={{ wordBreak: "break-word" }}
                                                                    >
                                                                        {item.nombreDocto}
                                                                    </span>
                                                                </div>
                                                                <div className="col-1 my-auto" style={{ paddingRight: 0 }}>
                                                                    <i
                                                                        className="fa fa-download fa-2x cursor"
                                                                        aria-hidden="true"
                                                                        style={{ marginRight: 15, marginTop: 5 }}
                                                                        onClick={() =>
                                                                            descargarArchivo(
                                                                                item
                                                                            )
                                                                        }
                                                                    ></i>
                                                                </div>
                                                                <div className="col-1 my-auto" style={{ paddingRight: 0 }}>
                                                                    <i
                                                                        className="fa fa-trash text-primary fa-2x cursor"
                                                                        aria-hidden="true"
                                                                        onClick={() =>
                                                                            eliminarArchivo(
                                                                                item
                                                                                    .idDocto
                                                                                    ? item.idDocto.split(
                                                                                        ";"
                                                                                    )[VALOR_ZERO]
                                                                                    : null
                                                                            )
                                                                        }
                                                                    ></i>
                                                                </div>
                                                            </div>
                                                        ))}
                                                </div>
                                            </div>

                                            <div className="container mt-3 col-12">
                                                <button
                                                    type="button"
                                                    className="btn btn-principal mt-4"
                                                    disabled={cargarDatos}
                                                    style={{ marginLeft: 20, float: 'right' }}
                                                    onClick={() => guardarArchivo()}
                                                >
                                                    {cargarDatos ? (
                                                        <Fragment>
                                                            <i className="fa fa-spinner fa-pulse fa-fw"></i>
                                                            <span className="sr-only">Loading...</span>
                                                        </Fragment>
                                                    ) : (
                                                            <span>Guardar</span>
                                                        )}
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-principal mt-4"
                                                    onClick={() => cerrarDialogo()}
                                                    style={{ float: 'right' }}
                                                >
                                                    Cancelar
                                            </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal_foot"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>,
        document.querySelector(`#spinnerNaranjo`)
    );
};

const mapStateToProps = (state) => ({
    store: state,
});

export default withRouter(
    connect(mapStateToProps, {

    })(ModalNominaPreliminarDelegado)
);
