import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { BarraNavegacion } from "../../transversales/Navbar";
import CabeceraNegocioAsegurado from "./CabeceraNegocioAsegurado";
import {resetSesion} from "../../../../actions/actions";
import { cerrarSesion } from "../../../../services/auth.services";
import cookie from "react-cookies";
import { GA_CATEGORIA_FORMULARIO_ENROLAMIENTO, GA_LABEL_SOLICITUD_CERRADA, RUTA_INICIO_SESION } from "../../../../utils/Constantes";
import { crearEventoTraza } from "../../../../utils/analitica";

const icoLike = require("../../../assets/images/dislike.svg");

class SolicitudCerrada extends Component {

  componentDidMount() {
    crearEventoTraza(GA_CATEGORIA_FORMULARIO_ENROLAMIENTO, GA_LABEL_SOLICITUD_CERRADA);
  }

  onSubmit = async () => {
    cerrarSesion(this.props.store.auth.usuarioSesion.rol.codigo);
    this.props.resetSesion();
    cookie.remove("time", { path: "/" });
    sessionStorage.removeItem("_nSeVC");
    sessionStorage.clear();
    this.props.history.push(RUTA_INICIO_SESION);
    window.location.replace('');
  };

  render() {
    return (
      <React.Fragment>
        <BarraNavegacion />
        <CabeceraNegocioAsegurado />
        <div className="container animated fadeIn">
          <div className="row pt-4 mb-4">
            <div className="col-12">
              <div className="card shadow">
                <div className="card-body p-5">
                  <div className="d-block text-center my-3">
                    <img src={icoLike} alt="Solicitud completa" width="250px" />
                  </div>

                  <h3 className="text-muted text-center mt-5">
                    El proceso de enrolamiento ha finalizado, favor
                  </h3>
                  <h3 className="text-primary text-center mb-5">
                    <b>comuníquese con su delegado para más información</b>
                  </h3>
                  <button
                    id="buttonCerrarSesion"
                    className="btn btn-principal btn-sm d-block mx-auto"
                    type="button"
                    onClick={this.onSubmit}
                  >
                    Cerrar sesión
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state,
});

export default withRouter(connect(mapStateToProps, {resetSesion})(SolicitudCerrada));