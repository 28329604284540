import React from "react";
import { PAGINA_1, PAGINA_2, PAGINA_3 } from "../../../../../utils/Constantes";
import { FaTrash } from "react-icons/fa";

export const NavbarAsegurable = ({ tipoIncorporacionMantencion, perfilDelegado, pagina, actualizarPagina, textoMovimiento }) => {
  return (
    <div className="row">
      <div className="col-12 ">
        <ul className="nav nav-tabs nav-fill o-white row p-0 m-0">
          <p>{tipoIncorporacionMantencion}</p>
          <li
            id="ingresarAsegurables"
            className={`${pagina === PAGINA_1 ? "nav-item active col-6" : "nav-item col-6"}`}
            onClick={() => actualizarPagina(PAGINA_1)}
          >
            <span className="d-none d-sm-block">{textoMovimiento.individual}</span>
            <span className="d-block d-sm-none">
              {pagina !== PAGINA_1 && <FaTrash />} {pagina === PAGINA_1 && <span>{textoMovimiento.individual}</span>}
            </span>
          </li>
          <li className={`${pagina === PAGINA_2 ? "nav-item active col-6" : "nav-item col-6"}`} onClick={() => actualizarPagina(PAGINA_2)}>
            <span className="d-none d-sm-block">{textoMovimiento.masiva}</span>
            <span className="d-block d-sm-none">
              {pagina !== PAGINA_2 && <FaTrash />} {pagina === PAGINA_2 && <span>{textoMovimiento.masiva}</span>}
            </span>
          </li>
          {/* TODO: Logica RRHH */}
          {tipoIncorporacionMantencion && perfilDelegado && (
            <li
              id="softwareRRHH"
              style={{ display: "none" }}
              className={pagina === PAGINA_3 ? "nav-item active col-4" : "nav-item col-4"}
              onClick={() => actualizarPagina(PAGINA_3)}
            >
              <span className="d-none d-sm-block">Software RRHH</span>
              <span className="d-block d-sm-none">
                {pagina !== PAGINA_3 && <FaTrash />} {pagina === PAGINA_3 && <span>Software RRHH</span>}
              </span>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};
