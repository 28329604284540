import React from "react";

const MsgErrorAsegurable = ({ error, touched }) => {
  return (
    <>
      {error && touched && (
        <small className="text-danger animated fadeIn">{error}</small>
      )}
    </>
  );
};

export default MsgErrorAsegurable;
