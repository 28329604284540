import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CargaIndividualMantencionIncorporacion from "./CargaIndividualMantencionIncorporacion";
import CargaIndividualMantencionExclusion from "./CargaIndividualMantencionExclusion";
import CargaContratantePerfilCompleto from "../../../../transversales/CargarContratantePerfilCompleto";
import { COD_EJECUTIVO_MANTENCION, GA_CATEGORIA_ASEGURABLE, GA_LABEL_INDIVIDUAL_MANTENCION, RUTA_ABSOLUTA_ESTADO_ENROLAMIENTO, RUTA_ABSOLUTA_EXCLUSION, RUTA_ABSOLUTA_INCORPORACION, VALOR_SIETE } from "../../../../../../utils/Constantes";
import { crearEventoTraza } from "../../../../../../utils/analitica";


class CargaIndividualMantencion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      incorporacion: props.location.pathname.includes(RUTA_ABSOLUTA_INCORPORACION) ||
        props.location.pathname.includes(RUTA_ABSOLUTA_ESTADO_ENROLAMIENTO),
      exclusion: props.location.pathname.includes(RUTA_ABSOLUTA_EXCLUSION),
    }
  }

  componentDidMount() {
    crearEventoTraza(GA_CATEGORIA_ASEGURABLE, GA_LABEL_INDIVIDUAL_MANTENCION);
  }

  render() {
    return (
      <React.Fragment>
        {this.props.store.auth.usuarioSesion.rol.codigo === VALOR_SIETE || 
         this.props.store.auth.usuarioSesion.rol.codigo === COD_EJECUTIVO_MANTENCION ?
          <CargaContratantePerfilCompleto /> :
          <div>
            {this.state.incorporacion && <CargaIndividualMantencionIncorporacion />}
            {this.state.exclusion && <CargaIndividualMantencionExclusion />}
          </div>
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state,
});

export default withRouter(
  connect(mapStateToProps, {})(CargaIndividualMantencion)
);
