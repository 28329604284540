import React, { useState, useEffect } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { guardarDataWizard } from "../../../../services/asegurable.services";
import CabeceraNegocioAsegurado from "./CabeceraNegocioAsegurado";
import { BarraNavegacion } from "../../transversales/Navbar";
import { notificacion } from "../../transversales/Notificaciones";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import {
  VALOR_ZERO,
  EXITO_MIS_DATOS,
  ERROR_MIS_DATOS,
  ID_PRODUCTO_VIDA_1,
  ID_PRODUCTO_VIDA_10
} from "../../../../utils/Constantes";

const CoberturaAsegurable = () => {
  const history = useHistory();
  const { cotizaciones } = useSelector(
    (store) => store.reducer.data_asegurable ? store.reducer.data_asegurable : []
  );
  const { dataWizardAsegurado } = useSelector((store) => store.reducer);

  const [coberturaSalud, setCoberturaSalud] = useState([]);
  const [coberturaVida, setCoberturaVida] = useState([]);

  const [carga, serCarga] = useState(false);

  useEffect(() => {
    setCoberturas();
    // eslint-disable-next-line
  }, []);

  const setCoberturas = () => {
    cotizaciones.forEach((cotizacion) => {
      if (
        cotizacion.subProducto.idSubProducto === ID_PRODUCTO_VIDA_1 ||
        cotizacion.subProducto.idSubProducto === ID_PRODUCTO_VIDA_10
      ) {
        //VIDA
        let coberturas = [];
        cotizacion.coberturas.forEach((cobertura, indice) => {
          if (indice === VALOR_ZERO) {
            coberturas.push({
              cobertura: cobertura.glosaCobertura,
              dis: true,
              val: true,
              vida: true,
              idSubProdCondicionadoCobertura:
                cobertura.idSubProdCondicionadoCobertura,
              descripcionCobertura: cobertura.descripcionCobertura,
              idSubProducto: cotizacion.subProducto.idSubProducto,
              glosaSubProducto: cotizacion.subProducto.glosaSubProducto,
              coberturaAsegurable: cotizacion.coberturaAsegurable,
            });
          } else {
            coberturas.push({
              cobertura: cobertura.glosaCobertura,
              dis: false,
              val: false,
              vida: true,
              idSubProdCondicionadoCobertura:
                cobertura.idSubProdCondicionadoCobertura,
              descripcionCobertura: cobertura.descripcionCobertura,
            });
          }
        });
        setCoberturaVida({ coberturas });
      } else {
        //SALUD
        let coberturas = [];
        cotizacion.coberturas.forEach((cobertura, indice) => {
          if (indice === VALOR_ZERO) {
            coberturas.push({
              cobertura: cobertura.glosaCobertura,
              dis: true,
              val: true,
              vida: false,
              idSubProdCondicionadoCobertura:
                cobertura.idSubProdCondicionadoCobertura,
              idSubProducto: cotizacion.subProducto.idSubProducto,
              glosaSubProducto: cotizacion.subProducto.glosaSubProducto,
              coberturaAsegurable: cotizacion.coberturaAsegurable,
            });
          } else {
            coberturas.push({
              cobertura: cobertura.glosaCobertura,
              dis: false,
              val: false,
              vida: false,
              idSubProdCondicionadoCobertura:
                cobertura.idSubProdCondicionadoCobertura,
            });
          }
        });

        setCoberturaSalud({ coberturas });
      }
    });
    serCarga(true);
  };

  const cambioValorRadio = (coberturaSeleccionada) => {
    if (coberturaSeleccionada.vida) {
      setCoberturaVida((coberturas) => ({
        ...coberturas,
        coberturas: coberturas.coberturas.map((cobertura) => ({
          ...cobertura,
          val:
            cobertura.cobertura === coberturaSeleccionada.cobertura
              ? !coberturaSeleccionada.val
              : cobertura.val,
        })),
      }));
    } else {
      setCoberturaSalud((coberturas) => ({
        ...coberturas,
        coberturas: coberturas.coberturas.map((cobertura) => ({
          ...cobertura,
          val:
            cobertura.cobertura === coberturaSeleccionada.cobertura
              ? !coberturaSeleccionada.val
              : cobertura.val,
        })),
      }));
    }
  };

  const guardarCoberturas = async () => {
    let dataEnvio = dataWizardAsegurado;
    let subProducto = [];
    let condicionadoCobertura = null;

    //COBERTURA SALUD
    if (coberturaSalud.coberturas && coberturaSalud.coberturas.length > VALOR_ZERO) {
      condicionadoCobertura = [];
      coberturaSalud.coberturas.forEach((saludCobertura) => {
        if (saludCobertura.val) {
          condicionadoCobertura.push({
            cobertura: {
              idCobertura: saludCobertura.idSubProdCondicionadoCobertura,
              principal: saludCobertura.dis,
              glosaCobertura: saludCobertura.cobertura,
            },
          });
        }
      });

      subProducto.push({
        idSubProducto: coberturaSalud.coberturas[0].idSubProducto,
        glosaSubProducto: coberturaSalud.coberturas[0].glosaSubProducto,
        condicionadoCobertura: condicionadoCobertura,
      });
    }

    //COBERTURA VIDA
    if (coberturaVida.coberturas && coberturaVida.coberturas.length > VALOR_ZERO) {
      condicionadoCobertura = [];
      coberturaVida.coberturas.forEach((vidaCobertura) => {
        if (vidaCobertura.val) {
          condicionadoCobertura.push({
            cobertura: {
              idCobertura: vidaCobertura.idSubProdCondicionadoCobertura,
              principal: vidaCobertura.dis,
              glosaCobertura: vidaCobertura.cobertura,
            },
          });
        }
      });

      subProducto.push({
        idSubProducto: coberturaVida.coberturas[0].idSubProducto,
        glosaSubProducto: coberturaVida.coberturas[0].glosaSubProducto,
        condicionadoCobertura: condicionadoCobertura,
      });
    }

    dataEnvio.subProducto = subProducto;
    const res = await guardarDataWizard(dataEnvio);
    if (res) {
      notificacion("success", EXITO_MIS_DATOS);
      history.push("/asegurable");
    } else {
      notificacion("warning", ERROR_MIS_DATOS);
    }
  };

  const renderTooltip = (props) => (
    <Tooltip id="cobertura-asegurable-tooltip">{props}</Tooltip>
  );

  return (
    <React.Fragment>
      <BarraNavegacion />
      <CabeceraNegocioAsegurado />
      {carga ? (
        <div className="container">
          <div className="shadow o-white">
            <div className="card py-4">
              <div className="row">
                <div className="col-12">
                  <div className="col-12" style={{ marginTop: "30px" }}>
                    <h5 className="font-weight-bold text-primary">
                      Seleccionar Coberturas
                    </h5>
                  </div>
                  {coberturaSalud.coberturas &&
                    coberturaSalud.coberturas[0].coberturaAsegurable && (
                      <div>
                        <div className="col-12" style={{ marginTop: "30px" }}>
                          <h6
                            className="subTitulos-seleccionar-coberturas p-2 font-weight-bold"
                            style={{ marginBottom: "0px" }}
                          >
                            Coberturas de Salud
                          </h6>
                        </div>

                        <div className="col-12">
                          Seleccioné sus coberturas de salud
                        </div>

                        <div
                          className="col-6"
                          style={{ marginTop: "15px", marginLeft: "15px" }}
                        >
                          <div className="row">
                            {coberturaSalud.coberturas &&
                              coberturaSalud.coberturas.map((item, indice) => (
                                <div className="col-6" key={indice}>
                                  <label>
                                    <input
                                      type="radio"
                                      value={item.val}
                                      checked={item.val}
                                      onClick={() => cambioValorRadio(item)}
                                      readOnly
                                      disabled={
                                        indice === VALOR_ZERO ? true : false
                                      }
                                    />{" "}
                                    <span className="label-text">
                                      {item.cobertura}
                                    </span>
                                  </label>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    )}

                  {coberturaVida.coberturas &&
                    coberturaVida.coberturas[0].coberturaAsegurable && (
                      <div>
                        <div className="col-12" style={{ marginTop: "30px" }}>
                          <h6
                            className="subTitulos-seleccionar-coberturas p-2 font-weight-bold"
                            style={{ marginBottom: "0px" }}
                          >
                            Coberturas de Vida
                          </h6>
                        </div>
                        <div className="col-12">
                          Seleccioné sus coberturas de vida
                        </div>

                        <div
                          className="col-6"
                          style={{ marginTop: "15px", marginLeft: "15px" }}
                        >
                          <div className="row">
                            {coberturaVida.coberturas &&
                              coberturaVida.coberturas.map((item, indice) => (
                                <div className="col-6" key={indice}>
                                  <label>
                                    <input
                                      type="radio"
                                      value={item.val}
                                      checked={item.val}
                                      onClick={() => cambioValorRadio(item)}
                                      readOnly
                                      disabled={
                                        indice === VALOR_ZERO ? true : false
                                      }
                                    />{" "}
                                    <OverlayTrigger
                                      placement="right"
                                      delay={{ show: 250, hide: 400 }}
                                      overlay={renderTooltip(
                                        item.descripcionCobertura
                                      )}
                                    >
                                      <span className="label-text">
                                        {item.cobertura}
                                      </span>
                                    </OverlayTrigger>
                                  </label>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    )}

                  {/* Botonera */}
                  <div className="col-12">
                    <div className="button-zone text-right pt-3 pb-3 ml-auto">
                      <button
                        id="botonSiguiente"
                        className="btn btn-principal btn-lg"
                        type="submit"
                        onClick={() => guardarCoberturas()}
                      >
                        Siguiente
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
          <div>CARGANDO</div>
        )}
    </React.Fragment>
  );
};

export default withRouter(CoberturaAsegurable);
