import React, { Component } from "react";
import { connect } from "react-redux";
import Direccion from "./direccion/Direccion";
import MisDatos from "./misDatos/MisDatos";
import PagoReembolso from "./pagosReembolso/PagoReembolso";
import { withRouter } from "react-router-dom";
import { wizard } from "../../../../../actions/actions";
import "../../../../assets/css/tabs.css";
import { PAGINA_1, TIPO_TAB, VALOR_DOS, VALOR_TRES, VALOR_UNO } from "../../../../../utils/Constantes";

class DatosPersonales extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagina: 1
    };
  }

  render() {
    const { soloProductoVida } = this.props.store.reducer.informacionPoliza;
    return (
      <div>
        <div className="card wizard">
          <div className="card-body">
            <div className="row">
              <div className="col-md-12">
                <nav>
                  <ul className="nav nav-tabs nav-fill o-white pb-3">
                    <li
                      id="tabMisDatos"
                      className={
                        this.props.store.reducer.wizard.pagina === VALOR_UNO &&
                          this.props.store.reducer.wizard.tab === VALOR_UNO
                          ? "nav-item active"
                          : "nav-item"
                      }
                      style={{ width: '0' }}
                      onClick={() => this.props.wizard({ tab: VALOR_UNO, type: TIPO_TAB })}
                    >
                      <span className="d-none d-sm-block">Mis Datos</span>
                      <span title="Mis Datos" className="d-block d-sm-none">
                        {this.props.store.reducer.wizard.tab !== VALOR_UNO && (
                          <i className="fa fa-address-book" aria-hidden="true"></i>
                        )}
                        {this.props.store.reducer.wizard.tab === VALOR_UNO && (
                          <span>Mis Datos</span>
                        )}
                      </span>
                    </li>
                    <li
                      id="tabDireccion"
                      className={
                        this.props.store.reducer.wizard.pagina === VALOR_UNO &&
                          this.props.store.reducer.wizard.tab === VALOR_DOS
                          ? "nav-item active"
                          : "nav-item"
                      }
                      style={{ width: '0' }}
                      onClick={() => this.props.wizard({ tab: VALOR_DOS, type: TIPO_TAB })}
                    >
                      <span className="d-none d-sm-block">
                        Dirección y Contacto
                      </span>
                      <span
                        title="Dirección y Contacto"
                        className="d-block d-sm-none"
                      >
                        {this.props.store.reducer.wizard.tab !== VALOR_DOS && (
                          <i className="fa fa-phone" aria-hidden="true"></i>
                        )}
                        {this.props.store.reducer.wizard.tab === VALOR_DOS && (
                          <span>Dirección y Contacto</span>
                        )}
                      </span>
                    </li>

                    {this.props.store.reducer.estructuraFormulario.formaPago && <li
                      id="tabDatosBancarios"
                      className={
                        this.props.store.reducer.wizard.pagina === VALOR_UNO &&
                          this.props.store.reducer.wizard.tab === VALOR_TRES
                          ? "nav-item active"
                          : "nav-item"
                      }
                      style={{ width: '0' }}
                      onClick={() => this.props.wizard({ tab: VALOR_TRES, type: TIPO_TAB })}
                    >
                      <span className="d-none d-sm-block">Forma de Pago</span>
                      <span
                        title="Datos Bancarios"
                        className="d-block d-sm-none"
                      >
                        {this.props.store.reducer.wizard.tab !== VALOR_TRES && (
                          <i className="fa fa-money" aria-hidden="true"></i>
                        )}
                        {this.props.store.reducer.wizard.tab === VALOR_TRES && (
                          <span>Datos Bancarios</span>
                        )}
                      </span>
                    </li>}

                  </ul>
                </nav>
              </div>
            </div>
          </div>

          {this.props.store.reducer.wizard.pagina === PAGINA_1 &&
            this.props.store.reducer.wizard.tab === VALOR_UNO && <MisDatos />}
          {this.props.store.reducer.wizard.pagina === PAGINA_1 &&
            this.props.store.reducer.wizard.tab === VALOR_DOS && <Direccion />}
          {(this.props.store.reducer.wizard.pagina === PAGINA_1 &&
            this.props.store.reducer.wizard.tab === VALOR_TRES && !soloProductoVida) && <PagoReembolso />}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  store: state
});

export default withRouter(
  connect(
    mapStateToProps,
    { wizard }
  )(DatosPersonales)
);
