import React, { Component } from "react";
import { connect } from "react-redux";
import {
  HashRouter as RouterH,
  Route,
  Switch,
  withRouter,
} from "react-router-dom";

import { IniciarSesion } from "./autenticacion/Autenticacion";
import { Home } from "./Home";
import PrivateRoute from "../../utils/PrivateRoute";
import DatosPersonales from "./perfiles/asegurable/datosPersonales/DatosPersonales";
import IngresarAsegurables from "./perfiles/delegado/ingresarAsegurables/IngresarAsegurables";
import Permisos from "./perfiles/delegado/misProcesos/Permisos.js";
import Terminos from "./perfiles/asegurable/Terminos";
import Negocios from "./perfiles/jefatura-ejecutivo/negocios/Negocios";
import Cotizaciones from "./perfiles/jefatura-ejecutivo/negocios/cotizaciones/Cotizaciones";
import NegociosAsegurable from "./perfiles/asegurable/NegociosAsegurable";
import ConfiguracionNegocio from "./perfiles/jefatura-ejecutivo/ejecutivo/configuracionNegocio/ConfiguracionNegocio";
import { guardarUsuarioSesion } from "../../actions/actionsAuth";
import SolicitudCompleta from "./perfiles/asegurable/SolicitudCompleta";
import NominaAsegurable from "./perfiles/delegado/misProcesos/NominaAsegurables";
import SolicitudCerrada from "./perfiles/asegurable/SolicitudCerrada";
import Procesos from "./perfiles/delegado/misProcesos/Procesos";
import InfoEnrolamiento from "./perfiles/delegado/infoEnrolamiento/InfoEnrolamiento";
import CrearNegocio from "./perfiles/jefatura-ejecutivo/negocios/crear-negocio/CrearNegocio";
import MisSolicitudes from "./perfiles/delegado/misProcesos/MisSolicitudes";
import IniciarSesionExterno from "./autenticacion/SesionExterno";
import ConfiguracionProducto from "./perfiles/jefatura-ejecutivo/ejecutivo/configuracionProducto/ConfiguracionProducto";
import EstadoEnrolamiento from "./perfiles/jefatura-ejecutivo/ejecutivo/estadoEnrolamiento/estadoEnrolamiento";
import SolicitudProcesos from "./perfiles/asegurable/SolicitudProcesos";
import CoberturaAsegurable from "./perfiles/asegurable/CoberturaAsegurable";
import SolicitudCompletaDelegado from "./perfiles/delegado/SolicitudCompletaDelegado";
import CargaAsegurablesRRHH from "./perfiles/delegado/ingresarAsegurables/cargaSoftwareRRHH/CargaAsegurablesRRHH";
import GestionarEjecutivos from "./perfiles/jefatura-ejecutivo/jefatura/GestionarEjecutivos";
import SolicitudFinalizadaPorAsegurable from "./perfiles/asegurable/SolicitudFinalizadaPorAsegurable";
import Inicio from "./transversales/Inicio";
import CargarContratantePerfilCompleto from "./transversales/CargarContratantePerfilCompleto";
import EstadoEnrolamientoCorredor from "./perfiles/jefatura-ejecutivo/ejecutivo/estadoEnrolamiento/estadoEnrolamientoCorredor";
import { Contrasena } from "./contrasena/Contrasena";
import {
  RUTA_CORREDOR_EXCLUSION,
  RUTA_CORREDOR_INCORPORACION,
  RUTA_DELEGADO_EXCLUSION,
  RUTA_DELEGADO_INCORPORACION,
  RUTA_EJECUTIVO_EXCLUSION,
  RUTA_EJECUTIVO_INCORPORACION,
  RUTA_EJECUTIVO_VER_SOLICITUDES,
  RUTA_EJECUTIVO_PERMISOS,
  RUTA_EJECUTIVO_INICIO,
  RUTA_INICIO_MANTENCION,
  RUTA_INICIO,
  RUTA_DELEGADO_INICIO_MANTENCION,
  COD_DELEGADO,
  COD_EJECUTIVO_MANTENCION,
  COD_EJECUTIVO_COMPLETO,
  COD_EJECUTIVO,
  COD_CORREDOR,
  COD_ASEGURADO,
  COD_JEFATURA,
  RUTA_COTIZACION_CORREDOR_ESTADO_ENROLAMIENTO,
  RUTA_INICIO_SESION,
  RUTA_OLVIDO_CONTRASENA,
  RUTA_CAMBIAR_CONTRASENA_EXPIRADA,
  RUTA_PROCESO_ENROLAMIENTO_DOBLE,
  RUTA_CAMBIAR_CONTRASENA_TEMPORAL,
  RUTA_RECUPERAR_CONTRASENA,
  RUTA_INICIO_SESION_EXTERNO,
  RUTA_MANTENCION_VISTA,
} from "../../utils/Constantes";
import { crearTrazaPagina } from "../../utils/analitica.js";
import MantencionPage from "./transversales/MantencionPage.js";

const lstRutas = [
  {
    path: RUTA_MANTENCION_VISTA,
    component: MantencionPage,
    private: false,
  },
  {
    path: RUTA_INICIO_SESION,
    component: IniciarSesion,
    private: false,
  },
  {
    path: RUTA_OLVIDO_CONTRASENA,
    component: Contrasena,
    private: false,
  },
  {
    path: RUTA_RECUPERAR_CONTRASENA,
    component: Contrasena,
    private: false,
  },
  {
    path: RUTA_CAMBIAR_CONTRASENA_EXPIRADA,
    component: Contrasena,
    private: false,
  },
  {
    path: RUTA_CAMBIAR_CONTRASENA_TEMPORAL,
    component: Contrasena,
    private: true,
    authorized: [COD_ASEGURADO, COD_DELEGADO, COD_EJECUTIVO, COD_JEFATURA],
  },
  {
    path: "/asegurable/aceptar-terminos",
    component: Terminos,
    private: true,
    authorized: [COD_ASEGURADO],
  },
  {
    path: "/asegurable/solicitud",
    component: SolicitudCompleta,
    private: true,
    authorized: [COD_ASEGURADO],
  },
  {
    path: "/asegurable/solicitud-finalizada-por-asegurable",
    component: SolicitudFinalizadaPorAsegurable,
    private: true,
    authorized: [COD_ASEGURADO],
  },
  {
    path: "/asegurable/solicitud-cerrada",
    component: SolicitudCerrada,
    private: true,
    authorized: [COD_ASEGURADO],
  },
  {
    path: RUTA_PROCESO_ENROLAMIENTO_DOBLE,
    component: SolicitudProcesos,
    private: true,
    authorized: [COD_ASEGURADO],
  },
  {
    path: RUTA_INICIO_SESION_EXTERNO,
    component: IniciarSesionExterno,
    private: false,
  },
  {
    path: "/asegurable/cobertura-asegurable",
    component: CoberturaAsegurable,
    private: true,
    authorized: [COD_ASEGURADO],
  },
  {
    path: "/",
    component: Home,
    private: true,
    authorized: [
      0,
      COD_ASEGURADO,
      COD_DELEGADO,
      COD_EJECUTIVO,
      COD_JEFATURA,
      COD_CORREDOR,
      COD_EJECUTIVO_MANTENCION,
      COD_EJECUTIVO_COMPLETO,
    ],
    routes: [
      {
        path: "/asegurable",
        component: DatosPersonales,
        authorized: [COD_ASEGURADO],
      },
      {
        path: "/negocios-asegurables",
        component: NegociosAsegurable,
        authorized: [0],
      },
      {
        path: "/delegado/inicio",
        component: InfoEnrolamiento,
        authorized: [COD_DELEGADO],
      },
      {
        path: RUTA_DELEGADO_INICIO_MANTENCION,
        component: Inicio,
        authorized: [COD_DELEGADO],
      },
      {
        path: "/delegado/solicitud-completa",
        component: SolicitudCompletaDelegado,
        private: true,
        authorized: [COD_DELEGADO],
      },
      {
        path: "/delegado/mis-procesos",
        component: Procesos,
        authorized: [COD_DELEGADO],
      },
      {
        path: "/delegado/nomina-asegurable",
        component: NominaAsegurable,
        authorized: [COD_DELEGADO],
      },
      {
        path: "/delegado/ingresar-asegurables",
        component: IngresarAsegurables,
        authorized: [COD_DELEGADO],
      },
      {
        path: RUTA_DELEGADO_INCORPORACION,
        component: IngresarAsegurables,
        authorized: [COD_DELEGADO],
      },
      {
        path: RUTA_DELEGADO_EXCLUSION,
        component: IngresarAsegurables,
        authorized: [COD_DELEGADO],
      },
      {
        path: "/delegado/ver-solicitudes",
        component: MisSolicitudes,
        authorized: [COD_DELEGADO],
      },
      {
        path: "/delegado/carga-software-alianza",
        component: CargaAsegurablesRRHH,
        authorized: [COD_DELEGADO],
      },
      {
        path: RUTA_CORREDOR_INCORPORACION,
        component: IngresarAsegurables,
        authorized: [COD_CORREDOR],
      },
      {
        path: RUTA_CORREDOR_EXCLUSION,
        component: IngresarAsegurables,
        authorized: [COD_CORREDOR],
      },
      {
        path: "/corredor/ver-solicitudes",
        component: MisSolicitudes,
        authorized: [COD_CORREDOR],
      },
      {
        path: RUTA_EJECUTIVO_EXCLUSION,
        component: IngresarAsegurables,
        authorized: [COD_EJECUTIVO_MANTENCION, COD_EJECUTIVO_COMPLETO],
      },
      {
        path: RUTA_EJECUTIVO_INCORPORACION,
        component: IngresarAsegurables,
        authorized: [COD_EJECUTIVO_MANTENCION, COD_EJECUTIVO_COMPLETO],
      },
      {
        path: RUTA_EJECUTIVO_VER_SOLICITUDES,
        component: CargarContratantePerfilCompleto,
        authorized: [COD_EJECUTIVO_MANTENCION, COD_EJECUTIVO_COMPLETO],
      },
      {
        path: RUTA_EJECUTIVO_PERMISOS,
        component: Permisos,
        authorized: [COD_EJECUTIVO_MANTENCION, COD_EJECUTIVO_COMPLETO],
      },
      {
        path: RUTA_EJECUTIVO_INICIO,
        component: Inicio,
        authorized: [COD_EJECUTIVO_MANTENCION, COD_EJECUTIVO_COMPLETO],
      },
      {
        path: "/negocios",
        component: Negocios,
        authorized: [
          COD_EJECUTIVO,
          COD_JEFATURA,
          COD_EJECUTIVO_COMPLETO,
          COD_CORREDOR,
        ],
      },
      {
        path: "/cotizaciones/inicio",
        component: Cotizaciones,
        authorized: [
          COD_EJECUTIVO,
          COD_JEFATURA,
          COD_EJECUTIVO_COMPLETO,
          COD_CORREDOR,
        ],
      },
      {
        path: "/cotizaciones/configurar-producto",
        component: ConfiguracionProducto,
        authorized: [COD_EJECUTIVO, COD_EJECUTIVO_COMPLETO],
      },
      {
        path: "/cotizaciones/estado-enrolamiento",
        component: EstadoEnrolamiento,
        authorized: [COD_EJECUTIVO, COD_EJECUTIVO_COMPLETO],
      },
      {
        path: RUTA_COTIZACION_CORREDOR_ESTADO_ENROLAMIENTO,
        component: EstadoEnrolamientoCorredor,
        authorized: [COD_CORREDOR],
      },
      {
        path: "/crear-negocio",
        component: CrearNegocio,
        authorized: [COD_EJECUTIVO, COD_EJECUTIVO_COMPLETO],
      },
      {
        path: "/configuracionNegocio",
        component: ConfiguracionNegocio,
        authorized: [COD_EJECUTIVO, COD_EJECUTIVO_COMPLETO],
      },
      {
        path: "/gestionar-Ejecutivos",
        component: GestionarEjecutivos,
        authorized: [COD_EJECUTIVO, COD_JEFATURA, COD_EJECUTIVO_COMPLETO],
      },
      {
        path: RUTA_INICIO_MANTENCION,
        component: Inicio,
        authorized: [
          COD_DELEGADO,
          COD_CORREDOR,
          COD_EJECUTIVO_MANTENCION,
          COD_EJECUTIVO_COMPLETO,
        ],
      },
      {
        path: RUTA_INICIO,
        component: InfoEnrolamiento,
        authorized: [COD_DELEGADO],
      },
      {
        path: RUTA_INICIO,
        component: Negocios,
        authorized: [
          COD_EJECUTIVO,
          COD_EJECUTIVO_MANTENCION,
          COD_EJECUTIVO_COMPLETO,
        ],
      },
    ],
  },
];

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: false,
      mantencion:
        process.env.REACT_APP_STATUS_MAINTENANCE === true ? true : false,
    };
  }

  componentDidMount() {
    const objLocacion = this.props.location;
    crearTrazaPagina(objLocacion);
  }

  render() {
    return (
      <RouterH>
        <Switch>
          {process.env.REACT_APP_STATUS_MAINTENANCE === "true" && (
            <MantencionPage />
          )}

          {lstRutas.map((objRuta, intIndice) => {
            if (objRuta.private) {
              return (
                <PrivateRoute
                  key={intIndice}
                  path={objRuta.path}
                  authorized={objRuta.authorized}
                  render={(props) => {
                    return (
                      <objRuta.component
                        {...props}
                        menu={this.state.steps}
                        routes={objRuta.routes}
                      />
                    );
                  }}
                />
              );
            } else {
              return (
                <Route
                  key={intIndice}
                  path={objRuta.path}
                  render={(props) => (
                    <objRuta.component {...props} routes={objRuta.routes} />
                  )}
                />
              );
            }
          })}
        </Switch>
      </RouterH>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state,
});

export default withRouter(
  connect(mapStateToProps, { guardarUsuarioSesion })(Main)
);
