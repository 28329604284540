import React, { useEffect, useState } from "react";
import * as actions from "../../../../../../actions/actions";
import {
  DONE,
  ESPACIO_VACIO,
  EXP_CARACTERES_ESPECIALES_AMPERSAND,
  EXP_ESPACIOS,
  EXP_SALTO_LINEAS,
  STRING_VACIO,
  VALOR_ZERO,
} from "../../../../../../utils/Constantes";
import { MySelect } from "../../../../transversales/Select";
import { useDispatch, useSelector } from "react-redux";
import {
  obtenerGiros,
  buscarEmpresaPorRut,
} from "../../../../../../services/negocios.service";
import {
  guardar_negocio_ldap_consulta,
  guardar_wizard_negocio_ldap,
  cambiar_wizard_actual,
  guardar_sucursal_ldap_consulta,
} from "../../../../../../reducer/ldap";
import { VALOR_UNO, VALOR_NUEVE } from "../../../../../../utils/Constantes";
import {
  EMPRESA_EXISTENTE,
  EMPRESA_INEXISTENTE,
  RUT,
  MESSAGE_SEARCH_EMPRESA,
  STORAGE_EMPRESA,
  STORAGE_WIZARD,
  STORAGE_WIZARD_ACTUAL,
} from "../../../../../../utils/ConstantesVentaDirecta";
import {
  VALIDACION_RUT,
  CAMPO_REQUERIDO_RUT,
} from "../../../../../../utils/Constantes";
import {
  letrasYAmpersand,
  validarRutEspeciales,
} from "../../../../../../utils/Functions";
import CryptoJS from "react-native-crypto-js";

const { format, validate } = require("rut.js");

const CDatosEmpresa = ({ step, setLoading, setMessageLoading }) => {
  const { giros } = useSelector((store) => store.reducer);
  const { wizard, wizardActual, negocioConsulta } = useSelector(
    (store) => store.ldap
  );

  const dispatch = useDispatch();

  // State Datos Empresa
  const initialState = {
    existeEmpresa: false,
    idOrganizacion: 0,
    giro: { value: 0, label: "" },
    razonSocial: "",
    rut: "",
    sucursales: [],
  };
  const [empresa, actualizarEmpresa] = useState(
    negocioConsulta ? negocioConsulta : initialState
  );

  // State Habilitar Campos
  const [campos, habilitarCampos] = useState(false);

  // State Mensaje
  const [mensaje, actualizarMensaje] = useState(null);
  const [errorGiro, mostrarErrorGiro] = useState(false);
  const [errorRazonSocial, mostrarErrorRazonSocial] = useState(false);

  useEffect(() => {
    // Obtener Giros
    const obtenerGirosEmpresa = async () => {
      if (giros.length < VALOR_UNO) {
        let resGiros = await obtenerGiros();
        dispatch(actions.guardar_giros(resGiros));
      }
    };
    obtenerGirosEmpresa();
  }, [giros, empresa, dispatch]);

  // Extraer los valores
  const { rut, razonSocial, giro } = empresa;

  // Cuando el usuario envia el formulario
  const submitEmpresa = (e) => {
    e.preventDefault();
    // Validar
    if (
      rut.trim() === "" ||
      (null !== razonSocial && razonSocial === "") ||
      (Object.keys(giro).length === VALOR_ZERO && giro.constructor === Object)
    ) {
      if (!validate(rut)) {
        actualizarMensaje(CAMPO_REQUERIDO_RUT);
      } else if (razonSocial === "") {
        mostrarErrorRazonSocial(true);
      } else if (Object.entries(giro).length === VALOR_ZERO) {
        mostrarErrorGiro(true);
      }
      return;
    }

    // Limpiar sucursales anteriores en caso de cambiar negocio
    if (empresa !== negocioConsulta)
      dispatch(guardar_sucursal_ldap_consulta(null));

    // Guarda los datos de la empresa
    dispatch(guardar_negocio_ldap_consulta(empresa));
    guardarStorageNegocio(empresa);

    // Guarda el estado del wizard
    guardarEstadoWizard();

    // Elimina mensaje del rut
    actualizarMensaje(null);
  };

  const guardarStorageNegocio = (empresa) => {
    let empresaEcript = CryptoJS.AES.encrypt(
      JSON.stringify(empresa),
      process.env.REACT_APP_HAS_CRYPT
    ).toString();

    sessionStorage.setItem(STORAGE_EMPRESA, JSON.stringify(empresaEcript));
  };

  const guardarEstadoWizard = () => {
    step.estado = DONE;
    let wizard_ = Object.assign([], wizard);
    wizard_.forEach((e) => {
      if (e.paso === step.paso) e = step;
    });
    dispatch(guardar_wizard_negocio_ldap(wizard_));
    dispatch(cambiar_wizard_actual(wizardActual + 1));

    let wizardEcript = CryptoJS.AES.encrypt(
      JSON.stringify(wizard_),
      process.env.REACT_APP_HAS_CRYPT
    ).toString();

    sessionStorage.setItem(STORAGE_WIZARD, JSON.stringify(wizardEcript));
    sessionStorage.setItem(STORAGE_WIZARD_ACTUAL, wizardActual + 1);
  };
  //validacionGiro
  const validacionGiro = (event, t) => {
    actualizarEmpresa({
      ...empresa,
      giro: t,
    });
    mostrarErrorGiro(t ? false : true);
  };

  //validacionRazonSocial
  const validacionRazonSocial = (event) => {
    actualizarEmpresa({
      ...empresa,
      razonSocial: event.target.value
        .replace(EXP_CARACTERES_ESPECIALES_AMPERSAND, STRING_VACIO)
        .replace(EXP_ESPACIOS, ESPACIO_VACIO)
        .replace(EXP_SALTO_LINEAS, STRING_VACIO),
    });

    mostrarErrorRazonSocial(event.target.value.trim() ? false : true);
  };
  //ValidacionRut
  const ValidacionRut = async (event) => {
    const valor = event.target.value;

    if (undefined !== event.target && [event.target.name][0] === RUT) {
      let idOrganizacion = 0;
      let existeEmpresa = false;
      let giro = {};
      let razonSocial = "";
      let sucursales = [];
      let rut =
        valor.length > 1
          ? format(valor).replace(/\./g, "").trim()
          : valor.replace("-", "");

      if (rut.length >= VALOR_NUEVE) {
        if (validate(rut) && validarRutEspeciales(rut)) {
          setLoading(true);
          setMessageLoading(MESSAGE_SEARCH_EMPRESA);
          const respuestaEmpresa = await buscarEmpresaPorRut(rut);

          if (respuestaEmpresa) {
            habilitarCampos(false);
            existeEmpresa = true;
            idOrganizacion = respuestaEmpresa.idOrganizacion;
            giro = respuestaEmpresa.giro;
            razonSocial = respuestaEmpresa.razonSocial;
            sucursales = respuestaEmpresa.sucursales;
            actualizarMensaje(EMPRESA_EXISTENTE);
          } else {
            habilitarCampos(true);
            actualizarMensaje(EMPRESA_INEXISTENTE);
          }
          setLoading(false);
        } else {
          actualizarMensaje(VALIDACION_RUT);
        }
      } else {
        habilitarCampos(false);
        actualizarMensaje(null);
      }
      actualizarEmpresa({
        existeEmpresa,
        idOrganizacion,
        giro,
        razonSocial,
        rut,
        sucursales,
      });
    }
  };

  return (
    <form className="container animated fadeInRight" onSubmit={submitEmpresa}>
      <div className="row">
        {/* rut */}
        <div className="col-md-12">
          <div className="form-group">
            <label>
              RUT <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              id="rut"
              name="rut"
              className="form-control"
              placeholder="ej. 12345678-9"
              maxLength="12"
              onChange={ValidacionRut}
              value={rut.length > 0 ? format(rut) : rut}
            />
            {null !== mensaje && (
              <label
                className={
                  mensaje === EMPRESA_EXISTENTE
                    ? "text-primary font-weight-bold"
                    : mensaje === EMPRESA_INEXISTENTE
                    ? "font-weight-bold"
                    : "text-danger"
                }
              >
                {mensaje}
              </label>
            )}
          </div>
        </div>

        {/* razonSocial */}
        <div className="col-md-12">
          <div className="form-group">
            <label>
              Razon social <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              id="razonSocial"
              name="razonSocial"
              className="form-control"
              placeholder={!campos ? "" : "Ingrese razón social"}
              onChange={validacionRazonSocial}
              value={letrasYAmpersand(razonSocial)}
              disabled={!campos}
              minLength={1}
              maxLength={100}
            />
            {errorRazonSocial && campos && (
              <small className="text-danger animated fadeIn animated fadeIn">
                Falta completar la razón social
              </small>
            )}
          </div>
        </div>

        {/* giro */}
        <div className="col-md-12 ">
          <div className="form-group">
            <label>
              Giro <span className="text-danger">*</span>
            </label>
            <MySelect
              id="giro"
              name="giro"
              tipoData="giro"
              value={giro || ""}
              onChange={validacionGiro}
              data={giros}
              titulo={!campos ? "" : "giro"}
              disabledText={!campos}
              onBlur={() => {}}
            />
            {errorGiro && campos && (
              <small className="text-danger animated fadeIn animated fadeIn">
                Falta completar el giro de la empresa
              </small>
            )}
          </div>
        </div>
      </div>

      {/* Botonera */}
      <div className="container button-zone text-right pt-3 pb-3 animated fadeIn">
        <button id="botonSiguiente" className="btn btn-principal" type="submit">
          Siguiente
        </button>
      </div>
    </form>
  );
};

export default CDatosEmpresa;
