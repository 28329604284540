import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { cambiar_wizard_actual } from "../../../../../../reducer/ldap";
import { VALOR_UNO, DONE } from "../../../../../../utils/Constantes";

const CabeceraWizardCrearNegocio = () => {
  const { wizardActual } = useSelector((store) => store.ldap);
  const { wizard } = useSelector((store) => store.ldap);
  const dispatch = useDispatch();

  const cambiarWizard = (step) => {

    let stepAnterior;
    if (step.paso === VALOR_UNO) {
      dispatch(cambiar_wizard_actual(step.paso));
    } else {
      stepAnterior = wizard.find((e) => step.paso - VALOR_UNO === e.paso);
      if (stepAnterior) {
        if (stepAnterior.estado === DONE) {
          dispatch(cambiar_wizard_actual(step.paso));
        }
      }
    }

  };

  return (
    <React.Fragment>
      {/* WIZARD */}
      <div className="row m-5 justify-content-center">
        {wizard.map((e, index) => (
          <div
            key={index}
            className={`icono-dot cursor ${
              e.paso === wizardActual && "clicked"
            } mr-5 text-center`}
            onClick={() => cambiarWizard(e)}
          >
            <div className="dot">
              <img
                className="d-block mx-auto mt-2"
                alt="img-icon"
                width="45px"
                src={e.icon}
              />
            </div>
            <div className="text">{e.glosa}</div>
          </div>
        ))}
      </div>
    </React.Fragment>
  );
};

export default CabeceraWizardCrearNegocio;
