import { useDispatch, useSelector } from "react-redux";
import { ejecutarCargando } from "../actions/actions";

export const useCargando = () => {

  const objGuardarEstado = useDispatch();
  const blnCargando      = useSelector(store => store.reducer.cargando);

  const mostrarOcultarCargando = ( blnCargando ) => {
    
    objGuardarEstado(ejecutarCargando(blnCargando));
  }

  return {
    cargando: blnCargando,
    mostrarOcultarCargando
  }
}