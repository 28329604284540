import React from "react";
import { useSelector } from "react-redux";
import Authorized from "../../utils/Authorized";
import Delegado from "./perfiles/delegado/Delegado";
import Jefatura from "./perfiles/jefatura-ejecutivo/jefatura/Jefatura";
import Ejecutivo from "./perfiles/jefatura-ejecutivo/ejecutivo/Ejecutivo";
import EjecutivoMantencion from "./perfiles/jefatura-ejecutivo/ejecutivo/EjecutivoMantencion";
import Corredor from "./perfiles/corredor/Corredor";
import Negocios from "./perfiles/asegurable/NegociosAsegurable";
import { Link } from "react-router-dom";
import EjecutivoCompleto from "./perfiles/jefatura-ejecutivo/ejecutivo/EjecutivoCompleto";
import { 
  COD_ASEGURADO, 
  COD_DELEGADO,
  COD_CORREDOR,
  RUTA_INICIO_SESION,
  COD_EJECUTIVO,
  COD_JEFATURA,
  COD_EJECUTIVO_MANTENCION,
  COD_EJECUTIVO_COMPLETO
} from "../../utils/Constantes";
import { BarraNavegacion } from "./transversales/Navbar";

export const Home = (objProps) => {
  
  const objSesion  = useSelector(store => store.auth.usuarioSesion);
  const { routes } = objProps;
  return (
    (objSesion) ?
    (
      <div id="home">
        <BarraNavegacion />
        <React.Fragment>
          <Authorized roles={[COD_ASEGURADO]}>
            <Negocios />
          </Authorized>
          <Authorized roles={[COD_DELEGADO]}>
            <Delegado routes={routes.filter((objRuta) => objRuta.authorized.includes(objSesion.rol.codigo))} />
          </Authorized>
          <Authorized roles={[COD_EJECUTIVO]}>
            <Ejecutivo routes={routes.filter((objRuta) => objRuta.authorized.includes(objSesion.rol.codigo) )} />
          </Authorized>
          <Authorized roles={[COD_JEFATURA]}>
            <Jefatura routes={routes.filter((objRuta) => objRuta.authorized.includes(objSesion.rol.codigo) )} />
          </Authorized>
          <Authorized roles={[COD_CORREDOR]}>
            <Corredor routes={routes.filter((objRuta) => objRuta.authorized.includes(objSesion.rol.codigo) )} />
          </Authorized>
          <Authorized roles={[COD_EJECUTIVO_MANTENCION]}>
            <EjecutivoMantencion routes={routes.filter((objRuta) => objRuta.authorized.includes(objSesion.rol.codigo) )} />
          </Authorized>
          <Authorized roles={[COD_EJECUTIVO_COMPLETO]}>
            <EjecutivoCompleto routes={routes.filter((objRuta) => objRuta.authorized.includes(objSesion.rol.codigo) )} />
          </Authorized>
        </React.Fragment>
      </div>
    ): 
    (<Link to={RUTA_INICIO_SESION} />)
  )
}
