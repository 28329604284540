import React from "react";
import { ModalCantidadAsegurables } from "./ModalCantidadAsegurables";
import { useState } from "react";
import {
  EXITO_AGREGAR_ASEGURADO,
  ERROR_AGREGAR_ASEGURADO,
  COD_EJECUTIVO,
  COD_EJECUTIVO_COMPLETO,
  COD_CORREDOR,
  ESTADO_COTIZACION_EN_PROCESO,
  PERFIL_DELEGADO,
  TIPO_INCORPORACION_NUEVO_NEGOCIO,
  VALOR_ZERO,
  PERFIL_LDAP,
  COD_STATUS_EXITO,
  ACCESO_ENVIADO_A,
  ERROR_ENVIO_AUTOMATICO,
  TELEFONO_MOVIL,
  CORREO,
  NOTIFICACION_ADVERTENCIA,
  ERROR_GENERICO,
  SIGNO_GUION,
  POSICION_INICIAL,
  SEGUNDA_POSICION,
  NOTIFICACION_EXITOSA,
  NOTIFICACION_ERROR,
  GA_CATEGORIA_ASEGURABLE,
  GA_LABEL_INDIVIDUAL_DIRECTA,
} from "../../../../../../utils/Constantes";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { formatTel, soloLetras } from "../../../../../../utils/Functions";
import { notificacion } from "../../../../transversales/Notificaciones";
import { useEffect } from "react";
import { guardar_cotizacion, guardar_asegurados_delegado, actualizar_asegurables } from "../../../../../../actions/actions";
import { enviarMailAsegurable, crearAsegurable, obtenerGrupoAsegurables } from "../../../../../../services/asegurable.services";

import {
  validacionEmail,
  validacionMaterno,
  validacionNombre,
  validacionPaterno,
  validacionRut,
  validacionSubProducto,
  validacionTelefono,
} from "./Form/YupValidation";

import FormAsegurable from "./Form/FormAsegurable";
import { obtenerCotizacionesNegocio } from "../../../../../../services/cotizacion.services";
import { crearEventoTraza } from "../../../../../../utils/analitica";
const { format, clean } = require("rut.js");

export const CargaIndividualVentaDirecta = ({ usuarioSesion, intCanalVenta }) => {
  const reducer = useSelector((store) => store.reducer);
  const auth = useSelector((store)=>store.auth);
  
  const [blnModalAsegurables, actualizarBlnModalAsegurables] = useState(false);
  const intSesionEjecutivo = usuarioSesion.rol.codigo === COD_EJECUTIVO || usuarioSesion.rol.codigo === COD_EJECUTIVO_COMPLETO;
  const blnSesionCorredor = usuarioSesion.rol.codigo === COD_CORREDOR;
  const [objCotizacion, actualizarObjCotizacion] = useState({});
  const [blnCargando, actualizarBlnCargando] = useState(false);

  const [listTipoProductos, actualizarListTipoProductos] = useState([]);

  //carga de cotizaciones
  useEffect(() => {
    //traza ga
    crearEventoTraza(GA_CATEGORIA_ASEGURABLE, GA_LABEL_INDIVIDUAL_DIRECTA);
    //definir de donde se obtienen las cotizaciones
    let cotizaciones = [];

    if (intSesionEjecutivo || blnSesionCorredor) {
      // perfil ejecutivo venta directa
      cotizaciones = reducer.cotizaciones;
    } else {
      // perfil delegado digital o directa
      cotizaciones = reducer.negocio.cotizaciones;
    }

    const cotizacionGestionada = cotizaciones.find(
      (item) => item.estadoCotizacion !== null && item.estadoCotizacion.idEstadoCotizacion === ESTADO_COTIZACION_EN_PROCESO
    );
    actualizarObjCotizacion(cotizacionGestionada);
    guardar_cotizacion(cotizacionGestionada);

    return () => {
      cotizaciones = [];
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const enviarDatosAsegurado = async (objValores) => {
    actualizarBlnCargando(true);

    let { negocio, delegado } = reducer;
    let lstGrupos = [];

    objValores.subProducto.forEach((subProd) => {
      lstGrupos.push({ idGrupo: subProd.idGrupo });
    });

    if (lstGrupos.length === VALOR_ZERO) {
      return notificacion(NOTIFICACION_ADVERTENCIA, ERROR_GENERICO);
    }

    const strRutLimpio = format(clean(objValores.rut)).split(SIGNO_GUION);

    let objDataEnvio = {
      nombre: objValores.nombres,
      apellidoMaterno: objValores.apellidoMaterno,
      apellidoPaterno: objValores.apellidoPaterno,
      correoElectronico: objValores.email,
      telefonoMovil: objValores.telefonoMovil,
      rut: Number(clean(strRutLimpio[POSICION_INICIAL])),
      digitoVerificador: strRutLimpio[SEGUNDA_POSICION],
      grupos: lstGrupos,
      idUsuarioCrea: usuarioSesion.rol.codigo,
      solicitud: { idSolicitud: VALOR_ZERO },
      tipoIncorporacion: {
        idTipoIncorporacion: TIPO_INCORPORACION_NUEVO_NEGOCIO,
      },
    };

    const strPerfil = intSesionEjecutivo ? PERFIL_LDAP : PERFIL_DELEGADO;

    let objRespCrearAsegurable = await crearAsegurable(objDataEnvio, strPerfil);

    if (objRespCrearAsegurable && objRespCrearAsegurable.status.codigo === COD_STATUS_EXITO) {
      notificacion(NOTIFICACION_EXITOSA, EXITO_AGREGAR_ASEGURADO);

      let lstAseguradosDelegado = Object.assign([], delegado.aseguradosDelegado);
      lstAseguradosDelegado.push(objDataEnvio);

      //Invocando actions relacionado con delegado
      guardar_asegurados_delegado(lstAseguradosDelegado);
      actualizar_asegurables(lstAseguradosDelegado);

      //TODO: no entrega fecha de enrolamiento
      if (negocio.fechaInicioEnrolamiento) {
        const intIdGrupo = lstAseguradosDelegado.find(({ rut }) => rut === Number(clean(strRutLimpio[POSICION_INICIAL]))).grupos[
          POSICION_INICIAL
        ].idGrupo;

        const objDatosEnvio = {
          idGrupo: intIdGrupo,
          perfil: strPerfil,
        };

        const lstGrupoAsegurables = await obtenerGrupoAsegurables(objDatosEnvio);

        if (lstGrupoAsegurables) {
          const intIdAsegurable = lstGrupoAsegurables.find(({ rut }) => rut === Number(clean(strRutLimpio[POSICION_INICIAL]))).idAsegurable;
          const objDatosCorreo = {
            asegurable: {
              idAsegurable: intIdAsegurable,
              rut: Number(clean(strRutLimpio[POSICION_INICIAL])),
              digitoVerificador: strRutLimpio[SEGUNDA_POSICION],
            },
          };

          const objEnvioCorreo = await enviarMailAsegurable(objDatosCorreo, strPerfil);
          if (objEnvioCorreo) {
            notificacion(NOTIFICACION_EXITOSA, `${ACCESO_ENVIADO_A} ${objValores.nombres} ${objValores.apellidoPaterno}`);
          } else {
            notificacion(NOTIFICACION_ADVERTENCIA, ERROR_ENVIO_AUTOMATICO);
          }
          actualizarBlnCargando(false);
          return objDatosCorreo;
        } else {
          notificacion(NOTIFICACION_ADVERTENCIA, ERROR_ENVIO_AUTOMATICO);
        }
      }
    } else {
      notificacion(NOTIFICACION_ERROR, ERROR_AGREGAR_ASEGURADO);
    }
    actualizarBlnCargando(false);
  };

  useEffect(() => {
    /**
     * carga cotizaciones del negocio
     * */
    const obtenerDetalleNegocio = async () => {
      if (intSesionEjecutivo && objCotizacion !== undefined) {
        let productos = [];

        objCotizacion.forEach((cot) => {
          if (cot.grupos[0])
            productos.push({
              label: `${cot.subProducto.glosaSubProducto} - ${cot.codigoCotizacion}`,
              value: cot.subProducto.idSubProducto,
              idGrupo: cot.grupos[0].idGrupo,
            });
          else
            notificacion("warning", `Producto folio "${cot.codigoCotizacion}" no se ha podido configurar, favor comunicarse con encargado`);
        });

        actualizarListTipoProductos(productos);
      } else {
        const dataEnvio = {
          idNegocio: reducer.negocio.idNegocio,
          perfil: PERFIL_DELEGADO,
        };

        const resNegocio = await obtenerCotizacionesNegocio(dataEnvio);
        let productos = [];

        resNegocio.cotizaciones.forEach((cot) => {
          if (cot.grupos[0])
            productos.push({
              label: `${cot.subProducto.glosaSubProducto} - ${cot.codigoCotizacion}`,
              value: cot.subProducto.idSubProducto,
              idGrupo: cot.grupos[0].idGrupo,
            });
          else
            notificacion("warning", `Producto folio "${cot.codigoCotizacion}" no se ha podido configurar, favor comunicarse con encargado`);
        });

        actualizarObjCotizacion(resNegocio.cotizaciones);
        actualizarListTipoProductos(productos);
      }
    };

    if (intCanalVenta === 2) obtenerDetalleNegocio();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <ModalCantidadAsegurables
        modalCantidadAsegurables={blnModalAsegurables}
        mostrarModalCantidadAsegurables={() => actualizarBlnModalAsegurables(!blnModalAsegurables)}
        maximoAsegurables={blnModalAsegurables}
      />
      <Formik
        validateOnMount={true}
        initialValues={{
          rut: "",
          nombres: "",
          apellidoPaterno: "",
          apellidoMaterno: "",
          email: "",
          telefonoMovil: "",
          subProducto: [],
        }}
        validationSchema={Yup.object().shape(
          {
            rut: validacionRut(reducer.delegado),
            nombres: validacionNombre(),
            apellidoPaterno: validacionPaterno(),
            apellidoMaterno: validacionMaterno(),
            email: validacionEmail(),
            telefonoMovil: validacionTelefono(),
            subProducto: validacionSubProducto(),
          },
          [TELEFONO_MOVIL, CORREO]
        )}
        onSubmit={async (values, { resetForm, errors }) => {
          values.rut = format(values.rut).replace(/[.]/g, "");
          values.nombres = soloLetras(values.nombres).trim();
          values.apellidoMaterno = soloLetras(values.apellidoMaterno).trim();
          values.apellidoPaterno = soloLetras(values.apellidoPaterno).trim();
          values.telefonoMovil = formatTel(values.telefonoMovil);

          enviarDatosAsegurado(values);
          resetForm({});
        }}
      >
        {({ values, errors, touched, setFieldValue, setFieldTouched }) => (
          <div className="container">
            {objCotizacion !== undefined && auth.tipoIncorporacion && reducer.negocio && (
              <FormAsegurable
                formik={{
                  values,
                  errors,
                  touched,
                  setFieldTouched,
                  setFieldValue,
                  usuarioSesion,
                }}
                props={{
                  intCanalVenta,
                  intTipoIncorporacion: reducer.tipoIncorporacion,
                  listTipoProductos,
                  objCotizacion,
                  blnCargando,
                }}
              />
            )}
          </div>
        )}
      </Formik>
    </React.Fragment>
  );
};
