import React, { Component } from 'react';
import ModalEditarAsegurable from '../../ModalEditarAsegurable';

class ModalAdvertenciaGestionadas  extends Component {
    render() {
        return (
            <div>
                <ModalEditarAsegurable
                    idSubmit="submitAdvertenciaGestionadas"
                    type="small"
                    show={this.props.modalAdvertenciaGestionadas}
                    title="Cambio de Estado"
                    action="Cerrar" 
                    onSubmit={() => {
                        this.props.mostrarModalAdvertenciaGestionadas();            
                    }}
                    onCancel={() => {
                        this.props.mostrarModalAdvertenciaGestionadas();            
                    }}
                    onClose={() => {
                        this.props.mostrarModalAdvertenciaGestionadas();
                    }}
                    showCancelButton={false}
                    showCloseButton={true}
                    showLeftColumn={false}
                    showSubmitButton={true}
                >
                    <div className="row">
                        <h5 className="col-12 col-md-8 offset-md-2">
                            Sólo puede haber una cotizacion gestionada. Si desea cambiar el estado de esta cotización, primero debe cambiar el estado de la actual cotización gestionada a generada.
                        </h5> 
                    </div>          
                </ModalEditarAsegurable>
            </div>
        );
    }
}

export default ModalAdvertenciaGestionadas ;