import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import ImgNuevoNegocio from "../../../../assets/images/nuevo-negocio.svg";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Spinner from "../../../transversales/Spinner";
import {
    obtenerEjecutivosPorRutJefatura,
    obtenerTodasLasJefaturas,
} from "../../../../../services/negocios.service";
import {
    obtenerJefaturaComercial,
} from "../../../../../services/transversales.service";

import ModalMoverEjecutivo from "./ModalMoverEjecutivo";
import {
    CAMPO_REQUERIDO,
    VALIDACION_REGION,
    VALOR_ZERO,
    VALOR_TRECE,
    VALIDACION_EJECUTIVO,
    VALIDACION_JEFATURA,
    GA_CATEGORIA_EJECUTIVOS,
    GA_LABEL_MOVER_EJECUTIVO,
} from "../../../../../utils/Constantes";
import { crearEventoTraza } from "../../../../../utils/analitica";


const MoverEjecutivo = (props) => {

    useEffect(() => {
        crearEventoTraza(GA_CATEGORIA_EJECUTIVOS, GA_LABEL_MOVER_EJECUTIVO);
    },[]);

    const { usuarioSesion } = useSelector((store) => store.auth);
    const [listaEjecutivos, setListaEjecutivos] = useState([]);
    const [cargando, setcargando] = useState(true);
    const [listaJefatura, setListaJefatura] = useState([]);
    const [listaJefaturaComercial, setListaJefaturaComercial] = useState([]);
    const [listaRegion, setListaRegion] = useState([]);
    const [desabilitarRegion, setDesabilitarRegion] = useState(true);
    const [modalMoverEjecutivo, setModalMoverEjecutivo] = useState(false);
    const [ejecutivo, setEjecutivo] = useState([{}]);
    const [jefatura, setJefatura] = useState([{}]);
    const [region, setRegion] = useState(0);
    const [idEjecutivoEquipo, setIdEjecutivoEquipo] = useState(0);

    useEffect(() => {
        async function cargarEjecutivos() {
            setListaEjecutivos(await obtenerEjecutivosPorRutJefatura(usuarioSesion.rut))
            const jefaturas = await obtenerTodasLasJefaturas()
            const jefaturaComercial = await obtenerJefaturaComercial()

            let rutSinDigitoVerificador = usuarioSesion.rut.split("-");

            let jefaturasFinal = []
            jefaturas.forEach((jefaturaTemporal) => {
                jefaturaComercial.forEach((jefaturaComercialTemporal) => {
                    if (jefaturaTemporal.rut !== parseInt(rutSinDigitoVerificador[0]) && jefaturaTemporal.idPersona === jefaturaComercialTemporal.idPersona) {
                        jefaturasFinal.push(jefaturaTemporal)
                    }
                })
            })

            setListaJefatura(jefaturasFinal)
            setListaJefaturaComercial(jefaturaComercial)
        }

        cargarEjecutivos();
        setListaJefatura();
        setcargando(false)
        // eslint-disable-next-line
    }, []);

    const handleSubmit = async (dato) => {
        setEjecutivo(listaEjecutivos.map((ejecutivo) => ejecutivo)
            .filter((item) => item.idPersona === parseInt(dato.ejecutivo)));

        setJefatura(listaJefatura.map((ejecutivo) => ejecutivo)
            .filter((item) => item.idPersona === parseInt(dato.jefatura)));

        setIdEjecutivoEquipo(listaJefaturaComercial.map((ejecutivo) => ejecutivo)
            .filter((item) => item.idPersona === parseInt(dato.jefatura))[0].idEjecutivoEquipo)
        setRegion(dato.region)
        setModalMoverEjecutivo(true);
    };

    const listarRegiones = async (dato) => {
        setListaRegion(listaJefaturaComercial
            .map((listaJefatura) => listaJefatura)
            .filter((item) => item.idPersona === parseInt(dato)));
        if (dato === "0") {
            setDesabilitarRegion(true)
        } else {
            setDesabilitarRegion(false)
        }
    }

    return (
        <React.Fragment>
            {
                cargando ? <Spinner claseColor="transparente"
                    idSpinner="spinnerTransparente"
                    posicionLogo="posicionLogo align-self-center w-100" />
                    :
                    <div className="card shadow mb-3">
                        <div className="card-body">
                            <div className="row">
                                <div className="mx-auto my-5 animated fadeIn">
                                    <h4 className="text-center">
                                        <img
                                            src={ImgNuevoNegocio}
                                            width="140"
                                            height="200"
                                            className="img-fluid d-block mx-auto mb-2"
                                            alt=""
                                        ></img>
                                        <strong style={{ color: "#e89b03" }}>Mover ejecutivo</strong>
                                    </h4>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12" style={{ paddingLeft: '15%', paddingRight: '15%' }}>
                                    <Formik
                                        enableReinitialize={true}
                                        initialValues={{
                                            ejecutivo: VALOR_ZERO,
                                            jefatura: VALOR_ZERO,
                                            region: VALOR_ZERO,
                                        }}
                                        validationSchema={Yup.object().shape({
                                            ejecutivo: Yup.number()
                                                .required(CAMPO_REQUERIDO)
                                                .test("validaEjecutivo", VALIDACION_EJECUTIVO, (value) => {
                                                    return value === VALOR_ZERO ? false : true;
                                                }),
                                            jefatura: Yup.number()
                                                .required(CAMPO_REQUERIDO)
                                                .test("validaJefatura", VALIDACION_JEFATURA, (value) => {
                                                    return value === VALOR_ZERO ? false : true;
                                                }),
                                            region: Yup.number()
                                                .required(CAMPO_REQUERIDO)
                                                .test("validaRegion", VALIDACION_REGION, (value) => {
                                                    return value === VALOR_ZERO ? false : true;
                                                }),
                                        })}
                                        onSubmit={async (
                                            values,
                                            { setSubmitting, initialValues }
                                        ) => {
                                            handleSubmit(values);
                                        }}
                                    >
                                        {({
                                            values,
                                            errors,
                                            touched,
                                            setFieldValue,
                                            setFieldTouched,
                                            handleChange,
                                            handleSubmit,
                                            handleBlur,
                                            field
                                        }) => {
                                            return (
                                                <Form
                                                    id="formularioAgregarEjecutivo"
                                                    name="agregar-form"
                                                    onSubmit={handleSubmit}
                                                    onKeyDown={(e) => {
                                                        if ((e.charCode || e.keyCode) === VALOR_TRECE) {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                >
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <strong style={{ fontSize: 15 }}>
                                                                Ejecutivos de su equipo <span className="text-danger">*</span>
                                                            </strong>
                                                            <select
                                                                id="ejecutivo"
                                                                name="ejecutivo"
                                                                className="form-control"
                                                                style={{ display: "block" }}
                                                                value={values.ejecutivo}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                            >
                                                                <option value="0"> Seleccione ejecutivo</option>
                                                                {listaEjecutivos && listaEjecutivos.map((item, i) => (
                                                                    <option
                                                                        key={`opcion_parentesco_${i}`}
                                                                        value={item.idPersona}
                                                                    >
                                                                        {item.nombre + " " + item.apellidoPaterno + " " + item.apellidoMaterno}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                            {errors.ejecutivo && touched.ejecutivo && (
                                                                <small className="text-danger animated fadeIn animated fadeIn">
                                                                    {errors.ejecutivo}
                                                                </small>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="row" style={{ marginTop: 15 }}>
                                                        <div className="col-md-12">
                                                            <strong style={{ fontSize: 15 }}>
                                                                Jefatura <span className="text-danger">*</span>
                                                            </strong>
                                                            <select
                                                                id="jefatura"
                                                                name="jefatura"
                                                                className="form-control"
                                                                style={{ display: "block" }}
                                                                value={values.jefatura}
                                                                onChange={(e) => {
                                                                    handleChange("jefatura")(e);
                                                                    listarRegiones(e.currentTarget.value);
                                                                }}
                                                                onBlur={handleBlur}
                                                            >
                                                                <option value="0"> Seleccione jefatura</option>
                                                                {listaJefatura && listaJefatura.map((item, i) => (
                                                                    <option
                                                                        key={`opcion_parentesco_${i}`}
                                                                        value={item.idPersona}
                                                                        onChange={(e) => {
                                                                            listarRegiones(e.currentTarget.value);
                                                                        }}
                                                                    >
                                                                        {item.nombre + " " + item.apellidoPaterno + " " + item.apellidoMaterno}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                            {errors.jefatura && touched.jefatura && (
                                                                <small className="text-danger animated fadeIn animated fadeIn">
                                                                    {errors.jefatura}
                                                                </small>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="row" style={{ marginTop: 15 }}>
                                                        <div className="col-md-12">
                                                            <strong style={{ fontSize: 15 }}>
                                                                Región <span className="text-danger">*</span>
                                                            </strong>
                                                            <select
                                                                id="region"
                                                                name="region"
                                                                className="form-control"
                                                                style={{ display: "block" }}
                                                                value={values.region}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                disabled={desabilitarRegion}
                                                            >
                                                                <option value="0"> Seleccione región</option>
                                                                {listaRegion && listaRegion[0] && listaRegion[0].jefaturaRegion &&
                                                                    listaRegion[0].jefaturaRegion.map((item, i) => (
                                                                        <option
                                                                            key={`opcion_parentesco_${i}`}
                                                                            value={item.idRegion}
                                                                        >
                                                                            {item.glosaRegion}
                                                                        </option>
                                                                    ))}
                                                            </select>
                                                            {errors.region && touched.region && (
                                                                <small className="text-danger animated fadeIn animated fadeIn">
                                                                    {errors.region}
                                                                </small>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <button
                                                        type="submit"
                                                        className="btn btn-principal mt-4 d-block ml-auto"
                                                    >
                                                        Aceptar
                                            </button>


                                                </Form>
                                            );
                                        }}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                        <ModalMoverEjecutivo show={modalMoverEjecutivo} titulo={"MOVER EJECUTIVO"} ejecutivo={ejecutivo}
                            jefatura={jefatura} region={region} idEjecutivoEquipo={idEjecutivoEquipo} setModalMoverEjecutivo={setModalMoverEjecutivo} />
                    </div>

            }
        </React.Fragment >

    );
};

export default withRouter(MoverEjecutivo);
