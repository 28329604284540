import axios from "axios";
import { ALMACENAMIENTO_SESION, ENDPOINT_LISTAR_CON_PRIMA_FIJA, REQUEST_POST_TIMEOUT, STORAGE_ITEM_SESION, VALOR_ZERO } from "../utils/Constantes";
import { buscarEnNavegador } from "../utils/almacenamientoNavegador";

class ServicesFactory {
  
  headers = undefined;

  configurarCabecerasPeticion = (strUrl, strAlamcenamiento, strTokenPuertaEnlace) => {

    this.headers  = {};

    let objSesion = buscarEnNavegador(STORAGE_ITEM_SESION, strAlamcenamiento);

    if (objSesion) {
      this.headers = {
        Authorization: `Bearer ${objSesion.tokenServlet}`,
        token: `${objSesion.token}`,
        rut: `${objSesion.rut}`,
      };
    }

    if (strTokenPuertaEnlace){ 
      this.headers = {
        Authorization: `Bearer ${strTokenPuertaEnlace}`,
      };
    }

    if (this.headers && 
        strUrl.indexOf("flujoEnrolamiento") < VALOR_ZERO && 
        strUrl.indexOf("crearSolicitud") < VALOR_ZERO &&
        strUrl.indexOf("verificarCierreEnrolamiento") < VALOR_ZERO &&
        !strUrl.includes("buscarSubProductoPorIdCondicionadoCobertura") &&
        !strUrl.includes("listarSubProductoCoberturaValorPorIdSubProducto") ) {
      delete this.headers.rut;
    }

    if (strUrl.indexOf("servdocto") > 0   || strUrl.indexOf("docto") > 0 || 
        strUrl.indexOf("poliza/1.0") > 0  || strUrl.indexOf("persona/1.0") > 0 ||
        strUrl.indexOf("empresa/1.0") > 0 || strUrl.indexOf(ENDPOINT_LISTAR_CON_PRIMA_FIJA) > 0) {
      delete this.headers.token;
    }

    if (strUrl.indexOf("buk") > VALOR_ZERO) {
      if (process.env.REACT_APP_ENV === "DEV" || process.env.REACT_APP_ENV === "DEV2" || process.env.REACT_APP_ENV === "DESA") {
        delete this.headers.Authorization;
        delete this.headers.token;
      }
    }

    if (strUrl.indexOf(ENDPOINT_LISTAR_CON_PRIMA_FIJA) > VALOR_ZERO ) {
      delete axios.defaults.headers.common["Accept"];
    }

    if(strUrl.indexOf("/suscripcionweb") > VALOR_ZERO ){
      delete this.headers.Authorization;
      delete this.headers.token;
    }
  }

  async get(strUrl, blnRutaDefecto = true, blnPuertaEnlace = false, strTokenPuertaEnlace = null, strAlamcenamiento = ALMACENAMIENTO_SESION) {
    
    this.configurarCabecerasPeticion(strUrl, strAlamcenamiento, strTokenPuertaEnlace);

    strUrl = blnRutaDefecto ? process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL + strUrl : strUrl;
    
    const objRespuesta = await axios.get(strUrl,{ headers: this.headers });
    return objRespuesta;
  }

  async post(strUrl, objCuerpo, blnRutaDefecto = true, strTokenPuertaEnlace = null, strAlamcenamiento = ALMACENAMIENTO_SESION) {

    this.configurarCabecerasPeticion(strUrl, strAlamcenamiento, strTokenPuertaEnlace);

    strUrl = blnRutaDefecto ? process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL + strUrl : strUrl;

    const objRespuesta = await axios.post(strUrl, objCuerpo,{ headers: this.headers, timeout: REQUEST_POST_TIMEOUT, signal: AbortSignal.timeout(REQUEST_POST_TIMEOUT) });
    return objRespuesta;
  }

  async put(strUrl, objCuerpo, blnRutaDefecto = true, blnPuertaEnlace = false, strAlamcenamiento = ALMACENAMIENTO_SESION) {
    
    this.configurarCabecerasPeticion(strUrl, strAlamcenamiento, null);
    strUrl = blnRutaDefecto ? process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL + strUrl : strUrl;

    const objRespuesta = await axios.put(strUrl, objCuerpo, { headers: this.headers });
    return objRespuesta;
  }

  async delete(strUrl, blnRutaDefecto = true, blnPuertaEnlace = false, strAlamcenamiento = ALMACENAMIENTO_SESION) {

    this.configurarCabecerasPeticion(strUrl, strAlamcenamiento, null);
    strUrl = blnRutaDefecto ? process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL + strUrl : strUrl;

    const objRespuesta = await axios.delete(strUrl,{ headers: this.headers });
    return objRespuesta;
  }

}

export default ServicesFactory;
