import React, { Component } from "react";
import DataTable from "react-data-table-component";
import { DATATABLE_NO_DATA } from "../../../utils/Constantes";

export default class Table extends Component {
  render() {
    return (
      <DataTable
        title={this.props.titulo}
        columns={this.props.columnas}
        data={this.props.datos}
        customTheme={this.props.tema}
        striped={true}
        noDataComponent={
          this.props.mensajeNoDatos
            ? this.props.mensajeNoDatos
            : DATATABLE_NO_DATA
        }
        pagination={this.props.paginacion}
        paginationPerPage={this.props.paginacionPorPagina}
        paginationComponentOptions={this.props.paginacionOpciones}
        defaultSortField={this.props.campoOrden}
        selectableRows={!!this.props.mostrarCheckBox}
        onRowSelected={this.props.funcionColumnaSeleccionada}
        selectableRowsPreSelectedField={this.props.propiedadPreseleccionado}
        selectableRowsDisabledField={this.props.propiedadDeshabilitado}
        onChangePage={this.props.funcionCambiarPagina}
        noHeader={true}
      />
    );
  }
}
