import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { guardar_negocio, limpiar_filtro_solicitudes } from "../../../../../actions/actions";
import moment from "moment";
import {
  ZERO_STRING,
  SIGNO_GUION,
  VALOR_ZERO,
  VALOR_UNO,
  PERFIL_DELEGADO,
  COD_EJECUTIVO_MANTENCION,
  COD_EJECUTIVO_COMPLETO,
  PERFIL_LDAP,
  VALIDAR_CONTRATANTE,
  VALIDAR_MOVIMIENTO,
  VALOR_VACIO_GUION,
  RUTA_VER_SOLICITUDES_CORREDOR,
  RUTA_VER_SOLICITUDES,
  RUTA_EJECUTIVO_VER_SOLICITUDES,
  MENSAJE_SIN_POLIZAS,
  FORMATO_FECHA_DDMMYYYY,
  MOVIMIENTO_SELECCIONADO_INICIAL,
  NEGOCIO_SELECCIONADO_INICIAL,
  POSICION_INICIAL,
  PALABRA_GRUPO,
  VALOR_VACIO_ID_CONTRATANTE,
  LARGO_UNO_AGRUPACIONES,
  LARGO_DOS_AGRUPACIONES,
} from "../../../../../utils/Constantes";
import Select from "react-select";
import MultiSelect from "../../../transversales/MultiSelect";
import { buscarPolizaPorRutContratante, listarPolizaPorRutEmpresa } from "../../../../../services/poliza.service";
import { loadingAction } from "../../../../../reducer/delegadoMantencion";
import { notificacion } from "../../../transversales/Notificaciones";

class SelectContratante extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idNegocioSeleccionado: this.props.idNegocio
        ? this.props.idNegocio
        : VALOR_ZERO,
      negocios: this.props.store.reducer.negocios,
      polizasSeleccionadas: [],
      cargarDatos: false,
      rutTemp: this.props.rutContratante,
      filtroPolizaVacio: [],
      idMovimientoSeleccionado: this.props.idMovimientoSeleccionado || VALOR_ZERO,
      errorContratante: "",
      errorMovimiento: "",
      contador: this.props.contador,
      contratanteAnterior: VALOR_VACIO_ID_CONTRATANTE,
      busquedaPlan: false,
    };
  }
  async componentDidMount() {
    const esEjecutivo =
      this.props.store.auth.usuarioSesion.rol.codigo === COD_EJECUTIVO_MANTENCION ||
      this.props.store.auth.usuarioSesion.rol.codigo === COD_EJECUTIVO_COMPLETO;

    const negocios = this.props.store.reducer.negocios

    this.setState({
      negocios,
      idNegocioSeleccionado: this.props.idNegocio
        ? this.props.idNegocio
        : VALOR_ZERO,
      idMovimientoSeleccionado: this.props.idMovimientoSeleccionado || VALOR_ZERO
    });
    if (esEjecutivo && this.state.negocios && this.state.negocios.length >= 1) {
      const opcionesSelect = this.state.negocios.map((item) => {
        return {
          value: item.idOrganizacion,
          label: this.props.rutContratante
            ? `${item.rut} ${item.razonSocial}`
            : item.razonSocial,
        };
      });
      this.setState({ opcionesSelect }, () => {
        if (this.props.idNegocio > 0) {
          const empresa = this.state.opcionesSelect.find(
            (opcion) => opcion.value === this.props.idNegocio
          );
          if (empresa) {
            this.setState({ empresa });
            this.buscarPolizasInactivas();
          } else {
            this.setState({ idMovimientoSeleccionado: MOVIMIENTO_SELECCIONADO_INICIAL, idNegocioSeleccionado: NEGOCIO_SELECCIONADO_INICIAL })
          }
        }
      });
    }
    if (!esEjecutivo && this.state.idNegocioSeleccionado) this.buscarPolizasInactivas();

  }

  async componentDidUpdate() {
    if (this.props.contador > this.state.contador) {
      await this.buscarContratante(null, this.state.idNegocioSeleccionado);
    }
  }

  handleSearch = async () => {
    await this.props.limpiar_filtro_solicitudes();
    this.buscarPolizasInactivas();
  }

  handleChange = async (event) => {
    const { usuarioSesion } = this.props.store.auth;
    const esEjecutivo =
      usuarioSesion.rol.codigo === COD_EJECUTIVO_MANTENCION ||
      usuarioSesion.rol.codigo === COD_EJECUTIVO_COMPLETO;
    const idNegocioSeleccionado = esEjecutivo ? Number(event.value) : Number(event.target.value);

    if (idNegocioSeleccionado !== VALOR_ZERO) {
      this.setState({ errorContratante: "" });
    }

    this.setState({ idNegocioSeleccionado });
    if (esEjecutivo) {
      this.setState({
        empresa:
          this.state.opcionesSelect.find(negocio => negocio.value === idNegocioSeleccionado)
      });
    }
    if (isNaN(idNegocioSeleccionado)) notificacion("warning", MENSAJE_SIN_POLIZAS);
    else if (
      this.props.location.pathname !== RUTA_EJECUTIVO_VER_SOLICITUDES &&
      this.props.location.pathname !== RUTA_VER_SOLICITUDES &&
      this.props.location.pathname !== RUTA_VER_SOLICITUDES_CORREDOR
    ) {
      await this.buscarContratante(event, idNegocioSeleccionado);
    }
  }

  buscarContratante = async (event, idNegocioSeleccionado) => {
    if (this.state.contratanteAnterior !== idNegocioSeleccionado) {
      this.setState({ busquedaPlan: false });
    } else {
      this.setState({ busquedaPlan: this.props.busquedaPlan });
    }
    const { auth } = this.props.store;
    const { negocios } = this.state;
    const esEjecutivo =
      auth.usuarioSesion.rol.codigo === COD_EJECUTIVO_MANTENCION ||
      auth.usuarioSesion.rol.codigo === COD_EJECUTIVO_COMPLETO;
    let opcionesPolizas = [];
    if (idNegocioSeleccionado === VALOR_ZERO) {
      opcionesPolizas = [];
      this.props.obtenerPolizas(idNegocioSeleccionado, opcionesPolizas);
    } else {
      const negocioPolizas = negocios.find((negocio) => {
        return Number(negocio.idOrganizacion) === idNegocioSeleccionado;
      });
      if (!negocioPolizas && this.props.contador === this.state.contador) return;

      this.setState({ contador: this.props.contador });

      let negocio = this.state.negocios.find(
        (e) => Number(e.idOrganizacion) === Number(idNegocioSeleccionado)
      );

      let polizas;

      if (esEjecutivo) {
        let dataEnvio = {
          perfil: PERFIL_LDAP,
          polizaVigente: true,
          rutContratante: negocioPolizas.rut,
        };
        this.props.loadingAction(true);
        polizas = await buscarPolizaPorRutContratante(dataEnvio);
        this.props.loadingAction(false);
        negocio = { ...negocio, polizas };
      } else {
        this.props.loadingAction(true);
        const rutEmpresa = `${negocioPolizas.rutEmpresa}-${negocioPolizas.digitoVerificador}`;
        polizas = await listarPolizaPorRutEmpresa(rutEmpresa);
        if (polizas) {
          const fechaHoy = new Date();
          polizas = polizas.filter(poliza =>
            (fechaHoy >= new Date(poliza.fechaInicioVigencia)
              && fechaHoy <= new Date(poliza.fechaFinVigencia)) || fechaHoy < new Date(poliza.fechaInicioVigencia)
          );
          if (polizas.length === 0) notificacion("warning", MENSAJE_SIN_POLIZAS);
          negocio = { ...negocio, polizas }
        } else {
          polizas = []
          notificacion("warning", MENSAJE_SIN_POLIZAS);
        }
        this.props.loadingAction(false);
      }

      this.props.guardar_negocio(negocio);

      if (this.props.rutContratante) {
        const rut = esEjecutivo
          ? negocio.rut
          : `${negocio.rutEmpresa +
          VALOR_VACIO_GUION +
          negocio.digitoVerificador
          }`;
        this.props.setRutContratante(rut);
      }
      if (this.state.busquedaPlan) {
        let agrupacionPoliza = [];

        polizas.forEach((poliza) => {

          let existe = false;
          if (poliza.agrupaciones.length === LARGO_UNO_AGRUPACIONES) {
            existe = agrupacionPoliza.find((agrupacion) => (agrupacion.agrupaciones[POSICION_INICIAL] && agrupacion.agrupaciones[POSICION_INICIAL].glosa)
              === (poliza.agrupaciones[POSICION_INICIAL] && poliza.agrupaciones[POSICION_INICIAL].glosa))

            if (!existe && poliza.agrupaciones.length > POSICION_INICIAL) {
              agrupacionPoliza.push(poliza)
            } else {
              let indice = agrupacionPoliza.indexOf(existe);
              agrupacionPoliza[indice] = {
                ...agrupacionPoliza[indice],
                polizaAgrupada: poliza
              }
            }
          }
        });
        polizas.forEach((poliza) => {
          poliza.agrupaciones.forEach((agrupacionTemporal) => {

            let existe = false;
            if (poliza.agrupaciones.length === LARGO_DOS_AGRUPACIONES) {
              existe = agrupacionPoliza.find((agrupacion) => (agrupacion.agrupaciones[POSICION_INICIAL] && agrupacion.agrupaciones[POSICION_INICIAL].glosa)
                === (agrupacionTemporal && agrupacionTemporal.glosa))

              if (!existe && poliza.agrupaciones.length > POSICION_INICIAL) {
                agrupacionPoliza.push(poliza)
              } else {
                let indice = agrupacionPoliza.indexOf(existe);
                agrupacionPoliza[indice] = {
                  ...agrupacionPoliza[indice],
                  polizaAgrupada: poliza
                }
              }
            }
          })
        });
        agrupacionPoliza.forEach((poliza) => {
          const rangoVigencia = `${moment.utc(new Date(poliza.fechaInicioVigencia).toUTCString()).format(FORMATO_FECHA_DDMMYYYY)}
           - ${moment.utc(new Date(poliza.fechaFinVigencia).toUTCString()).format(FORMATO_FECHA_DDMMYYYY)}`;

          poliza.grupos.forEach((grupo) => {
            let labelGrupo =
              poliza.grupos.length > VALOR_UNO ? `${PALABRA_GRUPO} ${grupo.nroGrupo}` : "";
            let nuevaPoliza = {
              poliza,
              idContratoSeguroGrupo: grupo.idContratoSeguroGrupo,
              value: poliza.numeroPoliza,
              label: `${poliza.agrupaciones[POSICION_INICIAL] ? poliza.agrupaciones[POSICION_INICIAL].glosa : poliza.numeroPoliza} ${labelGrupo}`,
              dataTooltip: rangoVigencia,
            };
            opcionesPolizas.push(nuevaPoliza);
          });
        });
      } else {
        polizas.forEach((poliza) => {
          const rangoVigencia = `${moment.utc(new Date(poliza.fechaInicioVigencia).toUTCString()).format(FORMATO_FECHA_DDMMYYYY)}
         - ${moment.utc(new Date(poliza.fechaFinVigencia).toUTCString()).format(FORMATO_FECHA_DDMMYYYY)}`;

          poliza.grupos.forEach((grupo) => {
            let labelGrupo =
              poliza.grupos.length > VALOR_UNO ? `${PALABRA_GRUPO} ${grupo.nroGrupo}` : "";

            let nuevaPoliza = {
              poliza,
              idContratoSeguroGrupo: grupo.idContratoSeguroGrupo,
              value: poliza.numeroPoliza,
              label: `${poliza.numeroPoliza}-${poliza.numeroRenovacion} ${poliza.tipoPoliza.glosa} ${labelGrupo}`,
              dataTooltip: rangoVigencia,
            };
            opcionesPolizas.push(nuevaPoliza);
          });
        });
      }

      this.props.obtenerPolizas(
        idNegocioSeleccionado,
        opcionesPolizas,
        negocio.polizas,
        negocio.rutEmpresa ? `${negocio.rutEmpresa}` : `${negocio.rut}`
      );

      if (this.props.tabInactiva !== undefined) {
        this.props.cargando(true);
        let dataEnvio = {
          perfil: esEjecutivo
            ? PERFIL_LDAP
            : PERFIL_DELEGADO,
          polizaVigente: false,
          rutContratante: esEjecutivo
            ? negocioPolizas.rut
            : negocioPolizas.rutEmpresa +
            SIGNO_GUION +
            negocioPolizas.digitoVerificador,
        };
        this.props.loadingAction(true);
        const polizasInactivas = await buscarPolizaPorRutContratante(dataEnvio);
        this.props.loadingAction(false);

        let polizasTemporales = [];
        //CARGAR POLIZAS EN FILTRO
        let polizasFiltro = [];
        polizasInactivas.forEach((poliza) => {
          polizasTemporales.push({
            label:
              poliza.numeroPoliza +
              SIGNO_GUION +
              poliza.numeroRenovacion +
              " " +
              poliza.tipoPoliza.glosa,
            value:
              poliza.numeroPoliza +
              SIGNO_GUION +
              poliza.numeroRenovacion +
              " " +
              poliza.tipoPoliza.glosa,
            nroPoliza: poliza.numeroPoliza,
            dataTooltip: `${moment(poliza.fechaInicioVigencia).format(
              "DD/MM/YYYY"
            )} - ${moment(poliza.fechaFinVigencia).format("DD/MM/YYYY")}`,
            nroRenovacion: poliza.numeroRenovacion,
          });

          polizasFiltro.push({
            etiqueta:
              poliza.numeroPoliza +
              SIGNO_GUION +
              poliza.numeroRenovacion +
              " " +
              poliza.tipoPoliza.glosa,
            numeroPoliza: poliza.numeroPoliza,
            numeroRenovacion: poliza.numeroRenovacion,
          });
        });
        this.props.setPolizasFiltro(polizasFiltro);
        this.setState({
          polizas: polizasTemporales,
          filtroPolizaVacio: polizasFiltro,
        });
        this.props.cargando(false);
      }
    }

    let nuevoEstado = {
      idNegocioSeleccionado,
    };

    if (esEjecutivo) {
      if (await event) {
        nuevoEstado.empresa = event;
      }
      else {
        nuevoEstado = this.state.opcionesSelect.find(negocio => negocio.value === this.state.idNegocioSeleccionado)
      }
    }

    this.setState(nuevoEstado);
    this.setState({
      contador: this.props.contador,
      contratanteAnterior: idNegocioSeleccionado,
    });
  };

  polizasSeleccionadas = (polizas) => {
    let polizasFiltroTemp = [];
    if (polizas) {
      polizas.forEach((filtro) => {
        polizasFiltroTemp.push({
          etiqueta: filtro.value,
          nroPoliza: filtro.nroPoliza,
          numeroRenovacion: parseInt(
            filtro.value.split(SIGNO_GUION)[VALOR_UNO].split(" ")[VALOR_ZERO]
          ),
        });
      });
      this.props.setPolizasFiltro(polizasFiltroTemp);
    } else {
      this.props.setPolizasFiltro(this.state.filtroPolizaVacio);
    }
    this.setState({
      polizasSeleccionadas: polizas,
    });
  };

  buscarPolizasInactivas = async () => {
    const { negocios, idNegocioSeleccionado } = this.state;
    const negocio = negocios.find((negocio) => {
      const coincideId =
        Number(negocio.idOrganizacion) === idNegocioSeleccionado;

      return negocio.polizas && coincideId;
    });

    await this.buscarContratante(negocio, this.state.idNegocioSeleccionado);
    let errorCamposFaltantes = false;
    if (
      this.state.idNegocioSeleccionado === VALOR_ZERO ||
      this.state.idNegocioSeleccionado === ZERO_STRING
    ) {
      errorCamposFaltantes = true;
      this.setState({
        errorContratante: VALIDAR_CONTRATANTE,
      });
    }
    if (this.state.idMovimientoSeleccionado === VALOR_ZERO) {
      errorCamposFaltantes = true;
      this.setState({
        errorMovimiento: VALIDAR_MOVIMIENTO,
      });
    }

    if (!errorCamposFaltantes) {
      if (this.state.polizasSeleccionadas === null) {
        this.props.buscarSolicitudes([]);
      } else {
        this.props.buscarSolicitudes(this.state.polizasSeleccionadas);
      }
    }
  };

  seleccionDeMovimiento = async (event) => {
    this.props.editarMovimiento(parseInt(event.target.value));
    this.setState({
      idMovimientoSeleccionado: parseInt(event.target.value),
    });
    if (parseInt(event.target.value) !== VALOR_ZERO) {
      this.setState({
        errorMovimiento: "",
      });
    }
  };

  render() {
    return (
      <div className="col-md-12 p-0">
        <div className="form-group">
          <div className="col-md-12 p-0">
            <div
              className={
                this.props.tabInactiva || this.props.tabActiva
                  ? "mx-5"
                  : "col-md-12"
              }
            >
              <label>Contratante</label>
              {
                this.props.store.auth.usuarioSesion.rol.codigo === COD_EJECUTIVO_MANTENCION ||
                  this.props.store.auth.usuarioSesion.rol.codigo === COD_EJECUTIVO_COMPLETO
                  ? (
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      onChange={this.handleChange}
                      name="color"
                      value={this.state.empresa}
                      options={this.state.opcionesSelect}
                      placeholder={"Ingrese contratante"}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: "3px",
                        colors: {
                          ...theme.colors,
                          primary25: "#FED580",
                          primary: "#ed8d00",
                        },
                      })}
                    />
                  ) : (
                    <select
                      id="idNegocio"
                      name="idNegocio"
                      onChange={this.handleChange}
                      value={this.state.idNegocioSeleccionado}
                      className="form-control"
                    >
                      {
                        <option value={VALOR_ZERO}>
                          Selecciona un contratante
                        </option>
                      }
                      {this.state.negocios.map((item, i) => (
                        <option
                          key={`negocio_${i}`}
                          value={item.idOrganizacion}
                          id={`opcion${item.razonSocial}`}
                        >
                          {this.props.rutContratante
                            ? `${item.rutEmpresa}-${item.digitoVerificador} ${item.razonSocial}`
                            : item.razonSocial}
                        </option>
                      ))}
                    </select>
                  )}
              <p style={{ color: "red" }}>{this.state.errorContratante}</p>
            </div>

            {/* Poliza Renovacion */}
            {this.props.tabInactiva !== undefined && (
              <div className="mx-5 mt-3">
                <label>Póliza renovación </label>
                <MultiSelect
                  onChange={(values) => {
                    this.polizasSeleccionadas(values);
                  }}
                  tooltip={true}
                  id="polizas"
                  name="polizas"
                  options={this.state.polizas}
                  noOptionsMessage={() => "No existen más pólizas"}
                  placeholder={"Seleccione póliza/s"}
                  value={this.state.polizasSeleccionadas}
                />
              </div>
            )}

            {/* Movimiento */}
            {(this.props.tabInactiva || this.props.tabActiva) && (
              <div className="mx-5 mt-3">
                <label>Movimiento</label>
                <select
                  id="idMovimiento"
                  name="idMovimiento"
                  onChange={this.seleccionDeMovimiento}
                  value={this.state.idMovimientoSeleccionado}
                  className="form-control"
                >
                  {<option value={VALOR_ZERO}>Selecciona un movimiento</option>}
                  {this.props.tipoMovimientos.map((item, i) => (
                    <option
                      key={`movimiento_${i}`}
                      value={item.id}
                      id={`opcion${item.glosa}`}
                    >
                      {item.glosa}
                    </option>
                  ))}
                </select>
                <p style={{ color: "red" }}>{this.state.errorMovimiento}</p>

                <div className="col-md-12 mb-5" style={{ padding: 0 }}>
                  <button
                    style={{ marginTop: 20 }}
                    type="submit"
                    className="btn btn-principal mt-4 d-block ml-auto"
                    disabled={this.state.cargarDatos}
                    onClick={this.handleSearch}
                  >
                    {this.state.cargarDatos ? (
                      <Fragment>
                        <i className="fa fa-spinner fa-pulse fa-fw"></i>
                        <span className="sr-only">Loading...</span>
                      </Fragment>
                    ) : (
                      <span>Buscar</span>
                    )}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state,
});

export default withRouter(
  connect(mapStateToProps, { guardar_negocio, limpiar_filtro_solicitudes, loadingAction })(
    SelectContratante
  )
);
