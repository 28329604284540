import ServicesFactory from "../config/servicesFactory";
import { COD_STATUS_EXITO, STATUS_EXITO } from "../utils/Constantes";

let servicios = new ServicesFactory();

export async function buscarPorRut(
  strRut
) {
  try {
    let url = `${process.env.REACT_APP_BUS_URL_EMPRESA}/corredor/persona/buscarPorRut/${strRut}`;
    let respuesta = await servicios.get(url, false);
    return respuesta !== undefined &&
      respuesta !== null &&
      respuesta.status === STATUS_EXITO &&
      respuesta.data.status.codigo === COD_STATUS_EXITO
      ? respuesta.data.objeto
      : null;
  } catch (error) {
    return null;
  }
}
