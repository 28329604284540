import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Table from "../../../transversales/DataTable";
import ModalEditarAsegurable from "../../jefatura-ejecutivo/ModalEditarAsegurable";
import InicioEnrolamientoDelegado from "./InicioEnrolamientoDelegado";
import {
  formatTel,
  soloLetras,
  validator,
} from "../../../../../utils/Functions";
import uuid from "uuid";
import { notificacion } from "../../../transversales/Notificaciones";
import {
  enviarMailAsegurable,
  obtenerGrupoAsegurables,
  actualizarAsegurableDelegado,
  listarCargasPorIdAsegurable,
} from "../../../../../services/asegurable.services";
import {
  guardar_asegurados_delegado,
  actualizar_asegurables,
  guardar_cotizacion,
} from "../../../../../actions/actions";
import { confirmAlert } from "react-confirm-alert";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import "react-confirm-alert/src/react-confirm-alert.css";
import {
  CAMPO_REQUERIDO,
  VALIDACION_RUT,
  RUT_DUPLICADO,
  VALIDACION_NOMBRE,
  VALIDACION_APELLIDO_P,
  VALIDACION_MAIL,
  ESTADOS_ENROLAMIENTO,
  EXITO_EDICION,
  ERROR_EDICION,
  EXITO_ELIMINAR,
  ESTADO_NO_INICIADO,
  ESTADO_EN_PROCESO,
  ESTADO_ENROLADO,
  TYPEOF_STRING,
  ESTADO_COTIZACION_EN_PROCESO,
  MINIMA_CANTIDAD_ASEGURABLES,
  ID_ZERO,
  MENSAJE_REENVIO_CORREO,
  TIPO_FILTRO,
  TIPO_BUSQUEDA,
  VALOR_CIEN_VEINTE,
  VALOR_CIEN,
  TIPO_CANAL_VENTA_DIGITAL,
  VALOR_UNO,
  TIPO_CANAL_VENTA_DIRECTA,
  VALOR_ZERO,
  TIPO_CANAL_VENTA_CORREDOR,
  PERFIL_DELEGADO,
  TIPO_INCORPORACION_NUEVO_NEGOCIO,
  VALOR_VACIO_GUION,
  VALOR_PRODUCTO,
  COD_EJECUTIVO,
  PERFIL_LDAP,
  SECCION_INGRESAR_ASEGURABLES,
  NOMINA_PRELIMINAR_DELEGADO,
  SIN_DOCUMENTOS,
  SIGNO_GUION,
  SOLICITUD_FINALIZADA_POR_ASEGURABLE,
  ESTADO_FINALIZADO_POR_ASEGURABLE,
  COD_STATUS_EXITO,
  VALOR_QUINCE,
  ERROR_SOLICITUD,
  ERROR_LARGO_OBSERVACION,
  VALOR_TREINTA_Y_OCHO,
  COD_EJECUTIVO_COMPLETO,
  COD_EJECUTIVO_MANTENCION,
  ELIMINAR_CARGAS_DELEGADO,
  ELIMINAR,
  VALIDACION_TELEFONO,
  TELEFONO_MOVIL,
  VALIDACION_SIN_CORREO,
  CORREO_ELECTRONICO,
  VALIDA_TELEFONO,
  LARGO_TELEFONO_VALIDO,
  LARGO_TELEFONO_VACIO,
  VALIDACION_SIN_TELEFONO,
  ACCESO_ENVIADO_A,
  ERROR_ENVIO_AUTOMATICO,
  NOTIFICACION_TIPO_EXITOSA,
  NOTIFICACION_PELIGRO,
  POSICION_INICIAL,
  COD_CORREDOR,
  PERFIL_CORREDOR,
  LARGO_CERO_GRUPOS,
  DOTACION_INICIAL,
  GA_CATEGORIA_ENROLAMIENTO,
  GA_LABEL_LISTADO_ASEGURABLES,
} from "../../../../../utils/Constantes";
import FiltrosAsegurables from "./FiltrosAsegurables";
import IndicadoresProductosAsegurable from "../../../transversales/IndicadoresProductosAsegurable";
import {
  PRODUCTO_SALUD,
  PRODUCTO_SALUD_PYME,
} from "../../../../../utils/ConstantesVentaDirecta";
import MultiSelect from "../../../transversales/MultiSelect";
import {
  getArchivosCotizacion,
  obtenerCotizacionesNegocio,
} from "../../../../../services/cotizacion.services";
import { eliminarCarga } from "../../../../../services/asegurable.services";
import checkVerde from "../../../../assets/images/checkGreen.svg";
import { EjecutarSeleccionIncorporacion } from "../../../autenticacion/Incorporacion";
import { crearEventoTraza } from "../../../../../utils/analitica";

const { clean, format, validate } = require("rut.js");
const iconoVida = require("../../../../assets/images/life.png");
const iconoSalud = require("../../../../assets/images/heart.png");

class MisProcesos extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fechaInicioEnrolamiento:
        this.props.store.reducer.negocio.fechaInicioEnrolamiento,
      asegurable: {
        nombreCompleto: "",
        rut: "",
        nombre: "",
        rutAsegurable: "",
        digitoVerificador: "",
        apellidoPaterno: "",
        apellidoMaterno: "",
        correoElectronico: "",
        telefonoMovil: "",
        cumpleRequisitos: null,
        status: "",
        asegurablesAdicionales: "",
      },
      modalInicioEnrolamiento: false,
      mostrarModalAsegurable: false,
      asegurables: [],
      asegurablesBusqueda: [],
      cargasAsegurado: [],
      aseguradoSeleccionado: null,
      cargando: true,
      cotizacion: [],
      sesionEjecutivo:
        this.props.store.auth.usuarioSesion.rol.codigo === COD_EJECUTIVO ||
        this.props.store.auth.usuarioSesion.rol.codigo ===
        COD_EJECUTIVO_COMPLETO,
      blnSesionCorredor:
        this.props.store.auth.usuarioSesion.rol.codigo === COD_CORREDOR,
      existeDocumentoPreliminar: false,
      temaTabla: {
        title: {
          backgroundColor: "rgb(255, 255, 255)",
          height: "0px",
        },
        header: {
          fontSize: "14px",
          backgroundColor: "rgb(255, 255, 255)",
          fontColor: "black",
          fontWeight: "600",
        },
        rows: {
          backgroundColor: "white",
          borderWidth: "0px",
        },
      },
      columnDefs: [
        {
          name: "Producto",
          selector: "producto",
        },
        {
          name: "Nombre Completo",
          selector: "nombreCompleto",
        },
        {
          name: "RUT",
          selector: "rut",
        },
        {
          name: "Asegurables Adicionales",
          cell: (row) =>
            row.asegurablesAdicionales !== VALOR_VACIO_GUION &&
              row.asegurablesAdicionales !== VALOR_ZERO ? (
              <span
                style={{
                  cursor: "pointer",
                  textDecoration: "underline",
                  textAlign: "center",
                  width: "100%",
                  color: "blue",
                }}
                onClick={() => this.verCargas(true, row)}
              >
                {row.asegurablesAdicionales}
              </span>
            ) : (
              <span
                style={{
                  textAlign: "center",
                  width: "100%",
                }}
              >
                {row.asegurablesAdicionales}
              </span>
            ),
        },
        {
          name: "Correo",
          selector: "correoElectronico",
        },
        {
          name: "Teléfono Móvil",
          selector: "telefonoMovil",
        },
        {
          name: "Estado de Enrolamiento",
          selector: "status",
          wrap: true,
        },
        {
          name: "Requisitos Asegurabilidad",
          selector: "cunpleRequisitos",
          cell: (row) =>
            (!row.requisitos && !row.solicitud.fechaRecepcion) ? (
              <span
                style={{
                  textAlign: "center",
                  width: "100%",
                }}
              >
                {VALOR_VACIO_GUION}
              </span>
            ) : <div className="row" style={{ width: '100%', textAlign: 'center' }}>
              <div className="col-12">
                {row.requisitos && row.requisitos.map((objRequisito, intIndice) => {
                  return objRequisito.cumpleRequisito ? <div key={intIndice} className="requisitos">
                    <i className="fa fa-check text-success" aria-hidden="true"></i>
                    {objRequisito.glosaSubProducto}
                  </div> :
                    <div className="requisitos" key={intIndice}>
                      <i className="fa fa-times text-danger" aria-hidden="true"></i>
                      {objRequisito.glosaSubProducto} 
                    </div>
                })}
              </div>
            </div>,
        },
        {
          cell: (row) => (
            <React.Fragment>
              <button
                className="btn btn-primary btn-sm btn-table mr-2"
                type="button"
                title="Editar"
                raised="true"
                primary="true"
                disabled={row.solicitud.idSolicitud}
                onClick={() => this.handleActionChange(row)}
              >
                <i className="fa fa-pencil" aria-hidden="true"></i>
              </button>
              {!this.state.sesionEjecutivo && (
                <button
                  className="btn btn-danger btn-sm btn-table mr-2"
                  type="button"
                  title="Eliminar"
                  raised="true"
                  primary="true"
                  disabled={
                    !this.props.store.reducer.negocio.fechaFinEnrolamiento
                      ? false
                      : true
                  }
                  onClick={() => this.confirm(row)}
                >
                  <i className="fa fa-trash" aria-hidden="true"></i>
                </button>
              )}
              <button
                className="btn btn-dark btn-sm btn-table mr-2"
                type="button"
                title="Reenviar Clave"
                raised="true"
                primary="true"
                disabled={
                  !this.state.fechaInicioEnrolamiento ||
                  row.solicitud.idSolicitud
                }
                onClick={() => this.reenviarCorreo(row)}
              >
                <i className="fa fa-unlock" aria-hidden="true"></i>
              </button>
            </React.Fragment>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
      ],
      opcionesTiposProductos: [],
      tiposProductosInicial: [],
      verModalCargas: false,
      eliminarCargaSeleccionada: false,
      datosCarga: {
        rut: "",
        nombre: "",
        apellidoPaterno: "",
        apellidoMaterno: "",
        parentesco: "",
        idAsegurable: 0,
      },
      observacionCarga: "",
      mensajeCargaEliminada: false,
      cargarDatos: false,
      errorEliminarCarga: false,
      mensajeErrorEliminarCarga: "",
      perfilEjecutivo:
        this.props.store.auth.usuarioSesion &&
          (this.props.store.auth.usuarioSesion.rol.codigo === COD_EJECUTIVO ||
            this.props.store.auth.usuarioSesion.rol.codigo ===
            COD_EJECUTIVO_MANTENCION || this.props.store.auth.usuarioSesion.rol.codigo === COD_EJECUTIVO_COMPLETO)
          ? true
          : false,
      numeroDotacion: DOTACION_INICIAL
    };
  }

  async componentDidMount() {

    crearEventoTraza(GA_CATEGORIA_ENROLAMIENTO, GA_LABEL_LISTADO_ASEGURABLES);

    // Validar columnas canal venta DIGITAL
    const idCanalVenta = this.props.store.reducer.negocio.canalVenta.idCanalVenta;

    if (idCanalVenta === VALOR_UNO) {
      let columnas = this.state.columnDefs.filter(
        (item) => item.selector !== VALOR_PRODUCTO
      );
      this.setState({ columnDefs: columnas });
    }

    //definir de donde se obtienen las cotizaciones
    const objPerfil = this.state.sesionEjecutivo ? PERFIL_LDAP : this.state.blnSesionCorredor ? PERFIL_CORREDOR : PERFIL_DELEGADO;
    const objDataEnvio = {
      idNegocio: this.props.store.reducer.negocio.idNegocio,
      objPerfil,
    };

    const objResNegocio = await obtenerCotizacionesNegocio(objDataEnvio);

    let cotizaciones = [];

    if (idCanalVenta === VALOR_UNO && (this.state.sesionEjecutivo || this.state.blnSesionCorredor)) {
      // perfil ejecutivo venta digital
      cotizaciones = objResNegocio.cotizaciones.gestionadas;
    } else if (this.state.sesionEjecutivo || this.state.blnSesionCorredor) {
      // perfil ejecutivo venta directa
      cotizaciones = objResNegocio.cotizaciones;
    } else {
      // perfil delegado digital o directa
      if (idCanalVenta === VALOR_UNO) {
        cotizaciones = objResNegocio.cotizaciones.gestionadas;
      }
      else{
        cotizaciones = objResNegocio.cotizaciones;
      }
    }

    const cotizacionGestionada = cotizaciones.filter(
      (item) =>
        item.estadoCotizacion !== null &&
        item.estadoCotizacion.idEstadoCotizacion >= ESTADO_COTIZACION_EN_PROCESO
    );

    this.setState({ cotizacion: cotizacionGestionada });
    await this.props.guardar_cotizacion(cotizacionGestionada);

    let calculoCantidadMinimaAdherencia;
    let cantidadMinimaAdherencia;

    if (
      this.props.store.reducer.negocio.canalVenta.idCanalVenta ===
      TIPO_CANAL_VENTA_DIGITAL
    ) {
      calculoCantidadMinimaAdherencia = Math.trunc(
        (cotizacionGestionada[POSICION_INICIAL].porcentajeMinimaAdherencia *
          cotizacionGestionada[POSICION_INICIAL].planCotizado[POSICION_INICIAL].totalTitular) /
        100
      );
      cantidadMinimaAdherencia =
        calculoCantidadMinimaAdherencia <= MINIMA_CANTIDAD_ASEGURABLES
          ? MINIMA_CANTIDAD_ASEGURABLES
          : calculoCantidadMinimaAdherencia;
    } else {
      calculoCantidadMinimaAdherencia = 1;
      cantidadMinimaAdherencia = 1;
    }
    this.obtenerAsegurables();

    const { totalTitular } = cotizacionGestionada.planCotizado
      ? cotizacionGestionada.planCotizado[0]
      : Number.POSITIVE_INFINITY;
    const porcentajeMaximo = Math.ceil(
      (VALOR_CIEN_VEINTE * totalTitular) / VALOR_CIEN
    );
    const maximoAsegurables = porcentajeMaximo < VALOR_CIEN ? porcentajeMaximo : VALOR_CIEN;

    this.setState(
      {
        cantidadMinimaAdherencia,
        maximoAsegurables,
      },
      () => {
        this.setState({ cargando: false });
      }
    );

    if (this.state.sesionEjecutivo) {
      this.existeDocumentoPreliminar();
    }
  }

  obtenerAsegurables = async () => {
    const { reducer } = this.props.store;
    const { negocio, cotizacion } = reducer;
    const canalVenta = negocio.canalVenta.idCanalVenta;
    let gruposVentaDirecta = [];
    let grupoAsegurables = [];

    // Obtener gruposVentaDirecta

    const cotizaciones = this.state.blnSesionCorredor ? reducer.cotizaciones :
      this.state.sesionEjecutivo
        ? reducer.cotizaciones
        : reducer.negocio.cotizaciones;

    if (
      canalVenta === TIPO_CANAL_VENTA_DIRECTA ||
      canalVenta === TIPO_CANAL_VENTA_CORREDOR
    ) {
      cotizaciones.forEach((cot) => {
        if (cotizacion || cotizacion.numeroDotacion < cot.numeroDotacion) {
          if (cot.grupos.length > 0)
            gruposVentaDirecta.push(cot.grupos[0].idGrupo);
          else
            notificacion(
              "warning",
              `Producto folio "${cot.codigoCotizacion}" no se ha podido configurar, favor comunicarse con encargado`
            );
        }
      });
    }

    const perfil = this.state.sesionEjecutivo ? PERFIL_LDAP : PERFIL_DELEGADO;

    // Obtener asegurables DIGITAL
    if (canalVenta === TIPO_CANAL_VENTA_DIGITAL) {
      grupoAsegurables = this.props.store.reducer.delegado.aseguradosDelegado;
      const { idGrupo } = cotizacion[POSICION_INICIAL].grupos[POSICION_INICIAL];
      const dataEnvio = {
        idGrupo: idGrupo,
        perfil,
      };
      grupoAsegurables = await obtenerGrupoAsegurables(dataEnvio);
    }

    // Obtener asegurables DIRECTA/CORREDOR
    else {
      for (let idGrupo of gruposVentaDirecta) {
        const dataEnvio = {
          idGrupo: idGrupo,
          perfil,
        };
        let asegurables = await obtenerGrupoAsegurables(dataEnvio);
        for (let asegurable of asegurables) {
          if (
            grupoAsegurables.filter((e) => e.rut === asegurable.rut).length >
            VALOR_ZERO
          ) {
            grupoAsegurables
              .find((e) => e.rut === asegurable.rut)
              .subProducto.push(asegurable.subProducto[0]);
          } else {
            grupoAsegurables.push(asegurable);
          }
        }
      }
    }

    if (grupoAsegurables !== null && grupoAsegurables.length > 0) {
      grupoAsegurables.forEach((e) => {
        e.key = uuid();
        e.producto =
          canalVenta > VALOR_UNO
            ? this.obtenerProductosAsegurable(e.subProducto)
            : null;
        e.rut =
          typeof e.rut === TYPEOF_STRING && e.rut.includes("-")
            ? e.rut
            : e.rut + "-" + e.digitoVerificador;
        e.nombreCompleto = `${e.nombre} ${e.apellidoPaterno} ${e.apellidoMaterno}`;
        if (e.solicitud.idSolicitud === ID_ZERO) {
          e.status = ESTADO_NO_INICIADO;
        } else if (
          e.solicitud.idSolicitud !== ID_ZERO &&
          e.solicitud.fechaRecepcion !== null
        ) {
          e.status = ESTADO_ENROLADO;
        } else if (
          e.solicitud.idSolicitud !== ID_ZERO &&
          e.solicitud.solicitudEstado.idSolicitudEstado ===
          SOLICITUD_FINALIZADA_POR_ASEGURABLE
        ) {
          e.status = ESTADO_FINALIZADO_POR_ASEGURABLE;
        } else if (e.solicitud.idSolicitud !== ID_ZERO) {
          e.status = ESTADO_EN_PROCESO;
        }
        if (e.status === ESTADO_ENROLADO) {
          e.asegurablesAdicionales = e.totalCarga;
        } else {
          e.asegurablesAdicionales = VALOR_VACIO_GUION;
        }
      });
      this.setState({
        asegurables: grupoAsegurables,
        asegurablesBusqueda: grupoAsegurables,
      });
      this.props.actualizar_asegurables(grupoAsegurables);
      this.props.guardar_asegurados_delegado(grupoAsegurables);
    }
  };

  obtenerProductosAsegurable = (subProductos) => {
    let productoSalud, productoVida;

    subProductos.forEach((item) => {
      if (
        item.idSubProducto === PRODUCTO_SALUD ||
        item.idSubProducto === PRODUCTO_SALUD_PYME
      )
        productoSalud = <img src={iconoSalud} alt="Salud" />;
      else productoVida = <img src={iconoVida} alt="Vida" />;
    });

    if (productoSalud && productoVida)
      return (
        <div>
          <img src={iconoSalud} alt="Salud" />{" "}
          <img src={iconoVida} alt="Vida" className="ml-2" />
        </div>
      );
    if (!productoSalud && productoVida) return productoVida;
    if (productoSalud && !productoVida) return productoSalud;
  };

  mostrarModalAsegurable = () => {
    this.setState({
      mostrarModalAsegurable: !this.state.mostrarModalAsegurable,
    });
  };

  handleActionChange = async (row) => {
    const perfil = this.state.sesionEjecutivo ? PERFIL_LDAP : PERFIL_DELEGADO;

    const dataEnvio = {
      idNegocio: this.props.store.reducer.negocio.idNegocio,
      perfil,
    };

    const resNegocio = await obtenerCotizacionesNegocio(dataEnvio);

    this.state.tiposProductosInicial = [];
    let productos = [];

    if (this.props.store.reducer.negocio.canalVenta.idCanalVenta === TIPO_CANAL_VENTA_DIGITAL) {
      resNegocio.cotizaciones.gestionadas.forEach((e) => {
        productos.push({
          label: `${e.subProducto.glosaSubProducto} - ${e.codigoCotizacion}`,
          value: e.subProducto.idSubProducto,
          idGrupo: e.grupos[0].idGrupo,
        });
        if (
          row.grupos.find((grupos) => grupos.idGrupo === e.grupos[0].idGrupo)
        ) {
          this.state.tiposProductosInicial.push({
            label: `${e.subProducto.glosaSubProducto} - ${e.codigoCotizacion}`,
            value: e.subProducto.idSubProducto,
            idGrupo: e.grupos[0].idGrupo,
          });
        }
      });
    } else {
      resNegocio.cotizaciones.forEach((e) => {
        if (e.grupos[0]) {
          productos.push({
            label: `${e.subProducto.glosaSubProducto} - ${e.codigoCotizacion}`,
            value: e.subProducto.idSubProducto,
            idGrupo: e.grupos[0].idGrupo,
          });
          if (
            row.grupos.find((grupos) => grupos.idGrupo === e.grupos[0].idGrupo)
          ) {
            this.state.tiposProductosInicial.push({
              label: `${e.subProducto.glosaSubProducto} - ${e.codigoCotizacion}`,
              value: e.subProducto.idSubProducto,
              idGrupo: e.grupos[0].idGrupo,
            });
          }
        }
      });
    }

    this.setState(
      {
        asegurable: row,
        opcionesTiposProductos: productos,
      },
      function () {
        this.mostrarModalAsegurable();
      }
    );
  };

  reenviarCorreo = async (row) => {
    const dataCorreo = {
      asegurable: {
        idAsegurable: row.idAsegurable,
        rut: Number(row.rut.split("-")[0]),
        digitoVerificador: row.digitoVerificador,
      },
    };

    const perfil = this.state.sesionEjecutivo ? PERFIL_LDAP : PERFIL_DELEGADO;

    const envioCorreo = await enviarMailAsegurable(dataCorreo, perfil);

    if (envioCorreo !== null) {
      notificacion(
        "success",
        `${MENSAJE_REENVIO_CORREO}${row.nombre} ${row.apellidoPaterno}`
      );
    } else {
      notificacion("danger", "Ha ocurrido un error");
    }
  };

  /* =========================
      FILTRO BÚSQUEDA
  ============================ */
  handleSearch = (asegurables, tipo) => {
    if (tipo === TIPO_FILTRO)
      this.setState({
        asegurablesBusqueda: asegurables,
      });
    if (tipo === TIPO_BUSQUEDA)
      this.setState({ asegurablesBusqueda: asegurables });
  };

  submit = async (values) => {
    if (this.props.store.reducer.cotizacion) {
      this.props.store.reducer.cotizacion.forEach(async objCotizacion => {


        if (!objCotizacion.grupos[LARGO_CERO_GRUPOS])
          return notificacion(
            "warning",
            `Ocurrio un error al realizar esta operación, favor comunicarse con encargado`
          );

        const { idGrupo } = objCotizacion.grupos[LARGO_CERO_GRUPOS];
        const { idAsegurable, idPersona } = this.state.asegurable;
        const rut = Number(clean(values.rut).slice(0, -1));
        const digitoVerificador = format(clean(values.rut)).split("-")[1];
        values.nombre = soloLetras(values.nombre).trim();
        values.apellidoPaterno = soloLetras(values.apellidoPaterno).trim();
        values.apellidoMaterno = soloLetras(values.apellidoMaterno).trim();

        let data = null;
        if (
          this.props.store.reducer.negocio.canalVenta.idCanalVenta ===
          TIPO_CANAL_VENTA_DIRECTA
        ) {
          data = {
            ...values,
            rut,
            digitoVerificador,
            idGrupo,
            idAsegurable,
            idPersona,
            grupos: values.subProducto,
            tipoIncorporacion: {
              idTipoIncorporacion: TIPO_INCORPORACION_NUEVO_NEGOCIO,
            },
          };
        } else {
          data = {
            ...values,
            rut,
            digitoVerificador,
            idGrupo,
            idAsegurable,
            idPersona,
          };
        }

        const perfil = this.state.sesionEjecutivo ? PERFIL_LDAP : PERFIL_DELEGADO;

        let res = await actualizarAsegurableDelegado(data, perfil);
        this.obtenerAsegurables();

        if (res !== null && res.status.codigo === "001") {
          //update state
          let update = this.state.asegurables.filter(
            (item) => item.key === this.state.asegurable.key
          )[0];

          let pos = this.state.asegurables.indexOf(update);

          let newAsegurables = [...this.state.asegurables];

          let apellidos = `${values.apellidoPaterno} ${values.apellidoMaterno}`;

          newAsegurables[pos] = { ...this.state.asegurable, ...values };
          newAsegurables[pos].rut = rut + "-" + digitoVerificador;
          newAsegurables[pos].apellidos = apellidos;

          if ((update.correoElectronico !== values.correoElectronico || update.telefonoMovil !== values.telefonoMovil)
            && this.props.store.reducer.negocio.fechaInicioEnrolamiento != null) {
            const dataCorreo = {
              asegurable: {
                idAsegurable,
                rut,
                digitoVerificador,
              },
            };
            const envioCorreo = await enviarMailAsegurable(
              dataCorreo,
              perfil
            );
            if (envioCorreo !== null) {
              notificacion(
                NOTIFICACION_TIPO_EXITOSA,
                `${ACCESO_ENVIADO_A} ${values.nombre} ${values.apellidoPaterno} ${values.apellidoMaterno}`
              );
            } else {
              notificacion(NOTIFICACION_PELIGRO, ERROR_ENVIO_AUTOMATICO);
            }
          }

          this.setState({
            asegurables: newAsegurables,
            asegurablesBusqueda: newAsegurables,
            mostrarModalAsegurable: false,
          });

          this.props.actualizar_asegurables(newAsegurables);
          this.props.guardar_asegurados_delegado(newAsegurables);

          this.setState({ cargando: false });
          notificacion("success", EXITO_EDICION);
        } else {
          this.setState({ cargando: false });
          notificacion("danger", ERROR_EDICION);
        }
      });
    }
  };

  confirm = (row) =>
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="modal-confirm-container">
            <div className="d-flex">
              <p className="h4">
                {row.solicitud.idSolicitud
                  ? "Está eliminando a un colaborador que ya ha comenzado su enrolamiento. ¿Desea continuar?"
                  : "¿Está seguro de eliminar a este asegurable?"}
              </p>
            </div>

            <div className="row mt-4">
              <button
                id="cancelar"
                className="btn btn-principal color-cancel btn-sm d-block ml-auto mr-2"
                onClick={() => {
                  onClose();
                }}
              >
                Cancelar
              </button>
              <button
                id="eliminar"
                className="btn btn-principal color-danger btn-sm"
                onClick={() => {
                  this.handleActionDelete(row);
                  onClose();
                }}
              >
                Eliminar
              </button>
            </div>
          </div>
        );
      },
    });

  handleActionDelete = async (row) => {
    const asegurables = this.state.asegurables;
    const rowDelete = asegurables.find((item) => item.key === row.key);
    rowDelete.vigente = false;

    const {
      apellidoMaterno,
      apellidoPaterno,
      correoElectronico,
      telefonoMovil,
      digitoVerificador,
      idAsegurable,
      idPersona,
      nombre,
      vigente,
    } = rowDelete;
    this.props.store.reducer.cotizacion.forEach(objCotizacion => {
      if (!objCotizacion.grupos[POSICION_INICIAL])
        return notificacion(
          "warning",
          `Ocurrio un error al realizar esta operación, favor comunicarse con encargado`
        );
    })

    this.props.store.reducer.cotizacion.forEach(async objCotizacion => {
      const { idGrupo } = objCotizacion.grupos[POSICION_INICIAL];
      const rut = Number(rowDelete.rut.split("-")[0]);

      const data = {
        apellidoMaterno,
        apellidoPaterno,
        correoElectronico,
        telefonoMovil,
        digitoVerificador,
        idAsegurable,
        idPersona,
        idGrupo,
        nombre,
        rut,
        vigente,
      };

      const perfil = this.state.sesionEjecutivo ? PERFIL_LDAP : PERFIL_DELEGADO;

      let res = await actualizarAsegurableDelegado(data, perfil);

      if (res !== null && res.status.codigo === "001") {
        const nuevoAsegurables = asegurables.filter((item) => item.vigente);
        this.setState({
          cargando: false,
          asegurables: nuevoAsegurables,
          asegurablesBusqueda: nuevoAsegurables,
        });
        this.props.actualizar_asegurables(nuevoAsegurables);
        this.props.guardar_asegurados_delegado(nuevoAsegurables);

        notificacion("success", EXITO_ELIMINAR);
      } else {
        this.setState({ cargando: false });
        notificacion("danger", ERROR_EDICION);
      }
    })
  };

  toInputUppercase = (e) => {
    e.target.value = ("" + e.target.value).toUpperCase();
  };

  busquedaAsegurables = (e) => {
    let busqueda = e.target.value.toUpperCase();
    let asegurables = this.props.store.reducer.grupoAsegurables.filter(
      (item) =>
        item.rut.includes(busqueda) ||
        item.nombreCompleto.toUpperCase().includes(busqueda) ||
        item.email.toUpperCase().includes(busqueda) ||
        item.status.toUpperCase().includes(busqueda)
    );
    this.setState({ asegurables });
  };

  rutUnico = (value) => {
    const { aseguradosDelegado } = this.props.store.reducer.delegado;
    const rutsAsegurables = aseguradosDelegado
      .map(({ rut }) => format(rut))
      .filter((item) => item !== format(this.state.asegurable.rut));

    return rutsAsegurables.includes(format(value)) ? false : true;
  };

  mostrarModalInicioEnrolamiento = (boolean) => {
    this.setState({
      modalInicioEnrolamiento: boolean,
    });
  };

  verCargas = async (boolean, data) => {
    const perfil = this.state.sesionEjecutivo ? PERFIL_LDAP : PERFIL_DELEGADO;

    let cargasAsegurado = await listarCargasPorIdAsegurable(
      data.idAsegurable,
      perfil
    );

    cargasAsegurado.forEach((carga) => {
      carga.verMas = false;
    });

    this.setState({
      aseguradoSeleccionado: data,
      verModalCargas: boolean,
      cargasAsegurado,
      eliminarCargaSeleccionada: false,
      mensajeCargaEliminada: false,
    });
  };

  actualizarFechaInicioEnrolamiento = (fechaInicioEnrolamiento) => {
    const nuevoArreglo = [];

    for (const asegurable of this.state.asegurablesBusqueda) {
      const copiaAsegurable = { ...asegurable };
      nuevoArreglo.push(copiaAsegurable);
    }

    this.setState({
      fechaInicioEnrolamiento,
      asegurablesBusqueda: nuevoArreglo,
    });
  };

  existeDocumentoPreliminar = async () => {
    const { negocio } = this.props.store.reducer;

    let data = {
      metadataDocto: {
        idNegocio: negocio.idNegocio,
        rutContratante:
          negocio.rutEmpresa + SIGNO_GUION + negocio.digitoVerificador,
        tipoDocumento: NOMINA_PRELIMINAR_DELEGADO,
      },
    };

    const respuesta = await getArchivosCotizacion(data);

    if (respuesta === null || respuesta === SIN_DOCUMENTOS) {
      this.setState({ existeDocumentoPreliminar: false });
    } else {
      this.setState({ existeDocumentoPreliminar: true });
    }
  };

  preEliminarCarga = async (
    rut,
    digitoVerificador,
    nombre,
    apellidoPaterno,
    apellidoMaterno,
    parentesco,
    idAsegurable
  ) => {
    this.setState({
      eliminarCargaSeleccionada: true,
      datosCarga: {
        rut: rut + -+digitoVerificador,
        nombre,
        apellidoPaterno,
        apellidoMaterno,
        parentesco,
        idAsegurable,
      },
      mensajeCargaEliminada: false,
      errorEliminarCarga: false,
    });
  };

  eliminarCarga = async () => {
    if (
      this.state.observacionCarga !== null &&
      this.state.observacionCarga !== "" &&
      this.state.observacionCarga.length > VALOR_QUINCE
    ) {
      this.setState({
        cargarDatos: true,
      });
      const perfil = this.state.sesionEjecutivo ? PERFIL_LDAP : PERFIL_DELEGADO;
      const dataEnvio = {
        titular: false,
        asegurable: {
          idAsegurable: this.state.datosCarga.idAsegurable,
          idUsuarioModifica: this.props.store.auth.usuarioSesion.id,
          observacion: this.state.observacionCarga,
          correoElectronico: this.state.aseguradoSeleccionado.correoElectronico,
          telefonoMovil: this.state.aseguradoSeleccionado.telefonoMovil
            ? this.state.aseguradoSeleccionado.telefonoMovil
            : "",
          nombre: this.state.aseguradoSeleccionado.nombre,
          apellidoPaterno: this.state.aseguradoSeleccionado.apellidoPaterno,
          apellidoMaterno: this.state.aseguradoSeleccionado.apellidoMaterno,
          notificacion: {
            tipoNotificacion: {
              idTipoNotificaion: VALOR_UNO,
            },
          },
          cargas: [
            {
              parentesco: {
                glosa: this.state.datosCarga.parentesco,
              },
              nombre: this.state.datosCarga.nombre,
              apellidoPaterno: this.state.datosCarga.apellidoPaterno,
              apellidoMaterno: this.state.datosCarga.apellidoMaterno,
            },
          ],
        },
        perfil,
      };

      const respuesta = await eliminarCarga(dataEnvio);

      if (respuesta != null && respuesta.status.codigo === COD_STATUS_EXITO) {
        const cargasAsegurado = await listarCargasPorIdAsegurable(
          this.state.aseguradoSeleccionado.idAsegurable,
          perfil
        );

        this.setState({
          cargasAsegurado,
          eliminarCargaSeleccionada: false,
          mensajeCargaEliminada: true,
          observacionCarga: "",
          cargarDatos: false,
        });
      } else {
        this.setState({
          cargarDatos: false,
          errorEliminarCarga: true,
          mensajeErrorEliminarCarga: ERROR_SOLICITUD,
        });
      }
    } else {
      this.setState({
        errorEliminarCarga: true,
        mensajeErrorEliminarCarga: ERROR_LARGO_OBSERVACION,
      });
    }
  };

  cancelarEliminarCarga = async () => {
    this.setState({
      observacionCarga: "",
      eliminarCargaSeleccionada: false,
    });
  };

  verObservacion = async (verMas, cargaAsegurado, indice) => {
    let cargas = this.state.cargasAsegurado;
    cargas[indice] = cargaAsegurado;
    cargas[indice].verMas = !verMas;
    this.setState({
      cargasAsegurado: cargas,
    });
  };

  render() {
    const {escogerIncorporacion} = this.props.store.auth;
    const numeroDotacion = this.state.cotizacion;
    const numeroAsegurables = this.state.asegurablesBusqueda.length;

    return (
      <React.Fragment>

        {this.state.modalInicioEnrolamiento && (
          <InicioEnrolamientoDelegado
            cantidadMinimaAdherencia={this.state.cantidadMinimaAdherencia}
            maximoAsegurables={this.state.maximoAsegurables}
            modalInicioEnrolamiento={this.state.modalInicioEnrolamiento}
            mostrarModalInicioEnrolamiento={this.mostrarModalInicioEnrolamiento}
            actualizarFechaInicioEnrolamiento={
              this.actualizarFechaInicioEnrolamiento
            }
          />
        )}

        {/* Editar asegurable */}
        <ModalEditarAsegurable
          idSubmit="submitMisProcesos"
          type="small"
          show={this.state.mostrarModalAsegurable}
          title="Editar Asegurable"
          action="Editar"
          onSubmit={this.submit}
          onCancel={() => {
            this.mostrarModalAsegurable();
            validator(
              document.querySelectorAll(
                "form[name=form-declaracion] input, form[name=form-declaracion] select",
                true
              ),
              true
            );
          }}
          onClose={() => {
            this.mostrarModalAsegurable();
            validator(
              document.querySelectorAll(
                "form[name=form-declaracion] input, form[name=form-declaracion] select",
                true
              ),
              true
            );
          }}
          showCancelButton={false}
          showCloseButton={true}
          showLeftColumn={false}
          showSubmitButton={false}
        >
          <Formik
            enableReinitialize={true}
            initialValues={{
              rut: this.state.asegurable.rut,
              nombre: this.state.asegurable.nombre,
              apellidoPaterno: this.state.asegurable.apellidoPaterno,
              apellidoMaterno: this.state.asegurable.apellidoMaterno,
              correoElectronico: this.state.asegurable.correoElectronico,
              subProducto: this.state.tiposProductosInicial,
              telefonoMovil: this.state.asegurable.telefonoMovil,
            }}
            validationSchema={Yup.object().shape({
              rut: Yup.string()
                .required(CAMPO_REQUERIDO)
                .test("validarRut", VALIDACION_RUT, (value) => {
                  return validate(value);
                })
                .test("rutDuplicado", RUT_DUPLICADO, this.rutUnico),
              nombre: Yup.string()
                .min(3, VALIDACION_NOMBRE)
                .required(CAMPO_REQUERIDO)
                .trim(),
              apellidoPaterno: Yup.string()
                .required(CAMPO_REQUERIDO)
                .min(3, VALIDACION_APELLIDO_P)
                .trim(),
              apellidoMaterno: Yup.string().trim(),
              correoElectronico: Yup.string()
                .ensure()
                .when(TELEFONO_MOVIL, {
                  is: '',
                  then: Yup.string().email(VALIDACION_MAIL).required(VALIDACION_SIN_CORREO)
                })
                .email(VALIDACION_MAIL),
              telefonoMovil: Yup.string()
                .ensure()
                .when(CORREO_ELECTRONICO, {
                  is: '',
                  then: Yup.string().test(VALIDA_TELEFONO, VALIDACION_TELEFONO, (value) => {
                    return (formatTel(value).length === LARGO_TELEFONO_VALIDO || formatTel(value).length === LARGO_TELEFONO_VACIO) ? true : false
                  }).required(VALIDACION_SIN_TELEFONO)
                })
                .test(VALIDA_TELEFONO, VALIDACION_TELEFONO, (value) => {
                  return formatTel(value).length === LARGO_TELEFONO_VALIDO ||
                    formatTel(value).length === LARGO_TELEFONO_VACIO
                    ? true
                    : false;
                }),
              subProducto: Yup.array().required(CAMPO_REQUERIDO).nullable(),
            }, [TELEFONO_MOVIL, CORREO_ELECTRONICO])}
            onSubmit={async (values, { resetForm }) => {
              values.telefonoMovil = formatTel(values.telefonoMovil);
              this.submit(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              resetForm,
              isSubmitting,
              validating,
              valid,
              setFieldValue,
              setFieldTouched,
            }) => {
              return (
                <div className="container">
                  <Form
                    className="mx-5"
                    onKeyDown={(e) => {
                      if ((e.charCode || e.keyCode) === 13) {
                        e.preventDefault();
                      }
                    }}
                  >
                    <div className="row animated fadeInRight">
                      {/* Producto */}
                      {this.props.store.reducer.negocio.canalVenta
                        .idCanalVenta === TIPO_CANAL_VENTA_DIRECTA && (
                          <div className="col-md-12">
                            <div className="form-group mb-2">
                              <label className="font-weight-bold">
                                Tipo de producto{" "}
                                <span className="text-danger">*</span>
                              </label>

                              <MultiSelect
                                value={values.subProducto}
                                onChange={(e) => {
                                  setFieldValue("subProducto", e);
                                }}
                                tooltip={true}
                                onBlur={setFieldTouched}
                                name="subProducto"
                                id="subProducto"
                                options={this.state.opcionesTiposProductos}
                                noOptionsMessage={() =>
                                  "No existen más subProducto para seleccionar"
                                }
                                placeholder={"Ingrese subProducto/s"}
                                errors={errors.subProducto}
                                touched={touched.subProducto}
                              />
                            </div>
                          </div>
                        )}

                      {/* rut */}
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>RUT</label>
                          <Field
                            className="form-control"
                            id="rut"
                            name="rut"
                            type="text"
                            placeholder="ej. 12345678-9"
                            maxLength="12"
                            minLength="11"
                            value={
                              values.rut.length > 1
                                ? format(values.rut)
                                : values.rut
                            }
                          />
                          {errors.rut && touched.rut && (
                            <small className="text-danger animated fadeIn">
                              {errors.rut}
                            </small>
                          )}
                        </div>
                      </div>

                      {/* nombre */}
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Nombres</label>

                          <Field
                            className="form-control"
                            id="nombre"
                            name="nombre"
                            type="text"
                            placeholder="Ingrese nombres"
                            value={soloLetras(values.nombre)}
                          />
                          {errors.nombre && touched.nombre && (
                            <small className="text-danger animated fadeIn">
                              {errors.nombre}
                            </small>
                          )}
                        </div>
                      </div>

                      {/* apellidoPaterno */}
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Apellido paterno</label>

                          <Field
                            className="form-control"
                            id="apellidoPaterno"
                            name="apellidoPaterno"
                            type="text"
                            placeholder="Ingrese apellido paterno"
                            value={soloLetras(values.apellidoPaterno)}
                          />
                          {errors.apellidoPaterno &&
                            touched.apellidoPaterno && (
                              <small className="text-danger animated fadeIn">
                                {errors.apellidoPaterno}
                              </small>
                            )}
                        </div>
                      </div>

                      {/* apellidoMaterno */}
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Apellido materno</label>

                          <Field
                            className="form-control"
                            id="apellidoMaterno"
                            name="apellidoMaterno"
                            type="text"
                            placeholder="Ingrese apellido materno"
                            value={soloLetras(values.apellidoMaterno)}
                          />
                        </div>
                      </div>

                      {/* email */}
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Email</label>
                          <Field
                            className="form-control"
                            id="correoElectronico"
                            name="correoElectronico"
                            type="text"
                            placeholder="test@gmail.com"
                            maxLength="50"
                            value={values.correoElectronico}
                          />
                          {errors.correoElectronico &&
                            touched.correoElectronico && (
                              <small className="text-danger animated fadeIn">
                                {errors.correoElectronico}
                              </small>
                            )}
                        </div>
                      </div>
                      {/* teléfono */}
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Teléfono móvil</label>
                          <Field
                            className="form-control"
                            id="telefonoMovil"
                            name="telefonoMovil"
                            type="text"
                            placeholder="569598044"
                            maxLength="13"
                            value={formatTel(values.telefonoMovil)}
                          />
                          {errors.telefonoMovil && touched.telefonoMovil && (
                            <small className="text-danger animated fadeIn">
                              {errors.telefonoMovil}
                            </small>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="button-zone text-right pt-3 pb-3">
                      <button
                        id="cancelarEditar"
                        className="btn btn-principal color-cancel color-secundario btn-sm"
                        type="button"
                        onClick={() => {
                          this.mostrarModalAsegurable();
                        }}
                      >
                        Cancelar
                      </button>
                      <button
                        id="submitEditar"
                        className="btn btn-principal btn_sm"
                        type="submit"
                      >
                        Editar
                      </button>
                    </div>
                  </Form>
                </div>
              );
            }}
          </Formik>
        </ModalEditarAsegurable>

        {/* Modal cargas */}
        <ModalEditarAsegurable
          type="medium"
          show={this.state.verModalCargas}
          title="Asegurables adicionales"
          onSubmit={() => this.setState({ verModalCargas: false })}
          titleButton="Aceptar"
          onClose={() => this.setState({ verModalCargas: false })}
          showCancelButton={false}
          showCloseButton={true}
          showLeftColumn={false}
          showSubmitButton={false}
        >
          <div className="">
            {this.state.aseguradoSeleccionado && (
              <h6>
                <span className="mr-5">
                  Titular:
                  <b>{` ${this.state.aseguradoSeleccionado.nombreCompleto}`}</b>
                </span>
                <span className="">
                  Rut: <b>{` ${this.state.aseguradoSeleccionado.rut}`}</b>
                </span>
              </h6>
            )}

            <table className="table table-hover">
              <thead className="thead-orange">
                <tr>
                  <th className="text-left" style={{ width: "16%" }}>
                    Rut
                  </th>
                  <th className="text-left" style={{ width: "17%" }}>
                    Nombre
                  </th>
                  <th className="text-left" style={{ width: "16%" }}>
                    Parentesco
                  </th>
                  <th className="text-left" style={{ width: "17%" }}>
                    Estado
                  </th>
                  <th className="text-left" style={{ width: "18%" }}>
                    Obsevaciones
                  </th>
                  <th style={{ width: "16%" }}>Opción</th>
                </tr>
              </thead>
              <tbody>
                {this.state.cargasAsegurado.map((item, indice) => (
                  <tr key={indice}>
                    <td>{`${item.rut}-${item.digitoVerificador}`}</td>
                    <td>{`${item.nombre} ${item.apellidoPaterno} ${item.apellidoMaterno}`}</td>
                    <td>{item.parentesco.glosa}</td>
                    <td>
                      {item.existeResumenIncorporacion
                        ? "Ingresado"
                        : "Eliminado por " + item.usuarioModifica.nombres}
                    </td>
                    <td>
                      {item.observacion === null || item.observacion === ""
                        ? item.observacion
                        : item.verMas
                          ? item.observacion
                          : item.observacion.substring(
                            VALOR_ZERO,
                            VALOR_TREINTA_Y_OCHO
                          )}
                      {item.observacion != null &&
                        item.observacion !== "" &&
                        item.observacion.length > VALOR_TREINTA_Y_OCHO && (
                          <span
                            style={{
                              cursor: "pointer",
                              textAlign: "center",
                              width: "100%",
                              color: "rgb(232, 155, 3)",
                            }}
                            onClick={() =>
                              this.verObservacion(item.verMas, item, indice)
                            }
                          >
                            {item.verMas ? (
                              <span> Ver menos</span>
                            ) : (
                              <span> Ver más</span>
                            )}
                          </span>
                        )}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <button
                        className="btn btn-danger btn-sm btn-table mr-2"
                        type="button"
                        title={
                          !this.state.perfilEjecutivo
                            ? ELIMINAR_CARGAS_DELEGADO
                            : ELIMINAR
                        }
                        raised="true"
                        primary="true"
                        disabled={
                          !item.existeResumenIncorporacion ||
                          !this.state.perfilEjecutivo
                        }
                        onClick={() =>
                          this.preEliminarCarga(
                            item.rut,
                            item.digitoVerificador,
                            item.nombre,
                            item.apellidoPaterno,
                            item.apellidoMaterno,
                            item.parentesco.glosa,
                            item.idAsegurable
                          )
                        }
                      >
                        <i className="fa fa-trash" aria-hidden="true"></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {this.state.eliminarCargaSeleccionada && (
              <div
                className="container col-12"
                style={{ background: "#FEF9F0", marginTop: 30 }}
              >
                <div style={{ padding: 30 }}>
                  <div
                    className="row font-weight-bold mb-0"
                    style={{ fontSize: 18 }}
                  >
                    ¿Esta seguro de eliminar esta carga?
                  </div>
                  <div className="row" style={{ marginTop: 25 }}>
                    <div className="font-weight-bold">
                      <span className="text-primary">RUT</span>
                      <br></br>
                      <span>{this.state.datosCarga.rut}</span>
                    </div>
                    <div
                      className="font-weight-bold"
                      style={{ marginLeft: 50 }}
                    >
                      <span className="text-primary">Nombre Completo</span>
                      <br></br>
                      <span>
                        {this.state.datosCarga.nombre +
                          " " +
                          this.state.datosCarga.apellidoPaterno +
                          " " +
                          this.state.datosCarga.apellidoMaterno}
                      </span>
                    </div>
                    <div
                      className="font-weight-bold"
                      style={{ marginLeft: 50 }}
                    >
                      <span className="text-primary">Parentesco</span>
                      <br></br>
                      <span>{this.state.datosCarga.parentesco}</span>
                    </div>
                  </div>
                  <div className="row" style={{ marginTop: 25 }}>
                    Ingresar observaciones
                    <textarea
                      id="observacion"
                      className="form form-control"
                      placeholder="Ingrese motivo por el cual se elimina la carga."
                      rows="3"
                      onChange={(e) =>
                        this.setState({ observacionCarga: e.target.value })
                      }
                      style={{ marginTop: 5 }}
                      minLength="15"
                      maxLength="250"
                    ></textarea>
                    {this.state.errorEliminarCarga && (
                      <span style={{ color: "red" }}>
                        {this.state.mensajeErrorEliminarCarga}
                      </span>
                    )}
                  </div>
                  <div className="row text-right" style={{ marginTop: 25 }}>
                    <div className="col-12 text-right">
                      <button
                        className="btn btn-principal btn-lg"
                        type="button"
                        onClick={() => this.cancelarEliminarCarga()}
                        style={{ marginRight: 15 }}
                      >
                        No
                      </button>
                      <button
                        className="btn btn-principal btn-lg"
                        type="button"
                        onClick={() => this.eliminarCarga()}
                        disabled={this.state.cargarDatos}
                      >
                        {this.state.cargarDatos ? (
                          <Fragment>
                            <i className="fa fa-spinner fa-pulse fa-fw"></i>
                            <span className="sr-only">Loading...</span>
                          </Fragment>
                        ) : (
                          <span>Si, eliminar</span>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {this.state.mensajeCargaEliminada && (
              <div
                className="container col-12"
                style={{
                  background: "#FEF9F0",
                  marginTop: 30,
                  height: 360,
                  paddingTop: 135,
                }}
              >
                <div className="row">
                  <div className="col-12 text-center">
                    <img
                      src={checkVerde}
                      width="50"
                      height="50"
                      className="img-fluid mx-auto mb-2"
                      id="imgCheckAgregarEjecutivo"
                      alt=""
                    ></img>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 text-center" style={{ fontSize: 22 }}>
                    <span className="text-primary font-weight-bold">
                      Carga Eliminada
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </ModalEditarAsegurable>

        <div>
          {(!this.state.sesionEjecutivo && !this.state.blnSesionCorredor) && (
            <div className="row">
              <div className="col-md-7 mb-3 text-muted">
                <h4 className="font-weight-bold">
                  Visualizador de Asegurables
                </h4>
                <p>Asegurables en proceso de enrolamiento</p>
              </div>
              <div className="col-md-5 mb-3 align-self-center">
                {escogerIncorporacion && <EjecutarSeleccionIncorporacion />}
              </div>
            </div>
          )}
          {this.state.cargando === true ? null : (
            <div className="row animated fadeIn">
              <div className="col-12 px-5 pb-5">
                <div className="row">
                  {/* Filtro */}
                  <div className="col-sm-8">
                    <FiltrosAsegurables
                      dataAsegurado={this.state.asegurables}
                      estadosEnrolamiento={ESTADOS_ENROLAMIENTO}
                      handleSearch={this.handleSearch}
                    ></FiltrosAsegurables>
                  </div>
                  <div className="col-sm-4">
                    {this.state.sesionEjecutivo || this.state.blnSesionCorredor ? (
                      <span
                        className="font-weight-bold mt-3 ml-3 puntero-general float-right"
                        style={{ color: "#e89b03" }}
                        onClick={() => {
                          this.props.setSeccionEnrolamiento(SECCION_INGRESAR_ASEGURABLES);
                        }}
                        disabled={
                          (!this.state.existeDocumentoPreliminar && !this.state.blnSesionCorredor) ||
                          this.props.store.reducer.negocio.fechaFinEnrolamiento ||
                          (numeroDotacion === numeroAsegurables)
                        }
                      >
                        <i
                          className="fa fa-plus-circle fa-lg mr-1"
                          aria-hidden="true"
                        ></i>{" "}
                        Agregar Asegurables
                      </span>
                    ) : (
                      <button
                        id="iniciarEnrolamiento"
                        disabled={
                          this.props.store.reducer.negocio
                            .fechaInicioEnrolamiento
                        }
                        title="Disponible previamente al inicio de enrolamiento"
                        className="btn btn-principal d-block float-right my-3"
                        onClick={() =>
                          this.mostrarModalInicioEnrolamiento(true)
                        }
                      >
                        <i className="fa fa-play mr-2" aria-hidden="true"></i>
                        Iniciar Enrolamiento
                      </button>
                    )}
                    {this.state.blnSesionCorredor &&
                      <button
                        id="iniciarEnrolamientoCorredor"
                        disabled={
                          this.props.store.reducer.negocio
                            .fechaInicioEnrolamiento
                        }
                        title="Disponible previamente al inicio de enrolamiento"
                        className="btn btn-principal d-block float-right my-3"
                        onClick={() =>
                          this.mostrarModalInicioEnrolamiento(true)
                        }
                      >
                        <i className="fa fa-play mr-2" aria-hidden="true"></i>
                        Iniciar Enrolamiento
                      </button>
                    }
                  </div>
                </div>

                <hr className="mt-0 pt-0" />

                {/* INDICADORES PRODUCTOS ASEGURADO */}
                {this.props.store.reducer.negocio.canalVenta.idCanalVenta >
                  VALOR_UNO && <IndicadoresProductosAsegurable />}

                {/* TABLA ASEGURADOS */}
                <div className="shadow">
                  <Table
                    datos={this.state.asegurablesBusqueda}
                    columnas={this.state.columnDefs}
                    tema={this.state.temaTabla}
                    campoOrden={"apellidos"}
                    paginacion={true}
                    paginacionPorPagina={10}
                    paginacionOpciones={{
                      rowsPerPageText: "Asegurables por página:",
                      rangeSeparatorText: "de",
                      noRowsPerPage: true,
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state,
});

export default withRouter(
  connect(mapStateToProps, {
    guardar_asegurados_delegado,
    actualizar_asegurables,
    guardar_cotizacion,
  })(MisProcesos)
);
