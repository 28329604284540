import {
  STRING_VACIO,
  VALOR_UNO,
  VALOR_ZERO,
  CHAR_Y,
  COMMA,
  VALOR_NEGATIVO,
  PERFIL_CORREDOR,
  PERFIL_DELEGADO_MANTENCION,
  ARROBA,
  POSICION_INICIAL,
  REGEX_OFUSCAR_CORREO,
  POSICION_UNO,
  ASTERISCO,
  CARACTERES_VISIBLES_CORREO,
  CARACTERES_VISIBLES_TELEFONO,
  COD_ERROR_PETICION_SERVIDOR,
  COD_STATUS_ERROR,
  COD_STATUS_NA,
  NOTIFICACION_TIPO_ERROR,
  ERROR_SOLICITUD,
  ERROR_PETICION_SERVIDOR,
  SIGNO_GUION,
  EXP_SALTO_LINEAS,
  EXP_SOLO_NUMERO,
  ESPACIO_VACIO,
  EXP_CARACTERES_ESPECIALES,
  EXP_ESPACIOS,
  EXP_CARACTERES_ESPECIALES_AMPERSAND,
  EXP_CARACTERES_ESPECIALES_DIAGNOSTICO,
} from "./Constantes";

const { validate, clean, format } = require("rut.js");

export function showElementsMenu() {
  setTimeout(() => {
    let submenuElements = document.querySelectorAll(
      "#sidebar-menu ul li.submenu ul"
    )[0];
    if (submenuElements) submenuElements.classList.add("show");
  }, 1);
}

export function typeWriter(txt, speed, element, i) {
  if (i < txt.length) {
    element.innerHTML += txt.charAt(i);
    i++;
    setTimeout(() => {
      typeWriter(txt, speed, element, i++);
    }, speed);
  }
}
export function convertTimeToDate(time) {
  let date = new Date(time);
  let day =
    date.getDate().toString().length === 1
      ? "0" + date.getDate().toString()
      : date.getDate().toString();
  let month =
    (date.getMonth() + 1).toString() === 1
      ? "0" + (date.getMonth() + 1).toString()
      : (date.getMonth() + 1).toString();
  let year = date.getFullYear();
  return `${year}-${month}-${day}`;
}

export function formattedDate(dateUnix) {
  const date = new Date(dateUnix);
  const day = date.getDate() < 10 ? "0".concat(date.getDate()) : date.getDate();
  const month =
    date.getMonth() + 1 < 10
      ? "0".concat(date.getMonth() + 1)
      : date.getMonth() + 1;
  return `${date.getFullYear()}-${month}-${day + 1}`;
}

export function checkRut(rut) {
  // Despejar Puntos
  let valor = rut.replace(".", "").replace(".", "").replace("-", "");

  // Aislar Cuerpo y Dígito Verificador
  let cuerpo = valor.slice(0, -1);
  let dv = valor.slice(-1).toUpperCase();

  // Formatear RUN
  rut = cuerpo.concat("-").concat(dv);

  // Si no cumple con el mínimo ej. (n.nnn.nnn)
  if (cuerpo.length < 7) {
    return false;
  }

  // Verifica el digito verificador
  if (dv.toString() !== GetDigVer(cuerpo).toString()) {
    return false;
  } else {
    return true;
  }
}

function GetDigVer(cuerpoRut) {
  let suma = 0,
    dig = 0,
    digver = 0,
    multiplo = 2,
    largo = cuerpoRut.length;

  while (largo !== 0) {
    dig = cuerpoRut.substr(largo - 1, 1);
    largo = largo - 1;
    suma = suma + dig * multiplo;
    multiplo = multiplo + 1;
    if (multiplo > 7) {
      multiplo = 2;
    }
  }

  let resto = suma - Math.floor(suma / 11) * 11;
  let fin = 11 - resto;

  if (fin === 10) {
    digver = "K";
  } else {
    if (fin === 11) {
      digver = 0;
    } else {
      digver = fin;
    }
  }

  return digver;
}

export function decodeFormParams(params) {
  var pairs = params.substring(1).split("&"),
    result = {};

  for (var i = 0; i < pairs.length; i++) {
    var pair = pairs[i].split("="),
      key = decodeURIComponent(pair[0]),
      value = decodeURIComponent(pair[1]),
      isArray = /\[\]$/.test(key),
      dictMatch = key.match(/^(.+)\[([^\]]+)\]$/);

    if (dictMatch) {
      key = dictMatch[1];
      var subkey = dictMatch[2];

      result[key] = result[key] || {};
      result[key][subkey] = value;
    } else if (isArray) {
      key = key.substring(0, key.length - 2);
      result[key] = result[key] || [];
      result[key].push(value);
    } else {
      result[key] = value;
    }
  }

  return result;
}

export function soloRut(value) {
  return (value = value.replace(/[^0-9kK]/g, ""));
}

export function formatearRut(value) {
  return (value = format(value));
}

export function validarRutEspeciales(rut) {
  let rutValido = true;
  rut = clean(rut);

  switch (rut) {
    case "111111111":
      rutValido = false;
      break;
    case "11111111":
      rutValido = false;
      break;
    case "222222222":
      rutValido = false;
      break;
    case "22222222":
      rutValido = false;
      break;
    case "333333333":
      rutValido = false;
      break;
    case "33333333":
      rutValido = false;
      break;
    case "444444444":
      rutValido = false;
      break;
    case "44444444":
      rutValido = false;
      break;
    case "555555555":
      rutValido = false;
      break;
    case "55555555":
      rutValido = false;
      break;
    case "666666666":
      rutValido = false;
      break;
    case "66666666":
      rutValido = false;
      break;
    case "777777777":
      rutValido = false;
      break;
    case "77777777":
      rutValido = false;
      break;
    case "888888888":
      rutValido = false;
      break;
    case "88888888":
      rutValido = false;
      break;
    case "999999999":
      rutValido = false;
      break;
    case "99999999":
      rutValido = false;
      break;
    case "000000000":
      rutValido = false;
      break;
    case "00000000":
      rutValido = false;
      break;
    default:
      rutValido = true;
      break;
  }

  if (rutValido && validate(rut) && rut.length >= 6) return true;
  else return false;
}

export function validator(inputElements, inputGroup = false) {
  let focus = false;

  for (let i = 0; i < inputElements.length; i++) {
    if (inputElements[i].parentNode.parentNode.childNodes.length > 2) {
      inputElements[i].parentNode.parentNode.removeChild(
        inputElements[i].parentNode.parentNode.childNodes[2]
      );
    }

    if (inputElements[i].parentNode.childNodes.length > 2) {
      inputElements[i].parentNode.removeChild(
        inputElements[i].parentNode.childNodes[2]
      );
    }

    if (
      inputElements[i].parentNode.childNodes.length > 1 &&
      inputElements[i].parentNode.className === "DayPickerInput"
    ) {
      inputElements[i].parentNode.removeChild(
        inputElements[i].parentNode.childNodes[1]
      );
    }
  }
  if (inputGroup) return;

  inputElements.forEach((element) => {
    let error_message = document.createElement("small");
    error_message.classList.add("form-text");
    error_message.classList.add("text-danger");

    if (element.value.trim() === "") {
      error_message.innerText =
        element.dataset.error !== undefined
          ? element.dataset.error
          : element.validationMessage;

      inputGroup
        ? element.parentNode.parentNode.appendChild(error_message)
        : element.parentNode.appendChild(error_message);
      if (!focus && element.required) {
        element.focus();
        focus = true;
      }
    } else if (element.value.trim() === "0" && element.tagName === "SELECT") {
      error_message.innerText = "Selecciona un elemento de la lista";
      inputGroup
        ? element.parentNode.parentNode.appendChild(error_message)
        : element.parentNode.appendChild(error_message);
      if (!focus) {
        element.focus();
        focus = true;
      }
    } else {
      if (!element.validity.valid) {
        error_message.innerText = element.validationMessage;
        inputGroup
          ? element.parentNode.parentNode.appendChild(error_message)
          : element.parentNode.appendChild(error_message);
        if (!focus) {
          element.focus();
          focus = true;
        }
      } else {
        if (element.dataset.validator !== undefined) {
          switch (element.dataset.validator) {
            case "rut":
              if (!validarRutEspeciales(element.value)) {
                error_message.innerText =
                  "El RUT que ud ha ingresado es invalido";
                inputGroup
                  ? element.parentNode.parentNode.appendChild(error_message)
                  : element.parentNode.appendChild(error_message);
                if (!focus) {
                  element.focus();
                  focus = true;
                }
              }
              break;
            case "numeros":
              if (/[^0-9]/.test(element.value)) {
                error_message.innerText = "Este campo es númerico";
                inputGroup
                  ? element.parentNode.parentNode.appendChild(error_message)
                  : element.parentNode.appendChild(error_message);
                if (!focus) {
                  element.focus();
                  focus = true;
                }
              }
              break;
            case "alfabetico":
              if (/[^a-zA-Z ]/.test(element.value)) {
                error_message.innerText =
                  "Este campo no acepta números ni carácteres especiales";
                inputGroup
                  ? element.parentNode.parentNode.appendChild(error_message)
                  : element.parentNode.appendChild(error_message);
                if (!focus) {
                  element.focus();
                  focus = true;
                }
              }
              break;
            default:
              break;
          }
        }
      }
    }
  });

  return focus;
}

export function detect() {
  var check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
}

/*************************
 * * * VALIDACIONES      *
 *************************/
export function formatTel(value) {
  if (!value || value === "+56" || value === "+56 ") return "";
  value = value.replace(/\+56/g, "");
  value = value.replace(/[^0-9]/g, "");
  value = `+56 ${value}`;
  return value;
}

export function formatNumberDecimal(value) {
  if (typeof value === "number") return value;

  value = value.replace(/^[^0-9.]*([0-9]*)(?:[^0-9]*(\.))?|[^0-9%]/g, "$1$2");

  if (!value || value === " ") return "";
  if (isNaN(value)) return 1;

  let regex = /(\d*.\d{0,4})/;
  value = value.match(regex)[0];

  return value;
}

export function formatNumberDecimalUF(value, constValue) {
  if (typeof value === "number") return value;

  if (value.length > constValue && value.indexOf(".") === VALOR_NEGATIVO) {
    let posicionFinal = value.length;
    let ultimoCaracter = /[a-z]/.test(value.slice(posicionFinal - VALOR_UNO))
      ? value.slice(posicionFinal - VALOR_UNO).toUpperCase()
      : value.slice(posicionFinal - VALOR_UNO);
    value = [
      value.slice(VALOR_ZERO, posicionFinal - VALOR_UNO),
      ".",
      ultimoCaracter,
    ].join("");
  }

  value = value.replace(/^[^0-9.]*([0-9]*)(?:[^0-9]*(\.))?|[^0-9%]/g, "$1$2");

  if (!value || value === " ") return "";
  if (isNaN(value)) return 1;

  let regex = /(\d*.\d{0,4})/;
  value = value.match(regex)[0];

  return value;
}

export function formatNumber(value) {
  if (!value) return "";
  if (typeof value === "number") return value;

  value = value.replace(/[^0-9]/g, "");
  return value;
}

export function formatMilesInput(value) {
  if (!value) return "";
  value = String(value);

  return (
    "$ " + value.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".")
  );
}

export function soloLetras(value) {
  if (value !== undefined) {
    const valueReplaced = value
      .replace(EXP_CARACTERES_ESPECIALES, STRING_VACIO)
      .replace(EXP_ESPACIOS, ESPACIO_VACIO)
      .replace(EXP_SOLO_NUMERO, STRING_VACIO);
    return valueReplaced;
  }
}

export function letrasYAmpersand(value) {
  if (value !== undefined) {
    const valueReplaced = value
      .replace(EXP_CARACTERES_ESPECIALES_AMPERSAND, STRING_VACIO)
      .replace(EXP_ESPACIOS, ESPACIO_VACIO)
      .replace(EXP_SOLO_NUMERO, STRING_VACIO);

    return valueReplaced;
  }
}

export function soloLetrasYNumeros(value) {
  return (value = value
    .replace(EXP_CARACTERES_ESPECIALES, STRING_VACIO)
    .replace(EXP_ESPACIOS, ESPACIO_VACIO)
    .replace(EXP_SALTO_LINEAS, STRING_VACIO));
}

export function formatDateInput(value) {
  value = value.replace(/[^0-9]/g, "");

  if (value.length >= 2 && value.length < 5) {
    value = value.substring(0, 2) + value.substring(2, value.length);
    if (value.length === 2) value = value + "012020";
    if (value.length === 3) value = value + "02020";
    if (value.length === 4) value = value + "2020";
  }

  if (value.length >= 5) {
    if (value.length === 5) value = value + "020";
    if (value.length === 6) value = value + "20";
    if (value.length === 7) value = value + "20";
    value =
      value.substring(0, 2) +
      "/" +
      value.substring(2, 4) +
      "/" +
      value.substring(4, 8);
  }

  let day = value.substring(0, 2);
  let month = value.substring(3, 5);
  let year = value.substring(6, 10);

  if (Number(day) > 31) day = "01";
  if (Number(month) > 12) month = "01";
  if (Number(year) < 1890 || Number(year) > 2020) year = "2020";

  return `${day}/${month}/${year}`;
}

export function roundToTwo(value) {
  return +(Math.round(value + "e+2") + "e-2");
}

export function capitalize(s) {
  return s.toLowerCase().replace(/\b./g, function (a) {
    return a.toUpperCase();
  });
}

export const arrayToFormattedString = (array) => {
  let arrayToString = STRING_VACIO;

  for (let index in array) {
    if (Number(index) === VALOR_ZERO) {
      arrayToString = arrayToString + array[index];
    } else if (Number(index) === array.length - VALOR_UNO) {
      arrayToString = arrayToString + CHAR_Y + array[index];
    } else {
      arrayToString = arrayToString + COMMA + array[index];
    }
  }
  return arrayToString;
};

export const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const obtenerUrlBase64 = (base64, contentType) => {
  const index = base64.indexOf(",");
  const archivoBase64 = base64.slice(index + 1, base64.length);

  const blob = b64toBlob(archivoBase64, contentType);
  return URL.createObjectURL(blob);
};

//recibe un string con el nombre del archivo y un string con los datos en formato base64, descargándolo inmediatamente
export const descargarExcelBase64 = (nombreArchivo, dataBase64) => {
  let a = document.createElement("a"); //Crea <a>
  a.href =
    "data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64," +
    dataBase64;
  a.download = nombreArchivo + ".xls"; //nombre del archivo más la extensión
  a.click(); //descarga el archivo de inmediato
};

export const verificarSiExisteObjetoEnArreglo = (
  arregloObjetos,
  parametros
) => {
  let objetos = [...arregloObjetos];
  for (const param of parametros) {
    objetos = objetos.filter(
      (objeto) => objeto[param.nombrePropiedad] === param.valorPropiedad
    );
  }

  return objetos.length ? objetos[0] : undefined;
};

export const REGEX_SOLO_LETRAS = /^[A-Za-zñÑáéíóúÁÉÍÓÚ ]*$/g;

export const obtenerIdOrganizacion = (negocios, filtroSolicitudes, perfil) => {
  if (!filtroSolicitudes) return 0;
  else if (
    perfil === PERFIL_CORREDOR ||
    perfil === PERFIL_DELEGADO_MANTENCION
  ) {
    const rutContratante = Number(
      filtroSolicitudes.rutContratante.split("-")[0]
    );
    return negocios.find((negocio) => negocio.rutEmpresa === rutContratante)
      ? negocios.find((negocio) => negocio.rutEmpresa === rutContratante)
          .idOrganizacion
      : 0;
  } else {
    return negocios.find(
      (negocio) => negocio.rut === filtroSolicitudes.rutContratante
    )
      ? negocios.find(
          (negocio) => negocio.rut === filtroSolicitudes.rutContratante
        ).idOrganizacion
      : 0;
  }
};

export const ocultarCorreoElectronico = (strCorreo) => {
  let strCorreoOfuscado = strCorreo.replace(
    REGEX_OFUSCAR_CORREO,
    (strCorreo) =>
      strCorreo.substring(POSICION_INICIAL, CARACTERES_VISIBLES_CORREO) +
      strCorreo
        .split(STRING_VACIO)
        .slice(CARACTERES_VISIBLES_CORREO, -POSICION_UNO)
        .map((v) => ASTERISCO)
        .join("") +
      ARROBA
  );
  return strCorreoOfuscado;
};

export const ocultarCorreoTelefono = (strTelefono) => {
  let strOfuscada = strTelefono
    .split(STRING_VACIO)
    .slice(POSICION_INICIAL, -CARACTERES_VISIBLES_TELEFONO)
    .map((caracter) => ASTERISCO)
    .join("");
  let strVisible = strTelefono.substring(
    strTelefono.length - CARACTERES_VISIBLES_TELEFONO
  );

  return strOfuscada + strVisible;
};

export const validarRespuestaPeticion = (objRespuestaPeticion, blnMensaje) => {
  let objRespuesta = null;

  if (!objRespuestaPeticion)
    return (objRespuesta = {
      tipoNotificacion: NOTIFICACION_TIPO_ERROR,
      error: ERROR_SOLICITUD,
    });

  if (objRespuestaPeticion === COD_ERROR_PETICION_SERVIDOR)
    return (objRespuesta = {
      tipoNotificacion: NOTIFICACION_TIPO_ERROR,
      error: ERROR_SOLICITUD,
    });

  if (
    objRespuestaPeticion.status &&
    objRespuestaPeticion.status.codigo === COD_STATUS_ERROR
  )
    return (objRespuesta = {
      tipoNotificacion: NOTIFICACION_TIPO_ERROR,
      error: ERROR_PETICION_SERVIDOR,
    });

  if (
    objRespuestaPeticion.status &&
    objRespuestaPeticion.status.codigo === COD_STATUS_NA &&
    blnMensaje
  )
    return (objRespuesta = {
      tipoNotificacion: NOTIFICACION_TIPO_ERROR,
      error: objRespuestaPeticion.status.mensaje,
    });

  if (
    objRespuestaPeticion.status &&
    objRespuestaPeticion.status.codigo === COD_STATUS_NA &&
    !blnMensaje
  )
    return (objRespuesta = {
      tipoNotificacion: NOTIFICACION_TIPO_ERROR,
      error: objRespuestaPeticion.objeto,
    });

  return objRespuesta;
};

export const extraerRutyDigitoVerificador = (strRutComppleto) => {
  let objRutSeparado = format(clean(strRutComppleto)).split(SIGNO_GUION);
  objRutSeparado[POSICION_INICIAL] = Number(
    clean(objRutSeparado[POSICION_INICIAL])
  );
  return objRutSeparado;
};

export const extraerDeclaracionesRepetidas = (lstDeclaraciones, objToId) => {
  let lstIds = lstDeclaraciones.map(objToId);

  let lstDeclaracionesFiltradas = lstDeclaraciones.filter(
    (objDeclaracion, intIndice) =>
      !lstIds.includes(objToId(objDeclaracion), intIndice + POSICION_UNO)
  );

  return lstDeclaracionesFiltradas;
};

export const procesarDeclaSaludAId = ({
  idPreguntaSeccionCobertura,
  nombre,
  actividadGlosa,
  fechaDiagnostico,
}) =>
  `${idPreguntaSeccionCobertura}-${nombre}-${actividadGlosa}-${fechaDiagnostico}`;

export const procesarDeclaEspecialAId = ({
  idPreguntaSeccionCobertura,
  nombre,
  causa,
  fechaDiagnostico,
}) => `${idPreguntaSeccionCobertura}-${nombre}-${causa}-${fechaDiagnostico}`;

export const procesarDeclaDeportesAId = ({
  idPreguntaSeccionCobertura,
  nombre,
  actividadGlosa,
  observacion,
}) =>
  `${idPreguntaSeccionCobertura}-${nombre}-${actividadGlosa}-${observacion}`;

export function validacionDireccion(direccion) {
  if (direccion !== undefined) {
    const direccionValidada = direccion
      .replace(EXP_CARACTERES_ESPECIALES_AMPERSAND, STRING_VACIO)
      .replace(EXP_ESPACIOS, ESPACIO_VACIO)
      .replace(EXP_SALTO_LINEAS, STRING_VACIO);

    return direccionValidada;
  }
}

export function validacionNumeroDireccion(numeroDireccion) {
  if (numeroDireccion !== undefined) {
    const numeroValidado = numeroDireccion
      .replace(EXP_CARACTERES_ESPECIALES, STRING_VACIO)
      .replace(EXP_ESPACIOS, ESPACIO_VACIO)
      .replace(EXP_SALTO_LINEAS, STRING_VACIO);

    return numeroValidado;
  }
}

export function soloLetrasNumerosYAmpersand(value) {
  if (value !== undefined) {
    const valueReplaced = value
      .replace(EXP_CARACTERES_ESPECIALES_AMPERSAND, STRING_VACIO)
      .replace(EXP_ESPACIOS, ESPACIO_VACIO)
      .replace(EXP_SALTO_LINEAS, STRING_VACIO);
    return valueReplaced;
  }
}

export function validacionDiagnostico(value) {
  if (value !== undefined) {
    const valueReplaced = value
      .replace(EXP_CARACTERES_ESPECIALES_DIAGNOSTICO, STRING_VACIO)
      .replace(EXP_ESPACIOS, ESPACIO_VACIO)
      .replace(EXP_SALTO_LINEAS, STRING_VACIO);
    return valueReplaced;
  }
}
