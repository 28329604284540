import CryptoJS from "react-native-crypto-js";

export const ejecutarEncriptacion = (objData) => {

    let strData = JSON.stringify(objData);
    let strDataEncriptada = CryptoJS.AES.encrypt(strData,process.env.REACT_APP_HAS_CRYPT).toString();

    return strDataEncriptada;
}

export const ejecutarDesencriptacion = (strData) => {

    let objData;
    let objDesencriptado = CryptoJS.AES.decrypt(JSON.parse(strData), process.env.REACT_APP_HAS_CRYPT);
    objData              = JSON.parse(objDesencriptado.toString(CryptoJS.enc.Utf8));
    return objData;
}