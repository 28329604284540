import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Table from "../../../transversales/DataTable";
import {errorBuilder} from "../../../../../utils/ErrorBuilder";
import * as moment from "moment";
import {loadingAction} from "../../../../../reducer/delegadoMantencion";
import { notificacion } from "../../../transversales/Notificaciones";
import { MENSAJE_REGLA_NO_APLICADA } from "../../../../../utils/Constantes";


const Exclusion = (props) => {


        const temaTabla = {
            title: {
                backgroundColor: "rgb(255, 255, 255)",
                height: "0px",
            },
            header: {
                fontSize: "14px",
                backgroundColor: "rgb(255, 255, 255)",
                fontColor: "black",
                fontWeight: "600",
            },
            rows: {
                backgroundColor: "white",
                borderWidth: "0px",
            },
        };

        const columnDefs = [
            {
                name: "Poliza",
                selector: "poliza",
            },
            {
                name: "RUT Titular",
                selector: "rutTitular",
            },
            {
                name: "RUT asegurado",
                selector: "rutAsegurado",
            },
            {
                name: "Nombre completo",
                selector: "nombreCompleto",
                wrap: true

            },
            {
                name: "Fecha fin vigencia",
                selector: "fechaFinVigencia",
            },
            {
                name: "Motivo exclusión",
                selector: "motivoExclusion",
                wrap:true,
            },
            {
                name: "Estado de solicitud",
                selector: "estadoSolicitud",
            },
            {
                minWidth: "150px",
                cell: (row) => (
                    <React.Fragment>
                        <button
                            disabled={true}
                            className="btn btn-danger btn-sm btn-table mr-2"
                            type="button"
                            title="Eliminar"
                            raised="true"
                            primary="true">
                        <i className="fa fa-trash" aria-hidden="true"></i>
                        </button>
                        <button
                            className="btn btn-primary btn-sm btn-table mr-2"
                            type="button"
                            title="Editar"
                            raised="true"
                            primary="true"
                            disabled={true}
                        >
                            <i className="fa fa-pencil" aria-hidden="true"></i>
                        </button>
                    </React.Fragment>
                ),
                ignoreRowClick: true,
                allowOverflow: true,
                button: true,
            },
        ];


        const [listaExclusion, setListaExclusion] = useState([]);

        const mapearFilas = solicitudes =>{
            const filas =  solicitudes.map(solicitud =>({
                poliza: solicitud.aseguradoPoliza.poliza.nroPoliza  +  "-" +  solicitud.aseguradoPoliza.poliza.nroRenovacion,
                rutTitular: solicitud.aseguradoPoliza.rut + "-" + solicitud.aseguradoPoliza.digitoVerificador,
                nombreCompleto: [solicitud.aseguradoPoliza.nombre, solicitud.aseguradoPoliza.apellidoMaterno, solicitud.aseguradoPoliza.apellidoPaterno].join(" "),
                fechaFinVigencia: moment(solicitud.aseguradoPoliza.fechaFinVigencia).format('DD/MM/YYYY'),
                motivoExclusion: solicitud.aseguradoPoliza.motivo.glosa,
                estadoSolicitud: solicitud.solicitudEstado.glosa,
                rutAsegurado: solicitud.aseguradoPoliza.rutAsegurado,
            }))
            return filas;
        }


       useEffect(() =>{

            const objRespuesta = errorBuilder(props.data);
            if(!objRespuesta.error){
                setListaExclusion(mapearFilas(objRespuesta.data));
            }else{
                if(!objRespuesta.data.includes(MENSAJE_REGLA_NO_APLICADA)){
                    notificacion("danger", objRespuesta.data)
                }
            }
        }, [props.data])

        return (
            <div className="col-12">
                <div className="row animated fadeIn">
                    <div className="col-12 px-5 pb-5">
                            <div className="shadow">
                                <Table
                                    datos={listaExclusion}
                                    columnas={columnDefs}
                                    tema={temaTabla}
                                    paginacion={true}
                                    paginacionPorPagina={10}
                                    paginacionOpciones={{
                                        rowsPerPageText: "Solicitudes por página:",
                                        rangeSeparatorText: "de",
                                        noRowsPerPage: true,
                                    }}
                                />
                            </div>
                    </div>
                </div>
            </div>
        );
}

const mapStateToProps = (state) => ({
    store: state,
});

export default withRouter(connect(mapStateToProps, {loadingAction})(Exclusion));
