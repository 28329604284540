import ServicesFactory from "../config/servicesFactory";
import { COD_STATUS_EXITO, STATUS_EXITO, EXITO_AGREGAR_PERFIL_EJECUTIVO } from "../utils/Constantes";

let services = new ServicesFactory();

export async function obtenerEjecutivosJefaturaPorRut(rut) {
    try {
        let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/ejecutivo/buscarPorRut/${rut}`;
        let res = await services.get(url, false);

        return res !== undefined && res !== null && res.data.status.codigo === COD_STATUS_EXITO
            ? res.data.objeto
            : null;
    } catch (error) {
        return null;
    }
}

export async function agregarEjecutivoAJefatura(data) {
    try {
        let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/ejecutivo/agregarJefatura`;
        let res = await services.post(url, data, false);
        return res !== undefined &&
            res !== null &&
            res.status === STATUS_EXITO &&
            res.data.status.codigo === COD_STATUS_EXITO
            ? res.data.objeto
            : null;
    } catch (error) {
        return null;
    }
}

export async function agregarPerfilEjecutivo(data) {
    try {
        let url = `${process.env.REACT_APP_API_GATEWAY_SERVPERFIL}/perfil/agregar`;
        let res = await services.post(url, data, false);
        return res !== undefined &&
            res !== null &&
            res.status === STATUS_EXITO &&
            res.data.status.mensaje === EXITO_AGREGAR_PERFIL_EJECUTIVO
            ? res.data.objeto
            : null;
    } catch (error) {
        return null;
    }
}