import { format, validate } from "rut.js";
import { notificacion } from "../app/components/transversales/Notificaciones";
import { CLAVE_ACCION_ACTUALIZAR, COD_ERROR_PETICION_SERVIDOR, COD_STATUS_EXITO, COD_VIA_CONTACTO_CORREO, ERROR_DIGITO_VERIFICADOR, FALSO, NOTIFICACION_TIPO_ERROR, POSICION_INICIAL, ROL_ASEGURABLE, ROL_DELEGADO, TECLA_ENTER_CODIGO, VALIDACION_RECUPERAR_CLAVE, VALOR_UNO, VERDADERO } from "../utils/Constantes";
import { buscarPuertaEnlaceToken } from "./auth.services";
import { ocultarCorreoElectronico, ocultarCorreoTelefono, validarRespuestaPeticion } from "../utils/Functions";
import { listarContactoAsegurable } from "./asegurable.services";
import ServicesFactory from "../config/servicesFactory";

let objServicio = new ServicesFactory();

export const iniciarSolicitudClave = async (objPeticion, strToken) => {
  try {
    let objRespuesta = await objServicio.post(
      `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/auth/solicitarClave`,
      objPeticion,
      FALSO,
      strToken
    );
    return objRespuesta ? objRespuesta.data.objeto : COD_ERROR_PETICION_SERVIDOR;
  } catch (objError) {
    return COD_ERROR_PETICION_SERVIDOR;
  }
}

export const iniciarSolicitudClaveSinWhatsApp = async (objPeticion, strToken) => {
  try {
    let objRespuesta = await objServicio.post(
      `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/auth/solicitarClave`,
      objPeticion,
      FALSO,
      strToken
    );
    return objRespuesta ? objRespuesta.data.objeto : COD_ERROR_PETICION_SERVIDOR;
  } catch (objError) {
    return COD_ERROR_PETICION_SERVIDOR;
  }
}

export const procesarClaveAsegurado = async (objClave, objEstado) => {

  let objCambioClave = null;
  let objValidacion  = null;

  if (objEstado.accion === CLAVE_ACCION_ACTUALIZAR) {

    const objPeticion  = {
      claveActual: objClave.actual,
      claveNueva: objClave.nueva,
      rut: objEstado.rutUsuario,
      usuCodigo: objEstado.idAsegurable,
    };
    objCambioClave = await actualizarClaveAsegurado(objPeticion);
    objValidacion  = validarRespuestaPeticion(objCambioClave, VERDADERO);
  }
  else{
    const objPeticion = {
      codigoCambioClave: objEstado.contrasena.actual,
      clave: objClave.nueva,
      rut: objEstado.rutUsuario,
    };
    objCambioClave = await crearClaveAsegurado(objPeticion);
    objValidacion  = validarRespuestaPeticion(objCambioClave, FALSO);
  }

  return objValidacion;
}

export const procesarClaveDelegado = async (objClave, objEstado) => {

  let objCambioClave = null;
  let objValidacion  = null;

  if (objEstado.accion === CLAVE_ACCION_ACTUALIZAR) {
    const objPeticion  = {
      claveActual: objClave.actual,
      claveNueva: objClave.nueva,
      rut: objEstado.rutUsuario,
      usuCodigo: objEstado.usuCodigo,
    };
    objCambioClave = await actualizarClaveDelegado(objPeticion);
    objValidacion  = validarRespuestaPeticion(objCambioClave, VERDADERO);
  }
  else{
    const objPeticion = {
      codigoCambioClave: objEstado.contrasena.actual,
      clave: objClave.nueva,
      rut: objEstado.rutUsuario,
    };
    
    objCambioClave = await crearClaveDelegado(objPeticion);
    objValidacion  = validarRespuestaPeticion(objCambioClave, FALSO);
  }
  
  return objValidacion;
}

export const listarEmailRecuperarClave = async (objPeticion, strTokenSesion) => {
  try {
    let objRespuesta = await objServicio.post(
      `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/auth/listarEmailRecuperarClave`,
      objPeticion,
      FALSO,
      strTokenSesion
    );
    return objRespuesta && objRespuesta.data.status.codigo === COD_STATUS_EXITO ? objRespuesta.data.objeto : objRespuesta.data;
  } catch (objError) {
    return COD_ERROR_PETICION_SERVIDOR;
  }
}

const actualizarClaveDelegado = async (objPeticion) => {
  
  try {
    let objRespuesta = await objServicio.put(
      `${process.env.REACT_APP_API_GATEWAY_BACKMOVIL_URL}/authEmpresa/actualizarClave`,
      objPeticion,
      FALSO
    );
    return objRespuesta ? objRespuesta.data : COD_ERROR_PETICION_SERVIDOR;
  } catch (objError) {
    return COD_ERROR_PETICION_SERVIDOR;
  }
}

const actualizarClaveAsegurado = async (objPeticion) => {
  try {
    let objRespuesta = await objServicio.put(
      `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/authAsegurable/actualizarClave`,
      objPeticion,
      FALSO
    );
    return objRespuesta ? objRespuesta.data : COD_ERROR_PETICION_SERVIDOR;
  } catch (objError) {
    return COD_ERROR_PETICION_SERVIDOR;
  }
}

const crearClaveAsegurado = async (objPeticion) => {
  try {
    let strTokenPuertaEnlace = await buscarPuertaEnlaceToken();
    let objRespuesta = await objServicio.post(
      `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/authAsegurable/crearClave`,
      objPeticion,
      FALSO,
      strTokenPuertaEnlace
    );
    return objRespuesta ? objRespuesta.data : COD_ERROR_PETICION_SERVIDOR;
  } catch (objError) {
    return COD_ERROR_PETICION_SERVIDOR;
  }
}

 const crearClaveDelegado = async (objPeticion) => {
  
  try {
    let strTokenPuertaEnlace = await buscarPuertaEnlaceToken();
    let objRespuesta = await objServicio.post(
      `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/auth/crearClave`,
      objPeticion,
      FALSO,
      strTokenPuertaEnlace
    );
    return objRespuesta ? objRespuesta.data : COD_ERROR_PETICION_SERVIDOR;
  } catch (objError) {
    return COD_ERROR_PETICION_SERVIDOR;
  }
}

export const validarCambioClaveAsegurable = async (strRut, strClaveTemporal, strTokenSesion) => {
  try {
    let objRespuesta = await objServicio.get(
      `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/authAsegurable/validarCodigoCambioClave/${strRut}/${strClaveTemporal}`,
      FALSO,
      null,
      strTokenSesion
    );
    return objRespuesta ? objRespuesta.data : COD_ERROR_PETICION_SERVIDOR;
  } catch (objError) {
    return COD_ERROR_PETICION_SERVIDOR;
  }
}

export const validarCambioClaveDelegado = async ( strRut, strClaveTemporal, strTokenSesion) => {
  try {
    let objRespuesta = await objServicio.get(
      `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/auth/validarCodigoCambioClave/${strRut}/${strClaveTemporal}`,
      FALSO,
      null,
      strTokenSesion
    );
    return objRespuesta ? objRespuesta.data : COD_ERROR_PETICION_SERVIDOR;
  } catch (objError) {
    return COD_ERROR_PETICION_SERVIDOR;
  }
}

const ejecutarSolicitudClave = async (objDatosPeticion, objToken, intViaContacto, objProceso) => {

  let objRespuesta  = await iniciarSolicitudClave(objDatosPeticion, objToken);
  let objValidacion = validarRespuestaPeticion(objRespuesta, VERDADERO);

  if (objValidacion) {
    objProceso.recaptcha.reset();
    notificacion(objValidacion.tipoNotificacion, objValidacion.error);
    return {
      enlaceEnviado: FALSO,
      correo: null,
      telefono: null,
      viaContacto: null
    };
  } 

  let strCorreoOfucado = objDatosPeticion.correoElectronico ? ocultarCorreoElectronico(objDatosPeticion.correoElectronico): null;
  let strTelfOfucado   = objDatosPeticion.telefonoMovil ? ocultarCorreoTelefono(objDatosPeticion.telefonoMovil): null;

  return {
    enlaceEnviado: VERDADERO,
    correo: strCorreoOfucado,
    telefono: strTelfOfucado,
    viaContacto: intViaContacto
  };
}

const procesarFormularioAsegurable = async (objProceso) => {

  let strRutUsuario = objProceso.estado.rutUsuario;
  strRutUsuario     = strRutUsuario.replace(/\./g,"").toUpperCase();
  const objToken    = await buscarPuertaEnlaceToken();
  let objContacto   = await listarContactoAsegurable(strRutUsuario, objToken);

  if (!objContacto) {

    notificacion(NOTIFICACION_TIPO_ERROR, VALIDACION_RECUPERAR_CLAVE);
    return {
      enlaceEnviado: FALSO,
      correo: null,
      telefono: null,
      viaContacto: null
    };
  }

  let objDatosPeticion = {
    "correoElectronico": objContacto.correoElectronico,
    "nombre": objContacto.nombre,
    "perfil": objProceso.tipoUsuario.perfil.toUpperCase(),
    "rut": strRutUsuario,
    "telefonoMovil": objContacto.telefonoMovil
  };

  return ejecutarSolicitudClave(objDatosPeticion, objToken, objContacto.viaContacto.idViaContacto, objProceso);
}

const procesarFormularioDelegado = async ( objProceso ) => {

  const objToken = await buscarPuertaEnlaceToken();

  if (objProceso.estado.correoDelegadoConfigurado) {
    let { datosPeticion, viaContacto } = objProceso.estado;
    return ejecutarSolicitudClave(datosPeticion, objToken, viaContacto, objProceso);
  }

  let strRutUsuario = objProceso.estado.rutUsuario;
  strRutUsuario     = strRutUsuario.replace(/\./g,"").toUpperCase();
  let objPeticion   = {"rut": strRutUsuario,"perfil": objProceso.tipoUsuario.perfil.toUpperCase() };
  let lstContacto   = await listarEmailRecuperarClave(objPeticion, objToken);
  let objValidacion = validarRespuestaPeticion(lstContacto, VERDADERO);
    
  if (objValidacion){ 
    notificacion(objValidacion.tipoNotificacion, objValidacion.error);
    return {
      enlaceEnviado: FALSO,
      correo: null,
      telefono: null,
      viaContacto: null
    };
  }

  if (!lstContacto || lstContacto.length < VALOR_UNO) {
    notificacion(NOTIFICACION_TIPO_ERROR, VALIDACION_RECUPERAR_CLAVE);
    return {
      enlaceEnviado: FALSO,
      correo: null,
      telefono: null,
      viaContacto: null
    };
  }

  if (lstContacto.length > VALOR_UNO) {
    let lstCorreo         = [...new Set(lstContacto.map(objContacto => objContacto.correoElectronico))];
    let strNombreDelegado = [...new Set(lstContacto.map(objContacto => objContacto.nombreDelegado))];

    if (lstCorreo.length > VALOR_UNO) {

      let objDatosPeticion = {
        "nombre": strNombreDelegado[POSICION_INICIAL],
        "perfil": objProceso.tipoUsuario.perfil.toUpperCase(),
        "rut": strRutUsuario,
        "telefonoMovil": null
      };

      return {
        escogerCorreo: VERDADERO, 
        cargandoRecuperarClave: FALSO,
        correos: lstCorreo,
        viaContacto: COD_VIA_CONTACTO_CORREO,
        datosPeticion: objDatosPeticion,
        habilitarEnvio: FALSO
      };
    }
  }
  
  if (lstContacto.length === VALOR_UNO) {

    let objContacto = Object.assign({}, ...lstContacto);

    let objDatosPeticion = {
      "correoElectronico": objContacto.correoElectronico,
      "nombre": objContacto.nombreDelegado,
      "perfil": objProceso.tipoUsuario.perfil.toUpperCase(),
      "rut": strRutUsuario,
      "telefonoMovil": null
    };

    return ejecutarSolicitudClave(objDatosPeticion, objToken, COD_VIA_CONTACTO_CORREO, objProceso);
  }
}

export const validarTeclaPrecionada = (objEvento) => {
  if ((objEvento.charCode || objEvento.keyCode) === TECLA_ENTER_CODIGO) {
    objEvento.preventDefault();
  }
}

export const validarFormulario = async (objProceso) => {

  objProceso.evento.preventDefault();
  let strRutUsuario = objProceso.estado.rutUsuario;
  strRutUsuario     = format(strRutUsuario.replace(/\./g,"").toUpperCase());
  let objRespuesta = {
    enlaceEnviado: FALSO,
    correo: null,
    telefono: null,
    viaContacto: null
  };

  if (!validate(strRutUsuario)){ 
    return notificacion(NOTIFICACION_TIPO_ERROR, ERROR_DIGITO_VERIFICADOR);
  }
  
  if (objProceso.tipoUsuario.perfil === ROL_ASEGURABLE) {
    objRespuesta = await procesarFormularioAsegurable(objProceso);
  }

  if (objProceso.tipoUsuario.perfil === ROL_DELEGADO) {
    objRespuesta = await procesarFormularioDelegado(objProceso);
  }
  
  return objRespuesta;
}