
  export function obtenerComunasDeUnaRegion(idRegion, ciudades, comunas) {

    let ciudadesFiltradas = ciudades.filter((c) => {
      return Number(idRegion) === Number(c.idRegion);
    });

    let comunasRegion = [];
    ciudadesFiltradas.forEach((c) => {
      comunas.forEach((co) => {
        if (Number(co.idCiudad) === Number(c.idCiudad)) {
          return comunasRegion.push(co);
        }
      });
    });

    // Eliminar repetidos
    let flag = {};
    comunasRegion = comunasRegion.filter((e) => {
      let exists = !flag[e.glosaComuna] || false;
      flag[e.glosaComuna] = true;
      return exists;
    });

    return comunasRegion;
  }

  export function obtenerCiudadDeUnaComuna(idComuna, comunas, ciudades) {
    if (!idComuna) return;
    let comuna = comunas.find((co) => {
      return Number(idComuna) === Number(co.idComuna);
    });

    let ciudad = ciudades.find((ci) => {
      return Number(comuna.idCiudad) === Number(ci.idCiudad);
    });

    return ciudad.idCiudad;
  }

  export function obtenerRegionDeUnaCiudad(idComuna, comunas, ciudades) {
    if (!idComuna) return;
    let comuna = comunas.find((co) => {
      return Number(idComuna) === Number(co.idComuna);
    });

    let ciudad = ciudades.find((ci) => {
      return Number(comuna.idCiudad) === Number(ci.idCiudad);
    });

    if (ciudad === undefined) return 0;

    return ciudad.idRegion;
  }