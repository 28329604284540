export const TIPO_USUARIO = "TIPO_USUARIO";
export const PERFILES_INICIO_SESION = "PERFILES_INICIO_SESION";
export const ESCOGER_PERFIL = "ESCOGER_PERFIL";
export const FORM_INICIO_SESION = "FORM_INICIO_SESION";
export const USUARIO_SESION = "USUARIO_SESION";
export const USUARIO_PERFILES = "USUARIO_PERFILES";
export const USUARIO_AUTENTICADO = "USUARIO_AUTENTICADO";
export const ESCOGER_INCORPORACION = "ESCOGER_INCORPORACION";
export const TIPO_INCORPORACION = "TIPO_INCORPORACION";
export const TIPO_INCORPORACION_PREVIO = "TIPO_INCORPORACION_PREVIO";
