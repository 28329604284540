import ServicesFactory from "../config/servicesFactory";
import {
  COD_STATUS_EXITO,
  STATUS_EXITO,
  TIPO_INCORPORACION_MANTENCION,
  TIPO_CANAL_VENTA_CORREDOR,
  ESTADO_NEGOCIO_5
} from "../utils/Constantes";

let services = new ServicesFactory();

/*************************
 *  DELEGADO             *
 *************************/
export async function buscarNegociosMantencionPoliza(usuario) {
  try {
    let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/poliza/listarPorRutDelegado/${usuario.rut}`;
    let respuesta = await services.get(url, false);

    return respuesta !== undefined &&
      respuesta !== null &&
      respuesta.status === STATUS_EXITO &&
      respuesta.data.status.codigo === COD_STATUS_EXITO
      ? homologarDataNegocioMantencion(respuesta.data.objeto, usuario)
      : [];
  } catch (error) {
    return null;
  }
}

export async function buscarNegociosCorredorPoliza(usuario, boolSoloVigentes) {
  try {
    let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/poliza/listarPorRutCorredor/${usuario.rut}/${boolSoloVigentes}`;
    let respuesta = await services.get(url, false);

    return respuesta !== undefined &&
      respuesta !== null &&
      respuesta.status === STATUS_EXITO &&
      respuesta.data.status.codigo === COD_STATUS_EXITO
      ? homologarDataNegocioMantencion(respuesta.data.objeto, usuario)
      : [];
  } catch (error) {
    return null;
  }
}

export async function buscarContratanteCorredor(usuario){
  try {
    let url = `${process.env.REACT_APP_BUS_URL_EMPRESA}/listarPorRutCorredor/${usuario.rut}`;
    let respuesta = await services.get(url, false);

    return respuesta !== undefined &&
      respuesta !== null &&
      respuesta.status === STATUS_EXITO &&
      respuesta.data.status.codigo === COD_STATUS_EXITO
      ? homologarDataNegocioMantencion(respuesta.data.objeto, usuario, true)
      : [];
  } catch (error) {
    return null;
  }
}

export async function crearCotizacionPoliza(dataEnvio, perfil) {
  try {
    let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/cotizacion/asignarPolizas/perfil/${perfil}`;
    let respuesta = await services.post(url, dataEnvio, false);

    return respuesta !== undefined &&
      respuesta !== null &&
      respuesta.data.status.codigo === "001"
      ? respuesta.data.objeto
      : null;
  } catch (error) {
    return null;
  }
}

export async function buscarPolizaPorRutContratante(dataEnvio) {
  try {
    let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/poliza/listarPorRutContratante`;
    let respuesta = await services.post(url, dataEnvio, false);
    
    return respuesta !== undefined &&
      respuesta !== null &&
      respuesta.data.status.codigo === "001"
      ? respuesta.data.objeto
      : null;
  } catch (error) {
    return null;
  }
}

export async function listarPolizaPorRutEmpresa(rutEmpresa) {
  try {
    let url = `${process.env.REACT_APP_BUS_URL_POLIZA}/listarPorRutEmpresa/${rutEmpresa}`;
    let respuesta = await services.get(url, false);
    return respuesta !== undefined &&
      respuesta !== null &&
      respuesta.data.status.codigo === "001"
      ? respuesta.data.objeto
      : null;
  } catch (error) {
    return null;
  }
}

/*************************
 *  HOMOLOGAR DATA       *
 *************************/

function homologarDataNegocioMantencion(negocios, usuario, corredor = false) {
  let negociosMantencion = [];
  if(corredor){
    negocios.forEach((e) => {
      let rutEmpresa = Number(e.rut.split("-")[0]);
      let digitoVerificador = e.rut.split("-")[1];
      let negocio = {
        idOrganizacion: e.idOrganizacion,
        //sucursales: e.sucursales,
        //giro: e.giro,
        //Data para creacion negocio api-enrolamiento
        canalVenta: {
          idCanalVenta: TIPO_CANAL_VENTA_CORREDOR,
        },
        digitoVerificador: digitoVerificador,
        idUsuarioCrea: usuario.id,
        razonSocial: e.razonSocial,
        /* region: {
          idRegion: e.sucursales[0].comuna.region.idRegion,
        }, */
        rutEmpresa: rutEmpresa,
        tipoIncorporacion: {
          idTipoIncorporacion: TIPO_INCORPORACION_MANTENCION,
        },
        estadoNegocio: {
          idEstadoNegocio: ESTADO_NEGOCIO_5,
        },
      };
      negociosMantencion.push(negocio);
    });
  } else {
    negocios.forEach((e) => {
      let rutEmpresa = Number(e.rut.split("-")[0]);
      let digitoVerificador = e.rut.split("-")[1];
      let negocio = {
        idOrganizacion: e.idOrganizacion,
        sucursales: e.sucursales,
        giro: e.giro,
        //Data para creacion negocio api-enrolamiento
        canalVenta: {
          idCanalVenta: TIPO_CANAL_VENTA_CORREDOR,
        },
        digitoVerificador: digitoVerificador,
        idUsuarioCrea: usuario.id,
        razonSocial: e.razonSocial,
        region: {
          idRegion: e.sucursales[0].comuna.region.idRegion,
        },
        rutEmpresa: rutEmpresa,
        tipoIncorporacion: {
          idTipoIncorporacion: TIPO_INCORPORACION_MANTENCION,
        },
        estadoNegocio: {
          idEstadoNegocio: ESTADO_NEGOCIO_5,
        },
      };
      negociosMantencion.push(negocio);
    });
  }

  return negociosMantencion;
}
