import React, { Component, Fragment } from "react";
import { crearEventoTraza } from "../../../../../utils/analitica";
import { GA_CATEGORIA_FORMULARIO_ENROLAMIENTO, GA_LABEL_CONSENTIMIENTO_BENEFICIARIOS } from "../../../../../utils/Constantes";

class InformacionBeneficiarios extends Component {

  componentDidMount() {
    crearEventoTraza(GA_CATEGORIA_FORMULARIO_ENROLAMIENTO, GA_LABEL_CONSENTIMIENTO_BENEFICIARIOS);
  }

  render() {
    return (
      <Fragment>
        <div className="col-md-12">
          <h5 className="font-weight-bold text-primary mb-0">Beneficiarios</h5>
          <hr className="mt-1" />
        </div>
        <ol className="mx-2 lista-numerica">
          <li>
            Puede designarse como Beneficiario a cualquier persona o personas.
            En la designación de más de un Beneficiario deberá señalar el
            porcentaje de indemnización que corresponde a cada uno, debiendo la
            suma de todos los porcentajes ser igual a cien (100). Si el
            Asegurado hace una designación sin porcentaje de indemnización, ésta
            se distribuirá por partes iguales entre aquellos que hubieren sido
            designados.
          </li>
          <li>
            A falta de designación de Beneficiario por parte del Asegurado, se
            entenderá como tales a los herederos legales de éste.
          </li>
          <li>
            El Asegurado podrá cambiar de Beneficiarios cuando lo estime
            conveniente, dando aviso por escrito al Asegurador mediante el
            formulario dispuesto para ello o a través de comunicación escrita.
          </li>
          <li>
            Compañía de Seguros Vida Cámara S.A. pagará válidamente a los
            Beneficiarios indicados en este instrumento, y con ellos quedará
            liberada de sus obligaciones, pues no le será disponible ningún
            cambio de Beneficiario, realizado en testamento o fuera de él, que
            no le hubiese sido notificado por escrito con anterioridad a la
            ocurrencia del siniestro.
          </li>
          <li>
            Esta designación una vez notificada a Compañía de Seguros Vida
            Cámara S.A. reemplaza cualquiera otra anterior.
          </li>
          <li>
            <div>
              <strong>Recomendación</strong>
            </div>
            Recomendamos para una mayor agilidad en el pago del siniestro, dejar
            como Beneficiario a personas mayores de 18 años, dado que según
            nuestra ley, los hijos menores de 18 años son incapaces de
            administrar sus bienes y necesariamente deben actuar representados
            por personas mayores de edad.
          </li>
        </ol>
        <ul className="my-5">
          <li>
            <strong>Aceptación Especial</strong>
          </li>
          <li className="row pt-2">
            <div className="col-1 text-center my-auto">
              <label className="switch">
                <input
                  ref="aceptacionRef"
                  name="aceptacion"
                  id="inputCheck"
                  onChange={() => {this.props.aceptarCheck()}}
                  type="checkbox"
                />
                <span className="slider round" />
              </label>
            </div>
            <div className="col-11">
              <span
                id="spanCheck"
                onClick={() => {this.props.aceptarCheck()}}
              >
                Entiendo y autorizo a que la(s) persona(s) designada(s) como
                beneficiario(s) en este formulario mantenga(n) dicha condición
                en el caso de renovarse y/o celebrarse un nuevo contrato de
                seguro de vida sucesivo a éste entre el contratante de esta
                póliza y Compañía de Seguros Vida Cámara S.A., lo cual es sin
                perjuicio de mi derecho a designar o modificar la designación de
                beneficiarios en la forma y oportunidad dispuesta por la póliza.
              </span>
            </div>
          </li>
        </ul>
      </Fragment>
    );
  }
}

export default InformacionBeneficiarios;
