import {
  USER_LOGOUT,
  DATA_ASEGURABLE,
  SHOW_MENU,
  GET_CIUDADES,
  GET_ESTADO_CIVIL,
  GET_COMUNAS,
  PREVISION_SALUD,
  GET_PARENTESCO,
  GET_REGIONES,
  GET_GENEROS,
  GET_FORMA_PAGO,
  GET_BANCO,
  GET_TIPO_CUENTA,
  UPDATE_DATOS_PERSONALES,
  UPDATE_DIRECCION,
  UPDATE_CONTACTO,
  UPDATE_DATOS_BANCARIOS,
  UPDATE_ID_SOLICITUD,
  ADD_CARGA,
  ADD_DETALLE,
  UPDATE_DETALLE,
  CAMBIO_DETALLE,
  UPDATE_CARGA,
  UPDATE_BENEFICIARIO,
  ADD_DECLARACION,
  WIZARD,
  PASSWORD_CHANGED,
  GET_LISTA_DECLARACIONES,
  GET_LISTA_PREGUNTAS_COBERTURA,
  ADD_NEGOCIO_ASEGURABLE,
  UPDATE_MODAL_COTIZACIONES_NEGOCIO,
  ACTUALIZAR_PROGRESO_ASEGURABLE,
  DECLARAR_CARGA,
  DECLARAR_DECLARACION,
  ACTUALIZAR_ASEGURABLES,
  ACTUALIZAR_BENEFICIARIOS,
  UPDATE_HEIGHT,
  GET_NEGOCIOS,
  GET_EJECUTIVOS,
  ESTADOS_NEGOCIOS,
  GUARDAR_REGIONES,
  GUARDAR_NEGOCIOS,
  GUARDAR_NEGOCIO,
  TIPO_NEGOCIO,
  GUARDAR_EJECUTIVOS,
  PERIODO_NEGOCIO,
  FECHA_TERMINO_ENROLAMIENTO,
  GUARDAR_GIROS,
  GUARDAR_DATOS_EMPRESA,
  GUARDAR_DATOS_SUCURSAL,
  GUARDAR_DATOS_DELEGADO,
  GUARDAR_COTIZACIONES,
  GUARDAR_COTIZACION,
  GUARDAR_CANALES_VENTA,
  MOSTRAR_MODAL_ARCHIVOS,
  MOSTRAR_MODAL_FECHA_ENROLAMIENTO,
  GUARDAR_ASEGURADOS_DELEGADO,
  DATA_WIZARD_ASEGURADO,
  MOSTRAR_SPINNER,
  COMPONENTE_WIZARD_EJECUTIVO,
  GUARDAR_ERRORES_CARGA_MASIVA,
  GUARDAR_COMUNAS,
  GUARDAR_CIUDADES,
  ACEPTAR_DECLARO_BENEFICIARIO,
  OBTENER_INFO_POLIZA,
  GUARDAR_DATOS_DELEGADO_NEGOCIO,
  GUARDAR_CHECKS_SECCION,
  GUARDAR_ESTRUCTURA_FORMULARIO,
  GUARDAR_OPT_GESTIONAR_EJECUTIVO,
  GUARDAR_CATALOGO_CONSENTIMIENTO,
  MENU_EJECUTIVO_COMPLETO,
  GUARDAR_FILTRO_SOLICITUDES,
  LIMPIAR_FILTRO_SOLICITUDES,
  OBTENER_PARENTESCO_BENEFICIARIOS,
  CARGANDO,
  TIPO_ALMACENAMIENTO,
} from "./types";

export const resetSesion = () => {
  return {
    type: USER_LOGOUT,
  };
};

export const guardar_menu_ejecutivo_completo = (payload) => {
  return {
    type: MENU_EJECUTIVO_COMPLETO,
    payload: payload,
  };
};

export const getListaDeclaraciones = (payload) => {
  return {
    type: GET_LISTA_DECLARACIONES,
    payload: payload,
  };
};

export const getListaPreguntasCobertura = (payload) => {
  return {
    type: GET_LISTA_PREGUNTAS_COBERTURA,
    payload: payload,
  };
};

export const add_negocio_asegurable = (payload) => {
  return {
    type: ADD_NEGOCIO_ASEGURABLE,
    payload: payload,
  };
};

export const guardar_previsiones_salud = (payload) => {
  return {
    type: PREVISION_SALUD,
    payload: payload,
  };
};

export const get_estado_civil = (payload) => {
  return {
    type: GET_ESTADO_CIVIL,
    payload: payload,
  };
};

export const get_generos = (payload) => {
  return {
    type: GET_GENEROS,
    payload: payload,
  };
};

export const get_parentescos = (payload) => {
  return {
    type: GET_PARENTESCO,
    payload: payload,
  };
};
export const obtener_parentescos_beneficiarios = (dato) => {
  return {
    type: OBTENER_PARENTESCO_BENEFICIARIOS,
    dato,
  };
};
export const get_ciudades = (payload) => {
  return {
    type: GET_CIUDADES,
    payload: payload,
  };
};

export const get_regiones = (payload) => {
  return {
    type: GET_REGIONES,
    payload: payload,
  };
};
export const get_comunas = (payload) => {
  return {
    type: GET_COMUNAS,
    payload: payload,
  };
};

export const get_forma_pago = (payload) => {
  return {
    type: GET_FORMA_PAGO,
    payload: payload,
  };
};

export const get_tipo_cuenta = (payload) => {
  return {
    type: GET_TIPO_CUENTA,
    payload: payload,
  };
};
export const get_banco = (payload) => {
  return {
    type: GET_BANCO,
    payload: payload,
  };
};

export const update_datos_personales = (payload) => {
  return {
    type: UPDATE_DATOS_PERSONALES,
    payload: payload,
  };
};

export const update_contacto = (payload) => {
  return {
    type: UPDATE_CONTACTO,
    payload: payload,
  };
};

export const update_direccion = (payload) => {
  return {
    type: UPDATE_DIRECCION,
    payload: payload,
  };
};
export const update_datos_bancarios = (payload) => {
  return {
    type: UPDATE_DATOS_BANCARIOS,
    payload: payload,
  };
};

export const update_id_solicitud = (payload) => {
  return {
    type: UPDATE_ID_SOLICITUD,
    payload: payload,
  };
};

export const add_carga = (payload) => {
  return {
    type: ADD_CARGA,
    payload: payload,
  };
};
export const update_carga = (payload) => {
  return {
    type: UPDATE_CARGA,
    payload: payload,
  };
};
export const update_beneficiario = (payload) => {
  return {
    type: UPDATE_BENEFICIARIO,
    payload: payload,
  };
};
export const add_declaracion = (payload) => {
  return {
    type: ADD_DECLARACION,
    payload: payload,
  };
};
export const add_detalle = (payload) => {
  return {
    type: ADD_DETALLE,
    payload: payload,
  };
};

export const update_detalle = (payload) => {
  return {
    type: UPDATE_DETALLE,
    payload: payload,
  };
};

export const cambio_detalle = (payload) => {
  return {
    type: CAMBIO_DETALLE,
    payload: payload,
  };
};

export const password_changed = (payload) => {
  return {
    type: PASSWORD_CHANGED,
    payload: payload,
  };
};

export const showMenu = (payload) => {
  return {
    type: SHOW_MENU,
    payload: payload,
  };
};

export const wizard = (payload) => {
  return {
    type: WIZARD,
    payload: payload,
  };
};

export const update_modal_cotizaciones_negocio = (payload) => {
  return {
    type: UPDATE_MODAL_COTIZACIONES_NEGOCIO,
    payload: payload,
  };
};

export const actualizar_progreso_asegurable = (payload) => {
  return {
    type: ACTUALIZAR_PROGRESO_ASEGURABLE,
    payload: payload,
  };
};

export const declarar_carga = (payload) => {
  return {
    type: DECLARAR_CARGA,
    payload: payload,
  };
};

export const declarar_declaracion = (payload) => {
  return {
    type: DECLARAR_DECLARACION,
    payload: payload,
  };
};

export const actualizar_asegurables = (payload) => {
  return {
    type: ACTUALIZAR_ASEGURABLES,
    payload: payload,
  };
};

export const get_negocios = (payload) => {
  return {
    type: GET_NEGOCIOS,
    payload: payload,
  };
};

export const get_ejecutivos = (payload) => {
  return {
    type: GET_EJECUTIVOS,
    payload: payload,
  };
};

// DISEÑO
export const update_height = (payload) => {
  return {
    type: UPDATE_HEIGHT,
    payload: payload,
  };
};

// CATALOGO
export const guardar_regiones = (payload) => {
  return {
    type: GUARDAR_REGIONES,
    payload: payload,
  };
};
export const guardar_ciudades = (payload) => {
  return {
    type: GUARDAR_CIUDADES,
    payload: payload,
  };
};
export const guardar_comunas = (payload) => {
  return {
    type: GUARDAR_COMUNAS,
    payload: payload,
  };
};

export const guardar_canales_venta = (payload) => {
  return {
    type: GUARDAR_CANALES_VENTA,
    payload: payload,
  };
};

// NEGOCIOS
export const guardar_negocios = (payload) => {
  return {
    type: GUARDAR_NEGOCIOS,
    payload: payload,
  };
};

export const guardar_negocio = (payload) => {
  return {
    type: GUARDAR_NEGOCIO,
    payload: payload,
  };
};

export const estados_negocios = (payload) => {
  return {
    type: ESTADOS_NEGOCIOS,
    payload: payload,
  };
};

export const tipo_negocio = (payload) => {
  return {
    type: TIPO_NEGOCIO,
    payload: payload,
  };
};

export const guardar_ejecutivos = (payload) => {
  return {
    type: GUARDAR_EJECUTIVOS,
    payload: payload,
  };
};

export const fecha_termino_enrolamiento = (payload) => {
  return {
    type: FECHA_TERMINO_ENROLAMIENTO,
    payload: payload,
  };
};

// COTIZACIONES
export const guardar_cotizaciones = (payload) => {
  return {
    type: GUARDAR_COTIZACIONES,
    payload: payload,
  };
};

export const guardar_cotizacion = (payload) => {
  return {
    type: GUARDAR_COTIZACION,
    payload: payload,
  };
};

export const mostrar_modal_archivos = (payload) => {
  return {
    type: MOSTRAR_MODAL_ARCHIVOS,
    payload: payload,
  };
};

export const mostrar_modal_fecha_enrolamiento = (payload) => {
  return {
    type: MOSTRAR_MODAL_FECHA_ENROLAMIENTO,
    payload: payload,
  };
};

export const mostrar_spinner = (payload) => {
  return {
    type: MOSTRAR_SPINNER,
    payload: payload,
  };
};

export const periodo_negocio = (payload) => {
  return {
    type: PERIODO_NEGOCIO,
    payload: payload,
  };
};

export const componente_wizard_ejecutivo = (payload) => {
  return {
    type: COMPONENTE_WIZARD_EJECUTIVO,
    payload: payload,
  };
};

export const guardar_giros = (payload) => {
  return {
    type: GUARDAR_GIROS,
    payload: payload,
  };
};

export const guardar_datos_empresa = (payload) => {
  return {
    type: GUARDAR_DATOS_EMPRESA,
    payload: payload,
  };
};

export const guardar_datos_sucursal = (payload) => {
  return {
    type: GUARDAR_DATOS_SUCURSAL,
    payload: payload,
  };
};

export const guardar_errores_carga_masiva = (payload) => {
  return {
    type: GUARDAR_ERRORES_CARGA_MASIVA,
    payload: payload,
  };
};

export const guardar_datos_delegado = (payload) => {
  return {
    type: GUARDAR_DATOS_DELEGADO,
    payload: payload,
  };
};

export const guardar_datos_delegado_negocio = (payload) => {
  return {
    type: GUARDAR_DATOS_DELEGADO_NEGOCIO,
    payload: payload,
  };
};

export const guardar_asegurados_delegado = (payload) => {
  return {
    type: GUARDAR_ASEGURADOS_DELEGADO,
    payload: payload,
  };
};

//ASEGURADO
export const guardar_data_asegurable = (payload) => {
  return {
    type: DATA_ASEGURABLE,
    payload: payload,
  };
};

export const guardar_data_wizard_asegurado = (payload) => {
  return {
    type: DATA_WIZARD_ASEGURADO,
    payload: payload,
  };
};

export const actualizar_beneficiarios = (payload) => {
  return {
    type: ACTUALIZAR_BENEFICIARIOS,
    payload: payload,
  };
};

export const aceptar_declaro_beneficiario = (payload) => {
  return {
    type: ACEPTAR_DECLARO_BENEFICIARIO,
    payload: payload,
  };
};

export const obtener_info_poliza = (payload) => {
  return {
    type: OBTENER_INFO_POLIZA,
    payload: payload,
  };
};

export const guardar_cheks_seccion = (payload) => {
  return {
    type: GUARDAR_CHECKS_SECCION,
    payload: payload,
  };
};

export const guardar_estructura_formulario = (payload) => {
  return {
    type: GUARDAR_ESTRUCTURA_FORMULARIO,
    payload: payload,
  };
};

export const guardar_opt_gestionar_ejecutivo = (payload) => {
  return {
    type: GUARDAR_OPT_GESTIONAR_EJECUTIVO,
    payload: payload,
  };
};

export const guardar_catalogo_consentimiento = (payload) => {
  return {
    type: GUARDAR_CATALOGO_CONSENTIMIENTO,
    payload: payload,
  };
};

export const guardar_filtro_solicitudes = (dato) => {
  return {
    type: GUARDAR_FILTRO_SOLICITUDES,
    dato,
  };
};

export const limpiar_filtro_solicitudes = (dato) => {
  return {
    type: LIMPIAR_FILTRO_SOLICITUDES,
    dato,
  };
};

export const ejecutarCargando = (objContenido) => {
  return {
    type: CARGANDO,
    payload: objContenido,
  };
};

export const guardarTipoAlmacenamiento = (objCotenido) => {
  return {
    type: TIPO_ALMACENAMIENTO,
    payload: objCotenido,
  };
};
