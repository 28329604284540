import React, { Component } from "react";
import BarPie from "../../../transversales/BarPie";
import { obtenerGrupoAsegurables } from "../../../../../services/asegurable.services";
import {
  guardar_negocio,
  guardar_cotizacion,
  guardar_asegurados_delegado,
  actualizar_asegurables,
} from "../../../../../actions/actions";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  MINIMA_CANTIDAD_ASEGURABLES,
  MINIMA_CANTIDAD_ASEGURABLES_VENTA_DIRECTA,
  VALOR_UNO,
  VALOR_CIEN,
  PERFIL_DELEGADO,
  TIPO_CANAL_VENTA_DIGITAL,
  TIPO_CANAL_VENTA_DIRECTA,
  VALOR_ZERO,
  TIPO_CANAL_VENTA_CORREDOR,
  COD_EJECUTIVO,
  PERFIL_LDAP,
  COD_EJECUTIVO_COMPLETO,
  RUTA_DELEGADO_SOLICITUD_COMPLETA,
  LARGO_CERO_COTIZACION,
  POSICION_INICIAL,
  COD_CORREDOR,
  ID_PRODUCTO_COMPL_SALUD,
  CANTIDAD_ASEGURABLES_APROBADOS_INICIAL,
  LARGO_CERO_TRABAJADORES_ENROLADOS,
  LARGO_CERO_SUCURSALES,
  PERFIL_B,
  GA_LABEL_INFORMACION_ENROLAMIENTO,
  GA_CATEGORIA_ENROLAMIENTO
} from "../../../../../utils/Constantes";
import {
  OPCIONES_DEFAULT_GRAFICO,
  OPCIONES_VENTA_DIRECTA_GRAFICO,
} from "../../../../../utils/ConstantesVentaDirecta";
import CabeceraNegocioDelegado from "./CabeceraNegocioDelegado";
import CabeceraNegocioDelegadoVD from "./CabeceraNegocioDelegadoVD";
import IndicadoresEnrolamiento from "./IndicadoresEnrolamiento";
import IndicadoresEnrolamientoVD from "./IndicadoresEnrolamientoVD";
import { obtenerConfiguracionDelegado } from "../../../../../services/negocios.service";
import { notificacion } from "../../../transversales/Notificaciones";
import Authorized from "../../../../../utils/Authorized";
import { EjecutarSeleccionIncorporacion } from "../../../autenticacion/Incorporacion";
import { crearEventoTraza } from "../../../../../utils/analitica";

class InfoEnrolamiento extends Component {
  constructor(props) {
    super(props);
    const idCanalVenta =
      this.props.store.reducer.negocio.canalVenta.idCanalVenta >
        TIPO_CANAL_VENTA_DIGITAL
        ? this.props.store.reducer.negocio.canalVenta.idCanalVenta
        : TIPO_CANAL_VENTA_DIGITAL;

    this.state = {
      negocio: null,
      cotizacion: null,
      cotizaciones: null,
      porcentajeAsegurablesEnrolados: 0,
      nominaCompleta: false,
      sesionEjecutivo:
        this.props.store.auth.usuarioSesion.rol.codigo === COD_EJECUTIVO ||
        this.props.store.auth.usuarioSesion.rol.codigo ===
        COD_EJECUTIVO_COMPLETO,
      blnSesionCorredor:
        this.props.store.auth.usuarioSesion.rol.codigo === COD_CORREDOR,
      data: {
        datasets: [
          {
            data: [0, 0],
            backgroundColor: ["#ffb421", "#aa843a"],
            hoverbackgroundColor: ["#ffb421", "#aa843a"],
            hoverBorderColor: ["#ffb421", "#aa843a"],
            hoverBorderWidth: 1,
          },
        ],
        labels: ["En proceso", "Terminado"],
      },
      canalVenta: idCanalVenta,
      datosContacto: null,
      listaProductoCobertura: [],
      totalAsegurablesVD: VALOR_ZERO,
      maximoAsegurableVD: VALOR_ZERO,
    };
  }

  redireccionarElementoGrafico = (elems) => {
    return elems;
  };

  dataGrafico(dataEnProceso, dataTerminado) {
    var data = {
      datasets: [
        {
          data: [dataEnProceso, dataTerminado],
          backgroundColor: ["#ffb421", "#aa843a"],
          hoverbackgroundColor: ["#ffb421", "#aa843a"],
          hoverBorderColor: ["#ffb421", "#aa843a"],
          hoverBorderWidth: 1,
        },
      ],
      labels: ["Total enrolados", "Faltantes enrolados"],
    };

    this.setState({ data });
  }

  /* =========================
      PETICIONES AL SERVIDOR
  ============================ */
  componentDidMount() {

    crearEventoTraza(GA_CATEGORIA_ENROLAMIENTO, GA_LABEL_INFORMACION_ENROLAMIENTO);

    const { reducer, auth } = this.props.store;
    const intIdCanalVenta = reducer.negocio.canalVenta.idCanalVenta;

    if (reducer.negocio.fechaFinEnrolamiento &&
        reducer.negocio.idEncuestaSatisfaccion &&
        auth.usuarioSesion.rol.codigo === PERFIL_B){
      this.props.history.push(RUTA_DELEGADO_SOLICITUD_COMPLETA);
    }

    let negocio,
      cotizacion,
      gruposVentaDirecta = [];
    if (this.props.location.state && !this.state.blnSesionCorredor) {
      negocio = this.props.location.state.negocio;
      cotizacion = this.props.location.state.negocio.cotizaciones;
    } else {
      negocio = reducer.negocio;
      cotizacion = reducer.cotizacion.length > LARGO_CERO_COTIZACION ? reducer.cotizacion : reducer.cotizaciones.gestionadas ? reducer.cotizaciones.gestionadas : reducer.cotizaciones
    }
    //definir de donde se obtienen las cotizaciones
    let cotizaciones;
    if (intIdCanalVenta === VALOR_UNO && this.state.sesionEjecutivo) {
      // perfil ejecutivo venta digital
      cotizaciones = reducer.cotizaciones.gestionadas;
    } else if (this.state.sesionEjecutivo || this.state.blnSesionCorredor) {
      // perfil ejecutivo venta directa
      cotizaciones = reducer.cotizaciones;
    } else {
      // perfil delegado digital o directa
      cotizaciones = negocio.cotizaciones;
    }
    this.setState({ cotizaciones });

    if (this.state.canalVenta === TIPO_CANAL_VENTA_DIRECTA) {
      let maximoDotacionTemp = VALOR_ZERO;
      cotizaciones.forEach((cot) => {
        if (cotizacion || cotizacion.numeroDotacion < cot.numeroDotacion) {
          cotizacion = cot;
          if (cot.grupos.length > 0)
            gruposVentaDirecta.push(cot.grupos[0].idGrupo);
          else
            notificacion(
              "warning",
              `Producto folio "${cot.codigoCotizacion}" no se ha podido configurar, favor comunicarse con encargado`
            );
        }
        if (maximoDotacionTemp < cot.numeroDotacion) {
          maximoDotacionTemp = cot.numeroDotacion;
        }
      });
      this.setState({ maximoAsegurableVD: maximoDotacionTemp });
    }

    this.obtenerEstatusEnrolamiento(negocio, cotizacion, gruposVentaDirecta);

    if (
      this.state.canalVenta === TIPO_CANAL_VENTA_DIRECTA ||
      this.state.canalVenta === TIPO_CANAL_VENTA_CORREDOR
    ) {
      this.obtenerProductosYCoberturas(cotizaciones);
    }
  }

  obtenerProductosYCoberturas(cotizaciones) {
    let listaProductoCobertura = [];
    cotizaciones.forEach((cot, i) => {
      if (!listaProductoCobertura.includes(cot.subProducto.glosaSubProducto)) {
        listaProductoCobertura.push({
          glosaSubProducto: cot.subProducto.glosaSubProducto,
          glosaCoberturas: [],
        });
        cot.coberturas.forEach((cob) => {
          listaProductoCobertura[i].glosaCoberturas.push({
            glosaCobertura: cob.glosaCobertura,
          });
        });
      }
    });

    let listaProductoCoberturaFilter = [];
    listaProductoCobertura.forEach((category) => {
      if (
        !listaProductoCoberturaFilter.find(
          (cat) => cat.glosaSubProducto === category.glosaSubProducto
        )
      ) {
        listaProductoCoberturaFilter.push(category);
      } else {
        let indice = -1;
        listaProductoCoberturaFilter.find(function (item, i) {
          if (item.glosaSubProducto === category.glosaSubProducto) {
            indice = i;
            return i;
          } else {
            return null;
          }
        });

        const { glosaCoberturas } = category;
        glosaCoberturas.forEach((glosa) => {
          if (
            listaProductoCoberturaFilter[indice].glosaCoberturas.filter(
              (e) => e.glosaCobertura === glosa.glosaCobertura
            ).length > 0
          ) {
          } else {
            listaProductoCoberturaFilter[indice].glosaCoberturas.push({
              glosaCobertura: glosa.glosaCobertura,
            });
          }
        });
      }
    });
    this.setState({ listaProductoCobertura: listaProductoCoberturaFilter });
  }

  async obtenerEstatusEnrolamiento(negocio, cotizacion, gruposVentaDirecta) {
    
    let lstGrupos;
    let lstCotizacion = cotizacion;
    if (this.state.canalVenta === TIPO_CANAL_VENTA_DIGITAL && cotizacion.length > LARGO_CERO_COTIZACION) {
      lstGrupos = cotizacion[POSICION_INICIAL].grupos;
      cotizacion = cotizacion[POSICION_INICIAL];
    } else {
      lstGrupos = cotizacion.grupos;
    }

    //obtener lista asegurables
    if (lstGrupos) {
      if (this.state.canalVenta === TIPO_CANAL_VENTA_DIGITAL) {
        let grupoAsegurables =
          this.props.store.reducer.delegado.aseguradosDelegado;
        if (grupoAsegurables.length < VALOR_UNO) {
          const { idGrupo } = lstGrupos[POSICION_INICIAL];
          const dataEnvio = {
            idGrupo: idGrupo,
            perfil: this.state.sesionEjecutivo ? PERFIL_LDAP : PERFIL_DELEGADO,
          };
          grupoAsegurables = await obtenerGrupoAsegurables(dataEnvio);
          this.props.guardar_asegurados_delegado(grupoAsegurables);
          this.props.actualizar_asegurables(grupoAsegurables);
        }
      } else {
        let grupoAsegurables = [];
        for (let idGrupo of gruposVentaDirecta) {
          const dataEnvio = {
            idGrupo: idGrupo,
            perfil: this.state.sesionEjecutivo ? PERFIL_LDAP : PERFIL_DELEGADO,
          };
          let asegurables = await obtenerGrupoAsegurables(dataEnvio);
          for (let asegurable of asegurables) {
            if (
              grupoAsegurables.filter((e) => e.rut === asegurable.rut).length >
              VALOR_ZERO
            ) {
              grupoAsegurables
                .find((e) => e.rut === asegurable.rut)
                .subProducto.push(asegurable.subProducto[0]);
            } else {
              grupoAsegurables.push(asegurable);
            }
          }
        }
        this.setState({ totalAsegurablesVD: grupoAsegurables.length });
        this.props.guardar_asegurados_delegado(grupoAsegurables);
        this.props.actualizar_asegurables(grupoAsegurables);
      }

      // Datos Dashboard
      const { aseguradosDelegado } = this.props.store.reducer.delegado;
      const trabajadoresIngresados = aseguradosDelegado.length;
      const intTrabajadoresEnrolados = aseguradosDelegado.length > LARGO_CERO_TRABAJADORES_ENROLADOS ? aseguradosDelegado.filter(
                                      (trabajador) => trabajador.solicitud.fechaRecepcion !== null
                                    ).length : LARGO_CERO_TRABAJADORES_ENROLADOS;
      // > Obtener cargas de asegurados enrolados
      let totalCargas = 0;
      aseguradosDelegado.forEach((trabajador) => {
        if (trabajador.solicitud.fechaRecepcion !== null) {
          totalCargas += trabajador.totalCarga;
        }
      });

      this.setState({ totalCargas });

      let calculoCantidadMinimaAdherencia;
      let porcentajeAsegurables;
      let cantidadMinimaAdherencia;
      let intPoblacion = cotizacion.numeroDotacion ? cotizacion.numeroDotacion : cotizacion.planCotizado[LARGO_CERO_COTIZACION].totalTitular;

      if (this.state.canalVenta === TIPO_CANAL_VENTA_DIGITAL) {
        calculoCantidadMinimaAdherencia = Math.trunc((cotizacion.porcentajeMinimaAdherencia * intPoblacion) / VALOR_CIEN);
        porcentajeAsegurables = Math.round((VALOR_CIEN * intTrabajadoresEnrolados) / intPoblacion);
        cantidadMinimaAdherencia = calculoCantidadMinimaAdherencia <= MINIMA_CANTIDAD_ASEGURABLES ? MINIMA_CANTIDAD_ASEGURABLES : calculoCantidadMinimaAdherencia;

      } else {
        calculoCantidadMinimaAdherencia = MINIMA_CANTIDAD_ASEGURABLES_VENTA_DIRECTA;
        cantidadMinimaAdherencia = trabajadoresIngresados ? trabajadoresIngresados : MINIMA_CANTIDAD_ASEGURABLES_VENTA_DIRECTA;
        porcentajeAsegurables = Math.round((VALOR_CIEN * intTrabajadoresEnrolados) / intPoblacion);
        const intIdSucursal = this.props.store.reducer.negocio.sucursales[LARGO_CERO_SUCURSALES].idOrganizacionSucursal;

        if (!this.state.sesionEjecutivo) {
          let delegado = await obtenerConfiguracionDelegado(
            intIdSucursal,
            PERFIL_DELEGADO
          );

          let ultimaCotizacion = Object.assign({}, this.state.ultimaCotizacion);
          ultimaCotizacion.nombre = delegado.persona.nombre;
          ultimaCotizacion.apellidoMaterno = delegado.persona.apellidoMaterno;
          ultimaCotizacion.apellidoPaterno = delegado.persona.apellidoPaterno;
          ultimaCotizacion.telefono = delegado.telefono;
          ultimaCotizacion.email = delegado.email;
          this.setState({ datosContacto: ultimaCotizacion });
        }
      }

      let faltantes =
        trabajadoresIngresados <= cantidadMinimaAdherencia
          ? cantidadMinimaAdherencia - intTrabajadoresEnrolados
          : trabajadoresIngresados - intTrabajadoresEnrolados;

      this.setState({
        trabajadoresEnrolados: intTrabajadoresEnrolados,
        trabajadoresPorEnrolar: faltantes,
      });

      this.dataGrafico(intTrabajadoresEnrolados, faltantes);
      this.setState(
        {
          porcentajeAsegurablesEnrolados: porcentajeAsegurables,
        },
        () => {
          let intTotalAsegurablesAprobados = CANTIDAD_ASEGURABLES_APROBADOS_INICIAL;
          aseguradosDelegado.forEach(
            objAsegurable => {
              objAsegurable.requisitos && objAsegurable.requisitos.forEach(objRequisito => {
                if (objRequisito.idSubProducto !== ID_PRODUCTO_COMPL_SALUD && objRequisito.cumpleRequisito) {
                  intTotalAsegurablesAprobados++;
                }
              })
            })
          if (this.state.canalVenta === TIPO_CANAL_VENTA_DIGITAL) {
            if (
              (this.state.porcentajeAsegurablesEnrolados >=
                cotizacion.porcentajeMinimaAdherencia || (cotizacion.length > LARGO_CERO_COTIZACION && this.state.porcentajeAsegurablesEnrolados >=
                  cotizacion[POSICION_INICIAL].porcentajeMinimaAdherencia)) &&
                  intTotalAsegurablesAprobados >= MINIMA_CANTIDAD_ASEGURABLES
            ) {
              this.setState({
                nominaCompleta: true,
              });
            } else {
              this.setState({
                nominaCompleta: false,
              });
            }
          } else {
            if (
              this.state.porcentajeAsegurablesEnrolados >=
              MINIMA_CANTIDAD_ASEGURABLES_VENTA_DIRECTA
            ) {
              this.setState({
                nominaCompleta: true,
              });
            }
          }
        }
      );
    }
    cotizacion = lstCotizacion;
    this.setState({ cotizacion, negocio });
  }

  render() {
    const { escogerIncorporacion } = this.props.store.auth;

    return (
      <React.Fragment>
        {this.state.negocio && this.state.cotizacion && this.state.canalVenta && (
          <div className="row animated fadeIn">
            <Authorized roles={[2]}>
              <div className="col-md-7 mb-3 text-muted">
                <h4 className="font-weight-bold mb-0">
                  Estado de enrolamiento
                </h4>
                <p>Cotizaciones actuales de nuestros clientes</p>
              </div>
              <div className="col-md-5 mb-3 align-self-center">
                {escogerIncorporacion && <EjecutarSeleccionIncorporacion />}
              </div>

              {/* INFO EMPRESA */}
              {this.state.canalVenta === TIPO_CANAL_VENTA_DIGITAL ? (
                <div className="col-12 mb-3">
                  <CabeceraNegocioDelegado
                    negocio={this.state.negocio}
                    cotizacion={this.state.cotizacion}
                  />
                </div>
              ) : (
                <div className="col-12 mb-3">
                  <CabeceraNegocioDelegadoVD
                    datosContacto={this.state.datosContacto}
                    negocio={this.state.negocio}
                    cotizacion={this.state.cotizacion}
                  />
                </div>
              )}
            </Authorized>

            {/* GRÁFICO */}
            <div className="col-md-5 pr-0">
              <BarPie
                data={this.state.data}
                options={
                  this.state.canalVenta === TIPO_CANAL_VENTA_DIGITAL
                    ? OPCIONES_DEFAULT_GRAFICO
                    : OPCIONES_VENTA_DIRECTA_GRAFICO
                }
                metodo={this.redireccionarElementoGrafico}
              ></BarPie>
            </div>

            {/* INDICADORES */}
            {this.state.canalVenta === TIPO_CANAL_VENTA_DIGITAL ? (
              <div className="col-md-7 pl-0 mt-sm-3 mt-md-0">
                <IndicadoresEnrolamiento
                  canalVenta={this.state.canalVenta}
                  negocio={this.state.negocio}
                  cotizacion={this.state.cotizacion.length > LARGO_CERO_COTIZACION ? this.state.cotizacion[POSICION_INICIAL] : this.state.cotizacion}
                  trabajadoresPorEnrolar={this.state.trabajadoresPorEnrolar}
                  trabajadoresEnrolados={this.state.trabajadoresEnrolados}
                  totalCargas={this.state.totalCargas}
                  porcentajeAsegurablesEnrolados={
                    this.state.porcentajeAsegurablesEnrolados
                  }
                  nominaCompleta={this.state.nominaCompleta}
                  listaProductoCobertura={this.state.listaProductoCobertura}
                  setSeccionEnrolamiento={this.props.setSeccionEnrolamiento}
                />
              </div>
            ) : (
              <div className="col-md-7 pl-0 mt-sm-3 mt-md-0">
                <IndicadoresEnrolamientoVD
                  canalVenta={this.state.canalVenta}
                  negocio={this.state.negocio}
                  cotizacion={this.state.cotizacion}
                  trabajadoresPorEnrolar={this.state.trabajadoresPorEnrolar}
                  trabajadoresEnrolados={this.state.trabajadoresEnrolados}
                  totalCargas={this.state.totalCargas}
                  porcentajeAsegurablesEnrolados={
                    this.state.porcentajeAsegurablesEnrolados
                  }
                  nominaCompleta={this.state.nominaCompleta}
                  listaProductoCobertura={this.state.listaProductoCobertura}
                  setSeccionEnrolamiento={this.props.setSeccionEnrolamiento}
                  totalAsegurablesVD={this.state.totalAsegurablesVD}
                  maximoAsegurableVD={this.state.maximoAsegurableVD}
                />
              </div>
            )}
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state,
});

export default withRouter(
  connect(mapStateToProps, {
    guardar_negocio,
    guardar_cotizacion,
    guardar_asegurados_delegado,
    actualizar_asegurables,
  })(InfoEnrolamiento)
);
