import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import ModalGestionarEjecutivos from "./ModalGestionarEjecutivos";
import AgregarEjecutivo from "./AgregarEjecutivo";
import MoverEjecutivo from "./MoverEjecutivo";
import { VALOR_ZERO, VALOR_UNO, GA_LABEL_GESTIONAR_EJECUTIVOS, GA_CATEGORIA_EJECUTIVOS } from "../../../../../utils/Constantes";
import { crearEventoTraza } from "../../../../../utils/analitica";

const GestionarEjecutivos = (props) => {
  const { optGestionarEjecutivo } = useSelector((store) => store.reducer);

  useEffect(() => {
    crearEventoTraza(GA_CATEGORIA_EJECUTIVOS, GA_LABEL_GESTIONAR_EJECUTIVOS);
  },[]);

  return (
    <React.Fragment>
      {optGestionarEjecutivo !== VALOR_ZERO ? (
        <div>
          {optGestionarEjecutivo === VALOR_UNO ?
            <AgregarEjecutivo />
            :
            <MoverEjecutivo />
          }
        </div>
      ) : (
          <ModalGestionarEjecutivos
            show={optGestionarEjecutivo === VALOR_ZERO}
            titulo="Gestionar ejecutivos"
          />
        )}
    </React.Fragment>
  );
};

export default withRouter(GestionarEjecutivos);
