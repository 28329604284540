import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import {
  wizard,
  add_declaracion,
  add_detalle,
} from "../../../../../../actions/actions";
import { crearEventoTraza } from "../../../../../../utils/analitica";
import { GA_CATEGORIA_FORMULARIO_ENROLAMIENTO, GA_LABEL_PREGUNTAS_AMPLIACION } from "../../../../../../utils/Constantes";

class CoberturasAmpliacion extends Component {
  aceptacionRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      checked: null,
      declaracionesAmpliacion: {
        aceptacion: null,
        enfermedad: "",
        fechaDiagnostico: "",
        idAsegurable: 0,
        idAsegurableCobertura: null,
        idCatalogoAceptacion: 0,
        idCatalogoTipoDeclaracion: 0,
        idDeclaracion: null,
        idPreguntaSeccion: 0,
        vigente: true,
      },
      detalle: [],
      informacion: null,
      loadingData:false
    };
  }

  componentDidMount() {

    crearEventoTraza(GA_CATEGORIA_FORMULARIO_ENROLAMIENTO, GA_LABEL_PREGUNTAS_AMPLIACION);
  }

  componentWillMount() {
    this.setState({
      informacion: this.props.store.reducer.declaraciones.filter(
        (a) => a.seccion === "Invalidez"
      )[0],
    });
  }

  handleChange = (e) => {
    const aceptacion = this.aceptacionRef.current.checked;
    let declaracionesAmpliacion = null;
    if (e.target.name !== "aceptacion") {
      declaracionesAmpliacion = {
        ...this.state.declaracionesAmpliacion,
        [e.target.name]: e.target.value,
      };
    } else {
      declaracionesAmpliacion = {
        ...this.state.declaracionesAmpliacion,
        aceptacion,
      };
    }
    this.setState({
      declaracionesAmpliacion,
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    const detalle = [...this.state.detalle, this.state.declaracionesAmpliacion];
    this.setState({
      detalle,
    });
    this.props.add_detalle(this.state.declaracionesAmpliacion);
    e.target.reset();
  };

  siguiente = () => {
    this.setState({ loadingData: true });
    if (
      this.state.checked === null ||
      (this.state.checked === "1" && this.state.detalle.length === 0)
    ) {
      return;
    }

    if (
      this.state.checked === "1" &&
      !this.aceptacionRef.current.checked &&
      this.state.detalle.length === 0
    ) {
      return;
    }

    this.setState({ animacion: "formOut" });
    setTimeout(() => {
      this.props.wizard({ tab: 4, type: "tab" });
    }, 100);
    this.setState({ loadingData: false });
  };

  render() {
    return (
      <React.Fragment>
        <div
          id="declaracionActividadesDeportes"
          className="cuerpo-wizard pt-3 pb-5 o-white container"
        >
          <form
            className="animated fadeInRight"
            onSubmit={this.onSubmit}
            ref={this.formularioRef}
          >
            <div className="row m-1 m-lg-4">
              <div className="titulo-marcado">3. Cobertura de Ampliación</div>
              <div className="col-12">
                <p className="">
                  ¿ Usted o algunos de sus asegurables ha sido diagnosticado o
                  está en conocimiento o se encuentra en proceso de estudio, por
                  enfermedades, padecimientos o malformaciones congénitas y/o
                  cromosopatías, o se encuentra en calidad de carga inválida
                  (duplo) en alguna Caja de Compensación de asignación Familiar
                  (CCAF) u otro Organismo Competente ?
                </p>
              </div>
              <div className="col-12">
                <div className="check-group">
                  <label className="custom-checkbox p-1">
                    <input
                      type="radio"
                      value="1"
                      name="radio"
                      checked={this.state.checked === "1"}
                      onChange={async (e) => {
                        await this.setState({
                          checked: e.target.value,
                        });
                      }}
                    />
                    <div className="check"></div>
                    <div className="option">Si</div>
                  </label>
                  <label className="custom-checkbox p-1">
                    <input
                      type="radio"
                      value="0"
                      name="radio"
                      checked={this.state.checked === "0"}
                      onChange={async (e) => {
                        await this.setState({
                          checked: e.target.value,
                        });
                      }}
                    />
                    <div className="check"></div>
                    <div className="option">No</div>
                  </label>
                </div>
              </div>
              {this.state.checked > 0 && (
                <div>
                  <div className="row">
                    <div className="col-12 col-md-8 offset-md-2">
                      <h5 className="font-weight-bold">
                        Diagnóstico y/o declaración de invalidez
                      </h5>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-8 offset-md-2">
                      <div className="form-group">
                        <label htmlFor="">
                          Nombre completo asegurable titular o dependiente
                        </label>
                        <select
                          ref={this.declaranteRef}
                          type="text"
                          className="form-control form-control-sm"
                          name="idAsegurable"
                          onChange={this.handleChange}
                          required
                        >
                          <option value="">SELECIONE UN ASEGURABLE</option>
                          <option
                            value={
                              this.props.store.reducer.data_asegurable
                                .idAsegurable
                            }
                          >{`${this.props.store.reducer.data_asegurable.datoPersonal.misDatos.nombre} ${this.props.store.reducer.data_asegurable.datoPersonal.misDatos.apellidoPaterno} ${this.props.store.reducer.data_asegurable.datoPersonal.misDatos.apellidoMaterno} (TITULAR)`}</option>

                          {this.props.store.reducer.data_asegurable.carga.map(
                            (item, i) => (
                              <option
                                key={i}
                                value={item.idAsegurable}
                              >{`${item.nombre} ${item.apellidoPaterno} ${item.apellidoMaterno} (CARGA)`}</option>
                            )
                          )}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-8 offset-md-2">
                      <div className="form-group">
                        <label htmlFor="">Diagnóstico</label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          required
                          data-validator="alfabetico"
                          name="enfermedad"
                          maxLength="150"
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-8 offset-md-2">
                      <div className="form-group">
                        <label htmlFor="">
                          Fecha diagnóstico y/o delcaración de invalidéz
                        </label>
                        <input
                          type="date"
                          className="form-control form-control-sm"
                          required
                          name="fechaDiagnostico"
                          onChange={this.handleChange}
                          max={moment().format("YYYY-MM-DD")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-8 offset-md-2">
                      <div className="button-zone text-right pt-3 pb-3">
                        <button
                          className="btn btn-principal color-secundario btn-sm"
                          type="submit"
                        >
                          Agregar
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 p-5">
                      <div className="form-check">
                        <input
                          ref={this.aceptacionRef}
                          className="form-check-input"
                          type="checkbox"
                          name="aceptacion"
                          id="defaultCheck1"
                          onChange={this.handleChange}
                        />
                        <label
                          className="form-check-label "
                          htmlFor="defaultCheck1"
                        >
                          Tomo conocimiento y acepto expresamente que toda
                          enfermedad preexistente y/o situación de salud
                          preexistente delcarada precedentemente implica que
                          respecto de ella(s), los beneficios de este seguro no
                          operarán si la causa del siniestro fuere producto de
                          una de las enfermedades preexistente o situaciones de
                          salud preexistente señaladas expresamente por mí en
                          este formulario.También acepto que, en caso de no
                          declarar en este formulario la práctica de deportes y
                          actividades riesgosas, tanto respecto de mí como de mi
                          grupo familiar, si correspondiere, ello significará
                          que la cobertura del seguro no operará si el siniestro
                          es consecuencia de la práctica de los deportes y/o
                          actividades riesgosas comprendidos en el listado
                          anteriormente señalado.
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </form>
        </div>
        <div className="button-zone text-right pt-3 pb-3">
          <button
            className="btn btn-principal color-secundario btn-lg"
            type="button"
          >
            Atrás
          </button>

          <button
            className="btn btn-principal btn-lg"
            type="button"
            onClick={this.siguiente}
            disabled={this.state.loadingData}
          >
            {this.state.loadingData ? (
              <>
                <i className="fa fa-spinner fa-pulse fa-fw"></i>
                <span className="sr-only">Loading...</span>
              </>
            ) : (
              <span>Siguiente</span>
            )}
          </button>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state,
});

export default withRouter(
  connect(mapStateToProps, { wizard, add_declaracion, add_detalle })(
    CoberturasAmpliacion
  )
);
