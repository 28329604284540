import React, { useState } from "react";
import { connect } from "react-redux";
import ReactDOM from "react-dom";
import { withRouter } from "react-router-dom";
import Table from "../../../transversales/DataTable";
import {
  COD_EJECUTIVO_MANTENCION,
  COD_EJECUTIVO_COMPLETO,
} from "../../../../../utils/Constantes";

const ModalResultadoSolicitud = (props) => {
  const [reRender, setRender] = useState(1);
  function verificarSesion() {
    if (
      props.sesion === COD_EJECUTIVO_MANTENCION ||
      props.sesion === COD_EJECUTIVO_COMPLETO
    ) {
      return true;
    }
  }
  const columnDefs = [
    {
      name: "Póliza Renovación",
      selector: "polizas",
    },
    {
      name: "Rut Asegurable",
      selector: "rut",
    },
    {
      name: "Nombre",
      selector: "nombreCompleto",
    },
    {
      name: "Tipo",
      selector: "tipo",
    },
    ...(verificarSesion()
      ? [
          {
            name: "Fecha de Nacimiento",
            selector: "fechaNacimiento",
          },
        ]
      : []),
    {
      name: "Declara Preexistencia",
      selector: "declaraPreexistencia",
      width: "200px",
      center: true,
    },
    {
      name: "Declara Actividad o Deporte",
      selector: "declaraDeporte",
      width: "200px",
      center: true,
    },
    {
      name: "Evaluación Suscripción",
      selector: "evaluacionSuscripcion",
    },
    {
      name: "Incorporado",
      selector: "incorporado",
    },
    {
      name: "Estado Solicitud",
      selector: "estadoSolicitud",
    },
    {
      name: "Observación",
      selector: "observacion",
      width: "230px",
      center: true,
      cell: (row) =>
        row.observacion !== "-" && reRender ? (
          <>
            <span
              style={{ width: "230px" }}
              className={row.verMas ? "" : "compactarColumna"}
            >
              {row.observacion}
              <span
                className="verMasVerMenos"
                onClick={() => {
                  row.verMas = !row.verMas;
                  setRender((reRender) => reRender + 1);
                }}
              >
                {row.verMas ? <span> Ver menos</span> : ""}
              </span>
            </span>
            <span
              className="verMasVerMenos"
              onClick={() => {
                row.verMas = !row.verMas;
                setRender((reRender) => reRender + 1);
              }}
            >
              {row.verMas ? "" : <span> Ver más</span>}
            </span>
          </>
        ) : (
          row.observacion
        ),
    },
  ];

  const temaTabla = {
    title: {
      backgroundColor: "rgb(255, 255, 255)",
      height: "0px",
    },
    header: {
      fontSize: "14px",
      backgroundColor: "rgb(255, 255, 255)",
      fontColor: "black",
      fontWeight: "600",
    },
    rows: {
      backgroundColor: "white",
      borderWidth: "0px",
    },
  };

  return ReactDOM.createPortal(
    <div
      className={`${
        props.mostrarModal ? "modal_overlay show lbg" : "modal_overlay"
      }`}
    >
      <div className="container h-100">
        <div className="align-self-center w-100">
          <div
            className="main_modal animated bounceInLeft center-2 large"
            style={{ maxWidth: "1100px" }}
          >
            <div className="row">
              <div className="col-md-12">
                <div className="modal_title">
                  <div className="title title font-weight-bold text-primary text-left">
                    Resultado solicitud
                  </div>
                  {true ? (
                    <div
                      className="button_close cursor"
                      onClick={() => {
                        props.cerrarModal();
                      }}
                    >
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        viewBox="0 0 352 512"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z" />
                      </svg>
                    </div>
                  ) : null}
                </div>

                <div className="modal_body py-2">
                  <div className="shadow">
                    <Table
                      datos={props.asegurablesSolicitud}
                      columnas={columnDefs}
                      tema={temaTabla}
                      paginacion={true}
                      paginacionPorPagina={10}
                      paginacionOpciones={{
                        rowsPerPageText: "Resultado por página:",
                        rangeSeparatorText: "de",
                        noRowsPerPage: true,
                      }}
                    />
                  </div>
                </div>
                <div className="modal_foot">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="button-zone text-right pt-3 pb-3 mr-5">
                        <button
                          id="cancelar"
                          onClick={() => {
                            props.cerrarModal();
                          }}
                          className="btn btn-principal color-cancel btn-sm"
                          type="button"
                        >
                          Cerrar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>,
    document.querySelector(`#spinnerNaranjo`)
  );
};

const mapStateToProps = (state) => ({
  store: state,
});

export default withRouter(
  connect(mapStateToProps, {})(ModalResultadoSolicitud)
);
