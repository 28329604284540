import ServicesFactory from "../config/servicesFactory";
import uuid from "uuid";
import { SIN_DOCUMENTOS, VALOR_ZERO, VALOR_UNO, VALOR_DOS, COD_STATUS_EXITO } from "../utils/Constantes";
import { capitalize } from "../utils/Functions";

let servicios = new ServicesFactory();

export async function listarPorCodigoCotizacion(data) {
  try {
    let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/cotizacion/listarPorCodigoCotizacionYIdNegocio/${data.folioCotizacion}/${data.idNegocio}`;
    let respuesta = await servicios.get(url, false);
    return respuesta !== undefined && respuesta !== null && respuesta.data.status.codigo === "001" ? respuesta.data.objeto : null;
  } catch (error) {
    return null;
  }
}

export async function obtenerNegocios(data) {
  try {
    let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/negocio/listarPorRut`;
    let respuesta = await servicios.post(url, data, false);

    return respuesta !== undefined && respuesta !== null && respuesta.data.status.codigo === "001"
      ? dataNegocios(respuesta.data.objeto)
      : [];
  } catch (error) {
    return [];
  }
}

export async function asignarEjecutivo(data) {
  try {
    let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/negocio/asignar`;
    let respuesta = await servicios.post(url, data, false);

    return respuesta !== undefined && respuesta !== null && respuesta.data.status.codigo === "001" ? respuesta.data : null;
  } catch (error) {
    return null;
  }
}

export async function setArchivosCotizacion(data) {
  try {
    let url = `${process.env.REACT_APP_API_URL_DOCUMENTO}/repositorio/guardarDocto`;
    let respuesta = await servicios.post(url, data, false);

    return respuesta !== undefined && respuesta !== null && respuesta.data.status.codigo === "001" ? respuesta.data.objeto : null;
  } catch (error) {
    return null;
  }
}

export async function getArchivosCotizacion(data) {
  try {
    let url = `${process.env.REACT_APP_API_URL_DOCUMENTO}/repositorio/buscarDocto`;
    let respuesta = await servicios.post(url, data, false);

    return respuesta !== undefined && respuesta !== null && respuesta.data.status.codigo === "001"
      ? respuesta.data.objeto
      : respuesta.data.status.codigo === "002"
      ? SIN_DOCUMENTOS
      : null;
  } catch (error) {
    return null;
  }
}

export async function listarPorAsegurable(data, perfil) {
  try {
    let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/cotizacion/listar/perfil/${perfil}`;
    let respuesta = await servicios.post(url, data, false);

    return respuesta !== undefined && respuesta !== null && respuesta.data.status.codigo === "001" ? respuesta.data.objeto : null;
  } catch (error) {
    return null;
  }
}

export async function eliminarArchivoCotizacion(idDocto) {
  try {
    let url = `${process.env.REACT_APP_API_URL_DOCUMENTO}/repositorio/eliminarDocto/${idDocto}`;
    let respuesta = await servicios.get(url, false);

    return respuesta !== undefined && respuesta !== null && respuesta.data.status.codigo === "001"
      ? (respuesta.data.objeto = { message: "Archivo eliminado" })
      : null;
  } catch (error) {
    return null;
  }
}

export async function actualizarCotizacion(dataEnvio) {
  try {
    let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/cotizacion/actualizar`;
    let respuesta = await servicios.put(url, dataEnvio, false);

    return respuesta !== undefined && respuesta !== null && respuesta.data.status.codigo === "001"
      ? (respuesta.data.objeto = { message: "null" })
      : null;
  } catch (error) {
    return null;
  }
}

export async function crearCotizacion(dataEnvio) {
  try {
    let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/cotizacion/crear`;
    let respuesta = await servicios.post(url, dataEnvio, false);

    return respuesta !== undefined && respuesta !== null && respuesta.data.status.codigo === "001" ? respuesta.data.objeto : null;
  } catch (error) {
    return null;
  }
}

export async function eliminarCotizaciones(cotizaciones) {
  try {
    let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/cotizacion/eliminarPorCodigoCotizacion`;
    let respuesta = await servicios.post(url, cotizaciones, false);

    return respuesta !== undefined && respuesta !== null && respuesta.data.status.codigo === "001" ? respuesta.data.objeto : null;
  } catch (error) {
    return null;
  }
}

export async function validarCotizacionrepetida(codigoCotizacion) {
  try {
    let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/cotizacion/validarPorCodigoCotizacion/${codigoCotizacion}`;
    let respuesta = await servicios.get(url, false);

    return respuesta !== undefined && respuesta !== null && respuesta.data.status.codigo === "001" ? respuesta.data.objeto : null;
  } catch (error) {
    return null;
  }
}
export async function validarRequisitosAsegurabilidadCotizacion({ codigoCotizacion, idSubProdCondicionado, idParentesco }) {
  try {
    let strUrl = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/cotizacion/requisitosAsegurabilidad/${codigoCotizacion}/${idSubProdCondicionado}/${idParentesco}`;
    let objRespuesta = await servicios.get(strUrl, false);

    return objRespuesta ? objRespuesta.data : null;
  } catch (objError) {
    return null;
  }
}

export async function validarRequisitosAsegurabilidadPoliza(data) {
  try {
    let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/poliza/validarRequisitosAsegurabilidad/`;
    let respuesta = await servicios.post(url, data, false);

    return respuesta !== undefined && respuesta !== null && respuesta.data.status.codigo === COD_STATUS_EXITO
      ? respuesta.data.objeto
      : null;
  } catch (error) {
    return null;
  }
}

export async function listarCotizacionConPrimaFija(token) {
  try {
    let strUrl = `${process.env.REACT_APP_BUS_URL_COTIZADOR}/listarConPrimaFijaPorToken/${token}`;
    let objRespuesta = await servicios.get(strUrl, false);

    return objRespuesta !== undefined && objRespuesta !== null && objRespuesta.data.status.codigo === COD_STATUS_EXITO
      ? objRespuesta.data.objeto
      : null;
  } catch (objError) {
    return null;
  }
}

/* ################ servicios DEF ############### */
export async function obtenerCotizacionesNegocio(data) {
  try {
    let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/negocio/buscarDetalleNegocioPorIdNegocio`;
    let res = await servicios.post(url, data, false);

    return res !== undefined && res !== null && res.data.status.codigo === "001" ? dataCotizaciones(res.data.objeto) : null;
  } catch (error) {
    return null;
  }
}

/* ============================= 
  Obtener nómina de asegurados aprobadas en formato excel
============================= */
export async function obtenerNominaAprobada(idNegocio) {
  try {
    let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/asegurable/nomina/buscarAprobadoPorIdNegocio/${idNegocio}`;
    let respuesta = await servicios.get(url, false);
    return respuesta !== undefined && respuesta !== null && respuesta.data.status.codigo === "001" ? respuesta.data.objeto : null;
  } catch (error) {
    return [];
  }
}

/* ============================= 
  HOMOLOGAR DATA PARA FRONT END 
  ============================= */
function dataNegocios(negocios) {
  if (negocios.length > VALOR_ZERO) {
    for (let negocio of negocios) {
      let fechaI = new Date(negocio.fechaInicioVigencia);
      let fechaT = new Date(negocio.fechaTerminoEnrolamiento);

      negocio.fechaInicioVigencia = fechaI.toLocaleDateString();
      negocio.fechaTerminoEnrolamiento = fechaT.toLocaleDateString();
    }
  }
  return negocios;
}

function dataCotizaciones(data) {
  data = dataNegocio(data);

  // Validar canalVenta digital
  if (data.canalVenta.idCanalVenta === VALOR_UNO) {
    let cotizaciones = data.cotizaciones;
    let gestionadas = [];
    let generadas = [];

    if (cotizaciones) {
      for (let c of cotizaciones) {
        if (c.estadoCotizacion) {
          c.estadoCotizacion.idEstadoCotizacion >= VALOR_DOS ? gestionadas.push(c) : generadas.push(c);
        } else generadas.push(c);
      }
    }
    data.cotizaciones = { gestionadas, generadas };
  }

  return data;
}

function dataNegocio(negocio) {
  if (negocio.estadoNegocio.idEstadoNegocio > VALOR_UNO) {
    if (negocio.ejecutivo) {
      negocio.ejecutivoAsignado = capitalize(`${negocio.ejecutivo.nombre} ${negocio.ejecutivo.apellidoPaterno}`);
      negocio.emailEjecutivoAsignado = negocio.ejecutivo.correoElectronico;
      negocio.idEjecutivoAsignado = negocio.ejecutivo.idPersona;
      negocio.key = uuid();
    } else {
      negocio.ejecutivoAsignado = `Ejecutivo no encontrado`;
      negocio.key = uuid();
    }
  } else {
    negocio.ejecutivoAsignado = `Por asignar`;
    negocio.key = uuid();
  }

  return negocio;
}
