import React, { Component } from "react";
const iconNoData = require("../../assets/images/no_data_found.svg");

export default class NoData extends Component {
  
  render() {
    return (
      <div className="mx-auto my-5 animated fadeIn">
        <img src={iconNoData} className="d-block mx-auto mb-2" height="70px" alt="No hay resultados" />
        <h4 className="text-center">{this.props.titulo}</h4>
      </div>
    );
  }
}

