import Files from "react-files";
import {
    _JPEG,
    _JPG,
    _PDF,
    _PNG,
    CODIGO_ERROR_1,
    CODIGO_ERROR_2,
    ERROR_CARGAR_ARCHIVO,
    EXITO_CARGAR_ARCHIVO,
    ERROR_BORRAR_ARCHIVO,
    EXITO_BORRAR_ARCHIVO,
    VALIDACION_PESO_ARCHIVO,
    VALIDACION_TIPO_ARCHIVO, VALOR_ZERO
} from "../../../../../utils/Constantes";
import React, {useEffect, useState} from "react";
import {notificacion} from "../../../transversales/Notificaciones";
import {obtenerArchivos, guardarArchivos, eliminarArchivo} from "../../../../../services/bus.document.services";
import checkVerde from "../../../../assets/images/checkGreen.svg";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {ModalVerDocumentos} from "../../../transversales/ModalVerDocumentos";

export const enviarArchivos  = async (listaDoctos, objParams, documentType) => {

    if(listaDoctos !== null && listaDoctos.length > 0){
        let archivosAdjuntos = listaDoctos.map(archivo => {
            let item = archivo;
            return { 
                nombreDocto: `${item.nombreDocto.split(".")[0]}.${item.nombreDocto.split(".")[1]}`,
                streamBase64: item.streamBase64
            }
        });

        let dataEnvioArchivos = {
            listaDoctos: archivosAdjuntos,
            metadataDocto: {
                [objParams.key]: objParams.value
            },
            tipoDocto: documentType,
        };

        let res = guardarArchivos(dataEnvioArchivos);
        if(res !== null && res !== ERROR_CARGAR_ARCHIVO){
            notificacion("success", EXITO_CARGAR_ARCHIVO)
            for (const docto of listaDoctos) {
                docto.newFile = false;
            }
        } else {
            notificacion("danger", ERROR_CARGAR_ARCHIVO);
        }
    }
}

export const obtenerArchivosComponente = async (objParams, documentType) => {
    let data = {
        metadataDocto: {
            [objParams.key]: objParams.value
        },
        tipoDocto: documentType
    };

    return await obtenerArchivos(data);
}


const CargarArchivoParentesco = (props) =>{    
    const [nombreArchivo, setNombreArchivo] = useState(null);
    const [abrirModalVerDocumentos, setAbrirModalVerDocumentos] = useState(false);
    const [extensionArchivo, setExtensionArchivo] = useState(null);
    const [previsualizarArchivo, setPrevisualizarArchivo] = useState(null);

    const [cargaArchivo, setCargaArchivo] = useState(false);
    const [listaDoctos, setListaDoctos] = useState([]);
    const [tipoDocReq, setTipoDocReq] = useState();

    useEffect(() =>{
        setTipoDocReq(props.tipoDocReq);
        if(props.cleanFiles){
            setListaDoctos([]);
        } else if(props.prevFiles && props.prevFiles.length){
            listaDocumentosReq(props.tipoDocReq,props.prevFiles);
        }
    }, [props.prevFiles, props.cleanFiles, props.maxFiles, props.tipoDocReq])
    
    const mostrarArchivosCargados = item => {
        if(props.files){
            props.files(item);
        }
    }

    const eliminarArchivos = (itemDoc, indice) => {
        setCargaArchivo(true);
      //Entra solo cuando el archivo esta cargado en el servidor
      if (itemDoc.idDocto !== undefined && itemDoc.idDocto !== null) {
        const idDoctoSucio = itemDoc.idDocto.split(";");
        const idDoctoLimpio = idDoctoSucio[0];
        let res = eliminarArchivo(idDoctoLimpio);
        if (
          res !== "undefined" &&
          res !== null &&
          res !== ERROR_BORRAR_ARCHIVO
        ) {
          notificacion("success", EXITO_BORRAR_ARCHIVO);
          let arrayListaDoctos = listaDoctos.filter((item, j) => j !== indice);
          setListaDoctos(arrayListaDoctos);
          mostrarArchivosCargados(arrayListaDoctos);
        } else {
          notificacion("danger", ERROR_BORRAR_ARCHIVO);
        }
      } else {
        // Entra cuando el archivo vive en el cliente
        let arrayListaDoctos = listaDoctos.filter((item, j) => j !== indice);
        setListaDoctos(tipoDocReq, arrayListaDoctos);
        mostrarArchivosCargados(arrayListaDoctos);
      }
      setCargaArchivo(false);
    };

    const mostrarErroresArchivo = (error) => {
        if (error.code === CODIGO_ERROR_2) {
            notificacion("warning", VALIDACION_PESO_ARCHIVO);
        } else if (error.code === CODIGO_ERROR_1) {
            notificacion("warning", VALIDACION_TIPO_ARCHIVO);
        }
    }

    const cargarArchivos  = (file, tipoDoc)  =>{
        setExtensionArchivo(file[0].extension);
        setCargaArchivo(true);
        if (file[0]) {
            let nombreArchivo = `${tipoDoc.glosa}.${file[0].extension}`;
            let objLectura = new FileReader();
            objLectura.readAsDataURL(file[0]);
            objLectura.onload = () => {
                const indice = objLectura.result.indexOf(",");
                let objListaDoctos = {
                    nombreDocto: nombreArchivo,
                    streamBase64: objLectura.result.slice(
                        indice + 1,
                        objLectura.result.length
                    ),
                    newFile: true,
                    requerido: tipoDoc.requerido,
                    base64: objLectura.result
                };
                setPrevisualizarArchivo(objListaDoctos.base64);
                const listaDoctosNueva = [...listaDoctos, objListaDoctos];
                listaDocumentosReq(tipoDocReq,listaDoctosNueva);
                mostrarArchivosCargados(listaDoctosNueva);
            };
        }
        setCargaArchivo(false)
    }

    const listaDocumentosReq = (tipoDocReq, listaDoc) => {
        let tipoDocIng = [];
        let listaDocRes = listaDoc.map((documento) => {
            for (const tipoDoc of tipoDocReq) {
                const nombreSinRut = documento.nombreDocto.split("_");
                let nombreSinExtension;
                if (nombreSinRut[0]) nombreSinExtension = nombreSinRut[0].split(".");
                if (nombreSinRut[1]) nombreSinExtension = nombreSinRut[1].split(".");
                if (nombreSinExtension[0].indexOf(tipoDoc.glosa) === 0) {
                    documento.requerido = tipoDoc.requerido;
                    tipoDocIng = [...tipoDocIng, tipoDoc];
                }
            }
            return documento;
        });

        if(tipoDocIng){
            let tipoDocRes = tipoDocReq.filter((tipoDoc, indice) => {
                let tipoDocIngIndice = tipoDocIng.sort((a, b) => a.id > b.id ? 1 : -1).map(tipo => tipo.id).indexOf(tipoDoc.id);
                if(tipoDocIngIndice >= 0 && indice !== tipoDocIngIndice){
                    let obligoIndice = indice;
                    return indice !== obligoIndice;
                }else{
                    return indice !== tipoDocIngIndice;
                }
            });
            setTipoDocReq(tipoDocRes);
        }
        setListaDoctos(listaDocRes);
    };

    const visualizar = documento => {
        //metodo para previsualizar documentos
        setAbrirModalVerDocumentos(!abrirModalVerDocumentos);
        setNombreArchivo(documento.newFile ? documento.nombreDocto : documento.nombreDocto.split("_")[1]); 

        if (!documento.newFile) {
            let cadenaReplaceBase64 = "";
            let letBase64 = "";
            setExtensionArchivo(documento.nombreDocto.split(".")[1]);

            if (documento.nombreDocto.split(".")[1] === "pdf") {
                cadenaReplaceBase64 = "data:application/pdf;base64,";
                letBase64 = cadenaReplaceBase64 + documento.streamBase64;
                setPrevisualizarArchivo(letBase64);
            } else {
                cadenaReplaceBase64 = "data:image/jpeg;base64,";
                letBase64 = cadenaReplaceBase64 + documento.streamBase64;
                setPrevisualizarArchivo(letBase64);
            }    
        } else {
            setExtensionArchivo(documento.nombreDocto.split(".")[1])
            setPrevisualizarArchivo(documento.base64);
        }    
    };

    const cerrarModalVerDocumentos = (estadoModalVerDocumentos) => {
        setAbrirModalVerDocumentos(estadoModalVerDocumentos) 
    };

    return (
        <React.Fragment>
            {abrirModalVerDocumentos && (
                <ModalVerDocumentos showbuttons="false"
                                    title={ nombreArchivo }
                                    onHide={cerrarModalVerDocumentos}
                                    show={abrirModalVerDocumentos}
                                    className="text-center"
                >
                    {extensionArchivo === "pdf" ? (
                    <iframe
                        src={previsualizarArchivo}
                        height="600px"
                        width="100%"
                        title="Visualizar PDF"
                    ></iframe>
                    ) : (
                        <img
                        className="img-fluid rounded"
                        alt={ nombreArchivo !== undefined ? nombreArchivo : "" }
                        src={ previsualizarArchivo }
                        />
                    )}
                </ModalVerDocumentos>
            )}
            <div className="row" id='documentosCargados'>
                <div className="col-md-12">
                    <div className="form-group">
                        { 
                            tipoDocReq && tipoDocReq.map((tipoDoc, indice) => 

                                <div key={`${indice}-documentosRequeridos`}>
                                    <Files
                                        id="subirArchivo"
                                        className={`${props.children !== null ? ' ' : 'input-file py-2'}`}
                                        onChange={(file) => {
                                            cargarArchivos(file, tipoDoc);
                                        }}
                                        onError={mostrarErroresArchivo}
                                        accepts={[_PDF, _PNG, _JPEG, _JPG]}
                                        multiple={false}
                                        maxFiles={props.maxFiles}
                                        minFiles={props.minFiles}
                                        clickable
                                    >
                                    
                                        <label  className={'text-primary'}>
                                            {tipoDoc.requerido && <span className="text-danger">*</span> } Adjuntar  el {tipoDoc.glosa} &nbsp;
                                            <span className="font-weight-bold txext-primary" >aquí</span>
                                        </label> 
                                
                                    </Files>
                                </div>
                            )}
                    </div>
                </div>
                <div className="col-md-12">
                    {  listaDoctos.length > VALOR_ZERO &&
                    <div className="font-weight-bold text-muted">
                        {props.children !== null ? '' : <span>Documentos Cargados</span>}
                    </div>
                    }
                </div>
                <div className="col-md-12">
                    <div style={{ overflowY: 'scroll', maxHeight: 300, overflowX: 'hidden' }}>
                        {cargaArchivo ? (
                                <div className="d-flex justify-content-center animated fadeIn my-5">
                                    <div className="spinner-border text-primary mb-5" role="status">
                                        <span className="sr-only">Cargando...</span>
                                    </div>
                                </div>
                            ) :
                            listaDoctos.length !== VALOR_ZERO &&
                            listaDoctos.map((item, i) => (

                                    <div className="row animated fadeIn" style={{ paddingTop: 15 }} key={i}>
                                        <div className="col-2">
                                            <img
                                                src={checkVerde}
                                                width="30"
                                                height="30"
                                                className="img-fluid mx-auto mb-2"
                                                id="imgCheckAgregarEjecutivo"
                                                alt=""></img>
                                        </div>
                                        
                                        <div className="col-8 my-auto" style={{ padding: 0, marginLeft: '-30px' }}>
                                        <span
                                            className="d-block"
                                            style={{ wordBreak: "break-word" }}
                                        >
                                            {item.nombreDocto}
                                        </span>
                                        </div>
                                        <div className="col-1 my-auto" style={{ paddingRight: 0 }}>
                                            <i
                                                className="fa fa-eye text-primary fa-2x cursor"
                                                aria-hidden="true"
                                                style={{ marginRight: 15, marginTop: 5 }}
                                                onClick={() => visualizar(item)}
                                            ></i>
                                        </div>
                                        <div className="col-1 my-auto" style={{ paddingRight: 0 }}>
                                            <i
                                                className="fa fa-trash text-primary fa-2x cursor"
                                                aria-hidden="true"
                                                onClick={() =>
                                                    eliminarArchivos(item, i)
                                                }
                                            ></i>
                                        </div>
                                    </div>

                                   
                                ))}
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => ({
    store: state,
});

export default withRouter(
    connect(mapStateToProps, {})(CargarArchivoParentesco)
);
