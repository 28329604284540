import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Dropdown, Badge } from "react-bootstrap";
import { VALOR_DOS, VALOR_ZERO, VALOR_UNO, SIGNO_GUION } from "../../../../../utils/Constantes";

class FiltrosAsegurables extends Component {
  constructor(props) {
    super(props);
    this.state = {
      asegurables: this.props.dataAsegurado,
      estadosEnrolamiento: this.props.estadosEnrolamiento,
      filtrosBusqueda: {
        asegurables: this.props.dataAsegurado,
        estadosEnrolamiento: this.props.estadosEnrolamiento,
      },
      filtros: [],
      filtroInicio: this.props.store.reducer.filtroSolicitudes 
        ? this.props.store.reducer.filtroSolicitudes.FechaModificacionSolicitud
        : null
    };
  }

  /* =========================
      BÚSQUEDAS REACTIVAS
  ============================ */
  busquedaReactivaAsegurados(bsq) {
    const { handleSearch } = this.props;
    let asegurables = Object.assign([], this.props.dataAsegurado);

    let busqueda = bsq.target.value.toLowerCase();

    let data = asegurables.filter((e) => {
      let rut = e.rut;
      let nombreCompleto = e.nombreCompleto.toLowerCase();
      return (
        nombreCompleto.indexOf(busqueda) > -1 || rut.indexOf(busqueda) > -1
      );
    });

    handleSearch(data, "busqueda");
  }

  /* =========================
      FILTROS DE BÚQUEDA
  ============================ */
  agregarFiltro(filtro, idFiltro) {
    this.setState({filtroInicio: null});
    let tipoFiltro = filtro.split(SIGNO_GUION);
    let dato = { idFiltro, filtro: filtro, tipoFiltro: tipoFiltro.length > VALOR_UNO ? VALOR_UNO : VALOR_DOS };
    let filtros = Object.assign([], this.state.filtros);
    filtros.push(dato);

    // Eliminar repetidos
    let flag = {};
    filtros = filtros.filter((e) => {
      let exists = !flag[e.filtro] || false;
      flag[e.filtro] = true;
      return exists;
    });

    this.setState({ filtros }, () => {
      this.filtrar();
    });
  }

  quitarFiltroSeleccionado(filtro) {
    let filtros = Object.assign([], this.state.filtros);
    filtros = filtros.filter((e) => e.filtro !== filtro.filtro);

    this.setState({ filtros }, () => {
      this.filtrar();
    });
  }

  filtrar() {
    const { handleSearch } = this.props;

    let asegurables = this.props.solicitudes ? [...this.props.store.delegado.asegurables] : Object.assign(
      [],
      this.props.store.reducer.grupoAsegurables
    );
    let filtros = Object.assign([], this.state.filtros);
    let asegurablesPre = [];

    if (filtros.length > 0) {
      for (let f of filtros) {
        let resultado = asegurables.filter((e) => {
          let filtro = f.filtro.replace(/ /g, "").toLowerCase();
          let estadoEnrolamiento = e.status.replace(/ /g, "").toLowerCase();
          let existePoliza = e.grupos.find(grupo => {
            return grupo.idContratoSeguro === Number(f.idFiltro)
          })

          const numeroPoliza = existePoliza ? this.props.polizas.find(poliza => poliza.idContratoSeguro === existePoliza.idContratoSeguro
          ) : undefined;

          return estadoEnrolamiento === filtro || numeroPoliza !== undefined;
        });
        asegurablesPre = asegurablesPre.concat(resultado);
        asegurablesPre = [...new Set(asegurablesPre)];
      }

    } else {
      asegurablesPre = this.props.solicitudes ? [...this.props.store.delegado.asegurables] : this.props.store.reducer.grupoAsegurables;
    }
    handleSearch(asegurablesPre, "filtro");

    if (this.props.filtrosSeleccionados) 
      this.props.filtrosSeleccionados(filtros);
  }

  render() {
    return (
      <div>
        <div className="row mb-3">
          <div className="col-xl-6 align-self-end">
            <input
              className="search-input w-100"
              autoComplete='off'
              type="text"
              id="bsq"
              onChange={(e) => {
                this.busquedaReactivaAsegurados(e);
              }}
              placeholder="Busca por nombre o RUT..."
            />
          </div>
          <div className="col-xl-6 pt-2 align-self-end">
            <Dropdown>
              <Dropdown.Toggle
                disabled={this.props.rutContratante === VALOR_ZERO}
                variant="dark"
                bsPrefix="py-2 btn-dark btn-block rounded"
                id="dropdown-basic"
              >
                Filtros de búsqueda
                <i className="fa fa-chevron-down ml-2" aria-hidden="true"></i>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown alignRight>
                  <Dropdown.Toggle
                    variant=""
                    bsPrefix="d-block text-dark px-3 py-1 w-100"
                    id="dropdown-estado"
                  >
                    <div className="row">
                      <div className="col-8">
                        <span className="d-block text-left">
                          Estados enrolamiento
                        </span>
                      </div>
                      <div className="col-4">
                        <i
                          className="fa fa-chevron-down"
                          aria-hidden="true"
                        ></i>
                      </div>
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Header bsPrefix="dropdown-header py-0">
                      Seleccionar estado
                    </Dropdown.Header>
                    <Dropdown.Divider />
                    {this.state.estadosEnrolamiento.length > 0
                      ? this.state.estadosEnrolamiento.map((e, i) => (
                        <Dropdown.Item
                          id={`item${i}`}
                          key={i}
                          onClick={() => this.agregarFiltro(e.glosa, e.id)}
                        >
                          <span style={{ fontSize: "0.875rem" }}>
                            {e.glosa}
                          </span>
                          {this.state.filtros.filter(
                            (f) => f.filtro === e.glosa
                          ).length > 0 ? (
                            <i
                              className="fa fa-check text-success ml-2"
                              aria-hidden="true"
                            ></i>
                          ) : null}
                        </Dropdown.Item>
                      ))
                      : null}
                  </Dropdown.Menu>
                </Dropdown>
                {this.props.solicitudes && (
                  <Dropdown alignRight>
                    <Dropdown.Toggle
                      variant=""
                      bsPrefix="d-block text-dark px-3 py-1 w-100"
                      id="dropdown-estado"
                    >
                      <div className="row">
                        <div className="col-8">
                          <span className="d-block text-left">
                            Pólizas
                          </span>
                        </div>
                        <div className="col-4">
                          <i
                            className="fa fa-chevron-down"
                            aria-hidden="true"
                          ></i>
                        </div>
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Header bsPrefix="dropdown-header py-0">
                        Seleccionar póliza
                      </Dropdown.Header>
                      <Dropdown.Divider />
                      {this.props.polizas && this.props.polizas.length > 0
                        ? this.props.polizas.map((e, i) => (
                          <Dropdown.Item
                            id={`item${i}`}
                            key={i}
                            onClick={() => this.agregarFiltro(e.etiqueta, e.idContratoSeguro)}
                          >
                            <span style={{ fontSize: "0.875rem" }}>
                              Póliza {e.etiqueta}
                              {this.state && this.state.filtros.length > VALOR_ZERO &&
                                this.state.filtros.map((filtro) => filtro.filtro === e.etiqueta &&
                                  <i
                                    className="fa fa-check text-success ml-2"
                                    aria-hidden="true"
                                    key={filtro.filtro}
                                  ></i>
                                )}
                            </span>
                          </Dropdown.Item>
                        ))
                        : null}
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="col-xl-12 align-self-end" style={{ marginTop: 15 }}>
            <div className="text-left mb-2">
              {
                this.state.filtroInicio &&
                <h5 className="d-inline animated fadeIn">
                  <Badge variant="secondary font-weight-light text-white mx-1" style={{ background: '#F8CD79' }}>
                    <span className="text-capitalize">
                      {this.props.asegurablesBusqueda.length} solicitudes
                    </span>
                    <i
                      id="quitarFiltro"
                      className="fa fa-times ml-1 cursor"
                      aria-hidden="true"
                      onClick={() => {
                        this.props.limpiarFiltroFechaMod();
                        this.setState({filtroInicio: null});
                      }}
                    ></i>
                  </Badge>
                </h5>
              }
              {this.state.filtros.map((f, i) => (
                <h5 key={i} className="d-inline animated fadeIn">
                  <Badge variant="secondary font-weight-light text-white mx-1" style={{ background: '#F8CD79' }}>
                    <span className="text-capitalize">
                      {f.filtro.split("-")[1] !== undefined ? "Póliza " : ""}
                      {f.filtro}
                    </span>
                    <i
                      id="quitarFiltro"
                      className="fa fa-times ml-1 cursor"
                      aria-hidden="true"
                      onClick={() => this.quitarFiltroSeleccionado(f)}
                    ></i>
                  </Badge>
                </h5>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state,
});

export default withRouter(connect(mapStateToProps, {})(FiltrosAsegurables));
