import React, { Component, Fragment } from "react";
import Table from "../../../transversales/DataTable";
import ModalEditarAsegurable from "../../jefatura-ejecutivo/ModalEditarAsegurable";

class TablaBeneficiarios extends Component {
  state = {
    mostrarModalEliminar: false,
    beneficiarios: this.props.beneficiarios,
    row: {},
    columnDefs: [
      {
        name: "Nombre completo",
        selector: "nombreCompleto",
      },
      {
        name: "RUT",
        selector: "rutBeneficiario",
      },
      {
        name: "% Beneficio",
        selector: "porcentajeBeneficio",
      },
      {
        cell: (row) => (
          <React.Fragment>
            <button
              id="editar"
              className="btn btn-primary btn-sm btn-table mr-2"
              type="button"
              title="Editar"
              raised="true"
              primary="true"
              onClick={() => this.editarBeneficiario(row)}
            >
              <i className="fa fa-pencil" aria-hidden="true"></i>
            </button>
            <button
              id="eliminar"
              className="btn btn-danger btn-sm btn-table"
              type="button"
              title="Eliminar"
              raised="true"
              primary="true"
              onClick={() => this.showModalEliminar(row)}
            >
              <i className="fa fa-trash" aria-hidden="true"></i>
            </button>
          </React.Fragment>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
    ],
  };

  componentWillReceiveProps(newProps) {

    
    this.setState({beneficiarios: []}, function(){this.setState({beneficiarios: newProps.beneficiarios})});
  }

  editarBeneficiario = (row) => {
    this.props.llenarFormulario(row);
  }

  showModalEliminar = (row) => {
    this.setState({
      mostrarModalEliminar: !this.state.mostrarModalEliminar,
      row,
    });
  };

  eliminarBeneficiario = () => {
    this.setState({ mostrarModalEliminar: !this.state.mostrarModalEliminar });

    let beneficiarios = this.props.beneficiarios.filter(
      (item) => item.rutBeneficiario !== this.state.row.rutBeneficiario
    );
    this.props.actualizarListaBeneficiarios(beneficiarios);
    

    let porcentaje =
      this.props.sumaPorcentaje - this.state.row.porcentajeBeneficio;
    this.props.actualizarPorcentaje(porcentaje);

    this.props.actualizarDatosBeneficiarios(beneficiarios);
  };

  render() {

    return (
      <Fragment>
        <ModalEditarAsegurable
          idSubmit="submitTablaBeneficiarios"
          type="small"
          show={this.state.mostrarModalEliminar}
          title="Eliminar Beneficiario"
          action="Continuar"
          onSubmit={this.eliminarBeneficiario}
          onCancel={() => {
            this.setState({
              mostrarModalEliminar: !this.state.mostrarModalEliminar,
              row: {},
            });
          }}
          onClose={() => {
            this.setState({
              mostrarModalEliminar: !this.state.mostrarModalEliminar,
              row: {},
            });
          }}
          showCancelButton
          showCloseButton
          showSubmitButton
          showLeftColumn={false}
        >
          <div className="row justify-content-center">
            <h5 className="col-12 col-md-10">
              Está seguro que desea eliminar este beneficiario?. ¿Desea continuar?
            </h5>
          </div>
        </ModalEditarAsegurable>
        <div className="container pb-5">
          <div>
            {this.state.beneficiarios !== null &&
            this.state.beneficiarios.length > 0 ? (
              <Table
                titulo="Beneficiarios"
                datos={this.state.beneficiarios}
                columnas={this.state.columnDefs}
              />
            ) : null}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default TablaBeneficiarios;
