import ServicesFactory from "../config/servicesFactory";
import uuid from "uuid";
import { COD_STATUS_EXITO, VALOR_UNO } from "../utils/Constantes";
import { capitalize } from "../utils/Functions";

let services = new ServicesFactory();

export async function obtenerNegocios(data) {
  try {
    let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/negocio/listarPorRut`;
    let res = await services.post(url, data, false);

    return res !== undefined && res !== null && res.data.status.codigo === COD_STATUS_EXITO
      ? dataNegocios(res.data.objeto)
      : { activos: [], inactivos: [] };
  } catch (error) {
    return null;
  }
}

export async function obtenerNegocio(data, ejecutivos) {
  try {
    let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/negocio/buscarDetalleNegocioPorIdNegocio`;
    let res = await services.post(url, data, false);

    return res !== undefined && res !== null && res.data.status.codigo === COD_STATUS_EXITO
      ? ejecutivos
        ? dataNegocio(res.data.objeto)
        : res.data.objeto
      : { activos: [], inactivos: [] };
  } catch (error) {
    return null;
  }
}

export async function obtenerNegocioDelegado(data) {
  try {
    let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/negocio/buscarDetalleNegocioPorIdNegocio`;
    let res = await services.post(url, data, false);

    return res !== undefined && res !== null && res.data.status.codigo === COD_STATUS_EXITO
      ? res.data.objeto
      : null;
  } catch (error) {
    return null;
  }
}

export async function obtenerEjecutivosPorRutJefatura(rut) {
  try {
    let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/ejecutivo/buscarPorRutJefatura/${rut}`;
    let res = await services.get(url, false);

    return res !== undefined && res !== null && res.data.status.codigo === COD_STATUS_EXITO
      ? capitalizeNombreEjecutivo(res.data.objeto)
      : [];
  } catch (error) {
    return null;
  }
}

export async function obtenerTodosLosEjecutivos() {
  try {
    let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/ejecutivo/listar`;
    let res = await services.get(url, false);

    return res !== undefined && res !== null && res.data.status.codigo === COD_STATUS_EXITO
      ? capitalizeNombreEjecutivo(res.data.objeto)
      : [];
  } catch (error) {
    return null;
  }
}


export async function obtenerTodasLasJefaturas() {
  try {
    let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/ejecutivo/listarJefatura`;
    let res = await services.get(url, false);

    return res !== undefined && res !== null && res.data.status.codigo === COD_STATUS_EXITO
      ? capitalizeNombreEjecutivo(res.data.objeto)
      : [];
  } catch (error) {
    return null;
  }
}

export async function obtenerEstadosNegocios() {
  try {
    let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/catalogo/listarEstadosNegocio`;
    let res = await services.get(url, false);

    return res !== undefined && res !== null && res.data.status.codigo === COD_STATUS_EXITO
      ? res.data.objeto
      : [];
  } catch (error) {
    return null;
  }
}

export async function verificarCierreEnrolamientoAsegurado(codigoCotizacion) {
  try {
    let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/negocio/verificarCierreEnrolamiento/${codigoCotizacion}`;
    let res = await services.get(url, false);

    return res !== undefined && res !== null && res.data.status.codigo === COD_STATUS_EXITO
      ? res.data.objeto
      : null;
  } catch (error) {
    return null;
  }
}

export async function crearNegocio(data) {
  try {
    let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/negocio/crear`;
    let respuesta = await services.post(url, data, false);

    return respuesta !== undefined &&
      respuesta !== null &&
      respuesta.data.status.codigo === COD_STATUS_EXITO
      ? respuesta.data.objeto
      : null;
  } catch (error) {
    return null;
  }
}

export async function asignarSucursal(data) {
  try {
    let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/negocio/asignarSucursal`;
    let respuesta = await services.post(url, data, false);

    return respuesta !== undefined && respuesta !== null && respuesta.data.status.codigo === COD_STATUS_EXITO
      ? respuesta.data.objeto
      : null;
  } catch (error) {
    return null;
  }
}

export async function actualizarNegocio(data) {
  try {
    let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/negocio/actualizar`;
    let res = await services.post(url, data, false);

    return res !== undefined && res !== null && res.data.status.codigo === COD_STATUS_EXITO
      ? res.data.objeto
      : null;
  } catch (error) {
    return null;
  }
}

export async function asignarAceptacionNegocio(data,perfil) {
  try {
    let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/negocio/asignarAceptacion/${perfil}`;
    let respuesta = await services.post(url, data, false);

    return respuesta !== undefined && respuesta !== null && respuesta.data.status.codigo === "001"
      ? respuesta.data.objeto
      : null;
  } catch (error) {
    return null;
  }
}

export async function eliminarNegocio(data) {
  try {
    let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/negocio/actualizar`;
    let res = await services.post(url, data, false);

    return res !== undefined && res !== null && res.data.status.codigo === COD_STATUS_EXITO
      ? res.data.objeto
      : [];
  } catch (error) {
    return null;
  }
}

export async function obtenerGiros() {
  try {
    let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/catalogo/listarGiro`;
    let res = await services.get(url, false);

    return res !== undefined && res !== null && res.data.status.codigo === COD_STATUS_EXITO
      ? res.data.objeto
      : [];
  } catch (error) {
    return null;
  }
}

export async function iniciarEnrolamientoEjecutivo(data) {
  try {
    let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/negocio/enrolamiento/proceso/crear`;
    let res = await services.post(url, data, false);

    return res !== undefined && res !== null && res.data.status.codigo === COD_STATUS_EXITO
      ? res.data.objeto
      : null;
  } catch (error) {
    return null;
  }
}

/***** CONFIGURACIÓN DEL NEGOCIO *****/
export async function buscarOrganizacionPorRut(rutEmpresa) {
  try {
    let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/negocio/buscarOrganizacionPorRut/${rutEmpresa}`;
    let res = await services.get(url, false);

    return res !== undefined && res !== null && res.data.status.codigo === COD_STATUS_EXITO
      ? res.data.objeto
      : null;
  } catch (error) {
    return null;
  }
}

export async function enviarOrganizacionSucursalEjecutivo(data) {
  try {
    let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/negocio/creaActualizaOrganizacionSucursalEjecutivo`;
    let res = await services.post(url, data, false);

    return res !== undefined && res !== null && res.data.status.codigo === COD_STATUS_EXITO
      ? res.data.objeto
      : null;
  } catch (error) {
    return null;
  }
}

export async function obtenerConfiguracionSucursal(rutEmpresa) {
  try {
    let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/negocio/obtenerConfiguracionSucursal/${rutEmpresa}`;
    let res = await services.get(url, false);

    return res !== undefined && res !== null && res.data.status.codigo === COD_STATUS_EXITO
      ? homologarDataConfiguracionNegocio(res.data.objeto)
      : null;
  } catch (error) {
    return null;
  }
}

export async function obtenerConfiguracionDelegado(idSucursal, perfil) {
  try {
    let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/persona/buscarDelegado/${idSucursal}/perfil/${perfil} `;
    let res = await services.get(url, false);

    return res !== undefined && res !== null && res.data.status.codigo === COD_STATUS_EXITO
      ? res.data.objeto
      : null;
  } catch (error) {
    return null;
  }
}

export async function obtenerPersonaPorRut(rutPersona, perfil) {
  try {
    let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/persona/buscarPorRut/${rutPersona}/perfil/${perfil}`;
    let respuesta = await services.get(url, false);

    return respuesta !== undefined &&
      respuesta !== null &&
      respuesta.data.status.codigo === COD_STATUS_EXITO
      ? respuesta.data.objeto
      : null;
  } catch (error) {
    return null;
  }
}

export async function actualizarConfiguracionNegocio(data) {
  try {
    let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/negocio/creaActualizaOrganizacion` ;
    let res = await services.post(url, data, false);

    return res !== undefined && res !== null && res.data.status.codigo === COD_STATUS_EXITO
      ? res.data.objeto
      : [];
  } catch (error) {
    return null;
  }
}

export async function actualizarConfiguracionSucursal(data) {
  try {
    let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/negocio/creaActualizaSucursal`;
    let res = await services.post(url, data, false);

    return res !== undefined && res !== null && res.data.status.codigo === COD_STATUS_EXITO
      ? res.data.objeto
      : null;
  } catch (error) {
    return null;
  }
}

export async function actualizarConfiguracionDelegado(data) {
  try {
    let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/persona/creaActualizaDelegado`;
    let res = await services.post(url, data, false);

    return res !== undefined && res !== null && res.data.status.codigo === COD_STATUS_EXITO
      ? res.data.objeto
      : null;
  } catch (error) {
    return null;
  }
}

export async function enviarEmailNotificacionEjecutivo(data) {
  try {
    let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/negocio/enviarEmailNominaEjecutivo`;
    let res = await services.post(url, data, false);

    return res !== undefined && res !== null && res.data.status.codigo === COD_STATUS_EXITO
      ? res.data.objeto
      : null;
  } catch (error) {
    return null;
  }
}

export async function buscarEmpresaPorRut(rutEmpresa) {
  try {
    let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/empresa/buscarPorRut/${rutEmpresa}`;
    let res = await services.get(url, false);

    return res !== undefined && res !== null && res.data.status.codigo === COD_STATUS_EXITO
      ? res.data.objeto
      : null;
  } catch (error) {
    return null;
  }
}

export async function buscarEmpresaPorRutEjecutivo(rut) {
  try {
    let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/empresa/buscarPorRutEjecutivo/${rut}`;                             
    let respuesta = await services.get(url, false);

    return respuesta !== undefined &&
      respuesta !== null &&
      respuesta.data.status.codigo === "001"
      ? respuesta.data.objeto
      : null;
  } catch (error) {
    return null;
  }
}

export async function enviarEncuestaSatisfaccion(data) {
  try {
    let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/negocio/crearEncuestaSatisfaccion`;
    let res = await services.post(url, data, false);

    return res !== undefined && res !== null && res.data.status.codigo === COD_STATUS_EXITO
      ? res.data.objeto
      : null;
  } catch (error) {
    return null;
  }
}

/*****************************************
 * * * HOMOLOGAR DATA PARA FRONT END     *
 *****************************************/
function capitalizeNombreEjecutivo(ejecutivos) {
  for (let e of ejecutivos) {
    let arrNombre = e.nombre.split(" ");
    let nombre = "";
    arrNombre.forEach((e) => {
      nombre += e.charAt(0).toUpperCase() + e.slice(1).toLowerCase();
    });
    e.nombre = nombre.replace(/([a-z])([A-Z])/g, "$1 $2");

    let arrApellidoMaterno = e.apellidoMaterno.split(" ");
    let apellidoMaterno = "";
    arrApellidoMaterno.forEach((e) => {
      apellidoMaterno += e.charAt(0).toUpperCase() + e.slice(1).toLowerCase();
    });
    e.apellidoMaterno = apellidoMaterno.replace(/([a-z])([A-Z])/g, "$1 $2");

    let arrApellidoPaterno = e.apellidoPaterno.split(" ");
    let apellidoPaterno = "";
    arrApellidoPaterno.forEach((e) => {
      apellidoPaterno += e.charAt(0).toUpperCase() + e.slice(1).toLowerCase();
    });
    e.apellidoPaterno = apellidoPaterno.replace(/([a-z])([A-Z])/g, "$1 $2");

    e.correoElectronico !== null
      ? (e.correoElectronico = e.correoElectronico.toLowerCase())
      : (e.mail = "-");
  }

  return ejecutivos;
}

function dataNegocios(negocios) {
  for (let n of negocios) {
    dataNegocio(n);
  }
  return negocios;
}

function dataNegocio(negocio) {
  if (negocio.estadoNegocio.idEstadoNegocio > VALOR_UNO) {
    if (negocio.ejecutivo) {
      negocio.ejecutivoAsignado = capitalize(
        `${negocio.ejecutivo.nombre} ${negocio.ejecutivo.apellidoPaterno}`
      );
      negocio.emailEjecutivoAsignado = negocio.ejecutivo.correoElectronico;
      negocio.idEjecutivoAsignado = negocio.ejecutivo.idPersona;
      negocio.key = uuid();
    } else {
      negocio.ejecutivoAsignado = `Ejecutivo no encontrado`;
      negocio.key = uuid();
    }
  } else {
    negocio.ejecutivoAsignado = `Por asignar`;
    negocio.key = uuid();
  }

  return negocio;
}

/***** CONFIGURACIÓN DEL NEGOCIO *****/
function homologarDataConfiguracionNegocio(data) {
  for (let d of data) {
    d.digitoVerificador = d.digitoVerificador.replace(/ /g, "");
  }
  return data;
}
