import ServicesFactory from "../config/servicesFactory";
import { SIN_DOCUMENTOS } from "../utils/Constantes";

let servicios = new ServicesFactory();

export async function guardarArchivos(data) {
  try {
    let url = `${process.env.REACT_APP_BUS_URL_DOCUMENTO}/repositorio/guardarDocto`;
    let respuesta = await servicios.post(url, data, false);

    return respuesta !== undefined && respuesta !== null && respuesta.data.status.codigo === "001"
      ? respuesta.data.objeto
      : null;
  } catch (error) {
    return null;
  }
}

export async function obtenerArchivos(data) {
  try {
    let url = `${process.env.REACT_APP_BUS_URL_DOCUMENTO}/repositorio/buscarDocto`;
    let respuesta = await servicios.post(url, data, false);

    return respuesta !== undefined && respuesta !== null && respuesta.data.status.codigo === "001"
      ? respuesta.data.objeto
      : respuesta.data.status.codigo === "002"
        ? SIN_DOCUMENTOS
        : null;
  } catch (error) {
    return null;
  }
}

export async function eliminarArchivo(idDocto) {
  try {
    let url = `${process.env.REACT_APP_BUS_URL_DOCUMENTO}/repositorio/eliminarDocto/${idDocto}`;
    let respuesta = await servicios.get(url, false);

    return respuesta !== undefined && respuesta !== null && respuesta.data.status.codigo === "001"
      ? (respuesta.data.objeto = { message: "Archivo eliminado" })
      : null;
  } catch (error) {
    return null;
  }
}