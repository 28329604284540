import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  guardar_cotizacion,
  mostrar_modal_archivos,
  mostrar_modal_fecha_enrolamiento,
  guardar_cotizaciones,
} from "../../../../../../../actions/actions";
import Authorized from "../../../../../../../utils/Authorized";
import moment from "moment";
import { ButtonToolbar, DropdownButton, Dropdown } from "react-bootstrap";
import {
  COD_JEFATURA,
  ID_NEGOCIO_EN_PROCESO,
  VALOR_ZERO,
  VALOR_TRES,
  VALOR_SIETE,
  PATROCINADORES_CONFIGURACION,
  SIN_PATROCINADOR
} from "../../../../../../../utils/Constantes";
import {
  RUTA_CONFIGURAR_PRODUCTO,
  ERROR_ELIMINAR_PRODUCTO,
  EXITO_ELIMINAR_PRODUCTO,
} from "../../../../../../../utils/ConstantesVentaDirecta";
import { notificacion } from "../../../../../transversales/Notificaciones";
import { actualizarConfigurarProducto } from "../../../../../../../services/producto.service";
import ModalEliminar from "../../../../../transversales/ModalEliminar";

class CotizacionNoDigital extends Component {
  constructor(props) {
    super(props);
    this.state = {
      perfilUsuario: this.props.store.auth.usuarioSesion.rol.codigo,
      negocio: this.props.negocio,
      cotizacion: this.props.cotizacion_,
      modalEliminar: false,
    };
  }

  eliminarProductoCotizacion = async () => {
    const dataEnvio = {
      idCotizacion: this.state.cotizacion.idCotizacion,
      idUsuarioModifica: this.props.store.auth.usuarioSesion.id,
      vigente: false,
    };
    const resEliminarProducto = await actualizarConfigurarProducto(dataEnvio);
    if (resEliminarProducto) {
      notificacion("success", EXITO_ELIMINAR_PRODUCTO);
      this.props.obtenerDetalleNegocio(this.state.negocio.idNegocio);
    }
    else notificacion("danger", ERROR_ELIMINAR_PRODUCTO);

    this.setState({ modalEliminar: false });
    return;
  };

  editarProductoCotizacion = async (cotizacion) => {
    await this.props.guardar_cotizacion(cotizacion);

    this.props.history.push({
      pathname: RUTA_CONFIGURAR_PRODUCTO,
      state: { editar: true },
    });
    return;
  };

  render() {

    const cotizacion = this.props.cotizacion_;
  
    const intIdPatrocinador = cotizacion.idPatrocinador > SIN_PATROCINADOR ? cotizacion.idPatrocinador : SIN_PATROCINADOR;
    const objConfiguracion  = PATROCINADORES_CONFIGURACION.find( (objPatrocinadorConf) => objPatrocinadorConf.id === intIdPatrocinador);
    const colorCotizacion = objConfiguracion.color;

    const noExisteContratoSeguroGrupo = !cotizacion.grupos.find(grupo => grupo.idContratoSeguroGrupo);
    let capitalCotizacion = { valorCapital: 0 };

    if (this.state.cotizacion.coberturas.length > VALOR_ZERO && this.state.cotizacion.coberturas[0] != null) {
      capitalCotizacion = this.state.cotizacion.coberturas[0]
        .cotizacionCobertura.capitales[0];
    }

    return (
      <React.Fragment>
        {/* MODAL ELIMINAR PRODUCTO COTIZACION */}
        {this.state.modalEliminar && (
          <ModalEliminar handleChange={this.eliminarProductoCotizacion} />
        )}

        {/* CARD COTIZACIÓN: DIRECTA/CORREDOR */}
        <div key={`cotizaciones_${this.props.indice}`} className="col-12">
          <div
            className="card p-3"
            style={{
              backgroundColor: colorCotizacion,
              borderRadius:
                this.props.mostrarDetalleCotizacion === true &&
                  cotizacion ===
                  this.props.cotizacionesBusqueda[this.props.posicionCotizacion]
                  ? "0.25rem 0.25rem 0 0"
                  : "0.25rem",
            }}
          >
            <span
              className="text-white font-weight-bold h6 m-0"
              style={{ cursor: "pointer" }}
              onClick={() => this.props.verCotizacion(cotizacion)}
            >
              <div className="row">
                <div className="col-6">
                  <span>{cotizacion.codigoCotizacion}</span>
                </div>
                <div className="col-6">
                  <div className="row">
                    <div className="col-11 text-right">
                      {cotizacion.subProducto.glosaSubProducto}
                    </div>
                    <div className="col-1">
                      {this.props.mostrarDetalleCotizacion === true &&
                        cotizacion ===
                        this.props.cotizacionesBusqueda[
                        this.props.posicionCotizacion
                        ] ? (
                        <i
                          className="fa fa-chevron-down d-block text-right animated fadeIn"
                          aria-hidden="true"
                        ></i>
                      ) : (
                        <i
                          className="fa fa-chevron-right d-block text-right animated fadeIn"
                          aria-hidden="true"
                        ></i>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </span>
          </div>

          <div
            className="mb-2 mt-0 card"
            style={{
              borderRadius: "0 0 0.25rem 0.25rem",
            }}
          >
            {this.props.mostrarDetalleCotizacion === true &&
              cotizacion ===
              this.props.cotizacionesBusqueda[
              this.props.posicionCotizacion
              ] && (
                <div>
                  <div className="row shadow no-gutters animated fadeIn py-4">
                    <div
                      className="col-12 col-md-3 text-center px-2"
                      style={{ borderRight: "1px solid #4444" }}
                    >
                      <p
                        className="font-weight-bold text-muted mb-0"
                        style={{ fontSize: "14px" }}
                      >
                        {this.state.cotizacion.descripcionPlan}
                      </p>

                      <p
                        className="font-weight-bold h3"
                        style={{ color: colorCotizacion }}
                      >
                        {this.state.cotizacion.valorPrimaNeta} UF
                      </p>
                    </div>

                    <div
                      className="col-12 col-md-3 px-3 text-center text-md-left"
                      style={{
                        borderRight: "1px solid #4444",
                        fontSize: "12px",
                      }}
                    >
                      <span className="font-weight-bold text-muted mr-1">
                        Fecha cotización:
                      </span>
                      {this.state.cotizacion.fechaCotizacion
                        ? moment(this.state.cotizacion.fechaCotizacion).format(
                          "DD-MM-YYYY"
                        )
                        : "-"}
                      <br />

                      <span className="font-weight-bold text-muted mr-1">
                        Fecha inicio vigencia:
                      </span>
                      {this.state.negocio.fechaInicioVigencia
                        ? moment(this.state.negocio.fechaInicioVigencia).format(
                          "DD-MM-YYYY"
                        )
                        : "-"}
                    </div>

                    <div
                      className="col-12 col-md-3 px-3 text-center text-md-left"
                      style={{
                        borderRight: "1px solid #4444",
                        fontSize: "12px",
                      }}
                    >
                      <span className="font-weight-bold text-muted mr-1">
                        Número de trabajadores:
                      </span>
                      {this.state.cotizacion.numeroDotacion}

                      {this.state.cotizacion.tipoCapital && capitalCotizacion && (
                        <Fragment>
                          <br />
                          <span className="font-weight-bold text-muted mr-1">
                            Tipo capital:
                          </span>
                          {this.state.cotizacion.tipoCapital.glosa}
                          <br />
                          <span className="font-weight-bold text-muted mr-1">
                            Valor capital:
                          </span>
                          {capitalCotizacion.valorCapital} <br />
                        </Fragment>
                      )}
                    </div>

                    <div className="col-12 col-md-3 mt-3 mt-md-0">
                      <ButtonToolbar className="text-center justify-content-center mt-2">
                        <DropdownButton
                          id="opciones"
                          drop="up"
                          title="Opciones"
                          disabled={this.state.perfilUsuario === COD_JEFATURA}
                          variant="dark btn-sm"
                        >
                          {/* Opciones */}
                          <Authorized roles={[VALOR_TRES, VALOR_SIETE]}>
                            <Dropdown.Item
                              id="configurarProducto"
                              disabled={this.props.store.reducer.negocio
                                .estadoNegocio.idEstadoNegocio >= ID_NEGOCIO_EN_PROCESO &&
                                !(this.props.store.reducer.negocio
                                  .estadoNegocio.idEstadoNegocio >= ID_NEGOCIO_EN_PROCESO && noExisteContratoSeguroGrupo)}
                              onClick={() =>
                                this.editarProductoCotizacion(cotizacion)
                              }
                            >
                              Configurar datos del producto
                            </Dropdown.Item>
                            <Dropdown.Item
                              id="eliminar"
                              disabled={this.props.store.reducer.negocio
                                .estadoNegocio.idEstadoNegocio >= 3}
                              onClick={() => {
                                this.setState({ modalEliminar: true });
                              }}
                            >
                              Eliminar producto
                            </Dropdown.Item>
                          </Authorized>
                        </DropdownButton>
                      </ButtonToolbar>
                    </div>
                  </div>
                  <div className="col-12 shadow no-gutters animated fadeIn py-4" style={{ marginTop: 5 }}>
                    <div className="row col-12" style={{ paddingRight: 0, paddingLeft: 10 }}>
                      <div
                        className="col-3 col-md-3 text-center px-2"
                        style={{ borderRight: "1px solid #4444", display: 'flex', alignItems: 'center' }}
                      >
                        <p
                          className="font-weight-bold h3"
                          style={{ color: "#707070", width: '100%' }}
                        >
                          Coberturas
                        </p>
                      </div>
                      <div className="col-9 px-2" >
                        <div className="row">
                          {this.state.cotizacion.coberturas.map((cobertura, indice) => (
                            <label
                              className="font-weight-bold"
                              key={`${cobertura.glosaCobertura}_${indice}`}
                              style={{
                                color: "#FFFFFF", background: "#ED8D34", marginLeft: 15,
                                borderRadius: 4, padding: 6
                              }}>
                              {cobertura.glosaCobertura}
                            </label>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state,
});

export default withRouter(
  connect(mapStateToProps, {
    guardar_cotizacion,
    mostrar_modal_archivos,
    mostrar_modal_fecha_enrolamiento,
    guardar_cotizaciones,
  })(CotizacionNoDigital)
);
