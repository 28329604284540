import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Sidebar from "./Sidebar";
import Router from "../../../utils/Router";
import Spinner from "../transversales/Spinner";

class Contenido extends Component {
  constructor(props) {
    super(props);
    this.props.store.reducer.modalCotizacionesNegocio = {
      verModal: false,
    };
    this.state = {
      cargando: true,
      codPerfil: this.props.store.auth.usuarioSesion.codigoRol,
    };
  }

  componentDidMount() {
    this.setState({ cargando: false });
  }

  render() {
    const { reducer } = this.props.store;
    const verSidebar = reducer.show_menu;
    return (
      <React.Fragment>
        {this.state.cargando === true ? (
          <div style={{ height: reducer.DOM.height - 150 + "px" }}>
            <Spinner
              claseColor="naranjo"
              idSpinner="spinnerNaranjo"
              posicionLogo="align-self-center w-100"
            />
          </div>
        ) : (
            <div id="content" className="animated fadeIn">
              {verSidebar && (
                <div className={`sidebar ${verSidebar ? "" : "hide"}`}>
                  <Sidebar menu={this.props.menu} />
                </div>
              )}
              
              <div className="content pt-4 pl-4 pr-4" id="spinnerInterior">
                <div id="spinnerTransparente" className="body-content">
                  <Router routes={this.props.routes} />
                </div>
              </div>
            </div>        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state,
});

export default withRouter(connect(mapStateToProps, {})(Contenido));
