import React, { useState, Fragment } from "react";
import { enviarEncuestaSatisfaccion } from "../../../services/negocios.service";
import {
  ERROR_SOLICITUD,
  PERFIL_ASEGURABLE,
  PERFIL_DELEGADO,
  VALOR_ZERO,
} from "../../../utils/Constantes";
import { EXITO_ENVIO_ENCUESTA } from "../../../utils/ConstantesVentaDirecta";
import "../../assets/css/encuesta-satisfaccion.css";
import { notificacion } from "./Notificaciones";

const EncuestaSatisfaccion = (props) => {
  const [opcion, setOpcion] = useState(0);
  const [observacion, setObservacion] = useState("");
  const [cargando, setCargando] = useState(false);

  const onSubmit = async () => {
    if (opcion !== VALOR_ZERO && observacion.length <= 140) {
      let dataEncuestaSatisfaccion = {
        observacion,
        idCalificacion: opcion,
      };

      const { idAsegurable, rutAsegurable, idNegocio } = props;

      if (idAsegurable && rutAsegurable) {
        dataEncuestaSatisfaccion.idAsegurable = idAsegurable;
        dataEncuestaSatisfaccion.rutAsegurable = rutAsegurable;
        dataEncuestaSatisfaccion.perfil = PERFIL_ASEGURABLE;
      }
      if (idNegocio) {
        dataEncuestaSatisfaccion.idNegocio = idNegocio;
        dataEncuestaSatisfaccion.perfil = PERFIL_DELEGADO;
      }

      setCargando(true);
      const respuesta = await enviarEncuestaSatisfaccion(
        dataEncuestaSatisfaccion
      );
      
      if (respuesta) {
        notificacion("success", EXITO_ENVIO_ENCUESTA);
        setTimeout(() => {
          setCargando(false);
          props.validarEncuestaEnviada();
        }, 3000);
      } else {
        notificacion("danger", ERROR_SOLICITUD);
      }
    }
  };

  return (
    <Fragment>
      <div className="animated fadeIn">
        <h3 className="text-primary text-center mb-5">
          <span className="text-muted">
            Para nosotros es importante medir la satisfacción de nuestros
            clientes, ayúdanos a mejorar{" "}
          </span>
          <b>¿Cómo evaluarías el proceso de enrolamiento?</b>
        </h3>

        <div id="chat">
          <div className="csat-rater">
            <ul className="csat-rater__stars">
              <li
                id="opcionCinco"
                className={`csat-rater__star ${
                  opcion === 5 ? "csat_selected" : ""
                }`}
                onClick={() => setOpcion(5)}
              ></li>
              <li
                id="opcionCuatro"
                className={`csat-rater__star ${
                  opcion >= 4 ? "csat_selected" : ""
                }`}
                onClick={() => setOpcion(4)}
              ></li>
              <li
                id="opcionTres"
                className={`csat-rater__star ${
                  opcion >= 3 ? "csat_selected" : ""
                }`}
                onClick={() => setOpcion(3)}
              ></li>
              <li
                id="opcionDos"
                className={`csat-rater__star ${
                  opcion >= 2 ? "csat_selected" : ""
                }`}
                onClick={() => setOpcion(2)}
              ></li>
              <li
                id="opcionUno"
                className={`csat-rater__star ${
                  opcion >= 1 ? "csat_selected" : ""
                }`}
                onClick={() => setOpcion(1)}
              ></li>
            </ul>
          </div>
        </div>

        <textarea
          id="observacion"
          className="form form-control"
          placeholder="Danos tu opinión de como fue tu experiencia usando esta herramienta... (opcional)"
          rows="3"
          onChange={(e) => setObservacion(e.target.value)}
        ></textarea>
        <span className="d-block text-right mt-1">
          Cantidad de caracteres{" "}
          <span
            className={
              observacion.length > 140 ? "text-danger animated fadeIn" : ""
            }
          >
            {observacion.length}{" "}
          </span>
          / 140
        </span>

        <button
          id="botonEncuestaSatisfaccion"
          className="btn btn-principal btn-sm d-block mx-auto mt-3"
          type="button"
          disabled={cargando}
          onClick={onSubmit}
        >
          Enviar
        </button>
      </div>
    </Fragment>
  );
};

export default EncuestaSatisfaccion;
