import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
    STORAGE_ITEM_NEGOCIO,
    STORAGE_ITEM_RUTA,
    COD_DELEGADO,
    RUT_DELEGADO,
    PERFIL_DELEGADO,
    COD_CORREDOR,
    USU_CORREDOR,
    PERFIL_CORREDOR,
    COD_EJECUTIVO_MANTENCION,
    COD_EJECUTIVO_COMPLETO,
    RUT_EJECUTIVO,
    PERFIL_LDAP,
    VALOR_ZERO,
    VALOR_UNO,
    RUTA_EJECUTIVO_INCORPORACION,
    TIPO_INCORPORACION_MANTENCION,
    RUTA_EJECUTIVO_EXCLUSION,
    RUTA_EJECUTIVO_VER_SOLICITUDES,
    RUTA_ABSOLUTA_ESTADO_ENROLAMIENTO,
    RUTA_ABSOLUTA_INCORPORACION,
    RUTA_ABSOLUTA_VER_SOLICITUDES,
    RUTA_ABSOLUTA_EXCLUSION,
    TIPO_CANAL_VENTA_DIGITAL,
    STORAGE_ITEM_FILTRO_CANAL_VENTA
} from "../../../utils/Constantes";
import { obtenerNegocios } from "../../../services/cotizacion.services";
import {
    buscarNegociosMantencionPoliza,
    buscarNegociosCorredorPoliza,
} from "../../../services/poliza.service";
import {
    buscarEmpresaPorRutEjecutivo,
} from "../../../services/negocios.service";
import {
    guardar_negocios,
    guardar_menu_ejecutivo_completo
} from "../../../actions/actions";
import Spinner from "../transversales/Spinner";
import CargaIndividualMantencionExclusion from "../perfiles/delegado/ingresarAsegurables/cargaIndividual/CargaIndividualMantencionExclusion";
import CargaIndividualMantencionIncorporacion from "../perfiles/delegado/ingresarAsegurables/cargaIndividual/CargaIndividualMantencionIncorporacion";
import {CargaIndividualVentaDirecta} from "../perfiles/delegado/ingresarAsegurables/cargaIndividual/CargaIndividualVentaDirecta";
import MisSolicitudes from "../perfiles/delegado/misProcesos/MisSolicitudes";
import {CargaIndividual
 } from "../perfiles/delegado/ingresarAsegurables/cargaIndividual/CargaIndividual";



class CargaContratantePerfilCompleto extends Component {

    constructor(props) {
        super(props);
        this.state = {
            idNegocio: 0,
            polizas: [],
            polizasSeleccionadas: [],
            fechaInicioVigencia: null,
            solicitudes: [],
            data: {
                polizas: [],
                rut: "",
                nombres: "",
                apellidoMaterno: "",
                apellidoPaterno: "",
                email: "",
            },
            modalCantidadAsegurables: false,
            maximoAsegurables: null,
            polizasFechaErronea: [],
            stringPolizasFechaErronea: "",
            cargandoAsegurable: false,
            rutContratante: "",
            arrayListaDoctos: [],
            cargaArchivo: false,
            mostrarArchivosCargados: true,
            minFechaVigencia: "",
            maxFechaVigencia: "",
            checkedMovRetro: false,
            nombreDocumento: "DOCTO_TEMPORAL",
            aceptacion:
                this.props.store.auth.usuarioSesion.rol.codigo === COD_EJECUTIVO_MANTENCION ||
                this.props.store.auth.usuarioSesion.rol.codigo === COD_EJECUTIVO_COMPLETO,
            limpiarArchivos: false,
            limpiarAceptacion: false,
            cargando: true,
            incorporacion: props.location.pathname.includes(RUTA_ABSOLUTA_INCORPORACION) ||
                props.location.pathname.includes(RUTA_ABSOLUTA_ESTADO_ENROLAMIENTO),
            exclusion: props.location.pathname.includes(RUTA_ABSOLUTA_EXCLUSION),
            verSolicitudes: props.location.pathname.includes(RUTA_ABSOLUTA_VER_SOLICITUDES),
        };
    }

    async componentDidMount() {

        await this.obtenerNegocios();
        this.setState({
            cargando: false
        })
    }

    obtenerNegocios = async () => {
        sessionStorage.removeItem(STORAGE_ITEM_NEGOCIO);
        sessionStorage.removeItem(STORAGE_ITEM_FILTRO_CANAL_VENTA);
        sessionStorage.removeItem(STORAGE_ITEM_RUTA);

        let { usuarioSesion } = this.props.store.auth;
        let { negocios } = this.props.store.reducer;
        const rolId = usuarioSesion.rol.codigo;

        let campoPerfil, perfil, idValue;

        if (rolId === COD_DELEGADO) {

            campoPerfil = RUT_DELEGADO;
            perfil = PERFIL_DELEGADO;
            idValue = usuarioSesion.rut

        } else if (rolId === COD_CORREDOR) {

            campoPerfil = USU_CORREDOR;
            perfil = PERFIL_CORREDOR;
            idValue = usuarioSesion.id

        } else if (rolId === COD_EJECUTIVO_MANTENCION || rolId === COD_EJECUTIVO_COMPLETO) {

            campoPerfil = RUT_EJECUTIVO;
            perfil = PERFIL_LDAP;
            idValue = usuarioSesion.rut
        }

        const dataEnvio = {
            negocio: { vigente: true },
            [campoPerfil]: idValue,
            perfil
        };

        negocios = await obtenerNegocios(dataEnvio);

        // Comprobar si hay negocios en estado Cierre existentes en susdig_Negocio (Negocios Mantención)
        this.buscarNegociosMantencionSuscripcion(negocios);

        // Consultar Negocios Mantención a través de API POLIZA
        let negociosPoliza;
        if (rolId === COD_DELEGADO) {
            negociosPoliza = await buscarNegociosMantencionPoliza(usuarioSesion);
        } else if (rolId === COD_CORREDOR) {
            negociosPoliza = await buscarNegociosCorredorPoliza(usuarioSesion, true);
        } else {
            negociosPoliza = await buscarEmpresaPorRutEjecutivo(usuarioSesion.rut);
        }

        if (negociosPoliza && negociosPoliza.length > VALOR_ZERO) {
            negociosPoliza.forEach((negocio) => {
                const negocioMantencion = this.state.negociosMantencion.find(
                    (negocioManto) => negocio.rutEmpresa === negocioManto.rutEmpresa
                );
                if (negocioMantencion !== undefined && rolId === COD_DELEGADO) {
                    negocio.declaracionDelegado = negocioMantencion.declaracionDelegado;
                }

                if (negocioMantencion !== undefined && rolId === COD_CORREDOR) {
                    negocio.declaracionesPerfil = negocioMantencion.declaracionesPerfil;
                }
            });
            if (rolId !== COD_EJECUTIVO_COMPLETO) {
                negociosPoliza = negociosPoliza.concat(this.state.negociosMantencion);
            }
            this.setState({ negociosMantencion: negociosPoliza });
        }

        //Eliminar negocios mantención repetidos
        if (this.state.negociosMantencion.length > VALOR_UNO) {
            const negociosMantencion = this.eliminarNegociosRepetidos(
                this.state.negociosMantencion
            );
            this.setState({ negociosMantencion });
        }

        // Comprobar tipo de incorporación según tipo negocios si es mixto se levanta modal para realizar elección
        if (rolId === COD_EJECUTIVO_MANTENCION || rolId === COD_EJECUTIVO_COMPLETO) {
            this.props.guardar_negocios(negociosPoliza);
            if (this.state.incorporacion) {
                if (this.props.location.pathname.includes(RUTA_ABSOLUTA_INCORPORACION)) {
                    this.props.guardar_menu_ejecutivo_completo(VALOR_ZERO);
                    this.props.history.push(RUTA_EJECUTIVO_INCORPORACION);
                } else {
                    this.props.history.push(RUTA_ABSOLUTA_ESTADO_ENROLAMIENTO);
                }
            } else if (this.state.exclusion) {
                this.props.history.push(RUTA_EJECUTIVO_EXCLUSION);
            } else {
                this.props.history.push(RUTA_EJECUTIVO_VER_SOLICITUDES);
            }
        } else {
            this.comprobarTipoProceso();
        }

    };

    buscarNegociosMantencionSuscripcion(negocios) {
        let negociosMantencion = [];
        let negociosDigital = [];

        negocios.forEach((e) => {
            if (
                e.tipoIncorporacion.idTipoIncorporacion ===
                TIPO_INCORPORACION_MANTENCION
            )
                negociosMantencion.push(e);
            else negociosDigital.push(e);
        });
        this.setState({ negociosMantencion, negociosDigital });
    }

    eliminarNegociosRepetidos(negocios) {
        let flag = {};
        negocios = negocios.filter((e) => {
            if (!e.idNegocio) {
                let exists = !flag[e.rutEmpresa] || false;
                flag[e.rutEmpresa] = true;
                return exists;
            } else return null;
        });
        return negocios;
    }



    render() {
        return (
            <React.Fragment>
                {this.state.cargando ? (
                    <Spinner
                        claseColor="transparente"
                        idSpinner="spinnerTransparente"
                        posicionLogo="posicionLogo align-self-center w-100"
                    />
                ) : (
                    <div>
                        {this.state.incorporacion && (
                            this.props.location.pathname.includes(RUTA_ABSOLUTA_INCORPORACION) ?
                                <CargaIndividualMantencionIncorporacion /> :
                                this.props.store.reducer.negocio.canalVenta.idCanalVenta === TIPO_CANAL_VENTA_DIGITAL ? <CargaIndividual /> : <CargaIndividualVentaDirecta />
                        )
                        }
                        {this.state.exclusion && <CargaIndividualMantencionExclusion />}
                        {this.state.verSolicitudes && <MisSolicitudes />}
                    </div>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    store: state,
});

export default withRouter(
    connect(mapStateToProps, {
        guardar_negocios,
        guardar_menu_ejecutivo_completo,
    })(CargaContratantePerfilCompleto)
);
