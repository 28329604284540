/**************************
 *  CREAR NEGOCIO         *
 **************************/
const iconoEmpresa = require("../app/assets/images/datos-empresa.svg");
const iconoSucursal = require("../app/assets/images/sucursal.svg");
const iconoDelegado = require("../app/assets/images/delegate.svg");

export const WIZARD_NEGOCIO_VENTA_DIRECTA = [
  { paso: 1, estado: "doing", glosa: "Empresa", icon: iconoEmpresa },
  { paso: 2, estado: "todo", glosa: "Sucursal", icon: iconoSucursal },
  { paso: 3, estado: "todo", glosa: "Delegado", icon: iconoDelegado },
];

export const MESSAGE_SEND = "Estamos configurando empresa";
export const MESSAGE_SEARCH_EMPRESA = "Buscando empresa en servidor...";
export const MESSAGE_SEARCH_DELEGADO = "Buscando persona en servidor...";

export const P_WZ1 = 0;
export const P_WZ2 = 1;
export const P_WZ3 = 2;

export const WZ1 = 1;
export const WZ2 = 2;
export const WZ3 = 3;

export const TODO = "todo";
export const DOING = "doing";
export const DONE = "done";
export const RUT = "rut";
export const RAZON_SOCIAL = "razonSocial";

export const LABEL_SUCURSAL = "Seleccione sucursal";
export const LABEL_NUEVA_SUCURSAL = "Nueva sucursal";


export const FEMENINO = "F";
export const MASCULINO = "M";

export const INITIAL_STATE_SUCURSAL = {
  sucursal: {
    value: -1,
    label: "Seleccione sucursal",
  },
  nombreSucursal: "",
  direccion: "",
  numero: "",
  complemento: "",
  region: "0",
  comuna: "",
  telefono: "",
  email: "",
};

export const R_CREAR_NEGOCIO = "crear-negocio";

/**************************
 *  FORMULARIOS WIZARD    *
 **************************/
export const EMPRESA_EXISTENTE = "Empresa encontrada en registros de Vida Cámara";
export const EMPRESA_INEXISTENTE = "Empresa no existe en registros de Vida Cámara, favor completar formulario";
export const SIN_DELEGADO_ASIGNADO = "SIN DELEGADO ASIGNADO";

/*********************************
 *  MENSAJES CREACION NEGOCIO    *
 *********************************/
export const EXITO_EMPRESA_EXISTENTE = "Esta empresa ya ha sido ingresada, y existe dentro de nuestros registros";
export const EXITO_EMPRESA_CREADA = "Empresa ha sido configurada correctamente en Vida Cámara";
export const EXITO_ENVIO_ENCUESTA = "Gracias por ayúdarnos a mejorar. Se ha cerrado su sesión."

/****************************
 *  CONFIGURACION PRODUCTO  *
 ****************************/
export const RUTA_STORAGE_CONFIGURAR_PRODUCTO = "cotizaciones/configurar-producto";
export const PRODUCTO_VIDA = 1;
export const PRODUCTO_VIDA_PYME = 10;
export const PRODUCTO_SALUD = 25;
export const PRODUCTO_SALUD_PYME = 26;

export const EXITO_CREAR_PRODUCTO = "Se realizado la configuración del producto correctamente";
export const EXITO_ACTUALIZAR_PRODUCTO = "Se ha actualizado la configuración del producto correctamente";
export const EXITO_ELIMINAR_PRODUCTO = "Se ha eliminado la configuración del producto correctamente";
export const ERROR_ELIMINAR_PRODUCTO = "No se ha podido eliminar el producto. Favor intente más tarde";
export const ERROR_ENVIO_PRODUCTO = "No se ha podido configurar el producto. Favor intente más tarde";

export const COBERTURA_INVALIDEZ_23 = 12;
export const COBERTURA_INVALIDEZ_80 = 13;

export const VARIABLE_UF = "VARIABLE UF";
export const VARIABLE_RENTA = "VARIABLE RENTA";

/****************************
 *  RUTAS  *
 ****************************/
export const RUTA_COTIZACIONES_INICIO = "/cotizaciones/inicio";
export const RUTA_CONFIGURAR_PRODUCTO = "/cotizaciones/configurar-producto";


/**************************
 *  SESSION STORE         *
 **************************/
export const RUTA_STORAGE_COTIZACIONES = "cotizaciones/inicio";

export const STORAGE_WIZARD = "_cnWzVC";
export const STORAGE_RUTA = "_nRuVC";

export const STORAGE_WIZARD_ACTUAL = "_cnWzAcVC";

export const STORAGE_CANAL_VENTA = "_cnCvVC";

export const STORAGE_EMPRESA = "_cnFEmpVC";
export const STORAGE_SUCURSAL = "_cnFSucVC";


/**************************
 *  OPCIONES GRÁFICOS     *
 **************************/
export const OPCIONES_DEFAULT_GRAFICO = {
  legend: {
    position: "bottom",
  },
};

export const OPCIONES_VENTA_DIRECTA_GRAFICO = {
  legend: {
    position: "bottom",
  },
  title: {
    display: "none",
    text: "Estado de enrolamiento",
    position: "bottom",
  },
};