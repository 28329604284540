import { 
	ESCOGER_INCORPORACION,
	ESCOGER_PERFIL, 
	FORM_INICIO_SESION, 
	PERFILES_INICIO_SESION, 
	TIPO_INCORPORACION, 
	TIPO_INCORPORACION_PREVIO, 
	TIPO_USUARIO, 
	USUARIO_AUTENTICADO, 
	USUARIO_PERFILES, 
	USUARIO_SESION
} from "../actions/typesAuth";
import { 
	CONFIGURACION_INICIAL_SESION,
	LISTA_PERFILES_INICIO_SESION 
} from "../utils/Constantes";

const objEstadoInicial = {
	perfilesInicioSesion: LISTA_PERFILES_INICIO_SESION,
	escogerPerfil: true,
	tipoUsuario: null,
	formInicioSesion: CONFIGURACION_INICIAL_SESION,
	usuarioSesion: null,
	usuarioPerfiles: [],
	usuarioAutenticado: false,
	escogerIncorporacion: false,
	tipoIncorporacion: 0,
	tipoIncorporacionPrevio: 0,
};

export default function AuthReducer(objEstado, objAccion) {

	objEstado = objEstadoInicial;

	switch (objAccion.type) {

		case TIPO_USUARIO:
			objEstado.tipoUsuario = objAccion.contenido;
			return {...objEstado};
	
		case PERFILES_INICIO_SESION:
			objEstado.perfilesInicioSesion = objAccion.contenido;
			return {...objEstado};

		case ESCOGER_PERFIL:
			objEstado.escogerPerfil = objAccion.contenido;
			return {...objEstado};

		case FORM_INICIO_SESION:
			objEstado.formInicioSesion = objAccion.contenido;
			return {...objEstado};

		case USUARIO_SESION:
			objEstado.usuarioSesion = objAccion.contenido;
			return {...objEstado};

		case USUARIO_PERFILES:
			objEstado.usuarioPerfiles = objAccion.contenido;
			return {...objEstado};

		case USUARIO_AUTENTICADO:
			objEstado.usuarioAutenticado = objAccion.contenido;
			return {...objEstado};

		case ESCOGER_INCORPORACION:
			objEstado.escogerIncorporacion = objAccion.contenido;
			return {...objEstado};

		case TIPO_INCORPORACION:
			objEstado.tipoIncorporacion = objAccion.contenido;
			return {...objEstado};

		case TIPO_INCORPORACION_PREVIO:
			objEstado.tipoIncorporacionPrevio = objAccion.contenido;
			return {...objEstado};

		default:
			return objEstado;
	}
}