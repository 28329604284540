import React from "react";
import { Router } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store/store";
import Main from "./app/components/Main";
import ReactNotification from "react-notifications-component";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "./app/assets/css/estilos.css";
import "./app/assets/css/modal.css";
import "./app/assets/css/font-awesome.min.css";
import "./app/assets/css/animate.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./app/assets/css/icons.css";
import "./app/assets/css/confirm.css";
import "./app/assets/css/file-input.css";
import "./app/assets/css/buttons.css";
import "react-notifications-component/dist/theme.css";
import { createBrowserHistory as createHistory } from "history";

const history = createHistory();

function App() {

  return (
    <Provider store={store}>
      <ReactNotification />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnVisibilityChange={false}
        draggable={false}
        pauseOnHover
      />
      <Router history={history}>
        <Main />
      </Router>
    </Provider>
  );
}

export default App;
