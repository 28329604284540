import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { DECLARACION_ACEPTACION } from "../../../../utils/Constantes";

const DeclaracionAceptacion = (props) => {
  const [aceptacion, setAceptacion] = useState(false);
  useEffect(() =>{
    if(props.limpiar){
      setAceptacion(false);
    } 
  }, [props.limpiar])

  const cargarAceptacion = () => {
    setAceptacion(!aceptacion);
    props.aceptacion(aceptacion);
  };

  const rolId = props.rolId;
  const cargaMasiva = props.cargaMasiva;

  return (
    <React.Fragment>
      {(rolId === props.codPerfil[0] || rolId === props.codPerfil[1]) && (
        <div className="col-md-12" style={{ marginTop: "15px" }}>
          {!cargaMasiva ? (
            <div className="form-group">
              <div
                style={{
                  float: "left",
                  marginRight: "10px",
                }}
              >
                <label className="switch">
                  <input
                    id="checkboxDeclaroConocimiento"
                    checked={aceptacion}
                    onChange={cargarAceptacion}
                    type="checkbox"
                  />
                  <span className="slider round" />
                </label>
              </div>
              <div
                style={{
                  display: "flow-root",
                  textAlign: "justify",
                }}
              >
                <span id="textDeclaroConocimiento" onClick={cargarAceptacion}>
                  {DECLARACION_ACEPTACION}
                </span>
              </div>
            </div>
          ) : (
            <div className="form-group">
              <div className="col-md-12">
                <div
                  style={{
                    float: "left",
                    marginRight: "10px",
                  }}
                >
                  <label className="switch">
                    <input
                      id="checkboxDeclaroConocimiento"
                      checked={aceptacion}
                      onChange={cargarAceptacion}
                      type="checkbox"
                    />
                    <span className="slider round" />
                  </label>
                </div>
                <div
                  style={{
                    display: "flow-root",
                    textAlign: "justify",
                  }}
                >
                  <span id="textDeclaroConocimiento" onClick={cargarAceptacion}>
                    {DECLARACION_ACEPTACION}
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  store: state,
});

export default withRouter(connect(mapStateToProps, {})(DeclaracionAceptacion));
