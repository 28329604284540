import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import moment from "moment";
import {
  VALIDACION_ASEGURABLE,
  VALIDACION_ENFERMEDAD,
  NO_DECLARA,
  CAMPO_REQUERIDO_ASEGURADO,
  MASK_DATE,
  W2_COLS_SALUD,
  W2_COLS_SALUD_2,
  CAMPO_REQUERIDO_DIAGNOSTICO,
  ID_CUATRO,
  VALOR_ZERO,
  NO_DECLARA_PREEXISTENCIA,
  VALIDACION_FECHA_DIAGNOSTICO,
  CAMPO_REQUERIDO_PATOLOGIA,
  STRING_VACIO,
  TIPO_CANAL_VENTA_DIRECTA,
  TIPO_CANAL_VENTA_CORREDOR,
  VALOR_UNO,
  ID_OCHO,
} from "../../../../../../utils/Constantes";
import {
  wizard,
  add_detalle,
  update_detalle,
  cambio_detalle,
  guardar_data_asegurable,
  guardar_data_wizard_asegurado,
  update_carga,
} from "../../../../../../actions/actions";
import DatePicker from "react-datepicker";
import MaskedTextInput from "react-text-mask";
import Table from "../../../../transversales/DataTable";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import "moment/locale/es";

import { ModalEjemplosSalud } from "./ModalEjemplosSalud";
import { CatalogoPregunta } from "../../CatalogoPregunta";
import { eliminarDeclaracion, procesarDeclaracion } from "../../../../../../services/declaracion.services";

const iconEjemplo = require("../../../../../assets/images/ejemplo.png");
const imgWarning = require("../../../../../assets/images/img-warning 1.png");

class PreguntaSalud extends Component {
  columnas =
    this.props.informacion.catalogoPregunta.idPregunta === ID_CUATRO ||
    this.props.informacion.catalogoPregunta.idPregunta === ID_OCHO
      ? W2_COLS_SALUD
      : W2_COLS_SALUD_2;

  constructor(props) {
    super(props);
    this.aceptacionRef = React.createRef();
    this.state = {
      checked: null,
      edit: 0,
      aceptacionCheck:
        this.props.store.reducer.dataWizardAsegurado.solicitud.declaroSalud !==
        null,
      claseAceptacion: "",
      declaraDeclaracion: null,
      animacion: "formInit",
      agregar: true,
      declaracionComplementaria: {
        key: null,
        rut: "",
        idCatalogoTipoDeclaracion: 0,
        causa: "",
        fechaDiagnostico: "",
        fechaDiagnosticoFormat: "",
        fechaNacimiento: "",
        //nueva variable
        finalizoTratamientoRadical: null,
      },
      detalle: [],
      cambioDetalle: false,
      informacion: null,
      columnDefs: [
        ...this.columnas,
        {
          cell: (row) => (
            <React.Fragment>
              <button
                id="botonEditarSalud"
                className="btn btn-primary btn-sm btn-table mr-2"
                type="button"
                title="Editar"
                raised="true"
                primary="true"
                onClick={() => this.handleActionChange(row)}
              >
                <i className="fa fa-pencil" aria-hidden="true"></i>
              </button>
              <button
                id="botonEliminarSalud"
                className="btn btn-danger btn-sm btn-table"
                type="button"
                title="Eliminar"
                raised="true"
                primary="true"
                onClick={() => this.confirm(row)}
              >
                <i className="fa fa-trash" aria-hidden="true"></i>
              </button>
            </React.Fragment>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
      ],
      declaracionCarga: false,
      modalEjDeclaracion: false,
      //nueva variable
      seleccionEjDeclaracion: {},
      validacionDeclaracion: null,
      //modal no
      modalPatologica: false,
      validacionOncologicoAceptado: false,
      preguntaPatologica: {},
    };
  }

  componentWillMount() {
    this.setState(
      {
        informacion: this.props.informacion,
        checked:
          this.props.store.reducer.objetoChecks[this.props.indexCheck]
            .declaracionCheck,
        aceptacionCheck:
          this.props.store.reducer.objetoChecks[this.props.indexCheck]
            .aceptacionCheck,
      },
      () => {
        const check = this.props.store.reducer.objetoChecks;

        if (check && check[this.props.indexCheck].detalle.length > VALOR_ZERO) {
          this.props.check(
            check[this.props.indexCheck].aceptacionCheck,
            this.state.informacion.catalogoPregunta.idPregunta
          );
        }
      }
    );
  }

  componentDidMount() {
    let idPreguntaSeccionCobertura =
      this.state.informacion.idPreguntaSeccionCobertura;

    const detalleOriginal =
      this.props.store.reducer.configuracionDeclaraciones.detalle.filter(
        (detalles) =>
          detalles.idPreguntaSeccionCobertura === idPreguntaSeccionCobertura &&
          detalles.catalogoDeclaracion.declaracion !== NO_DECLARA
      );

    let declaraDeclaracion;

    if (detalleOriginal.length > VALOR_ZERO) {
      if (
        detalleOriginal[0].catalogoDeclaracion.idCatalogoTipoDeclaracion ===
        NO_DECLARA_PREEXISTENCIA
      ) {
        declaraDeclaracion = false;
      } else {
        declaraDeclaracion = true;
      }
    } else {
      declaraDeclaracion = null;
    }

    let detalle = [];
    const tipoCanal =
      this.props.store.reducer.data_asegurable.canalVenta.idCanalVenta;
    if (
      tipoCanal === TIPO_CANAL_VENTA_DIRECTA ||
      tipoCanal === TIPO_CANAL_VENTA_CORREDOR
    ) {
      detalle = detalleOriginal.filter(
        (detalles) =>
          detalles.catalogoDeclaracion.idCatalogoTipoDeclaracion !==
            NO_DECLARA_PREEXISTENCIA ||
          (detalles.causa !== null && detalles.causa !== "")
      );
    } else {
      detalle = detalleOriginal.filter(
        (detalles) =>
          detalles.catalogoDeclaracion.idCatalogoTipoDeclaracion !==
          NO_DECLARA_PREEXISTENCIA
      );
    }

    if (
      this.state.informacion.catalogoPregunta.idPregunta === ID_CUATRO ||
      this.props.informacion.catalogoPregunta.idPregunta === ID_OCHO
    ) {
      detalle.forEach((item) => {
        item.actividadGlosa = item.catalogoDeclaracion.declaracion.replace(
          "Enfermedades",
          "E."
        );
        item.fechaDiagnosticoFormat = moment(item.fechaDiagnostico).format(
          "DD/MM/YYYY"
        );
        item.fechaDiagnostico = moment(item.fechaDiagnostico).format(
          "YYYY-MM-DD"
        );
      });
    }

    const idCatalogoAceptacion =
      this.state.informacion.catalogoAceptacion.idCatalogoAceptacion;
    const declaracionComplementaria = {
      ...this.state.declaracionComplementaria,
      idCatalogoAceptacion,
      idPreguntaSeccionCobertura,
    };

    this.setState({
      detalle,
      declaracionComplementaria,
      declaraDeclaracion,
    });
    const { dataWizardAsegurado, data_asegurable } = this.props.store.reducer;

    if (
      data_asegurable.canalVenta.idCanalVenta !== VALOR_UNO &&
      dataWizardAsegurado.cargas &&
      dataWizardAsegurado.cargas.length > VALOR_ZERO
    ) {
      this.state.informacion.glosasCoberturas.forEach((glosa) => {
        dataWizardAsegurado.cargas[VALOR_ZERO].coberturas.forEach(
          (cobertura) => {
            if (
              glosa.idCobertura === cobertura.idSubProdCondicionadoCobertura
            ) {
              this.setState({
                declaracionCarga: true,
              });
            }
          }
        );
      });
    } else {
      this.setState({
        declaracionCarga: false,
      });
    }
  }

  handleActionChange = (row) => {
    this.setState({
      agregar: false,
      declaracionComplementaria: row,
    });
  };

  handleActionDelete = (objDeclaracion) => {

    let objRespuesta = eliminarDeclaracion(objDeclaracion, this.props.store.reducer, this.state)

    if (objRespuesta.blnCarga){
      let { cargas } = this.props.store.reducer.dataWizardAsegurado;
      cargas[objRespuesta.intIndiceCarga] = {...objRespuesta.objAsegurable};
      this.props.update_carga(cargas);
    }
    else{
      this.props.guardar_data_wizard_asegurado(objRespuesta.objAsegurable);
    }

    this.setState({ detalle: objRespuesta.objDetalle, agregar: true });
    this.props.update_detalle(objRespuesta.objDetalle);
  };

  handleSubmit = (values) => {

    let objRespuesta = procesarDeclaracion(values, this.props.store.reducer, this.state);

    if (objRespuesta.blnCarga){
      let { cargas } = this.props.store.reducer.dataWizardAsegurado;
      cargas[objRespuesta.intIndiceCarga] = {...objRespuesta.objAsegurable};
      this.props.update_carga(cargas);
    }
    else{
      this.props.guardar_data_wizard_asegurado(objRespuesta.objAsegurable);
    }

    if (objRespuesta.agregar) {

      this.setState({
        detalle: objRespuesta.objDetalleEstado,
        declaracionComplementaria: objRespuesta.objDeclaracionNueva
      });
      this.props.add_detalle(objRespuesta.objDeclaracionNueva);
    } 
    else {
      this.setState({
        detalle: objRespuesta.objDetalleEstado,
        declaracionComplementaria: objRespuesta.objDeclaracionNueva,
        agregar: true,
      });

      this.props.update_detalle(objRespuesta.objDetalle);
    }
    this.refs.siDeclaraRef.checked = true;
  };

  cerrarModalVerDocumentos = (estadoModalVerDocumentos) => {
    this.setState({ modalEjDeclaracion: estadoModalVerDocumentos });
  };

  confirm = (row) =>
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="modal-confirm-container ">
            <div className="d-flex">
              <p className="h4">¿Está seguro de eliminar esta declaración?</p>
            </div>

            <div className="row mt-4">
              <button
                id="cancelarEliminarDeclaracion"
                className="btn btn-principal color-cancel btn-sm d-block ml-auto mr-2"
                onClick={() => {
                  onClose();
                }}
              >
                Cancelar
              </button>
              <button
                id="eliminarDeclaracion"
                className="btn btn-principal color-danger btn-sm"
                onClick={() => {
                  this.handleActionDelete(row);
                  onClose();
                }}
              >
                Eliminar
              </button>
            </div>
          </div>
        );
      },
    });

  confirmarNo = (e) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="modal-confirm-container">
            <div className="d-flex">
              <p className="h4">
                Ha realizado {this.state.detalle.length}{" "}
                {this.state.detalle.length === 1
                  ? "declaración"
                  : "declaraciones"}
                . ¿Confirma que desea eliminar declaraciones?
              </p>
            </div>

            <div className="row mt-4">
              <button
                id="noElimina"
                className="btn btn-principal color-secundario btn-sm d-block ml-auto mr-2"
                onClick={() => {
                  if (this.props.store.reducer.data_asegurable.declaracion) {
                    this.refs.siDeclaraRef.checked = true;
                    this.refs.noDeclaraRef.checked = false;
                    this.setState({
                      declaraDeclaracion: true,
                      checked: true,
                    });
                  }
                  onClose();
                }}
              >
                No
              </button>
              <button
                id="siElimina"
                className="btn btn-principal btn-sm"
                onClick={(e) => {
                  this.refs.siDeclaraRef.checked = false;
                  this.refs.noDeclaraRef.checked = true;
                  this.setState({
                    declaraDeclaracion: false,
                    checked: false,
                  });
                  this.deleteAllDeclarationsState();
                  onClose();
                }}
              >
                Sí
              </button>
            </div>
          </div>
        );
      },
    });
  };

  deleteAllDeclarationsState = () => {
    //update state
    this.setState({
      detalle: [],
    });

    //update detalle redux
    const { reducer } = this.props.store;
    const { detalle } = reducer.configuracionDeclaraciones;
    const { informacionPoliza } = reducer;
    const dataWizardTemporal = reducer.dataWizardAsegurado;

    const newDetalle = detalle.filter(
      (detalles) =>
        detalles.idPreguntaSeccionCobertura !==
        this.state.informacion.idPreguntaSeccionCobertura
    );

    this.props.update_detalle(newDetalle);

    //update objeto mongo

    if (reducer.dataWizardAsegurado.coberturas[0].declaraciones) {
      const declaraciones =
        reducer.dataWizardAsegurado.coberturas[0].declaraciones.filter(
          (detalles) =>
            detalles.idPreguntaSeccionCobertura !==
            this.state.informacion.idPreguntaSeccionCobertura
        );

      const { cotizaciones } = this.props.store.reducer.data_asegurable;

      const data = {
        ...reducer.dataWizardAsegurado,
        coberturas: informacionPoliza.coberturas
          ? this.coberturasMantencion(
              informacionPoliza.coberturas,
              declaraciones,
              dataWizardTemporal
            )
          : cotizaciones &&
            this.coberturasVentaDirecta(
              cotizaciones,
              declaraciones,
              dataWizardTemporal
            ),
      };

      this.props.guardar_data_wizard_asegurado(data);
    }

    if (
      reducer.dataWizardAsegurado.cargas &&
      reducer.dataWizardAsegurado.cargas.length > VALOR_ZERO
    ) {
      const cargas = [...reducer.dataWizardAsegurado.cargas];
      cargas.forEach((carga) => {
        if (
          carga.coberturas[0].declaraciones &&
          carga.coberturas[0].declaraciones.length > VALOR_ZERO
        ) {
          carga.coberturas.forEach((cobertura) => {
            cobertura.declaraciones = cobertura.declaraciones.filter(
              (detalles) =>
                detalles.idPreguntaSeccionCobertura !==
                this.state.informacion.idPreguntaSeccionCobertura
            );
          });
        }
      });
      this.props.update_carga(cargas);
    }
  };

  guardarFechaNacimiento = (rut, setFieldValue) => {
    if (rut && +rut !== VALOR_ZERO) {
      const { dataWizardAsegurado } = this.props.store.reducer;
      let fechaNacimiento;
      if (+rut === +dataWizardAsegurado.rut) {
        fechaNacimiento = dataWizardAsegurado.fechaNacimiento;
      } else {
        fechaNacimiento = dataWizardAsegurado.cargas.find(
          (carga) => +carga.rut === +rut
        ).fechaNacimiento;
      }

      setFieldValue("fechaNacimiento", fechaNacimiento);
    }
  };

  guardarPreguntaPatologia = (catalogo, id) => {
    const preguntaPatologica = catalogo.find(
      (elem) => elem.idCatalogoTipoDeclaracion === id
    );

    this.setState({ preguntaPatologica });
    this.validacionPatologia(
      preguntaPatologica,
      this.state.declaracionComplementaria.finalizoTratamientoRadical
    );
  };

  validacionPatologia = (preguntaPatologica, chkPatologia) => {
    if (preguntaPatologica.preguntaSecundaria === undefined) {
      this.setState({ validacionDeclaracion: true });
    } else if (chkPatologia === undefined) {
      this.setState({ validacionDeclaracion: false });
    } else if (preguntaPatologica.preguntaSecundaria && chkPatologia === null) {
      this.setState({ validacionDeclaracion: false });
    } else if (
      preguntaPatologica.preguntaSecundaria &&
      chkPatologia === false
    ) {
      this.setState({ validacionDeclaracion: true });
    } else if (preguntaPatologica.preguntaSecundaria && chkPatologia === true) {
      this.setState({ validacionDeclaracion: false });
    }
  };

  validacionOncologicaChk = (chkOncologico) => {
    this.setState({
      declaracionComplementaria: {
        ...this.state.declaracionComplementaria,
        finalizoTratamientoRadical: true,
      },
    });
    this.validacionPatologia(this.state.preguntaPatologica, chkOncologico);

    if (!this.state.validacionDeclaracion && this.state.detalle.length > 0) {
      this.setState({ validacionOncologicoAceptado: true });
      this.setState({ modalPatologica: true });
    }
    if (this.state.detalle.length === 0 && chkOncologico) {
      this.setState({ validacionOncologicoAceptado: true });
      this.setState({ modalPatologica: true });
      this.setState({
        declaraDeclaracion: false,
        checked: false,
      });
    }
  };

  render() {
    const { dataWizardAsegurado } = this.props.store.reducer;

    let catalogoDeclaraciones =
      this.state.informacion.catalogoDeclaraciones.filter((declaracion) => {
        return (
          declaracion.declaracion !== STRING_VACIO &&
          declaracion.declaracion !== NO_DECLARA
        );
      });

    return (
      <Fragment>
        {(this.state.informacion.catalogoPregunta.idPregunta === 4 ||
          this.state.informacion.catalogoPregunta.idPregunta === 8) && (
          <CatalogoPregunta
            catalogoPregunta={this.state.informacion.catalogoPregunta}
            chkPregunta={this.state.declaraDeclaracion}
          />
        )}

        <div className="row">
          <div className="col-9 pt-3">
            <p className="">
              {this.state.informacion.catalogoPregunta.pregunta}
            </p>
          </div>

          <div className="col-3 mt-4">
            <div
              className="check-group"
              style={{
                display: "flex",
                border: "1px solid #ccccd6",
              }}
            >
              <label className="custom-checkbox p-1 mt-2 ml-1">
                <input
                  id="siDeclara"
                  ref="siDeclaraRef"
                  type="radio"
                  name="radio"
                  checked={this.state.checked}
                  onChange={async (e) => {
                    await this.setState({
                      declaraDeclaracion: true,
                      checked: true,
                    });
                    this.props.check(
                      true,
                      this.state.informacion.catalogoPregunta.idPregunta
                    );
                    this.setState({ validacionOncologicoAceptado: false });
                    this.setState({ preguntaPatologica: {} });
                  }}
                />
                <div className="check-4"></div>
                <div className="option-4">Si</div>
              </label>
              <label className="custom-checkbox p-1 mt-2 ml-3">
                <input
                  id="noDeclara"
                  ref="noDeclaraRef"
                  type="radio"
                  name="radio"
                  checked={
                    this.state.checked === null ? null : !this.state.checked
                  }
                  onChange={async (e) => {
                    if (this.state.detalle.length > 0) {
                      await this.confirmarNo(e);
                    }
                    await this.setState({
                      declaraDeclaracion: false,
                      checked: false,
                    });
                    this.props.check(
                      false,
                      this.state.informacion.catalogoPregunta.idPregunta
                    );
                    this.setState({ validacionOncologicoAceptado: false });
                    this.setState({ preguntaPatologica: {} });
                  }}
                />
                <div className="check-4"></div>
                <div className="option-4 ">No</div>
              </label>
            </div>
          </div>
        </div>

        {catalogoDeclaraciones.find((elem) => elem.ejemplos) !== undefined && (
          <div className="col-12 mb-4">
            <div className="row">
              <div className="col12 col-md-6">
                {catalogoDeclaraciones
                  .slice(0, Math.round(catalogoDeclaraciones.length / 2))
                  .map((item, i) => (
                    <div
                      className="mb-2 d-flex"
                      style={{ borderBottom: "1px solid #ccccd6" }}
                      key={i}
                    >
                      <div
                        style={{
                          display: "inline-block",
                          width: "220px",
                        }}
                      >
                        <span>{" " + item.declaracion}</span>
                      </div>

                      <span
                        onClick={() => {
                          this.setState({ modalEjDeclaracion: true });
                          this.setState({
                            seleccionEjDeclaracion: item,
                          });
                        }}
                        style={{
                          display: "inline-block",
                          marginLeft: "10px",
                          color: "#ed8e03",
                          cursor: "pointer",
                        }}
                      >
                        Listado enfermedades
                      </span>
                    </div>
                  ))}
              </div>
              <div className="col12 col-md-6">
                {catalogoDeclaraciones
                  .slice(
                    Math.round(catalogoDeclaraciones.length / 2),
                    catalogoDeclaraciones.length
                  )
                  .map((item, i) => (
                    <div
                      className="mb-2 d-flex"
                      style={{ borderBottom: "1px solid #ccccd6" }}
                      key={i}
                    >
                      <div
                        style={{
                          display: "inline-block",
                          width: "220px",
                        }}
                      >
                        <span>{" " + item.declaracion}</span>
                      </div>

                      <span
                        onClick={() => {
                          this.setState({ modalEjDeclaracion: true });
                          this.setState({
                            seleccionEjDeclaracion: item,
                          });
                        }}
                        style={{
                          display: "inline-block",
                          marginLeft: "10px",
                          color: "#ed8e03",
                          cursor: "pointer",
                        }}
                      >
                        Listado enfermedades
                      </span>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        )}
        <ModalEjemplosSalud
          showbuttons="false"
          title={"modal_overlay show lbg"}
          onHide={(e) => this.setState({ modalPatologica: e })}
          show={this.state.modalPatologica}
          className="text-center"
          style={{
            marginTop: "170px",
            borderRadius: "20px",
          }}
        >
          <div style={{ display: "flex" }}>
            <img
              src={imgWarning}
              alt="Solicitud completa"
              width="30"
              height="30"
            />
            <h5
              style={{
                fontWeight: "bold",
                padding: "2px",
              }}
            >
              Importante
            </h5>
          </div>

          <p className="mt-3">
            Recuerde que, Ud no debe Declarar el padecimiento de una patología
            oncológica si han transcurrido más de 5 años desde que ha finalizado
            su tratamiento radical sin recaída posterior.
          </p>
        </ModalEjemplosSalud>

        <ModalEjemplosSalud
          showbuttons="false"
          title={"modal_overlay show lbg"}
          onHide={this.cerrarModalVerDocumentos}
          show={this.state.modalEjDeclaracion}
          className="text-center"
          style={{ marginTop: "170px", borderRadius: "20px" }}
        >
          <div style={{ display: "flex" }}>
            <img
              src={iconEjemplo}
              alt="iconEjemplo"
              style={{
                height: "35px",
                marginRight: "18px",
                marginTop: "2px",
              }}
            />
            <h5
              style={{
                color: "#ef6c00",
                fontWeight: "bold",
                padding: "2px",
              }}
            >
              {this.state.seleccionEjDeclaracion.declaracion}
            </h5>
          </div>

          <p className="mt-3">{this.state.seleccionEjDeclaracion.ejemplos}</p>
        </ModalEjemplosSalud>

        {this.state.checked && (
          <>
            <div className="container pt-3"></div>
            <Formik
              enableReinitialize={true}
              initialValues={{
                rut: this.state.declaracionComplementaria.rut,
                mostrarCombo: false,
                idCatalogoTipoDeclaracion:
                  this.state.declaracionComplementaria
                    .idCatalogoTipoDeclaracion,
                mostrarCausa: false,
                causa: this.state.declaracionComplementaria.causa,
                mostrarFechaDiagnostico: false,
                fechaDiagnostico: moment(
                  this.state.declaracionComplementaria.fechaDiagnostico
                ).format("DD/MM/YYYY"),
                fechaNacimiento:
                  this.state.declaracionComplementaria.fechaNacimiento,
                mostrarObservacion: false,
                observacion: this.state.declaracionComplementaria.observacion,
              }}
              validationSchema={Yup.object().shape({
                rut: Yup.string()
                  .required(CAMPO_REQUERIDO_ASEGURADO)
                  .test(
                    "validacionAsegurable",
                    VALIDACION_ASEGURABLE,
                    (value) => (Number(value) !== 0 ? true : false)
                  ),
                idCatalogoTipoDeclaracion: Yup.string()
                  .when("mostrarCombo", {
                    is: true,
                    then: Yup.string().required(VALIDACION_ENFERMEDAD),
                  })
                  .test("validacionActividad", VALIDACION_ENFERMEDAD, (value) =>
                    this.state.informacion.catalogoPregunta.idPregunta ===
                      ID_CUATRO ||
                    this.state.informacion.catalogoPregunta.idPregunta ===
                      ID_OCHO
                      ? Number(value) !== 0
                      : true
                  ),
                causa: Yup.string()
                  .when("mostrarCausa", {
                    is: true,
                    then: Yup.string().required(CAMPO_REQUERIDO_PATOLOGIA),
                  })
                  .test("validarCausa", CAMPO_REQUERIDO_PATOLOGIA, (value) =>
                    this.state.informacion.catalogoPregunta.idPregunta ===
                      ID_CUATRO ||
                    this.state.informacion.catalogoPregunta.idPregunta ===
                      ID_OCHO
                      ? true
                      : value
                      ? value.trim() !== ""
                      : false
                  ),
                fechaDiagnostico: Yup.string()
                  .when("mostrarFechaDiagnostico", {
                    is: true,
                    then: Yup.string().required(VALIDACION_FECHA_DIAGNOSTICO),
                  })
                  .test(
                    "fechaDiagnostico",
                    VALIDACION_FECHA_DIAGNOSTICO,
                    (value) =>
                      this.state.informacion.catalogoPregunta.idPregunta !==
                        5 &&
                      this.state.informacion.catalogoPregunta.idPregunta !== 7
                        ? value !== undefined && value !== "Invalid date"
                          ? true
                          : false
                        : true
                  ),
                observacion: Yup.string()
                  .when("mostrarObservacion", {
                    is: true,
                    then: Yup.string().required(CAMPO_REQUERIDO_DIAGNOSTICO),
                  })
                  .test(
                    "validarObservacion",
                    CAMPO_REQUERIDO_DIAGNOSTICO,
                    (value) =>
                      this.state.informacion.catalogoPregunta.idPregunta ===
                        ID_CUATRO ||
                      this.state.informacion.catalogoPregunta.idPregunta ===
                        ID_OCHO
                        ? value
                          ? value.trim() !== ""
                          : false
                        : true
                  ),
              })}
              onSubmit={async (
                values,
                { setSubmitting, resetForm, initialValues }
              ) => {
                await this.handleSubmit(values);

                await this.setState(
                  {
                    declaracionComplementaria: {
                      key: null,
                      rut: "",
                      idCatalogoTipoDeclaracion: 0,
                      causa: "",
                      fechaDiagnostico: "",
                      fechaDiagnosticoFormat: "",
                      observacion: "",
                    },
                  },
                  () => {
                    resetForm(initialValues);
                  }
                );
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                handleBlur,
                setFieldTouched,
                setFieldValue,
              }) => {
                return (
                  <Form
                    id="formularioActividades"
                    onSubmit={handleSubmit}
                    onKeyDown={(e) => {
                      if ((e.charCode || e.keyCode) === 13) {
                        e.preventDefault();
                      }
                    }}
                  >
                    <div className="row mx-md-5 mt-3">
                      <div className="col-12 form-group">
                        <label>
                          ASEGURABLE <span className="text-danger">*</span>
                        </label>
                        <select
                          className="form-control form-control-sm"
                          id="rut"
                          name="rut"
                          value={values.rut}
                          onChange={(e) => {
                            handleChange(e);
                            this.guardarFechaNacimiento(
                              e.target.value,
                              setFieldValue
                            );
                            setFieldValue("fechaDiagnostico", "");
                            setFieldValue("idCatalogoTipoDeclaracion", "");
                          }}
                          onBlur={handleBlur}
                        >
                          <option value="0">SELECCIONE UN ASEGURABLE</option>
                          {(this.props.store.reducer.informacionPoliza
                            .solicitudIncorporacion ||
                            this.props.store.reducer.informacionPoliza
                              .asegurable_5_50) && (
                            <option
                              name="nombre"
                              value={dataWizardAsegurado.rut}
                            >
                              {`${dataWizardAsegurado.nombre} ${dataWizardAsegurado.apellidoPaterno} ${dataWizardAsegurado.apellidoMaterno} (TITULAR)`}
                            </option>
                          )}

                          {dataWizardAsegurado.cargas &&
                            this.state.declaracionCarga &&
                            dataWizardAsegurado.cargas.map((item, i) => (
                              <option
                                name="nombre"
                                key={i}
                                value={item.rut}
                              >{`${item.nombre} ${item.apellidoPaterno} ${item.apellidoMaterno} (ADICIONAL)`}</option>
                            ))}
                        </select>
                        {errors.rut && touched.rut && (
                          <small className="text-danger animated fadeIn animated fadeIn">
                            {errors.rut}
                          </small>
                        )}
                      </div>

                      {this.state.informacion.catalogoPregunta.idPregunta ===
                        ID_CUATRO ||
                      this.state.informacion.catalogoPregunta.idPregunta ===
                        ID_OCHO ? (
                        <div className="col-12 form-group">
                          <label>
                            Patología <span className="text-danger">*</span>
                          </label>
                          <select
                            className="form-control form-control-sm"
                            id="idCatalogoTipoDeclaracion"
                            name="idCatalogoTipoDeclaracion"
                            value={values.idCatalogoTipoDeclaracion}
                            onChange={(e) => {
                              handleChange(e);
                              setFieldValue("mostrarCombo", true);
                              this.setState({
                                declaracionComplementaria: {
                                  ...this.state.declaracionComplementaria,
                                  finalizoTratamientoRadical: null,
                                },
                              });

                              this.guardarPreguntaPatologia(
                                catalogoDeclaraciones,
                                parseInt(e.target.value)
                              );
                            }}
                            onBlur={handleBlur}
                          >
                            <option value="0">SELECCIONE PATOLOGÍA</option>
                            {catalogoDeclaraciones.map((item, i) => (
                              <option
                                name="nombre"
                                key={i}
                                value={item.idCatalogoTipoDeclaracion}
                              >
                                {item.declaracion}
                              </option>
                            ))}
                          </select>
                          {errors.idCatalogoTipoDeclaracion &&
                            touched.idCatalogoTipoDeclaracion && (
                              <small className="text-danger animated fadeIn">
                                {errors.idCatalogoTipoDeclaracion}
                              </small>
                            )}
                        </div>
                      ) : (
                        <div className="col-12 form-group">
                          <label>
                            {/* Diagnóstico <span className="text-danger">*</span> */}
                            Patología <span className="text-danger">*</span>
                          </label>
                          <Field
                            id="causa"
                            name="causa"
                            value={values.causa}
                            type="text"
                            placeholder="Ingrese patología"
                            className="form-control form-control-sm"
                            onBlur={() => {
                              setFieldValue("mostrarCausa", true);
                            }}
                          />
                          {errors.causa && touched.causa && (
                            <small className="text-danger animated fadeIn">
                              {errors.idCatalogoTipoDeclaracion}
                            </small>
                          )}
                        </div>
                      )}

                      {this.state.preguntaPatologica &&
                        this.state.preguntaPatologica.preguntaSecundaria && (
                          <>
                            <div className="col-12 form-group">
                              <p className="text-justify">
                                {
                                  this.state.preguntaPatologica
                                    .preguntaSecundaria
                                }
                              </p>
                            </div>

                            <div className="col-12 form-group d-flex justify-content-center">
                              <div
                                className="check-group"
                                style={{
                                  display: "flex",
                                }}
                              >
                                <label className="custom-checkbox p-1 mt-2 ml-1">
                                  <input
                                    id="patologia"
                                    type="radio"
                                    name="radioPatologia"
                                    value={
                                      this.state.declaracionComplementaria
                                        .finalizoTratamientoRadical
                                    }
                                    onClick={() => {
                                      this.validacionOncologicaChk(true);
                                    }}
                                  />
                                  <div className="check-4"></div>
                                  <div className="option-4">Si</div>
                                </label>
                                <label className="custom-checkbox p-1 mt-2 ml-3">
                                  <input
                                    id="patologia"
                                    type="radio"
                                    name="radioPatologia"
                                    value={
                                      this.state.declaracionComplementaria
                                        .finalizoTratamientoRadical
                                    }
                                    onClick={() => {
                                      this.validacionOncologicaChk(false);
                                    }}
                                  />
                                  <div className="check-4"></div>
                                  <div className="option-4 ">No</div>
                                </label>
                              </div>
                            </div>
                          </>
                        )}

                      {this.state.informacion.catalogoPregunta.idPregunta ===
                        ID_CUATRO ||
                      this.state.informacion.catalogoPregunta.idPregunta ===
                        ID_OCHO
                        ? this.state.validacionDeclaracion && (
                            <div className="col-12 form-group">
                              <label>
                                Diagnóstico{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <Field
                                type="text"
                                className="form-control form-control-sm"
                                id="observacion"
                                name="observacion"
                                placeholder="Ingrese diagnóstico"
                                value={values.observacion}
                                onBlur={() => {
                                  setFieldValue("mostrarObservacion", true);
                                }}
                              />
                              {errors.observacion && touched.observacion && (
                                <small className="text-danger animated fadeIn">
                                  {errors.observacion}
                                </small>
                              )}
                            </div>
                          )
                        : null}

                      {this.state.informacion.catalogoPregunta.idPregunta !==
                        5 &&
                        this.state.validacionDeclaracion && (
                          <div className="col-12 form-group">
                            <label>
                              Fecha Diagnóstico/Conocimiento/Tratamiento y/o
                              Antecedentes{" "}
                              <span className="text-danger">*</span>
                            </label>

                            <DatePicker
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              id="fechaDiagnostico"
                              name="fechaDiagnostico"
                              value={values.fechaDiagnostico}
                              dateFormat="dd/MM/yyyy"
                              placeholderText="DD/MM/YYYY"
                              locale="es"
                              onChange={(value) => {
                                value = moment(value).format("DD/MM/YYYY");
                                setFieldValue("mostrarFechaDiagnostico", true);
                                setFieldTouched("fechaDiagnostico", true);
                                setFieldValue("fechaDiagnostico", value);
                              }}
                              minDate={new Date(values.fechaNacimiento)}
                              maxDate={new Date()}
                              customInput={
                                <MaskedTextInput
                                  className="form-control form-control-sm"
                                  placeholder="DD/MM/YYYY"
                                  mask={MASK_DATE}
                                />
                              }
                            />

                            {errors.fechaDiagnostico &&
                              touched.fechaDiagnostico && (
                                <small className="text-danger animated fadeIn">
                                  {errors.fechaDiagnostico}
                                </small>
                              )}
                          </div>
                        )}

                      {this.state.validacionDeclaracion && (
                        <div className="col-12 mb-5">
                          <button
                            id="buttonSubmit"
                            className="btn btn-principal color-secundario btn-sm d-block ml-auto"
                            type="submit"
                            onClick={() => {
                              this.setState({
                                declaracionComplementaria: {
                                  ...this.state.declaracionComplementaria,
                                  finalizoTratamientoRadical: null,
                                },
                              });
                            }}
                          >
                            {this.state.agregar ? "Agregar" : "Actualizar"}
                          </button>
                        </div>
                      )}

                      {this.state.checked &&
                        this.state.informacion.catalogoPregunta
                          .textoOpcional === undefined && (
                          <div className="col-12 mb-5">
                            <button
                              id="buttonSubmit"
                              className="btn btn-principal color-secundario btn-sm d-block ml-auto"
                              type="submit"
                            >
                              {this.state.agregar ? "Agregar" : "Actualizar"}
                            </button>
                          </div>
                        )}
                    </div>

                    {this.state.detalle.length > 0 ? (
                      <div
                        style={{ width: "100%" }}
                        className="dataDeclaraciones"
                      >
                        <hr />
                        <Table
                          titulo="Declaraciones actividades y deportes"
                          datos={this.state.detalle.filter(
                            (item) => item.nombre
                          )}
                          columnas={this.state.columnDefs}
                        />
                        <hr />
                      </div>
                    ) : null}
                  </Form>
                );
              }}
            </Formik>

            {this.state.detalle.length > 0 &&
              this.state.informacion.catalogoPregunta.textoOpcional &&
              this.state.informacion.catalogoPregunta.textoOpcional.length >
                0 && (
                <div className="container pt-3">
                  <hr />
                  <div className="row">
                    <div className="col-1 align-self-center">
                      <label className="switch">
                        <input
                          id="checkboxDeclaracion"
                          checked={this.state.aceptacionCheck}
                          onClick={() =>
                            this.setState(
                              {
                                aceptacionCheck: !this.state.aceptacionCheck,
                              },
                              function () {
                                this.props.checkAceptacion(
                                  this.state.aceptacionCheck,
                                  this.state.informacion.catalogoPregunta
                                    .idPregunta
                                );
                              }
                            )
                          }
                          type="checkbox"
                        />
                        <span className="slider round" />
                      </label>
                    </div>
                    <div className="col-11">
                      <label className="form-check-label " htmlFor="aceptacion">
                        <p className="text-justify">
                          {this.state.informacion.catalogoAceptacion.glosa}
                        </p>
                      </label>
                    </div>
                  </div>
                </div>
              )}

            {this.state.checked > 0 &&
              this.state.informacion.catalogoPregunta.textoOpcional ===
                undefined && (
                <div className="container pt-3">
                  <hr />
                  <div className="row">
                    <div className="col-1 align-self-center">
                      <label className="switch">
                        <input
                          id="checkboxDeclaracion"
                          checked={this.state.aceptacionCheck}
                          onClick={() =>
                            this.setState(
                              {
                                aceptacionCheck: !this.state.aceptacionCheck,
                              },
                              function () {
                                this.props.checkAceptacion(
                                  this.state.aceptacionCheck,
                                  this.state.informacion.catalogoPregunta
                                    .idPregunta
                                );
                              }
                            )
                          }
                          type="checkbox"
                        />
                        <span className="slider round" />
                      </label>
                    </div>
                    <div className="col-11">
                      <label className="form-check-label " htmlFor="aceptacion">
                        <p className="text-justify">
                          {this.state.informacion.catalogoAceptacion.glosa}
                        </p>
                      </label>
                    </div>
                  </div>
                </div>
              )}
          </>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state,
});

export default withRouter(
  connect(mapStateToProps, {
    wizard,
    add_detalle,
    update_detalle,
    cambio_detalle,
    guardar_data_asegurable,
    guardar_data_wizard_asegurado,
    update_carga,
  })(PreguntaSalud)
);
