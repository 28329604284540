import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import {
  formatMilesInput
} from "../../../../../utils/Functions";
const { format } = require("rut.js");


class DatosPersonales extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataWizard: this.props.store.reducer.dataWizardAsegurado,
    };
  }

  findGenero = (id) => {
    let genero = this.props.store.reducer.generos.filter((a) => {
      return a.idGenero === parseInt(id);
    })[0];

    return genero !== undefined ? genero : { descripcion: "" };
  };

  findEstadoCivil = (id) => {
    let estadoCivil = this.props.store.reducer.estadoCivil.filter((a) => {
      return a.idEstadoCivil === parseInt(id);
    })[0];

    return estadoCivil !== undefined ? estadoCivil : { glosa: "" };
  };

  findFormaPago = (id) => {
    let forma_pago = this.props.store.reducer.forma_pago.filter((a) => {
      return a.id === parseInt(id);
    })[0];

    return forma_pago !== undefined ? forma_pago : { nombre: "" };
  };

  findBanco = (id) => {
    let bancos = this.props.store.reducer.bancos.filter((a) => {
      return a.id === parseInt(id);
    })[0];

    return bancos !== undefined ? bancos : { nombre: "" };
  };

  findTipoCuenta = (id) => {    
    let tipo_cuenta = this.props.store.reducer.tipo_cuenta.filter((a) => {
      return a.id === parseInt(id);
    })[0];

    return tipo_cuenta !== undefined ? tipo_cuenta : { nombre: "" };
  };

  findRegion = (id) => {
    let regiones = this.props.store.reducer.regiones.filter((a) => {
      return a.idRegion === parseInt(id);
    })[0];

    return regiones !== undefined ? regiones : { glosaRegion: "" };
  };

  findComuna = (id) => {
    let comunas = this.props.store.reducer.comunas.filter((a) => {
      return a.idComuna === parseInt(id);
    })[0];

    return comunas !== undefined ? comunas : { glosaComuna: "" };
  };

  findCiudad = (id) => {
    let ciudades = this.props.store.reducer.ciudades.filter((a) => {
      return a.idCiudad === parseInt(id);
    })[0];
    return ciudades !== undefined ? ciudades : { glosaCiudad: "" };
  };

  render() {
    const { solicitudIncorporacion, asegurable_5_50 } = this.props.store.reducer.informacionPoliza;
    return (
      <React.Fragment>
        <div className="mt-3">
          <h6 className="bg-secondary text-white p-2 linea-subtitulo font-weight-bold">
            MIS DATOS
          </h6>
          <div className="row">
            <div className={`col-12 col-md-6 ${(solicitudIncorporacion || asegurable_5_50) && "col-lg-3"}`}>
              <div className="row">
                <div className="col-6 col-md-12 font-weight-bold text-muted">
                  RUT
                </div>
                <div className="col-6 col-md-12">
                  {format(`${this.props.store.auth.usuarioSesion.rut}`)}
                </div>
              </div>
            </div>

            <div className={`col-12 col-md-6 ${(solicitudIncorporacion || asegurable_5_50) && "col-lg-3"}`}>
              <div className="row">
                <div className="col-6 col-md-12 font-weight-bold text-muted">
                  Nombre completo
                </div>
                <div className="col-6 col-md-12">
                  {`${this.state.dataWizard.nombre} ${this.state.dataWizard.apellidoPaterno} ${this.state.dataWizard.apellidoMaterno}`}
                </div>
              </div>
            </div>

            {(solicitudIncorporacion || asegurable_5_50) && (
              <Fragment>
                <div className="col-12 col-md-6 col-lg-3">
                  <div className="row">
                    <div className="col-6 col-md-12 font-weight-bold text-muted">
                      Fecha de nacimiento
                    </div>
                    <div className="col-6 col-md-12">
                      {moment(this.state.dataWizard.fechaNacimiento).format(
                        "DD/MM/YYYY"
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-6 col-lg-3">
                  <div className="row">
                    <div className="col-6 col-md-12 font-weight-bold text-muted">
                      Genero
                    </div>
                    <div className="col-6 col-md-12">
                      {this.findGenero(
                        this.state.dataWizard.genero ? this.state.dataWizard.genero.idGenero : ''
                      ).descripcion.toUpperCase()}
                    </div>
                  </div>
                </div>
              </Fragment>
            )}
          </div>

          {(solicitudIncorporacion || asegurable_5_50) && (
            <div className="row mt-3">
              <div className="col-12 col-md-6 col-lg-3">
                <div className="row">
                  <div className="col-6 col-md-12 font-weight-bold text-muted">
                    Estado civil
                  </div>
                  <div className="col-6 col-md-12">
                    {this.findEstadoCivil(
                      this.state.dataWizard.estadoCivil ? this.state.dataWizard.estadoCivil.idEstadoCivil : ''
                    ).glosa.toUpperCase()}
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6 col-lg-3">
                <div className="row">
                  <div className="col-6 col-md-12 font-weight-bold text-muted">
                    Estatura (cm)
                  </div>
                  <div className="col-6 col-md-12">
                    {this.state.dataWizard.estatura} cm
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6 col-lg-3">
                <div className="row">
                  <div className="col-6 col-md-12 font-weight-bold text-muted">
                    Peso
                  </div>
                  <div className="col-6 col-md-12">
                    {this.state.dataWizard.peso} kg
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6 col-lg-3">
                <div className="row">
                  <div className="col-6 col-md-12 font-weight-bold text-muted">
                    Fecha Ingreso Empresa
                  </div>
                  <div className="col-6 col-md-12">
                    {moment(this.state.dataWizard.fechaIngresoEmpresa).format(
                        "DD/MM/YYYY"
                      )}
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6 col-lg-3">
                <div className="row">
                  <div className="col-6 col-md-12 font-weight-bold text-muted">
                    Renta
                  </div>
                  <div className="col-6 col-md-12">
                    {formatMilesInput(this.state.dataWizard.renta)}
                  </div>
                </div>
              </div>

            </div>
          )}
        </div>

        {(solicitudIncorporacion || asegurable_5_50) && (
        <Fragment>
        <div className="mt-3">
          <h6 className="bg-secondary text-white p-2 linea-subtitulo font-weight-bold">
            DIRECCIÓN
          </h6>
          <div className="row">
            <div className="col-12 col-md-6 col-lg-3">
              <div className="row">
                <div className="col-6 col-md-12 font-weight-bold text-muted">
                  Dirección particular
                </div>
                <div className="col-6 col-md-12">
                  {this.state.dataWizard.direccion}
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-3">
              <div className="row">
                <div className="col-6 col-md-12 font-weight-bold text-muted">
                  Número
                </div>
                <div className="col-6 col-md-12">
                  {this.state.dataWizard.numero}
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-3">
              <div className="row">
                <div className="col-6 col-md-12 font-weight-bold text-muted">
                  Departamento
                </div>
                <div className="col-6 col-md-12">
                  {this.state.dataWizard.departamento}
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-3">
              <div className="row">
                <div className="col-6 col-md-12 font-weight-bold text-muted" style={{overflow: "hidden", textOverflow: "ellipsis"}}>
                  Sector/Villa/Población
                </div>
                <div className="col-6 col-md-12">
                  {this.state.dataWizard.complementoDireccion}
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-12 col-md-6 col-lg-3">
              <div className="row">
                <div className="col-6 col-md-12 font-weight-bold text-muted">
                  Región
                </div>
                <div className="col-6 col-md-12">
                  {this.findRegion(
                    this.state.dataWizard.region ? this.state.dataWizard.region.idRegion : ''
                  ).glosaRegion.toUpperCase()}
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-3">
              <div className="row">
                <div className="col-6 col-md-12 font-weight-bold text-muted">
                  Ciudad
                </div>
                <div className="col-6 col-md-12">
                  {this.findCiudad(
                    this.state.dataWizard.ciudad ? this.state.dataWizard.ciudad.idCiudad : ''
                  ).glosaCiudad.toUpperCase()}
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-3">
              <div className="row">
                <div className="col-6 col-md-12 font-weight-bold text-muted">
                  Comuna
                </div>
                <div className="col-6 col-md-12">
                  {this.findComuna(
                    this.state.dataWizard.comuna ? this.state.dataWizard.comuna.idComuna : ''
                  ).glosaComuna.toUpperCase()}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-3">
          <h6 className="bg-secondary text-white p-2 linea-subtitulo font-weight-bold">
            CONTACTO
          </h6>
          <div className="row">
            <div className="col-12 col-md-6 col-lg-3">
              <div className="row">
                <div className="col-6 col-md-12 font-weight-bold text-muted">
                  Teléfono celular
                </div>
                <div className="col-6 col-md-12">
                  {this.state.dataWizard.telefonoMovil}
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-3">
              <div className="row">
                <div className="col-6 col-md-12 font-weight-bold text-muted">
                  Teléfono particular
                </div>
                <div className="col-6 col-md-12">
                  {this.state.dataWizard.telefonoFijo}
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-3">
              <div className="row">
                <div className="col-6 col-md-12 font-weight-bold text-muted">
                  Email
                </div>
                <div className="col-6 col-md-12 text-truncate">
                  {this.state.dataWizard.correoElectronico}
                </div>
              </div>
            </div>
          </div>
        </div>

        {!this.props.store.reducer.informacionPoliza.soloProductoVida &&
          <div className="mt-3">
            <h6 className="bg-secondary text-white p-2 linea-subtitulo font-weight-bold">
              DATOS BANCARIOS
            </h6>
            <div className="row">
              <div className="col-12 col-md-6 col-lg-3">
                <div className="row">
                  <div className="col-6 col-md-12 font-weight-bold text-muted">
                    Forma de Pago
                  </div>
                  <div className="col-6 col-md-12">
                    {
                      this.findFormaPago(
                        this.state.dataWizard.formaPago ? this.state.dataWizard.formaPago.idFormaPago : ''
                      ).nombre
                    }
                  </div>
                </div>
              </div>

              {this.state.dataWizard.formaPago && 
                this.state.dataWizard.formaPago.idFormaPago !== 4 && (
              <React.Fragment>
              <div className="col-12 col-md-6 col-lg-3">
                <div className="row">
                  <div className="col-6 col-md-12 font-weight-bold text-muted">
                    Banco
                  </div>
                  <div className="col-6 col-md-12">
                    {this.state.dataWizard.institucionFinanciera
                      .idInstitucionFinanciera === 0
                      ? ""
                      : this.findBanco(
                          this.state.dataWizard.institucionFinanciera
                            .idInstitucionFinanciera
                        ).nombre}
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6 col-lg-3">
                <div className="row">
                  <div className="col-6 col-md-12 font-weight-bold text-muted">
                    Tipo de cuenta
                  </div>
                  <div className="col-6 col-md-12">
                    {this.state.dataWizard.tipoCuentaBancaria.idTipoCuenta === 0
                      ? ""
                      : this.findTipoCuenta(
                          this.state.dataWizard.tipoCuentaBancaria.idTipoCuentaBancaria
                        ).nombre}
                  </div>
                </div>
              </div>
              
              <div className="col-12 col-md-6 col-lg-3">
                <div className="row">
                  <div className="col-6 col-md-12 font-weight-bold text-muted">
                    Número de cuenta
                  </div>
                  <div className="col-6 col-md-12" style={{overflow: "hidden", textOverflow: "ellipsis"}}>
                    {this.state.dataWizard.numeroCuenta}
                  </div>
                </div>
              </div>
              </React.Fragment>
              )}

            </div>
          </div>
        }

       </Fragment>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state,
});

export default withRouter(connect(mapStateToProps, {})(DatosPersonales));
