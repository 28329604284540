import React, { Component } from "react";
import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../assets/css/slider.css";
import { CANTIDAD_CREADAS, CANTIDAD_ENROLADAS, CANTIDAD_FINALIZADAS, CANTIDAD_INGRESADAS, CANTIDAD_PROCESADAS, CANTIDAD_RECHAZADAS, CANTIDAD_SOLICITUDES, CANTIDAD_SOLICITUDES_CREADAS, CANTIDAD_SOLICITUDES_ENROLADAS, CANTIDAD_SOLICITUDES_EN_INGRESO, CANTIDAD_SOLICITUDES_FINALIZADAS, CANTIDAD_SOLICITUDES_PROCESADAS, CANTIDAD_SOLICITUDES_RECHAZADAS, CANTIDAD_SOLICITUDES_TOTALES } from "../../../utils/Constantes";
class Deslizador extends Component {
  prepararSolicitudes(grupoSolicitudes) {
    let cantidadSolicitudesTotales = CANTIDAD_SOLICITUDES_TOTALES;
    let cantidadSolicitudesCreadas = CANTIDAD_SOLICITUDES_CREADAS;
    let cantidadSolicitudesEnIngreso = CANTIDAD_SOLICITUDES_EN_INGRESO;
    let cantidadSolicitudesEnroladas = CANTIDAD_SOLICITUDES_ENROLADAS;
    let cantidadSolicitudProcesada = CANTIDAD_SOLICITUDES_PROCESADAS;
    let cantidadSolicitudRechazada = CANTIDAD_SOLICITUDES_RECHAZADAS;
    let cantidadSolicitudFinalizada = CANTIDAD_SOLICITUDES_FINALIZADAS;
    grupoSolicitudes.forEach((e) => {
      cantidadSolicitudesTotales =
        cantidadSolicitudesTotales + e.cantidadSolicitud;
      cantidadSolicitudesCreadas =
        cantidadSolicitudesCreadas + e.cantidadSolcitudCreada;
      cantidadSolicitudesEnIngreso =
        cantidadSolicitudesEnIngreso + e.cantidadSolcitudEnIngreso;
      cantidadSolicitudesEnroladas =
        cantidadSolicitudesEnroladas + e.cantidadSolicitudEnrolada;
      cantidadSolicitudProcesada =
        cantidadSolicitudProcesada + e.cantidadSolicitudProcesada;
      cantidadSolicitudRechazada =
        cantidadSolicitudRechazada + e.cantidadSolicitudRechazada;
      cantidadSolicitudFinalizada =
        cantidadSolicitudFinalizada + e.cantidadSolicitudFinalizada;
    });
    let totalSolicitudes = [
      {
        title: CANTIDAD_CREADAS,
        numero: cantidadSolicitudesTotales,
        glosa: CANTIDAD_SOLICITUDES,
      },
      {
        title: CANTIDAD_INGRESADAS,
        numero: cantidadSolicitudesEnIngreso,
        glosa: CANTIDAD_SOLICITUDES,
      },
      {
        title: CANTIDAD_ENROLADAS,
        numero: cantidadSolicitudesEnroladas,
        glosa: CANTIDAD_SOLICITUDES,
      },
      {
        title: CANTIDAD_PROCESADAS,
        numeroProcesada: cantidadSolicitudFinalizada,
        numeroRechazada: cantidadSolicitudRechazada,
        glosaProcesada: CANTIDAD_FINALIZADAS,
        glosaRechazada: CANTIDAD_RECHAZADAS,
      },
    ];
    return totalSolicitudes;
  }

  render() {
    let totalSolicitudes = this.prepararSolicitudes(this.props.solicitudes);
    return (
      <div className="slider__vc">
        <Carousel indicators={false} interval={30000}>
          {totalSolicitudes.map((data, i) => {
            if (data.title !== "Cantidad procesadas") {
              return (
                <Carousel.Item key={i}>
                  <div>
                    <div className="slider__top d-flex">
                      <p className="slider__title">{data.title}</p>
                      <p className="slider__date">{this.props.periodo}</p>
                    </div>
                    <div className="slider__bottom d-flex">
                      <p className="slider__quantity">{data.numero}</p>
                      <p className="slider__detail">{data.glosa}</p>
                    </div>
                  </div>
                </Carousel.Item>
              );
            } else {
              return (
                <Carousel.Item key={i}>
                  <div>
                    <div className="slider__top d-flex">
                      <p className="slider__title">{data.title}</p>
                      <p className="slider__date">{this.props.periodo}</p>
                    </div>
                    <div className="slider__bottom d-flex">
                      <p className="slider__quantity__2">
                        {data.numeroProcesada}
                      </p>
                      <p className="slider__detail__2">{data.glosaProcesada}</p>
                      <p className="slider__quantity__2">
                        {data.numeroRechazada}
                      </p>
                      <p className="slider__detail__2">{data.glosaRechazada}</p>
                    </div>
                  </div>
                </Carousel.Item>
              );
            }
          })}
        </Carousel>
      </div>
    );
  }
}

export default Deslizador;
