import React from "react";

const iconoVida = require("../../assets/images/life.png");
const iconoSalud = require("../../assets/images/heart.png");

const IndicadoresProductosAsegurable = () => {
  return (
    <React.Fragment>
      <div className="row justify-content-start mt-2">
        <div className="col-4">
          <div className="card shadow border-dashed-grey mb-3 px-0">
            <div className="row no-gutters">
              <div className="col-6 pl-4">
                <div className="row no-gutters">
                  <img
                    className="col-6"
                    alt="img-icon"
                    width="20px"
                    src={iconoSalud}
                    style={{
                      maxWidth: "30px",
                    }}
                  />
                  <small className="col-6 pl-3 vertical-center">Salud</small>
                </div>
              </div>
              <div className="col-6 pl-3">
                <div className="row no-gutters">
                  <img
                    className="col-6"
                    alt="img-icon"
                    width="20px"
                    height="29px"
                    src={iconoVida}
                    style={{
                      maxWidth: "30px",
                    }}
                  />
                  <small className="col-6 pl-3 vertical-center">Vida</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default IndicadoresProductosAsegurable;
