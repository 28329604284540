import React, { Component } from "react";
import Authorized from "../../../../../../utils/Authorized";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import ModalAsignarEjecutivo from "../ModalAsignarEjecutivo";
import moment from "moment";
import { mostrar_modal_fecha_enrolamiento } from "../../../../../../actions/actions";
import { obtenerConfiguracionDelegado } from "../../../../../../services/negocios.service";
import {
  ESTADO_NEGOCIO_3,
  T_NEGOCIO_ACTIVO,
  ESTADO_NEGOCIO_2,
  TIPO_CANAL_VENTA_DIGITAL,
  COD_EJECUTIVO,
  COD_JEFATURA,
  ALERT_ULT_COTI,
  VALOR_ZERO,
  PERFIL_LDAP,
  COD_EJECUTIVO_COMPLETO,
  COD_CORREDOR,
  RUTA_COTIZACION_ESTADO_ENROLAMIENTO,
} from "../../../../../../utils/Constantes";
import { notificacion } from "../../../../transversales/Notificaciones";
import { guardar_datos_delegado_negocio } from "../../../../../../actions/actions";

const iconNegocio = require("../../../../../assets/images/negocio-asegurable.png");
const iconUsuario = require("../../../../../assets/images/usuario.png");

const { format } = require("rut.js");

class CabeceraNegocio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      negocio: this.props.store.reducer.negocio,
      cargando: true,
      ultimaCotizacion: this.props.store.reducer.negocio.ultimaCotizacion,
      tipoNegocio: this.props.tipoNegocio,
      perfil: this.props.store.auth.usuarioSesion.rol.codigo,
      fechaTerminoEnrolamiento: null,
      asignadoChange: "",
      dataModal: {
        value: 0,
        action: "",
      },
      modalAsignacion: false,
      canalVenta: this.props.negocio.canalVenta.idCanalVenta,
    };
  }

  async componentWillMount() {
    let ultimaCotizacion = this.props.ultimaCotizacion;
    if (ultimaCotizacion === null && ultimaCotizacion.length === VALOR_ZERO)
      ultimaCotizacion = null;

    if (this.props.store.reducer.negocio.sucursales[0]) {
      const idSucursal = this.props.store.reducer.negocio.sucursales[0]
        .idOrganizacionSucursal;
      const { usuarioSesion } = this.props.store.auth;
      let strPerfil = usuarioSesion.rol.codigo === COD_CORREDOR ? usuarioSesion.rol.perfil : PERFIL_LDAP
      let delegado = await obtenerConfiguracionDelegado(
        idSucursal,
        strPerfil
      );

      if (delegado && delegado.persona) {
        let ultimaCotizacion = Object.assign({}, this.state.ultimaCotizacion);
        ultimaCotizacion.nombre = delegado.persona.nombre;
        ultimaCotizacion.apellidoMaterno = delegado.persona.apellidoMaterno;
        ultimaCotizacion.apellidoPaterno = delegado.persona.apellidoPaterno;
        ultimaCotizacion.telefono = delegado.telefono ? delegado.telefono : ultimaCotizacion.telefono;
        ultimaCotizacion.email = delegado.email ? delegado.email : ultimaCotizacion.email;
        ultimaCotizacion.rutDelegado = delegado.persona.identificador;
        this.setState({ ultimaCotizacion, cargando: false });
        this.props.guardar_datos_delegado_negocio(ultimaCotizacion);
      } else {
        this.setState({
          ultimaCotizacion,
          cargando: false,
        });
        this.props.guardar_datos_delegado_negocio(ultimaCotizacion);
      }
    } else {
      this.setState({
        ultimaCotizacion,
        cargando: false,
      });
      this.props.guardar_datos_delegado_negocio(ultimaCotizacion);
    }
    if (this.props.store.auth.usuarioSesion.rol.codigo === COD_CORREDOR) {
      this.props.history.push({
        pathname: RUTA_COTIZACION_ESTADO_ENROLAMIENTO,
      });
    }
  }

  modalAsignacion = () => {
    let negocio = this.state.negocio;
    let dataModal = {
      value: negocio.idPersonaEjecutivo,
      action:
        negocio.ejecutivoAsignado === "Por asignar" ? "Asignar" : "Reasignar",
    };
    this.setState({
      dataModal,
      modalAsignacion: true,
    });
  };

  mostrarModalAsignacion = (boolean) => {
    this.setState({ modalAsignacion: boolean });
  };

  actualizarEjecutivoAsignado = (ejecutivo) => {
    let negocio = Object.assign({}, this.state.negocio);

    negocio.ejecutivoAsignado = `${ejecutivo.nombre} ${ejecutivo.apellidoPaterno}`;
    negocio.emailEjecutivoAsignado = ejecutivo.email;
    negocio.idEjecutivoAsignado = ejecutivo.idPersona;

    this.setState({ negocio });
  };

  render() {

    let ultimaCotizacion = this.props.ultimaCotizacion;

    return (
      <React.Fragment>
        {this.state.negocio.hasOwnProperty("ejecutivoAsignado") &&
          this.state.modalAsignacion && (
            <ModalAsignarEjecutivo
              negocio={this.state.negocio}
              ultimaCotizacion={this.state.ultimaCotizacion}
              tipoNegocio={this.state.tipoNegocio}
              value={this.state.dataModal.value}
              action={this.state.dataModal.action}
              modalAsignacion={this.state.modalAsignacion}
              actualizarEjecutivoAsignado={this.actualizarEjecutivoAsignado}
              mostrarModalAsignacion={this.mostrarModalAsignacion}
            />
          )}

        <div className="shadow">
          <div className="card pt-4 pb-5">
            <div className="row">
              <div className="col-md-2 text-center">
                <img width="85" height="85" src={iconNegocio} alt="" />
              </div>
              <div className="col-md-10 mt-3 mt-md-0 text-center text-md-left">
                <div className="row">
                  <div className="col-md-5">
                    <span
                      className="font-weight-bold mb-2 d-block h6"
                      style={{ color: "#e89b03" }}
                    >
                      Datos de empresa
                    </span>
                    <span className="font-weight-bold text-muted mr-1">
                      Razón social:
                    </span>
                    {this.state.negocio.razonSocial} <br />
                    <span className="font-weight-bold text-muted mr-1">
                      RUT:
                    </span>
                    {format(
                      this.state.negocio.rutEmpresa +
                      this.state.negocio.digitoVerificador
                    )}
                    <br />
                    <span className="font-weight-bold text-muted mr-1">
                      Fecha enrolamiento:
                    </span>
                    {this.state.negocio &&
                      this.state.negocio.fechaTerminoEnrolamiento
                      ? moment(
                        this.state.negocio.fechaTerminoEnrolamiento
                      ).format("DD-MM-YYYY")
                      : " - "}
                    {this.state.perfil === COD_JEFATURA &&
                      this.state.negocio.estadoNegocio.idEstadoNegocio ===
                      ESTADO_NEGOCIO_3 && (
                        <button
                          className="btn btn-sm btn-outline-warning d-inline ml-3 px-2 py-0"
                          onClick={() => {
                            return this.props.mostrar_modal_fecha_enrolamiento(
                              true
                            );
                          }}
                        >
                          Extender fecha
                        </button>
                      )}
                    {(this.state.perfil === COD_EJECUTIVO || this.state.perfil === COD_EJECUTIVO_COMPLETO) &&
                      this.state.negocio.estadoNegocio.idEstadoNegocio ===
                      ESTADO_NEGOCIO_2 &&
                      this.state.canalVenta > TIPO_CANAL_VENTA_DIGITAL && (
                        <button
                          className="btn btn-sm btn-outline-warning d-inline ml-3 px-2 py-0"
                          onClick={() => {
                            return this.props.mostrar_modal_fecha_enrolamiento(
                              true
                            );
                          }}
                        >
                          {!this.state.negocio.fechaTerminoEnrolamiento
                            ? "Definir fecha"
                            : "Extender fecha"}
                        </button>
                      )}
                    <br />
                  </div>

                  <div
                    className="col-1 mx-0 px-0"
                    style={{ borderLeft: "1px solid #e89b03" }}
                  ></div>

                  <div className="col-md-6 mt-3 mt-md-0 animated fadeIn">
                    <span
                      className="font-weight-bold mb-2 d-block h6"
                      style={{ color: "#e89b03" }}
                    >
                      Datos de contacto
                    </span>
                    <span className="font-weight-bold text-muted mr-1">
                      Nombre:
                    </span>
                    {!this.state.cargando
                      ? this.state.ultimaCotizacion.nombre +
                      " " +
                      this.state.ultimaCotizacion.apellidoPaterno +
                      " " +
                      this.state.ultimaCotizacion.apellidoMaterno
                      : "-"}{" "}
                    <br />
                    <span className="font-weight-bold text-muted mr-1">
                      Teléfono:
                    </span>
                    {!this.state.cargando
                      ? this.state.ultimaCotizacion.telefono
                      : "-"}{" "}
                    <br />
                    <span className="font-weight-bold text-muted mr-1">
                      Email:
                    </span>
                    {!this.state.cargando
                      ? this.state.ultimaCotizacion.email
                      : "-"}{" "}
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Authorized roles={[4]}>
            <div
              className="card py-3"
              style={{
                color: "#fff",
                background: "#212529",
                borderRadius: "0 0 0.25rem 0.25rem",
              }}
            >
              <div className="row mx-0">
                <div className="col-lg-6 col-md-12">
                  <img src={iconUsuario} className="ml-3" width="40" alt="" />
                  <span className="text-primary ml-3 font-weight-bold">
                    Ejecutivo /
                  </span>
                  <span className="font-weight-bold">
                    {` ${this.state.negocio.ejecutivoAsignado}`}{" "}
                  </span>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="d-block text-lg-right text-center">
                    {/* BOTÓN ASIGNAR/REASIGNAR */}
                    {this.state.tipoNegocio === T_NEGOCIO_ACTIVO && (
                      <button
                        onClick={() => {
                          if (
                            !ultimaCotizacion ||
                            ultimaCotizacion.length === VALOR_ZERO
                          )
                            notificacion("info", ALERT_ULT_COTI);
                          else this.modalAsignacion();
                        }}
                        id="asignarEjecutivo"
                        className="btn btn-whiteborder btn-sm d-inline mr-3"
                      >
                        <i className="fa fa-user mr-2" aria-hidden="true" />
                        {this.state.negocio.ejecutivoAsignado === "Por asignar"
                          ? "Asignar Ejecutivo"
                          : "Reasignar Ejecutivo"}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Authorized>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state,
});

export default withRouter(
  connect(mapStateToProps, {
    mostrar_modal_fecha_enrolamiento,
    guardar_datos_delegado_negocio,
  })(CabeceraNegocio)
);
