import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { BarraNavegacion } from "../../transversales/Navbar";
import CabeceraNegocioAsegurado from "./CabeceraNegocioAsegurado";
import { resetSesion, actualizar_progreso_asegurable } from "../../../../actions/actions";
import { cerrarSesion as cerrarSesionServicio } from "../../../../services/auth.services";
import cookie from "react-cookies";
import EncuestaSatisfaccion from "../../transversales/EncuestaSatisfaccion";
import BannerEncuesta from "../../transversales/banner/BannerEncuesta";
import { DONE, GA_CATEGORIA_FORMULARIO_ENROLAMIENTO, GA_LABEL_SOLICITUD_COMPLETADA, RESUMEN_INDICE, RUTA_INICIO_SESION, VERDADERO } from "../../../../utils/Constantes";
import { crearEventoTraza } from "../../../../utils/analitica";

const icoLike = require("../../../assets/images/like.svg");

class SolicitudCompletada extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idEncuestaSatisfaccion: this.props.store.reducer.data_asegurable
        .idEncuestaSatisfaccion,
      idAsegurable: this.props.store.reducer.data_asegurable.idAsegurable,
      rutAsegurable: this.props.store.auth.usuarioSesion.rut,
    };
  }

  componentDidMount() {
    crearEventoTraza(GA_CATEGORIA_FORMULARIO_ENROLAMIENTO, GA_LABEL_SOLICITUD_COMPLETADA);
    let { progresoAsegurable } = this.props.store.reducer;

    progresoAsegurable.wizard[RESUMEN_INDICE].hecho = VERDADERO;
    progresoAsegurable.wizard[RESUMEN_INDICE].estado = DONE;
    progresoAsegurable.porcentajeAvance = 100;

    this.props.actualizar_progreso_asegurable(progresoAsegurable);
  }

  cerrarSesion = async () => {
    cerrarSesionServicio(this.props.store.auth.usuarioSesion.rol.codigo);
    this.props.resetSesion();
    cookie.remove("time", { path: "/" });
    sessionStorage.removeItem("_nSeVC");
    sessionStorage.clear();
    this.props.history.push(RUTA_INICIO_SESION);
    window.location.replace("");
  };

  render() {
    return (
      <React.Fragment>
        <BarraNavegacion />
        <BannerEncuesta></BannerEncuesta>
        <CabeceraNegocioAsegurado />
        <div className="container animated fadeIn">
          <div className="row pt-4 mb-4">
            <div className="col-12">
              <div className="card shadow">
                <div className="card-body p-5">
                  <div className="d-block text-center my-3">
                    <img src={icoLike} alt="Solicitud completa" width="250px" />
                  </div>
                  <h3 className="text-primary text-center mt-5">
                    <b>COMPLETADO!</b> Ya se envío tu solicitud de incorporación
                  </h3>

                  {!this.state.idEncuestaSatisfaccion ? (
                    <EncuestaSatisfaccion
                      idAsegurable={this.state.idAsegurable}
                      rutAsegurable={this.state.rutAsegurable}
                      validarEncuestaEnviada={() => {
                        this.cerrarSesion();
                      }}
                    />
                  ) : (
                    <button
                      id="botonCerrarSesion"
                      className="btn btn-principal btn-sm d-block mx-auto mt-3"
                      type="button"
                      onClick={this.cerrarSesion}
                    >
                      Cerrar sesión
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state,
});

export default withRouter(
  connect(mapStateToProps, { resetSesion, actualizar_progreso_asegurable })(SolicitudCompletada)
);
