import React, { Component } from "react";
import ModalEditarAsegurable from "../ModalEditarAsegurable";

class ModalExpiracion extends Component {
  state = {
    modalExpiracion: this.props.modalExpiracion
  };
  render() {
    return (
      <ModalEditarAsegurable
        idSubmit="submitExpiracion"
        type="medium"
        show={this.state.modalExpiracion}
        title=""
        action="Aceptar"
        onClose={() => {
          this.props.mostrarModalExpiracion(false);
        }}
        onSubmit={() => {
          this.props.mostrarModalExpiracion(false);
        }}
        showCancelButton={false}
        showCloseButton={true}
        showLeftColumn={false}
      >
        <form name="form-declaracion" ref="formRef" onSubmit={this.submit}>
          <div className="row">
            <div className="col-12 col-md-8 offset-md-2 text-center">
              <div
                className="mb-3 font-weight-bold"
                style={{ color: "#ED8D32", fontSize: "1.8rem" }}
              >
                <i className="fa fa-exclamation-circle fa-4x"></i>{" "}
                <div>Fecha está cerca de expirar</div>
              </div>
              <div style={{ fontSize: "1.2rem" }}>
                <p>
                  Quedan XXX días para el vencimiento del periodo de
                  enrolamiento de la cotización YYY
                </p>
                <p>
                  Si desea modificar la fecha, ir al panel{" "}
                  <span className="text-muted">
                    <i className="fa fa-cog"></i> <span>Opciones</span>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </form>
      </ModalEditarAsegurable>
    );
  }
}

export default ModalExpiracion;
