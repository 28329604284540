import React from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

const ModalEliminar = ({titulo, handleChange}) => {

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
            <div className="modal-confirm-container">
              <div className="d-flex">
                <p className="h4">
                  {titulo ? titulo : "¿Está seguro de eliminar este producto?"}
                </p>
              </div>
    
              <div className="row mt-4">
                <button
                  className="btn btn-principal color-secundario btn-sm d-block ml-auto mr-2"
                  onClick={() => {
                    onClose();
                  }}
                >
                  No
                </button>
                <button
                  className="btn btn-principal btn-sm"
                  onClick={(e) => {
                      handleChange();
                      onClose();
                  }}
                >
                  Sí
                </button>
              </div>
            </div>
        );
      }
    });

    return null;
}

export default ModalEliminar;