import React from "react";
import imgLike from "../../../../assets/images/like-orange.svg";
import { withRouter } from "react-router-dom";
import Authorized from "../../../../../utils/Authorized";
import {
  SECCION_VER_ASEGURABLES,
  VALOR_TRES, VALOR_SIETE,
  COD_CORREDOR
} from "../../../../../utils/Constantes";

const IndicadoresEnrolamientoVD = (props) => {
  return (
    <div className="row">
      {/* Total trabajadores  */}
      <div className="col-md-6 mb-3">
        <div className="card shadow" style={{ minHeight: 110 }}>
          <div className="card-body py-3">
            <div
              className="mb-3"
              style={{
                fontSize: "12px",
                paddingLeft: 0,
              }}
            >
              <span className=" text-muted font-weight-bold">
                Número de trabajadores
              </span>
            </div>
            <div className="mb-0 font-weight-bold h6">
              {props.cotizacion.planCotizado
                ? props.totalAsegurablesVD.planCotizado[0].totalTitular +
                " Trabajadores"
                : props.maximoAsegurableVD > props.totalAsegurablesVD ?
                  props.maximoAsegurableVD + " Trabajadores" : props.totalAsegurablesVD + " Trabajadores"}
            </div>
          </div>
        </div>
      </div>

      {/* Trabajadores enrolados */}
      <div className="col-md-6 mb-3">
        <div className="card shadow" style={{ minHeight: 110 }}>
          <div className="card-body py-3">
            <div className="mb-3" style={{ fontSize: "12px" }}>
              <span className="text-muted font-weight-bold">
                Número de trabajadores enrolados
              </span>

              <Authorized roles={[2]}>
                <span
                  className="font-weight-bold mb-2 d-block h6 puntero-ver-venta-directa"
                  style={{ color: "#e89b03", float: "right" }}
                  onClick={() => {
                    props.history.push({
                      pathname: "/delegado/mis-procesos",
                    });
                  }}
                >
                  VER
                </span>
              </Authorized>
            </div>

            <div className="mb-0 font-weight-bold d-inline h6">
              {props.trabajadoresEnrolados} Titulares
            </div>
            <div className="mb-0 font-weight-bold d-inline h6 ml-5">
              {props.totalCargas} Cargas
            </div>
          </div>
        </div>
      </div>

      {/* Productos y coberturas */}
      <div className="col-md-6 mb-3">
        <div className="card shadow" style={{ minHeight: 160 }}>
          <div className="card-body py-3">
            <div className="d-flex mb-3" style={{ fontSize: "12px" }}>
              <span className="text-muted font-weight-bold">
                Productos y coberturas
              </span>
            </div>
            <div className="mb-0 font-weight-bold">
              <div className="row">
                <div className="tarjeta-producto-cobertura col-6">
                  <span className="span-prod-cobertura-venta-directa">
                    {props.listaProductoCobertura[0].glosaSubProducto}
                  </span>

                  {props.listaProductoCobertura[0].glosaCoberturas.map(
                    (item) => (
                      <li
                        key={`${props.listaProductoCobertura[0].glosaSubProducto}-${item.glosaCobertura}`}
                      >
                        <span style={{ color: "#e89b03", marginRight: 3 }}>
                          •
                        </span>
                        {item.glosaCobertura}
                      </li>
                    )
                  )}
                </div>
                {props.listaProductoCobertura[1] && (
                  <div className="tarjeta-producto-tamaño-letra col-6">
                    <span className="span-prod-cobertura-venta-directa">
                      {props.listaProductoCobertura[1].glosaSubProducto}
                    </span>
                    {props.listaProductoCobertura[1].glosaCoberturas.map(
                      (item) => (
                        <li
                          key={`${props.listaProductoCobertura[1].glosaSubProducto}-${item.glosaCobertura}`}
                        >
                          <span style={{ color: "#e89b03", marginRight: 3 }}>
                            •
                          </span>
                          <span>{item.glosaCobertura}</span>
                        </li>
                      )
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Opciones de enrolamiento */}
      <div className="col-md-6 mb-3">
        <div className="card shadow" style={{ minHeight: 160 }}>
          <div className="card-body py-3">
            <div className="row">
              <div className="col-12">
                <span
                  className="text-muted font-weight-bold d-block"
                  style={{ fontSize: "12px" }}
                >
                  Opciones de enrolamiento
                </span>
              </div>
              <Authorized roles={[VALOR_TRES, VALOR_SIETE, COD_CORREDOR]}>
                <span
                  className="font-weight-bold mt-3 ml-3 puntero-general"
                  style={{ color: "#e89b03" }}
                  onClick={() => { props.setSeccionEnrolamiento(SECCION_VER_ASEGURABLES) }}
                >
                  <i
                    className="fa fa-users mr-1"
                    aria-hidden="true"
                  ></i>{" "}
                  Asegurables
                </span>
              </Authorized>
              <Authorized roles={[2]}>
                <div className="col-12 text-center mb-2">
                  <span
                    className="text-muted font-weight-bold d-block text-right"
                    style={{ fontSize: "12px", visibility: "hidden" }}
                  >
                    Cierre enrolamiento
                  </span>
                  <div className="mb-0 font-weight-bold text-right">
                    <div className="col-12">
                      {props.negocio.fechaFinEnrolamiento ? (
                        <div className="d-block text-center">
                          <img
                            src={imgLike}
                            alt="Enrolamiento terminado"
                            width="40"
                            height="40"
                            className="icon-like-orange mt-0"
                          />
                          <p className="font-weight-bold mb-0">
                            Enrolamiento terminado
                          </p>
                        </div>
                      ) : (
                        <button
                          disabled={!props.nominaCompleta}
                          id="cerrarEnrolamiento"
                          className="btn btn-principal btn-sm mx-auto"
                          title={
                            !props.nominaCompleta
                              ? "Disponible cuando almenos un asegurables haya terminado su enrolamiento"
                              : null
                          }
                          onClick={() => {
                            props.history.push({
                              pathname: "/delegado/nomina-asegurable",
                              mostrarNomina: true,
                              aceptarNomina: true,
                              asegurablesSinEnrolar:
                                props.trabajadoresPorEnrolar,
                            });
                          }}
                        >
                          <div className="my-auto">
                            <i
                              aria-hidden="true"
                              className="fa fa-times-circle-o mr-2"
                            ></i>
                            Cerrar Enrolamiento
                          </div>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </Authorized>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(IndicadoresEnrolamientoVD);
