import React, { useEffect } from "react";

const vidacamara_mantencion = require("../../assets/images/vidacamara_mantencion.svg");
const browser_mantencion = require("../../assets/images/browser_mantencion.svg");

const MantencionPage = () => {
  useEffect(() => {
    // componentDidMount
    document.body.style.paddingBottom = "0px";

    return () => {
      // componentWillUnmount
      document.body.style.paddingBottom = "50px";
    };
  }, []);

  return (
    <div className="d-flex vh-100 align-items-center justify-content-center mantencion">
      <div className="card mantencion__card">
        <div className="mantencion__image-wrapper">
          <img
            src={vidacamara_mantencion}
            alt="Icono de mantención de videocámara"
            className="img-fluid mx-2 mantencion__image mantencion__image--large"
          />
        </div>
        <div className="mantencion__image-wrapper">
          <img
            src={browser_mantencion}
            alt="Icono de mantención del navegador"
            className="img-fluid mx-2 mantencion__image mantencion__image--small"
          />
        </div>
        <div className="card-body">
          <p className="mantencion__text-bold">
            Actualmente estamos actualizando una nueva experiencia digital.
          </p>
          <p className="mantencion__text-large">¡Gracias por tu comprensión!</p>
        </div>
      </div>
    </div>
  );
};

export default MantencionPage;
