import {
  COD_CORREDOR,
  COD_EJECUTIVO_COMPLETO,
  COD_EJECUTIVO_MANTENCION,
  PERFIL_CORREDOR,
  PERFIL_DELEGADO,
  PERFIL_LDAP,
  POSICION_INICIAL,
  RUT_DELEGADO,
  RUT_EJECUTIVO,
  SEGUNDA_POSICION,
  TIPO_INCORPORACION_MANTENCION,
  USU_CORREDOR,
} from "../../../utils/Constantes";
import { obtenerNegocios } from "../../../services/cotizacion.services";
import { extraerRutyDigitoVerificador } from "../../../utils/Functions";

/**
 * buscar idNegocio por rut empresa
 * @param lstPoliza
 * @param objNegocio
 * @param objSesion
 * @returns {Promise<*>}
 */
export async function buscarPorRutEmpresa(lstPoliza, objNegocio, objSesion) {
  let strPerfil = PERFIL_DELEGADO;
  let strCampoPerfil = RUT_DELEGADO;
  let strCampoPerfilValor = objSesion.rut;

  if (
    objSesion.rol.codigo === COD_EJECUTIVO_MANTENCION ||
    objSesion.rol.codigo === COD_EJECUTIVO_COMPLETO
  ) {
    strPerfil = PERFIL_LDAP;
    strCampoPerfil = RUT_EJECUTIVO;
    strCampoPerfilValor = objSesion.rut;
  }

  if (objSesion.rol.codigo === COD_CORREDOR) {
    strPerfil = PERFIL_CORREDOR;
    strCampoPerfil = USU_CORREDOR;
    strCampoPerfilValor = objSesion.id;
  }

  for (const objPoliza of lstPoliza) {
    const strRutLimpio = extraerRutyDigitoVerificador(
      objPoliza.poliza.rutEmpresa
    );
    let objDataEnvio = {
      negocio: {
        vigente: true,
        digitoVerificador: strRutLimpio[SEGUNDA_POSICION],
        rutEmpresa: strRutLimpio[POSICION_INICIAL],
      },
      [strCampoPerfil]: strCampoPerfilValor,
      perfil: strPerfil,
    };
    let lstNegocios = await obtenerNegocios(objDataEnvio);

    if (lstNegocios && lstNegocios.length > 0) {
      lstNegocios.forEach((objElemento) => {
        if (
          objElemento.tipoIncorporacion &&
          objElemento.tipoIncorporacion.idTipoIncorporacion ===
            TIPO_INCORPORACION_MANTENCION
        ) {
          objNegocio.idNegocio = objElemento.idNegocio;
        }
      });
    }
  }

  return objNegocio;
}
