import React, { useEffect } from "react";
import Spinner from "../transversales/Spinner";
import { GA_CATEGORIA_LOGIN_EXTERNO, RUTA_INICIO_SESION } from "../../../utils/Constantes";
import { useAutenticacion } from "../../../eventos/useAutenticacion";
import { crearEventoTraza } from "../../../utils/analitica";
import { useSelector } from "react-redux";
import { Incorporacion } from "./Incorporacion";
const { validate } = require("rut.js");

const IniciarSesionExterno = (props) => {

  const { procesarInicioExterno } = useAutenticacion();
  const blnUsuarioAutenticado     = useSelector(store => store.auth.usuarioAutenticado);
  const { rut, token, perfil }    = props.match.params;

  useEffect(() => {
    if (!validate(rut)) {
      props.history.push(RUTA_INICIO_SESION);
    }
    crearEventoTraza(GA_CATEGORIA_LOGIN_EXTERNO, perfil);
    procesarInicioExterno(rut, token, perfil);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Spinner claseColor="naranjo" idSpinner="spinnerNaranjo" posicionLogo="align-self-center w-100" />
      { blnUsuarioAutenticado && (
        <Incorporacion />
      )}
    </>
  );
};

export default IniciarSesionExterno;