import React from "react";
import productoAmparo from "../../../assets/images/Banner Amparo_2022.svg";
import productoLucas from "../../../assets/images/Banner Lucas_2022.svg";
import productoPaz from "../../../assets/images/Banner Paz_2022.svg";
import Carrusel from "react-bootstrap/Carousel";
import { crearEvento } from "../../../../utils/analitica";

export default function BannerEncuesta() {
  let imagenProductos = [
    {
      imagenProducto: productoAmparo,
      ruta: process.env.REACT_APP_URL_BANNER_AMPARO
    },
    {
      imagenProducto: productoLucas,
      ruta: process.env.REACT_APP_URL_BANNER_LUCAS
    },
    {
      imagenProducto: productoPaz,
      ruta: process.env.REACT_APP_URL_BANNER_PAZ
    },
  ];
  return (
    <div style={{ position: 'sticky' }}>
      <Carrusel indicators={false} interval={2000}>
        {imagenProductos.map((data, indice) => {
          return (
            <Carrusel.Item key={indice}>
              <div>
                <a
                  href={data.ruta}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => crearEvento("Accidentes_Personales", "Accediendo a Accidentes Personales","Banner_Asegurable" )}
                >
                  <img
                    className="img-fluid w-100"
                    src={data.imagenProducto}
                    alt="Nuevo seguro catastrófico"
                  />
                </a>
              </div>
            </Carrusel.Item>
          );
        })}
      </Carrusel>
    </div>
  );
}
