import React from "react";
import moment from "moment";
import { POSICION_INICIAL } from "../../../utils/Constantes";

export const ListaNegocio = ({
  negocio,
  negocioRevisado,
  tickNegocioRevisado,
  avatarInicioAmarillo,
  avatarInicioNegro,
  razonSocial,
  verDetalle,
}) => {
  return (
    <div className="horizontal-scroll__container mb-2 shadow bg-white mx-2 negociosLista">
      <div className="p-4">
        <table>
          <tbody>
            <tr>
              <td className="col-3 negocio-lista__column half-border">
                <div className="row">
                  <div className="col-5 row">
                    <div>
                      <div className="avatar-card">
                        {negocio.negocioUsuarioRevisor[POSICION_INICIAL].revisado ? (
                          <span className="tick-badge">
                            <img
                              src={tickNegocioRevisado}
                              alt="..."
                              width="40%"
                              class="ml-4"
                            />
                          </span>
                        ) : null}
                        <img
                          src={
                            negocio.negocioUsuarioRevisor[POSICION_INICIAL].revisado
                              ? avatarInicioNegro
                              : avatarInicioAmarillo
                          }
                          className="d-block mx-auto mb-2"
                          alt="No hay resultados"
                          width="100%"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-8 ">
                    <p className=" font-weight-bold ">NOMBRE APELLIDO</p>
                    <div>
                      {" "}
                      <span>
                        {negocio.usuarios[0].nombre}{" "}
                        {negocio.usuarios[0].apellidoPaterno}{" "}
                        {negocio.usuarios[0].apellidoMaterno}
                      </span>
                    </div>
                  </div>
                </div>
              </td>
              <td className="col-3 negocio-lista__column half-border">
                <p className="font-weight-bold ">CLIENTE/RAZÓN SOCIAL</p>
                <p className="m-0">{razonSocial}</p>
              </td>
              <td className=" col-2 negocio-lista__column half-border">
                <div className=" col-12">
                  <p className=" font-weight-bold  ">INGRESOS</p>
                  <div>
                    {" "}
                    {negocio.cantidadSolicitud}{" "}
                    {negocio.cantidadSolicitud === 1
                      ? "solicitud"
                      : "solicitudes"}
                  </div>
                </div>
              </td>
              <td className="col-2 negocio-lista__column_mini half-border ">
                <div className=" col-12">
                  <p className=" font-weight-bold">FECHA</p>
                  <p className="m-0">
                    {negocio.fechaModifica !== null
                      ? moment(negocio.fechaModifica).format("DD MMM YYYY")
                      : moment(negocio.fechaCrea).format("DD MMM YYYY")}
                  </p>
                </div>
              </td>
              <td className=" col-2negocio-lista__column h-100">
                <button
                  className="m-auto btn btn-principal color-secundario btn-block center-btn"
                  type="button"
                  onClick={() => {
                    verDetalle(negocio, negocio.revisado);
                  }}
                >
                  Ver detalle
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
