import React, { useState, Fragment, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import ImgGestionarEjecutivo from "../../../../assets/images/gestionarEjecutivo.png";
import checkRojo from "../../../../assets/images/checkRed.png";
import checkAmarillo from "../../../../assets/images/checkYellow.png";
import cruzRoja from "../../../../assets/images/cruzRed.png";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Spinner from "../../../transversales/Spinner";
import {
    obtenerEjecutivosJefaturaPorRut,
    agregarEjecutivoAJefatura,
    agregarPerfilEjecutivo,
} from "../../../../../services/ejecutivo.services";
import {
    obtenerJefaturaComercial,
} from "../../../../../services/transversales.service";
import { notificacion } from "../../../transversales/Notificaciones";
import {
    VALIDACION_RUT,
    RUTA_NEGOCIOS,
    PERFIL_LDAP,
    CAMPO_REQUERIDO_RADIO,
    VALIDACION_RADIO,
    CAMPO_REQUERIDO,
    VALIDACION_REGION,
    EXITO_AGREGAR_EJECUTIVO,
    ERROR_AGREGAR_EJECUTIVO,
    VALOR_ZERO,
    VALOR_TRES,
    VALOR_UNO,
    VALOR_DOS,
    VALOR_TRECE,
    VALIDACION_PERFIL,
    GA_LABEL_AGREGAR_EJECUTIVO,
    GA_CATEGORIA_EJECUTIVOS
} from "../../../../../utils/Constantes";
import { crearEventoTraza } from "../../../../../utils/analitica";

const { format, clean, validate } = require("rut.js");


const AgregarEjecutivo = (props) => {

    useEffect(() => {
        crearEventoTraza(GA_CATEGORIA_EJECUTIVOS, GA_LABEL_AGREGAR_EJECUTIVO);
    },[]);

    const [opcionGestionarEjecutivo, setOpcionGestionarEjecutivo] = useState(VALOR_ZERO);

    const [rutEjecutivo, setRutEjecutivo] = useState("");
    const [nombreEjecutivo, setNombreEjecutivo] = useState("");
    const [nombreJefatura, setNombreJefatura] = useState("");

    const [cargando, setcargando] = useState(false);

    const { usuarioSesion } = useSelector((store) => store.auth);

    const [ejecutivoJefatura, setEjecutivoJefatura] = useState(null);
    const [listaJefaturaComercial, setListaJefaturaComercial] = useState([]);
    const [cargarDatos, setCargarDatos] = useState(false);

    const cerrarDialogo = async () => {
        props.history.push(RUTA_NEGOCIOS);
    };

    const buscarEjecutivo = async (rut) => {
        const responseEjecutivo = await obtenerEjecutivosJefaturaPorRut(
            rut,
            PERFIL_LDAP
        );
        setEjecutivoJefatura(responseEjecutivo)
        if (responseEjecutivo === null) {
            //EJECUTIVO NO SE ENCUENTRA EN LA BD
            setRutEjecutivo(rut)
            setOpcionGestionarEjecutivo(VALOR_TRES);
        } else if (responseEjecutivo.jefatura === null) {
            //EJECUTIVO SIN JEFATURA ASGINADA
            setRutEjecutivo(format(responseEjecutivo.rut))
            setNombreEjecutivo(responseEjecutivo.nombre + " " + responseEjecutivo.apellidoPaterno + " " + responseEjecutivo.apellidoMaterno)
            const responseJefaturaComercial = await obtenerJefaturaComercial();
            setListaJefaturaComercial(responseJefaturaComercial
                .filter((item) => item.email === usuarioSesion.email));
            setOpcionGestionarEjecutivo(VALOR_UNO)
        } else {
            //EJECUTIVO CON JEFATURA ASGINADA
            setRutEjecutivo(format(responseEjecutivo.rut))
            setNombreEjecutivo(responseEjecutivo.nombre + " " + responseEjecutivo.apellidoPaterno + " " + responseEjecutivo.apellidoMaterno)
            setNombreJefatura(responseEjecutivo.jefatura.nombre + " " + responseEjecutivo.jefatura.apellidoPaterno + " " + responseEjecutivo.jefatura.apellidoMaterno)
            setOpcionGestionarEjecutivo(VALOR_DOS);
        }
        setcargando(false)
    }

    const agregarEjecutivo = async (dato) => {

        if (dato.radioAgregarEjecutivoSi) {
            const dataEnvio = {
                idPersona: ejecutivoJefatura.idPersona,
                idRegion: dato.region,
                idEjecutivoEquipo: listaJefaturaComercial[VALOR_ZERO].idEjecutivoEquipo,
            };
            let res = await agregarEjecutivoAJefatura(dataEnvio);
            if (res !== null) {
                agregarPerfilesEjecutivo(dato);
            } else {
                setCargarDatos(false);
                notificacion("danger", ERROR_AGREGAR_EJECUTIVO);
            }
            setcargando(false);
        } else {
            cerrarDialogo();
        }
    }

    const agregarPerfilesEjecutivo = async (dato) => {
        let dataEnvioPerfil = {
            rut: rutEjecutivo.replace(/\./g, ''),
            sitioCod: process.env.REACT_APP_COD_SITIO,
            envioMail: false
        }
        if (dato.perfil.length === 1) {
            dataEnvioPerfil = {
                idPerfil: dato.perfil[0],
                ...dataEnvioPerfil
            }
        } else if (dato.perfil.length === 2) {
            dataEnvioPerfil = {
                idPerfil: process.env.REACT_APP_COD_EJECUTIVO_COMPLETO,
                ...dataEnvioPerfil
            }
        }
        const resPerfil = await agregarPerfilEjecutivo(dataEnvioPerfil);
        if (await resPerfil !== null) {
            setcargando(false);
            notificacion("success", EXITO_AGREGAR_EJECUTIVO);
            cerrarDialogo();
        } else {
            setCargarDatos(false);
            notificacion("danger", ERROR_AGREGAR_EJECUTIVO);
        }
    }

    const handleSubmit = async (dato) => {
        setcargando(true)
        let rut = format(dato.rutEjecutivo).replace(/[.]/g, "");
        switch (opcionGestionarEjecutivo) {
            case VALOR_ZERO:
                buscarEjecutivo(rut);
                break;
            case VALOR_UNO:
                setCargarDatos(true);
                agregarEjecutivo(dato);
                break;
            case VALOR_DOS:
                cerrarDialogo();
                break;
            case VALOR_TRES:
                cerrarDialogo();
                break;
            default: return null;
        }
    };

    return (
        <React.Fragment>
            <div className="card shadow mb-3">
                <div className="card-body">
                    <div className="row">
                        <div className="mx-auto my-5 animated fadeIn">
                            <h4 className="text-center">
                                <img
                                    src={ImgGestionarEjecutivo}
                                    width="140"
                                    height="200"
                                    className="img-fluid d-block mx-auto mb-2"
                                    alt=""
                                ></img>
                                <strong style={{ color: "#e89b03" }}>Agregar ejecutivo</strong>
                            </h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12" style={{ paddingLeft: '15%', paddingRight: '15%' }}>
                            <Formik
                                enableReinitialize={true}
                                initialValues={{
                                    rutEjecutivo: "",
                                    radioAgregarEjecutivoSi: false,
                                    radioAgregarEjecutivoNo: false,
                                    region: VALOR_ZERO,
                                    perfil: []
                                }}
                                validationSchema={Yup.object().shape({
                                    rutEjecutivo: Yup.string()
                                        .test("validarRut", VALIDACION_RUT, (value) => {
                                            if (opcionGestionarEjecutivo !== VALOR_UNO) {
                                                return validate(clean(value));
                                            } else {
                                                return true;
                                            }
                                        }),
                                    radioAgregarEjecutivoSi: Yup.string()
                                        .required(CAMPO_REQUERIDO_RADIO)
                                        .test(
                                            "validarSelect",
                                            VALIDACION_RADIO,
                                            function (value) {
                                                return opcionGestionarEjecutivo === VALOR_ZERO ? true :
                                                    this.parent.radioAgregarEjecutivoSi !== 'true' && !this.parent.radioAgregarEjecutivoNo ? false : true;
                                            }
                                        ),
                                    region: Yup.number()
                                        .required(CAMPO_REQUERIDO)
                                        .test("validaRegion", VALIDACION_REGION, (value) => {
                                            return opcionGestionarEjecutivo === VALOR_ZERO ? true : value !== VALOR_ZERO ? true : false;
                                        }),
                                    perfil: Yup.array()
                                        .test("validaPerfil", VALIDACION_PERFIL, (value) => {
                                            return opcionGestionarEjecutivo === VALOR_ZERO
                                                ? true
                                                : value.length > 0
                                                    ? true
                                                    : false;
                                        }),
                                })}
                                onSubmit={async (
                                    values,
                                    { setSubmitting, resetForm, initialValues }
                                ) => {
                                    handleSubmit(values);
                                    resetForm(initialValues);
                                }}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    setFieldValue,
                                    setFieldTouched,
                                    handleChange,
                                    handleSubmit,
                                    handleBlur,
                                    field
                                }) => {
                                    return (
                                        <Form
                                            id="formularioAgregarEjecutivo"
                                            name="agregar-form"
                                            onSubmit={handleSubmit}
                                            onKeyDown={(e) => {
                                                if ((e.charCode || e.keyCode) === VALOR_TRECE) {
                                                    e.preventDefault();
                                                }
                                            }}
                                        >
                                            <div className="row">
                                                {/* Rut */}
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <strong style={{ fontSize: 15 }}>
                                                            RUT del ejecutivo
                                                        </strong>
                                                        <Field
                                                            id="rutEjecutivo"
                                                            name="rutEjecutivo"
                                                            placeholder="Ingresar RUT del ejecutivo"
                                                            maxLength="12"
                                                            minLength="11"
                                                            value={values.rutEjecutivo !== "-" && values.rutEjecutivo !== "" ? format(values.rutEjecutivo) : ""}
                                                            type="text"
                                                            width="100%"
                                                            className="form-control form-control-sm"
                                                            style={{ marginTop: 5 }}
                                                        />
                                                        {errors.rutEjecutivo &&
                                                            touched.rutEjecutivo && (
                                                                <small className="text-danger animated fadeIn">
                                                                    {errors.rutEjecutivo}
                                                                </small>
                                                            )}
                                                    </div>
                                                </div>
                                            </div>

                                            {opcionGestionarEjecutivo === VALOR_UNO ?
                                                <div className="col-12" id="agregarEjecutivoMargenes">
                                                    <div className="row">
                                                        <div className="col-md-12" style={{ marginTop: 15, marginBottom: 15 }}>
                                                            <strong style={{ fontSize: 15 }}>
                                                                Datos del ejecutivo
                                                            </strong>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-md-12" style={{ marginBottom: 0 }}>
                                                            <label>
                                                                Rut: {rutEjecutivo}
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <label>
                                                                Ejecutivo: {nombreEjecutivo}
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <div className="row" id="agregarEjecutivoMargenesAbajo">
                                                        <div className="col-md-12">
                                                            <img
                                                                src={checkRojo}
                                                                width="30"
                                                                height="30"
                                                                className="img-fluid mx-auto mb-2"
                                                                id="imgCheckAgregarEjecutivo"
                                                                alt=""
                                                            ></img>
                                                            <label>
                                                                Ejecutivo no se encuentra asignado a enrolamiento digital
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12" id="agregarEjecutivoSiYNo">
                                                        <div className="row">
                                                            <div className="col-8">
                                                                <label style={{ color: "#e89b03", marginTop: 5 }}>
                                                                    Desea agregar a {nombreEjecutivo} a su equipo
                                                                </label>
                                                            </div>

                                                            <div className="col-2">
                                                                <label style={{ fontSize: 20 }}>
                                                                    <input
                                                                        id="radioAgregarEjecutivoSi"
                                                                        name="radioAgregarEjecutivoSi"
                                                                        type="radio"
                                                                        size="100"
                                                                        value={values.radioAgregarEjecutivoSi}
                                                                        checked={values.radioAgregarEjecutivoSi}
                                                                        onClick={(event) => {
                                                                            setFieldValue(
                                                                                "radioAgregarEjecutivoNo",
                                                                                false
                                                                            );
                                                                            setFieldValue(
                                                                                "radioAgregarEjecutivoSi",
                                                                                !values.radioAgregarEjecutivoSi
                                                                            );
                                                                        }}
                                                                        readOnly
                                                                    />
                                                                    <span className="label-text" />
                                                                </label>
                                                                <span className="label-text" style={{ position: 'fixed', marginTop: 4 }}>
                                                                    SI
                                                                </span>
                                                            </div>
                                                            <div className="col-2">
                                                                <label style={{ fontSize: 20 }}>
                                                                    <input
                                                                        id="radioAgregarEjecutivoNo"
                                                                        name="radioAgregarEjecutivoNo"
                                                                        type="radio"
                                                                        value={values.radioAgregarEjecutivoNo}
                                                                        checked={values.radioAgregarEjecutivoNo}
                                                                        onClick={(event) => {
                                                                            cerrarDialogo()
                                                                        }}
                                                                        readOnly
                                                                    />
                                                                    <span className="label-text" />
                                                                </label>
                                                                <span className="label-text" style={{ position: 'fixed', marginTop: 4 }}>
                                                                    NO
                                                                </span>
                                                            </div>
                                                            <div className="col-8"></div>
                                                            {errors.radioAgregarEjecutivoSi &&
                                                                touched.radioAgregarEjecutivoSi && (
                                                                    <small className="text-danger animated fadeIn ">
                                                                        {errors.radioAgregarEjecutivoSi}
                                                                    </small>
                                                                )}
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <label>
                                                                    Región <span className="text-danger">*</span>
                                                                </label>
                                                                <select
                                                                    id="region"
                                                                    name="region"
                                                                    className="form-control"
                                                                    style={{ display: "block" }}
                                                                    value={values.region}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                >
                                                                    <option value="0"> Seleccione región</option>
                                                                    {listaJefaturaComercial && listaJefaturaComercial[VALOR_ZERO].jefaturaRegion &&
                                                                        listaJefaturaComercial[VALOR_ZERO].jefaturaRegion.map((item, i) => (
                                                                            <option
                                                                                key={`opcion_parentesco_${i}`}
                                                                                value={item.idRegion}
                                                                            >
                                                                                {item.glosaRegion}
                                                                            </option>
                                                                        ))}
                                                                </select>
                                                                {errors.region && touched.region && (
                                                                    <small className="text-danger animated fadeIn animated fadeIn">
                                                                        {errors.region}
                                                                    </small>
                                                                )}
                                                            </div>
                                                        </div>

                                                        <div className="row mt-3">
                                                            <div className="col-md-12">
                                                                <label id="checkbox-group">
                                                                    Perfil <span className="text-danger">*</span>
                                                                </label>
                                                                <div role="group" aria-labelledby="checkbox-group">

                                                                    <label className="switch mr-2">
                                                                        <Field
                                                                            type="checkbox"
                                                                            name="perfil"
                                                                            value={process.env.REACT_APP_COD_EJECUTIVO}
                                                                        />
                                                                        <span className="slider round" />
                                                                    </label>
                                                                    Ejecutivo Comercial

                                                                    <br />

                                                                    <label className="switch mr-2">
                                                                        <Field
                                                                            type="checkbox"
                                                                            name="perfil"
                                                                            value={process.env.REACT_APP_COD_EJECUTIVO_MANTENCION}
                                                                        />
                                                                        <span className="slider round" />
                                                                    </label>
                                                                    Ejecutivo Mantención
                                                                </div>
                                                                {errors.perfil && touched.perfil && (
                                                                    <small className="text-danger animated fadeIn animated fadeIn">
                                                                        {errors.perfil}
                                                                    </small>
                                                                )}
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                : opcionGestionarEjecutivo === VALOR_DOS ?
                                                    <div className="col-12" id="agregarEjecutivoMargenes">
                                                        <div className="row">
                                                            <div className="col-md-12" style={{ marginTop: 15, marginBottom: 15 }}>
                                                                <strong style={{ fontSize: 15 }}>
                                                                    Datos del ejecutivo
                                                                </strong>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-md-12" style={{ marginBottom: 0 }}>
                                                                <label>
                                                                    Rut: {rutEjecutivo}
                                                                </label>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <label>
                                                                    Ejecutivo: {nombreEjecutivo}
                                                                </label>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <img
                                                                    src={checkAmarillo}
                                                                    width="30"
                                                                    height="30"
                                                                    className="img-fluid mx-auto mb-2"
                                                                    id="imgCheckAgregarEjecutivo"
                                                                    alt=""
                                                                ></img>
                                                                <label>
                                                                    Ejecutivo pertenece al equipo de {nombreJefatura}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : opcionGestionarEjecutivo === VALOR_TRES &&
                                                    <div className="col-12" id="agregarEjecutivoMargenes">
                                                        <div className="row">
                                                            <div className="col-md-12" style={{ marginTop: 15, marginBottom: 15 }}>
                                                                <strong style={{ fontSize: 15 }}>
                                                                    Datos del ejecutivo
                                                                </strong>
                                                            </div>
                                                        </div>

                                                        <div className="row" style={{ marginBottom: 0, paddingBottom: 0 }}>
                                                            <div className="col-md-12" style={{ marginBottom: 0, paddingBottom: 0 }}>
                                                                <label>
                                                                    Rut: {rutEjecutivo}
                                                                </label>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-md-12" style={{ marginTop: 0 }}>
                                                                <label>
                                                                    Ejecutivo:
                                                                </label>
                                                                <img
                                                                    src={cruzRoja}
                                                                    width="30"
                                                                    height="30"
                                                                    alt=""
                                                                ></img>
                                                                <label style={{ color: 'red' }}>
                                                                    No se encuentra registrado en Vida Camara
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                            }


                                            {opcionGestionarEjecutivo === VALOR_ZERO ?
                                                <button
                                                    type="submit"
                                                    className="btn btn-principal mt-4 d-block ml-auto"
                                                >
                                                    Buscar
                                                </button>
                                                : opcionGestionarEjecutivo === VALOR_UNO ?
                                                    <button
                                                        type="submit"
                                                        className="btn btn-principal mt-4 d-block ml-auto"
                                                        disabled={cargarDatos}
                                                    >
                                                        {cargarDatos ? (
                                                            <Fragment>
                                                                <i className="fa fa-spinner fa-pulse fa-fw"></i>
                                                                <span className="sr-only">Loading...</span>
                                                            </Fragment>
                                                        ) : (
                                                            <span>Agregar</span>
                                                        )}
                                                    </button>
                                                    :
                                                    <button
                                                        type="submit"
                                                        className="btn btn-principal mt-4 d-block ml-auto"
                                                        onClick={() => cerrarDialogo()}
                                                    >
                                                        Cerrar
                                                    </button>
                                            }

                                        </Form>
                                    );
                                }}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
            {
                cargando ? <Spinner claseColor="transparente"
                    idSpinner="spinnerTransparente"
                    posicionLogo="posicionLogo align-self-center w-100" /> : null
            }
        </React.Fragment >

    );
};

export default withRouter(AgregarEjecutivo);
