import {COD_STATUS_EXITO, COD_STATUS_NA, ERROR_SERVIDOR} from "./Constantes";

export function errorBuilder(requestObject) {

    const serverErrorObject = {
        error: true,
        data: ERROR_SERVIDOR
    }


    if (requestObject.status) {
        switch (requestObject.status.codigo) {

            case COD_STATUS_EXITO:
                return {
                    error: false,
                    data: requestObject.objeto
                }
            case COD_STATUS_NA:
                return {
                    error: true,
                    data: requestObject.status.mensaje
                }
            default:
                return serverErrorObject
        }
    }
    return serverErrorObject

}