import React, { useState } from "react";

const BlockRadioSelection = ({ check, messages, onClick, numeroRadioButtons }) => {
  const [checkedRadio, setChecked] = useState(check);

  return (
    <React.Fragment>
        <div className="row mx-auto">
            <div className="col-md-12">
                <div className="check-group">
                    <label className="custom-checkbox p-1 block-checkbox">
                        <input
                            id="radioRA"
                            type="radio"
                            name="radio1"
                            checked={checkedRadio === 0}
                            onChange={async (e) => {
                                setChecked(0)
                                onClick(messages[0].option)
                            }}
                        />
                        <div className="check block-check-option"></div>
                        <div className="optionButtonBlock blockTitleOption">{messages[0].message}</div>
                        {checkedRadio === 0 && (
                            <small className="text-warning blockButtonHelpMessage">
                                {messages[0].helpMessage}
                            </small>
                        )}
                    </label>
                    <label className="custom-checkbox p-1 block-checkbox">
                        <input
                            id="radioRA2"
                            type="radio"
                            name="radio1"
                            checked={checkedRadio === 1}
                            onChange={async (e) => {
                               setChecked(1)
                                onClick(messages[1].option)
                            }}
                        />
                        <div className="check block-check-option"></div>
                        <div className="optionButtonBlock blockTitleOption">{messages[1].message}</div>
                        {checkedRadio === 1 && (
                            <small className="text-warning blockButtonHelpMessage">
                                {messages[1].helpMessage}
                            </small>
                        )}
                    </label>
                    { messages.length === 3 && 
                        <label className="custom-checkbox p-1 block-checkbox">
                            <input
                                type="radio"
                                name="radio1"
                                checked={checkedRadio === 2}
                                onChange={async (e) => {
                                    setChecked(2)
                                    onClick(messages[2].option)
                                }}
                            />
                            <div className="check block-check-option"></div>
                            <div className="optionButtonBlock blockTitleOption">{messages[2].message}</div>
                            {checkedRadio === 2 && (
                                <small className="text-warning blockButtonHelpMessage">
                                    {messages[2].helpMessage}
                                </small>
                            )}
                        </label>
                    }
                </div>
            </div>
        </div>
    </React.Fragment>
  );
};

export default BlockRadioSelection;
