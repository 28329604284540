import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { notificacion } from "../../../../transversales/Notificaciones";
import {
  VALIDACION_ASEGURABLE,
  ACTIVIDAD_DEPORTE,
  VALIDACION_ACTIVIDAD,
  W2_COLS_DEPORTES,
  ACEPTAR_TERMINOS,
  EXITO_DECLARACIONES,
  ERROR_DECLARACIONES,
  ALERTA_NO_DECLARACIONES,
  SI_NO_DECLARACIONES,
  NO_DECLARA_ACTIVIDADES_DEPORTES,
  NO_DECLARA,
  CAMPO_REQUERIDO_ACTIVIDAD,
  CAMPO_REQUERIDO_FRECUENCIA,
  CAMPO_REQUERIDO_ASEGURADO,
  DECLARACION_SALUD,
  DIAGNOSTICO_DISTINTO_CERO,
  VALOR_TRES,
  TIPO_PAGINA,
  TIPO_TAB,
  PAGINA_5,
  PAGINA_4,
  GA_CATEGORIA_FORMULARIO_ENROLAMIENTO,
  GA_LABEL_PREGUNTAS_DEPORTAS,
} from "../../../../../../utils/Constantes";
import {
  wizard,
  add_detalle,
  update_detalle,
  cambio_detalle,
  guardar_data_asegurable,
  guardar_data_wizard_asegurado,
  update_carga,
} from "../../../../../../actions/actions";
import Table from "../../../../transversales/DataTable";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import "moment/locale/es";
import { soloLetrasYNumeros } from "../../../../../../utils/Functions";
import { crearEventoTraza } from "../../../../../../utils/analitica";
import { eliminarDeclaracion, guardarDeclaraciones, procesarDeclaracion } from "../../../../../../services/declaracion.services";

class CoberturaActividadesDeportes extends Component {
  constructor(props) {
    super(props);
    this.aceptacionRef = React.createRef();
    this.state = {
      checked:
        this.props.store.reducer.dataWizardAsegurado.solicitud
          .declaroDeportes === null ||
          this.props.store.reducer.dataWizardAsegurado.solicitud
            .declaroDeportes === undefined
          ? null
          : this.props.store.reducer.dataWizardAsegurado.solicitud
            .declaroDeportes,
      edit: 0,
      aceptacionCheck:
        this.props.store.reducer.dataWizardAsegurado.solicitud
          .declaroDeportes !== null && this.props.store.reducer.dataWizardAsegurado.solicitud
          .declaroDeportes !== undefined,
      claseAceptacion: "",
      animacion: "formInit",
      agregar: true,
      declaracionComplementaria: {
        key: null,
        rut: "",
        idCatalogoTipoDeclaracion: 0,
        observacion: "",
      },
      detalle: [],
      cambioDetalle: false,
      informacion: null,
      columnDefs: [
        ...W2_COLS_DEPORTES,
        {
          cell: (row) => (
            <React.Fragment>
              <button
                id="botonEditar"
                className="btn btn-primary btn-sm btn-table mr-2"
                type="button"
                title="Editar"
                raised="true"
                primary="true"
                onClick={() => this.handleActionChange(row)}
              >
                <i className="fa fa-pencil" aria-hidden="true"></i>
              </button>
              <button
                id="botonEliminar"
                className="btn btn-danger btn-sm btn-table"
                type="button"
                title="Eliminar"
                raised="true"
                primary="true"
                onClick={() => this.confirm(row)}
              >
                <i className="fa fa-trash" aria-hidden="true"></i>
              </button>
            </React.Fragment>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
      ],
      loadingData: false
    };
  }

  componentWillMount() {
    setTimeout(() => {
      if (
        this.props.store.reducer.wizard.pagina === 3 &&
        this.props.store.reducer.wizard.tab === 2
      ) {
        this.setState({ animacion: "movingIn" });
      }
    }, 100);

    this.setState({
      informacion: this.props.store.reducer.declaraciones.find(
        (a) => a.seccion.glosa === ACTIVIDAD_DEPORTE
      ),
      cambioDetalle: false,
    });
  }

  componentDidMount() {

    crearEventoTraza(GA_CATEGORIA_FORMULARIO_ENROLAMIENTO, GA_LABEL_PREGUNTAS_DEPORTAS);

    let idPreguntaSeccionCobertura = this.state.informacion
      .idPreguntaSeccionCobertura;

    const detalle = this.props.store.reducer.configuracionDeclaraciones.detalle.filter(
      (detalles) =>
        detalles.idPreguntaSeccionCobertura === idPreguntaSeccionCobertura &&
        detalles.catalogoDeclaracion.idCatalogoTipoDeclaracion !==
        NO_DECLARA_ACTIVIDADES_DEPORTES
    );

    detalle.forEach(
      (item) => (item.actividadGlosa = item.catalogoDeclaracion.declaracion)
    );

    const idCatalogoAceptacion = this.state.informacion.catalogoAceptacion
      .idCatalogoAceptacion;
    const declaracionComplementaria = {
      ...this.state.declaracionComplementaria,
      idCatalogoAceptacion,
      idPreguntaSeccionCobertura,
    };

    this.setState({
      detalle,
      declaracionComplementaria,
    });
  }

  componentDidUpdate(nextProps, nextState) {
    if (this.state.detalle !== nextState.detalle) {
      this.setState({ cambioDetalle: true });
    }
  }

  handleActionChange = (row) => {
    this.setState({
      agregar: false,
      declaracionComplementaria: row,
    });
  };

  handleActionDelete = (objDeclaracion) => {

    let objRespuesta = eliminarDeclaracion(objDeclaracion, this.props.store.reducer, this.state)

    if (objRespuesta.blnCarga){
      let { cargas } = this.props.store.reducer.dataWizardAsegurado;
      cargas[objRespuesta.intIndiceCarga] = {...objRespuesta.objAsegurable};
      this.props.update_carga(cargas);
    }
    else{
      this.props.guardar_data_wizard_asegurado(objRespuesta.objAsegurable);
    }

    this.setState({ detalle: objRespuesta.objDetalle, agregar: true });
    this.props.update_detalle(objRespuesta.objDetalle);
  };

  handleSubmit = (values) => {

    let objRespuesta = procesarDeclaracion(values, this.props.store.reducer, this.state);

    if (objRespuesta.blnCarga){
      let { cargas } = this.props.store.reducer.dataWizardAsegurado;
      cargas[objRespuesta.intIndiceCarga] = {...objRespuesta.objAsegurable};
      this.props.update_carga(cargas);
    }
    else{
      this.props.guardar_data_wizard_asegurado(objRespuesta.objAsegurable);
    }

    if (objRespuesta.agregar) {

      this.setState({
        detalle: objRespuesta.objDetalleEstado,
        declaracionComplementaria: objRespuesta.objDeclaracionNueva
      });
      this.props.add_detalle(objRespuesta.objDeclaracionNueva);
    } 
    else {
      this.setState({
        detalle: objRespuesta.objDetalleEstado,
        declaracionComplementaria: objRespuesta.objDeclaracionNueva,
        agregar: true,
      });

      this.props.update_detalle(objRespuesta.objDetalle);
    }
    this.refs.siDeclaraRef.checked = true;
  };

  enviarDeclaraciones = async () => {

    const objPregunta                             = this.state.informacion;
    let { dataWizardAsegurado }                   = this.props.store.reducer;
    dataWizardAsegurado.solicitud.declaroDeportes = this.state.checked;
    let objRespuesta                              = await guardarDeclaraciones(dataWizardAsegurado,[objPregunta]);

    await this.props.guardar_data_wizard_asegurado(objRespuesta);

    return objRespuesta;
  };

  atras = () => {
    this.setState({ animacion: "formOut" });

    setTimeout(() => {
      this.props.wizard({ tab: 1, type: "tab" });
    }, 100);
  };

  siguiente = async () => {
    this.setState({ loadingData: true });

    if (this.state.checked === null) {
      this.setState({ loadingData: false });
      return notificacion("warning", SI_NO_DECLARACIONES);
    }

    if (this.state.checked && this.state.detalle.length < 1) {
      this.setState({ loadingData: false });
      return notificacion("warning", ALERTA_NO_DECLARACIONES);
    }

    if (this.state.checked && !this.state.aceptacionCheck) {
      this.setState({ loadingData: false });
      return notificacion("warning", ACEPTAR_TERMINOS);
    }

    const objRespuesta = await this.enviarDeclaraciones();

    if (!objRespuesta) {
      this.setState({ loadingData: false });
      return notificacion("danger", ERROR_DECLARACIONES);
    }

    notificacion("success", EXITO_DECLARACIONES);
    this.props.cambio_detalle(this.state.cambioDetalle);
    this.setState({ animacion: "formOut", cambioDetalle: false });

    const declaracionSalud = this.props.store.reducer.declaraciones.find(
      (declaracion) => declaracion.seccion.glosa === DECLARACION_SALUD
    );

    const pagina = this.props.store.reducer.estructuraFormulario.beneficiarios ? PAGINA_4 : PAGINA_5;

    const objetoWizard = declaracionSalud
      ? { tab: VALOR_TRES, type: TIPO_TAB }
      : { pagina, type: TIPO_PAGINA };

    setTimeout(() => {
      this.props.wizard(objetoWizard);
    }, 100);
    this.setState({ loadingData: false });
  };

  confirm = (row) =>
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="modal-confirm-container">
            <div className="d-flex">
              <p className="h4">¿Está seguro de eliminar esta declaración?</p>
            </div>

            <div className="row mt-4">
              <button
                id="cancelarEliminarDeclaracion"
                className="btn btn-principal color-cancel btn-sm d-block ml-auto mr-2"
                onClick={() => {
                  onClose();
                }}
              >
                Cancelar
              </button>
              <button
                id="eliminarDeclaracion"
                className="btn btn-principal color-danger btn-sm"
                onClick={() => {
                  this.handleActionDelete(row);
                  onClose();
                }}
              >
                Eliminar
              </button>
            </div>
          </div>
        );
      },
    });

  confirmarNo = (e) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="modal-confirm-container">
            <div className="d-flex">
              <p className="h4">
                Ha realizado {this.state.detalle.length}{" "}
                {this.state.detalle.length === 1
                  ? "declaración"
                  : "declaraciones"}
                . ¿Confirma que desea eliminar declaraciones?
              </p>
            </div>

            <div className="row mt-4">
              <button
                id="noElimina"
                className="btn btn-principal color-secundario btn-sm d-block ml-auto mr-2"
                onClick={() => {
                  if (this.props.store.reducer.data_asegurable.declaracion) {
                    this.refs.siDeclaraRef.checked = true;
                    this.refs.noDeclaraRef.checked = false;
                    this.setState({
                      checked: true,
                    });
                    onClose();
                  }
                }}
              >
                No
              </button>
              <button
                id="siElimina"
                className="btn btn-principal btn-sm"
                onClick={(e) => {
                  this.refs.siDeclaraRef.checked = false;
                  this.refs.noDeclaraRef.checked = true;
                  this.setState({
                    checked: false,
                  });
                  this.deleteAllDeclarationsState();
                  onClose();
                }}
              >
                Sí
              </button>
            </div>
          </div>
        );
      },
    });
  };

  deleteAllDeclarationsState = () => {
    //update state
    this.setState({
      detalle: [],
    });

    //update detalle redux
    const { reducer } = this.props.store;
    const { detalle } = reducer.configuracionDeclaraciones;
    const { informacionPoliza } = reducer;
    const dataWizardTemporal = reducer.dataWizardAsegurado;

    const newDetalle = detalle.filter(
      (detalles) =>
        detalles.idPreguntaSeccionCobertura !==
        this.state.informacion.idPreguntaSeccionCobertura
    );

    this.props.update_detalle(newDetalle);

    //update objeto mongo

    if (reducer.dataWizardAsegurado.coberturas[0].declaraciones) {
      const declaraciones = reducer.dataWizardAsegurado.coberturas[0].declaraciones.filter(
        (detalles) =>
          detalles.idPreguntaSeccionCobertura !==
          this.state.informacion.idPreguntaSeccionCobertura
      );

      const { cotizaciones } = this.props.store.reducer.data_asegurable;

      const data = {
        ...reducer.dataWizardAsegurado,
        coberturas: informacionPoliza.coberturas
          ? this.coberturasMantencion(informacionPoliza.coberturas, declaraciones, dataWizardTemporal)
          : cotizaciones &&
          this.coberturasVentaDirecta(cotizaciones, declaraciones, dataWizardTemporal),
      };

      this.props.guardar_data_wizard_asegurado(data);
    }

    if (
      reducer.dataWizardAsegurado.cargas &&
      reducer.dataWizardAsegurado.cargas.length > 0
    ) {
      const cargas = [...reducer.dataWizardAsegurado.cargas];
      cargas.forEach((carga) => {
        if (
          carga.coberturas[0].declaraciones &&
          carga.coberturas[0].declaraciones.length > 0
        ) {
          carga.coberturas.forEach((cobertura) => {
            cobertura.declaraciones = cobertura.declaraciones.filter(
              (detalles) =>
                detalles.idPreguntaSeccionCobertura !==
                this.state.informacion.idPreguntaSeccionCobertura
            );
          });
        }
      });
      this.props.update_carga(cargas);
    }
  };

  render() {
    const { dataWizardAsegurado } = this.props.store.reducer;

    let catalogoDeclaraciones = this.state.informacion.catalogoDeclaraciones.filter(
      (declaracion) => {
        return (
          declaracion.declaracion !== "" &&
          declaracion.declaracion !== NO_DECLARA &&
          declaracion.declaracion !== null
        );
      }
    );

    return (
      <React.Fragment>
        <div
          id="declaracionActividadesDeportes"
          className="cuerpo-wizard pt-3 pb-5 o-white container"
        >
          <div className="animated fadeInRight" ref="formularioRef">
            <div className="row m-1 m-lg-4">
              {this.props.store.reducer.informacionPoliza.asegurable_5_50 ? (
                <h5 className="font-weight-bold text-primary">
                  2. Actividades y Deportes
                </h5>
              ) : (
                <h5 className="font-weight-bold text-primary">
                  {
                    this.state.informacion.glosasCoberturas.map(item => item.glosa).reduce((resultado, item) => [resultado, " - ", item])
                  }
                </h5>
              )}
              <div className="col-12">
                <p className="">
                  {this.state.informacion.catalogoPregunta.pregunta}
                </p>
              </div>

              <div className="col-12 mb-4">
                <div className="row">
                  <div className="col12 col-md-6">
                    {catalogoDeclaraciones
                      .slice(0, Math.round(catalogoDeclaraciones.length / 2))
                      .map((item, i) => (
                        <div className="mb-1" key={i}>
                          <i
                            className="fa fa-circle fa-xs"
                            style={{ color: "#e89b03" }}
                          />
                          <span>{" " + item.declaracion}</span>
                        </div>
                      ))}
                  </div>
                  <div className="col12 col-md-6">
                    {catalogoDeclaraciones
                      .slice(
                        Math.round(catalogoDeclaraciones.length / 2),
                        catalogoDeclaraciones.length
                      )
                      .map((item, i) => (
                        <div className="mb-1" key={i}>
                          <i
                            className="fa fa-circle fa-xs"
                            style={{ color: "#e89b03" }}
                          />
                          <span>{" " + item.declaracion}</span>
                        </div>
                      ))}
                  </div>
                </div>
              </div>

              <div className="col-12">
                <div className="check-group">
                  <label className="custom-checkbox p-1">
                    <input
                      id="siDeclara"
                      ref="siDeclaraRef"
                      type="radio"
                      name="radio"
                      checked={this.state.checked}
                      onClick={async (e) => {
                        await this.setState({
                          checked: true,
                        });
                      }}
                    />
                    <div className="check"></div>
                    <div className="option">Si</div>
                  </label>
                  <label className="custom-checkbox p-1">
                    <input
                      id="noDeclara"
                      ref="noDeclaraRef"
                      type="radio"
                      name="radio"
                      checked={
                        this.state.checked === null ? null : !this.state.checked
                      }
                      onClick={async (e) => {
                        if (this.state.detalle.length > 0) {
                          await this.confirmarNo(e);
                        }
                        await this.setState({
                          checked: false,
                        });
                      }}
                    />
                    <div className="check"></div>
                    <div className="option">No</div>
                  </label>
                </div>
              </div>

              {this.state.checked > 0 && (
                <>
                  <div className="container pt-3">
                    <hr />
                  </div>
                  <Formik
                    enableReinitialize={true}
                    initialValues={{
                      rut: this.state.declaracionComplementaria.rut,
                      idCatalogoTipoDeclaracion: this.state
                        .declaracionComplementaria.catalogoDeclaracion
                        ? this.state.declaracionComplementaria.catalogoDeclaracion
                          .idCatalogoTipoDeclaracion
                        : this.state.declaracionComplementaria
                          .idCatalogoTipoDeclaracion,
                      observacion: this.state.declaracionComplementaria
                        .observacion,
                    }}
                    validationSchema={Yup.object().shape({
                      rut: Yup.string()
                        .required(CAMPO_REQUERIDO_ASEGURADO)
                        .test(
                          "validacionAsegurable",
                          VALIDACION_ASEGURABLE,
                          (value) => {
                            return Number(value) !== 0 ? true : false;
                          }
                        ),
                      idCatalogoTipoDeclaracion: Yup.string()
                        .required(CAMPO_REQUERIDO_ACTIVIDAD)
                        .test(
                          "validacionActividad",
                          VALIDACION_ACTIVIDAD,
                          (value) => {
                            return Number(value) !== 0 ? true : false;
                          }
                        ),
                      observacion: Yup.string()
                        .required(CAMPO_REQUERIDO_FRECUENCIA)
                        .test(
                          "validarVacios",
                          CAMPO_REQUERIDO_FRECUENCIA,
                          (value) => (value ? value.trim() !== "" : false)
                        )
                        .test(
                          "validarVacios",
                          DIAGNOSTICO_DISTINTO_CERO,
                          (value) => (value ? value.trim() !== "0" : false)
                        ),
                    })}
                    onSubmit={async (
                      values,
                      { setSubmitting, resetForm, initialValues }
                    ) => {
                      await this.handleSubmit(values);

                      await this.setState(
                        {
                          declaracionComplementaria: {
                            key: null,
                            rut: "",
                            idCatalogoTipoDeclaracion: 0,
                            observacion: "",
                          },
                        },
                        () => {
                          resetForm(initialValues);
                        }
                      );
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleSubmit,
                      handleBlur,
                    }) => {
                      return (
                        <Form
                          id="formularioActividades"
                          className="col-12"
                          onSubmit={handleSubmit}
                          onKeyDown={(e) => {
                            if ((e.charCode || e.keyCode) === 13) {
                              e.preventDefault();
                            }
                          }}
                        >
                          <div className="row mx-md-5 mt-3">
                            <div className="col-12 form-group">
                              <label>
                                Persona que practica la actividad o deporte
                                seleccionada{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <select
                                className="form-control form-control-sm"
                                id="rut"
                                name="rut"
                                value={values.rut}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              >
                                <option value="0">
                                  SELECCIONE UN ASEGURABLE
                                </option>
                                {(this.props.store.reducer.informacionPoliza
                                  .solicitudIncorporacion ||
                                  this.props.store.reducer.informacionPoliza
                                    .asegurable_5_50) && (
                                    <option
                                      name="nombre"
                                      value={dataWizardAsegurado.rut}
                                    >
                                      {`${dataWizardAsegurado.nombre} ${dataWizardAsegurado.apellidoPaterno} ${dataWizardAsegurado.apellidoMaterno} (TITULAR)`}
                                    </option>
                                  )}

                                {dataWizardAsegurado.cargas &&
                                  dataWizardAsegurado.cargas.map((item, i) => (
                                    <option
                                      name="nombre"
                                      key={i}
                                      value={item.rut}
                                    >{`${item.nombre} ${item.apellidoPaterno} ${item.apellidoMaterno} (ADICIONAL)`}</option>
                                  ))}
                              </select>
                              {errors.rut && touched.rut && (
                                <small className="text-danger animated fadeIn animated fadeIn">
                                  {errors.rut}
                                </small>
                              )}
                            </div>

                            <div className="col-12 form-group">
                              <label>
                                Actividad o deporte{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <select
                                className="form-control form-control-sm"
                                id="idCatalogoTipoDeclaracion"
                                name="idCatalogoTipoDeclaracion"
                                value={values.idCatalogoTipoDeclaracion}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              >
                                <option value="0">
                                  SELECCIONE UNA ACTIVIDAD O DEPORTE
                                </option>
                                {catalogoDeclaraciones.map((item, i) => (
                                  <option
                                    name="nombre"
                                    key={i}
                                    value={item.idCatalogoTipoDeclaracion}
                                  >
                                    {item.declaracion}
                                  </option>
                                ))}
                              </select>
                              {errors.idCatalogoTipoDeclaracion &&
                                touched.idCatalogoTipoDeclaracion && (
                                  <small className="text-danger animated fadeIn">
                                    {errors.idCatalogoTipoDeclaracion}
                                  </small>
                                )}
                            </div>

                            <div className="col-12 form-group">
                              <label>
                                Frecuencia con la que practica la actividad o
                                deporte <span className="text-danger">*</span>
                              </label>
                              <Field
                                id="observacion"
                                name="observacion"
                                maxLength="30"
                                value={soloLetrasYNumeros(values.observacion)}
                                type="text"
                                placeholder="Ingrese frecuencia"
                                className="form-control form-control-sm"
                              />
                              {errors.observacion && touched.observacion && (
                                <small className="text-danger animated fadeIn">
                                  {errors.observacion}
                                </small>
                              )}
                            </div>

                            <div className="col-12">
                              <button
                                id="buttonSubmit"
                                className="btn btn-principal color-secundario btn-sm d-block ml-auto"
                                type="submit"
                              >
                                {this.state.agregar ? "Agregar" : "Actualizar"}
                              </button>
                            </div>
                          </div>

                          {this.state.detalle.length > 0 ? (
                            <div className="pt-3">
                              <hr />
                              <Table
                                titulo="Declaraciones actividades y deportes"
                                datos={this.state.detalle.filter(
                                  (item) => item.nombre
                                )}
                                columnas={this.state.columnDefs}
                              />
                              <hr />
                            </div>
                          ) : null}
                        </Form>
                      );
                    }}
                  </Formik>
                  <div className="container pt-3">
                    <hr />
                    <div className="row">
                      <div className="col-1 align-self-center">
                        <label className="switch">
                          <input
                            id="checkboxDeclaracion"
                            checked={this.state.aceptacionCheck}
                            onClick={() =>
                              this.setState({
                                aceptacionCheck: !this.state.aceptacionCheck,
                              })
                            }
                            type="checkbox"
                          />
                          <span className="slider round" />
                        </label>
                      </div>
                      <div className="col-11">
                        <label className="form-check-label " htmlFor="aceptacion">
                          <p className="text-justify">
                            {this.state.informacion.catalogoAceptacion.glosa}
                          </p>
                        </label>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="button-zone text-right pt-3 pb-3">
          <button
            id="botonAtras"
            className="btn btn-principal color-secundario btn-lg"
            type="button"
            onClick={this.atras}
          >
            Atrás
          </button>

          <button
            id="botonSiguiente"
            className="btn btn-principal btn-lg"
            type="button"
            onClick={this.siguiente}
            disabled={this.state.loadingData}
          >
            {this.state.loadingData ? (
              <>
                <i className="fa fa-spinner fa-pulse fa-fw"></i>
                <span className="sr-only">Loading...</span>
              </>
            ) : (
              <span>Siguiente</span>
            )}
          </button>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state,
});

export default withRouter(
  connect(mapStateToProps, {
    wizard,
    add_detalle,
    update_detalle,
    cambio_detalle,
    guardar_data_asegurable,
    guardar_data_wizard_asegurado,
    update_carga,
  })(CoberturaActividadesDeportes)
);
