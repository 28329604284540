import React, {useState} from "react";
import {withRouter} from "react-router-dom";
import ModalDetallesCargasAsegurados from "./ModalDetallesCargasAsegurados";
import moment from "moment";
import {VALOR_TITULAR} from "../../../utils/Constantes";


const DatosPersonalesAsegurado = (props) => {


    const [verModalDetalleCargas, setVerModalDetalleCargas] = useState(false);
    const [cargasSeleccionadas, setCargasSeleccionadas] = useState([]);


    const mapearPropiedadesCarga = cargas => {

        return cargas.map( ({nombre, apellidoPaterno, apellidoMaterno, parentesco, poliza}) =>({
            nombreCompleto: [nombre, apellidoPaterno, apellidoMaterno].join(" "),
            fechaFinVigencia: moment(poliza.fechaFinVigencia).format("DD/MM/YYYY"),
            parentesco: parentesco.glosa,
        }))
    }

    const mostrarModalCargas = cargas => {
        setVerModalDetalleCargas(true)
        setCargasSeleccionadas(cargas);
    }

    const mostrarMensajeError = mensajeError => {
        return (
            <div className="row w-100" key={mensajeError}>
                <div className="col-12" id="datosPersonalesAsegurado">
                    <div className="row">
                        <div className="col-md-12">
                            <label>
                                {mensajeError}
                            </label>
                        </div>
                    </div>

                </div>
            </div>
        )
    }

    return(
        <React.Fragment>
            {verModalDetalleCargas && <ModalDetallesCargasAsegurados cargas={mapearPropiedadesCarga(cargasSeleccionadas)} cerrarModal={() => setVerModalDetalleCargas(false)} />}

            {!props.errorDataAsegurado ?

                props.polizas.map( ({poliza, nombre, apellidoPaterno, apellidoMaterno, cargas}) => {

                    if(poliza.mensajeError){
                        return mostrarMensajeError(poliza.mensajeError)
                    }else if(props.tipoAsegurado === VALOR_TITULAR){
                        return(
                            <div className="row w-100" key={poliza.nroPoliza + poliza.nroRenovacion}>
                                <div className="col-12" id="datosPersonalesAsegurado">
                                    <div className="row">
                                        <div className="col-md-12" style={{ marginBottom: 0 }}>
                                            <label>
                                                <b>Poliza</b>: {poliza.nroPoliza + '-' + poliza.nroRenovacion}
                                            </label>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12">
                                            <label>
                                                <b>Nombre completo titular</b>: {nombre + ", "+ apellidoPaterno+ " " + apellidoMaterno}
                                            </label>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12">
                                            <label>
                                                <b>Fecha fin vigencia</b> : {moment(poliza.fechaFinVigencia).format("DD/MM/YYYY")}
                                            </label>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12">
                                            <label>
                                                <b>Cargas</b>:
                                                {cargas.length?
                                                    <React.Fragment>
                                                        {"  " +  cargas.length + "  "}
                                                        <span className="font-weight-bold text-primary" id={"verCargas"} onClick={() => mostrarModalCargas(cargas)} >Ver cargas</span>
                                                    </React.Fragment>:
                                                    "No tiene cargas"}
                                            </label>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        )
                    }else{
                        return(
                            <div className="row w-100" key={poliza.nroPoliza + poliza.nroRenovacion}>
                                <div className="col-12" id="datosPersonalesAsegurado">
                                    <div className="row">
                                        <div className="col-md-12" style={{ marginBottom: 0 }}>
                                            <label>
                                                <b>Poliza</b>: {poliza.nroPoliza + '-' + poliza.nroRenovacion}
                                            </label>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12">
                                            <label>
                                                <b>Nombre completo carga</b>: {cargas[0].nombre + ", "+ cargas[0].apellidoPaterno+ " " + cargas[0].apellidoMaterno}
                                            </label>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12">
                                            <label>
                                                <b>Fecha fin vigencia</b> : {moment(cargas[0].poliza.fechaFinVigencia).format("DD/MM/YYYY")}
                                            </label>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12">
                                            <label>
                                                <b>Nombre completo titular</b>: {nombre + ", "+ apellidoPaterno+ " " + apellidoMaterno}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                })
                : mostrarMensajeError(props.errorDataAsegurado)}
        </React.Fragment>
    )
}

export default withRouter(DatosPersonalesAsegurado);


