import React from "react";

export default function Campo({ objConfiguracion, procesarCampoFormulario }) {

  return (
    <input  id={objConfiguracion.id}
            name={objConfiguracion.id}
            onChange={(objEvento) => { procesarCampoFormulario(objEvento) }}
            type={objConfiguracion.tipo}
            className="form-control"
            placeholder={objConfiguracion.nombre}
            maxLength={objConfiguracion.maximaLongitud}
            minLength={objConfiguracion.minimaLongitud}
            value={objConfiguracion.valor} />
  );
}