import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import {
  guardar_data_asegurable,
  guardar_data_wizard_asegurado,
  guardar_previsiones_salud,
  get_ciudades,
  get_comunas,
  get_estado_civil,
  get_parentescos,
  obtener_parentescos_beneficiarios,
  get_generos,
  get_regiones,
  get_forma_pago,
  get_banco,
  get_tipo_cuenta,
  add_declaracion,
  update_id_solicitud,
  wizard,
  getListaDeclaraciones,
  getListaPreguntasCobertura,
  actualizar_progreso_asegurable,
  update_carga,
  update_detalle,
  obtener_info_poliza,
  guardar_estructura_formulario,
  guardar_catalogo_consentimiento,
} from "../../../../actions/actions";
import {
  getPrevisionSalud,
  getRegiones,
  getCiudades,
  getComunas,
  getEstadoCivil,
  getGeneros,
  getParentesco,
  getFormasDePago,
  getInstitucionesBancarias,
  getTiposDeCuentas,
  buscarAsegurablePorRut,
  obtenerDataWizard,
  obtenerPreguntasDeclaraciones,
  obtenerInformacionPoliza,
  listarCargasPorRutAsegurable,
  getParentescoBeneficiario,
} from "../../../../services/asegurable.services";
import { arrayToFormattedString } from "../../../../utils/Functions";

import { withRouter, Redirect } from "react-router-dom";
import { FaUser } from "react-icons/fa";

import Spinner from "../../transversales/Spinner";
import Footer from "../../transversales/Footer";
import {
  W1_MIS_DATOS,
  W1_DIRECCION,
  W1_DATOS_PAGO,
  FORMA_PAGO_SERVIPAG,
  PAGINA_1,
  PAGINA_2,
  PAGINA_3,
  PAGINA_4,
  PAGINA_5,
  PROGRESO_INICIAL,
  PROGRESO_FINAL,
  PROGRESO_ETAPA,
  PROGRESO_ETAPA_15,
  PROGRESO_ETAPA_20,
  PROGRESO_SUB_ETAPA,
  DONE,
  DOING,
  TODO,
  ID_UNO,
  ID_CUATRO,
  VALOR_ZERO,
  VALOR_CIEN,
  TIPO_POLIZA_VIDA,
  TIPO_VALOR_RENTA,
  VALOR_UNO,
  DECLARACION_SALUD,
  VALOR_DOS,
  ESTADO_NEGOCIO_3,
  STRING_VACIO,
  MENU_WIZARD,
  VALOR_TRES,
  VALOR_SIETE,
  EL_CODIGO,
  LOS_CODIGOS,
  POL_5_50,
  PUNTO_COMA,
  STRING_Y,
  CLAUSULA_ADICIONAL,
  CLAUSULAS_ADICIONALES,
  CAD_5_50,
  POL,
  CAD,
  TIPO_CANAL_VENTA_DIRECTA,
  TIPO_CANAL_VENTA_DIGITAL,
  FLUJO_VENTA_DIRECTA,
  FLUJO_VENTA_DIGITAL,
  FLUJO_MANTENCION,
  ID_TIPO_ATRIBUTO_CONDICION_6,
  ID_TIPO_ATRIBUTO_CONDICION_7,
  ID_TIPO_ATRIBUTO_CONDICION_9,
  ID_TIPO_ATRIBUTO_CONDICION_17,
  TIPO_PAGINA,
  TIPO_TAB,
  TIPO_INCORPORACION_MANTENCION,
  SOLICITUD_FINALIZADA_POR_ASEGURABLE,
  DOCUMENT_TYPE_TEMPORAL,
  ID_COBERTURA_FALLECIMIENTO,
  COBERTURA_FALLECIMIENTO,
  COBERTURA_SEGURO_VIDA,
  BENEFICIARIOS_MENU,
  TIPO_INCORPORACION_NUEVO_NEGOCIO,
  VALOR_CINCO,
  VALOR_CUATRO,
  SIN_POLIZAS,
  TIPO_CANAL_VENTA_CORREDOR,
  POSICION_INICIAL,
  SIGNO_GUION,
  ID_TIPO_POLIZA_SALUD,
  ID_TIPO_POLIZA_VIDA,
  ID_TIPO_POLIZA_ACCIDENTE_PERSONAL,
  ID_TIPO_POLIZA_APOYO_FAMILIAR,
  TIPO_SOLICITUD_DECLARACION_BENEFICIARIO,
  BANCO_RIPLEY_PLAN_INICIAL,
  MENU_UNO,
  MENU_TRES,
  DATO_INDEFINIDO,
  LARGO_CERO_COBERTURAS,
  ID_PRODUCTO_COBERTURA,
  SIN_VALOR_CAPITAL,
  SIN_VALOR_PLAN,
  TAMANO_UNO,
  FALSO,
  RUTA_PROCESO_ENROLAMIENTO_DOBLE,
  RUTA_CAMBIAR_CONTRASENA_TEMPORAL,
  RUTA_INICIO_SESION,
  NOTIFICACION_ADVERTENCIA,
  NO_SE_ENCONTRO_ASEGURABLE,
  ID_PRODUCTO_COMPL_SALUD,
  VALOR_VACIO,
} from "../../../../utils/Constantes";
import DatosPersonales from "./datosPersonales/DatosPersonales";
import Cargas from "./cargas/Cargas";
import Declaraciones from "./declaraciones/Declaraciones";
import Resumen from "./resumen/Resumen";
import CabeceraNegocioAsegurado from "./CabeceraNegocioAsegurado";
import { verificarCierreEnrolamientoAsegurado } from "../../../../services/negocios.service";
import Beneficiarios from "./beneficiarios/Beneficiarios";
import {
  buscarSubProducto,
  listarSubProducto,
} from "../../../../services/producto.service";
import { listarPorAsegurable } from "../../../../services/cotizacion.services";
import { obtenerArchivosComponente } from "../../transversales/CargarArchivo";
import { setearDocumentosCargas } from "./cargas/CargaValidator";
import { listarPorNroPolizaNroRenovacion, listarPorRut } from "../../../../services/bus.poliza.services";
import { eliminarTodoEnNavegador } from "../../../../utils/almacenamientoNavegador";
import { notificacion } from "../../transversales/Notificaciones";

class Asegurable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataWizard: null,
      cargando: true,
      solicitud: 1,
      terminos: false,
      solicitudCerrada: false,
      solicitudMultiplesProcesos: false,
      fechaRecepcion: null,
      porcentajeProceso: 0,
      declaroEspecial: null,
      seleccionarCoberturas: false,
      arregloDeclaraciones: [],
      menu: {},
      cubrePreexistencias: true,
      arregloPol: [],
      arregloCad: [],
      tipoFlujo: null,
      idTipoSolicitud: ID_UNO, // Incorporacion
    };
  }

  async componentWillMount() {
    
    const { usuarioSesion } = this.props.store.auth;

    if (usuarioSesion.temporal === FALSO) {
      this.props.history.push(RUTA_CAMBIAR_CONTRASENA_TEMPORAL);
      return;
    }

    let objDatosAsegurable  = await buscarAsegurablePorRut(usuarioSesion.rut);
    await this.props.guardar_data_asegurable(objDatosAsegurable);

    if(!objDatosAsegurable){
      eliminarTodoEnNavegador();
      this.props.history.push(RUTA_INICIO_SESION);
      notificacion(NOTIFICACION_ADVERTENCIA, NO_SE_ENCONTRO_ASEGURABLE);
      return;
    }
    
    if (objDatosAsegurable && objDatosAsegurable.length > TAMANO_UNO) {
      
      this.props.history.push(RUTA_PROCESO_ENROLAMIENTO_DOBLE);
      return;
    }

    // Si el asegurable está en más de un negocio, obtener la solicitud del negocio nuevo
    const datosAseguradoSolicitudCero = await objDatosAsegurable.find((datoAsegurado) => !datoAsegurado.idSolicitud);

    let datosAsegurado;
    
    if (datosAseguradoSolicitudCero) {
      datosAsegurado = datosAseguradoSolicitudCero;
    } else {
      datosAsegurado = objDatosAsegurable.find((aseg) =>
      aseg.idAsegurable === Math.max(...objDatosAsegurable.map((item) => item.idAsegurable),VALOR_ZERO));
    }

    if ((datosAsegurado.idTipoSolicitud === 0) || (datosAsegurado.idTipoSolicitud === null)) {
      //Se Asigna el tipo de solicitud
      await this.asignarTipoSolicitud(datosAsegurado);
    } 
    else {
      await this.mantenerTipoSolicitud(datosAsegurado);
    }

    await this.props.guardar_catalogo_consentimiento(datosAsegurado.catalogoConsentimientos);
    await this.props.guardar_data_asegurable(datosAsegurado);

    this.setState({
      termino: datosAsegurado.primerIngreso,
      fechaRecepcion: datosAsegurado.fechaRecepcion,
    });

    await this.obtenerInformacionPoliza();

    let codigoCotizacion = datosAsegurado.codigoCotizacion;
    if (codigoCotizacion) {
      //SolicitudCerrada 0 (sin resultados) - SolicitudCerrada 1 (cerrada) - SolicitudCerrada 2 (no cerrada)
      let solicitudCerrada = await verificarCierreEnrolamientoAsegurado(
        codigoCotizacion
      );
      if (solicitudCerrada)
        solicitudCerrada === 1
          ? (solicitudCerrada = true)
          : (solicitudCerrada = false);

      this.setState({ solicitudCerrada });
    }
    if (datosAsegurado.idSolicitud) {
      await this.obtenerTipoFlujo();
      await this.obtenerPreguntasDeclaraciones();
      await this.obtenerDatosCatalogo();
      await this.obtenerDataWizard(datosAsegurado);
      await this.obtenerCotizacionesConCondicionados(datosAsegurado);
      await this.guardarTipoIncorporacion();
      await this.ordenarDeclaraciones();
      await this.obtenerPreguntas();
      await this.condicionarFormulario();
      await this.obtenerCargasIncorporacionCarga();

      const { data_asegurable : { tipoFlujo } } = this.props.store.reducer;

      if (tipoFlujo !== FLUJO_MANTENCION) {
        const { dataWizardAsegurado } = this.props.store.reducer;
        await Promise.all(
            
          dataWizardAsegurado.coberturas.map(async ( objCobertura ) => {
            const objSubproducto                        = await buscarSubProducto(objCobertura.idSubProdCondicionadoCobertura);
            let objCondCobertura                        = Object.assign({}, ...objSubproducto.condicionadoCobertura);
            objCobertura.configuracion                  = objCondCobertura.cobertura;
            objCobertura.condicionado                   = objCondCobertura.condicionado;
            objCobertura.idSubProdCondicionadoCobertura = objCondCobertura.idSubProdCondicionadoCobertura;
            return objCobertura;
          })
        ).then((lstCoberturas) => {
          let lstCoberturasCargas = [];

          lstCoberturas.forEach(objCobertura => {
            if (objCobertura.idProducto === ID_PRODUCTO_COMPL_SALUD) {
              lstCoberturasCargas.push(objCobertura);
            }
          })
          
          this.props.guardar_data_wizard_asegurado({
            ...dataWizardAsegurado,
            coberturas: [...lstCoberturas],
            coberturasCargas: [...lstCoberturasCargas]
          });
        });
      }

    } else if (datosAsegurado.idSolicitud === VALOR_ZERO) {
      this.setState({ terminos: true, cargando: false });
    }
    datosAsegurado.cotizaciones =
      datosAsegurado.cotizaciones &&
      datosAsegurado.cotizaciones.filter((item) => item !== null);

    if (datosAsegurado.cotizaciones && datosAsegurado.cotizaciones.length > 0) {
      let seleccionarCobertura = false;
      datosAsegurado.cotizaciones.forEach((cotizacion) => {
        //VALIDAR BOOLEAN COTIZACIONCOBERTURA
        if (cotizacion.coberturaAsegurable) {
          seleccionarCobertura = true;
        }
      });
      if (seleccionarCobertura) {
        //VALIDAR SI YA SE GUARDO EN MONGO
        if (this.state.dataWizard === null) {
          this.setState({ seleccionarCoberturas: true });
        }
      }
    }
    await this.props.guardar_data_asegurable(datosAsegurado);
  }

  validarMultiplesSolicitudes = (dataAsegurados) => {

    if (dataAsegurados.length < VALOR_DOS) return false;
    let procesosEmpresa = [];
    dataAsegurados.forEach((item) => {
      if (
        item.estadoNegocio &&
        item.estadoNegocio.idEstadoNegocio === ESTADO_NEGOCIO_3
      ) {
        procesosEmpresa.push(item);
      }
    });
    if (procesosEmpresa.length > VALOR_UNO) {
      this.setState({ solicitudMultiplesProcesos: true });
      this.props.history.push("/asegurable/solicitud-procesos");
      return true;
    }
    return false;
  };

  asignarTipoSolicitud = async (datosAsegurado) => {
    // Poliza listar Por Rut Titular
    let rutAsegurable = `${datosAsegurado.rut}-${datosAsegurado.digitoVerificador}`;
    let polizasTitular = await listarPorRut(rutAsegurable);
    polizasTitular = polizasTitular.filter(poliza => new Date(poliza.fechaFinVigencia) > new Date());
    if (polizasTitular != null && polizasTitular.length > 0) {
      // Se comparan las polizas y se filtran por las seleccionadas.
      let mapPolizas = polizasTitular.filter((poliza) => {
        let objPoliza;
        let grupos = datosAsegurado.grupos.find(
          (grupo) => grupo.idContratoSeguro === poliza.idContratoSeguro
        );
        if (
          grupos !== undefined &&
          poliza.idContratoSeguro === grupos.idContratoSeguro
        ) {
          objPoliza = poliza;
        }
        return objPoliza;
      });

      // Se validan las existencias
      let existeVida = false;
      let existeSalud = false;
      let existeAccidentePersonal = false;
      let existeApoyoFamiliar = false;

      mapPolizas.forEach((poliza) => {
        if (poliza.estadoPoliza.idEstadoPoliza === VALOR_UNO) {
          if (poliza.tipoPoliza.idTipoPoliza === VALOR_UNO) {
            existeSalud = true;
          }
          if (poliza.tipoPoliza.idTipoPoliza === VALOR_DOS) {
            existeVida = true;
          }
          if (poliza.tipoPoliza.idTipoPoliza === VALOR_TRES) {
            existeAccidentePersonal = true;
          }
          if (poliza.tipoPoliza.idTipoPoliza === VALOR_SIETE) {
            existeApoyoFamiliar = true;
          }
        }
      });

      //Polizas seleccionadas
      let vidaSeleccionada = false;
      let saludSeleccionada = false;
      let accidentePersonalSeleccionada = false;
      let apoyoFamiliarSeleccionada = false;

      Promise.all(
        datosAsegurado.grupos.map(async (grupo) => {
          if (this.state.tipoFlujo === FLUJO_MANTENCION || (grupo && grupo.nroPoliza && (grupo.nroRenovacion !== null && grupo.nroRenovacion !== DATO_INDEFINIDO))) {
            let poliza = await listarPorNroPolizaNroRenovacion(grupo.nroPoliza, grupo.nroRenovacion);
            if (poliza.tipoPoliza.idTipoPoliza === ID_TIPO_POLIZA_SALUD) {
              saludSeleccionada = true;
            }
            if (poliza.tipoPoliza.idTipoPoliza === ID_TIPO_POLIZA_VIDA) {
              vidaSeleccionada = true;
            }
            if (poliza.tipoPoliza.idTipoPoliza === ID_TIPO_POLIZA_ACCIDENTE_PERSONAL) {
              accidentePersonalSeleccionada = true;
            }
            if (poliza.tipoPoliza.idTipoPoliza === ID_TIPO_POLIZA_APOYO_FAMILIAR) {
              apoyoFamiliarSeleccionada = true;
            }
          } else {
            vidaSeleccionada = true;
            saludSeleccionada = true;
            accidentePersonalSeleccionada = true;
            apoyoFamiliarSeleccionada = true;
          }
        })
      ).then((values) => {
        // Si existe Vida (1) y Salud (2), entonces el tipo de solicitud es Carga (4)
        if (existeVida && existeSalud && existeAccidentePersonal && existeApoyoFamiliar &&
          saludSeleccionada && vidaSeleccionada && accidentePersonalSeleccionada && apoyoFamiliarSeleccionada) {
          this.setState({ idTipoSolicitud: VALOR_CUATRO });
        } else if (existeSalud && saludSeleccionada && !vidaSeleccionada &&
          !accidentePersonalSeleccionada && !apoyoFamiliarSeleccionada) {
          // Si existe Salud (1), entonces el tipo de solicitud es Carga (4)
          this.setState({ idTipoSolicitud: VALOR_CUATRO });
        } else if (((existeVida && vidaSeleccionada) ||
          (existeAccidentePersonal && accidentePersonalSeleccionada) ||
          (existeApoyoFamiliar && apoyoFamiliarSeleccionada)) && !saludSeleccionada) {
          this.setState({ idTipoSolicitud: VALOR_CINCO });
        }

        if (datosAsegurado) {
          datosAsegurado.idTipoSolicitud = this.state.idTipoSolicitud;
        }
      });
    }

    if (datosAsegurado) {
      datosAsegurado.idTipoSolicitud = this.state.idTipoSolicitud;
    }
  };

  mantenerTipoSolicitud = async (datosAsegurado) => {
    this.setState({ idTipoSolicitud: datosAsegurado.idTipoSolicitud });
  };

  obtenerPolCad = async (coberturas) => {

    Promise.all(
      coberturas.map(async (objCobertura) => {
        if (
          this.props.store.reducer.informacionPoliza.grupos !== undefined &&
          this.props.store.reducer.informacionPoliza.grupos.length >
          VALOR_UNO &&
          objCobertura.tipoPoliza.idTipoPoliza === ID_TIPO_POLIZA_SALUD
        ) {
          return objCobertura.condicionado.glosa;
        } 
        else if (
          this.props.store.reducer.informacionPoliza.grupos !== undefined &&
          this.props.store.reducer.informacionPoliza.grupos.length === VALOR_UNO
        ) {
          return objCobertura.condicionado.glosa;
        }
      })
    ).then((values) => {
      let polUnicos = [];
      let cadUnicos = [];

      let valoresUnicos = [...new Set(values)];
      valoresUnicos = valoresUnicos.filter(function (valorUnico) {
        return valorUnico !== undefined;
      });
      valoresUnicos.forEach((value) => {
        if (value.includes(POL)) {
          polUnicos = [...polUnicos, value];
        } else if (value.includes(CAD)) {
          cadUnicos = [...cadUnicos, value];
        }
      });
      const infoPolCad = `${this.state.asegurable_5_50 || polUnicos.length === VALOR_UNO
        ? EL_CODIGO
        : LOS_CODIGOS
        }
      ${this.state.asegurable_5_50
          ? POL_5_50
          : arrayToFormattedString(polUnicos) + PUNTO_COMA
        } ${STRING_Y} 
      ${this.state.asegurable_5_50 || cadUnicos.length === VALOR_UNO
          ? CLAUSULA_ADICIONAL
          : CLAUSULAS_ADICIONALES
        }
      ${this.state.asegurable_5_50
          ? CAD_5_50
          : arrayToFormattedString(cadUnicos)
        }`;

      const informacionPoliza = {
        ...this.props.store.reducer.informacionPoliza,
      };

      informacionPoliza.infoPolCad = infoPolCad;
      informacionPoliza.coberturas = coberturas;

      this.props.obtener_info_poliza(informacionPoliza);
    });
  };

  obtenerTipoFlujo() {
    const { informacionPoliza } = this.props.store.reducer;

    let tipoFlujo;

    const condicionMantencion =
      informacionPoliza &&
      informacionPoliza.grupos &&
      informacionPoliza.grupos[0].idContratoSeguroGrupo !== VALOR_ZERO;
    if (informacionPoliza.canalVenta) {
      if (
        informacionPoliza.canalVenta.idCanalVenta ===
        TIPO_CANAL_VENTA_DIRECTA &&
        !condicionMantencion
      ) {
        tipoFlujo = FLUJO_VENTA_DIRECTA;
      } else if (
        informacionPoliza.canalVenta.idCanalVenta ===
        TIPO_CANAL_VENTA_DIGITAL &&
        !condicionMantencion
      ) {
        tipoFlujo = FLUJO_VENTA_DIGITAL;
      } else {
        tipoFlujo = FLUJO_MANTENCION;
      }

      this.setState({ tipoFlujo });
      this.props.guardar_data_asegurable({ tipoFlujo });
    }
  }

  obtenerCotizacionesConCondicionados = async (datosAsegurado) => {
    const { idAsegurable, rut, digitoVerificador } = datosAsegurado;

    const perfil = this.props.store.auth.usuarioSesion.rol.perfil;

    const rutAsegurable = rut + "-" + digitoVerificador;

    const cotizacionesConCondicionado = await listarPorAsegurable(
      {
        idAsegurable,
        rutAsegurable,
      },
      perfil
    );

    this.props.guardar_data_asegurable({ cotizacionesConCondicionado });
  };

  condicionarFormulario = () => {

    const { informacionPoliza, data_asegurable, dataWizardAsegurado } = this.props.store.reducer;

    if (informacionPoliza.canalVenta) {
      let idsSubProducto;

      if (this.state.tipoFlujo === FLUJO_VENTA_DIRECTA) {
        idsSubProducto = data_asegurable.cotizaciones.map(
          (cotizacion) => cotizacion.subProducto.idSubProducto
        );
      } else if (this.state.tipoFlujo === FLUJO_MANTENCION) {
        idsSubProducto = informacionPoliza.grupos.map(
          (grupo) => grupo.idSubProducto
        );
      } else {
        /* salud digital 5/50 */
        idsSubProducto = data_asegurable.cotizaciones.map(
          (objCotizacion) => objCotizacion.subProducto.idSubProducto
        );
      }

      let estructuraFormulario = {
        formaPago: false,
        cargas: null,
        declaraciones: {
          especial: false,
          personalSalud: false,
        },
        beneficiarios: null,
      };

      Promise.all(
        idsSubProducto.map(async (idSubProducto) => {
          const subProducto = await listarSubProducto(idSubProducto);
          return subProducto;
        })
      ).then((lstSubproductos) => {
        let  lstAsgCoberturas =  this.state.tipoFlujo === FLUJO_MANTENCION ? [...informacionPoliza.coberturas] : [...dataWizardAsegurado.coberturas];
        const lstIdCoberturas = lstAsgCoberturas.map(objCobertura => objCobertura.idSubProdCondicionadoCobertura);

        lstSubproductos.forEach((lstsubProdCoberturas) =>{
          const lstCoberturas = lstsubProdCoberturas.filter(objCobertura => lstIdCoberturas.includes(objCobertura.idSubProdCondicionadoCobertura))

          lstCoberturas.forEach((objCobertura) => {
            objCobertura.valorAtributoTemaCondicion.forEach((objCondicion) => {

              switch (objCondicion.tipoAtributoCondicion.idTipoAtributoCondicion) {
                case ID_TIPO_ATRIBUTO_CONDICION_6:
                case ID_TIPO_ATRIBUTO_CONDICION_7:
                  estructuraFormulario.cargas = true;
                  break;
  
                case ID_TIPO_ATRIBUTO_CONDICION_9:
                  estructuraFormulario.beneficiarios = true;
                  break;
                
                case ID_TIPO_ATRIBUTO_CONDICION_17:
                  estructuraFormulario.formaPago = true;
                  break;
                default:
                  break;
              }
            });
          });
        });

        if (this.state.tipoFlujo === FLUJO_MANTENCION) {
          Promise.all(
            informacionPoliza.grupos.map(async (grupo) => {
              if (this.state.tipoFlujo === FLUJO_MANTENCION || (grupo && grupo.nroPoliza && grupo.nroRenovacion)) {
                let poliza = await listarPorNroPolizaNroRenovacion(grupo.nroPoliza, grupo.nroRenovacion);
                if (await poliza.agrupaciones && poliza.agrupaciones[POSICION_INICIAL] && poliza.agrupaciones[POSICION_INICIAL].glosa
                  && poliza.agrupaciones[POSICION_INICIAL].glosa === BANCO_RIPLEY_PLAN_INICIAL) {
                  estructuraFormulario.cargas = null;
                }
              }
            })
          ).then((respuesta) => {
            this.props.guardar_estructura_formulario(estructuraFormulario);
            this.obtenerPaginaWizardActual();

            const menu = MENU_WIZARD;

            menu[MENU_UNO].visible = estructuraFormulario.cargas;
            menu[MENU_TRES].visible = estructuraFormulario.beneficiarios;
            this.setState({ menu, cargando: false });
          });
        } else {
          this.props.guardar_estructura_formulario(estructuraFormulario);
          this.obtenerPaginaWizardActual();

          const menu = MENU_WIZARD;

          menu[1].visible = estructuraFormulario.cargas;
          menu[3].visible = estructuraFormulario.beneficiarios;
          this.setState({ menu, cargando: false });
        }
      });
    }
  };

  obtenerInformacionPoliza = async () => {
    
    let informacionPoliza = await obtenerInformacionPoliza(this.props.store.auth.usuarioSesion.rut);

    if (
      informacionPoliza.canalVenta &&
      informacionPoliza.canalVenta.idCanalVenta === TIPO_CANAL_VENTA_CORREDOR &&
      informacionPoliza.grupos
    ) {
      const grupos = await informacionPoliza.grupos;
      informacionPoliza.grupos = grupos.filter(
        (grupo) => grupo.nroPoliza > SIN_POLIZAS
      );
    }
    //si no es 5/50 (para mantencion)
    if (
      informacionPoliza &&
      informacionPoliza.grupos &&
      informacionPoliza.grupos[0].idContratoSeguroGrupo !== VALOR_ZERO
    ) {
      const solicitudIncorporacion = informacionPoliza.grupos
        .map((grupo) => grupo.idPolizaAsegurado)
        .includes(VALOR_ZERO);
      const tipoProducto = informacionPoliza.grupos.filter(
        (grupo) =>
          grupo.poliza.tipoPoliza.glosa === TIPO_POLIZA_VIDA &&
          grupo.idPolizaAsegurado === VALOR_ZERO
      );
      const productoVida = tipoProducto.length > VALOR_ZERO;

      const soloProductoVida =
        informacionPoliza.grupos.filter(
          (grupo) => grupo.poliza.tipoPoliza.glosa !== TIPO_POLIZA_VIDA
        ).length === VALOR_ZERO;

      const tipoCapital = informacionPoliza.grupos.filter(
        (grupo) =>
          grupo.poliza.tipoPoliza.glosa === TIPO_POLIZA_VIDA &&
          grupo.tipoValorPrincipal === TIPO_VALOR_RENTA
      );
      const tipoCapitalRenta = tipoCapital.length > VALOR_ZERO;
      let coberturas         = [];

      await Promise.all(
        informacionPoliza.grupos.map(async (objGrupo) => {

          const { idContratoSeguroGrupo, poliza: {grupos} } = objGrupo;

          const coberturas = grupos.find((grupoPoliza) => 
            grupoPoliza.idContratoSeguroGrupo === idContratoSeguroGrupo
          ).coberturas;

          await Promise.all(
            
            coberturas.map(async ( objCobertura ) => {
              const objSubproducto                        = await buscarSubProducto(objCobertura.idCobertura);
              let objCondCobertura                        = Object.assign({}, ...objSubproducto.condicionadoCobertura);
              objCobertura.configuracion                  = objCondCobertura.cobertura;
              objCobertura.condicionado                   = objCondCobertura.condicionado;
              objCobertura.codigoCotizacion               = objGrupo.codigoCotizacion;
              objCobertura.idSubProdCondicionadoCobertura = objCondCobertura.idSubProdCondicionadoCobertura;
              return objCobertura;
            })
          ).then((lstCoberturas) => {
            
            objGrupo.coberturaPrincipal = lstCoberturas.find((objCobertura) => objCobertura.principal);
            objGrupo.coberturas = lstCoberturas;
          });

          return objGrupo;
        })
      ).then((lstGrupos) => {

        lstGrupos.forEach((objGrupo) => {
          objGrupo.coberturas.forEach((objCobertura) => {
            objCobertura = {
              ...objCobertura,
              tipoPoliza: objGrupo.poliza.tipoPoliza,
            };
            coberturas.push(objCobertura);
          });
        });

        informacionPoliza.grupos = lstGrupos;
      });
      
      informacionPoliza.coberturasPrincipales = coberturas.map(
        (objGrupo) => objGrupo.coberturaPrincipal
      );
      
      let objCoberturasCargas = coberturas.filter(
        (objCobertura) => objCobertura.tipoPoliza.idTipoPoliza === ID_TIPO_POLIZA_SALUD
      );

      informacionPoliza.coberturasCargas = objCoberturasCargas ? objCoberturasCargas : [];

      informacionPoliza.segurosContratados = arrayToFormattedString(
        informacionPoliza.grupos.map((grupo) => grupo.poliza.tipoPoliza.glosa)
      );

      informacionPoliza.solicitudIncorporacion = solicitudIncorporacion;
      informacionPoliza.productoVida = productoVida;
      informacionPoliza.tipoCapitalRenta = tipoCapitalRenta;
      informacionPoliza.soloProductoVida = soloProductoVida;
      informacionPoliza.coberturas = coberturas;

      const cubrePreexistencias =
        informacionPoliza.coberturas.find(
          (cobertura) => cobertura.cubrePreexistencia
        ) !== undefined;

      informacionPoliza.cubrePreexistencias = cubrePreexistencias;

      await this.props.obtener_info_poliza(informacionPoliza);

      this.obtenerPolCad(coberturas);

      if (!solicitudIncorporacion) {
        const estatura =
          informacionPoliza.estatura === null
            ? VALOR_ZERO
            : (Number(informacionPoliza.estatura) * VALOR_CIEN).toFixed(0);
        const peso =
          informacionPoliza.peso === null
            ? VALOR_ZERO
            : Number(informacionPoliza.peso).toFixed(0);

        const dataWizardAsegurado = {
          ...this.props.store.reducer.dataWizardAsegurado,
          cargas: null,
          solicitud: {
            declaroCarga: null,
            declaroEspecial: null,
            declaroDeportes: null,
            declaroSalud: null,
          },
          rut: informacionPoliza.rut,
          digitoVerificador: informacionPoliza.digitoVerificador,
          nombre: informacionPoliza.nombre,
          apellidoPaterno: informacionPoliza.apellidoPaterno,
          apellidoMaterno: informacionPoliza.apellidoMaterno,
          codigoCotizacion: informacionPoliza.codigoCotizacion,
          idAsegurable: informacionPoliza.idAsegurable,
          idPersona: informacionPoliza.idPersona,
          actividadLaboral: informacionPoliza.actividad,
          estadoCivil: {idEstadoCivil: informacionPoliza.idEstadoCivil,},
          estatura,
          fechaIngresoEmpresa: informacionPoliza.fechaContratoTrabajo,
          fechaNacimiento: informacionPoliza.fechaNacimiento,
          genero: {idGenero: informacionPoliza.idGenero,},
          peso,
          renta: informacionPoliza.rentaInformada,
          sistemaPrevisionSalud: {idSistemaPrevisionSalud: informacionPoliza.idPrevision,},
          complementoDireccion: informacionPoliza.contacto.complemento,
          correoElectronico: informacionPoliza.correoElectronico,
          comuna: {idComuna: informacionPoliza.contacto.idComuna,},
          ciudad: {idCiudad: informacionPoliza.contacto.idCiudad,},
          region: {idRegion: informacionPoliza.contacto.idRegion,},
          departamento: informacionPoliza.contacto.complemento,
          direccion: informacionPoliza.contacto.calle,
          numero: informacionPoliza.contacto.numero,
          telefonoFijo: informacionPoliza.contacto.telefonoFijo,
          telefonoMovil: informacionPoliza.contacto.telefono,
          formaPago: {idFormaPago: informacionPoliza.formaPago.idFormaPago,},
          institucionFinanciera: {idInstitucionFinanciera: informacionPoliza.formaPago.idBanco,},
          tipoCuentaBancaria: {idTipoCuentaBancaria: informacionPoliza.formaPago.idTipoCuenta,},
          numeroCuenta: informacionPoliza.formaPago.numCuenta,
          coberturas: [...informacionPoliza.coberturas]
        };
        this.props.guardar_data_wizard_asegurado(dataWizardAsegurado);
      }
    } else if (
      informacionPoliza.grupos[0].idContratoSeguroGrupo === VALOR_ZERO &&
      informacionPoliza.grupos[0].idContratoSeguro === VALOR_ZERO
    ) {
      const asegurable_5_50 = true;
      informacionPoliza.asegurable_5_50 = asegurable_5_50;
      informacionPoliza.cubrePreexistencias = true;
      informacionPoliza.infoPolCad = `${EL_CODIGO}
      ${POL_5_50} ${STRING_Y} 
      ${CLAUSULA_ADICIONAL}
      ${CAD_5_50}`;
      const dataWizardAsegurado = {
        ...this.props.store.reducer.dataWizardAsegurado,
        informacionPoliza,
      };
      this.props.guardar_data_wizard_asegurado(dataWizardAsegurado);

      const menu = MENU_WIZARD.menu_5_50;

      this.setState({ menu });
    }
    await this.props.obtener_info_poliza(informacionPoliza);
  };

  async obtenerPreguntasDeclaracionesPorSubProdCondicionadoYCodigoCotizacion(
    idSubProdCondicionadoCobertura,
    codigoCotizacion
  ) {
    let preguntas = [];
    let preguntasDeclaraciones = await obtenerPreguntasDeclaraciones(
      idSubProdCondicionadoCobertura,
      codigoCotizacion
    );

    preguntasDeclaraciones =
      preguntasDeclaraciones &&
      preguntasDeclaraciones.map((pregunta) => {
        return {
          idSubProdCondicionadoCobertura: idSubProdCondicionadoCobertura,
          idPreguntaSeccionCobertura: pregunta.idPreguntaSeccionCobertura,
          catalogoPregunta: pregunta.catalogoPregunta,
        };
      });
    if (preguntasDeclaraciones) {
      preguntas.push(...preguntasDeclaraciones);
    }
    return preguntas;
  }

  obtenerPreguntasDeclaraciones = async () => {
    if (
      this.props.store.reducer.data_asegurable.canalVenta &&
      (this.props.store.reducer.data_asegurable.canalVenta.idCanalVenta ===
        VALOR_DOS || this.props.store.reducer.data_asegurable.canalVenta.idCanalVenta === TIPO_CANAL_VENTA_DIGITAL)
    ) {
      this.ordenarPreguntasDeclaraciones();
    } else {
      // para flujos distintos a 5/50 (mantencion)
      this.ordenarPreguntasDeclaraciones();
    }
  };

  ordenarPreguntasDeclaraciones = async () => {

    const { informacionPoliza, data_asegurable } = this.props.store.reducer;

    if (this.state.tipoFlujo === FLUJO_VENTA_DIRECTA || this.state.tipoFlujo === FLUJO_VENTA_DIGITAL) {
      for (let cotizacion of data_asegurable.cotizaciones) {
        for (let cobertura of cotizacion.coberturas) {
          const preguntasPorCobertura = await obtenerPreguntasDeclaraciones(
            cobertura.cotizacionCobertura.idSubProdCondicionadoCobertura,
            cotizacion.codigoCotizacion
          );

          if (preguntasPorCobertura) {
            preguntasPorCobertura.forEach(async (pregunta) => {
              pregunta.glosasCoberturas = [
                {
                  idCobertura:
                    cobertura.cotizacionCobertura
                      .idSubProdCondicionadoCobertura,
                  glosa:
                    cobertura.glosaCobertura === COBERTURA_FALLECIMIENTO
                      ? COBERTURA_SEGURO_VIDA
                      : cobertura.glosaCobertura,
                },
              ];
            });
            const arregloDeclaraciones =
              await this.state.arregloDeclaraciones.concat(
                preguntasPorCobertura
              );

            let declaracionesSinDuplicados = [];

            for (let index in arregloDeclaraciones) {
              const duplicado = await declaracionesSinDuplicados.find(
                (pregunta) => {
                  return (
                    arregloDeclaraciones[index].catalogoPregunta.idPregunta ===
                    pregunta.catalogoPregunta.idPregunta
                  );
                }
              );
              if (duplicado === undefined) {
                await declaracionesSinDuplicados.push(
                  arregloDeclaraciones[index]
                );
              } else {
                const glosasCoberturas = arregloDeclaraciones[
                  index
                ].glosasCoberturas.concat(duplicado.glosasCoberturas);

                declaracionesSinDuplicados.forEach(
                  (declaracionesSinDuplicar) => {
                    if (
                      declaracionesSinDuplicar.catalogoPregunta.idPregunta ===
                      duplicado.catalogoPregunta.idPregunta
                    ) {
                      glosasCoberturas.forEach((glosaCobertura) => {
                        if (
                          !declaracionesSinDuplicar.glosasCoberturas.includes(
                            glosaCobertura
                          )
                        ) {
                          declaracionesSinDuplicar.glosasCoberturas.push(
                            glosaCobertura
                          );
                        }
                      });
                    }
                  }
                );
              }
            }

            // Se eliminan las declaracion con agrupaciones.
            declaracionesSinDuplicados = this.limpiarPreguntasAgrupadas(
              declaracionesSinDuplicados
            );

            this.setState({ arregloDeclaraciones: declaracionesSinDuplicados });
            this.props.getListaDeclaraciones(declaracionesSinDuplicados);
          }
        }
      }
    } else {
      for (let cotizacion of informacionPoliza.grupos) {

        cotizacion.coberturas.forEach(async (cobertura) => {
          const preguntasPorCobertura = await obtenerPreguntasDeclaraciones(
            cobertura.idCobertura,
            cotizacion.codigoCotizacion
          );

          if (preguntasPorCobertura) {
            preguntasPorCobertura.forEach((pregunta) => {
              pregunta.glosasCoberturas = [
                {
                  idCobertura: cobertura.idCobertura,
                  glosa:
                    cobertura.idCobertura === ID_COBERTURA_FALLECIMIENTO
                      ? COBERTURA_SEGURO_VIDA
                      : cobertura.glosa,
                },
              ];
            });
            const arregloDeclaraciones = this.state.arregloDeclaraciones.concat(
              preguntasPorCobertura
            );

            let declaracionesSinDuplicados = [];

            for (let index in arregloDeclaraciones) {
              const duplicado = declaracionesSinDuplicados.find((pregunta) => {
                return (
                  arregloDeclaraciones[index].catalogoPregunta.idPregunta ===
                  pregunta.catalogoPregunta.idPregunta
                );
              });
              if (duplicado === undefined) {
                declaracionesSinDuplicados.push(arregloDeclaraciones[index]);
              } else {
                const glosasCoberturas = arregloDeclaraciones[
                  index
                ].glosasCoberturas.concat(duplicado.glosasCoberturas);
                const indexDuplicado =
                  declaracionesSinDuplicados.indexOf(duplicado);
                declaracionesSinDuplicados[indexDuplicado].glosasCoberturas =
                  glosasCoberturas;
                if (
                  arregloDeclaraciones[index].prioridad < duplicado.prioridad
                ) {
                  declaracionesSinDuplicados[indexDuplicado] =
                    arregloDeclaraciones[index];
                }
              }
            }

            // Se eliminan las declaracion con agrupaciones.
            declaracionesSinDuplicados = this.limpiarPreguntasAgrupadas(
              declaracionesSinDuplicados
            );

            this.setState({ arregloDeclaraciones: declaracionesSinDuplicados });
            this.props.getListaDeclaraciones(declaracionesSinDuplicados);
          }
        });
      }
    }
  };

  limpiarPreguntasAgrupadas = (declaracionesSinDuplicados) => {
    return declaracionesSinDuplicados.filter((pregunta) => {
      let resPregunta;
      //Cuento cuantas agrupaciones hay, que sean mayores que 0.
      let intAgrupa = declaracionesSinDuplicados.filter((p) => {
        let agrupacion;
        if (pregunta.agrupa > VALOR_ZERO && p.agrupa === pregunta.agrupa) {
          agrupacion = p;
        }
        return agrupacion;
      });
      //Si existe mas de un registro, la prioridad 1 manda.
      if (intAgrupa && intAgrupa.length > VALOR_UNO) {
        if (pregunta.agrupa > VALOR_ZERO && pregunta.prioridad === VALOR_UNO) {
          resPregunta = pregunta;
        }
      } else {
        // Si existe un solo registro.
        resPregunta = pregunta;
      }
      // Los registros que no estan agrupados.
      if (pregunta.agrupa === VALOR_ZERO) {
        resPregunta = pregunta;
      }
      return resPregunta;
    });
  };

  guardarTipoIncorporacion = () => {
    const { informacionPoliza } = this.props.store.reducer;

    const condicionMantencion =
      informacionPoliza &&
      informacionPoliza.grupos &&
      informacionPoliza.grupos[0].idContratoSeguroGrupo !== VALOR_ZERO;

    if (condicionMantencion) {
      const dataEnvio = {
        ...this.props.store.reducer.dataWizardAsegurado,
        solicitud: {
          ...this.props.store.reducer.dataWizardAsegurado.solicitud,
          tipoIncorporacion: {
            idTipoIncorporacion: TIPO_INCORPORACION_MANTENCION,
          },
        },
      };
      this.props.guardar_data_wizard_asegurado(dataEnvio);
    } else {
      const dataEnvio = {
        ...this.props.store.reducer.dataWizardAsegurado,
        solicitud: {
          ...this.props.store.reducer.dataWizardAsegurado.solicitud,
          tipoIncorporacion: {
            idTipoIncorporacion: TIPO_INCORPORACION_NUEVO_NEGOCIO,
          },
        },
      };
      this.props.guardar_data_wizard_asegurado(dataEnvio);
    }
  };

  async ordenarDeclaraciones() {

    const { dataWizardAsegurado } = this.props.store.reducer;
    let declaracionesTitularConRut, cargasDeclaracionesConRut;
    
    if (
      dataWizardAsegurado.coberturas &&
      dataWizardAsegurado.coberturas.length > VALOR_VACIO
    ) {
      if (
        dataWizardAsegurado.coberturas[0].declaraciones &&
        dataWizardAsegurado.coberturas[0].declaraciones.length > VALOR_ZERO
      ) {
        const declaracionesTitular =
          dataWizardAsegurado.coberturas[0].declaraciones;
        declaracionesTitularConRut = declaracionesTitular.map((item) => {
          return {
            ...item,
            rut: dataWizardAsegurado.rut,
            nombre: `${dataWizardAsegurado.nombre} ${dataWizardAsegurado.apellidoPaterno} ${dataWizardAsegurado.apellidoMaterno}`,
          };
        });
        const { informacionPoliza } = this.props.store.reducer;
        const { cotizaciones } = this.props.store.reducer.data_asegurable;
        const dataWizardTemporal = this.state.dataWizard.coberturas;
        let coberturas = [];
        if (!informacionPoliza.asegurable_5_50) {
          coberturas = await this.obtenerCoberturas(informacionPoliza);
        }
        const data = {
          ...this.props.store.reducer.dataWizardAsegurado,
          coberturas: informacionPoliza.coberturas
            ? informacionPoliza.coberturas.map((objCoberturaPoliza) => {
              return {
                idProductoCobertura: objCoberturaPoliza.idProductoCobertura,
                capitalAsegurado: coberturas.find(
                  (objCobertura) => objCobertura.idCobertura === objCoberturaPoliza.idCobertura
                ).capital,
                idSubProdCondicionadoCobertura: objCoberturaPoliza.idCobertura,
                declaraciones: declaracionesTitularConRut,
                consentimiento: dataWizardTemporal.find(
                  (objCobertura) => objCobertura.consentimiento
                )
                  ? dataWizardTemporal.find((objCobertura) => objCobertura.consentimiento)
                    .consentimiento
                  : null,
                tipoPoliza: objCoberturaPoliza.tipoPoliza,
                glosa: objCoberturaPoliza.glosa,
                principal: objCoberturaPoliza.principal
              };
            })
            : cotizaciones &&
            this.coberturasVentaDirectaConDeclaracion(
              cotizaciones,
              declaracionesTitularConRut,
              dataWizardTemporal
            ),
        };
        data.cargas = data.cargas.filter((dato) => dato !== undefined);
        this.props.guardar_data_wizard_asegurado(data);
      }
    } else {
      const { informacionPoliza } = this.props.store.reducer;
      const { cotizaciones } = this.props.store.reducer.data_asegurable;
      dataWizardAsegurado.coberturas = informacionPoliza.coberturasPrincipales
        ? informacionPoliza.coberturasPrincipales.map((item) => {
          let objRetorna;
          if (item != null)
            objRetorna = {
              idProductoCobertura: item.idProductoCobertura,
              capitalAsegurado: STRING_VACIO,
              idSubProdCondicionadoCobertura: item.idCobertura,
              declaraciones: null,
            };
          return objRetorna;
        })
        : cotizaciones &&
        this.coberturasVentaDirecta(cotizaciones, dataWizardAsegurado);

      this.props.guardar_data_wizard_asegurado(dataWizardAsegurado);
    }

    if (
      dataWizardAsegurado.cargas !== undefined &&
      dataWizardAsegurado.cargas !== null &&
      dataWizardAsegurado.cargas.length > VALOR_ZERO
    ) {
      dataWizardAsegurado.cargas.forEach((carga) => {
        if (carga.coberturas !== null) {
          carga.coberturas.forEach((c) => {
            if (c.declaraciones) {
              c.declaraciones.forEach((d) => {
                d.rut = carga.rut;
                d.nombre = `${carga.nombre} ${carga.apellidoPaterno} ${carga.apellidoMaterno}`;
              });
            }
          });
        }
      });

      cargasDeclaracionesConRut = dataWizardAsegurado.cargas;
      this.props.update_carga(cargasDeclaracionesConRut);
    }

    const declaracionesCargas = cargasDeclaracionesConRut
      ? cargasDeclaracionesConRut.map((item) =>
        item.coberturas.length > LARGO_CERO_COBERTURAS ? item.coberturas[POSICION_INICIAL].declaraciones : null
      )
      : null;
    let declaracionesCargasFlat = declaracionesCargas
      ? declaracionesCargas.flat()
      : [];

    const decCargasFlat =
      declaracionesCargasFlat.length === VALOR_UNO &&
        declaracionesCargasFlat.includes(null)
        ? []
        : declaracionesCargasFlat;

    const decTitularConRut =
      declaracionesTitularConRut === undefined
        ? []
        : declaracionesTitularConRut;

    let detalle = [...decTitularConRut, ...decCargasFlat];

    detalle = detalle.filter(Boolean);

    detalle.forEach((item) => {
      if (typeof item.fechaDiagnostico === "number") {
        item.fechaDiagnosticoFormat = moment(item.fechaDiagnostico).format(
          "DD-MM-YYYY"
        );
      }
    });
    this.props.update_detalle(detalle);
  }

  coberturasVentaDirecta(cotizacion, res) {

    const { planCotizado } = this.props.store.reducer.data_asegurable;
    let arregloCotizacion = [];
    cotizacion.forEach((cot) => {
      cot.coberturas.forEach((cob) => {
        let objPlan = SIN_VALOR_PLAN;
        if (this.state.tipoFlujo === FLUJO_VENTA_DIGITAL) {
          objPlan = planCotizado.find((objPlanCotizado) => {
            return objPlanCotizado.idSubProdCondicionadoCobertura === cob.cotizacionCobertura.idSubProdCondicionadoCobertura
          })
        }

        let objCoberturaNueva = {
          idProductoCobertura: cob.idProductoCobertura !== ID_PRODUCTO_COBERTURA ? cob.idProductoCobertura : objPlan.idProductoCobertura,
          idSubProdCondicionadoCobertura:
            cob.cotizacionCobertura.idSubProdCondicionadoCobertura,
          idSubProducto: cot.subProducto.idSubProducto,
          capitalAsegurado:
          cot.coberturas[POSICION_INICIAL].cotizacionCobertura.capitales && cot.coberturas[POSICION_INICIAL].cotizacionCobertura.capitales.length > ID_PRODUCTO_COBERTURA ? cot.coberturas[POSICION_INICIAL].cotizacionCobertura.capitales[
              POSICION_INICIAL
            ].valorCapital : "",
          idProducto: cot.subProducto.producto.idProducto,
          cubrePreexistencia: !cot.evaluacionPreexistencia,
          glosa: cob.glosaCobertura ? cob.glosaCobertura : "",
          codigoCotizacion: cot.codigoCotizacion,
        }
        if (res.coberturas) {
          res.coberturas.forEach(objCobertura => {
            if (objCobertura.idProductoCobertura === cob.idProductoCobertura) {
              if (objCobertura.consentimiento) {
                objCoberturaNueva.consentimiento = objCobertura.consentimiento;
              }
              if (objCobertura.declaraciones) {
                objCoberturaNueva.declaraciones = objCobertura.declaraciones;
              }
            }
          })
        }
        arregloCotizacion.push(objCoberturaNueva);
      });
    });
    return arregloCotizacion;
  }

  coberturasVentaDirectaConDeclaracion(cotizacion, declaracion, dataWizard) {
    const { planCotizado } = this.props.store.reducer.data_asegurable;
    let arregloCotizacion = [];
    cotizacion.forEach((cot) => {
      cot.coberturas.forEach((cob) => {
        let objPlan = SIN_VALOR_PLAN;
        if (this.state.tipoFlujo === FLUJO_VENTA_DIGITAL) {
          objPlan = planCotizado.find((objPlanCotizado) => {
            return objPlanCotizado.idSubProdCondicionadoCobertura === cob.cotizacionCobertura.idSubProdCondicionadoCobertura
          })
        }
        arregloCotizacion.push({
          idProductoCobertura: cob.idProductoCobertura !== ID_PRODUCTO_COBERTURA ? cob.idProductoCobertura : objPlan.idProductoCobertura,
          idSubProdCondicionadoCobertura:
            cob.cotizacionCobertura.idSubProdCondicionadoCobertura,
          declaraciones: declaracion,
          consentimiento: dataWizard[POSICION_INICIAL].consentimiento
            ? dataWizard[POSICION_INICIAL].consentimiento
            : null,
          idSubProducto: cot.subProducto.idSubProducto,
          capitalAsegurado:
          cot.coberturas[POSICION_INICIAL].cotizacionCobertura.capitales && cot.coberturas[POSICION_INICIAL].cotizacionCobertura.capitales.length > SIN_VALOR_CAPITAL ? cot.coberturas[POSICION_INICIAL].cotizacionCobertura.capitales[
              POSICION_INICIAL
            ].valorCapital : "",
          idProducto: cot.subProducto.producto.idProducto,
          cubrePreexistencia: !cot.evaluacionPreexistencia
        });
      });
    });
    return arregloCotizacion;
  }

  async obtenerCoberturas(informacionPoliza) {
    let poliza = informacionPoliza.grupos.map(
      (grupo) => grupo.nroPoliza + SIGNO_GUION + grupo.nroRenovacion
    );
    let coberturas = [];
    for (const pol of poliza) {
      const datosPol = await listarPorNroPolizaNroRenovacion(
        pol.split("-")[VALOR_ZERO],
        pol.split("-")[VALOR_UNO]
      );
      coberturas.push(await datosPol.grupos[VALOR_ZERO].coberturas);
    }

    return coberturas.flat();
  }

  actualizarProgreso() {
    const { reducer } = this.props.store;
    const data = reducer.dataWizardAsegurado;
    const dataAsegurado = reducer.data_asegurable;
    const { estructuraFormulario } = reducer;
    if (Object.entries(estructuraFormulario).length > VALOR_ZERO) {
      let { progresoAsegurable, informacionPoliza } = this.props.store.reducer;
      progresoAsegurable.porcentajeAvance = PROGRESO_INICIAL;

      if (dataAsegurado.fechaRecepcion)
        return (progresoAsegurable.porcentajeAvance = PROGRESO_FINAL);

      // Wizzard 1
      const tabMisDatos = W1_MIS_DATOS;
      const tabDireccion = W1_DIRECCION;
      const tabPago = W1_DATOS_PAGO;

      if (data !== null) {
        // Validacion wizzard 1
        let tab1 = true;
        let tab2 = true;
        let tab3 = true;

        const incorporacionCargaMantencion =
          informacionPoliza &&
          informacionPoliza.grupos &&
          informacionPoliza.grupos[0].idContratoSeguroGrupo !== VALOR_ZERO &&
          !informacionPoliza.solicitudIncorporacion;

        if (incorporacionCargaMantencion) {
          progresoAsegurable.porcentajeAvance += PROGRESO_ETAPA;
        } else {
          tabMisDatos.forEach((t) => {
            if (data[t] === null || data[t] === undefined) tab1 = false;
          });
          tabDireccion.forEach((t) => {
            if (data[t] === null || data[t] === undefined) tab2 = false;
          });

          //condicion por forma de pago cuando no es soloProductoVida
          if (estructuraFormulario.formaPago) {
            //estructuraFormulario.formaPago
            tabPago.forEach((t) => {
              if (data[t] === null || data[t] === undefined) tab3 = false;
            });
            if (data.formaPago) {
              if (data.formaPago.idFormaPago === FORMA_PAGO_SERVIPAG)
                tab3 = true;
            }
          }

          if (tab1) progresoAsegurable.porcentajeAvance += PROGRESO_SUB_ETAPA;
          if (tab2) progresoAsegurable.porcentajeAvance += PROGRESO_SUB_ETAPA;
          if (tab3) progresoAsegurable.porcentajeAvance += PROGRESO_SUB_ETAPA;
        }

        progresoAsegurable.wizard[0].hecho = tab1 && tab2 && tab3;
        progresoAsegurable.wizard[0].estado =
          tab1 && tab2 && tab3 ? DONE : DOING;

        //evaluar si se calcula para etapa adicional
        const etapaAdicional = estructuraFormulario.beneficiarios; //soloProductoVida tiene Beneficiarios pero no cargas, entonces PROGRESO_ETAPA = 30

        let porcentajeProgreso;
        if (etapaAdicional) {
          porcentajeProgreso = PROGRESO_ETAPA_20;
        } else {
          porcentajeProgreso = PROGRESO_ETAPA;
        }

        // Wizzard 2
        if (estructuraFormulario.cargas !== undefined) {
          if (!estructuraFormulario.cargas) {
            progresoAsegurable.wizard[1].hecho = true;
            progresoAsegurable.wizard[1].estado = DONE;
          } else {
            if (data.solicitud.declaroCarga) {
              if (data.cargas) {
                let cantCargas = data.cargas.length;
                progresoAsegurable.wizard[1].hecho = cantCargas > VALOR_ZERO;
                progresoAsegurable.wizard[1].estado =
                  cantCargas > VALOR_ZERO ? DONE : DOING;
                if (cantCargas > VALOR_ZERO)
                  progresoAsegurable.porcentajeAvance += porcentajeProgreso;
              }
            } else if (data.solicitud.declaroCarga === false) {
              progresoAsegurable.wizard[1].hecho = true;
              progresoAsegurable.wizard[1].estado = DONE;
              progresoAsegurable.porcentajeAvance += porcentajeProgreso;
            } else if (
              data.solicitud.declaroCarga === null &&
              progresoAsegurable.wizard[0].estado === DONE
            ) {
              progresoAsegurable.wizard[1].hecho = false;
              progresoAsegurable.wizard[1].estado = DOING;
            } else if (data.solicitud.declaroCarga === null) {
              progresoAsegurable.wizard[1].hecho = false;
              progresoAsegurable.wizard[1].estado = TODO;
            }
          }
        }

        // Wizzard 3
        const declaracionSalud = this.props.store.reducer.declaraciones.find(
          (declaracion) => declaracion.seccion.glosa === DECLARACION_SALUD
        );

        const declaroEspecialTrue =
          data.solicitud.declaroEspecial !== null &&
          data.solicitud.declaroEspecial !== undefined;
        const declaroDeportesTrue =
          data.solicitud.declaroDeportes !== null &&
          data.solicitud.declaroDeportes !== undefined;
        const declaroSaludTrue =
          data.solicitud.declaroSalud !== null &&
          data.solicitud.declaroSalud !== undefined;

        const arregloDeclaro = declaracionSalud
          ? [declaroEspecialTrue, declaroDeportesTrue, declaroSaludTrue]
          : [declaroEspecialTrue, declaroDeportesTrue];

        let progresoEtapa = VALOR_ZERO;
        for (let index in arregloDeclaro) {
          if (arregloDeclaro[index]) {
            progresoEtapa += declaracionSalud
              ? PROGRESO_SUB_ETAPA
              : PROGRESO_ETAPA_15;
          }
        }
        const indiceDeclaraciones = VALOR_DOS;
        if (!arregloDeclaro.includes(false)) {
          progresoAsegurable.wizard[indiceDeclaraciones].hecho = true;
          progresoAsegurable.wizard[indiceDeclaraciones].estado =
            declaroEspecialTrue ? DONE : DOING;
          progresoAsegurable.porcentajeAvance += porcentajeProgreso;
        } else if (
          arregloDeclaro.includes(false) &&
          progresoAsegurable.wizard[0].estado === DONE &&
          progresoAsegurable.wizard[1].estado === DONE
        ) {
          progresoAsegurable.wizard[indiceDeclaraciones].hecho = false;
          progresoAsegurable.wizard[indiceDeclaraciones].estado = DOING;
          progresoAsegurable.porcentajeAvance += progresoEtapa;
        } else if (!arregloDeclaro.includes(true)) {
          progresoAsegurable.wizard[indiceDeclaraciones].hecho = false;
          progresoAsegurable.wizard[indiceDeclaraciones].estado = TODO;
        }

        if (
          this.props.store.reducer.data_asegurable.idTipoSolicitud ===
          VALOR_CINCO
        ) {
          progresoAsegurable.wizard[VALOR_ZERO].hecho = true;
          progresoAsegurable.wizard[VALOR_ZERO].estado = DONE;
          progresoAsegurable.wizard[VALOR_UNO].hecho = true;
          progresoAsegurable.wizard[VALOR_UNO].estado = DONE;
          progresoAsegurable.wizard[VALOR_DOS].hecho = true;
          progresoAsegurable.wizard[VALOR_DOS].estado = DONE;
          progresoAsegurable.wizard[VALOR_TRES].hecho = false;
          progresoAsegurable.wizard[VALOR_TRES].estado = DOING;
        }

        // #Beneficiarios
        const indiceBeneficiarios = VALOR_TRES;
        if (estructuraFormulario.beneficiarios !== undefined) {
          if (!estructuraFormulario.beneficiarios) {
            progresoAsegurable.wizard[indiceBeneficiarios].hecho = true;
            progresoAsegurable.wizard[indiceBeneficiarios].estado = DONE;
          } else {
            if (data.solicitud.aceptacionBeneficiario) {
              if (data.beneficiarios) {
                let sumaPorcentaje = data.beneficiarios
                  .map((beneficiario) =>
                    Number(beneficiario.porcentajeBeneficio)
                  )
                  .reduce((a, b) => a + b, 0);
                progresoAsegurable.wizard[indiceBeneficiarios].hecho =
                  sumaPorcentaje === VALOR_CIEN || !data.beneficiarios.length;
                progresoAsegurable.wizard[indiceBeneficiarios].estado =
                  sumaPorcentaje === VALOR_CIEN || !data.beneficiarios.length
                    ? DONE
                    : DOING;

                if (
                  !data.beneficiarios.length ||
                  sumaPorcentaje === VALOR_CIEN
                ) {
                  progresoAsegurable.porcentajeAvance += porcentajeProgreso;
                }
              } else {
                progresoAsegurable.wizard[indiceBeneficiarios].estado = DOING;
              }
            } else if (
              data.solicitud.declaroBeneficiario === null ||
              data.solicitud.declaroBeneficiario === undefined
            ) {
              progresoAsegurable.wizard[indiceBeneficiarios].hecho = false;
              if (
                progresoAsegurable.wizard[indiceBeneficiarios - 1].estado ===
                DONE
              ) {
                progresoAsegurable.wizard[indiceBeneficiarios].estado = DOING;
              } else {
                progresoAsegurable.wizard[indiceBeneficiarios].estado = TODO;
              }
            }
          }
        }

        //WIZARD 5
        if (
          progresoAsegurable.wizard[0].estado === DONE &&
          progresoAsegurable.wizard[1].estado === DONE &&
          progresoAsegurable.wizard[2].estado === DONE &&
          progresoAsegurable.wizard[3].estado === DONE
        ) {
          progresoAsegurable.wizard[4].estado = DOING;
        }
      }
    }
  }

  obtenerPaginaWizardActual() {
    const { progresoAsegurable, wizard, estructuraFormulario } =
      this.props.store.reducer;
    let datosPersonales = progresoAsegurable.wizard[0].estado !== DONE;
    const cargas =
      progresoAsegurable.wizard[0].estado === DONE &&
      progresoAsegurable.wizard[1].estado !== DONE;

    const declaraciones =
      progresoAsegurable.wizard[0].estado === DONE &&
      progresoAsegurable.wizard[1].estado === DONE &&
      progresoAsegurable.wizard[2].estado !== DONE;

    const beneficiarios =
      progresoAsegurable.wizard[0].estado === DONE &&
      progresoAsegurable.wizard[1].estado === DONE &&
      progresoAsegurable.wizard[2].estado === DONE &&
      progresoAsegurable.wizard[3].estado !== DONE;

    const resumen =
      progresoAsegurable.wizard[0].estado === DONE &&
      progresoAsegurable.wizard[1].estado === DONE &&
      progresoAsegurable.wizard[2].estado === DONE &&
      progresoAsegurable.wizard[3].estado === DONE &&
      progresoAsegurable.wizard[4].estado !== DONE;
    if (datosPersonales) wizard.pagina = PAGINA_1;
    if (cargas) wizard.pagina = PAGINA_2;
    if (declaraciones) wizard.pagina = PAGINA_3;
    if (beneficiarios) wizard.pagina = PAGINA_4;
    if (resumen) wizard.pagina = PAGINA_5;

    // CALCULAR TAB
    // Wizzard 1
    const data = this.props.store.reducer.dataWizardAsegurado;
    const tabMisDatos = W1_MIS_DATOS;
    const tabDireccion = W1_DIRECCION;
    const tabPago = W1_DATOS_PAGO;

    let tab = 1;
    let w1 = true;
    let w2 = true;
    let w3 = true;

    if (data !== null) {
      // Validacion wizzard 1
      tabMisDatos.forEach((t) => {
        if (data[t] === null || data[t] === undefined) w1 = false;
      });
      tabDireccion.forEach((t) => {
        if (data[t] === null || data[t] === undefined) w2 = false;
      });

      if (estructuraFormulario.formaPago) {
        tabPago.forEach((t) => {
          if (data[t] === null || data[t] === undefined) w3 = false;
        });
        if (data.formaPago) {
          if (data.formaPago.idFormaPago === FORMA_PAGO_SERVIPAG) w3 = true;
        }
      }
    }

    if (!estructuraFormulario.formaPago) {
      w1 && w2 ? (tab = VALOR_UNO) : w1 && (tab = VALOR_DOS);
    } else {
      w1 && w2
        ? (tab = VALOR_TRES)
        : w1
          ? (tab = VALOR_DOS)
          : w3 && (tab = VALOR_UNO);
    }
    if (
      (data.solicitud.tipoSolicitud != null &&
        data.solicitud.tipoSolicitud.idTipoSolicitud === VALOR_CINCO) ||
      this.props.store.reducer.data_asegurable.idTipoSolicitud === VALOR_CINCO
    ) {
      wizard.pagina = PAGINA_4;
    }

    this.props.wizard({
      pagina: wizard.pagina,
      type: TIPO_PAGINA,
    });

    this.props.wizard({
      tab: datosPersonales ? tab : wizard.tab,
      type: TIPO_TAB,
    });
  }

  validarAvanzarPaginaWizzard(item) {
    const { wizard } = this.props.store.reducer.progresoAsegurable;
    const datosPersonales = wizard[0].hecho;
    const asegurablesAdicionales = wizard[1].hecho;
    const declaraciones = wizard[2].hecho;
    const beneficiarios = wizard[3].hecho;

    if (!datosPersonales) return;
    if (!asegurablesAdicionales && item.pagina > PAGINA_2) return;
    if (!declaraciones && item.pagina > PAGINA_3) return;
    if (!beneficiarios && item.pagina > PAGINA_4) return;

    this.props.wizard({
      pagina: item.pagina,
      type: TIPO_PAGINA,
    });
    this.props.wizard({
      tab: 1,
      type: TIPO_TAB,
    });
  }

  async obtenerPreguntas() {
    const { informacionPoliza, data_asegurable } = this.props.store.reducer;

    const preguntas = [];

    if (this.state.tipoFlujo === FLUJO_VENTA_DIRECTA || this.state.tipoFlujo === FLUJO_VENTA_DIGITAL) {
      for (let cotizacion of data_asegurable.cotizaciones) {
        if (cotizacion) {
          for (let cobertura of cotizacion.coberturas) {
            let preguntasDeclaraciones =
              await this.obtenerPreguntasDeclaracionesPorSubProdCondicionadoYCodigoCotizacion(
                cobertura.cotizacionCobertura.idSubProdCondicionadoCobertura,
                cotizacion.codigoCotizacion
              );
            preguntas.push(...preguntasDeclaraciones);
          }
        }
      }
    } else if (this.state.tipoFlujo === FLUJO_MANTENCION) {
      for (let cobertura of informacionPoliza.coberturas) {
        let preguntasDeclaraciones =
          await this.obtenerPreguntasDeclaracionesPorSubProdCondicionadoYCodigoCotizacion(
            cobertura.idCobertura,
            data_asegurable.codigoCotizacion
          );
        preguntas.push(...preguntasDeclaraciones);
      }
    }
    this.props.getListaPreguntasCobertura(preguntas);
  }

  /*************************
   *  PETICIONES SERVIDOR  *
   *************************/
  async obtenerDatosCatalogo() {
    const promises = [];
    promises.push(getPrevisionSalud());
    promises.push(getGeneros());
    promises.push(getEstadoCivil());
    promises.push(getParentesco());
    promises.push(getRegiones());
    promises.push(getCiudades());
    promises.push(getComunas());
    promises.push(getFormasDePago());
    promises.push(getInstitucionesBancarias());
    promises.push(getTiposDeCuentas());
    promises.push(getParentescoBeneficiario());
    const [res1, res2, res3, res4, res5, res6, res7, res8, res9, res10, res11] =
      await Promise.all(promises);

    await this.props.guardar_previsiones_salud(res1);
    await this.props.get_generos(res2);
    await this.props.get_estado_civil(res3);
    await this.props.get_parentescos(res4);
    await this.props.get_regiones(res5);
    await this.props.get_ciudades(res6);
    await this.props.get_comunas(res7);
    await this.props.get_forma_pago(res8);
    await this.props.get_banco(res9);
    await this.props.get_tipo_cuenta(res10);
    await this.props.obtener_parentescos_beneficiarios(res11);
  }

  async obtenerDataWizard(datosAsegurado) {
    const { informacionPoliza } = this.props.store.reducer;
    let res = await obtenerDataWizard(datosAsegurado.idSolicitud);

    let dataAsegurado;
    if (res) {
      if (!res.cargas) res.cargas = [];

      if (
        informacionPoliza.asegurable_5_50 ||
        informacionPoliza.solicitudIncorporacion
      ) {
        res.rut = datosAsegurado.rut;
        res.digitoVerificador = datosAsegurado.digitoVerificador;
        res.codigoCotizacion = datosAsegurado.codigoCotizacion;
        res.idAsegurable = datosAsegurado.idAsegurable;
        res.idPersona = datosAsegurado.idPersona;
      }
      if (res.coberturas === null || res.coberturas === undefined) {
        const { informacionPoliza } = this.props.store.reducer;
        const { cotizaciones } = this.props.store.reducer.data_asegurable;
        res.coberturas = informacionPoliza.coberturas
          ? informacionPoliza.coberturas.map((objCoberturaPoliza) => {
            return {
              idProductoCobertura: objCoberturaPoliza.idProductoCobertura,
              capitalAsegurado: res.coberturas.find(
                (objCobertura) =>
                  objCobertura.idSubProducto === objCoberturaPoliza.subProducto.idSubProducto
              ).capitalAsegurado,
              idSubProdCondicionadoCobertura: objCoberturaPoliza.idCobertura,
              declaraciones: null,
              consentimiento:
                res.cobertura && res.cobertura[VALOR_ZERO].consentimiento
                  ? res.cobertura[VALOR_ZERO].consentimiento
                  : null,
            };
          })
          : cotizaciones && this.coberturasVentaDirecta(cotizaciones, res);
      }
      dataAsegurado = res;
      this.setState({ dataWizard: res });
    } else {

      if (
        informacionPoliza.asegurable_5_50 ||
        informacionPoliza.solicitudIncorporacion ||
        informacionPoliza.idTipoSolicitud === TIPO_SOLICITUD_DECLARACION_BENEFICIARIO
      ) {
        let coberturas = [];
        if (!informacionPoliza.asegurable_5_50) {
          coberturas = await this.obtenerCoberturas(informacionPoliza);
        }
        dataAsegurado = {
          rut: datosAsegurado.rut,
          digitoVerificador: datosAsegurado.digitoVerificador,
          nombre: datosAsegurado.nombre,
          apellidoPaterno: datosAsegurado.apellidoPaterno,
          apellidoMaterno: datosAsegurado.apellidoMaterno,
          codigoCotizacion: datosAsegurado.codigoCotizacion,
          idAsegurable: datosAsegurado.idAsegurable,
          idPersona: datosAsegurado.idPersona,
          cargas: [],
          solicitud: {
            idSolicitud: datosAsegurado.idSolicitud,
            declaroCarga: null,
            declaroDeportes: null,
            declaroEspecial: null,
            tipoSolicitud: {
              idTipoSolicitud: this.state.idTipoSolicitud,
            },
          },
          telefonoMovil: datosAsegurado.telefonoMovil,
          coberturas: informacionPoliza.coberturas
            ? informacionPoliza.coberturas.map((item) => {
              return {
                ...item,
                idProductoCobertura: item.idProductoCobertura,
                idSubProdCondicionadoCobertura: item.idCobertura,
                consentimiento: item.consentimiento
                  ? item.consentimiento
                  : null,
                tipoPoliza: item.tipoPoliza,
                capitalAsegurado: coberturas
                  ? coberturas.find(
                    (cob) => cob.idCobertura === item.idCobertura
                  )
                    ? coberturas.find(
                      (cob) => cob.idCobertura === item.idCobertura
                    ).capital
                    : null
                  : null,
              };
            })
            : null,
        };
      } else {
        dataAsegurado = {
          ...this.props.store.reducer.dataWizardAsegurado,
          solicitud: {
            ...this.props.store.reducer.dataWizardAsegurado.solicitud,
            idSolicitud: datosAsegurado.idSolicitud,
            tipoSolicitud: {
              idTipoSolicitud: this.state.idTipoSolicitud,
            },
          },
        };
      }
    }

    const documentoCargas = await obtenerArchivosComponente(
      {
        key: "idAsegurable",
        value: this.props.store.reducer.data_asegurable.idAsegurable,
      },
      DOCUMENT_TYPE_TEMPORAL
    );

    setearDocumentosCargas(dataAsegurado, documentoCargas);

    if (this.state.idTipoSolicitud) {
      dataAsegurado = {
        ...dataAsegurado,
        solicitud: {
          ...dataAsegurado.solicitud,
          tipoSolicitud: {
            idTipoSolicitud: this.state.idTipoSolicitud,
          },
        },
      };
    }

    if (
      dataAsegurado.coberturas &&
      dataAsegurado.coberturas.find((cob) => cob.consentimiento)
    ) {
      const consentimiento = dataAsegurado.coberturas
        ? dataAsegurado.coberturas.find((cob) => cob.consentimiento)
          ? dataAsegurado.coberturas.find((cob) => cob.consentimiento)
            .consentimiento
          : null
        : null;

      dataAsegurado = {
        ...dataAsegurado,
        consentimiento: consentimiento,
      };
    } else if (
      datosAsegurado.canalVenta.idCanalVenta === TIPO_CANAL_VENTA_DIRECTA
    ) {
      dataAsegurado.coberturas = this.coberturasVentaDirecta(
        datosAsegurado.cotizaciones,
        dataAsegurado
      );
    }

    await this.props.guardar_data_wizard_asegurado(dataAsegurado);
  }

  async obtenerCargasIncorporacionCarga() {
    const { solicitud, rut, digitoVerificador } =
      this.props.store.reducer.dataWizardAsegurado;

    //Si es incorporación carga entonces buscar las cargas vigentes de polizas anteriores.
    if (
      solicitud.tipoSolicitud &&
      solicitud.tipoSolicitud.idTipoSolicitud === ID_CUATRO
    ) {
      const rutTitular = rut + "-" + digitoVerificador;
      const cargasTitularIncorporacionCarga =
        await listarCargasPorRutAsegurable(rutTitular);
      await this.props.guardar_data_wizard_asegurado({
        ...this.props.store.reducer.dataWizardAsegurado,
        cargasTitularIncorporacionCarga,
      });
    }
  }

  render() {
    if (
      this.props.store.reducer.dataWizardAsegurado &&
      this.props.store.reducer.dataWizardAsegurado.solicitud
    )
      this.actualizarProgreso();

    return this.state.cargando ? (
      <Spinner
        claseColor="naranjo"
        idSpinner="spinnerNaranjo"
        posicionLogo="align-self-center w-100"
      />
    ) : (
      <div
        style={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
        }}
        className="animated fadeIn"
      >
        <CabeceraNegocioAsegurado />

        {this.state.solicitud === 1 ? (
          <React.Fragment>
            {this.props.store.reducer.data_asegurable.idEstadoSolicitud ===
              SOLICITUD_FINALIZADA_POR_ASEGURABLE ? (
              <Redirect to="/asegurable/solicitud-finalizada-por-asegurable" />
            ) : this.props.store.reducer.data_asegurable.fechaRecepcion ? (
              <Redirect to="/asegurable/solicitud" />
            ) : this.state.solicitudCerrada === true ? (
              <Redirect to="/asegurable/solicitud-cerrada" />
            ) : this.state.terminos === true ? (
              <Redirect to="/asegurable/aceptar-terminos" />
            ) : this.state.seleccionarCoberturas === true ? (
              <Redirect to="/asegurable/cobertura-asegurable" />
            ) : (
              <Redirect to="/asegurable" />
            )}

            {this.state.cargando ? (
              <Spinner
                claseColor="transparente"
                idSpinner="spinnerTransparente"
                posicionLogo="posicionLogo align-self-center w-100"
              />
            ) : (
              <div className="container">
                <div className="row">
                  <div className="col-12 col-lg-3">
                    <ul className="pasos">
                      {this.state.menu &&
                        this.state.menu.length > 0 &&
                        this.state.menu
                          .filter((item) => {
                            if (
                              this.props.store.reducer.data_asegurable
                                .idTipoSolicitud === VALOR_CINCO
                            ) {
                              if (
                                item.pagina === PAGINA_1 ||
                                item.pagina === PAGINA_2 ||
                                item.pagina === PAGINA_3
                              ) {
                                return (item.visible = false);
                              } else {
                                return (item.visible = true);
                              }
                            } else return item.visible;
                          })
                          .map((item, i) => {
                            const indexGlosa =
                              this.props.store.reducer.progresoAsegurable.wizard.findIndex(
                                (elemento) => {
                                  return (
                                    elemento.glosa.toLowerCase() ===
                                    item.glosa
                                      .replace(/\s/g, "")
                                      .toLowerCase() ||
                                    elemento.glosa.toLowerCase() ===
                                    BENEFICIARIOS_MENU.toLowerCase()
                                  );
                                }
                              );
                            return (
                              <li
                                id={`avanzarPagina${i}`}
                                className={
                                  this.props.store.reducer.progresoAsegurable
                                    .wizard[indexGlosa].estado
                                }
                                key={`step_${i}`}
                                onClick={() => {
                                  this.validarAvanzarPaginaWizzard(item);
                                }}
                              >
                                <span className="d-none d-sm-block">
                                  {item.glosa}
                                </span>
                                <span className="d-block d-sm-none">
                                  <FaUser />
                                </span>
                                <div className="numero">{i + 1}</div>
                              </li>
                            );
                          })}
                    </ul>
                  </div>
                  <div className="col-12 col-lg-9">
                    {this.props.store.reducer.wizard.pagina === PAGINA_1 && (
                      <DatosPersonales
                        siguientePagina={this.siguientePagina}
                      ></DatosPersonales>
                    )}
                    {this.props.store.reducer.wizard.pagina === PAGINA_2 &&
                      this.props.store.reducer.estructuraFormulario.cargas && (
                        <Cargas
                          siguientePagina={this.siguientePagina}
                          anteriorPagina={this.anteriorPagina}
                        ></Cargas>
                      )}
                    {this.props.store.reducer.wizard.pagina === PAGINA_3 && (
                      <Declaraciones
                        anteriorPagina={this.anteriorPagina}
                      ></Declaraciones>
                    )}
                    {this.props.store.reducer.wizard.pagina === PAGINA_4 &&
                      this.props.store.reducer.estructuraFormulario
                        .beneficiarios && (
                        <Beneficiarios
                          anteriorPagina={this.anteriorPagina}
                        ></Beneficiarios>
                      )}
                    {this.props.store.reducer.wizard.pagina === PAGINA_5 && (
                      <Resumen anteriorPagina={this.anteriorPagina}></Resumen>
                    )}
                  </div>
                </div>
              </div>
            )}
          </React.Fragment>
        ) : (
          <span>Ya te enrolaste</span>
        )}
        <Footer />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  store: state,
});

export default withRouter(
  connect(mapStateToProps, {
    guardar_data_asegurable,
    guardar_data_wizard_asegurado,
    guardar_previsiones_salud,
    get_ciudades,
    get_comunas,
    get_estado_civil,
    get_parentescos,
    obtener_parentescos_beneficiarios,
    get_generos,
    get_regiones,
    get_forma_pago,
    get_banco,
    get_tipo_cuenta,
    update_id_solicitud,
    wizard,
    getListaDeclaraciones,
    getListaPreguntasCobertura,
    add_declaracion,
    actualizar_progreso_asegurable,
    update_carga,
    update_detalle,
    obtener_info_poliza,
    guardar_estructura_formulario,
    guardar_catalogo_consentimiento,
  })(Asegurable)
);
