import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  componente_wizard_ejecutivo,
  guardar_datos_delegado,
  get_generos,
} from "../../../../../../actions/actions";
import { getGeneros } from "../../../../../../services/asegurable.services";
import { formatTel, soloLetras } from "../../../../../../utils/Functions";
import {
  CAMPO_REQUERIDO,
  VALIDACION_RUT,
  VALIDACION_NOMBRE,
  VALIDACION_APELLIDO_P,
  VALIDACION_APELLIDO_M,
  VALIDACION_MAIL,
  VALIDACION_TELEFONO,
  VALIDACION_SEXO,
  VALIDACION_FECHA,
  ERROR_ACTUALIZAR_DELEGADO,
  ERROR_OBTENER_PERSONA_DELEGADO,
  EXITO_ACTUALIZAR_DELEGADO,
  VALOR_UNO,
  VALOR_DOS,
  PERFIL_LDAP,
  VALOR_ZERO,
  CAMPO_REQUERIDO_FECHA,
  MINIMA_EDAD,
  MASK_DATE,
  INVALID_DATE,
  TYPEOF_STRING,
  CAMPO_REQUERIDO_TELEFONO,
  VALIDACION_APELLIDO_P_MAXIMO,
  VALIDACION_APELLIDO_M_MAXIMO,
  VALIDACION_NOMBRE_MAXIMO,
} from "../../../../../../utils/Constantes";
import {
  actualizarConfiguracionDelegado,
  actualizarConfiguracionSucursal,
  obtenerPersonaPorRut,
  obtenerTodasLasJefaturas,
  obtenerTodosLosEjecutivos,
} from "../../../../../../services/negocios.service";
import { notificacion } from "../../../../transversales/Notificaciones";
import DatePicker from "react-datepicker";
import MaskedTextInput from "react-text-mask";

import "moment/locale/es";
import moment from "moment";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Spinner from "../../../../transversales/Spinner";

const { validate, format, clean } = require("rut.js");

class DatosDelegado extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cargando: false,
      loadingData: false,
      finalizando: false,
      delegadoConfigurado: true,
      disabledForm: false,
      negocio: this.props.store.reducer.negocio,
      idSucursal: null,
      fechaNacimiento: "",
      fechaValida: true,
      datosDelegado: null,
      ejecutivos: [],
      jefaturas: [],
      delegadoValido: true,
      catalogo: {
        generos: null,
      },
    };
  }

  async componentWillMount() {
    this.obtenerDatosDelegadoConfigurado();

    await this.obtenerJefaturasEjecutivos();
    await this.obtenerGeneros();
    this.setState({ cargando: false });

    // Validar tipo canal venta negocio
    const negocio_ = this.props.store.reducer.negocio;
    if (
      negocio_.canalVenta.idCanalVenta !== VALOR_UNO ||
      negocio_.estadoNegocio.idEstadoNegocio > VALOR_DOS
    ) {
      this.setState({ disabledForm: true });
    }
    this.validaDelegado(this.state.datosDelegado.rut, null, null);
  }

  /*************************
   *  PETICIONES SERVIDOR  *
   *************************/
  async obtenerJefaturasEjecutivos() {
    const ejecutivos = await obtenerTodosLosEjecutivos();
    const jefaturas = await obtenerTodasLasJefaturas();
    this.setState({ ejecutivos, jefaturas });
  }

  async enviarDatosDelegado(values) {
    if (!this.state.delegadoValido) return;
    const dataEnvio = {
      email: values.email,
      idOrganizacionSucursal: this.state.datosDelegado.idOrganizacionSucursal, //(ID que arrojo al crear la sucursal)
      idOrganizacionSucursalContacto:
        this.state.datosDelegado.idOrganizacionSucursalContacto, //(Si es un delegado nuevo el ID es 0, de lo contrario el id correspondiente)
      persona: {
        apellidoMaterno: values.apellidoMaterno,
        apellidoPaterno: values.apellidoPaterno,
        correoElectronico: values.email,
        fechaNacimiento: this.formatearFecha(values.fechaNacimiento),
        idUsuCodigoMod: this.props.store.auth.usuarioSesion.id, //(es el "id" que trae el iniciarSesion)
        identificador: format(values.rut).replace(/[.]/g, ""), // .replace(/./g, '') (Rut del delegado)
        nombre: values.nombre,
        genero: values.sexo === "1" ? "M" : "F", //("F" si es mujer, de lo contrario "M")
      },
      telefono: values.telefono,
    };

    // Validar envio, con tipo canal venta y estado negocio negocio
    const negocio_ = this.props.store.reducer.negocio;
    if (
      negocio_.canalVenta.idCanalVenta === VALOR_UNO &&
      negocio_.estadoNegocio.idEstadoNegocio <= VALOR_DOS
    ) {
      const actualizarDelegado = await actualizarConfiguracionDelegado(
        dataEnvio
      );

      this.guardarDelegadoReducer(values);

      this.setState({ finalizando: false });

      if (!actualizarDelegado || actualizarDelegado === VALOR_ZERO) {
        this.setState({ loadingData: false });
        return notificacion("danger", ERROR_ACTUALIZAR_DELEGADO);
      } else {
        if (!actualizarDelegado || actualizarDelegado === VALOR_ZERO) {
          this.setState({ loadingData: false });
          return notificacion("danger", ERROR_ACTUALIZAR_DELEGADO);
        } else {
          let delegado = await obtenerPersonaPorRut(
            dataEnvio.persona.identificador,
            PERFIL_LDAP
          );

          if (delegado === null) {
            return notificacion("danger", ERROR_OBTENER_PERSONA_DELEGADO);
          }

          let datosDelegado = Object.assign({}, this.state.datosDelegado);
          datosDelegado.idPersonaDelegado = delegado.idPersona;
          this.setState({ datosDelegado });

          const dataEnvioSucursal = {
            sucursales: [
              {
                idPersonaDelegado: this.state.datosDelegado.idPersonaDelegado,
                idOrganizacionSucursal:
                  this.state.datosDelegado.idOrganizacionSucursal,
                idSucursal: this.state.idSucursal,
              },
            ],
          };

          await actualizarConfiguracionSucursal(dataEnvioSucursal);
          notificacion("success", EXITO_ACTUALIZAR_DELEGADO);
          this.setState({ finalizando: false });
          this.props.volver();
          return;
        }
      }
    }
  }

  async obtenerGeneros() {
    if (this.props.store.reducer.generos.length < 1) {
      const generos = await getGeneros();
      const catalogo = { generos: generos };
      this.setState({ catalogo });
      this.props.get_generos(generos);
    } else {
      const catalogo = { generos: this.props.store.reducer.generos };
      this.setState({ catalogo });
    }
  }

  guardarDelegadoReducer(data) {
    let delegado =
      this.props.store.reducer.ejecutivo.configuracionNegocio.datosDelegado;

    const dataDelegado = {
      ...delegado,
      nombre: data.nombre,
      apellidoMaterno: data.apellidoMaterno,
      apellidoPaterno: data.apellidoPaterno,
      fechaNacimiento: new Date(this.state.fechaNacimiento).getTime(),
      sexo: data.sexo === "1" ? "M" : "F",
      telefono: data.telefono,
      email: data.email,
    };

    this.props.guardar_datos_delegado(dataDelegado);
  }

  /*************************
   * * * VALIDACIONES      *
   *************************/
  obtenerDatosDelegadoConfigurado() {
    if (this.props.delegado !== null && this.props.sucursal !== null) {
      let datosDelegado = {
        idPersonaDelegado: this.props.delegado.idPersonaDelegado,
        idOrganizacionSucursal: this.props.sucursal.idOrganizacionSucursal,
        idOrganizacionSucursalContacto:
          this.props.delegado.idOrganizacionSucursalContacto,
        rut: this.props.delegado.rut,
        nombre: this.props.delegado.nombre,
        apellidoMaterno: this.props.delegado.apellidoMaterno,
        apellidoPaterno: this.props.delegado.apellidoPaterno,
        sexo: this.props.delegado.sexo
          ? this.props.delegado.sexo === "M"
            ? VALOR_DOS
            : VALOR_UNO
          : VALOR_ZERO,
        fechaNacimiento: this.props.delegado.fechaNacimiento,
        telefono: this.props.delegado.telefono,
        email: this.props.delegado.email,
      };
      this.setState({
        datosDelegado,
        fechaNacimiento: this.props.delegado.fechaNacimiento
          ? new Date(this.props.delegado.fechaNacimiento)
          : null,
        idSucursal: this.props.sucursal.idSucursal,
      });

      this.validaDelegado(this.props.delegado.rut);
    } else {
      this.setState({
        delegadoConfigurado: false,
        idSucursal: this.props.sucursal.idSucursal,
      });
    }
  }

  validaDelegado(rut, setFieldValue, errors) {
    rut = clean(rut);
    if (setFieldValue) setFieldValue("rut", rut);
    if (validate(rut)) {
      const delegadoValido = this.state.ejecutivos
        .concat(this.state.jefaturas)
        .find((e) => `${e.rut}${e.digitoVerificador}` === rut);

      this.setState({ delegadoValido: delegadoValido ? false : true });
    } else this.setState({ delegadoValido: true });
  }

  formatearFecha = (fecha) => {
    let fechaNacimiento;
    if (typeof fecha === TYPEOF_STRING) {
      if (fecha.includes("/")) {
        let fNac = fecha.split("/");
        fechaNacimiento = `${fNac[2]}-${fNac[1]}-${fNac[0]}`;
      } else {
        let fNac = fecha.split("-");
        fechaNacimiento = ` ${fNac[0]}-${fNac[1]}-${fNac[2]}`;
      }
    } else {
      fechaNacimiento = moment(fecha).format("YYYY-MM-DD");
    }
    return fechaNacimiento;
  };

  fechaMayoriaEdad() {
    let fecha = new Date();
    return new Date(
      fecha.getFullYear() -
        18 +
        "/" +
        (fecha.getMonth() + 1) +
        "/" +
        fecha.getDate()
    );
  }

  fechaMinima = () => {
    let fecha = new Date();
    return new Date(
      fecha.getFullYear() -
        MINIMA_EDAD +
        "/" +
        (fecha.getMonth() + VALOR_UNO) +
        "/" +
        fecha.getDate()
    );
  };

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  render() {
    return (
      <React.Fragment>
        {!this.state.cargando ? (
          <Formik
            initialValues={{
              rut: this.state.datosDelegado.rut,
              nombre: this.state.datosDelegado.nombre,
              apellidoPaterno: this.state.datosDelegado.apellidoPaterno,
              apellidoMaterno: this.state.datosDelegado.apellidoMaterno,
              sexo: this.state.datosDelegado.sexo,
              fechaNacimiento: moment(
                this.state.datosDelegado.fechaNacimiento
              ).format("DD/MM/YYYY"),
              telefono: this.state.datosDelegado.telefono,
              email: this.state.datosDelegado.email,
              delegado: false,
            }}
            validationSchema={Yup.object().shape({
              rut: Yup.string().test(
                "validarRut",
                VALIDACION_RUT,
                function (value) {
                  return validate(format(value));
                }
              ),
              nombre: Yup.string()
                .min(1, VALIDACION_NOMBRE)
                .required(CAMPO_REQUERIDO)
                .max(25, VALIDACION_NOMBRE_MAXIMO),
              apellidoMaterno: Yup.string()
                .min(1, VALIDACION_APELLIDO_M)
                .max(25, VALIDACION_APELLIDO_M_MAXIMO),
              apellidoPaterno: Yup.string()
                .min(1, VALIDACION_APELLIDO_P)
                .required(CAMPO_REQUERIDO)
                .max(25, VALIDACION_APELLIDO_P_MAXIMO),

              sexo: Yup.string()
                .required(CAMPO_REQUERIDO)
                .test("validaSexo", VALIDACION_SEXO, (value) => {
                  return Number(value) !== 0 ? true : false;
                }),
              fechaNacimiento: Yup.string()
                .required(CAMPO_REQUERIDO_FECHA)
                .test("validaFecha", VALIDACION_FECHA, (value) => {
                  return value !== INVALID_DATE ? true : false;
                }),
              telefono: Yup.string()
                .required(CAMPO_REQUERIDO_TELEFONO)
                .test("validaTelefono", VALIDACION_TELEFONO, (value) => {
                  return formatTel(value).length === 13 ||
                    formatTel(value).length === 3
                    ? true
                    : false;
                }),
              email: Yup.string()
                .email(VALIDACION_MAIL)
                .required(CAMPO_REQUERIDO),
            })}
            onSubmit={async (values, { setSubmitting }) => {
              if (this.state.negocio.canalVenta.idCanalVenta > VALOR_UNO) {
                this.setState({ finalizando: true });
                this.props.volver();
                return;
              }

              this.setState({ loadingData: true });
              await this.enviarDatosDelegado(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              setFieldValue,
              handleChange,
              handleBlur,
              setFieldTouched,
              handleSubmit,
              isSubmitting,
              validating,
              valid,
            }) => {
              return (
                <Form
                  className="container animated fadeInRight"
                  id="formDatosDelegado"
                  onKeyDown={(e) => {
                    if ((e.charCode || e.keyCode) === 13) {
                      e.preventDefault();
                    }
                  }}
                >
                  <div className="row mx-3">
                    {/* rut */}
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>
                          RUT <span className="text-danger">*</span>
                        </label>
                        <Field
                          className="form-control"
                          id="rut"
                          name="rut"
                          type="text"
                          placeholder="ej. 12345678-9"
                          maxLength="12"
                          minLength="11"
                          disabled={this.state.disabledForm}
                          value={
                            values.rut.length > 1
                              ? format(values.rut)
                              : values.rut
                          }
                          onBlur={() => {
                            this.validaDelegado(
                              values.rut,
                              setFieldValue,
                              valid
                            );
                          }}
                        />
                        {errors.rut && touched.rut && (
                          <small className="animated fadeIn text-danger">
                            {errors.rut}
                          </small>
                        )}
                        {!this.state.delegadoValido && (
                          <small className="animated fadeIn text-danger">
                            Ejecutivo o jefatura no puede ser configurado como
                            delegado
                          </small>
                        )}
                      </div>
                    </div>

                    {/* nombre */}
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>
                          Nombre <span className="text-danger">*</span>
                        </label>
                        <Field
                          className="form-control"
                          id="nombre"
                          name="nombre"
                          type="text"
                          placeholder="Ingrese su nombre completo"
                          disabled={this.state.disabledForm}
                          value={soloLetras(values.nombre)}
                        />
                        {errors.nombre && touched.nombre && (
                          <small className="text-danger animated fadeIn animated fadeIn">
                            {errors.nombre}
                          </small>
                        )}
                      </div>
                    </div>

                    {/* apellidoPaterno */}
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Apellido paterno{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <Field
                          className="form-control"
                          id="apellidoPaterno"
                          name="apellidoPaterno"
                          type="text"
                          placeholder="Ingrese su apellido paterno"
                          disabled={this.state.disabledForm}
                          value={soloLetras(values.apellidoPaterno)}
                        />
                        {errors.apellidoPaterno && touched.apellidoPaterno && (
                          <small className="text-danger animated fadeIn animated fadeIn">
                            {errors.apellidoPaterno}
                          </small>
                        )}
                      </div>
                    </div>

                    {/* apellido materno */}
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Apellido materno</label>
                        <Field
                          className="form-control"
                          id="apellidoMaterno"
                          name="apellidoMaterno"
                          type="text"
                          placeholder="Ingrese su apellido materno"
                          disabled={this.state.disabledForm}
                          value={soloLetras(values.apellidoMaterno)}
                        />
                        {errors.apellidoMaterno && touched.apellidoMaterno && (
                          <small className="text-danger animated fadeIn animated fadeIn">
                            {errors.apellidoMaterno}
                          </small>
                        )}
                      </div>
                    </div>

                    {/* sexo */}
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Género <span className="text-danger">*</span>
                        </label>
                        <select
                          id="sexo"
                          name="sexo"
                          className="form-control"
                          style={{ display: "block" }}
                          disabled={this.state.disabledForm}
                          value={values.sexo}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option value="0"> Seleccione género</option>
                          {this.state.catalogo.generos &&
                            this.state.catalogo.generos.map((item, i) => (
                              <option
                                key={`opcion_parentesco_${i}`}
                                value={item.idGenero}
                              >
                                {item.descripcion}
                              </option>
                            ))}
                        </select>
                        {errors.sexo && touched.sexo && (
                          <small className="text-danger animated fadeIn animated fadeIn">
                            {errors.sexo}
                          </small>
                        )}
                      </div>
                    </div>

                    {/* fecha nacimiento */}
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Fecha de nacimiento{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <DatePicker
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          id="fechaNacimiento"
                          autoComplete="off"
                          name="fechaNacimiento"
                          disabled={this.state.disabledForm}
                          value={values.fechaNacimiento}
                          selected={null}
                          dateFormat="dd/MM/yyyy"
                          placeholderText="DD/MM/YYYY"
                          locale="es"
                          onChange={(value) => {
                            value = moment(value).format("DD/MM/YYYY");
                            setFieldTouched("fechaNacimiento", true);
                            setFieldValue("fechaNacimiento", value);
                          }}
                          minDate={this.fechaMinima()}
                          maxDate={this.fechaMayoriaEdad()}
                          customInput={
                            <MaskedTextInput
                              className="form-control"
                              placeholder="DD/MM/YYYY"
                              mask={MASK_DATE}
                            />
                          }
                        />
                        {errors.fechaNacimiento && touched.fechaNacimiento && (
                          <small className="text-danger animated fadeIn">
                            {errors.fechaNacimiento}
                          </small>
                        )}
                      </div>
                    </div>

                    {/* teléfono */}
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Teléfono <span className="text-danger">*</span>
                        </label>
                        <Field
                          id="telefono"
                          name="telefono"
                          className="form-control"
                          maxLength="13"
                          placeholder="Ingrese teléfono"
                          disabled={this.state.disabledForm}
                          value={formatTel(values.telefono)}
                        />
                        {errors.telefono && touched.telefono && (
                          <small className="text-danger animated fadeIn">
                            {errors.telefono}
                          </small>
                        )}
                      </div>
                    </div>

                    {/* email */}
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Email <span className="text-danger">*</span>
                        </label>
                        <Field
                          className="form-control"
                          id="email"
                          name="email"
                          type="text"
                          placeholder="Ingrese email ej: test@gmail.com"
                          maxLength="50"
                          disabled={this.state.disabledForm}
                          value={values.email}
                        />
                        {errors.email && touched.email && (
                          <small className="text-danger animated fadeIn">
                            {errors.email}
                          </small>
                        )}
                      </div>
                    </div>

                    {/* tipo delegado */}
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Tipo delegado</label>
                        <div className="d-block">
                          <label className="switch mt-1">
                            <input
                              disabled
                              defaultChecked={true}
                              type="checkbox"
                            />
                            <span className="slider round" />
                          </label>
                          <label className="ml-2">Delegado base</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Botonera */}
                  <div className="container button-zone text-right pt-3 pb-3 animated fadeIn">
                    <button
                      className="btn btn-principal color-secundario"
                      type="button"
                      onClick={() =>
                        this.props.wizardChange(this.props.wizard - 1)
                      }
                    >
                      Atrás
                    </button>

                    <button
                      className="btn btn-principal"
                      type="submit"
                      disabled={
                        this.state.finalizando || this.state.loadingData
                      }
                      id="botonFinalizar"
                    >
                      {this.state.loadingData ? (
                        <Fragment>
                          <i className="fa fa-spinner fa-pulse fa-fw"></i>
                          <span className="sr-only">Loading...</span>
                        </Fragment>
                      ) : (
                        <span>Finalizar</span>
                      )}
                    </button>
                    {this.state.finalizando && (
                      <Spinner
                        claseColor="transparente"
                        idSpinner="spinnerTransparente"
                        posicionLogo="posicionLogo align-self-center w-100"
                      />
                    )}
                  </div>
                </Form>
              );
            }}
          </Formik>
        ) : (
          <div className="d-flex justify-content-center animated fadeIn my-5">
            <div className="spinner-border text-primary mb-5" role="status">
              <span className="sr-only">Cargando...</span>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state,
});

export default withRouter(
  connect(mapStateToProps, {
    componente_wizard_ejecutivo,
    guardar_datos_delegado,
    get_generos,
  })(DatosDelegado)
);
