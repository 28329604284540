import ServicesFactory from "../config/servicesFactory";

let services = new ServicesFactory();

export async function asignarPermisoDelegadoMantencion(data) {
    try {
        let url = `${process.env.REACT_APP_API_GATEWAY_SERVPERFIL}/perfil/agregar`;
        let res = await services.post(url, data, false);
        return res !== undefined &&
            res !== null
            ? res.data.objeto
            : null;
    } catch (error) {
        return null;
    }
}

export async function obtenerDelegadoPorRut(rut) {
    try {
        let url = `${process.env.REACT_APP_BUS_URL_PERSONA}/buscarPorCodPerfil/${rut}/75`;
        let res = await services.get(url, false);
        return res !== undefined && res !== null
            ? res.data.objeto
            : null;
    } catch (error) {
        return null;
    }
}
