import { COD_ASEGURADO, 
	COD_CORREDOR, 
	COD_DELEGADO, 
	COD_EJECUTIVO, 
	COD_EJECUTIVO_COMPLETO, 
	COD_EJECUTIVO_MANTENCION, 
	COD_JEFATURA,
	GA_CATEGORIA_LOGIN,
	GA_EVENTO,
	GA_PERFIL_ASEGURADO, 
	GA_PERFIL_CORREDOR, 
	GA_PERFIL_DELEGADO, 
	GA_PERFIL_EJECUTIVO, 
	GA_PERFIL_EJECUTIVO_COMPLETO, 
	GA_PERFIL_EJECUTIVO_MANTO, 
	GA_PERFIL_JEFATURA,
	GA_TIPO_PAGINA,
	GA_TIPO_TRAZA
} from "../utils/Constantes";

export const crearTrazaPagina = (objLocacion) => {
	window.gtag(GA_EVENTO, GA_TIPO_PAGINA, {
		url: objLocacion.pathname,
		ruta: objLocacion.hash,
		parametros: objLocacion.search
	});
}

export const crearEvento = (strCategoria, strAccion, strEtiqueta) => {

	window.gtag(GA_EVENTO, strAccion, {
		categoria: strCategoria,
		pagina: strEtiqueta
	});
}

export const crearEventoTraza = (strCategoria, strPagina) => {

	window.gtag(GA_EVENTO, GA_TIPO_TRAZA, {
		categoria: strCategoria,
		pagina: strPagina
	});
}

export const crearAnaliticaPorPerfil = (intCodigoRol) => {

	switch (intCodigoRol) {

		case COD_ASEGURADO:
			crearEventoTraza(GA_CATEGORIA_LOGIN, GA_PERFIL_ASEGURADO);
			break;

		case COD_DELEGADO:
			crearEventoTraza(GA_CATEGORIA_LOGIN, GA_PERFIL_DELEGADO);
			break;

		case COD_CORREDOR:
			crearEventoTraza(GA_CATEGORIA_LOGIN, GA_PERFIL_CORREDOR);
			break;

		case COD_JEFATURA:
			crearEventoTraza(GA_CATEGORIA_LOGIN, GA_PERFIL_JEFATURA);
			break;

		case COD_EJECUTIVO:
			crearEventoTraza(GA_CATEGORIA_LOGIN, GA_PERFIL_EJECUTIVO);
			break;

		case COD_EJECUTIVO_MANTENCION:
			crearEventoTraza(GA_CATEGORIA_LOGIN, GA_PERFIL_EJECUTIVO_MANTO);
			break;

		case COD_EJECUTIVO_COMPLETO:
			crearEventoTraza(GA_CATEGORIA_LOGIN, GA_PERFIL_EJECUTIVO_COMPLETO);
			break;
		default:
			break;
	}
}