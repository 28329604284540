import ServicesFactory from "../config/servicesFactory";
import {
  COD_STATUS_EXITO,
  STATUS_EXITO,
} from "../utils/Constantes";

let services = new ServicesFactory();


/*************************
 *  ASEGURADO  *
 *************************/
export async function buscarDatosPersonalesYPoliza(data, perfil) {
  try {
    let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/asegurado/listarPorRut/perfil/${perfil}`;    
    let respuesta = await services.post(url, data,false);
    return respuesta !== undefined && respuesta !== null ? respuesta.data : null;
  } catch (error) {
    return null;
  }
}

export async function crearSolicitud(data, perfil) {
  try {
    let respuesta = await services.post(`/solicitud/crear/perfil/${perfil}`, data);
    return respuesta !== undefined && respuesta !== null ? respuesta.data : null;
  } catch (error) {
    return null;
  }
}

export async function listarAseguradoPolizaExclusion(data, perfil) {
  try {
    let respuesta = await services.post(`/solicitud/listar/perfil/${perfil}`, data);
    return respuesta !== undefined && respuesta !== null ? respuesta.data : null;
  } catch (error) {
    return null;
  }
}
export async function getTiposAsegurados() {
  try {
    let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/catalogo/listarTipoAsegurado`;
    let res = await services.get(url, false);
    return res !== undefined &&
    res !== null &&
    res.status === STATUS_EXITO &&
    res.data.status.codigo === COD_STATUS_EXITO
        ? res.data.objeto
        : [];
  } catch (error) {
    return [];
  }
}

