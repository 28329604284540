import { useState } from "react";
import { FALSO, STORAGE_ITEM_TIPO_INCORPORACION, VALOR_ZERO } from "../utils/Constantes";
import { useDispatch, useSelector } from "react-redux";
import { guardarTipoIncorporacion, guardarTipoIncorporacionPrevio } from "../actions/actionsAuth";
import { useEffect } from "react";
import { useAutenticacion } from "./useAutenticacion";
import { guardarEnNavegador } from "../utils/almacenamientoNavegador";

export const useIncorporacion = () => {

	const objGuardarEstado					 = useDispatch();
	const strAlmacenamiento     		 = useSelector(store => store.reducer.tipoAlmacenamiento);
	const intTipoIncorporacion	  	 = useSelector(store => store.auth.tipoIncorporacion);
	const intTipoIncorporacionPrevio = useSelector(store => store.auth.tipoIncorporacionPrevio);
	const { actualizarPerfilSesion } = useAutenticacion();

	const [ blnMostrarModal, actualizarMostrarModal] = useState(FALSO);							   
	
	const guardarSeleccionModal = async ( intTipoIncorporacion ) => {
		
		objGuardarEstado(guardarTipoIncorporacion(intTipoIncorporacion));
		actualizarMostrarModal(FALSO);
	}

	const guardarIncorporacion = ( intTipoIncorporacion ) => {
		
		objGuardarEstado(guardarTipoIncorporacion(intTipoIncorporacion));
	}

	useEffect( () => {

		if (intTipoIncorporacionPrevio !== intTipoIncorporacion &&
				intTipoIncorporacion > VALOR_ZERO) {
			objGuardarEstado(guardarTipoIncorporacionPrevio(intTipoIncorporacion));
			actualizarPerfilSesion(intTipoIncorporacion);
			guardarEnNavegador(STORAGE_ITEM_TIPO_INCORPORACION, intTipoIncorporacion, strAlmacenamiento);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[intTipoIncorporacion]);

	return {
    mostrarModal: blnMostrarModal,
    guardarSeleccionModal,
		guardarIncorporacion,
		actualizarMostrarModal
  }
}