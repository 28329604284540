import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { notificacion } from "../../transversales/Notificaciones";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from "react-day-picker/moment";
import "moment/locale/es";
import moment from "moment";
import { actualizarNegocio } from "../../../../services/negocios.service";
import fileImg from "../../../assets/images/file.svg";
import {
  fecha_termino_enrolamiento,
  guardar_negocios,
  guardar_negocio,
} from "../../../../actions/actions";
import {
  PERFIL_LDAP,
  FECHA_ENROLAMIENTO_ACTUALIZADA,
  FECHA_ENROLAMIENTO_ERROR,
  VALOR_ZERO,
  VALOR_DIA,
} from "../../../../utils/Constantes";

class ModalFechaEnrolamiento extends Component {
  constructor(props) {
    super(props);
    const { negocio } = this.props.store.reducer;
    this.state = {
      negocio: negocio,
      fechaValida: true,
      fechaTerminoEnrolamientoActual: negocio.fechaTerminoEnrolamiento,
      fechaTerminoEnrolamientoNueva: negocio.fechaTerminoEnrolamiento
        ? moment(negocio.fechaTerminoEnrolamiento).format("DD-MM-YYYY")
        : "",
      fechaTerminoEnrolamientoNuevaSinFormato: negocio.fechaTerminoEnrolamiento
        ? negocio.fechaTerminoEnrolamiento
        : "",
      fechaMinima: negocio.fechaTerminoEnrolamiento
        ? negocio.fechaTerminoEnrolamiento
        : new Date(Date.now() + VALOR_DIA),
      dataEnvio: {
        fechaTerminoEnrolamiento: new Date(negocio.fechaTerminoEnrolamiento),
        idNegocio: negocio.idNegocio,
        perfil: PERFIL_LDAP,
      },
      loading: false
    };
  }

  obtenerFecha(fecha) {
    const fechaSeleccionada = new Date(
      moment(fecha).format("YYYY/MM/DD")
    );
    const fechaMinimaTemporal = new Date(
      moment(this.state.fechaMinima).format("YYYY/MM/DD")
    );
    if (
      typeof fecha === "undefined" || fecha === null ||
      fechaSeleccionada < fechaMinimaTemporal
    ) {
      this.setState({ fechaValida: false });
    } else {
      this.setState({ fechaValida: true });
      let dataEnvio = Object.assign({}, this.state.dataEnvio);
      let fechaC = moment(fecha).format("YYYY-MM-DD");
      dataEnvio.fechaTerminoEnrolamiento = fechaC;
      let fechaTerminoEnrolamientoNueva = moment(fecha).format("DD-MM-YYYY");
      this.setState({
        dataEnvio,
        fechaTerminoEnrolamientoNueva,
        fechaTerminoEnrolamientoNuevaSinFormato: fecha,
      });
    }
  }

  async guardarFecha() {

    if (!this.state.fechaTerminoEnrolamientoNuevaSinFormato) {
      this.setState({ fechaValida: false });
    } else {
      this.setState({ loading: true });

      const res = await actualizarNegocio(this.state.dataEnvio);

      if (res === 1) {
        notificacion("success", FECHA_ENROLAMIENTO_ACTUALIZADA);
        this.props.fecha_termino_enrolamiento(
          this.state.fechaTerminoEnrolamientoNuevaSinFormato
        );
        this.setState({
          fechaMinima: this.state.fechaTerminoEnrolamientoNuevaSinFormato,
        });

        let negocios = [...this.props.store.reducer.negocios];
        if (negocios.length > VALOR_ZERO) {
          const negocio = negocios.find(
            (negocio) => negocio.idNegocio === this.state.negocio.idNegocio
          );

          const posicion = negocios.indexOf(negocio);

          negocios[
            posicion
          ].fechaTerminoEnrolamiento = this.state.dataEnvio.fechaTerminoEnrolamiento;

          this.props.guardar_negocios(negocios);
          this.props.guardar_negocio(negocios[posicion]);
        }
      } else notificacion("warning", FECHA_ENROLAMIENTO_ERROR);

      this.props.onClose();
      this.setState({ loading: false });
    }

  }

  render() {
    return this.props.cotizacion ? (
      <div
        className={`${!this.props.show ? "modal_overlay " : "modal_overlay show "
          } ${this.props.overlay === undefined
            ? ""
            : this.props.overlay
              ? ""
              : "light"
          }`}
        id={this.props.id}
      >
        <div
          className={`main_modal animated bounceInLeft ${this.props.centered ? "center" : ""
            } ${this.props.type === undefined ? "large" : this.props.type}`}
          style={{ maxWidth: "450px" }}
        >
          <div className="row">
            <div className="d-none d-md-block col-md-4 bg-primary text-white text-center left-side">
              <div className="icon-section">
                <div>
                  <img
                    src={fileImg}
                    className="card-img"
                    alt=""
                    width="70"
                    height="70"
                  ></img>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-8">
              <div className="modal_title">
                <div className="title">{this.props.title}</div>
                {this.props.showCloseButton === undefined ||
                  this.props.showCloseButton ? (
                  <div
                    className="button_close"
                    onClick={() => {
                      this.props.onClose();
                    }}
                  >
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      viewBox="0 0 352 512"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z" />
                    </svg>
                  </div>
                ) : null}
              </div>
              <div className="">
                <div className="form-group main_modal">
                  <DayPickerInput
                    onDayChange={this.obtenerFecha.bind(this)}
                    inputProps={{ className: "form-control form-control-sm" }}
                    formatDate={formatDate}
                    parseDate={parseDate}
                    format={"DD-MM-YYYY"}
                    value={this.state.fechaTerminoEnrolamientoNueva}
                    placeholder="DD-MM-YYYY"
                    disabledDays={{
                      after: new Date(),
                    }}
                    dayPickerProps={{
                      localeUtils: MomentLocaleUtils,
                      locale: "es",
                      modifiers: {
                        disabled: [
                          {
                            before: new Date(this.state.fechaMinima),
                          },
                        ],
                      },
                    }}
                  />
                  {!this.state.fechaValida && (
                    <small className="animated fadeIn text-danger">
                      Fecha inválida
                    </small>
                  )}
                </div>
              </div>
              <div className="modal_foot">
                <div className="button_zone">
                  {this.props.showCancelButton === undefined ||
                    this.props.showCancelButton ? (
                    <button
                      className="btn btn-principal color-cancel btn-sm"
                      type="button"
                      onClick={() => {
                        this.props.onCancel();
                      }}
                    >
                      Cancelar
                    </button>
                  ) : null}

                  <button
                    className="btn btn-principal btn-sm"
                    type="button"
                    disabled={!this.state.fechaValida}
                    onClick={() => {
                      this.guardarFecha();
                    }}
                  >
                    {this.state.loading ? (
                      <Fragment>
                        <i className="fa fa-spinner fa-pulse fa-fw"></i>
                        <span className="sr-only">Loading...</span>
                      </Fragment>
                    ) : (
                      <span>Guardar</span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : null;
  }
}

const mapStateToProps = (state) => ({
  store: state,
});

export default withRouter(
  connect(mapStateToProps, {
    fecha_termino_enrolamiento,
    guardar_negocios,
    guardar_negocio,
  })(ModalFechaEnrolamiento)
);
