import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import NominaAsegurables from "./NominaAsegurables";
import MisProcesos from "./MisProcesos";

const Procesos = (props) => {

  const { negocio } = props.store.reducer;

  const mostrarNomina = props.location.mostrarNomina;
  const fechaFinEnrolamiento = negocio ? negocio.fechaFinEnrolamiento : null;

  return mostrarNomina || fechaFinEnrolamiento ? (
    <NominaAsegurables />
  ) : (
      <MisProcesos />
    );
};

const mapStateToProps = (state) => ({
  store: state,
});

export default withRouter(connect(mapStateToProps)(Procesos));
