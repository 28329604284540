import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import moment from "moment";
import DatePicker from "react-datepicker";
import MaskedTextInput from "react-text-mask";
import "react-datepicker/dist/react-datepicker.css";
import {
  VALIDACION_ASEGURABLE,
  W2_COLS_COMPLEMENTARIA,
  VALIDACION_FECHA_DIAGNOSTICO,
  NO_DECLARA_PREEXISTENCIA,
  MASK_DATE,
  CAMPO_REQUERIDO_FECHA,
  CAMPO_REQUERIDO_DIAGNOSTICO,
  CAMPO_REQUERIDO_ASEGURADO,
  VALOR_ZERO,
  NO_DECLARA,
  TIPO_CANAL_VENTA_DIRECTA,
  TIPO_CANAL_VENTA_CORREDOR,
  VALOR_UNO,
} from "../../../../../../utils/Constantes";
import {
  wizard,
  add_detalle,
  update_detalle,
  cambio_detalle,
  guardar_data_asegurable,
  guardar_data_wizard_asegurado,
  update_carga,
} from "../../../../../../actions/actions";
import Table from "../../../../transversales/DataTable";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import "moment/locale/es";
import { eliminarDeclaracion, procesarDeclaracion } from "../../../../../../services/declaracion.services";

class PreguntaComplementarias extends Component {
  state = {
    edit: 0,
    aceptacionCheck:
      this.props.store.reducer.dataWizardAsegurado.solicitud.declaroEspecial !==
      null && this.props.store.reducer.dataWizardAsegurado.solicitud.declaroEspecial !== undefined,
    claseAceptacion: "",
    declaraDeclaracion: null,
    animacion: "formInit",
    agregar: true,
    declaracionComplementaria: {
      key: null,
      causa: "",
      fechaDiagnostico: "",
      fechaDiagnosticoFormat: "",
      fechaNacimiento: "",
      glosasCoberturas: null,
    },
    detalle: [],
    cambioDetalle: false,
    informacion: null,
    columnDefs: [
      ...W2_COLS_COMPLEMENTARIA,
      {
        cell: (row) => (
          <Fragment>
            <button
              id="botonEditar"
              className="btn btn-primary btn-sm btn-table mr-2"
              type="button"
              title="Editar"
              raised="true"
              primary="true"
              onClick={() => this.handleActionChange(row)}
            >
              <i className="fa fa-pencil" aria-hidden="true"></i>
            </button>
            <button
              id="botonEliminar"
              className="btn btn-danger btn-sm btn-table"
              type="button"
              title="Eliminar"
              raised="true"
              primary="true"
              onClick={() => this.confirm(row)}
            >
              <i className="fa fa-trash" aria-hidden="true"></i>
            </button>
          </Fragment>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
        declaracionCarga: false,
      },
    ],
    declaracionCarga: false,
    checked: null,
  };

  componentWillMount() {
    this.setState({
      informacion: this.props.informacion,
      checked: this.props.store.reducer.objetoChecks[this.props.indexCheck].declaracionCheck,
      aceptacionCheck: this.props.store.reducer.objetoChecks[this.props.indexCheck].aceptacionCheck
    }, () => {
      const check = this.props.store.reducer.objetoChecks;

      if (check && check[this.props.indexCheck].detalle.length > VALOR_ZERO) {
        this.props.check(
          check[this.props.indexCheck].aceptacionCheck,
          this.state.informacion.catalogoPregunta.idPregunta
        );
      }
    });
  }

  componentDidMount() {

    let idPreguntaSeccionCobertura = this.state.informacion
      .idPreguntaSeccionCobertura;

    const detalleOriginal = this.props.store.reducer.configuracionDeclaraciones.detalle.filter(
      (detalles) =>
        detalles.idPreguntaSeccionCobertura === idPreguntaSeccionCobertura &&
        detalles.catalogoDeclaracion.declaracion !== NO_DECLARA
    );

    let declaraDeclaracion;

    if (detalleOriginal.length > VALOR_ZERO) {
      if (
        detalleOriginal[0].catalogoDeclaracion.idCatalogoTipoDeclaracion ===
        NO_DECLARA_PREEXISTENCIA
      ) {
        declaraDeclaracion = false;
      } else {
        declaraDeclaracion = true;
      }
    } else {
      declaraDeclaracion = null;
    }

    let detalle = [];
    const tipoCanal = this.props.store.reducer.data_asegurable.canalVenta
      .idCanalVenta;
    if (
      tipoCanal === TIPO_CANAL_VENTA_DIRECTA ||
      tipoCanal === TIPO_CANAL_VENTA_CORREDOR
    ) {
      detalle = detalleOriginal.filter(
        (detalles) => detalles.causa !== null && detalles.causa !== ""
      );
    } else {
      detalle = detalleOriginal.filter(
        (detalles) =>
          detalles.catalogoDeclaracion.idCatalogoTipoDeclaracion !==
          NO_DECLARA_PREEXISTENCIA
      );
    }

    const idCatalogoAceptacion = this.state.informacion.catalogoAceptacion
      .idCatalogoAceptacion;
    const declaracionComplementaria = {
      ...this.state.declaracionComplementaria,
      idCatalogoAceptacion,
      idPreguntaSeccionCobertura,
    };

    detalle.forEach((declaracion) => {
      declaracion.fechaDiagnosticoFormat = moment(
        declaracion.fechaDiagnostico
      ).format("DD/MM/YYYY");
      declaracion.fechaDiagnostico = moment(
        declaracion.fechaDiagnostico
      ).format("YYYY-MM-DD");
    });

    this.setState({
      detalle,
      declaracionComplementaria,
      declaraDeclaracion,
    });
    const { dataWizardAsegurado, data_asegurable } = this.props.store.reducer;
    
    if (data_asegurable.canalVenta.idCanalVenta !== VALOR_UNO &&
        dataWizardAsegurado.cargas &&
        dataWizardAsegurado.cargas.length > VALOR_ZERO) {

      this.state.informacion.glosasCoberturas.forEach(glosa => {
        dataWizardAsegurado.cargas[VALOR_ZERO].coberturas.forEach(cobertura => {
          if (glosa.idCobertura === cobertura.idSubProdCondicionadoCobertura) {
            this.setState({
              declaracionCarga: true
            })
          }
        })
      });
    } else {
      this.setState({
        declaracionCarga: true
      })
    }
  }

  handleActionChange = (row) => {
    this.setState({
      agregar: false,
      declaracionComplementaria: row
    });
  };

  handleActionDelete = (objDeclaracion) => {

    let objRespuesta = eliminarDeclaracion(objDeclaracion, this.props.store.reducer, this.state)

    if (objRespuesta.blnCarga){
      let { cargas } = this.props.store.reducer.dataWizardAsegurado;
      cargas[objRespuesta.intIndiceCarga] = {...objRespuesta.objAsegurable};
      this.props.update_carga(cargas);
    }
    else{
      this.props.guardar_data_wizard_asegurado(objRespuesta.objAsegurable);
    }

    this.setState({ detalle: objRespuesta.objDetalle, agregar: true });
    this.props.update_detalle(objRespuesta.objDetalle);
  };

  handleSubmit = (values) => {

    let objRespuesta = procesarDeclaracion(values, this.props.store.reducer, this.state);

    if (objRespuesta.blnCarga){
      let { cargas } = this.props.store.reducer.dataWizardAsegurado;
      cargas[objRespuesta.intIndiceCarga] = {...objRespuesta.objAsegurable};
      this.props.update_carga(cargas);
    }
    else{
      this.props.guardar_data_wizard_asegurado(objRespuesta.objAsegurable);
    }

    if (objRespuesta.agregar) {

      this.setState({
        detalle: objRespuesta.objDetalleEstado,
        declaracionComplementaria: objRespuesta.objDeclaracionNueva
      });
      this.props.add_detalle(objRespuesta.objDeclaracionNueva);
    } 
    else {
      this.setState({
        detalle: objRespuesta.objDetalleEstado,
        declaracionComplementaria: objRespuesta.objDeclaracionNueva,
        agregar: true,
      });

      this.props.update_detalle(objRespuesta.objDetalle);
    }
    this.refs.siDeclaraRef.checked = true;
  };

  confirm = (row) =>
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="modal-confirm-container">
            <div className="d-flex">
              <p className="h4">¿Está seguro de eliminar esta declaración?</p>
            </div>

            <div className="row mt-4">
              <button
                id="cancelarEliminarDeclaracion"
                className="btn btn-principal color-cancel btn-sm d-block ml-auto mr-2"
                onClick={() => {
                  onClose();
                }}
              >
                Cancelar
              </button>
              <button
                id="eliminarDeclaracion"
                className="btn btn-principal color-danger btn-sm"
                onClick={() => {
                  this.handleActionDelete(row);
                  onClose();
                }}
              >
                Eliminar
              </button>
            </div>
          </div>
        );
      },
    });

  confirmarNo = (e) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="modal-confirm-container">
            <div className="d-flex">
              <p className="h4">
                Ha realizado {this.state.detalle.length}{" "}
                {this.state.detalle.length === 1
                  ? "declaración"
                  : "declaraciones"}
                . ¿Confirma que desea eliminar declaraciones?
              </p>
            </div>

            <div className="row mt-4">
              <button
                id="noEliminaDeclaraciones"
                className="btn btn-principal color-secundario btn-sm d-block ml-auto mr-2"
                onClick={() => {
                  this.refs.siDeclaraRef.checked = true;
                  this.refs.noDeclaraRef.checked = false;
                  this.setState({
                    declaraDeclaracion: true,
                    checked: true
                  });
                  onClose();
                }}
              >
                No
              </button>
              <button
                id="siEliminaDeclaraciones"
                className="btn btn-principal btn-sm"
                onClick={(e) => {
                  this.refs.siDeclaraRef.checked = false;
                  this.refs.noDeclaraRef.checked = true;
                  this.setState({
                    declaraDeclaracion: false,
                    checked: false
                  });
                  this.deleteAllDeclarationsState();
                  onClose();
                }}
              >
                Sí
              </button>
            </div>
          </div>
        );
      },
    });
  };

  deleteAllDeclarationsState = () => {
    //update state
    this.setState({
      detalle: [],
    });

    //update detalle redux
    const { reducer } = this.props.store;
    const { detalle } = reducer.configuracionDeclaraciones;
    const { informacionPoliza } = reducer;
    const { cotizaciones } = this.props.store.reducer.data_asegurable;
    const dataWizardTemporal = reducer.dataWizardAsegurado;

    const newDetalle = detalle.filter(
      (detalles) =>
        detalles.idPreguntaSeccionCobertura !==
        this.state.informacion.idPreguntaSeccionCobertura
    );

    this.props.update_detalle(newDetalle);

    //update objeto mongo

    if (reducer.dataWizardAsegurado.coberturas[0].declaraciones) {
      const declaraciones = reducer.dataWizardAsegurado.coberturas[0].declaraciones.filter(
        (detalles) =>
          detalles.idPreguntaSeccionCobertura !==
          this.state.informacion.idPreguntaSeccionCobertura
      );

      const data = {
        ...reducer.dataWizardAsegurado,
        coberturas: informacionPoliza.coberturas
          ? this.coberturasMantencion(informacionPoliza.coberturas, declaraciones, dataWizardTemporal)
          : cotizaciones && this.coberturasVentaDirecta(cotizaciones, declaraciones, dataWizardTemporal)
      };

      this.props.guardar_data_wizard_asegurado(data);
    }

    if (
      reducer.dataWizardAsegurado.cargas &&
      reducer.dataWizardAsegurado.cargas.length > VALOR_ZERO
    ) {
      const cargas = [...reducer.dataWizardAsegurado.cargas];
      cargas.forEach((carga) => {
        if (
          carga.coberturas[0].declaraciones &&
          carga.coberturas[0].declaraciones.length > VALOR_ZERO
        ) {
          carga.coberturas.forEach((cobertura) => {
            cobertura.declaraciones = cobertura.declaraciones.filter(
              (detalles) =>
                detalles.idPreguntaSeccionCobertura !==
                this.state.informacion.idPreguntaSeccionCobertura
            );
          });
        }
      });
      this.props.update_carga(cargas);
    }
  };

  reset() {
    this.setState({
      declaracionComplementaria: {
        key: null,
        causa: "",
        fechaDiagnostico: "",
        fechaDiagnosticoFormat: "",
        rut: "",
      },
    });
  }

  guardarFechaNacimiento = (rut, setFieldValue) => {
    if (rut && +rut !== VALOR_ZERO) {
      const { dataWizardAsegurado } = this.props.store.reducer;
      let fechaNacimiento;
      if (+rut === +dataWizardAsegurado.rut) {
        fechaNacimiento = dataWizardAsegurado.fechaNacimiento;
      } else {
        fechaNacimiento = dataWizardAsegurado.cargas.find(carga => +carga.rut === +rut).fechaNacimiento;
      };

      setFieldValue("fechaNacimiento", fechaNacimiento);
    }
  }

  render() {
    const { dataWizardAsegurado } = this.props.store.reducer;
    return (
      <Fragment>
        <div className="col-12 pt-3">
          <p className="text-justify">
            {this.props.informacion.catalogoPregunta.pregunta}
          </p>
        </div>

        <div className="col-12">
          <div className="check-group">
            <label className="custom-checkbox p-1">
              <input
                id="siDeclara"
                ref="siDeclaraRef"
                type="radio"
                name="radio"
                checked={this.state.checked}
                onClick={async (e) => {
                  await this.setState({
                    declaraDeclaracion: true,
                    checked: true
                  });
                  this.props.check(
                    true,
                    this.state.informacion.catalogoPregunta.idPregunta
                  );
                }}
              />
              <div className="check"></div>
              <div className="option">Si</div>
            </label>
            <label className="custom-checkbox p-1">
              <input
                id="noDeclara"
                ref="noDeclaraRef"
                type="radio"
                name="radio"
                checked={this.state.checked === null ? null : !this.state.checked}
                onClick={async (e) => {
                  if (this.state.detalle.length > 0) {
                    await this.confirmarNo(e);
                  }
                  await this.setState({
                    declaraDeclaracion: false,
                    checked: false
                  });
                  this.props.check(
                    false,
                    this.state.informacion.catalogoPregunta.idPregunta
                  );
                }}
              />
              <div className="check"></div>
              <div className="option">No</div>
            </label>
          </div>
        </div>

        {this.state.checked && (
          <>
            <div className="container pt-3">
              <hr />
            </div>
            <Formik
              enableReinitialize={true}
              initialValues={{
                rut: this.state.declaracionComplementaria.rut,
                causa: this.state.declaracionComplementaria.causa,
                fechaDiagnostico: moment(
                  this.state.declaracionComplementaria.fechaDiagnostico
                ).format("DD/MM/YYYY"),
                fechaNacimiento: this.state.declaracionComplementaria.fechaNacimiento,
              }}
              validationSchema={Yup.object().shape({
                rut: Yup.string()
                  .required(CAMPO_REQUERIDO_ASEGURADO)
                  .test(
                    "validacionAsegurable",
                    VALIDACION_ASEGURABLE,
                    (value) => {
                      return Number(value) !== 0 ? true : false;
                    }
                  ),
                fechaDiagnostico: Yup.string()
                  .required(CAMPO_REQUERIDO_FECHA)
                  .test(
                    "fechaDiagnostico",
                    VALIDACION_FECHA_DIAGNOSTICO,
                    (value) => {
                      return value !== "Invalid date" ? true : false;
                    }
                  ),
                causa: Yup.string()
                  .required(CAMPO_REQUERIDO_DIAGNOSTICO)
                  .test("validarVacios", CAMPO_REQUERIDO_DIAGNOSTICO, (value) =>
                    value ? value.trim() !== "" : false
                  ),
              })}
              onSubmit={async (
                values,
                { setSubmitting, resetForm, initialValues }
              ) => {
                this.handleSubmit(values);
                this.reset();
                await this.setState(
                  {
                    declaracionComplementaria: {
                      key: null,
                      causa: "",
                      fechaDiagnostico: "",
                      fechaDiagnosticoFormat: "",
                      rut: "",
                    },
                  },
                  () => {
                    resetForm(initialValues);
                  }
                );
              }}
            >
              {({
                values,
                errors,
                touched,
                setFieldValue,
                setFieldTouched,
                handleChange,
                handleSubmit,
                handleBlur,
              }) => {
                return (
                  <Form
                    id="formularioPreexistencias"
                    className="col-12"
                    onSubmit={handleSubmit}
                    onKeyDown={(e) => {
                      if ((e.charCode || e.keyCode) === 13) {
                        e.preventDefault();
                      }
                    }}
                  >
                    <div className="row mx-md-5 mt-3">
                      <div className="col-12 form-group">
                        <label>
                          Nombre completo asegurable titular o dependiente{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <select
                          className="form-control form-control-sm"
                          id="rut"
                          name="rut"
                          value={values.rut}
                          onChange={(e) => {
                            handleChange(e);
                            this.guardarFechaNacimiento(e.target.value, setFieldValue);
                            setFieldValue("fechaDiagnostico", "")
                            setFieldValue("causa", "")
                          }}
                          onBlur={handleBlur}
                        >
                          <option value="0">SELECCIONE UN ASEGURABLE</option>

                          {(this.props.store.reducer.informacionPoliza
                            .solicitudIncorporacion ||
                            this.props.store.reducer.informacionPoliza
                              .asegurable_5_50) && (
                              <option name="nombre" value={dataWizardAsegurado.rut}>
                                {`${dataWizardAsegurado.nombre} ${dataWizardAsegurado.apellidoPaterno} ${dataWizardAsegurado.apellidoMaterno} (TITULAR)`}
                              </option>
                            )}

                          {dataWizardAsegurado.cargas && this.state.declaracionCarga &&
                            dataWizardAsegurado.cargas.map((item, i) => (
                              <option
                                name="nombre"
                                key={i}
                                value={item.rut}
                              >{`${item.nombre} ${item.apellidoPaterno} ${item.apellidoMaterno} (ADICIONAL)`}</option>
                            ))}
                        </select>
                        {errors.rut && touched.rut && (
                          <small className="text-danger animated fadeIn animated fadeIn">
                            {errors.rut}
                          </small>
                        )}
                      </div>

                      <div className="col-12 form-group">
                        <label>
                          Diagnóstico <span className="text-danger">*</span>
                        </label>
                        <Field
                          id="causa"
                          name="causa"
                          value={values.causa}
                          type="text"
                          placeholder="Ingrese diagnóstico"
                          maxLength="40"
                          className="form-control form-control-sm"
                        />
                        {errors.causa && touched.causa && (
                          <small className="text-danger animated fadeIn">
                            {errors.causa}
                          </small>
                        )}
                      </div>

                      <div className="col-12 form-group">
                        <label>
                          Fecha diagnóstico y/o declaración de invalidéz{" "}
                          <span className="text-danger">*</span>
                        </label>

                        <DatePicker
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          id="fechaDiagnostico"
                          name="fechaDiagnostico"
                          value={values.fechaDiagnostico}
                          dateFormat="dd/MM/yyyy"
                          placeholderText="DD/MM/YYYY"
                          locale="es"
                          onChange={(value) => {
                            value = moment(value).format("DD/MM/YYYY");
                            setFieldTouched("fechaDiagnostico", true);
                            setFieldValue("fechaDiagnostico", value);
                          }}
                          minDate={new Date(values.fechaNacimiento)}
                          maxDate={new Date()}
                          customInput={
                            <MaskedTextInput
                              className="form-control form-control-sm"
                              placeholder="DD/MM/YYYY"
                              mask={MASK_DATE}
                            />
                          }
                        />

                        {errors.fechaDiagnostico && touched.fechaDiagnostico && (
                          <small className="text-danger animated fadeIn">
                            {errors.fechaDiagnostico}
                          </small>
                        )}
                      </div>

                      <div className="col-12">
                        <button
                          id="botonSubmit"
                          className="btn btn-principal color-secundario btn-sm d-block ml-auto"
                          type="submit"
                        >
                          {this.state.agregar ? "Agregar" : "Actualizar"}
                        </button>
                      </div>
                    </div>

                    {this.state.detalle.length > 0 ? (
                      <div className="pt-3">
                        <hr />
                        <Table
                          titulo="Declaraciones complementarias"
                          datos={this.state.detalle.filter(
                            (item) => item.nombre
                          )}
                          columnas={this.state.columnDefs}
                        />
                        <hr />
                      </div>
                    ) : null}
                  </Form>
                );
              }}
            </Formik>
            <div className="container pt-3">
              <hr />
              <div className="row">
                <div className="col-1 align-self-center">
                  <label className="switch">
                    <input
                      id="checkboxDeclaro"
                      checked={this.state.aceptacionCheck}
                      onClick={() =>
                        this.setState(
                          {
                            aceptacionCheck: !this.state.aceptacionCheck,
                          },
                          function () {
                            this.props.checkAceptacion(
                              this.state.aceptacionCheck,
                              this.state.informacion.catalogoPregunta.idPregunta
                            );
                          }
                        )
                      }
                      type="checkbox"
                    />
                    <span className="slider round" />
                  </label>
                </div>
                <div className="col-11">
                  <label className="form-check-label " htmlFor="aceptacion">
                    <p className="text-justify">
                      {this.props.informacion.catalogoAceptacion.glosa}
                    </p>
                  </label>
                </div>
              </div>
            </div>
          </>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state,
});

export default withRouter(
  connect(mapStateToProps, {
    wizard,
    add_detalle,
    update_detalle,
    cambio_detalle,
    guardar_data_asegurable,
    guardar_data_wizard_asegurado,
    update_carga,
  })(PreguntaComplementarias)
);
