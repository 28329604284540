import React, { Component, Fragment } from "react";
import { actualizarUsuarioRevisor } from "../../../../../services/bus.poliza.services";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  ESTADO_NO_INICIADO,
  ERROR_EDICION,
  ESTADO_ENROLADO,
  COD_STATUS_EXITO,
  EXITO_ELIMINAR_SOLICITUD,
  PERFIL_DELEGADO,
  COD_EJECUTIVO_MANTENCION,
  COD_EJECUTIVO_COMPLETO,
  COD_STATUS_ERROR,
} from "../../../../../utils/Constantes";
import { actualizarAsegurableDelegado } from "../../../../../services/asegurable.services";
import { notificacion } from "../../../transversales/Notificaciones";
import ModalEditarAsegurable from "../../jefatura-ejecutivo/ModalEditarAsegurable";
import { obtenerMisSolicitudesAction } from "../../../../../reducer/delegadoMantencion";

class EliminarSolicitud extends Component {
  state = {
    mostrarModalConfirmacion: false,
  };

  eliminarSolicitud = async () => {
    const asegurables = [...this.props.asegurables];
    const asegurablesReducer = this.props.store.delegado.asegurables;
    const objetoEliminar = asegurables.find(
      (asegurable) => asegurable.rut === this.props.row.rut
    );
    const objetoEliminarReducer = asegurablesReducer.find(
      (asegurableReducer) => asegurableReducer.rut === this.props.row.rut
    );
    objetoEliminar.vigente = false;
    objetoEliminarReducer.vigente = false;

    const {
      apellidoMaterno,
      apellidoPaterno,
      correoElectronico,
      digitoVerificador,
      idAsegurable,
      idPersona,
      nombre,
      vigente,
      grupos,
    } = objetoEliminar;

    const rut = Number(objetoEliminar.rut.split("-")[0]);

    const data = {
      apellidoMaterno,
      apellidoPaterno,
      correoElectronico,
      digitoVerificador,
      idAsegurable,
      idPersona,
      grupos,
      nombre,
      rut,
      vigente,
    };

    let eliminar = await actualizarAsegurableDelegado(data, PERFIL_DELEGADO);

    if (eliminar !== null && eliminar.status.codigo === COD_STATUS_EXITO) {
      const asegurablesActualizados = asegurables.filter(
        (item) => item.vigente
      );
      const asegurablesReducerActualizados = asegurablesReducer.filter(
        (item) => item.vigente
      );
      this.actualizaRevisor();
      this.props.setAsegurables(asegurablesActualizados);
      this.props.obtenerMisSolicitudesAction(asegurablesReducerActualizados);

      notificacion("success", EXITO_ELIMINAR_SOLICITUD);
    } else {
      this.setState({ cargando: false });
      notificacion("danger", ERROR_EDICION);
    }
  };

  actualizaRevisor = async () => {
    const dataEnvio = {
      idNegocio: this.props.idNegocio,
      revisado: false,
    };
    let actualizarRevisor = await actualizarUsuarioRevisor(dataEnvio);
    if (actualizarRevisor === COD_STATUS_ERROR) {
      notificacion("danger", ERROR_EDICION);
    }
  };

  evaluarEliminar = () => {
    if (this.props.row.status === ESTADO_NO_INICIADO) {
      this.eliminarSolicitud();
    } else {
      this.mostrarModalConfirmacion();
    }
  };

  mostrarModalConfirmacion = () => {
    this.setState({
      mostrarModalConfirmacion: !this.state.mostrarModalConfirmacion,
    });
  };

  render() {
    return (
      <Fragment>
        <ModalEditarAsegurable
          idSubmit="submitEliminarSolicitud"
          type="small"
          show={this.state.mostrarModalConfirmacion}
          title="Eliminar Solicitud"
          action="Continuar"
          onSubmit={this.eliminarSolicitud}
          onCancel={() => {
            this.mostrarModalConfirmacion();
          }}
          onClose={() => {
            this.mostrarModalConfirmacion();
          }}
          showCancelButton
          showCloseButton
          showSubmitButton
          showLeftColumn={false}
        >
          <div className="row">
            <h5 className="col-12 col-md-8 offset-md-2">
              Está eliminando a un colaborador que ya ha comenzado su
              enrolamiento. ¿Desea continuar?
            </h5>
          </div>
        </ModalEditarAsegurable>
        <button
          disabled={
            this.props.row.status === ESTADO_ENROLADO ||
            this.props.store.auth.usuarioSesion.rol.codigo ===
            COD_EJECUTIVO_MANTENCION ||
            this.props.store.auth.usuarioSesion.rol.codigo ===
            COD_EJECUTIVO_COMPLETO
          }
          className="btn btn-danger btn-sm btn-table mr-2"
          type="button"
          title="Eliminar"
          raised="true"
          primary="true"
          onClick={this.evaluarEliminar}
        >
          <i className="fa fa-trash" aria-hidden="true"></i>
        </button>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state,
});

export default withRouter(
  connect(mapStateToProps, { obtenerMisSolicitudesAction })(EliminarSolicitud)
);
