import * as yup from "yup";
import {
  VALOR_ZERO,
  VALOR_TRES,
  CAMPO_REQUERIDO_FECHA,
  INVALID_DATE,
  CAMPO_REQUERIDO_VALOR_CAPITAL,
  CAMPO_REQUERIDO_FOLIO_COTIZACION,
  CAMPO_REQUERIDO_PRIMA_NEGOCIO,
  VALIDACION_SELECT_2,
  VALIDACION_TIPO_PRODUCTO,
  VALIDACION_COBERTURA_PRODUCTO,
  VALIDACION_TIPO_CAPITAL,
  CAMPO_REQUERIDO_NUMERO_TRABAJADORES,
  VALIDACION_FECHA,
  VALIDACION_MAYOR_CERO,
  VALIDACION_MINIMO_CARACTERES,
} from "../../../../../../utils/Constantes";

export const INITIAL_VALUES = {
  tipoDeProducto: 0,
  coberturas: null,
  seleccionCobertura: false,
  capital: 0,
  valorCapital: "",
  preexistencia: 0,
  folioCotizacion: "",
  primaNegocio: "",
  fechaCotizacion: "",
  fechaInicioVigencia: "",
  numeroTrabajadores: "",
  capitalAnterior: "",
  valorCapitalAnterior: "",
};

const SCHEMA_VALIDATIONS = {
  tipoDeProducto: yup
    .string()
    .test("select", VALIDACION_TIPO_PRODUCTO, (value) => {
      return Number(value) !== VALOR_ZERO ? true : false;
    }),
  coberturas: yup
    .mixed()
    .test("validarCobertura", VALIDACION_COBERTURA_PRODUCTO, (value) => {
      return value ? true : false;
    }),
  seleccionCobertura: yup
    .mixed()
    .test("validarCobertura", VALIDACION_SELECT_2, (value) => {
      return value !== VALOR_ZERO ? true : false;
    }),
  capital: yup.string().test("select", VALIDACION_TIPO_CAPITAL, (value) => {
    return Number(value) !== VALOR_ZERO ? true : false;
  }),
  valorCapital: yup
    .string()
    .required(CAMPO_REQUERIDO_VALOR_CAPITAL)
    .test("validarVacios", CAMPO_REQUERIDO_VALOR_CAPITAL, (value) =>
      value ? value.trim() !== "" : false
    )
    .test("numMinimo", VALIDACION_MAYOR_CERO, (value) =>
      value ? (value > VALOR_ZERO ? true : false) : true
    ),
  primaNegocio: yup
    .string()
    .required(CAMPO_REQUERIDO_PRIMA_NEGOCIO)
    .test("validarVacios", CAMPO_REQUERIDO_PRIMA_NEGOCIO, (value) =>
      value ? value.trim() !== "" : false
    )
    .test("numMinimo", VALIDACION_MAYOR_CERO, (value) =>
      value > VALOR_ZERO ? true : false
    ),
  fechaInicioVigencia: yup
    .string()
    .required(CAMPO_REQUERIDO_FECHA)
    .test("fecha", VALIDACION_FECHA, (value) => {
      return value !== INVALID_DATE ? true : false;
    }),
  fechaCotizacion: yup
    .string()
    .required(CAMPO_REQUERIDO_FECHA)
    .test("fecha", VALIDACION_FECHA, (value) => {
      return value !== INVALID_DATE ? true : false;
    }),
  folioCotizacion: yup
    .string()
    .required(CAMPO_REQUERIDO_FOLIO_COTIZACION)
    .test("validarVacios", CAMPO_REQUERIDO_FOLIO_COTIZACION, (value, t) => {
      return value ? value.trim() !== "" : false;
    })
    .test("caracteresMinimo", VALIDACION_MINIMO_CARACTERES, (value) =>
      value ? (value.length < VALOR_TRES ? false : true) : true
    ),
  numeroTrabajadores: yup
    .string()
    .required(CAMPO_REQUERIDO_NUMERO_TRABAJADORES)
    .test("validarVacios", CAMPO_REQUERIDO_PRIMA_NEGOCIO, (value) =>
      value ? value.trim() !== "" : false
    )
    .test("numMinimo", VALIDACION_MAYOR_CERO, (value) =>
      value > VALOR_ZERO ? true : false
    ),
  capitalAnterior: yup
    .string()
    .test("select", VALIDACION_TIPO_CAPITAL, (value) => {
      return value === "RENTA" || value === "FIJO UF" ? true : false;
    }),
  valorCapitalAnterior: yup
    .string()
    .required(CAMPO_REQUERIDO_VALOR_CAPITAL)
    .test("validarVacios", CAMPO_REQUERIDO_VALOR_CAPITAL, (value) =>
      value ? value.trim() !== "" : false
    )
    .test("numMinimo", VALIDACION_MAYOR_CERO, (value) =>
      value ? (value > VALOR_ZERO ? true : false) : true
    ),
};

export const obtenerValidacionesFormulario = (
  validaPreexistencia,
  capitalesVariables = [],
  validaCapital,
  continuidadCobertura,
  capitalContinuidadAnterior
) => {
  if (validaPreexistencia) {
    let schema = Object.assign({}, SCHEMA_VALIDATIONS);
    if (!continuidadCobertura) {
      delete schema.capitalAnterior;
      delete schema.valorCapitalAnterior;
    } else {
      if (
        capitalContinuidadAnterior.capitalAnterior === "RENTA" ||
        capitalContinuidadAnterior.capitalAnterior === "FIJO UF"
      ) {
        delete schema.capitalAnterior;
      }
      if (capitalContinuidadAnterior.valorCapitalAnterior > 0) {
        delete schema.valorCapitalAnterior;
      }
    }

    if (!validaCapital) {
      delete schema.capital;
      delete schema.valorCapital;
    } else if (validaCapital && capitalesVariables.length) {
      delete schema.valorCapital;
    }
    return yup.object().shape(schema);
  } else {
    let schema = Object.assign({}, SCHEMA_VALIDATIONS);
    if (!continuidadCobertura) {
      delete schema.capitalAnterior;
      delete schema.valorCapitalAnterior;
    } else {
      if (
        capitalContinuidadAnterior.capitalAnterior === "RENTA" ||
        capitalContinuidadAnterior.capitalAnterior === "FIJO UF"
      ) {
        delete schema.capitalAnterior;
      }
      if (capitalContinuidadAnterior.valorCapitalAnterior > 0) {
        delete schema.valorCapitalAnterior;
      }
    }
    delete schema.preexistencia;
    if (!validaCapital) {
      delete schema.capital;
      delete schema.valorCapital;
    }
    return yup.object().shape(schema);
  }
};
