import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Contenido from "../../transversales/Contenido";

class Corredor extends Component {
  state = {
    menu: this.props.store.auth.usuarioSesion.menu,
  };

  render() {
    return <Contenido menu={this.state.menu} routes={this.props.routes} />;
  }
}

const mapStateToProps = (state) => ({
  store: state,
});

export default withRouter(
  connect(mapStateToProps, {})(Corredor)
);
