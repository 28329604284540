import React, { Component } from "react";
import { actualizarUsuarioRevisor } from "../../../../../services/bus.poliza.services";
import {
  buscarAsegurablePorRut,
  cerrarEnrolamiento,
  eliminarDataWizardMongo,
  obtenerDataWizard,
  obtenerPreguntasDeclaraciones,
} from "../../../../../services/asegurable.services";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { animateScroll as scroll } from "react-scroll";
import { notificacion } from "../../../transversales/Notificaciones";
import {
  wizard,
  actualizar_progreso_asegurable,
  guardar_data_wizard_asegurado,
  update_carga,
  update_detalle,
  resetSesion,
  get_ciudades,
} from "../../../../../actions/actions";
import {
  ERROR_SOLICITUD,
  ACEPTAR_TERMINOS,
  ESTADO_SOLICITUD_FINALIZADO,
  COMPLETAR_INFORMACION,
  TRANSFERENCIA,
  GENERO_M,
  VALOR_ZERO,
  PAGINA_3,
  PAGINA_4,
  TIPO_PAGINA,
  ZERO_STRING,
  TIPO_SOLICITUD_DECLARACION_BENEFICIARIO,
  TIPO_POLIZA_VIDA,
  TIPO_CANAL_VENTA_DIRECTA,
  ERROR_EDICION,
  COD_STATUS_ERROR,
  POSICION_INICIAL,
  DECLARACION_TERMINOS_CONDICIONES,
  NRO_DOCUMENTO_TERMINO_CONDICION,
  TIPO_DOCUMENTO_TERMINO_CONDICION,
  TIPO_DATA_PDF,
  ID_PRODUCTO_VIDA,
  VALIDACION_100_BENEFICIO,
  SIN_BENEFICIARIOS,
  CIEN_POR_CIENTO,
  LARGO_CERO_COBERTURAS,
  TIPO_INCORPORACION_MANTENCION,
  PUNTO_COMA,
  SEGUNDA_POSICION,
  TEXTO_TERMINOS_CONDICIONES,
  STATUS_EXITO,
  STATUS_TIMEOUT,
} from "../../../../../utils/Constantes";
import Consentimientos from "./Consetimientos";
import DatosPersonales from "./DatosPersonales";
import Cargas from "./Cargas";
import Declaraciones from "./Declaraciones";
import "moment/locale/es";
import DatosEmpresa from "./DatosEmpresa";
import Beneficiarios from "./Beneficiarios";
import { Fragment } from "react";
import SeguroVida from "./SeguroVida";
import { obtenerUrlBase64 } from "../../../../../utils/Functions";
import { obtenerArchivos } from "../../../../../services/bus.document.services";
import Spinner from "../../../transversales/Spinner";
import { obtenerCiudades } from "../../../../../services/transversales.service";
import { separarCoberturas, validarDeclaracionNoDeclaro } from "../../../../../services/declaracion.services";

const logo = require("../../../../assets/images/logo_vida_camara.png");

class Resumen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataWizard: this.props.store.reducer.dataWizardAsegurado,
      checkedTerminos: false,
      modal: false,
      notificaciones: true,
      ampliacion: [],
      deportes: [],
      dataResumen: null,
      preguntas: [],
      cerrandoEnrolamiento: false,
      soloSalud: true,
      terminoCondicion: [],
      rutaTerminoCondicion: "",
      versionTerminoCondicion: "",
      cargando: true,
    };
  }

  async componentDidMount() {

    this.state.dataWizard.coberturas.map((cob) => {
      if (
        cob.idProducto === ID_PRODUCTO_VIDA ||
        (cob.tipoPoliza && cob.tipoPoliza.glosa === TIPO_POLIZA_VIDA)
      ) {
        this.setState({ soloSalud: false });
      }
      return null;
    });

    let lstCiudades = await obtenerCiudades();
    await this.props.get_ciudades(lstCiudades);
    await this.obtenerDataMongo();
    await this.obtenerPreguntas();
    await this.obtenerRutaTerminoCondicion();

    if ((this.state.dataResumen && 
        this.state.dataResumen instanceof Object && 
        Object.keys(this.state.dataResumen).length > VALOR_ZERO) &&
        lstCiudades && lstCiudades.length > VALOR_ZERO) {
        this.setState({ cargando: false });
    }
    else{
      notificacion("danger", ERROR_SOLICITUD);
      this.anteriorPagina();
    }
  }

  async obtenerDataMongo() {
    this.setState({ cerrandoEnrolamiento: true });
    
    const dataResumen = await obtenerDataWizard(
      this.props.store.reducer.data_asegurable.idSolicitud
    );

    this.setState({ dataResumen: Object.assign({}, dataResumen) }, () => {
      this.setState({ cerrandoEnrolamiento: false });
    });
  }

  async obtenerPreguntas() {
    const { preguntasCobertura } = this.props.store.reducer;
    this.setState({ preguntas: preguntasCobertura });
  }

  async obtenerRutaTerminoCondicion() {
    let objDatos = {
      "metadataDocto": {
        "nroDocumento": NRO_DOCUMENTO_TERMINO_CONDICION,
        "tipoDocumento": TIPO_DOCUMENTO_TERMINO_CONDICION
      },
      "tipoDocto": TIPO_DOCUMENTO_TERMINO_CONDICION
    };

    let lstArchivos = await obtenerArchivos(objDatos);
    if (lstArchivos && Array.isArray(lstArchivos)) {
      let objArchivo    = lstArchivos.find(objArchivo => objArchivo.nombreDocto.includes(TEXTO_TERMINOS_CONDICIONES));
      let strRutaTyC    = obtenerUrlBase64(objArchivo.streamBase64, TIPO_DATA_PDF);
      let strVersionTyC = objArchivo.idDocto.split(PUNTO_COMA)[SEGUNDA_POSICION];

      this.setState({
        terminoCondicion: lstArchivos,
        rutaTerminoCondicion: strRutaTyC,
        versionTerminoCondicion: strVersionTyC
      });
    }
  }

  async obtenerPreguntasDeclaraciones(
    idSubProdCondicionadoCobertura,
    codigoCotizacion
  ) {
    let preguntas = [];
    let preguntasDeclaraciones = await obtenerPreguntasDeclaraciones(
      idSubProdCondicionadoCobertura,
      codigoCotizacion
    );

    preguntasDeclaraciones =
      preguntasDeclaraciones &&
      preguntasDeclaraciones.map((pregunta) => {
        return {
          idSubProdCondicionadoCobertura: idSubProdCondicionadoCobertura,
          idPreguntaSeccionCobertura: pregunta.idPreguntaSeccionCobertura,
          catalogoPregunta: pregunta.catalogoPregunta,
        };
      });
    if (preguntasDeclaraciones) {
      preguntas.push(...preguntasDeclaraciones);
    }
    return preguntas;
  }

  updateChecked = async () => {
    this.setState({
      checkedTerminos: !this.state.checkedTerminos,
    });
  };

  datosValidos = (dato) =>
    dato !== undefined &&
    dato !== null &&
    dato !== "" &&
    dato !== 0 &&
    dato !== "0";

  datosObligatorios = (dataWizardAsegurado) => {
    const {
      direccion,
      numero,
      telefonoMovil,
      correoElectronico,
      numeroCuenta,
      ciudad,
      comuna,
      formaPago,
    } = dataWizardAsegurado;

    const arregloData = [
      direccion,
      numero,
      telefonoMovil,
      correoElectronico,
      numeroCuenta,
      ciudad,
      comuna,
      formaPago,
    ];

    if (arregloData.some((dato) => dato === undefined || dato === null)) {
      return false;
    } else {
      const { idCiudad } = dataWizardAsegurado.ciudad;
      const { idComuna } = dataWizardAsegurado.comuna;
      const { idFormaPago } = dataWizardAsegurado.formaPago;
      const { idInstitucionFinanciera } =
        dataWizardAsegurado.institucionFinanciera;
      const { idTipoCuentaBancaria } = dataWizardAsegurado.tipoCuentaBancaria;
      const { declaroEspecial, declaroDeportes, declaroCarga } =
        dataWizardAsegurado.solicitud;

      const arregloObligatorios = [
        direccion,
        numero,
        telefonoMovil,
        correoElectronico,
        idCiudad,
        idComuna,
        idFormaPago,
        declaroEspecial,
        declaroDeportes,
        declaroCarga,
      ];
      const arregloBancarios = [
        numeroCuenta,
        idInstitucionFinanciera,
        idTipoCuentaBancaria,
      ];

      const datosObligatorios =
        arregloObligatorios.every(this.datosValidos) &&
        (idFormaPago === TRANSFERENCIA
          ? arregloBancarios.every(this.datosValidos)
          : true);

      return datosObligatorios;
    }
  };

  cerrarEnrolamiento = async () => {

    if (this.state.checkedTerminos) {
      
      const { data_asegurable, estructuraFormulario } = this.props.store.reducer;
      
      this.setState({ cerrandoEnrolamiento: true });

      if (data_asegurable.fechaRecepcion) { return window.location.replace(""); }

      let objAsegurado = this.state.dataResumen;
      
      if (objAsegurado.beneficiarios != null && objAsegurado.beneficiarios.length > SIN_BENEFICIARIOS) {

        let intSumaPorcentaje = objAsegurado.beneficiarios
                                .map((objBeneficiario) => Number(objBeneficiario.porcentajeBeneficio))
                                .reduce((intValorActual, intNuevoValor) => intValorActual + intNuevoValor, POSICION_INICIAL);
        
        if (intSumaPorcentaje !== CIEN_POR_CIENTO) {
          this.setState({ cerrandoEnrolamiento: false });
          return notificacion("warning", VALIDACION_100_BENEFICIO)
        }
      }

      if (objAsegurado === null) {
        notificacion("warning", COMPLETAR_INFORMACION);
      } 
      else {
        const { ciudades, comunas, estadoCivil, previsionSalud, generos, data_asegurable, declaraciones } = this.props.store.reducer;
        const { razonSocial, rutEmpresa, digitoVerificadorEmpresa } = data_asegurable;

        objAsegurado.solicitud.recibirInformacion = this.state.notificaciones;
        objAsegurado.solicitud.solicitudEstado    = { idSolicitudEstado: ESTADO_SOLICITUD_FINALIZADO, };

        if (objAsegurado.solicitud.tipoSolicitud === null || 
            objAsegurado.solicitud.tipoSolicitud.idTipoSolicitud !== TIPO_SOLICITUD_DECLARACION_BENEFICIARIO) {

          objAsegurado.cargas.forEach((objCarga) => {
            
            if (previsionSalud.length > VALOR_ZERO) {
              objCarga.sistemaPrevisionSalud.glosa = previsionSalud.find((objPrevision) => objPrevision.idIsapre === objCarga.sistemaPrevisionSalud.idSistemaPrevisionSalud).glosa;
            }
            if (null === objCarga.grupos){
              objCarga.grupos = data_asegurable.grupos;
            }
          });

          objAsegurado.ciudad.glosa       = ciudades.find((objCiudad) => objCiudad.idCiudad === objAsegurado.ciudad.idCiudad).glosaCiudad;
          objAsegurado.comuna.glosa       = comunas.find((objComuna) => objComuna.idComuna === objAsegurado.comuna.idComuna).glosaComuna;
          objAsegurado.estadoCivil.glosa  = estadoCivil.find((objEstadoCivil) => objEstadoCivil.idEstadoCivil === objAsegurado.estadoCivil.idEstadoCivil).glosa;
          objAsegurado.genero.descripcion = generos.find((objGenero) => objGenero.idGenero === objAsegurado.genero.idGenero).descripcion;

          if (previsionSalud.length > VALOR_ZERO) {
            objAsegurado.sistemaPrevisionSalud.glosa = previsionSalud.find( (objPrevision) => objPrevision.idIsapre === objAsegurado.sistemaPrevisionSalud.idSistemaPrevisionSalud).glosa;
          }

          if (!estructuraFormulario.formaPago) {
            objAsegurado.formaPago             = { idFormaPago: VALOR_ZERO };
            objAsegurado.institucionFinanciera = { idInstitucionFinanciera: VALOR_ZERO };
            objAsegurado.tipoCuentaBancaria    = { idTipoCuentaBancaria: VALOR_ZERO };
          }
        }
        objAsegurado.correoElectronico        = data_asegurable.correoElectronico;
        objAsegurado.telefonoMovil            = data_asegurable.telefonoMovil
        objAsegurado.solicitud.fechaRecepcion = new Date();

        objAsegurado.contratante = {
          razonSocial: razonSocial,
          rutEmpresa: rutEmpresa,
          digitoVerificador: digitoVerificadorEmpresa,
        };

        if (declaraciones.length && declaraciones.length > 0) {
          objAsegurado = validarDeclaracionNoDeclaro(objAsegurado, declaraciones);
          objAsegurado = await separarCoberturas(objAsegurado, this.state.preguntas);
        }
        let lstSubProductos = [];

        data_asegurable.cotizaciones.forEach(objCotizacion => {
          lstSubProductos.push(objCotizacion.subProducto);
        })

        let objPeticion = {
          asegurable: objAsegurado,
          nombreDelegado: `${data_asegurable.nombreDelegado} ${data_asegurable.apellidoPaternoDelegado}`,
          idGeneroDelegado: GENERO_M,
          negocio:{
            idNegocio: data_asegurable.idNegocio,
            canalVenta: data_asegurable.canalVenta
          },
          subProductos: lstSubProductos,
          polizas: []
        };

        if (!objPeticion.asegurable.numeroCuenta) {
          objPeticion.asegurable = {
            ...objPeticion.asegurable,
            numeroCuenta: ZERO_STRING,
          };
        }

        if (this.props.store.reducer.data_asegurable.canalVenta.idCanalVenta !== TIPO_CANAL_VENTA_DIRECTA) {
          objPeticion.asegurable.cargas = objPeticion.asegurable.cargas.map(
            (carga) => {
              return {
                ...carga,
                cobertura: {
                  ...carga.cobertura,
                  capitalAsegurado: objPeticion.asegurable.coberturas.length > LARGO_CERO_COBERTURAS && objPeticion.asegurable.coberturas.capitalAsegurado
                  && objPeticion.asegurable.coberturas.find(
                    (cob) =>
                      cob.idSubProdCondicionadoCobertura ===
                      carga.cobertura.idSubProdCondicionadoCobertura
                  ).capitalAsegurado,
                },
                coberturas: carga.coberturas.map((cob) => {
                  let capitalTemporal = objPeticion.asegurable.coberturas.find(
                    (cobb) =>
                      cobb.idSubProdCondicionadoCobertura ===
                      cob.idSubProdCondicionadoCobertura && cob.principal
                  );
                  return {
                    ...cob,
                    capitalAsegurado: capitalTemporal && capitalTemporal.capitalAsegurado ? capitalTemporal.capitalAsegurado : null,
                  };
                }),
              };
            }
          );
        }

        if (data_asegurable.canalVenta.idCanalVenta !== TIPO_CANAL_VENTA_DIRECTA && 
            objAsegurado.solicitud.tipoIncorporacion.idTipoIncorporacion === TIPO_INCORPORACION_MANTENCION) {
          
          const { informacionPoliza : { coberturasCargas, coberturas } } = this.props.store.reducer;

          data_asegurable.grupos.forEach(objGrupo => {

            let objPoliza = {
              nroPoliza:objGrupo.nroPoliza,
              nroRenovacion:objGrupo.nroRenovacion,
              idSubProducto:objGrupo.idSubProducto,
              tipoPoliza: objGrupo.poliza.tipoPoliza
            };
            objPeticion.polizas.push(objPoliza);
          })

          //TITULAR
          objPeticion.asegurable.coberturas.forEach(objCobertura => {
            let objCoberAux = coberturas.find(objCober => objCober.idSubProdCondicionadoCobertura === objCobertura.idSubProdCondicionadoCobertura);
            objCobertura.configuracion = {...objCoberAux.configuracion};

            data_asegurable.grupos.forEach(objGrupo => {
              objGrupo.poliza.grupos.forEach(objGrupoPoliza => {
                objGrupoPoliza.coberturas.forEach(objCoberturaPoliza => {

                if (objCobertura.idProductoCobertura === objCoberturaPoliza.idProductoCobertura) {
                    objCobertura.codigoCotizacion = objGrupo.codigoCotizacion;
                    objCobertura.idSubProducto = objGrupo.idSubProducto;
                  }
                })
              })
            })
            data_asegurable.cotizaciones.forEach(objCotizacion => {
              objCotizacion.coberturas.forEach(objCoberturaCotizacion => {
                if (objCobertura.idSubProdCondicionadoCobertura === objCoberturaCotizacion.cotizacionCobertura.idSubProdCondicionadoCobertura) {
                  objCobertura.idProductoCoberturaAntiguos = objCoberturaCotizacion.cotizacionCobertura.idProductoCoberturaAntiguos;
                  objCobertura.glosaCobertura = objCoberturaCotizacion.glosaCobertura;
                }
              })
            })
          })

          //CARGA
          objPeticion.asegurable.cargas.forEach(objCargas => {
            objCargas.coberturas.forEach(objCobertura => {

              let objCoberAux = coberturasCargas.find(objCober => objCober.idSubProdCondicionadoCobertura === objCobertura.idSubProdCondicionadoCobertura);
              objCobertura.configuracion = {...objCoberAux.configuracion};

              data_asegurable.grupos.forEach(objGrupo => {
                objGrupo.poliza.grupos.forEach(objGrupoPoliza => {
                  objGrupoPoliza.coberturas.forEach(objCoberturaPoliza => {
                    if (objCobertura.idProductoCobertura === objCoberturaPoliza.idProductoCobertura) {
                      objCobertura.codigoCotizacion = objGrupo.codigoCotizacion;
                      objCobertura.idSubProducto = objGrupo.idSubProducto;
                    }
                  })
                })
              })
              data_asegurable.cotizaciones.forEach(objCotizacion => {
                objCotizacion.coberturas.forEach(objCoberturaCotizacion => {
                  if (objCobertura.idSubProdCondicionadoCobertura === objCoberturaCotizacion.cotizacionCobertura.idSubProdCondicionadoCobertura) {
                    objCobertura.idProductoCoberturaAntiguos = objCoberturaCotizacion.cotizacionCobertura.idProductoCoberturaAntiguos;
                    objCobertura.glosaCobertura = objCoberturaCotizacion.glosaCobertura;
                  }
                })
              })
            })
          })
        }
        else{

          const { dataWizardAsegurado : { coberturasCargas, coberturas } } = this.props.store.reducer;
          //TITULAR
          objPeticion.asegurable.coberturas.forEach(objCobertura => {

            let objCoberAux = coberturas.find(objCober => objCober.idSubProdCondicionadoCobertura === objCobertura.idSubProdCondicionadoCobertura);
            objCobertura.configuracion = {...objCoberAux.configuracion};

            data_asegurable.cotizaciones.forEach(objCotizacion => {
              objCotizacion.coberturas.forEach(objCoberturaCotizacion => {
                if (objCobertura.idSubProdCondicionadoCobertura === objCoberturaCotizacion.cotizacionCobertura.idSubProdCondicionadoCobertura) {
                  objCobertura.codigoCotizacion = objCotizacion.codigoCotizacion;
                  objCobertura.idProductoCoberturaAntiguos = objCoberturaCotizacion.cotizacionCobertura.idProductoCoberturaAntiguos;
                  objCobertura.idSubProducto = objCotizacion.subProducto.idSubProducto;
                  objCobertura.glosaCobertura = objCoberturaCotizacion.glosaCobertura;
                }
              })
            })
          })

          //CARGA
          objPeticion.asegurable.cargas.forEach(objCargas => {
            objCargas.coberturas.forEach(objCobertura => {

              let objCoberAux = coberturasCargas.find(objCober => objCober.idSubProdCondicionadoCobertura === objCobertura.idSubProdCondicionadoCobertura);
              objCobertura.configuracion = {...objCoberAux.configuracion};

              data_asegurable.cotizaciones.forEach(objCotizacion => {
                objCotizacion.coberturas.forEach(objCoberturaCotizacion => {
                if (objCobertura.idSubProdCondicionadoCobertura === objCoberturaCotizacion.cotizacionCobertura.idSubProdCondicionadoCobertura) {
                    objCobertura.codigoCotizacion = objCotizacion.codigoCotizacion;
                    objCobertura.idProductoCoberturaAntiguos = objCoberturaCotizacion.cotizacionCobertura.idProductoCoberturaAntiguos;
                    objCobertura.idSubProducto = objCotizacion.subProducto.idSubProducto;
                    objCobertura.glosaCobertura = objCoberturaCotizacion.glosaCobertura;
                  }
                })
              })
            })
          })
        }

        objPeticion.asegurable.solicitud = {
          ...objPeticion.asegurable.solicitud,
          versionTerminoCondicion: this.state.versionTerminoCondicion,
        };
        this.setState({ cargando: true });
  
        let res              = await cerrarEnrolamiento(objPeticion);
        const intIdSolicitud = objPeticion.asegurable.solicitud.idSolicitud;
        
        if (res && (res.status === STATUS_EXITO || res.status === STATUS_TIMEOUT)) {
          if(res.data.status.codigo === '001'){
            await this.actualizarProceso(intIdSolicitud);
          }
          else{
            notificacion("danger", ERROR_SOLICITUD);
          }
        }
        else {
          const { usuarioSesion } = this.props.store.auth;
          let objDatosAsegurable  = await buscarAsegurablePorRut(usuarioSesion.rut);
          
          if(objDatosAsegurable){
            let objAsegurable = Object.assign({}, ...objDatosAsegurable);
            
            if (objAsegurable.idEstadoSolicitud === ESTADO_SOLICITUD_FINALIZADO) {
              await this.actualizarProceso(intIdSolicitud);
            }
            else{
              notificacion("danger", ERROR_SOLICITUD);
            }
          }
          else{
            await this.actualizarProceso(intIdSolicitud);
          }
        }
        this.setState({ cargando: false });
        this.setState({ cerrandoEnrolamiento: false });
        this.setState({ animacion: "formOut" });
      }
    } else {
      notificacion("warning", ACEPTAR_TERMINOS);
    }
  };

  actualizarProceso = async (intIdSolicitud) => {
    await eliminarDataWizardMongo(intIdSolicitud);
    this.actualizaRevisor();
    this.props.history.push("/asegurable/solicitud");
  }

  actualizaRevisor = async () => {
    const dataEnvio = {
      idNegocio: this.props.store.reducer.data_asegurable.idNegocio,
      revisado: false,
    };
    let actualizarRevisor = await actualizarUsuarioRevisor(dataEnvio);
    if (actualizarRevisor === COD_STATUS_ERROR) {
      notificacion("danger", ERROR_EDICION);
    }
  };

  anteriorPagina = () => {
    scroll.scrollToTop();

    const pagina = this.props.store.reducer.estructuraFormulario.beneficiarios
      ? PAGINA_4
      : PAGINA_3;

    this.props.wizard({ pagina, type: TIPO_PAGINA });
  };

  async componentWillMount() {
    setTimeout(() => {
      if (this.props.store.reducer.wizard.pagina === PAGINA_4) {
        this.setState({ animacion: "movingIn" });
      }
    }, 100);
  }

  render() {

    return this.state.cargando ? (
      <Spinner
        claseColor="naranjo"
        idSpinner="spinnerNaranjo"
        posicionLogo="align-self-center w-100"
      />
    ) : (
      <div id="resumen">
        <div
          id="resumen"
          className="cuerpo-wizard bg-white container p-3 p p-lg-5"
        >
          <form className="animated fadeInRight container-resumen">
            <div className="row">
              <div className="col-md-5 align-self-center">
                <img
                  src={logo}
                  alt="Vida Cámara"
                  className="img-gray img-fluid d-block mx-auto"
                />
              </div>
              <div className="col-md-7 align-self-center">
                <p className="my-auto text-justify text-muted">
                  <b>IMPORTANTE</b>. Usted está solicitando su incorporacion
                  como Asegurado a una Póliza o Contrato de Seguros Colectivos
                  cuyas condiciones han sido convenidas por el Contratante
                  individualizado anteriormente, directamente con la Compañia de
                  Seguros.
                </p>
              </div>
            </div>
            <hr />
            <DatosEmpresa />
            <DatosPersonales />
            {!this.props.store.reducer.informacionPoliza.soloProductoVida && (
              <Cargas />
            )}
            <Declaraciones />
            {this.state.dataResumen && !this.state.soloSalud && (
              <SeguroVida
                informacionPoliza={this.props.store.reducer.informacionPoliza}
                dataWizard={this.state.dataWizard}
                dataWizardAsegurado={this.state.dataResumen}
                canalVenta={this.props.store.reducer.data_asegurable.canalVenta}
              />
            )}
            {this.props.store.reducer.dataWizardAsegurado.beneficiarios && (
              <Beneficiarios />
            )}
            <br />
            <Consentimientos />
            <hr />
            <div className="row mt-2">
              <div className="col-12 mt-2">
                <div className="row">
                  <div className="col-1 text-center align-self-center">
                    <label className="switch">
                      <input
                        id="checkboxDeclaroConocimiento"
                        checked={this.state.checkedTerminos}
                        onChange={this.updateChecked}
                        type="checkbox"
                      />
                      <span className="slider round" />
                    </label>
                  </div>
                  <div className="col-11">
                    <span
                      id="textDeclaroConocimiento"
                      onClick={this.updateChecked}
                    >
                      {DECLARACION_TERMINOS_CONDICIONES}
                    </span>{" "}
                  </div>
                  <div className="col-1"></div>
                  <div className="col-11">
                    <span>
                      <a
                        id="linkTerminoCondicion"
                        className={
                          "text-primary"
                        }
                        href={
                          this.state.rutaTerminoCondicion
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        TyC de Uso del Sistema Suscripción Digital
                      </a>
                      .
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div >

        <div className="button-zone text-right pt-3 pb-3">
          <button
            id="botonAtras"
            className="btn btn-principal color-secundario btn-lg"
            type="button"
            onClick={this.anteriorPagina}
          >
            Atrás
          </button>

          <button
            id="botonFinalizar"
            className="btn btn-principal btn-lg"
            type="button"
            disabled={this.state.cerrandoEnrolamiento}
            onClick={() => this.cerrarEnrolamiento()}
          >
            {this.state.cerrandoEnrolamiento ? (
              <Fragment>
                <i className="fa fa-spinner fa-pulse fa-fw"></i>
                <span className="sr-only">Loading...</span>
              </Fragment>
            ) : (
              <span>Finalizar</span>
            )}
          </button>
        </div>
      </div >
    );
  }
}

const mapStateToProps = (state) => ({
  store: state,
});

export default withRouter(
  connect(mapStateToProps, {
    wizard,
    actualizar_progreso_asegurable,
    update_carga,
    update_detalle,
    guardar_data_wizard_asegurado,
    resetSesion,
    get_ciudades,
  })(Resumen)
);
