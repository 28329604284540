import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Contenido from "../../../transversales/Contenido";
import {TIPO_INCORPORACION_MANTENCION} from "../../../../../utils/Constantes";
import { guardarTipoIncorporacion } from "../../../../../actions/actionsAuth";

class EjecutivoMantencion extends Component {
  state = {
    menu: this.props.store.auth.usuarioSesion.menu,
  };

  componentDidMount() {
    this.props.guardarTipoIncorporacion(TIPO_INCORPORACION_MANTENCION)
  }


  render() {
    return <Contenido menu={this.state.menu} routes={this.props.routes} />;
  }
}

const mapStateToProps = (state) => ({
  store: state,
});

export default withRouter(connect(mapStateToProps, {guardarTipoIncorporacion})(EjecutivoMantencion));
