import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import ModalEditarAsegurable from "../ModalEditarAsegurable";
import { validator } from "../../../../../utils/Functions";
import { notificacion } from "../../../transversales/Notificaciones";
import { asignarEjecutivo } from "../../../../../services/cotizacion.services";
import { enviarOrganizacionSucursalEjecutivo } from "../../../../../services/negocios.service";
import { guardar_negocios } from "../../../../../actions/actions";
import {
  EXITO_ASIGNAR_EJECUTIVO,
  EXITO_REASIGNAR_EJECUTIVO,
  ERROR_SOLICITUD,
  TITULO_ASIGNAR,
  TITULO_REASIGNAR,
} from "../../../../../utils/Constantes";

class ModalAsignarEjecutivo extends Component {
  state = {
    ejecutivos: this.props.store.reducer.ejecutivos,
    negocio: this.props.negocio,
    negocios: this.props.store.reducer.negocios["activos"],
    regiones: this.props.store.reducer.regiones,
    ultimaCotizacion: this.props.ultimaCotizacion,
    tipoNegocio: this.props.tipoNegocio,
    value: this.props.value,
    action: this.props.action,
    mailEjecutivoAnterior: "",
    nombreEjecutivoAnterior: "",
    idEjecutivoAnterior: 0,
    nuevoEjecutivo: null,
    loadingData: false,
    dataEnvioAsignar: {
      emailEjecutivoAnterior: this.props.negocio.emailEjecutivoAsignado,
      emailEjecutivoNuevo: this.props.negocio.emailEjecutivoAsignado,
      nombreEjecutivoAnterior:
        this.props.negocio.ejecutivoAsignado === "Por asignar"
          ? ""
          : this.props.negocio.ejecutivoAsignado,
      nombreEjecutivoNuevo: "",
      nombreJefatura: this.props.store.auth.usuarioSesion.nombre,
      idPersonaEjecutivo: 0,
      personaDelegado: {
        apellidoPaterno: this.props.ultimaCotizacion.apellidoPaterno,
        cargo: this.props.ultimaCotizacion.cargo,
        digitoVerificador: this.props.ultimaCotizacion.rutDelegado.split(
          "-"
        )[1],
        email: this.props.ultimaCotizacion.email,
        nombre: this.props.ultimaCotizacion.nombre,
        rut: Number(this.props.ultimaCotizacion.rutDelegado.split("-")[0]),
        telefono: this.props.ultimaCotizacion.telefono,
      },
      empresa: {
        digitoVerificador: this.props.negocio.digitoVerificador,
        numeroDotacion: this.props.ultimaCotizacion.numeroDotacion,
        razonSocial: this.props.negocio.razonSocial,
        rutEmpresa: this.props.negocio.rutEmpresa,
      },
      negocio: {
        idNegocio: Number(this.props.negocio.idNegocio),
        idPersonaEjecutivo: 0,
        region: {
          glosa: this.props.negocio.region.glosa,
        },
      },
      cotizacion: {
        codigoCotizacion: this.props.ultimaCotizacion.codigoCotizacion,
        descripcion: this.props.ultimaCotizacion.descripcion,
        fechaInicioVigencia: this.props.ultimaCotizacion.fechaInicioVigencia,
        numeroCarga: this.props.ultimaCotizacion.numeroCarga,
        primaBruta: this.props.ultimaCotizacion.primaBruta,
        titularSinCarga: this.props.ultimaCotizacion.titularSinCarga,
        titularConUnaCarga: this.props.ultimaCotizacion.titularConUnaCarga,
        titularConDosMasCargas: this.props.ultimaCotizacion
          .titularConDosMasCargas,
      },
    },
  };

  componentDidMount() {
    let idEjecutivoAnterior = this.state.negocio.idPersonaEjecutivo;
    let objetoEjecutivo = this.state.ejecutivos.find(
      (item) => idEjecutivoAnterior === item.idPersona
    );

    if (objetoEjecutivo) {
      let mailEjecutivoAnterior = objetoEjecutivo.correoElectronico;
      let nombreEjecutivoAnterior = `${objetoEjecutivo.nombre} ${objetoEjecutivo.apellidoPaterno}`;
      this.setState({
        mailEjecutivoAnterior,
        nombreEjecutivoAnterior,
        idEjecutivoAnterior,
      });
    }
  }

  handleChange = (e) => {
    const nuevoEjecutivo = this.state.ejecutivos.find(
      (item) => Number(item.idPersona) === Number(e.target.value)
    );

    if (nuevoEjecutivo === undefined) return this.setState({ value: 0 });

    this.setState({
      idEjecutivoAnterior: nuevoEjecutivo.idPersona,
      nuevoEjecutivo,
      value: nuevoEjecutivo.idPersona,
      emailNuevoEjecutivo: nuevoEjecutivo.correoElectronico,
    });
  };

  submit = async () => {
    this.setState({loadingData: true});
    let dataEnvio = Object.assign({}, this.state.dataEnvioAsignar);
    const ejecutivo = this.state.nuevoEjecutivo;

    if (ejecutivo === null) return;

    dataEnvio.nombreEjecutivoNuevo = `${ejecutivo.nombre} ${ejecutivo.apellidoPaterno} ${ejecutivo.apellidoMaterno}`;
    dataEnvio.idPersonaEjecutivo = this.state.negocio.idPersonaEjecutivo;
    dataEnvio.negocio.idPersonaEjecutivo = ejecutivo.idPersona;
    dataEnvio.emailEjecutivoNuevo = this.state.emailNuevoEjecutivo;
    dataEnvio.emailEjecutivoAnterior = this.state.mailEjecutivoAnterior;

    const res = await asignarEjecutivo(dataEnvio);

    if (res !== null && res.status.codigo === "001") {
      // Crear/Actualiza sucursal ejecutivo
      this.crearActualizarEjecutivoSucursal(ejecutivo.idPersona);

      if (this.state.action === TITULO_ASIGNAR) {
        notificacion("success", EXITO_ASIGNAR_EJECUTIVO);
      } else {
        notificacion("success", EXITO_REASIGNAR_EJECUTIVO);
      }
    } else {
      notificacion("danger", ERROR_SOLICITUD);
    }
    this.setState({
      action: TITULO_REASIGNAR,
    });

    this.setState({ value: 0, loadingData:false });
    this.props.actualizarEjecutivoAsignado(ejecutivo);
    this.props.mostrarModalAsignacion(false);
    this.actualizarNegocios(ejecutivo);
  };

  async crearActualizarEjecutivoSucursal(idEjecutivo) {
    const sucursal = this.props.negocio.sucursales[0];
    const idUsuario = this.props.store.auth.usuarioSesion.id;

    if (sucursal) {
      if (sucursal.organizacionSucursalEjecutivo) {
        let dataEnvio = {
          idPersona: idEjecutivo,
          idUsuarioModifica: idUsuario,
          idOrganizacionSucursal: sucursal.idOrganizacionSucursal,
          idOrganizacionSucursalEjecutivo:
            sucursal.organizacionSucursalEjecutivo
              .idOrganizacionSucursalEjecutivo,
        };

        await enviarOrganizacionSucursalEjecutivo(dataEnvio);
      }
    }
  }

  actualizarNegocios(ejecutivo) {
    let negocios = Object.assign([], this.props.store.reducer.negocios);
    const idNegocio = this.state.negocio.idNegocio;

    negocios.forEach((n) => {
      if (n.idNegocio === idNegocio) {
        n.ejecutivoAsignado = `${ejecutivo.nombre} ${ejecutivo.apellidoPaterno} ${ejecutivo.apellidoMaterno}`;
        n.emailEjecutivoAsignado = ejecutivo.mail;
        n.idEjecutivoAsignado = ejecutivo.idPersona;
        n.idPersonaEjecutivo = ejecutivo.idPersona;
      }
    });

    this.props.guardar_negocios(negocios);
  }

  render() {
    let ejecutivos = this.state.ejecutivos;

    if (this.props.negocio.idEjecutivoAsignado) {
      ejecutivos = this.state.ejecutivos.filter(
        (item) =>
          Number(item.idPersona) !==
          Number(this.props.negocio.idEjecutivoAsignado)
      );
    }

    return (
      <ModalEditarAsegurable
        idSubmit="submitAsignarEjecutivo"
        type="small"
        show={this.props.modalAsignacion}
        title="Asignar Ejecutivo"
        action={this.state.action}
        loadingData={this.state.loadingData}
        onSubmit={() => {
          this.submit();
        }}
        onCancel={() => {
          this.props.mostrarModalAsignacion(false);
          validator(
            document.querySelectorAll(
              "form[name=form-declaracion] input, form[name=form-declaracion] select",
              true
            ),
            true
          );
        }}
        onClose={() => {
          this.props.mostrarModalAsignacion(false);
          validator(
            document.querySelectorAll(
              "form[name=form-declaracion] input, form[name=form-declaracion] select",
              true
            ),
            true
          );
        }}
        showCancelButton={true}
        showCloseButton={true}
        showLeftColumn={false}
        showSubmitButton={true}
      >
        <form name="form-declaracion" ref="formRef" onSubmit={this.submit}>
          <div className="row">
            <div className="col-12 col-md-8 offset-md-2">
              <div className="form-group">
                <label htmlFor="declarante">Nombre Ejecutivo</label>
                <select
                  id="selectAsignarEjecutivo"
                  value={this.state.value}
                  ref="nombreRef"
                  type="text"
                  className="form-control form-control-sm"
                  name="nombre1"
                  onChange={this.handleChange}
                  required
                >
                  <option defaultValue="SELECCIONE UN EJECUTIVO" id="optionDefault">
                    SELECCIONE UN EJECUTIVO
                  </option>

                  {ejecutivos !== null &&
                    ejecutivos.length > 0 &&
                    ejecutivos.map((item, i) => (
                      <option
                        id={`option${i}`}
                        name="nombre"
                        onChange={this.handleChange}
                        key={i}
                        value={item.idPersona}
                      >
                        {`${item.nombre} ${item.apellidoPaterno} ${item.apellidoMaterno}`}
                      </option>
                    ))}
                </select>
              </div>
              <p>
                Si presiona el botón
                {this.state.action === "Asignar" ? " Asignar" : " Reasignar"} se
                enviará un correo al ejecutivo
                {this.state.action === "Asignar"
                  ? " Asignado"
                  : " Reasignado. También se enviará un correo al ejecutivo anterior"}
                .
              </p>
            </div>
          </div>
        </form>
      </ModalEditarAsegurable>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state,
  negocioRedux: state.negocio,
});

export default withRouter(
  connect(mapStateToProps, { guardar_negocios })(ModalAsignarEjecutivo)
);
