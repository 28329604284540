import React, { useEffect, useState, Fragment } from "react";
import { Form } from "formik";
import MultiSelect from "../../../../../transversales/MultiSelect";
import { PRODUCTO_VIDA, PRODUCTO_VIDA_PYME } from "../../../../../../../utils/ConstantesVentaDirecta";
import {
  COD_CORREDOR,
  COD_DELEGADO,
  COD_EJECUTIVO,
  COD_EJECUTIVO_COMPLETO,
  COD_EJECUTIVO_MANTENCION,
  ERROR_EDICION,
  ID_ZERO,
  MASK_DATE,
  PERFIL_DELEGADO,
  PERFIL_LDAP,
  POSICION_INICIAL,
  RUT_DELEGADO,
  RUT_EJECUTIVO,
  SUMAR_UNO_CONTADOR,
  TIPO_INCORPORACION_MANTENCION,
  USU_CORREDOR,
  VALOR_INICIAL_CONTADOR,
} from "../../../../../../../utils/Constantes";

import { notificacion } from "../../../../../transversales/Notificaciones";

import DeclaracionAceptacion from "../../../DeclaracionAceptacion";
import SelectContratante from "../../SelectContratante";
import { listarMisSolicitudes } from "../../../../../../../services/asegurable.services";

import CargarArchivo from "../../../../../transversales/CargarArchivo";
import moment from "moment";
import DatePicker from "react-datepicker";
import MaskedTextInput from "react-text-mask";
import { InputEmail, InputRut, InputTelefono, InputTexto } from "./InputForm";

const FormAsegurable = ({ formik, props }) => {
  const { values, errors, touched, setFieldTouched, setFieldValue, usuarioSesion, handleBlur } = formik;

  const { intCanalVenta, intTipoIncorporacion, objCotizacion, blnCargando, listTipoProductos } = props;

  const [blnContinuidadCobertura, actualizarBlnContinuidadCobertura] = useState(false);
  const [blnAceptacion, actualizarBlnAceptacion] = useState(
    usuarioSesion.rol.codigo === COD_EJECUTIVO || usuarioSesion.rol.codigo === COD_EJECUTIVO_COMPLETO
  );
  const [blnLimpiarAceptacion, actualizarBlnLimpiarAceptacion] = useState(false);
  const [blnDatosCargados, actualizarBlnDatosCargados] = useState(false);
  const intRolId = usuarioSesion.rol.codigo;
 
  /************inicio mantencion***************/
  const [blnBusquedaPlan, actualizarBlnBusquedaPlan] = useState(false);
  const [intContador, actualizarIntContador] = useState(0);
  const [blnBloquearBusquedaPlan, actualizarBlnBloquearBusquedaPlan] = useState(true);
  const [idNegocio, actualizarIdNegocio] = useState(0);
  const [listPolizas, actualizarListPolizas] = useState([]);
  const [listPolizasSeleccionadas, actualizarListPolizasSeleccionadas] = useState([]);
  const [blnLimpiarArchivos, actualizarBlnLimpiarArchivos] = useState(false);
  const [blnFormulario, actualizarFormulario] = useState(false);
  const [strMinFechaVigencia, actualizarStrMinFechaVigencia] = useState("");
  const [strMaxFechaVigencia, actualizarStrMaxFechaVigencia] = useState("");
  const blnCheckedMovRetro = false;
  const strNombreDocumento = "DOCTO_TEMPORAL";

  const aceptarBusquedaPlan = () => {
    actualizarBlnBusquedaPlan(blnBusquedaPlan);
    actualizarIntContador(intContador + SUMAR_UNO_CONTADOR);
  };

  const obtenerPolizas = async (idNegocio, listPolizas, polizasFiltro, rutContratante) => {
    actualizarBlnBloquearBusquedaPlan(true);

    const campoPerfil =
    usuarioSesion.rol.codigo=== COD_DELEGADO
        ? RUT_DELEGADO
        : usuarioSesion.rol.codigo=== COD_EJECUTIVO_MANTENCION || usuarioSesion.rol.codigo=== COD_EJECUTIVO_COMPLETO
        ? RUT_EJECUTIVO
        : USU_CORREDOR;

    const dataEnvioSolicitudes = {
      [campoPerfil]:
      usuarioSesion.rol.codigo === COD_DELEGADO ||
      usuarioSesion.rol.codigo=== COD_EJECUTIVO_MANTENCION ||
      usuarioSesion.rol.codigo === COD_EJECUTIVO_COMPLETO
          ? usuarioSesion.rut
          : usuarioSesion.rol.codigo,
      rutContratante: Number(rutContratante.split("-")[0]),
      idTipoIncorporacion: TIPO_INCORPORACION_MANTENCION,
      fechaEnvioDesde: null,
      fechaEnvioHasta: null,
      polizaVigente: true,
      perfil: usuarioSesion.rol.codigo === COD_DELEGADO ? PERFIL_DELEGADO : PERFIL_LDAP,
    };
    const solicitudes = await listarMisSolicitudes(dataEnvioSolicitudes);

    listPolizas.forEach((poliza) => {
      if (poliza.poliza.agrupaciones && poliza.poliza.agrupaciones.length > POSICION_INICIAL) {
        actualizarBlnBloquearBusquedaPlan(false);
      }
    });
    if (solicitudes) {
      actualizarIdNegocio(idNegocio);
      actualizarListPolizas(listPolizas);
    } else {
      notificacion("danger", ERROR_EDICION);
    }

    if (blnBloquearBusquedaPlan) {
      actualizarBlnBusquedaPlan(false);
      actualizarIntContador(VALOR_INICIAL_CONTADOR);
    }
  };

  //Cambia cada ves seleccionas el input
  const llenarPolizasSeleccionadas = (item) => {
    actualizarListPolizasSeleccionadas(item);
    actualizarBlnLimpiarArchivos(false);
    actualizarBlnLimpiarAceptacion(false);
   
  };

  useEffect(() => {
    obtenerMes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const obtenerMinDiasInclusion = () => {
    //obteniendo el menor dia de inclusion
    const polizasSeleccionadasVigencia = listPolizasSeleccionadas ? listPolizasSeleccionadas : [];
    let minDiasInclusion = 0;
    if (polizasSeleccionadasVigencia.length > 0) {
      minDiasInclusion = polizasSeleccionadasVigencia[0].poliza.diasInclusion;
      polizasSeleccionadasVigencia.forEach((element) => {
        if (element.poliza.diasInclusion < minDiasInclusion) {
          minDiasInclusion = element.poliza.diasInclusion;
        }
      });
    }
    return minDiasInclusion;
  };

  const obtenerMaxFechaVigencia = () => {
    //obteniendo mayor fecha de vigencia entre polizas seleccionadas
    const polizasSeleccionadasVigencia = listPolizasSeleccionadas ? listPolizasSeleccionadas : [];

    let polizaFechaFinVigencia = 0;
    if (polizasSeleccionadasVigencia.length > 0) {
      polizasSeleccionadasVigencia.forEach((element) => {
        if (element.poliza.fechaFinVigencia > polizaFechaFinVigencia) {
          polizaFechaFinVigencia = element.poliza.fechaFinVigencia;
        }
      });
    }
    return polizaFechaFinVigencia;
  };
  const cargarDatePicker = (value) => {
    if (blnCheckedMovRetro && value !== null && value.length > 0) {
      let diasInclusion = obtenerMinDiasInclusion();
      let startDates = value.map((item) => {
        return moment(moment(item.poliza.fechaInicioVigencia).format("YYYY-MM-DD"), "YYYY-MM-DD");
      });
      let now = moment();
      let dia = Number(now.format("D"));
      let anio = now.year();
      let mes = now.month();
      if (dia <= diasInclusion) {
        let mesAnterior = moment(`${anio}-${mes}-01 00:00:00`, "YYYY-MM-DD hh:mm:ss");
        actualizarStrMinFechaVigencia(moment(moment.min(startDates)).toDate());
        actualizarStrMaxFechaVigencia(moment(mesAnterior.clone().endOf("month")).toDate());
      } else {
        let fechaMaxima = moment(`${anio}-${mes + 1}-${diasInclusion} 00:00:00`, "YYYY-MM-DD hh:mm:ss");
        actualizarStrMinFechaVigencia(moment(moment.min(startDates)).toDate());
        actualizarStrMaxFechaVigencia(moment(moment.min(fechaMaxima)).toDate());
      }
    } else {
      obtenerMes();
    }
  };

  const obtenerMes = () => {
    if (!blnCheckedMovRetro) {
      let diasInclusion = obtenerMinDiasInclusion();
      let fechaMaxVigencia = obtenerMaxFechaVigencia();

      fechaMaxVigencia = moment(fechaMaxVigencia).format("YYYY-MM-DD");

      let now = moment();
      let anio = now.year();
      let mes = now.month();
      let dia = Number(now.format("D"));

      if (dia <= diasInclusion) {
        let mesActual = moment(`${anio}-${mes + 1}-01 00:00:00`, "YYYY-MM-DD hh:mm:ss");

        actualizarStrMinFechaVigencia(mesActual.toDate());
        actualizarStrMaxFechaVigencia(moment(`${fechaMaxVigencia} 00:00:00`, "YYYY-MM-DD hh:mm:ss").toDate());
      } else {
        let mesSiguiente = moment(`${anio}-${mes + 2}-01 00:00:00`, "YYYY-MM-DD hh:mm:ss");

        actualizarStrMinFechaVigencia(mesSiguiente.toDate());
        actualizarStrMaxFechaVigencia(moment(`${fechaMaxVigencia} 00:00:00`, "YYYY-MM-DD hh:mm:ss").toDate());
      }
    }
  };
  useEffect(() => {
    cargarDatePicker(listPolizasSeleccionadas);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listPolizasSeleccionadas]);

  /********************* fin mantencion ************/

  useEffect(() => {
    const hasErrors = Array.isArray(errors) ? errors.length > 0 : Object.values(errors).some((error) => error);

    if (blnAceptacion) {
      actualizarBlnDatosCargados(!hasErrors);
    } else {
      actualizarBlnDatosCargados(false);
    }
  }, [errors, blnAceptacion]);

  useEffect(() => {
    if (intTipoIncorporacion === 2) {
      if (idNegocio !== ID_ZERO) actualizarFormulario(true);
    }

    if (intCanalVenta === 2) actualizarFormulario(true);
    if (intCanalVenta === 1) actualizarFormulario(true);
  }, [intTipoIncorporacion, idNegocio, intCanalVenta]);

  const consultarContinuidadCobertura = async (event) => {
    actualizarBlnContinuidadCobertura(false);
    if (event) {
      event.forEach((e) => {
        const producto = objCotizacion.find((item) => Number(e.value) === Number(item.subProducto.idSubProducto));
        producto.coberturas.forEach((c) => {
          if (c.cotizacionCobertura.continuidadCobertura) {
            actualizarBlnContinuidadCobertura(true);
          }
        });
      });
    }
  };

  const obtenerCoberturas = async (event, setFieldValue) => {
    if (event) {
      let tiposCapital2 = [];
      event.forEach((e) => {
        if (Number(e.value) === PRODUCTO_VIDA || Number(e.value) === PRODUCTO_VIDA_PYME) {
          const tiposCapital = objCotizacion.filter((item) => Number(e.value) === Number(item.subProducto.idSubProducto));

          if (tiposCapital2.includes(tiposCapital[0])) {
          } else {
            tiposCapital2.push(tiposCapital[0]);
          }
        }
      });
    }
  };
  return (
    <Form
      className="mx-5"
      onKeyDown={(e) => {
        if ((e.charCode || e.keyCode) === 13) {
          e.preventDefault();
        }
      }}
    >
      <div className="row animated">
        {blnContinuidadCobertura && (
          <div className="col-md-12 ">
            <div className="form-group infoContinuidadCobertura form-group">
              <div className="row">
                <div className="col-1 pl-0 pr-2 pr-xl-3 d-flex justify-content-end">
                  <div
                    style={{
                      width: 26,
                      height: 26,
                      marginTop: "3px",
                      backgroundColor: "#e89b03",
                      borderRadius: 20,
                    }}
                  >
                    <span className="beneficiarioI text-white">i</span>
                  </div>
                </div>
                <div className="col-11">
                  <div className="row font-weight-bold" style={{ fontSize: "0.85rem" }}>
                    Información importante
                  </div>
                  <div className="row">
                    <p className="m-0" style={{ fontSize: "0.75rem" }}>
                      Solo debe ingresar asegurable sin{" "}
                      <span className="font-weight-bold" style={{ color: "#e89b03" }}>
                        continuidad de cobertura
                      </span>
                    </p>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* Habilitar Mantencion*/}
        {intTipoIncorporacion === 2 && (
          <>
            <SelectContratante obtenerPolizas={obtenerPolizas} busquedaPlan={blnBusquedaPlan} contador={intContador} />
            <>
              {blnFormulario && (
                <div className="col-md-12">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-1 align-self-center">
                        <label className="switch">
                          <input
                            checked={blnBusquedaPlan}
                            onChange={() => {
                              aceptarBusquedaPlan();
                            }}
                            type="checkbox"
                            disabled={blnBloquearBusquedaPlan}
                          />
                          <span className="slider round" />
                        </label>
                      </div>
                      <div className="col-11" style={{ paddingLeft: "0px" }}>
                        <label> Búsqueda por Plan</label>
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <label>Póliza</label>
                    <MultiSelect
                      value={values.poliza}
                      onChange={(value) => {
                        llenarPolizasSeleccionadas(value);
                        setFieldValue("poliza", value);
                      }}
                      tooltip={true}
                      onBlur={setFieldTouched}
                      name="poliza"
                      id="poliza"
                      options={listPolizas}
                      noOptionsMessage={() => "No existen más pólizas para este contratante"}
                      placeholder={"Ingrese póliza/s"}
                    />
                    {errors.poliza && <small className="text-danger animated fadeIn">{errors.poliza}</small>}
                  </div>
                </div>
              )}
            </>
          </>
        )}

        {/* 1. tipoDeProducto */}
        {intCanalVenta === 2 && (
          <div className="col-md-12">
            <div className="form-group mb-2">
              <label className="font-weight-bold">
                Tipo de producto <span className="text-danger">*</span>
              </label>

              <MultiSelect
                value={values.subProducto}
                onChange={(e) => {
                  setFieldValue("subProducto", e);
                  obtenerCoberturas(e, setFieldValue);
                  consultarContinuidadCobertura(e);
                }}
                tooltip={true}
                onBlur={setFieldTouched}
                name="subProducto"
                options={listTipoProductos}
                noOptionsMessage={() => "No existen más subProducto para seleccionar"}
                placeholder={"Ingrese subProducto/s"}
                errors={errors.subProducto}
                touched={touched.subProducto}
              />
            </div>
          </div>
        )}

        {/* rut */}
        {blnFormulario && (
          <div className="col-md-12">
            <div className="form-group">
              <label>
                Rut colaborador <span className="text-danger">*</span>
              </label>

              <InputRut
                values={values}
                actualizarLimpiarAceptacion={actualizarBlnLimpiarAceptacion}
                errors={errors.rut}
                touched={touched.rut}
              />
            </div>
          </div>
        )}

        {/* nombres */}
        {blnFormulario && (
          <div className="col-md-12">
            <div className="form-group">
              <label>
                Nombres <span className="text-danger">*</span>
              </label>

              <InputTexto
                type={"nombres"}
                values={values.nombres}
                errors={errors.nombres}
                touched={touched.nombres}
                placeholder={"Ingrese nombre"}
              />
            </div>
          </div>
        )}

        {/* apellidoPaterno */}
        {blnFormulario && (
          <div className="col-md-6">
            <div className="form-group">
              <label>
                Apellido paterno <span className="text-danger">*</span>
              </label>

              <InputTexto
                type={"apellidoPaterno"}
                values={values.apellidoPaterno}
                errors={errors.apellidoPaterno}
                touched={touched.apellidoPaterno}
                placeholder={"Ingrese apellido paterno"}
              />
            </div>
          </div>
        )}
        {/* apellidoMaterno */}
        {blnFormulario && (
          <div className="col-md-6">
            <div className="form-group">
              <label>Apellido materno</label>

              <InputTexto
                type={"apellidoMaterno"}
                values={values.apellidoMaterno}
                errors={null}
                touched={null}
                placeholder={"Ingrese el apellido materno"}
              />
            </div>
          </div>
        )}

        {/* email */}
        {blnFormulario && (
          <div className="col-md-6">
            <div className="form-group">
            <label>Email</label>
              <InputEmail values={values.email} errors={errors.email} touched={touched.email} />
            </div>
          </div>
        )}

        {/* teléfono */}
        {blnFormulario && (
          <div className="col-md-6">
            <div className="form-group">
              <label>Teléfono móvil</label>
              <InputTelefono values={values.telefonoMovil} errors={errors.telefonoMovil} touched={touched.telefonoMovil} />
            </div>
          </div>
        )}
        {/* Fecha Inicio Vigencia */}
        {blnFormulario && intCanalVenta !== 1 && intCanalVenta !== 2 && (
          <div className="col-md-12">
            <div className="form-group up-calendar">
              <label>Fecha Inicio Vigencia Propuesta</label>

              <DatePicker
                autoComplete="off"
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                id="fechaInicioVigencia"
                name="fechaInicioVigencia"
                value={values.fechaInicioVigencia}
                dateFormat="dd/MM/yyyy"
                placeholderText="DD/MM/YYYY"
                locale="es"
                onBlur={handleBlur}
                onChange={(value) => {
                  value = moment(value).format("DD/MM/YYYY");
                  setFieldValue("fechaInicioVigencia", value);
                }}
                customInput={<MaskedTextInput className="form-control" placeholder="DD/MM/YYYY" mask={MASK_DATE} />}
                minDate={strMinFechaVigencia}
                maxDate={strMaxFechaVigencia}
              />

              {errors.fechaInicioVigencia && touched.fechaInicioVigencia && (
                <small className="text-danger animated fadeIn">{errors.fechaInicioVigencia}</small>
              )}
            </div>
          </div>
        )}

        {/* Adjuntar Documentos */}
        {blnFormulario && intCanalVenta !== 1 && intCanalVenta !== 2 && (
          <div className="col-md-12">
            <div className="form-group">
              <label>Adjuntar documentos</label>
              <CargarArchivo
                children={null}
                maxFiles={3}
                minFiles={1}
                filename={strNombreDocumento}
                files={null}
                fromCargas={false}
                cleanFiles={blnLimpiarArchivos}
              />
            </div>
          </div>
        )}

        {/* Declaracion Aceptacion */}
        {blnFormulario && (
          <DeclaracionAceptacion
            aceptacion={() => actualizarBlnAceptacion(!blnAceptacion)}
            rolId={intRolId}
            codPerfil={[COD_CORREDOR, COD_DELEGADO]}
            limpiar={blnLimpiarAceptacion}
          />
        )}
      </div>

      <div className="button-zone text-right pt-3 pb-3">
        <button id="guardar" className="btn btn-principal mr-3" type="submit" disabled={!blnDatosCargados}>
          {blnCargando ? (
            <Fragment>
              <i className="fa fa-spinner fa-pulse fa-fw"></i>
              <span className="sr-only">Loading...</span>
            </Fragment>
          ) : (
            <span>Guardar</span>
          )}
        </button>
      </div>
    </Form>
  );
};

export default FormAsegurable;
