import ServicesFactory from "../config/servicesFactory";
import { COD_STATUS_EXITO, STATUS_EXITO } from "../utils/Constantes";

let services = new ServicesFactory();

export async function listarSubProductos() {
  try {
    let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/producto/listarSubProductos`;
    let respuesta = await services.get(url, false);

    return respuesta !== undefined &&
      respuesta !== null &&
      respuesta.status === STATUS_EXITO &&
      respuesta.data.status.codigo === COD_STATUS_EXITO
      ? respuesta.data.objeto
      : [];
  } catch (error) {
    return null;
  }
}

export async function listarCondicionesSubProducto(idSubProducto) {
  try {
    let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/producto/listarCondicionCoberturaPorIdSubProducto/${idSubProducto}`;
    let respuesta = await services.get(url, false);

    return respuesta !== undefined &&
      respuesta !== null &&
      respuesta.status === STATUS_EXITO &&
      respuesta.data.status.codigo === COD_STATUS_EXITO
      ? respuesta.data.objeto
      : [];
  } catch (error) {
    return null;
  }
}

export async function crearConfigurarProducto(data) {
  try {
    let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/cotizacion/agregarProducto`;
    let respuesta = await services.post(url, data, false);

    return respuesta !== undefined &&
      respuesta !== null &&
      respuesta.status === STATUS_EXITO &&
      respuesta.data.status.codigo === COD_STATUS_EXITO
      ? respuesta.data.objeto
      : null;
  } catch (error) {
    return null;
  }
}

export async function actualizarConfigurarProducto(data) {
  try {
    let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/cotizacion/actualizarProducto`;
    let respuesta = await services.post(url, data, false);

    return respuesta !== undefined &&
      respuesta !== null &&
      respuesta.status === STATUS_EXITO &&
      respuesta.data.status.codigo === COD_STATUS_EXITO
      ? respuesta.data.objeto
      : null;
  } catch (error) {
    return null;
  }
}

export async function buscarSubProducto(idSubProdCondicionadoCobertura) {
  try {
    let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/producto/buscarSubProductoPorIdCondicionadoCobertura/${idSubProdCondicionadoCobertura}`;
    let respuesta = await services.get(url, false);

    return respuesta !== undefined &&
      respuesta !== null &&
      respuesta.status === STATUS_EXITO &&
      respuesta.data.status.codigo === COD_STATUS_EXITO
      ? respuesta.data.objeto
      : null;
  } catch (error) {
    return null;
  }
}

export async function obtenerDocumentoCondicionado(glosaCondicionado) {
  try {
    let url = `${process.env.REACT_APP_API_URL_DOCUMENTO_2}/documento/obtenerDoctoPol/?nombreDoc=${glosaCondicionado}`;
    let respuesta = await services.get(url, false);

    return respuesta !== undefined && respuesta !== null && respuesta.data.status.codigo === "001"
      ? respuesta.data.objeto
      : null;
  } catch (error) {
    return null;
  }
}


export async function obtenerDocumentoPoliza(rutContratante, nroPoliza, nroRenovacion) {
  try {
    let url = `${process.env.REACT_APP_API_GATEWAY_BACKMOVIL_URL}/documento/obtenerDoctoPolizaPorContratante/${rutContratante}/${nroPoliza}/${nroRenovacion}`;
    let respuesta = await services.get(url, false);

    return respuesta !== undefined && respuesta !== null && respuesta.data.status.codigo === "001"
      ? respuesta.data.objeto
      : null;
  } catch (error) {
    return null;
  }
}

export async function listarSubProducto(idSubProducto) {
  try {
    let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/producto/listarSubProductoCoberturaValorPorIdSubProducto/${idSubProducto}`;
    let respuesta = await services.get(url, false);

    return respuesta !== undefined &&
      respuesta !== null &&
      respuesta.status === STATUS_EXITO &&
      respuesta.data.status.codigo === COD_STATUS_EXITO
      ? respuesta.data.objeto
      : null;
  } catch (error) {
    return null;
  }
}
