import Files from "react-files";
import {
    _JPEG,
    _JPG,
    _PDF,
    _PNG,
    CODIGO_ERROR_1,
    CODIGO_ERROR_2,
    ERROR_CARGAR_ARCHIVO,
    EXITO_CARGAR_ARCHIVO,
    VALIDACION_PESO_ARCHIVO,
    VALIDACION_TIPO_ARCHIVO, VALOR_ZERO
} from "../../../utils/Constantes";
import React, { useEffect, useState } from "react";
import { notificacion } from "./Notificaciones";
import { obtenerArchivos, guardarArchivos } from "../../../services/bus.document.services";
import checkVerde from "../../assets/images/checkGreen.svg";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ModalVerDocumentos } from "./ModalVerDocumentos";

export const enviarArchivos = async (listaDoctos, objParams, documentType) => {

    if (listaDoctos !== null && listaDoctos.length > 0) {
        let archivosAdjuntos = listaDoctos.map(archivo => {
            let item = archivo;
            return {
                nombreDocto: `${item.nombreDocto.split(".")[0]}.${item.nombreDocto.split(".")[1]}`,
                streamBase64: item.streamBase64
            }
        });

        let dataEnvioArchivos = {
            listaDoctos: archivosAdjuntos,
            metadataDocto: {
                [objParams.key]: objParams.value
            },
            tipoDocto: documentType,
        };

        let res = guardarArchivos(dataEnvioArchivos);
        if (res !== null && res !== ERROR_CARGAR_ARCHIVO) {
            notificacion("success", EXITO_CARGAR_ARCHIVO)
            for (const docto of listaDoctos) {
                docto.newFile = false;
            }
        } else {
            notificacion("danger", ERROR_CARGAR_ARCHIVO);
        }
    }
}

export const obtenerArchivosComponente = async (objParams, documentType) => {

    let data = {
        metadataDocto: {
            [objParams.key]: objParams.value
        },
        tipoDocto: documentType
    };

    return await obtenerArchivos(data);
}


const CargarArchivo = (props) => {

    const [nombreArchivo, setNombreArchivo] = useState(null);
    const [abrirModalVerDocumentos, setAbrirModalVerDocumentos] = useState(false);
    const [extensionArchivo, setExtensionArchivo] = useState(null);
    const [previsualizarArchivo, setPrevisualizarArchivo] = useState(null);

    const [cargaArchivo, setCargaArchivo] = useState(false);
    const [mostrarArchivoCargado, setMostrarArchivoCargado] = useState(props.prevFiles ? props.prevFiles.length !== props.maxFiles : true)
    const [listaDoctos, setListaDoctos] = useState([]);

    useEffect(() => {
        if (props.cleanFiles) {
            setListaDoctos([]);
        } else if (props.prevFiles && props.prevFiles.length) {
            setListaDoctos(props.prevFiles);
        }
        if (props.fromCargas) {
            setMostrarArchivoCargado(props.prevFiles.length !== props.maxFiles);
        }

    }, [props.prevFiles, props.cleanFiles, props.maxFiles, props.fromCargas])

    const mostrarArchivosCargados = item => {
        let archivos = item;
        if (archivos && archivos.length === props.maxFiles) {
            setMostrarArchivoCargado(false);
        } else {
            if (archivos.length >= props.minFiles - 1 && archivos.length <= props.maxFiles) {
                setMostrarArchivoCargado(true);
            }
        }

        if (props.files) {
            props.files(archivos);
        }
    }

    const eliminarArchivo = i => {
        setCargaArchivo(true)
        let arrayListaDoctos = listaDoctos.filter((item, j) => j !== i);
        setListaDoctos(arrayListaDoctos);
        mostrarArchivosCargados(arrayListaDoctos);
        setCargaArchivo(false)
        if (props.obtenerArchivoEliminado) {
            props.obtenerArchivoEliminado(listaDoctos[i]);
        }
    }

    const mostrarErroresArchivo = (error) => {
        if (error.code === CODIGO_ERROR_2) {
            notificacion("warning", VALIDACION_PESO_ARCHIVO);
        } else if (error.code === CODIGO_ERROR_1) {
            notificacion("warning", VALIDACION_TIPO_ARCHIVO);
        }
    }

    const cargarArchivos = (file) => {
        setExtensionArchivo(file[0].extension);
        setCargaArchivo(true);

        if (file[0]) {
            let nombreArchivo = `${props.filename}.${file[0].extension}`;

            let objLectura = new FileReader();
            objLectura.readAsDataURL(file[0]);
            objLectura.onload = () => {
                const indice = objLectura.result.indexOf(",");

                let objListaDoctos = {
                    nombreDocto: nombreArchivo,
                    streamBase64: objLectura.result.slice(
                        indice + 1,
                        objLectura.result.length
                    ),
                    newFile: true,
                };
                const listaDoctosNueva = [...listaDoctos, objListaDoctos];
                setListaDoctos(listaDoctosNueva);
                mostrarArchivosCargados(listaDoctosNueva);
            };
        }
        setCargaArchivo(false)
    }

    const previsualizar = documento => {
        //metodo para previsualizar documentos
        setAbrirModalVerDocumentos(!abrirModalVerDocumentos);
        setNombreArchivo(documento.nombreDocto);

        if (documento.newFile) {
            let cadenaReplaceBase64 = "";
            let letBase64 = "";

            setExtensionArchivo(documento.nombreDocto.split(".")[1]);
            if (documento.nombreDocto.split(".")[1] === "pdf") {
                cadenaReplaceBase64 = "data:application/pdf;base64,";
                letBase64 = cadenaReplaceBase64 + documento.streamBase64;
                setPrevisualizarArchivo(letBase64);
            } else {
                cadenaReplaceBase64 = "data:image/jpeg;base64,";
                letBase64 = cadenaReplaceBase64 + documento.streamBase64;
                setPrevisualizarArchivo(letBase64);
            }
        }
    };

    const cerrarModalVerDocumentos = (estadoModalVerDocumentos) => {
        setAbrirModalVerDocumentos(estadoModalVerDocumentos);
    };

    return (
        <React.Fragment>
            {abrirModalVerDocumentos && (
                <ModalVerDocumentos
                    title={nombreArchivo}
                    onHide={cerrarModalVerDocumentos}
                    show={abrirModalVerDocumentos}
                >
                    {extensionArchivo === "pdf" ? (
                        <iframe src={previsualizarArchivo}
                            height="600px"
                            width="100%"
                            title="Visualizar PDF"
                        />
                    ) : (
                        <img className="img-fluid rounded"
                            alt={nombreArchivo !== undefined ? nombreArchivo : ""}
                            src={previsualizarArchivo}
                        />
                    )
                    }
                </ModalVerDocumentos>
            )}
            <div className="row" id='documentosCargados'>
                <div className="col-md-12">
                    <div className="form-group">
                        {mostrarArchivoCargado && listaDoctos.length !== props.maxFiles ? (
                            <Files
                                id="subirArchivo"
                                className={`${props.children !== null ? ' ' : 'input-file py-2'}`}
                                onChange={(file) => {
                                    cargarArchivos(file);
                                }}
                                onError={mostrarErroresArchivo}
                                accepts={[_PDF, _PNG, _JPEG, _JPG]}
                                multiple={false}
                                maxFiles={props.maxFiles}
                                minFiles={props.minFiles}
                                clickable
                            >
                                {
                                    props.children === null ?
                                        <div className="row cursor text-center">
                                            <div className="my-auto ml-auto">
                                            </div>
                                            <div className="my-auto mr-auto ml-2">
                                                <span className="d-block">
                                                    {" "}
                                                    Pincha aqui para cargar archivos
                                                </span>
                                            </div>
                                        </div>
                                        : props.children}

                            </Files>
                        ) : null}
                    </div>
                </div>
                <div className="col-md-12">
                    {listaDoctos.length > VALOR_ZERO &&
                        <div className="font-weight-bold text-muted">
                            {props.children !== null ? '' : <span>Documentos Cargados</span>}
                        </div>
                    }
                </div>
                <div className="col-md-12">
                    <div style={{ overflowY: 'scroll', maxHeight: 300, overflowX: 'hidden' }}>
                        {cargaArchivo ? (
                            <div className="d-flex justify-content-center animated fadeIn my-5">
                                <div className="spinner-border text-primary mb-5" role="status">
                                    <span className="sr-only">Cargando...</span>
                                </div>
                            </div>
                        ) :

                            listaDoctos.length > VALOR_ZERO &&
                            listaDoctos.map((item, i) => (
                                <div className="row animated fadeIn" style={{ paddingTop: 15 }} key={i}>
                                    <div className="col-2">
                                        <img
                                            src={checkVerde}
                                            width="30"
                                            height="30"
                                            className="img-fluid mx-auto mb-2"
                                            id="imgCheckAgregarEjecutivo"
                                            alt=""></img>
                                    </div>
                                    <div className="col-8 my-auto" style={{ padding: 0, marginLeft: '-30px' }}>
                                        <span
                                            className="d-block"
                                            style={{ wordBreak: "break-word" }}
                                        >
                                            {item.nombreDocto}
                                        </span>
                                    </div>
                                    <div className="col-1 my-auto" style={{ paddingRight: 0 }}>
                                        <i
                                            className="fa fa-eye text-primary fa-2x cursor"
                                            aria-hidden="true"
                                            style={{ marginRight: 15, marginTop: 5 }}
                                            onClick={() => previsualizar(item)}
                                        ></i>
                                    </div>
                                    <div className="col-1 my-auto" style={{ paddingRight: 0 }}>
                                        <i
                                            className="fa fa-trash text-primary fa-2x cursor"
                                            aria-hidden="true"
                                            onClick={() =>
                                                eliminarArchivo(i)
                                            }
                                        ></i>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => ({
    store: state,
});

export default withRouter(
    connect(mapStateToProps, {})(CargarArchivo)
);
