import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Asegurable from "./Asegurable";
import { add_negocio_asegurable } from "../../../../actions/actions";
const { format } = require("rut.js");


class NegociosAsegurable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      negocios: [],
      seleccionado: false
    };
  }

  async componentWillMount() {

  }

  addNegocio = item => {
    let negocioAsegurable = Object.assign({}, item);
    negocioAsegurable.rutAsegurable =
      negocioAsegurable.rutAsegurable +
      "-" +
      negocioAsegurable.digitoVerificadorAsegurable;
    negocioAsegurable.rutNegocio =
      negocioAsegurable.rutNegocio +
      "-" +
      negocioAsegurable.digitoVerificadorNegocio;
    negocioAsegurable.rutNegocio = negocioAsegurable.rutNegocio.replace(
      / /g,
      ""
    );

    delete negocioAsegurable.digitoVerificadorAsegurable;
    delete negocioAsegurable.digitoVerificadorNegocio;

    this.props.add_negocio_asegurable(negocioAsegurable);
    this.setState({ seleccionado: true });
  };

  render() {
    return this.state.negocios.length === 0 ? (
      <Asegurable />
    ) : (
      <div className="row">
        {this.state.negocios.map((item, i) => (
          <div
            key={`negocio_${i}`}
            className="col-12 col-sm-6 col-md-4 col-lg-4 pb-2 pt-2 m-3"
          >
            <div className="card  tarjeta-negocio">
              <div className="card-body">
                <div className="row">
                  <div className="col-12 text-center pt-2">
                    <div className="img-icon">
                      <img
                        alt="img-icon"
                        src={require("../../../assets/images/maletin-icon.svg")}
                      />
                    </div>
                  </div>
                </div>
                <div className="row pt-4">
                  <h3 className="mx-auto">{item.razonSocial}</h3>
                  <br />
                </div>
                <div className="row">
                  <h6 className="mx-auto">
                    {format(item.rutNegocio + item.digitoVerificadorNegocio)}
                  </h6>
                </div>

                <div className="row pt-4">
                  <div className="col-12 text-center">
                    <button
                      id={`enrolar${i}`}
                      onClick={() => this.addNegocio(item)}
                      className="btn btn-principal btn-sm"
                    >
                      Enrolar
                    </button>
                  </div>
                </div>
                <hr />

                <div className="row">
                  <h4 className="mx-auto"> {item.descripcion} </h4>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default withRouter(connect(null, { add_negocio_asegurable })(NegociosAsegurable));
