import React, { Fragment, useEffect } from "react";
import Recaptcha from "react-google-invisible-recaptcha";
import Spinner from "../transversales/Spinner";
import { Row, Col, InputGroup, FormGroup, FormControl, Form as FormBootstrap } from "react-bootstrap";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Field, Formik, Form } from "formik";
import Campo from "../transversales/CampoFormulario";
import Modal from "../transversales/Modal";
import {
  CAMPO_REQUERIDO,
  MIN_DIGITOS_ACTUAL,
  CLAVE_MIN_MAX,
  CLAVE_NO_COINCIDE,
  CLAVE_ALFANUMERICA,
  CLAVE_TAMANO_MINIMO_ACTUAL,
  CLAVE_TAMANO_MAXIMO_ACTUAL,
  CLAVE_TAMANO_MINIMO_NUEVA,
  CLAVE_TAMANO_MAXIMO_NUEVA,
  TEXTO_VALIDAR_DIGITOS,
  REGEX_CLAVE_NUEVA,
  TEXTO_CONFIRMAR_CLAVE,
  RUTA_INICIO_SESION,
  RUTA_CAMBIAR_CONTRASENA_TEMPORAL,
  RUTA_RECUPERAR_CONTRASENA,
  FALSO,
  VERDADERO,
  RUTA_CAMBIAR_CONTRASENA_EXPIRADA,
  COD_TELEFONO,
  COD_CORREO,
  TEXTO_ENVIO_CORREO_TELEFONO,
  RUTA_OLVIDO_CONTRASENA,
  TEXTO_ENVIO_SOLO_TELEFONO,
  COD_VIA_CONTACTO_WSP,
  COD_VIA_CONTACTO_CORREO,
  COD_VIA_CONTACTO_TODOS,
  TEXTO_ENVIO_SOLO_CORREO,
  CONFIGURACION_CAMPO_RUT,
  GA_CATEGORIA_CONTRASENA,
  GA_LABEL_RECUPERAR_CONTRASENA,
  GA_LABEL_CAMBIAR_CONTRASENA,
  GA_LABEL_CONTRASENA_EXPIRADA
} from "../../../utils/Constantes";
import * as Yup from "yup";
import { useContrasena } from "../../../eventos/useContrasena";
import { useCargando } from "../../../eventos/useCargando";
import { validarTeclaPrecionada } from "../../../services/contrasena.sevices";
import { useHistory } from "react-router-dom";
import { format } from "rut.js";
import { useSelector } from "react-redux";
import { useRef } from "react";
import { crearEventoTraza } from "../../../utils/analitica";

const LogoVC = require("../../assets/images/logo_vida_camara.png");
const LogoAlerta = require("../../assets/images/alerta.svg");

const objEsquemaValidacionActualizar = Yup.object().shape({
  actual: Yup.string()
    .required(CAMPO_REQUERIDO)
    .min(CLAVE_TAMANO_MINIMO_ACTUAL, MIN_DIGITOS_ACTUAL)
    .max(CLAVE_TAMANO_MAXIMO_ACTUAL, MIN_DIGITOS_ACTUAL),
  nueva: Yup.string()
    .required(CAMPO_REQUERIDO)
    .min(CLAVE_TAMANO_MINIMO_NUEVA, CLAVE_MIN_MAX)
    .max(CLAVE_TAMANO_MAXIMO_NUEVA, CLAVE_MIN_MAX)
    .test(TEXTO_VALIDAR_DIGITOS, CLAVE_ALFANUMERICA, function (objValor) {
      return REGEX_CLAVE_NUEVA.test(objValor);
    }),
  confirmacion: Yup.string()
    .required(CAMPO_REQUERIDO)
    .test(TEXTO_CONFIRMAR_CLAVE, CLAVE_NO_COINCIDE, function (objValor) {
      return this.parent.nueva === objValor;
    }),
});

const objEsquemaValidacionRecuperar = Yup.object().shape({
  nueva: Yup.string()
    .required(CAMPO_REQUERIDO)
    .min(CLAVE_TAMANO_MINIMO_NUEVA, CLAVE_MIN_MAX)
    .max(CLAVE_TAMANO_MAXIMO_NUEVA, CLAVE_MIN_MAX)
    .test(TEXTO_VALIDAR_DIGITOS, CLAVE_ALFANUMERICA, function (objValor) {
      return REGEX_CLAVE_NUEVA.test(objValor);
    }),
  confirmacion: Yup.string()
    .required(CAMPO_REQUERIDO)
    .test(TEXTO_CONFIRMAR_CLAVE, CLAVE_NO_COINCIDE, function (objValor) {
      return this.parent.nueva === objValor;
    }),
});

const ContrasenaSolicitudExpirada = () => {

  const objRedireccionar = useHistory();

  useEffect(() => {
    crearEventoTraza(GA_CATEGORIA_CONTRASENA, GA_LABEL_CONTRASENA_EXPIRADA);
		// eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return(
    <div className="fondo-actualizar-contrasena animated fadeIn">
      <section className="card centrado-porcentual absolute-position p-5 shadow">
      <div className="card-body text-center">
        
        <img src={LogoAlerta} className="img-fluid card-icono-alerta" alt="icono-alerta" />
        <h3 className="font-weight-bold mt-4">La solicitud de cambio de clave ha expirado</h3>

        <h4 className="text-center mb-4">
          <span className="cursor text-primary font-weight-bold link-a" onClick={() => objRedireccionar.push(RUTA_OLVIDO_CONTRASENA) }>
            Favor realice otra solicitud
          </span>
        </h4>
        
        <section className="button-zone text-center">
          <button className="btn btn-principal" type="button" onClick={() => objRedireccionar.push(RUTA_INICIO_SESION) } >
            Volver
          </button>
        </section>
      </div>
      </section>
    </div>
  )
}

const ContrasenaCambiarFormulario = ({ blnIngresarActual, objError, objTocado, objValor }) => {

  const { cambioContrasena, mostrarContrasena }	= useContrasena();
  let { contrasena : { blnMostrarActual, blnMostrarNueva, blnMostrarConfirmacion } } = cambioContrasena;

  let strClaseErrorActual       = objError.actual && objTocado.actual ? "campo-error":"";
  let strClaseErrorNueva        = objError.nueva && objTocado.nueva ? "campo-error":"";
  let strClaseErrorConfirmacion = objError.confirmacion && objTocado.confirmacion ? "campo-error":"";

  return (
    <Fragment>
      { blnIngresarActual && ( 
        <FormBootstrap.Group className="mt-3">
          <label htmlFor="actual">Contraseña actual</label>
          <InputGroup className={`mb-4 right ${strClaseErrorActual}`}>
            <Field
              value={ objValor.actual }
              autoComplete="true"
              id="actual"
              name="actual"
              type={ blnMostrarActual ? "text" : "password" }
              className="form-control"
              placeholder=""
            />
            <InputGroup.Append onClick={() => { mostrarContrasena("blnMostrarActual", blnMostrarActual) }} >
              <InputGroup.Text>
                { blnMostrarActual ? ( <FaEyeSlash /> ) : ( <FaEye /> ) }
              </InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
          { objError.actual && objTocado.actual && (
            <small className="d-block text-danger animated fadeIn">{objError.actual}</small>
          )}
        </FormBootstrap.Group>
      )}

      <FormBootstrap.Group>
        <label htmlFor="nueva">Contraseña nueva</label>
        <InputGroup className={`mb-4 right ${strClaseErrorNueva}`}>
          <Field
            value={ objValor.nueva }
            autoComplete="true"
            id="nueva"
            name="nueva"
            type={ blnMostrarNueva ? "text" : "password" }
            className="form-control"
            placeholder=""
          />
          <InputGroup.Append onClick={() => { mostrarContrasena("blnMostrarNueva", blnMostrarNueva) }} >
            <InputGroup.Text>
              { blnMostrarNueva ? ( <FaEyeSlash /> ) : ( <FaEye /> ) }
            </InputGroup.Text>
          </InputGroup.Append>
        </InputGroup>
        { objError.nueva && objTocado.nueva && (
          <small className="d-block text-danger animated fadeIn">{objError.nueva}</small>
        )}
      </FormBootstrap.Group>
      
      <FormBootstrap.Group>
        <label htmlFor="confirmacion">Confirmar contraseña</label>
        <InputGroup className={`mb-4 right ${strClaseErrorConfirmacion}`} >
          <Field
            value={ objValor.confirmacion }
            autoComplete="true"
            id="confirmacion"
            name="confirmacion"
            type={ blnMostrarConfirmacion ? "text" : "password" }
            className="form-control"
            placeholder=""
          />
          <InputGroup.Append onClick={() => { mostrarContrasena("blnMostrarConfirmacion", blnMostrarConfirmacion) }} >
            <InputGroup.Text>
              { blnMostrarConfirmacion ? ( <FaEyeSlash /> ) : ( <FaEye /> ) }
            </InputGroup.Text>
          </InputGroup.Append>
        </InputGroup>
        { objError.confirmacion && objTocado.confirmacion && (
          <small className="d-block text-danger animated fadeIn">{objError.confirmacion}</small>
        )}
      </FormBootstrap.Group>
    </Fragment>
  )
}

const ContrasenaCambiar = ({ objEsquemaValidacion, blnIngresarActual, objRecaptcha, objRuta, blnTemporal }) => {

  const { cambioContrasena, procesarCambioContrasena, procesarRecuperarContrasena, procesarContrasenaTemporal } = useContrasena();

  useEffect( () => {
    crearEventoTraza(GA_CATEGORIA_CONTRASENA, GA_LABEL_CAMBIAR_CONTRASENA);

    if (blnTemporal) {
      procesarContrasenaTemporal();
    }
    else{
      const {params : { claveActual, rut, perfil } } = objRuta;
      procesarRecuperarContrasena(claveActual, rut, perfil);
    }
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[]);

  return(
    <Formik
      initialValues={{
        actual: cambioContrasena.contrasena.actual,
        nueva: cambioContrasena.contrasena.nueva,
        confirmacion: cambioContrasena.contrasena.confirmacion,
        dirty: false,
      }}
      validationSchema={objEsquemaValidacion}
      onSubmit = { async (values, { resetForm }) => { 
        await procesarCambioContrasena(values, resetForm, objRecaptcha);
      }}>

      {({ values, errors, touched, handleSubmit }) => {

        return(
          <div className="fondo-actualizar-contrasena animated fadeIn">
            <section className="card centrado-porcentual absolute-position p-5 shadow">
            <Form className="card-body" onSubmit={handleSubmit} onKeyDown={(objEvento) => {validarTeclaPrecionada(objEvento)}}>
              
              <img src={LogoVC} className="img-fluid" alt="logo-vida-camara" />
              <h4 className="font-weight-bold mt-5">Crea tu nuevo acceso </h4>
              
              <ContrasenaCambiarFormulario
                objValor={values}
                objError={errors}
                objTocado={touched}
                blnIngresarActual={blnIngresarActual} />

              <section className="button-zone text-center">
                <button className="btn btn-principal" type="submit">
                  Actualizar
                </button>
              </section>
            </Form>
            </section>
          </div>
        )
      }}
    </Formik>
  )
}

export const ContrasenaRecuperar = ({objRecaptcha}) => {

  const objRedireccionar = useHistory();
	const objTipoUsuario   = useSelector(store => store.auth.tipoUsuario);
  const { cargando }     = useCargando();
  const { recuperarContrasena, procesarCampoRecuperar, procesarEnvioEnlace, procesarCorreoRecuperar } = useContrasena();
  let { rutUsuario, enlaceEnviado, correo, telefono, viaContacto, escogerCorreo, correos, habilitarEnvio } = recuperarContrasena;
  let strPerfil = "";

  let objConfiguracion = {
    ...CONFIGURACION_CAMPO_RUT,
    valor: rutUsuario ? format(rutUsuario): ""
  };

  if (objTipoUsuario) {
    strPerfil = objTipoUsuario.perfil;  
  }
  else{
    objRedireccionar.push(RUTA_INICIO_SESION);
  }

  useEffect(() => {
    crearEventoTraza(GA_CATEGORIA_CONTRASENA, GA_LABEL_RECUPERAR_CONTRASENA);
		// eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);
  
  return cargando ? (
    <Spinner claseColor="naranjo" idSpinner="spinnerNaranjo" posicionLogo="align-self-center w-100" />
  ) : (
    <div className="fondo-actualizar-contrasena animated fadeIn">
      <section className="card centrado-porcentual absolute-position p-5 shadow card-olvido-contrasena">
        <FormBootstrap 
          className="card-body text-center" 
          onSubmit={ (objEvento) => {
            procesarEnvioEnlace(objEvento, objRecaptcha);
          }} 
          onKeyDown={(objEvento) => validarTeclaPrecionada(objEvento) } >

          <img src={LogoVC} className="img-fluid" alt="logo-vida-camara" />
          <h4 className="font-weight-bold mt-5">Recuperar Contraseña {strPerfil} </h4>

          {enlaceEnviado ? (
            { 
              [COD_VIA_CONTACTO_TODOS]: TEXTO_ENVIO_CORREO_TELEFONO.replace(COD_CORREO, correo).replace(COD_TELEFONO, telefono),
              [COD_VIA_CONTACTO_CORREO]: TEXTO_ENVIO_SOLO_CORREO.replace(COD_CORREO, correo),
              [COD_VIA_CONTACTO_WSP]: TEXTO_ENVIO_SOLO_TELEFONO.replace(COD_TELEFONO, telefono)
            }[viaContacto]
          ) : (
            <React.Fragment>
              <p>Ingresa tu RUT y te enviaremos un email a la brevedad a tu correo asociado con las indicaciones para recuperar tu contraseña.</p>

              <FormGroup className="mt-3">
                <InputGroup className={`mb-4`}>
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      <i className="fa fa-user" aria-hidden="true"></i>
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                    <Campo objConfiguracion={objConfiguracion} procesarCampoFormulario={procesarCampoRecuperar} />
                </InputGroup>
                {escogerCorreo && (
                  <InputGroup className={`mb-4`}>
                    <InputGroup.Prepend>
                      <InputGroup.Text>
                        <i className="fa fa-envelope" aria-hidden="true"></i>
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl as="select" placeholder="Seleccione un correo" onChange={(objEvento) => procesarCorreoRecuperar(objEvento.target.value) }>
                      <option value="">Seleccione un correo</option>
                      { correos.map((strCorreo, intIndice) => (
                        <option key={intIndice} value={strCorreo}>{strCorreo}</option>
                      ))}
                    </FormControl>
                  </InputGroup>
                )}
              </FormGroup>
            </React.Fragment>
          ) }

          <Row>
            <Col className="mt-3">
              <button className="btn btn-principal-login color-secundario mr-3" type="button" onClick={() => objRedireccionar.push(RUTA_INICIO_SESION) } >
                Atrás
              </button>
              {!enlaceEnviado && (
                <button disabled={!habilitarEnvio} className="btn btn-principal-login" type="submit" id="botonIngresar">
                  Enviar
                </button>
              )}
            </Col>
          </Row>
        </FormBootstrap>
      </section>
    </div>
  )
}

export const ModalContrasenaCambiar = ({mostrarModal, cerrarModal, objRecaptcha}) => {

  const { cambioContrasena, procesarCambioContrasena, procesarContrasenaTemporal } = useContrasena();
  const { cargando } = useCargando();

  useEffect( () => {
    procesarContrasenaTemporal();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[]);

  useEffect( () => {
    if(mostrarModal){
      crearEventoTraza(GA_CATEGORIA_CONTRASENA, GA_LABEL_CAMBIAR_CONTRASENA);
    }
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[mostrarModal]);
  return(
    <Modal
      type="small"
      show={mostrarModal}
      showLeftColumn={false}
      title="Cambiar contraseña"
      onClose={cerrarModal}
      showCancelButton={false}
      showCloseButton={true}
      >
      {cargando && <Spinner claseColor="transparente"idSpinner="spinnerNaranjo" posicionLogo="align-self-center w-100" />}


      <Formik
        initialValues={{
          actual: cambioContrasena.contrasena.actual,
          nueva: cambioContrasena.contrasena.nueva,
          confirmacion: cambioContrasena.contrasena.confirmacion,
          dirty: false,
        }}
        validationSchema={objEsquemaValidacionActualizar}
        onSubmit= { async (values, { resetForm }) => { 

          await procesarCambioContrasena(values, resetForm, objRecaptcha);
        }}>

        {({ values, errors, touched, handleSubmit }) => {

          return(
            <Form onSubmit={handleSubmit} onKeyDown={(objEvento) => {validarTeclaPrecionada(objEvento)}}>
              <ContrasenaCambiarFormulario
                objValor={values}
                objError={errors}
                objTocado={touched}
                blnIngresarActual={VERDADERO} />

              <section className="button-zone text-center">
                <button className="btn btn-principal" type="submit">
                  Actualizar
                </button>
              </section>
            </Form>
          )
        }}
      </Formik>
    </Modal>
  )
}

export const Contrasena = (props) => {

  const { cargando } = useCargando();
  let objRecaptcha   = useRef();

  return (
    <Fragment>
      <Recaptcha
        ref={(objReferencia) => {objRecaptcha.current = objReferencia}}
        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
      />
      { cargando ? (
        <Spinner claseColor="naranjo" idSpinner="spinnerNaranjo" posicionLogo="align-self-center w-100" />
      ) : (
        { 
          [RUTA_CAMBIAR_CONTRASENA_TEMPORAL]: <ContrasenaCambiar 
                                                objEsquemaValidacionActualizar={objEsquemaValidacionActualizar}
                                                blnIngresarActual={VERDADERO}
                                                objRecaptcha={objRecaptcha}
                                                objRuta={props.match}
                                                blnTemporal={true} />,
          [RUTA_RECUPERAR_CONTRASENA]: <ContrasenaCambiar 
                                          objEsquemaValidacionActualizar={objEsquemaValidacionRecuperar}
                                          blnIngresarActual={FALSO}
                                          objRecaptcha={objRecaptcha} 
                                          objRuta={props.match}
                                          blnTemporal={false} />,
          [RUTA_CAMBIAR_CONTRASENA_EXPIRADA] : <ContrasenaSolicitudExpirada />,
          [RUTA_OLVIDO_CONTRASENA] : <ContrasenaRecuperar
                                      objRecaptcha={objRecaptcha} />
        }[props.match.path]
      )}
    </Fragment>
  )
}