import React from "react";

import { useState } from "react";
import {
  EXITO_AGREGAR_ASEGURADO,
  ERROR_AGREGAR_ASEGURADO,
  COD_EJECUTIVO,
  COD_EJECUTIVO_COMPLETO,
  COD_CORREDOR,
  ESTADO_COTIZACION_EN_PROCESO,
  PERFIL_DELEGADO,
  TIPO_INCORPORACION_NUEVO_NEGOCIO,
  VALOR_ZERO,
  PERFIL_LDAP,
  COD_STATUS_EXITO,
  ACCESO_ENVIADO_A,
  ERROR_ENVIO_AUTOMATICO,
  DOTACION_INICIAL,
  PERFIL_B,
  POSICION_INICIAL,
  RUTA_DELEGADO_SOLICITUD_COMPLETA,
  TIPO_CANAL_VENTA_DIGITAL,
  WARNING_AGREGAR_ASEGURADO,
  VALOR_CIEN_VEINTE,
  VALOR_CIEN,
  CORREO,
  TELEFONO_MOVIL,
} from "../../../../../../utils/Constantes";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { notificacion } from "../../../../transversales/Notificaciones";
import { useEffect } from "react";
import { guardar_cotizacion, guardar_asegurados_delegado, actualizar_asegurables } from "../../../../../../actions/actions";
import { enviarMailAsegurable, crearAsegurable, obtenerGrupoAsegurables } from "../../../../../../services/asegurable.services";

import { ModalCantidadAsegurables } from "./ModalCantidadAsegurables";
import FormAsegurable from "./Form/FormAsegurable";
import {
  validacionEmail,
  validacionMaterno,
  validacionNombre,
  validacionPaterno,
  validacionRut,
  validacionTelefono,
} from "./Form/YupValidation";
import { formatTel, soloLetras } from "../../../../../../utils/Functions";
import { format } from "rut.js";

export const CargaIndividual = ({ usuarioSesion, intCanalVenta }) => {
  const reducer = useSelector((store) => store.reducer);


  const [intNumeroDotacion, actualizarIntNumeroDotacion] = useState(DOTACION_INICIAL);
  const [blnModalAsegurable, actualizarBlnModalAsegurable] = useState(false);
  const [intMaximoAsegurables, actualizarIntMaximoAsegurable] = useState(null);
  const intSesionEjecutivo = usuarioSesion.rol.codigo === COD_EJECUTIVO || usuarioSesion.rol.codigo=== COD_EJECUTIVO_COMPLETO;
  const [listCotizacion, actualizarListCotizacion] = useState([]);
  const [blnCargando, actualizarBlnCargando] = useState(false);

  const blnSesionCorredor = usuarioSesion.rol.codigo === COD_CORREDOR;

  useEffect(() => {
    if (reducer.negocio.fechaFinEnrolamiento && usuarioSesion.rol.codigo === PERFIL_B) {
      this.props.history.push(RUTA_DELEGADO_SOLICITUD_COMPLETA);
    } else {
      //definir de donde se obtienen las cotizaciones
      let cotizaciones;
      if (intSesionEjecutivo || blnSesionCorredor) {
        // perfil ejecutivo venta digital
        cotizaciones = reducer.cotizaciones.gestionadas;
      } else {
        // perfil delegado digital o directa
        cotizaciones = reducer.negocio.cotizaciones;
      }

      let lstCotizacionGestionada = [];
      cotizaciones.forEach((objCotizacion) => {
        if (
          (objCotizacion.estadoCotizacion !== null && objCotizacion.estadoCotizacion.idEstadoCotizacion === ESTADO_COTIZACION_EN_PROCESO) ||
          objCotizacion.estadoCotizacion.idEstadoCotizacion === 2
        ) {
          lstCotizacionGestionada = [...lstCotizacionGestionada, objCotizacion];
        }
      });

      actualizarListCotizacion(lstCotizacionGestionada);
      guardar_cotizacion(lstCotizacionGestionada);

      if (reducer.negocio.canalVenta.idCanalVenta === TIPO_CANAL_VENTA_DIGITAL) {
        const objPerfil = intSesionEjecutivo ? PERFIL_LDAP : PERFIL_DELEGADO;
        const { idGrupo } = lstCotizacionGestionada[POSICION_INICIAL].grupos[POSICION_INICIAL];
        const objDataEnvio = { idGrupo: idGrupo, objPerfil };

        new Promise(async function (response) {
          response(await obtenerGrupoAsegurables(objDataEnvio));
        }).then((objRespuesta) => {
          actualizarIntNumeroDotacion(lstCotizacionGestionada[POSICION_INICIAL].numeroDotacion);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const enviarDatosAsegurado = async (values) => {
    const objPerfil = intSesionEjecutivo ? PERFIL_LDAP : PERFIL_DELEGADO;
    const { idGrupo } = listCotizacion[POSICION_INICIAL].grupos[POSICION_INICIAL];
    const objDataEnviaAsegurable = { idGrupo: idGrupo, objPerfil };

    new Promise(async function (response) {
      response(await obtenerGrupoAsegurables(objDataEnviaAsegurable));
    }).then(async (objRespuesta) => {
      const intNumeroAsegurables = objRespuesta.length;

      if (blnSesionCorredor && intNumeroAsegurables === intNumeroDotacion) {
        notificacion("warning", WARNING_AGREGAR_ASEGURADO);
      } else {
        actualizarBlnCargando(true);
        let lstGrupos = [];
        listCotizacion.forEach((objCotizacion) => {
          lstGrupos = [...lstGrupos, { idGrupo: objCotizacion.grupos[POSICION_INICIAL].idGrupo }];
        });

        let objDataEnvio = {
          nombre: values.nombres,
          apellidoMaterno: values.apellidoMaterno,
          apellidoPaterno: values.apellidoPaterno,
          correoElectronico: values.email,
          telefonoMovil: values.telefonoMovil,
          rut: Number(values.rut.split("-")[0]),
          digitoVerificador: values.rut.split("-")[1],
          idUsuarioCrea: usuarioSesion.rol.codigo,
          grupos: lstGrupos,
          solicitud: { idSolicitud: VALOR_ZERO },
          tipoIncorporacion: {
            idTipoIncorporacion: TIPO_INCORPORACION_NUEVO_NEGOCIO,
          },
        };

        let objRespuestaCrearAsegurable = await crearAsegurable(objDataEnvio, objPerfil);

        if (objRespuestaCrearAsegurable !== null && objRespuestaCrearAsegurable.status.codigo === COD_STATUS_EXITO) {
          notificacion("success", EXITO_AGREGAR_ASEGURADO);

          let objAseguradosDelegado = Object.assign([], reducer.delegado.aseguradosDelegado);
          objAseguradosDelegado.push(objDataEnvio);

          guardar_asegurados_delegado(objAseguradosDelegado);
          actualizar_asegurables(objAseguradosDelegado);

          if (reducer.negocio.fechaInicioEnrolamiento !== null) {
            const { idGrupo } = listCotizacion[0].grupos[0];
            const dataEnvio = {
              idGrupo: idGrupo,
              perfil: objPerfil,
            };
            const grupoAsegurables = await obtenerGrupoAsegurables(dataEnvio);

            if (objRespuestaCrearAsegurable !== null) {
              const intIdAsegurable = grupoAsegurables.find(({ rut }) => rut === Number(values.rut.split("-")[0])).idAsegurable;

              const objDataCorreo = {
                asegurable: {
                  idAsegurable: intIdAsegurable,
                  rut: Number(values.rut.split("-")[0]),
                  digitoVerificador: values.rut.split("-")[1],
                },
              };

              const envioCorreo = await enviarMailAsegurable(objDataCorreo, objPerfil);

              if (envioCorreo !== null) {
                notificacion("success", `${ACCESO_ENVIADO_A} ${values.nombres} ${values.apellidoPaterno}`);
              } else {
                notificacion("warning", ERROR_ENVIO_AUTOMATICO);
              }
              actualizarBlnCargando(false);
              return objDataCorreo;
            } else {
              notificacion("warning", ERROR_ENVIO_AUTOMATICO);
            }
          }
        } else {
          notificacion("danger", ERROR_AGREGAR_ASEGURADO);
        }
        actualizarBlnCargando(false);
      }
    });
  };

  const validarCantidadAsegurables = () => {
    const { aseguradosDelegado } = reducer.delegado;
    const { totalTitular } = reducer.cotizacion[POSICION_INICIAL].planCotizado[POSICION_INICIAL];
    const asegurablesIngresados = aseguradosDelegado.length;
    const porcentajeMaximo = Math.ceil((VALOR_CIEN_VEINTE * totalTitular) / VALOR_CIEN);
    const maximoAsegurables = porcentajeMaximo < VALOR_CIEN ? porcentajeMaximo : VALOR_CIEN;
    actualizarIntMaximoAsegurable(maximoAsegurables);
    return asegurablesIngresados < maximoAsegurables;
  };

  return (
    <React.Fragment>
      <ModalCantidadAsegurables
        modalCantidadAsegurables={blnModalAsegurable}
        mostrarModalCantidadAsegurables={() => actualizarBlnModalAsegurable(!blnModalAsegurable)}
        maximoAsegurables={intMaximoAsegurables}
      />
      <Formik
        initialValues={{
          rut: "",
          nombres: "",
          apellidoPaterno: "",
          apellidoMaterno: "",
          email: "",
          telefonoMovil: "",
        }}
        validationSchema={Yup.object().shape(
          {
            rut: validacionRut(reducer.delegado),
            nombres: validacionNombre(),
            apellidoPaterno: validacionPaterno(),
            apellidoMaterno: validacionMaterno(),
            email: validacionEmail(),
            telefonoMovil: validacionTelefono(),
          },
          [TELEFONO_MOVIL, CORREO]
        )}
        onSubmit={async (values, { resetForm }) => {
          actualizarBlnCargando(true);
          validarCantidadAsegurables();

          if (validarCantidadAsegurables()) {
            values.rut = format(values.rut).replace(/[.]/g, "");
            values.nombres = soloLetras(values.nombres).trim();
            values.apellidoMaterno = soloLetras(values.apellidoMaterno).trim();
            values.apellidoPaterno = soloLetras(values.apellidoPaterno).trim();
            values.telefonoMovil = formatTel(values.telefonoMovil);

            enviarDatosAsegurado(values);
          } else {
            actualizarBlnModalAsegurable(true);
          }
          resetForm({});
        }}
      >
        {({ values, errors, touched, setFieldValue, setFieldTouched }) => (
          <div className="container">
            <FormAsegurable
              formik={{
                values,
                errors,
                touched,
                setFieldTouched,
                setFieldValue,
                usuarioSesion,
              }}
              props={{
                intCanalVenta,
                tipoIncorporacion: reducer.tipoIncorporacion,
                objCotizacion: listCotizacion,
                blnCargando,
                tipoProductos: [],
              }}
            />
          </div>
        )}
      </Formik>
    </React.Fragment>
  );
};
