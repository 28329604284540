import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { obtenerCanalesVenta } from "../../../../../../services/transversales.service";
import { guardar_canales_venta } from "../../../../../../actions/actions";
import { STORAGE_CANAL_VENTA } from "../../../../../../utils/ConstantesVentaDirecta";
import {
  VALOR_TRES,
  VALOR_UNO,
  VALOR_ZERO,
} from "../../../../../../utils/Constantes";

const ModalTipoCanal = (props) => {
  const [canalVenta, setCanalVenta] = useState(0);
  const [validarCanal, setValidarCanal] = useState(true);

  const { canalesVenta } = useSelector((store) => store.reducer);

  const dispatch = useDispatch();

  useEffect(() => {
    // Canales venta
    const obtenerCanalesVenta_ = async () => {
      const dbCanalesVenta = await obtenerCanalesVenta();
      dispatch(guardar_canales_venta(dbCanalesVenta));
    };

    if (canalesVenta === null || canalesVenta.length < VALOR_UNO) {
      obtenerCanalesVenta_();
    }
  }, [canalesVenta, dispatch]);

  let canalesVenta_ = Object.assign([], canalesVenta);

  // QUITAR CANAL CORREDOR [NO APLICA PARA ESTE MVP DIC 2020]
  canalesVenta_ = canalesVenta_.filter(
    (e) => e.idCanalVenta !== VALOR_UNO && e.idCanalVenta !== VALOR_TRES
  );

  const obtenerCanalVenta = async (tipo) => {
    props.obtenerCanalVenta(tipo);
  };

  const aceptar = () => {
    if (canalVenta !== VALOR_ZERO) {
      props.obtenerCanalVenta(canalVenta);
      sessionStorage.setItem(STORAGE_CANAL_VENTA, canalVenta);
    } else {
      setValidarCanal(false);
    }
  };

  return ReactDOM.createPortal(
    <div
      className={`${props.show ? "modal_overlay show lbg" : "modal_overlay"}`}
    >
      <div className="container h-100">
        <div className="align-self-center w-100">
          <div
            className="main_modal animated bounceInLeft center-2 large"
            style={{ maxWidth: "750px" }}
          >
            <div className="row">
              <div className="col-md-12">
                <div className="modal_title">
                  <div className="title title font-weight-bold text-primary text-left">
                    {props.titulo}
                  </div>
                  {true ? (
                    <div
                      className="button_close cursor"
                      onClick={() => {
                        obtenerCanalVenta(0);
                      }}
                    >
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        viewBox="0 0 352 512"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z" />
                      </svg>
                    </div>
                  ) : null}
                </div>

                <div className="container mt-3 col-11">
                  <label>
                    <strong>Tipo de venta</strong>
                  </label>
                  <select
                    className="form-control"
                    onChange={(e) => {
                      const value = Number(e.target.value);
                      setCanalVenta(value);
                    }}
                  >
                    <option value={0} key={0} id={0}>
                      Seleccione canal
                    </option>
                    {canalesVenta_.map((e) => (
                      <option
                        value={e.idCanalVenta}
                        key={e.idCanalVenta}
                        id={e.idCanalVenta}
                      >
                        {e.glosa}
                      </option>
                    ))}
                  </select>
                  {!validarCanal && (
                    <label className="text-danger mt-2">
                      Favor seleccionar canal de venta
                    </label>
                  )}

                  <button
                    type="button"
                    className="btn btn-principal mt-4 d-block ml-auto"
                    onClick={() => aceptar()}
                  >
                    Aceptar
                  </button>
                </div>

                <div className="modal_foot"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>,
    document.querySelector(`#spinnerNaranjo`)
  );
};

export default withRouter(ModalTipoCanal);
