import React from "react";
import moment from "moment";
import { POSICION_INICIAL } from "../../../utils/Constantes";

export const NegociosCuadriculados = ({
  negocio,
  tickNegocioRevisado,
  avatarInicioAmarillo,
  avatarInicioNegro,
  razonSocial,
  verDetalle,
}) => {
  return (
    <div className="col-12 col-md-6 col-xl-4 p-2">
      <div
        className="m-1 card p-4 shadow bg-white rounded h-100"
        key={negocio.id}
      >
        <div className="row h-100">
          <div className="container mt-4 text-muted">
            <div className="row">
              <div className="col-6 col-md-5">
                <div className="avatar-card">
                  {negocio.negocioUsuarioRevisor[POSICION_INICIAL].revisado ? (
                    <span className="tick-badge">
                      <img src={tickNegocioRevisado} alt="..." width="60%" />
                    </span>
                  ) : null}
                  <img
                    src={
                      negocio.negocioUsuarioRevisor[POSICION_INICIAL].revisado
                        ? avatarInicioNegro
                        : avatarInicioAmarillo
                    }
                    className="d-block mx-auto mb-2"
                    alt="No hay resultados"
                    width="100%"
                  />
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-7">
                <h6 className="font-weight-bold align-self-left">
                  NOMBRE APELLIDO
                </h6>
                <span>
                  {negocio.usuarios[0].nombre}{" "}
                  {negocio.usuarios[0].apellidoPaterno}{" "}
                  {negocio.usuarios[0].apellidoMaterno}
                </span>
                <h6 className="font-weight-bold align-self-left mt-3">
                  CLIENTE/RAZÓN SOCIAL
                </h6>
                {/* <div style={{overflow: "hidden", whiteSpace: "nowrap", display: "inline-block !important"}}> */}
                <div>
                  {/* Zenta Group */}
                  {razonSocial}
                </div>
                <h6 className="font-weight-bold align-self-left mt-3">
                  INGRESOS
                </h6>
                <span>
                  {negocio.cantidadSolicitud}{" "}
                  {negocio.cantidadSolicitud === 1
                    ? "solicitud"
                    : "solicitudes"}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="divisor" />
        <div>
          <div className="row">
            <div className="container mt-4 text-muted ">
              <div className="row">
                <div className="col-6 col-md-5">
                  <h6 className="font-weight-bold align-self-left">FECHA</h6>
                  <span className="text-primary">
                    {" "}
                    {negocio.fechaModifica !== null
                      ? moment(negocio.fechaModifica).format("DD MMM YYYY")
                      : moment(negocio.fechaCrea).format("DD MMM YYYY")}
                  </span>
                </div>
                <div className="col-12 col-sm-6 col-md-6 text-center p-0">
                  <button
                    className="btn btn-principal-login color-secundario btn-inicio"
                    type="button"
                    onClick={() => {
                      verDetalle(negocio, negocio.revisado);
                    }}
                  >
                    Ver detalle
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
