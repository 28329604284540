import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import Table from "../../../../transversales/DataTable";
import {
  agregarPolizaSoftwareAlianza,
  guardarAsegurableSeleccionadosPorSoftwareAlianza,
  guardarAsegurablesFiltroFecha,
  guardarAsegurablesFiltroOriginal,
  guardarAsegurablesFinalesSoftwareAlianza,
  guardarFechaVigenciaPoliza,
  guardarPolizasSeleccionadas,
} from "../../../../../../reducer/delegadoMantencion";

class TablaAseguradosRRHH extends Component {

  timer = null;

  state = {
    temaTabla: {
      title: {
        backgroundColor: "#262E33",
        height: "0px",
      },
      header: {
        fontSize: "14px",
        backgroundColor: "#262E33",
        fontColor: "white",
        fontWeight: "600",
      },
      rows: {
        backgroundColor: "white",
        borderWidth: "0px",
        height: "40px",
      },
    },
    columnDefs: [
      {
        name: "Fecha Incorporación",
        selector: "fechaIncorporacion",
        minWidth: "150px",
      },
      {
        name: "RUT",
        selector: "rutCompleto",
      },
      {
        name: "Nombres",
        selector: "nombres",
      },
      {
        name: "Apellido Paterno",
        selector: "apellidoPaterno",
      },
      {
        name: "Apellido Materno",
        selector: "apellidoMaterno",
      },
      {
        name: "Correo",
        selector: "correoElectronico",
      },
      {
        name: "Sistema Previsional",
        selector: "sistemaPrevisional",
      },
      {
        name: "Poliza",
        selector: "polizas",
      },
      {
        name: "Fecha Inicio Vigencia Solicitada",
        selector: "fechaInicioVigencia",
      },
    ],
    asegurablesFiltrado: [],
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.complementarDatosPolizaAsegurables();

    const elementoFiltro = document.getElementById("bsq-software-alianza");

    if(elementoFiltro){
      elementoFiltro.autocomplete = "off";
    }
  }

  seleccionarAsegurable = (columna) => {


    const { respuestaAsegurablesFiltroFechaOriginal,
            respuestaAsegurablesFiltroFecha,
            asegurablesSeleccionadosSoftwareAlianza
    }= this.props.store.delegado.softwareAlianza;

    const asegurablesSeleccionados = [...columna.selectedRows.filter((asegurable) => !asegurable.deshabilitado)];

    if(asegurablesSeleccionadosSoftwareAlianza.length !== asegurablesSeleccionados.length){

      for (const asegurableOriginal of respuestaAsegurablesFiltroFechaOriginal) {
        const asegurable = asegurablesSeleccionados.find(asegurableSeleccionado => asegurableOriginal.id === asegurableSeleccionado.id);
        asegurableOriginal.preSeleccionado = asegurable ? true : asegurableOriginal.preSeleccionado;
      }

      for (const asegurableFiltro of respuestaAsegurablesFiltroFecha) {
        const asegurable = asegurablesSeleccionados.find(
            asegurableSeleccionado => asegurableSeleccionado.id === asegurableFiltro.id
        );

        if(!asegurable){
          const asegurableOriginal = respuestaAsegurablesFiltroFechaOriginal.find(asegurableOriginal =>
          asegurableOriginal.id === asegurableFiltro.id && asegurableOriginal.preSeleccionado && !asegurableOriginal.deshabilitado)
          if(asegurableOriginal){
            asegurableOriginal.preSeleccionado = false;
            asegurableFiltro.preSeleccionado = false;
          }
        }else asegurableFiltro.preSeleccionado = true;
      }
      this.props.guardarAsegurablesFiltroFecha([...respuestaAsegurablesFiltroFecha]);
      this.props.guardarAsegurablesFiltroOriginal([...respuestaAsegurablesFiltroFechaOriginal]);
      this.props.guardarAsegurableSeleccionadosPorSoftwareAlianza(asegurablesSeleccionados);
    }

    this.reiniciarCheckboxes();
  };

  reiniciarCheckboxes = () => {
    let nodes = document.getElementsByClassName("rdt_TableCell");
    for (const node of nodes) {
      for (const childrenNode of node.children) {
        if (childrenNode.nodeName === "INPUT" && childrenNode.disabled) {
          childrenNode.checked = true;
        }
      }
    }
  };

  async complementarDatosPolizaAsegurables() {
    const {
      asegurablesFinales,
      agregarPolizaAsegurablesSotwareAlianza,
      respuestaAsegurablesFiltroFecha,
      respuestaAsegurablesFiltroFechaOriginal
    } = this.props.store.delegado.softwareAlianza;

    if (agregarPolizaAsegurablesSotwareAlianza) {


      const asegurablesFiltrados = [...respuestaAsegurablesFiltroFecha];
      const asegurablesRespuestaOriginal = [...respuestaAsegurablesFiltroFechaOriginal];

      const respuestaAsegurablesFiltrados = this.deshabilitarCheckboxAsegurablesSeleccionados(asegurablesFiltrados);
      const respuestaAsegurablesOriginales = this.deshabilitarCheckboxAsegurablesSeleccionados(asegurablesRespuestaOriginal);


      this.props.agregarPolizaSoftwareAlianza(false);
      this.props.guardarFechaVigenciaPoliza(null);
      this.props.guardarPolizasSeleccionadas([]);
      this.props.guardarAsegurableSeleccionadosPorSoftwareAlianza([]);
      this.props.guardarAsegurablesFinalesSoftwareAlianza([
        ...asegurablesFinales,
        ...respuestaAsegurablesOriginales.asegurablesSeleccionados,
      ]);
      this.props.guardarAsegurablesFiltroFecha(respuestaAsegurablesFiltrados.copiaAsegurables);
      this.props.guardarAsegurablesFiltroOriginal(respuestaAsegurablesOriginales.copiaAsegurables)
    }
  }

  deshabilitarCheckboxAsegurablesSeleccionados = (asegurables) => {

    const copiaAsegurables = [];
    const asegurablesSeleccionados = [];

    const {
      fechaVigenciaPolizaCargaSofware,
      polizasSeleccionadas
    } = this.props.store.delegado.softwareAlianza;

    for (const asegurable of asegurables) {
      const copiaAsegurable = {...asegurable};

      if (copiaAsegurable.preSeleccionado && !copiaAsegurable.deshabilitado) {
        copiaAsegurable.fechaInicioVigencia = moment(
            fechaVigenciaPolizaCargaSofware
        ).format("DD/MM/YYYY");
        copiaAsegurable.polizas = polizasSeleccionadas.reduce(
            (acumulador, { poliza }, index) => {
              return (
                  acumulador +
                  `${poliza.numeroPoliza}-${poliza.numeroRenovacion}${
                      index !== polizasSeleccionadas.length - 1 ? "," : ""
                  }`
              );
            },
            ""
        );
        copiaAsegurable.deshabilitado = true;
        asegurablesSeleccionados.push(copiaAsegurable);
      }
      copiaAsegurables.push(copiaAsegurable);
    }

    return {
      asegurablesSeleccionados,
      copiaAsegurables
    }

  }

  busquedaReactivaAsegurados = (event) => {

    const textoFiltro = event.target.value;
    clearTimeout(this.timer)

    this.timer = setTimeout(() =>{

      const { respuestaAsegurablesFiltroFechaOriginal,
      } = this.props.store.delegado.softwareAlianza;

      if (textoFiltro) {
        const asegurables = [...respuestaAsegurablesFiltroFechaOriginal]
        const busqueda = textoFiltro.toLowerCase();
        const data = asegurables.filter((e) => {
          let rutCompleto = e.rutCompleto;
          let nombres = e.nombres.toLowerCase();
          let apellidoPaterno = e.apellidoPaterno.toLowerCase();
          let apellidoMaterno = e.apellidoMaterno.toLowerCase();
          let asegurableFiltro = (
              nombres.indexOf(busqueda) > -1 ||
              rutCompleto.indexOf(busqueda) > -1 ||
              apellidoPaterno.indexOf(busqueda) > -1 ||
              apellidoMaterno.indexOf(busqueda) > -1
          );
          return asegurableFiltro
        });
        this.props.guardarAsegurablesFiltroFecha(data);
      } else {
        this.props.guardarAsegurablesFiltroFecha(respuestaAsegurablesFiltroFechaOriginal);
      }

    },200 )
  }

  render() {
    const {
      respuestaAsegurablesFiltroFecha,
      respuestaAsegurablesFiltroFechaOriginal
    } = this.props.store.delegado.softwareAlianza;

    return (
      <div>
        {respuestaAsegurablesFiltroFechaOriginal.length > 0 &&

          <div className="col-4 offset-8 mt-2 mb-2">
            <input
                className="search-input w-100"
                type="text"
                id="bsq-software-alianza"
                onChange={this.busquedaReactivaAsegurados}
                placeholder="Buscar"
            />
          </div>
        }
        <div className="shadow">
          <Table
            datos={respuestaAsegurablesFiltroFecha}
            columnas={this.state.columnDefs}
            tema={this.state.temaTabla}
            campoOrden={"fila"}
            paginacion={true}
            paginacionPorPagina={10}
            paginacionOpciones={{
              rowsPerPageText: "Filas por página:",
              rangeSeparatorText: "de",
              noRowsPerPage: true,
            }}
            mostrarCheckBox={true}
            funcionColumnaSeleccionada={this.seleccionarAsegurable}
            propiedadPreseleccionado="preSeleccionado"
            propiedadDeshabilitado="deshabilitado"
            mensajeNoDatos={
              respuestaAsegurablesFiltroFechaOriginal.length ?
              "No hemos encontrado trabajadores incorporados que coincidan con la busqueda introducida, por favor intente con otra busqueda":
              "No hemos encontrado trabajadores incorporados en las fechas seleccionadas, favor seleccionar otras fechas"
            }
            funcionCambiarPagina={this.reiniciarCheckboxes}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state,
});

export default withRouter(
  connect(mapStateToProps, {
    guardarAsegurablesFinalesSoftwareAlianza,
    guardarAsegurableSeleccionadosPorSoftwareAlianza,
    guardarAsegurablesFiltroOriginal,
    guardarAsegurablesFiltroFecha,
    agregarPolizaSoftwareAlianza,
    guardarPolizasSeleccionadas,
    guardarFechaVigenciaPoliza,
  })(TablaAseguradosRRHH)
);
