import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import moment from "moment";
import {
  TIPO_CANAL_VENTA_DIGITAL,
  TIPO_CANAL_VENTA_CORREDOR,
  VALOR_INICIAL_INDICE,
  VALOR_VACIO_GUION,
  ESPACIO_VACIO,
} from "../../../../utils/Constantes";
import { STRING_VACIO } from "../../../../utils/Constantes";
const { format } = require("rut.js");
const iconNegocio = require("../../../assets/images/negocio-asegurable.png");

class CabeceraNegocioAsegurado extends Component {
  constructor(props) {
    super(props);
    this.state = {
      negocio: this.props.store.reducer.data_asegurable,
    };
  }

  render() {
    const canalVenta = this.state.negocio.canalVenta;
    const asegurable5_50 =
      this.props.store.reducer.informacionPoliza &&
      this.props.store.reducer.informacionPoliza.grupos &&
      this.props.store.reducer.informacionPoliza.grupos[0]
        .idContratoSeguroGrupo === 0;
    let objSeguroContratado = "";
    this.props.store.reducer.data_asegurable && this.props.store.reducer.data_asegurable.cotizaciones.forEach((objPlan, intIndice) => {
      if (intIndice === VALOR_INICIAL_INDICE) {
        objSeguroContratado = objPlan.subProducto.producto.glosaProducto
      } else {
        objSeguroContratado = objSeguroContratado.concat(ESPACIO_VACIO).concat(VALOR_VACIO_GUION).concat(ESPACIO_VACIO).concat(objPlan.subProducto.producto.glosaProducto)
      }
    });

    return (
      <div className="container my-4 animated fadIn">
        <div className="shadow">
          <div className="card py-4">
            <div className="row">
              <div className="col-md-2 text-center">
                <img width="85" height="85" src={iconNegocio} alt="" />
              </div>
              <div className="col-md-8 mt-3 mt-md-0 text-center text-md-left">
                <div className="row">
                  <div className="col-md-12">
                    <span
                      className="font-weight-bold mb-2 d-block h6"
                      style={{ color: "#e89b03" }}
                    >
                      Datos de Empresa Contratante
                    </span>
                  </div>
                  <div className="col-md-5">
                    <span className="font-weight-bold text-muted mr-1">
                      Razón social:
                    </span>
                    {this.state.negocio.razonSocial}
                    <br />
                    <span className="font-weight-bold text-muted mr-1">
                      RUT:
                    </span>
                    {format(this.state.negocio.rutEmpresa+""+this.state.negocio.digitoVerificadorEmpresa)}
                    <br />
                    <span className="font-weight-bold text-muted mr-1">
                      Delegado:
                    </span>
                    {`${this.state.negocio.nombreDelegado} ${this.state.negocio.apellidoPaternoDelegado}`}

                    <br />
                  </div>

                  <div
                    className="col-1 mx-0 px-0"
                    style={{ borderLeft: "1px solid #e89b03" }}
                  ></div>

                  <div className="col-md-6 mt-3 mt-md-0">
                    {canalVenta &&
                      (canalVenta.idCanalVenta === TIPO_CANAL_VENTA_DIGITAL ||
                        canalVenta.idCanalVenta === TIPO_CANAL_VENTA_CORREDOR)
                      && (
                        <Fragment>
                          <span className="font-weight-bold text-muted mr-1">
                            Seguro contratado:
                          </span>
                          {asegurable5_50
                            ? this.state.negocio.planCotizado
                              ? objSeguroContratado
                              : STRING_VACIO
                            : this.props.store.reducer.informacionPoliza
                              ? this.props.store.reducer.informacionPoliza
                                .segurosContratados
                              : STRING_VACIO}{" "}
                          <br />
                        </Fragment>
                      )}
                    {asegurable5_50 && (
                      <Fragment>
                        <span className="font-weight-bold text-muted mr-1">
                          Días restantes:
                        </span>
                        {this.state.negocio.fechaTerminoEnrolamiento
                          ? moment(
                            this.state.negocio.fechaTerminoEnrolamiento
                          ).diff(moment(), "days") + " días"
                          : "-"}
                        <br />
                        <span className="font-weight-bold text-muted mr-1">
                          Fecha límite:
                        </span>
                        {this.state.negocio.fechaTerminoEnrolamiento
                          ? moment(
                            this.state.negocio.fechaTerminoEnrolamiento
                          ).format("DD-MM-YYYY")
                          : "-"}

                        <br />
                      </Fragment>
                    )}
                  </div>
                </div>
              </div>
              {this.props.store.reducer.progresoAsegurable && (
                <div className="col-md-2 pl-0 text-center align-self-center">
                  <CircularProgressbar
                    className="mb-1"
                    styles={buildStyles({
                      pathColor: "#ed8d00",
                      textColor: "#ed8d00",
                      trailColor: "#d6d6d6",
                    })}
                    value={
                      this.props.store.reducer.progresoAsegurable
                        .porcentajeAvance
                    }
                    text={`${this.props.store.reducer.progresoAsegurable.porcentajeAvance.toFixed(
                      0
                    )}%`}
                  />
                  <span className="font-weight-bold text-muted">
                    Tu Progreso
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state,
});

export default withRouter(
  connect(mapStateToProps, {})(CabeceraNegocioAsegurado)
);
