import { buscarEmpresaPorRutEjecutivo, obtenerNegocioDelegado } from "../../../services/negocios.service";
import { COD_CORREDOR, COD_DELEGADO, COD_EJECUTIVO_COMPLETO, COD_EJECUTIVO_MANTENCION, ERROR_BUSCAR_NEGOCIO_DELEGADO, ESTADOS_NEGOCIO_INACTIVOS, ESTADO_COTIZACION_EN_PROCESO, PERFIL_CORREDOR, PERFIL_DELEGADO, PERFIL_LDAP, RUTA_DELEGADO_INICIO, RUTA_INICIO_MANTENCION, RUTA_NEGOCIOS, RUT_DELEGADO, RUT_EJECUTIVO, STORAGE_ITEM_FILTRO_CANAL_VENTA, TIPO_INCORPORACION_NUEVO_NEGOCIO, USU_CORREDOR, VALOR_VACIO } from "../../../utils/Constantes";
import { buscarEnNavegador } from "../../../utils/almacenamientoNavegador";
import { buscarContratanteCorredor, buscarNegociosMantencionPoliza } from "../../../services/poliza.service";
import { obtenerNegocios } from "../../../services/cotizacion.services";

export const listarNegociosPorRol = async (objUsuarioSesion, strTipoAlmacen, intTipoIncorporacion) => {

	let objRespuesta = {
		negocios: null,
		redireccionar: null,
		negocio:null,
		delegadoNuevoNegocio: false,
		cotizacion: null,
		error:false,
		mensajeError: null
	};

	if (objUsuarioSesion.rol.codigo === COD_EJECUTIVO_MANTENCION ||
			objUsuarioSesion.rol.codigo === COD_EJECUTIVO_COMPLETO) {
		
		let	lstNegocios = await buscarEmpresaPorRutEjecutivo(objUsuarioSesion.rut);

		objRespuesta.negocios			 = lstNegocios;
		objRespuesta.redireccionar = RUTA_INICIO_MANTENCION;
	}

	if (objUsuarioSesion.rol.codigo === COD_DELEGADO ||
			objUsuarioSesion.rol.codigo === COD_CORREDOR) {

			if (intTipoIncorporacion === TIPO_INCORPORACION_NUEVO_NEGOCIO) {

				if (objUsuarioSesion.rol.codigo === COD_DELEGADO) {

					try {
						let objDatosPeticion = configurarPeticionListarNegocios(objUsuarioSesion, strTipoAlmacen);
						let lstNegocios			 = await obtenerNegocios(objDatosPeticion);
						let objNegociosFlujo = configurarNegociosPorFlujo(lstNegocios);
						let objNegocio			 = Object.assign({}, ...objNegociosFlujo.negociosDigital);
						const objDatosEnvio  = {
							idNegocio: objNegocio.idNegocio,
							perfil: PERFIL_DELEGADO,
						};
	
						let objDetalleNegocio						 = await obtenerNegocioDelegado(objDatosEnvio);
						const lstCotizacionesGestionadas = objDetalleNegocio.cotizaciones.filter((objCotizacion) =>
							objCotizacion.estadoCotizacion &&
							objCotizacion.estadoCotizacion.idEstadoCotizacion >= ESTADO_COTIZACION_EN_PROCESO
						);

						objRespuesta.negocio							= objDetalleNegocio;
						objRespuesta.cotizacion					 	= lstCotizacionesGestionadas;
						objRespuesta.delegadoNuevoNegocio = true;
						objRespuesta.redireccionar				= RUTA_DELEGADO_INICIO;

					} catch (objError) {
						console.error(objError);
						objRespuesta.error				 = true;
						objRespuesta.mensajeError  = ERROR_BUSCAR_NEGOCIO_DELEGADO;
						objRespuesta.redireccionar = "error";
					}
				}

				if (objUsuarioSesion.rol.codigo === COD_CORREDOR) {
					objRespuesta.redireccionar = RUTA_NEGOCIOS;
				}
			}
			else{ //INCORPORACION MANTO

				let objDatosPeticion = configurarPeticionListarNegocios(objUsuarioSesion, strTipoAlmacen);
				let lstNegocios			 = await obtenerNegocios(objDatosPeticion);
				let objNegociosFlujo = configurarNegociosPorFlujo(lstNegocios);

				if (objUsuarioSesion.rol.codigo === COD_DELEGADO) {
					lstNegocios = await buscarNegociosMantencionPoliza(objUsuarioSesion);
				}
				else {
					lstNegocios = await buscarContratanteCorredor(objUsuarioSesion);
				}
	
				if (lstNegocios && lstNegocios.length > VALOR_VACIO) {
			
					lstNegocios.forEach( objNegocio => {
						
						let objNegocioMantencion = objNegociosFlujo.negociosMantencion.find( objNegocioManto => objNegocio.rutEmpresa === objNegocioManto.rutEmpresa);
					
						if (objNegocioMantencion) {
							
							if (objUsuarioSesion.rol.codigo === COD_DELEGADO) {
								objNegocio.declaracionDelegado = objNegocioMantencion.declaracionDelegado
							}
			
							if (objUsuarioSesion.rol.codigo === COD_DELEGADO) {
								objNegocio.declaracionesPerfil = objNegocioMantencion.declaracionesPerfil;
							}
						}
					});
	
					objNegociosFlujo.negociosMantencion = lstNegocios;
				}
	
				objRespuesta.negocios = objNegociosFlujo.negociosMantencion;
				objRespuesta.redireccionar = RUTA_INICIO_MANTENCION;
			}
	}

	return objRespuesta;
}

const configurarPeticionListarNegocios = (objUsuarioSesion, strTipoAlmacen) => {

	let strCampoPerfil, strPerfil, strCampoValor;

	switch (objUsuarioSesion.rol.codigo) {
		case COD_EJECUTIVO_MANTENCION:
		case COD_EJECUTIVO_COMPLETO:
			strCampoPerfil = RUT_EJECUTIVO;
			strPerfil      = PERFIL_LDAP;
			strCampoValor  = objUsuarioSesion.rut;
			break;

		case COD_CORREDOR:
			strCampoPerfil = USU_CORREDOR;
			strPerfil      = PERFIL_CORREDOR;
			strCampoValor  = objUsuarioSesion.id;
			break;
		
		default://DELEGADO
			strCampoPerfil = RUT_DELEGADO;
			strPerfil      = PERFIL_DELEGADO;
			strCampoValor  = objUsuarioSesion.rut;
			break;
	}

	let objDatosPeticion = {
		negocio: { 
			vigente: true 
		},
		[strCampoPerfil]: strCampoValor,
		perfil: strPerfil
	};

	const intFiltroCanalVenta = buscarEnNavegador(STORAGE_ITEM_FILTRO_CANAL_VENTA, strTipoAlmacen);

	if (intFiltroCanalVenta) {

		objDatosPeticion.negocio.canalVenta = { "idCanalVenta" : intFiltroCanalVenta } 
	}

	return objDatosPeticion;
}

const configurarNegociosPorFlujo = (lstNegocios) => {

	let lstMantencionNegocios = [];
	let lstDigitalNegocios	  = [];
	
	if (lstNegocios && lstNegocios.length > VALOR_VACIO) {

		let lstEstadosInactivos = ESTADOS_NEGOCIO_INACTIVOS.map(objEstado => objEstado.idEstadoNegocio);

		lstNegocios.forEach( (objNegocio) => {

			if ( lstEstadosInactivos.includes(objNegocio.estadoNegocio.idEstadoNegocio) ){
				lstMantencionNegocios.push(objNegocio);
			}
			else{ 
				lstDigitalNegocios.push(objNegocio);
			}
		});
	}

	return { negociosMantencion: lstMantencionNegocios, negociosDigital: lstDigitalNegocios }
}
