import { useDispatch, useSelector } from "react-redux";
import {
  guardarEscogerIncorporacion,
  guardarEscogerPerfil,
  guardarFormulario,
  guardarPerfilesInicioSesion,
  guardarTipoIncorporacion,
  guardarTipoIncorporacionPrevio,
  guardarTipoUsuario,
  guardarUsuarioAutenticado,
  guardarUsuarioPerfiles,
  guardarUsuarioSesion,
} from "../actions/actionsAuth";
import {
  COD_ASEGURADO,
  COD_CORREDOR,
  COD_DELEGADO,
  CONFIGURACION_INICIAL_SESION,
  FALSO,
  GA_CATEGORIA_LOGIN,
  GA_LABEL_ERROR,
  LISTA_PERFILES_INICIO_SESION,
  NOTIFICACION_TIPO_ERROR,
  NOTIFICACION_TIPO_WARN,
  PERFIL_ASEGURABLE,
  PERFIL_CORREDOR,
  PERFIL_DELEGADO,
  PERFIL_LDAP,
  ROL_CORREDOR,
  ROL_DELEGADO,
  RUTA_DEFAULT,
  RUTA_INICIO_SESION,
  SESION_TIEMPO_CERRANDO,
  SESION_TIEMPO_CLASE_INFO,
  SESION_TIEMPO_MINIMO_CERRAR_SESION_SEGUNDOS,
  SESION_TIEMPO_MINIMO_MINUTOS,
  SESION_TIEMPO_MOSTRAR_ALERTA_SEGUNDOS,
  SESION_TIEMPO_REACTIVANDO,
  STORAGE_ITEM_HORA_INICIO_SESION,
  STORAGE_ITEM_PERFILES,
  STORAGE_ITEM_SELECCIONAR_INCORPORACION,
  STORAGE_ITEM_SESION,
  STORAGE_ITEM_TIEMPO_MAXIMO_SESION,
  STORAGE_ITEM_TIPO_INCORPORACION,
  STORAGE_ITEM_TIPO_USUARIO,
  VALOR_DOS,
  VALOR_MINUTOS_SEGUNDOS,
  VALOR_SEGUNDO_EN_MILISEGUNDOS,
  VALOR_ZERO,
  VERDADERO,
} from "../utils/Constantes";
import { notificacion } from "../app/components/transversales/Notificaciones";
import {
  actualizarTokensSesion,
  buscarPerfilPorTipoIncorporacion,
  cerrarSesion as cerrarSesionServicio,
  iniciarSesion,
  mostrarAlertaTiempoSesion,
  procesarAuthExterno,
  validarFormularioInicioSesion,
} from "../services/auth.services";
import {
  guardar_cotizacion,
  guardar_negocio,
  guardar_negocios,
} from "../actions/actions";
import { useHistory } from "react-router-dom";
import { crearAnaliticaPorPerfil, crearEventoTraza } from "../utils/analitica";
import {
  guardarEnNavegador,
  buscarEnNavegador,
  eliminarTodoEnNavegador,
} from "../utils/almacenamientoNavegador";
import moment from "moment";
import { useRef } from "react";
import Swal from "sweetalert2";
import { obtenerPersonaPorRut } from "../services/negocios.service";
import { listarNegociosPorRol } from "../app/components/negocio/Negocios";
import { useCargando } from "./useCargando";

export const useAutenticacion = () => {
  const { mostrarOcultarCargando } = useCargando();
  const objRedireccion = useHistory();
  const strAlmacenamiento = useSelector(
    (store) => store.reducer.tipoAlmacenamiento
  );
  const blnUsuarioAutenticado = useSelector(
    (store) => store.auth.usuarioAutenticado
  );
  const lstSesionPerfiles = useSelector(
    (store) => store.auth.perfilesInicioSesion
  );
  const objFormulario = useSelector((store) => store.auth.formInicioSesion);
  const objTipoUsuario = useSelector((store) => store.auth.tipoUsuario);
  const objUsuarioSesion = useSelector((store) => store.auth.usuarioSesion);
  const lstUsuarioPerfiles = useSelector((store) => store.auth.usuarioPerfiles);
  const objGuardarEstado = useDispatch();
  let objIntervaloCalcular = useRef();
  let objTemporizadorCalculo = useRef();
  let objAlertaTiempoSesion = useRef();

  const guardarSeleccionPerfil = (objPerfil) => {
    let objTipoUsuario = { perfil: objPerfil.perfil, codigo: objPerfil.codigo };

    objGuardarEstado(guardarTipoUsuario(objTipoUsuario));
    objGuardarEstado(guardarEscogerPerfil(false));
  };

  const procesarInicioSesion = async (objEvento) => {
    mostrarOcultarCargando(VERDADERO);
    objEvento.preventDefault();

    let objValidacion = validarFormularioInicioSesion(
      objFormulario,
      objTipoUsuario
    );

    if (objValidacion.blnError) {
      mostrarOcultarCargando(FALSO);
      objGuardarEstado(guardarFormulario(CONFIGURACION_INICIAL_SESION));
      return notificacion(objValidacion.tipoError, objValidacion.mensajeError);
    }

    let objAutenticacion = await iniciarSesion(objFormulario, objTipoUsuario);

    if (objAutenticacion.blnError) {
      mostrarOcultarCargando(FALSO);
      objGuardarEstado(guardarFormulario(CONFIGURACION_INICIAL_SESION));

      crearEventoTraza(GA_CATEGORIA_LOGIN, `${GA_LABEL_ERROR} ${objAutenticacion.validacion.error}`);

      return notificacion(
        objAutenticacion.validacion.tipoNotificacion,
        objAutenticacion.validacion.error
      );
    }

    await guardarInformacionSesion(objAutenticacion.sesion, objTipoUsuario);
    crearAnaliticaPorPerfil(objAutenticacion.sesion.rol.codigo);

    objGuardarEstado(guardarUsuarioAutenticado(VERDADERO));

    if (!objAutenticacion.sesion.validarIncorporacion) {
      mostrarOcultarCargando(FALSO);
      objRedireccion.push(RUTA_DEFAULT);
    }
  };

  const procesarInicioExterno = async (strRut, strToken, strPerfil) => {
    mostrarOcultarCargando(VERDADERO);
    cargarSesionGuardada();

    if (objUsuarioSesion) {
      objGuardarEstado(guardarUsuarioAutenticado(VERDADERO));

      if (!objUsuarioSesion.validarIncorporacion) {
        mostrarOcultarCargando(FALSO);
        objRedireccion.push(RUTA_DEFAULT);
      }
    } else {
      let objTipoUsuario;

      if (strPerfil.toUpperCase() === PERFIL_DELEGADO) {
        objTipoUsuario = {
          perfil: ROL_DELEGADO,
          codigo: COD_DELEGADO,
          activo: true,
        };
      } else {
        objTipoUsuario = {
          perfil: ROL_CORREDOR,
          codigo: COD_CORREDOR,
          activo: true,
        };
      }

      let objAutenticacion = await procesarAuthExterno(
        strRut,
        strToken,
        objTipoUsuario.codigo
      );

      if (objAutenticacion.blnError) {
        mostrarOcultarCargando(FALSO);
        return notificacion(
          objAutenticacion.validacion.tipoNotificacion,
          objAutenticacion.validacion.error
        );
      }

      objGuardarEstado(guardarTipoUsuario(objTipoUsuario));
      objGuardarEstado(guardarEscogerPerfil(false));

      await guardarInformacionSesion(objAutenticacion.sesion, objTipoUsuario);
      crearAnaliticaPorPerfil(objAutenticacion.sesion.rol.codigo);

      objGuardarEstado(guardarUsuarioAutenticado(VERDADERO));

      if (!objAutenticacion.sesion.validarIncorporacion) {
        mostrarOcultarCargando(FALSO);
        objRedireccion.push(RUTA_DEFAULT);
      }
    }
  };

  const liberarPerfilSesion = () => {
    objGuardarEstado(guardarPerfilesInicioSesion(LISTA_PERFILES_INICIO_SESION));
    objGuardarEstado(guardarTipoUsuario(null));
    objGuardarEstado(guardarEscogerPerfil(VERDADERO));
  };

  const procesarCampoFormulario = (objEvento) => {
    let intValorCampo = objEvento.target.value;
    let strNombreCampo = objEvento.target.name;
    let objData = { ...objFormulario, [strNombreCampo]: intValorCampo };

    objGuardarEstado(guardarFormulario(objData));
  };

  const guardarInformacionSesion = async (objSesion, objTipoUsuario) => {
    if (
      objTipoUsuario.codigo === COD_DELEGADO ||
      objTipoUsuario.codigo === COD_CORREDOR
    ) {
      guardarEnNavegador(
        STORAGE_ITEM_PERFILES,
        objSesion.perfiles,
        strAlmacenamiento
      );
      guardarEnNavegador(
        STORAGE_ITEM_SELECCIONAR_INCORPORACION,
        objSesion.escogerIncorporacion,
        strAlmacenamiento
      );
      objGuardarEstado(guardarUsuarioPerfiles(objSesion.perfiles));
      objGuardarEstado(
        guardarEscogerIncorporacion(objSesion.escogerIncorporacion)
      );
    }

    let strHoraInicioSesion = moment().format();
    objSesion.minutosMostrarAlerta =
      objSesion.minutosVigenciaSesion - VALOR_DOS;

    guardarEnNavegador(
      STORAGE_ITEM_HORA_INICIO_SESION,
      strHoraInicioSesion,
      strAlmacenamiento
    );
    guardarEnNavegador(
      STORAGE_ITEM_TIEMPO_MAXIMO_SESION,
      objSesion.minutosMostrarAlerta,
      strAlmacenamiento
    );
    guardarEnNavegador(STORAGE_ITEM_SESION, objSesion, strAlmacenamiento);
    guardarEnNavegador(
      STORAGE_ITEM_TIPO_USUARIO,
      objTipoUsuario,
      strAlmacenamiento
    );

    objGuardarEstado(guardarUsuarioSesion(objSesion));
    objGuardarEstado(guardarFormulario(CONFIGURACION_INICIAL_SESION));
    
    let strPerfil;
    let blnBuscarPersona = true;
    switch (objTipoUsuario.codigo) {
      case COD_DELEGADO:
        strPerfil = PERFIL_DELEGADO;
        break;

      case COD_CORREDOR:
        strPerfil = PERFIL_CORREDOR;
        break;

      case COD_ASEGURADO:
        blnBuscarPersona = false;
        strPerfil = PERFIL_ASEGURABLE;
        break;
    
      default:
        strPerfil = PERFIL_LDAP;
        break;
    }

    if (blnBuscarPersona) {
      const objPersona = await obtenerPersonaPorRut(objSesion.rut, strPerfil);
      if (objPersona) {
        objSesion.idPersona = objPersona.idPersona;
      }
    }
    
    objTemporizadorCalculo.current = setTimeout(() => {
      calcularTiempoSesion();
    }, VALOR_SEGUNDO_EN_MILISEGUNDOS);
  };

  const calcularTiempoSesion = () => {
    objIntervaloCalcular.current = setInterval(async () => {
      const intTiempoMaximoSesion = Number(
        buscarEnNavegador(STORAGE_ITEM_TIEMPO_MAXIMO_SESION, strAlmacenamiento)
      );
      const strHoraInicioSesion = buscarEnNavegador(
        STORAGE_ITEM_HORA_INICIO_SESION,
        strAlmacenamiento
      );
      let datHoraInicioSesion = moment(strHoraInicioSesion);
      let datTiempoTranscurrido = moment().diff(datHoraInicioSesion);
      const objTiempoSesion = moment.duration(datTiempoTranscurrido);
      const intTiempoFaltante = Number(
        intTiempoMaximoSesion - objTiempoSesion.minutes()
      );
      let intSegundosRestante = Number(
        VALOR_MINUTOS_SEGUNDOS - objTiempoSesion.seconds()
      );

      if (intTiempoFaltante === SESION_TIEMPO_MINIMO_MINUTOS) {
        if (intSegundosRestante <= SESION_TIEMPO_MOSTRAR_ALERTA_SEGUNDOS) {
          mostrarAlertaTiempoSesion(
            objAlertaTiempoSesion,
            intSegundosRestante,
            procesarReinicioSesion,
            cerrarSesion
          );
        }
        if (
          intSegundosRestante === SESION_TIEMPO_MINIMO_CERRAR_SESION_SEGUNDOS
        ) {
          cerrarSesion();
        }
      }

      if (intTiempoFaltante < SESION_TIEMPO_MINIMO_MINUTOS) {
        cerrarSesion();
      }
    }, VALOR_SEGUNDO_EN_MILISEGUNDOS);
  };

  const cargarSesionGuardada = () => {
    mostrarOcultarCargando(VERDADERO);

    const objUsuarioSesion = buscarEnNavegador(
      STORAGE_ITEM_SESION,
      strAlmacenamiento
    );
    const lstPerfiles = buscarEnNavegador(
      STORAGE_ITEM_PERFILES,
      strAlmacenamiento
    );
    const objTipoUsuario = buscarEnNavegador(
      STORAGE_ITEM_TIPO_USUARIO,
      strAlmacenamiento
    );
    const blnEscogerIncorporacion = buscarEnNavegador(
      STORAGE_ITEM_SELECCIONAR_INCORPORACION,
      strAlmacenamiento
    );
    const intTipoIncorporacion = buscarEnNavegador(
      STORAGE_ITEM_TIPO_INCORPORACION,
      strAlmacenamiento
    );

    if (objUsuarioSesion) {
      objGuardarEstado(guardarUsuarioSesion(objUsuarioSesion));
      objGuardarEstado(guardarUsuarioAutenticado(VERDADERO));

      objTemporizadorCalculo.current = setTimeout(() => {
        calcularTiempoSesion();
      }, VALOR_SEGUNDO_EN_MILISEGUNDOS);

      if (objTipoUsuario) {
        objGuardarEstado(guardarTipoUsuario(objTipoUsuario));
      }

      if (
        objTipoUsuario.codigo === COD_DELEGADO ||
        objTipoUsuario.codigo === COD_CORREDOR
      ) {
        if (lstPerfiles) {
          objGuardarEstado(guardarUsuarioPerfiles(lstPerfiles));
          objGuardarEstado(
            guardarEscogerIncorporacion(blnEscogerIncorporacion)
          );
          objGuardarEstado(guardarTipoIncorporacion(intTipoIncorporacion));
          actualizarPerfilSesion(intTipoIncorporacion);
        }
      } else {
        objRedireccion.push(RUTA_DEFAULT);
        mostrarOcultarCargando(FALSO);
      }
    } else {
      mostrarOcultarCargando(FALSO);
    }
  };

  const cerrarSesion = async () => {
    Swal.fire({
      titleText: SESION_TIEMPO_CERRANDO,
      icon: NOTIFICACION_TIPO_WARN,
      allowOutsideClick: FALSO,
      allowEscapeKey: FALSO,
      showCloseButton: FALSO,
      showConfirmButton: FALSO,
      showCancelButton: FALSO,
      customClass: {
        container: SESION_TIEMPO_CLASE_INFO,
      },
      willOpen: () => {
        Swal.showLoading();
      },
    });

    const objSesion = buscarEnNavegador(STORAGE_ITEM_SESION, strAlmacenamiento);

    if (objSesion) {
      cerrarSesionServicio(objSesion.rol.codigo);
      eliminarTodoEnNavegador();
      clearInterval(objIntervaloCalcular.current);
      clearTimeout(objTemporizadorCalculo.current);
      objRedireccion.push(RUTA_INICIO_SESION);
      objGuardarEstado(guardarEscogerPerfil(VERDADERO));
      objGuardarEstado(guardarEscogerIncorporacion(FALSO));
      objGuardarEstado(guardarUsuarioAutenticado(FALSO));
      objGuardarEstado(guardarTipoIncorporacionPrevio(VALOR_ZERO));
      objGuardarEstado(guardarTipoIncorporacion(VALOR_ZERO));
      objGuardarEstado(guardarUsuarioPerfiles([]));
      objGuardarEstado(guardarTipoUsuario(null));
      objGuardarEstado(guardarUsuarioSesion(null));
    }
    Swal.close();
  };

  const procesarReinicioSesion = async () => {
    clearInterval(objIntervaloCalcular.current);
    clearTimeout(objTemporizadorCalculo.current);
    objIntervaloCalcular.current = null;
    objTemporizadorCalculo.current = null;

    Swal.fire({
      titleText: SESION_TIEMPO_REACTIVANDO,
      icon: NOTIFICACION_TIPO_WARN,
      allowOutsideClick: FALSO,
      allowEscapeKey: FALSO,
      showCloseButton: FALSO,
      showConfirmButton: FALSO,
      showCancelButton: FALSO,
      customClass: {
        container: SESION_TIEMPO_CLASE_INFO,
      },
      willOpen: () => {
        Swal.showLoading();
      },
    });

    let objUsuarioSesion = buscarEnNavegador(
      STORAGE_ITEM_SESION,
      strAlmacenamiento
    );
    let objTipoUsuario = buscarEnNavegador(
      STORAGE_ITEM_TIPO_USUARIO,
      strAlmacenamiento
    );
    let objRespuesta = await actualizarTokensSesion(
      objUsuarioSesion,
      objTipoUsuario.codigo
    );

    if (objRespuesta.validacion.blnError) {
      Swal.close();
      objAlertaTiempoSesion.current = null;
      cerrarSesion();
      return notificacion(
        objRespuesta.validacion.tipoNotificacion,
        objRespuesta.validacion.error
      );
    } else {
      objUsuarioSesion.token = objRespuesta.tokens.autenticacion;
      objUsuarioSesion.tokenServlet = objRespuesta.tokens.puertaEnlace;
      await guardarInformacionSesion(objUsuarioSesion, objTipoUsuario);
    }

    Swal.close();
    objAlertaTiempoSesion.current = null;
  };

  const actualizarPerfilSesion = async (intTipoIncorporacion) => {
    if (objTipoUsuario) {
      let objPerfil = buscarPerfilPorTipoIncorporacion(
        lstUsuarioPerfiles,
        objTipoUsuario,
        intTipoIncorporacion
      );

      if (objPerfil) {
        let objSesion = { ...objUsuarioSesion };
        objSesion.perfil = { ...objPerfil.perfil };
        objSesion.menu = [...objPerfil.menu];
        objSesion.rol = { ...objPerfil.rol };

        let objRespuesta = await listarNegociosPorRol(
          objSesion,
          strAlmacenamiento,
          intTipoIncorporacion
        );

        if (objRespuesta.error) {
          cerrarSesion();
          notificacion(NOTIFICACION_TIPO_ERROR, objRespuesta.mensajeError);
          mostrarOcultarCargando(FALSO);
        } else {
          objGuardarEstado(guardarUsuarioSesion(objSesion));
          guardarEnNavegador(STORAGE_ITEM_SESION, objSesion, strAlmacenamiento);

          if (objRespuesta.delegadoNuevoNegocio) {
            objGuardarEstado(guardar_negocio(objRespuesta.negocio));
            objGuardarEstado(guardar_cotizacion(objRespuesta.cotizacion));
          } else {
            objGuardarEstado(guardar_negocios(objRespuesta.negocios));
          }

          objRedireccion.push(objRespuesta.redireccionar);
          mostrarOcultarCargando(FALSO);
        }
      }
    }
  };

  return {
    perfiles: lstSesionPerfiles,
    usuarioSesion: objUsuarioSesion,
    usuarioAutenticado: blnUsuarioAutenticado,
    guardarSeleccionPerfil,
    actualizarPerfilSesion,
    liberarPerfilSesion,
    procesarCampoFormulario,
    procesarInicioSesion,
    procesarReinicioSesion,
    procesarInicioExterno,
    cargarSesionGuardada,
    cerrarSesion,
  };
};
