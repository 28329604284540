import React, { useState, useEffect, useMemo } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import moment from "moment";
import uuid from "uuid";
import {
  loadingAction,
  obtenerMisSolicitudesAction,
} from "../../../../../reducer/delegadoMantencion";
import {
  listarMisSolicitudes,
  enviarMailAsegurable,
  listarPorIdSolicitud,
} from "../../../../../services/asegurable.services";
import { obtenerTipoMovimiento } from "../../../../../services/asegurable.services";
import { notificacion } from "../../../transversales/Notificaciones";
import {
  TIPO_INCORPORACION_MANTENCION,
  ERROR_LISTAR_MIS_SOLICITUDES,
  TYPEOF_STRING,
  ID_ZERO,
  ESTADO_NO_INICIADO,
  ESTADO_ENROLADO,
  ESTADO_EN_PROCESO,
  MENSAJE_REENVIO_CORREO,
  ERROR_SERVIDOR,
  VALOR_ZERO,
  COD_DELEGADO,
  RUT_DELEGADO,
  USU_CORREDOR,
  PERFIL_DELEGADO,
  SOLICITUD_ACTIVO,
  SOLICITUD_INACTIVO,
  ESTADO_FINALIZADO_POR_ASEGURABLE,
  SOLICITUD_FINALIZADA_POR_ASEGURABLE,
  COD_EJECUTIVO_MANTENCION,
  RUT_EJECUTIVO,
  PERFIL_LDAP,
  PERFIL_CORREDOR,
  VALOR_UNO,
  VALOR_DOS,
  ID_TIPO_MOVIMIENTO_EXCLUSION,
  STRING_VACIO,
  COD_EJECUTIVO_COMPLETO,
  TIPO_SOLICITUD_DECLARACION_BENEFICIARIO,
  VALOR_INICIAL_INDICE,
  FECHA_ENVIO_ACCESO,
  GA_CATEGORIA_NEGOCIOS,
  GA_LABEL_LISTAR_SOLICITUDES,
  ROL_ASEGURADO,
} from "../../../../../utils/Constantes";
import Spinner from "../../../transversales/Spinner";
import "react-datepicker/dist/react-datepicker.css";
import SelectContratante from "../ingresarAsegurables/SelectContratante";
import { Fragment } from "react";
import EditarSolicitudes from "./EditarSolicitudes";
import EliminarSolicitud from "./EliminarSolicitud";
import { Tabs, Tab } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import ModalResultadoSolicitud from "./ModalResultadoSolicitud";
import Incorporacion from "./Incorporacion";
import Exclusion from "./Exclusion";
import { listarAseguradoPolizaExclusion } from "../../../../../services/asegurado.services";
import { limpiar_filtro_solicitudes } from "../../../../../actions/actions";
import { obtenerIdOrganizacion } from "../../../../../utils/Functions";
import { crearEventoTraza } from "../../../../../utils/analitica";

const MisSolicitudes = (props) => {
  const temaTabla = {
    title: {
      backgroundColor: "rgb(255, 255, 255)",
      height: "0px",
    },
    header: {
      fontSize: "14px",
      backgroundColor: "rgb(255, 255, 255)",
      fontColor: "black",
      fontWeight: "600",
    },
    rows: {
      backgroundColor: "white",
      borderWidth: "0px",
    },
  };

  const columnDefs = [
    {
      name: FECHA_ENVIO_ACCESO,
      selector: "fechaEnvioCorreoFormateado",
    },
    {
      name: "Póliza",
      selector: "polizas",
    },
    {
      name: "Nombre",
      selector: "nombreCompleto",
    },
    {
      name: "RUT",
      selector: "rut",
    },
    {
      name: "Correo",
      selector: "correoElectronico",
    },
    {
      name: "Fecha Envio Solicitud",
      selector: "fechaEnvioSolicitud",
    },
    {
      name: "Teléfono móvil",
      selector: "telefonoMovil",
    },
    {
      name: "Estado de Enrolamiento",
      selector: "status",
      wrap: true,
    },
    {
      name: "Incorporado",
      selector: "incorporado"
    },
    {
      minWidth: "150px",
      cell: (row) => (
        <React.Fragment>
          <button
            className="btn btn-primary btn-sm btn-table mr-2"
            type="button"
            title="Ver Detalle"
            raised="true"
            primary="true"
            disabled={
              row.status !== ESTADO_ENROLADO ||
              props.store.auth.tipoIncorporacion !==
              TIPO_INCORPORACION_MANTENCION ||
              (row.solicitud.tipoSolicitud && row.solicitud.tipoSolicitud.idTipoSolicitud === TIPO_SOLICITUD_DECLARACION_BENEFICIARIO)
            }
            onClick={() => obtenerResultadoSolicitud(row)}
          >
            <i className="fa fa-search" aria-hidden="true"></i>
          </button>
          {solicitudActiva === SOLICITUD_ACTIVO && (
            <button
              className="btn btn-primary btn-sm btn-table mr-2"
              type="button"
              title="Editar"
              raised="true"
              primary="true"
              disabled={row.status !== ESTADO_NO_INICIADO}
              onClick={() => editarSolicitud(row)}
            >
              <i className="fa fa-pencil" aria-hidden="true"></i>
            </button>
          )}
          {solicitudActiva === SOLICITUD_ACTIVO && (
            <EliminarSolicitud
              row={row}
              asegurables={asegurablesBusqueda}
              setAsegurables={setAsegurablesBusqueda}
              idNegocio={negocios.filter((negocio) => negocio.idOrganizacion === idNegocio)[0].idNegocio}
            />
          )}
          {solicitudActiva === SOLICITUD_ACTIVO && (
            <button
              className="btn btn-dark btn-sm btn-table mr-2"
              type="button"
              title="Reenviar Clave"
              raised="true"
              primary="true"
              disabled={row.status !== ESTADO_NO_INICIADO}
              onClick={() => reenviarCorreo(row)}
            >
              <i className="fa fa-unlock" aria-hidden="true"></i>
            </button>
          )}
        </React.Fragment>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const dispatch = useDispatch();
  const { asegurables, loading } = useSelector((store) => store.delegado);
  const { negocios, negocio, filtroSolicitudes } = useSelector((store) => store.reducer);
  const { usuarioSesion } = useSelector((store) => store.auth);
  const { limpiar_filtro_solicitudes } = props;

  const idOrganizacion = useMemo(() => obtenerIdOrganizacion(negocios, filtroSolicitudes, usuarioSesion.rol.perfil), [negocios, filtroSolicitudes, usuarioSesion])
  const idMovimientoSeleccionado = filtroSolicitudes ? filtroSolicitudes.idMovimientoSeleccionado : 0;
  const FechaModificacionSolicitud = filtroSolicitudes ? new Date(filtroSolicitudes.FechaModificacionSolicitud) : null;
  const [asegurablesBusqueda, setAsegurablesBusqueda] = useState([]);
  const [fechasFiltro, setFechasFiltro] = useState({
    fechaDesde: null,
    fechaHasta: null,
  });
  const [rutContratante, setRutContratante] = useState(filtroSolicitudes ? filtroSolicitudes.rutContratante : 0);
  const [polizas, setPolizas] = useState([]);
  const [polizasFiltro, setPolizasFiltro] = useState([]);
  const [idNegocio, setIdNegocio] = useState(idOrganizacion);
  const [mostrarModalEditar, setModalEditar] = useState(false);
  const [infoEditar, setInfoEditar] = useState({});
  const [mostrarModalDetalleSolicitud, setMostrarModalDetalleSolicitud] =
    useState(false);

  const [solicitudActiva, setSolicitudActiva] = useState(SOLICITUD_ACTIVO);
  const [asegurablesDetalleSolicitud, setAsegurablesDetalleSolicitud] =
    useState([]);
  const [tipoMovimientos, setTipoMovimientos] = useState([]);
  const [seleccionMovimiento, setSeleccionMovimiento] = useState(filtroSolicitudes ? filtroSolicitudes.idMovimientoSeleccionado : VALOR_ZERO);
  const [mostrarSolicitudes, setMostrarSolicitudes] = useState(VALOR_ZERO);
  const [respuestaExclusiones, setRespuestaExclusiones] = useState([]);


  useEffect(() => {
    return () => {
      limpiar_filtro_solicitudes();
    }
  }, [limpiar_filtro_solicitudes])

  useEffect(() => {
    crearEventoTraza(GA_CATEGORIA_NEGOCIOS, GA_LABEL_LISTAR_SOLICITUDES);
    const obtenerTipoMovimientos = async () => {
      const tipoMovimientoTemporal = await obtenerTipoMovimiento();

      setTipoMovimientos(
        tipoMovimientoTemporal
          .filter(
            (condicionado) =>
              condicionado.id === VALOR_UNO || condicionado.id === VALOR_DOS
          )
          .map((condicionado) => {
            condicionado.glosa = condicionado.glosa.replaceAll(
              ROL_ASEGURADO,
              STRING_VACIO
            );
            return condicionado;
          })
      );
    };
    obtenerTipoMovimientos();
  }, []);

  const campoPerfil =
    usuarioSesion.rol.codigo === COD_DELEGADO
      ? RUT_DELEGADO
      : usuarioSesion.rol.codigo === COD_EJECUTIVO_MANTENCION ||
        usuarioSesion.rol.codigo === COD_EJECUTIVO_COMPLETO
        ? RUT_EJECUTIVO
        : USU_CORREDOR;

  const dataEnvioSolicitudes = {
    [campoPerfil]:
      usuarioSesion.rol.codigo === COD_DELEGADO ||
        usuarioSesion.rol.codigo === COD_EJECUTIVO_MANTENCION ||
        usuarioSesion.rol.codigo === COD_EJECUTIVO_COMPLETO
        ? usuarioSesion.rut
        : usuarioSesion.id,
    rutContratante: Number(rutContratante ? rutContratante.split("-")[0] : 0),
    idTipoIncorporacion: TIPO_INCORPORACION_MANTENCION,
    fechaEnvioDesde: null,
    fechaEnvioHasta: null,
    polizaVigente: true,
    perfil:
      usuarioSesion.rol.codigo === COD_DELEGADO
        ? PERFIL_DELEGADO
        : usuarioSesion.rol.codigo === COD_EJECUTIVO_MANTENCION ||
          usuarioSesion.rol.codigo === COD_EJECUTIVO_COMPLETO
          ? PERFIL_LDAP
          : PERFIL_CORREDOR,
    polizaFiltro: [],
  };
  const obtenerResultadoSolicitud = async (titularSeleccionado) => {
    const asegurables = [];
    const perfil =
      usuarioSesion.rol.codigo === COD_EJECUTIVO_MANTENCION ||
        usuarioSesion.rol.codigo === COD_EJECUTIVO_COMPLETO
        ? PERFIL_LDAP
        : PERFIL_DELEGADO;

    let respuesta = await listarPorIdSolicitud(
      titularSeleccionado.solicitud.idSolicitud,
      perfil
    );
    for (const asegurable of respuesta) {
      const polizas = asegurable.grupos.map((grupo, index) => {
        return (
          <div key={index}>
            {grupo.nroPoliza}-{grupo.nroRenovacion}
          </div>
        );
      });
      asegurables.push({
        polizas: polizas,
        rut: asegurable.rut + "-" + asegurable.digitoVerificador,
        nombreCompleto:
          asegurable.nombre +
          " " +
          asegurable.apellidoPaterno +
          " " +
          asegurable.apellidoMaterno,
        tipo: asegurable.parentesco.glosa,
        evaluacionSuscripcion: asegurable.tieneEvaluacion ? "SI" : "NO",
        incorporado: asegurable.incorporado ? "SI" : "NO",
        estadoSolicitud: asegurable.estadoSolicitudIncorporacion,
        observacion: asegurable.observacion,
        fechaNacimiento: asegurable.fechaNacimiento
          ? moment(asegurable.fechaNacimiento).format("DD-MM-YYYY")
          : "-",
        declaraPreexistencia: asegurable.declaraPreexistencia ? "SI" : "NO",
        declaraDeporte: asegurable.declaraDeporte ? "SI" : "NO",
        verMas: false,
      });
    }

    setAsegurablesDetalleSolicitud(asegurables);
    setMostrarModalDetalleSolicitud(true);
  };

  const listarSolicitudes = async (tabSeleccionada) => {

    //SE VALIDA TIPO DE MOVIMIENTO
    if (seleccionMovimiento === VALOR_UNO) {
      //INCORPORACION
      dispatch(loadingAction(true));

      dataEnvioSolicitudes.polizaVigente = solicitudActiva === SOLICITUD_ACTIVO;
      dataEnvioSolicitudes.fechaEnvioDesde = fechasFiltro.fechaDesde;
      dataEnvioSolicitudes.fechaEnvioHasta = fechasFiltro.fechaHasta;
      let solicitudes = await listarMisSolicitudes(dataEnvioSolicitudes);
      if (solicitudes) {
        solicitudes.forEach((e) => {
          e.key = uuid();
          e.rut = typeof e.rut === TYPEOF_STRING && e.rut.includes("-")
              ? e.rut
              : e.rut + "-" + e.digitoVerificador;
          e.nombreCompleto = `${e.nombre} ${e.apellidoPaterno} ${e.apellidoMaterno}`;
          e.apellidos = `${e.apellidoPaterno} ${e.apellidoMaterno}`;
          e.telefonoMovil = e.telefonoMovil !== null ? e.telefonoMovil : "";
          e.fechaEnvioCorreoFormateado = e.fechaEnvioCorreo
            ? moment(e.fechaEnvioCorreo).format("DD-MM-YYYY")
            : "";
          e.fechaEnvioSolicitud = e.solicitud.fechaRecepcion
            ? moment(e.solicitud.fechaRecepcion).format("DD-MM-YYYY")
            : "";
          e.incorporado = e.incorporado === true ? 'SI' : 'NO';
          if (e.solicitud.idSolicitud === ID_ZERO) {
            e.status = ESTADO_NO_INICIADO;
          } else if (
            e.solicitud.idSolicitud !== ID_ZERO &&
            e.solicitud.fechaRecepcion !== null
          ) {
            e.status = ESTADO_ENROLADO;
          } else if (
            e.solicitud.idSolicitud !== ID_ZERO &&
            e.solicitud.solicitudEstado &&
            e.solicitud.solicitudEstado.idSolicitudEstado ===
            SOLICITUD_FINALIZADA_POR_ASEGURABLE
          ) {
            e.status = ESTADO_FINALIZADO_POR_ASEGURABLE;
          } else if (e.solicitud.idSolicitud !== ID_ZERO) {
            e.status = ESTADO_EN_PROCESO;
          }

          if (solicitudActiva === SOLICITUD_ACTIVO) {
            e.polizas = e.grupos.map((grupo, indice) => {
              let poliza;

              if (
                usuarioSesion.rol.codigo === COD_EJECUTIVO_MANTENCION ||
                usuarioSesion.rol.codigo === COD_EJECUTIVO_COMPLETO
              ) {
                poliza = negocio.polizas.find((p) => {
                  return p.idContratoSeguro === grupo.idContratoSeguro;
                });
              } else {
                poliza = polizas.find((p) => {
                  return p.poliza.idContratoSeguro === grupo.idContratoSeguro || (p.poliza.polizaAgrupada && p.poliza.polizaAgrupada.idContratoSeguro === grupo.idContratoSeguro);
                });
              }

              if (poliza) {
                if (indice > VALOR_INICIAL_INDICE) {
                  return (
                    <div key={indice}>
                      {usuarioSesion.rol.codigo === COD_EJECUTIVO_MANTENCION ||
                        usuarioSesion.rol.codigo === COD_EJECUTIVO_COMPLETO
                        ? `${poliza.numeroPoliza}-${poliza.numeroRenovacion}`
                        : `${poliza.poliza.numeroPoliza}-${poliza.poliza.numeroRenovacion}`}
                    </div>
                  );
                } else {
                  return (
                    <div key={indice}>
                      {usuarioSesion.rol.codigo === COD_EJECUTIVO_MANTENCION ||
                        usuarioSesion.rol.codigo === COD_EJECUTIVO_COMPLETO
                        ? `${poliza.numeroPoliza}-${poliza.numeroRenovacion}`
                        : poliza.poliza.polizaAgrupada ? `${poliza.poliza.polizaAgrupada.numeroPoliza}-${poliza.poliza.polizaAgrupada.numeroRenovacion}` : `${poliza.poliza.numeroPoliza}-${poliza.poliza.numeroRenovacion}`}
                    </div>
                  );
                }
              }
              return null;
            });
          } else {
            e.polizas = e.grupos.map((grupo, index) => {
              let poliza = polizasFiltro.find(
                (p) => p.numeroPoliza === grupo.nroPoliza
              );
              if (poliza !== undefined) {
                return (
                  <div key={index}>
                    {grupo.nroPoliza}-{grupo.nroRenovacion}
                  </div>
                );
              }
              return null;
            });
          }
        });

        const solicitudesConPolizasVigentes = solicitudes.filter(
          (solicitud) => {
            const polizasAsegurable = solicitud.polizas.filter(
              (poliza) => poliza
            );
            return polizasAsegurable.length;
          }
        );

        dispatch(obtenerMisSolicitudesAction(solicitudesConPolizasVigentes));
        setAsegurablesBusqueda(solicitudesConPolizasVigentes);
        setMostrarSolicitudes(VALOR_UNO);
      } else {
        notificacion("danger", ERROR_LISTAR_MIS_SOLICITUDES);
      }
    } else if (seleccionMovimiento === VALOR_DOS) {
      //EXCLUSION

      dispatch(loadingAction(true));

      setRespuestaExclusiones(
        await listarAseguradoPolizaExclusion(
          {
            aseguradoPolizaContratante: {
              rutContratante,
              tipoMovimiento: {
                ...{ id: ID_TIPO_MOVIMIENTO_EXCLUSION },
              },
            },
          },
          usuarioSesion.rol.perfil
        )
      );

      dispatch(loadingAction(false));

      setMostrarSolicitudes(VALOR_DOS);
    }
  };

  const handleSearch = (dataFiltro, tipo) => {
    setAsegurablesBusqueda(dataFiltro);
  };

  const reenviarCorreo = async (row) => {
    const dataCorreo = {
      asegurable: {
        idAsegurable: row.idAsegurable,
        rut: Number(row.rut.split("-")[0]),
        digitoVerificador: row.digitoVerificador,
      },
    };

    const perfil =
      usuarioSesion.rol.codigo === COD_EJECUTIVO_MANTENCION ||
        usuarioSesion.rol.codigo === COD_EJECUTIVO_COMPLETO
        ? PERFIL_LDAP
        : PERFIL_DELEGADO;

    const envioCorreo = await enviarMailAsegurable(dataCorreo, perfil);

    if (envioCorreo !== null) {
      notificacion(
        "success",
        `${MENSAJE_REENVIO_CORREO}${row.nombre} ${row.apellidoPaterno}`
      );
    } else {
      notificacion("danger", ERROR_SERVIDOR);
    }
  };

  const obtenerPolizas = async (idNegocio, nPolizas, polizasFiltro) => {
    polizasFiltro &&
      polizasFiltro.forEach((poliza) => {
        poliza.etiqueta = `${poliza.numeroPoliza}-${poliza.numeroRenovacion} ${poliza.tipoPoliza.glosa}`;
      });
    setPolizas(nPolizas);
    setPolizasFiltro(polizasFiltro);
    setIdNegocio(idNegocio);
    setFechasFiltro({
      fechaDesde: null,
      fechaHasta: null,
    });
  };

  const editarSolicitud = (row) => {
    setModalEditar(!mostrarModalEditar);
    setInfoEditar(row);
  };

  const buscarSolicitudes = async (polizasSeleccionadasInactivas) => {
    dataEnvioSolicitudes.polizaFiltro = polizasSeleccionadasInactivas;
    if (
      rutContratante !== VALOR_ZERO &&
      seleccionMovimiento !== VALOR_ZERO &&
      rutContratante !== ""
    ) {
      listarSolicitudes(SOLICITUD_INACTIVO);
    }
  };

  const cargando = async (valorCargando) => {
    dispatch(loadingAction(valorCargando));
  };

  const editarMovimiento = async (movimiento) => {
    setSeleccionMovimiento(movimiento);
  };

  return (
    <Fragment>
      {mostrarModalEditar && (
        <EditarSolicitudes
          mostrarModalEditar={mostrarModalEditar}
          setModalEditar={setModalEditar}
          infoEditar={infoEditar}
          idNegocio={idNegocio}
          polizas={polizas}
          polizasFiltro={polizasFiltro}
          asegurables={asegurablesBusqueda}
          setAsegurables={setAsegurablesBusqueda}
          dataEnvioSolicitudes={dataEnvioSolicitudes}
        />
      )}

      <ModalResultadoSolicitud
        mostrarModal={mostrarModalDetalleSolicitud}
        asegurablesSolicitud={asegurablesDetalleSolicitud}
        sesion={usuarioSesion.rol.codigo}
        cerrarModal={() => setMostrarModalDetalleSolicitud(false)}
      />

      <div className="container">
        <div className="row">
          <div className="col-md-7 mb-3 text-muted">
            <h4 className="font-weight-bold">Mis Solicitudes</h4>
            <p>Solicitudes por asegurable</p>
          </div>
        </div>

        <div className="card shadow mb-3">
          <Tabs
            id="tabSolicitudActivas"
            activeKey={solicitudActiva}
            onSelect={(tabSeleccionada) => {
              setSolicitudActiva(tabSeleccionada);
              setAsegurablesBusqueda([]);
              setIdNegocio(0);
              setRutContratante("");
              setMostrarSolicitudes(VALOR_ZERO);
              setSeleccionMovimiento(VALOR_ZERO);
            }}
          >
            {/* SOLICITUD ACTIVOS */}
            <Tab eventKey="activos" title="Activos">
              <div className="col-md-12 mt-3">
                {solicitudActiva === "activos" && (
                  <SelectContratante
                    obtenerPolizas={obtenerPolizas}
                    rutContratante
                    setRutContratante={setRutContratante}
                    idNegocio={idNegocio}
                    tabActiva="true"
                    tipoMovimientos={tipoMovimientos}
                    idMovimientoSeleccionado={idMovimientoSeleccionado}
                    buscarSolicitudes={buscarSolicitudes}
                    setSeleccionMovimiento={setSeleccionMovimiento}
                    setFechasFiltro={setFechasFiltro}
                    editarMovimiento={editarMovimiento}
                  />
                )}
              </div>
            </Tab>

            {/* SOLICITUD INACTIVOS */}
            <Tab eventKey="inactivos" title="Inactivos">
              <div className="col-md-12 mt-3">
                {solicitudActiva === "inactivos" && (
                  <SelectContratante
                    obtenerPolizas={obtenerPolizas}
                    rutContratante
                    setRutContratante={setRutContratante}
                    tabInactiva="true"
                    polizas={polizas}
                    polizasFiltro={polizasFiltro}
                    buscarSolicitudes={buscarSolicitudes}
                    setPolizasFiltro={setPolizasFiltro}
                    cargando={cargando}
                    idNegocio={idNegocio}
                    idMovimientoSeleccionado={idMovimientoSeleccionado}
                    tipoMovimientos={tipoMovimientos}
                    setSeleccionMovimiento={setSeleccionMovimiento}
                    setFechasFiltro={setFechasFiltro}
                    editarMovimiento={editarMovimiento}
                  />
                )}
              </div>
            </Tab>
          </Tabs>

          {loading ? (
            <Spinner
              claseColor="transparente"
              idSpinner="spinnerInterior"
              posicionLogo="posicionLogo align-self-center w-100"
            />
          ) : (
            <div className="row animated fadeIn">
              {mostrarSolicitudes === VALOR_UNO ? (
                <Incorporacion
                  rutContratante={rutContratante}
                  asegurables={asegurables}
                  handleSearch={handleSearch}
                  polizasFiltro={polizasFiltro}
                  fechasFiltro={fechasFiltro}
                  setFechasFiltro={setFechasFiltro}
                  FechaModificacionSolicitud={FechaModificacionSolicitud}
                  listarSolicitudes={listarSolicitudes}
                  asegurablesBusqueda={asegurablesBusqueda}
                  columnDefs={columnDefs}
                  temaTabla={temaTabla}
                  solicitudActiva={solicitudActiva}
                />
              ) : (
                mostrarSolicitudes === VALOR_DOS && (
                  <Exclusion data={respuestaExclusiones} />
                )
              )}
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  store: state,
});

export default withRouter(connect(mapStateToProps, { limpiar_filtro_solicitudes })(MisSolicitudes));
