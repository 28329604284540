import React, { Component } from "react";

import { Doughnut } from "react-chartjs-2";

export default class BarPie extends Component {
  render() {
    return (
      <Doughnut
        data={this.props.data}
        options={this.props.options}
        onElementsClick={(elems) => {
          this.props.metodo(elems);
        }}
      />
    );
  }
}
