import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import ReactDOM from "react-dom";
import { withRouter } from "react-router-dom";
import {
    RUTA_NEGOCIOS,
    EXITO_MOVER_EJECUTIVO,
    ERROR_AGREGAR_EJECUTIVO,
    VALOR_ZERO,
} from "../../../../../utils/Constantes";
import {
    agregarEjecutivoAJefatura,
} from "../../../../../services/ejecutivo.services";
import {
    guardar_opt_gestionar_ejecutivo,
} from "../../../../../actions/actions";
import { notificacion } from "../../../transversales/Notificaciones";

const ModalMoverEjecutivo = (props) => {
    const [cargarDatos, setCargarDatos] = useState(false);

    const cerrarDialogo = async () => {
        props.setModalMoverEjecutivo(false);
    };

    const volverRutaNegocios = async () => {
        props.history.push(RUTA_NEGOCIOS);
    };

    const moverEjecutivo = async () => {
        setCargarDatos(true);
        const dataEnvio = {
            idPersona: props.ejecutivo[VALOR_ZERO].idPersona,
            idRegion: props.region,
            idEjecutivoEquipo: props.idEjecutivoEquipo,
        };
        let res = await agregarEjecutivoAJefatura(dataEnvio);
        if (res !== null) {
            notificacion("success", EXITO_MOVER_EJECUTIVO);
            cerrarDialogo();
            volverRutaNegocios();
        } else {
            notificacion("danger", ERROR_AGREGAR_EJECUTIVO);
            setCargarDatos(false);
        }
    }

    return ReactDOM.createPortal(
        <div
            className={`${props.show ? "modal_overlay show lbg" : "modal_overlay"}`}
        >
            <div className="container h-100">
                <div className="align-self-center w-100">
                    <div
                        className="main_modal animated bounceInLeft center-2 large"
                        style={{ maxWidth: "550px" }}
                    >
                        <div className="row">
                            <div className="col-md-12">
                                <div className="modal_title">
                                    <div className="title title font-weight-bold text-primary text-left">
                                        <div
                                            className="mb-3 font-weight-bold"
                                        >
                                            <i style={{ fontSize: "1.2rem" }} className="fa fa-exclamation-circle fa-4x">
                                                <span style={{ fontWeight: 'bold', marginLeft: 5 }}>{props.titulo}</span></i>
                                        </div>
                                    </div>
                                    <div
                                        className="button_close cursor"
                                        onClick={() => {
                                            cerrarDialogo();
                                        }}
                                    >
                                        <svg
                                            stroke="currentColor"
                                            fill="currentColor"
                                            viewBox="0 0 352 512"
                                            height="1em"
                                            width="1em"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z" />
                                        </svg>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="container mt-3 text-center">
                                            ¿Desea asignar a{" "}
                                            <span style={{ fontWeight: 'bold' }}>
                                                {props.ejecutivo[0].nombre} {props.ejecutivo[0].apellidoPaterno} {props.ejecutivo[0].apellidoMaterno}
                                            </span> al equipo de{" "}
                                            <span style={{ fontWeight: 'bold' }}>
                                                {props.jefatura[0].nombre} {props.jefatura[0].apellidoPaterno} {props.jefatura[0].apellidoMaterno}
                                            </span> ?
                                        </div>

                                        <div className="container mt-3 col-11 text-center">
                                            <button
                                                type="button"
                                                className="btn btn-principal mt-4"
                                                onClick={() => moverEjecutivo()}
                                                disabled={cargarDatos}
                                                style={{ marginRight: 20 }}
                                            >
                                                {cargarDatos ? (
                                                    <Fragment>
                                                        <i className="fa fa-spinner fa-pulse fa-fw"></i>
                                                        <span className="sr-only">Loading...</span>
                                                    </Fragment>
                                                ) : (
                                                        <span>Aceptar</span>
                                                    )}
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-principal mt-4"
                                                onClick={() => cerrarDialogo()}
                                            >
                                                Cancelar
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className="modal_foot"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>,
        document.querySelector(`#spinnerNaranjo`)
    );
};

const mapStateToProps = (state) => ({
    store: state,
});

export default withRouter(
    connect(mapStateToProps, {
        guardar_opt_gestionar_ejecutivo,
    })(ModalMoverEjecutivo)
);
