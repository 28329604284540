import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Dropdown, Badge, FormControl } from "react-bootstrap";
import {
  guardar_negocios,
  periodo_negocio,
} from "../../../../../../actions/actions";
import Picker from "react-month-picker";
import Authorized from "../../../../../../utils/Authorized";
import {
  MONTHS,
  T_NEGOCIO_ACTIVO,
  ESTADOS_NEGOCIO_INACTIVOS,
  ESTADOS_NEGOCIO_ACTIVOS,
} from "../../../../../../utils/Constantes";
import MonthBox from "./MonthBox";
import "../../../../../assets/css/month-datepicker.css";
import { VALOR_VACIO } from "../../../../../../utils/Constantes";

const months = MONTHS;

class Filtros extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cargando: true,
      ejecutivos: this.props.ejecutivos,
      tipoNegocio: this.props.tipoNegocio,
      estadosNegocios: [],
      canalesVenta: this.props.canalesVenta,
      canalVenta: this.props.store.ldap.canalVentaBusqueda,
      negocios: this.props.negociox,
      filtrosBusqueda: {
        ejecutivos: this.props.ejecutivos,
        estadosNegocios: this.props.estadosNegocios,
      },
      filtros: [],
      mvalue: this.obtenerPeriodo(),
      mrange: { from: { year: 2014, month: 8 }, to: { year: 2015, month: 5 } },
      pickerLang: {
        months: months,
        from: "From",
        to: "To",
      },
    };

    this.handleClickMonthBox = this.handleClickMonthBox.bind(this);
    this.handleAMonthChange = this.handleAMonthChange.bind(this);
  }

  componentWillMount() {
    this.filtrarEstadosNegocios();
  }

  componentDidUpdate(nextProps, nextState) {
    if (this.state.mvalue !== nextState.mvalue) {
      this.props.periodo_negocio(this.makeText(this.state.mvalue));
    }
  }

  filtrarEstadosNegocios() {
    if (this.state.tipoNegocio === T_NEGOCIO_ACTIVO)
      this.setState({ estadosNegocios: ESTADOS_NEGOCIO_ACTIVOS });
    else this.setState({ estadosNegocios: ESTADOS_NEGOCIO_INACTIVOS });

    this.setState({ cargando: false });
  }

  obtenerPeriodo = () => {
    let date = new Date();
    let periodo;
    this.props.store.reducer.periodoNegocio
      ? (periodo = {
          year: obtenerAnio(this.props.store.reducer.periodoNegocio),
          month: obtenerMes(this.props.store.reducer.periodoNegocio),
        })
      : (periodo = { year: date.getFullYear(), month: date.getMonth() + 1 });

    function obtenerAnio(date) {
      return Number(date.split(" ")[1]);
    }
    function obtenerMes(date) {
      let mes;
      months.forEach(function (m, i) {
        if (m.indexOf(date.split(".")[0]) === 0) return (mes = i + 1);
      });
      return mes;
    }
    return periodo;
  };

  /* =========================
      BÚSQUEDAS REACTIVAS
  ============================ */
  busquedaReactivaNegocios(bsq) {
    const { handleSearch } = this.props;
    let negocios = Object.assign([], this.props.negociox);

    let busqueda = bsq.target.value.toLowerCase();

    let data = negocios.filter((e) => {
      let rutEmpresa = e.rutEmpresa + e.digitoVerificador;
      let razonSocial = e.razonSocial.toLowerCase().replace(/' '/g, "");

      return (
        razonSocial.indexOf(busqueda) > -1 ||
        rutEmpresa.indexOf(busqueda.replace(/[.-]/g, "").replace(/k$/, "")) > -1
      );
    });
    handleSearch(data, "busqueda");
  }

  busquedaReactivaEjecutivos(bsq) {
    bsq = bsq.replace(/ /g, "");

    let data = this.state.ejecutivos.filter((e) => {
      let nombreCompleto = e.nombre + e.apellidoPaterno + e.apellidoMaterno;
      let ejecutivo = nombreCompleto.toLowerCase().replace(/ /g, "");
      return ejecutivo.indexOf(bsq.toLowerCase()) > -1;
    });

    let filtrosBusqueda = Object.assign({}, this.state.filtrosBusqueda);
    filtrosBusqueda.ejecutivos = data;
    this.setState({ filtrosBusqueda });
  }

  /* =========================
      FILTROS DE BÚQUEDA
  ============================ */
  filtrarCanalVenta(canalVenta) {
    this.props.handleCanaVenta(canalVenta.idCanalVenta);
  }

  agregarFiltro(filtro, idFiltro, tipo) {
    let dato = { tipo: tipo, idFiltro, filtro: filtro };

    let filtros = Object.assign([], this.state.filtros);
    filtros.push(dato);

    // Eliminar repetidos
    let flag = {};
    filtros = filtros.filter((e) => {
      let exists = !flag[e.filtro] || false;
      flag[e.filtro] = true;
      return exists;
    });

    this.setState({ filtros }, () => {
      this.filtrar();
    });
  }

  quitarFiltroSeleccionado(filtro) {
    let filtros = Object.assign([], this.state.filtros);
    filtros = filtros.filter((e) => e.filtro !== filtro.filtro);

    this.setState({ filtros }, () => {
      this.filtrar();
    });
  }

  filtrar() {
    const { handleSearch } = this.props;
    let lstNegocios = Object.assign([], this.props.negocioxO);
    let lstFiltros = Object.assign([], this.state.filtros);

    if (lstFiltros.length > VALOR_VACIO) {
      let lstNegociosPre = [];
      for (let objFiltro of lstFiltros) {
        if(this.props.negocioxO  && this.props.negocioxO.length > VALOR_VACIO ){
          let objResultado = this.props.negocioxO.filter(
            (objNegocio) =>
            objNegocio.idPersonaEjecutivo === objFiltro.idFiltro ||
            objNegocio.estadoNegocio.idEstadoNegocio === objFiltro.idFiltro
          );
          lstNegociosPre = lstNegociosPre.concat(objResultado);
        }
      }
      lstNegocios = lstNegociosPre;
    }
    handleSearch(lstNegocios, "filtro");
  }

  /*=============================
      ACCIONES DATE PICKER MONTH
  =============================== */
  handleClickMonthBox(e) {
    this.refs.pickAMonth.show();
  }

  handleAMonthChange(value, text) {
    const { handlePeriod } = this.props;
    if (text <= 9) text = `0${text}`;
    let fechaInicio = `${value}-${text}-01`;
    let fechaTermino = `${value}-${text}-${new Date(value, text, 0).getDate()}`;
    let periodo = { year: value, month: Number(text) };

    this.setState({ mvalue: periodo }, () => {
      handlePeriod(fechaInicio, fechaTermino);
    });
  }

  makeText = (m) => {
    if (m && m.year && m.month)
      return this.state.pickerLang.months[m.month - 1] + ". " + m.year;
    return "?";
  };

  obtenerUltimosAnios() {
    let fecha = new Date();
    let anioActual = fecha.getFullYear();
    let anios = [anioActual];

    for (let i = 10; i > 0; i--) {
      anios.push(--anioActual);
    }
    anios.sort();
    return anios;
  }

  render() {
    return (
      <div>
        {!this.state.cargando && (
          <div className="row mb-3">
            {/* INPUT BSQ */}
            <div className="col-sm-8 col-xl-5 align-self-end">
              <input
                className="search-input w-100"
                type="text"
                id="bsq"
                onChange={(e) => {
                  this.busquedaReactivaNegocios(e);
                }}
                placeholder="Buscar..."
              />
              <div className="text-left mb-2">
                {this.state.filtros.map((f, i) => (
                  <h5 key={i} className="d-inline animated fadeIn">
                    <Badge variant="secondary font-weight-light text-white mx-1">
                      <span className="text-capitalize">
                        {f.tipo.charAt(0).toUpperCase() +
                          f.tipo.slice(1) +
                          ": "}{" "}
                        {f.filtro}
                      </span>
                      <i
                        className="fa fa-times ml-1 cursor"
                        aria-hidden="true"
                        onClick={() => this.quitarFiltroSeleccionado(f)}
                      ></i>
                    </Badge>
                  </h5>
                ))}
              </div>
            </div>

            {/* CALENDARIO */}
            <div className="col-3 col-sm-4 col-md-4 col-xl-1 mt-2 mt-xl-0 align-self-end">
              <div className="edit">
                <Picker
                  ref="pickAMonth"
                  years={this.obtenerUltimosAnios()}
                  value={this.state.mvalue}
                  lang={this.state.pickerLang.months}
                  onChange={this.handleAMonthChange}
                  onDismiss={this.handleAMonthDissmis}
                >
                  <MonthBox
                    value={this.makeText(this.obtenerPeriodo())}
                    onClick={this.handleClickMonthBox}
                  />
                </Picker>
              </div>
            </div>

            {/* FILTRO BÚSQUEDA */}
            <div className="col-9 col-sm-12 col-md-4 col-xl-3 pt-2 align-self-end mb-sm-2 mb-md-0">
              <Dropdown>
                <Dropdown.Toggle
                  variant="dark"
                  bsPrefix="p-2 btn-dark btn-block rounded"
                  id="dropdown-basic"
                >
                  Filtros de búsqueda
                  <i className="fa fa-chevron-down ml-2" aria-hidden="true"></i>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown alignRight>
                    <Dropdown.Toggle
                      variant=""
                      bsPrefix="d-block text-dark px-3 py-1 w-100"
                      id="dropdown-estado"
                    >
                      <div className="row">
                        <div className="col-8">
                          <span className="d-block text-left">Estados</span>
                        </div>
                        <div className="col-4">
                          <i
                            className="fa fa-chevron-down"
                            aria-hidden="true"
                          ></i>
                        </div>
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Header bsPrefix="dropdown-header py-0">
                        Seleccionar estado
                      </Dropdown.Header>
                      <Dropdown.Divider />
                      {this.state.estadosNegocios.length > 0
                        ? this.state.estadosNegocios.map((e, i) => (
                            <Dropdown.Item
                              key={i}
                              onClick={() =>
                                this.agregarFiltro(
                                  e.glosa,
                                  e.idEstadoNegocio,
                                  "estado"
                                )
                              }
                            >
                              <span style={{ fontSize: "0.875rem" }}>
                                {e.glosa}
                              </span>
                              {this.state.filtros.filter(
                                (f) => f.filtro === e.glosa
                              ).length > 0 ? (
                                <i
                                  className="fa fa-check text-success ml-2"
                                  aria-hidden="true"
                                ></i>
                              ) : null}
                            </Dropdown.Item>
                          ))
                        : null}
                    </Dropdown.Menu>
                  </Dropdown>

                  <Authorized roles={[4]}>
                    {this.state.ejecutivos && (
                      <Dropdown alignRight>
                        <Dropdown.Toggle
                          variant=""
                          bsPrefix="d-block text-dark px-3 py-1 w-100"
                          id="dropdown-ejecutivo"
                        >
                          <div className="row">
                            <div className="col-8">
                              <span className="d-block text-left">
                                Ejecutivos
                              </span>
                            </div>
                            <div className="col-4">
                              <i
                                className="fa fa-chevron-down"
                                aria-hidden="true"
                              ></i>
                            </div>
                          </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Header bsPrefix="dropdown-header py-0">
                            Buscar y seleccionar ejecutivo
                          </Dropdown.Header>
                          <FormControl
                            autoFocus
                            className="mx-3 my-2 w-auto search-input"
                            placeholder="Buscar ejecutivo..."
                            onChange={(e) =>
                              this.busquedaReactivaEjecutivos(e.target.value)
                            }
                          />
                          <Dropdown.Divider />
                          <div className="dropdown-menu-x">
                            {this.state.filtrosBusqueda.ejecutivos.length > 0
                              ? this.state.filtrosBusqueda.ejecutivos.map(
                                  (e, i) => (
                                    <Dropdown.Item
                                      key={i}
                                      onClick={() =>
                                        this.agregarFiltro(
                                          `${e.nombre} ${e.apellidoPaterno}`,
                                          e.idPersona,
                                          "ejecutivo"
                                        )
                                      }
                                    >
                                      <span style={{ fontSize: "0.875rem" }}>
                                        {e.nombre} {e.apellidoPaterno}{" "}
                                        {e.apellidoMaterno}
                                      </span>
                                      {this.state.filtros.filter(
                                        (f) =>
                                          f.filtro ===
                                          `${e.nombre} ${e.apellidoPaterno} ${e.apellidoMaterno}`
                                      ).length > 0 ? (
                                        <i
                                          className="fa fa-check text-success ml-2"
                                          aria-hidden="true"
                                        ></i>
                                      ) : null}
                                    </Dropdown.Item>
                                  )
                                )
                              : null}
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                  </Authorized>
                </Dropdown.Menu>
              </Dropdown>
            </div>

            {/* TIPO CANAL VENTA  */}
            <div className="col-md-4 col-sm-12 col-xl-3 align-self-end">
              <Dropdown>
                <Dropdown.Toggle
                  variant="dark"
                  bsPrefix="p-2 btn-dark btn-block rounded"
                  id="dropdownCanalVenta"
                >
                  Canal de venta
                  <i className="fa fa-chevron-down ml-2" aria-hidden="true"></i>
                  {!this.props.canalVenta && (
                    <span className="icon-canal-venta text-warning">
                      <i className="fa fa-exclamation-circle fa-stack-1x fadeOut animated slow infinite"></i>
                    </span>
                  )}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Header bsPrefix="dropdown-header py-0">
                    Seleccionar canal venta
                  </Dropdown.Header>
                  <Dropdown.Divider />
                  {this.state.canalesVenta.length > 0
                    ? this.state.canalesVenta.map((e, i) =>
                        e.idCanalVenta !== 3 ? (
                          <Dropdown.Item
                            key={i}
                            id={`canalventa${e.glosa}`}
                            onClick={() => this.filtrarCanalVenta(e)}
                          >
                            <span style={{ fontSize: "0.875rem" }}>
                              {e.glosa}
                            </span>
                            {this.state.canalVenta === e.idCanalVenta ? (
                              <i
                                className="fa fa-check text-success ml-2"
                                aria-hidden="true"
                              ></i>
                            ) : null}
                          </Dropdown.Item>
                        ) : null
                      )
                    : null}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state,
});

export default withRouter(
  connect(mapStateToProps, { guardar_negocios, periodo_negocio })(Filtros)
);
