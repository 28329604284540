import React from "react";
import { Row, Col } from "react-bootstrap";
import { EscogerPerfil } from "./EscogerPerfil";
import { FormularioInicio } from "./FormularioInicio";
import { version } from "../../../../package.json";
import Spinner from "../transversales/Spinner";
import { TAMANO_CINCO, TAMANO_SIETE } from "../../../utils/Constantes";
import { useSelector } from "react-redux";
import { useAutenticacion } from "../../../eventos/useAutenticacion";
import { useEffect } from "react";
import { Incorporacion } from "./Incorporacion";

const LogoVC = require("../../assets/images/logo_vida_camara.png");

export const IniciarSesion = () => {

  const { cargarSesionGuardada } = useAutenticacion();
  const blnEscogerPerfil         = useSelector(store => store.auth.escogerPerfil);
  const blnCargando              = useSelector(store => store.reducer.cargando);
  const blnUsuarioAutenticado    = useSelector(store => store.auth.usuarioAutenticado);

  useEffect(() => {
    cargarSesionGuardada();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return (
    <>
    {
      blnCargando ? (
        <Spinner claseColor="naranjo" idSpinner="spinnerNaranjo" posicionLogo="align-self-center w-100" />
      ) : (
        <>
          <div className="fondo-login animated fadeIn">
            <section className="card centrado-porcentual p-4 p-sm-5 fondo-login-form medium-width shadow">
              <Row>
                <Col xs="12" sm="12" md={{ span: TAMANO_CINCO, offset: TAMANO_SIETE }} className="text-center">
                  <img src={LogoVC} className="img-fluid" alt="logo-vida-camara"/>
                  { blnEscogerPerfil ? (
                    <EscogerPerfil />
                  ) : (
                    <FormularioInicio />
                  )}
                </Col>
              </Row>
            </section>
            <div className="versionWebSuscripcion">{version}</div>
          </div>
        </>
    )}
    { blnUsuarioAutenticado && (
      <Incorporacion />
    )}
    </>
  )
}