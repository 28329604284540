import React from "react";
import { withRouter } from "react-router-dom";
import Authorized from "../../../../../utils/Authorized";
import {
  SECCION_VER_ASEGURABLES,
  VALOR_TRES, VALOR_SIETE,
  COD_CORREDOR,
  PALABRA_TRABAJADORES
} from "../../../../../utils/Constantes";
import { roundToTwo } from "../../../../../utils/Functions";
import graficoBarrasImage from "../../../../assets/images/grafico.svg";
import imgLike from "../../../../assets/images/like-orange.svg";

const IndicadoresEnrolamiento = (props) => {
  return (
    <div className="row">
      {/* Total trabajadores  */}
      <div className="col-md-6 mb-3">
        <div className="card shadow" style={{ minHeight: 110 }}>
          <div className="card-body py-3">
            <div
              className="mb-3"
              style={{
                fontSize: "12px",
                paddingLeft: 0,
              }}
            >
              <span className=" text-muted font-weight-bold">
                Número de trabajadores
              </span>
            </div>
            <div className="mb-0 font-weight-bold h6">              
              {props.cotizacion.numeroDotacion} {PALABRA_TRABAJADORES}
            </div>
          </div>
        </div>
      </div>

      {/* Número de trabajadores enrolados /  Trabajadores faltantes por llenar formulario */}
      <div className="col-md-6 mb-3">
        <div className="card shadow" style={{ minHeight: 110 }}>
          <div className="card-body py-3">
            <div className="mb-3" style={{ fontSize: "12px" }}>
              <span className="text-muted font-weight-bold">
                Número de trabajadores enrolados
              </span>

              <Authorized roles={[2]}>
                <span
                  className="font-weight-bold mb-2 d-block h6 puntero-ver-venta-directa"
                  style={{ color: "#e89b03", float: "right" }}
                  onClick={() => {
                    props.history.push({
                      pathname: "/delegado/mis-procesos",
                    });
                  }}
                >
                  VER
                </span>
              </Authorized>
            </div>

            <div className="mb-0 font-weight-bold d-inline h6">
              {props.trabajadoresEnrolados} Titulares
            </div>
            <div className="mb-0 font-weight-bold d-inline h6 ml-5">
              {props.totalCargas} Cargas
            </div>
          </div>
        </div>
      </div>

      {/* Mínima asegurable / Productos y coberturas */}
      <div className="col-md-6 mb-3">
        <div className="card shadow">
          <div className="card-body py-3">
            <div className="d-flex mb-3" style={{ fontSize: "12px" }}>
              <span className="text-muted font-weight-bold">
                Mínima asegurable
              </span>
              <span className="text-danger ml-2">
                {roundToTwo(props.cotizacion.porcentajeMinimaAdherencia)}%
              </span>
            </div>
            <div className="row">
              <div className="col-8">
                <div className="mb-0 font-weight-bold">
                  {props.trabajadoresPorEnrolar} trabajadores por enrolar
                </div>
                <small style={{ color: "#3cc480" }}>
                  {props.porcentajeAsegurablesEnrolados}%
                </small>
              </div>
              <div className="col-4">
                <img
                  className="d-block ml-auto"
                  style={{ marginTop: "-17px" }}
                  width="60"
                  src={graficoBarrasImage}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Opciones de enrolamiento */}
      <div className="col-md-6 mb-3">
        <div className="card shadow">
          <div className="card-body py-3">
            <div className="row">
              <div className="col-12">
                <span
                  className="text-muted font-weight-bold d-block"
                  style={{ fontSize: "12px" }}
                >
                  Opciones de enrolamiento
                </span>
              </div>
              <Authorized roles={[VALOR_TRES, VALOR_SIETE, COD_CORREDOR]}>
                <span
                  className="font-weight-bold mt-3 ml-3 puntero-general"
                  style={{ color: "#e89b03" }}
                  onClick={() => { props.setSeccionEnrolamiento(SECCION_VER_ASEGURABLES) }}
                  
                >
                  <i
                    className="fa fa-users mr-1"
                    aria-hidden="true"
                  ></i>{" "}
                  Asegurables
                </span>
              </Authorized>
              <Authorized roles={[2]}>
                <div className="col-12 text-center mb-2">
                  <span
                    className="text-muted font-weight-bold d-block text-right"
                    style={{ fontSize: "12px", visibility: "hidden" }}
                  >
                    Cierre enrolamiento
                  </span>
                  <div className="mb-0 font-weight-bold text-right">
                    <div className="col-12">
                      {props.negocio.fechaFinEnrolamiento ? (
                        <div className="d-block text-center">
                          <img
                            src={imgLike}
                            alt="Enrolamiento terminado"
                            width="40"
                            height="40"
                            className="icon-like-orange mt-0"
                          />
                          <p className="font-weight-bold mb-0">
                            Enrolamiento terminado
                          </p>
                        </div>
                      ) : (
                        <button
                          disabled={!props.nominaCompleta}
                          id="cerrarEnrolamiento"
                          className="btn btn-principal btn-sm mx-auto"
                          title={
                            !props.nominaCompleta
                              ? "Disponible cuando la nómina de asegurables este completa"
                              : null
                          }
                          onClick={(objEvento) => {
                            objEvento.preventDefault();
                            
                            props.history.push({
                              pathname: "/delegado/nomina-asegurable",
                              mostrarNomina: true,
                              aceptarNomina: true,
                              asegurablesSinEnrolar:
                                props.trabajadoresPorEnrolar,
                            });
                          }}
                        >
                          <div className="my-auto">
                            <i
                              aria-hidden="true"
                              className="fa fa-times-circle-o mr-2"
                            ></i>
                            Cerrar Enrolamiento
                          </div>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </Authorized>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(IndicadoresEnrolamiento);