import uuid from "uuid";
import { CATALOGO_DECLARACION_OTRO, ID_CUATRO, ID_OCHO, NO_DECLARA, PRIMERA_POSICION_FECHA_DIVIDIDA, SECCION_DECLARACION_DEPORTE_ID, SECCION_DECLARACION_ESPECIAL_ID, SECCION_DECLARACION_SALUD_ID, SEGUNDA_POSICION_FECHA_DIVIDIDA, SIGNO_BARRA, SIGNO_GUION, TERCERA_POSICION_FECHA_DIVIDIDA, VALOR_ZERO } from "../utils/Constantes";
import { guardarDataWizard } from "./asegurable.services";
import { extraerDeclaracionesRepetidas, procesarDeclaDeportesAId, procesarDeclaEspecialAId, procesarDeclaSaludAId } from "../utils/Functions";

export const procesarDeclaracion = (objFormulario, objReducer, objEstado) => {

  const { agregar, informacion, declaracionComplementaria, checked, detalle } = objEstado;
  const { dataWizardAsegurado, configuracionDeclaraciones, declaraciones } = objReducer;
  const objCopiaFormulario = { ...objFormulario };
  let objDeclaracionNueva  = declaracionComplementaria;  
  let objAsegurable, blnCarga, intIndiceCarga, objDetalleEstado, objDetalle;
  let fechaDiagnostico, fechaDiagnosticoFormat;

  if (Number(objFormulario.rut) === Number(dataWizardAsegurado.rut)) {
    blnCarga = false;
    objAsegurable = dataWizardAsegurado;
  } 
  else {
    blnCarga       = true;
    objAsegurable  = dataWizardAsegurado.cargas.find((objElmento) => Number(objElmento.rut) === Number(objFormulario.rut));
    intIndiceCarga = dataWizardAsegurado.cargas.indexOf(objAsegurable);
  }
  let objTipoDeclaracion;

  switch(informacion.seccion.idSeccion){
    case SECCION_DECLARACION_SALUD_ID:
      if (informacion.catalogoPregunta.idPregunta === ID_CUATRO ||
          informacion.catalogoPregunta.idPregunta === ID_OCHO) {
        
        objTipoDeclaracion = informacion.catalogoDeclaraciones.find((objElemento) => objElemento.idCatalogoTipoDeclaracion === Number(objCopiaFormulario.idCatalogoTipoDeclaracion));
        objCopiaFormulario.actividadGlosa = objTipoDeclaracion.declaracion.replace("Enfermedades","E.");
        objTipoDeclaracion.declaracion = objCopiaFormulario.actividadGlosa;
      }
      else{
        objTipoDeclaracion = informacion.catalogoDeclaraciones[0];
        objTipoDeclaracion.idCatalogoDeclaracion = CATALOGO_DECLARACION_OTRO;
        objTipoDeclaracion.idCatalogoTipoDeclaracion = CATALOGO_DECLARACION_OTRO;
        objCopiaFormulario.actividadGlosa = objCopiaFormulario.causa;
      }
      break;
    case SECCION_DECLARACION_ESPECIAL_ID:
      objTipoDeclaracion = informacion.catalogoDeclaraciones[0];
      objTipoDeclaracion.idCatalogoDeclaracion = CATALOGO_DECLARACION_OTRO;
      objTipoDeclaracion.idCatalogoTipoDeclaracion = CATALOGO_DECLARACION_OTRO;

      if (objTipoDeclaracion.declaracion === NO_DECLARA) {
        objTipoDeclaracion.declaracion = "";
      }

      break;
    case SECCION_DECLARACION_DEPORTE_ID:
      objTipoDeclaracion = informacion.catalogoDeclaraciones.find((objElemento) => objElemento.idCatalogoTipoDeclaracion === Number(objCopiaFormulario.idCatalogoTipoDeclaracion));
      objCopiaFormulario.actividadGlosa = objTipoDeclaracion.declaracion;
      break;
    default: break;
  }

  objCopiaFormulario.nombre         = `${objAsegurable.nombre} ${objAsegurable.apellidoPaterno} ${objAsegurable.apellidoMaterno}`;
  objCopiaFormulario.idSeccion      = informacion.seccion.idSeccion;
  objCopiaFormulario.idPregunta     = informacion.catalogoPregunta.idPregunta;
  objDeclaracionNueva.catalogoDeclaracion = {...objTipoDeclaracion};

  const lstGlosasCoberturas = Object.assign({}, ...declaraciones.filter(objDeclaracion =>
    objDeclaracion.catalogoPregunta.idPregunta === informacion.catalogoPregunta.idPregunta
  )).glosasCoberturas;

  objDeclaracionNueva.glosasCoberturas = lstGlosasCoberturas.filter((v,i,a)=>a.findIndex(v2=>(v.glosa === v2.glosa))===i);

  if (objCopiaFormulario.fechaDiagnostico && objCopiaFormulario.fechaDiagnostico !== "") {
    let fechaDividida = objCopiaFormulario.fechaDiagnostico.split(SIGNO_BARRA);
    fechaDiagnostico = `${fechaDividida[TERCERA_POSICION_FECHA_DIVIDIDA]}${SIGNO_GUION}${fechaDividida[SEGUNDA_POSICION_FECHA_DIVIDIDA]}${SIGNO_GUION}${fechaDividida[PRIMERA_POSICION_FECHA_DIVIDIDA]}`;
    fechaDiagnosticoFormat = `${fechaDividida[PRIMERA_POSICION_FECHA_DIVIDIDA]}${SIGNO_GUION}${fechaDividida[SEGUNDA_POSICION_FECHA_DIVIDIDA]}${SIGNO_GUION}${fechaDividida[TERCERA_POSICION_FECHA_DIVIDIDA]}`;
  }
  else{
    fechaDiagnostico = "";
    fechaDiagnosticoFormat = "";
  }

  if (agregar) {
    objDeclaracionNueva.key = uuid();
    objDeclaracionNueva.catalogoAceptacion = informacion.catalogoAceptacion;
    objDeclaracionNueva.catalogoPregunta = informacion.catalogoPregunta;
    objDeclaracionNueva.idPreguntaSeccionCobertura = informacion.idPreguntaSeccionCobertura;
    objDeclaracionNueva.aceptacionPregunta = checked ? checked : VALOR_ZERO;

    objDeclaracionNueva = {
      ...objDeclaracionNueva,
      ...objCopiaFormulario,
      fechaDiagnostico: fechaDiagnostico,
      fechaDiagnosticoFormat: fechaDiagnosticoFormat,
    };

    objDetalleEstado = [...detalle, objDeclaracionNueva];
    let lstdeclaraciones = objAsegurable.declaraciones ? [...objAsegurable.declaraciones, objDeclaracionNueva] : [objDeclaracionNueva];
    objAsegurable.declaraciones = lstdeclaraciones;
  } 
  else {
    objDeclaracionNueva.catalogoPregunta   = informacion.catalogoPregunta;
    objDeclaracionNueva.aceptacionPregunta = checked ? checked : VALOR_ZERO;

    objDeclaracionNueva = {
      ...objDeclaracionNueva,
      ...objCopiaFormulario,
      fechaDiagnostico: fechaDiagnostico,
      fechaDiagnosticoFormat: fechaDiagnosticoFormat,
    };

    let objDeclaracion         = detalle.find((objElemento) => objElemento.key === declaracionComplementaria.key);
    let intIndiceDetalleEstado = detalle.indexOf(objDeclaracion);
    let intIndiceDetalle       = configuracionDeclaraciones.detalle.indexOf(objDeclaracion);

    objDetalleEstado = [...detalle];
    objDetalle       = [...configuracionDeclaraciones.detalle];

    objDetalleEstado[intIndiceDetalleEstado] = objDeclaracionNueva;
    objDetalle[intIndiceDetalle] = objDeclaracionNueva;

    const objDeclaracionEditar = objAsegurable.declaraciones.find((objElemento) => objElemento.key === objDeclaracionNueva.key);
    const intIndiceDeclaracion = objAsegurable.declaraciones.indexOf(objDeclaracionEditar);
    const lstdeclaraciones     = [...objAsegurable.declaraciones];
    lstdeclaraciones[intIndiceDeclaracion] = objDeclaracionNueva;
    objAsegurable.declaraciones = lstdeclaraciones;
  }

  return {
    objAsegurable,
    blnCarga, 
    intIndiceCarga,
    agregar,
    objDetalleEstado,
    objDetalle,
    objDeclaracionNueva
  }
}

const agregarDeclaracionNoDeclaro = (objAsegurable, objPregunta) => {

  let lstDeclaraciones         = objAsegurable.declaraciones;
  const blnExisteDeclaracion   = lstDeclaraciones
    ? lstDeclaraciones.filter(
      (objElemento) =>
        objElemento.idPreguntaSeccionCobertura === objPregunta.idPreguntaSeccionCobertura && 
        objElemento.catalogoDeclaracion.declaracion !== NO_DECLARA
    ).length > 0
    : false;

  const blnExisteDeclaracionNoDeclaro = lstDeclaraciones
    ? lstDeclaraciones.filter(
      (objElemento) =>
        objElemento.idPreguntaSeccionCobertura === objPregunta.idPreguntaSeccionCobertura &&
        objElemento.catalogoDeclaracion.declaracion === NO_DECLARA
    ).length > 0
    : false;
  
    //Si no se declara y no existe la declaracion de que no se declara, ingresarla
  if (!blnExisteDeclaracion) {
    if (!blnExisteDeclaracionNoDeclaro) {

      const objDeclaracionNoDeclara = {
        idPreguntaSeccionCobertura: objPregunta.idPreguntaSeccionCobertura,
        idSeccion: objPregunta.seccion.idSeccion,
        catalogoDeclaracion: objPregunta.catalogoDeclaraciones.find((objElemento) => objElemento.declaracion === NO_DECLARA),
        catalogoAceptacion: objPregunta.catalogoAceptacion,
        catalogoPregunta: objPregunta.catalogoPregunta
      };

      if (objDeclaracionNoDeclara.catalogoDeclaracion === undefined) {
        objDeclaracionNoDeclara.catalogoDeclaracion = {
          idCatalogoDeclaracion: 59, 
          idCatalogoTipoDeclaracion: 63, 
          declaracion: NO_DECLARA, 
          icono: "" 
        }
      } 
      
      objAsegurable.declaraciones = lstDeclaraciones ? [...lstDeclaraciones, objDeclaracionNoDeclara] : [objDeclaracionNoDeclara];
    }
    //Si se declara y existe la declaracion de que no se declara, eliminarla
  } 
  else {
    if (blnExisteDeclaracionNoDeclaro) {
      const objDeclaracionNoDeclaro = lstDeclaraciones.find(
        (objElemento) =>
          objElemento.idPreguntaSeccionCobertura === objPregunta.idPreguntaSeccionCobertura &&
          objElemento.catalogoDeclaracion.declaracion === NO_DECLARA
      );
      lstDeclaraciones = lstDeclaraciones.filter((objElemento) => objElemento !== objDeclaracionNoDeclaro);
      objAsegurable.declaraciones = [...lstDeclaraciones];
    }
  }

  return objAsegurable;
};

export const validarDeclaracionNoDeclaro = (objAsegurable, lstPreguntas) => {

  lstPreguntas.forEach(objPregunta => {
    //Evalúa cargas
    objAsegurable.cargas.forEach((objCarga) => {
      objCarga = agregarDeclaracionNoDeclaro(objCarga, objPregunta);
      objCarga.coberturas.forEach(objCobertura => {
        objCobertura.declaraciones = objCarga.declaraciones;
      });
    });

    //Evalua titular
    objAsegurable = agregarDeclaracionNoDeclaro(objAsegurable, objPregunta);
    objAsegurable.coberturas.forEach(objCobertura => {
      objCobertura.declaraciones = objAsegurable.declaraciones;
    });
  });

  return objAsegurable;
};


export const guardarDeclaraciones = async (objAsegurable, lstPreguntas) => {

  objAsegurable = validarDeclaracionNoDeclaro(objAsegurable, lstPreguntas);
  const objDatosEnvio = {...objAsegurable};
  await guardarDataWizard(objDatosEnvio);
  return objDatosEnvio;
};

export const eliminarDeclaracion = (objDeclaracion, objReducer, objEstado) => {

  const { dataWizardAsegurado } = objReducer;
  let objAsegurable, blnCarga, intIndiceCarga;

  if (Number(objDeclaracion.rut) === Number(dataWizardAsegurado.rut)) {
    blnCarga = false ;
    objAsegurable = dataWizardAsegurado;
  } 
  else {
    blnCarga       = true;
    objAsegurable  = dataWizardAsegurado.cargas.find((objElemento) => Number(objElemento.rut) === Number(objDeclaracion.rut));
    intIndiceCarga = dataWizardAsegurado.cargas.indexOf(objAsegurable);
  }

  objAsegurable.declaraciones = objAsegurable.declaraciones.filter((objElemento) => objElemento.key !== objDeclaracion.key);
  let objDetalle = objEstado.detalle.filter((objElemento) => objElemento.key !== objDeclaracion.key);

  return {
    objAsegurable,
    blnCarga, 
    intIndiceCarga,
    objDetalle
  }
};

export const separarCoberturas = async (dataAsegurable, preguntas) => {
  //TITULAR
  dataAsegurable.coberturas = dataAsegurable.coberturas.map((cobertura) =>
    ordernarPreguntasDeclaraciones(cobertura, preguntas)
  );

  // CARGAS
  dataAsegurable.cargas.forEach((objCarga) => {
    objCarga.coberturas = objCarga.coberturas.map((objCobertura) =>
      ordernarPreguntasDeclaraciones(objCobertura, preguntas)
    );
  });

  return dataAsegurable;
};

const ordernarPreguntasDeclaraciones = (cobertura, preguntas) => {

  let declaraciones = [];

  cobertura.declaraciones.forEach((declaracion) => {
    if (declaracion.catalogoPregunta) {
      preguntas.forEach((pregunta) => {
        if (
          pregunta.idSubProdCondicionadoCobertura === cobertura.idSubProdCondicionadoCobertura &&
          pregunta.catalogoPregunta.idPregunta === declaracion.catalogoPregunta.idPregunta
        )
          return declaraciones.push(declaracion);
      });
    }
  });

  //Eliminar repetidos
  let lstEspDeclaraciones = declaraciones.filter((objDeclaracion) => objDeclaracion.idSeccion === SECCION_DECLARACION_ESPECIAL_ID);
  let lstDepDeclaraciones = declaraciones.filter((objDeclaracion) => objDeclaracion.idSeccion === SECCION_DECLARACION_DEPORTE_ID);
  let lstSalDeclaraciones = declaraciones.filter((objDeclaracion) => objDeclaracion.idSeccion === SECCION_DECLARACION_SALUD_ID);

  lstSalDeclaraciones = extraerDeclaracionesRepetidas(lstSalDeclaraciones, procesarDeclaSaludAId);
  lstDepDeclaraciones = extraerDeclaracionesRepetidas(lstDepDeclaraciones, procesarDeclaDeportesAId);
  lstEspDeclaraciones = extraerDeclaracionesRepetidas(lstEspDeclaraciones, procesarDeclaEspecialAId);

  declaraciones = [];
  declaraciones = [...declaraciones,...lstEspDeclaraciones];
  declaraciones = [...declaraciones,...lstDepDeclaraciones];
  declaraciones = [...declaraciones,...lstSalDeclaraciones];

  cobertura.declaraciones = declaraciones;

  return cobertura;
};