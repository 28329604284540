import React, { useState, useEffect, useCallback, Fragment } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  VALIDACION_RUT,
  VALIDACION_NOMBRE,
  VALIDACION_APELLIDO_P,
  VALIDACION_APELLIDO_M,
  VALIDACION_MAIL,
  VALIDACION_TELEFONO,
  VALIDACION_SEXO,
  MASK_DATE,
  VALOR_UNO,
  MAYORIA_EDAD,
  MINIMA_EDAD,
  INVALID_DATE,
  CAMPO_REQUERIDO_FECHA,
  PERFIL_LDAP,
  VALOR_ZERO,
  CAMPO_REQUERIDO_NOMBRE,
  CAMPO_REQUERIDO_APELLIDO_PATERNO,
  CAMPO_REQUERIDO_EMAIL,
  CAMPO_REQUERIDO_GENERO,
  CAMPO_REQUERIDO_TELEFONO,
  VALIDACION_APELLIDO_M_MAXIMO,
  VALIDACION_APELLIDO_P_MAXIMO,
  VALIDACION_NOMBRE_MAXIMO,
} from "../../../../../../utils/Constantes";
import { formatTel, soloLetras } from "../../../../../../utils/Functions";
import {
  obtenerPersonaPorRut,
  obtenerTodasLasJefaturas,
  obtenerTodosLosEjecutivos,
} from "../../../../../../services/negocios.service";
import { useDispatch, useSelector } from "react-redux";
import { getGeneros } from "../../../../../../services/asegurable.services";
import moment from "moment";
import DatePicker from "react-datepicker";
import MaskedTextInput from "react-text-mask";
import "react-datepicker/dist/react-datepicker.css";
import * as actions from "../../../../../../actions/actions";
import { cambiar_wizard_actual } from "../../../../../../reducer/ldap";
import { MESSAGE_SEARCH_DELEGADO } from "../../../../../../utils/ConstantesVentaDirecta";

const { validate, format, clean } = require("rut.js");

const CDatosDelegado = ({
  loading,
  setLoading,
  setMessageLoading,
  enviarCrearNegocio,
}) => {
  const { generos } = useSelector((store) => store.reducer);
  const { sucursalConsulta, wizardActual } = useSelector((store) => store.ldap);

  const [delegadoEmpresa, setDelegadoEmpresa] = useState({
    rutDelegado: "",
    nombre: "",
    apellidoPaterno: "",
    apellidoMaterno: "",
    genero: "",
    fechaNacimiento: null,
    telefonoMovil: "",
    correoElectronico: "",
  });

  const [cargando, setCargando] = useState(false);
  const [ejecutivos, setEjecutivos] = useState([]);
  const [jefaturas, setJefaturas] = useState([]);
  const [delegadoValido, setDelegadoValido] = useState(true);

  const [disabledTextRut, setDisabledTextRut] = useState(true);
  const [disabledTextNombre, setDisabledTextNombre] = useState(true);
  const [disabledTextApePat, setDisabledTextApePat] = useState(true);
  const [disabledTextApeMat, setDisabledTextApeMat] = useState(true);
  const [disabledGenero, setDisabledGenero] = useState(true);
  const [disabledFecha, setDisabledFecha] = useState(true);
  const [disabledTextTel, setDisabledTextTel] = useState(true);
  const [disabledTextEmail, setDisabledtextEmail] = useState(true);

  const [validarDelegadoRegistrado, setValidarDelegadoRegistrado] =
    useState(null);

  const [generoId, setGeneroId] = useState(0);

  const dispatch = useDispatch();

  /**************************
   *  DATA DE DELEGADO      *
   **************************/
  useEffect(() => {
    const obtenerDelegadoEmpresa = async () => {
      if (sucursalConsulta.delegado.rutDelegado === undefined) {
        setDisabledTextRut(false);
      } else {
        setDelegadoEmpresa(sucursalConsulta.delegado);
        validarDisabledForm(sucursalConsulta.delegado);
      }
    };
    obtenerDelegadoEmpresa();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const obtenerJefaturasEjecutivos = async () => {
      const ejecutivos = await obtenerTodosLosEjecutivos();
      const jefaturas = await obtenerTodasLasJefaturas();

      setEjecutivos(ejecutivos);
      setJefaturas(jefaturas);
    };
    obtenerJefaturasEjecutivos();

    // eslint-disable-next-line
  }, []);

  const obtenerDatosDelegadoPorRut = async (rut, setFieldValue, errors) => {
    setLoading(true);
    setMessageLoading(MESSAGE_SEARCH_DELEGADO);
    if (errors.rut === undefined && rut !== "-" && rut.length > VALOR_ZERO) {
      let formatRut = format(rut);
      formatRut = formatRut.replace(/\./g, "");
      const responseDelegado = await obtenerPersonaPorRut(
        formatRut,
        PERFIL_LDAP
      );
      if (responseDelegado == null) {
        setValidarDelegadoRegistrado(false);

        setDisabledTextRut(false);
        clearValueForm(false);

        clearForm(setFieldValue);
      } else {
        setValidarDelegadoRegistrado(true);
        validarDisabledForm(responseDelegado);
        setFieldValue("nombre", responseDelegado.nombre);
        setFieldValue("apellidoPaterno", responseDelegado.apellidoPaterno);
        setFieldValue("apellidoMaterno", responseDelegado.apellidoMaterno);
        const generoMatch = generos.find(
          (item) => item.codigoDescripcion === responseDelegado.genero
        );
        setFieldValue("sexo", generoMatch.idGenero);
        const fechaNac = moment(responseDelegado.fechaNacimiento).format(
          "DD/MM/YYYY"
        );
        setFieldValue("fechaNacimiento", fechaNac);
        setFieldValue(
          "telefono",
          responseDelegado.telefonoMovil ? responseDelegado.telefonoMovil : ""
        );
        setFieldValue(
          "email",
          responseDelegado.correoElectronico
            ? responseDelegado.correoElectronico
            : ""
        );
      }
    } else {
      setValidarDelegadoRegistrado(false);
      clearForm(setFieldValue);
      clearValueForm(true);
    }

    validaDelegado(rut, setFieldValue);
    setLoading(false);
  };

  const clearForm = (setFieldValue) => {
    setFieldValue("nombre", "");
    setFieldValue("apellidoPaterno", "");
    setFieldValue("apellidoMaterno", "");
    setFieldValue("sexo", "");
    setFieldValue("fechaNacimiento", "");
    setFieldValue("telefono", "");
    setFieldValue("email", "");
  };

  const clearValueForm = (value) => {
    setDisabledTextNombre(value);
    setDisabledTextApePat(value);
    setDisabledTextApeMat(value);
    setDisabledGenero(value);
    setDisabledFecha(value);
    setDisabledTextTel(value);
    setDisabledtextEmail(value);
  };

  const asignarGeneroDelegado = useCallback(() => {
    if (
      sucursalConsulta.delegado.genero !== undefined &&
      generos.length > VALOR_ZERO
    ) {
      let idGeneroDelegado = generos.find(
        (item) => item.codigoDescripcion === sucursalConsulta.delegado.genero
      ).idGenero;
      setGeneroId(idGeneroDelegado);
    } else {
      setGeneroId(VALOR_ZERO);
    }
    // eslint-disable-next-line
  }, []);

  /**************************
   *  CATALOGO - GENERO     *
   **************************/
  const obtenerGeneros = useCallback(async () => {
    if (generos.length < VALOR_UNO) {
      const generosTemp = await getGeneros();
      dispatch(actions.get_generos(generosTemp));
      asignarGeneroDelegado(generosTemp);
    } else {
      asignarGeneroDelegado(generos);
    }
    setCargando(true);
  }, [dispatch, generos, asignarGeneroDelegado]);

  useEffect(() => {
    obtenerGeneros();
  }, [obtenerGeneros]);

  /**************************
   *  VALIDACION DATA     *
   **************************/
  const validaDelegado = (rut, setFieldValue, errors) => {
    rut = clean(rut);
    if (setFieldValue) setFieldValue("rut", rut);
    if (validate(rut)) {
      const delegadoValido = ejecutivos
        .concat(jefaturas)
        .find((e) => `${e.rut}${e.digitoVerificador}` === rut);

      setDelegadoValido(delegadoValido ? false : true);
    } else setDelegadoValido(true);
  };

  const fechaMayoriaEdad = () => {
    let fecha = new Date();
    return new Date(
      fecha.getFullYear() -
        MAYORIA_EDAD +
        "/" +
        (fecha.getMonth() + VALOR_UNO) +
        "/" +
        fecha.getDate()
    );
  };

  const fechaMinima = () => {
    let fecha = new Date();
    return new Date(
      fecha.getFullYear() -
        MINIMA_EDAD +
        "/" +
        (fecha.getMonth() + VALOR_UNO) +
        "/" +
        fecha.getDate()
    );
  };

  /**************************
   *  DATA DE ENVIO         *
   **************************/
  const enviarDatosDelegado = (data) => {
    if (!delegadoValido) return;

    let fechaFormat = moment(data.fechaNacimiento, "DD/MM/YYYY");
    data.fechaNacimiento = moment(fechaFormat).format("YYYY-MM-DD");
    enviarCrearNegocio(data);
  };

  /**************************
   *  FORMULARIO         *
   **************************/
  const validacionesFormulario = {
    rut: Yup.string().test("validarRut", VALIDACION_RUT, function (value) {
      return validate(format(value));
    }),
    nombre: !disabledTextNombre
      ? Yup.string().min(1, VALIDACION_NOMBRE)
        .required(CAMPO_REQUERIDO_NOMBRE)
        .max(25, VALIDACION_NOMBRE_MAXIMO)
      : null,
    apellidoMaterno: !disabledTextApeMat
      ? Yup.string().min(1, VALIDACION_APELLIDO_M)
        .max(25, VALIDACION_APELLIDO_M_MAXIMO)
      : null,
    apellidoPaterno: !disabledTextApePat
      ? Yup.string()
          .min(1, VALIDACION_APELLIDO_P)
          .required(CAMPO_REQUERIDO_APELLIDO_PATERNO)
          .max(25, VALIDACION_APELLIDO_P_MAXIMO)
      : null,
    email: !disabledTextEmail
      ? Yup.string().email(VALIDACION_MAIL).required(CAMPO_REQUERIDO_EMAIL)
      : null,
    sexo: !disabledGenero
      ? Yup.string()
          .required(CAMPO_REQUERIDO_GENERO)
          .test("validaSexo", VALIDACION_SEXO, (value) => {
            return Number(value) !== 0 ? true : false;
          })
      : null,
    fechaNacimiento: !disabledFecha
      ? Yup.string()
          .required(CAMPO_REQUERIDO_FECHA)
          .test("fecha", "validacion fecha", (value) => {
            return value !== INVALID_DATE ? true : false;
          })
      : null,
    telefono: Yup.string()
      .required(CAMPO_REQUERIDO_TELEFONO)
      .test("validaTelefono", VALIDACION_TELEFONO, (value) => {
        return formatTel(value).length === 13 || formatTel(value).length === 3
          ? true
          : false;
      }),
  };

  const validarDisabledForm = (responseDelegado) => {
    if (!responseDelegado.nombre) {
      setDisabledTextNombre(false);
    } else {
      setDisabledTextNombre(true);
    }
    if (!responseDelegado.apellidoPaterno) {
      setDisabledTextApePat(false);
    } else {
      setDisabledTextApePat(true);
    }
    if (!responseDelegado.apellidoMaterno) {
      setDisabledTextApeMat(false);
    } else {
      setDisabledTextApeMat(true);
    }
    if (!responseDelegado.genero) {
      setDisabledGenero(false);
    } else {
      setDisabledGenero(true);
    }
    if (!responseDelegado.fechaNacimiento) {
      setDisabledFecha(false);
    } else {
      setDisabledFecha(true);
    }
    if (!responseDelegado.telefonoMovil) {
      setDisabledTextTel(false);
    } else {
      setDisabledTextTel(true);
    }
    if (!responseDelegado.correoElectronico) {
      setDisabledtextEmail(false);
    } else {
      setDisabledtextEmail(true);
    }
  };

  return (
    <React.Fragment>
      {cargando ? (
        <Formik
          initialValues={{
            rut: delegadoEmpresa.rutDelegado || "",
            nombre: delegadoEmpresa.nombre ? delegadoEmpresa.nombre : "",
            apellidoPaterno: delegadoEmpresa.apellidoPaterno || "",
            apellidoMaterno: delegadoEmpresa.apellidoMaterno || "",
            sexo: generoId,
            fechaNacimiento: delegadoEmpresa.fechaNacimiento
              ? moment(delegadoEmpresa.fechaNacimiento).format("DD/MM/YYYY")
              : "",
            telefono: delegadoEmpresa.telefonoMovil || "",
            email: delegadoEmpresa.correoElectronico || "",
            delegado: false,
          }}
          validationSchema={Yup.object().shape(validacionesFormulario)}
          onSubmit={async (values, { setSubmitting }) => {
            enviarDatosDelegado(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
            setFieldTouched,
          }) => {
            return (
              <Form
                className="container animated fadeInRight"
                id="formDatosDelegado"
                onKeyDown={(e) => {
                  if ((e.charCode || e.keyCode) === 13) {
                    e.preventDefault();
                  }
                }}
              >
                <div className="row mx-3">
                  {/* rut */}
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>
                        RUT <span className="text-danger">*</span>
                      </label>
                      <Field
                        className="form-control"
                        id="rut"
                        name="rut"
                        type="text"
                        placeholder="ej. 12345678-9"
                        maxLength="12"
                        minLength="11"
                        disabled={disabledTextRut}
                        value={
                          values.rut.length > 1
                            ? format(values.rut)
                            : values.rut
                        }
                        onBlur={() => {
                          obtenerDatosDelegadoPorRut(
                            values.rut,
                            setFieldValue,
                            errors
                          );
                        }}
                      />
                      {values.rut && errors.rut ? (
                        <small className="animated fadeIn text-danger">
                          {errors.rut}
                        </small>
                      ) : (
                        <div>
                          {validarDelegadoRegistrado != null &&
                            !validarDelegadoRegistrado &&
                            values.rut && (
                              <label className="animated fadeIn text-danger">
                                Delegado no existe en Vida Cámara, favor llenar
                                información faltante.
                              </label>
                            )}

                          {validarDelegadoRegistrado != null &&
                            validarDelegadoRegistrado &&
                            values.rut && (
                              <label className="animated fadeIn text-primary font-weight-bold">
                                Delegado existe en Vida Cámara
                              </label>
                            )}
                        </div>
                      )}

                      {!delegadoValido && (
                        <small className="animated fadeIn text-danger font-weight-bold">
                          Ejecutivo o jefatura no puede ser configurado como
                          delegado
                        </small>
                      )}
                    </div>
                  </div>

                  {/* nombre */}
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>
                        Nombre <span className="text-danger">*</span>
                      </label>
                      <Field
                        className="form-control"
                        id="nombre"
                        name="nombre"
                        type="text"
                        placeholder="Ingrese su nombre completo"
                        value={soloLetras(values.nombre)}
                        disabled={disabledTextNombre}
                      />
                      {errors.nombre && touched.nombre && (
                        <small className="text-danger animated fadeIn animated fadeIn">
                          {errors.nombre}
                        </small>
                      )}
                    </div>
                  </div>

                  {/* apellidoPaterno */}
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>
                        Apellido Paterno <span className="text-danger">*</span>
                      </label>
                      <Field
                        className="form-control"
                        id="apellidoPaterno"
                        name="apellidoPaterno"
                        type="text"
                        placeholder="Ingrese su apellido paterno"
                        value={soloLetras(values.apellidoPaterno)}
                        disabled={disabledTextApePat}
                      />
                      {errors.apellidoPaterno && touched.apellidoPaterno && (
                        <small className="text-danger animated fadeIn animated fadeIn">
                          {errors.apellidoPaterno}
                        </small>
                      )}
                    </div>
                  </div>

                  {/* apellido materno */}
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Apellido Materno</label>
                      <Field
                        className="form-control"
                        id="apellidoMaterno"
                        name="apellidoMaterno"
                        type="text"
                        placeholder="Ingrese su apelldio materno"
                        value={soloLetras(values.apellidoMaterno)}
                        disabled={disabledTextApeMat}
                      />
                      {errors.apellidoMaterno && touched.apellidoMaterno && (
                        <small className="text-danger animated fadeIn animated fadeIn">
                          {errors.apellidoMaterno}
                        </small>
                      )}
                    </div>
                  </div>

                  {/* sexo */}
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>
                        Género <span className="text-danger">*</span>
                      </label>
                      <select
                        id="sexo"
                        name="sexo"
                        className="form-control"
                        style={{ display: "block" }}
                        value={values.sexo}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={disabledGenero}
                      >
                        <option value="0"> Seleccione género</option>
                        {generos &&
                          generos.map((item, i) => (
                            <option
                              key={`opcion_parentesco_${i}`}
                              value={item.idGenero}
                            >
                              {item.descripcion}
                            </option>
                          ))}
                      </select>
                      {errors.sexo && touched.sexo && (
                        <small className="text-danger animated fadeIn animated fadeIn">
                          {errors.sexo}
                        </small>
                      )}
                    </div>
                  </div>

                  {/* fecha nacimiento */}
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>
                        Fecha de Nacimiento{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <DatePicker
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        id="fechaNacimiento"
                        autoComplete="off"
                        name="fechaNacimiento"
                        value={values.fechaNacimiento}
                        selected={null}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="DD/MM/YYYY"
                        locale="es"
                        disabled={disabledFecha}
                        onChange={(value) => {
                          value = moment(value).format("DD/MM/YYYY");
                          setFieldTouched("fechaNacimiento", true);
                          setFieldValue("fechaNacimiento", value);
                        }}
                        minDate={fechaMinima()}
                        maxDate={fechaMayoriaEdad()}
                        customInput={
                          <MaskedTextInput
                            className="form-control"
                            placeholder="DD/MM/YYYY"
                            mask={MASK_DATE}
                          />
                        }
                      />
                      {errors.fechaNacimiento && touched.fechaNacimiento && (
                        <small className="text-danger animated fadeIn">
                          {errors.fechaNacimiento}
                        </small>
                      )}
                    </div>
                  </div>

                  {/* teléfono */}
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>
                        Teléfono <span className="text-danger">*</span>
                      </label>
                      <Field
                        id="telefono"
                        name="telefono"
                        className="form-control"
                        maxLength="13"
                        value={formatTel(values.telefono)}
                        disabled={disabledTextTel}
                        placeholder="Ingrese teléfono"
                      />
                      {errors.telefono && touched.telefono && (
                        <small className="text-danger animated fadeIn">
                          {errors.telefono}
                        </small>
                      )}
                    </div>
                  </div>

                  {/* email */}
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>
                        Email <span className="text-danger">*</span>
                      </label>
                      <Field
                        className="form-control"
                        id="email"
                        name="email"
                        type="text"
                        placeholder="Ingrese email ej: test@gmail.com"
                        maxLength="50"
                        value={values.email}
                        disabled={disabledTextEmail}
                      />
                      {errors.email && touched.email && (
                        <small className="text-danger animated fadeIn">
                          {errors.email}
                        </small>
                      )}
                    </div>
                  </div>

                  {/* tipo delegado */}
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Tipo Delegado</label>
                      <div className="d-block">
                        <label className="switch mt-1">
                          <input
                            disabled
                            defaultChecked={true}
                            type="checkbox"
                          />
                          <span className="slider round" />
                        </label>
                        <label className="ml-2">Delegado Base</label>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Botonera */}
                <div className="container button-zone text-right pt-3 pb-3 animated fadeIn">
                  <button
                    className="btn btn-principal color-secundario"
                    type="button"
                    onClick={() => {
                      dispatch(cambiar_wizard_actual(wizardActual - 1));
                      sessionStorage.setItem("_cnWzAcVC", wizardActual - 1);
                    }}
                  >
                    Atrás
                  </button>

                  <button
                    className="btn btn-principal"
                    type="submit"
                    id="botonFinalizar"
                    disabled={loading}
                  >
                    {loading ? (
                      <Fragment>
                        <i className="fa fa-spinner fa-pulse fa-fw"></i>
                        <span className="sr-only">Loading...</span>
                      </Fragment>
                    ) : (
                      <span>Finalizar</span>
                    )}
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      ) : (
        <div className="d-flex justify-content-center animated fadeIn my-5">
          <div className="spinner-border text-primary mb-5" role="status">
            <span className="sr-only">Cargando...</span>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default CDatosDelegado;
