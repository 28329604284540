import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  actualizar_beneficiarios,
  guardar_data_wizard_asegurado,
  update_beneficiario,
} from "../../../../../actions/actions";
import {
  VALIDACION_RUT,
  CAMPO_REQUERIDO_NOMBRE,
  CAMPO_REQUERIDO_APELLIDO_PATERNO,
  CAMPO_REQUERIDO_PARENTESCO,
  CAMPO_REQUERIDO_RUT,
  VALIDACION_PARENTESCO,
  EXITO_BENEFICIARIO,
  ERROR_SOLICITUD,
  VALIDACION_PORCENTAJE_BENEFICIO,
  VALIDACION_MAIL,
  VALIDACION_TELEFONO,
  VALIDACION_SUMA_BENEFICIO,
  CAMPO_REQUERIDO,
  VALIDACION_CONTACTO,
  VALIDACION_NUMERO_CONDICIONAL,
  RUT_TITULAR,
  RUT_DUPLICADO_BENEFICIARIOS,
  CAMPO_REQUERIDO_GENERO,
  VALOR_ZERO,
  CONYUGUE_UNICO,
  PADRE_UNICO,
  MADRE_UNICA,
  VALOR_UNO,
  STRING_RUT,
  DIGITO_VERIFICADOR,
  NOTIFICACION_SIN_OPCIONES,
  SIN_PARENTESCO_SELECCIONADO,
  POSICION_INICIAL,
  GA_CATEGORIA_FORMULARIO_ENROLAMIENTO,
  GA_LABEL_BENEFICIARIOS,
  VALIDACION_APELLIDO_M_MAXIMO,
  VALIDACION_APELLIDO_M,
  VALIDACION_APELLIDO_P_MAXIMO,
  VALIDACION_APELLIDO_P,
  VALIDACION_NOMBRE,
  VALIDACION_NOMBRE_MAXIMO,
  PARENTESCO_BENEFICIARIO_CONVIVIENTE_CIVIL,
  PARENTESCO_BENEFICIARIO_MADRE,
  PARENTESCO_BENEFICIARIO_PADRE,
  PARENTESCO_BENEFICIARIO_CONYUGUE,
} from "../../../../../utils/Constantes";
import TablaBeneficiarios from "./TablaBeneficiarios";
import { confirmAlert } from "react-confirm-alert";
import { guardarDataWizard } from "../../../../../services/asegurable.services";
import { notificacion } from "../../../transversales/Notificaciones";
import {
  formatTel,
  formatNumber,
  verificarSiExisteObjetoEnArreglo,
  soloLetras,
  validacionDireccion,
  soloLetrasYNumeros,
  soloLetrasNumerosYAmpersand,
} from "../../../../../utils/Functions";
import uuid from "uuid";
import { crearEventoTraza } from "../../../../../utils/analitica";

const { format, clean, validate } = require("rut.js");

class FormularioBeneficiarios extends Component {
  state = {
    parentescos_beneficiarios:
      this.props.store.reducer.parentescos_beneficiarios.length > 0
        ? this.props.store.reducer.parentescos_beneficiarios.filter((p) => {
          return p.vigente === true;
        })
        : null,
    generos: this.props.store.reducer.generos,
    rutBeneficiarioActual: "",
    glosaParentescoInconsistente: "",
    agregar: true,
    beneficiarios: [],
    sumaPorcentaje: 0,
    direccion: "",
    telefono: "+56",
    correoElectronico: "",
    esCarga: false,
    checked:
      this.props.store.reducer.dataWizardAsegurado.solicitud
        .declaroBeneficiario === null ||
        this.props.store.reducer.dataWizardAsegurado.solicitud
          .declaroBeneficiario === undefined
        ? false
        : this.props.store.reducer.dataWizardAsegurado.solicitud
          .declaroBeneficiario,
    data: {
      key: null,
      rutAsegurable: "",
      digitoVerificador: "",
      rutBeneficiario: "",
      nombre: "",
      apellidoPaterno: "",
      apellidoMaterno: "",
      idParentesco: SIN_PARENTESCO_SELECCIONADO,
      idGenero: 0,
      porcentajeBeneficio: "",
      correoElectronico: "",
      telefono: "+56",
      complementoDireccion: "",
      numero: "",
      departamento: "",
      direccion: "",
    },
  };

  componentDidMount() {

    crearEventoTraza(GA_CATEGORIA_FORMULARIO_ENROLAMIENTO, GA_LABEL_BENEFICIARIOS);

    const { dataWizardAsegurado } = this.props.store.reducer;

    if (
      dataWizardAsegurado !== null &&
      dataWizardAsegurado.beneficiarios !== undefined
    ) {
      let { beneficiarios } = dataWizardAsegurado;

      if (beneficiarios !== null && beneficiarios.length > VALOR_ZERO) {
        let sumaPorcentaje = VALOR_ZERO;

        beneficiarios.forEach((beneficiario) => {
          sumaPorcentaje =
            sumaPorcentaje + Number(beneficiario.porcentajeBeneficio);

          beneficiario.rutBeneficiario = format(
            beneficiario.rut + "-" + beneficiario.digitoVerificador
          );
          beneficiario.nombreCompleto = `${beneficiario.nombre} ${beneficiario.apellidoPaterno} ${beneficiario.apellidoMaterno}`;
          beneficiario.parentescoGlosa = beneficiario.parentescoGlosa
            ? beneficiario.parentescoGlosa
            : beneficiario.parentesco.glosa;
          beneficiario.idParentesco = beneficiario.idParentesco
            ? beneficiario.idParentesco
            : beneficiario.parentesco.idParentesco;

          beneficiario.idGenero = beneficiario.idGenero
            ? beneficiario.idGenero
            : beneficiario.genero.idGenero;
          beneficiario.key = uuid();
        });

        this.props.guardarSumaPorcentaje(sumaPorcentaje);
        this.props.actualizar_beneficiarios(beneficiarios);
        this.setState({ beneficiarios, sumaPorcentaje });
      }
    }
  }

  handleSubmit = async (values) => {
    let lstBeneficiarios  = this.state.beneficiarios;
    let intSumaPorcentaje = this.state.sumaPorcentaje;

    //Formatear Campos
    values.nombreCompleto = `${values.nombre} ${values.apellidoPaterno} ${values.apellidoMaterno}`;
    values.parentescoGlosa = this.props.store.reducer.parentescos_beneficiarios
      .length
      ? this.props.store.reducer.parentescos_beneficiarios.find(
        (item) => item.idParentescoBeneficiario === Number(values.idParentesco)
      ).glosa
      : this.state.data.parentesco && this.state.data.parentesco.glosa;
    values.parentesco = {
      idParentesco: Number(values.idParentesco),
      glosa: values.parentescoGlosa,
    };

    values.generoDescripcion = this.props.store.reducer.generos.length
      ? this.props.store.reducer.generos.find(
        (item) => item.idGenero === Number(values.idGenero)
      ).descripcion
      : this.state.data.genero && this.state.data.genero.descripcion;

    values.genero = {
      idGenero: Number(values.idGenero),
      descripcion: values.generoDescripcion,
    };

    values.rut = Number(
      clean(format(clean(values.rutBeneficiario)).split("-")[0])
    );
    values.digitoVerificador = format(clean(values.rutBeneficiario)).split(
      "-"
    )[1];
    values.rutBeneficiario = format(
      `${values.rut}-${values.digitoVerificador}`
    );

    let beneficiarios;
    let sumaPorcentaje;

    //Agregar Beneficiario
    if (this.state.agregar) {
      const key = uuid();
      beneficiarios = [...this.state.beneficiarios, { ...values, key }];
      sumaPorcentaje = this.state.sumaPorcentaje + Number(values.porcentajeBeneficio);
    } 
    else {
      const dataCopy = { ...this.state.data, ...values };

      let beneficiario = this.state.beneficiarios.find(
        (item) => item.key === dataCopy.key
      );

      let index = this.state.beneficiarios.indexOf(beneficiario);

      beneficiarios = this.state.beneficiarios;

      beneficiarios[index] = dataCopy;

      sumaPorcentaje = beneficiarios.reduce(function (total, item) {
        return Number(total) + Number(item.porcentajeBeneficio);
      }, 0);
    }
    let respuesta = await this.actualizarDatosBeneficiarios(beneficiarios);

    if (respuesta) {
      this.setState({ beneficiarios, sumaPorcentaje: Number(sumaPorcentaje) });
      this.props.guardarSumaPorcentaje(sumaPorcentaje);
    }
    else{
      this.setState({ beneficiarios : lstBeneficiarios, sumaPorcentaje: intSumaPorcentaje});
      this.props.guardarSumaPorcentaje(intSumaPorcentaje);
    }
    this.limpiarFormulario();
  };

  limpiarFormulario = (rutBeneficiario, esCarga) => {
    this.setState({
      data: {
        rutBeneficiario: rutBeneficiario || "",
        nombre: "",
        apellidoPaterno: "",
        apellidoMaterno: "",
        idParentesco: SIN_PARENTESCO_SELECCIONADO,
        idGenero: 0,
        porcentajeBeneficio: 0,
        correoElectronico: "",
        telefono: "",
        complementoDireccion: "",
        numero: "",
        departamento: "",
        direccion: "",
      },
      direccion: "",
      telefono: "+56",
      correoElectronico: "",
      agregar: true,
      esCarga: esCarga || false,
    });
  };

  notificarSinOpciones = (catalogo) => {
    if (!catalogo.length) {
      notificacion("info", NOTIFICACION_SIN_OPCIONES);
    }
  };

  actualizarCargasSiSonBeneficiarios = (beneficiarios) => {
    const { cargas }           = this.props.store.reducer.dataWizardAsegurado;
    const lstParentescosCargas = this.props.store.reducer.parentescos;
    const lstBeneParentescos   = this.props.store.reducer.parentescos_beneficiarios;
    let blnErrorParentesco     = false;

    if (cargas && cargas.length > VALOR_ZERO) {
      for (const beneficiario of beneficiarios) {
        const cargaYBeneficiario = verificarSiExisteObjetoEnArreglo(cargas, [
          {
            nombrePropiedad: STRING_RUT,
            valorPropiedad: beneficiario.rut,
          },
          {
            nombrePropiedad: DIGITO_VERIFICADOR,
            valorPropiedad: beneficiario.digitoVerificador,
          },
        ]);
        
        if (cargaYBeneficiario) {
          
          const parentescoBene = Object.assign({}, ...lstBeneParentescos.filter((parentesco) => {
            return parentesco.idParentescoBeneficiario === beneficiario.parentesco.idParentesco && parentesco
          }));

          if (parentescoBene && Object.keys(parentescoBene).length > VALOR_ZERO) {
            const parentesco = Object.assign({}, ...lstParentescosCargas.filter((parentesco) => {
              return parentesco.idParentesco === parentescoBene.parentesco.idParentesco && parentesco
            }));
            
            if (parentesco && Object.keys(parentesco).length > VALOR_ZERO) {
              const indiceCargaModificada = cargas.findIndex(
                ({ rut, digitoVerificador }) =>
                  rut === cargaYBeneficiario.rut &&
                  digitoVerificador === cargaYBeneficiario.digitoVerificador
              );
    
              cargas[indiceCargaModificada].nombre          = beneficiario.nombre;
              cargas[indiceCargaModificada].apellidoPaterno = beneficiario.apellidoPaterno;
              cargas[indiceCargaModificada].apellidoMaterno = beneficiario.apellidoMaterno;
              cargas[indiceCargaModificada].idGenero        = beneficiario.idGenero;
              cargas[indiceCargaModificada].idParentesco    = parentesco.idParentesco;
              cargas[indiceCargaModificada].parentesco      = parentesco;
              cargas[indiceCargaModificada].parentescoGlosa = beneficiario.parentesco.descripcion;
              cargas[indiceCargaModificada].genero          = beneficiario.genero;
            }
            else{
              blnErrorParentesco = true;
            }
          }
          else{
            blnErrorParentesco = true;
          }
        }
      }
    }
    return blnErrorParentesco ? null : cargas;
  };

  actualizarDatosBeneficiarios = async (beneficiarios) => {
    const { dataWizardAsegurado } = this.props.store.reducer;

    const cargas = this.actualizarCargasSiSonBeneficiarios(beneficiarios);
    
    if (!cargas) {
      notificacion("danger", `El parentesco asociado al RUT del beneficiario no coincide con el indicado en el apartado de asegurable adicional`);
      return null;
    }
    else{
      dataWizardAsegurado.cargas = cargas || [];
      this.props.actualizar_beneficiarios(beneficiarios);
      this.props.guardar_data_wizard_asegurado(dataWizardAsegurado);
      let enviarBeneficiarios = await guardarDataWizard(dataWizardAsegurado);
  
      if (enviarBeneficiarios !== null) {
        notificacion("success", EXITO_BENEFICIARIO);
      } else notificacion("danger", ERROR_SOLICITUD);

      return 'OK'
    }
  };

  actualizarPorcentaje = (porcentaje) => {
    this.setState({ sumaPorcentaje: porcentaje });
  };

  actualizarListaBeneficiarios = (beneficiarios) => {
    this.setState({ beneficiarios });
    this.limpiarFormulario();
  };

  llenarFormulario = (data) => {
    this.setState({ agregar: false, data, ...data });
  };

  rutUnico = (value) => {
    const { beneficiarios } = this.props.store.reducer.dataWizardAsegurado;

    if (beneficiarios && beneficiarios.length > 0) {
      const rutsBeneficiarios = beneficiarios
        .map(({ rutBeneficiario }) => rutBeneficiario)
        .filter((item) => item !== format(this.state.data.rutBeneficiario));

      return !rutsBeneficiarios.includes(format(value));
    }
    return true;
  };

  rutTitular = (value) => {
    const rutTitular = this.props.store.auth.usuarioSesion.rut;
    return format(value) !== format(rutTitular);
  };

  conyugueUnico = (value) => {
    const { beneficiarios } = this.props.store.reducer.dataWizardAsegurado;

    if (
      (Number(value) === PARENTESCO_BENEFICIARIO_CONYUGUE ||
        Number(value) === PARENTESCO_BENEFICIARIO_CONVIVIENTE_CIVIL) &&
      beneficiarios !== undefined &&
      beneficiarios !== null &&
      beneficiarios.length > VALOR_ZERO
    ) {
      const parentescos = beneficiarios
        .map(({ idParentesco }) => Number(idParentesco))
        .filter((item) => item !== Number(this.state.data.idParentesco));

      return parentescos.includes(PARENTESCO_BENEFICIARIO_CONYUGUE) ||
        parentescos.includes(PARENTESCO_BENEFICIARIO_CONVIVIENTE_CIVIL)
        ? false
        : true;
    } else {
      return true;
    }
  };

  padreUnico = (value, id) => {
    const { beneficiarios } = this.props.store.reducer.dataWizardAsegurado;

    if (
      Number(value) === id &&
      beneficiarios !== undefined &&
      beneficiarios !== null &&
      beneficiarios.length > VALOR_ZERO
    ) {
      const parentescos = beneficiarios
        .map(({ idParentesco }) => Number(idParentesco))
        .filter((item) => item !== Number(this.state.data.idParentesco));

      return parentescos.includes(id) ? false : true;
    } else {
      return true;
    }
  };

  consistenciaParentesco = (value) => {
    const { reducer } = this.props.store;
    const { parentescos } = reducer;
    const parentescoBeneficiario = reducer.parentescos_beneficiarios;
    const { cargas } = reducer.dataWizardAsegurado;

    const rutSinDv = Number(
      clean(format(this.state.rutBeneficiarioActual).split("-")[0])
    );

    const idsParentescosUnicos = [
      PARENTESCO_BENEFICIARIO_CONYUGUE,
      PARENTESCO_BENEFICIARIO_PADRE,
      PARENTESCO_BENEFICIARIO_MADRE,
      PARENTESCO_BENEFICIARIO_CONVIVIENTE_CIVIL,
    ];

    if (
      idsParentescosUnicos.includes(Number(value)) &&
      cargas !== undefined &&
      cargas !== null &&
      cargas.length > VALOR_ZERO
    ) {

      const parentesco = Object.assign({}, ...parentescoBeneficiario.filter((parentesco) => {
        return parentesco.idParentescoBeneficiario === Number(value) && parentesco.parentesco.idParentesco
      }));
      const idParentesco = parentesco.parentesco.idParentesco;

      const parentescoInconsistente = cargas.find(
        (carga) =>
          Number(carga.parentesco.idParentesco) === idParentesco &&
          rutSinDv !== carga.rut
      );

      if (parentescoInconsistente) {
        const glosaParentescoInconsistente = parentescos.find(
          (parentesco) => parentesco.idParentesco === idParentesco
        ).glosa;

        this.setState({ glosaParentescoInconsistente });
      }
      return !parentescoInconsistente;
    } else {
      return true;
    }
  };

  consistenciaPareja = (value) => {
    const { cargas } = this.props.store.reducer.dataWizardAsegurado;
    const parentescoBeneficiario = this.props.store.reducer.parentescos_beneficiarios;
    const parentesco = parentescoBeneficiario.filter((parentesco) => {
      return parentesco.idParentescoBeneficiario === Number(value) && parentesco.parentesco.idParentesco
    })
    const idParentesco = parentesco[POSICION_INICIAL] && parentesco[POSICION_INICIAL].parentesco.idParentesco;

    const yaExistePareja =
      cargas &&
      cargas.find(
        (carga) =>
          Number(carga.parentesco.idParentesco) === PARENTESCO_BENEFICIARIO_CONYUGUE ||
          Number(carga.parentesco.idParentesco) === PARENTESCO_BENEFICIARIO_CONVIVIENTE_CIVIL
      );

    const valueEsPareja =
      idParentesco === PARENTESCO_BENEFICIARIO_CONYUGUE ||
      idParentesco === PARENTESCO_BENEFICIARIO_CONVIVIENTE_CIVIL;
    if (yaExistePareja && valueEsPareja) {
      const parejaConsistente = cargas.find(
        (carga) => Number(carga.parentesco.idParentesco) === idParentesco
      );

      return parejaConsistente;
    } else {
      return true;
    }
  };

  obtenerCargas() {
    const { dataWizardAsegurado } = this.props.store.reducer;
    const { cargas } = dataWizardAsegurado;

    return cargas;
  }

  existeCarga = (value) => {

    this.setState({ rutBeneficiarioActual: value });

    const { beneficiarios }  = this.props.store.reducer.dataWizardAsegurado;
    const rutsBeneficiarios  = beneficiarios && beneficiarios.length ? beneficiarios.map(({ rutBeneficiario }) => format(rutBeneficiario)) : [];
    const beneficiarioExiste = rutsBeneficiarios.includes(format(value));
    const cargas             = this.obtenerCargas();
    
    if (cargas) cargas.forEach((carga) => (carga.rut = parseInt(carga.rut)));
    const rutDv = format(value);
    const [rutBeneficiario, dvBeneficiario] = rutDv.split("-");

    if (cargas && cargas.length) {
      const cargaYBeneficiario = verificarSiExisteObjetoEnArreglo(cargas, [
        {
          nombrePropiedad: "rut",
          valorPropiedad: parseInt(rutBeneficiario.replaceAll(".", "")),
        },
        {
          nombrePropiedad: "digitoVerificador",
          valorPropiedad: dvBeneficiario,
        },
      ]);
      if (cargaYBeneficiario && !beneficiarioExiste) {
        const { nombre, apellidoPaterno, apellidoMaterno, parentesco, genero } = cargaYBeneficiario;

        let parentescoBeneficiario = Object.assign({}, ...this.state.parentescos_beneficiarios.filter((beneficiario) => {
          return beneficiario.parentesco.idParentesco === parentesco.idParentesco && beneficiario;
        }));

        this.setState({
          esCarga: true,
          data: {
            ...this.state.data,
            ...{
              rutBeneficiario: rutDv,
              idParentesco: parentescoBeneficiario && parentescoBeneficiario.idParentescoBeneficiario,
              idGenero: genero.idGenero,
              nombre,
              apellidoPaterno,
              apellidoMaterno,
            },
          },
        });
      } else if (this.state.esCarga && this.state.agregar) {
        this.limpiarFormulario(value, false);
      }
    }
    return null;
  };

  confirmarNo = (e) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="modal-confirm-container">
            <div className="d-flex">
              <p className="h4">
                Ha declarado {this.state.beneficiarios.length}{" "}
                {this.state.beneficiarios.length === VALOR_UNO
                  ? "beneficiario"
                  : "beneficiarios"}
                . ¿Confirma que desea eliminar beneficiarios?
              </p>
            </div>
            <div className="row mt-4">
              <button
                id="botonCerrar"
                className="btn btn-principal color-secundario btn-sm d-block ml-auto mr-2"
                onClick={() => {
                  this.setState({ checked: true });
                  onClose();
                }}
              >
                No
              </button>
              <button
                id="borrarBeneficiarios"
                className="btn btn-principal btn-sm"
                onClick={(e) => {
                  this.setState({
                    checked: false,
                  });
                  this.borrarBeneficiarios();
                  onClose();
                }}
              >
                Sí
              </button>
            </div>
          </div>
        );
      },
    });
  };

  borrarBeneficiarios = () => {
    this.setState({
      beneficiarios: [],
      sumaPorcentaje: VALOR_ZERO,
    });
    this.props.update_beneficiario([]);
  };

  render() {
    return (
      <div className="card wizard o-white">
        <div id="cargas" className="cuerpo-wizard o-white container">
          <div className="row mx-5 animated fadeInRight">
            <div className="col-md-12">
              <h5 className="font-weight-bold text-primary mb-0">
                ¿Desea Declarar beneficiario?
              </h5>
              <hr className="mt-1" />
            </div>

            <div className="col-md-12">
              <div className="check-group">
                <label className="custom-checkbox p-1">
                  <input
                    id="siDeclaroBeneficiarrio"
                    type="radio"
                    name="radio"
                    checked={this.state.checked}
                    onChange={async (e) => {
                      this.setState({ checked: true });
                      this.props.declaraBeneficiario(true);
                    }}
                  />
                  <div className="check"></div>
                  <div className="option">Si</div>
                </label>
                <label className="custom-checkbox p-1">
                  <input
                    id="noDeclaroBeneficiarrio"
                    type="radio"
                    name="radio"
                    checked={!this.state.checked}
                    onChange={async (e) => {
                      if (
                        this.state.beneficiarios !== null &&
                        this.state.beneficiarios.length > 0
                      ) {
                        this.confirmarNo(e);
                      } else {
                        this.setState({ checked: false });
                        this.props.declaraBeneficiario(false);
                        
                      }
                    }}
                  />
                  <div className="check"></div>
                  <div className="option">No</div>
                </label>
              </div>
            </div>
            {this.state.checked && (
              <div className="col-md-12">
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    rutBeneficiario: this.state.data.rutBeneficiario,
                    nombre: this.state.data.nombre,
                    apellidoPaterno: this.state.data.apellidoPaterno,
                    apellidoMaterno: this.state.data.apellidoMaterno,
                    idParentesco: this.state.data.idParentesco,
                    idGenero: this.state.data.idGenero,
                    porcentajeBeneficio: this.state.data.porcentajeBeneficio,
                    correoElectronico: this.state.data.correoElectronico,
                    telefono: this.state.data.telefono,
                    complementoDireccion: this.state.data.complementoDireccion,
                    numero: this.state.data.numero,
                    departamento: this.state.data.departamento,
                    direccion: this.state.data.direccion,
                  }}
                  validationSchema={Yup.object().shape({
                    rutBeneficiario: Yup.string()
                      .required(CAMPO_REQUERIDO_RUT)
                      .test("validarRut", VALIDACION_RUT, (value) => {
                        return validate(clean(value));
                      })
                      .test("rutTitular", RUT_TITULAR, this.rutTitular)
                      .test(
                        "rutDuplicado",
                        RUT_DUPLICADO_BENEFICIARIOS,
                        this.rutUnico
                      ),
                    nombre: Yup.string()
                      .required(CAMPO_REQUERIDO_NOMBRE)
                      .test("validarVacios", CAMPO_REQUERIDO_NOMBRE, (value) =>
                        value ? value.trim() !== "" : false)
                      .min(1, VALIDACION_NOMBRE)
                      .max(25, VALIDACION_NOMBRE_MAXIMO),
                    apellidoPaterno: Yup.string()
                      .required(CAMPO_REQUERIDO_APELLIDO_PATERNO)
                      .test(
                        "validarVacios",
                        CAMPO_REQUERIDO_APELLIDO_PATERNO,
                        (value) => (value ? value.trim() !== "" : false))
                      .min(1, VALIDACION_APELLIDO_P)
                      .max(25, VALIDACION_APELLIDO_P_MAXIMO),
                    apellidoMaterno: Yup.string()
                      .min(1, VALIDACION_APELLIDO_M)
                      .max(25, VALIDACION_APELLIDO_M_MAXIMO),
                    idParentesco: Yup.string()
                      .required(CAMPO_REQUERIDO_PARENTESCO)
                      .test(
                        "validaParentesco",
                        VALIDACION_PARENTESCO,
                        (value) => {
                          return Number(value) !== SIN_PARENTESCO_SELECCIONADO ? true : false;
                        }
                      )
                      .test("conyugueUnico", CONYUGUE_UNICO, this.conyugueUnico)
                      .test("padreUnico", PADRE_UNICO, (value) =>
                        this.padreUnico(value, PARENTESCO_BENEFICIARIO_PADRE)
                      )
                      .test(
                        "consistenciaParentesco",
                        `El Rut ingresado como ${this.state.glosaParentescoInconsistente} debe ser el mismo para Asegurable Adicional y Beneficiario`,
                        (value) => this.consistenciaParentesco(value)
                      )
                      .test(
                        "consistenciaPareja",
                        `No puede ingresar un asegurable adicional como CONYUGUE y un beneficiario como CONVIVIENTE CIVIL y viceversa`,
                        (value) => this.consistenciaPareja(value)
                      )
                      .test("madreUnica", MADRE_UNICA, (value) =>
                        this.padreUnico(value, PARENTESCO_BENEFICIARIO_MADRE)
                      ),
                    idGenero: Yup.string()
                      .required(CAMPO_REQUERIDO_GENERO)
                      .test(
                        "validarSelect",
                        CAMPO_REQUERIDO_GENERO,
                        (value) => {
                          return Number(value) !== 0 ? true : false;
                        }
                      ),
                    porcentajeBeneficio: Yup.string()
                      .required(CAMPO_REQUERIDO)
                      .test(
                        "validaPorcentaje",
                        VALIDACION_PORCENTAJE_BENEFICIO,
                        (value) => {
                          return Number(value) !== 0 && Number(value) <= 100
                            ? true
                            : false;
                        }
                      )
                      .test(
                        "sumaPorcentaje",
                        VALIDACION_SUMA_BENEFICIO,
                        (value) => {
                          if (this.state.agregar) {
                            return (
                              this.state.sumaPorcentaje + Number(value) <= 100
                            );
                          } else {
                            return (
                              this.state.sumaPorcentaje -
                              Number(this.state.data.porcentajeBeneficio) +
                              Number(value) <=
                              100
                            );
                          }
                        }
                      ),
                    correoElectronico: Yup.string().email(VALIDACION_MAIL),
                    telefono: Yup.string().test(
                      "validaTelefono",
                      VALIDACION_TELEFONO,
                      (value) => {
                        return value === undefined ||
                          formatTel(value).length === 13 ||
                          formatTel(value).length === 3 ||
                          formatTel(value).trim().length === 0
                          ? true
                          : false;
                      }
                    ),
                    numero: Yup.string().test(
                      "validarVacios",
                      VALIDACION_NUMERO_CONDICIONAL,
                      (value) => {
                        value
                          ? (value = value.replace(/[^0-9]/g, ""))
                          : (value = "");
                        if (this.state.direccion !== "") {
                          return value ? value.trim() !== "" : false;
                        } else {
                          return true;
                        }
                      }
                    ),
                    complementoDireccion: Yup.string(),
                    departamento: Yup.string(),
                    direccion: Yup.string().test(
                      "validarContacto",
                      VALIDACION_CONTACTO,
                      (value) => {
                        return (
                          this.state.direccion !== "" ||
                          (value !== "" && value !== undefined) ||
                          (this.state.telefono !== "+56" &&
                            this.state.telefono !== "") ||
                          this.state.correoElectronico !== ""
                        );
                      }
                    ),
                  })}
                  onSubmit={async (
                    values,
                    { setSubmitting, resetForm, initialValues }
                  ) => {
                    this.handleSubmit(values);
                    resetForm(initialValues);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    setFieldValue,
                    setFieldTouched,
                    handleChange,
                    handleSubmit,
                    handleBlur,
                  }) => {
                    return (
                      <Form
                        id="formularioCargas"
                        name="cargas-form"
                        onSubmit={handleSubmit}
                        onKeyDown={(e) => {
                          if ((e.charCode || e.keyCode) === 13) {
                            e.preventDefault();
                          }
                        }}
                      >
                        <div className="row">
                          {/* Rut */}
                          <div className="col-md-12">
                            <div className="form-group">
                              <label>
                                RUT <span className="text-danger">*</span>
                              </label>
                              <Field
                                id="rutBeneficiario"
                                name="rutBeneficiario"
                                placeholder="ej. 12345678-9"
                                maxLength="12"
                                minLength="11"
                                validate={(value) => this.existeCarga(value)}
                                value={format(values.rutBeneficiario)}
                                type="text"
                                className="form-control form-control-sm"
                              />
                              {errors.rutBeneficiario &&
                                touched.rutBeneficiario && (
                                  <small className="text-danger animated fadeIn">
                                    {errors.rutBeneficiario}
                                  </small>
                                )}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          {/* Nombres */}
                          <div className="col-md-12">
                            <div className="form-group">
                              <label>
                                Nombres <span className="text-danger">*</span>
                              </label>
                              <Field
                                innerRef={this.textInput}
                                type="text"
                                className="form-control form-control-sm"
                                id="nombre"
                                name="nombre"
                                maxLength="50"
                                placeholder="Ingrese nombres"
                                value={soloLetras(values.nombre)}
                              />
                              {errors.nombre && touched.nombre && (
                                <small className="text-danger animated fadeIn">
                                  {errors.nombre}
                                </small>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          {/* Apellido paterno */}
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>
                                Apellido Paterno{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <Field
                                type="text"
                                className="form-control form-control-sm"
                                id="apellidoPaterno"
                                name="apellidoPaterno"
                                maxLength="50"
                                placeholder="Ingrese apellido paterno"
                                value={soloLetras(values.apellidoPaterno)}
                              />
                              {errors.apellidoPaterno &&
                                touched.apellidoPaterno && (
                                  <small className="text-danger animated fadeIn">
                                    {errors.apellidoPaterno}
                                  </small>
                                )}
                            </div>
                          </div>

                          {/* Apellido materno */}
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Apellido Materno</label>
                              <Field
                                type="text"
                                className="form-control form-control-sm"
                                id="apellidoMaterno"
                                name="apellidoMaterno"
                                maxLength="50"
                                placeholder="Ingrese apellido materno"
                                value={soloLetras(values.apellidoMaterno)}
                              />
                              {errors.apellidoMaterno &&
                                touched.apellidoMaterno && (
                                  <small className="text-danger animated fadeIn">
                                    {errors.apellidoMaterno}
                                  </small>
                                )}
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          {/* Parentesco */}
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>
                                Parentesco{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <select
                                id="idParentesco"
                                name="idParentesco"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.idParentesco}
                                className="form-control form-control-sm"
                                onClick={() =>
                                  this.notificarSinOpciones(
                                    this.state.parentescos_beneficiarios
                                  )
                                }
                              >
                                {this.state.parentescos_beneficiarios &&
                                  this.state.parentescos_beneficiarios.length ? (
                                  <Fragment>
                                    <option value={SIN_PARENTESCO_SELECCIONADO}>
                                      Selecciona una opción
                                    </option>
                                    {this.state.parentescos_beneficiarios.map(
                                      (item, i) => (
                                        <option
                                          key={`parentesco_${i}`}
                                          value={item.idParentescoBeneficiario}
                                        >
                                          {item.glosa.toUpperCase()}
                                        </option>
                                      )
                                    )}
                                  </Fragment>
                                ) : this.state.data.parentesco &&
                                  !this.state.agregar ? (
                                  <option
                                    value={
                                      this.state.data.parentesco.idParentesco
                                    }
                                  >
                                    {this.state.data.parentesco.glosa.toUpperCase()}
                                  </option>
                                ) : (
                                  <option value="0">
                                    Selecciona una opción
                                  </option>
                                )}
                              </select>
                              {errors.idParentesco && touched.idParentesco && (
                                <small className="text-danger animated fadeIn animated fadeIn">
                                  {errors.idParentesco}
                                </small>
                              )}
                            </div>
                          </div>

                          {/* genero */}
                          <div className="col-md-3">
                            <div className="form-group">
                              <label>
                                Género <span className="text-danger">*</span>
                              </label>
                              <select
                                id="idGenero"
                                name="idGenero"
                                className="form-control form-control-sm"
                                style={{ display: "block" }}
                                value={values.idGenero}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                onClick={() =>
                                  this.notificarSinOpciones(
                                    this.props.store.reducer.generos
                                  )
                                }
                              >
                                {this.props.store.reducer.generos &&
                                  this.props.store.reducer.generos.length ? (
                                  <Fragment>
                                    <option value="0">
                                      {" "}
                                      Seleccione género
                                    </option>
                                    {this.props.store.reducer.generos.map(
                                      (item, i) => (
                                        <option
                                          key={`opcion_parentesco_${i}`}
                                          value={item.idGenero}
                                        >
                                          {item.descripcion}
                                        </option>
                                      )
                                    )}
                                  </Fragment>
                                ) : this.state.data.genero &&
                                  !this.state.agregar ? (
                                  <option
                                    value={this.state.data.genero.idGenero}
                                  >
                                    {this.state.data.genero.descripcion.toUpperCase()}
                                  </option>
                                ) : (
                                  <option value="0"> Seleccione género</option>
                                )}
                              </select>
                              {errors.idGenero && touched.idGenero && (
                                <small className="text-danger animated fadeIn animated fadeIn">
                                  {errors.idGenero}
                                </small>
                              )}
                            </div>
                          </div>

                          {/* % Beneficio */}
                          <div className="col-md-3">
                            <div className="form-group">
                              <label>
                                % Beneficio{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <Field
                                type="text"
                                className="form-control form-control-sm"
                                id="porcentajeBeneficio"
                                name="porcentajeBeneficio"
                                placeholder="Ingrese % Beneficio"
                                maxLength="5"
                                value={formatNumber(values.porcentajeBeneficio)}
                              />
                              {errors.porcentajeBeneficio &&
                                touched.porcentajeBeneficio && (
                                  <small className="text-danger animated fadeIn">
                                    {errors.porcentajeBeneficio}
                                  </small>
                                )}
                            </div>
                          </div>
                        </div>
                        
                        <div className="row">
                          
                          {/* Dirección */}
                          <div className="col-md-12">
                            <div className="form-group">
                              <label>Dirección</label>
                              <span className="text-danger">*</span>
                              <Field
                                innerRef={this.textInput}
                                type="text"
                                className="form-control form-control-sm"
                                id="direccion"
                                name="direccion"
                                maxLength="100"
                                placeholder="Ingrese direccion"
                                value={validacionDireccion(values.direccion)}
                                onChange={(event) => {
                                  this.setState({
                                    direccion: event.target.value,
                                  });
                                  setFieldValue(
                                    "direccion",
                                    event.target.value
                                  );
                                }}
                              />
                              {errors.direccion && touched.direccion && (
                                <small className="text-danger animated fadeIn">
                                  {errors.direccion}
                                </small>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          {/* Sector */}
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Sector/Villa/Población</label>
                              <Field
                                innerRef={this.textInput}
                                type="text"
                                className="form-control form-control-sm"
                                id="complementoDireccion"
                                name="complementoDireccion"
                                maxLength="100"
                                value={soloLetrasNumerosYAmpersand(values.complementoDireccion)}
                              />
                              {errors.complementoDireccion &&
                                touched.complementoDireccion && (
                                  <small className="text-danger animated fadeIn">
                                    {errors.complementoDireccion}
                                  </small>
                                )}
                            </div>
                          </div>
                          {/* Número */}
                          <div className="col-md-3">
                            <div className="form-group">
                              <label>Número</label>
                              <span className="text-danger">*</span>
                              <Field
                                innerRef={this.textInput}
                                type="text"
                                className="form-control form-control-sm"
                                id="numero"
                                name="numero"
                                maxLength="10"
                                value={formatNumber(values.numero)}
                              />
                              {errors.numero && touched.numero && (
                                <small className="text-danger animated fadeIn">
                                  {errors.numero}
                                </small>
                              )}
                            </div>
                          </div>
                          {/* Departamento */}
                          <div className="col-md-3">
                            <div className="form-group">
                              <label>Departamento</label>
                              <Field
                                innerRef={this.textInput}
                                type="text"
                                className="form-control form-control-sm"
                                id="departamento"
                                name="departamento"
                                maxLength="20"
                                value={soloLetrasYNumeros(values.departamento)}
                              />
                              {errors.departamento && touched.departamento && (
                                <small className="text-danger animated fadeIn">
                                  {errors.departamento}
                                </small>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          {/* Correo Electrónico*/}
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Correo Electrónico</label>
                              <Field
                                innerRef={this.textInput}
                                type="text"
                                className="form-control form-control-sm"
                                id="correoElectronico"
                                name="correoElectronico"
                                maxLength="80"
                                value={values.correoElectronico}
                                onChange={(event) => {
                                  this.setState({
                                    correoElectronico:
                                      event.target.value.trim(),
                                  });
                                  setFieldValue(
                                    "correoElectronico",
                                    event.target.value.trim()
                                  );
                                }}
                              />
                              {errors.correoElectronico &&
                                touched.correoElectronico && (
                                  <small className="text-danger animated fadeIn">
                                    {errors.correoElectronico}
                                  </small>
                                )}
                            </div>
                          </div>
                          {/* Teléfono */}
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Teléfono móvil</label>
                              <Field
                                innerRef={this.textInput}
                                type="text"
                                className="form-control form-control-sm"
                                id="telefono"
                                name="telefono"
                                maxLength="13"
                                value={formatTel(values.telefono)}
                                onChange={(event) => {
                                  this.setState({
                                    telefono: event.target.value.trim(),
                                  });
                                  setFieldValue(
                                    "telefono",
                                    event.target.value.trim()
                                  );
                                }}
                              />
                              {errors.telefono && touched.telefono && (
                                <small className="text-danger animated fadeIn">
                                  {errors.telefono}
                                </small>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-12">
                            <div className="button-zone text-right pt-3 pb-3">
                              {!this.state.agregar && (
                                <button
                                  id="cancelar"
                                  onClick={this.limpiarFormulario}
                                  className="btn btn-principal color-cancel btn-sm"
                                  type="button"
                                >
                                  Cancelar
                                </button>
                              )}
                              <button
                                id="agregar"
                                className="btn btn-principal color-secundario btn-sm"
                                type="submit"
                              >
                                {this.state.agregar ? "Agregar" : "Actualizar"}
                              </button>
                            </div>
                          </div>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            )}
            <TablaBeneficiarios
              beneficiarios={this.state.beneficiarios}
              sumaPorcentaje={this.state.sumaPorcentaje}
              actualizarPorcentaje={this.actualizarPorcentaje}
              actualizarListaBeneficiarios={this.actualizarListaBeneficiarios}
              actualizarDatosBeneficiarios={this.actualizarDatosBeneficiarios}
              llenarFormulario={this.llenarFormulario}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state,
});

export default withRouter(
  connect(mapStateToProps, {
    actualizar_beneficiarios,
    guardar_data_wizard_asegurado,
    update_beneficiario,
  })(FormularioBeneficiarios)
);
