import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Contenido from "../../../transversales/Contenido";
import ModalArchivosCotizacion from "../ModalArchivosCotizacion";
import { mostrar_modal_archivos, mostrar_modal_fecha_enrolamiento } from "../../../../../actions/actions";
import ModalFechaEnrolamiento from "../ModalFechaEnrolamiento";
import { TIPO_INCORPORACION_MANTENCION } from "../../../../../utils/Constantes";
import { guardarTipoIncorporacion } from "../../../../../actions/actionsAuth";

class EjecutivoCompleto extends Component {
  constructor(props) {    
    super(props);
    this.state = {
      menu: this.props.store.auth.usuarioSesion.menu,
    };    

    let ruta = sessionStorage.getItem('_nRuVC');
    if(ruta) this.props.history.push(`/${ruta}`);
    else this.props.history.push(`/negocios`);
  }

  componentDidMount() {
    this.props.guardarTipoIncorporacion(TIPO_INCORPORACION_MANTENCION)
  }
  
  cerrarModal(tipo) {
    switch (tipo) {
      case "archivos":
        this.props.mostrar_modal_archivos(false);
        break;

      case "editar":
        this.props.mostrar_modal_fecha_enrolamiento(false);
        break;

      default:
        break;
    }
  }

  render() {
    return (
      <React.Fragment>
        {/* MODALES */}      
        {this.props.store.reducer.modalCotizacionesNegocio.cargarArchivos && (
          <ModalArchivosCotizacion
            type="medium"
            show={
              this.props.store.reducer.modalCotizacionesNegocio.cargarArchivos
            }
            cotizacion={this.props.store.reducer.cotizacion}
            title="Adjuntar archivos cotización "
            loadingData={this.state.loadingData}
            onSubmit={() => {
              alert("submit");
            }}
            onCancel={() => {
              this.cerrarModal("archivos");
            }}
            onClose={() => {
              this.cerrarModal("archivos");
            }}
            showCancelButton={true}
            showCloseButton={true}
          ></ModalArchivosCotizacion>
        )}
        {this.props.store.reducer.modalCotizacionesNegocio.fechaCotizacion && (
        <ModalFechaEnrolamiento
          type="medium"
          show={
            this.props.store.reducer.modalCotizacionesNegocio.fechaCotizacion
          }
          cotizacion={this.props.store.reducer.cotizacion}
          title="Definir plazo fin de enrolamiento"
          onSubmit={() => {
            alert("submit");
          }}
          onCancel={() => {
            this.cerrarModal("editar");
          }}
          onClose={() => {
            this.cerrarModal("editar");
          }}
          showCancelButton={true}
          showCloseButton={true}
        ></ModalFechaEnrolamiento>
        )}
         
          <Contenido menu={this.state.menu} routes={this.props.routes} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  store: state
});

export default withRouter(connect(mapStateToProps, {mostrar_modal_archivos, mostrar_modal_fecha_enrolamiento, guardarTipoIncorporacion})(EjecutivoCompleto));
