import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  guardar_cotizacion,
  guardar_negocio,
} from "../../../../../actions/actions";
import Table from "../../../transversales/DataTable";
import ModalEditarAsegurable from "../../jefatura-ejecutivo/ModalEditarAsegurable";
import { actualizarCotizacion } from "../../../../../services/cotizacion.services";
import {
  actualizarNegocio,
  enviarEmailNotificacionEjecutivo,
} from "../../../../../services/negocios.service";
import { notificacion } from "../../../transversales/Notificaciones";
import {
  TYPEOF_STRING,
  ID_ZERO,
  ID_UNO,
  ESTADO_COTIZACION_FINALIZADA,
  ID_NEGOCIO_CERRADO,
  PERFIL_DELEGADO,
  EXITO_ACEPTAR_NOMINA_ASEGURABLES,
  ERROR_ACEPTAR_NOMINA_ASEGURABLES,
  ESTADO_ENROLADO,
  TIPO_CANAL_VENTA_DIRECTA,
  RUTA_DEFAULT,
  ROL_DELEGADO,
  COD_MENU_ENROLAMIENTO_DIGITAL,
  ID_SUBPRODUCTO_SALUD_DIGITAL,
  VALOR_VACIO_GUION,
  ID_SUBPRODUCTO_VIDA,
  GA_CATEGORIA_ENROLAMIENTO,
  GA_LABEL_NOMINA_ENROLAMIENTO
} from "../../../../../utils/Constantes";
import { crearPerfilDelegadoMantencion } from "../../../../../services/auth.services";
import { EjecutarSeleccionIncorporacion } from "../../../autenticacion/Incorporacion";
import { crearEventoTraza } from "../../../../../utils/analitica";

const NominaAsegurable = (props) => {

  const [loadingData, setLoadingData] = useState(false);
  const [asegurables, stateAsegurables] = useState([]);
  const [showModal, stateShowModal] = useState(false);
  const [totalAprobados, stateTotalAprobados] = useState(ID_ZERO);
  const [totalNoAprobados, stateTotalNoAprobados] = useState(ID_ZERO);
  const [totalSinEnrolar] = useState(props.location.asegurablesSinEnrolar);
  const [totalBeneficiarios, setTotalBeneficiarios] = useState(0);

  const aceptarNomina = props.location.aceptarNomina;
  const fechaFinEnrolamiento = props.store.reducer.negocio.fechaFinEnrolamiento;

  const temaTabla = {
    title: {
      backgroundColor: "rgb(255, 255, 255)",
      height: "0px",
    },
    header: {
      fontSize: "14px",
      backgroundColor: "rgb(255, 255, 255)",
      fontColor: "black",
      fontWeight: "600",
    },
    rows: {
      backgroundColor: "white",
      borderWidth: "0px",
    },
  };

  const columnDefs = [
    {
      name: "Nombre",
      selector: "nombre",
    },
    {
      name: "Apellidos",
      selector: "apellidos",
    },
    {
      name: "RUT",
      selector: "rut",
    },
    {
      name: "Correo",
      selector: "correoElectronico",
    },
    {
      name: "Estado de Enrolamiento",
      selector: "status",
    },
    {
      name: "Requisitos Asegurabilidad",
      selector: "cunpleRequisitos",
      cell: (row) =>
      (!row.requisitos && !row.solicitud.fechaRecepcion) ? (
        <span
          style={{
            textAlign: "center",
            width: "100%",
          }}
        >
          {VALOR_VACIO_GUION}
        </span>
      ) : <div class="row" style={{ width: '100%', textAlign: 'center' }}>
        <div class="col-12">
          {row.requisitos && row.requisitos.map(objRequisito => {
            return objRequisito.cumpleRequisito ? <div className="requisitos">
              <i className="fa fa-check text-success" aria-hidden="true"></i>
              {objRequisito.glosaSubProducto}
            </div> :
            <div className="requisitos">
              <i className="fa fa-times text-danger" aria-hidden="true"></i>
              {objRequisito.glosaSubProducto} 
            </div>
          })}
        </div>
      </div>,
    },
  ];

  const obtenerNominaFinal = () => {
    let nominaFinal;
    if (
      props.store.reducer.negocio.canalVenta.idCanalVenta ===
      TIPO_CANAL_VENTA_DIRECTA 
    ) {
      nominaFinal = props.store.reducer.grupoAsegurables.filter(
        (objAsegurable) => objAsegurable.solicitud.fechaRecepcion !== null
      );
    } else {
      nominaFinal = props.store.reducer.delegado.aseguradosDelegado.filter(
        (asegurable) => {   
          if (asegurable.requisitos) {
            return asegurable.requisitos.find(objRequisito => {
              return objRequisito.cumpleRequisito !== null
            })
          }
          return null;
        }
      );
    }

    nominaFinal.forEach((asegurable) => {
      asegurable.rut =
        typeof asegurable.rut === TYPEOF_STRING && asegurable.rut.includes("-")
          ? asegurable.rut
          : asegurable.rut + "-" + asegurable.digitoVerificador;
      asegurable.apellidos = `${asegurable.apellidoPaterno} ${asegurable.apellidoMaterno}`;
      asegurable.status = ESTADO_ENROLADO;
      if (
        asegurable.declaraBeneficiario &&
        asegurable.requisitos === null
      ) {
        setTotalBeneficiarios((totalBeneficiarios) => totalBeneficiarios + 1);
      }
    });

    stateAsegurables(nominaFinal);

    const noAprobados = props.store.reducer.delegado.aseguradosDelegado.filter(
      (asegurable) =>
        asegurable.requisitos && asegurable.requisitos.find(
          (objRequisito) => ( (objRequisito.idSubProducto === ID_SUBPRODUCTO_SALUD_DIGITAL && !objRequisito.cumpleRequisito) ||
                              (objRequisito.idSubProducto === ID_SUBPRODUCTO_VIDA && !objRequisito.cumpleRequisito) )

        )
    );

    stateTotalNoAprobados(noAprobados.length);
    nominaFinal.length - noAprobados.length < 0
      ? stateTotalAprobados(0)
      : stateTotalAprobados(nominaFinal.length - noAprobados.length);
  };

  useEffect(() => {
    crearEventoTraza(GA_CATEGORIA_ENROLAMIENTO, GA_LABEL_NOMINA_ENROLAMIENTO);
    obtenerNominaFinal();
    // eslint-disable-next-line
  }, []);

  const handleSubmit = async () => {
    stateShowModal(false);
    setLoadingData(true);

    if (props.store.auth.usuarioSesion.nombrePerfil === ROL_DELEGADO) {
      //adiciona perfil Delegado Mantencion a Delegado
      const data = {
        rut: props.store.auth.usuarioSesion.rut,
        sesion: {
          descripcion: "",
          estiloCod: 2,
          ipHabilitada: true,
          perfilCod: process.env.REACT_APP_COD_DELEGADO_MANTENCION,
          sesionPerfilCod: 0,
          sesionTipoCod: 3,
          sitioCod: COD_MENU_ENROLAMIENTO_DIGITAL,
          subPerfilCod: 0,
          usuCodigo: 0,
          usuCreador: "APIPERFIL",
        },
      };
      await crearPerfilDelegadoMantencion(data);
    }

    const { cotizaciones } = props.store.reducer.negocio;
    let objResponseCotizacion;
    cotizaciones.forEach(async (objCotizacion) => {
      const objDataCotizacion = {
        idCotizacion: objCotizacion.idCotizacion,
        perfil: PERFIL_DELEGADO,
        estadoCotizacion: {
          idEstadoCotizacion: ESTADO_COTIZACION_FINALIZADA,
        },
      };
      objResponseCotizacion = await actualizarCotizacion(objDataCotizacion);
    })

    const dataNegocio = {
      fechaFinEnrolamiento: new Date(),
      idNegocio: props.store.reducer.negocio.idNegocio,
      estadoNegocio: { idEstadoNegocio: ID_NEGOCIO_CERRADO },
      perfil: PERFIL_DELEGADO,
    };

    const responseNegocio = await actualizarNegocio(dataNegocio);

    if (objResponseCotizacion !== null && responseNegocio === ID_UNO) {
      const negocioE = props.store.reducer.negocio;

      const dataEnvioEmail = {
        idNegocio: negocioE.idNegocio,
      };

      const responseEmail = await enviarEmailNotificacionEjecutivo(
        dataEnvioEmail
      );

      if (responseEmail)
        notificacion("success", EXITO_ACEPTAR_NOMINA_ASEGURABLES);

      let negocio = props.store.reducer.negocio;
      negocio.fechaFinEnrolamiento = new Date();
      negocio.estadoNegocio.idEstadoNegocio = ID_NEGOCIO_CERRADO;
      props.guardar_negocio(negocio);

      props.history.push(RUTA_DEFAULT);
    } else {
      notificacion("danger", ERROR_ACEPTAR_NOMINA_ASEGURABLES);
    }
  };

  const { escogerIncorporacion } = props.store.auth;

  return (    
    <Fragment>
      <div className="container">
        <div className="row">
          <div className="col-md-7 mb-3 text-muted">
            <h4 className="font-weight-bold">Visualizador de Asegurables</h4>
            <p>Asegurables en proceso de enrolamiento</p>
          </div>
          <div className="col-md-5 mb-3 align-self-center">
            {escogerIncorporacion && <EjecutarSeleccionIncorporacion />}
          </div>
        </div>
        <div className="row animated fadeIn">
          <div className="col-12 px-5 pb-5">
            {aceptarNomina && (
              <div className="row">
                <div className="col-sm-12 mb-3">
                  <button
                    id="aceptarNomina"
                    disabled={fechaFinEnrolamiento}
                    className="btn btn-principal btn-sm d-block float-right "
                    onClick={() => stateShowModal(true)}
                  >
                    Aceptar Nómina
                  </button>
                </div>
              </div>
            )}
            <div className="shadow ">
              <Table
                datos={asegurables}
                columnas={columnDefs}
                tema={temaTabla}
                campoOrden={"apellidos"}
                paginacion={true}
                paginacionPorPagina={10}
                paginacionOpciones={{
                  rowsPerPageText: "Asegurables por página:",
                  rangeSeparatorText: "de",
                  noRowsPerPage: true,
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <ModalEditarAsegurable
        loadingData={loadingData}
        idSubmit="submitNominaAsegurables"
        type="medium"
        show={showModal}
        title="¿Está seguro que desea aprobar la nómina?"
        action="Aceptar"
        onSubmit={() => handleSubmit()}
        onCancel={() => stateShowModal(false)}
        onClose={() => stateShowModal(false)}
        showCancelButton={true}
        showCloseButton={true}
        showLeftColumn={false}
        showSubmitButton={true}
      >
        <div className="row mx-auto">
          <h5 className="text-black-50">
            <strong>Resultado proceso enrolamiento</strong>
          </h5>

          <div className="border-dashed">
            <p>
              <strong className="text-success">{totalAprobados}</strong>{" "}
              Personas completaron el formulario y
              <strong className="text-success"> cumplen </strong>
              con los requisitos de asegurabilidad.
            </p>
          </div>
          <div className="border-dashed">
            <p>
              <strong className="text-danger">{totalNoAprobados}</strong>{" "}
              Personas completaron el formulario y
              <strong className="text-danger"> no cumplen </strong>con los
              requisitos de asegurabilidad.
            </p>
          </div>
          <div className="border-dashed">
            <p>
              <strong>{totalSinEnrolar}</strong> Personas no terminaron el
              formulario.
            </p>
          </div>
          <div className="border-dashed">
            <p>
              <strong>{totalBeneficiarios}</strong> Personas declararon
              beneficiarios y cumplen con los requisitos.
            </p>
          </div>
          <div>
            <h6 className="font-weight-bold d-block text-primary">
              Importante:
            </h6>
            <div>
              <small className="text-muted d-block">
                • Solo se pondrán asegurar las <strong>{totalAprobados}</strong>{" "}
                personas que cumplieron con los requisitos de asegurabilidad.
              </small>
              <small className="text-muted d-block">
                • Al aceptar la nómina esta poniendo fin a la etapa de
                enrolamiento, por lo que no podrá agregar a más personas.
              </small>
              <small className="text-muted d-block">
                • Las personas que no hayan terminado el formulario ya no podrán
                hacerlo por esta vía.
              </small>
            </div>
          </div>
        </div>
      </ModalEditarAsegurable>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  store: state,
});

NominaAsegurable.propTypes = {
  props: PropTypes.object,
};

export default withRouter(
  connect(mapStateToProps, { guardar_cotizacion, guardar_negocio })(
    NominaAsegurable
  )
);
