import React, { Component, Fragment } from "react";
import SelectContratante from "../SelectContratante";
import {
  listarSoftwareAlianza,
  listarEmpresasBUK,
  listarEmpleadosBUK,
} from "../../../../../../services/transversales.service";
import { notificacion } from "../../../../transversales/Notificaciones";
import moment from "moment";
import {
  MASK_DATE,
  VALOR_ZERO,
  STRING_VACIO,
  VALIDAR_CONTRATANTE,
  CAMPO_REQUERIDO_SOFTWARE,
  CAMPO_REQUERIDO_FECHA,
  SOFTWARE_NO_EXISTE,
  ERROR_SERVICIO_EMPLEADOS_BUK,
  VALOR_UNO,
} from "../../../../../../utils/Constantes";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import MaskedTextInput from "react-text-mask";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  agregarPolizaSoftwareAlianza,
  guardarAsegurableSeleccionadosPorSoftwareAlianza,
  guardarAsegurablesFiltroFecha,
  guardarAsegurablesFiltroOriginal,
  guardarAsegurablesFinalesSoftwareAlianza,
  guardarExistenEmpleadosSoftwareAlianza,
  guardarFechaVigenciaPoliza, guardarParametrosCargaSoftwareRRHH,
  guardarPolizasContratante,
  guardarPolizasSeleccionadas,
  guardarSiEsPrimerRenderizadoTabla,
  loadingAction,
} from "../../../../../../reducer/delegadoMantencion";

class CargaSoftwareRRHH extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listaSoftwareAlianza: [],
      listaEmpresasBUK: [],
      idNegocio: 0, // para validar campos del formulario
      selectNegocio: undefined, // para cotejar existencia de empresa Buk
      negocioTouched: false, // Controla touched selectContratante
      idEmpresaSoftware: 0,
      selectSoftware: this.props.store.delegado.softwareAlianza.parametrosCargaSofwareRRHH ? this.props.store.delegado.softwareAlianza.parametrosCargaSofwareRRHH.selectSoftware : 0,
      objetoSoftware: {},
      polizas: [],
      tieneSoftware: true, // Controla si se muestra el mensaje de software no existe para empresa buk
      fechaDesde: undefined,
      fechaHasta: undefined,
    };
  }

  componentDidMount() {

    this.obtenerListaSoftware();
    this.desactivarAutocomplete();
    this.listenerOnTouchContratante();
  }

  // Obtiene lista de softwares disponibles
  obtenerListaSoftware = async () => {
    const listaSoftwareAlianza = await listarSoftwareAlianza();
    if (listaSoftwareAlianza) {
      this.setState({
        listaSoftwareAlianza,
      });
    }
  };

  // Obtiene los contratantes dispnibles (onChange Contratante)
  obtenerPolizas = async (
    idNegocio,
    polizas,
    polizasFiltro,
    rutContratante
  ) => {
    this.limpiarProps();
    if (Number(idNegocio) !== VALOR_ZERO) {

      // si el contratante seleccionado es distinto del contratante anterior, resetea cambios
      if (Number(idNegocio) !== this.state.idNegocio) {
        this.setState({ idEmpresaSoftware: 0 });
        this.setState({ objetoSoftware: {} });
        this.setState({ tieneSoftware: true });
        this.setState({ fechaDesde: undefined });
        this.setState({ fechaHasta: undefined });
      }

      this.props.guardarPolizasContratante(polizas);
      const negocios = await this.props.store.reducer.negocios;
      await this.setState({
        idNegocio,
        polizas,
        selectNegocio: negocios.filter(
          (negocio) => Number(negocio.idOrganizacion) === Number(idNegocio)
        )[0],
      });
    } else {
      // Limpia los campos del state
      this.setState({ idNegocio: 0 });
      this.setState({ idEmpresaSoftware: 0 });
      this.setState({ objetoSoftware: {} });
      this.setState({ tieneSoftware: true });
      this.setState({ fechaDesde: undefined });
      this.setState({ fechaHasta: undefined });
    }
  };

  // Obtiene las empresas disponibles en BUK (onChange Software)
  obtenerEmpresasBUK = async (event) => {
    if (Number(event.target.value) !== VALOR_ZERO) {

      let listadoEmpresasBUK = []; // Contiene el response completo del servicio empresas buk
      let paginaBUK = 0;

      // Contiene solo las empresas buk filtradas desde el response (o false si el servicio falla)
      await new Promise(async (resolve, reject) => {
        do {
          listadoEmpresasBUK = await listarEmpresasBUK(`?page=${++paginaBUK}`);
          if (!listadoEmpresasBUK && paginaBUK === VALOR_UNO) {
            listadoEmpresasBUK = { pagination: { next: false } };
            reject(false);
          } else if (listadoEmpresasBUK) {
            const empresas = [];
            for (let i = 0; listadoEmpresasBUK.data.length > i && !!listadoEmpresasBUK; i++) {
              const empresa = listadoEmpresasBUK.data[i];
              empresas.push(empresa);
            }
            resolve(empresas);
          } else {
            reject(false);
            listadoEmpresasBUK = { pagination: { next: false } };
          }
        } while (listadoEmpresasBUK.pagination.next);
      }).then(async (resolved) => {
        const selectedSw = this.state.listaSoftwareAlianza.filter(
          (software) => Number(event.target.value) === Number(software.idSoftwareAlianza)
        );
        const negocio = await this.state.selectNegocio;
        const filtroEmpresas = await resolved.filter((empresa) => {
          const rutNegocio = `${negocio.rutEmpresa}-${negocio.digitoVerificador}`;
          const rutEmpresa = empresa.rut.replaceAll(".", "");
          return rutNegocio === rutEmpresa;
        });

        if (filtroEmpresas.length === VALOR_ZERO) {
          this.setState({ tieneSoftware: false });
        } else {
          this.setState({ idEmpresaSoftware: filtroEmpresas[0].id });
          this.setState({ objetoSoftware: selectedSw[0] });
          this.setState({ tieneSoftware: true });
          this.setState({ selectSoftware: Number(event.target.value) });
        }
      }, (rejected) => {
        notificacion("danger", ERROR_SERVICIO_EMPLEADOS_BUK);
      });
    } else {
      // Limpia los campos del state
      this.setState({ objetoSoftware: {} });
      this.setState({ tieneSoftware: true });
      this.setState({ fechaDesde: undefined });
      this.setState({ fechaHasta: undefined });
    }
  };

  // Agrega minDate en formato Date (si no, devuelve en string y lanza warnings)
  handleMinDate = (value) => {
    const fecha = value ? value.split("/") : undefined;
    const fechaMinima = fecha
      ? new Date(fecha[2], fecha[1] - 1, fecha[0])
      : undefined;
    return fechaMinima;
  };

  // Agrega maxDate en formato Date (si no, devuelve en string y lanza warnings)
  handleMaxDate = (value) => {
    const fecha = value ? value.split("/") : undefined;
    const fechaMaxima = fecha
      ? new Date(fecha[2], fecha[1] - 1, fecha[0])
      : undefined;
    return fechaMaxima;
  };

  // Redirecciona a tab Ingresar asegurables
  redireccionaAsergurable = () => {
    const tabElement = document.getElementById("ingresarAsegurables");
    tabElement.click();
  };

  // Desactiva autocompletado en campos de fecha
  desactivarAutocomplete = () => {
    document.getElementById("fechaDesde").autocomplete = "off";
    document.getElementById("fechaHasta").autocomplete = "off";
  };

  // Validaciones para el formulario
  validarSoftware = (value) => Number(value) !== VALOR_ZERO;
  validarFechaDesde = (value) =>
    value !== null && value !== undefined && value !== STRING_VACIO;
  validarFechaHasta = (value) =>
    value !== null && value !== undefined && value !== STRING_VACIO;

  // Busar empleados
  buscarEmpleados = async () => {
    const { fechaDesde, fechaHasta, idEmpresaSoftware } = this.state;
    const asegurablesSinFiltrosFecha = [];
    const params = {
      page: 0,
      companyId: idEmpresaSoftware
    };
    let errorRespuestaServicio = false;

    let empleados = {};
    this.limpiarProps();
    //Forzar un delay para transicion de desmonte del componente.
    //AgregarPolizaSoftware y Tabla Asegurados RRHH
    await new Promise((resolve) => {
      setTimeout(() => resolve('done'), 300);
    })
    this.props.loadingAction(true);

    do {
      empleados = await listarEmpleadosBUK(
        `page=${++params.page}&company_id=${params.companyId}`
      );

      if (!empleados && params.page === VALOR_UNO) {
        errorRespuestaServicio = true;
        empleados = { pagination: { next: false } };
        notificacion("danger", ERROR_SERVICIO_EMPLEADOS_BUK);
      } else if (empleados) {
        for (let index = 0; index < empleados.data.length && !!empleados; index++) {
          const empleado = empleados.data[index];
          asegurablesSinFiltrosFecha.push(
            this.mapearAsegurableSoftwareAlianza(empleado)
          );
        }
      } else {
        empleados = { pagination: { next: false } };
      }

    } while (empleados.pagination.next);

    this.props.loadingAction(false);

    if (!errorRespuestaServicio) {
      const asegurablesFiltrosFecha = asegurablesSinFiltrosFecha.filter(
        (asegurable) => {
          const condicionFechaDesde = moment(
            asegurable.fechaIncorporacion,
            "DD-MM-YYYY"
          ).isSameOrAfter(moment(fechaDesde, "DD/MM/YYYY"));
          const condicionFechaHasta = moment(
            asegurable.fechaIncorporacion,
            "DD-MM-YYYY"
          ).isSameOrBefore(moment(fechaHasta, "DD/MM/YYYY"));

          return condicionFechaDesde && condicionFechaHasta;
        }
      );

      let asegurablesFiltrosFechaOriginal = asegurablesFiltrosFecha.map((obj) =>
        Object.assign({}, obj)
      );

      this.props.guardarAsegurablesFiltroFecha(asegurablesFiltrosFecha);
      this.props.guardarAsegurablesFiltroOriginal(
        asegurablesFiltrosFechaOriginal
      );
      this.props.guardarExistenEmpleadosSoftwareAlianza(
        !!asegurablesFiltrosFecha.length
      );
      this.props.guardarSiEsPrimerRenderizadoTabla(false);
    }
  };

  // Limpia los campos del formulario segun se vayan seleccionando los index 0 de los select
  limpiarCampos = async (event, setFieldValue, setFieldTouched, errors) => {
    if (event.target.id === 'idNegocio') {
      setFieldValue("selectSoftware", VALOR_ZERO);
      setFieldTouched("selectSoftware", false);
      setFieldValue("fechaDesde", undefined);
      setFieldTouched("fechaDesde", false);
      setFieldValue("fechaHasta", undefined);
      setFieldTouched("fechaHasta", false);
      errors = {};
    }
    if (Number(event.target.value) === VALOR_ZERO) {
      setFieldValue("fechaDesde", undefined);
      setFieldTouched("fechaDesde", false);
      setFieldValue("fechaHasta", undefined);
      setFieldTouched("fechaHasta", false);
      errors = {};
    }
  }

  cargarParametrosPreCargados = (setFieldValue, setFieldTouched, errors) => {

    const { parametrosCargaSofwareRRHH } = this.props.store.delegado.softwareAlianza;

    if (parametrosCargaSofwareRRHH) {

      this.setState({
        ...this.state,
        ...{ selectSoftware: parametrosCargaSofwareRRHH.selectSoftware },
        ...{ idNegocio: parametrosCargaSofwareRRHH.negocio.idNegocio },
        ...{ idEmpresaSoftware: parametrosCargaSofwareRRHH.idEmpresaSoftware },
        ...{ objetoSoftware: parametrosCargaSofwareRRHH.objetoSoftware },
        ...{ listaSoftwareAlianza: parametrosCargaSofwareRRHH.listaSoftwareAlianza }
      })


      this.props.guardarParametrosCargaSoftwareRRHH(null);

    }

  }

  // Añade event listener para limpiar formulario al cambiar contratante en funcion de onChange de software
  listenerContratante = (setFieldValue, setFieldTouched, errors) => {
    const options = {
      once: true
    };
    document.getElementById('idNegocio').addEventListener(
      "change",
      (negocioEvent) => this.limpiarCampos(negocioEvent, setFieldValue, setFieldTouched, errors),
      options
    );
  }

  // Listener para efecto onTouch(mobile)/onClick(desktop) de contratante
  listenerOnTouchContratante = () => {
    document.getElementById('idNegocio').addEventListener("click", (event) => this.onTouchContratante(event));
    document.getElementById('idNegocio').addEventListener("touchend", (event) => this.onTouchContratante(event));
  }

  // Manejo "manual" de efecto onTouch en contratante
  onTouchContratante = (event) => {
    if (Number(event.target.value) === VALOR_ZERO) {
      this.setState({ negocioTouched: true });
    } else {
      this.setState({ negocioTouched: false });
    }
  }

  mapearAsegurableSoftwareAlianza(asegurable) {
    return {
      id: asegurable.id,
      fechaIncorporacion: moment(asegurable.active_since, "YYYY-MM-DD").format(
        "DD-MM-YYYY"
      ),
      rutCompleto: asegurable.rut.replaceAll(".", ""),
      nombres: asegurable.first_name,
      apellidoPaterno: asegurable.surname,
      apellidoMaterno: asegurable.second_surname,
      correoElectronico: asegurable.email,
      sistemaPrevisional: asegurable.health_company,
      polizas: "--------",
      fechaInicioVigencia: "--------",
      preSeleccionado: false,
      deshabilitado: false,
      contratante: this.state.selectNegocio.razonSocial,
      idNegocio: this.state.idNegocio,
      polizasTotales: this.state.polizas,
    };
  }

  limpiarProps() {
    this.props.guardarFechaVigenciaPoliza(null);
    this.props.guardarPolizasSeleccionadas([]);
    this.props.guardarAsegurablesFiltroFecha([]);
    this.props.guardarAsegurablesFiltroOriginal([]);
    this.props.guardarAsegurablesFinalesSoftwareAlianza([])
    this.props.guardarAsegurableSeleccionadosPorSoftwareAlianza([]);
    this.props.agregarPolizaSoftwareAlianza(false);
    this.props.guardarExistenEmpleadosSoftwareAlianza(false);
    this.props.guardarSiEsPrimerRenderizadoTabla(true);
  }

  componentWillUnmount() {
    this.props.guardarParametrosCargaSoftwareRRHH({
      listaSoftwareAlianza: this.state.listaSoftwareAlianza,
      selectSoftware: this.state.selectSoftware,
      objetoSoftware: this.state.objetoSoftware,
      idEmpresaSoftware: this.state.idEmpresaSoftware,
      fechaDesde: this.state.fechaDesde,
      fechaHasta: this.state.fechaHasta,
      negocio: {
        idNegocio: this.state.idNegocio,
        polizas: this.state.polizas,
        selectNegocio: this.state.selectNegocio
      }
    });
  }

  render() {
    // Simula margen bajo el select de contratante, al haber un error deja de mostrarse para que aparesca el error correspondiente con animacion
    const contratanteErrorClasses =
      this.state.negocioTouched && Number(this.state.idNegocio) === VALOR_ZERO ?
        'd-none' : 'ml-3 invisible';

    return (
      <Fragment>
        <h3 className="text-muted font-weight-bold">
          Ingreso de asegurables vía software de RRHH
        </h3>

        <div className="container">
          <div className="mx-5">
            <div className="row no-gutters">
              <div className="col-1 pt-2">
                <div className="circle circle-software text-primary float-right mr-2">
                  1
                </div>
                <div className="circle circle-software text-primary float-right mr-2">
                  2
                </div>
                <div className="circle circle-dates text-primary float-right mr-2">
                  3
                </div>
              </div>

              <div className="col-11 pr-4">
                {/* Contratante */}
                <label className="d-block mt-3 text-muted">
                  Seleccionar contratante
                </label>
                <small className="text-primary mx-3">
                  Debe seleccionar un contratante para empezar
                </small>
                <SelectContratante obtenerPolizas={this.obtenerPolizas} idNegocio={Number(this.state.idNegocio)} />
                <small className={contratanteErrorClasses}></small> {/* Elemento funciona como margen inferior */}
                {this.state.negocioTouched &&
                  Number(this.state.idNegocio) === VALOR_ZERO && (
                    <small className="text-danger ml-3 animated fadeIn">
                      {VALIDAR_CONTRATANTE}
                    </small>
                  )}

                {/* formik */}
                <Formik
                  enableReinitialize={false}
                  initialValues={{
                    selectSoftware: this.state.selectSoftware,
                    fechaDesde: undefined,
                    fechaHasta: undefined,
                  }}
                  validationSchema={Yup.object().shape({
                    selectSoftware: Yup.string()
                      .required(CAMPO_REQUERIDO_SOFTWARE)
                      .test(
                        "validarSoftware",
                        CAMPO_REQUERIDO_SOFTWARE,
                        this.validarSoftware
                      ),
                    fechaDesde: Yup.string()
                      .required(CAMPO_REQUERIDO_FECHA)
                      .test(
                        "validarFechaDesde",
                        CAMPO_REQUERIDO_FECHA,
                        this.validarFechaDesde
                      ),
                    fechaHasta: Yup.string()
                      .required(CAMPO_REQUERIDO_FECHA)
                      .test(
                        "validarFechaHasta",
                        CAMPO_REQUERIDO_FECHA,
                        this.validarFechaHasta
                      ),
                  })}
                  onSubmit={async (values, { setSubmitting: resetForm }) => {
                    resetForm({});
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    setFieldValue,
                    handleChange,
                    handleBlur,
                    setFieldTouched,
                  }) => (
                    <Form
                      id="formularioCargaSoftware"
                      name="carga-software-form"
                      onKeyDown={(e) => {
                        if ((e.charCode || e.keyCode) === 13) {
                          e.preventDefault();
                        }
                      }}
                      onKeyUp={this.cargarParametrosPreCargados(setFieldValue, setFieldTouched, errors)}
                    >
                      {/* Software */}
                      <label className="d-block mt-4 text-muted">
                        Seleccionar su software de RRHH
                        </label>
                      <small className="text-primary mx-3">
                        Debe seleccionar la herramienta nominal de sus
                        trabajadores
                        </small>
                      <div className="form-group mx-3 form-min-height">
                        <label>Software</label>
                        <select
                          id="selectSoftware"
                          name="selectSoftware"
                          value={values.selectSoftware}
                          onChange={(event) => {
                            handleChange(event);
                            this.limpiarProps();
                            this.obtenerEmpresasBUK(event);
                            this.limpiarCampos(event, setFieldValue, setFieldTouched, errors);
                            this.listenerContratante(setFieldValue, setFieldTouched, errors);
                          }}
                          onBlur={handleBlur}
                          className="form-control"
                          disabled={Number(this.state.idNegocio) === VALOR_ZERO}
                        >
                          {<option value="0">Seleccione herramienta</option>}
                          {this.state.listaSoftwareAlianza.map(
                            (item, index) => (
                              <option
                                key={`software_${index}`}
                                value={item.idSoftwareAlianza}
                                id={`opcion${item.nombre}`}
                              >
                                {item.nombre}
                              </option>
                            )
                          )}
                        </select>
                        {errors.selectSoftware &&
                          touched.selectSoftware &&
                          Number(this.state.idNegocio) !== VALOR_ZERO && (
                            <small className="text-danger animated fadeIn">
                              {errors.selectSoftware}
                            </small>
                          )}
                        {!errors.selectSoftware &&
                          touched.selectSoftware &&
                          Number(this.state.idNegocio) !== VALOR_ZERO &&
                          !this.state.tieneSoftware && (
                            <small className="text-danger animated fadeIn">
                              {SOFTWARE_NO_EXISTE}{" "}
                              <span
                                className="software-redirect"
                                onClick={this.redireccionaAsergurable}
                              >
                                aquí.
                                </span>
                            </small>
                          )}
                      </div>

                      <label className="d-block mt-4 text-muted">
                        Seleccionar fecha
                        </label>
                      <small className="text-primary mx-3">
                        Debe seleccionar su rango de fecha para asignar póliza
                        </small>
                      <div className="row m-0 p-0">
                        <div className="form-group up-calendar col-6">
                          {/* Fecha desde */}
                          <label>Fecha desde</label>
                          <DatePicker
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            popperPlacement="bottom-start"
                            dropdownMode="select"
                            id="fechaDesde"
                            name="fechaDesde"
                            value={values.fechaDesde}
                            dateFormat="dd/MM/yyyy"
                            placeholderText="DD/MM/YYYY"
                            locale="es"
                            onChange={async (value) => {
                              const fecha = await moment(value).format(
                                "DD/MM/YYYY"
                              );
                              this.setState({ fechaDesde: fecha });
                              setFieldValue("fechaDesde", fecha);
                              setFieldTouched("fechaDesde", true);
                            }}
                            onBlur={handleBlur}
                            maxDate={this.handleMaxDate(values.fechaHasta)}
                            customInput={
                              <MaskedTextInput
                                className="form-control"
                                placeholder="DD/MM/YYYY"
                                mask={MASK_DATE}
                              />
                            }
                            disabled={
                              Number(this.state.idNegocio) === VALOR_ZERO ||
                              Number(values.selectSoftware) === VALOR_ZERO
                            }
                          />
                          {errors.fechaDesde &&
                            touched.fechaDesde &&
                            Number(values.selectSoftware) !== VALOR_ZERO && (
                              <small className="text-danger animated fadeIn">
                                {errors.fechaDesde}
                              </small>
                            )}
                        </div>
                        <div className="form-group up-calendar col-6">
                          {/* Fecha hasta */}
                          <label>Fecha hasta</label>
                          <DatePicker
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            popperPlacement="bottom-start"
                            dropdownMode="select"
                            id="fechaHasta"
                            name="fechaHasta"
                            value={values.fechaHasta}
                            dateFormat="dd/MM/yyyy"
                            placeholderText="DD/MM/YYYY"
                            locale="es"
                            onChange={async (value) => {
                              const fecha = await moment(value).format(
                                "DD/MM/YYYY"
                              );
                              this.setState({ fechaHasta: fecha });
                              setFieldValue("fechaHasta", fecha);
                              setFieldTouched("fechaHasta", true);
                            }}
                            onBlur={handleBlur}
                            minDate={this.handleMinDate(values.fechaDesde)}
                            customInput={
                              <MaskedTextInput
                                className="form-control"
                                placeholder="DD/MM/YYYY"
                                mask={MASK_DATE}
                              />
                            }
                            disabled={
                              Number(this.state.idNegocio) === VALOR_ZERO ||
                              Number(values.selectSoftware) === VALOR_ZERO
                            }
                          />
                          {errors.fechaHasta &&
                            touched.fechaHasta &&
                            Number(values.selectSoftware) !== VALOR_ZERO && (
                              <small className="text-danger animated fadeIn">
                                {errors.fechaHasta}
                              </small>
                            )}
                        </div>
                      </div>

                      <button
                        id="botonBuscar"
                        type="submit"
                        className="btn btn-principal mt-3 d-block ml-auto"
                        onClick={this.buscarEmpleados}
                        disabled={
                          Number(this.state.idNegocio) === VALOR_ZERO ||
                          Number(values.selectSoftware) === VALOR_ZERO ||
                          values.fechaDesde === null ||
                          values.fechaDesde === undefined ||
                          values.fechaDesde === STRING_VACIO ||
                          values.fechaHasta === null ||
                          values.fechaHasta === undefined ||
                          values.fechaHasta === STRING_VACIO
                        }
                      >
                        Buscar
                      </button>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state,
});

export default withRouter(
  connect(mapStateToProps, {
    guardarExistenEmpleadosSoftwareAlianza,
    guardarPolizasSeleccionadas,
    agregarPolizaSoftwareAlianza,
    guardarAsegurablesFiltroFecha,
    guardarAsegurablesFiltroOriginal,
    guardarAsegurableSeleccionadosPorSoftwareAlianza,
    guardarFechaVigenciaPoliza,
    guardarPolizasContratante,
    guardarSiEsPrimerRenderizadoTabla,
    guardarAsegurablesFinalesSoftwareAlianza,
    guardarParametrosCargaSoftwareRRHH,
    loadingAction,
  })(CargaSoftwareRRHH)
);
