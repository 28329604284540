import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  guardar_cotizacion,
  mostrar_modal_archivos,
  mostrar_modal_fecha_enrolamiento,
  guardar_cotizaciones,
} from "../../../../../../../actions/actions";
import Authorized from "../../../../../../../utils/Authorized";
import moment from "moment";
import { ButtonToolbar, DropdownButton, Dropdown } from "react-bootstrap";
import ModalEditarAsegurable from "../../../ModalEditarAsegurable";
import {
  actualizarCotizacion,
  getArchivosCotizacion,
  eliminarArchivoCotizacion,
} from "../../../../../../../services/cotizacion.services";
import { notificacion } from "../../../../../transversales/Notificaciones";
import ModalAdvertenciaGestionadas from "../ModalAdvertenciaGestionadas ";
import { roundToTwo } from "../../../../../../../utils/Functions";
import {
  VAL_COTIS_GEST,
  EXITO_ESTADO_COTI,
  ERROR_ESTADO_COTI,
  T_GENERADA,
  T_GESTIONADA,
  COD_JEFATURA,
  PERFIL_LDAP,
  VALOR_TRES,
  VALOR_SIETE,
  VALOR_DOS,
  VALOR_UNO,
  LARGO_CERO_COTIZACION,
  PATROCINADORES_CONFIGURACION,
  SIN_PATROCINADOR
} from "../../../../../../../utils/Constantes";

class Cotizacion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      perfilUsuario: this.props.store.auth.usuarioSesion.rol.codigo,
      negocio: this.props.negocio,
      cotizacion: this.props.cotizacion_,
      mostrarModalCambiarEstado: false,
      modalAdvertenciaGestionadas: false,
      loadingData: false,
    };
    this.props.guardar_cotizacion(this.props.cotizacionesGestionadas[0]);
  }

  editarCotizacion() {
    this.props.mostrar_modal_archivos(true);
    this.props.history.push("/negocios");
  }

  subirArchivos() {
    this.props.mostrar_modal_archivos(true);
  }

  submit = async () => {
    this.setState({ loadingData: true });

    let objData, intCambiarEstado;
    let lstCotizacionesModificadasCorrectamente = [];
    let lstCotizacionesNoModificadasCorrectamente = [];
    var lstCotizacionesPorCorrelativo = [];
    var lstCotizacionesPorNoCorrelativo = [];

    if (this.props.tipoCotizacion === T_GESTIONADA) {

      const objCotizacion = this.state.cotizacion;
      lstCotizacionesPorCorrelativo = this.props.store.reducer.cotizaciones.gestionadas.filter(objCotizacionGestionada =>
        objCotizacionGestionada.nroProceso === objCotizacion.nroProceso && objCotizacionGestionada.rutEmpresa === objCotizacion.rutEmpresa
      );
      lstCotizacionesPorCorrelativo.forEach(objCotizacion => {

        objCotizacion.estadoCotizacion.idEstadoCotizacion = VALOR_UNO;
        objData = {
          idCotizacion: objCotizacion.idCotizacion,
          estadoCotizacion: { idEstadoCotizacion: VALOR_UNO },
          vigente: true,
          perfil: PERFIL_LDAP,
        };
        intCambiarEstado = actualizarCotizacion(objData);
        if (intCambiarEstado === null) {
          lstCotizacionesNoModificadasCorrectamente.push(objCotizacion);
        } else {
          lstCotizacionesModificadasCorrectamente.push(objCotizacion);
        }
      });

      if (lstCotizacionesNoModificadasCorrectamente.length > LARGO_CERO_COTIZACION) {
        return notificacion("warning", ERROR_ESTADO_COTI);
      } 
      else if (lstCotizacionesModificadasCorrectamente.length === lstCotizacionesPorCorrelativo.length) {
        
        let { generadas } = this.props.store.reducer.cotizaciones;
        let { gestionadas } = this.props.store.reducer.cotizaciones;

        gestionadas = gestionadas.filter(
          (objGestionada) =>
            objGestionada.codigoCotizacion === lstCotizacionesModificadasCorrectamente.filter((objCotizacionModificada) => {
              return objCotizacionModificada.codigoCotizacion !== objGestionada.codigoCotizacion
            })
        );

        lstCotizacionesModificadasCorrectamente.forEach(objCotizacion => {
          generadas.push(objCotizacion);
          let objData = {
            metadataDocto: { folio: objCotizacion.codigoCotizacion },
          };

          const archivosCotizacion = getArchivosCotizacion(objData);
          if (archivosCotizacion && Array.isArray(archivosCotizacion)) {
            archivosCotizacion.map(async (file) => {
              let idDocto = file.idDocto.split(";")[0];
              await eliminarArchivoCotizacion(idDocto);
            });
          }
        });

        const objCotizaciones = { generadas, gestionadas };
        this.mostrarModalCambiarEstado();
        this.props.cambiarEstadoCotizacion(objCotizaciones);
        this.props.guardar_cotizaciones(objCotizaciones);
        notificacion("success", EXITO_ESTADO_COTI);
      }
      this.setState({ loadingData: false });
    } 
    else if (this.props.tipoCotizacion === T_GENERADA) {
      //validar que no existan cotizaciones gestionadas
      if (this.props.store.reducer.cotizaciones.gestionadas.length > LARGO_CERO_COTIZACION) {
        this.mostrarModalCambiarEstado();
        return notificacion("warning", VAL_COTIS_GEST);
      }
      const cotizacion = this.state.cotizacion;
      lstCotizacionesPorCorrelativo = this.props.store.reducer.cotizaciones.generadas.filter(objCotizacion =>
        objCotizacion.nroProceso === cotizacion.nroProceso && objCotizacion.rutEmpresa === cotizacion.rutEmpresa
      );
      lstCotizacionesPorNoCorrelativo = this.props.store.reducer.cotizaciones.generadas.filter(objCotizacion =>
        objCotizacion.nroProceso !== cotizacion.nroProceso
      );
      lstCotizacionesPorCorrelativo.forEach(objCotizacion => {
        objCotizacion.estadoCotizacion.idEstadoCotizacion = VALOR_DOS;
        objData = {
          idCotizacion: objCotizacion.idCotizacion,
          estadoCotizacion: { idEstadoCotizacion: VALOR_DOS },
          vigente: true,
          perfil: PERFIL_LDAP,
        };

        intCambiarEstado = actualizarCotizacion(objData);
        if (intCambiarEstado === null) {
          lstCotizacionesNoModificadasCorrectamente.push(objCotizacion);
        } else {
          lstCotizacionesModificadasCorrectamente.push(objCotizacion);
        }
      });
      if (lstCotizacionesNoModificadasCorrectamente.length > LARGO_CERO_COTIZACION) {
        return notificacion("warning", ERROR_ESTADO_COTI);
      } 
      else if (lstCotizacionesModificadasCorrectamente.length === lstCotizacionesPorCorrelativo.length) {
        let { generadas } = this.props.store.reducer.cotizaciones;
        let { gestionadas } = this.props.store.reducer.cotizaciones;

        generadas = lstCotizacionesPorNoCorrelativo;

        lstCotizacionesModificadasCorrectamente.forEach(objCotizacion => {
          gestionadas.push(objCotizacion);
        });

        const objCotizaciones = { generadas, gestionadas };
        this.props.cambiarEstadoCotizacion(objCotizaciones);
        this.props.guardar_cotizaciones(objCotizaciones);
        notificacion("success", EXITO_ESTADO_COTI);
      }
      this.setState({ loadingData: false });
    }
  };

  mostrarModalCambiarEstado = () => {
    this.setState({
      mostrarModalCambiarEstado: !this.state.mostrarModalCambiarEstado,
    });
  };

  mostrarModalAdvertenciaGestionadas = () => {
    this.setState(
      { modalAdvertenciaGestionadas: !this.state.modalAdvertenciaGestionadas },
      () => { }
    );
  };

  render() {
    const objCotizacion         = this.props.cotizacion_;
    const intIdPatrocinador     = objCotizacion.idPatrocinador > SIN_PATROCINADOR ? objCotizacion.idPatrocinador : SIN_PATROCINADOR;
    const objConfiguracion      = PATROCINADORES_CONFIGURACION.find( (objPatrocinadorConf) => objPatrocinadorConf.id === intIdPatrocinador);
    const stringColorCotizacion = objConfiguracion.color;
    let intPrimaNetaTotal       = 0;
    let intPrimaBrutaTotal      = 0;

    this.state.cotizacion.planCotizado.forEach(objPlanCotizado =>{
      intPrimaNetaTotal  += objPlanCotizado.primaNeta;
      intPrimaBrutaTotal += objPlanCotizado.primaBruta;
    })


    return (
      <React.Fragment>
        <ModalEditarAsegurable
          idSubmit="submitCotizacion"
          type="small"
          show={this.state.mostrarModalCambiarEstado}
          action="Cambiar"
          onSubmit={this.submit}
          loadingData={this.state.loadingData}
          onCancel={() => {
            this.mostrarModalCambiarEstado();
          }}
          onClose={() => {
            this.mostrarModalCambiarEstado();
          }}
          showCancelButton={true}
          showCloseButton={true}
          showLeftColumn={false}
          showSubmitButton={true}
        >
          <div className="row">
            <h5 className="col-12 col-md-8 offset-md-2">
              ¿Está seguro que desea cambiar el estado de la cotización de{" "}
              <strong>{this.props.tipoCotizacion}</strong> a{" "}
              <strong>
                {this.props.tipoCotizacion === T_GESTIONADA
                  ? T_GENERADA
                  : T_GESTIONADA}
              </strong>
              ?
            </h5>
          </div>
        </ModalEditarAsegurable>

        {this.state.modalAdvertenciaGestionadas && (
          <ModalAdvertenciaGestionadas
            modalAdvertenciaGestionadas={this.state.modalAdvertenciaGestionadas}
            mostrarModalAdvertenciaGestionadas={
              this.mostrarModalAdvertenciaGestionadas
            }
          />
        )}

        <div className="row shadow no-gutters animated fadeIn py-4">
          <div
            className="col-12 col-md-3 text-center px-2"
            style={{ borderRight: "1px solid #4444" }}
          >
            <p
              className="font-weight-bold text-muted mb-0"
              style={{ fontSize: "14px" }}
            >
              {this.state.cotizacion.descripcionPlan}
            </p>

            <p className="font-weight-bold h3" style={{ color: stringColorCotizacion }}>
              {roundToTwo(intPrimaNetaTotal)} UF
            </p>

            <p
              className="font-weight-bold text-muted mr-1"
              style={{ fontSize: "12px" }}
            >
              Prima mensual bruta:
              <span
                className="font-weight-normal pl-1"
                style={{ color: stringColorCotizacion }}
              >
                {roundToTwo(intPrimaBrutaTotal)}{" "}
                UF
              </span>
            </p>
          </div>

          <div
            className="col-12 col-md-3 px-3 text-center text-md-left"
            style={{
              borderRight: "1px solid #4444",
              fontSize: "12px",
            }}
          >
            <span className="font-weight-bold text-muted mr-1">
              Fecha cotización:
            </span>
            {this.state.cotizacion.fechaCotizacion
              ? moment(this.state.cotizacion.fechaCotizacion).format(
                "DD-MM-YYYY"
              )
              : "-"}
            <br />
            <span className="font-weight-bold text-muted mr-1">
              Fecha vencimiento:
            </span>
            {this.state.cotizacion.fechaVencimiento
              ? moment(this.state.cotizacion.fechaVencimiento).format(
                "DD-MM-YYYY"
              )
              : "-"}
            <br />

            <span className="font-weight-bold text-muted mr-1">
              Fecha inicio vigencia:
            </span>
            {this.state.cotizacion.fechaInicioVigencia
              ? moment(this.state.cotizacion.fechaInicioVigencia).format(
                "DD-MM-YYYY"
              )
              : "-"}
          </div>

          <div
            className="col-12 col-md-3 px-3 text-center text-md-left"
            style={{
              borderRight: "1px solid #4444",
              fontSize: "12px",
            }}
          >
            <span className="font-weight-bold text-muted mr-1">
              Mínima asegurable:
            </span>
            {this.state.cotizacion.porcentajeMinimaAdherencia}% <br />
            <span className="font-weight-bold text-muted mr-1">
              Número de asegurables:
            </span>
            {this.state.cotizacion.planCotizado[0].totalTitular} <br />
            <span className="font-weight-bold text-muted mr-1">
              Total de cargas asegurable:
            </span>
            {this.state.cotizacion.planCotizado[0].totalCarga} <br />
          </div>

          <div className="col-12 col-md-3 mt-3 mt-md-0">
            <ButtonToolbar className="text-center justify-content-center mt-2">
              <DropdownButton
                id="opciones"
                drop="up"
                title="Opciones"
                disabled={
                  this.state.perfilUsuario === COD_JEFATURA &&
                  this.props.tipoCotizacion !== T_GESTIONADA
                }
                variant="dark btn-sm"
              >
                {/* Opciones */}
                {this.props.tipoCotizacion === T_GESTIONADA && (
                  <Dropdown.Item
                    id="mostrarModalArchivos"
                    onClick={() => this.props.mostrar_modal_archivos(true)}
                  >
                    {this.props.store.reducer.negocio.estadoNegocio
                      .idEstadoNegocio < 3 ? (
                      <Authorized roles={[VALOR_TRES, VALOR_SIETE]}> Adjuntar archivos </Authorized>
                    ) : (
                      <Authorized roles={[VALOR_TRES, VALOR_SIETE]}> Ver archivos </Authorized>
                    )}
                    <Authorized roles={[4]}> Ver archivos </Authorized>
                  </Dropdown.Item>
                )}

                <Authorized roles={[VALOR_TRES, VALOR_SIETE]}>
                  <Dropdown.Item
                    id="cambiarEstado"
                    disabled={
                      this.props.store.reducer.negocio.estadoNegocio
                        .idEstadoNegocio > 2
                        ? true
                        : false
                    }
                    onClick={() => this.mostrarModalCambiarEstado()}
                  >
                    Cambiar Estado
                  </Dropdown.Item>
                </Authorized>
              </DropdownButton>
            </ButtonToolbar>
          </div>
        </div>
        <div className="col-12 shadow no-gutters animated fadeIn py-4" style={{ marginTop: 5 }}>
          <div className="row col-12" style={{ paddingRight: 0, paddingLeft: 10 }}>
            <div
              className="col-3 col-md-3 text-center px-2"
              style={{ borderRight: "1px solid #4444", display: 'flex', alignItems: 'center' }}
            >
              <p
                className="font-weight-bold h3"
                style={{ color: "#707070", width: '100%' }}
              >
                Coberturas
              </p>
            </div>
            <div className="col-9 px-2" >
              <div className="row">
                {this.state.cotizacion.coberturas.map((objCobertura, intIndice) => (
                  <label
                    className="font-weight-bold"
                    key={`${objCobertura.glosaCobertura}_${intIndice}`}
                    style={{
                      color: "#FFFFFF", background: stringColorCotizacion, marginLeft: 15,
                      borderRadius: 4, padding: 6
                    }}>
                    {objCobertura.glosaCobertura}
                  </label>
                ))}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state,
});

export default withRouter(
  connect(mapStateToProps, {
    guardar_cotizacion,
    mostrar_modal_archivos,
    mostrar_modal_fecha_enrolamiento,
    guardar_cotizaciones,
  })(Cotizacion)
);
