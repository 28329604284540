import React from "react";
import ReactDOM from "react-dom";
import logoWhite from "../../assets/images/logo-white.png";
import PropTypes from "prop-types";

const Spinner = ({ claseColor, idSpinner, posicionLogo }) => {
  return ReactDOM.createPortal(
    <div className={claseColor}>
      <div className="container d-flex h-100">
        <div className={posicionLogo}>
          <img src={logoWhite} height="40px" alt="logo_vida_camara_blanco" />
          <br />
          <div className="lds-roller mt-2">
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
          </div>
        </div>
      </div>
    </div>,
    document.querySelector(`#${idSpinner}`)
  );
};
Spinner.propTypes = {
  claseColor: PropTypes.string.isRequired,
  idSpinner: PropTypes.string.isRequired,
  posicionLogo: PropTypes.string.isRequired
};

export default Spinner;
