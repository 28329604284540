import { useSelector } from "react-redux";
import { CLAVE_ACCION_ACTUALIZAR, CLAVE_ACCION_CREAR, COD_ASEGURADO, COD_DELEGADO, CONTRASENA_VALORES_INICIALES, EXITO_ACTUALIZAR_CLAVE, FALSO, NOTIFICACION_ERROR_CAPTCHA, NOTIFICACION_TIPO_ERROR, NOTIFICACION_TIPO_EXITOSA, PERFIL_ASEGURABLE, PERFIL_DELEGADO, RUTA_CAMBIAR_CONTRASENA_EXPIRADA, RUTA_INICIO_SESION, STRING_VACIO, VERDADERO } from "../utils/Constantes";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { buscarPuertaEnlaceToken } from "../services/auth.services";
import { procesarClaveAsegurado, procesarClaveDelegado, validarCambioClaveAsegurable, validarCambioClaveDelegado } from "../services/contrasena.sevices";
import { validarRespuestaPeticion } from "../utils/Functions";
import { format, validate } from "rut.js";
import { validarFormulario } from "../services/contrasena.sevices";
import { notificacion } from "../app/components/transversales/Notificaciones";
import { eliminarTodoEnNavegador } from "../utils/almacenamientoNavegador";
import { useCargando } from "./useCargando";

const objCambioInicial = {
  contrasena: CONTRASENA_VALORES_INICIALES,
  dirty: FALSO,
  rutUsuario: null,
  tipoUsuario:null,
  accion: null,
  usuCodigo: 0,
  idAsegurable: 0
};

const objRecuperarInicial = {
  rutUsuario:null,
  correoEnviado: FALSO,
  correo: null,
  telefono: null,
  escogerCorreo: FALSO,
  correos: null,
  viaContacto: null,
  habilitarEnvio: FALSO
}

export const useContrasena = () => {

  const objRedireccionar           = useHistory();
  const objTipoUsuario             = useSelector(store => store.auth.tipoUsuario);
	const objUsuarioSesion           = useSelector(store => store.auth.usuarioSesion);
	const objAsegurable              = useSelector(store => store.reducer.data_asegurable);
  const { mostrarOcultarCargando } = useCargando();

  const [ objCambio, actualizarCambio]             = useState(objCambioInicial);
  const [ objRecuperar, actualizarRecuperar]       = useState(objRecuperarInicial);
  const [ blnMostrarModal, actualizarMostrarModal] = useState(FALSO);

  const procesarContrasenaTemporal = () => {

    let objEstadoTemporal = {...objCambio};

    if (objUsuarioSesion) {

      objEstadoTemporal.rutUsuario  = objUsuarioSesion.rut;
      objEstadoTemporal.tipoUsuario = objUsuarioSesion.rol.codigo; 
      objEstadoTemporal.accion      = CLAVE_ACCION_ACTUALIZAR;
      objEstadoTemporal.usuCodigo   = objUsuarioSesion.id;
    }

    if (objAsegurable) {
      objEstadoTemporal.idAsegurable = objAsegurable.idAsegurable;
    }

    actualizarCambio(objEstadoTemporal);
  }

  const procesarRecuperarContrasena = async (strClaveActual, strRut, strPerfil) => {

    const strToken     = await buscarPuertaEnlaceToken();
    let objRespuesta   = null;
    let intTipoUsuario = 0;

    if (strPerfil === PERFIL_ASEGURABLE){
      objRespuesta = await validarCambioClaveAsegurable( strRut, strClaveActual, strToken);
      intTipoUsuario = COD_ASEGURADO;
    }
    if (strPerfil === PERFIL_DELEGADO){
      objRespuesta = await validarCambioClaveDelegado( strRut, strClaveActual, strToken);
      intTipoUsuario = COD_DELEGADO;
    }
    
    let objValidacion  = validarRespuestaPeticion(objRespuesta);

    if (objValidacion) {
      objRedireccionar.push(RUTA_CAMBIAR_CONTRASENA_EXPIRADA);
    }

    let objEstadoTemporal = {...objCambio};

    objEstadoTemporal.rutUsuario  = strRut;
    objEstadoTemporal.tipoUsuario = intTipoUsuario;
    objEstadoTemporal.accion      = CLAVE_ACCION_CREAR;
    objEstadoTemporal.contrasena  = {...objCambio.contrasena, actual: strClaveActual };

    actualizarCambio(objEstadoTemporal);
  }

  const mostrarContrasena = (strNombreCampo, blnMostrarContrasena) => {

    let objEstadoTemporal = {...objCambio};

    objEstadoTemporal.contrasena[strNombreCampo] = !blnMostrarContrasena;

    actualizarCambio(objEstadoTemporal);
  }

  const procesarCampoRecuperar = (objEvento) => { 

    let strRut = format(objEvento.target.value.replace(/\./g,"").toUpperCase());

    let objEstadoTemporal = {...objRecuperar};

    objEstadoTemporal.rutUsuario = strRut;
    objEstadoTemporal.habilitarEnvio = validate(strRut); 

    actualizarRecuperar(objEstadoTemporal);
  }

  const mostrarModal = ( blnMostrarModal ) => {

    actualizarMostrarModal(blnMostrarModal);
  }

  const procesarEnvioEnlace = async (objEvento, objRecaptcha) => { 

    let objValidacion = {
      tipoUsuario: objTipoUsuario,
      recaptcha: objRecaptcha.current,
      evento: objEvento,
      estado: objRecuperar
    }
    let objRespuesta = await validarFormulario(objValidacion);
    let objEstadoTemporal = {...objRecuperar, ...objRespuesta};
    actualizarRecuperar(objEstadoTemporal);
  }

  const procesarCambioContrasena = async (objValores, liberarFormulario, objRecaptcha) => {

    mostrarOcultarCargando(VERDADERO);

    await objRecaptcha.current.execute();
    let objRespuesta = objRecaptcha.current.getResponse();
  
    if (objRespuesta) {
      
      let objValidacion = null;

      if (objCambio.tipoUsuario === COD_ASEGURADO) {
        objValidacion = await procesarClaveAsegurado(objValores, objCambio);
      }
  
      if (objCambio.tipoUsuario === COD_DELEGADO) {
        objValidacion = await procesarClaveDelegado(objValores, objCambio);
      }

      if (objValidacion) {
        objRecaptcha.current.reset();
        mostrarOcultarCargando(FALSO);
        return notificacion(objValidacion.tipoNotificacion, objValidacion.error);
      }
      eliminarTodoEnNavegador();
      objRedireccionar.push(RUTA_INICIO_SESION);
      mostrarOcultarCargando(FALSO);
      return notificacion(NOTIFICACION_TIPO_EXITOSA, EXITO_ACTUALIZAR_CLAVE);
    }
    else{
      liberarFormulario({});
      mostrarOcultarCargando(FALSO);
      return notificacion(NOTIFICACION_TIPO_ERROR, NOTIFICACION_ERROR_CAPTCHA);
    }
    
  }

  const procesarCorreoRecuperar = async (strCorreo) =>{

    if (strCorreo !== STRING_VACIO) {

      let objDatosPeticion = {...objRecuperar.datosPeticion, correoElectronico: strCorreo }; 
      let objEstado = {
        ...objRecuperar,
        datosPeticion: {...objDatosPeticion},
        habilitarEnvio: VERDADERO,
        correoDelegadoConfigurado:VERDADERO
      };
      
      actualizarRecuperar(objEstado);
    }
    else{
      let objEstado = {
        ...objRecuperar,
        habilitarEnvio: FALSO,
        correoDelegadoConfigurado:FALSO
      };
      actualizarRecuperar(objEstado);
    }
  }

  return {
    cambioContrasena: objCambio,
    recuperarContrasena: objRecuperar,
    verModal: blnMostrarModal,
    procesarContrasenaTemporal,
    procesarRecuperarContrasena,
    mostrarContrasena,
    procesarCampoRecuperar,
    procesarEnvioEnlace,
    procesarCambioContrasena,
    procesarCorreoRecuperar,
    mostrarModal
  }
}