import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tabs, Tab } from "react-bootstrap";
import TabNegocios from "./TabNegocios";
import * as actions from "../../../../../actions/actions";

const NegociosCanalDigital = (props) => {

  const [key, setKey] = useState(props.key_);
  const dispatch = useDispatch();
  const { DOM } = useSelector((store) => store.reducer);

  return (
    <Tabs
      id="controlled-tab-example"
      activeKey={key}
      onSelect={(k) => {
        dispatch(actions.tipo_negocio(k));
        sessionStorage.setItem("_nTnVC", k);
        dispatch(actions.mostrar_spinner("transparente"));
        setKey(k);
        props.obtenerKeyTipoNegocio(k);
      }}
    >
      {/* NEGOCIOS ACTIVOS */}
      <Tab eventKey="activos" title="Activos">
        {!props.buscando && (
          <TabNegocios
            cantNegociosFilt={props.negociosFiltros.length}
            negocios={props.negociosBusqueda}
            tipoNegocio={props.tipoNegocio}
            height={DOM.height}
          />
        )}
      </Tab>

      {/* NEGOCIOS INACTIVOS */}
      <Tab eventKey="inactivos" title="Inactivos">
        {!props.buscando && (
          <TabNegocios
            cantNegociosFilt={props.negociosFiltros.length}
            negocios={props.negociosBusqueda}
            tipoNegocio={props.tipoNegocio}
            height={DOM.height}
          />
        )}
      </Tab>
    </Tabs>
  );
};

export default NegociosCanalDigital;
