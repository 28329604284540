import {
  USER_LOGOUT,
  DATA_ASEGURABLE,
  SHOW_MENU,
  GET_CIUDADES,
  GET_COMUNAS,
  GET_ESTADO_CIVIL,
  GET_GENEROS,
  GET_PARENTESCO,
  GET_REGIONES,
  GET_FORMA_PAGO,
  GET_BANCO,
  GET_TIPO_CUENTA,
  UPDATE_DATOS_PERSONALES,
  UPDATE_DIRECCION,
  UPDATE_CONTACTO,
  UPDATE_DATOS_BANCARIOS,
  UPDATE_ID_SOLICITUD,
  ADD_CARGA,
  ADD_DETALLE,
  UPDATE_DETALLE,
  CAMBIO_DETALLE,
  UPDATE_CARGA,
  UPDATE_BENEFICIARIO,
  ADD_DECLARACION,
  WIZARD,
  PASSWORD_CHANGED,
  GET_LISTA_DECLARACIONES,
  ADD_NEGOCIO_ASEGURABLE,
  UPDATE_MODAL_COTIZACIONES_NEGOCIO,
  DECLARAR_CARGA,
  DECLARAR_DECLARACION,
  ACTUALIZAR_ASEGURABLES,
  UPDATE_HEIGHT,
  GET_NEGOCIOS,
  GUARDAR_REGIONES,
  GUARDAR_CIUDADES,
  GUARDAR_COMUNAS,
  GUARDAR_NEGOCIOS,
  GUARDAR_NEGOCIO,
  GUARDAR_EJECUTIVOS,
  ESTADOS_NEGOCIOS,
  TIPO_NEGOCIO,
  MOSTRAR_MODAL_ASIGNACION,
  PERIODO_NEGOCIO,
  FECHA_TERMINO_ENROLAMIENTO,
  GUARDAR_GIROS,
  GUARDAR_DATOS_EMPRESA,
  GUARDAR_DATOS_SUCURSAL,
  GUARDAR_DATOS_DELEGADO,
  GUARDAR_ERRORES_CARGA_MASIVA,
  GUARDAR_COTIZACIONES,
  GUARDAR_COTIZACION,
  GUARDAR_CANALES_VENTA,
  MOSTRAR_MODAL_ARCHIVOS,
  MOSTRAR_MODAL_FECHA_ENROLAMIENTO,
  GUARDAR_ASEGURADOS_DELEGADO,
  DATA_WIZARD_ASEGURADO,
  ACTUALIZAR_PROGRESO_ASEGURABLE,
  PREVISION_SALUD,
  MOSTRAR_SPINNER,
  COMPONENTE_WIZARD_EJECUTIVO,
  ACTUALIZAR_BENEFICIARIOS,
  ACEPTAR_DECLARO_BENEFICIARIO,
  OBTENER_INFO_POLIZA,
  GUARDAR_DATOS_DELEGADO_NEGOCIO,
  GUARDAR_CHECKS_SECCION,
  GUARDAR_ESTRUCTURA_FORMULARIO,
  GET_LISTA_PREGUNTAS_COBERTURA,
  GUARDAR_OPT_GESTIONAR_EJECUTIVO,
  GUARDAR_CATALOGO_CONSENTIMIENTO,
  MENU_EJECUTIVO_COMPLETO,
  GUARDAR_FILTRO_SOLICITUDES,
  LIMPIAR_FILTRO_SOLICITUDES,
  OBTENER_PARENTESCO_BENEFICIARIOS,
  CARGANDO,
  TIPO_ALMACENAMIENTO
} from "../actions/types";
import { ALMACENAMIENTO_SESION, LISTA_PERFILES_INICIO_SESION } from "../utils/Constantes";

// State inicial
let initialState = {
  data_asegurable: null,
  show_menu: true,
  isapres: [],
  generos: [],
  estadoCivil: [],
  previsionSalud: [],
  comunas: [],
  ciudades: [],
  canalesVenta: [],
  parentescos: [],
  parentescos_beneficiarios: [],
  declaraciones: [],
  preguntasCobertura: [],
  forma_pago: [],
  bancos: [],
  tipo_cuenta: [],
  negocio_asegurable: {
    rutAsegurable: "",
    grupoAsegurable: 0,
    rutNegocio: "",
    razonSocial: "",
    descripcion: "",
    codigoCotizacion: "",
  },
  progresoAsegurable: {
    porcentajeAvance: 0,
    wizard: [
      { glosa: "datosPersonales", hecho: false, estado: "todo" },
      { glosa: "asegurablesAdicionales", hecho: false, estado: "todo" },
      { glosa: "declaraciones", hecho: false, estado: "todo" },
      { glosa: "seguroDeVida", hecho: false, estado: "todo" },
      { glosa: "resumen", hecho: false, estado: "todo" },
    ],
  },
  grupoAsegurables: [],
  declaroDeportes: false,
  DOM: { height: 0 },
  cambioDetalle: false,
  regiones: [],
  negocios: [],
  negocio: null,
  ejecutivos: [],
  estadosNegocios: [],
  tipoNegocio: "activos",
  periodoNegocio: null,
  giros: [],
  delegadoNegocio: null,
  ejecutivo: {
    configuracionNegocio: {
      datosEmpresa: null,
      datosSucursal: null,
      datosDelegado: null,
    },
  },
  cotizaciones: {},
  cotizacion: {},
  modalCotizacionesNegocio: {
    cargarArchivos: false,
    asignarEjecutivo: false,
    fechaCotizacion: false,
  },
  mostrarModalAsignacion: false,
  delegado: {
    aseguradosDelegado: [],
    erroresCargaMasiva: null,
  },
  wizard: {
    pagina: 1,
    tab: 1,
  },
  negocioAsegurado: {},
  dataWizardAsegurado: null,
  configuracionDeclaraciones: {
    detalle: [],
  },
  informacionPoliza: {},
  cargandoSpinnerNaranjo: false,
  cargandoSpinnerTransparente: false,
  componenteWizardEjecutivo: "DatosEmpresa",
  estructuraFormulario: {},
  optGestionarEjecutivo: 0,
  catalogoConsentimiento: [],
  menuEjecutivoCompleto: 0,
  filtroSolicitudes: null,
  tipoUsuario:null,
  perfilesInicioSesion: LISTA_PERFILES_INICIO_SESION,
  cargando:false,
  tipoAlmacenamiento:ALMACENAMIENTO_SESION
};

export default function ldap(state, action) {
  state = initialState;

  switch (action.type) {

    case USER_LOGOUT:
      return {
        state: {},
      };

    case SHOW_MENU:
      state.show_menu = action.payload;
      return {
        ...state,
      };

    case MENU_EJECUTIVO_COMPLETO:
      state.menuEjecutivoCompleto = action.payload;
      return {
        ...state,
      };
    case UPDATE_MODAL_COTIZACIONES_NEGOCIO:
      state.modalCotizacionesNegocio = action.payload;
      return {
        ...state,
      };
    case WIZARD:
      if (action.payload.type === "pagina") {
        state.wizard.pagina = action.payload.pagina;
      }
      if (action.payload.type === "tab") {
        state.wizard.tab = action.payload.tab;
      }
      return {
        ...state,
      };
    case GET_LISTA_DECLARACIONES:
      state.declaraciones = action.payload;
      return {
        ...state,
      };
    case GET_LISTA_PREGUNTAS_COBERTURA:
      state.preguntasCobertura = action.payload;
      return {
        ...state,
      };
    case GET_CIUDADES:
      state.ciudades = action.payload;
      return {
        ...state,
      };
    case GET_COMUNAS:
      state.comunas = action.payload;
      return {
        ...state,
      };
    case GET_ESTADO_CIVIL:
      state.estadoCivil = action.payload;
      return {
        ...state,
      };
    case GET_GENEROS:
      state.generos = action.payload;
      return {
        ...state,
      };
    case GET_PARENTESCO:
      state.parentescos = action.payload;
      return {
        ...state,
      };
    case OBTENER_PARENTESCO_BENEFICIARIOS:
      state.parentescos_beneficiarios = action.dato;
      return {
        ...state,
      };
    case GET_REGIONES:
      state.regiones = action.payload;
      return {
        ...state,
      };
    case GET_FORMA_PAGO:
      state.forma_pago = action.payload;
      return {
        ...state,
      };
    case GET_BANCO:
      state.bancos = action.payload;
      return {
        ...state,
      };
    case GET_TIPO_CUENTA:
      state.tipo_cuenta = action.payload;
      return {
        ...state,
      };

    case CAMBIO_DETALLE:
      state.cambioDetalle =
        state.cambioDetalle || action.payload ? true : false;
      return {
        ...state,
      };

    case UPDATE_HEIGHT:
      state.DOM.height = action.payload;
      return {
        ...state,
      };
    case GET_NEGOCIOS:
      state.negocios = action.payload;
      return {
        ...state,
      };

    // CATALOGO
    case GUARDAR_REGIONES:
      state.regiones = action.payload;
      return {
        ...state,
      };
    case GUARDAR_CIUDADES:
      state.ciudades = action.payload;
      return {
        ...state,
      };
    case GUARDAR_COMUNAS:
      state.comunas = action.payload;
      return {
        ...state,
      };
    case GUARDAR_CANALES_VENTA:
      state.canalesVenta = action.payload;
      return {
        ...state,
      };

    // NEGOCIOS
    case GUARDAR_NEGOCIOS:
      state.negocios = action.payload;
      return {
        ...state,
      };
    case GUARDAR_NEGOCIO:
      state.negocio = action.payload;
      return {
        ...state,
      };
    case GUARDAR_EJECUTIVOS:
      state.ejecutivos = action.payload;
      return {
        ...state,
      };
    case TIPO_NEGOCIO:
      state.tipoNegocio = action.payload;
      return {
        ...state,
      };

    case ESTADOS_NEGOCIOS:
      state.estadosNegocios = action.payload;
      return {
        ...state,
      };
    case MOSTRAR_MODAL_ASIGNACION:
      state.mostrarModalAsignacion = action.payload;
      return {
        ...state,
      };

    case FECHA_TERMINO_ENROLAMIENTO:
      state.negocio.fechaTerminoEnrolamiento = action.payload;
      return {
        ...state,
      };
    case GUARDAR_FILTRO_SOLICITUDES:
      state.filtroSolicitudes = action.dato;
      return {
        ...state,
      };
    case LIMPIAR_FILTRO_SOLICITUDES:
      state.filtroSolicitudes = null;
      return {
        ...state,
      };
    // COTIZACIÓN
    case GUARDAR_COTIZACIONES:
      state.cotizaciones = action.payload;
      return {
        ...state,
      };
    case GUARDAR_COTIZACION:
      state.cotizacion = action.payload;
      return {
        ...state,
      };
    case MOSTRAR_MODAL_ARCHIVOS:
      state.modalCotizacionesNegocio.cargarArchivos = action.payload;
      return {
        ...state,
      };
    case MOSTRAR_MODAL_FECHA_ENROLAMIENTO:
      state.modalCotizacionesNegocio.fechaCotizacion = action.payload;
      return {
        ...state,
      };
    case MOSTRAR_SPINNER:
      if (action.payload === "naranjo") {
        state.cargandoSpinnerNaranjo = true;
        state.cargandoSpinnerTransparente = false;
      } else if (action.payload === "transparente") {
        state.cargandoSpinnerNaranjo = false;
        state.cargandoSpinnerTransparente = true;
      } else {
        state.cargandoSpinnerNaranjo = false;
        state.cargandoSpinnerTransparente = false;
      }
      return {
        ...state,
      };
    case PERIODO_NEGOCIO:
      state.periodoNegocio = action.payload;
      return {
        ...state,
      };
    case COMPONENTE_WIZARD_EJECUTIVO:
      state.componenteWizardEjecutivo = action.payload;
      return {
        ...state,
      };
    case GUARDAR_GIROS:
      state.giros = action.payload;
      return {
        ...state,
      };
    case GUARDAR_DATOS_EMPRESA:
      state.ejecutivo.configuracionNegocio.datosEmpresa = action.payload;

      return {
        ...state,
      };
    case GUARDAR_DATOS_SUCURSAL:
      state.ejecutivo.configuracionNegocio.datosSucursal = action.payload;

      return {
        ...state,
      };
    case GUARDAR_DATOS_DELEGADO:
      state.ejecutivo.configuracionNegocio.datosDelegado = action.payload;

      return {
        ...state,
      };

    case GUARDAR_DATOS_DELEGADO_NEGOCIO:
      state.delegadoNegocio = action.payload;
      return {
        ...state,
      };

    // DELEGADO
    case GUARDAR_ASEGURADOS_DELEGADO:
      state.delegado.aseguradosDelegado = action.payload;
      return {
        ...state,
      };

    case GUARDAR_ERRORES_CARGA_MASIVA:
      state.delegado.erroresCargaMasiva = action.payload;
      return {
        ...state,
      };
    //ASEGURADO
    case DATA_ASEGURABLE:
      state.data_asegurable = {
        ...state.data_asegurable,
        ...action.payload,
      };
      return {
        ...state,
      };
    case UPDATE_DATOS_PERSONALES:
      state.data_asegurable.datoPersonal.misDatos = {
        ...state.data_asegurable.datoPersonal.misDatos,
        ...action.payload,
      };
      return {
        ...state,
      };
    case UPDATE_DIRECCION:
      state.data_asegurable.datoPersonal.direccion = {
        ...state.data_asegurable.datoPersonal.direccion,
        ...action.payload,
      };
      return {
        ...state,
      };
    case UPDATE_CONTACTO:
      state.data_asegurable.datoPersonal.contacto = {
        ...state.data_asegurable.datoPersonal.contacto,
        ...action.payload,
      };
      return {
        ...state,
      };
    case UPDATE_DATOS_BANCARIOS:
      state.data_asegurable.datoPersonal.datoBancario = {
        ...state.data_asegurable.datoPersonal.datoBancario,
        ...action.payload,
      };
      return {
        ...state,
      };
    case UPDATE_ID_SOLICITUD:
      state.data_asegurable.idSolicitud = action.payload;
      return {
        ...state,
      };
    case ADD_CARGA:
      let add_carga =
        state.dataWizardAsegurado.cargas !== null
          ? state.dataWizardAsegurado.cargas
          : [];

      add_carga.push(action.payload);
      state.dataWizardAsegurado.cargas = add_carga;

      return {
        ...state,
      };
    case UPDATE_CARGA:
      state.dataWizardAsegurado.cargas = action.payload;
      return {
        ...state,
      };
    case UPDATE_BENEFICIARIO:
      state.dataWizardAsegurado.beneficiarios = action.payload;
      return {
        ...state,
      };
    case ADD_DECLARACION:
      state.data_asegurable.declaracion = {
        ...state.data_asegurable.declaracion,
        ...action.payload,
      };
      return {
        ...state,
      };
    case ADD_DETALLE:
      state.configuracionDeclaraciones.detalle = [
        ...state.configuracionDeclaraciones.detalle,
        action.payload,
      ];
      return {
        ...state,
      };
    case UPDATE_DETALLE:
      state.configuracionDeclaraciones.detalle = action.payload;
      return {
        ...state,
      };

    case PASSWORD_CHANGED:
      state.data_asegurable.cambioPassword = action.payload;
      return {
        ...state,
      };

    case ADD_NEGOCIO_ASEGURABLE:
      state.negocio_asegurable = action.payload;
      return {
        ...state,
      };

    case ACTUALIZAR_PROGRESO_ASEGURABLE:
      state.progresoAsegurable = action.payload;

      return {
        ...state,
      };
    case DECLARAR_CARGA:
      state.data_asegurable.declaroCarga = action.payload;
      return {
        ...state,
      };
    case DECLARAR_DECLARACION:
      state.data_asegurable.declaroDeclaracion = action.payload;
      return {
        ...state,
      };
    case ACTUALIZAR_ASEGURABLES:
      state.grupoAsegurables = action.payload;
      return {
        ...state,
      };

    case DATA_WIZARD_ASEGURADO:
      state.dataWizardAsegurado = action.payload;
      return {
        ...state,
      };

    case ACTUALIZAR_BENEFICIARIOS:
      state.dataWizardAsegurado.beneficiarios = action.payload;
      return {
        ...state,
      };

    case ACEPTAR_DECLARO_BENEFICIARIO:
      state.dataWizardAsegurado.solicitud.declaroBeneficiario = action.payload;
      return {
        ...state,
      };

    case OBTENER_INFO_POLIZA:
      state.informacionPoliza = action.payload;
      return {
        ...state,
      };

    case GUARDAR_CHECKS_SECCION:
      state.objetoChecks = action.payload;
      return {
        ...state,
      };

    case GUARDAR_ESTRUCTURA_FORMULARIO:
      state.estructuraFormulario = action.payload;
      return {
        ...state,
      };

    // CATALOGO
    case PREVISION_SALUD:
      state.previsionSalud = action.payload;
      return {
        ...state,
      };

    case GUARDAR_OPT_GESTIONAR_EJECUTIVO:
      state.optGestionarEjecutivo = action.payload;
      return {
        ...state,
      };

    case GUARDAR_CATALOGO_CONSENTIMIENTO:
      state.catalogoConsentimientos = action.payload;
      return {
        ...state,
      };

    case CARGANDO:
      state.cargando = action.payload;
      return {
        ...state,
      };
    
    case TIPO_ALMACENAMIENTO:
      state.tipoAlmacenamiento = action.payload;
      return {
        ...state,
      };

    default:
      return state;
  }
}
