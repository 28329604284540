import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { BarraNavegacion } from "../../transversales/Navbar";
import CabeceraNegocioAsegurado from "./CabeceraNegocioAsegurado";
import { resetSesion } from "../../../../actions/actions";
import { cerrarSesion as cerrarSesionServicio } from "../../../../services/auth.services";
import cookie from "react-cookies";
import { GA_CATEGORIA_FORMULARIO_ENROLAMIENTO, GA_LABEL_SOLICITUD_FINALIZADA, RUTA_INICIO_SESION } from "../../../../utils/Constantes";
import { crearEventoTraza } from "../../../../utils/analitica";

class SolicitudCompletada extends Component {

  componentDidMount() {
    crearEventoTraza(GA_CATEGORIA_FORMULARIO_ENROLAMIENTO, GA_LABEL_SOLICITUD_FINALIZADA);
  }

  cerrarSesion = async () => {
    cerrarSesionServicio(this.props.store.auth.usuarioSesion.rol.codigo);
    this.props.resetSesion();
    cookie.remove("time", { path: "/" });
    sessionStorage.removeItem("_nSeVC");
    sessionStorage.clear();
    this.props.history.push(RUTA_INICIO_SESION);
    window.location.replace("");
  };

  render() {
    return (
      <React.Fragment>
        <BarraNavegacion />
        <CabeceraNegocioAsegurado />
        <div className="container animated fadeIn">
          <div className="row pt-4 mb-4">
            <div className="col-12">
              <div className="card shadow">
                <div className="card-body p-5">
                  <h3 className="text-primary mt-5 mb-5 pb-5">
                    <b>PROCESO FINALIZADO SIN INCORPORARSE</b> 
                    <div className="pt-4">Usted no ha completado su solicitud, finalizará el proceso sin terminar su incorporación a la póliza.</div>
                  </h3>
                <button
                    id="botonCerrarSesion"
                    className="btn btn-principal btn-sm d-block mx-auto mt-3"
                    type="button"
                    onClick={this.cerrarSesion}
                >
                    Cerrar sesión
                </button>  
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state,
});

export default withRouter(
  connect(mapStateToProps, { resetSesion })(SolicitudCompletada)
);
