import React, { Component } from "react";
import { connect } from "react-redux";
import { HashRouter as RouterH, Route, withRouter } from "react-router-dom";
import { AnimatedSwitch, spring } from "react-router-transition";
import {
  COD_ASEGURADO,
  COD_DELEGADO,
  COD_EJECUTIVO,
  COD_JEFATURA,
  COD_CORREDOR,
  COD_EJECUTIVO_MANTENCION,
  COD_EJECUTIVO_COMPLETO,
  RUTA_DEFAULT,
} from "./Constantes";

// we need to map the `scale` prop we define below
// to the transform style property
function mapStyles(styles) {
  return {
    opacity: styles.opacity,
    transform: `scale(${styles.scale})`,
  };
}

// wrap the `spring` helper to use a bouncy config
function bounce(val) {
  return spring(val, {
    stiffness: 330,
    damping: 22,
  });
}

const bounceTransition = {
  // start in a transparent, upscaled state
  atEnter: {
    opacity: 0,
    scale: 1.2,
  },
  // leave in a transparent, downscaled state
  atLeave: {
    opacity: bounce(0),
    scale: bounce(0.8),
  },
  // and rest at an opaque, normally-scaled state
  atActive: {
    opacity: bounce(1),
    scale: bounce(1),
  },
};

class Router extends Component {
  render() {
    const { usuarioSesion } = this.props.store.auth;

    return this.props.location.pathname === RUTA_DEFAULT ? (
      <RouterH>
        <AnimatedSwitch
          atEnter={bounceTransition.atEnter}
          atLeave={bounceTransition.atLeave}
          atActive={bounceTransition.atActive}
          mapStyles={mapStyles}
          className="route-wrapper"
        >
          {usuarioSesion.rol.codigo === COD_ASEGURADO && (
            <Route
              exact
              path={this.props.location.pathname}
              {...this.props}
              render={() => (<React.Fragment><p>Asegurable</p></React.Fragment>)}
            />
          )}
          {(usuarioSesion.rol.codigo === COD_DELEGADO ||
            usuarioSesion.rol.codigo === COD_CORREDOR ||
            usuarioSesion.rol.codigo === COD_EJECUTIVO_MANTENCION ||
            usuarioSesion.rol.codigo === COD_EJECUTIVO_COMPLETO) && (
            <Route
              exact
              path={this.props.location.pathname}
              {...this.props}
              render={() => (<React.Fragment></React.Fragment>)}
            />
          )}
          {usuarioSesion.rol.codigo === COD_EJECUTIVO && (
            <Route
              exact
              path={this.props.location.pathname}
              {...this.props}
              render={() => (<React.Fragment><p>Ejecutivo</p></React.Fragment>)}
            />
          )}
          {usuarioSesion.rol.codigo === COD_JEFATURA && (
            <Route
              exact
              path={this.props.location.pathname}
              {...this.props}
              render={() => (<React.Fragment><p>Jefatura</p></React.Fragment>)}
            />
          )}
          <Route
            render={() => (
              <React.Fragment><p>NO MATCH</p></React.Fragment>
            )}
          />
        </AnimatedSwitch>
      </RouterH>
    ) : (
      <RouterH>
        <AnimatedSwitch
          atEnter={bounceTransition.atEnter}
          atLeave={bounceTransition.atLeave}
          atActive={bounceTransition.atActive}
          mapStyles={mapStyles}
          className="route-wrapper"
        >
          {this.props.routes.map((route, i) => {

            return route.authorized !== undefined &&
              route.authorized !== null &&
              route.authorized.length !== 0 &&
              usuarioSesion !== null &&
              route.authorized.includes(usuarioSesion.rol.codigo) ? (
              <Route
                key={i}
                path={route.path}
                {...this.props}
                render={() => <route.component />}
              />
            ) : (
              <Route
                key={i}
                path={route.path}
                {...this.props}
                render={() => (
                  <React.Fragment>
                    <h1>404</h1>
                    <p>Página no encontrada</p>
                  </React.Fragment>
                )}
              />
            );
          })}
          <Route
            render={() => (
              <React.Fragment>
                <p>NO MATCH</p>
              </React.Fragment>
            )}
          />
        </AnimatedSwitch>
      </RouterH>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state,
});

export default withRouter(connect(mapStateToProps, {})(Router));
