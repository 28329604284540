import React, { useEffect } from "react";
import { Row, Col, Form, InputGroup } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Campo from "../transversales/CampoFormulario";
import {
  ROL_INTERNO,
  CONFIGURACION_CAMPO_RUT,
  CONFIGURACION_CAMPO_USUARIO,
  CONFIGURACION_CAMPO_CONTRASENA,
  RUTA_OLVIDO_CONTRASENA,
  ROL_CORREDOR,
  GA_CATEGORIA_LOGIN,
  GA_LABEL_FORMULARIO
} from "../../../utils/Constantes";
import { useAutenticacion } from "../../../eventos/useAutenticacion";
import { useSelector } from "react-redux";
import { crearEventoTraza } from "../../../utils/analitica";

const { format } = require("rut.js");

export const FormularioInicio = () => {

  const { procesarInicioSesion, liberarPerfilSesion, procesarCampoFormulario } = useAutenticacion();
  const strTipoUsuario      = useSelector(store => store.auth.tipoUsuario);
  const objFormInicioSesion = useSelector(store => store.auth.formInicioSesion);

  let objConfiguracion = {
    ...CONFIGURACION_CAMPO_RUT,
    valor: objFormInicioSesion.rut?format(objFormInicioSesion.rut):""
  };

  if (strTipoUsuario.perfil === ROL_INTERNO) {
    objConfiguracion = {
      ...CONFIGURACION_CAMPO_USUARIO,
      valor: objFormInicioSesion.usuario
    }
  }

  useEffect(() => {
    crearEventoTraza(GA_CATEGORIA_LOGIN, `${GA_LABEL_FORMULARIO}${strTipoUsuario.perfil}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return (
    <>
    <Form onSubmit={(objEvento) => { procesarInicioSesion(objEvento) }} className="text-center">
      <Row>
        <Col>
          <h4 className="font-weight-bold">Bienvenido</h4>
          <p>{strTipoUsuario.perfil} </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text>
                  <i className="fa fa-user" aria-hidden="true"></i>
                </InputGroup.Text>
              </InputGroup.Prepend>
                <Campo objConfiguracion={objConfiguracion} procesarCampoFormulario={procesarCampoFormulario} />
            </InputGroup>
          </Form.Group>
          <Form.Group>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text>
                  <i className="fa fa-lock" aria-hidden="true"></i>
                </InputGroup.Text>
              </InputGroup.Prepend>
                <Campo objConfiguracion={CONFIGURACION_CAMPO_CONTRASENA} procesarCampoFormulario={procesarCampoFormulario} />
            </InputGroup>
          </Form.Group>
        </Col>
      </Row>
    
      {(strTipoUsuario.perfil !== ROL_INTERNO && strTipoUsuario.perfil !== ROL_CORREDOR) && (
        <Row>
          <Col className="text-right" >
            <NavLink to={{pathname: RUTA_OLVIDO_CONTRASENA, aboutProps: {tipoUsuario: strTipoUsuario}}}>
              ¿Olvidaste tu contraseña?
            </NavLink>
          </Col>
        </Row>
      )}

      <Row>
        <Col className="mt-3">
          <button className="btn btn-principal-login color-secundario mr-3" type="button" onClick={() => { liberarPerfilSesion() }} >
            Atrás
          </button>

          <button className="btn btn-principal-login" type="submit" id="botonIngresar">
            Entrar
          </button>
        </Col>
      </Row>
    </Form>
    </>
  );
}