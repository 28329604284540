import {ID_VALOR_ATRIBUTO_CONDICION_INTERVIGENCIA, TIPO_INCORPORACION_CARGA} from "../../../../../utils/Constantes";

export function obtenerDocumentosPrevios(documento, documentosPrevios){
    
    const indiceDocumento = documentosPrevios.map(arr => {  
        const nombreSinRut = arr.nombreDocto.split("_");
        const nombreSinExtension = nombreSinRut[1].split(".")
        return nombreSinExtension[0];
    }).indexOf(documento.glosa);
    
    if (documentosPrevios[indiceDocumento]) {
        documentosPrevios[indiceDocumento].requerido = documento.requerido;

        return [documentosPrevios[indiceDocumento]];
    }  
    return [];
}

export function documentoRequeridoPorParentesco(parentescos, idParentescoSeleccionado, incorporacionCarga, cotizacionesConCondicionado){
    const condiciones = cotizacionesConCondicionado
    .map(cotizacionConCondicionado => cotizacionConCondicionado.valorAtributoTemaCondiciones)
    .flat();
    
    const tieneIntervigencia =  condiciones.find(condicion => condicion.valorAtributoCondicion.idValorAtributoCondicion === ID_VALOR_ATRIBUTO_CONDICION_INTERVIGENCIA);
    if(incorporacionCarga && !tieneIntervigencia){
        const parentescoSeleccionado = parentescos.find( ({idParentesco}) => +idParentesco === +idParentescoSeleccionado );
        if(parentescoSeleccionado){
            const tipoSolicitudIncorporacionCarga = parentescoSeleccionado
            .tipoSolicitudes
            .find(({idTipoSolicitud}) => idTipoSolicitud === TIPO_INCORPORACION_CARGA)
            return tipoSolicitudIncorporacionCarga ? tipoSolicitudIncorporacionCarga.tipoDocumentos : [];
        }
    }
    return [];
}

export const obtenerDocumentosDeCargas = (dataWizardAsegurado) => {

    const documentosCargas = [];

    for (const carga of dataWizardAsegurado.cargas) {
        if(carga.documentos){
            for (const documento of carga.documentos) {
                const keyDocumento =`${carga.rut}-${carga.digitoVerificador}`
                if(!documento.nombreDocto.includes(keyDocumento)){
                    documento.nombreDocto = `${carga.rut}-${carga.digitoVerificador}_${documento.nombreDocto}`;
                }
            }
            documentosCargas.push(...carga.documentos);
            delete carga.documentos;
        }
    }
    return documentosCargas;
}

export const setearDocumentosCargas = (dataWizardAsegurado, documentosCargas) =>{


    if(dataWizardAsegurado.cargas && dataWizardAsegurado.cargas.length &&
       Array.isArray(documentosCargas)){

        for (const carga of dataWizardAsegurado.cargas) {
            carga.documentos = [];
        }

        const rutUnicos = [];
        const rutsAsegurables = documentosCargas
            .map(documento => documento.nombreDocto
            .split("_")[0])

        for (const rutAsegurable of rutsAsegurables) {
            if(!rutUnicos.includes(rutAsegurable)){
                rutUnicos.push(rutAsegurable);
            }
        }

        for (const rutAsegurable of rutUnicos) {
            const cargaConDocumentos = dataWizardAsegurado.cargas.find(({rut, digitoVerificador}) => rut + "-" +digitoVerificador === rutAsegurable);
            if(cargaConDocumentos){
                cargaConDocumentos.documentos = documentosCargas
                    .filter(documento => documento.nombreDocto.split("_")[0] === rutAsegurable);
            }
        }
    }

}