import React, { Component } from 'react'
import moment from "moment";
import {
    CHAR_S, ERROR_AGREGAR_POLIZA_ASEGURABLES_SOFTWARE,
    FECHA_FUERA_VIGENCIA,
    MASK_DATE, NUMERO, STRING_VACIO, VALOR_UNO, 
} from "../../../../../../utils/Constantes";

import DatePicker from "react-datepicker";
import MaskedTextInput from "react-text-mask";
import "react-datepicker/dist/react-datepicker.css";
import {connect} from "react-redux";
import {
}
    from "../../../../../../actions/actions";
import {withRouter} from "react-router-dom";
import MultiSelect from "../../../../transversales/MultiSelect";
import {arrayToFormattedString} from "../../../../../../utils/Functions";
import {
    agregarPolizaSoftwareAlianza,
    guardarPolizasSeleccionadas,
    guardarFechaVigenciaPoliza,
} from "../../../../../../reducer/delegadoMantencion";

class AgregarPolizaSoftwareRRHH extends Component {   
       
    state = {
        polizasSeleccionadas: [],
        polizasFechaErronea: [],
        stringPolizasFechaErronea: "",
        seleccionarAsegurablesError: false
    };

    componentDidMount() {
        this.desactivarAutocomplete();
    }

    desactivarAutocomplete = () => {
        document.getElementById("fechaInicioVigenciaSolicitada").autocomplete = "off";
    };

    validarFechaVigencia = (fechaVigencia) => {

        if(fechaVigencia){
            const fechaStr = moment(fechaVigencia).format("DD/MM/YYYY");
            const fecha = fechaStr.split("/");
            const fechaInicioVigencia = `${fecha[2]}-${fecha[1]}-${fecha[0]}`;

            const {  polizasSeleccionadas } = this.props.store.delegado.softwareAlianza;

            const polizasFechaErronea = polizasSeleccionadas.filter((({poliza}) =>
                    fechaInicioVigencia < moment(poliza.fechaInicioVigencia).format("YYYY-MM-DD")
                    || fechaInicioVigencia > moment(poliza.fechaFinVigencia).format("YYYY-MM-DD")
            )).map(({poliza}) => poliza.numeroPoliza);

            const stringPolizasFechaErronea = arrayToFormattedString(polizasFechaErronea);

            return {polizasFechaErronea, stringPolizasFechaErronea};
        }
        return {};
    }

    validarAsegurablesSeleccionados(asegurablesSeleccionadosSoftwareAlianza){

        const asegurablesSeleccionados = asegurablesSeleccionadosSoftwareAlianza
        .filter(({preSeleccionado, deshabilitado}) => preSeleccionado && !deshabilitado)
        return {
            seleccionarAsegurablesError: !asegurablesSeleccionados.length
        };
    }

    guardarPolizaYFechaVigencia =  () => {

        const { respuestaAsegurablesFiltroFechaOriginal, fechaVigenciaPolizaCargaSofware } = this.props.store.delegado.softwareAlianza;
        const errorValidarAsegurableSeleccionado = this.validarAsegurablesSeleccionados(respuestaAsegurablesFiltroFechaOriginal);
        const errorValidarFechaVigencia = this.validarFechaVigencia(fechaVigenciaPolizaCargaSofware);

        this.setState({
            ...errorValidarAsegurableSeleccionado,
            ...errorValidarFechaVigencia
        })

        if( !(errorValidarFechaVigencia.polizasFechaErronea.length || errorValidarAsegurableSeleccionado.seleccionarAsegurablesError)){
            this.props.agregarPolizaSoftwareAlianza(true);
        }
    }

    render() {


        const validacionFecha = `${FECHA_FUERA_VIGENCIA}${this.state.polizasFechaErronea.length > VALOR_UNO ? CHAR_S : STRING_VACIO} ${NUMERO} ${this.state.stringPolizasFechaErronea}.`;

        return (
            <div className="row justify-content-start mt-2">
                <div className="col-12">
                    <div className="card shadow border-dashed-grey mb-3 px-0">
                        <div className="row no-gutters">
                            <div className="col-5 pl-5">
                                <div className="row no-gutters">
                                    <div className="col-12 pl-2">
                                        <label>Poliza</label>
                                        <MultiSelect
                                            value={this.props.store.delegado.softwareAlianza.polizasSeleccionadas}
                                            onChange={(polizasSeleccionadas) => {
                                                this.props.guardarPolizasSeleccionadas(polizasSeleccionadas);
                                            }}
                                            tooltip={true}
                                            onBlur={false}
                                            name="poliza"
                                            options={this.props.store.delegado.softwareAlianza.polizasContratante}
                                            noOptionsMessage={() =>
                                                "No existen más pólizas para este contratante"
                                            }
                                            placeholder={"Ingrese póliza/s"}
                                            errors={false}
                                            touched={false}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-4 pl-5">
                                <div className="row no-gutters">
                                    <div className="col-12 pl-2">
                                        <label>Fecha Inicio Vigencia Solicitada</label>
                                        <DatePicker
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            popperPlacement="bottom-start"
                                            dropdownMode="select"
                                            id="fechaInicioVigenciaSolicitada"
                                            name="fechaInicioVigenciaSolicitada"
                                            value={moment(this.props.store.delegado.softwareAlianza.fechaVigenciaPolizaCargaSofware).format(
                                                "DD/MM/YYYY"
                                            )}
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText="DD/MM/YYYY"
                                            locale="es"
                                            onChange={(fechaVigenciaPolizaCargaSofware) => {
                                                this.props.guardarFechaVigenciaPoliza(fechaVigenciaPolizaCargaSofware)
                                            }}
                                            customInput={
                                                <MaskedTextInput
                                                    className="form-control"
                                                    placeholder="DD/MM/YYYY"
                                                    mask={MASK_DATE}
                                                />
                                            }
                                        />
                                        { this.state.polizasFechaErronea.length > 0 && (
                                            <small  className="text-danger animated fadeIn">
                                                {validacionFecha}
                                            </small>
                                        )
                                        }

                                    </div>
                                </div>
                            </div>
                            <div className="col-2 pl-3">
                                <div className="row no-gutters">
                                    <div className="col-12">
                                        <button
                                            id="enviar"
                                            onClick={this.guardarPolizaYFechaVigencia}
                                            className="btn btn-principal mt-4 d-block"
                                            disabled={ !(this.props.store.delegado.softwareAlianza.fechaVigenciaPolizaCargaSofware &&
                                                         this.props.store.delegado.softwareAlianza.polizasSeleccionadas &&
                                                         this.props.store.delegado.softwareAlianza.polizasSeleccionadas.length) }>
                                            Añadir
                                        </button>
                                        {
                                            this.state.seleccionarAsegurablesError &&
                                            <small  className="text-danger animated fadeIn">
                                                {ERROR_AGREGAR_POLIZA_ASEGURABLES_SOFTWARE}
                                            </small>
                                        }
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    store: state,
});

export default withRouter(
    connect(mapStateToProps, {
        agregarPolizaSoftwareAlianza,
        guardarPolizasSeleccionadas,
        guardarFechaVigenciaPoliza
    })(
    AgregarPolizaSoftwareRRHH)
);