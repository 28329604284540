import React, { Component } from "react";
import Select, { components } from "react-select";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const styles = {
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: "#F7C366",
      borderRadius: "3px",
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: "white",
    fontWeight: "bold",
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: "white",
    ":hover": {
      backgroundColor: "#ed8d00",
      color: "white",
    },
  }),
};

const MultiValueLabel = (props) => {
  const dataValue = {
    ...props,
    children:
      props.data.cobertura !== undefined
        ? props.data.cobertura.condicionado &&
          `${props.data.cobertura.condicionado.descripcion} - ${props.data.cobertura.condicionado.glosa}`
        : props.children,
  };

  return (
    <OverlayTrigger
      placement="bottom"
      overlay={
        <Tooltip id={dataValue.label} className="tooltip-bottom">
          {`${dataValue.dataTooltip}`}
        </Tooltip>
      }
    >
      <span>
        <components.MultiValueLabel {...dataValue} />
      </span>
    </OverlayTrigger>
  );
};

class MultiSelect extends Component {
  render() {
    return (
      <React.Fragment>
        <Select
          id="idMultiSelect"
          isDisabled={this.props.disabled}
          value={this.props.value}
          onChange={this.props.onChange}
          onBlur={this.props.onBlur}
          components={this.props.tooltip ? { MultiValueLabel } : false}
          isMulti
          name={this.props.name}
          options={this.props.options}
          noOptionsMessage={this.props.noOptionsMessage}
          className="basic-multi-select"
          classNamePrefix="select"
          placeholder={this.props.placeholder}
          theme={(theme) => ({
            ...theme,
            borderRadius: "3px",
            colors: {
              ...theme.colors,
              primary25: "#FED580",
              primary: "#ed8d00",
            },
          })}
          styles={styles}
          key={this.props.folioCotizacion}
        />
        {this.props.errors && this.props.touched && (
          <small className="text-danger animated fadeIn">
            {this.props.errors}
          </small>
        )}
      </React.Fragment>
    );
  }
}

export default MultiSelect;
