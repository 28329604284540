import React, { Component, Fragment } from "react";
import Files from "react-files";
import { notificacion } from "../../../../transversales/Notificaciones";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { guardar_negocios } from "../../../../../../actions/actions";
import plantilla from "../../../../../assets/docs/PlantillaNominaAsegurable.xlsx";
import plantillaMantencion from "../../../../../assets/docs/PlantillaNominaAsegurableMantencion.xlsx";
import plantillaCC from "../../../../../assets/docs/PlantillaNominaAsegurableCC.xlsx";
import XLSX from "xlsx";
import {
  TIPO_BINARIO,
  TIPO_DATA_DOC_XLSX,
  VALIDACION_PESO_ARCHIVO,
  VALIDACION_TIPO_ARCHIVO,
  CODIGO_ERROR_1,
  CODIGO_ERROR_2,
  CARGA_MASIVA,
  _XLSX,
  ESTADO_EXITOSO,
  ERROR_ENVIO_DOCUMENTO,
  ESTADO_FALLIDO,
  ADJUNTE_EXCEL,
  UNIDADES_PROGRESO,
  PORCENTAJE_LIMITE_PROGRESO,
  CIEN_POR_CIENTO,
  VALOR_ZERO,
  VALOR_UNO,
  MAX_FILE,
  TIPO_INCORPORACION_MANTENCION,
  PERFIL_DELEGADO,
  VALIDAR_CONTRATANTE,
  VALIDAR_POLIZA,
  TITLE_SALUD_DIGITAL,
  EXITO_ENVIO_CORREO,
  ERROR_PETICION_SERVIDOR,
  ID_ZERO,
  ZERO_STRING,
  SUBMENU_GLOSA_VER_ASEGURABLES,
  SUBMENU_GLOSA_VER_SOLICITUDES,
  ERROR_ASIGNACION_SUCURSAL,
  TIPO_CANAL_VENTA_DIRECTA,
  VALIDACION_TIPO_PRODUCTO,
  COD_DELEGADO,
  COD_CORREDOR,
  COD_EJECUTIVO,
  PERFIL_LDAP,
  COD_EJECUTIVO_MANTENCION,
  VALOR_NUEVE,
  COD_EJECUTIVO_COMPLETO,
  EJECUTIVO_COMPLETO_MENU_NEGOCIOS,
  EJECUTIVO_COMPLETO_MENU_MANTENCION,
  PROCESANDO_CARGA_MASIVA,
  CARGA_MASIVA_ASINCRONA,
  CARGA_MASIVA_NORMAL,
  LARGO_CERO_COTIZACION,
  COD_STATUS_ERROR,
  ERROR_EDICION,
  VALOR_INICIAL_CONTADOR,
  POSICION_INICIAL,
  SUMAR_UNO_INDICE,
  SUMAR_UNO_CONTADOR,
  VALIDACION_POLIZA,
  PRIMERA_POSICION_LISTA,
  PERFIL_CORREDOR,
  VALOR_GUION,
  TIPO_CANAL_VENTA_CORREDOR,
  ESTADO_NEGOCIO_CERRADO,
  ERROR_CREAR_NEGOCIO,
  ERROR_CREAR_COTIZACION,
  SEGUNDA_POSICION,
  GA_CATEGORIA_ASEGURABLE,
  GA_LABEL_INGRESO_MASIVO,
} from "../../../../../../utils/Constantes";
import {
  obtenerErroresCargaMasiva,
  ejecutarJobCargaMasiva,
  obtenerEstadoJobCargaMasiva,
  enviarMailAsegurable,
} from "../../../../../../services/asegurable.services";
import { guardar_errores_carga_masiva } from "../../../../../../actions/actions";
import SelectContratante from "../SelectContratante";
import MultiSelect from "../../../../transversales/MultiSelect";
import {
  crearNegocio,
  asignarSucursal,
  actualizarNegocio,
  obtenerNegocioDelegado,
  obtenerPersonaPorRut,
  asignarAceptacionNegocio,
} from "../../../../../../services/negocios.service";
import { crearCotizacionPoliza } from "../../../../../../services/poliza.service";
import DeclaracionAceptacion from "../../DeclaracionAceptacion";
import { listarColaboradoresPorNroPolizaNroRenovacion } from "../../../../../../services/bus.persona.services";
import { actualizarUsuarioRevisor } from "../../../../../../services/bus.poliza.services";
import { buscarPorRutEmpresa } from "../../../../transversales/negocio";
import { buscarPorRut } from "../../../../../../services/bus.empresa.services";
import { crearEventoTraza } from "../../../../../../utils/analitica";
import {ErroresCargaMasiva} from "./ErroresCargaMasiva";

class CargaMasiva extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idNegocio: 0,
      polizas: [],
      polizasSeleccionadas: [],
      dataCotizacionPolizas: [],
      archivoCargaMasiva: null,
      cargando: false,
      porcentajeProgreso: 0,
      mostrarBarraProgreso: false,
      intervalIdProgreso: null,
      existenErrores: null,
      archivoVacio: false,
      data: {
        idNegocio: 0,
        polizas: [],
      },
      canalVenta:
        this.props.store.reducer.negocio &&
        this.props.store.reducer.negocio.canalVenta
          ? this.props.store.reducer.negocio.canalVenta.idCanalVenta
          : null,
      opcionesTiposProductos: [],
      subProducto: [],
      cotizacionProducto: [],
      productosSeleccionados: [],
      sesionEjecutivo:
        this.props.store.auth.usuarioSesion.rol.codigo === COD_EJECUTIVO ||
        this.props.store.auth.usuarioSesion.rol.codigo ===
          COD_EJECUTIVO_COMPLETO,
      aceptacion:
        this.props.store.auth.usuarioSesion.rol.codigo === COD_EJECUTIVO ||
        this.props.store.auth.usuarioSesion.rol.codigo ===
          COD_EJECUTIVO_MANTENCION ||
        this.props.store.auth.usuarioSesion.rol.codigo ===
          COD_EJECUTIVO_COMPLETO,
      limpiarAceptacion: false,
      continuidadCobertura: false,
      busquedaPlan: false,
      contador: VALOR_INICIAL_CONTADOR,
      bloquearBusquedaPlan: true,
      blnSesionCorredor:
        this.props.store.auth.usuarioSesion.rol.codigo === COD_CORREDOR,
    };
  }

  componentDidMount() {
    crearEventoTraza(GA_CATEGORIA_ASEGURABLE, GA_LABEL_INGRESO_MASIVO);

    if (this.state.canalVenta === TIPO_CANAL_VENTA_DIRECTA) {
      this.obtenerTiposProductos();
    }
  }

  componentWillUnmount() {
    this.props.guardar_errores_carga_masiva(null);
    if (this.state.canalVenta === TIPO_CANAL_VENTA_DIRECTA) {
      this.obtenerTiposProductos();
    }
  }

  obtenerPolizas = (idNegocio, polizas) => {
    this.setState({
      bloquearBusquedaPlan: true,
    });
    this.setState({ idNegocio, polizas, data: { idNegocio, polizas: [] } });
    polizas.forEach((poliza) => {
      if (
        poliza.poliza.agrupaciones &&
        poliza.poliza.agrupaciones.length > POSICION_INICIAL
      ) {
        this.setState({
          bloquearBusquedaPlan: false,
        });
      }
    });
    if (this.state.bloquearBusquedaPlan) {
      this.setState({
        busquedaPlan: false,
        contador: VALOR_INICIAL_CONTADOR,
      });
    }
  };

  obtenerDataCargaMasiva(archivo) {
    /* convert data to binary string */
    let data = new Uint8Array(archivo);

    let arr = [];
    for (let i = 0; i !== data.length; ++i)
      arr[i] = String.fromCharCode(data[i]);
    let bstr = arr.join("");

    /* Call XLSX */
    let workbook = XLSX.read(bstr, { type: TIPO_BINARIO });

    /* DO SOMETHING WITH workbook HERE */
    let first_sheet_name = workbook.SheetNames[0];
    /* Get worksheet */
    let worksheet = workbook.Sheets[first_sheet_name];
    XLSX.utils.sheet_to_json(worksheet, { raw: true });
  }

  descargarArchivo(documento) {
    let archivo = documento.archivoBase64;
    let nombreDocto = documento.nombreDocto;
    let tipo = TIPO_DATA_DOC_XLSX;

    const linkSource = `${tipo}${archivo}`;
    const downloadLink = document.createElement("a");

    downloadLink.href = linkSource;
    downloadLink.download = nombreDocto;
    downloadLink.click();
  }

  onFilesChange(file) {
    let date = new Date();
    let fechaActual = `${date.getDate()}${
      date.getMonth() + 1
    }${date.getFullYear()}`;

    if (file[0]) {
      let nombreArchivo = `${CARGA_MASIVA}${fechaActual}${_XLSX}`;
      let reader = new FileReader();
      let dataEnvio;
      reader.readAsDataURL(file[0]);
      reader.onload = () => {
        const index = reader.result.indexOf(",");
        const archivoBase64 = reader.result.slice(
          index + 1,
          reader.result.length
        );

        dataEnvio = {
          listaDoctos: {
            nombreDocto: nombreArchivo,
            streamBase64: archivoBase64,
            dataArchivo: this.obtenerDataCargaMasiva(file[0]),
          },
        };
        this.setState({
          archivoCargaMasiva: {
            archivoBase64: archivoBase64,
            nombreDocto: nombreArchivo,
          },
        });
      };
      return dataEnvio;
    }
  }

  onFilesError(error, file) {
    if (error.code === CODIGO_ERROR_2) {
      notificacion("warning", VALIDACION_PESO_ARCHIVO);
    } else if (error.code === CODIGO_ERROR_1) {
      notificacion("warning", VALIDACION_TIPO_ARCHIVO);
    }
  }

  obtenerGruposDeNegocioSuscripcion = async (objDataNegocio) => {
    const { usuarioSesion } = this.props.store.auth;
    let detalleNegocio = await obtenerNegocioDelegado(objDataNegocio);
    let grupos = [];
    this.configurarDataCotizacionPolizas(
      usuarioSesion,
      objDataNegocio.idNegocio
    );
    
    // Polizas seleccionadas
    this.state.polizasSeleccionadas.forEach((objPoliza) => {
      detalleNegocio.cotizaciones.forEach((objCotizacion) => {
        if (
          objPoliza.poliza.codigoCotizacion ===
            objCotizacion.codigoCotizacion ||
          (objPoliza.poliza.polizaAgrupada &&
            objPoliza.poliza.polizaAgrupada.codigoCotizacion ===
              objCotizacion.codigoCotizacion)
        ) {
          objCotizacion.grupos.forEach((objGrupo) => {
            if (
              objGrupo.idContratoSeguro === objPoliza.poliza.idContratoSeguro ||
              (objPoliza.poliza.polizaAgrupada &&
                objPoliza.poliza.polizaAgrupada.idContratoSeguro ===
                  objGrupo.idContratoSeguro)
            ) {
              objGrupo.idCotizacion = objCotizacion.idCotizacion;
              grupos.push(objGrupo);
            }
          });
        }
      });
    });

    return grupos;
  };

  obtenerIdPersonaDelegado = async (rut) => {
    let dataPersona = await obtenerPersonaPorRut(rut, PERFIL_DELEGADO);

    return dataPersona.idPersona;
  };

  actualizarNegocioSuscripcion = async (idNegocio) => {
    const { usuarioSesion } = this.props.store.auth;
    const { negocios, negocio } = this.props.store.reducer;
    const indiceNegocioActualizado = negocios.findIndex(
      ({ rutEmpresa }) => rutEmpresa === negocio.rutEmpresa
    );

    negocios[indiceNegocioActualizado].declaracionDelegado = true;

    const idPersona = await this.obtenerIdPersonaDelegado(usuarioSesion.rut);

    const data = {
      idPersonaDelegado: idPersona,
      perfil: PERFIL_DELEGADO,
      idNegocio: idNegocio,
      declaracionDelegado: true,
    };
    actualizarNegocio(data);
    this.props.guardar_negocios([...negocios]);
  };

  crearNegocioSuscripcion = async (objNegocio) => {
    const { usuarioSesion } = this.props.store.auth;
    const { negocios } = this.props.store.reducer;
    let strPerfil = PERFIL_DELEGADO;
    let blnEsDelegado = true;
    let blnEsCorredor = false;
    let blnEsEjecutivo = false;

    if (
      usuarioSesion.rol.codigo === COD_EJECUTIVO_MANTENCION ||
      usuarioSesion.rol.codigo === COD_EJECUTIVO_COMPLETO
    ) {
      strPerfil = PERFIL_LDAP;
      blnEsDelegado = false;
      blnEsCorredor = false;
      blnEsEjecutivo = true;
    }

    if (usuarioSesion.rol.codigo === COD_CORREDOR) {
      strPerfil = PERFIL_CORREDOR;
      blnEsDelegado = false;
      blnEsCorredor = true;
      blnEsEjecutivo = false;
    }

    objNegocio.perfil = strPerfil;

    if (blnEsEjecutivo) {
      let objRutSeparado = objNegocio.rut.split(VALOR_GUION);
      const objEjecutivo = objNegocio.polizas[
        POSICION_INICIAL
      ].colaboradores.ejecutivos.find(
        (ejecutivo) => ejecutivo.rut === usuarioSesion.rut
      );
      const objCanalVenta = { idCanalVenta: TIPO_CANAL_VENTA_CORREDOR };
      const objTipoIncorporacion = {
        idTipoIncorporacion: TIPO_INCORPORACION_MANTENCION,
      };
      const objEstadoNegocio = { idEstadoNegocio: ESTADO_NEGOCIO_CERRADO };
      const objRegion = {
        idRegion: objEjecutivo.direcciones[POSICION_INICIAL].region
          ? objEjecutivo.direcciones[POSICION_INICIAL].region.idRegion
          : null,
      };
      const blnDeclaracionDelegado = false;
      const intRutEmpresa = Number(objRutSeparado[POSICION_INICIAL]);
      const strDigitoVerificador = objRutSeparado[SEGUNDA_POSICION];
      const intIdUsuarioCrea = usuarioSesion.id;
      const intIdPersonaEjecutivo = usuarioSesion.idPersona;

      objNegocio = {
        ...objNegocio,
        canalVenta: objCanalVenta,
        tipoIncorporacion: objTipoIncorporacion,
        estadoNegocio: objEstadoNegocio,
        region: objRegion,
        declaracionDelegado: blnDeclaracionDelegado,
        rutEmpresa: intRutEmpresa,
        digitoVerificador: strDigitoVerificador,
        idUsuarioCrea: intIdUsuarioCrea,
        idPersonaEjecutivo: intIdPersonaEjecutivo,
      };
    }

    if (blnEsCorredor) {
      // Se busca la organizacion del corredor
      let intIdOrganizacionCorredor = VALOR_ZERO;
      const lstOrganizaciones = await buscarPorRut(usuarioSesion.rut);
      const objOrganizacion = Object.assign(
        {},
        ...lstOrganizaciones.map((objElemento) => ({ ...objElemento }))
      );

      if (objOrganizacion) {
        intIdOrganizacionCorredor = objOrganizacion.idOrganizacion;
      }

      let objRegion = { idRegion: VALOR_ZERO };
      let intIdPersonaDelegado = VALOR_ZERO;
      let intIdPersonaEjecutivo = VALOR_ZERO;

      for (const objPoliza of objNegocio.polizas) {
        const objColaboradores =
          await listarColaboradoresPorNroPolizaNroRenovacion(
            objPoliza.numeroPoliza,
            objPoliza.numeroRenovacion
          );
        intIdPersonaDelegado = objColaboradores.delegado
          ? objColaboradores.delegado.idPersona
          : VALOR_ZERO;
        let objPersonaEjecutivo = await objColaboradores.ejecutivos.find(
          (objEjecutivo) => {
            return objEjecutivo.personaPorTipo &&
              objEjecutivo.personaPorTipo.idPersonaGrupo === VALOR_NUEVE
              ? objEjecutivo
              : null;
          }
        );

        if (objPersonaEjecutivo) {
          intIdPersonaEjecutivo = objPersonaEjecutivo.idPersona;
          objRegion = {
            idRegion:
              objPersonaEjecutivo.direcciones[VALOR_ZERO].region.idRegion,
          };
        }
      }

      objNegocio = {
        ...objNegocio,
        idOrganizacionCorredor: intIdOrganizacionCorredor,
        region: objRegion,
        idPersonaDelegado: intIdPersonaDelegado,
        idPersonaEjecutivo: intIdPersonaEjecutivo,
      };

      objNegocio.polizas = objNegocio.polizas.map((objPoliza) => ({
        ...objPoliza,
        nroPoliza: objPoliza.numeroPoliza,
        nroRenovacion: objPoliza.numeroRenovacion,
      }));
    }

    if (blnEsDelegado) {
      let objRegion = { idRegion: VALOR_ZERO };
      let intIdPersonaEjecutivo = VALOR_ZERO;

      for (const objPoliza of objNegocio.polizas) {
        const objColaboradores =
          await listarColaboradoresPorNroPolizaNroRenovacion(
            objPoliza.numeroPoliza,
            objPoliza.numeroRenovacion
          );
        let objPersonaEjecutivo = await objColaboradores.ejecutivos.find(
          (objEjecutivo) => {
            return objEjecutivo.personaPorTipo &&
              objEjecutivo.personaPorTipo.idPersonaGrupo === VALOR_NUEVE
              ? objEjecutivo
              : null;
          }
        );

        if (objPersonaEjecutivo) {
          intIdPersonaEjecutivo = objPersonaEjecutivo.idPersona;
          objRegion = {
            idRegion:
              objPersonaEjecutivo.direcciones[VALOR_ZERO].region.idRegion,
          };
        }
      }

      objNegocio = {
        ...objNegocio,
        region: objRegion,
        idPersonaEjecutivo: intIdPersonaEjecutivo,
      };
    }

    const intIdNegocio = await crearNegocio(objNegocio);

    if (intIdNegocio && intIdNegocio !== VALOR_ZERO) {
      let objRespAsignacion = this.asignarSucursal(intIdNegocio, objNegocio);

      if (objRespAsignacion) {
        if (blnEsDelegado) {
          let objRespuesta = this.actualizarNegocioSuscripcion(intIdNegocio);

          if (!objRespuesta) {
            return null;
          }
        }

        if (blnEsCorredor) {
          const objNegocioMemoria = negocios.find(
            (objElemento) =>
              objElemento.idOrganizacion === objNegocio.idOrganizacion
          );

          const intIndiceNegocio = negocios.indexOf(objNegocioMemoria);

          if (
            !negocios[intIndiceNegocio].declaracionesPerfil ||
            negocios[intIndiceNegocio].declaracionesPerfil.length === VALOR_ZERO
          ) {
            let objRespuesta = this.asignarAceptacion(intIdNegocio,strPerfil);

            if (!objRespuesta) {
              return null;
            }
          }
        }

        return intIdNegocio;
      }
      return null;
    } else {
      return null;
    }
  };

  asignarAceptacion = async (idNegocio, strPerfil) => {
    const { usuarioSesion } = this.props.store.auth;
    const { negocios, negocio } = this.props.store.reducer;

    const data = {
      idCatalogoDeclaracionPerfil: VALOR_UNO,
      idNegocio,
      idPersona: usuarioSesion.id,
      idUsuarioCrea: usuarioSesion.id,
    };

    const asignarAceptacion = await asignarAceptacionNegocio(data,strPerfil);

    //actualizar reducer
    if (asignarAceptacion) {
      const objetoNegocio = negocios.find(
        (item) => item.idOrganizacion === negocio.idOrganizacion
      );
      const indexNegocio = negocios.indexOf(objetoNegocio);
      const copiaNegocios = [...negocios];
      copiaNegocios[indexNegocio].declaracionesPerfil = [
        { idCatalogoDeclaracionPerfil: VALOR_UNO },
      ];
      this.props.guardar_negocios(copiaNegocios);
    }
  };

  asignarSucursal = async (idNegocio, negocio) => {
    const { usuarioSesion } = this.props.store.auth;
    const data = {
      idNegocio,
      rutEmpresa: negocio.rutEmpresa,
      digitoVerificador: negocio.digitoVerificador,
      idUsuarioCrea: this.props.store.auth.usuarioSesion.id,
      perfil:
        usuarioSesion.rol.codigo === COD_EJECUTIVO_MANTENCION ||
        usuarioSesion.rol.codigo === COD_EJECUTIVO_COMPLETO
          ? PERFIL_LDAP
          : PERFIL_DELEGADO,
    };

    const asignacionSucursal = await asignarSucursal(data);
    if (!asignacionSucursal) {
      notificacion("danger", ERROR_ASIGNACION_SUCURSAL);
      return;
    }
  };

  configurarDataCotizacionPolizas = (usuarioSesion, idNegocio) => {
    let dataCotizacionPolizas = [];
    this.state.polizasSeleccionadas.forEach((e, index) => {
      const dataCotizacion = {
        idContratoSeguroGrupo: e.idContratoSeguroGrupo,
        codigoCotizacion: e.poliza.codigoCotizacion,
        codigoUsuario: usuarioSesion.id,
        idContratoSeguro: e.poliza.idContratoSeguro,
        idNegocio: idNegocio,
        nroGrupo: e.poliza.numeroPoliza.toString(),
        nroPoliza: e.poliza.numeroPoliza,
        nroRenovacion: e.poliza.numeroRenovacion,
        fechaInicioVigencia: e.poliza.fechaInicioVigencia,
        fechaFinVigencia: e.poliza.fechaFinVigencia,
        id: index,
      };

      dataCotizacionPolizas.push(dataCotizacion);

      if (e.poliza.polizaAgrupada) {
        const dataCotizacion = {
          idContratoSeguroGrupo:
            e.poliza.polizaAgrupada.grupos[POSICION_INICIAL]
              .idContratoSeguroGrupo,
          codigoCotizacion: e.poliza.polizaAgrupada.codigoCotizacion,
          codigoUsuario: usuarioSesion.id,
          idContratoSeguro: e.poliza.polizaAgrupada.idContratoSeguro,
          idNegocio: idNegocio,
          nroGrupo: e.poliza.polizaAgrupada.numeroPoliza.toString(),
          nroPoliza: e.poliza.polizaAgrupada.numeroPoliza,
          nroRenovacion: e.poliza.polizaAgrupada.numeroRenovacion,
          fechaInicioVigencia: e.poliza.polizaAgrupada.fechaInicioVigencia,
          fechaFinVigencia: e.poliza.polizaAgrupada.fechaFinVigencia,
          id: index + SUMAR_UNO_INDICE,
        };

        dataCotizacionPolizas.push(dataCotizacion);
      }
    });
    this.setState({ dataCotizacionPolizas });
  };

  crearCotizacionSuscripcion = async (idNegocio) => {
    let { usuarioSesion } = this.props.store.auth;

    await this.configurarDataCotizacionPolizas(usuarioSesion, idNegocio);

    const perfil =
      usuarioSesion.rol.codigo === COD_EJECUTIVO_MANTENCION ||
      usuarioSesion.rol.codigo === COD_EJECUTIVO_COMPLETO
        ? PERFIL_LDAP
        : PERFIL_DELEGADO;

    let resCotizaciones = await crearCotizacionPoliza(
      this.state.dataCotizacionPolizas,
      perfil
    );

    if (resCotizaciones.length > VALOR_ZERO) {
      resCotizaciones.forEach((e, index) => {
        e.id = index;
      });
    }

    return resCotizaciones;
  };

  obtenerIdContratoSeguroGrupos(resCotizaciones) {
    let gruposPoliza = [];
    this.state.dataCotizacionPolizas.forEach((e) => {
      resCotizaciones.forEach((e1) => {
        if (e.codigoCotizacion === e1.codigoCotizacion) {
          gruposPoliza.push({
            idGrupo: e1.idGrupo,
            idContratoSeguroGrupo: e.idContratoSeguroGrupo,
            fechaInicioVigencia: e.fechaInicioVigencia,
            fechaFinVigencia: e.fechaFinVigencia,
            nroPoliza: e.nroPoliza,
            nroRenovacion: e.nroRenovacion,
          });
        }
      });
    });

    return gruposPoliza;
  }

  enviarCorreosAsegurados = async (idGrupo, idNegocio) => {
    const { usuarioSesion } = this.props.store.auth;
    const dataCorreo = {
      grupo: {
        idGrupo,
        nombre: TITLE_SALUD_DIGITAL,
      },
    };

    const perfil =
      usuarioSesion.rol.codigo === COD_EJECUTIVO_MANTENCION ||
      usuarioSesion.rol.codigo === COD_EJECUTIVO_COMPLETO
        ? PERFIL_LDAP
        : PERFIL_DELEGADO;

    const envioCorreo = await enviarMailAsegurable(dataCorreo, perfil);
    if (envioCorreo) {
      this.actualizaRevisor(idNegocio);
      notificacion("success", EXITO_ENVIO_CORREO);
    } else {
      notificacion("danger", ERROR_PETICION_SERVIDOR);
    }
  };

  actualizaRevisor = async (idNegocio) => {
    const dataEnvio = {
      idNegocio,
      revisado: false,
    };
    let actualizarRevisor = await actualizarUsuarioRevisor(dataEnvio);
    if (actualizarRevisor === COD_STATUS_ERROR) {
      notificacion("danger", ERROR_EDICION);
    }
  };

  evaluarExcel = async () => {
    const { reducer, auth } = this.props.store;
    let intIdNegocio = reducer.negocio.idNegocio
      ? reducer.negocio.idNegocio
      : ID_ZERO;

    const strPerfil =
      this.state.sesionEjecutivo ||
      auth.usuarioSesion.rol.codigo === COD_EJECUTIVO_MANTENCION ||
      auth.usuarioSesion.rol.codigo === COD_EJECUTIVO_COMPLETO
        ? PERFIL_LDAP
        : PERFIL_DELEGADO;

    //ejecutar job
    let blnEnviarCorreoMasivo = false;
    let lstIdGrupos = [];
    let objDataEnvio;
    
    if (
      this.state.canalVenta !== TIPO_CANAL_VENTA_DIRECTA &&
      auth.tipoIncorporacion === TIPO_INCORPORACION_MANTENCION
    ) {
      // NEGOCIO MANTENCION

      let { usuarioSesion } = auth;
      let { negocio } = reducer;
      
      negocio = await buscarPorRutEmpresa(
        this.state.polizasSeleccionadas,
        negocio,
        usuarioSesion
      );
      intIdNegocio = negocio.idNegocio;

      let lstCotizaciones = [];
      // Validar creación negocio y grupos al crear cotizaciones
      if (negocio.idNegocio === ID_ZERO || !negocio.idNegocio) {
        intIdNegocio = await this.crearNegocioSuscripcion(negocio);

        if (intIdNegocio) {
          lstCotizaciones = await this.crearCotizacionSuscripcion(intIdNegocio);
        } else {
          return notificacion("danger", ERROR_CREAR_NEGOCIO);
        }
      } else {
        intIdNegocio = negocio.idNegocio;
        let objDataEnvio = {
          idNegocio: intIdNegocio,
          perfil: usuarioSesion.rol.glosa.toUpperCase(),
        };

        lstCotizaciones = await this.obtenerGruposDeNegocioSuscripcion(
          objDataEnvio
        );

        if (lstCotizaciones.length === LARGO_CERO_COTIZACION) {
          lstCotizaciones = await this.crearCotizacionSuscripcion(intIdNegocio);
        }
      }

      if (lstCotizaciones && lstCotizaciones.length > VALOR_ZERO) {
        lstIdGrupos = lstCotizaciones.map(
          (objCotizacion) => objCotizacion.idGrupo
        );
        let grupoPolizas = this.obtenerIdContratoSeguroGrupos(lstCotizaciones);

        objDataEnvio = {
          lstIdGrupo: lstIdGrupos,
          codigoUsuario: auth.usuarioSesion.id,
          idTipoIncorporacion: TIPO_INCORPORACION_MANTENCION,
          documento: this.state.archivoCargaMasiva.archivoBase64,
          grupoPolizas,
          limiteCarga: CARGA_MASIVA_NORMAL,
          continuidadCobertura: false,
          idNegocio: intIdNegocio,
        };
        blnEnviarCorreoMasivo = true;
      } else {
        return notificacion("danger", ERROR_CREAR_COTIZACION);
      }
    } else {
      if (this.state.canalVenta === TIPO_CANAL_VENTA_DIRECTA) {
        // NEGOCIO VENTA DIRECTA

        lstIdGrupos = this.state.productosSeleccionados.map(
          (objProducto) => objProducto.idGrupo
        );

        objDataEnvio = {
          lstIdGrupo: lstIdGrupos,
          codigoUsuario: auth.usuarioSesion.id,
          idTipoIncorporacion:
            reducer.negocio.tipoIncorporacion.idTipoIncorporacion,
          documento: this.state.archivoCargaMasiva.archivoBase64,
          limiteCarga: CARGA_MASIVA_ASINCRONA,
          continuidadCobertura: this.state.continuidadCobertura,
          idNegocio: intIdNegocio,
        };
      } else {
        // NEGOCIO NUEVO

        reducer.cotizacion.forEach((objCotizacion) => {
          objCotizacion.grupos.forEach((objGrupo) => {
            lstIdGrupos.push(objGrupo.idGrupo);
          });
        });

        objDataEnvio = {
          lstIdGrupo: lstIdGrupos,
          codigoUsuario: auth.usuarioSesion.id,
          idTipoIncorporacion:
            reducer.negocio.tipoIncorporacion.idTipoIncorporacion,
          documento: this.state.archivoCargaMasiva.archivoBase64,
          limiteCarga: CARGA_MASIVA_NORMAL,
          continuidadCobertura: false,
          idNegocio: intIdNegocio,
        };
      }
    }

    const blnEjecutarJob = await ejecutarJobCargaMasiva(
      objDataEnvio,
      strPerfil
    );

    if (this.state.canalVenta !== TIPO_CANAL_VENTA_DIRECTA) {
      if (blnEjecutarJob) {
        const intIdJob = blnEjecutarJob.id;
        const objIntervaloIdConsulta = setInterval(async () => {
          //consultar estado
          const objConsultarEstado = await obtenerEstadoJobCargaMasiva(
            intIdJob,
            strPerfil
          );

          if (objConsultarEstado) {
            const { status } = objConsultarEstado;

            if (status === ESTADO_EXITOSO) {
              //llamar mongo log errores
              clearInterval(objIntervaloIdConsulta);
              //se toma el primer idGrupo porque en el batch la ejecucuion queda con id = al primer idGrupo
              const objErroresCargaMasiva = await obtenerErroresCargaMasiva(
                lstIdGrupos[POSICION_INICIAL],
                strPerfil
              );
              if (objErroresCargaMasiva && 
                  objErroresCargaMasiva instanceof Object && 
                  Object.keys(objErroresCargaMasiva).length > VALOR_ZERO) {

                this.props.guardar_errores_carga_masiva(objErroresCargaMasiva);

                if (
                  objErroresCargaMasiva.personas.length === VALOR_ZERO &&
                  objErroresCargaMasiva.totalProceso !== VALOR_ZERO
                ) {
                  this.setState({ existenErrores: false, archivoVacio: false });
                  // Enviar correo a los asegurados
                  if (blnEnviarCorreoMasivo) {
                    this.enviarCorreosAsegurados(
                      lstIdGrupos[POSICION_INICIAL],
                      intIdNegocio
                    );
                  }
                } else if (
                  objErroresCargaMasiva.personas.length === VALOR_ZERO &&
                  objErroresCargaMasiva.totalProceso === VALOR_ZERO
                ) {
                  this.setState({ archivoVacio: true, existenErrores: false });
                } else {
                  this.setState({ existenErrores: true, archivoVacio: false });
                }
              } else {
                notificacion("danger", ERROR_ENVIO_DOCUMENTO);
                this.setState({ mostrarBarraProgreso: false });
              }

              this.setState({ porcentajeProgreso: CIEN_POR_CIENTO });
            } else if (status === ESTADO_FALLIDO) {
              clearInterval(objIntervaloIdConsulta);
            }
          }
        }, 3000);
      } else {
        notificacion("danger", ERROR_ENVIO_DOCUMENTO);
      }
    } else {
      this.setState({
        porcentajeProgreso: CIEN_POR_CIENTO,
        mostrarBarraProgreso: false,
        archivoCargaMasiva: null,
        data: {
          idNegocio: VALOR_ZERO,
          polizas: [],
          subProducto: null,
        },
      });
      notificacion("success", PROCESANDO_CARGA_MASIVA);
    }

    this.limpiarRegistros();
  };

  async limpiarRegistros() {
    await this.setState({
      aceptacion:
        this.props.store.auth.usuarioSesion.rol.codigo === COD_EJECUTIVO ||
        this.props.store.auth.usuarioSesion.rol.codigo ===
          COD_EJECUTIVO_MANTENCION ||
        this.props.store.auth.usuarioSesion.rol.codigo ===
          COD_EJECUTIVO_COMPLETO,
      limpiarAceptacion: true,
    });
  }

  mostrarBarraProgreso = () => {
    if (
      this.state.canalVenta !== TIPO_CANAL_VENTA_DIRECTA &&
      this.props.store.reducer.tipoIncorporacion ===
        TIPO_INCORPORACION_MANTENCION &&
      this.props.store.reducer.menuEjecutivoCompleto !==
        EJECUTIVO_COMPLETO_MENU_NEGOCIOS
    ) {
      // Validar contratante
      if (
        this.state.idNegocio === VALOR_ZERO ||
        this.state.idNegocio === ZERO_STRING
      ) {
        notificacion("warning", VALIDAR_CONTRATANTE);
        return;
      }

      // Validar poliza
      if (
        this.state.polizasSeleccionadas === null ||
        this.state.polizasSeleccionadas.length === VALOR_ZERO
      ) {
        notificacion("warning", VALIDAR_POLIZA);
        return;
      }

      if (
        this.state.polizasSeleccionadas === null ||
        (this.state.polizasSeleccionadas.length > PRIMERA_POSICION_LISTA &&
          this.state.busquedaPlan)
      ) {
        notificacion("warning", VALIDACION_POLIZA);
        return;
      }
    }

    if (
      this.state.canalVenta === TIPO_CANAL_VENTA_DIRECTA &&
      (this.state.productosSeleccionados === null ||
        this.state.productosSeleccionados.length === VALOR_ZERO) &&
      this.props.store.reducer.menuEjecutivoCompleto !==
        EJECUTIVO_COMPLETO_MENU_MANTENCION
    ) {
      notificacion("warning", VALIDACION_TIPO_PRODUCTO);
      return;
    }

    // Validar archivo
    if (this.state.archivoCargaMasiva) {
      this.setState({ mostrarBarraProgreso: true });

      this.evaluarExcel();

      const intervalIdProgreso = setInterval(() => {
        if (this.state.porcentajeProgreso < CIEN_POR_CIENTO) {
          const porcentajeProgreso =
            this.state.porcentajeProgreso + UNIDADES_PROGRESO;
          this.setState(
            { porcentajeProgreso, intervalIdProgreso },
            function () {
              if (this.state.porcentajeProgreso >= PORCENTAJE_LIMITE_PROGRESO) {
                clearInterval(intervalIdProgreso);
              }
            }
          );
        } else {
          clearInterval(intervalIdProgreso);
        }
      }, 100);
    } else {
      notificacion("warning", ADJUNTE_EXCEL);
    }
  };

  //obtener tipos productos
  async obtenerTiposProductos() {
    const { negocio, cotizaciones } = this.props.store.reducer;
    let productos = [];
    const cotizacionesNegocio =
      this.state.sesionEjecutivo || this.state.blnSesionCorredor
        ? cotizaciones
        : negocio.cotizaciones;

    cotizacionesNegocio.forEach((e) => {
      productos.push({
        label: `${e.subProducto.glosaSubProducto} - ${e.codigoCotizacion}`,
        value: e.subProducto.idSubProducto,
        idGrupo: e.grupos[0].idGrupo,
      });
    });
    this.setState({
      cotizacionProducto: negocio,
      opcionesTiposProductos: productos,
    });
  }

  consultarContinuidadCobertura = async (event) => {
    this.setState({ continuidadCobertura: false });
    const cotizaciones =
      this.state.sesionEjecutivo || this.state.blnSesionCorredor
        ? this.props.store.reducer.cotizaciones
        : this.state.cotizacionProducto.cotizaciones;

    if (event) {
      event.forEach((e) => {
        const producto = cotizaciones.find(
          (item) => Number(e.value) === Number(item.subProducto.idSubProducto)
        );
        producto.coberturas.forEach((c) => {
          if (c.cotizacionCobertura.continuidadCobertura) {
            this.setState({ continuidadCobertura: true });
          }
        });
      });
    }
  };

  cargarAceptacion = (value) => {
    this.setState({ aceptacion: !value });
  };

  aceptarBusquedaPlan = () => {
    this.setState({
      busquedaPlan: !this.state.busquedaPlan,
      contador: this.state.contador + SUMAR_UNO_CONTADOR,
      subProducto: [],
    });
  };

  render() {
    const { reducer, auth } = this.props.store;
    const nombreMenuAsegurables =
      auth.tipoIncorporacion === TIPO_INCORPORACION_MANTENCION
        ? SUBMENU_GLOSA_VER_SOLICITUDES
        : SUBMENU_GLOSA_VER_ASEGURABLES;

    const rolId = auth.usuarioSesion.rol.codigo;

    const existeExclusionMasiva = this.props.existeExclusionMasiva;
    return (
      <React.Fragment>
        <div className="container">
          <div className="mx-5">
            <div className="row no-gutters">
              {auth.tipoIncorporacion === TIPO_INCORPORACION_MANTENCION &&
                reducer.menuEjecutivoCompleto !==
                  EJECUTIVO_COMPLETO_MENU_NEGOCIOS && (
                  <Fragment>
                    <SelectContratante
                      obtenerPolizas={this.obtenerPolizas}
                      busquedaPlan={this.state.busquedaPlan}
                      contador={this.state.contador}
                    />
                    <div className="col-md-12">
                      <div className="form-group">
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-1 align-self-center">
                              <label className="switch">
                                <input
                                  checked={this.state.busquedaPlan}
                                  onChange={() => {
                                    this.aceptarBusquedaPlan();
                                  }}
                                  type="checkbox"
                                  disabled={this.state.bloquearBusquedaPlan}
                                />
                                <span className="slider round" />
                              </label>
                            </div>
                            <div
                              className="col-md-11"
                              style={{ paddingLeft: "0px" }}
                            >
                              <label> Búsqueda por Plan</label>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <label>Póliza</label>
                          <MultiSelect
                            value={this.state.data.polizas}
                            onChange={(value) => {
                              this.setState({
                                data: {
                                  ...this.state.data,
                                  polizas: value,
                                },
                                polizasSeleccionadas: value,
                              });
                            }}
                            tooltip={true}
                            onBlur={false}
                            name="poliza"
                            options={this.state.polizas}
                            noOptionsMessage={() =>
                              "No existen más pólizas para este contratante"
                            }
                            placeholder={"Ingrese póliza/s"}
                            errors={false}
                            touched={false}
                          />
                        </div>
                      </div>
                    </div>
                  </Fragment>
                )}

              {this.state.canalVenta === TIPO_CANAL_VENTA_DIRECTA &&
                this.props.store.reducer.menuEjecutivoCompleto !==
                  EJECUTIVO_COMPLETO_MENU_MANTENCION && (
                  <Fragment>
                    {/* 1. tipoDeProducto */}
                    <div className="col-md-12">
                      <div className="form-group">
                        <div className="col-md-12">
                          <label className="font-weight-bold">
                            Tipo de producto{" "}
                            <span className="text-danger">*</span>
                          </label>

                          <MultiSelect
                            value={this.state.data.subProducto}
                            onChange={(e) => {
                              this.setState({
                                data: {
                                  ...this.state.data,
                                  subProducto: e,
                                },
                                productosSeleccionados: e,
                              });
                              this.consultarContinuidadCobertura(e);
                            }}
                            tooltip={true}
                            name="subProducto"
                            options={this.state.opcionesTiposProductos}
                            noOptionsMessage={() =>
                              "No existen más subProducto para seleccionar"
                            }
                            placeholder={"Ingrese subProducto/s"}
                            errors={false}
                            touched={false}
                          />
                        </div>
                      </div>
                    </div>
                  </Fragment>
                )}

              <div className="col-1 pt-2">
                <div className="circle text-primary float-right mr-2">1</div>
                <div className="circle text-primary float-right mr-2">2</div>
              </div>
              <div className="col-11 pr-4">
                <label className="d-block mt-3 text-muted">
                  Planilla Carga Masiva
                </label>
                <small className="text-primary">
                  Debe descargar la planilla y luego llenar con los datos de los
                  asegurables
                </small>
                <div className="text-center">
                  <a
                    className="btn btn-principal color-secundario btn-sm mt-1"
                    download="PLANTILLA_CARGA_MASIVA_VC.xlsx"
                    href={
                      rolId === COD_EJECUTIVO_COMPLETO
                        ? this.props.store.reducer.menuEjecutivoCompleto ===
                          EJECUTIVO_COMPLETO_MENU_NEGOCIOS
                          ? !this.state.continuidadCobertura
                            ? plantilla
                            : plantillaCC
                          : plantillaMantencion
                        : this.props.store.auth.tipoIncorporacion ===
                            TIPO_INCORPORACION_MANTENCION ||
                          rolId === COD_EJECUTIVO_MANTENCION
                        ? plantillaMantencion
                        : !this.state.continuidadCobertura
                        ? plantilla
                        : plantillaCC
                    }
                    target={"_blank"}
                    rel="noopener noreferrer"
                  >
                    {" "}
                    Descargar planilla carga masiva{" "}
                    <i className="fa fa-download ml-2" aria-hidden="true"></i>
                  </a>
                </div>

                <label className="d-block mt-3 text-muted"> Carga Masiva</label>
                <small className="text-primary">
                  Cuando tenga su archivo con la informacion de los asegurables,
                  debe cargar el documento aquí
                </small>
                {!this.state.cargando &&
                this.state.archivoCargaMasiva === null ? (
                  <Files
                    id="subirArchivo"
                    className="input-file py-2"
                    onChange={(file) => {
                      this.onFilesChange(file);
                    }}
                    onError={this.onFilesError}
                    accepts={[_XLSX]}
                    multiple={false}
                    maxFiles={VALOR_UNO}
                    maxFileSize={MAX_FILE}
                    minFileSize={VALOR_UNO}
                    clickable
                  >
                    <div className="row cursor text-center">
                      <div className="my-auto ml-auto">
                        <i
                          title="Adjuntar excel"
                          className="fa fa-file-excel-o text-excel d-block text-right"
                          aria-hidden="true"
                        ></i>
                      </div>
                      <div className="my-auto mr-auto ml-2">
                        <span className="d-block">
                          {" "}
                          Subir documento de carga masiva
                        </span>
                      </div>
                    </div>
                  </Files>
                ) : (
                  !this.state.cargando && (
                    <div className="row border-dashed-grey ml-0">
                      <div className="col-3"></div>
                      <div
                        id="descargarArchivo"
                        className="col-6 pl-0 my-auto"
                        onClick={() =>
                          this.descargarArchivo(this.state.archivoCargaMasiva)
                        }
                      >
                        <span
                          className="d-block cursor"
                          style={{ wordBreak: "break-word" }}
                        >
                          {" "}
                          {this.state.archivoCargaMasiva.nombreDocto}
                        </span>
                      </div>
                      <div className="col-3 my-auto">
                        <i
                          id="eliminarArchivo"
                          className="fa fa-trash text-warning fa-2x cursor"
                          aria-hidden="true"
                          onClick={() => {
                            clearInterval(this.state.intervalIdProgreso);
                            clearInterval(this.state.intervalIdConsulta);
                            this.props.guardar_errores_carga_masiva(null);
                            this.setState({
                              archivoCargaMasiva: null,
                              mostrarBarraProgreso: false,
                              porcentajeProgreso: 0,
                              existenErrores: null,
                            });
                          }}
                        ></i>
                      </div>
                    </div>
                  )
                )}

                {this.state.mostrarBarraProgreso === true && (
                  <div className="row mb-3">
                    <small className="col-12">
                      <strong className="pull-right">
                        {this.state.porcentajeProgreso.toString() + "%"}
                      </strong>
                    </small>
                    <div className="col-12">
                      <div className="progress">
                        <div
                          className={`progress-bar ${
                            this.state.porcentajeProgreso < 100 &&
                            "progress-bar-striped progress-bar-animated"
                          } bg-warning`}
                          role="progressbar"
                          aria-valuenow={this.state.porcentajeProgreso}
                          aria-valuemin="0"
                          aria-valuemax="100"
                          style={{
                            width:
                              this.state.porcentajeProgreso.toString() + "%",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {this.state.existenErrores !== null &&
                  (this.state.existenErrores ? (
                    <>
                      <div className="row mt-1 ml-1 mb-3">
                        <i className="fa fa-times-circle fa-2x text-danger mt-1"></i>
                        <small className="text-danger ml-2">
                          Hemos encontrado registros, faltantes y/o con problemas.{" "}
                          <br />
                          El detalle de los errores se encuentra a continuación:
                        </small>
                      </div>
                      <ErroresCargaMasiva
                          datosSolicitud={  this.props.datosSolicitud }
                          erroresCargaMasiva={ this.props.store.reducer.delegado.erroresCargaMasiva }
                          usuarioRolId={ this.props.store.auth.usuarioSesion.rol.codigo }
                          tipoIncorporacion={ this.props.tipoIncorporacion}
                      />
                    </>
                  ) : this.state.archivoVacio ? (
                    <div className="row mt-1 ml-1 mt-1">
                      <i className="fa fa-times-circle fa-2x text-danger"></i>
                      <small className="text-danger mt-1 ml-2">
                        El archivo cargado no tiene registros, o no cuenta con
                        el formato adecuado.
                      </small>
                    </div>
                  ) : (
                    <div className="row mt-1 ml-1 mt-1">
                      <i className="fa fa-check-circle fa-2x text-success"></i>
                      <small className="text-success mt-1 ml-2">
                        Se ha cargado correctamente el archivo. Los asegurables
                        ya
                        <br />
                        se encuentran ingresados en la sección{" "}
                        <strong>{nombreMenuAsegurables}</strong>.
                      </small>
                    </div>
                  ))}
              </div>

              {!existeExclusionMasiva && (
                <DeclaracionAceptacion
                  aceptacion={this.cargarAceptacion}
                  rolId={rolId}
                  codPerfil={[COD_CORREDOR, COD_DELEGADO]}
                  cargaMasiva={true}
                  limpiar={this.state.limpiarAceptacion}
                />
              )}

              <button
                id="enviar"
                className="btn btn-principal mt-3 d-block ml-auto"
                onClick={this.mostrarBarraProgreso}
                disabled={
                  existeExclusionMasiva
                    ? this.state.mostrarBarraProgreso
                    : this.state.mostrarBarraProgreso || !this.state.aceptacion
                }
              >
                Enviar
              </button>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state,
});

export default withRouter(
  connect(mapStateToProps, { guardar_errores_carga_masiva, guardar_negocios })(
    CargaMasiva
  )
);
