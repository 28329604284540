import React from "react";

import TablaErrores from "./TablaErrores";
import { COD_EJECUTIVO_COMPLETO, COD_EJECUTIVO_MANTENCION, PAGINA_2, VALOR_ZERO } from "../../../../../../utils/Constantes";

const iconoFormatoErroneo = require("../../../../../assets/images/formato-erroneo.svg");
const iconoUsuarioDuplicado = require("../../../../../assets/images/asegurable.svg");
const iconoVacio = require("../../../../../assets/images/empty.svg");
const iconoFechaVigenciaPoliza = require("../../../../../assets/images/fecha-vigencia-poliza.svg");
export const ErroresCargaMasiva = ({ datosSolicitud, usuarioRolId, erroresCargaMasiva, tipoIncorporacion }) => {
  return (
    <div>
      {datosSolicitud.intPagina === PAGINA_2 &&
        (datosSolicitud.blnDeclaracionAceptacion || usuarioRolId === COD_EJECUTIVO_MANTENCION || usuarioRolId === COD_EJECUTIVO_COMPLETO) &&
        erroresCargaMasiva &&
        erroresCargaMasiva.personas.length > VALOR_ZERO && (
            <React.Fragment>
              <div className="card shadow border-dashed-grey mb-3 px-0">
                <div className="row no-gutters">
                  <div className="col-3 pl-5">
                    <div className="row no-gutters">
                      <img
                        className="col-2 mt-2"
                        alt="img-icon"
                        width="20px"
                        src={iconoFormatoErroneo}
                        style={{
                          maxWidth: "30px",
                        }}
                      />
                      <small
                        className="col-10 pl-2 text-wrap"
                        style={{
                          maxWidth: "130px",
                        }}
                      >
                        Campo con formato incorrecto
                      </small>
                    </div>
                  </div>
                  <div className="col-3 pl-5">
                    <div className="row no-gutters">
                      <img
                        className="col-2 mt-2"
                        alt="img-icon"
                        width="22px"
                        height="22px"
                        src={iconoUsuarioDuplicado}
                        style={{
                          maxWidth: "none",
                        }}
                      />
                      <small
                        className="col-10 pl-2 text-wrap"
                        style={{
                          maxWidth: "155px",
                        }}
                      >
                        Asegurable ya agregado o duplicado en el documento
                      </small>
                    </div>
                  </div>
                  <div className="col-2 pl-5">
                    <div className="row no-gutters">
                      <img
                        className="col-2 mt-1"
                        alt="img-icon"
                        width="20px"
                        src={iconoVacio}
                        style={{
                          maxWidth: "30px",
                        }}
                      />
                      <small
                        className="col-10 text-wrap pl-2"
                        style={{
                          maxWidth: "55px",
                        }}
                      >
                        Campo vacío
                      </small>
                    </div>
                  </div>
                  <div className="col-3 pl-5">
                    <div className="row no-gutters">
                      <img
                        className="col-2 mt-1"
                        alt="img-icon"
                        width="20px"
                        src={iconoFechaVigenciaPoliza}
                        style={{
                          maxWidth: "30px",
                        }}
                      />
                      <small
                        className="col-10 text-wrap pl-2"
                        style={{
                          maxWidth: "150px",
                        }}
                      >
                        Fecha fuera del rango de vigencia de póliza o del mes actual
                      </small>
                    </div>
                  </div>
                </div>
              </div>
              <TablaErrores tipoIncorporacion={tipoIncorporacion} />
            </React.Fragment>
        )}
    </div>
  );
};
