import React, { Component, Fragment } from "react";
import {
  guardar_data_wizard_asegurado,
  wizard,
} from "../../../../../../actions/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { notificacion } from "../../../../transversales/Notificaciones";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { guardarDataWizard } from "../../../../../../services/asegurable.services";
import {
  EXITO_MIS_DATOS,
  ERROR_MIS_DATOS,
  CAMPO_REQUERIDO,
  VALIDACION_TELEFONO,
  VALIDACION_MAIL,
  VALIDACION_CIUDAD,
  VALIDACION_COMUNA,
  CAMPO_REQUERIDO_DIRECCION,
  CAMPO_REQUERIDO_NUMERO,
  CAMPO_REQUERIDO_EMAIL,
  PAGINA_3,
  TIPO_PAGINA,
  TIPO_TAB,
  PAGINA_2,
  PAGINA_1,
  NOTIFICACION_SIN_OPCIONES,
  GA_CATEGORIA_FORMULARIO_ENROLAMIENTO,
  GA_LABEL_DIRECCION,
} from "../../../../../../utils/Constantes";
import {
  formatTel,
  formatNumber,
  soloLetrasYNumeros,
  validacionDireccion,
  soloLetrasNumerosYAmpersand,
} from "../../../../../../utils/Functions";
import { crearEventoTraza } from "../../../../../../utils/analitica";

class Direccion extends Component {
  constructor(props) {
    super(props);
    const misDatos = this.props.store.reducer.dataWizardAsegurado;
    misDatos.correoElectronico = misDatos.correoElectronico
      ? misDatos.correoElectronico
      : this.props.store.reducer.data_asegurable.correoElectronico;
    this.state = {
      misDatos: misDatos,
      solicitud: this.props.store.reducer.data_asegurable.idSolicitud,
      catalogo: {
        ciudades: this.props.store.reducer.ciudades,
        comunas: this.props.store.reducer.comunas,
        comunasCiudad: [],
      },
      loadingData: false,
    };
  }

  componentDidMount() {
    crearEventoTraza(GA_CATEGORIA_FORMULARIO_ENROLAMIENTO, GA_LABEL_DIRECCION);

    if (this.state.misDatos.comuna) {
      this.obtenerComunasDeUnaCiudad(this.state.misDatos.ciudad.idCiudad);
    }
  }

  /*************************
   * * * VALIDACIONES      *
   *************************/
  notificarSinOpciones = (catalogo) => {
    if (!catalogo.length) {
      notificacion("info", NOTIFICACION_SIN_OPCIONES);
    }
  };

  async obtenerComunasDeUnaCiudad(idCiudad) {
    let comunasCiudad = this.state.catalogo.comunas.filter((c) => {
      return Number(idCiudad) === Number(c.idCiudad);
    });

    // Eliminar repetidos
    let flag = {};
    comunasCiudad = comunasCiudad.filter((e) => {
      let exists = !flag[e.glosaComuna] || false;
      flag[e.glosaComuna] = true;
      return exists;
    });

    let catalogo = Object.assign({}, this.state.catalogo);
    catalogo.comunasCiudad = comunasCiudad;
    await this.setState({ catalogo });
  }

  obtenerRegionDeUnaCiudad(idComuna) {
    if (!idComuna) return;

    let comuna = this.state.catalogo.comunas.find((co) => {
      return Number(idComuna) === Number(co.idComuna);
    });

    let ciudad = this.state.catalogo.ciudades.find((ci) => {
      return Number(comuna.idCiudad) === Number(ci.idCiudad);
    });

    if (ciudad === undefined) return 0;

    return Number(ciudad.idRegion);
  }

  /*************************
   *  PETICIONES SERVIDOR  *
   *************************/
  async enviarMisDatos(values) {
    this.setState({ loadingData: true });
    const dataEnvio = this.state.misDatos;
    const { reducer } = this.props.store;

    if (reducer.dataWizardAsegurado.coberturasCargas) {
      dataEnvio.coberturasCargas = [...reducer.dataWizardAsegurado.coberturasCargas];
    }

    // Datos de glosa de catalogos (importante si es que servicios de catálogo fallan)

    const glosaComuna =
      reducer.comunas && reducer.comunas.length
        ? reducer.comunas.find(
            ({ idComuna }) => idComuna === Number(values.comuna)
          ).glosaComuna
        : reducer.dataWizardAsegurado.comuna &&
          reducer.dataWizardAsegurado.comuna.glosa;

    const glosaCiudad =
      reducer.ciudades && reducer.ciudades.length
        ? reducer.ciudades.find(
            ({ idCiudad }) => idCiudad === Number(values.ciudad)
          ).glosaCiudad
        : reducer.dataWizardAsegurado.ciudad &&
          reducer.dataWizardAsegurado.ciudad.glosa;

    // Datos de envío
    dataEnvio.complementoDireccion = values.complemento;
    dataEnvio.comuna = {
      idComuna: Number(values.comuna),
      glosa: glosaComuna,
    };
    dataEnvio.ciudad = {
      idCiudad: Number(values.ciudad),
      glosa: glosaCiudad,
    };
    dataEnvio.region = {
      idRegion: this.obtenerRegionDeUnaCiudad(values.comuna),
    };

    dataEnvio.correoElectronico = values.email;
    dataEnvio.departamento = values.departamento;
    dataEnvio.direccion = values.direccionParticular;
    dataEnvio.numero = values.numero;
    dataEnvio.telefonoFijo = values.telefonoParticular;
    dataEnvio.telefonoMovil = values.telefonoCelular;
    dataEnvio.solicitud.idSolicitud = this.state.solicitud;

    await this.props.guardar_data_wizard_asegurado(dataEnvio);
    const res = await guardarDataWizard(dataEnvio);
    if (res) {
      notificacion("success", EXITO_MIS_DATOS);

      const { formaPago } = this.props.store.reducer.estructuraFormulario;
      if (!formaPago) {
        setTimeout(() => {
          if (this.props.store.reducer.estructuraFormulario.cargas) {
            this.props.wizard({ pagina: PAGINA_2, type: TIPO_PAGINA });
          } else {
            this.props.wizard({ pagina: PAGINA_3, type: TIPO_PAGINA });
          }
          this.props.wizard({ tab: PAGINA_1, type: TIPO_TAB });
        }, 100);
      } else {
        this.props.wizard({ tab: PAGINA_3, type: TIPO_TAB });
      }
    } else {
      notificacion("warning", ERROR_MIS_DATOS);
    }
    this.setState({ loadingData: false });
  }

  render() {
    const misDatos = this.state.misDatos;
    return (
      <React.Fragment>
        <Formik
          initialValues={{
            // DIRECCIÓN
            direccionParticular:
              misDatos && misDatos.direccion ? misDatos.direccion : "",
            numero: misDatos && misDatos.numero ? misDatos.numero : "",
            departamento:
              misDatos && misDatos.departamento ? misDatos.departamento : "",
            complemento:
              misDatos && misDatos.complementoDireccion
                ? misDatos.complementoDireccion
                : "",
            ciudad: misDatos && misDatos.ciudad ? misDatos.ciudad.idCiudad : 0,
            comuna: misDatos && misDatos.comuna ? misDatos.comuna.idComuna : 0,
            // CONTACTO
            telefonoCelular:
              misDatos && misDatos.telefonoMovil
                ? misDatos.telefonoMovil
                : "+56",
            telefonoParticular:
              misDatos && misDatos.telefonoFijo ? misDatos.telefonoFijo : "+56",
            email:
              misDatos && misDatos.correoElectronico
                ? misDatos.correoElectronico
                : "",
          }}
          validationSchema={Yup.object().shape({
            // DIRECCIÓN
            direccionParticular: Yup.string()
              .required(CAMPO_REQUERIDO_DIRECCION)
              .test("validarVacios", CAMPO_REQUERIDO_DIRECCION, (value) =>
                value ? value.trim() !== "" : false
              ),
            numero: Yup.string()
              .required(CAMPO_REQUERIDO_NUMERO)
              .test("validarVacios", CAMPO_REQUERIDO_NUMERO, (value) => {
                value ? (value = value.replace(/[^0-9]/g, "")) : (value = "");
                return value ? value.trim() !== "" : false;
              }),
            departamento: Yup.string().test((value) => {
              value ? (value = value.replace(/[^0-9]/g, "")) : (value = "");
              return value ? value.trim() !== "" : true;
            }),
            complemento: Yup.string(),
            ciudad: Yup.string()
              .required(CAMPO_REQUERIDO)
              .test("validaCiudad", VALIDACION_CIUDAD, (value) => {
                return Number(value) !== 0 ? true : false;
              }),
            comuna: Yup.string()
              .required(CAMPO_REQUERIDO)
              .test("validaComuna", VALIDACION_COMUNA, (value) => {
                return Number(value) !== 0 ? true : false;
              }),
            // CONTACTO
            telefonoCelular: Yup.string().test(
              "validaTelefono",
              VALIDACION_TELEFONO,
              (value) => {
                return formatTel(value).length === 13 ||
                  formatTel(value).length === 3
                  ? true
                  : false;
              }
            ),
            telefonoParticular: Yup.string(),
            email: Yup.string()
              .email(VALIDACION_MAIL)
              .required(CAMPO_REQUERIDO_EMAIL),
          })}
          onSubmit={async (values, { setSubmitting }) => {
            values.telefonoCelular = formatTel(values.telefonoCelular);
            this.enviarMisDatos(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            validating,
            valid,
          }) => {
            return (
              <fieldset
                disabled={
                  !this.props.store.reducer.informacionPoliza
                    .solicitudIncorporacion &&
                  !this.props.store.reducer.informacionPoliza.asegurable_5_50
                }
              >
                <Form
                  id="formularioDireccion"
                  name="mi-direccion-form"
                  onKeyDown={(e) => {
                    if ((e.charCode || e.keyCode) === 13) {
                      e.preventDefault();
                    }
                  }}
                >
                  <div
                    id="miDireccion"
                    className="cuerpo-wizard pt-3 pb-5 o-white container"
                  >
                    <div className="row mx-5 animated fadeInRight">
                      <div className="col-12">
                        <h5 className="font-weight-bold text-primary">
                          Dirección
                        </h5>
                        <hr className="mt-1" />
                      </div>

                      {/* dirccionParticular */}
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Dirección particular{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <Field
                            className="form-control form-control-sm"
                            id="direccionParticular"
                            name="direccionParticular"
                            type="text"
                            maxLength="100"
                            placeholder="Ingrese dirección particular"
                            value={validacionDireccion(values.direccionParticular)}
                          />
                          {errors.direccionParticular &&
                            touched.direccionParticular && (
                              <small className="text-danger animated fadeIn">
                                {errors.direccionParticular}
                              </small>
                            )}
                        </div>
                      </div>

                      {/* numero */}
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Número <span className="text-danger">*</span>
                          </label>
                          <Field
                            className="form-control form-control-sm"
                            id="numero"
                            name="numero"
                            type="text"
                            maxLength="10"
                            placeholder="Ingrese número"
                            value={formatNumber(values.numero)}
                          />
                          {errors.numero && touched.numero && (
                            <small className="text-danger animated fadeIn">
                              {errors.numero}
                            </small>
                          )}
                        </div>
                      </div>

                      {/* departamento */}
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Departamento</label>
                          <Field
                            className="form-control form-control-sm"
                            id="departamento"
                            name="departamento"
                            type="text"
                            maxLength="20"
                            placeholder="Ingrese departamento"
                            value={soloLetrasYNumeros(values.departamento)}
                          />
                          {errors.departamento && touched.departamento && (
                            <small className="text-danger animated fadeIn">
                              {errors.departamento}
                            </small>
                          )}
                        </div>
                      </div>

                      {/* complemento */}
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Sector/Villa/Población</label>
                          <Field
                            className="form-control form-control-sm"
                            id="complemento"
                            name="complemento"
                            type="text"
                            maxLength="100"
                            placeholder="Ingrese sector/villa/población"
                            value={soloLetrasNumerosYAmpersand(values.complemento)}
                          />
                          {errors.complemento && touched.complemento && (
                            <small className="text-danger animated fadeIn">
                              {errors.complemento}
                            </small>
                          )}
                        </div>
                      </div>

                      {/* ciudad */}
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Ciudad <span className="text-danger">*</span>
                          </label>
                          <select
                            id="ciudad"
                            name="ciudad"
                            className="form-control form-control-sm"
                            style={{ display: "block" }}
                            value={values.ciudad}
                            onChange={(e) => {
                              handleChange(e);
                              this.obtenerComunasDeUnaCiudad(e.target.value);
                            }}
                            onBlur={handleBlur}
                            onClick={() =>
                              this.notificarSinOpciones(
                                this.state.catalogo.ciudades
                              )
                            }
                          >
                            {this.state.catalogo.ciudades &&
                            this.state.catalogo.ciudades.length ? (
                              <Fragment>
                                <option value="0"> Seleccione ciudad</option>
                                {this.state.catalogo.ciudades.map((item, i) => (
                                  <option
                                    key={`opcion_parentesco_${i}`}
                                    value={item.idCiudad}
                                  >
                                    {item.glosaCiudad}
                                  </option>
                                ))}
                              </Fragment>
                            ) : this.state.misDatos.ciudad &&
                              this.state.misDatos.ciudad.idCiudad ? (
                              <option
                                value={this.state.misDatos.ciudad.idCiudad}
                              >
                                {this.state.misDatos.ciudad.glosa}
                              </option>
                            ) : (
                              <option value="0"> Seleccione ciudad</option>
                            )}
                          </select>
                          {errors.ciudad && touched.ciudad && (
                            <small className="text-danger animated fadeIn animated fadeIn">
                              {errors.ciudad}
                            </small>
                          )}
                        </div>
                      </div>

                      {/* comuna */}
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Comuna <span className="text-danger">*</span>
                          </label>
                          <select
                            id="comuna"
                            name="comuna"
                            disabled={
                              this.state.catalogo.comunasCiudad.length === 0 &&
                              this.props.store.reducer.comunas.length > 0
                            }
                            className="form-control form-control-sm"
                            style={{ display: "block" }}
                            value={values.comuna}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            onClick={() =>
                              this.notificarSinOpciones(
                                this.state.catalogo.comunasCiudad
                              )
                            }
                          >
                            {this.state.catalogo.comunasCiudad &&
                            this.state.catalogo.comunasCiudad.length ? (
                              <Fragment>
                                <option value="0"> Seleccione comuna</option>
                                {this.state.catalogo.comunasCiudad.map(
                                  (item, i) => (
                                    <option
                                      key={`opcion_parentesco_${i}`}
                                      value={item.idComuna}
                                    >
                                      {item.glosaComuna}
                                    </option>
                                  )
                                )}
                              </Fragment>
                            ) : this.state.misDatos.comuna &&
                              this.state.misDatos.comuna.idComuna ? (
                              <option
                                value={this.state.misDatos.comuna.idComuna}
                              >
                                {this.state.misDatos.comuna.glosa}
                              </option>
                            ) : (
                              <option value="0"> Seleccione comuna</option>
                            )}
                          </select>
                          {errors.comuna && touched.comuna && (
                            <small className="text-danger animated fadeIn animated fadeIn">
                              {errors.comuna}
                            </small>
                          )}
                        </div>
                      </div>

                      {/* CONTACTO */}
                      <div className="col-12 mt-3">
                        <h5 className="font-weight-bold text-primary">
                          Contacto
                        </h5>
                        <hr className="mt-1" />
                      </div>

                      {/* telefonoCelular */}
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Teléfono celular{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <Field
                            id="telefonoCelular"
                            name="telefonoCelular"
                            className="form-control form-control-sm"
                            maxLength="13"
                            value={formatTel(values.telefonoCelular)}
                          />
                          {errors.telefonoCelular &&
                            touched.telefonoCelular && (
                              <small className="text-danger animated fadeIn">
                                {errors.telefonoCelular}
                              </small>
                            )}
                        </div>
                      </div>

                      {/* telefonoParticular */}
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Teléfono particular</label>
                          <Field
                            id="telefonoParticular"
                            name="telefonoParticular"
                            className="form-control form-control-sm"
                            maxLength="13"
                            value={formatTel(values.telefonoParticular)}
                          />
                          {errors.telefonoParticular &&
                            touched.telefonoParticular && (
                              <small className="text-danger animated fadeIn">
                                {errors.telefonoParticular}
                              </small>
                            )}
                        </div>
                      </div>

                      {/* email */}
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>
                            Email <span className="text-danger">*</span>
                          </label>
                          <Field
                            className="form-control form-control-sm"
                            id="email"
                            name="email"
                            type="text"
                            placeholder="tucorreo@gmail.com"
                            maxLength="80"
                            value={values.email}
                          />
                          {errors.email && touched.email && (
                            <small className="text-danger animated fadeIn">
                              {errors.email}
                            </small>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Botonera */}
                  <div className="button-zone text-right pt-3 pb-3 ml-auto">
                    <button
                      id="botonSiguiente"
                      className="btn btn-principal btn-lg"
                      type="submit"
                      disabled={this.state.loadingData}
                    >
                      {this.state.loadingData ? (
                        <Fragment>
                          <i className="fa fa-spinner fa-pulse fa-fw"></i>
                          <span className="sr-only">Loading...</span>
                        </Fragment>
                      ) : (
                        <span>Siguiente</span>
                      )}
                    </button>
                  </div>
                </Form>
              </fieldset>
            );
          }}
        </Formik>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state,
});

export default withRouter(
  connect(mapStateToProps, { guardar_data_wizard_asegurado, wizard })(Direccion)
);
