import React from "react";
import Select from "react-select";
import { ID_ZERO, VALOR_ZERO, VALOR_NEGATIVO, VALOR_UNO, TITULO_GIRO, TITULO_SUCURSAL } from "../../../utils/Constantes";
import { LABEL_SUCURSAL, LABEL_NUEVA_SUCURSAL } from "../../../utils/ConstantesVentaDirecta";

const styles = {
  option: (styles, state) => {
    if (state.isSelected) {
      return {
        ...styles,
        backgroundColor: state.isSelected ? "#ed8d00" : null,
        color: state.isSelected ? "white" : null,
      };
    } else {
      return {
        ...styles,
        padding: "5px 10px",
      };
    }
  },
};
export class MySelect extends React.Component {
  handleChange = (value) => {
    this.props.onChange(this.props.titulo, value);
  };

  handleBlur = () => {
    this.props.onBlur(this.props.titulo, true);
  };

  obtenerData() {
    if (this.props.tipoData === TITULO_GIRO) {
      return this.props.data
        .filter((data) => data.idGiro !== ID_ZERO)
        .map((data) => {
          return { value: data.idGiro, label: data.glosa };
        });
    } else if (this.props.tipoData === TITULO_SUCURSAL) {
      let data = this.props.data.map((data, index) => {
        return {
          value: `${data.idOrganizacionSucursal}`,
          label: `${index+VALOR_UNO}• ${data.nombreSucursal} / ${data.direccion} #${data.numero} / ${data.nombreCompleto}`,
        };
      });
      data.unshift({ value: VALOR_ZERO, label: LABEL_NUEVA_SUCURSAL });
      data.unshift({ value: VALOR_NEGATIVO, label: LABEL_SUCURSAL });
      return data;
    }
  }

  obtenerValue() {
    if (this.props.tipoData === TITULO_GIRO) {
      if (this.props.value.idGiro !== "") {
        if (this.props.value.glosa) {
          return {
            value: this.props.value.idGiro,
            label: this.props.value.glosa,
          };
        } else {
          return this.props.value;
        }
      }
    } else if (this.props.tipoData === TITULO_SUCURSAL) {
      return this.props.value;
    }
  }

  render() {
    let data = this.obtenerData();
    let value = this.obtenerValue();
    return (
      <div>
        <Select
          id="giro"
          name="giro"
          isSearchable={true}
          className="basic-single"
          classNamePrefix="select"
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          value={value}
          options={data}
          styles={styles}
          placeholder={this.props.titulo ? `Ingrese ${this.props.titulo}` : ""}
          isDisabled={this.props.disabledText ? this.props.disabledText : false}
        />

        {!!this.props.error && this.props.touched && (
          <small className="text-danger animated fadeIn">
            {this.props.error}
          </small>
        )}
      </div>
    );
  }
}
