import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

const calendario = require("../../../../../assets/images/calendario.svg");

export class MonthBox extends Component {
    constructor(props, context) {
      super(props, context);
      this.state = {
        value: this.props.store.reducer.periodoNegocio || this.props.value 
      }; 
  
      this._handleClick = this._handleClick.bind(this);
    }

    render() { 
      return (
        <div className="text-center cursor" onClick={this._handleClick}>
          <span
            className="d-block font-weight-bold mb-2 d-block h6 text-primary"
            style={{ fontSize: "14px" }}
          >
            {this.state.value.split(".")[0].substr(0, 3)}.{" "}
            {this.state.value.split(".")[1]}
          </span>
          <img
            src={calendario}
            style={{ width: "40px" }}
            alt="Período"
            title="Seleccionar período"
          />
        </div>
      );
    }
  
    _handleClick(e) {
      this.props.onClick && this.props.onClick(e);
    }
  }
  
  const mapStateToProps = state => ({
    store: state
  });
  
  export default withRouter(connect(mapStateToProps, { })(MonthBox));