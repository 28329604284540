import ServicesFactory from "../config/servicesFactory";
import { COD_STATUS_EXITO, POSICION_INICIAL, STATUS_EXITO } from "../utils/Constantes";

let services = new ServicesFactory();

export async function obtenerRegiones() {
  try {

    let res = await services.get(
      `${process.env.REACT_APP_BUS_URL_BACKMOVIL}/geografica/listarRegiones`,
      false,
      true
    );
    return res !== undefined &&
      res !== null &&
      res.status === STATUS_EXITO &&
      res.data.status.codigo === COD_STATUS_EXITO
      ? validarEstados(res.data.objeto)
      : [];
  } catch (error) {
    return [];
  }
}

export async function obtenerCiudades() {
  try {
    let res = await services.get(
      `${process.env.REACT_APP_BUS_URL_BACKMOVIL}/geografica/listarCiudad`,
      false,
      true
    );
    return res !== undefined &&
      res !== null &&
      res.status === STATUS_EXITO &&
      res.data.status.codigo === COD_STATUS_EXITO
      ? validarEstados(res.data.objeto)
      : [];
  } catch (error) {
    return [];
  }
}

export async function obtenerComunas() {
  try {
    let res = await services.get(
      `${process.env.REACT_APP_BUS_URL_BACKMOVIL}/geografica/listarComuna`,
      false,
      true
    );
    return res !== undefined &&
      res !== null &&
      res.status === STATUS_EXITO &&
      res.data.status.codigo === COD_STATUS_EXITO
      ? validarEstados(res.data.objeto)
      : [];
  } catch (error) {
    return [];
  }
}

export async function obtenerCanalesVenta() {
  try {
    let res = await services.get(
      `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/catalogo/listarCanalVenta`,
      false,
      true
    );
    return res !== undefined &&
      res !== null &&
      res.status === STATUS_EXITO &&
      res.data.status.codigo === COD_STATUS_EXITO
      ? res.data.objeto
      : [];
  } catch (error) {
    return [];
  }
}

export async function listarTiposCapital() {
  try {
    let res = await services.get(
      `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/catalogo/listarTipoCapital`,
      false,
      true
    );
    return res !== undefined &&
      res !== null &&
      res.status === STATUS_EXITO &&
      res.data.status.codigo === COD_STATUS_EXITO
      ? res.data.objeto
      : [];
  } catch (error) {
    return [];
  }
}

export async function listarSoftwareAlianza() {
  try {
    let res = await services.get(
      `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/catalogo/listarSoftwareAlianza`,
      false,
      true
    );
    return res !== undefined &&
      res !== null &&
      res.status === STATUS_EXITO &&
      res.data.status.codigo === COD_STATUS_EXITO
      ? res.data.objeto
      : [];
  } catch (error) {
    return [];
  }
}

export async function listarEmpresasBUK(queryParams = '') {
  try {
    let res = await services.get(
      `${process.env.REACT_APP_API_GATEWAY_BUK_URL}/empresa/listar${queryParams}`,
      false,
      true
    );
    return res !== undefined &&
      res !== null &&
      res.status === STATUS_EXITO &&
      res.data.status.codigo === COD_STATUS_EXITO
      ? res.data.objeto
      : [];
  } catch (error) {
    return [];
  }
}

export async function listarEmpleadosBUK(queryParams) {
  try {
    let res = await services.get(
      `${process.env.REACT_APP_API_GATEWAY_BUK_URL}/empleado/listar?${queryParams}`,
      false,
      true
    );
    return res !== undefined &&
      res !== null &&
      res.status === STATUS_EXITO &&
      res.data.status.codigo === COD_STATUS_EXITO
      ? res.data.objeto
      : res;
  } catch (error) {
    return error;
  }
}

export async function obtenerJefaturaComercial() {
  try {
    let res = await services.get(
      `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/catalogo/listarJefaturaComercial`,
      false,
      true
    );
    return res !== undefined &&
      res !== null &&
      res.status === STATUS_EXITO &&
      res.data.status.codigo === COD_STATUS_EXITO
      ? res.data.objeto
      : [];
  } catch (error) {
    return [];
  }
}

export function validarEstados(data) {
  return data[POSICION_INICIAL].hasOwnProperty('estado')
    ? data.filter(dato => dato.estado)
    : data;
}
