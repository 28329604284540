import { Component } from "react";
import { connect } from "react-redux";

class Authorized extends Component {
  render() {
    const { usuarioSesion } = this.props.store.auth;
    const { roles, children } = this.props;

    return this.props !== null && usuarioSesion !== null
      ? typeof roles !== "object"
        ? usuarioSesion.rol !== null && usuarioSesion.rol.codigo === roles
          ? children
          : null
        : roles.includes(usuarioSesion.rol.codigo)
        ? children
        : null
      : null;
  }
}

const mapStateToProps = state => ({
  store: state
});

export default connect(
  mapStateToProps,
  {}
)(Authorized);
