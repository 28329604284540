import ServicesFactory from "../config/servicesFactory";
import { CODIGO_ESTATUS_ERROR, CODIGO_ESTATUS_NO_APLICA, COD_STATUS_EXITO, STATUS_EXITO } from "../utils/Constantes";

let servicios = new ServicesFactory();

export async function listarPorNroPolizaNroRenovacion(
  nroPoliza,
  nroRenovacion
) {
  try {
    let url = `${process.env.REACT_APP_BUS_URL_POLIZA}/listarPorNroPolizaNroRenovacion/${nroPoliza}/intNroRenovacion/${nroRenovacion}`;
    let respuesta = await servicios.get(url, false);
    return respuesta !== undefined &&
      respuesta !== null &&
      respuesta.status === STATUS_EXITO &&
      respuesta.data.status.codigo === COD_STATUS_EXITO
      ? respuesta.data.objeto
      : null;
  } catch (error) {
    return null;
  }
}

export async function listarPorRut(strRut) {
  try {
    let url = `${process.env.REACT_APP_BUS_URL_POLIZA}/listarPorRut/${strRut}`;
    let respuesta = await servicios.get(url, false);
    return respuesta !== undefined &&
      respuesta !== null &&
      respuesta.status === STATUS_EXITO &&
      respuesta.data.status.codigo === COD_STATUS_EXITO
      ? respuesta.data.objeto
      : null;
  } catch (error) {
    return null;
  }
}

export async function listarDetallePorRut(data) {
  try {
    let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/negocio/listar`;
    let res = await servicios.post(url, data, false);
    return res !== undefined &&
      res !== null &&
      res.status === STATUS_EXITO &&
      res.data.status.codigo === COD_STATUS_EXITO
      ? res.data.objeto
      : res.data.status.codigo === CODIGO_ESTATUS_NO_APLICA
        ? res.data.objeto
        : CODIGO_ESTATUS_ERROR;
  } catch (error) {
    return "004";
  }
}

export async function crearUsuarioRevisor(data) {
  try {
    let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/usuariorevisor/crear`;
    let res = await servicios.post(url, data, false);
    return res !== undefined &&
      res !== null &&
      res.data.status.codigo === COD_STATUS_EXITO
      ? res.data.objeto
      : null;
  } catch (error) {
    return null;
  }
}

export async function actualizarUsuarioRevisor(data) {
  try {
    let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/usuariorevisor/actualizar`;
    let res = await servicios.put(url, data, false);

    return res !== undefined &&
      res !== null &&
      res.data.status.codigo === COD_STATUS_EXITO
      ? res.data.objeto
      : res.data.status.codigo;
  } catch (error) {
    return null;
  }
}
