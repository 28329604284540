import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
const { format } = require("rut.js");

class Cargas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataWizard: this.props.store.reducer.dataWizardAsegurado,
    };
  }

  findGenero = (id) => {
    let genero = this.props.store.reducer.generos.filter((a) => {
      return a.idGenero === parseInt(id);
    })[0];

    return genero !== undefined ? genero : { descripcion: "" };
  };

  findParentesco = (id) => {
    let parentesco = this.props.store.reducer.parentescos.filter((a) => {
      return a.idParentesco === parseInt(id);
    })[0];

    return parentesco !== undefined ? parentesco : { glosa: "" };
  };
  findIsapre = (id) => {    
    let isapres = this.props.store.reducer.previsionSalud.filter(
      (a) => a.idIsapre === parseInt(id)
    )[0];
    return isapres !== undefined ? isapres : { glosa: "" };
  };

  render() {
    const { cargas } = this.state.dataWizard;    
    return (
      <React.Fragment>
        <h6 className="bg-secondary text-white mt-3 p-2 linea-subtitulo font-weight-bold">
            CARGAS
        </h6>
        <h6 className="font-weight-bold">
          Cargas ({cargas !== null ? cargas.length : "0"})
        </h6>
        {cargas !== null && cargas.length !== 0 ? (
          <div>
            {cargas
              .filter((a) => a.vigente)
              .map((item, i) => {
                return (
                  <div className="row mt-2" key={i}>
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="card-body">
                          <div className="card-text">
                            <div className="row">
                              <div className="col-12 col-md-6 col-lg-3">
                                <div className="row">
                                  <div className="col-6 col-md-12 font-weight-bold text-muted">
                                    RUT
                                  </div>
                                  <div className="col-6 col-md-12">
                                    {format(`${item.rut}-${item.digitoVerificador}`)}
                                  </div>
                                </div>
                              </div>

                              <div className="col-12 col-md-6 col-lg-3">
                                <div className="row">
                                  <div className="col-6 col-md-12 font-weight-bold text-muted">
                                    Nombre completo
                                  </div>
                                  <div className="col-6 col-md-12">
                                    {`${item.nombre} ${item.apellidoPaterno} ${item.apellidoMaterno}`}
                                  </div>
                                </div>
                              </div>

                              <div className="col-12 col-md-6 col-lg-3">
                                <div className="row">
                                  <div className="col-6 col-md-12 font-weight-bold text-muted">
                                    Parentesco
                                  </div>
                                  <div className="col-6 col-md-12">
                                    {this.findParentesco(
                                      item.parentesco.idParentesco
                                    ).glosa.toUpperCase()}
                                  </div>
                                </div>
                              </div>

                              <div className="col-12 col-md-6 col-lg-3">
                                <div className="row">
                                  <div className="col-6 col-md-12 font-weight-bold text-muted">
                                    Fecha de Nacimiento
                                  </div>
                                  <div className="col-6 col-md-12">
                                    {moment(item.fechaNacimiento).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row mt-2">
                              <div className="col-12 col-md-6 col-lg-3">
                                <div className="row">
                                  <div className="col-6 col-md-12 font-weight-bold text-muted">
                                    Salud Previsional
                                  </div>
                                  <div className="col-6 col-md-12">
                                    {this.findIsapre(
                                      item.sistemaPrevisionSalud
                                        .idSistemaPrevisionSalud
                                    ).glosa.toUpperCase()}
                                  </div>
                                </div>
                              </div>

                              <div className="col-12 col-md-6 col-lg-3">
                                <div className="row">
                                  <div className="col-6 col-md-12 font-weight-bold text-muted">
                                    Peso
                                  </div>
                                  <div className="col-6 col-md-12">
                                    {item.peso} Kg
                                  </div>
                                </div>
                              </div>

                              <div className="col-12 col-md-6 col-lg-3">
                                <div className="row">
                                  <div className="col-6 col-md-12 font-weight-bold text-muted">
                                    Estatura
                                  </div>
                                  <div className="col-6 col-md-12">
                                    {item.estatura} cm
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        ) : (
          <p className="text-center mt-3">No se han registrado cargas</p>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state,
});

export default withRouter(connect(mapStateToProps, {})(Cargas));
