const LOADING = "LOADING";
const OBTENER_MIS_SOLICITUDES = "OBTENER_MIS_SOLICITUDES";

//SOFTWARE ALIANZA
const EXISTEN_EMPLEADOS_SOFTWARE_ALIANZA = "EXISTEN_EMPLEADOS_SOFTWARE_ALIANZA";
const PRIMERA_RENDERIZACION_TABLA = "PRIMERA_RENDERIZACION_TABLA";
const ASEGURABLES_FILTRO_FECHA = "ASEGURABLES_FILTRO_FECHA";
const ASEGURABLES_FILTRO_FECHA_ORIGINAL = "ASEGURABLES_FILTRO_FECHA_ORIGINAL";
const POLIZAS_CONTRATANTE = "POLIZAS_CONTRATANTE";
const PARAMETROS_CARGA_SOFTWARE_RRHH = "PARAMETROS_CARGA_SOFTWARE_RRHH";
const ASEGURABLES_FINALES_SOFTWARE_ALIANZA = "ASEGURABLES_FINALES_SOFTWARE_ALIANZA";


const POLIZAS_SELECCIONADAS = "POLIZAS_SELECCIONADAS";
const FECHA_VIGENCIA_POLIZA = "FECHA_VIGENCIA_POLIZA";
const AGREGAR_POLIZA_SOFTWARE_ALIANZA = "AGREGAR_POLIZA_SOFTWARE_ALIANZA";

const ASEGURABLES_SELECCIONADOS_SOFTWARE_ALIANZA = "ASEGURABLES_SOFTWARE_ALIANZA";

const initialState = {
  loading: false,
  asegurables: [],
  softwareAlianza:{
    existenEmpleados: false,
    primeraRenderizacionTabla: true,
    agregarPolizaAsegurablesSotwareAlianza: false,
    asegurablesFinales: [],
    polizasSeleccionadas: [],
    polizasContratante: [],
    respuestaAsegurablesFiltroFecha:[],
    respuestaAsegurablesFiltroFechaOriginal: [],
    asegurablesSeleccionadosSoftwareAlianza: [],
    fechaVigenciaPolizaCargaSofware: null,
    parametrosCargaSofwareRRHH: null
  }
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: action.payload
      }
    case OBTENER_MIS_SOLICITUDES:
      return {
        ...state,
        asegurables: action.payload,
        loading: false
      }


    //SOFTWARE ALIANZA
    case POLIZAS_SELECCIONADAS:
      return {
        ...state,
        softwareAlianza: {
          ...state.softwareAlianza,
          polizasSeleccionadas: action.payload
        }
      }

    case FECHA_VIGENCIA_POLIZA:
      return {
        ...state,
        softwareAlianza: {
          ...state.softwareAlianza,
          fechaVigenciaPolizaCargaSofware: action.payload
        }
      }

    case AGREGAR_POLIZA_SOFTWARE_ALIANZA:
      return {
        ...state,
        softwareAlianza: {
          ...state.softwareAlianza,
          agregarPolizaAsegurablesSotwareAlianza: action.payload
        }
      }

    case ASEGURABLES_SELECCIONADOS_SOFTWARE_ALIANZA:
      return {
        ...state,
        softwareAlianza: {
          ...state.softwareAlianza,
          asegurablesSeleccionadosSoftwareAlianza: action.payload
        }
      }

    case POLIZAS_CONTRATANTE:
      return {
        ...state,
        softwareAlianza: {
          ...state.softwareAlianza,
          polizasContratante: action.payload
        }
      }

    case EXISTEN_EMPLEADOS_SOFTWARE_ALIANZA:
      return {
        ...state,
        softwareAlianza: {
          ...state.softwareAlianza,
          existenEmpleados: action.payload
        }
      }

    case PRIMERA_RENDERIZACION_TABLA:
      return {
        ...state,
        softwareAlianza: {
          ...state.softwareAlianza,
          primeraRenderizacionTabla: action.payload
        },
      }

    case ASEGURABLES_FINALES_SOFTWARE_ALIANZA:
      return {
        ...state,
        softwareAlianza: {
          ...state.softwareAlianza,
          asegurablesFinales: action.payload
        }
      }

    case ASEGURABLES_FILTRO_FECHA:
      return {
        ...state,
        softwareAlianza: {
          ...state.softwareAlianza,
          respuestaAsegurablesFiltroFecha: action.payload
        },
      }

      case ASEGURABLES_FILTRO_FECHA_ORIGINAL:
        return {
          ...state,
          softwareAlianza: {
            ...state.softwareAlianza,
            respuestaAsegurablesFiltroFechaOriginal: action.payload
          },
        }
      case PARAMETROS_CARGA_SOFTWARE_RRHH:
        return {
          ...state,
          softwareAlianza: {
            ...state.softwareAlianza,
            parametrosCargaSofwareRRHH: action.payload
          },
        }
    default:
      return state;
  }
}

///ACTIONS


export const loadingAction = (value) => async (dispatch, getState) => {
  dispatch({
    type: LOADING,
    payload: value
  });  
};

export const obtenerMisSolicitudesAction = (data) => (dispatch, getState) => {
  dispatch({
    type: OBTENER_MIS_SOLICITUDES,
    payload: data
  });  
};


export const guardarAsegurablesFiltroFecha = (payload) => {
  return {
    type: ASEGURABLES_FILTRO_FECHA,
    payload: payload,
  };
};

export const guardarAsegurablesFiltroOriginal = (payload) => {
  return {
    type: ASEGURABLES_FILTRO_FECHA_ORIGINAL,
    payload: payload,
  };
};

export const guardarSiEsPrimerRenderizadoTabla = (payload) => {
  return {
    type: PRIMERA_RENDERIZACION_TABLA,
    payload: payload,
  };
};

export const guardarExistenEmpleadosSoftwareAlianza = (payload) => {
  return {
    type: EXISTEN_EMPLEADOS_SOFTWARE_ALIANZA,
    payload: payload,
  };
};


export const guardarAsegurablesFinalesSoftwareAlianza = (payload) => {
  return {
    type: ASEGURABLES_FINALES_SOFTWARE_ALIANZA,
    payload: payload,
  };
};


export const guardarAsegurableSeleccionadosPorSoftwareAlianza = (payload) => {
  return {
    type: ASEGURABLES_SELECCIONADOS_SOFTWARE_ALIANZA,
    payload: payload,
  };
};

export const agregarPolizaSoftwareAlianza = (payload) => {
  return {
    type: AGREGAR_POLIZA_SOFTWARE_ALIANZA,
    payload: payload,
  }
};

export const guardarFechaVigenciaPoliza = (payload) => {
  return {
    type: FECHA_VIGENCIA_POLIZA,
    payload: payload,
  };
};

export const guardarPolizasSeleccionadas = (payload) => {
  return {
    type: POLIZAS_SELECCIONADAS,
    payload: payload,
  };
};

export const guardarPolizasContratante = (payload) => {
  return {
    type: POLIZAS_CONTRATANTE,
    payload: payload,
  };
};

export const guardarParametrosCargaSoftwareRRHH = (payload) => {
  return {
    type: PARAMETROS_CARGA_SOFTWARE_RRHH,
    payload: payload,
  };
};

