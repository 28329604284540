import ServicesFactory from "../config/servicesFactory";
import {
  ROL_USUARIO_ASEGURADO,
  ROL_USUARIO_CORREDOR,
  ROL_USUARIO_DELEGADO,
  ROL_USUARIO_EJECUTIVO,
  ROL_USUARIO_JEFATURA,
  ROL_USUARIO_EJECUTIVO_MANTENCION,
  ROL_USUARIO_EJECUTIVO_COMPLETO,
  COD_JEFATURA,
  COD_EJECUTIVO,
  COD_DELEGADO,
  COD_ASEGURADO,
  COD_EJECUTIVO_COMPLETO,
  COD_STATUS_EXITO,
  COD_ERROR_EXCEPCION_PETICION,
  COD_CORREDOR,
  COD_EJECUTIVO_MANTENCION,
  COD_ERROR_PETICION_SERVIDOR,
  PERFIL_LDAP,
  PERFIL_ASEGURABLE,
  PERFIL_CORREDOR,
  PERFIL_DELEGADO,
  PERFIL_DELEGADO_MANTENCION,
  PERFIL_CORREDOR_COMERCIAL,
  OBJETO_SESION,
  FALSO,
  VERDADERO,
  NOTIFICACION_TIPO_ERROR,
  ERROR_SOLICITUD,
  ERROR_PETICION_SERVIDOR,
  COD_STATUS_ERROR,
  ERROR_CREDENCIALES_INCORRECTAS,
  ERROR_TOKEN_SESION,
  COD_STATUS_NA,
  STRING_VACIO,
  EXPRESION_REGULAR_PUNTO,
  ROL_INTERNO,
  ERROR_INGRESO_CREDENCIALES,
  ERROR_DIGITO_VERIFICADOR,
  NOTIFICACION_TIPO_WARN,
  VALOR_SEGUNDO_EN_MILISEGUNDOS,
  SESION_TIEMPO_SELECTOR_SEGUNDOS,
  SESION_TIEMPO_CLASE_TIEMPO,
  COLOR_AZUL_OSCURO,
  COLOR_NARANJA,
  SESION_TIEMPO_TEXTO_CERRAR,
  SESION_TIEMPO_TEXTO_REACTIVAR,
  NO_DECIMALES,
  SESION_TIEMPO_ALERTA_TEXTO,
  SESION_TIEMPO_ALERTA_TITULO,
  PRIMERA_POSICION,
  TAMANO_UNO,
  LARGO_LISTA_VACIO,
  ERROR_NO_PERFILES,
  TIPO_INCORPORACION_MANTENCION,
  TIPO_INCORPORACION_NUEVO_NEGOCIO,
  ERROR_TOKEN_GATEWAY,
  ERROR_TOKEN_API,
  RUTA_INICIAR_SESION_LDAP_CON_PERFIL,
} from "../utils/Constantes";
import { procesarMenu } from "../utils/Menu";
import Swal from "sweetalert2";

let services = new ServicesFactory();
const { format, validate } = require("rut.js");

async function iniciarusuarioSesionInterno(objPeticion, strTokenSesion) {
  try {
    let strUrl = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}${RUTA_INICIAR_SESION_LDAP_CON_PERFIL}`;
    let objRespuesta = await services.post(
      strUrl,
      objPeticion,
      FALSO,
      strTokenSesion
    );

    return objRespuesta && objRespuesta.data.status.codigo === COD_STATUS_EXITO
      ? configurarusuarioSesionInterno(objRespuesta.data.objeto)
      : objRespuesta.data.status;
  } catch (objError) {
    return COD_ERROR_EXCEPCION_PETICION;
  }
}

async function iniciarusuarioSesionDelegadoCorredor(
  objPeticion,
  strTokenSesion
) {
  try {
    let strUrl = `${process.env.REACT_APP_API_GATEWAY_SERVPERFIL}/auth/empresa/iniciarSession`;
    let objRespuesta = await services.post(
      strUrl,
      objPeticion,
      FALSO,
      strTokenSesion
    );

    return objRespuesta && objRespuesta.data.status.codigo === COD_STATUS_EXITO
      ? configurarusuarioSesionDelegadoCorredor(objRespuesta.data.objeto)
      : objRespuesta.data;
  } catch (objError) {
    return COD_ERROR_PETICION_SERVIDOR;
  }
}

async function iniciarusuarioSesionAsegurado(objPeticion, strTokenSesion) {
  try {
    let strUrl = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/authAsegurable/iniciarSession`;
    let objRespuesta = await services.post(
      strUrl,
      objPeticion,
      FALSO,
      strTokenSesion
    );

    return objRespuesta && objRespuesta.data.status.codigo === COD_STATUS_EXITO
      ? configurarusuarioSesionAsegurado(objRespuesta.data.objeto)
      : objRespuesta.data;
  } catch (objError) {
    return COD_ERROR_PETICION_SERVIDOR;
  }
}

export async function cerrarSesion(intIdPerfil) {
  try {
    let strUrl;
    switch (intIdPerfil) {
      case COD_JEFATURA:
      case COD_EJECUTIVO:
      case COD_EJECUTIVO_MANTENCION:
      case COD_EJECUTIVO_COMPLETO:
        strUrl = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/auth/cerrarSessionLdap`;
        break;
      case COD_DELEGADO:
      case COD_CORREDOR:
        strUrl = `${process.env.REACT_APP_API_GATEWAY_BACKMOVIL_URL}/auth/cerrarSesion`;
        break;
      default: //COD_ASEGURADO
        strUrl = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/authAsegurable/cerrarSession`;
        break;
    }
    let objRespuesta = await services.put(strUrl, {}, FALSO);
    return objRespuesta ? objRespuesta.data : COD_ERROR_PETICION_SERVIDOR;
  } catch (objError) {
    return COD_ERROR_PETICION_SERVIDOR;
  }
}

export async function buscarPuertaEnlaceToken() {
  try {
    const strUrl = `${process.env.REACT_APP_SERVLET_URL}`;
    let objRespuesta = await services.get(strUrl, FALSO);
    return objRespuesta
      ? objRespuesta.data.map.AccessToken.myArrayList[PRIMERA_POSICION]
      : null;
  } catch (objError) {
    return null;
  }
}

async function buscarAuthToken(strRutUsuario, strTokenPuertaEnlace) {
  try {
    const strUrl = `/auth/crearToken`;
    let objRespuesta = await services.post(
      strUrl,
      strRutUsuario,
      VERDADERO,
      strTokenPuertaEnlace
    );
    return objRespuesta ? objRespuesta.data : null;
  } catch (objError) {
    return null;
  }
}

async function buscarAuthTokenAsegurable(strRutUsuario, strTokenPuertaEnlace) {
  try {
    const strUrl = `/auth/asegurable/crearToken`;
    let objRespuesta = await services.post(
      strUrl,
      strRutUsuario,
      VERDADERO,
      strTokenPuertaEnlace
    );
    return objRespuesta ? objRespuesta.data : null;
  } catch (objError) {
    return null;
  }
}

async function buscarAuthTokenLdap(objPeticion, strTokenPuertaEnlace) {
  try {
    const strUrl = `/auth/ldap/crearToken`;
    let objRespuesta = await services.post(
      strUrl,
      objPeticion,
      VERDADERO,
      strTokenPuertaEnlace
    );
    return objRespuesta ? objRespuesta.data : null;
  } catch (objError) {
    return null;
  }
}

export async function enviarPerfilDelegado(objData) {
  try {
    let objRespuesta = await services.post(
      `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/auth/crearUsuarioConPerfil`,
      objData,
      FALSO
    );
    return objRespuesta !== undefined &&
      objRespuesta !== null &&
      objRespuesta.data.status.codigo === "001"
      ? objRespuesta.data.objeto
      : null;
  } catch (objError) {
    return null;
  }
}

export async function crearPerfilDelegadoMantencion(objData) {
  try {
    await services.post(
      `${process.env.REACT_APP_API_GATEWAY_SERVPERFIL}/perfil/session/crear`,
      objData,
      FALSO,
      null
    );
  } catch (objError) {
    return null;
  }
}

async function iniciarSesionExterno(strRut, strToken, strTokenBearer) {
  try {
    let strUrl = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/auth/buscarSesionPorRut/${strRut}/hashToken/${strToken}`;
    let objRespuesta = await services.get(strUrl, FALSO, null, strTokenBearer);

    return objRespuesta && objRespuesta.data.status.codigo === COD_STATUS_EXITO
      ? configurarSesionExterno(objRespuesta.data.objeto)
      : objRespuesta.data;
  } catch (objError) {
    return COD_ERROR_PETICION_SERVIDOR;
  }
}

function validarRespuestaSesion(objInicioSesion, strToken) {
  let objRespuesta = null;

  if (!objInicioSesion)
    return (objRespuesta = {
      tipoNotificacion: NOTIFICACION_TIPO_ERROR,
      error: ERROR_SOLICITUD,
    });

  if (objInicioSesion === COD_ERROR_PETICION_SERVIDOR)
    return (objRespuesta = {
      tipoNotificacion: NOTIFICACION_TIPO_ERROR,
      error: ERROR_PETICION_SERVIDOR,
    });

  if (
    objInicioSesion.status &&
    objInicioSesion.status.codigo === COD_STATUS_ERROR
  )
    return (objRespuesta = {
      tipoNotificacion: NOTIFICACION_TIPO_ERROR,
      error: ERROR_CREDENCIALES_INCORRECTAS,
    });

  if (objInicioSesion.status && objInicioSesion.status.codigo === COD_STATUS_NA)
    return (objRespuesta = {
      tipoNotificacion: NOTIFICACION_TIPO_ERROR,
      error: objInicioSesion.status.mensaje,
    });

  if (!strToken || !objInicioSesion.token) {
    return (objRespuesta = {
      tipoNotificacion: NOTIFICACION_TIPO_ERROR,
      error: ERROR_TOKEN_SESION,
    });
  }

  return objRespuesta;
}

// HOMOLOGAR DATA LOGIN PARA ESTRUCTURA FRONT
function configurarusuarioSesionInterno(objSesion) {
  // Validar codPerfil para usuarios JEFATURA y EJECUTIVO
  let { perfilRespuesta } = objSesion;
  let { perfil, codActiveDirectory } = perfilRespuesta;
  let objRol = {};

  switch (Number(perfil.codPerfil)) {
    case Number(process.env.REACT_APP_COD_EJECUTIVO):
      objRol = { ...ROL_USUARIO_EJECUTIVO, perfil: PERFIL_LDAP };
      break;

    case Number(process.env.REACT_APP_COD_JEFATURA):
      objRol = { ...ROL_USUARIO_JEFATURA, perfil: PERFIL_LDAP };
      break;

    case Number(process.env.REACT_APP_COD_EJECUTIVO_MANTENCION):
      objRol = { ...ROL_USUARIO_EJECUTIVO_MANTENCION, perfil: PERFIL_LDAP };
      break;

    default: //EJECUTIVO COMPLETO
      objRol = { ...ROL_USUARIO_EJECUTIVO_COMPLETO, perfil: PERFIL_LDAP };
      break;
  }

  let objSesionRespuesta = {
    ...OBJETO_SESION,
    id: perfilRespuesta.codUsuario,
    email: objSesion.email,
    descripcion: objSesion.descripcion,
    rut: objSesion.rut,
    nombre: objSesion.nombre,
    estadoUsuario: perfilRespuesta.estado,
    minutosVigenciaSesion: objSesion.minutosVigenciaSesion,
    perfiles: [
      {
        perfil: {
          codigo: perfil.codPerfil,
          nombre: perfil.nombrePerfil,
          nombreAplicacion: perfil.nombreAplicacion,
          codigoAplicacion: perfil.codAplicacion,
        },
        rol: { ...objRol },
        menu: procesarMenu(perfilRespuesta.perfil.menu, objRol.codigo),
        menuOriginal: perfilRespuesta.perfil.menu,
      },
    ],
    token: objSesion.token,
    cambiarPassword: FALSO,
    rol: { ...objRol },
    menu: procesarMenu(perfilRespuesta.perfil.menu, objRol.codigo),
    usuario: codActiveDirectory.toLowerCase(),
  };

  return objSesionRespuesta;
}

function configurarusuarioSesionDelegadoCorredor(objSesion) {
  const { empresaDto } = objSesion;
  let lstPerfilRespuesta = objSesion.perfilRespuestaDto;
  let objSesionRespuesta = {
    ...OBJETO_SESION,
    perfiles: [],
    rut: empresaDto.rut,
    temporal: empresaDto.temporal,
    minutosVigenciaSesion: empresaDto.minutosVigenciaSesion,
    token: empresaDto.token,
    cambiarPassword: FALSO,
  };

  for (const objPerfil of lstPerfilRespuesta) {
    let objRol = {};

    switch (Number(objPerfil.perfil.codPerfil)) {
      case Number(process.env.REACT_APP_COD_CORREDOR):
        objRol = { ...ROL_USUARIO_CORREDOR, perfil: PERFIL_CORREDOR };
        break;

      case Number(process.env.REACT_APP_COD_CORREDOR_COMERCIAL):
        objRol = { ...ROL_USUARIO_CORREDOR, perfil: PERFIL_CORREDOR_COMERCIAL };
        break;

      case Number(process.env.REACT_APP_COD_DELEGADO_MANTENCION):
        objRol = {
          ...ROL_USUARIO_DELEGADO,
          perfil: PERFIL_DELEGADO_MANTENCION,
        };
        break;

      default: //REACT_APP_COD_DELEGADO
        objRol = { ...ROL_USUARIO_DELEGADO, perfil: PERFIL_DELEGADO };
        break;
    }

    let perfil = {
      perfil: {
        codigo: objPerfil.perfil.codPerfil,
        nombre: objPerfil.perfil.nombrePerfil,
        nombreAplicacion: objPerfil.perfil.nombreAplicacion,
        codigoAplicacion: objPerfil.perfil.codAplicacion,
      },
      rol: { ...objRol },
      menu: procesarMenu(objPerfil.perfil.menu, objRol.codigo),
      menuOriginal: objPerfil.perfil.menu,
    };

    objSesionRespuesta = {
      ...objSesionRespuesta,
      id: objPerfil.codUsuario,
      nombre: objPerfil.nombre,
      estadoUsuario: objPerfil.estado,
    };

    objSesionRespuesta.perfiles.push(perfil);
  }

  return objSesionRespuesta;
}

function configurarSesionExterno(objSesion) {
  const { empresaDto } = objSesion;
  let lstPerfilRespuesta = objSesion.listaPerfilRespuestaDto;
  let objSesionRespuesta = {
    ...OBJETO_SESION,
    cambiarPassword: FALSO,
  };

  if (empresaDto) {
    objSesionRespuesta = {
      ...objSesionRespuesta,
      rut: empresaDto.rut,
      temporal: empresaDto.temporal,
      minutosVigenciaSesion: empresaDto.minutosVigenciaSesion,
      token: empresaDto.token,
    };
  }

  for (const objPerfil of lstPerfilRespuesta) {
    let objRol = {};

    switch (Number(objPerfil.perfil.codPerfil)) {
      case Number(process.env.REACT_APP_COD_CORREDOR):
        objRol = { ...ROL_USUARIO_CORREDOR, perfil: PERFIL_CORREDOR };
        break;

      case Number(process.env.REACT_APP_COD_CORREDOR_COMERCIAL):
        objRol = { ...ROL_USUARIO_CORREDOR, perfil: PERFIL_CORREDOR_COMERCIAL };
        break;

      case Number(process.env.REACT_APP_COD_DELEGADO_MANTENCION):
        objRol = {
          ...ROL_USUARIO_DELEGADO,
          perfil: PERFIL_DELEGADO_MANTENCION,
        };
        break;

      default: //REACT_APP_COD_DELEGADO
        objRol = { ...ROL_USUARIO_DELEGADO, perfil: PERFIL_DELEGADO };
        break;
    }

    let perfil = {
      perfil: {
        codigo: objPerfil.perfil.codPerfil,
        nombre: objPerfil.perfil.nombrePerfil,
        nombreAplicacion: objPerfil.perfil.nombreAplicacion,
        codigoAplicacion: objPerfil.perfil.codAplicacion,
      },
      rol: { ...objRol },
      menu: procesarMenu(objPerfil.perfil.menu, objRol.codigo),
      menuOriginal: objPerfil.perfil.menu,
    };

    objSesionRespuesta = {
      ...objSesionRespuesta,
      id: objPerfil.codUsuario,
      nombre: objPerfil.nombre,
      estadoUsuario: objPerfil.estado,
    };

    objSesionRespuesta.perfiles.push(perfil);
  }

  return objSesionRespuesta;
}

function configurarusuarioSesionAsegurado(objSesion) {
  let objSesionRespuesta = {
    ...OBJETO_SESION,
    correo: objSesion.email,
    rut: objSesion.rut,
    nombre: objSesion.nombres,
    apellido: objSesion.apellidos,
    estadoUsuario: objSesion.estadoLogin,
    minutosVigenciaSesion: objSesion.minutosVigenciaSesion,
    token: objSesion.token,
    temporal: objSesion.temporal,
    cambiarPassword: objSesion.temporal,
    rol: { ...ROL_USUARIO_ASEGURADO, perfil: PERFIL_ASEGURABLE },
  };

  return objSesionRespuesta;
}

const procesarAuthInterno = async (objPeticion) => {
  const strToken = await buscarPuertaEnlaceToken();
  let objAutenticacion = {};
  objAutenticacion.blnError = FALSO;
  objAutenticacion.sesion = await iniciarusuarioSesionInterno(
    objPeticion,
    strToken
  );
  objAutenticacion.validacion = validarRespuestaSesion(
    objAutenticacion.sesion,
    strToken
  );

  if (objAutenticacion.validacion) {
    objAutenticacion.blnError = VERDADERO;
  }

  if (!objAutenticacion.blnError) {
    if (
      Number(objAutenticacion.sesion.rol.codigo) !== COD_EJECUTIVO &&
      Number(objAutenticacion.sesion.rol.codigo) !== COD_EJECUTIVO_MANTENCION &&
      Number(objAutenticacion.sesion.rol.codigo) !== COD_EJECUTIVO_COMPLETO &&
      Number(objAutenticacion.sesion.rol.codigo) !== COD_JEFATURA
    ) {
      objAutenticacion.validacion.tipoNotificacion = NOTIFICACION_TIPO_ERROR;
      objAutenticacion.validacion.error = ERROR_CREDENCIALES_INCORRECTAS;
      objAutenticacion.blnError = VERDADERO;
    }
    objAutenticacion.sesion.tokenServlet = strToken;
    objAutenticacion.sesion.validarIncorporacion = false;
  }
  return objAutenticacion;
};

const procesarAuthDelegadoCorredor = async (objPeticion) => {
  const strTokenPuertaEnlace = await buscarPuertaEnlaceToken();
  let objAutenticacion = {};
  objAutenticacion.blnError = FALSO;
  objAutenticacion.sesion = await iniciarusuarioSesionDelegadoCorredor(
    objPeticion,
    strTokenPuertaEnlace
  );
  objAutenticacion.validacion = validarRespuestaSesion(
    objAutenticacion.sesion,
    strTokenPuertaEnlace
  );

  if (objAutenticacion.validacion) {
    objAutenticacion.blnError = VERDADERO;
  }

  if (!objAutenticacion.blnError) {
    if (objAutenticacion.sesion.perfiles.length > LARGO_LISTA_VACIO) {
      objAutenticacion.sesion.tokenServlet = strTokenPuertaEnlace;
      objAutenticacion.sesion.validarIncorporacion = true;

      if (objAutenticacion.sesion.perfiles.length === TAMANO_UNO) {
        let objPerfil = Object.assign({}, ...objAutenticacion.sesion.perfiles);

        objAutenticacion.sesion.perfil = { ...objPerfil.perfil };
        objAutenticacion.sesion.menu = [...objPerfil.menu];
        objAutenticacion.sesion.rol = { ...objPerfil.rol };
        objAutenticacion.sesion.escogerIncorporacion = FALSO;
      } else {
        objAutenticacion.sesion.escogerIncorporacion = VERDADERO;
      }
    } else {
      objAutenticacion.blnError = VERDADERO;
      objAutenticacion.validacion.tipoNotificacion = NOTIFICACION_TIPO_ERROR;
      objAutenticacion.validacion.error = ERROR_NO_PERFILES;
    }
  }
  return objAutenticacion;
};

const procesarAuthAsegurable = async (objPeticion) => {
  const strToken = await buscarPuertaEnlaceToken();
  let objAutenticacion = {};
  objAutenticacion.blnError = FALSO;
  objAutenticacion.sesion = await iniciarusuarioSesionAsegurado(
    objPeticion,
    strToken
  );
  objAutenticacion.validacion = validarRespuestaSesion(
    objAutenticacion.sesion,
    strToken
  );

  if (objAutenticacion.validacion) {
    objAutenticacion.blnError = VERDADERO;
  } else {
    objAutenticacion.sesion.tokenServlet = strToken;
    objAutenticacion.sesion.validarIncorporacion = false;
  }

  return objAutenticacion;
};

export const iniciarSesion = async (objFormulario, strTipoUsuario) => {
  let objAutenticacion;
  let objPeticion = {
    clave: objFormulario.clave,
    rut: format(objFormulario.rut).replace(
      EXPRESION_REGULAR_PUNTO,
      STRING_VACIO
    ),
  };

  switch (strTipoUsuario.codigo) {
    case COD_DELEGADO:
    case COD_CORREDOR:
      objPeticion.sitioCod = objFormulario.idSitio;
      objAutenticacion = await procesarAuthDelegadoCorredor(
        objPeticion,
        strTipoUsuario.codigo
      );
      break;

    case COD_ASEGURADO:
      objAutenticacion = await procesarAuthAsegurable(objPeticion);
      break;

    default: //ROL INTERNO
      objPeticion = {
        clave: objFormulario.clave,
        idSitio: objFormulario.idSitio,
        usuario: objFormulario.usuario,
      };
      objAutenticacion = await procesarAuthInterno(objPeticion);
      break;
  }

  return objAutenticacion;
};

export const validarFormularioInicioSesion = (
  objFormulario,
  objTipoUsuario
) => {
  let blnFormularioValido = FALSO;
  let blnValidarRut = FALSO;
  let objValidacion = { blnError: false, mensajeError: null, tipoError: null };

  if (objTipoUsuario.perfil === ROL_INTERNO) {
    blnFormularioValido = objFormulario.usuario && objFormulario.clave;
  } else {
    blnFormularioValido = objFormulario.rut && objFormulario.clave;
    blnValidarRut = VERDADERO;
  }

  if (!blnFormularioValido) {
    objValidacion.blnError = VERDADERO;
    objValidacion.mensajeError = ERROR_INGRESO_CREDENCIALES;
    objValidacion.tipoError = NOTIFICACION_TIPO_ERROR;
  }

  if (
    blnValidarRut &&
    !validate(
      format(objFormulario.rut).replace(EXPRESION_REGULAR_PUNTO, STRING_VACIO)
    )
  ) {
    objValidacion.blnError = VERDADERO;
    objValidacion.mensajeError = ERROR_DIGITO_VERIFICADOR;
    objValidacion.tipoError = NOTIFICACION_TIPO_ERROR;
  }

  return objValidacion;
};

export const mostrarAlertaTiempoSesion = (
  objAlertaTiempoSesion,
  intSegundosRestante,
  procesarReinicioSesion,
  cerrarSesion
) => {
  if (!objAlertaTiempoSesion.current) {
    let objIntervaloAlerta;

    objAlertaTiempoSesion.current = Swal.fire({
      titleText: SESION_TIEMPO_ALERTA_TITULO,
      html: SESION_TIEMPO_ALERTA_TEXTO,
      icon: NOTIFICACION_TIPO_WARN,
      timer: Number(intSegundosRestante * VALOR_SEGUNDO_EN_MILISEGUNDOS),
      timerProgressBar: VERDADERO,
      allowOutsideClick: FALSO,
      allowEscapeKey: FALSO,
      showCloseButton: FALSO,
      showConfirmButton: VERDADERO,
      showCancelButton: VERDADERO,
      confirmButtonText: SESION_TIEMPO_TEXTO_REACTIVAR,
      cancelButtonText: SESION_TIEMPO_TEXTO_CERRAR,
      iconColor: COLOR_NARANJA,
      confirmButtonColor: COLOR_NARANJA,
      cancelButtonColor: COLOR_AZUL_OSCURO,
      customClass: {
        container: SESION_TIEMPO_CLASE_TIEMPO,
      },
      willOpen: () => {
        const strContenido = Swal.getHtmlContainer().querySelector(
          SESION_TIEMPO_SELECTOR_SEGUNDOS
        );
        objIntervaloAlerta = setInterval(() => {
          strContenido.textContent = (
            Swal.getTimerLeft() / VALOR_SEGUNDO_EN_MILISEGUNDOS
          ).toFixed(NO_DECIMALES);
        }, VALOR_SEGUNDO_EN_MILISEGUNDOS);
      },
      willClose: () => {
        clearInterval(objIntervaloAlerta);
      },
    }).then((objResultado) => {
      if (objResultado.isConfirmed) {
        procesarReinicioSesion();
      } else if (objResultado.dismiss === Swal.DismissReason.cancel) {
        cerrarSesion();
      }
    });
  }
};

export const actualizarTokensSesion = async (
  objUsuarioSesion,
  intCodigoTipoUsuario
) => {
  let strTokenPuertaEnlace = await buscarPuertaEnlaceToken();
  let objTokensSesion = {
    validacion: { blnError: false, error: null, tipoNotificacion: null },
    tokens: { puertaEnlace: null, autenticacion: null },
  };

  if (strTokenPuertaEnlace) {
    let objRespuesta = null;

    if (intCodigoTipoUsuario === COD_ASEGURADO) {
      objRespuesta = await buscarAuthTokenAsegurable(
        objUsuarioSesion.rut,
        strTokenPuertaEnlace
      );
    }

    if (
      intCodigoTipoUsuario === COD_DELEGADO ||
      intCodigoTipoUsuario === COD_CORREDOR
    ) {
      objRespuesta = await buscarAuthToken(
        objUsuarioSesion.rut,
        strTokenPuertaEnlace
      );
    }

    if (intCodigoTipoUsuario === COD_EJECUTIVO) {
      let objPeticion = {
        rut: objUsuarioSesion.rut,
        nombreCuenta: objUsuarioSesion.usuario,
      };

      objRespuesta = await buscarAuthTokenLdap(
        objPeticion,
        strTokenPuertaEnlace
      );
    }

    if (objRespuesta) {
      if (objRespuesta.status.codigo === COD_STATUS_EXITO) {
        objTokensSesion.tokens.puertaEnlace = strTokenPuertaEnlace;
        objTokensSesion.tokens.autenticacion = objRespuesta.objeto.token;
      } else {
        objTokensSesion.validacion.blnError = true;
        objTokensSesion.validacion.error = objRespuesta.status.mensaje;
        objTokensSesion.validacion.tipoNotificacion = NOTIFICACION_TIPO_ERROR;
      }
    } else {
      objTokensSesion.validacion.blnError = true;
      objTokensSesion.validacion.error = ERROR_TOKEN_API;
      objTokensSesion.validacion.tipoNotificacion = NOTIFICACION_TIPO_ERROR;
    }
  } else {
    objTokensSesion.validacion.blnError = true;
    objTokensSesion.validacion.error = ERROR_TOKEN_GATEWAY;
    objTokensSesion.validacion.tipoNotificacion = NOTIFICACION_TIPO_ERROR;
  }

  return objTokensSesion;
};

export const procesarAuthExterno = async (strRut, strToken) => {
  let objAutenticacion = {};
  objAutenticacion.blnError = FALSO;
  const strTokenPuertaEnlace = await buscarPuertaEnlaceToken();
  objAutenticacion.sesion = await iniciarSesionExterno(
    strRut,
    strToken,
    strTokenPuertaEnlace
  );
  objAutenticacion.validacion = validarRespuestaSesion(
    objAutenticacion.sesion,
    strTokenPuertaEnlace
  );

  if (objAutenticacion.validacion) {
    objAutenticacion.blnError = VERDADERO;
  }

  if (!objAutenticacion.blnError) {
    if (objAutenticacion.sesion.perfiles.length > LARGO_LISTA_VACIO) {
      objAutenticacion.sesion.tokenServlet = strTokenPuertaEnlace;
      objAutenticacion.sesion.validarIncorporacion = true;

      if (objAutenticacion.sesion.perfiles.length === TAMANO_UNO) {
        let objPerfil = Object.assign({}, ...objAutenticacion.sesion.perfiles);

        objAutenticacion.sesion.perfil = { ...objPerfil.perfil };
        objAutenticacion.sesion.menu = [...objPerfil.menu];
        objAutenticacion.sesion.rol = { ...objPerfil.rol };
        objAutenticacion.sesion.escogerIncorporacion = FALSO;
      } else {
        objAutenticacion.sesion.escogerIncorporacion = VERDADERO;
      }
    } else {
      objAutenticacion.blnError = VERDADERO;
      objAutenticacion.validacion.tipoNotificacion = NOTIFICACION_TIPO_ERROR;
      objAutenticacion.validacion.error = ERROR_NO_PERFILES;
    }
  }
  return objAutenticacion;
};

export const buscarPerfilPorTipoIncorporacion = (
  lstUsuarioPerfiles,
  objTipoUsuario,
  intTipoIncorporacion
) => {
  let intCodigoPerfil = 0;

  if (objTipoUsuario.codigo === COD_DELEGADO) {
    if (intTipoIncorporacion === TIPO_INCORPORACION_MANTENCION) {
      intCodigoPerfil = process.env.REACT_APP_COD_DELEGADO_MANTENCION;
    }

    if (intTipoIncorporacion === TIPO_INCORPORACION_NUEVO_NEGOCIO) {
      intCodigoPerfil = process.env.REACT_APP_COD_DELEGADO;
    }
  }

  if (objTipoUsuario.codigo === COD_CORREDOR) {
    if (intTipoIncorporacion === TIPO_INCORPORACION_MANTENCION) {
      intCodigoPerfil = process.env.REACT_APP_COD_CORREDOR;
    }

    if (intTipoIncorporacion === TIPO_INCORPORACION_NUEVO_NEGOCIO) {
      intCodigoPerfil = process.env.REACT_APP_COD_CORREDOR_COMERCIAL;
    }
  }

  let objPerfilEncontrado = lstUsuarioPerfiles.find(
    (objPerfil) => Number(objPerfil.perfil.codigo) === Number(intCodigoPerfil)
  );

  return objPerfilEncontrado;
};
