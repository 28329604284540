import { Field } from "formik";
import React from "react";

import { formatTel, soloLetras } from "../../../../../../../utils/Functions";
import MsgErrorAsegurable from "./MsgErrorAsegurable";

const { format } = require("rut.js");

export const InputRut = ({
  values,
  actualizarLimpiarAceptacion,
  errors,
  touched,
}) => {
  return (
    <>
      <Field
        className="form-control"
        id="rut"
        name="rut"
        type="text"
        placeholder="ej. 12345678-9"
        maxLength="12"
        minLength="11"
        value={values.rut.length > 1 ? format(values.rut) : values.rut}
        onKeyDown={() => {
          actualizarLimpiarAceptacion(true);
        }}
      />
      {errors && touched && (
        <MsgErrorAsegurable error={errors} touched={touched} />
      )}
    </>
  );
};

export const InputTexto = ({ type, values, errors, touched, placeholder }) => {
  return (
    <>
      <Field
        className="form-control"
        id={type}
        name={type}
        type="text"
        placeholder={placeholder}
        value={soloLetras(values)}
      />
      {errors && touched && (
        <MsgErrorAsegurable error={errors} touched={touched} />
      )}
    </>
  );
};

export const InputEmail = ({ values, errors, touched }) => {
  return (
    <>
      <Field
        className="form-control"
        id="email"
        name="email"
        type="text"
        placeholder="test@gmail.com"
        maxLength="50"
        value={values}
      />
      <MsgErrorAsegurable error={errors} touched={touched} />
    </>
  );
};

export const InputTelefono = ({ values, errors, touched }) => {
  return (
    <>
      <Field
        id="telefonoMovil"
        name="telefonoMovil"
        className="form-control"
        maxLength="13"
        placeholder="56959702588"
        value={formatTel(values)}
      />
      <MsgErrorAsegurable error={errors} touched={touched} />
    </>
  );
};
