import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  guardar_datos_empresa,
  guardar_giros,
} from "../../../../../../actions/actions";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { MySelect } from "../../../../transversales/Select";
import {
  obtenerGiros,
  actualizarConfiguracionNegocio,
  buscarOrganizacionPorRut,
} from "../../../../../../services/negocios.service";
import {
  VALIDACION_NOMBRE,
  CAMPO_REQUERIDO,
  ERROR_ACTUALIZAR_NEGOCIO,
  EXITO_ACTUALIZAR_NEGOCIO,
  ID_ORGANIZACION_DEFAULT,
  ID_ZERO,
  VALOR_DOS,
  VALOR_UNO,
} from "../../../../../../utils/Constantes";
import { notificacion } from "../../../../transversales/Notificaciones";
import {soloLetrasNumerosYAmpersand } from "../../../../../../utils/Functions";

const { format } = require("rut.js");

class DatosEmpresa extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cargando: true,
      loadingData: false,
      datosEmpresa: this.props.negocio,
      catalogo: {
        giros: null,
      },
      disabledForm: false,
    };
  }

  async componentWillMount() {
    await this.obtenerGiros();
    await this.obtenerIdOrganizacion();
    this.setState({ cargando: false });

    // Validar tipo canal venta negocio
    const negocio_ = this.props.store.reducer.negocio;
    if (
      negocio_.canalVenta.idCanalVenta !== VALOR_UNO ||
      negocio_.estadoNegocio.idEstadoNegocio > VALOR_DOS
    ) {
      this.setState({ disabledForm: true });
    }
  }

  /*************************
   *  PETICIONES SERVIDOR  *
   *************************/
  async obtenerGiros() {
    let giros;
    if (this.props.store.reducer.giros.length > 0) {
      giros = this.props.store.reducer.giros;
    } else giros = await obtenerGiros();

    let catalogo = { giros: giros };
    this.props.guardar_giros(giros);
    this.setState({ catalogo });
  }

  obtenerGlosaGiro(idGiro) {
    let giro;
    for (let g of this.state.catalogo.giros) {
      if (Number(g.idGiro) === Number(idGiro)) return (giro = g);
    }

    return giro;
  }

  async enviarDatosDelNegocio(values) {

    this.setState({loadingData: true});

    const dataEnvio = {
      idOrganizacionNoEnrolada: this.state.datosEmpresa
        .idOrganizacionNoEnrolada,
      organizacion: {
        idUsuarioModifica: this.props.store.auth.usuarioSesion.id,
        giro: {
          idGiro:
            values.giro.value !== undefined
              ? values.giro.value
              : values.giro.idGiro,
        },
        idOrganizacion: this.state.datosEmpresa.idOrganizacion,
        identificador: this.state.datosEmpresa.rut,
        razonSocial: values.razonSocial,
      },
    };

    if (dataEnvio.organizacion.idOrganizacion === undefined) {
      dataEnvio.organizacion.idOrganizacion = ID_ORGANIZACION_DEFAULT;
    }

    let comparaGiro = false;
    if (this.state.datosEmpresa.giro) {
      comparaGiro =
        dataEnvio.organizacion.giro.idGiro ===
        Number(this.state.datosEmpresa.giro.idGiro);
    }

    let comparaRazonSocial =
      dataEnvio.organizacion.razonSocial ===
      this.state.datosEmpresa.razonSocial;

    if (comparaGiro && comparaRazonSocial) {
      this.props.wizardChange(this.props.wizard + 1);
      return;
    }

    // Validar envio, con tipo canal venta y estado negocio negocio
    const negocio_ = this.props.store.reducer.negocio;
    if (
      negocio_.canalVenta.idCanalVenta === VALOR_UNO &&
      negocio_.estadoNegocio.idEstadoNegocio <= VALOR_DOS
    ) {
      const actualizarEmpresa = await actualizarConfiguracionNegocio(dataEnvio);

      if (!actualizarEmpresa) {
        return notificacion("danger", ERROR_ACTUALIZAR_NEGOCIO);
      } else {
        let datosEmpresa = Object.assign({}, this.state.datosEmpresa);

        datosEmpresa.razonSocial = values.razonSocial;
        datosEmpresa.giro.idGiro = Number(values.giro.value);
        datosEmpresa.giro.glosa = values.giro.label;

        this.setState({ datosEmpresa });
        await this.props.guardar_datos_empresa(datosEmpresa);

        notificacion("success", EXITO_ACTUALIZAR_NEGOCIO);
      }
    }
    this.props.wizardChange(this.props.wizard + 1);
    this.setState({loadingData: false});
  }

  async obtenerIdOrganizacion() {
    let {
      datosEmpresa,
    } = this.props.store.reducer.ejecutivo.configuracionNegocio;

    if (datosEmpresa) this.setState({ datosEmpresa });
    else {
      const organizacion = await buscarOrganizacionPorRut(
        this.state.datosEmpresa.rut
      );

      if (organizacion) {
        datosEmpresa = Object.assign({}, this.state.datosEmpresa);
        datosEmpresa.idOrganizacion = organizacion.idOrganizacion;
        this.setState({ datosEmpresa });
        this.props.guardar_datos_empresa(datosEmpresa);
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        {!this.state.negocio && !this.state.cargando ? (
          <Formik
            initialValues={{
              rut: this.props.negocio.rut,
              razonSocial: this.props.negocio.razonSocial,
              giro: this.props.negocio.giro ? this.props.negocio.giro : "",
            }}
            validationSchema={Yup.object().shape({
              rut: Yup.string(),
              razonSocial: Yup.string()
                .min(1, VALIDACION_NOMBRE)
                .required(CAMPO_REQUERIDO),
              giro: Yup.object()
                .required(CAMPO_REQUERIDO)
                .test("giro", CAMPO_REQUERIDO, (value) => {
                  return !(
                    ID_ZERO === Number(value.idGiro) ||
                    ID_ZERO === Number(value.value)
                  );
                }),
            })}
            onSubmit={async (values, { setSubmitting }) => {
              this.enviarDatosDelNegocio(values);
            }}
          >
            {({ values, errors, touched, setFieldValue, setFieldTouched }) => {
              return (
                <Form
                  className="container animated fadeInRight"
                  onKeyDown={(e) => {
                    if ((e.charCode || e.keyCode) === 13) {
                      e.preventDefault();
                    }
                  }}
                >
                  <div className="row mx-3">
                    {/* rut */}
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>
                          RUT <span className="text-danger">*</span>
                        </label>
                        <Field
                          className="form-control"
                          disabled
                          id="rut"
                          name="rut"
                          type="text"
                          placeholder="ej. 12345678-9"
                          maxLength="12"
                          minLength="11"
                          value={format(values.rut)}
                        />
                      </div>
                    </div>

                    {/* razonSocial */}
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>
                          Razon social <span className="text-danger">*</span>
                        </label>
                        <Field
                          className="form-control"
                          id="razonSocial"
                          name="razonSocial"
                          type="text"
                          placeholder="Ingrese razón social"
                          disabled={this.state.disabledForm}
                          value={soloLetrasNumerosYAmpersand(values.razonSocial)}
                        />
                        {errors.razonSocial && touched.razonSocial && (
                          <small className="text-danger animated fadeIn animated fadeIn">
                            {errors.razonSocial}
                          </small>
                        )}
                      </div>
                    </div>

                    {/* giro */}
                    <div className="col-md-12 ">
                      <div className="form-group">
                        <label>
                          Giro <span className="text-danger">*</span>
                        </label>
                        <MySelect
                          id="giro"
                          name="giro"
                          tipoData="giro"
                          value={values.giro}
                          onChange={setFieldValue}
                          onBlur={setFieldTouched}
                          error={errors.giro}
                          touched={touched.giro}
                          data={this.state.catalogo.giros}
                          disabledText={this.state.disabledForm}
                          titulo={"giro"}
                        />
                      </div>
                    </div>
                  </div>

                  {/* Botonera */}
                  <div className="container button-zone text-right pt-3 pb-3 animated fadeIn">
                    <button 
                      id="botonSiguiente" 
                      className="btn btn-principal" 
                      type="submit"
                      disabled={this.state.loadingData}
                    >
                      {this.state.loadingData ? (
                        <Fragment>
                          <i className="fa fa-spinner fa-pulse fa-fw"></i>
                          <span className="sr-only">Loading...</span>
                        </Fragment>
                      ) : (
                        <span>Siguiente</span>
                      )}
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        ) : (
          <div className="d-flex justify-content-center animated fadeIn my-5">
            <div className="spinner-border text-primary mb-5" role="status">
              <span className="sr-only">Cargando...</span>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state,
});

export default withRouter(
  connect(mapStateToProps, {
    guardar_giros,
    guardar_datos_empresa,
  })(DatosEmpresa)
);
