import React, { Component } from "react";
import { actualizarUsuarioRevisor } from "../../../../../services/bus.poliza.services";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  CAMPO_REQUERIDO,
  VALIDACION_RUT,
  VALIDACION_NOMBRE,
  VALIDACION_APELLIDO_P,
  VALIDACION_MAIL,
  MASK_DATE,
  FECHA_FUERA_VIGENCIA,
  VALOR_UNO,
  CHAR_S,
  STRING_VACIO,
  NUMERO,
  VALOR_ZERO,
  ERROR_AGREGAR_ASEGURADO,
  ERROR_ENVIO_AUTOMATICO,
  RUT_SOLICITUD_DUPLICADO,
  EXITO_EDICION,
  ERROR_EDICION,
  COD_STATUS_EXITO,
  PERFIL_DELEGADO,
  VALIDACION_NOMBRE_VALIDO,
  VALIDACION_APELLIDO_P_VALIDO,
  VALIDACION_APELLIDO_M_VALIDO,
  VALIDACION_APELLIDO_M,
  VALIDACION_APELLIDO_M_MAXIMO,
  VALIDACION_APELLIDO_P_MAXIMO,
  VALIDACION_NOMBRE_MAXIMO,
  COD_EJECUTIVO_MANTENCION,
  PERFIL_LDAP,
  COD_EJECUTIVO_COMPLETO,
  VALIDACION_TELEFONO,
  SIGNO_GUION,
  POSICION_INICIAL,
  POSICION_DIGITO_VERIFICADOR,
  TIPO_INCORPORACION_MANTENCION,
  VALIDACION_SIN_TELEFONO,
  VALIDACION_SIN_CORREO,
  TELEFONO_MOVIL,
  CORREO_ELECTRONICO,
  VALIDA_TELEFONO,
  LARGO_TELEFONO_VALIDO,
  LARGO_TELEFONO_VACIO,
  ACCESO_ENVIADO_A,
  COD_EJECUTIVO,
} from "../../../../../utils/Constantes";
import ModalEditarAsegurable from "../../jefatura-ejecutivo/ModalEditarAsegurable";
import DatePicker from "react-datepicker";
import MaskedTextInput from "react-text-mask";
import moment from "moment";
import SelectContratante from "../ingresarAsegurables/SelectContratante";
import MultiSelect from "../../../transversales/MultiSelect";
import {
  arrayToFormattedString,
  formatTel,
  REGEX_SOLO_LETRAS,
} from "../../../../../utils/Functions";
import { crearNegocio } from "../../../../../services/negocios.service";
import { crearCotizacionPoliza } from "../../../../../services/poliza.service";
import { notificacion } from "../../../transversales/Notificaciones";
import {
  actualizarAsegurableDelegado,
  enviarMailAsegurable,
  listarMisSolicitudes,
} from "../../../../../services/asegurable.services";
import { obtenerMisSolicitudesAction } from "../../../../../reducer/delegadoMantencion";

const { clean, format, validate } = require("rut.js");

class EditarSolicitudes extends Component {
  state = {
    idNegocio: this.props.idNegocio,
    polizasSeleccionadas: [],
    polizas: this.props.polizas,
    key: this.props.infoEditar.key,
    rut: this.props.infoEditar.rut,
    nombre: this.props.infoEditar.nombre,
    apellidoPaterno: this.props.infoEditar.apellidoPaterno,
    apellidoMaterno: this.props.infoEditar.apellidoMaterno,
    correoElectronico: this.props.infoEditar.correoElectronico,
    telefonoMovil: this.props.infoEditar.telefonoMovil,
    fechaInicioVigencia: this.props.infoEditar.grupos[0].fechaInicioVigencia,
    fechaInicioVigenciaFormato: moment(
      new Date(this.props.infoEditar.grupos[0].fechaInicioVigencia)
    ).format("DD/MM/YYYY"),
    polizasFechaErronea: [],
    stringPolizasFechaErronea: "",
    perfil:
      this.props.store.auth.usuarioSesion.rol.codigo ===
        COD_EJECUTIVO_MANTENCION ||
        this.props.store.auth.usuarioSesion.rol.codigo === COD_EJECUTIVO_COMPLETO
        ? PERFIL_LDAP
        : PERFIL_DELEGADO,
  };

  componentDidMount() {
    const polizasSeleccionadas = this.props.infoEditar.grupos.map((grupo) => {
      const polizaSeleccionada = this.state.polizas.find(
        (poliza) =>
          poliza.idContratoSeguroGrupo === grupo.idContratoSeguroGrupo ||
          poliza.poliza.idContratoSeguro === grupo.idContratoSeguro
      );
      return polizaSeleccionada;
    });
    this.setState({ polizasSeleccionadas });
  }

  crearNegocioSuscripcion = async (negocio) => {
    // Leer respuesta
    if (negocio.rutEmpresa === undefined && negocio.digitoVerificador === undefined) {
      negocio = {
        ...negocio,
        rutEmpresa: parseInt(negocio.rut.split(SIGNO_GUION)[POSICION_INICIAL]),
        digitoVerificador: negocio.rut.split(SIGNO_GUION)[POSICION_DIGITO_VERIFICADOR],
        tipoIncorporacion: { idTipoIncorporacion: TIPO_INCORPORACION_MANTENCION }
      }
    }
    negocio.perfil = this.state.perfil;
    const idNegocio = await crearNegocio(negocio);
    if (!idNegocio) return null;

    return idNegocio;
  };

  crearCotizacionSuscripcion = async (idNegocio) => {
    let { usuarioSesion } = this.props.store.auth;

    let dataCotizacionPolizas = [];

    this.state.polizasSeleccionadas.forEach((e) => {
      const dataCotizacion = {
        codigoCotizacion: e.poliza.codigoCotizacion,
        codigoUsuario: usuarioSesion.id,
        idContratoSeguro: e.poliza.idContratoSeguro,
        idNegocio: idNegocio,
        nroGrupo: e.poliza.numeroPoliza.toString(),
      };

      dataCotizacionPolizas.push(dataCotizacion);
    });

    let resCotizaciones = await crearCotizacionPoliza(
      dataCotizacionPolizas,
      this.state.perfil
    );

    if (!resCotizaciones) return null;

    resCotizaciones.forEach((cot) => {
      let poliza = this.state.polizasSeleccionadas.find(
        (p) => cot.idContratoSeguro === p.poliza.idContratoSeguro
      );

      cot.idContratoSeguroGrupo = poliza.idContratoSeguroGrupo;
    });

    resCotizaciones = resCotizaciones.map((e) => {
      return {
        idGrupo: e.idGrupo,
        idContratoSeguroGrupo: e.idContratoSeguroGrupo,
        idContratoSeguro: e.idContratoSeguro,
      };
    });

    return resCotizaciones;
  };

  submit = async (values) => {
    let { negocio } = this.props.store.reducer;

    const idNegocio = await this.crearNegocioSuscripcion(negocio);
    const resCotizaciones = await this.crearCotizacionSuscripcion(idNegocio);

    if (!resCotizaciones)
      return notificacion("danger", ERROR_AGREGAR_ASEGURADO);

    const { idAsegurable, idPersona, grupos } = this.props.infoEditar;
    const rut = Number(clean(values.rut).slice(0, -1));
    const digitoVerificador = format(clean(values.rut)).split("-")[1];
    let fecha = this.state.fechaInicioVigenciaFormato.split("/");
    const fechaInicioVigencia = `${fecha[2]}-${fecha[1]}-${fecha[0]}`;

    resCotizaciones.forEach((grupo) => {
      const buscarGrupo = grupos.find(
        (g) => g.idContratoSeguroGrupo === grupo.idContratoSeguroGrupo
      );

      grupo.fechaInicioVigencia = fechaInicioVigencia;
      grupo.idGrupoAsegurable =
        buscarGrupo === undefined ? 0 : buscarGrupo.idGrupoAsegurable;
      grupo.vigente = true;
    });

    let envioGrupos = [...resCotizaciones];

    for (let index in grupos) {
      const encontrarGrupo = envioGrupos.find(
        (item) =>
          item.idContratoSeguroGrupo === grupos[index].idContratoSeguroGrupo
      );
      if (encontrarGrupo === undefined) {
        const objetoGrupo = {
          fechaInicioVigencia,
          idContratoSeguro: grupos[index].idContratoSeguro,
          idContratoSeguroGrupo: grupos[index].idContratoSeguroGrupo,
          idGrupo: grupos[index].idGrupo,
          idGrupoAsegurable: grupos[index].idGrupoAsegurable,
          vigente: false,
        };
        envioGrupos.push(objetoGrupo);
      }
    }

    const dataSolicitud = {
      nombre: values.nombre,
      apellidoPaterno: values.apellidoPaterno,
      apellidoMaterno: values.apellidoMaterno,
      correoElectronico: values.correoElectronico,
      telefonoMovil: values.telefonoMovil,
      rut,
      digitoVerificador,
      idAsegurable,
      idPersona,
      grupos: envioGrupos,
      vigente: true,
    };

    const actualizarSolicitud = await actualizarAsegurableDelegado(
      dataSolicitud,
      this.state.perfil
    );

    if (
      actualizarSolicitud !== null &&
      actualizarSolicitud.status.codigo === COD_STATUS_EXITO
    ) {
      this.actualizaRevisor(idNegocio);
      let asegurablesActualizados = [...this.props.asegurables];

      //si no se modifica el contratante
      if (this.props.idNegocio === this.state.idNegocio) {
        const objetoAsegurable = this.props.asegurables.find(
          (asegurable) => asegurable.key === this.state.key
        );
        const index = this.props.asegurables.indexOf(objetoAsegurable);

        asegurablesActualizados[index] = {
          ...asegurablesActualizados[index],
          ...values,
        };
        asegurablesActualizados[
          index
        ].nombreCompleto = `${values.nombre} ${values.apellidoPaterno}`;
        asegurablesActualizados[index].polizas = values.poliza.map(
          (poliza, index) => (
            <div key={index}>
              {poliza.poliza.numeroPoliza}-{poliza.poliza.numeroRenovacion}
            </div>
          )
        );
        asegurablesActualizados[index].rut = `${rut}-${digitoVerificador}`;
        asegurablesActualizados[index].digitoVerificador = digitoVerificador;

        const dataEnvio = { ...this.props.dataEnvioSolicitudes };

        dataEnvio.fechaEnvioDesde = null;
        dataEnvio.fechaEnvioHasta = null;
        dataEnvio.polizaVigente = true;

        const solicitudes = await listarMisSolicitudes(dataEnvio);
        if (solicitudes) {
          const datosActualizados = solicitudes.find(
            (solicitud) =>
              (solicitud.idAsegurable = this.props.infoEditar.idAsegurable)
          );
          asegurablesActualizados[index].grupos = datosActualizados.grupos;
          asegurablesActualizados[index].fechaEnvioCorreoFormateado =
            datosActualizados.fechaEnvioCorreo
              ? moment(datosActualizados.fechaEnvioCorreo).format("DD-MM-YYYY")
              : "";
        }

        //si se modifica el contratante quitarlo del listado de asegurable
      } else {
        asegurablesActualizados = asegurablesActualizados.filter(
          (asegurable) => asegurable.rut !== this.state.rut
        );
      }

      if (
        this.props.infoEditar.correoElectronico !== values.correoElectronico || this.props.infoEditar.telefonoMovil !== values.telefonoMovil
      ) {
        const dataCorreo = {
          asegurable: {
            idAsegurable,
            rut,
            digitoVerificador,
          },
        };
        const codPerfil = this.props.store.auth.usuarioSesion.rol.codigo;
        const perfil =
          codPerfil === COD_EJECUTIVO_MANTENCION ||
            codPerfil === COD_EJECUTIVO_COMPLETO ||
            codPerfil === COD_EJECUTIVO
            ? PERFIL_LDAP
            : PERFIL_DELEGADO;
        const envioCorreo = await enviarMailAsegurable(
          dataCorreo,
          perfil
        );
        if (envioCorreo !== null) {
          notificacion(
            "success",
            `${ACCESO_ENVIADO_A} ${values.nombre} ${values.apellidoPaterno} ${values.apellidoMaterno}`
          );
        } else {
          notificacion("danger", ERROR_ENVIO_AUTOMATICO);
        }
      }
      this.props.setAsegurables(asegurablesActualizados);
      this.props.obtenerMisSolicitudesAction(asegurablesActualizados);
      this.props.setModalEditar(!this.props.mostrarModalEditar);
      notificacion("success", EXITO_EDICION);
    } else {
      notificacion("danger", ERROR_EDICION);
    }
  };

  actualizaRevisor = async (idNegocio) => {
    const dataEnvio = {
      idNegocio,
      revisado: false,
    };
    let actualizarRevisor = await actualizarUsuarioRevisor(dataEnvio);
    if (actualizarRevisor === COD_STATUS_EXITO) {
      notificacion("danger", ERROR_EDICION);
    }
  };

  obtenerPolizas = (idNegocio, polizas) => {
    this.setState({ idNegocio, polizas, polizasSeleccionadas: [] });
  };

  validarFechaVigencia = (value) => {
    if (value && this.state.polizasSeleccionadas) {
      const fecha = this.state.fechaInicioVigenciaFormato.split("/");
      const fechaInicioVigencia = `${fecha[2]}-${fecha[1]}-${fecha[0]}`;

      const polizasFechaErronea = this.state.polizasSeleccionadas
        .filter(
          ({ poliza }) =>
            fechaInicioVigencia <
            moment(poliza.fechaInicioVigencia).format("YYYY-MM-DD") ||
            fechaInicioVigencia >
            moment(poliza.fechaFinVigencia).format("YYYY-MM-DD")
        )
        .map(({ poliza }) => poliza.numeroPoliza);

      const stringPolizasFechaErronea =
        arrayToFormattedString(polizasFechaErronea);

      this.setState({ polizasFechaErronea, stringPolizasFechaErronea });

      return polizasFechaErronea.length === VALOR_ZERO;
    }
    return true;
  };

  rutUnico = (value) => {
    if (value && this.state.polizasSeleccionadas) {
      const asegurablesGrupo = this.props.asegurables.filter((asegurable) => {
        const incluyeGrupo = this.state.polizasSeleccionadas.find((grupo) => {
          const seleccionadoIncluyeGrupo = asegurable.grupos.find(
            (grupoS) =>
              grupoS.idContratoSeguroGrupo === grupo.idContratoSeguroGrupo
          );
          return seleccionadoIncluyeGrupo !== undefined;
        });
        return incluyeGrupo !== undefined;
      });

      const rutAsegurables = asegurablesGrupo
        .filter((item) => {
          return (
            format(item.rut) !== format(this.props.infoEditar.rut) &&
            item.status === this.props.infoEditar.status
          );
        })
        .map(({ rut }) => format(rut));

      return !rutAsegurables.includes(format(value));
    }
    return true;
  };

  render() {
    const validacionFecha = `${FECHA_FUERA_VIGENCIA}${this.state.polizasFechaErronea.length > VALOR_UNO ? CHAR_S : STRING_VACIO
      } ${NUMERO} ${this.state.stringPolizasFechaErronea}.`;

    return (
      <ModalEditarAsegurable
        idSubmit="submitEditarSolicitudes"
        type="small"
        show={this.props.mostrarModalEditar}
        title="Editar Solicitud"
        action="Editar"
        onSubmit={this.submit}
        onCancel={() => {
          this.props.setModalEditar(!this.props.mostrarModalEditar);
        }}
        onClose={() => {
          this.props.setModalEditar(!this.props.mostrarModalEditar);
        }}
        showCancelButton={false}
        showCloseButton={true}
        showLeftColumn={false}
        showSubmitButton={false}
      >
        <div className="container">
          <div className="mx-5">
            <div className="row animated fadeInRight">
              <SelectContratante
                obtenerPolizas={this.obtenerPolizas}
                idNegocio={this.state.idNegocio}
              />
              <Formik
                enableReinitialize={true}
                initialValues={{
                  idNegocio: this.state.idNegocio,
                  poliza: this.state.polizasSeleccionadas,
                  rut: this.state.rut,
                  nombre: this.state.nombre,
                  apellidoPaterno: this.state.apellidoPaterno,
                  apellidoMaterno: this.state.apellidoMaterno,
                  correoElectronico: this.state.correoElectronico,
                  telefonoMovil: this.state.telefonoMovil,
                  fechaInicioVigencia: moment(
                    new Date(this.state.fechaInicioVigencia)
                  ).format("DD/MM/YYYY"),
                }}
                validationSchema={Yup.object().shape({
                  poliza: Yup.array().required(CAMPO_REQUERIDO).nullable(),
                  rut: Yup.string()
                    .required(CAMPO_REQUERIDO)
                    .test("validarRut", VALIDACION_RUT, (value) => {
                      return validate(format(value));
                    })
                    .test(
                      "rutDuplicado",
                      RUT_SOLICITUD_DUPLICADO,
                      this.rutUnico
                    ),
                  nombre: Yup.string()
                    .matches(REGEX_SOLO_LETRAS, VALIDACION_NOMBRE_VALIDO)
                    .min(3, VALIDACION_NOMBRE)
                    .max(25, VALIDACION_NOMBRE_MAXIMO)
                    .required(CAMPO_REQUERIDO)
                    .trim(),
                  apellidoPaterno: Yup.string()
                    .matches(REGEX_SOLO_LETRAS, VALIDACION_APELLIDO_P_VALIDO)
                    .min(3, VALIDACION_APELLIDO_P)
                    .max(25, VALIDACION_APELLIDO_P_MAXIMO)
                    .required(CAMPO_REQUERIDO)
                    .trim(),
                  apellidoMaterno: Yup.string()
                    .matches(REGEX_SOLO_LETRAS, VALIDACION_APELLIDO_M_VALIDO)
                    .min(3, VALIDACION_APELLIDO_M)
                    .max(25, VALIDACION_APELLIDO_M_MAXIMO)
                    .trim(),
                  correoElectronico: Yup.string()
                    .ensure()
                    .when(TELEFONO_MOVIL, {
                      is: '',
                      then: Yup.string().email(VALIDACION_MAIL).required(VALIDACION_SIN_CORREO)
                    })
                    .email(VALIDACION_MAIL),
                  telefonoMovil: Yup.string()
                    .ensure()
                    .when(CORREO_ELECTRONICO, {
                      is: '',
                      then: Yup.string().test(VALIDA_TELEFONO, VALIDACION_TELEFONO, (value) => {
                        return (formatTel(value).length === LARGO_TELEFONO_VALIDO || formatTel(value).length === LARGO_TELEFONO_VACIO) ? true : false
                      }).required(VALIDACION_SIN_TELEFONO)
                    })
                    .test(VALIDA_TELEFONO, VALIDACION_TELEFONO, (value) => {
                      return formatTel(value).length === LARGO_TELEFONO_VALIDO ||
                        formatTel(value).length === LARGO_TELEFONO_VACIO
                        ? true
                        : false;
                    }),
                  fechaInicioVigencia: Yup.string()
                    .required(CAMPO_REQUERIDO)
                    .test(
                      "validarFecha",
                      validacionFecha,
                      this.validarFechaVigencia
                    ),
                }, [TELEFONO_MOVIL, CORREO_ELECTRONICO])}
                onSubmit={async (values, { resetForm }) => {
                  values.nombre = values.nombre.trim();
                  values.apellidoMaterno = values.apellidoMaterno.trim();
                  values.apellidoPaterno = values.apellidoPaterno.trim();
                  values.telefonoMovil = formatTel(values.telefonoMovil);
                  this.submit(values);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  resetForm,
                  isSubmitting,
                  validating,
                  valid,
                  setFieldValue,
                  setFieldTouched,
                }) => {
                  return (
                    <Form
                      className="col-md-12"
                      onKeyDown={(e) => {
                        if ((e.charCode || e.keyCode) === 13) {
                          e.preventDefault();
                        }
                      }}
                    >
                      <div className="row">
                        {/* póliza */}
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>Póliza</label>
                            <MultiSelect
                              value={values.poliza}
                              onChange={(value) => {
                                this.setState({
                                  polizasSeleccionadas: value,
                                });
                                setFieldValue("poliza", value);
                              }}
                              tooltip={true}
                              onBlur={setFieldTouched}
                              name="poliza"
                              options={this.state.polizas}
                              noOptionsMessage={() =>
                                "No existen más pólizas para este contratante"
                              }
                              placeholder={"Ingrese póliza/s"}
                              errors={errors.poliza}
                              touched={touched.poliza}
                            />
                          </div>
                        </div>

                        {/* rut */}
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>RUT</label>
                            <Field
                              className="form-control"
                              id="rut"
                              name="rut"
                              type="text"
                              placeholder="ej. 12345678-9"
                              maxLength="12"
                              minLength="11"
                              value={
                                values.rut && values.rut.length > 1
                                  ? format(values.rut)
                                  : values.rut
                              }
                            />
                            {errors.rut && touched.rut && (
                              <small className="text-danger animated fadeIn">
                                {errors.rut}
                              </small>
                            )}
                          </div>
                        </div>

                        {/* nombre */}
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>Nombres</label>

                            <Field
                              className="form-control"
                              id="nombre"
                              name="nombre"
                              type="text"
                              placeholder="Ingrese nombres"
                              value={values.nombre}
                            />
                            {errors.nombre && touched.nombre && (
                              <small className="text-danger animated fadeIn">
                                {errors.nombre}
                              </small>
                            )}
                          </div>
                        </div>

                        {/* apellidoPaterno */}
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Apellido paterno</label>

                            <Field
                              className="form-control"
                              id="apellidoPaterno"
                              name="apellidoPaterno"
                              type="text"
                              placeholder="Ingrese apellido paterno"
                              value={values.apellidoPaterno}
                            />
                            {errors.apellidoPaterno &&
                              touched.apellidoPaterno && (
                                <small className="text-danger animated fadeIn">
                                  {errors.apellidoPaterno}
                                </small>
                              )}
                          </div>
                        </div>

                        {/* apellidoMaterno */}
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Apellido materno</label>

                            <Field
                              className="form-control"
                              id="apellidoMaterno"
                              name="apellidoMaterno"
                              type="text"
                              placeholder="Ingrese apellido materno"
                              value={values.apellidoMaterno}
                            />
                            {errors.apellidoMaterno &&
                              touched.apellidoMaterno && (
                                <small className="text-danger animated fadeIn">
                                  {errors.apellidoMaterno}
                                </small>
                              )}
                          </div>
                        </div>

                        {/* email */}
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Email</label>
                            <Field
                              className="form-control"
                              id="correoElectronico"
                              name="correoElectronico"
                              type="text"
                              placeholder="test@gmail.com"
                              maxLength="50"
                              value={values.correoElectronico}
                            />
                            {errors.correoElectronico &&
                              touched.correoElectronico && (
                                <small className="text-danger animated fadeIn">
                                  {errors.correoElectronico}
                                </small>
                              )}
                          </div>
                        </div>

                        {/* telefonoMovil */}
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Teléfono móvil</label>
                            <Field
                              className="form-control"
                              id="telefonoMovil"
                              name="telefonoMovil"
                              type="tel"
                              placeholder="5691515151"
                              maxLength="13"
                              value={formatTel(values.telefonoMovil)}
                            />
                            {errors.telefonoMovil && touched.telefonoMovil && (
                              <small className="text-danger animated fadeIn">
                                {errors.telefonoMovil}
                              </small>
                            )}
                          </div>
                        </div>

                        {/* Fecha Inicio Vigencia */}
                        <div className="col-md-12">
                          <div className="form-group up-calendar">
                            <label>Fecha Inicio Vigencia</label>

                            <DatePicker
                              autoComplete="off"
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              popperPlacement="top-start"
                              dropdownMode="select"
                              id="fechaInicioVigencia"
                              name="fechaInicioVigencia"
                              value={this.state.fechaInicioVigenciaFormato}
                              dateFormat="dd/MM/yyyy"
                              placeholderText="DD/MM/YYYY"
                              locale="es"
                              onBlur={handleBlur}
                              onChange={(value) => {
                                value = moment(value).format("DD/MM/YYYY");
                                this.setState({
                                  fechaInicioVigencia: value,
                                  fechaInicioVigenciaFormato: value,
                                });
                                setFieldValue("fechaInicioVigencia", value);
                              }}
                              customInput={
                                <MaskedTextInput
                                  className="form-control"
                                  placeholder="DD/MM/YYYY"
                                  mask={MASK_DATE}
                                />
                              }
                            />
                            {errors.fechaInicioVigencia &&
                              touched.fechaInicioVigencia && (
                                <small className="text-danger animated fadeIn">
                                  {errors.fechaInicioVigencia}
                                </small>
                              )}
                          </div>
                        </div>
                      </div>

                      <div className="button-zone text-right pt-3 pb-3">
                        <button
                          id="cancelarEditar"
                          className="btn btn-principal color-cancel color-secundario btn-sm"
                          type="button"
                          onClick={() => {
                            this.props.setModalEditar(
                              !this.props.mostrarModalEditar
                            );
                          }}
                        >
                          Cancelar
                        </button>
                        <button
                          id="submitEditar"
                          className="btn btn-principal btn_sm"
                          type="submit"
                        >
                          Editar
                        </button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </ModalEditarAsegurable>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state,
});

export default withRouter(
  connect(mapStateToProps, {
    obtenerMisSolicitudesAction,
  })(EditarSolicitudes)
);
