import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  guardar_regiones,
  guardar_negocios,
  guardar_ejecutivos,
  guardar_canales_venta,
  guardar_menu_ejecutivo_completo,
  estados_negocios,
  tipo_negocio,
  mostrar_spinner,
  guardar_filtro_solicitudes,
  limpiar_filtro_solicitudes
} from "../../../../../actions/actions";
import { guardar_canal_venta_busqueda } from "../../../../../reducer/ldap";
import {
  obtenerRegiones,
  obtenerCanalesVenta,
} from "../../../../../services/transversales.service";
import {
  obtenerNegocios,
  obtenerEjecutivosPorRutJefatura,
  obtenerEstadosNegocios,
} from "../../../../../services/negocios.service";
import { notificacion } from "../../../transversales/Notificaciones";
import Spinner from "../../../transversales/Spinner";
import Filtros from "./filtros/Filtros";
import {
  NO_DATA_NEGOCIOS,
  PERFIL_LDAP,
  COD_JEFATURA,
  T_NEGOCIO_ACTIVO,
  RUTA_COTIZACIONES,
  STORAGE_ITEM_RUTA,
  STORAGE_ITEM_NEGOCIO,
  STORAGE_ITEM_SESION,
  VALOR_ZERO,
  VALOR_UNO,
  PERFIL_CORREDOR,
  COD_CORREDOR,
  COD_EJECUTIVO_COMPLETO,
  MONTHS,
  VALOR_CORRECCION_MES,
  RUTA_INICIO_SESION,
  STORAGE_ITEM_FILTRO_CANAL_VENTA,
  GA_CATEGORIA_NEGOCIOS,
  GA_LABEL_LISTADO_NEGOCIOS
} from "../../../../../utils/Constantes";
import { showElementsMenu } from "../../../../../utils/Functions";
import NegociosCanalDigital from "./NegociosCanalDigital";
import NegociosCanalVentaDirecta from "./NegociosCanalVentaDirecta";
import ImgCanalVenta from "../../../../assets/images/decision.svg";
import moment from "moment";
import { EjecutarSeleccionIncorporacion } from "../../../autenticacion/Incorporacion";
import { crearEventoTraza } from "../../../../../utils/analitica";
class Negocios extends Component {
  constructor(props) {
    super(props);

    this.state = {
      carngado: false,
      canalVenta: null,
      negocios: [],
      negociosFiltros: [],
      negociosBusqueda: [],
      regiones: [],
      ejecutivos: [],
      estadosNegocios: [],
      canalesVenta: [],
      mostrarFiltros: false,
      fechaInicio: moment().startOf("month").format("YYYY-MM-DD"),
      fechaTermino: moment().endOf("month").format("YYYY-MM-DD"),
      action: "Asignar",
      checked: "activos",
      tipoNegocio: "activos",
      buscando: false,
      key: "activos",
      blnSesionCorredor:
        this.props.store.auth.usuarioSesion.rol.codigo === COD_CORREDOR,
    };
  }

  componentDidMount() {
    crearEventoTraza(GA_CATEGORIA_NEGOCIOS, GA_LABEL_LISTADO_NEGOCIOS);
  }

  async componentWillMount() {
    const { reducer, auth } = this.props.store;
    const { ldap } = this.props.store;
    const strTipoNegocio = reducer.tipoNegocio;

    if (strTipoNegocio) {
      this.setState({ tipoNegocio: strTipoNegocio, key:  strTipoNegocio});
      this.props.tipo_negocio(strTipoNegocio);
    } else {
      this.props.tipo_negocio(T_NEGOCIO_ACTIVO);
    }

    if (reducer.periodoNegocio) {

      let lstPeriodo = reducer.periodoNegocio.split(" ");
      let intMes = MONTHS.findIndex((strMes) => strMes === lstPeriodo[0].split(".")[0]) + VALOR_CORRECCION_MES;

      let datFechaInicio  = `${lstPeriodo[1]}-${intMes}-01`;
      let datFechaTermino = `${lstPeriodo[1]}-${intMes}-${new Date(lstPeriodo[1], intMes, 0).getDate()}`;

      this.setState({ fechaInicio: datFechaInicio, fechaTermino: datFechaTermino })
    }

    if (this.props.store.auth.usuarioSesion.rol.codigo === 7) {
      this.props.guardar_menu_ejecutivo_completo(1);
    }

    showElementsMenu();

    if (reducer.cargandoSpinnerTransparente) {
      this.props.mostrar_spinner("transparente");
    } else {
      this.props.mostrar_spinner("naranjo");
    }

    // Obtener canal venta storage
    const canalVentaBusqueda = Number(sessionStorage.getItem(STORAGE_ITEM_FILTRO_CANAL_VENTA));
    if (canalVentaBusqueda) {
      this.setState({ canalVenta: canalVentaBusqueda });
      this.props.guardar_canal_venta_busqueda(canalVentaBusqueda);
    }

    // Validar canal venta seleccionado
    if (
      ldap.canalVentaBusqueda &&
      (auth.usuarioSesion.rol.codigo !== COD_EJECUTIVO_COMPLETO && auth.usuarioSesion.rol.codigo !== COD_CORREDOR)
    )
      this.setState({ canalVenta: ldap.canalVentaBusqueda });

    // Regiones
    if (reducer.regiones === null || reducer.regiones.length < VALOR_UNO) {
      let dbRegiones = await obtenerRegiones();
      this.props.guardar_regiones(dbRegiones);
      this.setState({ regiones: dbRegiones });
    } else {
      this.setState({ regiones: reducer.regiones });
    }

    // Canales venta
    if (
      reducer.canalesVenta === null ||
      reducer.canalesVenta.length < VALOR_UNO
    ) {
      let dbCanalesVenta = await obtenerCanalesVenta();
      this.props.guardar_canales_venta(dbCanalesVenta);
      this.setState({ canalesVenta: dbCanalesVenta });
    } else {
      this.setState({ canalesVenta: reducer.canalesVenta });
    }
    // Estados negocio
    if (
      reducer.estadosNegocios === null ||
      reducer.estadosNegocios.length < VALOR_UNO
    ) {
      let dbEstadosNegocios = await obtenerEstadosNegocios();
      this.props.estados_negocios(dbEstadosNegocios);
      this.setState({ estadosNegocios: dbEstadosNegocios });
    } else {
      this.setState({
        estadosNegocios: reducer.estadosNegocios,
      });
    }

    // Ejecutivos
    const rutJefatura = auth.usuarioSesion.rut;
    let dbEjecutivos = await obtenerEjecutivosPorRutJefatura(rutJefatura);
    this.props.guardar_ejecutivos(dbEjecutivos);
    this.setState({ ejecutivos: dbEjecutivos });

    // Negocios
    if (reducer.negocios === null || reducer.negocios === undefined ||reducer.negocios.length === VALOR_ZERO) {
      await this.obtenerNegociosPeriodo();
    } else {
      this.setState({
        negociosBusqueda: reducer.negocios,
        negociosFiltros: reducer.negocios,
        negocios: reducer.negocios,
      });
    }
    this.props.mostrar_spinner("ninguno");
    let ruta = sessionStorage.getItem(STORAGE_ITEM_RUTA);
    let idNegocio = sessionStorage.getItem(STORAGE_ITEM_NEGOCIO);

    if (ruta === RUTA_COTIZACIONES && idNegocio)
      this.props.history.push("/cotizaciones/inicio");
    else sessionStorage.setItem(STORAGE_ITEM_RUTA, "negocios");

    if (!sessionStorage.getItem(STORAGE_ITEM_SESION))
      return this.props.history.push(RUTA_INICIO_SESION);
  }

  obtenerNegociosPeriodo = async (canalVenta = VALOR_ZERO) => {
    this.props.mostrar_spinner("transparente");
    this.setState({
      negociosBusqueda: [],
      negocios: [],
    });

    const rutRol =
      this.props.store.auth.usuarioSesion.rol.codigo === COD_JEFATURA
        ? "rutJefatura"
        : "rutEjecutivo";

    const data = {
      negocio: {
        vigente: true,
        canalVenta: {
          idCanalVenta: canalVenta ? canalVenta : this.state.canalVenta,
        },
      },
      fechaInicio: this.state.fechaInicio,
      fechaTermino: this.state.fechaTermino,
      [rutRol]: this.props.store.auth.usuarioSesion.rut,
      perfil: PERFIL_LDAP,
    };

    if (data.negocio.canalVenta.idCanalVenta) {
      if (this.props.store.auth.usuarioSesion.rol.codigo === COD_CORREDOR) {
        data.usuCodCorredor = this.props.store.auth.usuarioSesion.id;
        data.perfil = PERFIL_CORREDOR;
      }

      let dbNegocios = await obtenerNegocios(
        data,
        this.props.store.reducer.ejecutivos
      );
      if (dbNegocios) {
        await this.props.guardar_negocios(dbNegocios);
        this.setState({
          negociosFiltros: dbNegocios,
          negociosBusqueda: dbNegocios,
          negocios: dbNegocios,
        });
      } else {
        return notificacion("warning", NO_DATA_NEGOCIOS);
      }
    }

    this.props.mostrar_spinner("ninguno");
  };

  handleSearch = (negocios, tipo) => {
    this.setState({ buscando: true });

    if (tipo === "filtro") {
      this.setState(
        {
          negociosFiltros: negocios,
          negociosBusqueda: negocios,
        },
        () => this.setState({ buscando: false })
      );
    }
    if (tipo === "busqueda") {
      this.setState({ negociosBusqueda: negocios }, () =>
        this.setState({ buscando: false })
      );
    }
  };

  handlePeriod = (fechaInicio, fechaTermino) => {
    this.setState({ fechaInicio, fechaTermino }, () => {
      this.props.mostrar_spinner("transparente");
      this.obtenerNegociosPeriodo();
    });
  };

  handleCanaVenta = (canalVenta) => {
    this.setState({ canalVenta });
    this.props.guardar_canal_venta_busqueda(canalVenta);
    this.obtenerNegociosPeriodo(canalVenta);
    sessionStorage.setItem(STORAGE_ITEM_FILTRO_CANAL_VENTA, canalVenta);
  };

  obtenerKeyTipoNegocio = (k) => {
    this.setState({ key: k, tipoNegocio: k }, () => {
      this.obtenerNegociosPeriodo();
    });
  };

  render() {

    let { escogerIncorporacion } = this.props.store.auth;

    return (
      <React.Fragment>
        {this.props.store.reducer.cargandoSpinnerNaranjo ? (
          <div
            style={{ height: this.props.store.reducer.DOM.height - 150 + "px" }}
          >
            <Spinner
              claseColor="naranjo"
              idSpinner="spinnerNaranjo"
              posicionLogo="align-self-center w-100"
            />
          </div>
        ) : this.props.store.reducer.cargandoSpinnerTransparente ? (
          <div
            style={{ height: this.props.store.reducer.DOM.height - 150 + "px" }}
          >
            <Spinner
              claseColor="transparente"
              idSpinner="spinnerTransparente"
              posicionLogo="posicionLogo align-self-center w-100"
            />
          </div>
        ) : (
          <div className="animated fadeIn">
            <div className="row">
              <div className="col-9 text-muted">
                <h4 className="font-weight-bold mb-0">Negocios</h4>
                <p>Cotizaciones actuales de nuestros clientes</p>
              </div>
              <div className="col-3 cursor text-right">
                <button
                  className="actualizar text-right mt-4 mb-1"
                  onClick={async () => {
                    this.props.mostrar_spinner("transparente");
                    this.obtenerNegociosPeriodo();
                    setTimeout(() => {
                      this.props.mostrar_spinner("ninguno");
                    }, 300);
                  }}
                  style={{
                    height: 40,
                  }}
                >
                  <i
                    className="fa fa-redo fa-2x text-warning"
                    aria-hidden="true"
                  ></i>
                  <span className="ml-2">Actualizar</span>
                </button>
              </div>
              {escogerIncorporacion && <EjecutarSeleccionIncorporacion />}
            </div>

            <Filtros
              canalesVenta={this.state.canalesVenta}
              canalVenta={this.state.canalVenta}
              tipoNegocio={this.state.tipoNegocio}
              negocioxO={this.state.negocios}
              negociox={this.state.negociosFiltros}
              ejecutivos={this.state.ejecutivos}
              estadosNegocios={this.state.estadosNegocios}
              handleSearch={this.handleSearch}
              handlePeriod={this.handlePeriod}
              handleCanaVenta={this.handleCanaVenta}
              className="mb-4"
            ></Filtros>

            <div className="card shadow mb-3">
              {!this.state.canalVenta ? (
                <div className="card-body">
                  <div className="row">
                    <div className="mx-auto my-5 animated fadeIn">
                      <h4 className="text-center">
                        Seleccione <strong>Canal de venta</strong>
                      </h4>
                      <img
                        src={ImgCanalVenta}
                        className="d-block mx-auto mb-2"
                        alt="No hay resultados"
                        height="70px"
                      />
                    </div>
                  </div>
                </div>
              ) : (
                !this.state.buscando && (
                  <React.Fragment>
                    {/* NEGOCIOS - CANAL VENTA DIGITAL [1] (5/50) */}
                    {this.state.canalVenta === VALOR_UNO ? (
                      <NegociosCanalDigital
                        className="animated fadeIn"
                        obtenerKeyTipoNegocio={this.obtenerKeyTipoNegocio}
                        key_={this.state.key}
                        buscando={this.state.buscando}
                        negociosFiltros={this.state.negociosFiltros}
                        negociosBusqueda={this.state.negociosBusqueda}
                        tipoNegocio={this.state.tipoNegocio}
                      />
                    ) : (
                      <NegociosCanalVentaDirecta
                        className="animated fadeIn"
                        obtenerKeyTipoNegocio={this.obtenerKeyTipoNegocio}
                        key_={this.state.key}
                        buscando={this.state.buscando}
                        negociosFiltros={this.state.negociosFiltros}
                        negociosBusqueda={this.state.negociosBusqueda}
                        tipoNegocio={this.state.tipoNegocio}
                      />
                    )}
                  </React.Fragment>
                )
              )}
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state,
});

export default withRouter(
  connect(mapStateToProps, {
    guardar_canal_venta_busqueda,
    guardar_regiones,
    guardar_negocios,
    guardar_ejecutivos,
    guardar_canales_venta,
    guardar_menu_ejecutivo_completo,
    estados_negocios,
    tipo_negocio,
    mostrar_spinner,
    guardar_filtro_solicitudes,
    limpiar_filtro_solicitudes,
  })(Negocios)
);
