import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { notificacion } from "../../../../transversales/Notificaciones";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  EXITO_AGREGAR_ASEGURADO,
  ERROR_AGREGAR_ASEGURADO,
  CAMPO_REQUERIDO,
  VALIDACION_RUT,
  VALIDACION_NOMBRE,
  VALIDACION_APELLIDO_P,
  VALIDACION_MAIL,
  MASK_DATE,
  TIPO_INCORPORACION_MANTENCION,
  ERROR_ENVIO_AUTOMATICO,
  PERFIL_DELEGADO,
  ERROR_ASEGURADO_REPETIDO,
  COD_STATUS_NA,
  ID_ZERO,
  VALOR_ZERO,
  FECHA_FUERA_VIGENCIA,
  CHAR_S,
  VALOR_UNO,
  STRING_VACIO,
  NUMERO,
  ERROR_EDICION,
  ERROR_ASIGNACION_SUCURSAL,
  COD_DELEGADO,
  COD_CORREDOR,
  RUT_DELEGADO,
  USU_CORREDOR,
  VALIDACION_APELLIDO_M,
  VALIDACION_APELLIDO_M_MAXIMO,
  VALIDACION_APELLIDO_P_MAXIMO,
  VALIDACION_NOMBRE_MAXIMO,
  VALIDACION_TELEFONO,
  COD_EJECUTIVO_MANTENCION,
  RUT_EJECUTIVO,
  PERFIL_LDAP,
  VALOR_NUEVE,
  RUT_SOLICITUD_DUPLICADO,
  DOCUMENT_TYPE_TEMPORAL,
  COD_EJECUTIVO_COMPLETO,
  RUTA_ABSOLUTA_INCORPORACION,
  VALOR_DOS,
  PERFIL_CORREDOR,
  ID_PERSONA_CERO,
  COD_STATUS_ERROR,
  VALIDACION_POLIZA,
  VALOR_INICIAL_CONTADOR,
  POSICION_INICIAL,
  SUMAR_UNO_CONTADOR,
  PRIMERA_POSICION_LISTA,
  VALIDACION_SIN_CORREO,
  VALIDACION_SIN_TELEFONO,
  LARGO_TELEFONO_VALIDO,
  LARGO_TELEFONO_VACIO,
  VALIDA_TELEFONO,
  TELEFONO_MOVIL,
  CORREO,
  ACCESO_ENVIADO_A,
  LARGO_CERO_COTIZACION,
  ESTADO_NEGOCIO_CERRADO,
  VALOR_GUION,
  ERROR_ACTUALIZAR_NEGOCIO,
  ERROR_BUSCAR_DELEGADO,
  ERROR_ASIGNACION_ACEPTACION,
  ERROR_CREAR_COTIZACION,
  SIGNO_BARRA,
  SIGNO_GUION,
  ERROR_CREAR_NEGOCIO,
  SEGUNDA_POSICION,
  TERCERA_POSICION,
  TIPO_CANAL_VENTA_CORREDOR,
  GA_CATEGORIA_ASEGURABLE,
  GA_LABEL_INDIVIDUAL_NUEVO_NEGOCIO,
} from "../../../../../../utils/Constantes";
import {
  arrayToFormattedString,
  formatTel,
  soloLetras,
} from "../../../../../../utils/Functions";
import {
  guardar_asegurados_delegado,
  guardar_cotizacion,
  actualizar_asegurables,
  guardar_negocios,
  guardar_menu_ejecutivo_completo,
} from "../../../../../../actions/actions";
import {
  enviarMailAsegurable,
  crearAsegurable,
  listarMisSolicitudes,
} from "../../../../../../services/asegurable.services";
import {ModalCantidadAsegurables}from "./ModalCantidadAsegurables";
import es from "date-fns/locale/es";
import DatePicker, { registerLocale } from "react-datepicker";
import MaskedTextInput from "react-text-mask";
import "react-datepicker/dist/react-datepicker.css";
import SelectContratante from "../SelectContratante";
import MultiSelect from "../../../../transversales/MultiSelect";
import {
  crearNegocio,
  asignarSucursal,
  actualizarNegocio,
  obtenerPersonaPorRut,
  obtenerNegocioDelegado,
  asignarAceptacionNegocio,
} from "../../../../../../services/negocios.service";
import { crearCotizacionPoliza } from "../../../../../../services/poliza.service";
import moment from "moment";
import Spinner from "../../../../transversales/Spinner";
import { loadingAction } from "../../../../../../reducer/delegadoMantencion";
import CargarArchivo, {
  enviarArchivos,
} from "../../../../transversales/CargarArchivo";
import DeclaracionAceptacion from "../../DeclaracionAceptacion";
import { buscarPorRut } from "../../../../../../services/bus.empresa.services";
import { actualizarUsuarioRevisor } from "../../../../../../services/bus.poliza.services";
import { buscarPorRutEmpresa } from "../../../../transversales/negocio";
import { listarColaboradoresPorNroPolizaNroRenovacion } from "../../../../../../services/bus.persona.services";
import { crearEventoTraza } from "../../../../../../utils/analitica";

registerLocale("es", es);

const { format, validate, clean } = require("rut.js");

class CargaIndividualMantencionIncorporacion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idNegocio: 0,
      polizas: [],
      polizasSeleccionadas: [],
      fechaInicioVigencia: null,
      diasInclusion: 0,
      solicitudes: [],
      data: {
        polizas: [],
        rut: "",
        nombres: "",
        apellidoMaterno: "",
        apellidoPaterno: "",
        email: "",
        telefonoMovil: "",
      },
      modalCantidadAsegurables: false,
      maximoAsegurables: null,
      polizasFechaErronea: [],
      stringPolizasFechaErronea: "",
      cargandoAsegurable: false,
      rutContratante: "",
      arrayListaDoctos: [],
      cargaArchivo: false,
      mostrarArchivosCargados: true,
      minFechaVigencia: "",
      maxFechaVigencia: "",
      checkedMovRetro: false,
      nombreDocumento: "DOCTO_TEMPORAL",
      aceptacion:
        this.props.store.auth.usuarioSesion.rol.codigo ===
          COD_EJECUTIVO_MANTENCION ||
        this.props.store.auth.usuarioSesion.rol.codigo ===
          COD_EJECUTIVO_COMPLETO,
      limpiarArchivos: false,
      limpiarAceptacion: false,
      busquedaPlan: false,
      contador: VALOR_INICIAL_CONTADOR,
      bloquearBusquedaPlan: true,
    };
  }

  componentDidMount() {

    crearEventoTraza(GA_CATEGORIA_ASEGURABLE, GA_LABEL_INDIVIDUAL_NUEVO_NEGOCIO);
    this.obtenerMes();
    if (
      this.props.location.pathname.includes(RUTA_ABSOLUTA_INCORPORACION) &&
      this.props.store.auth.usuarioSesion.rol.codigo !== COD_EJECUTIVO_COMPLETO
    ) {
      this.props.guardar_menu_ejecutivo_completo(VALOR_ZERO);
    } else {
      this.props.guardar_menu_ejecutivo_completo(VALOR_DOS);
    }
  }

  obtenerPolizas = async (
    idNegocio,
    polizas,
    polizasFiltro,
    rutContratante
  ) => {
    this.setState({
      bloquearBusquedaPlan: true,
    });
    const { usuarioSesion } = this.props.store.auth;

    const campoPerfil =
      usuarioSesion.rol.codigo === COD_DELEGADO
        ? RUT_DELEGADO
        : usuarioSesion.rol.codigo === COD_EJECUTIVO_MANTENCION ||
          usuarioSesion.rol.codigo === COD_EJECUTIVO_COMPLETO
        ? RUT_EJECUTIVO
        : USU_CORREDOR;

    const dataEnvioSolicitudes = {
      [campoPerfil]:
        usuarioSesion.rol.codigo === COD_DELEGADO ||
        usuarioSesion.rol.codigo === COD_EJECUTIVO_MANTENCION ||
        usuarioSesion.rol.codigo === COD_EJECUTIVO_COMPLETO
          ? usuarioSesion.rut
          : usuarioSesion.id,
      rutContratante: Number(rutContratante.split("-")[0]),
      idTipoIncorporacion: TIPO_INCORPORACION_MANTENCION,
      fechaEnvioDesde: null,
      fechaEnvioHasta: null,
      polizaVigente: true,
      perfil:
        usuarioSesion.rol.codigo === COD_DELEGADO
          ? PERFIL_DELEGADO
          : PERFIL_LDAP,
    };

    const solicitudes = await listarMisSolicitudes(dataEnvioSolicitudes);
    polizas.forEach((poliza) => {
      if (
        poliza.poliza.agrupaciones &&
        poliza.poliza.agrupaciones.length > POSICION_INICIAL
      ) {
        this.setState({
          bloquearBusquedaPlan: false,
        });
      }
    });
    if (solicitudes) {
      this.setState({ idNegocio, polizas, solicitudes, rutContratante });
    } else {
      notificacion("danger", ERROR_EDICION);
    }
    if (this.state.bloquearBusquedaPlan) {
      this.setState({
        busquedaPlan: false,
        contador: VALOR_INICIAL_CONTADOR,
      });
    }
  };

  obtenerGruposDeNegocioSuscripcion = async (objDataNegocio) => {
    let objDetalleNegocio = await obtenerNegocioDelegado(objDataNegocio);
    let lstGrupos = [];

    this.state.polizasSeleccionadas.forEach((objPoliza) => {
      if (objPoliza.poliza.polizaAgrupada) {
        this.state.polizasSeleccionadas.push({
          poliza: objPoliza.poliza.polizaAgrupada,
          idContratoSeguroGrupo:
            objPoliza.poliza.polizaAgrupada.grupos[POSICION_INICIAL]
              .idContratoSeguroGrupo,
          value: objPoliza.poliza.polizaAgrupada.numeroPoliza,
          label: `${
            objPoliza.poliza.agrupaciones[POSICION_INICIAL]
              ? objPoliza.poliza.agrupaciones[POSICION_INICIAL].glosa
              : objPoliza.poliza.numeroPoliza
          }`,
          dataTooltip: objPoliza.dataTooltip,
        });
      }
    });

    // Polizas seleccionadas
    if (objDetalleNegocio) {
      this.state.polizasSeleccionadas.forEach((objPoliza) => {
        objDetalleNegocio.cotizaciones.forEach((objCotizacion) => {
          if (
            objPoliza.poliza.codigoCotizacion === objCotizacion.codigoCotizacion
          ) {
            objCotizacion.grupos.forEach((objGrupo) => {
              if (
                objGrupo.idContratoSeguro ===
                  objPoliza.poliza.idContratoSeguro &&
                objPoliza.poliza.grupos &&
                objPoliza.poliza.grupos.length > 0 &&
                objGrupo.idContratoSeguroGrupo ===
                  objPoliza.poliza.grupos[POSICION_INICIAL]
                    .idContratoSeguroGrupo
              ) {
                lstGrupos.push({
                  idGrupo: objGrupo.idGrupo,
                  idContratoSeguroGrupo:
                    objPoliza.poliza.grupos[POSICION_INICIAL]
                      .idContratoSeguroGrupo,
                  codigoCotizacion: objCotizacion.codigoCotizacion,
                });
              }
            });
          }
        });
      });
    }
    return lstGrupos;
  };

  obtenerIdPersonaDelegado = async (strRut) => {
    let objPersona = await obtenerPersonaPorRut(strRut, PERFIL_DELEGADO);

    return objPersona.idPersona;
  };

  actualizarNegocioSuscripcion = async (intIdNegocio) => {
    const { negocios, negocio } = this.props.store.reducer;
    const { usuarioSesion } = this.props.store.auth;
    const intIndiceNegocioActualizado = negocios.findIndex(
      ({ rutEmpresa }) => rutEmpresa === negocio.rutEmpresa
    );

    negocios[intIndiceNegocioActualizado].declaracionDelegado = true;

    const intIdPersona = await this.obtenerIdPersonaDelegado(usuarioSesion.rut);

    if (intIdPersona) {
      let intIdOrganizacionCorredor = VALOR_ZERO;

      if (usuarioSesion.rol.codigo === COD_CORREDOR) {
        const objOrganizacion = await buscarPorRut(usuarioSesion.rut);
        if (objOrganizacion && objOrganizacion.length > VALOR_ZERO) {
          intIdOrganizacionCorredor =
            objOrganizacion[VALOR_ZERO].idOrganizacion;
        }
      }

      const objData = {
        idPersonaDelegado: intIdPersona,
        perfil:
          usuarioSesion.rol.codigo === COD_EJECUTIVO_MANTENCION ||
          usuarioSesion.rol.codigo === COD_EJECUTIVO_COMPLETO
            ? PERFIL_LDAP
            : PERFIL_DELEGADO,
        idNegocio: intIdNegocio,
        declaracionDelegado: true,
        idOrganizacionCorredor: intIdOrganizacionCorredor,
      };
      let objRespActualizacion = actualizarNegocio(objData);

      if (objRespActualizacion) {
        this.props.guardar_negocios([...negocios]);
      } else {
        notificacion("danger", ERROR_ACTUALIZAR_NEGOCIO);
      }
      return objRespActualizacion;
    } else {
      notificacion("danger", ERROR_BUSCAR_DELEGADO);
    }

    return intIdPersona;
  };

  crearNegocioSuscripcion = async (objNegocio) => {
    const { negocios } = this.props.store.reducer;
    const { usuarioSesion } = this.props.store.auth;
    let strPerfil = PERFIL_DELEGADO;
    let blnEsDelegado = true;
    let blnEsCorredor = false;
    let blnEsEjecutivo = false;

    if (
      usuarioSesion.rol.codigo === COD_EJECUTIVO_MANTENCION ||
      usuarioSesion.rol.codigo === COD_EJECUTIVO_COMPLETO
    ) {
      strPerfil = PERFIL_LDAP;
      blnEsDelegado = false;
      blnEsCorredor = false;
      blnEsEjecutivo = true;
    }

    if (usuarioSesion.rol.codigo === COD_CORREDOR) {
      strPerfil = PERFIL_CORREDOR;
      blnEsDelegado = false;
      blnEsCorredor = true;
      blnEsEjecutivo = false;
    }

    objNegocio.perfil = strPerfil;

    if (blnEsEjecutivo) {
      let objRutSeparado = objNegocio.rut.split(VALOR_GUION);
      const objEjecutivo = objNegocio.polizas[
        POSICION_INICIAL
      ].colaboradores.ejecutivos.find(
        (ejecutivo) => ejecutivo.rut === usuarioSesion.rut
      );
      const objCanalVenta = { idCanalVenta: TIPO_CANAL_VENTA_CORREDOR };
      const objTipoIncorporacion = {
        idTipoIncorporacion: TIPO_INCORPORACION_MANTENCION,
      };
      const objEstadoNegocio = { idEstadoNegocio: ESTADO_NEGOCIO_CERRADO };
      const objRegion = {
        idRegion: objEjecutivo.direcciones[POSICION_INICIAL].region
          ? objEjecutivo.direcciones[POSICION_INICIAL].region.idRegion
          : null,
      };
      const blnDeclaracionDelegado = false;
      const intRutEmpresa = Number(objRutSeparado[POSICION_INICIAL]);
      const strDigitoVerificador = objRutSeparado[SEGUNDA_POSICION];
      const intIdUsuarioCrea = usuarioSesion.id;
      const intIdPersonaEjecutivo = usuarioSesion.idPersona;

      objNegocio = {
        ...objNegocio,
        canalVenta: objCanalVenta,
        tipoIncorporacion: objTipoIncorporacion,
        estadoNegocio: objEstadoNegocio,
        region: objRegion,
        declaracionDelegado: blnDeclaracionDelegado,
        rutEmpresa: intRutEmpresa,
        digitoVerificador: strDigitoVerificador,
        idUsuarioCrea: intIdUsuarioCrea,
        idPersonaEjecutivo: intIdPersonaEjecutivo,
      };
    }

    if (blnEsCorredor) {
      // Se busca la organizacion del corredor
      let intIdOrganizacionCorredor = VALOR_ZERO;
      const lstOrganizaciones = await buscarPorRut(usuarioSesion.rut);
      const objOrganizacion = Object.assign(
        {},
        ...lstOrganizaciones.map((objElemento) => ({ ...objElemento }))
      );

      if (objOrganizacion) {
        intIdOrganizacionCorredor = objOrganizacion.idOrganizacion;
      }

      let objRegion = { idRegion: VALOR_ZERO };
      let intIdPersonaDelegado = VALOR_ZERO;
      let intIdPersonaEjecutivo = VALOR_ZERO;

      for (const objPoliza of objNegocio.polizas) {
        const objColaboradores =
          await listarColaboradoresPorNroPolizaNroRenovacion(
            objPoliza.numeroPoliza,
            objPoliza.numeroRenovacion
          );
        intIdPersonaDelegado = objColaboradores.delegado
          ? objColaboradores.delegado.idPersona
          : VALOR_ZERO;
        let objPersonaEjecutivo = await objColaboradores.ejecutivos.find(
          (objEjecutivo) => {
            return objEjecutivo.personaPorTipo &&
              objEjecutivo.personaPorTipo.idPersonaGrupo === VALOR_NUEVE
              ? objEjecutivo
              : null;
          }
        );

        if (objPersonaEjecutivo) {
          intIdPersonaEjecutivo = objPersonaEjecutivo.idPersona;
          objRegion = {
            idRegion:
              objPersonaEjecutivo.direcciones[VALOR_ZERO].region.idRegion,
          };
        }
      }

      objNegocio = {
        ...objNegocio,
        idOrganizacionCorredor: intIdOrganizacionCorredor,
        region: objRegion,
        idPersonaDelegado: intIdPersonaDelegado,
        idPersonaEjecutivo: intIdPersonaEjecutivo,
      };

      objNegocio.polizas = objNegocio.polizas.map((objPoliza) => ({
        ...objPoliza,
        nroPoliza: objPoliza.numeroPoliza,
        nroRenovacion: objPoliza.numeroRenovacion,
      }));
    }

    if (blnEsDelegado) {
      let objRegion = { idRegion: VALOR_ZERO };
      let intIdPersonaEjecutivo = VALOR_ZERO;

      for (const objPoliza of objNegocio.polizas) {
        const objColaboradores =
          await listarColaboradoresPorNroPolizaNroRenovacion(
            objPoliza.numeroPoliza,
            objPoliza.numeroRenovacion
          );
        let objPersonaEjecutivo = await objColaboradores.ejecutivos.find(
          (objEjecutivo) => {
            return objEjecutivo.personaPorTipo &&
              objEjecutivo.personaPorTipo.idPersonaGrupo === VALOR_NUEVE
              ? objEjecutivo
              : null;
          }
        );

        if (objPersonaEjecutivo) {
          intIdPersonaEjecutivo = objPersonaEjecutivo.idPersona;
          objRegion = {
            idRegion:
              objPersonaEjecutivo.direcciones[VALOR_ZERO].region.idRegion,
          };
        }
      }

      objNegocio = {
        ...objNegocio,
        region: objRegion,
        idPersonaEjecutivo: intIdPersonaEjecutivo,
      };
    }

    if (!objNegocio.idPersonaEjecutivo) {
      objNegocio.idPersonaEjecutivo = ID_PERSONA_CERO;
    }
    if (!objNegocio.idPersonaDelegado) {
      objNegocio.idPersonaDelegado = ID_PERSONA_CERO;
    }

    const intIdNegocio = await crearNegocio(objNegocio);

    if (intIdNegocio && intIdNegocio !== VALOR_ZERO) {
      let objRespAsignacion = this.asignarSucursal(intIdNegocio, objNegocio);

      if (objRespAsignacion) {
        if (blnEsDelegado) {
          let objRespuesta = this.actualizarNegocioSuscripcion(intIdNegocio);

          if (!objRespuesta) {
            return null;
          }
        }

        if (blnEsCorredor) {
          const objNegocioMemoria = negocios.find(
            (objElemento) =>
              objElemento.idOrganizacion === objNegocio.idOrganizacion
          );

          const intIndiceNegocio = negocios.indexOf(objNegocioMemoria);

          if (
            !negocios[intIndiceNegocio].declaracionesPerfil ||
            negocios[intIndiceNegocio].declaracionesPerfil.length === VALOR_ZERO
          ) {
            let objRespuesta = this.asignarAceptacion(intIdNegocio, strPerfil);

            if (!objRespuesta) {
              return null;
            }
          }
        }

        return intIdNegocio;
      }
      return null;
    } else {
      return null;
    }
  };

  asignarAceptacion = async (idNegocio, strPerfil) => {
    const { negocios, negocio } = this.props.store.reducer;
    const { usuarioSesion } = this.props.store.auth;

    const data = {
      idCatalogoDeclaracionPerfil: VALOR_UNO,
      idNegocio,
      idPersona: usuarioSesion.idPersona,
      idUsuarioCrea: usuarioSesion.id
    };

    const objRespAsignarAceptacion = await asignarAceptacionNegocio(data,strPerfil);

    //actualizar reducer
    if (objRespAsignarAceptacion) {
      const objNegocioMemoria = negocios.find(
        (objElemento) => objElemento.idOrganizacion === negocio.idOrganizacion
      );
      const intIndiceNegocio = negocios.indexOf(objNegocioMemoria);
      const lstCopiaNegocios = [...negocios];
      lstCopiaNegocios[intIndiceNegocio].declaracionesPerfil = [
        { idCatalogoDeclaracionPerfil: VALOR_UNO },
      ];
      this.props.guardar_negocios(lstCopiaNegocios);
    } else {
      notificacion("danger", ERROR_ASIGNACION_ACEPTACION);
      return null;
    }
  };

  asignarSucursal = async (intIdNegocio, objNegocio) => {
    const { usuarioSesion } = this.props.store.auth;
    const objData = {
      idNegocio: intIdNegocio,
      rutEmpresa: objNegocio.rutEmpresa,
      digitoVerificador: objNegocio.digitoVerificador,
      idUsuarioCrea: usuarioSesion.id,
      perfil:
        usuarioSesion.rol.codigo === COD_EJECUTIVO_MANTENCION ||
        usuarioSesion.rol.codigo === COD_EJECUTIVO_COMPLETO
          ? PERFIL_LDAP
          : PERFIL_DELEGADO,
    };

    const objRespAsignacion = await asignarSucursal(objData);

    if (!objRespAsignacion) {
      notificacion("danger", ERROR_ASIGNACION_SUCURSAL);
      return null;
    }

    return objRespAsignacion;
  };

  crearCotizacionSuscripcion = async (idNegocio) => {
    let { polizasSeleccionadas } = this.state;
    const { usuarioSesion } = this.props.store.auth;
    let lstCotizacionPolizas = [];

    polizasSeleccionadas.forEach((objPolizaSeleccionada) => {
      const objDatosCotizacion = {
        codigoCotizacion: objPolizaSeleccionada.poliza.codigoCotizacion,
        codigoUsuario: usuarioSesion.id,
        idContratoSeguro: objPolizaSeleccionada.poliza.idContratoSeguro,
        idNegocio: idNegocio,
        nroGrupo: objPolizaSeleccionada.poliza.numeroPoliza.toString(),
        nroPoliza: objPolizaSeleccionada.poliza.numeroPoliza,
        nroRenovacion: objPolizaSeleccionada.poliza.numeroRenovacion,
      };

      lstCotizacionPolizas.push(objDatosCotizacion);
    });

    const perfil =
      usuarioSesion.rol.codigo === COD_EJECUTIVO_MANTENCION ||
      usuarioSesion.rol.codigo === COD_EJECUTIVO_COMPLETO
        ? PERFIL_LDAP
        : usuarioSesion.rol.codigo === COD_CORREDOR
        ? PERFIL_CORREDOR
        : PERFIL_DELEGADO;

    let lstResCotizaciones = await crearCotizacionPoliza(
      lstCotizacionPolizas,
      perfil
    );

    if (lstResCotizaciones) {
      lstResCotizaciones.forEach((objCotizacion) => {
        let objPoliza = polizasSeleccionadas.find(
          (objElemento) =>
            objCotizacion.idContratoSeguro ===
            objElemento.poliza.idContratoSeguro
        );

        objCotizacion.idContratoSeguroGrupo = objPoliza.idContratoSeguroGrupo;
      });

      lstResCotizaciones = lstResCotizaciones.map((objCotizacion) => {
        return {
          idGrupo: objCotizacion.idGrupo,
          idContratoSeguroGrupo: objCotizacion.idContratoSeguroGrupo,
          idContratoSeguro: objCotizacion.idContratoSeguro,
          codigoCotizacion: objCotizacion.codigoCotizacion,
        };
      });

      return lstResCotizaciones;
    } else {
      return null;
    }
  };

  crearAsegurado = async (objDataAsegurable, lstCotizaciones, intIdNegocio) => {
    let {
      delegado: { aseguradosDelegado },
    } = this.props.store.reducer;
    const { usuarioSesion } = this.props.store.auth;
    let { rol } = usuarioSesion;
    let strFecha = this.state.fechaInicioVigencia.split(SIGNO_BARRA);
    const datInicioVigencia = `${strFecha[TERCERA_POSICION]}-${strFecha[SEGUNDA_POSICION]}-${strFecha[POSICION_INICIAL]}`;

    lstCotizaciones.forEach((grupo) => {
      grupo.fechaInicioVigencia = datInicioVigencia;
    });

    const strRutLimpio = format(clean(objDataAsegurable.rut)).split(
      SIGNO_GUION
    );

    let objDataCrearAsegurable = {
      nombre: objDataAsegurable.nombres,
      apellidoMaterno: objDataAsegurable.apellidoMaterno,
      apellidoPaterno: objDataAsegurable.apellidoPaterno,
      correoElectronico: objDataAsegurable.email,
      telefonoMovil: objDataAsegurable.telefonoMovil,
      rut: Number(clean(strRutLimpio[POSICION_INICIAL])),
      digitoVerificador: strRutLimpio[SEGUNDA_POSICION],
      idUsuarioCrea: usuarioSesion.id,
      grupos: lstCotizaciones,
      solicitud: { idSolicitud: 0 },
      tipoIncorporacion: { idTipoIncorporacion: TIPO_INCORPORACION_MANTENCION },
    };

    let strPerfil = PERFIL_DELEGADO;
    let strCampoPerfil = RUT_DELEGADO;
    let strCampoPerfilValor = usuarioSesion.rut;

    if (
      rol.codigo === COD_EJECUTIVO_MANTENCION ||
      rol.codigo === COD_EJECUTIVO_COMPLETO
    ) {
      strPerfil = PERFIL_LDAP;
      strCampoPerfil = RUT_EJECUTIVO;
      strCampoPerfilValor = usuarioSesion.rut;
    }

    if (rol.codigo === COD_CORREDOR) {
      strPerfil = PERFIL_CORREDOR;
      strCampoPerfil = USU_CORREDOR;
      strCampoPerfilValor = usuarioSesion.id;
    }

    let objRespCrearAsegurable = await crearAsegurable(
      objDataCrearAsegurable,
      strPerfil
    );
    let objRespServicio = objRespCrearAsegurable.status;
    let objRespAsegurable = objRespCrearAsegurable.objeto;

    if (objRespAsegurable) {
      if (objRespAsegurable.idAsegurable) {
        let objDataEnviarArchivos = {
          key: "idAsegurable",
          value: objRespAsegurable.idAsegurable,
        };

        enviarArchivos(
          this.state.arrayListaDoctos,
          objDataEnviarArchivos,
          DOCUMENT_TYPE_TEMPORAL
        );
        this.actualizaRevisor(intIdNegocio);
        this.enviarCorreoAsegurado(objRespAsegurable);

        const objDataEnvioSolicitudes = {
          [strCampoPerfil]: strCampoPerfilValor,
          rutContratante: Number(this.state.rutContratante),
          idTipoIncorporacion: TIPO_INCORPORACION_MANTENCION,
          fechaEnvioDesde: null,
          fechaEnvioHasta: null,
          polizaVigente: true,
          perfil:
            usuarioSesion.rol.codigo === COD_DELEGADO
              ? PERFIL_DELEGADO
              : PERFIL_LDAP,
        };

        const lstSolicitudes = await listarMisSolicitudes(
          objDataEnvioSolicitudes
        );
        this.setState({ solicitudes: lstSolicitudes });

        let lstDelegadoAsegurados = Object.assign([], aseguradosDelegado);
        lstDelegadoAsegurados.push(objDataCrearAsegurable);

        this.props.guardar_asegurados_delegado(lstDelegadoAsegurados);
        this.props.actualizar_asegurables(lstDelegadoAsegurados);
        notificacion("success", EXITO_AGREGAR_ASEGURADO);
      } else {
        notificacion("danger", ERROR_AGREGAR_ASEGURADO);
      }
    } else if (objRespServicio.codigo === COD_STATUS_NA) {
      notificacion("warning", ERROR_ASEGURADO_REPETIDO);
    } else {
      notificacion("danger", ERROR_AGREGAR_ASEGURADO);
    }

    this.limpiarRegistros();
  };

  actualizaRevisor = async (idNegocio) => {
    const dataEnvio = {
      idNegocio,
      revisado: false,
    };
    let actualizarRevisor = await actualizarUsuarioRevisor(dataEnvio);
    if (actualizarRevisor === COD_STATUS_ERROR) {
      notificacion("danger", ERROR_EDICION);
    }
  };

  async limpiarRegistros() {
    await this.setState({
      limpiarArchivos: true,
      aceptacion:
        this.props.store.auth.usuarioSesion.rol.codigo ===
          COD_EJECUTIVO_MANTENCION ||
        this.props.store.auth.usuarioSesion.rol.codigo ===
          COD_EJECUTIVO_COMPLETO,
      limpiarAceptacion: true,
    });
  }

  async enviarDatosAsegurado(objValores) {
    let { polizasSeleccionadas } = this.state;
    let { negocio } = this.props.store.reducer;
    const { usuarioSesion } = this.props.store.auth;

    negocio = await buscarPorRutEmpresa(
      polizasSeleccionadas,
      negocio,
      usuarioSesion
    );
    let lstResCotizaciones = [];
    let intIdNegocio;

    if (!negocio.idNegocio || negocio.idNegocio === ID_ZERO) {
      intIdNegocio = await this.crearNegocioSuscripcion(negocio);

      if (intIdNegocio) {
        lstResCotizaciones = await this.crearCotizacionSuscripcion(
          intIdNegocio
        );
      } else {
        return notificacion("danger", ERROR_CREAR_NEGOCIO);
      }
    } else {
      intIdNegocio = negocio.idNegocio;
      let objDataEnvio = {
        idNegocio: intIdNegocio,
        perfil: usuarioSesion.rol.glosa.toUpperCase(),
      };
      lstResCotizaciones = await this.obtenerGruposDeNegocioSuscripcion(
        objDataEnvio
      );

      if (lstResCotizaciones.length === LARGO_CERO_COTIZACION) {
        lstResCotizaciones = await this.crearCotizacionSuscripcion(
          intIdNegocio
        );
      }
    }

    if (lstResCotizaciones) {
      // Crear el asegurable
      this.crearAsegurado(objValores, lstResCotizaciones, intIdNegocio);
    } else {
      return notificacion("danger", ERROR_CREAR_COTIZACION);
    }
  }

  enviarCorreoAsegurado = async (asegurable) => {
    if (asegurable !== null) {
      const dataCorreo = {
        asegurable: {
          idAsegurable: asegurable.idAsegurable,
          rut: asegurable.rut,
          digitoVerificador: asegurable.digitoVerificador,
        },
      };

      const perfil =
        this.props.store.auth.usuarioSesion.rol.codigo ===
          COD_EJECUTIVO_MANTENCION ||
        this.props.store.auth.usuarioSesion.rol.codigo ===
          COD_EJECUTIVO_COMPLETO
          ? PERFIL_LDAP
          : this.props.store.auth.usuarioSesion.rol.codigo === COD_CORREDOR
          ? PERFIL_CORREDOR
          : PERFIL_DELEGADO;

      const envioCorreo = await enviarMailAsegurable(dataCorreo, perfil);
      if (envioCorreo !== null) {
        notificacion(
          "success",
          `${ACCESO_ENVIADO_A} ${asegurable.nombre} ${asegurable.apellidoPaterno} ${asegurable.apellidoMaterno}`
        );
      } else {
        notificacion("warning", ERROR_ENVIO_AUTOMATICO);
      }
      return dataCorreo;
    } else {
      notificacion("warning", ERROR_ENVIO_AUTOMATICO);
    }
  };

  validarFechaVigencia = (value) => {
    if (value) {
      const fecha = value.split("/");
      const fechaInicioVigencia = `${fecha[2]}-${fecha[1]}-${fecha[0]}`;

      const polizasSeleccionadas = this.state.polizasSeleccionadas
        ? this.state.polizasSeleccionadas
        : [];

      const polizasFechaErronea = polizasSeleccionadas
        .filter(
          ({ poliza }) =>
            fechaInicioVigencia <
              moment(poliza.fechaInicioVigencia).format("YYYY-MM-DD") ||
            fechaInicioVigencia >
              moment(poliza.fechaFinVigencia).format("YYYY-MM-DD")
        )
        .map(({ poliza }) => poliza.numeroPoliza);

      const stringPolizasFechaErronea =
        arrayToFormattedString(polizasFechaErronea);

      this.setState({ polizasFechaErronea, stringPolizasFechaErronea });

      return polizasFechaErronea.length === VALOR_ZERO;
    }
  };

  llenarPolizasSeleccionadas(item) {
    this.setState(
      {
        polizasSeleccionadas: item,
        limpiarArchivos: false,
        limpiarAceptacion: false,
      },
      function () {
        this.cargarDatePicker(this.state.polizasSeleccionadas);
      }
    );
  }

  cargarDatePicker(value) {
    if (this.state.checkedMovRetro && value !== null && value.length > 0) {
      let diasInclusion = this.obtenerMinDiasInclusion();
      let startDates = value.map((item) => {
        return moment(
          moment(item.poliza.fechaInicioVigencia).format("YYYY-MM-DD"),
          "YYYY-MM-DD"
        );
      });
      let now = moment();
      let dia = Number(now.format("D"));
      let anio = now.year();
      let mes = now.month();
      if (dia <= diasInclusion) {
        let mesAnterior = moment(
          `${anio}-${mes}-01 00:00:00`,
          "YYYY-MM-DD hh:mm:ss"
        );
        this.setState({
          minFechaVigencia: moment(moment.min(startDates)).toDate(),
        });
        this.setState({
          maxFechaVigencia: moment(mesAnterior.clone().endOf("month")).toDate(),
        });
      } else {
        let fechaMaxima = moment(
          `${anio}-${mes + 1}-${diasInclusion} 00:00:00`,
          "YYYY-MM-DD hh:mm:ss"
        );
        this.setState({
          minFechaVigencia: moment(moment.min(startDates)).toDate(),
        });
        this.setState({
          maxFechaVigencia: moment(moment.min(fechaMaxima)).toDate(),
        });
      }
    } else {
      this.obtenerMes();
    }
  }

  obtenerMes() {
    if (!this.state.checkedMovRetro) {
      let diasInclusion = this.obtenerMinDiasInclusion();
      let fechaMaxVigencia = this.obtenerMaxFechaVigencia();
      fechaMaxVigencia = moment(fechaMaxVigencia).format("YYYY-MM-DD");

      let now = moment();
      let anio = now.year();
      let mes = now.month();
      let dia = Number(now.format("D"));

      if (dia <= diasInclusion) {
        let mesActual = moment(
          `${anio}-${mes + 1}-01 00:00:00`,
          "YYYY-MM-DD hh:mm:ss"
        );
        this.setState({ minFechaVigencia: mesActual.toDate() });
        this.setState({
          maxFechaVigencia: moment(
            `${fechaMaxVigencia} 00:00:00`,
            "YYYY-MM-DD hh:mm:ss"
          ).toDate(),
        });
      } else {
        let mesSiguiente = moment(
          `${anio}-${mes + 2}-01 00:00:00`,
          "YYYY-MM-DD hh:mm:ss"
        );
        this.setState({ minFechaVigencia: mesSiguiente.toDate() });
        this.setState({
          maxFechaVigencia: moment(
            `${fechaMaxVigencia} 00:00:00`,
            "YYYY-MM-DD hh:mm:ss"
          ).toDate(),
        });
      }
    }
  }

  obtenerMinDiasInclusion() {
    //obteniendo el menor dia de inclusion
    const polizasSeleccionadas = this.state.polizasSeleccionadas
      ? this.state.polizasSeleccionadas
      : [];
    let minDiasInclusion = 0;
    if (polizasSeleccionadas.length > 0) {
      minDiasInclusion = polizasSeleccionadas[0].poliza.diasInclusion;
      polizasSeleccionadas.forEach((element) => {
        if (element.poliza.diasInclusion < minDiasInclusion) {
          minDiasInclusion = element.poliza.diasInclusion;
        }
      });
    }
    return minDiasInclusion;
  }

  obtenerMaxFechaVigencia() {
    //obteniendo mayor fecha de vigencia entre polizas seleccionadas
    const polizasSeleccionadas = this.state.polizasSeleccionadas
      ? this.state.polizasSeleccionadas
      : [];
    let polizaFechaFinVigencia = 0;
    if (polizasSeleccionadas.length > 0) {
      polizasSeleccionadas.forEach((element) => {
        if (element.poliza.fechaFinVigencia > polizaFechaFinVigencia) {
          polizaFechaFinVigencia = element.poliza.fechaFinVigencia;
        }
      });
    }
    return polizaFechaFinVigencia;
  }

  rutUnico = (value) => {
    if (this.state.solicitudes && value) {
      const noEnrolados = this.state.solicitudes.filter(
        (item) => item.solicitud.fechaRecepcion === null
      );
      const asegurablesGrupo = noEnrolados.filter((asegurable) => {
        const incluyeGrupo = this.state.polizasSeleccionadas.find((poliza) => {
          const seleccionadoIncluyeGrupo = asegurable.grupos.find(
            (grupoS) =>
              grupoS.idContratoSeguroGrupo === poliza.idContratoSeguroGrupo
          );
          return seleccionadoIncluyeGrupo !== undefined;
        });
        return incluyeGrupo !== undefined;
      });

      const rutAsegurables = asegurablesGrupo.map((item) =>
        format(`${item.rut}-${item.digitoVerificador}`)
      );

      return !rutAsegurables.includes(format(value));
    }
  };

  mostrarModalCantidadAsegurables = () => {
    this.setState({
      modalCantidadAsegurables: !this.state.modalCantidadAsegurables,
    });
  };

  actualizarChecked = async () => {
    this.setState(
      { checkedMovRetro: !this.state.checkedMovRetro },
      function () {
        this.cargarDatePicker(this.state.polizasSeleccionadas);
      }
    );
  };

  llenarDocumentos = (documentos) => {
    if (documentos) {
      this.setState({ arrayListaDoctos: documentos });
    }
  };

  cargarAceptacion = (value) => {
    this.setState({ aceptacion: !value });
  };

  aceptarBusquedaPlan = () => {
    this.setState({
      busquedaPlan: !this.state.busquedaPlan,
      contador: this.state.contador + SUMAR_UNO_CONTADOR,
    });
  };

  render() {
    const validacionFecha = `${FECHA_FUERA_VIGENCIA}${
      this.state.polizasFechaErronea.length > VALOR_UNO ? CHAR_S : STRING_VACIO
    } ${NUMERO} ${this.state.stringPolizasFechaErronea}.`;

    const cargandoAsegurable = this.props.store.delegado.loading;

    const rolId = this.props.store.auth.usuarioSesion.rol.codigo;

    return (
      <React.Fragment>
        {cargandoAsegurable && (
          <Spinner
            claseColor="transparente"
            idSpinner="spinnerTransparente"
            posicionLogo="posicionLogo align-self-center w-100"
          />
        )}
        <ModalCantidadAsegurables
          modalCantidadAsegurables={this.state.modalCantidadAsegurables}
          mostrarModalCantidadAsegurables={this.mostrarModalCantidadAsegurables}
          maximoAsegurables={this.state.maximoAsegurables}
        />
        {!this.state.cargando ? (
          <div className="container">
            <div className="mx-5">
              <div className="row animated fadeInRight">
                <SelectContratante
                  obtenerPolizas={this.obtenerPolizas}
                  busquedaPlan={this.state.busquedaPlan}
                  contador={this.state.contador}
                />

                {this.state.idNegocio !== ID_ZERO && (
                  <Formik
                    enableReinitialize={true}
                    initialValues={{
                      idNegocio: this.state.idNegocio,
                      poliza: [],
                      rut: "",
                      nombres: "",
                      apellidoPaterno: "",
                      apellidoMaterno: "",
                      email: "",
                      telefonoMovil: "",
                      fechaInicioVigencia: "",
                    }}
                    validationSchema={Yup.object().shape(
                      {
                        poliza: Yup.array()
                          .required(CAMPO_REQUERIDO)
                          .nullable()
                          .test("validaPoliza", VALIDACION_POLIZA, (value) => {
                            if (!this.state.busquedaPlan) {
                              return true;
                            } else if (
                              value.length === PRIMERA_POSICION_LISTA
                            ) {
                              return true;
                            } else {
                              return false;
                            }
                          }),
                        rut: Yup.string()
                          .required(CAMPO_REQUERIDO)
                          .test("validarRut", VALIDACION_RUT, (value) => {
                            return validate(format(value));
                          })
                          .test(
                            "rutDuplicado",
                            RUT_SOLICITUD_DUPLICADO,
                            this.rutUnico
                          ),
                        nombres: Yup.string()
                          .min(1, VALIDACION_NOMBRE)
                          .max(25, VALIDACION_NOMBRE_MAXIMO)
                          .required(CAMPO_REQUERIDO)
                          .trim(),
                        apellidoPaterno: Yup.string()
                          .min(1, VALIDACION_APELLIDO_P)
                          .max(25, VALIDACION_APELLIDO_P_MAXIMO)
                          .required(CAMPO_REQUERIDO)
                          .trim(),
                        apellidoMaterno: Yup.string()
                          .min(1, VALIDACION_APELLIDO_M)
                          .max(25, VALIDACION_APELLIDO_M_MAXIMO)
                          .trim(),
                        email: Yup.string()
                          .ensure()
                          .when(TELEFONO_MOVIL, {
                            is: "",
                            then: Yup.string()
                              .email(VALIDACION_MAIL)
                              .required(VALIDACION_SIN_CORREO),
                          })
                          .email(VALIDACION_MAIL),
                        telefonoMovil: Yup.string()
                          .ensure()
                          .when(CORREO, {
                            is: "",
                            then: Yup.string()
                              .test(
                                VALIDA_TELEFONO,
                                VALIDACION_TELEFONO,
                                (value) => {
                                  return formatTel(value).length ===
                                    LARGO_TELEFONO_VALIDO ||
                                    formatTel(value).length ===
                                      LARGO_TELEFONO_VACIO
                                    ? true
                                    : false;
                                }
                              )
                              .required(VALIDACION_SIN_TELEFONO),
                          })
                          .test(
                            VALIDA_TELEFONO,
                            VALIDACION_TELEFONO,
                            (value) => {
                              return formatTel(value).length ===
                                LARGO_TELEFONO_VALIDO ||
                                formatTel(value).length === LARGO_TELEFONO_VACIO
                                ? true
                                : false;
                            }
                          ),
                        fechaInicioVigencia: Yup.string()
                          .required(CAMPO_REQUERIDO)
                          .test(
                            "validarFecha",
                            validacionFecha,
                            this.validarFechaVigencia
                          ),
                      },
                      [TELEFONO_MOVIL, CORREO]
                    )}
                    onSubmit={async (values, { resetForm }) => {
                      this.props.loadingAction(true);
                      values.nombres = values.nombres.trim();
                      values.apellidoMaterno = values.apellidoMaterno.trim();
                      values.apellidoPaterno = values.apellidoPaterno.trim();
                      values.telefonoMovil = formatTel(values.telefonoMovil);
                      this.enviarDatosAsegurado(values).then(() =>
                        this.props.loadingAction(false)
                      );
                      resetForm({});
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      setFieldValue,
                      setFieldTouched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      resetForm,
                      isSubmitting,
                      validating,
                      valid,
                    }) => {
                      return (
                        <Form
                          id="idFormIndividualMantencion"
                          className="col-md-12"
                          onKeyDown={(e) => {
                            if ((e.charCode || e.keyCode) === 13) {
                              e.preventDefault();
                            }
                          }}
                        >
                          <div className="row">
                            <div className="col-1 align-self-center">
                              <label className="switch">
                                <input
                                  checked={this.state.busquedaPlan}
                                  onChange={() => {
                                    this.aceptarBusquedaPlan();
                                    resetForm({});
                                  }}
                                  type="checkbox"
                                  disabled={this.state.bloquearBusquedaPlan}
                                />
                                <span className="slider round" />
                              </label>
                            </div>
                            <div
                              className="col-11"
                              style={{ paddingLeft: "0px" }}
                            >
                              <label> Búsqueda por Plan</label>
                            </div>
                          </div>
                          <div className="row">
                            {/* póliza */}
                            <div className="col-md-12">
                              <div className="form-group">
                                <label>Póliza</label>
                                <MultiSelect
                                  value={values.poliza}
                                  onChange={(value) => {
                                    this.llenarPolizasSeleccionadas(value);
                                    setFieldValue("poliza", value);
                                  }}
                                  tooltip={true}
                                  onBlur={setFieldTouched}
                                  name="poliza"
                                  id="poliza"
                                  options={this.state.polizas}
                                  noOptionsMessage={() =>
                                    "No existen más pólizas para este contratante"
                                  }
                                  placeholder={"Ingrese póliza/s"}
                                />
                                {errors.poliza && (
                                  <small className="text-danger animated fadeIn">
                                    {errors.poliza}
                                  </small>
                                )}
                              </div>
                            </div>
                            {/* rut */}
                            <div className="col-md-12">
                              <div className="form-group">
                                <label>Rut colaborador</label>
                  
                                <Field
                                  className="form-control"
                                  id="rut"
                                  name="rut"
                                  type="text"
                                  placeholder="ej. 12345678-9"
                                  maxLength="12"
                                  minLength="11"
                                  value={
                                    values.rut.length > 1
                                      ? format(values.rut)
                                      : values.rut
                                  }
                                />
                                {errors.rut && touched.rut && (
                                  <small className="text-danger animated fadeIn">
                                    {errors.rut}
                                  </small>
                                )}
                              </div>
                            </div>

                            {/* nombres */}
                            <div className="col-md-12">
                              <div className="form-group">
                                <label>Nombres</label>

                                <Field
                                  className="form-control"
                                  id="nombres"
                                  name="nombres"
                                  type="text"
                                  placeholder="Ingrese nombres"
                                  value={soloLetras(values.nombres)}
                                />
                                {errors.nombres && touched.nombres && (
                                  <small className="text-danger animated fadeIn">
                                    {errors.nombres}
                                  </small>
                                )}
                              </div>
                            </div>

                            {/* apellidoPaterno */}
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Apellido paterno</label>

                                <Field
                                  className="form-control"
                                  id="apellidoPaterno"
                                  name="apellidoPaterno"
                                  type="text"
                                  placeholder="Ingrese apellido paterno"
                                  value={soloLetras(values.apellidoPaterno)}
                                />
                                {errors.apellidoPaterno &&
                                  touched.apellidoPaterno && (
                                    <small className="text-danger animated fadeIn">
                                      {errors.apellidoPaterno}
                                    </small>
                                  )}
                              </div>
                            </div>

                            {/* apellidoMaterno */}
                            <div className="col-md-6">
                              <div className="form-group">
                                {/* {JSON.stringify(errors)} */}
                                <label>Apellido materno</label>
                                <Field
                                  className="form-control"
                                  id="apellidoMaterno"
                                  name="apellidoMaterno"
                                  type="text"
                                  placeholder="Ingrese apellido materno"
                                  value={soloLetras(values.apellidoMaterno)}
                                />
                                {errors.apellidoMaterno &&
                                  touched.apellidoMaterno && (
                                    <small className="text-danger animated fadeIn">
                                      {errors.apellidoMaterno}
                                    </small>
                                  )}
                              </div>
                            </div>

                            {/* email */}
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Email</label>
                                <Field
                                  className="form-control"
                                  id="email"
                                  name="email"
                                  type="text"
                                  placeholder="test@gmail.com"
                                  maxLength="50"
                                  value={values.email}
                                />
                                {errors.email && touched.email && (
                                  <small className="text-danger animated fadeIn">
                                    {errors.email}
                                  </small>
                                )}
                              </div>
                            </div>

                            {/* teléfono */}
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Teléfono móvil</label>
                                <Field
                                  className="form-control"
                                  id="telefonoMovil"
                                  name="telefonoMovil"
                                  type="text"
                                  placeholder="5691515151"
                                  maxLength="13"
                                  value={formatTel(values.telefonoMovil)}
                                />
                                {errors.telefonoMovil &&
                                  touched.telefonoMovil && (
                                    <small className="text-danger animated fadeIn">
                                      {errors.telefonoMovil}
                                    </small>
                                  )}
                              </div>
                            </div>

                            {/* Movimiento Retroactivo */}
                            {rolId === COD_EJECUTIVO_MANTENCION ||
                            rolId === COD_EJECUTIVO_COMPLETO ? (
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label>Movimiento Retroactivo</label>
                                  <label
                                    className="switch"
                                    style={{ marginLeft: "10px" }}
                                  >
                                    <input
                                      name="movRetroactivo"
                                      type="checkbox"
                                      checked={this.state.checkedMovRetro}
                                      onChange={this.actualizarChecked}
                                    />
                                    <span className="slider round" />
                                  </label>
                                </div>
                              </div>
                            ) : null}

                            {/* Fecha Inicio Vigencia */}
                            <div className="col-md-12">
                              <div className="form-group up-calendar">
                                <label>Fecha Inicio Vigencia Propuesta</label>

                                <DatePicker
                                  autoComplete="off"
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  id="fechaInicioVigencia"
                                  name="fechaInicioVigencia"
                                  value={values.fechaInicioVigencia}
                                  dateFormat="dd/MM/yyyy"
                                  placeholderText="DD/MM/YYYY"
                                  locale="es"
                                  onBlur={handleBlur}
                                  onChange={(value) => {
                                    value = moment(value).format("DD/MM/YYYY");
                                    this.setState({
                                      fechaInicioVigencia: value,
                                    });
                                    setFieldValue("fechaInicioVigencia", value);
                                  }}
                                  customInput={
                                    <MaskedTextInput
                                      className="form-control"
                                      placeholder="DD/MM/YYYY"
                                      mask={MASK_DATE}
                                    />
                                  }
                                  minDate={this.state.minFechaVigencia}
                                  maxDate={this.state.maxFechaVigencia}
                                />

                                {errors.fechaInicioVigencia &&
                                  touched.fechaInicioVigencia && (
                                    <small className="text-danger animated fadeIn">
                                      {errors.fechaInicioVigencia}
                                    </small>
                                  )}
                              </div>
                            </div>

                            {/* Adjuntar Documentos */}
                            <div className="col-md-12">
                              <div className="form-group">
                                <label>Adjuntar documentos</label>
                                <CargarArchivo
                                  children={null}
                                  maxFiles={3}
                                  minFiles={1}
                                  filename={this.state.nombreDocumento}
                                  files={this.llenarDocumentos}
                                  fromCargas={false}
                                  cleanFiles={this.state.limpiarArchivos}
                                />
                              </div>
                            </div>

                            {/* Declaracion Aceptacion */}
                            <DeclaracionAceptacion
                              aceptacion={this.cargarAceptacion}
                              rolId={rolId}
                              codPerfil={[COD_CORREDOR, COD_DELEGADO]}
                              limpiar={this.state.limpiarAceptacion}
                            />
                          </div>

                          <div className="button-zone text-right pt-3 pb-3">
                            <button
                              id="botonGuardar"
                              className="btn btn-principal mr-3"
                              type="submit"
                              disabled={!this.state.aceptacion}
                            >
                              Guardar
                            </button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="d-flex justify-content-center animated fadeIn my-5">
            <div className="spinner-border text-primary mb-5" rol="status">
              <span className="sr-only">Cargando...</span>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state,
});

export default withRouter(
  connect(mapStateToProps, {
    guardar_asegurados_delegado,
    guardar_cotizacion,
    actualizar_asegurables,
    guardar_negocios,
    loadingAction,
    guardar_menu_ejecutivo_completo,
  })(CargaIndividualMantencionIncorporacion)
);
