import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import ModalTipoCanal from "./ModalTipoCanal";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import CabeceraWizardCrearNegocio from "./CabeceraWizardCrearNegocio";
import CDatosEmpresa from "./CDatosEmpresa";
import CDatosSucursal from "./CDatosSucursal";
import CDatosDelegado from "./CDatosDelegado";
import ModalCarga from "./ModalCarga";
import {
  guardar_canal_venta,
  guardar_wizard_negocio_ldap,
  cambiar_wizard_actual,
  guardar_negocio_ldap_consulta,
  guardar_sucursal_ldap_consulta,
} from "../../../../../../reducer/ldap";
import {
  TIPO_CANAL_VENTA_DIRECTA,
  TIPO_CANAL_VENTA_CORREDOR,
  PERFIL_LDAP,
  STORAGE_ITEM_RUTA,
  STORAGE_ITEM_NEGOCIO,
  RUTA_NEGOCIOS,
  VALOR_UNO,
  VALOR_ZERO,
  GA_CATEGORIA_NEGOCIOS,
  GA_LABEL_CREAR_NEGOCIO,
} from "../../../../../../utils/Constantes";
import {
  P_WZ1,
  P_WZ2,
  P_WZ3,
  WZ1,
  WZ2,
  WZ3,
  EXITO_EMPRESA_EXISTENTE,
  FEMENINO,
  MASCULINO,
  MESSAGE_SEND,
  EXITO_EMPRESA_CREADA,
  STORAGE_SUCURSAL,
  STORAGE_EMPRESA,
  STORAGE_WIZARD,
  STORAGE_CANAL_VENTA,
  STORAGE_WIZARD_ACTUAL,
  INITIAL_STATE_SUCURSAL,
  WIZARD_NEGOCIO_VENTA_DIRECTA,
  R_CREAR_NEGOCIO,
} from "../../../../../../utils/ConstantesVentaDirecta";
import CryptoJS from "react-native-crypto-js";
import {
  crearNegocio,
  obtenerNegocio,
  actualizarConfiguracionNegocio,
  actualizarConfiguracionSucursal,
  enviarOrganizacionSucursalEjecutivo,
  actualizarConfiguracionDelegado,
} from "../../../../../../services/negocios.service";
import { asignarEjecutivo } from "../../../../../../services/cotizacion.services";
import { notificacion } from "../../../../transversales/Notificaciones";
import moment from "moment";
import { crearEventoTraza } from "../../../../../../utils/analitica";

const { format } = require("rut.js");

const CrearNegocio = (props) => {
  const [canalVenta, setCanalVenta] = useState(null);
  const [loading, setLoading] = useState(false);
  const [messageLoading, setMessageLoading] = useState(null);

  const { ldap, auth } = useSelector((store) => store);
  const { wizard, wizardActual } = ldap;
  const dispatch = useDispatch();

  /**************************
   *  VALIDAR STORAGE       *
   **************************/
  const limpiarStorage = () => {
    sessionStorage.removeItem(STORAGE_EMPRESA); // Formulario empresa
    sessionStorage.removeItem(STORAGE_SUCURSAL); // Formulario sucursal
    sessionStorage.removeItem(STORAGE_CANAL_VENTA); // Canal venta seleccionado
    sessionStorage.removeItem(STORAGE_WIZARD_ACTUAL); // Configuración wizard
    sessionStorage.removeItem(STORAGE_WIZARD); // Wizard actual

    setTimeout(() => {
      dispatch(guardar_canal_venta(null));
      dispatch(guardar_negocio_ldap_consulta(null));
      dispatch(guardar_sucursal_ldap_consulta(INITIAL_STATE_SUCURSAL));
      dispatch(guardar_wizard_negocio_ldap(WIZARD_NEGOCIO_VENTA_DIRECTA));
      dispatch(cambiar_wizard_actual(VALOR_UNO));
    }, 1000);
  };

  const obtenerDataStorageEmpresa = useCallback(() => {
    // Decrypt Data empresa
    if (sessionStorage.getItem(STORAGE_EMPRESA)) {
      let dataEcrypt = CryptoJS.AES.decrypt(
        JSON.parse(sessionStorage.getItem(STORAGE_EMPRESA)),
        process.env.REACT_APP_HAS_CRYPT
      );
      let decryptedData = JSON.parse(dataEcrypt.toString(CryptoJS.enc.Utf8));
      dispatch(guardar_negocio_ldap_consulta(decryptedData));
    }
  }, [dispatch]);

  const obtenerDataStorageSucursal = useCallback(() => {
    // Decrypt Data sucursal
    if (sessionStorage.getItem(STORAGE_SUCURSAL)) {
      let dataEcrypt = CryptoJS.AES.decrypt(
        JSON.parse(sessionStorage.getItem(STORAGE_SUCURSAL)),
        process.env.REACT_APP_HAS_CRYPT
      );
      let decryptedData = JSON.parse(dataEcrypt.toString(CryptoJS.enc.Utf8));
      dispatch(guardar_sucursal_ldap_consulta(decryptedData));
    }
  }, [dispatch]);

  const obtenerDataStorageWizard = useCallback(() => {
    // Decrypt Data wizard
    if (sessionStorage.getItem(STORAGE_WIZARD)) {
      let dataEcrypt = CryptoJS.AES.decrypt(
        JSON.parse(sessionStorage.getItem(STORAGE_WIZARD)),
        process.env.REACT_APP_HAS_CRYPT
      );
      let decryptedData = JSON.parse(dataEcrypt.toString(CryptoJS.enc.Utf8));
      dispatch(guardar_wizard_negocio_ldap(decryptedData));
    }
  }, [dispatch]);

  // Validar persistencia de datos sessionStorage
  useEffect(() => {
    // Validar ruta actual
    sessionStorage.removeItem(STORAGE_ITEM_NEGOCIO);
    sessionStorage.setItem(STORAGE_ITEM_RUTA, R_CREAR_NEGOCIO);

    // Canal Venta
    const canalVenta_ = Number(sessionStorage.getItem(STORAGE_CANAL_VENTA));
    if (canalVenta_) setCanalVenta(canalVenta_);

    // WizardActual
    const wizardActual_ = Number(sessionStorage.getItem(STORAGE_WIZARD_ACTUAL));
    if (wizardActual_) dispatch(cambiar_wizard_actual(wizardActual_));

    // Data wizard
    obtenerDataStorageWizard();

    // Data Formulario Empresa
    obtenerDataStorageEmpresa();

    // Data Formulario Sucursal
    obtenerDataStorageSucursal();
  }, [
    dispatch,
    obtenerDataStorageEmpresa,
    obtenerDataStorageSucursal,
    obtenerDataStorageWizard,
  ]);

  useEffect(() => {
    crearEventoTraza(GA_CATEGORIA_NEGOCIOS, GA_LABEL_CREAR_NEGOCIO);
  }, []);

  /**************************
   *  CANAL VENTA           *
   **************************/
  const obtenerCanalVenta = (canalVenta_) => {
    setCanalVenta(canalVenta_);
    dispatch(guardar_canal_venta(canalVenta_));
    if (canalVenta_ === VALOR_ZERO) props.history.push(RUTA_NEGOCIOS);
  };

  /**************************
   *  DATA DE ENVIO         *
   **************************/
  const enviarCrearNegocio = async (datosDelegado) => {
    datosDelegado.rut = datosDelegado.rut.replace(/[.-]/g, "");
    datosDelegado.rut = format(datosDelegado.rut);
    datosDelegado.rut = datosDelegado.rut.replace(/[.]/g, "");

    setMessageLoading(MESSAGE_SEND);
    setLoading(true);

    // Crear Negocio
    const objCrearNegocio = {
      perfil: PERFIL_LDAP,
      canalVenta: { idCanalVenta: canalVenta },
      rutEmpresa: ldap.negocioConsulta.rut.split("-")[0],
      digitoVerificador: ldap.negocioConsulta.rut.split("-")[1],
      estadoNegocio: { idEstadoNegocio: 2 },
      idUsuarioCrea: auth.usuarioSesion.id,
      razonSocial: ldap.negocioConsulta.razonSocial,
      region: { idRegion: ldap.sucursalConsulta.region },
      tipoIncorporacion: { idTipoIncorporacion: 1 },
    };

    const idNegocio = await crearNegocio(objCrearNegocio);

    if (!idNegocio) {
      limpiarStorage();
      notificacion("success", EXITO_EMPRESA_EXISTENTE);
      props.history.push(RUTA_NEGOCIOS);
      return;
    }

    let idOrganizacion;
    let idOrganizacionSucursal = ldap.sucursalConsulta.idOrganizacionSucursal;
    let idPersonaDelegado = ldap.sucursalConsulta.idPersonaDelegado
      ? ldap.sucursalConsulta.idPersonaDelegado
      : 0;

    if (!ldap.negocioConsulta.existeEmpresa) {
      // Crear Organización
      const objBuscarDetalle = {
        idNegocio,
        perfil: PERFIL_LDAP,
      };
      const respuestaBuscarDetalleNegocio = await obtenerNegocio(
        objBuscarDetalle
      );
      const objCrearOrganizacion = {
        idOrganizacionNoEnrolada:
          respuestaBuscarDetalleNegocio.idOrganizacionNoEnrolada,
        organizacion: {
          giro: { idGiro: ldap.negocioConsulta.giro.value },
          idUsuarioModifica: auth.usuarioSesion.id,
          identificador: ldap.negocioConsulta.rut,
          razonSocial: ldap.negocioConsulta.razonSocial,
        },
      };
      idOrganizacion = await actualizarConfiguracionNegocio(
        objCrearOrganizacion
      );
    }

    if (ldap.sucursalConsulta.nuevaSucursal) {
      // Crear Organización Sucursal
      const objCrearOrganizacionSucursal = {
        digitoVerificador: ldap.negocioConsulta.rut.split("-")[1],
        idNegocio,
        rutEmpresa: ldap.negocioConsulta.rut.split("-")[0],
        sucursales: [
          {
            calle: ldap.sucursalConsulta.direccion,
            ciudad: { idCiudad: ldap.sucursalConsulta.ciudad },
            complemento: ldap.sucursalConsulta.complemento,
            comuna: { idComuna: ldap.sucursalConsulta.comuna },
            email: ldap.sucursalConsulta.email,
            nombreSucursal: ldap.sucursalConsulta.nombreSucursal,
            idOrganizacion,
            idUsuarioCrea: auth.usuarioSesion.id,
            numero: ldap.sucursalConsulta.numero,
            telefonoFijo1: ldap.sucursalConsulta.telefono,
          },
        ],
      };

      idOrganizacionSucursal = await actualizarConfiguracionSucursal(
        objCrearOrganizacionSucursal
      );

      // Crear Organización Sucursal Ejecutivo
      const objCrearOrganizacionSucursalEjecutivo = {
        idOrganizacionSucursal,
        idPersona: auth.usuarioSesion.idPersona,
        idUsuarioModifica: auth.usuarioSesion.id,
      };
      await enviarOrganizacionSucursalEjecutivo(
        objCrearOrganizacionSucursalEjecutivo
      );

      idPersonaDelegado = await crearDelegado(
        datosDelegado,
        idOrganizacionSucursal
      );
    } else if (ldap.sucursalConsulta.idOrganizacionSucursal) {
      const objCrearOrganizacionSucursal = {
        idNegocio,
        sucursales: [
          {
            idOrganizacionSucursal:
              ldap.sucursalConsulta.idOrganizacionSucursal,
            calle: ldap.sucursalConsulta.direccion,
            ciudad: { idCiudad: ldap.sucursalConsulta.ciudad },
            complemento: ldap.sucursalConsulta.complemento,
            comuna: { idComuna: ldap.sucursalConsulta.comuna },
            email: ldap.sucursalConsulta.email,
            nombreSucursal: ldap.sucursalConsulta.nombreSucursal,
            idOrganizacion,
            idUsuarioCrea: auth.usuarioSesion.id,
            numero: ldap.sucursalConsulta.numero,
            telefonoFijo1: ldap.sucursalConsulta.telefono,
          },
        ],
      };

      idOrganizacionSucursal = await actualizarConfiguracionSucursal(
        objCrearOrganizacionSucursal
      );
    }

    // Validar si delegado existe o no
    if (!ldap.sucursalConsulta.nuevaSucursal && idPersonaDelegado === 0) {
      idPersonaDelegado = await crearDelegado(
        datosDelegado,
        idOrganizacionSucursal
      );
    }

    if (!ldap.sucursalConsulta.idOrganizacionSucursal)
      actualizarOrganizacionSucursal(
        idOrganizacionSucursal,
        idPersonaDelegado,
        idNegocio
      );

    // Asignar Ejecutivo
    const objNegocio = {
      negocio: {
        idNegocio,
        idPersonaDelegado,
        idPersonaEjecutivo: auth.usuarioSesion.idPersona,
        idUsuarioModifica: auth.usuarioSesion.id,
      },
    };
    asignarEjecutivo(objNegocio);

    limpiarStorage();
    notificacion("success", EXITO_EMPRESA_CREADA);
    props.history.push(RUTA_NEGOCIOS);
    setLoading(false);
  };

  const actualizarOrganizacionSucursal = async (
    idOrganizacionSucursal,
    idPersonaDelegado,
    idNegocio
  ) => {
    const objCrearOrganizacionSucursal = {
      idNegocio,
      idUsuarioModifica: auth.usuarioSesion.id,
      sucursales: [
        {
          idOrganizacionSucursal,
          idSucursal: ldap.sucursalConsulta.idSucursal
            ? ldap.sucursalConsulta.idSucursal
            : VALOR_UNO,
          idPersonaDelegado,
          idUsuarioCrea: auth.usuarioSesion.id,
        },
      ],
    };
    await actualizarConfiguracionSucursal(objCrearOrganizacionSucursal);
  };

  const crearDelegado = async (datosDelegado, idOrganizacionSucursal) => {
    // Crea Delegado
    const objCrearDelegado = {
      email: datosDelegado.email,
      idOrganizacionSucursal,
      persona: {
        apellidoMaterno: datosDelegado.apellidoMaterno,
        apellidoPaterno: datosDelegado.apellidoPaterno,
        genero: Number(datosDelegado.sexo) === VALOR_UNO ? FEMENINO : MASCULINO,
        idUsuCodigoMod: auth.usuarioSesion.id,
        identificador: datosDelegado.rut,
        nombre: datosDelegado.nombre,
        fechaNacimiento: moment(datosDelegado.fechaNacimiento).format(
          "YYYY-MM-DD"
        ),
      },
      telefono: datosDelegado.telefono,
    };
    return await actualizarConfiguracionDelegado(objCrearDelegado);
  };

  return (
    <React.Fragment>
      <ModalTipoCanal
        show={canalVenta === null}
        titulo="Agregar canal venta"
        obtenerCanalVenta={obtenerCanalVenta}
      />
      {canalVenta !== null && (
        <React.Fragment>
          {/* TITULO */}
          <div className="row">
            <div className="col-md-7 text-muted">
              <h4 className="font-weight-bold mb-0">
                {canalVenta === TIPO_CANAL_VENTA_DIRECTA && "Venta Directa"}
                {canalVenta === TIPO_CANAL_VENTA_CORREDOR && "Corredor"}
              </h4>
              <p>
                {wizardActual === WZ1 && "Datos empresa"}
                {wizardActual === WZ2 && "Datos sucursal"}
                {wizardActual === WZ3 && "Datos delegado"}
              </p>
            </div>
          </div>

          <div className="card shadow my-3">
            <CabeceraWizardCrearNegocio />

            {wizardActual === WZ1 && (
              <CDatosEmpresa
                step={wizard[P_WZ1]}
                setLoading={setLoading}
                setMessageLoading={setMessageLoading}
              />
            )}
            {wizardActual === WZ2 && <CDatosSucursal step={wizard[P_WZ2]} />}
            {wizardActual === WZ3 && (
              <CDatosDelegado
                step={wizard[P_WZ3]}
                loading={loading}
                setLoading={setLoading}
                setMessageLoading={setMessageLoading}
                enviarCrearNegocio={enviarCrearNegocio}
              />
            )}
          </div>

          <ModalCarga show={loading} mensaje={messageLoading} />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default withRouter(CrearNegocio);
