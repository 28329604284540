import React, { Fragment } from "react";
import ReactDOM from "react-dom";

export default function ModalEditarAsegurable(props) {
  return ReactDOM.createPortal(
    <div
      className={`${!props.show ? "modal_overlay " : "modal_overlay show "} ${
        props.overlay === undefined ? "" : props.overlay ? "" : "light"
      }`}
      id={props.id}
    >
      <div
        className={`main_modal animated bounceInLeft ${
          props.centered ? "center" : ""
        } ${props.type === undefined ? "large" : props.type}`}
      >
        <div className="row mt-5">
          {props.showLeftColumn !== false && (
            <div className="d-none d-md-block col-md-4 bg-primary text-white text-center left-side">
              {props.leftSideContent !== undefined && (
                <div className="icon-section">
                  <div
                    className={
                      props.leftSideContent
                        ? `${props.leftSideContent.icono} icono`
                        : null
                    }
                  >
                    <span className="path1"></span>
                    <span className="path2"></span>
                    <span className="path3"></span>
                    <span className="path4"></span>
                    <span className="path5"></span>
                  </div>
                  <div className="pt-3">
                    {props.leftSideContent
                      ? props.leftSideContent.declaracion
                      : null}
                  </div>
                </div>
              )}
            </div>
          )}
          <div
            className={
              props.showLeftColumn !== false ? "col-12 col-md-8" : "col-12"
            }
          >
            <div className="modal_title">
              <div className="title">
                <h3 style={{ color: "#ed8d00" }}>{props.title}</h3>
              </div>
              {props.showCloseButton === undefined || props.showCloseButton ? (
                <div
                  id="closeButton"
                  className="button_close cursor"
                  onClick={() => {
                    props.onClose();
                  }}
                >
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    viewBox="0 0 352 512"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z" />
                  </svg>
                </div>
              ) : null}
            </div>
            <div className="modal_body py-2">{props.children}</div>
            <div className="modal_foot">
              {props.disableSubmitButton !== undefined && (
                <label>
                  <input
                    id="checkDisabledButton"
                    checked={props.checkDisabledButton}
                    onChange={props.activeModalButton}
                    type="checkbox"
                  />{" "}
                  {props.checkText}
                </label>
              )}

              <div className="button_zone">
                {props.showCancelButton === undefined ||
                props.showCancelButton ? (
                  <button
                    id="cancelButton"
                    className="btn btn-principal color-cancel color-secundario btn-sm"
                    type="button"
                    onClick={() => {
                      props.onCancel();
                    }}
                  >
                    Cancelar
                  </button>
                ) : null}


                {(props.showSubmitButton === undefined ||
                props.showSubmitButton) && (
                  <button
                  id={props.idSubmit}
                  className="btn btn-principal btn-sm"
                  type="submit"
                  disabled={props.disableSubmitButton || props.loadingData}
                  onClick={() => {
                    props.onSubmit();
                  }}
                >
                  {props.loadingData ? (
                    <Fragment>
                      <i className="fa fa-spinner fa-pulse fa-fw"></i>
                      <span className="sr-only">Loading...</span>
                    </Fragment>
                    ) : (
                      <span>{props.action}</span>
                  )}                  
                </button>                  
                )}                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>,
    document.querySelector("#modalAsegurable")
  );
}


