import React, { Component } from "react";
import PreguntaComplementarias from "./coberturasComplementarias/PreguntaComplementarias";
import PreguntaSalud from "./declaracionSalud/PreguntaSalud";

import { INVALIDEZ, VALOR_ZERO } from "../../../../../utils/Constantes";

class PreguntasMultiples extends Component {
  render() {
    return this.props.declaracionesSeccion.map((pregunta, index) => (
      <div key={`pregunta_${index}`} className="col-12 pt-3">
        <div className="h6 font-weight-bold text-primary">
          {this.props.declaracionesSeccion[index].glosasCoberturas &&
            this.props.declaracionesSeccion[index].glosasCoberturas
              .map((item) => item.glosa)
              .reduce((resultado, item) => [resultado, " - ", item])}
        </div>

        <div
          className="card p-3"
          style={{
            backgroundColor: "#ED8E03",
            borderRadius: "0.25rem",
          }}
        >
          <span
            className="text-white font-weight-bold h6 m-0"
            style={{ cursor: "pointer" }}
            onClick={() => {
              const declaracionesSeccion = [...this.props.declaracionesSeccion];
              declaracionesSeccion.forEach((declaracion, indice) => {
                if (index !== indice) {
                  declaracion.mostrarPregunta = false;
                }
              });
              declaracionesSeccion[index].mostrarPregunta =
                !this.props.declaracionesSeccion[index].mostrarPregunta;
              this.setState({ declaracionesSeccion });
            }}
          >
            <div className="row">
              <div className="col-6">
                <span>Pregunta {index + 1}</span>
              </div>
              <div className="col-6">
                <div className="float-right">
                  {this.props.declaracionesSeccion[index].mostrarPregunta ? (
                    <i
                      className="fa fa-chevron-down d-block text-right animated fadeIn"
                      aria-hidden="true"
                    ></i>
                  ) : (
                    <i
                      className="fa fa-chevron-right d-block text-right animated fadeIn"
                      aria-hidden="true"
                    ></i>
                  )}
                </div>
              </div>
            </div>
          </span>
        </div>

        <div
          className="mb-2 mt-0 card"
          style={{
            borderRadius: "0 0 0.25rem 0.25rem",
          }}
        >
          {this.props.declaracionesSeccion[index].mostrarPregunta &&
          this.props.declaracionesSeccion.length > VALOR_ZERO ? (
            this.props.declaracionesSeccion[index].seccion.glosa ===
            INVALIDEZ ? (
              <PreguntaComplementarias
                informacion={this.props.declaracionesSeccion[index]}
                indexCheck={index}
                check={this.props.check}
                checkAceptacion={this.props.checkAceptacion}
              />
            ) : (
              <PreguntaSalud
                informacion={this.props.declaracionesSeccion[index]}
                indexCheck={index}
                check={this.props.check}
                checkAceptacion={this.props.checkAceptacion}
              />
            )
          ) : null}
        </div>
      </div>
    ));
  }
}

export default PreguntasMultiples;
