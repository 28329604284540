import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  SECCION_INGRESAR_ASEGURABLES,
  SECCION_VER_ASEGURABLES,
  SECCION_VER_INDICADORES,
  TIPO_CANAL_VENTA_DIGITAL,
} from "../../../../../../utils/Constantes";
import CabeceraNegocioDelegado from "../../../delegado/infoEnrolamiento/CabeceraNegocioDelegado";
import CabeceraNegocioDelegadoVD from "../../../delegado/infoEnrolamiento/CabeceraNegocioDelegadoVD";
import { useHistory } from "react-router-dom";
import { Fragment } from "react";
import MisProcesos from "../../../delegado/misProcesos/MisProcesos";
import IngresarAsegurables from "../../../delegado/ingresarAsegurables/IngresarAsegurables";
import InfoEnrolamiento from "../../../delegado/infoEnrolamiento/InfoEnrolamiento";

const EstadoEnrolamiento = (props) => {
  const history = useHistory();
  const { reducer } = useSelector((store) => store);
  const { negocio, delegadoNegocio } = reducer;
  const canalVenta = negocio.canalVenta.idCanalVenta;

  const [seccionEnrolamiento, setSeccionEnrolamiento] = useState(
    SECCION_VER_INDICADORES
  );

  return (
    <React.Fragment>
      {negocio && canalVenta ? (
        <div className="row animated fadeIn">
          <div className="col-md-7 mb-3 text-muted">
            <h4 className="font-weight-bold mb-0">Estado de enrolamiento</h4>
          </div>
          <div className="col-md-5 mb-3 align-self-center">
            {/* VOLVER ATRÁS */}
            <button
              id="volverDetalleNegocio"
              onClick={() => {
                history.push("/cotizaciones/inicio");
              }}
              className="btn btn-principal btn-sm d-block ml-auto"
            >
              <i className="fa fa-chevron-left mr-2" aria-hidden="true"></i>
              Volver al detalle del negocio
            </button>
          </div>

          {/* CABECERA INFO EMPRESA */}
          {canalVenta === TIPO_CANAL_VENTA_DIGITAL ? (
            <div className="col-12 mb-3">
              <CabeceraNegocioDelegado
                negocio={negocio}
                cotizacion={reducer.cotizaciones.gestionadas}
              />
            </div>
          ) : (
            <div className="col-12 mb-3">
              <CabeceraNegocioDelegadoVD
                datosContacto={delegadoNegocio}
                negocio={negocio}
              />
            </div>
          )}

          {/* INFO ENROLAMIENTO */}

          {seccionEnrolamiento === SECCION_VER_ASEGURABLES && (
            <Fragment>
              <div className="col-md-12 button-zone text-right pb-4">
                <button
                  id="botonIndicadores"
                  className="btn btn-principal btn-sm"
                  type="button"
                  onClick={() => {
                    setSeccionEnrolamiento(SECCION_VER_INDICADORES);
                  }}
                >
                  <i className="fa fa-pie-chart mr-1" aria-hidden="true"></i>{" "}
                  <span>Ver indicadores</span>
                </button>
              </div>

              <div className="col-12 mb-3">
                <div className="card shadow my-3">
                  <div className="row">
                    <div className="col-12">
                      <h4
                        className="ml-4 mt-4 mb-3"
                        style={{ color: "#e89b03", fontWeight: 650 }}
                      >
                        Lista de Asegurables
                      </h4>
                    </div>
                  </div>

                  <MisProcesos
                    setSeccionEnrolamiento={setSeccionEnrolamiento}
                  />
                </div>
              </div>
            </Fragment>
          )}

          {seccionEnrolamiento === SECCION_INGRESAR_ASEGURABLES && (
            <Fragment>
              <div className="col-10 offset-sm-1">
                <span
                  className="font-weight-bold mt-3 ml-3 puntero-general float-right text-muted"
                  onClick={() => {
                    setSeccionEnrolamiento(SECCION_VER_ASEGURABLES);
                  }}
                >
                  {
                    <i
                      className="fa fa-chevron-left mr-1"
                      aria-hidden="true"
                    ></i>
                  }{" "}
                  Volver al listado de asegurables
                </span>
              </div>
              <div className="col-12">
                <IngresarAsegurables />
              </div>
            </Fragment>
          )}

          {seccionEnrolamiento === SECCION_VER_INDICADORES && (
            <Fragment>
              {/* INDICADORES */}
              <div className="col-md-12">
                <InfoEnrolamiento
                  setSeccionEnrolamiento={setSeccionEnrolamiento}
                />
              </div>
            </Fragment>
          )}
        </div>
      ) : (
        <h1>no pasó</h1>
      )}
    </React.Fragment>
  );
};

export default EstadoEnrolamiento;
