import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { actualizar_asegurables } from "../../../../actions/actions";
import Contenido from "../../transversales/Contenido";

class Delegado extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      menu: this.props.store.auth.usuarioSesion.menu,
     };    
    
  }

  render() {
    return <Contenido menu={this.state.menu} routes={this.props.routes} />;
  }
}

const mapStateToProps = (state) => ({
  store: state,
});

export default withRouter(
  connect(mapStateToProps, { actualizar_asegurables })(Delegado)
);
