import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

class PrivateRoute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: false
    };
  }

  componentWillMount() {
    if (this.props.store.auth.usuarioSesion !== null) {
      this.setState({ isAuthenticated: true });
    }
  }

  render() {
    return this.state.isAuthenticated ? (
      <Route {...this.props} />
    ) : (
      <Redirect from="/" to="/inicio-sesion" />
    );
  }
}

const mapStateToProps = state => ({
  store: state
});

export default connect(
  mapStateToProps,
  {}
)(PrivateRoute);
