import React, { useRef } from "react";
import { ModalContrasenaCambiar } from "../contrasena/Contrasena";
import Recaptcha from "react-google-invisible-recaptcha";
import {
  VERDADERO,
  FALSO
} from "../../../utils/Constantes";
import { Dropdown } from "react-bootstrap";
import { useAutenticacion } from "../../../eventos/useAutenticacion";
import { useContrasena } from "../../../eventos/useContrasena";

const logoVC = require("../../assets/images/logo_vida_camara.png");

export const BarraNavegacion = () => {

  const { verModal, mostrarModal } = useContrasena();
  const { cerrarSesion }           = useAutenticacion();
  let objRecaptcha                 = useRef();

  let lstOpciones = [
    {
      nombre: "Cambiar contraseña",
      id: "cambiarContrasena",
      evento: () =>{ mostrarModal(VERDADERO)}
    },
    {
      nombre: "Cerrar sesión",
      id: "cerrarSesion",
      evento: () =>{cerrarSesion(VERDADERO)}
    },
  ]

  return (
    <React.Fragment>
      <nav className="navbar navbar-light bg-white shadow">
        <figure className="navbar-brand">
          <img
            src={logoVC}
            height="30"
            className="d-inline-block align-top"
            alt="Vida Camara"
          />
        </figure>

        <section className="drop">
        <Dropdown className="barra-navegacion-dropdown" >
          <Dropdown.Toggle >
            <i className="fa fa-cog" aria-hidden="true" />
            <span className="d-none d-sm-inline">Opciones</span>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {lstOpciones.map((objOpcion, intIndice) => (
              <Dropdown.Item as="button" key={intIndice} onClick={objOpcion.evento}  >{objOpcion.nombre}</Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        </section>
      </nav>
      <ModalContrasenaCambiar 
        mostrarModal={verModal}
        cerrarModal={() =>{mostrarModal(FALSO)}}
        objRecaptcha={objRecaptcha}
      />

      <Recaptcha
        ref={(objReferencia) => {objRecaptcha.current = objReferencia}}
        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
      />
    </React.Fragment>
  );
}