import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  ID_PREGUNTA_COBERTURA,
  NO_DECLARA_PREEXISTENCIA,
  NO_DECLARA_ACTIVIDADES_DEPORTES,
  TIPO_CANAL_VENTA_DIRECTA,
  NO_DECLARA,
  TIPO_CANAL_VENTA_CORREDOR,
} from "../../../../../utils/Constantes";
import {
  extraerDeclaracionesRepetidas,
  procesarDeclaDeportesAId,
  procesarDeclaEspecialAId,
  procesarDeclaSaludAId,
} from "../../../../../utils/Functions";

class Declaraciones extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataWizard: this.props.store.reducer.dataWizardAsegurado,
      declaraciones:
        this.props.store.reducer.configuracionDeclaraciones.detalle,
      declaracionesComplemetarias: [],
      declaracionesDeportes: [],
      declaracionesSalud: [],
    };
  }

  async componentWillMount() {
    const declaraciones =
      this.props.store.reducer.configuracionDeclaraciones.detalle;
    let declaracionesComplemetarias = [];
    let declaracionesDeportes = [];
    let declaracionesSalud = [];

    const tipoCanal =
      this.props.store.reducer.data_asegurable.canalVenta.idCanalVenta;
    if (
      tipoCanal === TIPO_CANAL_VENTA_DIRECTA ||
      tipoCanal === TIPO_CANAL_VENTA_CORREDOR
    ) {
      declaraciones.forEach((item) => {
        if (item.idSeccion === ID_PREGUNTA_COBERTURA.SECCION_2 && item.causa) {
          declaracionesComplemetarias.push(item);
        } else if (
          item.idSeccion === ID_PREGUNTA_COBERTURA.SECCION_3 &&
          item.catalogoDeclaracion &&
          item.catalogoDeclaracion.idCatalogoTipoDeclaracion !==
            NO_DECLARA_ACTIVIDADES_DEPORTES
        ) {
          declaracionesDeportes.push(item);
        } else if (
          item.idSeccion === ID_PREGUNTA_COBERTURA.SECCION_1 &&
          (item.causa || item.catalogoDeclaracion.declaracion !== NO_DECLARA)
        ) {
          declaracionesSalud.push(item);
        }
      });
    } else {
      declaraciones.forEach((item) => {
        if (
          item.idSeccion === ID_PREGUNTA_COBERTURA.SECCION_2 &&
          item.catalogoDeclaracion.idCatalogoTipoDeclaracion !==
            NO_DECLARA_PREEXISTENCIA
        ) {
          declaracionesComplemetarias.push(item);
        } else if (
          item.idSeccion === ID_PREGUNTA_COBERTURA.SECCION_3 &&
          item.catalogoDeclaracion.idCatalogoTipoDeclaracion !==
            NO_DECLARA_ACTIVIDADES_DEPORTES
        ) {
          declaracionesDeportes.push(item);
        } else if (
          item.idSeccion === ID_PREGUNTA_COBERTURA.SECCION_1 &&
          item.catalogoDeclaracion.idCatalogoTipoDeclaracion !==
            NO_DECLARA_PREEXISTENCIA
        ) {
          declaracionesSalud.push(item);
        }
      });
    }

    declaracionesSalud = extraerDeclaracionesRepetidas(
      declaracionesSalud,
      procesarDeclaSaludAId
    );
    declaracionesDeportes = extraerDeclaracionesRepetidas(
      declaracionesDeportes,
      procesarDeclaDeportesAId
    );
    declaracionesComplemetarias = extraerDeclaracionesRepetidas(
      declaracionesComplemetarias,
      procesarDeclaEspecialAId
    );

    this.setState({
      declaracionesComplemetarias,
      declaracionesDeportes,
      declaracionesSalud,
    });
  }

  render() {
    const declaracion = [];
    declaracion.detalle = [];

    return (
      <React.Fragment>
        {this.state.declaraciones.length > 0 ? (
          <React.Fragment>
            <br />
            <h5 className="font-weight-bold">Declaraciones</h5>
            {this.state.declaracionesComplemetarias.length > 0 ? (
              <div className="mt-3">
                <h6 className="bg-secondary text-white p-2 linea-subtitulo font-weight-bold">
                  DECLARACIONES COMPLEMENTARIAS
                </h6>
                <div className="d-none d-md-block">
                  <table className="table table-sm">
                    <thead>
                      <tr>
                        <th className="text-left">Nombre completo</th>
                        <th className="text-left">Diagnóstico</th>
                        <th className="text-left">Fecha</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.declaracionesComplemetarias.map(
                        (item, i) =>
                          item.nombre && (
                            <tr key={`table_enfermedades_${i}`}>
                              <td>{item.nombre}</td>
                              <td>{item.causa}</td>
                              <td>{item.fechaDiagnosticoFormat}</td>
                            </tr>
                          )
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : null}

            {this.state.declaracionesDeportes.length ? (
              <div className="mt-3">
                <h6 className="bg-secondary text-white p-2 linea-subtitulo font-weight-bold">
                  DECLARACIONES DE ACTIVIDADES Y DEPORTES
                </h6>
                <div className="d-none d-md-block">
                  <table className="table table-sm">
                    <thead>
                      <tr>
                        <th className="text-left">Nombre completo</th>
                        <th className="text-left">Actividad o Deporte</th>
                        <th className="text-left">Frecuencia por semana</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.declaracionesDeportes.map(
                        (item, i) =>
                          item.nombre && (
                            <tr key={`table_enfermedades_${i}`}>
                              <td>{item.nombre}</td>
                              <td>{item.catalogoDeclaracion.declaracion}</td>
                              <td>{item.observacion}</td>
                            </tr>
                          )
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : null}

            {this.state.declaracionesSalud.length ? (
              <div className="mt-3">
                <h6 className="bg-secondary text-white p-2 linea-subtitulo font-weight-bold">
                  DECLARACIONES DE SALUD
                </h6>
                <div className="d-none d-md-block">
                  <table className="table table-sm">
                    <thead>
                      <tr>
                        <th className="text-left">Nombre completo</th>
                        <th className="text-left">Diagnóstico o Enfermedad</th>
                        <th className="text-left">Fecha de diagnóstico</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.declaracionesSalud.map(
                        (item, i) =>
                          item.nombre && (
                            <tr key={`table_enfermedades_${i}`}>
                              <td>{item.nombre}</td>
                              <td>
                                {item.catalogoDeclaracion.declaracion !==
                                  null &&
                                item.catalogoDeclaracion.declaracion !==
                                  undefined &&
                                item.catalogoDeclaracion.declaracion !== ""
                                  ? item.catalogoDeclaracion.declaracion
                                  : item.causa}
                              </td>
                              <td>
                                {item.fechaDiagnosticoFormat
                                  ? item.fechaDiagnosticoFormat
                                  : " - "}
                              </td>
                            </tr>
                          )
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : null}
          </React.Fragment>
        ) : null}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state,
});

export default withRouter(connect(mapStateToProps, {})(Declaraciones));
