import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  obtenerCotizacionesNegocio,
  obtenerNominaAprobada,
} from "../../../../../../services/cotizacion.services";
import Spinner from "../../../../transversales/Spinner";
import CabeceraNegocio from "./CabeceraNegocio";
import ModalExpiracion from "../ModalExpiracion";
import InicioEnrolamientoEjecutivo from "./InicioEnrolamientoEjecutivo";
import Authorized from "../../../../../../utils/Authorized";
import Cotizacion from "./cotizacion/Cotizacion";
import CotizacionNoDigital from "./cotizacion/CotizacionNoDigital";
import { Dropdown } from "react-bootstrap";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import ModalCarga from "../crear-negocio/ModalCarga";
import {
  guardar_cotizaciones,
  guardar_cotizacion,
  guardar_negocio,
  guardar_negocios,
  mostrar_modal_fecha_enrolamiento,
  mostrar_spinner,
} from "../../../../../../actions/actions";
import { guardar_canal_venta_busqueda } from "../../../../../../reducer/ldap";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import {
  eliminarNegocio,
  actualizarNegocio,
} from "../../../../../../services/negocios.service";
//import { eliminarCotizaciones } from "../../../../../../services/cotizacion.services";
import ModalNominaPreliminarDelegado from "./ModalNominaPreliminarDelegado";
import { notificacion } from "../../../../transversales/Notificaciones";
import {
  PERFIL_LDAP,
  ID_NEGOCIO_PERDIDO,
  T_GENERADAS,
  T_GESTIONADAS,
  T_NEGOCIO_ACTIVO,
  VALOR_UNO,
  MENSAJE_MODAL_INICIO_ENROLAMIENTO,
  STORAGE_ITEM_RUTA,
  STORAGE_ITEM_NEGOCIO,
  RUTA_NEGOCIOS,
  TRANSPARENTE,
  EXITO_ELIMINAR_NEGOCIO,
  NEGOCIO_ACTUALIZADO,
  VALOR_ZERO,
  ESTADO_NEGOCIO_3,
  TIPO_CANAL_VENTA_DIGITAL,
  ESTADO_NEGOCIO_6,
  STRING_VACIO,
  VALOR_TRES,
  VALOR_CUATRO,
  VALOR_CINCO,
  VALOR_QUINCE,
  VALOR_SIETE,
  CAMPO_REQUERIDO,
  ERROR_LARGO_OBSERVACION,
  ERROR_OBTENER_NOMINA_APROBADA,
  COD_CORREDOR,
  PATROCINADORES_CONFIGURACION,
  SIN_PATROCINADOR,
  LARGO_LISTA_VACIO,
  STORAGE_ITEM_FILTRO_CANAL_VENTA,
  GA_CATEGORIA_NEGOCIOS,
  GA_LABEL_DETALLE_NEGOCIO
} from "../../../../../../utils/Constantes";
import NoData from "../../../../transversales/NoData";
import { RUTA_STORAGE_COTIZACIONES } from "../../../../../../utils/ConstantesVentaDirecta";
import { descargarExcelBase64 } from "../../../../../../utils/Functions";
import { crearEventoTraza } from "../../../../../../utils/analitica";
class Cotizaciones extends Component {
  idNegocio;
  ultimaCotizacion;

  constructor(props) {
    super(props);

    this.state = {
      spin: false,
      cargando: true,
      negocios: this.props.store.reducer,
      negocio: null,
      tipoNegocio: this.props.store.reducer.tipoNegocio,
      fechaTerminoEnrolamiento: null,
      ultimaCotizacion: null,
      cotizaciones: [],
      cotizacionesBusqueda: [],
      tipoCotizacion: T_GESTIONADAS,
      blnMostrarDetalleCotizacionGestionada: false,
      blnMostrarDetalleCotizacionGenerada: false,
      blnMostrarDetalleCotizacionNoDigital: false,
      modalExpiracion: false,
      modalInicioEnrolamiento: false,
      posicionCotizacion: 0,
      archivosCotizacion: null,
      cargandoEstadoEnrolamiento: false,
      mensajeModal: "",
      mostrarModalNominaPreliminar: VALOR_ZERO,
      motivoCierre: "",
      motivoCierreError: false,
    };

    sessionStorage.setItem(
      STORAGE_ITEM_RUTA,
      RUTA_STORAGE_COTIZACIONES
    );
    if (sessionStorage.getItem(STORAGE_ITEM_NEGOCIO)) {
      this.idNegocio = sessionStorage.getItem(STORAGE_ITEM_NEGOCIO);
    } else if (this.props.location.state) {
      this.idNegocio = this.props.location.state.idNegocio;
      if (this.idNegocio) {
        sessionStorage.setItem(STORAGE_ITEM_NEGOCIO, this.idNegocio);
      }
    } else {
      sessionStorage.removeItem(STORAGE_ITEM_NEGOCIO);
      return this.props.history.push(RUTA_NEGOCIOS);
    }
  }

  componentDidMount() {
    this.setState({ spin: true });
    crearEventoTraza(GA_CATEGORIA_NEGOCIOS, GA_LABEL_DETALLE_NEGOCIO);
  }

  componentWillMount() {
    const idNegocio = sessionStorage.getItem(STORAGE_ITEM_NEGOCIO);
    this.obtenerDetalleNegocio(idNegocio);

    // Obtener canal venta storage
    const canalVentaBusqueda = Number(sessionStorage.getItem(STORAGE_ITEM_FILTRO_CANAL_VENTA));
    if (canalVentaBusqueda) {
      this.props.guardar_canal_venta_busqueda(canalVentaBusqueda);
    }

    this.setState({
      tipoNegocio: sessionStorage.getItem("_nTnVC")
        ? sessionStorage.getItem("_nTnVC")
        : this.props.store.reducer.tipoNegocio,
    });
  }

  /* =========================
      OBTENER DATA
    ============================ */
  obtenerDetalleNegocio = async (idNegocio) => {
    this.setState({ cargando: true });
    const { usuarioSesion } = this.props.store.auth;
    let strPerfil = usuarioSesion.rol.codigo === COD_CORREDOR ? usuarioSesion.rol.perfil : PERFIL_LDAP
    const dataEnvio = {
      idNegocio: Number(idNegocio),
      perfil: strPerfil,
    };
    const resNegocio = await obtenerCotizacionesNegocio(dataEnvio);

    if (resNegocio !== null) {
      // Data negocio y cofiguracion negocio
      const negocio = Object.assign({}, resNegocio);
      delete negocio.cotizaciones;

      this.props.guardar_negocio(negocio);
      this.setState(
        {
          negocio,
          ultimaCotizacion: negocio.ultimaCotizacion
            ? negocio.ultimaCotizacion
            : [],
          fechaTerminoEnrolamiento: negocio.fechaTerminoEnrolamiento,
        },
        () => {
          this.setState({ cargando: false });
        }
      );

      // Cotizaciones
      const cotizaciones = resNegocio.cotizaciones;
      if (cotizaciones !== null && Object.keys(cotizaciones).length !== VALOR_ZERO) {
        let tipoCotizacion;

        if (resNegocio.canalVenta.idCanalVenta === VALOR_UNO)
          tipoCotizacion =
            cotizaciones.gestionadas.length > 0 ? T_GESTIONADAS : T_GENERADAS;

        this.setState({
          cotizaciones,
          cotizacionesBusqueda: cotizaciones,
          tipoCotizacion,
        });
        this.props.guardar_cotizaciones(cotizaciones);
      } else return;

      if (cotizaciones.length === 1)
        this.setState({ blnMostrarDetalleCotizacionGestionada: true, blnMostrarDetalleCotizacionGenerada: false });
    }
    this.setState({ cargando: false });
  };

  async obtenerDetalleNegocioInactivo(idNegocio) {
    const { negocios } = this.props.store.reducer;

    if (negocios.length > 0) {
      let negocio = negocios.find(
        (n) => Number(n.idNegocio) === Number(idNegocio)
      );
      await this.props.guardar_negocio(negocio);
      this.setState(
        {
          negocio,
          ultimaCotizacion: negocio.ultimaCotizacion
            ? negocio.ultimaCotizacion
            : [],
          fechaTerminoEnrolamiento: negocio.fechaTerminoEnrolamiento,
        },
        () => {
          this.setState({ cargando: false });
        }
      );
    }
  }

  /* =========================
      BÚSQUEDAS REACTIVAS
    ============================ */
  busquedaReactivaCotizaciones(bsq) {
    let busqueda = bsq.target.value.toLowerCase().replace(/' '/g, "");

    let data = this.state.cotizaciones[this.state.tipoCotizacion].filter(
      (e) => {
        let codigoCotizacion = e.codigoCotizacion
          .toLowerCase()
          .replace(/' '/g, "");
        return codigoCotizacion.indexOf(busqueda) > -1;
      }
    );

    let cotizacionesBusqueda = Object.assign(
      {},
      this.state.cotizacionesBusqueda
    );
    cotizacionesBusqueda[this.state.tipoCotizacion] = data;

    this.setState({ cotizacionesBusqueda });
  }

  /* =========================
      ACCIONES
    ============================ */
  obtenerColorCotizacion = (objCotizacion) => {
    const intIdPatrocinador = objCotizacion.idPatrocinador > SIN_PATROCINADOR ? objCotizacion.idPatrocinador : SIN_PATROCINADOR;
    const objConfiguracion  = PATROCINADORES_CONFIGURACION.find( (objPatrocinadorConf) => objPatrocinadorConf.id === intIdPatrocinador);
    return objConfiguracion.color;
  }

  verCotizacion = (cotizacion) => {

    // Validar según canal venta negocio
    let validacionCotizacion;
    if (this.state.negocio.canalVenta.idCanalVenta === TIPO_CANAL_VENTA_DIGITAL) {
      validacionCotizacion = this.state.posicionCotizacion === this.state.cotizacionesBusqueda[this.state.tipoCotizacion].indexOf(cotizacion);
    } else {
      validacionCotizacion = this.state.posicionCotizacion === this.state.cotizacionesBusqueda.indexOf(cotizacion);
    }
    
    if (this.state.negocio.canalVenta.idCanalVenta === TIPO_CANAL_VENTA_DIGITAL) {

      if (this.state.blnMostrarDetalleCotizacionGestionada && 
          validacionCotizacion && 
          this.state.tipoCotizacion === T_GESTIONADAS) {

        this.setState({
          blnMostrarDetalleCotizacionGestionada: !this.state.blnMostrarDetalleCotizacionGestionada,
          cotizacion,
          blnMostrarDetalleCotizacionGenerada: false
        });
      } 
      else if ( this.state.blnMostrarDetalleCotizacionGenerada && 
                validacionCotizacion && 
                this.state.tipoCotizacion === T_GENERADAS) {
        this.setState({
          blnMostrarDetalleCotizacionGenerada: !this.state.blnMostrarDetalleCotizacionGenerada,
          cotizacion,
          blnMostrarDetalleCotizacionGestionada: false
        });
      } 
      else {
        let posicionCotizacion;

        if (this.state.negocio.canalVenta.idCanalVenta === TIPO_CANAL_VENTA_DIGITAL) {
          posicionCotizacion = this.state.cotizaciones[this.state.tipoCotizacion].indexOf(cotizacion);
        } else {
          posicionCotizacion = this.state.cotizaciones.indexOf(cotizacion);
        }

        if (cotizacion.estadoCotizacion.idEstadoCotizacion === 1) {

          this.setState({
            blnMostrarDetalleCotizacionGenerada: true,
            cotizacion,
            posicionCotizacion,
            blnMostrarDetalleCotizacionGestionada: false
          });
        } else {
          this.setState({
            blnMostrarDetalleCotizacionGestionada: true,
            cotizacion,
            posicionCotizacion,
            blnMostrarDetalleCotizacionGenerada: false
          });
        }
      }
    } else {
      if (this.state.blnMostrarDetalleCotizacionNoDigital && validacionCotizacion) {
        this.setState({
          blnMostrarDetalleCotizacionNoDigital: !this.state.blnMostrarDetalleCotizacionNoDigital,
          cotizacion,
        });
      } else {
        let intPosicionCotizacion = this.state.cotizaciones.indexOf(cotizacion);
        this.setState({
          blnMostrarDetalleCotizacionNoDigital: true,
          cotizacion,
          posicionCotizacion: intPosicionCotizacion,
        });
      }
    }
  };

  mostrarModalExpiracion = (boolean) => {
    this.setState({ modalExpiracion: boolean });
  };

  mostrarModalInicioEnrolamiento = async (
    boolean,
    inicioEnrolamiento = false
  ) => {
    this.setState({
      cargandoEstadoEnrolamiento: true,
      mensajeModal: MENSAJE_MODAL_INICIO_ENROLAMIENTO,
    });
    if (inicioEnrolamiento) this.props.history.push(RUTA_NEGOCIOS);
    this.setState({
      modalInicioEnrolamiento: boolean,
    });
    this.setState({
      cargandoEstadoEnrolamiento: false,
      mensajeModal: "",
    });
  };

  handleInputChange = (e) => {
    this.setState({ motivoCierre: e.target.value });
    if (this.state.motivoCierre.length > 0) {
      this.setState({ motivoCierreError: false });
    }
    this.modalCerrarNegocio();
  };

  modalCerrarNegocio = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        //const { existeEnrolado } = this.state.negocio;

        const toRender = (
          <div className="modal-confirm-container-responsive">
            <div className="d-flex flex-column">
              <p>
                Al cerrar el negocio se detendrá el Proceso de Enrolamiento
                Digital y el Enrolamiento deberá seguir el flujo tradicional.
              </p>
              <p>Por favor, ingrese el motivo por el cual cierra el negocio.</p>
              <textarea
                id="motivoCierre"
                className="form form-control"
                rows="3"
                minLength="15"
                maxLength="400"
                onChange={this.handleInputChange}
              ></textarea>
              {this.state.motivoCierreError &&
                ((this.state.motivoCierre.trim().length === VALOR_ZERO && (
                  <span className="d-block text-left text-danger animated fadeIn">
                    {CAMPO_REQUERIDO}
                  </span>
                )) ||
                  (this.state.motivoCierre.trim().length > VALOR_ZERO &&
                    this.state.motivoCierre.trim().length <= VALOR_QUINCE && (
                      <span className="d-block text-left text-danger animated fadeIn">
                        {ERROR_LARGO_OBSERVACION}
                      </span>
                    )))}
            </div>
            <div className="d-flex">
              <p className="mt-3">¿Está seguro que desea continuar?</p>
            </div>
            <div className="row mt-4">
              <button
                className="btn btn-principal color-cancel btn-sm d-block ml-auto mr-2"
                onClick={() => {
                  onClose();
                }}
              >
                Cancelar
              </button>
              <button
                className="btn btn-danger btn-sm"
                onClick={async () => {
                  await this.cerrarNegocio();
                  if (!this.state.motivoCierreError) onClose();
                }}
                disabled={this.state.motivoCierreError}
              >
                Cerrar Negocio
              </button>
            </div>
          </div>
        );
        return toRender;
      },
      willUnmount: () => {
        this.setState({ motivoCierre: "" });
        this.setState({ motivoCierreError: false });
      },
    });
  };

  modalEliminarNegocio = () =>
    confirmAlert({
      customUI: ({ onClose }) => {
        const { idEstadoNegocio } = this.state.negocio.estadoNegocio;
        const toRender = (
          <div className="modal-confirm-container-responsive">
            <div className="d-flex flex-column">
              {idEstadoNegocio === ESTADO_NEGOCIO_3 ? (
                <>
                  <p>
                    El negocio ya ha iniciado el Proceso de Enrolamiento; al
                    eliminar el negocio todos los acceso de delegados y
                    asegurables se eliminaran.
                  </p>
                  <p>¿Esta seguro que desea eliminar el negocio?</p>
                </>
              ) : (
                <p className="h4">¿Está seguro de eliminar este negocio?</p>
              )}
            </div>
            <div className="row mt-4">
              <button
                className="btn btn-principal color-cancel btn-sm d-block ml-auto mr-2"
                onClick={() => {
                  onClose();
                }}
              >
                No
              </button>
              <button
                className="btn btn-danger btn-sm"
                onClick={() => {
                  this.eliminarNegocio();
                  onClose();
                }}
              >
                Si, eliminar
              </button>
            </div>
          </div>
        );
        return toRender;
      },
    });

  eliminarNegocio = async () => {
    let cotizaciones = [];

    if (
      this.props.store.reducer.negocio.canalVenta.idCanalVenta === VALOR_UNO
    ) {
      this.props.store.reducer.cotizaciones.generadas.length > VALOR_ZERO &&
        this.props.store.reducer.cotizaciones.generadas.map((generada) => {
          return cotizaciones.push(generada.codigoCotizacion);
        });
      this.props.store.reducer.cotizaciones.gestionadas.length > VALOR_ZERO &&
        this.props.store.reducer.cotizaciones.gestionadas.map((gestionada) => {
          return cotizaciones.push(gestionada.codigoCotizacion);
        });
    } else {
      this.props.store.reducer.cotizaciones.length &&
        this.props.store.reducer.cotizaciones.forEach((cotizacion) => {
          if (cotizacion.codigoCotizacion !== STRING_VACIO) {
            return cotizaciones.push(cotizacion.codigoCotizacion);
          }
        });
    }

    const dataEnvio = {
      idNegocio: this.state.negocio.idNegocio,
      vigente: false,
      perfil: PERFIL_LDAP,
      estadoNegocio: { idEstadoNegocio: ID_NEGOCIO_PERDIDO },
    };

    let negocioEliminado = await eliminarNegocio(dataEnvio);
    if (negocioEliminado === 1) {
      //await eliminarCotizaciones(cotizaciones);
      let negocios = this.props.store.reducer.negocios;
      if (negocios !== null && negocios !== undefined) {
        let negociosA = negocios.filter((n) => {
          return n.idNegocio !== this.state.negocio.idNegocio;
        });
        this.props.guardar_negocios(negociosA);
      }
      this.props.mostrar_spinner(TRANSPARENTE);
      sessionStorage.removeItem(STORAGE_ITEM_NEGOCIO);
      this.props.history.push(RUTA_NEGOCIOS);
      notificacion("success", `${EXITO_ELIMINAR_NEGOCIO}`);
    }
  };

  cerrarNegocio = async () => {
    if (this.state.motivoCierre.trim().length < VALOR_QUINCE) {
      this.setState({ motivoCierreError: true });
      this.modalCerrarNegocio();
    } else {
      const dataEnvio = {
        idNegocio: this.state.negocio.idNegocio,
        idUsuarioModifica: this.props.store.auth.usuarioSesion.id,
        perfil: PERFIL_LDAP,
        estadoNegocio: {
          idEstadoNegocio: ESTADO_NEGOCIO_6,
        },
        motivoCierre: this.state.motivoCierre,
      };
      if ((await actualizarNegocio(dataEnvio)) === 1) {
        let negocios = this.props.store.reducer.negocios;
        if (negocios !== null && negocios !== undefined) {
          let negociosA = negocios.filter((n) => {
            return n.idNegocio !== this.state.negocio.idNegocio;
          });
          this.props.guardar_negocios(negociosA);
        }
        this.props.mostrar_spinner(TRANSPARENTE);
        sessionStorage.removeItem(STORAGE_ITEM_NEGOCIO);
        this.props.history.push(RUTA_NEGOCIOS);
        notificacion("success", `${NEGOCIO_ACTUALIZADO}`);
      }
    }
  };

  cambiarEstadoCotizacion = (cotizaciones) => {
    this.setState({ cotizacionesBusqueda: [], cotizaciones }, function () {
      this.setState({ cotizacionesBusqueda: cotizaciones });
    });
  };

  validarCotizacionesDirectaCorrecta = (cotizaciones) => {
    let cotizacionesValidas = [];
    cotizaciones.forEach((item) => {
      if (item.coberturas && item.coberturas.length > VALOR_ZERO) {
        cotizacionesValidas.push(item);
      }
    });
    return cotizacionesValidas.length;
  };

  modalNominaPreliminarDelegado = () => {
    this.setState({
      mostrarModalNominaPreliminar:
        this.state.mostrarModalNominaPreliminar + VALOR_UNO,
    });
  };

  procesarCotizacionesNegocio(lstCotizacion){
    lstCotizacion.sort((objActual, objPrevio) => objActual.nroProceso - objPrevio.nroProceso || objActual.codigoCotizacion.localeCompare(objPrevio.codigoCotizacion))
  }

  render() {
    const { negocio, cotizaciones } = this.state;
    let estadoNegocio;
    if (this.state.negocio)
      estadoNegocio = this.state.negocio.estadoNegocio.idEstadoNegocio;

    if(negocio && negocio.canalVenta.idCanalVenta === TIPO_CANAL_VENTA_DIGITAL && cotizaciones){
      if (cotizaciones.generadas !== null && 
          cotizaciones.generadas !== undefined && 
          cotizaciones.generadas.length > LARGO_LISTA_VACIO) {
        this.procesarCotizacionesNegocio(cotizaciones.generadas);
      }
      if (cotizaciones.gestionadas !== null && 
          cotizaciones.gestionadas !== undefined && 
          cotizaciones.gestionadas.length > LARGO_LISTA_VACIO) {
        this.procesarCotizacionesNegocio(cotizaciones.gestionadas);
      }
    }

    return (
      <React.Fragment>
        <Authorized roles={[4]}>
          {this.state.modalExpiracion && (
            <ModalExpiracion
              modalExpiracion={this.state.modalExpiracion}
              mostrarModalExpiracion={this.mostrarModalExpiracion}
            />
          )}
        </Authorized>
        {this.state.modalInicioEnrolamiento && (
          <InicioEnrolamientoEjecutivo
            negocio={this.state.negocio}
            idNegocio={this.state.idNegocio}
            cotizacionesGestionadas={this.state.cotizaciones.gestionadas}
            modalInicioEnrolamiento={this.state.modalInicioEnrolamiento}
            mostrarModalInicioEnrolamiento={this.mostrarModalInicioEnrolamiento}
          />
        )}
        <div className="row">
          <div className="col-12 col-sm-6 text-muted">
            <h4 className="font-weight-bold mb-0">Cotizaciones</h4>
            <p>Cotizaciones actuales de nuestros clientes</p>
          </div>
          <div className="col-12 text-center d-sm-none">
            <input
              className="search-input col-sm-12"
              onChange={(e) => {
                this.busquedaReactivaCotizaciones(e);
              }}
              type="text"
              placeholder="Buscar por folio..."
            />
          </div>
          <div className="col-sm-6 text-right d-none d-sm-block">
            <input
              className="search-input"
              onChange={(e) => {
                this.busquedaReactivaCotizaciones(e);
              }}
              type="text"
              placeholder="Buscar por folio..."
            />
          </div>
        </div>
        {this.state.cargando === true && this.state.spin ? (
          <div
            style={{ height: this.props.store.reducer.DOM.height - 150 + "px" }}
          >
            <Spinner
              claseColor="transparente"
              idSpinner="spinnerTransparente"
              posicionLogo="posicionLogo align-self-center w-100"
            />
          </div>
        ) : (
          true && (
            <React.Fragment>
              <div className="animated fadeIn mt-3">
                <div className="row mb-3">
                  {/* VOLVER ATRÁS */}
                  <button
                    onClick={() => {
                      this.props.mostrar_spinner("transparente");
                      sessionStorage.removeItem(STORAGE_ITEM_NEGOCIO);
                      this.props.history.push("/negocios");
                      sessionStorage.setItem("_nTnVC", T_NEGOCIO_ACTIVO);
                    }}
                    className="btn btn-principal btn-sm mr-3 ml-auto"
                  >
                    <i
                      className="fa fa-chevron-left mr-2"
                      aria-hidden="true"
                    ></i>
                    Volver al listado de negocios
                  </button>

                  {/* CONFIGURACIÓN */}
                  {
                    <div className="mr-3" data-container="body">
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="dark btn-sm"
                          bsPrefix="d-inline-block"
                          id="dropdownConfiguracion"
                        >
                          <i className="fa fa-cog mr-2" aria-hidden="true"></i>
                          Configuración
                          <i
                            className="fa fa-chevron-down ml-2"
                            aria-hidden="true"
                          ></i>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Authorized roles={[VALOR_TRES, VALOR_SIETE]}>
                            <Dropdown.Item
                              id="datosNegocio"
                              onClick={() => {
                                this.props.history.push({
                                  pathname: "/configuracionNegocio",
                                });
                              }}
                            >
                              Datos de Negocio
                            </Dropdown.Item>
                          </Authorized>
                          {this.state.negocio &&
                            this.state.negocio.tipoIncorporacion
                              .idTipoIncorporacion === 1 && (
                              <Dropdown.Item
                                onClick={() => {
                                  this.modalCerrarNegocio();
                                }}
                                disabled={
                                  this.state.tipoNegocio === T_NEGOCIO_ACTIVO
                                    ? false
                                    : true
                                }
                              >
                                Cerrar Negocio
                              </Dropdown.Item>
                            )}

                          <Dropdown.Item
                            onClick={() => this.modalEliminarNegocio()}
                            disabled={
                              this.state.tipoNegocio === T_NEGOCIO_ACTIVO
                                ? false
                                : true
                            }
                          >
                            Eliminar Negocio
                          </Dropdown.Item>
                          <Authorized roles={[VALOR_TRES, VALOR_SIETE]}>
                            <Dropdown.Item
                              onClick={() =>
                                this.modalNominaPreliminarDelegado()
                              }
                              disabled={
                                this.state.tipoNegocio === T_NEGOCIO_ACTIVO
                                  ? false
                                  : true
                              }
                            >
                              Nómina Preliminar Delegado
                            </Dropdown.Item>
                          </Authorized>
                          {estadoNegocio === VALOR_CINCO && (
                            <Authorized
                              roles={[VALOR_TRES, VALOR_CUATRO, VALOR_SIETE]}
                            >
                              <Dropdown.Item
                                onClick={async () => {
                                  let dataBase64 = await obtenerNominaAprobada(
                                    this.idNegocio
                                  );
                                  if (dataBase64) {
                                    descargarExcelBase64(
                                      "nómina-aprobada",
                                      dataBase64.nominaBase64
                                    );
                                  } else {
                                    notificacion(
                                      "danger",
                                      `${ERROR_OBTENER_NOMINA_APROBADA}`
                                    );
                                  }
                                }}
                              >
                                Nómina Aprobada
                              </Dropdown.Item>
                            </Authorized>
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  }
                </div>

                {this.state.negocio && (
                  <CabeceraNegocio
                    className="animated fadeIn"
                    negocio={this.state.negocio}
                    ultimaCotizacion={this.state.ultimaCotizacion}
                    tipoNegocio={this.state.tipoNegocio}
                  ></CabeceraNegocio>
                )}

                <div className="card shadow my-3">
                  {this.state.negocio &&
                    this.state.negocio.canalVenta.idCanalVenta === VALOR_UNO ? (
                    <Tabs
                      id="tabsCotizaciones"
                      activeKey={this.state.tipoCotizacion}
                      onSelect={(tipoCotizacion) => {
                        this.setState({ tipoCotizacion });
                      }}
                    >
                      <Tab eventKey="gestionadas" title="Gestionadas">
                        {/* LISTADO DE COTIZACIONES */}
                        <div className="card-body">
                          <div className="row">
                            {!this.state.cotizacionesBusqueda[T_GESTIONADAS] ? (
                              <NoData
                                titulo={"No hay cotizaciones disponibles"}
                              ></NoData>
                            ) : this.state.cotizacionesBusqueda[T_GESTIONADAS]
                              .length > 0 ? (
                              <div className="col-12">
                                <div className="row">
                                  <Authorized roles={[VALOR_TRES, VALOR_SIETE]}>
                                    <div className="col-12 my-3 d-flex">
                                      {estadoNegocio < ESTADO_NEGOCIO_3 ? (
                                        // BOTÓN INICIO ENROLAMIENTO
                                        <button
                                          className="d-block btn btn-principal btn-sm ml-auto"
                                          alt="Iniciar proceso de enrolamiento"
                                          id="inicioEnrolamiento"
                                          onClick={() =>
                                            this.mostrarModalInicioEnrolamiento(
                                              true
                                            )
                                          }
                                        >
                                          <i
                                            className="fa fa-play mr-1"
                                            aria-hidden="true"
                                          ></i>{" "}
                                          Inicio Enrolamiento
                                        </button>
                                      ) : (
                                        // BOTÓN ESTADO ENROLAMIENTO
                                        <button
                                          className="d-block btn btn-principal btn-sm ml-auto"
                                          alt="Ver estado del enrolamiento"
                                          id="estadoEnrolamiento"
                                          onClick={() =>
                                            this.props.history.push(
                                              "/cotizaciones/estado-enrolamiento"
                                            )
                                          }
                                        >
                                          <i
                                            className="fa fa-pie-chart mr-1"
                                            aria-hidden="true"
                                          ></i>{" "}
                                          Estado enrolamiento
                                        </button>
                                      )}
                                    </div>
                                  </Authorized>
                                  {this.state.cotizacionesBusqueda[
                                    T_GESTIONADAS
                                  ].map((cotizacion, i) => (

                                    <div
                                      key={`cotizaciones_${i}`}
                                      className="col-12"
                                    >
                                      <div
                                        className="card p-3"
                                        style={{
                                          backgroundColor: this.obtenerColorCotizacion(cotizacion),
                                          borderRadius:
                                            this.state
                                              .blnMostrarDetalleCotizacionGestionada ===
                                              true &&
                                              cotizacion ===
                                              this.state.cotizacionesBusqueda[
                                              T_GESTIONADAS
                                              ][this.state.posicionCotizacion]
                                              ? "0.25rem 0.25rem 0 0"
                                              : "0.25rem",
                                        }}
                                      >
                                        <span
                                          className="text-white font-weight-bold h6 m-0"
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            this.verCotizacion(cotizacion)
                                          }
                                        >
                                          <div className="row">
                                            <div className="col-6">
                                              <span>
                                                {cotizacion.codigoCotizacion}
                                              </span>
                                            </div>
                                            <div className="col-6">
                                              <div className="row">
                                                <div className="col-11 text-right">
                                                  {
                                                    cotizacion.subProducto
                                                      .glosaSubProducto
                                                  }
                                                </div>
                                                <div className="col-1">
                                                  {this.state
                                                    .blnMostrarDetalleCotizacionGestionada ===
                                                    true &&
                                                    cotizacion ===
                                                    this.state
                                                      .cotizacionesBusqueda[
                                                    this.state
                                                      .posicionCotizacion
                                                    ] ? (
                                                    <i
                                                      className="fa fa-chevron-down d-block text-right animated fadeIn"
                                                      aria-hidden="true"
                                                    ></i>
                                                  ) : (
                                                    <i
                                                      className="fa fa-chevron-right d-block text-right animated fadeIn"
                                                      aria-hidden="true"
                                                    ></i>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </span>
                                      </div>

                                      <div
                                        className="mb-2 mt-0 card"
                                        style={{
                                          borderRadius: "0 0 0.25rem 0.25rem",
                                        }}
                                      >
                                        {this.state.cotizaciones[T_GESTIONADAS]
                                          .length === 1 ||
                                          (this.state.blnMostrarDetalleCotizacionGestionada ===
                                            true &&
                                            cotizacion ===
                                            this.state.cotizaciones[
                                            T_GESTIONADAS
                                            ][
                                            this.state.posicionCotizacion
                                            ]) ? (
                                          <Cotizacion
                                            cotizacionesGestionadas={
                                              [
                                                cotizacion
                                              ]
                                            }
                                            negocio={this.state.negocio}
                                            cotizacion_={cotizacion}
                                            tipoCotizacion="gestionada"
                                            cambiarEstadoCotizacion={
                                              this.cambiarEstadoCotizacion
                                            }
                                          />
                                        ) : null}
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            ) : (
                              <NoData
                                titulo={"No hay cotizaciones disponibles"}
                              ></NoData>
                            )}
                          </div>
                        </div>
                      </Tab>
                      <Tab eventKey="generadas" title="Generadas">
                        {/* LISTADO DE COTIZACIONES */}
                        <div className="card-body">
                          <div className="row">
                            {this.state.cotizacionesBusqueda[T_GENERADAS] ? (
                              this.state.cotizacionesBusqueda[T_GENERADAS]
                                .length > 0 ? (
                                this.state.cotizacionesBusqueda[
                                  T_GENERADAS
                                ].map((cotizacion, i) => (
                                  <div
                                    key={`cotizaciones_${i}`}
                                    className="col-12"
                                  >
                                    <div
                                      className="card p-3"
                                      style={{
                                        backgroundColor: this.obtenerColorCotizacion(cotizacion),
                                        borderRadius:
                                          this.state
                                            .blnMostrarDetalleCotizacionGenerada ===
                                            true &&
                                            cotizacion ===
                                            this.state.cotizacionesBusqueda[
                                            T_GENERADAS
                                            ][this.state.posicionCotizacion]
                                            ? "0.25rem 0.25rem 0 0"
                                            : "0.25rem",
                                      }}
                                    >
                                      <span
                                        className="text-white font-weight-bold h6 m-0"
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          this.verCotizacion(cotizacion)
                                        }
                                      >
                                        <div className="row">
                                          <div className="col-6">
                                            <span>
                                              {cotizacion.codigoCotizacion}
                                            </span>
                                          </div>
                                          <div className="col-6">
                                            <div className="row">
                                              <div className="col-11 text-right">
                                                {
                                                  cotizacion.subProducto
                                                    .glosaSubProducto
                                                }
                                              </div>
                                              <div className="col-1">
                                                {this.state
                                                  .blnMostrarDetalleCotizacionGenerada ===
                                                  true &&
                                                  cotizacion ===
                                                  this.state
                                                    .cotizacionesBusqueda[
                                                  this.state
                                                    .posicionCotizacion
                                                  ] ? (
                                                  <i
                                                    className="fa fa-chevron-down d-block text-right animated fadeIn"
                                                    aria-hidden="true"
                                                  ></i>
                                                ) : (
                                                  <i
                                                    className="fa fa-chevron-right d-block text-right animated fadeIn"
                                                    aria-hidden="true"
                                                  ></i>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </span>
                                    </div>

                                    <div
                                      className="mb-2 mt-0 card"
                                      style={{
                                        borderRadius: "0 0 0.25rem 0.25rem",
                                      }}
                                    >
                                      {this.state.cotizaciones[T_GENERADAS]
                                        .length === 1 ||
                                        (this.state.blnMostrarDetalleCotizacionGenerada ===
                                          true &&
                                          cotizacion ===
                                          this.state.cotizaciones[T_GENERADAS][
                                          this.state.posicionCotizacion
                                          ]) ? (
                                        <Cotizacion
                                          cotizacionesGestionadas={
                                            [
                                              cotizacion
                                            ]
                                          }
                                          negocio={this.state.negocio}
                                          tipoCotizacion="generada"
                                          cotizacion_={cotizacion}
                                          cambiarEstadoCotizacion={
                                            this.cambiarEstadoCotizacion
                                          }
                                        />
                                      ) : null}
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <NoData
                                  titulo={"No hay cotizaciones disponibles"}
                                ></NoData>
                              )
                            ) : (
                              <NoData
                                titulo={"No hay cotizaciones disponibles"}
                              ></NoData>
                            )}
                          </div>
                        </div>
                      </Tab>
                    </Tabs>
                  ) : (
                    <div className="card-body">
                      <div className="row">
                        <div className="container-fluid">
                          <Authorized roles={[VALOR_TRES, VALOR_SIETE]}>
                            <div className="col-12 d-flex my-3 ">
                              {this.state.negocio &&
                                (estadoNegocio < ESTADO_NEGOCIO_3 ? (
                                  <Fragment>
                                    {/* BOTÓN AGREGAR PRODUCTO */}
                                    <button
                                      className="btn btn-principal btn-sm mr-3 ml-auto"
                                      alt="Agregar pruducto"
                                      onClick={() => {
                                        this.props.history.push(
                                          "/cotizaciones/configurar-producto"
                                        );
                                      }}
                                    >
                                      <i
                                        className="fa fa-plus mr-1"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      Agregar datos productos
                                    </button>

                                    {/* BOTÓN INICIAR ENROLAMIENTO */}
                                    <button
                                      className="btn btn-principal btn-sm"
                                      alt="Iniciar proceso de enrolamiento"
                                      id="inicioEnrolamiento"
                                      onClick={() =>
                                        this.mostrarModalInicioEnrolamiento(
                                          true
                                        )
                                      }
                                    >
                                      <i
                                        className="fa fa-play mr-1"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      Inicio Enrolamiento
                                    </button>
                                  </Fragment>
                                ) : (
                                  // BOTÓN ESTADO ENROLAMIENTO
                                  <button
                                    className="d-block btn btn-principal btn-sm ml-auto"
                                    alt="Ver estado del enrolamiento"
                                    id="estadoEnrolamiento"
                                    onClick={() =>
                                      this.props.history.push(
                                        "/cotizaciones/estado-enrolamiento"
                                      )
                                    }
                                  >
                                    <i
                                      className="fa fa-pie-chart mr-1"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    Estado enrolamiento
                                  </button>
                                ))}
                            </div>
                          </Authorized>
                        </div>
                        {/* Cotizacion canal "Venta directa", "Corredor" */}
                        <div className="col-12 mt-3">
                          {this.validarCotizacionesDirectaCorrecta(
                            this.state.cotizaciones
                          ) > VALOR_ZERO ? (
                            this.state.cotizaciones.map(
                              (cotizacion, indice) => (
                                <CotizacionNoDigital
                                  obtenerDetalleNegocio={
                                    this.obtenerDetalleNegocio
                                  }
                                  key={indice}
                                  mostrarDetalleCotizacion={
                                    this.state.blnMostrarDetalleCotizacionNoDigital
                                  }
                                  posicionCotizacion={
                                    this.state.posicionCotizacion
                                  }
                                  cotizacionesBusqueda={
                                    this.state.cotizacionesBusqueda
                                  }
                                  negocio={this.state.negocio}
                                  cotizacion_={cotizacion}
                                  verCotizacion={this.verCotizacion}
                                  cambiarEstadoCotizacion={
                                    this.cambiarEstadoCotizacion
                                  }
                                />
                              )
                            )
                          ) : (
                            <NoData
                              titulo={"No hay cotizaciones disponibles"}
                            ></NoData>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </React.Fragment>
          )
        )}
        <ModalCarga
          show={this.state.cargandoEstadoEnrolamiento}
          mensaje={this.state.mensajeModal}
        />
        <ModalNominaPreliminarDelegado
          show={this.state.mostrarModalNominaPreliminar}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state,
});

export default withRouter(
  connect(mapStateToProps, {
    guardar_cotizaciones,
    guardar_cotizacion,
    guardar_negocio,
    guardar_negocios,
    mostrar_modal_fecha_enrolamiento,
    mostrar_spinner,
    guardar_canal_venta_busqueda,
  })(Cotizaciones)
);
