import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
import {
  ACEPTAR_TERMINOS,
  ERROR_GUARDAR_ACEPTACION,
  EXITO_ACEPTACION,
  VALOR_TRES,
  TIPO_PAGINA,
  VALIDACION_100_BENEFICIO,
  VALOR_CIEN,
  TIPO_TAB,
  PAGINA_5,
  ALERTA_NO_BENEFICIARIOS,
  VALOR_ZERO,
  TIPO_CANAL_VENTA_DIRECTA,
  SIGNO_GUION,
  TAB_ACTIVA_BENEFICIARIO_CONSENTIMIENTO,
  TAB_ACTIVA_BENEFICIARIO_DECLARACION,
  NOMBRE_TITULAR_BENEFICIARIO,
  RUT_TITULAR_BENEFICIARIO,
  RAZON_SOCIAL_BENEFICIARIO,
  VALOR_CINCO,
  TIPO_SOLICITUD_DECLARACION_BENEFICIARIO,
  VALOR_VACIO,
  ESPACIO_VACIO,
  TIPO_INCORPORACION_MANTENCION,
  GA_CATEGORIA_FORMULARIO_ENROLAMIENTO,
  GA_LABEL_CONSENTIMIENTO_VIDA,
  GA_LABEL_CONSENTIMIENTO_BENEFICIARIOS,
  ID_CATALOGO_CONSENTIMIENTO_MANTO,
  ID_CATALOGO_CONSENTIMIENTO_NUEVO_NEGOCIO,
  PRODUCTOS_CON_BENEFICIARIOS,
  POLIZAS_CON_BENEFICIARIOS,
} from "../../../../../utils/Constantes";
import {
  wizard,
  actualizar_beneficiarios,
  aceptar_declaro_beneficiario,
  guardar_data_wizard_asegurado,
} from "../../../../../actions/actions";
import InformacionBeneficiarios from "./InformacionBeneficiarios";
import { notificacion } from "../../../transversales/Notificaciones";
import FormularioBeneficiarios from "./FormularioBeneficiarios";
import { guardarDataWizard } from "../../../../../services/asegurable.services";
import { Tabs, Tab } from "react-bootstrap";
import { crearEventoTraza } from "../../../../../utils/analitica";

class Beneficiarios extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sumaPorcentaje: 0,
      validacionPorcentaje: false,
      aceptacionCheck:
        this.props.store.reducer.dataWizardAsegurado.solicitud
          .aceptacionBeneficiario,
      mostrandoFormulario:
        this.props.store.reducer.dataWizardAsegurado.solicitud
          .aceptacionBeneficiario,
      declaroBeneficiario: this.props.store.reducer.dataWizardAsegurado
        .solicitud.declaroBeneficiario
        ? this.props.store.reducer.dataWizardAsegurado.solicitud
            .declaroBeneficiario
        : this.props.store.reducer.dataWizardAsegurado.solicitud
            .aceptacionBeneficiario &&
          this.props.store.reducer.dataWizardAsegurado.solicitud
            .aceptacionBeneficiario
        ? false
        : null,
      declaroConsentimiento: this.props.store.reducer.dataWizardAsegurado
        .consentimiento
        ? this.props.store.reducer.dataWizardAsegurado.consentimiento.aceptacion
        : false,
      habilitarDeclaracionBeneficiario: this.props.store.reducer
        .dataWizardAsegurado.consentimiento
        ? false
        : true,
      tabBeneficiarioActiva: this.props.store.reducer.dataWizardAsegurado
        .consentimiento
        ? TAB_ACTIVA_BENEFICIARIO_DECLARACION
        : TAB_ACTIVA_BENEFICIARIO_CONSENTIMIENTO,
      consentimiento: {
        catalogoConsentimiento: {
          glosa: "",
          idCatalogoConsentimiento: 0,
        },
        capital: VALOR_ZERO,
        poliza: "",
        negocioMantencion: false,
      },
      coberturas: [],
      loadingData:false
    };
  }

  async componentDidMount() {
    let strAccionGa =
      this.state.tabBeneficiarioActiva ===
      TAB_ACTIVA_BENEFICIARIO_CONSENTIMIENTO
        ? GA_LABEL_CONSENTIMIENTO_VIDA
        : GA_LABEL_CONSENTIMIENTO_BENEFICIARIOS;
    crearEventoTraza(GA_CATEGORIA_FORMULARIO_ENROLAMIENTO, strAccionGa);

    let { dataWizardAsegurado, informacionPoliza, catalogoConsentimientos } =
      this.props.store.reducer;
    let {
      data_asegurable: {
        razonSocial,
        rutEmpresa,
        digitoVerificadorEmpresa,
        canalVenta,
      },
    } = this.props.store.reducer;
    let { usuarioSesion } = this.props.store.auth;

    let intIdTipoIncorporacion =
      dataWizardAsegurado.solicitud.tipoIncorporacion.idTipoIncorporacion;
    let intIdCanalVenta = canalVenta.idCanalVenta;
    let strTitularBeneficiario =
      usuarioSesion.nombre + ESPACIO_VACIO + usuarioSesion.apellido;
    let strRazonSocial =
      razonSocial +
      ESPACIO_VACIO +
      SIGNO_GUION +
      ESPACIO_VACIO +
      rutEmpresa +
      SIGNO_GUION +
      digitoVerificadorEmpresa;

    if (
      intIdCanalVenta !== TIPO_CANAL_VENTA_DIRECTA &&
      intIdTipoIncorporacion === TIPO_INCORPORACION_MANTENCION
    ) {
      //MANTENCION
      let objGrupoPolizaVida = informacionPoliza.grupos.find((objGrupo) =>
        POLIZAS_CON_BENEFICIARIOS.includes(objGrupo.poliza.tipoPoliza.idTipoPoliza)
      );
      let strPoliza =
        objGrupoPolizaVida.nroPoliza +
        SIGNO_GUION +
        objGrupoPolizaVida.nroRenovacion;
      let intCapital = objGrupoPolizaVida.coberturas.find(
        (objCobertura) => objCobertura.principal === true
      ).capital;
      let strConsentimiento = catalogoConsentimientos
        .find(
          (objCon) =>
            objCon.idCatalogoConsentimiento === ID_CATALOGO_CONSENTIMIENTO_MANTO
        )
        .glosa.replace(NOMBRE_TITULAR_BENEFICIARIO, strTitularBeneficiario)
        .replace(RUT_TITULAR_BENEFICIARIO, usuarioSesion.rut)
        .replace(RAZON_SOCIAL_BENEFICIARIO, strRazonSocial);

      let objConsentimiento = {
        capital: intCapital,
        poliza: strPoliza,
        negocioMantencion: true,
        catalogoConsentimiento: {
          idCatalogoConsentimiento: ID_CATALOGO_CONSENTIMIENTO_MANTO,
          glosa: strConsentimiento,
        },
      };

      this.setState({
        consentimiento: objConsentimiento,
        coberturas: dataWizardAsegurado.coberturas,
      });
    } else {
      //NEGOCIO NUEVO
      let strConsentimiento = catalogoConsentimientos
        .find(
          (objCon) =>
            objCon.idCatalogoConsentimiento ===
            ID_CATALOGO_CONSENTIMIENTO_NUEVO_NEGOCIO
        )
        .glosa.replace(NOMBRE_TITULAR_BENEFICIARIO, strTitularBeneficiario)
        .replace(RUT_TITULAR_BENEFICIARIO, usuarioSesion.rut)
        .replace(RAZON_SOCIAL_BENEFICIARIO, strRazonSocial);

      let objConsentimiento = {
        negocioMantencion: false,
        catalogoConsentimiento: {
          glosa: strConsentimiento,
          idCatalogoConsentimiento: ID_CATALOGO_CONSENTIMIENTO_NUEVO_NEGOCIO,
        },
      };

      this.setState({
        consentimiento: objConsentimiento,
        coberturas: dataWizardAsegurado.coberturas,
      });
    }
  }

  aceptarCheck = () => {
    this.setState({ aceptacionCheck: !this.state.aceptacionCheck });
  };

  aceptarConsentimiento = () => {
    this.setState({ declaroConsentimiento: !this.state.declaroConsentimiento });
  };

  declaraBeneficiario = (value) => {
    this.setState({ declaroBeneficiario: value });
  };

  guardarSumaPorcentaje = (sumaPorcentaje) => {
    this.setState({ sumaPorcentaje });
  };

  anteriorPagina = () => {
    scroll.scrollToTop();
    if (this.props.store.reducer.informacionPoliza.soloProductoVida) {
      this.props.wizard({ pagina: VALOR_TRES, type: TIPO_PAGINA });
      this.props.wizard({ tab: VALOR_TRES, type: TIPO_TAB });
    } else {
      this.props.wizard({ pagina: VALOR_TRES, type: TIPO_PAGINA });
    }
  };

  siguientePagina = async () => {
    this.setState({ loadingData: true });

    if (!this.props.store.reducer.dataWizardAsegurado.consentimiento) {
      if (this.state.declaroConsentimiento) {
        //GUARDAR CONSENTIMIENTO
        let { dataWizardAsegurado } = this.props.store.reducer;

        dataWizardAsegurado.coberturas = dataWizardAsegurado.coberturas.map(
          (objCobertura) => {
            let intCapital = this.state.coberturas.find(
              (objCob) =>
                objCob.idSubProdCondicionadoCobertura ===
                objCobertura.idSubProdCondicionadoCobertura
            ).capital;
            let intCapitalAsegurado = objCobertura.capitalAsegurado
              ? objCobertura.capitalAsegurado
              : intCapital;

            let objCoberturaNuevo = {
              ...objCobertura,
              capitalAsegurado: intCapitalAsegurado,
            };

            if (
              PRODUCTOS_CON_BENEFICIARIOS.includes(objCobertura.idProducto) ||
              (objCobertura.tipoPoliza &&
                POLIZAS_CON_BENEFICIARIOS.includes(
                  objCobertura.tipoPoliza.idTipoPoliza
                ))
            ) {
              objCoberturaNuevo.consentimiento = {
                aceptacion: true,
                ...this.state.consentimiento,
              };
            }

            return objCoberturaNuevo;
          }
        );
        dataWizardAsegurado.consentimiento = { ...this.state.consentimiento };

        // Guarda los datos en el mongo
        this.guardar(dataWizardAsegurado);
        this.setState({
          tabBeneficiarioActiva: TAB_ACTIVA_BENEFICIARIO_DECLARACION,
        });
      } else {
        // Se valida si acepto los terminos de condición
        notificacion("warning", ACEPTAR_TERMINOS);
        return;
      }
    } else {
      if (this.state.declaroBeneficiario === false) {
        this.props.actualizar_beneficiarios([]);
      }

      let { dataWizardAsegurado } = this.props.store.reducer;
      dataWizardAsegurado = {
        ...dataWizardAsegurado,
        solicitud: {
          ...dataWizardAsegurado.solicitud,
          declaroBeneficiario: this.state.declaroBeneficiario,
          aceptacionBeneficiario: true,
        },
      };

      // Se valida si acepto los terminos de condición
      if (!this.state.aceptacionCheck) {
        notificacion("warning", ACEPTAR_TERMINOS);
        this.setState({ loadingData: false });
        return;
      }

      // Guarda los datos en el mongo
      this.guardar(dataWizardAsegurado);

      // Se valida si declaro beneficiarios
      if (this.state.declaroBeneficiario) {
        if (
          !dataWizardAsegurado.beneficiarios ||
          !dataWizardAsegurado.beneficiarios.length
        ) {
          notificacion("warning", ALERTA_NO_BENEFICIARIOS);
          this.setState({ loadingData: false });
          return;
        } else if (
          this.state.sumaPorcentaje !== VALOR_CIEN &&
          this.state.mostrandoFormulario
        ) {
          notificacion("warning", VALIDACION_100_BENEFICIO);
          this.setState({ loadingData: false });
          return;
        }
      }

      if (this.state.declaroBeneficiario !== null) {
        this.props.aceptar_declaro_beneficiario(this.state.declaroBeneficiario);
        if (
          !dataWizardAsegurado.beneficiarios ||
          !dataWizardAsegurado.beneficiarios.length
        ) {
          this.props.actualizar_beneficiarios([]);
        }
        scroll.scrollToTop();
        setTimeout(() => {
          this.props.wizard({ pagina: PAGINA_5, type: TIPO_PAGINA });
        }, 100);
      } else {
        this.setState({
          declaroBeneficiario: false,
          mostrandoFormulario: true,
        });
      }
    }
    this.setState({ loadingData: false });
  };

  guardar = async (dataWizardAsegurado) => {
    if (
      dataWizardAsegurado.solicitud &&
      dataWizardAsegurado.solicitud.tipoSolicitud &&
      dataWizardAsegurado.solicitud.tipoSolicitud.idTipoSolicitud &&
      dataWizardAsegurado.solicitud.tipoSolicitud.idTipoSolicitud ===
        TIPO_SOLICITUD_DECLARACION_BENEFICIARIO
    ) {
      dataWizardAsegurado.peso = VALOR_ZERO;
      dataWizardAsegurado.estatura = VALOR_ZERO;
    }
    let enviarDeclaroBeneficiario = await guardarDataWizard(
      dataWizardAsegurado
    );
    if (enviarDeclaroBeneficiario !== null) {
      notificacion("success", EXITO_ACEPTACION);
      scroll.scrollToTop();
      this.props.guardar_data_wizard_asegurado(dataWizardAsegurado);
    } else notificacion("danger", ERROR_GUARDAR_ACEPTACION);
  };

  render() {
    return (
      <Fragment>
        <div className="card wizard o-white">
          <div className="row col-12 pt-3 mt-3 mx-5">
            <Tabs
              className="col-12"
              id="tabBeneficiarios"
              style={{ width: "100%" }}
              activeKey={this.state.tabBeneficiarioActiva}
              onSelect={(tabSeleccionada) => {
                this.setState({
                  tabBeneficiarioActiva: tabSeleccionada,
                });
              }}
            >
              {/* Consentimiento Seguro Vida */}
              <Tab
                eventKey="tabConsentimiento"
                title="Consentimiento Seguro Vida"
              >
                <div className="col-md-12 mt-3">
                  <div className="col-md-12">
                    <div
                      className="row"
                      style={{
                        background: "#EAE8E8",
                        padding: 10,
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          width: 26,
                          height: 26,
                          margin: "3px 5px 0 0",
                          backgroundColor: "white",
                          borderRadius: 20,
                        }}
                      >
                        <span className="beneficiarioI">i</span>
                      </div>
                      <span
                        className="font-weight-bold"
                        style={{ marginTop: 4 }}
                      >
                        Información Importante
                      </span>
                    </div>
                    <div className="row" style={{ marginTop: 20 }}>
                      <div>
                        <span
                          className="font-weight-bold"
                          style={{ marginLeft: 10 }}
                        >
                          Aceptación
                        </span>
                      </div>
                    </div>
                    <div
                      className="row"
                      style={{ marginTop: 10, marginBottom: 100 }}
                    >
                      <div
                        className="col-1 text-center my-auto"
                        style={{ marginTop: 1 }}
                      >
                        <label className="switch">
                          <input
                            ref="aceptacionRef"
                            name="aceptacion"
                            id="inputCheck"
                            checked={this.state.declaroConsentimiento}
                            onChange={() => {
                              this.aceptarConsentimiento();
                            }}
                            type="checkbox"
                          />
                          <span className="slider round" />
                        </label>
                      </div>
                      <div className="col-10">
                        <span>
                          {
                            this.state.consentimiento.catalogoConsentimiento
                              .glosa
                          }
                        </span>
                      </div>
                      <div className="col-1"></div>
                      <div className="col-1"></div>
                      {this.state.consentimiento.negocioMantencion && (
                        <div className="col-10">
                          <div
                            className="row col-12"
                            style={{ display: "content", marginTop: 20 }}
                          >
                            <div>
                              <span className="font-weight-bold">
                                Número de Póliza
                              </span>
                              <br></br>
                              <span style={{ marginTop: 10 }}>
                                {this.state.consentimiento.poliza}
                              </span>
                            </div>
                            {this.state.consentimiento.capital >
                              VALOR_VACIO && (
                              <div style={{ marginLeft: 25 }}>
                                <span className="font-weight-bold">
                                  Capital asegurado
                                </span>
                                <br></br>
                                <span style={{ marginTop: 10 }}>
                                  {this.state.consentimiento.capital}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Tab>

              {/* Declaración Beneficiarios */}
              <Tab
                eventKey="tabDeclaracion"
                title="Declaración Beneficiarios"
                disabled={!this.state.declaroConsentimiento}
              >
                <div className="col-md-12 pt-3">
                  {this.state.aceptacionCheck &&
                  this.state.mostrandoFormulario ? (
                    <FormularioBeneficiarios
                      guardarSumaPorcentaje={this.guardarSumaPorcentaje}
                      declaraBeneficiario={this.declaraBeneficiario}
                    />
                  ) : (
                    <InformacionBeneficiarios
                      aceptarCheck={this.aceptarCheck}
                    />
                  )}
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
        <div className="button-zone text-right pt-3 pb-3">
          <button
            id="paginaAnterior"
            className="btn btn-principal color-secundario btn-lg"
            type="button"
            onClick={this.anteriorPagina}
            disabled={
              this.props.store.reducer.data_asegurable.idTipoSolicitud ===
              VALOR_CINCO
            }
          >
            Atrás
          </button>

          <button
            id="paginaSiguiente"
            className="btn btn-principal btn-lg"
            type="button"
            onClick={this.siguientePagina}
            disabled={this.state.loadingData}
          >
            {this.state.loadingData ? (
              <>
                <i className="fa fa-spinner fa-pulse fa-fw"></i>
                <span className="sr-only">Loading...</span>
              </>
            ) : (
              <span>Siguiente</span>
            )}
          </button>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state,
});

export default withRouter(
  connect(mapStateToProps, {
    wizard,
    aceptar_declaro_beneficiario,
    actualizar_beneficiarios,
    guardar_data_wizard_asegurado,
  })(Beneficiarios)
);
