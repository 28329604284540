import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";

export default class Modal extends Component {
  render() {
    return ReactDOM.createPortal(
      <div
        className={`${
          !this.props.show ? "modal_overlay " : "modal_overlay show "
        } ${
          this.props.overlay === undefined
            ? ""
            : this.props.overlay
            ? ""
            : "light"
        }`}
        id={this.props.id}
      >
        <div
          className={`main_modal animated bounceInLeft ${
            this.props.centered ? "center" : ""
          } ${this.props.type === undefined ? "large" : this.props.type}`}
        >
          <div className="row">
            {this.props.showLeftColumn !== false && (
              <div className="d-none d-md-block col-md-4 bg-primary text-white text-center left-side">
                {this.props.leftSideContent !== undefined && (
                  <div className="icon-section">
                    <div
                      className={
                        this.props.leftSideContent
                          ? `${this.props.leftSideContent.icono} icono`
                          : null
                      }
                    >
                      <span className="path1"></span>
                      <span className="path2"></span>
                      <span className="path3"></span>
                      <span className="path4"></span>
                      <span className="path5"></span>
                    </div>
                    <div className="pt-3">
                      {this.props.leftSideContent
                        ? this.props.leftSideContent.declaracion
                        : null}
                    </div>
                  </div>
                )}
              </div>
            )}
            <div
              className={
                this.props.showLeftColumn !== false
                  ? "col-12 col-md-8"
                  : "col-12"
              }
            >
              <div className="modal_title">
                <div className="title">
                  <h3 style={{ color: "#ed8d00" }}>{this.props.title}</h3>
                </div>
                {this.props.showCloseButton === undefined ||
                this.props.showCloseButton ? (
                  <div
                    className="button_close cursor"
                    onClick={() => {
                      this.props.onClose();
                    }}
                  >
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      viewBox="0 0 352 512"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z" />
                    </svg>
                  </div>
                ) : null}
              </div>
              <div className="modal_body">{this.props.children}</div>
              <div className="modal_foot">
                {this.props.disableSubmitButton !== undefined && (
                  <label>
                    <input
                      checked={this.props.checkDisabledButton}
                      onChange={this.props.activeModalButton}
                      type="checkbox"
                    />{" "}
                    {this.props.checkText}
                  </label>
                )}

                <div className="button_zone">
                  {this.props.showCancelButton === undefined ||
                  this.props.showCancelButton ? (
                    <button
                      className="btn btn-principal color-cancel btn-sm"
                      type="button"
                      onClick={() => {
                        this.props.onCancel();
                      }}
                    >
                      Cancelar
                    </button>
                  ) : null}

                  {this.props.titleButton !== undefined ? (
                    <button
                      className="btn btn-principal btn-sm"
                      type="submit"
                      disabled={this.props.disableSubmitButton}
                      onClick={() => {
                        this.props.onSubmit();
                      }}
                    >
                      {this.props.titleButton}
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>,
      document.querySelector(`#spinnerNaranjo`)
    );
  }
}

Modal.propTypes = {
  show: PropTypes.bool,
  type: PropTypes.string,
  showLeftColumn: PropTypes.bool,
  title: PropTypes.string,
  showCloseButton: PropTypes.bool,
  showCancelButton: PropTypes.bool,
};
