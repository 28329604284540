import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import {
  PAGINA_1,
  PAGINA_2,
  PAGINA_3,
  TIPO_INCORPORACION_MANTENCION,
  TIPO_CANAL_VENTA_DIRECTA,
  COD_DELEGADO,
  TIPO_CANAL_VENTA_CORREDOR,
  COD_EJECUTIVO_MANTENCION,
  TITULO_INGRESAR_ASEGURABLES,
  TITULO_EXCLUSION_INDIVIDUAL,
  TITULO_CARGA_MASIVA,
  TITULO_EXCLUSION_MASIVA,
  RUTA_ABSOLUTA_INCORPORACION,
  RUTA_ABSOLUTA_INGRESAR_ASEGURABLES,
  RUTA_ABSOLUTA_ESTADO_ENROLAMIENTO,
  COD_EJECUTIVO_COMPLETO,
  COD_CORREDOR,
  GA_CATEGORIA_ASEGURABLE,
  GA_LABEL_AGREGAR_ASEGURABLE,
} from "../../../../../utils/Constantes";
//import SeleccionTipoIncorporacion from "../SeleccionTipoIncorporacion";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Spinner from "../../../transversales/Spinner";
import AgregarPolizaSoftwareRRHH from "../ingresarAsegurables/cargaSoftwareRRHH/AgregarPolizaSoftwareRRHH";

import TablaAseguradosRRHH from "../ingresarAsegurables/cargaSoftwareRRHH/TablaAseguradosRRHH";
import CargaSoftwareRRHH from "../ingresarAsegurables/cargaSoftwareRRHH/CargaSoftwareRRHH";

import { NavbarAsegurable } from "./NavbarAsegurable";
import CargaMasiva from "../ingresarAsegurables/cargaMasiva/CargaMasiva";
import CargaIndividualMantencion from "../ingresarAsegurables/cargaIndividual/CargaIndividualMantencion";

import { CargaIndividual } from "./cargaIndividual/CargaIndividual";
import { CargaIndividualVentaDirecta } from "./cargaIndividual/CargaIndividualVentaDirecta";
import { EjecutarSeleccionIncorporacion } from "../../../autenticacion/Incorporacion";
import { crearEventoTraza } from "../../../../../utils/analitica";

export const IngresarAsegurablesPage = () => {

  useEffect(() => {
    crearEventoTraza(GA_CATEGORIA_ASEGURABLE, GA_LABEL_AGREGAR_ASEGURABLE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const location = useLocation();
  

  const usuarioSesion = useSelector((store) => store.auth.usuarioSesion);
  const negocio = useSelector((store) => store.reducer.negocio);
  const delegado = useSelector((store) => store.delegado);
  const tipoIncorporacion = useSelector((store) => store.auth.tipoIncorporacion);
  const usuarioRolId  = usuarioSesion.rol.codigo;

  const intCanalVenta = negocio && negocio.canalVenta ? negocio.canalVenta.idCanalVenta : null;
  const [intPagina, actualizarIntPagina] = useState(1);

  const blnDeclaracionAceptacion = true;


  const objMovimiento = {
    individual:
      location.pathname.includes(RUTA_ABSOLUTA_INCORPORACION) ||
      location.pathname.includes(RUTA_ABSOLUTA_INGRESAR_ASEGURABLES) ||
      location.pathname.includes(RUTA_ABSOLUTA_ESTADO_ENROLAMIENTO)
        ? TITULO_INGRESAR_ASEGURABLES
        : TITULO_EXCLUSION_INDIVIDUAL,
    masiva:
      location.pathname.includes(RUTA_ABSOLUTA_INCORPORACION) ||
      location.pathname.includes(RUTA_ABSOLUTA_INGRESAR_ASEGURABLES) ||
      location.pathname.includes(RUTA_ABSOLUTA_ESTADO_ENROLAMIENTO)
        ? TITULO_CARGA_MASIVA
        : TITULO_EXCLUSION_MASIVA,
    blnSesionCorredor: usuarioRolId === COD_CORREDOR,
  };

  const { existenEmpleados, primeraRenderizacionTabla, asegurablesFinales } = delegado.softwareAlianza;
  const { loading } = delegado;

  //TODO: Actualmente guarda en session un 1
  const intSeleccionTipoProceso = sessionStorage.getItem("_nTiVC");
  const blnTipoIncorporacionMantencion = tipoIncorporacion === TIPO_INCORPORACION_MANTENCION;
  const blnPerfilDelegado = usuarioRolId === COD_DELEGADO;
  
  return (
    <div className="row">
      <div className="col-md-7 mb-3 text-muted"></div>

      {objMovimiento.blnSesionCorredor && (
        <div className="col-md-5 mb-3 align-self-center">{intSeleccionTipoProceso && <EjecutarSeleccionIncorporacion />}</div>
      )}




      <div className="col-10 offset-sm-1">
        <div className="card shadow">
          {(blnDeclaracionAceptacion || usuarioRolId === COD_EJECUTIVO_MANTENCION || usuarioRolId === COD_EJECUTIVO_COMPLETO) && (
            <NavbarAsegurable
              pagina={intPagina}
              textoMovimiento={objMovimiento}
              actualizarPagina={actualizarIntPagina}
              tipoIncorporacionMantencion={blnTipoIncorporacionMantencion}
              perfilDelegado={blnPerfilDelegado}
            />
          )}

          {/* <NavbarIngresoAsegurable /> */}
          <div className="card-body">
             {/* TODO: CARGA INDIVIDUAL */}
             {intPagina === PAGINA_1 &&
              (blnDeclaracionAceptacion || usuarioRolId === COD_EJECUTIVO_MANTENCION || usuarioRolId === COD_EJECUTIVO_COMPLETO) &&
              (blnTipoIncorporacionMantencion || usuarioRolId === COD_EJECUTIVO_MANTENCION || usuarioRolId === COD_EJECUTIVO_COMPLETO ? (
                <CargaIndividualMantencion />
              ) : intCanalVenta === TIPO_CANAL_VENTA_DIRECTA || intCanalVenta === TIPO_CANAL_VENTA_CORREDOR ? (
                <CargaIndividualVentaDirecta usuarioSesion={usuarioSesion} intCanalVenta={intCanalVenta} />
              ) : (
                <CargaIndividual usuarioSesion={usuarioSesion} intCanalVenta={intCanalVenta} />
              ))}


            {intPagina === PAGINA_2 &&
              blnDeclaracionAceptacion &&
              (intCanalVenta !== TIPO_CANAL_VENTA_CORREDOR && negocio && negocio.fechaInicioEnrolamiento ? (
                <div className="container text-justify my-5 text-muted text-center h4 animated fadeInRight">
                  Ya se ha iniciado el enrolamiento. Solo puede ingresar
                  <br></br>
                  asegurables individualmente.
                </div>
              ) : (
                <CargaMasiva             datosSolicitud={{ intPagina, blnDeclaracionAceptacion }}
                                         usuarioRolId={usuarioRolId}
                                         tipoIncorporacion={tipoIncorporacion}/>
              ))}
            {/* TODO: Logica RRHH */}
            {intPagina === PAGINA_3 && blnDeclaracionAceptacion && blnTipoIncorporacionMantencion && <CargaSoftwareRRHH />}
          </div>

          {/* TODO: Logica RRHH */}
          <div className="col-10 offset-sm-1 my-4">
            {intPagina === PAGINA_3 && (
              <TransitionGroup>
                {loading ? (
                  <Spinner claseColor="transparente" idSpinner="spinnerInterior" posicionLogo="posicionLogo align-self-center w-100" />
                ) : (
                  !primeraRenderizacionTabla && (
                    <CSSTransition timeout={300} classNames="software-alianza-transicion">
                      <div>
                        {existenEmpleados && <AgregarPolizaSoftwareRRHH />}
                        <TablaAseguradosRRHH />
                        <div className="row no-gutters">
                          <div className="col-12 offset-10">
                            <button
                              id="enviar"
                              disabled={!asegurablesFinales.length}
                              className="btn btn-principal mt-4 d-block"
                              onClick={() => {
                                this.props.history.push({
                                  pathname: "/delegado/carga-software-alianza",
                                });
                              }}
                            >
                              Continuar
                            </button>
                          </div>
                        </div>
                      </div>
                    </CSSTransition>
                  )
                )}
              </TransitionGroup>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IngresarAsegurablesPage;
