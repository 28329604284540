import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { resetSesion } from "../../../../actions/actions";
import { cerrarSesion as cerrarSesionServicio } from "../../../../services/auth.services";
import cookie from "react-cookies";
import EncuestaSatisfaccion from "../../transversales/EncuestaSatisfaccion";
import { RUTA_INICIO_SESION } from "../../../../utils/Constantes";

const icoLike = require("../../../assets/images/like.svg");

class SolicitudCompletadaDelegado extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idEncuestaSatisfaccion: this.props.store.reducer.negocio
        .idEncuestaSatisfaccion,
      idNegocio: this.props.store.reducer.negocio.idNegocio,
    };
  }

  cerrarSesion = async () => {
    cerrarSesionServicio(this.props.store.auth.usuarioSesion.rol.codigo);
    this.props.resetSesion();
    cookie.remove("time", { path: "/" });
    sessionStorage.removeItem("_nSeVC");
    sessionStorage.clear();
    this.props.history.push(RUTA_INICIO_SESION);
    window.location.replace("");
  };

  render() {
    return (
      <React.Fragment>
        <div className="animated fadeIn">
          <div className="row pt-4 mb-4">
            <div className="col-12">
              <div className="card shadow">
                <div className="card-body p-5">
                  <div className="d-block text-center my-3">
                    <img src={icoLike} alt="Solicitud completa" width="250px" />
                  </div>

                  <h3 className="text-primary text-center mt-5">
                    <b>COMPLETADO!</b> El proceso de enrolamiento a terminado
                  </h3>

                  {!this.state.idEncuestaSatisfaccion ? (
                    <EncuestaSatisfaccion
                      idNegocio={this.state.idNegocio}
                      validarEncuestaEnviada={() => {
                        this.cerrarSesion();
                      }}
                    />
                  ) : (
                      <button
                        id="botonCerrarSesion"
                        className="btn btn-principal btn-sm d-block mx-auto mt-3"
                        type="button"
                        onClick={this.cerrarSesion}
                      >
                        Cerrar sesión
                      </button>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state,
});

export default withRouter(
  connect(mapStateToProps, { resetSesion })(SolicitudCompletadaDelegado)
);
