import React from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

const ModalEliminarAsegurableRRHH = (props) => {

  const asegurable = props.datosAsegurable;

  // { handleSubmitDelete, handleMostrarEliminar, datosAsegurable }

  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className="modal-confirm-container">
          <div className="d-flex">
            <p className="h4">
              ¿Está seguro(a) que desea eliminar al trabajador?
            </p>
          </div>

          <div className="row mt-4">
            <button
              className="btn btn-principal color-secundario btn-sm d-block ml-auto mr-2"
              onClick={() => {
                onClose();
                props.handleMostrarEliminar();
              }}
            >
              No
            </button>

            <button
              className="btn btn-principal btn-sm"
              onClick={() => {
                onClose();
                props.handleSubmitDelete(asegurable);
              }}
            >
              Sí
            </button>
          </div>
        </div>
      );
    }
  });

  return null;
}

export default ModalEliminarAsegurableRRHH;