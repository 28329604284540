import React, { Component } from "react";

export default class Consentimientos extends Component {
  render() {
    return (
      <React.Fragment>
        <h6 className="bg-secondary text-white p-2 linea-subtitulo font-weight-bold">
          DECLARACIONES, AUTORIZACIONES Y CONSENTIMIENTO DE DATOS PERSONALES.
        </h6>

        <p className="text-justify">
          <strong>I.DECLARACIONES</strong>
          <br />
          Declaro haber sido oportuna y completamente informado por Compañía de
          Seguros de Vida Cámara S.A, en adelante “la Compañía” de las
          características del seguro colectivo al cual solicito ser incorporado,
          especialmente las condiciones generales que detalla la cobertura, sus
          exclusiones, declaraciones obligatorias según formularios
          proporcionados en línea por “la Compañía”, formas de término, formas
          de pago; las que he tenido a mi alcance y disposición en forma previa
          a esta declaración y que declaro haber leído y revisado, conjuntamente
          con los Términos y Condiciones de uso del sitio y la Política de
          Privacidad. También declaro haber sido informado(o), acerca de que
          cualquier omisión, error, reticencia o inexactitud relativa a mi
          identidad, estado de salud, edad, ocupación, actividades o deportes
          riesgosos, que pudiere influir en la apreciación del riesgo, faculta a
          Compañía de Seguros de Vida Cámara S.A. para proponer una modificación
          al contrato de seguros, para reducir proporcionalmente o rechazar el
          pago de la indemnización reclamada; todo lo cual acepto expresamente.
          Por lo mismo, autorizo a la Compañía de Seguros de Vida Cámara S.A. a
          solicitar todos aquellos antecedentes y exámenes médicos que
          consideren necesarios para la evaluación del riesgo propuesto.
          <br /> <br />
          <strong>
            II.AUTORIZACIÓN PARA SOLICITAR INFORMACIÓN CONFIDENCIAL
          </strong>
          <br />
          Autorizo expresamente a cualquier médico, profesional de la salud,
          institución de salud pública o privada, tribunales de justicia, juez
          árbitro y a cualquier otra persona natural o jurídica, pública o
          privada que cuente con datos, información o antecedentes relativos a
          mi estado de salud física o psíquica relevantes y atingentes a la
          reclamación de reembolso o pago de indemnización que hubiere
          presentado a la aseguradora, tales como fichas clínicas, antecedentes
          clínicos, informes médicos y análisis o exámenes de laboratorios
          clínicos, a entregarlos a Compañía de Seguros de Vida Cámara S.A. y/o
          al liquidador oficial de seguros que tuviere asignada la liquidación
          del caso, cuando alguno de estos así lo solicite, según corresponda,
          para la evaluación del riesgo y/o de la procedencia y/o monto de la
          indemnización reclamada, por lo cual otorgo mi expreso consentimiento
          conforme lo disponible el artículo 4 de la Ley N°19.628 y el Artículo
          127 del Código Sanitario. Por otra parte, Compañía de Seguros de Vida
          Cámara S.A. se obliga a guardar absoluta reserva y confidencialidad
          respecto de la información recibida, así como a utilizarla solo para
          los fines antes informados.
          <br /> <br />
          <strong>
            III.CONTRATACION ELECTRÓNICA Y COMUNICACIÓN ENTRE LAS PARTES
          </strong>
          <br />
          Declaro conocer y aceptar que la solicitud de incorporación al seguro
          colectivo se emite en forma electrónica y que cualquier comunicación,
          declaración o notificación que deba remitir o enviar la Compañía de
          Seguros al Asegurado con motivo de la póliza se efectúe a la dirección
          de correo electrónico registrada en mis datos personales, el que será
          el canal formal de comunicación, razón por la cual me obligo a
          mantenerla debidamente actualizada y a revisar las comunicaciones que
          se me envíen. Igualmente, autorizo expresamente a la Compañía de
          Seguros para que, directamente o, a través del contratante del seguro,
          me envíe y haga entrega del certificado de cobertura o copia de la
          póliza a la dirección de correo electrónico previamente indicada. En
          caso de que no desee recibir por medio de correo electrónico las
          comunicaciones enviadas por la Compañía de Seguros lo comunicaré a
          ésta a través de su contact center (600 320 0700) informado en el
          sitio www.vidacamara.cl
          <br /> <br />
          <strong>IV.USO DE DATOS PERSONALES</strong>
          <br />
          Conforme a lo señalado en la Ley N°19.628, relativa a la Protección de
          la Vida Privada, por este acto vengo en facultar expresamente a la
          Compañía de Seguros a la que estoy solicitando el(los) presente(s)
          seguro(s), o quien sus derechos represente, para hacer uso de mis
          datos de carácter personal para los fines de evaluación de riesgo y/o
          de liquidación del o los siniestros que denuncie e indemnizaciones o
          reembolsos que reclame, autorización que cubre los datos denominados
          como sensibles. Esta autorización faculta a la Aseguradora para
          efectuar el tratamiento de los datos, es decir, cualquier operación o
          complejo de operaciones o procedimientos técnicos, de carácter
          automatizado o no, que permitan recolectar, almacenar, grabar,
          organizar, elaborar, seleccionar, extraer, confrontar, interconectar,
          disociar, comunicar, ceder, transferir, transmitir o cancelar datos de
          carácter personal, o utilizarlos en cualquier otra forma. Dejo
          constancia que la autorización abarca el acceso a los contenidos o
          copias de las recetas médicas, análisis o exámenes de laboratorios
          clínicos y servicios relacionados con la salud, según lo expresa el
          artículo 127 del Código Sanitario, modificado por la Ley ya citada; y
          además aplica a su matriz, filiales, sociedades coligadas, empresas
          relacionadas, sociedades del Grupo Empresarial al que pertenece y
          terceros prestadores de servicios, estén ubicados dentro o fuera de
          Chile, con el propósito de que tanto Compañía de Seguros de Vida
          Cámara S.A. como dichas entidades: i) me contacten y pongan a mi
          disposición, en la forma que estimen pertinente, la más completa
          información y ofrecimiento de sus productos y servicios; ii) procedan
          al almacenamiento y tratamiento de mis datos personales y de mi
          información personal para los objetivos del cumplimiento de este
          Contrato de Seguro; y iii) hagan efectivos el o los convenios de
          beneficios o asistencias adicionales que pudieren estar asociados a
          los seguros que he contratado, enviando, cuando corresponda al caso
          según el tipo de reclamación o siniestro y/o modalidad de pago o
          indemnización, informaciones y/o antecedentes a I-MED, prestadores de
          los beneficios de asistencia que fueren pertinentes y farmacias.
          <br /> <br />
         </p>
      </React.Fragment>
    );
  }
}
