import React from "react";
import moment from "moment";
import imgNegAsegurable from "../../../../assets/images/negocio-asegurable.png";
import { SIGNO_GUION, VALOR_INICIAL_INDICE } from "../../../../../utils/Constantes";

const { format } = require("rut.js");

const CabeceraNegocioDelegado = (props) => {
  let strProducto = "";
  props.cotizacion && props.cotizacion.forEach((objCotizacion, intIndice) => {
    if (intIndice === VALOR_INICIAL_INDICE) {
      strProducto = objCotizacion.subProducto.glosaSubProducto;
    } else {
      strProducto = strProducto + " " + SIGNO_GUION + " " + objCotizacion.subProducto.glosaSubProducto;
    }
  })
  const { fechaTerminoEnrolamiento } = props.negocio
  let fechaTer = moment(fechaTerminoEnrolamiento).format("DD/MM/YYYY");
  let diasRestantes = moment(fechaTerminoEnrolamiento).diff(moment().toDate(), "days") + " días";

  return (
    <div className="card shadow mb-3 py-4">
      <div className="row">
        <div className="col-md-3 text-center">
          <img width="85" height="85" src={imgNegAsegurable} alt="" />
        </div>
        <div className="col-md-9">
          <div className="row">
            <div className="col-md-5">
              <span
                className="font-weight-bold mb-2 d-block h6"
                style={{ color: "#e89b03" }}
              >
                Datos de empresa
              </span>
              <span className="font-weight-bold text-muted mr-1">
                Razón social:
              </span>
              {props.negocio.razonSocial} <br />
              <span className="font-weight-bold text-muted mr-1">RUT:</span>
              {format(
                `${props.negocio.rutEmpresa}-${props.negocio.digitoVerificador}`
              )}{" "}
              <br />
              <span className="font-weight-bold text-muted mr-1">
                Ejecutivo:
              </span>
              {`${props.negocio.ejecutivo.nombre} ${props.negocio.ejecutivo.apellidoPaterno}`}
              <br />
            </div>

            <div
              className="col-md-1 "
              style={{ borderLeft: "1px solid #e89b03" }}
            ></div>

            <div className="col-md-6">
              <span
                className="font-weight-bold mb-2 d-block h6"
                style={{ color: "#e89b03", visibility: "hidden" }}
              >
                Datos de la cotización
              </span>
              <span className="font-weight-bold text-muted mr-1">
                Producto:
              </span>
              {strProducto}
              <br />
              <span className="font-weight-bold text-muted mr-1">
                Fecha termino enrolamiento:
              </span>
              {fechaTer} <br />
              <span className="font-weight-bold text-muted mr-1">
                Días restantes:
              </span>
              {fechaTerminoEnrolamiento
                ? diasRestantes
                : "-"}
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CabeceraNegocioDelegado;
