export const INVALIDEZ = "Declaración Especial";
export const ACTIVIDAD_DEPORTE = "Declaración Actividad y Deporte";
export const DECLARACION_SALUD = "Declaración Personal Salud";
export const SALUD = "Salud";
export const COMPLEMENTARIA = "Complementaria";

/**************************
 *  COD_RESPUESTAS        *
 **************************/
export const COD_RES_1 = 1;
export const STATUS_EXITO = 200;
export const STATUS_TIMEOUT = 504;
export const COD_STATUS_EXITO = "001";
export const COD_STATUS_NA = "002";
export const CODIGO_ESTATUS_NO_APLICA = "002";
export const COD_STATUS_ERROR = "003";
export const CODIGO_ESTATUS_ERROR = "003";
export const COD_ERROR_PETICION_SERVIDOR = "02";
export const COD_ERROR_EXCEPCION_PETICION = "004";

/**************************
 *  ANALITYCS             *
 **************************/
export const GA_CATEGORIA_LOGIN = "Inicio sesión";
export const GA_CATEGORIA_LOGIN_EXTERNO = "Inicio sesión externo";
export const GA_CATEGORIA_CONTRASENA = "Manejo contraseña";
export const GA_CATEGORIA_FORMULARIO_ENROLAMIENTO = "Formulario enrolamiento";
export const GA_CATEGORIA_NEGOCIOS = "Negocios";
export const GA_CATEGORIA_EJECUTIVOS = "Ejecutivos";
export const GA_CATEGORIA_ASEGURABLE = "Asegurable";
export const GA_CATEGORIA_ENROLAMIENTO = "Enrolamiento";
export const GA_LABEL_ESCOGER_PERFIL = "Escoger perfil";
export const GA_LABEL_FORMULARIO = "Inicio sesión ";
export const GA_LABEL_ERROR = "Error: ";
export const GA_LABEL_INCORPORACION = "Escoger tipo incorporación";
export const GA_LABEL_RECUPERAR_CONTRASENA = "Recuperar contraseña";
export const GA_LABEL_CAMBIAR_CONTRASENA = "Cambiar contraseña";
export const GA_LABEL_CONTRASENA_EXPIRADA = "Contraseña expirada";
export const GA_LABEL_TERMINOS = "Terminos y condiciones";
export const GA_LABEL_SOLICITUD_PROCESOS = "Enrolamiento con doble solicitud";
export const GA_LABEL_SOLICITUD_FINALIZADA =
  "Enrolamiento finalizado sin incorporarse";
export const GA_LABEL_SOLICITUD_COMPLETADA = "Enrolamiento completado";
export const GA_LABEL_SOLICITUD_CERRADA = "Enrolamiento cerrado";
export const GA_LABEL_DATOS_PERSONALES = "Paso Datos personales: Mis datos";
export const GA_LABEL_DIRECCION = "Paso Datos personales: Dirección";
export const GA_LABEL_FORMA_PAGO = "Paso Datos personales: Forma de pago";
export const GA_LABEL_CARGAS = "Paso Cargas: Asegurable adicionales";
export const GA_LABEL_PREGUNTAS_COMPLEMENTARIA =
  "Paso Declaraciones: Preguntas complementarias";
export const GA_LABEL_PREGUNTAS_DEPORTAS =
  "Paso Declaraciones: Preguntas actividades y deportes";
export const GA_LABEL_PREGUNTAS_AMPLIACION =
  "Paso Declaraciones: Preguntas ampliación";
export const GA_LABEL_PREGUNTAS_SALUD = "Paso Declaraciones: Preguntas salud";
export const GA_LABEL_CONSENTIMIENTO_VIDA =
  "Paso Seguro de Vida: Consentimiento de seguro de vida";
export const GA_LABEL_CONSENTIMIENTO_BENEFICIARIOS =
  "Paso Seguro de Vida: Consentimiento de beneficiarios";
export const GA_LABEL_BENEFICIARIOS =
  "Paso Seguro de Vida: Declaración de beneficiarios";
export const GA_LABEL_RESUMEN = "Paso Resumen";
export const GA_LABEL_LISTADO_NEGOCIOS = "Listar negocios";
export const GA_LABEL_DETALLE_NEGOCIO = "Detalle negocio";
export const GA_LABEL_CONFIGURACION_NEGOCIO = "Configuración negocio";
export const GA_LABEL_CREAR_NEGOCIO = "Crear negocio";
export const GA_LABEL_AGREGAR_COTIZACION = "Agregar cotización";
export const GA_LABEL_RESUMEN_SOLICITUDES = "Resumen solicitudes";
export const GA_LABEL_LISTAR_SOLICITUDES = "Ver solicitudes";
export const GA_LABEL_AGREGAR_ASEGURABLE = "Agregar asegurable";
export const GA_LABEL_INDIVIDUAL_MANTENCION = "Ingreso individual mantención";
export const GA_LABEL_INDIVIDUAL_DIRECTA = "Ingreso individual venta directa";
export const GA_LABEL_INDIVIDUAL_NUEVO_NEGOCIO =
  "Ingreso individual nuevo negocio";
export const GA_LABEL_INGRESO_MASIVO = "Ingreso masivo";
export const GA_LABEL_GESTIONAR_EJECUTIVOS = "Gestionar ejecutivos";
export const GA_LABEL_AGREGAR_EJECUTIVO = "Agregar ejecutivo";
export const GA_LABEL_MOVER_EJECUTIVO = "Mover ejecutivo";
export const GA_LABEL_PERMISOS_EJECUTIVO = "Ejecutivo permisos";
export const GA_LABEL_INFORMACION_ENROLAMIENTO = "Información enrolamiento";
export const GA_LABEL_NOMINA_ENROLAMIENTO = "Nómina enrolamiento";
export const GA_LABEL_LISTADO_ASEGURABLES = "Listado asegurables";
export const GA_PERFIL_JEFATURA = "Perfil jefatura";
export const GA_PERFIL_EJECUTIVO = "Perfil ejecutivo comercial";
export const GA_PERFIL_EJECUTIVO_MANTO = "Perfil ejecutivo mantención";
export const GA_PERFIL_EJECUTIVO_COMPLETO = "Perfil ejecutivo completo";
export const GA_PERFIL_DELEGADO = "Perfil delegado";
export const GA_PERFIL_CORREDOR = "Perfil corredor";
export const GA_PERFIL_ASEGURADO = "Perfil asegurado";
export const GA_TIPO_PAGINA = "Pagina";
export const GA_TIPO_TRAZA = "Traza";
export const GA_EVENTO = "event";

/**************************
 *  TRANSVERSALES         *
 **************************/
export const COD_JEFATURA = 4;
export const COD_EJECUTIVO = 3;
export const COD_DELEGADO = 2;
export const COD_ASEGURADO = 1;
export const COD_CORREDOR = 5;
export const COD_EJECUTIVO_MANTENCION = 6;
export const COD_EJECUTIVO_COMPLETO = 7;
export const PERFIL_A = 1;
export const PERFIL_B = 2;
export const PERFIL_C = 3;
export const PERFIL_LDAP = "LDAP";
export const PERFIL_CORREDOR = "CORREDOR";
export const PERFIL_CORREDOR_COMERCIAL = "CORREDOR COMERCIAL";
export const PERFIL_DELEGADO = "DELEGADO";
export const PERFIL_DELEGADO_MANTENCION = "DELEGADO MANTENCIÓN";
export const PERFIL_ASEGURADO = "ASEGURADO";
export const PERFIL_ASEGURABLE = "ASEGURABLE";
export const PERFIL_EJECUTIVO = "EJECUTIVO";
export const PERFIL_JEFATURA = "JEFATURA";
export const PERFIL_EJECUTIVO_MANTENCION = "EJECUTIVO MANTENCIÓN";
export const PERFIL_EJECUTIVO_COMPLETO = "EJECUTIVO COMPLETO";
export const PERFILES_MANTENCION = [
  PERFIL_DELEGADO_MANTENCION,
  PERFIL_CORREDOR,
  PERFIL_EJECUTIVO_MANTENCION,
  PERFIL_EJECUTIVO_COMPLETO,
];
export const PERFILES_NEGOCIO_NUEVO = [
  PERFIL_DELEGADO,
  PERFIL_CORREDOR_COMERCIAL,
  PERFIL_EJECUTIVO,
  PERFIL_EJECUTIVO_COMPLETO,
];
export const EJECUTIVO_COMPLETO_MENU_NEGOCIOS = 1;
export const EJECUTIVO_COMPLETO_MENU_MANTENCION = 2;
export const PERFILES = ["Asegurable", "Delegado", "Corredor", "Interno"];
export const OBJETO_PERFILES = [
  { codigo: COD_DELEGADO, nombre: PERFIL_DELEGADO },
  { codigo: COD_CORREDOR, nombre: PERFIL_CORREDOR },
  { codigo: COD_EJECUTIVO, nombre: PERFIL_LDAP },
  { codigo: COD_EJECUTIVO_MANTENCION, nombre: PERFIL_LDAP },
  { codigo: COD_EJECUTIVO_COMPLETO, nombre: PERFIL_LDAP },
];

export const ROL_DELEGADO = "Delegado";
export const ROL_CORREDOR = "Corredor";
export const ROL_INTERNO = "Interno";
export const ROL_ASEGURABLE = "Asegurable";
export const ROL_ASEGURADO = "Asegurado";
export const ROL_CORREDOR_COMERCIAL = "Corredor Comercial";
export const ROL_DELEGADO_MANTENCION = "Delegado Mantención";
export const ROL_EJECUTIVO = "Ejecutivo";
export const ROL_JEFATURA = "Jefatura";
export const ROL_EJECUTIVO_MANTENCION = "Ejecutivo Mantención";
export const ROL_EJECUTIVO_COMPLETO = "Ejecutivo Completo";

export const ROL_USUARIO_ASEGURADO = {
  codigo: COD_ASEGURADO,
  glosa: ROL_ASEGURABLE,
};
export const ROL_USUARIO_CORREDOR = {
  codigo: COD_CORREDOR,
  glosa: ROL_CORREDOR,
};
export const ROL_USUARIO_DELEGADO = {
  codigo: COD_DELEGADO,
  glosa: ROL_DELEGADO,
};
export const ROL_USUARIO_EJECUTIVO = {
  codigo: COD_EJECUTIVO,
  glosa: ROL_EJECUTIVO,
};
export const ROL_USUARIO_JEFATURA = {
  codigo: COD_JEFATURA,
  glosa: ROL_JEFATURA,
};
export const ROL_USUARIO_EJECUTIVO_MANTENCION = {
  codigo: COD_EJECUTIVO_MANTENCION,
  glosa: ROL_EJECUTIVO_MANTENCION,
};
export const ROL_USUARIO_EJECUTIVO_COMPLETO = {
  codigo: COD_EJECUTIVO_COMPLETO,
  glosa: ROL_EJECUTIVO_COMPLETO,
};

export const RUT_DELEGADO = "rutDelegado";
export const RUT_EJECUTIVO = "rutEjecutivo";
export const USU_CORREDOR = "usuCodCorredor";
export const STRING_RUT = "rut";
export const DIGITO_VERIFICADOR = "digitoVerificador";

export const FLUJO_VENTA_DIRECTA = "ventaDirecta";
export const FLUJO_VENTA_DIGITAL = "ventaDigital";
export const FLUJO_MANTENCION = "mantencion";

export const ID_TIPO_ATRIBUTO_CONDICION_6 = 6;
export const ID_TIPO_ATRIBUTO_CONDICION_7 = 7;
export const ID_TIPO_ATRIBUTO_CONDICION_9 = 9;
export const ID_TIPO_ATRIBUTO_CONDICION_17 = 17;

export const STRING_ON = "on";

export const NOTIFICACION_SIN_OPCIONES =
  "Por el momento no es posible modificar esta opción. Intente más tarde.";
export const ACEPTAR_TERMINOS = "Debe aceptar los términos para continuar";
export const DATATABLE_NO_DATA = "No hay datos disponibles";
export const TYPEOF_NUMBER = "number";
export const TYPEOF_STRING = "string";
export const TYPEOF_OBJECT = "object";

export const MASK_DATE = [
  /\d/,
  /\d/,
  "/",
  /\d/,
  /\d/,
  "/",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const STRING_VACIO = "";
export const ESPACIO_VACIO = " ";
export const CHAR_S = "s";
export const NUMERO = "Nº";
export const CHAR_Y = " y ";
export const COMMA = ", ";
export const VALOR_VACIO_GUION = "-";
export const VALOR_GUION = "-";
export const VALOR_NUMERAL = "#";
export const ARROBA = "@";
export const ASTERISCO = "*";
export const VALOR_DUPLICADO = "duplicado";
export const ID_ZERO = 0;
export const ID_UNO = 1;
export const ID_TRES = 3;
export const ID_CUATRO = 4;
export const ID_OCHO = 8;
export const VALOR_NEGATIVO = -1;
export const VALOR_ZERO = 0;
export const ZERO_STRING = "0";
export const VALOR_UNO = 1;
export const UNO_STRING = "1";
export const DIA_UNO = "1";
export const MES_UNO = 1;
export const VALOR_DOS = 2;
export const DOS_STRING = "2";
export const VALOR_TRES = 3;
export const VALOR_CUATRO = 4;
export const VALOR_CINCO = 5;
export const VALOR_SEIS = 6;
export const VALOR_NUEVE = 9;
export const VALOR_CINCUENTA = 50;
export const VALOR_CIEN = 100;
export const VALOR_CIEN_VEINTE = 120;
export const VALOR_OCHENTA = 80;
export const VALOR_TRECE = 13;
export const VALOR_QUINCE = 15;
export const VALOR_TREINTA_Y_OCHO = 38;
export const VALOR_SIETE = 7;
export const VALOR_DIA = 86400000;
export const VALOR_SESENTA = 60;
export const VALOR_TEMPORIZADOR = 1000;
export const ID_PERSONA_CERO = 0;
export const LARGO_CERO_SUCURSALES = 0;
export const LARGO_CERO_COTIZACION = 0;
export const LARGO_UNO_COTIZACION = 1;
export const LARGO_CERO_AGRUPACIONES = 0;
export const LARGO_CERO_GRUPOS = 0;
export const LARGO_CERO_COBERTURAS = 0;
export const LARGO_CERO_TRABAJADORES_ENROLADOS = 0;
export const SIN_BENEFICIARIOS = 0;
export const LARGO_UNO_AGRUPACIONES = 1;
export const LARGO_DOS_AGRUPACIONES = 2;
export const POSICION_INICIAL_LISTA_POLIZA = 0;
export const POSICION_INICIAL = 0;
export const SEGUNDA_POSICION = 1;
export const TERCERA_POSICION = 2;
export const PRIMERA_POSICION_LISTA = 1;
export const SEGUNDA_POSICION_LISTA = 2;
export const VALOR_INICIAL_CONTADOR = 0;
export const VALOR_VACIO_ID_CONTRATANTE = 0;
export const SUMAR_UNO_CONTADOR = 1;
export const SUMAR_UNO_INDICE = 1;
export const VALOR_INICIAL_INDICE = 0;
export const POSICION_DIGITO_VERIFICADOR = 1;
export const MOVIMIENTO_SELECCIONADO_INICIAL = 0;
export const NEGOCIO_SELECCIONADO_INICIAL = 0;
export const ID_PREGUNTA_SIETE = 7;
export const NEGOCIO_USUARIO_REVISOR_ID_INICIAL = 0;
export const LARGO_TELEFONO_VALIDO = 13;
export const LARGO_TELEFONO_VACIO = 0;
export const LARGO_CORREO_VACIO = 0;
export const ID_PRODUCTO_COBERTURA = 0;
export const SIN_VALOR_CAPITAL = 0;
export const SIN_VALOR_PLAN = 0;
export const CANTIDAD_NUMERO_TELEFONO_VISIBLE = 3;
export const PRIMER_CARACTER = 0;
export const CANTIDAD_ASEGURABLES_APROBADOS_INICIAL = 0;
export const DOTACION_INICIAL = 0;
export const CANTIDAD_ASEGURABLE_INICIAL = 0;
export const LARGO_LISTA_VACIO = 0;
export const VALOR_VACIO = 0;
export const PRIMERA_POSICION = 0;
export const VERDADERO = true;
export const FALSO = false;
export const ANCHO_MONTRAR_MENU = 992;
export const POSICION_UNO = 1;
export const NO_DECIMALES = 0;
export const MENU_UNO = 1;
export const MENU_TRES = 3;
export const MENU_BAJAS_CODIGO = 13;
export const MENU_BAJAS_GLOSA = "Bajas";
export const NUMERO_RENOVACION_CERO = 0;
export const NOTIFICACION_EXITOSA = "success";
export const NOTIFICACION_ADVERTENCIA = "warning";
export const NOTIFICACION_ERROR = "danger";
export const NOTIFICACION_INFO = "info";
export const NO_SE_ENCONTRO_ASEGURABLE =
  "No se encontro un asegurable para enrolar";

export const QUINCE_YEARS = 15;
export const QUINCE_DIAS = 15;
export const MAYORIA_EDAD = 18;
export const MINIMA_EDAD = 90;
export const DIAS_TERMINO_ENROLAMIENTO = 90;

export const SIN_INDEX = -1;
export const PLAZO_ENROLAMIENTO = 14;
export const MENSAJE_REENVIO_CORREO = "se ha reenviado acceso temporal a ";
export const TIPO_FILTRO = "filtro";
export const TIPO_BUSQUEDA = "busqueda";

export const TIPO_PAGINA = "pagina";
export const TIPO_TAB = "tab";

export const STORAGE_ITEM_RUTA = "_nRuVC";
export const STORAGE_ITEM_NEGOCIO = "_nNegVC";
export const STORAGE_ITEM_SESION = "_nSeVC";
export const STORAGE_ITEM_NEGOCIO_ACTIVO = "_nTnVC";
export const STORAGE_ITEM_PERFILES = "_nPeVC";
export const STORAGE_ITEM_HORA_INICIO_SESION = "_nTmVC";
export const STORAGE_ITEM_TIEMPO_MAXIMO_SESION = "_nTmMxVC";
export const STORAGE_ITEM_TIPO_USUARIO = "_nTuVC";
export const STORAGE_ITEM_FILTRO_CANAL_VENTA = "_nCvBVC";
export const STORAGE_ITEM_SELECCIONAR_INCORPORACION = "_nSeTiVC";
export const STORAGE_ITEM_TIPO_INCORPORACION = "_nTiVC";

export const ERROR_SERVIDOR =
  "Ha ocurrido un error al comunicarse con el servidor";
export const MENSAJE_REGLA_NO_APLICADA = "Regla no aplicada";
export const TRANSPARENTE = "transparente";

export const SIN_NUMEROS = "El diagnóstico no debe contener números";
export const DIAGNOSTICO_DISTINTO_CERO =
  "El diagnóstico debe ser distinto de 0";

export const SECCION_VER_INDICADORES = "verIndicadores";
export const SECCION_VER_ASEGURABLES = "verAsegurables";
export const SECCION_INGRESAR_ASEGURABLES = "ingresarAsegurables";

export const ACTIVE = "active";
export const ERROR_DIGITO_VERIFICADOR =
  "Digito verificador incorrecto, favor revisar y volver a intentar.";

export const PRIMERA_POSICION_FECHA_DIVIDIDA = 0;
export const SEGUNDA_POSICION_FECHA_DIVIDIDA = 1;
export const TERCERA_POSICION_FECHA_DIVIDIDA = 2;

export const EVENTO_CLICK = "click";
export const CLASE_CERRADO = "closed";
export const CLASE_ESCONDER_SUBMENU = "hide-submenu";
export const CLASE_MOSTRAR_SUBMENU = "show-submenu";
export const CLASE_BD_DESBORDAMIENTO = "bd-overflow";
export const REGLA_CERO_PIXELES = "show-submenu";
export const SELECTOR_SUBMENU = "#sidebar-menu ul li.submenu";
export const SELECTOR_APLICACION = "#app";

export const VALOR_MINUTOS_SEGUNDOS = 60;
export const VALOR_MINUTO_EN_MILISEGUNDOS = 60000;
export const VALOR_SEGUNDO_EN_MILISEGUNDOS = 1000;

/**************************
 *  LOGIN                 *
 **************************/
export const SESION_TIEMPO_MAXIMO_DEFECTO_MINUTOS = 30;
export const SESION_TIEMPO_MINIMO_MINUTOS = 1;
export const SESION_TIEMPO_MOSTRAR_ALERTA_SEGUNDOS = 31;
export const SESION_TIEMPO_MINIMO_CERRAR_SESION_SEGUNDOS = 0;
export const SESION_TIEMPO_ALERTA_TITULO = "Su sesión será finalizada";
export const SESION_TIEMPO_ALERTA_TEXTO = "en <tiempo></tiempo> segundos";
export const SESION_TIEMPO_TEXTO_REACTIVAR = "Reactivar Sesión";
export const SESION_TIEMPO_TEXTO_CERRAR = "Cerrar Sesión";
export const SESION_TIEMPO_SELECTOR_SEGUNDOS = "tiempo";
export const SESION_TIEMPO_CERRANDO = "Cerrando sesion...";
export const SESION_TIEMPO_REACTIVANDO = "Reactivando sesion...";
export const SESION_TIEMPO_CLASE_INFO = "alerta-sesion-info";
export const SESION_TIEMPO_CLASE_TIEMPO = "alerta-sesion-tiempo";
export const ERROR_INGRESO_CREDENCIALES =
  "Para iniciar sesión, debe ingresar crendeciales";
export const ERROR_CREDENCIALES_INCORRECTAS =
  "El usuario o contraseña no es válida, inténtelo nuevamente";
export const ERROR_PERFIL_INCORRECTO = "Este perfil no corresponde al usuario";
export const ERROR_NO_PERFILES = "El usuario no tiene perfiles configurados";
export const ERROR_PETICION_SERVIDOR =
  "Ocurrio un error al realizar la solicitud, inténtelo nuevamente";
export const ERROR_SOLICITUD =
  "Hubo un problema al realizar la solicitud, favor intente más tarde";
export const ERROR_ENVIAR_CORREO_ASEGURABLE =
  "Ocurrio un error al enviar los correos a los asegurables, intente reenviar más tarde";
export const ERROR_TOKEN_SESION =
  "No se pudo obtener la información del usuario, favor intentelo más tarde";
export const ERROR_CREAR_SOLICITUD_EXCLUSION =
  "Registro duplicado. Ver solicitudes";
export const ERROR_SIN_PERFILES =
  "Las credenciales ingresadas no tienen permiso para ingresar al sistema";
export const ERROR_SOLICITUD_CAMBIO_CLAVE =
  "Ocurrio un problema al validar cambio de clave solicitado";
export const ERROR_LIMITE_SESION =
  "Este usuario ha llegado al limite de sesiones activas";
export const ERROR_TOKEN_API = "Error buscando token en api autenticación";
export const ERROR_TOKEN_GATEWAY =
  "Error buscando token en la puerta de enlace";
export const TITULO_INTERNO = "Inicie sesión como usuario interno VidaCamara";
export const TITULO_DELEGADO = "Inicie sesión como usuario Delegado";
export const TITULO_ASEGURADO = "Inicie sesión como usuario Asegurado";
export const TITULO_CORREDOR = "Inicie sesión como usuario Corredor";
export const IV_KEY = "1234567890123456";
export const OBJETO_SESION = {
  id: null,
  rut: null,
  nombre: null,
  apellido: null,
  correo: null,
  descripcion: null,
  estadoUsuario: null,
  minutosVigencia: null,
  token: null,
  temporal: null,
  cambiarPassword: null,
  rol: {},
  perfiles: [],
};

/**************************
 *  ACTUALIZAR CLAVE      *
 **************************/
export const MIN_DIGITOS_ACTUAL = "Debe ingresar 4 dígitos";
export const CLAVE_VALIDA = "Debe ingresar contraseña válida";
export const CLAVE_ALFANUMERICA = "Debe ingresar contraseña alfanumérica";
export const CLAVE_MIN_MAX = "Mínimo 4 carácteres, máximo 10 carácteres";
export const CLAVE_NO_COINCIDE = "Las contraseñas no coinciden";
export const EXITO_ACTUALIZAR_CLAVE =
  "Las contraseña se actualizó correctamente, favor inicie sesión nuevamente";
export const CLAVE_TAMANO_MINIMO_ACTUAL = 4;
export const CLAVE_TAMANO_MAXIMO_ACTUAL = 4;
export const CLAVE_TAMANO_MINIMO_NUEVA = 4;
export const CLAVE_TAMANO_MAXIMO_NUEVA = 10;
export const CLAVE_ACCION_ACTUALIZAR = 1;
export const CLAVE_ACCION_CREAR = 2;
export const TEXTO_VALIDAR_DIGITOS = "validarDigitos";
export const TEXTO_CONFIRMAR_CLAVE = "confirmarContraseña";
export const REGEX_CLAVE_NUEVA = /^[a-zA-Z0-9]+$/;
export const REGEX_OFUSCAR_CORREO = /[a-z0-9\-_.]+@/gi;
export const CARACTERES_VISIBLES_CORREO = 3;
export const CARACTERES_VISIBLES_TELEFONO = 3;
export const CONTRASENA_VALORES_INICIALES = {
  actual: "",
  nueva: "",
  confirmacion: "",
  blnMostrarActual: false,
  blnMostrarNueva: false,
  blnMostrarConfirmacion: false,
  blnContrasenaCoinciden: false,
};
/**************************
 *  MENU                  *
 **************************/
export const COD_MENU_CREAR_NEGOCIO = 3;
export const COD_MENU_NOMINA = 8;
export const COD_MENU_ENROLAMIENTO_DIGITAL = 75;
export const COD_MENU_INICIO_MANTENCION = 17;
// ICON
export const ICON_HOME = "fa fa-home";
export const ICON_USERS = "fa fa-users";

// LDAP
export const MENU_GLOSA_NEGOCIOS = "Negocios";
export const SUBMENU_GLOSA_NEGOCIOS = "Listar Negocios";
export const SUBMENU_GLOSA_CREAR_NEGOCIO = "Crear Negocio";
export const SUBMENU_GLOSA_GESTIONAR_EJECUTIVOS = "Gestionar Ejecutivos";

// DELEGADO:
export const MENU_GLOSA_INICIO = "Inicio";
export const MENU_GLOSA_ASEGURABLES = "Asegurables";
export const SUBMENU_GLOSA_VER_ASEGURABLES = "Ver Asegurables";
export const SUBMENU_GLOSA_INGRESAR_ASEGURABLES = "Ingresar Asegurables";
export const ID_MENU_MANTENCION = 9;

export const MENU_GLOSA_MANTENCION = "Mantención";
export const SUBMENU_GLOSA_INCORPORACION = "Incorporación";
export const SUBMENU_GLOSA_VER_SOLICITUDES = "Ver Solicitudes";
export const SUBMENU_GLOSA_PERMISOS = "Permisos";
export const SUBMENU_GLOSA_BAJAS = "Bajas";
export const SUBMENU_GLOSA_EXCLUSION = "Exclusión";
export const SUBMENU_GLOSA_ELIMINACION = "Eliminación";
export const SUBMENU_GLOSA_INICIO = "Inicio";

/**************************
 *  RUTAS              *
 **************************/
export const RUTA_DEFAULT = "/";
export const RUTA_NEGOCIOS = "/negocios";
export const RUTA_INICIO = "/inicio";
export const RUTA_EJECUTIVO_INCORPORACION = "/ejecutivo/incorporacion";
export const RUTA_EJECUTIVO_EXCLUSION = "/ejecutivo/exclusion";
export const RUTA_COTIZACIONES = "cotizaciones/inicio";
export const RUTA_CREAR_NEGOCIO = "/crear-negocio";
export const RUTA_DELEGADO_INICIO = "/delegado/inicio";
export const RUTA_DELEGADO_INICIO_MANTENCION = "/delegado/inicio-mantencion";
export const RUTA_VER_ASEGURABLES = "/delegado/mis-procesos";
export const RUTA_PERMISOS = "/ejecutivo/permisos";
export const RUTA_INGRESAR_ASEGURABLES = "/delegado/ingresar-asegurables";
export const RUTA_ASEGURABLE = "/asegurable";
export const RUTA_GESTIONAR_EJECUTIVO = "/gestionar-ejecutivos";
export const RUTA_DELEGADO_SOLICITUD_COMPLETA = "/delegado/solicitud-completa";
export const RUTA_DELEGADO_INCORPORACION = "/delegado/incorporacion";
export const RUTA_DELEGADO_EXCLUSION = "/delegado/exclusion";
export const RUTA_DELEGADO_ELIMINACION = "/delegado/eliminacion";
export const RUTA_EJECUTIVO_VER_SOLICITUDES = "/ejecutivo/ver-solicitudes";
export const RUTA_EJECUTIVO_PERMISOS = "/ejecutivo/permisos";
export const RUTA_EJECUTIVO_INICIO = "/ejecutivo/inicioMantencion";
export const RUTA_VER_SOLICITUDES = "/delegado/ver-solicitudes";
export const RUTA_EJECUTIVO_ELIMINACION = "/ejecutivo/eliminacion";
export const RUTA_INCORPORACION_CORREDOR = "/corredor/incorporacion";
export const RUTA_VER_SOLICITUDES_CORREDOR = "/corredor/ver-solicitudes";
export const RUTA_CORREDOR_INCORPORACION = "/corredor/incorporacion";
export const RUTA_CORREDOR_EXCLUSION = "/corredor/exclusion";
export const RUTA_CORREDOR_ELIMINACION = "/corredor/eliminacion";
export const RUTA_COTIZACION_CORREDOR_ESTADO_ENROLAMIENTO =
  "/cotizaciones-corredor/estado-enrolamiento";
export const RUTA_COTIZACION_ESTADO_ENROLAMIENTO =
  "/cotizaciones/estado-enrolamiento";
export const RUTA_ABSOLUTA_INCORPORACION = "incorporacion";
export const RUTA_ABSOLUTA_INGRESAR_ASEGURABLES = "ingresar-asegurables";
export const RUTA_ABSOLUTA_ESTADO_ENROLAMIENTO = "estado-enrolamiento";
export const RUTA_ABSOLUTA_EXCLUSION = "exclusion";
export const RUTA_ABSOLUTA_VER_SOLICITUDES = "ver-solicitudes";
export const RUTA_INICIO_MANTENCION = "/inicioMantencion";
export const RUTA_CAMBIAR_CONTRASENA_TEMPORAL = "/actualizar-contrasena";
export const RUTA_INICIO_SESION = "/inicio-sesion";
export const RUTA_OLVIDO_CONTRASENA = "/olvido-contrasena";
export const RUTA_CAMBIAR_CONTRASENA_EXPIRADA =
  "/solicitud-contrasena-expirada";
export const RUTA_PROCESO_ENROLAMIENTO_DOBLE = "/asegurable/solicitud-procesos";
export const RUTA_RECUPERAR_CONTRASENA =
  "/recuperar-contrasena/:claveActual/:rut/:perfil";
export const RUTA_INICIO_SESION_EXTERNO =
  "/login-externo/rut/:rut/hashToken/:token/:perfil";
export const RUTA_INICIAR_SESION_LDAP_CON_PERFIL =
  "/auth/iniciarSessionLdapConPerfil";
export const RUTA_MANTENCION_VISTA = "/enrolamiento/mantencion";
/**************************
 *  CATALOGO              *
 **************************/
export const MONTHS = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];
export const GENERO_M = 2;

/**************************
 *  WIZARD                *
 **************************/
export const PAGINA_1 = 1;
export const PAGINA_2 = 2;
export const PAGINA_3 = 3;
export const PAGINA_4 = 4;
export const PAGINA_5 = 5;

export const RESUMEN_INDICE = 4;

export const DONE = "done";
export const DOING = "doing";
export const TODO = "todo";

export const MENU_WIZARD = [
  {
    pagina: 1,
    glosa: "Datos Personales",
    visible: true,
  },
  {
    pagina: 2,
    glosa: "Asegurables Adicionales",
    visible: false,
  },
  {
    pagina: 3,
    glosa: "Declaraciones",
    visible: true,
  },
  {
    pagina: 4,
    glosa: "Seguro de Vida",
    visible: false,
  },
  {
    pagina: 5,
    glosa: "Resumen",
    visible: true,
  },
];

/**************************
 *  RESUMENES             *
 **************************/
export const BENEFICIARIOS = "Beneficiarios";
export const CONSENTIMIENTO_SEGURO_DE_VIDA = "Consentimiento Seguro de Vida";
export const ACEPTACION = "Aceptación";
export const NO_DECLARA_BENEFICIARIO = "No se declara Beneficiarios";

/**************************
 *  FORMULARIOS           *
 **************************/
export const CAMPO_REQUERIDO = "Este campo es obligatorio";
export const CAMPO_REQUERIDO_RUT = "El campo RUT es obligatorio";
export const CAMPO_REQUERIDO_NOMBRE = "El nombre es obligatorio";
export const CAMPO_REQUERIDO_APELLIDO_MATERNO =
  "El apellido materno es obligatorio";
export const CAMPO_REQUERIDO_APELLIDO_PATERNO =
  "El apellido paterno es obligatorio";
export const CAMPO_REQUERIDO_GENERO = "Debe seleccionar género";
export const CAMPO_REQUERIDO_PARENTESCO = "Debe seleccionar parentesco";
export const CAMPO_REQUERIDO_ESTADO_CIVIL = "Debe seleccionar estado civil";
export const CAMPO_REQUERIDO_FECHA = "Debe seleccionar fecha";
export const CAMPO_REQUERIDO_SISTEMA_PREVISIONAL =
  "Debe seleccionar un sistema previsional";
export const CAMPO_REQUERIDO_PESO = "El peso es obligatorio";
export const CAMPO_REQUERIDO_ESTATURA = "La estatura es obligatoria";
export const CAMPO_REQUERIDO_ACTIVIDAD = "La actividad es obligatoria";
export const CAMPO_REQUERIDO_RENTA = "La renta es obligatoria";
export const CAMPO_REQUERIDO_DIRECCION = "La dirección es obligatoria";
export const CAMPO_REQUERIDO_NUMERO = "El número es obligatorio";
export const CAMPO_REQUERIDO_TELEFONO = "El teléfono es obligatorio";
export const CAMPO_REQUERIDO_EMAIL = "El email es obligatorio";
export const CAMPO_REQUERIDO_FORMA_PAGO = "Debe seleccionar forma de pago";
export const CAMPO_REQUERIDO_BANCO = "Debe seleccionar un banco";
export const CAMPO_REQUERIDO_TIPO_CUENTA = "Debe seleccionar tipo de cuenta";
export const CAMPO_REQUERIDO_NUMERO_CUENTA =
  "El número de cuenta es obligatorio";
export const CAMPO_REQUERIDO_DIAGNOSTICO = "El diagnóstico es obligatorio";
export const CAMPO_REQUERIDO_PATOLOGIA = "La patología es obligatoria";
export const CAMPO_REQUERIDO_FRECUENCIA = "La frecuencia es obligatoria";
export const CAMPO_REQUERIDO_ASEGURADO = "Debe seleccionar un asegurable";
export const CAMPO_REQUERIDO_NOMBRE_SUCURSAL =
  "El nombre sucursal es obligatorio";
export const CAMPO_REQUERIDO_VALOR_CAPITAL = "El valor es obligatorio";
export const CAMPO_REQUERIDO_FOLIO_COTIZACION =
  "El folio de la cotización es obligatorio";
export const CAMPO_REQUERIDO_PRIMA_NEGOCIO =
  "La prima de negocio es obligatoria";
export const CAMPO_REQUERIDO_NUMERO_TRABAJADORES =
  "El número de trabajadores es obligatorio";
export const CAMPO_REQUERIDO_SOFTWARE = "Debe seleccionar un software";
export const CAMPO_REQUERIDO_RADIO = "Debe seleccionar opción";
export const VALIDACION_CONTACTO =
  "Debe ingresar al menos uno de los siguientes datos: dirección, correo electrónico y/o teléfono";
export const VALIDACION_NUMERO_CONDICIONAL =
  "Si ingresa el campo dirección, debe ingresar número";
export const VALIDACION_RUT = "Debe ingresar rut válido";
export const RUT_DUPLICADO = "El asegurable ya se encuentra ingresado.";
export const RUT_SOLICITUD_DUPLICADO =
  "Este RUT ya se encuentra para la póliza y grupo seleccionados";
export const VALIDACION_DIRECCION = "Debe ingresar direccion";
export const VALIDACION_NOMBRE = "Debe ingresar nombre";
export const VALIDACION_NOMBRE_VALIDO = "Debe ingresar un nombre valido";
export const VALIDACION_NOMBRE_MAXIMO =
  "Debe ingresar un nombre que contenga menos de 25 caracteres";
export const VALIDACION_APELLIDO_P = "Debe ingresar apellido paterno";
export const VALIDACION_APELLIDO_P_VALIDO =
  "Debe ingresar apellido paterno valido";
export const VALIDACION_APELLIDO_P_MAXIMO =
  "Debe ingresar un apellido paterno que contenga menos de 25 caracteres";
export const VALIDACION_APELLIDO_M = "Debe ingresar apellido materno";
export const VALIDACION_APELLIDO_M_VALIDO =
  "Debe ingresar apellido materno valido";
export const VALIDACION_APELLIDO_M_MAXIMO =
  "Debe ingresar un apellido materno que contenga menos de 25 caracteres";
export const VALIDACION_MAIL = "Debe ingresar email válido";
export const VALIDACION_TELEFONO = "Debe ingresar teléfono válido";
export const VALIDACION_POLIZA = "Debe ingresar solo un plan";
export const VALIDACION_SEXO = "Debe seleccionar género";
export const VALIDACION_GENERO = "Debe seleccionar género";
export const VALIDACION_DOCUMENTO_CARGA =
  "Se deben cargar los documentos requeridos (*)";
export const VALIDACION_TIPO_ASEGURADO =
  "Debe seleccionar un tipo de asegurado";
export const VALIDACION_TIPO_EXCLUSION =
  "Debe seleccionar un tipo de exclusión";
export const VALIDACION_REGION = "Debe seleccionar región";
export const VALIDACION_SUCURSAL = "Debe seleccionar sucursal";
export const VALIDACION_CIUDAD = "Debe seleccionar ciudad";
export const VALIDACION_COMUNA = "Debe seleccionar comuna";
export const VALIDACION_FECHA = "Debe seleccionar fecha válida";
export const VALIDACION_NO_FECHA_INGRESO_EMPRESA =
  'Primero debe seleccionar "Fecha de nacimiento"';
export const VALIDACION_FECHA_INGRESO_EMPRESA =
  "Fecha no puede ser mayor a 15 años de Fecha de nacimiento";
export const VALIDACION_PARENTESCO = "Debe seleccionar parentesco";
export const VALIDACION_PORCENTAJE_BENEFICIO =
  "Debe ingresar porcentaje válido";
export const VALIDACION_SUMA_BENEFICIO =
  "La suma total de porcentaje entre beneficiarios no puede superar el %100";
export const VALIDACION_SELECT = "Debe seleccionar una opción";
export const VALIDACION_SELECT_2 = "Debe seleccionar al menos una opción";
export const VALIDAR_CONTRATANTE = "Debe seleccionar un contratante";
export const VALIDAR_MOVIMIENTO = "Debe seleccionar un movimiento";
export const MENSAJE_SIN_POLIZAS =
  "La empresa contratante seleccionada no tiene pólizas vigentes";
export const VALIDAR_POLIZA = "Debe seleccionar al menos una poliza";
export const VALIDAR_POLIZA_GRUPO = "Debe seleccionar solo un grupo por poliza";
export const VALIDACION_TIPO_PRODUCTO = "Debe seleccionar tipo producto";
export const VALIDACION_COBERTURA_PRODUCTO =
  "Debe seleccionar al menos una cobertura";
export const VALIDACION_TIPO_CAPITAL = "Debe seleccionar tipo capital";
export const VALIDACION_MAYOR_CERO = "Debe ingresar valor mayor a cero";
export const VALIDACION_MINIMO_CARACTERES =
  "Debe ingresar al menos 3 caracteres";
export const VALIDACION_COTIZACION_EXISTENTE =
  "Folio de cotización ya se encuentra ingresado por sistema, favor ingresar uno diferente";
export const VALIDACION_RADIO = "Debe seleccionar alguna opción";
export const VALIDACION_PERFIL = "Debe seleccionar como mínimo un perfil";
export const INVALID_DATE = "Invalid date";
export const FECHA_INVALIDA = "Fecha inválida";
export const FORMATO_FECHA_DDMMYYYY = "DD/MM/YYYY";
export const VALIDACION_RECUPERAR_CLAVE =
  "No hemos encontrado información asociada al rut, favor comunicarse con su ejecutiva de Vida Cámara.";
export const NO_APLICA = "N/A";

export const VALIDACION_EJECUTIVO = "Debe seleccionar ejecutivo";
export const VALIDACION_JEFATURA = "Debe seleccionar jefatura";
export const VALIDACION_SIN_CORREO =
  "Si no ingresa un correo electronico debe ingresar un telefono movil";
export const VALIDACION_SIN_TELEFONO =
  "Si no ingresa un télefono móvil debe ingresar un correo electronico";

export const VALIDA_TELEFONO = "validaTelefono";
export const TELEFONO_MOVIL = "telefonoMovil";
export const CORREO = "email";
export const CORREO_ELECTRONICO = "correoElectronico";

export const ACCESO_ENVIADO_A = "Se ha enviado acceso temporal a";
export const ENVIO_ACCESO = "Envío Acceso";
export const FECHA_ENVIO_ACCESO = "Fecha Envío Acceso";

// Multiselect
export const TITULO_GIRO = "giro";
export const TITULO_SUCURSAL = "sucursal";

export const SOFTWARE_NO_EXISTE =
  "Lamentablemente no cuentas con el software de RRHH seleccionado, favor haz el ingreso de asegurables"; // Concatenar redireccion a Ingreso de asegurable individual

/**************************
 *  ARCHIVOS              *
 **************************/
export const MAX_FILE = 2000000;
export const TIPO_DATA_DOC_PDF = "data:application/pdf;base64,";
export const TIPO_DATA_PDF = "application/pdf";
export const TIPO_DATA_PNG = "data:image/png;base64,";
export const TIPO_DATA_JPEG = "data:image/jpeg;base64,";
export const TIPO_DATA_DOC_XLS = "data:application/vnd.ms-excel;base64,";
export const TIPO_DATA_DOC_XLSX =
  "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
export const TIPO_DATA_MSG = "data:application/octet-stream;base64,";

export const ARCHIVO_COTIZ = "cotizacion";
export const ARCHIVO_CARTA = "cartaAceptacion";
export const ARCHIVO_CARTA_EV = "carta_aceptacion_cotiz";

export const EXITO_CARGAR_ARCHIVO = "Se ha cargado el archivo seleccionado";
export const EXITO_CARGAR_ARCHIVO_COTIZACION =
  "Se ha subido el archivo de cotización";
export const EXITO_CARGAR_ARCHIVO_CARTA =
  "Se ha subido el archivo de carta de aceptación";
export const EXITO_CARGAR_ARCHIVO_NOMINA_PRELIMINAR_DELEGADO =
  "Se ha subido el archivo de nómina preliminar delegado";

export const ERROR_CARGAR_ARCHIVO =
  "Hubo un error al cargar el archivo, favor intente más tarde";
export const EXITO_BORRAR_ARCHIVO = "Se ha eliminado el archivo seleccionado";
export const ERROR_BORRAR_ARCHIVO =
  "Hubo un error al borrar el archivo, favor intente más tarde";
export const EXITO_CARGAR_FECHA_ACEP = "Se ha cargado la fecha de aceptación";
export const ERROR_CARGAR_FECHA_ACEP =
  "Hubo un error al actualizar la fecha de aceptación, favor intente más tarde";
export const VALIDACION_PESO_ARCHIVO =
  "El archivo adjunto no puede ser mayor a 2MB";
export const VALIDACION_TIPO_ARCHIVO = "El archivo adjunto no es válido";
export const SIN_DOCUMENTOS = "sin documentos";
export const CARTA_ACEPTACION_COTIZ = "carta_aceptacion_cotiz";
export const PDF = "pdf";
export const _PDF = ".pdf";
export const XLSX = "xlsx";
export const _XLSX = ".xlsx";
export const XLS = "xls";
export const _XLS = ".xls";
export const PNG = "png";
export const _PNG = ".png";
export const JPEG = "jpeg";
export const _JPEG = ".jpeg";
export const JPG = "jpg";
export const _JPG = ".jpg";
export const MSG = "msg";
export const NOMINA_PRELIMINAR_DELEGADO = "NominaPreliminar";
export const DOCUMENT_TYPE_TEMPORAL = "temporal";

export const TIPO_BINARIO = "binary";

export const CODIGO_ERROR_1 = 1;
export const CODIGO_ERROR_2 = 2;

export const ERROR_FORMATO_ARCHIVO_NOMINA_PRELIMINAR =
  "El formato del archivo no es valido, verifique que sea un archivo PDF, PNG, JPEG, MSG";
export const ERROR_ARCHIVO_NO_CARGADO =
  "Debe cargar un archivo con uno de los siguientes formatos (PDF, PNG, JPEG, MSG) antes de guardar.";
export const BASE64 = "base64";

/**************************
 *  DECLARACIONES         *
 **************************/
export const CATALOGO_DECLARACION_OTRO = 59;
export const CATALOGO_DECLARCION_PREXISTENCIA = 59;

/**************************
 *  JEFATURA - EJECUTIVO  *
 **************************/
export const MENSAJE_MODAL_INICIO_ENROLAMIENTO = "Cargando inicio enrolamiento";
export const ALERTA_INI_ENROLAMIENTO_1 =
  'Debe realizar la configuración del negocio. Diríjase a la opción de "Configuración" y luego a "Datos de Negocio".';
export const ALERTA_INI_ENROLAMIENTO_2 =
  "Debe contar con una cotización en estado gestionada. Configure una cotización generada.";
export const ALERTA_INI_ENROLAMIENTO_3_1 =
  "Debe adjuntar carta de aceptacion para las cotizaciones: ";
export const ALERTA_INI_ENROLAMIENTO_3_2 =
  '. Diríjase a "Opciones" y luego a "Adjuntar archivos".';
export const ALERTA_INI_ENROLAMIENTO_4 =
  "Debe seleccionar la fecha fin periodo enrolamiento";
export const ALERTA_INI_ENROLAMIENTO_5 =
  "Tienes que configurar al menos un producto para iniciar proceso de enrolamiento";
export const CONFIR_PROC_ENROLAMIETO =
  "Se ha dado inicio al proceso de enrolamiento. Se ha enviado un correo al delegado asignado.";
export const INICIO_PROC_ENROLAMIETO =
  "¿Está seguro que desea iniciar el Enrolamiento?";
export const EXITO_PROC_ENROLAMIETO =
  "Se ha realizado el proceso de inicio enrolamiento";
export const EXITO_ASIGNAR_EJECUTIVO =
  "Se ha enviado un correo a al ejecutivo asignado";
export const EXITO_REASIGNAR_EJECUTIVO =
  "Se ha enviado un correo informando al ejecutivo anterior y al reasignado";
export const EXITO_ELIMINAR_NEGOCIO = "Se ha eliminado el negocio";
export const TITULO_ASIGNAR = "Asignar";
export const TITULO_REASIGNAR = "Reasignar";
export const FECHA_ENROLAMIENTO_ACTUALIZADA =
  "Se ha actualizado la fecha de enrolamiento";
export const NEGOCIO_ACTUALIZADO = "Se ha actualizado el negocio";
export const FECHA_ENROLAMIENTO_ERROR =
  "No se pudo guardar la fecha, favor inténtelo más tarde";
export const EXITO_AGREGAR_EJECUTIVO =
  "Se ha agregado el ejecutivo correctamente.";
export const EXITO_MOVER_EJECUTIVO =
  "Se ha reasignado el ejecutivo correctamente.";
export const EXITO_AGREGAR_PERFIL_EJECUTIVO = "Perfil asignado";
export const ERROR_OBTENER_NOMINA_APROBADA =
  "Error al obtener la nómina solicitada. Asegúrese de que el negocio haya contado con asegurables válidos.";
/*  ESTADOS NEGOCIO  */
export const T_NEGOCIO_ACTIVO = "activos";
export const T_NEGOCIO_INACTIVO = "inactivos";
export const ESTADOS_NEGOCIO_ACTIVOS = [
  { idEstadoNegocio: 1, glosa: "Por Asignar" },
  { idEstadoNegocio: 2, glosa: "Asignado" },
  { idEstadoNegocio: 3, glosa: "En Proceso" },
];
export const ESTADOS_NEGOCIO_INACTIVOS = [
  { idEstadoNegocio: 5, glosa: "Cerrado" },
  { idEstadoNegocio: 6, glosa: "Cerrado Manual" },
];

/* ESTADO COTIZACION */
export const ESTADO_COTIZACION_EN_PROCESO = 3;
export const ESTADO_COTIZACION_FINALIZADA = 5;

/*  EJECUTIVO -> CONFIGURACIÓN NEGOCIO  */
export const WIZARD_EMPRESA = 1;
export const WIZARD_SUCURSAL = 2;
export const WIZARD_DELEGADO = 3;
export const VAL_ENROLAMIENTO_WIZARD = "wizardVacio";
export const VAL_ENROLAMIENTO_GESTIO = "sinGestionadas";
export const VAL_ENROLAMIENTO_CARTA = "sinCartaAceptacion";
export const VAL_ENROLAMIENTO_OK = "validadoCorrectamente";
export const VAL_ENROLAMIENTO_FECHA = "sinFechaEnrolamiento";
export const VAL_ENROLAMIENTO_PRODUCTO = "sinProducto";

export const ID_ORGANIZACION_DEFAULT = 0;
export const NO_ORGANIZACION_NEGOCIO =
  "No se pudo obtener organización del negocio, favor intente más tarde";
export const NO_DATA_NEGOCIO =
  "No hay datos del negocio, favor complete información";
export const NO_DATA_SUCURSAL =
  "No hay datos de la sucursal, favor complete información";
export const NO_DATA_DELEGADO =
  "No hay datos del delegado, favor complete información";
/*  EJECUTIVO -> CONFIGURACIÓN NEGOCIO -> NEGOCIO */
export const ERROR_ACTUALIZAR_NEGOCIO =
  "Ocurrió un error al actualizar los datos del negocio";
export const EXITO_ACTUALIZAR_NEGOCIO =
  "Se han actualizado los datos del negocio correctamente";
/*  EJECUTIVO -> CONFIGURACIÓN NEGOCIO -> SUCURSAL */
export const ERROR_OBTENER_SUCURSAL =
  "No hay datos acerca de la sucursal, favor complete información";
export const ERROR_ACTUALIZAR_SUCURSAL =
  "Ocurrió un error al actualizar los datos de la sucursal";
export const EXITO_ACTUALIZAR_SUCURSAL =
  "Se han actualizado los datos de la sucursal correctamente";
/*  EJECUTIVO -> CONFIGURACIÓN NEGOCIO -> DELEGADO */
export const ERROR_ACTUALIZAR_DELEGADO =
  "Ocurrió un error al actualizar los datos del delegado";
export const ERROR_OBTENER_PERSONA_DELEGADO =
  "Ocurrió un error al obtener los datos del delegado configurado";
export const EXITO_ACTUALIZAR_DELEGADO =
  "Se han actualizado los datos del delegado correctamente, la configuración del negocio está completa";
export const ERROR_CREAR_COTIZACION = "Ocurrió un error al crear cotización";
export const ERROR_CREAR_NEGOCIO = "Ocurrió un error al crear negocio";
export const ERROR_CREAR_FORMULARIO =
  "Ocurrió un error al crear el formulario del asegurable";
//Recuperar contraseña
export const UNDEFINIDO_RECUPERAR_CONTRASENA = "@undefined";
export const TEXTO_ENVIO_SOLO_CORREO =
  "Se ha enviado un correo a {Correo}, el cual se encuentra registrado en nuestros sistemas. Sigue los pasos para cambiar la contraseña.";
export const TEXTO_ENVIO_CORREO_TELEFONO =
  "Se ha enviado un correo a {Correo} y se ha enviado un WhatsApp a {Telf}, el cual se encuentra registrado en nuestros sistemas. Sigue los pasos para cambiar la contraseña.";
export const TEXTO_ENVIO_SOLO_TELEFONO =
  "Se ha enviado un WhatsApp a {Telf}, el cual se encuentra registrado en nuestros sistemas. Sigue los pasos para cambiar la contraseña.";
export const TEXTO_RECUPERAR_CONTRASENA =
  ", el cual se encuentra registrado en nuestros sistemas. Sigue los pasos para cambiar la contraseña.";
export const COD_CORREO = "{Correo}";
export const COD_TELEFONO = "{Telf}";
export const COD_VIA_CONTACTO_TODOS = 0;
export const COD_VIA_CONTACTO_CORREO = 1;
export const COD_VIA_CONTACTO_WSP = 2;

/**************************
 *  NEGOCIOS              *
 **************************/
export const ID_NEGOCIO_EN_POR_ASGINAR = 1;
export const ID_NEGOCIO_EN_ASIGNADO = 2;
export const ID_NEGOCIO_EN_PROCESO = 3;
export const ID_NEGOCIO_PERDIDO = 4;
export const ID_NEGOCIO_CERRADO = 5;
export const ID_NEGOCIO_CERRADO_MANUAL = 6;
export const ESTADO_NEGOCIO_1 = 1;
export const ESTADO_NEGOCIO_2 = 2;
export const ESTADO_NEGOCIO_EN_PROCESO = 3;
export const ESTADO_NEGOCIO_3 = 3;
export const ESTADO_NEGOCIO_4 = 4;
export const ESTADO_NEGOCIO_5 = 5;
export const ESTADO_NEGOCIO_CERRADO = 5;
export const ESTADO_NEGOCIO_6 = 6;
export const NEGOCIO_MANTENCION = "Mantención";
export const TIPO_INCORPORACION_MIXTO = 0;
export const TIPO_INCORPORACION_NUEVO_NEGOCIO = 1;
export const TIPO_INCORPORACION_MANTENCION = 2;
export const TIPO_INCORPORACION_CARGA = 4;
export const TIPO_CANAL_VENTA_DIGITAL = 1;
export const TIPO_CANAL_VENTA_DIRECTA = 2;
export const TIPO_CANAL_VENTA_CORREDOR = 3;
export const ID_CERTIFICADO_NACIMIENTO = 2;
export const CARGA_MASIVA_NORMAL = 50;
export const CARGA_MASIVA_ASINCRONA = 5000;
export const SIN_POLIZAS = 0;

export const VALOR_ANUAL = 12;

export const NO_DATA_NEGOCIOS = "No se han podido obtener negocios";
export const ALERT_ULT_COTI =
  "No se puede asignar este negocio, debe contar con al menos una cotización";

/**************************
 *  DELEGADO              *
 **************************/
export const MENU_DELEGADO_ASEGURABLES = "Asegurables";
export const TITLE_SALUD_DIGITAL = "Salud Digital";
export const EXITO_ENVIO_CORREO =
  "Se ha enviado acceso temporal a los asegurables de la lista";
export const EXITO_EDICION = "Se ha editado la información del asegurable";
export const ERROR_EDICION =
  "Ha ocurrido un error al comunicarse con el servidor";
export const EXITO_ELIMINAR = "Se ha eliminado el asegurable";
export const EXITO_ELIMINAR_SOLICITUD =
  "Se ha eliminado la solicitud seleccionada";
export const PROCESANDO_CARGA_MASIVA =
  "Una vez se termine de procesar el archivo, se enviará un mail de aviso.";

export const DATA_FECHA_DEFAULT = "2019-01-01T12:00:00.015Z";
export const DATA_FECHA_MIN_DEFAULT = "1900-01-01T12:00:00.015Z";

export const EXITO_AGREGAR_ASEGURADO =
  "Se ha ingresado asegurado correctamente!";
export const WARNING_AGREGAR_ASEGURADO =
  "Se ha ingresado la cantidad máxima de asegurables";
export const ERROR_AGREGAR_ASEGURADO = "Ocurrió un error al ingresar asegurado";
export const ERROR_ASEGURADO_REPETIDO = "Asegurado ya ha sido ingresado";
export const ERROR_LISTAR_MIS_SOLICITUDES =
  "Ocurrió un error al obtener mis solicitudes";
export const ERROR_BUSCAR_NEGOCIO_DELEGADO =
  "Ocurrió un error al buscar el negocio del delegado";
export const ERROR_GENERICO =
  "Ocurrio un error al realizar esta operación, favor comunicarse con encargado";

export const ESTADOS_ENROLAMIENTO = [
  { id: 1, glosa: "No iniciado" },
  { id: 1, glosa: "En proceso" },
  { id: 2, glosa: "Enrolado" },
];
export const ERROR_ENVIO_AUTOMATICO =
  "No se ha podido enviar correo a asegurable. Utilice botón de reenvío";
export const EXITO_ACEPTAR_NOMINA_ASEGURABLES =
  "Nómina de asegurables aceptada, se ha enviado email a ejecutivo";
export const ERROR_ACEPTAR_NOMINA_ASEGURABLES =
  "Ocurrió un error al aceptar la nómina de asegurables";

export const ERROR_SERVICIO_EMPLEADOS_BUK =
  "El servicio BUK no se encuentra disponible en estos momentos. Por favor intente más tarde.";

export const ESTADO_EN_PROCESO = "en proceso";
export const ESTADO_FINALIZADO_POR_ASEGURABLE = "finalizado asegurable";
export const ESTADO_ENROLADO = "enrolado";
export const ESTADO_NO_INICIADO = "no iniciado";
export const MAX_E_A = 999;

export const VALOR_PRODUCTO = "producto";

export const COLLAPSE = "collapse";

export const MINIMA_CANTIDAD_ASEGURABLES = 5;
export const MINIMA_CANTIDAD_ASEGURABLES_VENTA_DIRECTA = 1;

export const ID_PREGUNTA_COBERTURA = {
  SECCION_1: 1,
  SECCION_2: 2,
  SECCION_3: 3,
};

export const CARGA_MASIVA = "CARGA_MASIVA_";
export const ESTADO_EXITOSO = "succeeded";
export const ESTADO_FALLIDO = "failed";
export const ERROR_ENVIO_DOCUMENTO =
  "Ha ocurrido un error en el servidor al enviar archivo. Por favor intente más tarde";
export const ADJUNTE_EXCEL = "Debe adjuntar archivo excel para continuar";

export const UNIDADES_PROGRESO = 1;
export const CIEN_POR_CIENTO = 100;
export const PORCENTAJE_LIMITE_PROGRESO = 90;

export const ERROR_CAMPO_VACIO = "es requerido, no puede estar vacío";
export const ERROR_USUARIO_AGREGADO =
  "Este asegurable ya se encuentra agregado";
export const ERROR_USUARIO_DUPLICADO =
  "Este asegurable ya se encuentra repetido en el documento";
export const ERROR_FORMATO_INCORRECTO = "tiene formato incorrecto";
export const ERROR_FECHA_INVALIDA = "El formato de fecha está incorrecto";
export const ERROR_RANGO_VIGENCIA =
  "Fecha ingresada fuera del rango de vigencia de Póliza";
export const ERROR_FECHA_VIGENCIA_RETROACTIVA =
  "Fecha Inicio Vigencia, no puede estar fuera del mes de ingreso de la carga masiva";
export const ERROR_DUPLICIDAD_ASEGURABLE_NEGOCIO =
  "Este asegurable ya se encuentra agregado en otro negocio";
export const ERROR_DUPLICIDAD_ASEGURABLE_POLIZA =
  "Este asegurable se encuentra vigente en otra poliza";
export const ERROR_RUT_INVALIDO = "ingresado no es valido";
export const MIN_DATE = "01/01/1900";
export const COLUMNA_FECHA_INICIO_VIGENCIA = "fechaInicioVigencia";
export const CAMPOS_TABLA_ERRORES = [
  "Nombre",
  "Apellido Paterno",
  "Apellido Materno",
  "Rut",
  "Mail o Telefono",
  "Asegurable",
  "Fecha",
  "Teléfono",
  "Correo",
];

export const SUBSTRING_ASEGURABLE = "asegurable";

export const FECHA_FUERA_VIGENCIA =
  "Fecha ingresada fuera del rango de vigencia de Póliza";

export const FECHA_HOY = "Hoy";

export const ERROR_AGREGAR_POLIZA_ASEGURABLES_SOFTWARE =
  "Por favor seleccionar trabajadores para completar información";

export const BANCO_RIPLEY_PLAN_INICIAL = "BANCO RIPLEY PLAN INICIAL";

export const ERROR_ASIGNACION_SUCURSAL = "Error al asignar sucursal";
export const ERROR_ASIGNACION_ACEPTACION = "Error al asignar aceptación";
export const ERROR_BUSCAR_DELEGADO = "Error al buscar delegado";
export const MENSAJE_MODAL_INICIO_ENROLAMIENTO_VENTA_DIRECTA =
  "¿Está seguro que desea iniciar el enrolamiento? Al iniciar enrolamiento no podrá editar a los asegurables, incluyendo quitar/agregar productos.";

export const ERROR_AGREGAR_EJECUTIVO =
  "Hubo un problema al realizar la solicitud, favor intente más tarde";

export const SOLICITUD_ACTIVO = "activos";
export const SOLICITUD_INACTIVO = "inactivos";

export const EXITO_ASIGNAR_PERFIL = "Se ha asignado el perfil correctamente.";
export const ERROR_ASIGNAR_PERFIL =
  "Ha ocurrido un error al asignar el perfil, inténtelo nuevamente";
export const ASUNTO_MAIL_ASIGNAR_PERFIL =
  "Ingreso Enrolamiento Digital | Vida Cámara";
export const EMISOR_MAIL_ASIGNAR_PERFIL = "procesos@vidacamara.cl";
export const NOMBRE_PLANTILLA_MAIL_ASIGNAR_PERFIL = "xslt-delegado";

/**************************
 *  COTIZACIONES          *
 **************************/
export const T_GENERADA = "generada";
export const T_GESTIONADA = "gestionada";
export const T_GENERADAS = "generadas";
export const T_GESTIONADAS = "gestionadas";
export const VAL_COTIS_GEST = "Actualmente ya existe una cotización gestionada";
export const EXITO_ESTADO_COTI = "Se ha cambiado el estado de la cotizacion";
export const ERROR_ESTADO_COTI =
  "Hubo un error al cambiar estado de la cotizacion";

/**************************
 *  ASEGURADO            *
 **************************/

export const VALOR_TITULAR = "Titular";
export const VALOR_CARGA = "Carga";
export const EXITO_SOLICITUD_EXCLUSION =
  "Se ha creado la solicitud de exclusión";
export const ID_TIPO_MOVIMIENTO_EXCLUSION = 2;

/**************************
 *  ASEGURABLE            *
 **************************/

export const FORMA_PAGO_SERVIPAG = 4;
export const PROGRESO_INICIAL = 0;
export const PROGRESO_FINAL = 100;
export const PROGRESO_ETAPA = 30;
export const PROGRESO_ETAPA_20 = 20;
export const PROGRESO_ETAPA_15 = 15;
export const PROGRESO_SUB_ETAPA = 10;

export const PARENTESCO_TITULAR = 0;
export const PARENTESCO_CONYUGUE = 1;
export const PARENTESCO_MADRE = 3;
export const PARENTESCO_HIJO = 4;
export const PARENTESCO_CONVIVIENTE_NO_CIVIL = 5;
export const PARENTESCO_HIJO_ESTUDIANTE = 19;
export const PARENTESCO_PADRE = 2;
export const PARENTESCO_DUPLO = 20;
export const PARENTESCO_ESPECIAL = 21;
export const PARENTESCO_CONVIVIENTE_CIVIL = 22;
export const SIN_PARENTESCO_SELECCIONADO = -1;

export const PARENTESCO_BENEFICIARIO_CONYUGUE = 0;
export const PARENTESCO_BENEFICIARIO_CONVIVIENTE_CIVIL = 1;
export const PARENTESCO_BENEFICIARIO_CONVIVIENTE_NO_CIVIL = 2;
export const PARENTESCO_BENEFICIARIO_HIJO = 3;
export const PARENTESCO_BENEFICIARIO_PADRE = 4;
export const PARENTESCO_BENEFICIARIO_MADRE = 5;
export const PARENTESCO_BENEFICIARIO_HERMANO = 6;
export const PARENTESCO_BENEFICIARIO_TIO = 7;
export const PARENTESCO_BENEFICIARIO_SOBRINO = 8;
export const PARENTESCO_BENEFICIARIO_ABUELO = 9;
export const PARENTESCO_BENEFICIARIO_HIJO_ESTUDIANTE = 11;
export const PARENTESCO_BENEFICIARIO_OTRO = 12;
export const PARENTESCO_BENEFICIARIO_ESPECIAL = 13;

export const NO_DECLARA_PREEXISTENCIA = 63;
export const NO_DECLARA_ACTIVIDADES_DEPORTES = 64;
export const ID_VALOR_ATRIBUTO_CONDICION_INTERVIGENCIA = 42;

export const ID_PREGUNTA_PREEXISTENCIA = 1;
export const ID_PREGUNTA_ACTIVIDADES = 3;

export const TIPO_SOLICITUD_DECLARACION_BENEFICIARIO = 5;

export const NO_DECLARA = "No Declara";

export const BENEFICIARIOS_MENU = "Beneficiarios";
export const RESUMEN_MENU = "Resumen";

export const TIPO_POLIZA_VIDA = "Vida";
export const TIPO_POLIZA_SALUD = "Salud";
export const TIPO_VALOR_RENTA = "Renta";
export const POLIZA_VIGENTE = 1;

export const DOCUMENTOS_NO_ENCONTRADOS =
  "No están disponibles los documentos asociados. Por favor ingrese más tarde.";

export const POL = "POL";
export const CAD = "CAD";
export const EL_CODIGO = " el código ";
export const LOS_CODIGOS = " los códigos ";
export const POL_5_50 = "POL 320190080";
export const PUNTO_COMA = ";";
export const STRING_Y = "y";
export const CLAUSULA_ADICIONAL = " Cláusula Adicional ";
export const CLAUSULAS_ADICIONALES = " Cláusulas Adicionales ";
export const CAD_5_50 = "CAD 320180045";

export const ERROR_LARGO_OBSERVACION =
  "Debe ingresar una observacion de minimo 15 caracteres.";

export const SIN_PESO = 0;
export const SIN_ALTURA = 0;

export const INFORMATIVO_FECHA_INGRESO_EMPRESA =
  "La fecha de ingreso a la empresa corresponde a la fecha de incorporación del trabajador a la empresa contratante. La fecha de inicio de vigencia de las coberturas del seguro será informada con posterioridad por la compañía, una vez evaluada y aceptada la solicitud";

/**************************
 * DATA ASEGURABLE        *
 **************************/
export const ESTADO_SOLICITUD_INGRESO = 1;
export const ESTADO_SOLICITUD_FINALIZADO = 2;
export const ESTADO_SOLICITUD_RECHAZADO = 3;
export const SOLICITUD_FINALIZADA_POR_ASEGURABLE = 4;

export const DATO_INDEFINIDO = "undefined";
export const EXITO_MIS_DATOS = "Se han guardado correctamente los datos";
export const EXITO_PETICION_REQUISITOS_ASEGURABILIDAD =
  "No se pudo validar los requisitos de asegurabilidad";
export const ERROR_MIS_DATOS =
  "No se pudieron guardar sus datos, favor intente más tarde";
export const EXITO_CARGAS = "Se han guardado correctamente las cargas";
export const EXITO_BENEFICIARIO =
  "La lista de beneficiarios se ha actualizado correctamente";
export const EXITO_ACEPTACION = "La aceptación se ha guardado correctamente";
export const ERROR_GUARDAR_ACEPTACION =
  "Hubo un error al guardar la aceptación, por favor intente más tarde";

export const EXITO_UPDATE_CARGA = "Se ha actualizado correctamente la carga";
export const ALERTA_NO_CARGAS =
  "No ha declarado cargas, favor ingrese un registro";
export const ALERTA_NO_BENEFICIARIOS =
  "No ha declarado beneficiarios, favor ingrese un registro";
export const EXITO_DECLARACIONES =
  "Se han guardado correctamente las declaraciones";
export const ERROR_DECLARACIONES = "Ocurrió un error al guardar declaraciones";
export const ALERTA_NO_DECLARACIONES =
  "No ha realizado declaraciones. AL selecciona SI, debe ingresar un registro";

export const RUT_DUPLICADO_CARGAS =
  "Este RUT ya se encuentra como asegurable adicional";
export const RUT_DUPLICADO_BENEFICIARIOS =
  "Este RUT ya se encuentra como beneficiario";
export const RUT_TITULAR = "Este RUT corresponde al RUT del titular";
export const CONYUGUE_UNICO = "Ya ha incluido un conyugue o conviviente civil";
export const CONYUGUE_DISTINTO_POLIZA_ANTERIOR =
  "Ya ha incluido un conyugue o conviviente civil con RUT distinto en polizas anteriores";
export const PADRE_DISTINTO_POLIZA_ANTERIOR =
  "Ya ha incluido un padre con RUT distinto en polizas anteriores";
export const MADRE_DISTINTO_POLIZA_ANTERIOR =
  "Ya ha incluido una madre con RUT distinto en polizas anteriores";
export const PADRE_UNICO = "Ya ha incluido un asegurable adicional como padre";
export const MADRE_UNICA = "Ya ha incluido un asegurable adicional como madre";
export const EDAD_HIJO =
  "Diferencia de edad entre titular e hijo debe ser de 15 años o más";
export const VALIDACION_ASEGURABLE = "Debe seleccionar un asegurable";
export const VALIDACION_ACTIVIDAD = "Debe seleccionar una actividad o deporte";
export const VALIDACION_ENFERMEDAD = "Debe seleccionar una enfermedad";
export const EXITO_ENROLAMIENTO = "Se ha completado la solicitud correctamente";
export const SI_NO_DECLARACIONES = "Debe indicar SI/NO ingresará declaraciones";
export const VALIDACION_FECHA_DIAGNOSTICO =
  "Debe indicar la fecha de diagnostico";

export const VALIDACION_100_BENEFICIO =
  "la suma de % beneficio de beneficiarios debe ser %100";

export const DECLARACION_SALUD_FECHA = "fechaDiagnostico";
export const DECLARACION_SALUD_IDASEGURABLE = "idAsegurable";
export const ASEGURABLE_IDSUBPRODUCTO_COBERTURA = 6;

export const COMPLETAR_INFORMACION =
  "Asegúrese de completar la información obligatoria para finalizar el proceso";
export const TRANSFERENCIA = 3;

export const PLANES_COBERTURAS = [
  {
    plan: "Plan Salud",
    url: "https://www.vidacamara.cl/docs/default-source/documentos-seguros-colectivos/5-50/sd-salud-sin-deducible.pdf",
  },
  {
    plan: "Plan Salud con Deducible",
    url: "https://www.vidacamara.cl/docs/default-source/documentos-seguros-colectivos/5-50/sd-salud-con-deducible.pdf",
  },
  {
    plan: "Plan Salud + Dental",
    url: "https://www.vidacamara.cl/docs/default-source/documentos-seguros-colectivos/5-50/sd-salud-sin-deducible-dental.pdf",
  },
  {
    plan: "Plan Salud con Deducible + Dental",
    url: "https://www.vidacamara.cl/docs/default-source/documentos-seguros-colectivos/5-50/sd-salud-con-deducible-dental.pdf",
  },
];

export const URL_PDF_EXCLUSIONES_GENERAL =
  "https://www.vidacamara.cl/vidacamara/site/docs/20200605/20200605113652/exclusiones_5_50.pdf";

export const URL_BANNER_ASEGURABLE =
  "https://info.vidacamara.cl/seguro-accidentes-personales?&utm_source=suscripcion-digital&utm_medium=display&utm_campaign=paso-final-enrolamiento&utm_content=banner-final";

export const W1_MIS_DATOS = [
  "actividadLaboral",
  "estadoCivil",
  "estatura",
  "fechaIngresoEmpresa",
  "fechaNacimiento",
  "genero",
  "peso",
  "renta",
  "sistemaPrevisionSalud",
];
export const W1_DIRECCION = [
  "direccion",
  "numero",
  "ciudad",
  "comuna",
  "telefonoMovil",
  "correoElectronico",
];
export const W1_DATOS_PAGO = [
  "formaPago",
  "institucionFinanciera",
  "tipoCuentaBancaria",
  "numeroCuenta",
];

export const W2_COLS_COMPLEMENTARIA = [
  {
    name: "Nombre completo",
    selector: "nombre",
  },
  {
    name: "Diagnóstico",
    selector: "causa",
  },
  {
    name: "Fecha",
    selector: "fechaDiagnosticoFormat",
  },
];

export const W2_COLS_SALUD = [
  {
    name: "Nombre completo",
    selector: "nombre",
  },
  {
    name: "Patología",
    selector: "actividadGlosa",
  },
  {
    name: "Diagnóstico",
    selector: "observacion",
  },
  {
    name: "Fecha",
    selector: "fechaDiagnosticoFormat",
  },
];

export const W2_COLS_SALUD_2 = [
  {
    name: "Nombre completo",
    selector: "nombre",
  },
  {
    name: "Patología",
    selector: "causa",
  },
];

export const W2_COLS_DEPORTES = [
  {
    name: "Nombre completo",
    selector: "nombre",
  },
  {
    name: "Actividad o deporte",
    selector: "actividadGlosa",
  },
  {
    name: "Frecuencia por semana",
    selector: "observacion",
  },
];

export const ASEGURABLE_BENEFICIARIO = [
  { key: "1", value: "Padre" },
  { key: "2", value: "Madre" },
  { key: "3", value: "Hijo/a" },
  { key: "4", value: "Conyuge" },
  { key: "5", value: "Otro" },
];
export const DATA_BENEFICIARIO = {
  rut: "x",
  nombre_completo: "x",
  fecha_nacimiento: "1993-10-20",
  parentesco: "1",
  telefono: "",
  email: "",
  porcentaje: "",
};
export const RESUMEN_ENFERMEDADES = [
  {
    rut: "26802876-5",
    nombre_completo: "ANTHONY JOSE MEDINA FUENTES",
    enfermedad: "Oculares",
    fecha_diagnostico: "20/10/1992",
  },
  {
    rut: "26802879-5",
    nombre_completo: "ANTONELLA VALENTINA MEDINA FUENTES",
    enfermedad: "Bacteria",
    fecha_diagnostico: "10/10/1994",
  },
];

export const ID_PRODUCTO_COMPL_SALUD = 1;
export const ID_PRODUCTO_VIDA = 4;
export const ID_PRODUCTO_ACCIDENTES_PERSONALES = 3;
//ID de subproductos
export const ID_PRODUCTO_VIDA_1 = 1;
export const ID_PRODUCTO_VIDA_10 = 10;
export const COMPLEMENTARIO_SALUD_PYME = 25;
export const COMPLEMENTARIO_SALUD = 26;

export const ID_SUBPRODUCTO_SALUD_DIGITAL = 24;
export const ID_SUBPRODUCTO_VIDA = 1;
export const ID_SUBPRODUCTO_APOY0_FAMILIAR = 6;
export const ID_SUBPRODUCTO_ACCIDENTES_PERSONALES = 8;

export const ID_TIPO_POLIZA_SALUD = 1;
export const ID_TIPO_POLIZA_VIDA = 2;
export const ID_TIPO_POLIZA_ACCIDENTE_PERSONAL = 3;
export const ID_TIPO_POLIZA_APOYO_FAMILIAR = 7;

export const POLIZAS_CON_BENEFICIARIOS = [
  ID_TIPO_POLIZA_VIDA,
  ID_TIPO_POLIZA_ACCIDENTE_PERSONAL,
  ID_TIPO_POLIZA_APOYO_FAMILIAR,
];
export const SUBPRODUCTOS_CON_BENEFICIARIOS = [
  ID_SUBPRODUCTO_VIDA,
  ID_SUBPRODUCTO_APOY0_FAMILIAR,
  ID_SUBPRODUCTO_ACCIDENTES_PERSONALES,
];

export const PRODUCTOS_CON_BENEFICIARIOS = [
  ID_PRODUCTO_VIDA,
  ID_PRODUCTO_ACCIDENTES_PERSONALES,
];

export const ID_CATALOGO_CONSENTIMIENTO_MANTO = 2;
export const ID_CATALOGO_CONSENTIMIENTO_NUEVO_NEGOCIO = 1;

export const ID_SOLICITUD_FINALIZADA_POR_ASEGURABLE = 4;
export const COBERTURA_PRINCIPAL_VIDA = "Fallecimiento";
export const FINALIZAR_PROCESO = "finalizarProceso";
export const SOLICITAR_REEVALUACION = "solicitarReevaluacion";
export const CONTINUAR_SIN_CARGA = "continuarSinCarga";

export const SECCION_DATOS_PERSONALES = "datosPersonales";
export const SECCION_ASEGURABLES_ADICIONALES = "asegurablesAdicionales";

export const TAB_ACTIVA_BENEFICIARIO_CONSENTIMIENTO = "tabConsentimiento";
export const TAB_ACTIVA_BENEFICIARIO_DECLARACION = "tabDeclaracion";
export const TAB_UNO = 1;
export const NUMERO_DE_POLIZA = "Numero de Poliza";
export const CAPITAL_ASEGURADO = "Capital asegurado";
export const NOMBRE_TITULAR_BENEFICIARIO = "<nombre titular>";
export const RUT_TITULAR_BENEFICIARIO = "<Rut Titular>";
export const RAZON_SOCIAL_BENEFICIARIO =
  "<Razón Social Rut Empresa Contratante>";
//SIGNOS

export const SIGNO_IGUAL = "=";
export const SIGNO_GUION_BAJO = "_";
export const SIGNO_PUNTO = ".";
export const SIGNO_GUION = "-";
export const SIGNO_BARRA = "/";
export const SIGNO_ASTERISCO = "*";

export const PALABRA_GRUPO = "Grupo";
export const PALABRA_TRABAJADORES = "Trabajadores";

export const ID_COBERTURA_FALLECIMIENTO = 15;
export const COBERTURA_FALLECIMIENTO = "Fallecimiento";
export const COBERTURA_SEGURO_VIDA = "Seguro de Vida";

export const TITULO_INGRESAR_ASEGURABLES = "Ingresar Asegurables";
export const TITULO_EXCLUSION_INDIVIDUAL = "Exclusión individual";
export const TITULO_CARGA_MASIVA = "Carga masiva";
export const TITULO_EXCLUSION_MASIVA = "Exclusión masiva";

export const DECLARACION_ACEPTACION = `Declaro que las personas que serán informadas a 
Compañía de Seguros de Vida Cámara S.A. para su incorporación al seguro son personal dependiente o
vinculado a la empresa u organización
contratante y que ésta cuenta con las
autorizaciones necesarias de parte de
dicho personal para iniciar dicho proceso
e informar los datos personales que se
requieren. Declaro que he leído y acepto
las condiciones de la cotización y los
términos y condiciones.`;

export const DECLARACION_CONDICIONES_SEGURO = `Declaro que he leído y tomado conocimiento de la cobertura, exclusiones y demás condiciones del seguro.`;

export const DECLARACION_TERMINOS_CONDICIONES = `Leídas y revisadas todas las informaciones proporcionadas
por Compañía de Seguros de Vida Cámara S.A., y declarando
que entiendo que al marcar esta casilla o checkbox,
manifiesto mi consentimiento, acepto los términos y
condiciones, la política de privacidad y de tratamiento de
datos, así como las coberturas, exclusiones y demás
condiciones del seguro, por lo que confirmo mi elección de
ser incorporado al seguro colectivo.`;

export const NRO_DOCUMENTO_TERMINO_CONDICION = 1;
export const TIPO_DOCUMENTO_TERMINO_CONDICION = "suscripcionDigital";

export const ELIMINAR_CARGAS_DELEGADO =
  "Para eliminar cargas declaradas, favor comunicarse con su ejecutivo de venta.";
export const ELIMINAR = "ELIMINAR";

//Deslizador
export const CANTIDAD_SOLICITUDES_TOTALES = 0;
export const CANTIDAD_SOLICITUDES_CREADAS = 0;
export const CANTIDAD_SOLICITUDES_EN_INGRESO = 0;
export const CANTIDAD_SOLICITUDES_ENROLADAS = 0;
export const CANTIDAD_SOLICITUDES_PROCESADAS = 0;
export const CANTIDAD_SOLICITUDES_RECHAZADAS = 0;
export const CANTIDAD_SOLICITUDES_FINALIZADAS = 0;
export const CANTIDAD_CREADAS = "Cantidad Creadas";
export const CANTIDAD_SOLICITUDES = "Cantidad de solicitudes";
export const CANTIDAD_INGRESADAS = "Cantidad Ingresadas";
export const CANTIDAD_ENROLADAS = "Cantidad Enroladas";
export const CANTIDAD_PROCESADAS = "Cantidad procesadas";
export const CANTIDAD_FINALIZADAS = "Cantidad de finalizadas";
export const CANTIDAD_RECHAZADAS = "Cantidad de rechazadas";

export const ESTADO_ENROLAMIENTO = "Estado Enrolamiento";

//Notificacion
export const NOTIFICACION_PELIGRO = "danger";
export const NOTIFICACION_INTENTELO_MAS_TARDE =
  "Error al realizar la solicitud. Intente de nuevo más tarde.";
export const NOTIFICACION_ERROR_CAPTCHA =
  "Error al validar el captcha. Intente de nuevo más tarde.";

//MODAL
export const TITULO_MODAL_ACEPTAR_DOCUMENTOS_COBERTURAS = "Coberturas";
export const TITULO_MODAL_ACEPTAR_DOCUMENTOS_COBERTURAS_EXCLUSIONES =
  "Coberturas y Exclusiones";
export const TEXTO_MODAL_ACEPTAR_DOCUMENTOS_COBERTURAS =
  "Para continuar con el proceso debe haber hecho click y leído los enlaces de coberturas.";
export const TEXTO_MODAL_ACEPTAR_DOCUMENTOS_COBERTURAS_EXCLUSIONES =
  "Para continuar con el proceso debe haber hecho click y leído los enlaces de coberturas y exclusiones.";

export const PATROCINADORES_CONFIGURACION = [
  {
    id: 2,
    nombre: "MUTUAL",
    color: "#c8d644",
    token: "TK_CotizadorMutual",
  },
  {
    id: 1,
    nombre: "TALANA",
    color: "#0081FC",
    token: "TK_CotizadorTalana",
  },
  {
    id: 0,
    nombre: "VC",
    color: "#e38100",
    token: "TK_CotizadorSinPatrocinador",
  },
];

export const SIN_PATROCINADOR = 0;
export const VALOR_CORRECCION_MES = 1;
export const TAMANO_UNO = 1;
export const ENDPOINT_LISTAR_CON_PRIMA_FIJA = "listarConPrimaFijaPorToken";
export const NOTIFICACION_TIPO_EXITOSA = "success";
export const NOTIFICACION_TIPO_INFO = "info";
export const NOTIFICACION_TIPO_WARN = "warning";
export const NOTIFICACION_TIPO_ERROR = "danger";
export const EXPRESION_REGULAR_PUNTO = /[.]/g;
export const CONFIGURACION_CAMPO_RUT = {
  id: "rut",
  nombre: "RUT",
  maximaLongitud: "12",
  minimaLongitud: "11",
  tipo: "text",
};
export const CONFIGURACION_CAMPO_USUARIO = {
  id: "usuario",
  nombre: "Nombre de usuario",
  maximaLongitud: "20",
  minimaLongitud: "1",
  tipo: "text",
};
export const CONFIGURACION_CAMPO_CONTRASENA = {
  id: "clave",
  nombre: "Contraseña",
  maximaLongitud: "30",
  minimaLongitud: "1",
  tipo: "password",
};
export const LISTA_PERFILES_INICIO_SESION = [
  { perfil: "Asegurable", codigo: COD_ASEGURADO, activo: false },
  { perfil: "Delegado", codigo: COD_DELEGADO, activo: false },
  { perfil: "Corredor", codigo: COD_CORREDOR, activo: false },
  { perfil: "Interno", codigo: COD_EJECUTIVO, activo: false },
];

export const CONFIGURACION_INICIAL_SESION = {
  rut: "",
  usuario: "",
  clave: "",
  idSitio: Number(process.env.REACT_APP_COD_SITIO),
};

export const COD_GALLETA_TIEMPO = "time";
export const TAMANO_CINCO = 5;
export const TAMANO_SIETE = 7;
export const ALMACENAMIENTO_SESION = "SESION";
export const ALMACENAMIENTO_LOCAL = "LOCAL";
export const COLOR_NARANJA = "#f38f31";
export const COLOR_AZUL_OSCURO = "#212529";
export const TECLA_ENTER_CODIGO = 13;
export const REQUEST_POST_TIMEOUT = 20000;
export const TEXTO_TERMINOS_CONDICIONES = "TyC";
export const ERROR_ASEGURABILIDAD_ENDPOINT ="No se pudo encontrar los requisitos de asegurabilidad para las coberturas: ";
export const ERROR_ASEGURABILIDAD_NO_CUBRE_CARGA ="La carga no cuple los requisitos de asegurabilidad para las coberturas: ";
export const ERROR_ASEGURABILIDAD_NO_CUBRE_TITULAR ="El titular no cuple los requisitos de asegurabilidad para las coberturas: ";

export const SECCION_DECLARACION_DEPORTE_ID = 3;
export const SECCION_DECLARACION_SALUD_ID = 1;
export const SECCION_DECLARACION_ESPECIAL_ID = 2;

//EXPRESIONES REGULARES

export const EXP_CARACTERES_ESPECIALES_AMPERSAND =
  /[^a-zA-Z0-9\u00C0-\u017F\s&.]/g;
export const EXP_CARACTERES_ESPECIALES = /[^a-zA-Z0-9\u00C0-\u017F\s]/g;
export const EXP_ESPACIOS = /\s{2,}/g;
export const EXP_SALTO_LINEAS = /\n/g;
export const EXP_SOLO_NUMERO = /\d/g;
export const EXP_CARACTERES_ESPECIALES_DIAGNOSTICO =
  /[^a-zA-Z0-9.,\\u00C0\u017Fs&/\s{2,}/g]+/g;
