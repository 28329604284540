import React, { useState, Fragment, useEffect } from "react";
import { withRouter } from "react-router-dom";
import ImgGestionarDelegado from "../../../../assets/images/gestionarEjecutivo.png";
import checkAmarillo from "../../../../assets/images/checkYellow.png";
import cruzRoja from "../../../../assets/images/cruzRed.png";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Spinner from "../../../transversales/Spinner";
import { obtenerDelegadoPorRut, asignarPermisoDelegadoMantencion } from "../../../../../services/delegado.service";
import { notificacion } from "../../../transversales/Notificaciones";
import {
    VALIDACION_RUT,
    CAMPO_REQUERIDO_RADIO,
    VALIDACION_RADIO,
    VALOR_ZERO,
    VALOR_TRES,
    VALOR_UNO,
    VALOR_DOS,
    EXITO_ASIGNAR_PERFIL,
    ERROR_ASIGNAR_PERFIL,
    ASUNTO_MAIL_ASIGNAR_PERFIL,
    EMISOR_MAIL_ASIGNAR_PERFIL,
    NOMBRE_PLANTILLA_MAIL_ASIGNAR_PERFIL,
    GA_LABEL_PERMISOS_EJECUTIVO,
    GA_CATEGORIA_EJECUTIVOS
} from "../../../../../utils/Constantes";
import { crearEventoTraza } from "../../../../../utils/analitica";

const { format, clean, validate } = require("rut.js");

const Permisos = (props) => {

    useEffect(() => {
        crearEventoTraza(GA_CATEGORIA_EJECUTIVOS, GA_LABEL_PERMISOS_EJECUTIVO);
    },[]);

    const [cargando, setcargando] = useState(false);
    const [cargarDatos, setCargarDatos] = useState(false);
    const [opcionGestionarDelegado, setOpcionGestionarDelegado] = useState(VALOR_ZERO);
    const [rutDelegado, setRutDelegado] = useState("");
    const [nombreDelegado, setNombreDelegado] = useState("");
    const [infoDelegado, setInfoDelegado] = useState("");


    const buscarDelegado = async (rut) => {
        const responseDelegado = await obtenerDelegadoPorRut(rut);
        setInfoDelegado(responseDelegado);
        if (responseDelegado === null || responseDelegado.vigente === false) {
            //DELEGADO NO SE ENCUENTRA EN LA BD
            setRutDelegado(rut)
            setOpcionGestionarDelegado(VALOR_TRES);
        } else if (responseDelegado.length > 0) {
            //DELEGADO CON PERFIL ASGINADO
            responseDelegado.forEach(el => {
                setRutDelegado(format(rut))
                setNombreDelegado(el.nombre)
            });
            let existePerfil = responseDelegado.find(item => item.perfil.codPerfil === 205);
            existePerfil ? setOpcionGestionarDelegado(VALOR_DOS) : setOpcionGestionarDelegado(VALOR_UNO);
        } else {
            setRutDelegado(format(responseDelegado.rut))
            setNombreDelegado(responseDelegado.nombre + ' ' +
                responseDelegado.apellidoPaterno + ' ' +
                responseDelegado.apellidoMaterno
            );
            setOpcionGestionarDelegado(VALOR_UNO)
        }
        setcargando(false)
    }

    const cerrarDialogo = async () => {
        setOpcionGestionarDelegado(0)
    };

    const asignarPermisoDelegado = async (dato) => {
        let rut = format(rutDelegado).replace(/[.]/g, "");

        if (dato.radioAgregarPermisoDelegadoSi) {
            const dataEnvio = {
                idPerfil: process.env.REACT_APP_COD_DELEGADO_MANTENCION,
                rut: rut,
                sitioCod: process.env.REACT_APP_COD_SITIO,
                envioMail: false,
                nombrePlantilla: NOMBRE_PLANTILLA_MAIL_ASIGNAR_PERFIL,
                xml: nombreDelegado,
                asunto: ASUNTO_MAIL_ASIGNAR_PERFIL,
                destinatario: infoDelegado.direcciones[0].correoElectronico,
                emisor: EMISOR_MAIL_ASIGNAR_PERFIL,
                adjuntos: []
            };

            let res = await asignarPermisoDelegadoMantencion(dataEnvio);
            if (res !== null) {
                setCargarDatos(false);
                notificacion("success", EXITO_ASIGNAR_PERFIL);
                cerrarDialogo();
            } else {
                setCargarDatos(false);
                notificacion("danger", ERROR_ASIGNAR_PERFIL);
            }
            setCargarDatos(false);
        } else {
            cerrarDialogo();
        }
    }

    const handleSubmit = async (dato) => {
        let rut = format(dato.rutDelegado).replace(/[.]/g, "");
        switch (opcionGestionarDelegado) {
            case VALOR_ZERO:
                buscarDelegado(rut);
                break;
            case VALOR_UNO:
                setCargarDatos(true);
                asignarPermisoDelegado(dato);
                break;
            case VALOR_DOS:
                cerrarDialogo();
                break;
            case VALOR_TRES:
                cerrarDialogo();
                break;
            default: return null;
        }
    };

    return (
        <React.Fragment>
            <div className="card shadow mb-3">
                <div className="card-body">
                    <div className="row">
                        <div className="mx-auto my-5 animated fadeIn">
                            <h4 className="text-center">
                                <img src={ImgGestionarDelegado}
                                    width="140"
                                    height="200"
                                    className="img-fluid d-block mx-auto mb-2"
                                    alt="">
                                </img>
                                <strong style={{ color: "#e89b03" }}>Asignar Perfil Delegado Mantención</strong>
                            </h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12" style={{ paddingLeft: '15%', paddingRight: '15%' }}>
                            <Formik
                                enableReinitialize={true}
                                initialValues={{
                                    rutDelegado: "",
                                    radioAgregarPermisoDelegadoSi: false,
                                    radioAgregarPermisoDelegadoNo: false,
                                }}
                                validationSchema={Yup.object().shape({
                                    rutDelegado: Yup.string()
                                        .test("validarRut", VALIDACION_RUT, (value) => {
                                            if (opcionGestionarDelegado !== VALOR_UNO) {
                                                return validate(clean(value));
                                            } else {
                                                return true;
                                            }
                                        }),
                                    radioAgregarPermisoDelegadoSi: Yup.string()
                                        .required(CAMPO_REQUERIDO_RADIO)
                                        .test(
                                            "validarSelect",
                                            VALIDACION_RADIO,
                                            function (value) {
                                                return opcionGestionarDelegado === VALOR_ZERO ? true :
                                                    this.parent.radioAgregarPermisoDelegadoSi !== 'true' &&
                                                        !this.parent.radioAgregarPermisoDelegadoNo ? false : true;
                                            }
                                        ),
                                })}
                                onSubmit={async (
                                    values,
                                    { resetForm }
                                ) => {
                                    handleSubmit(values);
                                    resetForm({});
                                }}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    setFieldValue,
                                    handleSubmit,
                                    field
                                }) => {
                                    return (
                                        <Form
                                            id="formularioAgregarPermisoDelegado"
                                            name="agregar-form"
                                            onSubmit={handleSubmit}
                                        >
                                            <div className="row">
                                                {/* Rut */}
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <strong style={{ fontSize: 15 }}>
                                                            RUT del Delegado
                                                        </strong>
                                                        <Field
                                                            id="rutDelegado"
                                                            name="rutDelegado"
                                                            placeholder="Ingresar RUT del delegado"
                                                            maxLength="12"
                                                            minLength="11"
                                                            value={values.rutDelegado !== "-" && values.rutDelegado !== "" ? format(values.rutDelegado) : ""}
                                                            type="text"
                                                            width="100%"
                                                            className="form-control form-control-sm"
                                                            style={{ marginTop: 5 }}
                                                        />
                                                        {errors.rutDelegado && touched.rutDelegado && (
                                                            <small className="text-danger animated fadeIn">
                                                                {errors.rutDelegado}
                                                            </small>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>

                                            {opcionGestionarDelegado === VALOR_UNO ?
                                                <div className="col-12" id="agregarEjecutivoMargenes">
                                                    <div className="row">
                                                        <div className="col-md-12" style={{ marginTop: 15, marginBottom: 15 }}>
                                                            <strong style={{ fontSize: 15 }}>
                                                                Datos del delegado
                                                            </strong>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-md-12" style={{ marginBottom: 0 }}>
                                                            <label>
                                                                Rut: {rutDelegado}
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <label>
                                                                Delegado: {nombreDelegado}
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <div className="row" id="agregarEjecutivoMargenesAbajo" />

                                                    <div className="col-md-12" id="agregarEjecutivoSiYNo">
                                                        <div className="row">
                                                            <div className="col-8">
                                                                <label style={{ color: "#e89b03", marginTop: 5 }}>
                                                                    ¿Esta seguro de querer crear permiso al Delegado {nombreDelegado} al sistema Suscripción Digital?
                                                                </label>
                                                            </div>

                                                            <div className="col-2">
                                                                <label style={{ fontSize: 20 }}>
                                                                    <input
                                                                        id="radioAgregarPermisoDelegadoSi"
                                                                        name="radioAgregarPermisoDelegadoSi"
                                                                        type="radio"
                                                                        size="100"
                                                                        value={values.radioAgregarPermisoDelegadoSi}
                                                                        checked={values.radioAgregarPermisoDelegadoSi}
                                                                        onClick={(event) => {
                                                                            setFieldValue(
                                                                                "radioAgregarPermisoDelegadoNo",
                                                                                false
                                                                            );
                                                                            setFieldValue(
                                                                                "radioAgregarPermisoDelegadoSi",
                                                                                !values.radioAgregarPermisoDelegadoSi
                                                                            );
                                                                        }}
                                                                        readOnly
                                                                    />
                                                                    <span className="label-text" />
                                                                </label>
                                                                <span className="label-text" style={{ position: 'fixed', marginTop: 4 }}>
                                                                    SI
                                                                </span>
                                                            </div>
                                                            <div className="col-2">
                                                                <label style={{ fontSize: 20 }}>
                                                                    <input
                                                                        id="radioAgregarPermisoDelegadoNo"
                                                                        name="radioAgregarPermisoDelegadoNo"
                                                                        type="radio"
                                                                        value={values.radioAgregarPermisoDelegadoNo}
                                                                        checked={values.radioAgregarPermisoDelegadoNo}
                                                                        onClick={(event) => {
                                                                            cerrarDialogo()
                                                                        }}
                                                                        readOnly
                                                                    />
                                                                    <span className="label-text" />
                                                                </label>
                                                                <span className="label-text" style={{ position: 'fixed', marginTop: 4 }}>
                                                                    NO
                                                                </span>
                                                            </div>
                                                            <div className="col-8"></div>
                                                            {errors.radioAgregarPermisoDelegadoSi &&
                                                                touched.radioAgregarPermisoDelegadoSi && (
                                                                    <small className="text-danger animated fadeIn ">
                                                                        {errors.radioAgregarPermisoDelegadoSi}
                                                                    </small>
                                                                )}
                                                        </div>
                                                    </div>
                                                </div>
                                                : opcionGestionarDelegado === VALOR_DOS ?
                                                    <div className="col-12" id="agregarEjecutivoMargenes">
                                                        <div className="row">
                                                            <div className="col-md-12" style={{ marginTop: 15, marginBottom: 15 }}>
                                                                <strong style={{ fontSize: 15 }}>
                                                                    Datos del Delegado
                                                                </strong>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-md-12" style={{ marginBottom: 0 }}>
                                                                <label>
                                                                    Rut: {rutDelegado}
                                                                </label>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <label>
                                                                    Delegado: {nombreDelegado}
                                                                </label>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <img
                                                                    src={checkAmarillo}
                                                                    width="30"
                                                                    height="30"
                                                                    className="img-fluid mx-auto mb-2"
                                                                    id="imgCheckAgregarEjecutivo"
                                                                    alt=""
                                                                ></img>
                                                                <label>
                                                                    Delegado ya posee permisos.
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : opcionGestionarDelegado === VALOR_TRES &&
                                                    <div className="col-12" id="agregarEjecutivoMargenes">
                                                        <div className="row">
                                                            <div className="col-md-12" style={{ marginTop: 15, marginBottom: 15 }}>
                                                                <strong style={{ fontSize: 15 }}>
                                                                    Datos del Delegado
                                                                </strong>
                                                            </div>
                                                        </div>

                                                        <div className="row" style={{ marginBottom: 0, paddingBottom: 0 }}>
                                                            <div className="col-md-12" style={{ marginBottom: 0, paddingBottom: 0 }}>
                                                                <label>
                                                                    Rut: {rutDelegado}
                                                                </label>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-md-12" style={{ marginTop: 0 }}>
                                                                <img
                                                                    src={cruzRoja}
                                                                    width="30"
                                                                    height="30"
                                                                    alt=""
                                                                ></img>
                                                                <label style={{ color: 'red' }}>
                                                                    No se encuentra información del Delegado.
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                            }


                                            {
                                                opcionGestionarDelegado === VALOR_ZERO ?
                                                    <button
                                                        type="submit"
                                                        className="btn btn-principal mt-4 d-block ml-auto"
                                                    >
                                                        Buscar
                                                    </button>
                                                    : opcionGestionarDelegado === VALOR_UNO ?
                                                        <button
                                                            type="submit"
                                                            className="btn btn-principal mt-4 d-block ml-auto"
                                                            disabled={cargarDatos}
                                                        >
                                                            {
                                                                cargarDatos ? (
                                                                    <Fragment>
                                                                        <i className="fa fa-spinner fa-pulse fa-fw"></i>
                                                                        <span className="sr-only">Loading...</span>
                                                                    </Fragment>
                                                                ) : (
                                                                    <span>Agregar</span>
                                                                )
                                                            }
                                                        </button >
                                                        :
                                                        <button
                                                            type="button"
                                                            className="btn btn-principal mt-4 d-block ml-auto"
                                                            onClick={(event) => {
                                                                event.preventDefault();
                                                                cerrarDialogo()
                                                            }}
                                                        >
                                                            Cerrar
                                                        </button>
                                            }

                                        </Form >
                                    );
                                }}
                            </Formik >
                        </div >
                    </div >
                </div >
            </div >
            {
                cargando ? <Spinner claseColor="transparente"
                    idSpinner="spinnerTransparente"
                    posicionLogo="posicionLogo align-self-center w-100" /> : null
            }
        </React.Fragment >
    );
}

export default withRouter(Permisos);
