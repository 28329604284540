import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ModalEditarAsegurable from "../../jefatura-ejecutivo/ModalEditarAsegurable";
import { enviarMailAsegurable } from "../../../../../services/asegurable.services";
import { notificacion } from "../../../transversales/Notificaciones";
import { guardar_negocio } from "../../../../../actions/actions";
import {
  TITLE_SALUD_DIGITAL,
  EXITO_ENVIO_CORREO,
  ERROR_PETICION_SERVIDOR,
  TIPO_CANAL_VENTA_DIRECTA,
  MENSAJE_MODAL_INICIO_ENROLAMIENTO_VENTA_DIRECTA,
  ERROR_ENVIAR_CORREO_ASEGURABLE,
  PERFIL_DELEGADO,
  ID_SUBPRODUCTO_SALUD_DIGITAL,
  POSICION_INICIAL,
} from "../../../../../utils/Constantes";
import { actualizarNegocio } from "../../../../../services/negocios.service";


class InicioEnrolamientoDelegado extends Component {

  state = {
    loadingData: false
  }

  submit = async () => {

    this.setState({ loadingData: true });

    const data = {
      perfil: PERFIL_DELEGADO,
      idNegocio: this.props.store.reducer.negocio.idNegocio,
      fechaInicioEnrolamiento: new Date(),
    };

    const negocioActualizado = await actualizarNegocio(data);

    if (negocioActualizado != null) {

      this.props.guardar_negocio({
        ...this.props.store.reducer.negocio,
        fechaInicioEnrolamiento: new Date(),
      });

      this.props.actualizarFechaInicioEnrolamiento(new Date());

      const dataCorreo = {
        grupo: {
          idGrupo: this.props.store.reducer.cotizacion[POSICION_INICIAL].grupos[POSICION_INICIAL].idGrupo,
          nombre: TITLE_SALUD_DIGITAL,
        },
      };
      const envioCorreo = await enviarMailAsegurable(dataCorreo, PERFIL_DELEGADO);
      if (envioCorreo !== null) {
        notificacion("success", EXITO_ENVIO_CORREO);


      } else {
        notificacion("danger", ERROR_ENVIAR_CORREO_ASEGURABLE);
      }
    } else {
      notificacion("danger", ERROR_PETICION_SERVIDOR);
    }

    this.props.mostrarModalInicioEnrolamiento();
  };

  render() {
    let productoDigital = false;
    this.props.store.reducer.negocio.cotizaciones && this.props.store.reducer.negocio.cotizaciones.forEach(objCotizacion => {
      if (objCotizacion.subProducto.idSubProducto === ID_SUBPRODUCTO_SALUD_DIGITAL)
        productoDigital = true;
    });
    let mensaje, showSubmitButton;
    if (
      this.props.store.reducer.delegado.aseguradosDelegado.length >=
      this.props.cantidadMinimaAdherencia
    ) {
      if (
        this.props.store.reducer.delegado.aseguradosDelegado.length <=
        this.props.maximoAsegurables || !productoDigital
      ) {
        if (
          this.props.store.reducer.negocio.canalVenta.idCanalVenta ===
          TIPO_CANAL_VENTA_DIRECTA
        ) {
          mensaje = MENSAJE_MODAL_INICIO_ENROLAMIENTO_VENTA_DIRECTA;
        } else {
          mensaje =
            "¿Está seguro que desea iniciar el proceso de enrolamiento?";
        }
        showSubmitButton = true;
      } else {
        mensaje = `No puede iniciar enrolamiento con más de ${this.props.maximoAsegurables} asegurables, que es el máximo permitido para esta cotización`;
        showSubmitButton = false;
      }
    } else {
      mensaje = `Debe ingresar mínimo ${this.props.cantidadMinimaAdherencia} asegurables para iniciar enrolamiento`;
      showSubmitButton = false;
    }
    return (
      <ModalEditarAsegurable
        idSubmit="submitInicioEnrolamientoDelegado"
        type="small"
        show={this.props.modalInicioEnrolamiento}
        loadingData={this.state.loadingData}
        title="Inicio de Enrolamiento"
        action="Iniciar"
        onSubmit={this.submit}
        onCancel={() => {
          this.props.mostrarModalInicioEnrolamiento();
        }}
        onClose={() => {
          this.props.mostrarModalInicioEnrolamiento();
        }}
        showCancelButton={true}
        showCloseButton={true}
        showLeftColumn={false}
        showSubmitButton={showSubmitButton}
      >
        <div className="row">
          <h5 className="col-12 col-md-8 offset-md-2">{mensaje}</h5>
        </div>
      </ModalEditarAsegurable>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state,
});

export default withRouter(
  connect(mapStateToProps, { guardar_negocio })(InicioEnrolamientoDelegado)
);
