import React, { useState } from "react";

const RadioSelection = ({ disabled, value, name, obtenerCheckValue }) => {
  const [checked, setChecked] = useState(value);

  return (
    <React.Fragment>
      <div className="d-block">
        <label className="custom-checkbox p-1">
          <input
            id={name}
            name={name}
            type="radio"
            checked={checked}
            disabled={disabled}
            onChange={() => {
              setChecked(true);
              obtenerCheckValue(true, name)
            }}
          />
          <div className={disabled === false ? "check": "check-disabled"}></div>
          <div className="option">Si</div>
        </label>
        <label className="custom-checkbox p-1">
          <input
            id={name}
            name={name}
            type="radio"
            checked={checked === false}
            disabled={disabled}
            onChange={() => {
              setChecked(false);
              obtenerCheckValue(false, name)
            }}
          />
          <div className={disabled === false ? "check": "check-disabled"}></div>
          <div className="option">No</div>
        </label>
      </div>
    </React.Fragment>
  );
};

export default RadioSelection;
