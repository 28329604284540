import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { notificacion } from "../../../../transversales/Notificaciones";
import {
  INVALIDEZ,
  ACEPTAR_TERMINOS,
  EXITO_DECLARACIONES,
  ERROR_DECLARACIONES,
  SI_NO_DECLARACIONES,
  VALOR_UNO,
  PAGINA_3,
  NO_DECLARA,
  ALERTA_NO_DECLARACIONES,
  PAGINA_2,
  PAGINA_1,
  TIPO_PAGINA,
  VALOR_ZERO,
  GA_CATEGORIA_FORMULARIO_ENROLAMIENTO,
  GA_LABEL_PREGUNTAS_COMPLEMENTARIA,
} from "../../../../../../utils/Constantes";
import {
  wizard,
  add_detalle,
  update_detalle,
  guardar_data_asegurable,
  guardar_data_wizard_asegurado,
  update_carga,
  guardar_cheks_seccion,
} from "../../../../../../actions/actions";
import "react-confirm-alert/src/react-confirm-alert.css";
import "moment/locale/es";
import "react-datepicker/dist/react-datepicker.css";
import PreguntaComplementarias from "./PreguntaComplementarias";
import PreguntasMultiples from "../PreguntasMultiples";
import { crearEventoTraza } from "../../../../../../utils/analitica";
import { guardarDeclaraciones } from "../../../../../../services/declaracion.services";

class CoberturasComplemetarias extends Component {
  constructor(props) {
    super(props);
    this.aceptacionRef = React.createRef();
    this.state = {
      checked:
        this.props.store.reducer.dataWizardAsegurado.solicitud
          .declaroEspecial === null ||
        this.props.store.reducer.dataWizardAsegurado.solicitud
          .declaroEspecial === undefined
          ? null
          : this.props.store.reducer.dataWizardAsegurado.solicitud
              .declaroEspecial,
      edit: 0,
      aceptacionCheck:
        this.props.store.reducer.dataWizardAsegurado.solicitud
          .declaroEspecial !== null,
      claseAceptacion: "",
      animacion: "formInit",
      detalle: [],
      loadingData:false
    };
  }

  componentWillMount() {
    crearEventoTraza(
      GA_CATEGORIA_FORMULARIO_ENROLAMIENTO,
      GA_LABEL_PREGUNTAS_COMPLEMENTARIA
    );

    const { reducer } = this.props.store;
    setTimeout(() => {
      if (
        reducer.wizard.pagina === PAGINA_3 &&
        reducer.wizard.tab === VALOR_UNO
      ) {
        this.setState({ animacion: "movingIn" });
      }
    }, 100);

    const declaracionesSeccion = reducer.declaraciones.filter(
      (pregunta) => pregunta.seccion.glosa === INVALIDEZ
    );
    const arregloChecks = declaracionesSeccion.map((declaracion) => {

      const existeDeclaracion =
        reducer.configuracionDeclaraciones.detalle.filter(
          (item) =>
            declaracion.idPreguntaSeccionCobertura ===
              item.idPreguntaSeccionCobertura &&
            item.catalogoDeclaracion.declaracion !== NO_DECLARA
        );

      const blnDeclaracionNoDeclara =
        reducer.configuracionDeclaraciones.detalle.filter(
          (item) =>
            declaracion.idPreguntaSeccionCobertura ===
              item.idPreguntaSeccionCobertura &&
            item.catalogoDeclaracion.declaracion === NO_DECLARA
        );

      const declaracionCheck = (existeDeclaracion && existeDeclaracion.length > VALOR_ZERO) ? true :
                                (blnDeclaracionNoDeclara && blnDeclaracionNoDeclara.length > VALOR_ZERO) ? false : null;

      const blnAceptacionCheck =
        (existeDeclaracion && existeDeclaracion.length > VALOR_ZERO) ||
        (blnDeclaracionNoDeclara &&
          blnDeclaracionNoDeclara.length > VALOR_ZERO);

      const detalle = reducer.configuracionDeclaraciones.detalle.filter(
        (declaracionAsegurable) =>
          declaracionAsegurable.idPreguntaSeccionCobertura ===
            declaracion.idPreguntaSeccionCobertura &&
          declaracionAsegurable.catalogoDeclaracion.declaracion !== NO_DECLARA
      );
      return {
        idPregunta: declaracion.catalogoPregunta.idPregunta,
        idPreguntaSeccionCobertura: declaracion.idPreguntaSeccionCobertura,
        declaracionCheck,
        aceptacionCheck: blnAceptacionCheck,
        detalle,
      };
    });

    const preguntaSinChecked = arregloChecks.find(
      (item) => item.declaracionCheck === null
    );

    this.setState({
      declaracionesSeccion,
      arregloChecks,
      preguntaSinChecked,
    });
    this.props.guardar_cheks_seccion(arregloChecks);
  }

  enviarDeclaraciones = async () => {

    const lstPreguntas                            = this.state.declaracionesSeccion;
    let { dataWizardAsegurado }                   = this.props.store.reducer;
    dataWizardAsegurado.solicitud.declaroEspecial = this.state.checked;
    let objRespuesta                              = await guardarDeclaraciones(dataWizardAsegurado, lstPreguntas);
    await this.props.guardar_data_wizard_asegurado(objRespuesta);

    return objRespuesta;
  };

  check = (boolean, idPregunta) => {
    const arregloChecks = this.props.store.reducer.objetoChecks;
    const objetoCheck = arregloChecks.find(
      (objeto) => objeto.idPregunta === idPregunta
    );
    const index = arregloChecks.indexOf(objetoCheck);
    arregloChecks[index] = {
      ...arregloChecks[index],
      declaracionCheck: boolean,
    };

    let checked;
    const existeDeclaracionCheckTrue = arregloChecks.find(
      (objeto) => objeto.declaracionCheck
    );
    if (existeDeclaracionCheckTrue) {
      checked = true;
    } else {
      checked = false;
    }

    const preguntaSinChecked = arregloChecks.find(
      (item) => item.declaracionCheck === null
    );

    this.setState({ arregloChecks, checked, preguntaSinChecked });
    this.props.guardar_cheks_seccion(arregloChecks);
  };

  checkAceptacion = (boolean, idPregunta) => {
    const arregloChecks = [...this.state.arregloChecks];
    const objetoCheck = arregloChecks.find(
      (objeto) => objeto.idPregunta === idPregunta
    );
    const index = arregloChecks.indexOf(objetoCheck);
    arregloChecks[index] = {
      ...arregloChecks[index],
      aceptacionCheck: boolean,
    };

    let aceptacionCheck;
    const existeAceptacionCheckFalse = arregloChecks.find(
      (objeto) => !objeto.aceptacionCheck
    );
    if (existeAceptacionCheckFalse) {
      aceptacionCheck = false;
    } else {
      aceptacionCheck = true;
    }

    this.setState({ arregloChecks, aceptacionCheck });
    this.props.guardar_cheks_seccion(arregloChecks);
  };

  mostrarPreguntaConAdvertencia = (strAdvertencia) => {
    const lstArregloChecks = [...this.props.store.reducer.objetoChecks];
    let intIdPregunta      = 0;

    switch(strAdvertencia){
      case SI_NO_DECLARACIONES:
        intIdPregunta = lstArregloChecks.find((objCheck) => objCheck.declaracionCheck === null).idPregunta;
      break;
      case ALERTA_NO_DECLARACIONES:
        intIdPregunta = lstArregloChecks.find((objCheck) => objCheck.declaracionCheck && objCheck.detalle.length < 1).idPregunta;
      break;
      case ACEPTAR_TERMINOS:
        intIdPregunta = lstArregloChecks.find((objCheck) => objCheck.declaracionCheck && !objCheck.aceptacionCheck).idPregunta;
      break;
      default: break;
    }

    const lstDeclaracionesSeccion = [...this.state.declaracionesSeccion];
    lstDeclaracionesSeccion.forEach((objDeclaracionSeccion) => {
      objDeclaracionSeccion.mostrarPregunta = (objDeclaracionSeccion.catalogoPregunta.idPregunta === intIdPregunta);
    });

    this.setState({ declaracionesSeccion: lstDeclaracionesSeccion });
  }

  atras = () => {
    this.setState({ animacion: "formOut" });

    setTimeout(() => {
      const pagina = this.props.store.reducer.estructuraFormulario.cargas
        ? PAGINA_2
        : PAGINA_1;

      this.props.wizard({ pagina, type: TIPO_PAGINA });
    });
  };

  siguiente = async () => {
    this.setState({ loadingData: true });
    if (this.state.preguntaSinChecked) {
      this.mostrarPreguntaConAdvertencia(SI_NO_DECLARACIONES);
      notificacion("warning", SI_NO_DECLARACIONES);
      this.setState({ loadingData: false });
      return;
    }

    const lstArregloChecks = this.state.arregloChecks.map((objDeclaracion) => {
      const lstDetalles =
        this.props.store.reducer.configuracionDeclaraciones.detalle.filter(
          (objDeclaracionAsegurable) =>
            objDeclaracionAsegurable.idPreguntaSeccionCobertura === objDeclaracion.idPreguntaSeccionCobertura &&
            objDeclaracionAsegurable.catalogoDeclaracion.declaracion !== NO_DECLARA
        );
      return {
        ...objDeclaracion,
        detalle: lstDetalles,
      };
    });

    const blnSiDeclaraSinRegistro = lstArregloChecks.map((objDeclaracion) => {
      return (objDeclaracion.declaracionCheck && objDeclaracion.detalle.length < 1) 
    }).includes(true);

    if (blnSiDeclaraSinRegistro) {
      this.mostrarPreguntaConAdvertencia(ALERTA_NO_DECLARACIONES);
      notificacion("warning", ALERTA_NO_DECLARACIONES);
      this.setState({ loadingData: false });
      return;
    }

    const blnSiDeclaraSinAceptacion = lstArregloChecks.map((objDeclaracion) => {
      return (objDeclaracion.declaracionCheck && !objDeclaracion.aceptacionCheck) 
    }).includes(true);

    if (blnSiDeclaraSinAceptacion) {
      this.mostrarPreguntaConAdvertencia(ACEPTAR_TERMINOS);
      notificacion("warning", ACEPTAR_TERMINOS);
      this.setState({ loadingData: false });
      return;
    }

    const res = await this.enviarDeclaraciones();

    if (!res) {
      notificacion("danger", ERROR_DECLARACIONES);
      this.setState({ loadingData: false });
      return
    }

    notificacion("success", EXITO_DECLARACIONES);
    this.setState({ animacion: "formOut" });

    setTimeout(() => {
      this.props.wizard({ tab: 2, type: "tab" });
    }, 100);
    this.setState({ loadingData: false });
  };

  render() {
    return (
      <Fragment>
        <div
          id="declaracionActividadesDeportes"
          className="cuerpo-wizard pt-3 pb-5 o-white container"
        >
          <div className="animated fadeInRight" ref="formularioRef">
            <div className="row m-1 m-lg-4">
              {this.props.store.reducer.informacionPoliza.asegurable_5_50 ? (
                <h5 className="font-weight-bold text-primary">
                  1. Coberturas Complementarias de Salud
                </h5>
              ) : (
                this.state.declaracionesSeccion.length === 1 && (
                  <h5 className="font-weight-bold text-primary">
                    {this.state.declaracionesSeccion[0].glosasCoberturas
                      .map((item) => item.glosa)
                      .reduce((resultado, item) => [resultado, " - ", item])}
                  </h5>
                )
              )}
              {this.state.declaracionesSeccion.length === 1 &&
              this.props.store.reducer.objetoChecks !== undefined ? (
                <PreguntaComplementarias
                  informacion={this.state.declaracionesSeccion[0]}
                  indexCheck={0}
                  check={this.check}
                  checkAceptacion={this.checkAceptacion}
                />
              ) : (
                <PreguntasMultiples
                  declaracionesSeccion={this.state.declaracionesSeccion}
                  check={this.check}
                  checkAceptacion={this.checkAceptacion}
                />
              )}
            </div>
          </div>
        </div>
        <div className="button-zone text-right pt-3 pb-3">
          <button
            id="botonAtras"
            className="btn btn-principal color-secundario btn-lg"
            type="button"
            onClick={this.atras}
          >
            Atrás
          </button>

          <button
            id="botonSiguiente"
            className="btn btn-principal btn-lg"
            type="button"
            onClick={this.siguiente}
            disabled={this.state.loadingData}
          >
            {this.state.loadingData ? (
              <>
                <i className="fa fa-spinner fa-pulse fa-fw"></i>
                <span className="sr-only">Loading...</span>
              </>
            ) : (
              <span>Siguiente</span>
            )}
          </button>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state,
});

export default withRouter(
  connect(mapStateToProps, {
    wizard,
    add_detalle,
    update_detalle,
    guardar_data_asegurable,
    guardar_data_wizard_asegurado,
    update_carga,
    guardar_cheks_seccion,
  })(CoberturasComplemetarias)
);
