import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  componente_wizard_ejecutivo,
  guardar_giros,
  guardar_datos_empresa,
  guardar_datos_sucursal,
  guardar_datos_delegado,
} from "../../../../../../actions/actions";
import DatosEmpresa from "./DatosEmpresa";
import DatosSucursal from "./DatosSucursal";
import DatosDelegado from "./DatosDelegado";
import {
  obtenerConfiguracionDelegado,
  obtenerNegocio,
} from "./../../../../../../services/negocios.service";
import {
  PERFIL_LDAP,
  WIZARD_EMPRESA,
  WIZARD_SUCURSAL,
  WIZARD_DELEGADO,
  VALOR_ZERO,
  GA_CATEGORIA_NEGOCIOS,
  GA_LABEL_CONFIGURACION_NEGOCIO,
} from "../../../../../../utils/Constantes";
import { crearEventoTraza } from "../../../../../../utils/analitica";

const iconoEmpresa = require("../../../../../../app/assets/images/datos-empresa.svg");
const iconoDelegado = require("../../../../../../app/assets/images/delegate.svg");
const iconoSucursal = require("../../../../../../app/assets/images/sucursal.svg");

class ConfiguracionNegocio extends Component {
  constructor(props) {
    super(props);

    this.wizardChange = this.wizardChange.bind(this);
    this.actualizarNegocio = this.actualizarNegocio.bind(this);
    this.volver = this.volver.bind(this);

    this.state = {
      wizard: WIZARD_EMPRESA,
      cargando: true,
      idNegocio: null,
      negocio: null,
      sucursal: null,
      delegado: null,
    };
  }

  componentDidMount() {
    crearEventoTraza(GA_CATEGORIA_NEGOCIOS, GA_LABEL_CONFIGURACION_NEGOCIO);
  }

  async componentWillMount() {
    let idNegocio = JSON.parse(sessionStorage.getItem("_nNegVC"));
    if (!idNegocio) this.props.history.push("/negocios");

    this.setState({ idNegocio }, async () => {
      await this.obtenerDatosEmpresaSucursal();
      this.setState({ cargando: false });
    });
    this.props.guardar_datos_delegado(null);
  }

  /*************************
   *  PETICIONES SERVIDOR  *
   *************************/
  async obtenerDatosEmpresaSucursal() {
    let sucursal, datosEmpresa;
    const dataEnvio = {
      idNegocio: this.state.idNegocio,
      perfil: PERFIL_LDAP,
    };

    datosEmpresa = await obtenerNegocio(dataEnvio);

    const negocio = {
      idOrganizacionNoEnrolada: datosEmpresa.idOrganizacionNoEnrolada,
      idNegocio: this.state.idNegocio,
      idPersonaDelegado: datosEmpresa.idPersonaDelegado,
      rut: `${datosEmpresa.rutEmpresa}-${datosEmpresa.digitoVerificador}`,
      razonSocial: datosEmpresa.razonSocial,
      giro: datosEmpresa.giro ? datosEmpresa.giro : { idGiro: "", glosa: "" },
    };
    if (datosEmpresa.sucursales.length > VALOR_ZERO) {
      sucursal = datosEmpresa.sucursales[0];
      this.setState({ sucursal }, async () => {
        await this.obtenerDatosDelegado();
      });
    } else {
      this.obtenerDelegadoSinSucursal();
    }

    this.setState({ negocio });

    this.props.guardar_datos_empresa(negocio);
    this.props.guardar_datos_sucursal(sucursal);
  }

  async obtenerDatosDelegado() {
    const { reducer } = this.props.store;
    const idSucursal = this.state.sucursal.idOrganizacionSucursal;
    const { datosDelegado } = reducer.ejecutivo.configuracionNegocio;
    const { ultimaCotizacion } = reducer.negocio;

    if (datosDelegado) {
      this.setState({ delegado: datosDelegado });
    } else if (idSucursal) {
      let delegado = await obtenerConfiguracionDelegado(
        idSucursal,
        PERFIL_LDAP
      );
      if (delegado && delegado.persona) {
        delegado = {
          idPersonaDelegado: delegado.persona.idPersona,
          idOrganizacionSucursal: delegado.idOrganizacionSucursal,
          idOrganizacionSucursalContacto:
            delegado.idOrganizacionSucursalContacto,
          rut: delegado.persona.identificador,
          nombre: delegado.persona.nombre,
          apellidoMaterno: delegado.persona.apellidoMaterno,
          apellidoPaterno: delegado.persona.apellidoPaterno,
          sexo: delegado.persona.genero,
          fechaNacimiento: delegado.persona.fechaNacimiento,
          telefono: delegado.telefono,
          email: delegado.email,
        };
      } else {
        delegado = {
          idPersonaDelegado: 0,
          idOrganizacionSucursal: delegado.idOrganizacionSucursal,
          idOrganizacionSucursalContacto: 0,
          rut: ultimaCotizacion.rutDelegado,
          nombre: ultimaCotizacion.nombre,
          apellidoMaterno: ultimaCotizacion.apellidoMaterno,
          apellidoPaterno: ultimaCotizacion.apellidoPaterno,
          fechaNacimiento: null,
          sexo: null,
          telefono: ultimaCotizacion.telefono,
          email: ultimaCotizacion.email,
        };
      }
      this.setState({ delegado });
      this.props.guardar_datos_delegado(delegado);
    }
  }

  obtenerDelegadoSinSucursal() {
    const { reducer } = this.props.store;
    const { ultimaCotizacion } = reducer.negocio;
    let delegado = {
      idPersonaDelegado: 0,
      idOrganizacionSucursal: 0,
      idOrganizacionSucursalContacto: 0,
      rut: ultimaCotizacion.rutDelegado,
      nombre: ultimaCotizacion.nombre,
      apellidoMaterno: ultimaCotizacion.apellidoMaterno,
      apellidoPaterno: ultimaCotizacion.apellidoPaterno,
      fechaNacimiento: null,
      sexo: null,
      telefono: ultimaCotizacion.telefono,
      email: ultimaCotizacion.email,
    };
    this.setState({ delegado });
  }

  actualizarNegocio(negocio) {
    this.setState({ negocio });
  }

  wizardChange(wizard) {
    if (wizard === WIZARD_DELEGADO) {
      this.obtenerDatosEmpresaSucursal();
    }

    if (wizard > WIZARD_DELEGADO) this.volver(true);
    else this.setState({ wizard: wizard });
  }

  volver(advertencia = false) {
    if (advertencia) return;
    sessionStorage.removeItem("_nRuVC");
    sessionStorage.setItem("_nRuVC", "cotizaciones");
    this.props.history.push("/cotizaciones/inicio");
  }

  render() {
    let mostrarComponente;

    if (this.state.wizard === WIZARD_EMPRESA && this.state.negocio) {
      mostrarComponente = (
        <DatosEmpresa
          wizard={this.state.wizard}
          wizardChange={this.wizardChange}
          actualizarNegocio={this.actualizarNegocio}
          negocio={this.state.negocio}
        />
      );
    } else if (this.state.wizard === WIZARD_SUCURSAL && this.state.negocio) {
      mostrarComponente = (
        <DatosSucursal
          wizard={this.state.wizard}
          wizardChange={this.wizardChange}
          sucursal={this.state.sucursal}
          negocio={this.state.negocio}
        />
      );
    } else if (this.state.wizard === WIZARD_DELEGADO && this.state.sucursal) {
      mostrarComponente = (
        <DatosDelegado
          wizard={this.state.wizard}
          wizardChange={this.wizardChange}
          delegado={this.state.delegado}
          sucursal={this.state.sucursal}
          volver={this.volver}
        />
      );
    }

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-7 text-muted">
            <h4 className="font-weight-bold mb-0">
              {this.state.wizard === WIZARD_EMPRESA
                ? "Datos del negocio"
                : this.state.wizard === WIZARD_SUCURSAL
                ? "Datos de la sucursal"
                : this.state.wizard === WIZARD_DELEGADO && "Datos del delegado"}
            </h4>
          </div>

          <div className="col-md-5">
            <button
              className="btn btn-principal btn-sm d-block ml-auto"
              onClick={() => this.volver()}
            >
              <i className="fa fa-chevron-left mr-2" aria-hidden="true"></i>{" "}
              Volver al listado de cotización
            </button>
          </div>
        </div>

        <div className="card shadow my-3">
          <div className="row m-5 justify-content-center">
            <div
              className={`icono-dot ${
                this.state.wizard === WIZARD_EMPRESA && "clicked"
              } mr-5 text-center`}
              onClick={() => this.setState({ wizard: WIZARD_EMPRESA })}
            >
              <div className="dot">
                <img
                  className="d-block mx-auto mt-2"
                  alt="img-icon"
                  width="45px"
                  src={iconoEmpresa}
                />
              </div>
              <div className="text">Empresa</div>
            </div>

            <div
              className={`icono-dot ${
                this.state.wizard === WIZARD_SUCURSAL && "clicked"
              } mr-5 ml-2 text-center`}
              onClick={() => {
                const {
                  datosEmpresa,
                } = this.props.store.reducer.ejecutivo.configuracionNegocio;
                const datosIncompletos = Object.values(datosEmpresa).every(
                  (value) =>
                    value === undefined ||
                    value === null ||
                    value === "" ||
                    value === {} ||
                    value === [] ||
                    value === 0
                );

                if (datosIncompletos) {
                  return;
                } else {
                  if (this.state.sucursal)
                    return this.setState({ wizard: WIZARD_SUCURSAL });
                }
              }}
            >
              <div className="dot">
                <img
                  className="d-block mx-auto mt-2"
                  alt="img-icon"
                  width="45px"
                  src={iconoSucursal}
                />
              </div>
              <div className="text">Sucursal</div>
            </div>

            <div
              className={`icono-dot ${
                this.state.wizard === WIZARD_DELEGADO && "clicked"
              } ml-2 text-center`}
              onClick={() => {
                const {
                  datosEmpresa,
                  datosSucursal,
                } = this.props.store.reducer.ejecutivo.configuracionNegocio;
                let datos = [datosEmpresa, datosSucursal];

                datos = datos.filter((dato) => {
                  return dato !== undefined;
                });

                const datosIncompletos = datos.map((item) =>
                  Object.values(item).every(
                    (value) =>
                      value === undefined ||
                      value === null ||
                      value === "" ||
                      value === {} ||
                      value === [] ||
                      value === 0
                  )
                );

                if (datosIncompletos.includes(true)) {
                  return;
                } else {
                  if (this.state.sucursal)
                    this.setState({ wizard: WIZARD_DELEGADO });
                }
              }}
            >
              <div className="dot">
                <img
                  className="d-block mx-auto mt-2"
                  alt="img-icon"
                  width="40px"
                  src={iconoDelegado}
                />
              </div>
              <div className="text">Delegado</div>
            </div>
          </div>

          {this.state.cargando ? (
            <div className="d-flex justify-content-center animated fadeIn my-5">
              <div className="spinner-border text-primary mb-5" role="status">
                <span className="sr-only">Cargando...</span>
              </div>
            </div>
          ) : (
            mostrarComponente
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state,
});

export default withRouter(
  connect(mapStateToProps, {
    componente_wizard_ejecutivo,
    guardar_giros,
    guardar_datos_empresa,
    guardar_datos_sucursal,
    guardar_datos_delegado,
  })(ConfiguracionNegocio)
);
