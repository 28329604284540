import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Spinner from "../../../transversales/Spinner";
import Negocio from "./Negocio";
import NoData from "../../../transversales/NoData";
import { mostrar_spinner } from "../../../../../actions/actions";
import { VALOR_ZERO,
         T_NEGOCIO_INACTIVO 
} from "../../../../../utils/Constantes";

class TabNegocios extends Component {
  obtenerGlosaCanalVenta(idCanalVenta) {
    const { canalesVenta } = this.props.store.reducer;
    if (canalesVenta.length > VALOR_ZERO) {
      return canalesVenta.find((e) => e.idCanalVenta === idCanalVenta).glosa;
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.props.store.reducer.cargandoSpinnerTransparente ? (
          <div
            style={{ height: this.props.store.reducer.DOM.height - 150 + "px" }}
          >
            <Spinner
              claseColor="transparente"
              idSpinner="spinnerTransparente"
              posicionLogo="posicionLogo align-self-center w-100"
            />
          </div>
        ) : this.props.store.reducer.cargandoSpinnerNaranjo ? (
          <div
            style={{ height: this.props.store.reducer.DOM.height - 150 + "px" }}
          >
            <Spinner
              claseColor="naranjo"
              idSpinner="spinnerNaranjo"
              posicionLogo="align-self-center w-100"
            />
          </div>
        ) : (
          <div className="card-body">
            <div className="row">
              <div className="col-12">
                <h5 className="text-primary">
                  Canal de venta{" "}
                  <strong>
                    {this.obtenerGlosaCanalVenta(
                      this.props.store.ldap.canalVentaBusqueda
                    )}
                  </strong>
                </h5>
              </div>
              {this.props.cantNegociosFilt === 0 ? (
                <div className="col-12">
                  <NoData titulo={"No hay negocios disponibles"} />
                </div>
              ) : this.props.negocios.length > 0 ? (
                <React.Fragment>
                  {this.props.tipoNegocio !== T_NEGOCIO_INACTIVO
                    ? this.props.negocios
                        .sort((a, b) => (a.fechaCrea < b.fechaCrea ? 1 : -1))
                        .map((negocio, i) =>
                          negocio.estadoNegocio.idEstadoNegocio !== 5 &&
                          negocio.estadoNegocio.idEstadoNegocio !== 6 ? (
                            <Negocio
                              key={i}
                              negocio={negocio}
                              tipoNegocio={this.props.tipoNegocio}
                            />
                          ) : null
                        )
                    : this.props.negocios
                        .sort((a, b) =>
                          a.fechaFinEnrolamiento < b.fechaFinEnrolamiento
                            ? 1
                            : -1
                        )
                        .map((negocio, i) =>
                          negocio.estadoNegocio.idEstadoNegocio === 5 ||
                          negocio.estadoNegocio.idEstadoNegocio === 6 ? (
                            <Negocio
                              key={i}
                              negocio={negocio}
                              tipoNegocio={this.props.tipoNegocio}
                            />
                          ) : null
                        )}
                </React.Fragment>
              ) : (
                <NoData titulo={"No hay negocios disponibles"} />
              )}
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state,
});

export default withRouter(
  connect(mapStateToProps, {
    mostrar_spinner,
  })(TabNegocios)
);
