import React from "react";
import ReactDOM from "react-dom";
import { Modal, Container, Row, Col } from "react-bootstrap";
import ImgNuevoNegocio from "../../assets/images/nuevo-negocio.svg";
import ImgManencionNegocio from "../../assets/images/mantencion.svg";
import { 
	GA_CATEGORIA_LOGIN,
	GA_LABEL_INCORPORACION,
	TIPO_INCORPORACION_MANTENCION, 
	TIPO_INCORPORACION_NUEVO_NEGOCIO,
	VALOR_VACIO,
	VERDADERO
} from "../../../utils/Constantes";
import { useIncorporacion } from "../../../eventos/useIncorporacion";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import Spinner from "../transversales/Spinner";
import { useCargando } from "../../../eventos/useCargando";
import { crearEventoTraza } from "../../../utils/analitica";

export const ModalIncorporacion = ({blnMostrarModal, guardarSeleccionModal}) => {

	return ReactDOM.createPortal(
		<Modal
			show={blnMostrarModal}
			size="lg"
			centered
			className="incorporacion"
		>
			<Modal.Header>
				<Modal.Title className="modal-titulo">
				Seleccionar tipo de proceso
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Container>
					<Row>
						<Col xs={12} md={6}>
							<div className="modal-proceso-elemento" onClick={ () => { guardarSeleccionModal (TIPO_INCORPORACION_MANTENCION) } }>
								<img
									src={ImgManencionNegocio}
									width="140"
									className="img-fluid d-block mx-auto mb-2"
									alt="Movimientos póliza vigente"
								></img>
								<h5 className="text-center">
									Movimientos póliza vigente
								</h5>
							</div>
						</Col>
						<Col xs={12} md={6}>
							<div className="modal-proceso-elemento" onClick={ () => { guardarSeleccionModal (TIPO_INCORPORACION_NUEVO_NEGOCIO) } } > 
								<img
									src={ImgNuevoNegocio}
									className="img-fluid d-block mx-auto mb-2"
									alt="Ingreso nuevo negocio"
								></img>
								<h5 className="text-center">
									Ingreso nuevo negocio
								</h5>
							</div>
						</Col>
					</Row>
				</Container>
			</Modal.Body>
		</Modal>,
		document.querySelector(`#modalTipoIncorporacion`)
	)
}

const procesarIncorporacion = (intCodigoPerfil) => {

	let intTipoIncorporacion;

	switch(intCodigoPerfil){

		case Number(process.env.REACT_APP_COD_DELEGADO):
			intTipoIncorporacion = TIPO_INCORPORACION_NUEVO_NEGOCIO;
			break;

		case Number(process.env.REACT_APP_COD_DELEGADO_MANTENCION):
			intTipoIncorporacion = TIPO_INCORPORACION_MANTENCION;
			break;

		case Number(process.env.REACT_APP_COD_CORREDOR):
			intTipoIncorporacion = TIPO_INCORPORACION_MANTENCION;
			break;

		case Number(process.env.REACT_APP_COD_CORREDOR_COMERCIAL):
			intTipoIncorporacion = TIPO_INCORPORACION_NUEVO_NEGOCIO;
			break;

		default: 
			intTipoIncorporacion = 0
			break;
	}
	
	return intTipoIncorporacion;
}

export const EjecutarSeleccionIncorporacion = () => {
	
	const {mostrarOcultarCargando} = useCargando();
	const blnCargando = useSelector(store => store.reducer.cargando);
	const { mostrarModal, guardarSeleccionModal, actualizarMostrarModal }	= useIncorporacion();

	return (
		<>
			<button 
				className="btn btn-principal d-block ml-auto my-auto" 
				title="Selecciona tipo de proceso"
				onClick={ () => { mostrarOcultarCargando(VERDADERO); actualizarMostrarModal (VERDADERO) } } >
				<i aria-hidden="true" className="fa fa-exchange mr-2"></i>
				Seleccionar tipo de proceso
			</button>
			{
				blnCargando && (
					<Spinner
						claseColor="transparente"
						idSpinner="spinnerNaranjo"
						posicionLogo="align-self-center w-100"
					/>
				)
			}
			
			<ModalIncorporacion
				blnMostrarModal={mostrarModal}
				guardarSeleccionModal={guardarSeleccionModal}
			/>
		</>
  );
}

export const Incorporacion = () => {

	const blnEscogerIncorporacion = useSelector(store => store.auth.escogerIncorporacion);
	const objUsuarioSesion				= useSelector(store => store.auth.usuarioSesion);
	const intTipoIncorporacion		= useSelector(store => store.auth.tipoIncorporacion);

	const { mostrarModal, guardarIncorporacion, guardarSeleccionModal, actualizarMostrarModal }	= useIncorporacion();

	useEffect( () => {

		if (objUsuarioSesion && objUsuarioSesion.validarIncorporacion) {
			
			if (!blnEscogerIncorporacion) {
				guardarIncorporacion(procesarIncorporacion(objUsuarioSesion.perfil.codigo));
			}
			if (intTipoIncorporacion === VALOR_VACIO && blnEscogerIncorporacion){
				actualizarMostrarModal(blnEscogerIncorporacion);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[objUsuarioSesion]);

	useEffect(() => {
		if (mostrarModal) {
			crearEventoTraza(GA_CATEGORIA_LOGIN, GA_LABEL_INCORPORACION);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
  },[mostrarModal]);

	return (
		<ModalIncorporacion 
			blnMostrarModal={mostrarModal}
			guardarSeleccionModal={guardarSeleccionModal}
		/>
	)
}