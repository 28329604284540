import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CoberturasComplemetarias from "./coberturasComplementarias/CoberturasComplemetarias";
import CoberturasAmpliacion from "./coberturasAmpliacion/CoberturasAmpliacion";
import CoberturaActividadesDeportes from "./coberturaActividadesDeportes/CoberturaActividadesDeportes";
import DeclaracionSalud from "./declaracionSalud/DeclaracionSalud";
import "../../../../assets/css/tabs.css";
import {
  wizard,
  actualizar_progreso_asegurable
} from "../../../../../actions/actions";
import { INVALIDEZ, ACTIVIDAD_DEPORTE, DECLARACION_SALUD, VALOR_ZERO, PAGINA_3, VALOR_TRES, TIPO_TAB, PAGINA_2, PAGINA_1, } from "../../../../../utils/Constantes";

class Declaraciones extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagina: 1,
      idProductoCobertura: 0,
      incorporaCobertura: false,
      seccionComplementaria: "",
      seccionDeporte: "",
      seccionAmpliacion: "",
      seccionSalud: "",
      paginaDeclaraciones: null,
    };
  }

  componentDidMount() {
    const { declaraciones } = this.props.store.reducer;

    let secciones = declaraciones.map(
      declaracion => declaracion.seccion
    );

    const paginaDeclaraciones = PAGINA_3;

    this.setState({
      seccionComplementaria: secciones.filter(
        seccion => seccion.glosa === INVALIDEZ
      ),
      seccionDeporte: secciones.filter(
        seccion => seccion.glosa === ACTIVIDAD_DEPORTE
      ),
      seccionSalud: secciones.filter(
        seccion => seccion.glosa === DECLARACION_SALUD
      ),
      paginaDeclaraciones
    });
  }

  render() {
    const { wizard } = this.props.store.reducer;
    return (
      <div>
        <div className="card wizard">
          <div className="card-body">
            <div className="row">
              <div className="col-md-12">
                <nav>
                  <ul className="nav nav-tabs nav-fill o-white">
                    {this.state.seccionComplementaria.length > 0 ? (
                      <li
                        id="tabComplementarias"
                        className={
                          this.props.store.reducer.wizard.pagina === this.state.paginaDeclaraciones &&
                            this.props.store.reducer.wizard.tab === 1
                            ? "nav-item active"
                            : "nav-item"
                        }
                        style={{ width: '0' }}
                        onClick={() =>
                          this.props.wizard({ tab: 1, type: "tab" })
                        }
                      >
                        <span className="d-none d-sm-block">
                          Especial de Salud
                        </span>
                        <span
                          title="Complementarias"
                          className="d-block d-sm-none"
                        >
                          {this.props.store.reducer.wizard.pagina !== this.state.paginaDeclaraciones &&
                            this.props.store.reducer.wizard.tab !== 1 && (
                              <i
                                className="fa fa-address-book"
                                aria-hidden="true"
                              ></i>
                            )}
                          {this.props.store.reducer.wizard.pagina === this.state.paginaDeclaraciones &&
                            this.props.store.reducer.wizard.tab === 1 && (
                              <span>Complementarias</span>
                            )}
                        </span>
                      </li>
                    ) : null}

                    {this.state.seccionDeporte.length > 0 ? (
                      <li
                        id="tabActividades"
                        className={
                          this.props.store.reducer.wizard.pagina === this.state.paginaDeclaraciones &&
                            this.props.store.reducer.wizard.tab === 2
                            ? "nav-item active"
                            : "nav-item"
                        }
                        style={{ width: '0' }}
                        onClick={() =>
                          this.props.wizard({ tab: 2, type: "tab" })
                        }
                      >
                        <span className="d-none d-sm-block">
                          Actividades y Deportes
                        </span>
                        <span
                          title="Actividades y Deportes"
                          className="d-block d-sm-none"
                        >
                          {this.props.store.reducer.wizard.pagina !== this.state.paginaDeclaraciones &&
                            this.props.store.reducer.wizard.tab !== 2 && (
                              <i
                                className="fa fa-address-book"
                                aria-hidden="true"
                              ></i>
                            )}
                          {this.props.store.reducer.wizard.pagina === this.state.paginaDeclaraciones &&
                            this.props.store.reducer.wizard.tab === 2 && (
                              <span>Actividades y Deportes</span>
                            )}
                        </span>
                      </li>
                    ) : null}
                    {this.state.seccionAmpliacion.length > 0 ? (
                      <li
                        id="tabAmpliacion"
                        className={
                          this.props.store.reducer.wizard.pagina === this.state.paginaDeclaraciones &&
                            this.props.store.reducer.wizard.tab === 3
                            ? "nav-item active"
                            : "nav-item"
                        }
                        style={{ width: '0' }}
                        onClick={() =>
                          this.props.wizard({ tab: 3, type: "tab" })
                        }
                      >
                        <span className="d-none d-sm-block">Ampliación</span>
                        <span title="Ampliación" className="d-block d-sm-none">
                          {this.props.store.reducer.wizard.pagina !== this.state.paginaDeclaraciones &&
                            this.props.store.reducer.wizard.tab !== 3 && (
                              <i
                                className="fa fa-address-book"
                                aria-hidden="true"
                              ></i>
                            )}
                          {this.props.store.reducer.wizard.pagina === this.state.paginaDeclaraciones &&
                            this.props.store.reducer.wizard.tab === 3 && (
                              <span>Ampliación</span>
                            )}
                        </span>
                      </li>
                    ) : null}
                    {this.state.seccionSalud.length > VALOR_ZERO ? (
                      <li
                        id="tabSalud"
                        className={
                          this.props.store.reducer.wizard.pagina === this.state.paginaDeclaraciones &&
                            this.props.store.reducer.wizard.tab === PAGINA_3
                            ? "nav-item active"
                            : "nav-item"
                        }
                        style={{ width: '0' }}
                        onClick={() =>
                          this.props.wizard({ tab: VALOR_TRES, type: TIPO_TAB })
                        }
                      >
                        <span className="d-none d-sm-block">
                          Personal de Salud
                        </span>
                        <span
                          title="Declaración Salud"
                          className="d-block d-sm-none"
                        >
                          {this.props.store.reducer.wizard.pagina !== this.state.paginaDeclaraciones &&
                            this.props.store.reducer.wizard.tab !== VALOR_TRES && (
                              <i
                                className="fa fa-address-book"
                                aria-hidden="true"
                              ></i>
                            )}
                          {this.props.store.reducer.wizard.pagina === this.state.paginaDeclaraciones &&
                            this.props.store.reducer.wizard.tab === VALOR_TRES && (
                              <span>Declaración Salud</span>
                            )}
                        </span>
                      </li>
                    ) : null}
                  </ul>
                </nav>
              </div>
            </div>
          </div>

          {(this.state.seccionComplementaria.length > VALOR_ZERO &&
            wizard.pagina === this.state.paginaDeclaraciones
            && wizard.tab === PAGINA_1) && (
              <CoberturasComplemetarias />
            )}
          {this.state.seccionDeporte.length > VALOR_ZERO &&
            wizard.pagina === this.state.paginaDeclaraciones &&
            wizard.tab === PAGINA_2 && (
              <CoberturaActividadesDeportes />
            )}
          {this.state.seccionAmpliacion.length > VALOR_ZERO &&
            wizard.pagina === this.state.paginaDeclaraciones &&
            wizard.tab === VALOR_TRES && (
              <CoberturasAmpliacion />
            )}
          {this.state.seccionSalud.length > VALOR_ZERO &&
            wizard.pagina === this.state.paginaDeclaraciones &&
            wizard.tab === VALOR_TRES && <DeclaracionSalud />}
        </div>
      </div >
    );
  }
}

const mapStateToProps = state => ({
  store: state
});

export default withRouter(
  connect(
    mapStateToProps,
    { wizard, actualizar_progreso_asegurable }
  )(Declaraciones)
);
