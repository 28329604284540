import React, { Component } from "react";
import { connect } from "react-redux";
import FiltrosAsegurables from "./FiltrosAsegurables";
import { Dropdown } from "react-bootstrap";
import DatePicker from "react-datepicker";
import moment from "moment";
import MaskedTextInput from "react-text-mask";
import Table from "../../../transversales/DataTable";
import { withRouter } from "react-router-dom";
import ReactExport from "react-export-excel";
import {
  MASK_DATE,
  ESTADOS_ENROLAMIENTO,
  VALOR_ZERO,
  COD_DELEGADO,
  PERFIL_DELEGADO,
  COD_EJECUTIVO_MANTENCION,
  COD_EJECUTIVO_COMPLETO,
  PERFIL_LDAP,
  PERFIL_CORREDOR,
  VALOR_DOS,
  VALOR_UNO,
  RUT_DELEGADO,
  RUT_EJECUTIVO,
  USU_CORREDOR,
  SIGNO_GUION,
  POSICION_INICIAL,
  FORMATO_FECHA_DDMMYYYY,
  NO_APLICA,
  VALOR_VACIO_GUION,
  ESTADO_ENROLAMIENTO,
  ENVIO_ACCESO,
  ERROR_LISTAR_MIS_SOLICITUDES,
} from "../../../../../utils/Constantes";
import Spinner from "../../../transversales/Spinner";
import { listarDetallePorTipoIncorporacion } from "../../../../../services/asegurable.services";
import { limpiar_filtro_solicitudes } from "../../../../../actions/actions";
import { notificacion } from "../../../transversales/Notificaciones";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

class Incorporacion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      exportarExcel: false,
      listaAsegurableCarga: [],
      filtroExcel: [],
      polizaFiltro: [],
      solicitud: [],
      dataExport: [],
      cargando: false,
      FechaModificacionSolicitud: this.props.FechaModificacionSolicitud,
      asegurablesBusqueda: []
    };
  }

  componentDidMount() {
    if (this.props.FechaModificacionSolicitud) {
      this.setState({
        asegurablesBusqueda: this.props.asegurablesBusqueda.filter(asegurable =>
          asegurable.grupos[POSICION_INICIAL].fechaCrea >= this.props.FechaModificacionSolicitud || asegurable.solicitud.fechaModifica >= this.props.FechaModificacionSolicitud
        )
      })
    }
    else this.setState({ asegurablesBusqueda: this.props.asegurablesBusqueda })
  }

  componentDidUpdate(prevProps) {
    if (this.props.asegurablesBusqueda !== prevProps.asegurablesBusqueda) {
      this.setState({ asegurablesBusqueda: this.props.asegurablesBusqueda });
    }
  }

  exportarData = async () => {
    this.setState({
      cargando: true,
    });
    const { usuarioSesion } = this.props.store.auth;
    const campoPerfil =
      usuarioSesion.rol.codigo === COD_DELEGADO
        ? RUT_DELEGADO
        : usuarioSesion.rol.codigo === COD_EJECUTIVO_MANTENCION ||
          usuarioSesion.rol.codigo === COD_EJECUTIVO_COMPLETO
          ? RUT_EJECUTIVO
          : USU_CORREDOR;
    const dataArmada = {
      rutEjecutivo: usuarioSesion.rut,
      rutContratante: this.props.rutContratante.split(SIGNO_GUION)[VALOR_ZERO],
      idTipoIncorporacion: VALOR_DOS,
      fechaEnvioDesde: this.props.fechasFiltro.fechaDesde,
      fechaEnvioHasta: this.props.fechasFiltro.fechaHasta,
      polizaVigente: this.props.solicitudActiva === "activos",
      perfil:
        usuarioSesion.rol.codigo === COD_DELEGADO
          ? PERFIL_DELEGADO
          : usuarioSesion.rol.codigo === COD_EJECUTIVO_MANTENCION ||
            usuarioSesion.rol.codigo === COD_EJECUTIVO_COMPLETO
            ? PERFIL_LDAP
            : PERFIL_CORREDOR,
      polizaFiltro: this.state.polizaFiltro,
      solicitud: this.state.solicitud,
      [campoPerfil]:
        usuarioSesion.rol.codigo === COD_DELEGADO ||
          usuarioSesion.rol.codigo === COD_EJECUTIVO_MANTENCION ||
          usuarioSesion.rol.codigo === COD_EJECUTIVO_COMPLETO
          ? usuarioSesion.rut
          : usuarioSesion.id,
    };
    const listaAsegurableCarga = await listarDetallePorTipoIncorporacion(dataArmada);

    if (!listaAsegurableCarga) {
      notificacion("danger", ERROR_LISTAR_MIS_SOLICITUDES);
    }

    if (this.props.store.reducer.filtroSolicitudes && this.props.store.reducer.filtroSolicitudes.FechaModificacionSolicitud) {
      this.setState({
        listaAsegurableCarga: listaAsegurableCarga.filter(asegurable =>
          asegurable.solicitud.fechaModifica >= this.state.FechaModificacionSolicitud || asegurable.fechaEnvioCorreo >= this.state.FechaModificacionSolicitud
        )
      });
    } else this.setState({ listaAsegurableCarga });
    //ARMAR DATA
    let dataAsegurable = [];
    let bandera = VALOR_ZERO;
    this.state.listaAsegurableCarga &&
      this.state.listaAsegurableCarga.forEach((asegurable) => {
        if (bandera === VALOR_ZERO) {
          let data = [
            {
              value: asegurable.fechaEnvioCorreo
                ? moment(asegurable.fechaEnvioCorreo).format("DD/MM/YYYY")
                : "-",
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "E89B03" } },
                font: { color: { rgb: "FFFFFF" } },
                alignment: { horizontal: "center" },
              },
              border: {
                bottom: { style: "thin", color: { rgb: "000000" } },
              },
            },
            {
              value:
                asegurable.solicitud.aseguradoPoliza.poliza.nroPoliza +
                "-" +
                asegurable.solicitud.aseguradoPoliza.poliza.nroRenovacion,
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "E89B03" } },
                font: { color: { rgb: "FFFFFF" } },
                alignment: { horizontal: "center" },
              },
            },
            {
              value: asegurable.solicitud.aseguradoPoliza.poliza.tipoPoliza
                .glosa
                ? asegurable.solicitud.aseguradoPoliza.poliza.tipoPoliza.glosa
                : "-",
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "E89B03" } },
                font: { color: { rgb: "FFFFFF" } },
                alignment: { horizontal: "center" },
              },
            },
            {
              value:
                asegurable.rut && asegurable.digitoVerificador
                  ? asegurable.rut + "-" + asegurable.digitoVerificador
                  : "-",
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "E89B03" } },
                font: { color: { rgb: "FFFFFF" } },
                alignment: { horizontal: "center" },
              },
            },
            {
              value:
                asegurable.nombre +
                " " +
                asegurable.apellidoPaterno +
                " " +
                asegurable.apellidoMaterno,
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "E89B03" } },
                font: { color: { rgb: "FFFFFF" } },
                alignment: { horizontal: "left" },
              },
            },
            {
              value: asegurable.telefonoMovil
                ? asegurable.telefonoMovil
                : "N/A",
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "E89B03" } },
                font: { color: { rgb: "FFFFFF" } },
                alignment: { horizontal: "center" },
              },
            },
            {
              value: asegurable.cargas[VALOR_ZERO]
                ? asegurable.cargas[VALOR_ZERO].rut +
                "-" +
                asegurable.cargas[VALOR_ZERO].digitoVerificador
                : "N/A",
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "E89B03" } },
                font: { color: { rgb: "FFFFFF" } },
                alignment: { horizontal: "center" },
              },
            },
            {
              value: asegurable.cargas[VALOR_ZERO]
                ? asegurable.cargas[VALOR_ZERO].nombre +
                " " +
                asegurable.cargas[VALOR_ZERO].apellidoPaterno +
                " " +
                asegurable.cargas[VALOR_ZERO].apellidoMaterno
                : "N/A",
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "E89B03" } },
                font: { color: { rgb: "FFFFFF" } },
                alignment: { horizontal: "left" },
              },
            },
            {
              value: asegurable.parentesco ? asegurable.parentesco.glosa : "-",
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "E89B03" } },
                font: { color: { rgb: "FFFFFF" } },
                alignment: { horizontal: "center" },
              },
            },
            ...(usuarioSesion.rol.codigo === COD_EJECUTIVO_MANTENCION ||
              usuarioSesion.rol.codigo === COD_EJECUTIVO_COMPLETO
              ? [
                {
                  value: asegurable.fechaNacimiento
                    ? moment(asegurable.fechaNacimiento).format("DD-MM-YYYY")
                    : "N/A",
                  style: {
                    fill: {
                      patternType: "solid",
                      fgColor: { rgb: "E89B03" },
                    },
                    font: { color: { rgb: "FFFFFF" } },
                    alignment: { horizontal: "center" },
                  },
                },
              ]
              : []),

            {
              value: asegurable.declaraPreexistencia ? "SI" : "NO",
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "E89B03" } },
                font: { color: { rgb: "FFFFFF" } },
                alignment: { horizontal: "center" },
              },
            },
            {
              value: asegurable.declaraDeporte ? "SI" : "NO",
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "E89B03" } },
                font: { color: { rgb: "FFFFFF" } },
                alignment: { horizontal: "center" },
              },
            },
            {
              value: asegurable.estadoSolicitudIncorporacion
                ? asegurable.estadoSolicitudIncorporacion
                : "-",
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "E89B03" } },
                font: { color: { rgb: "FFFFFF" } },
                alignment: { horizontal: "center" },
              },
            },
            {
              value: asegurable.solicitud.fechaRecepcion
                ? moment(asegurable.solicitud.fechaRecepcion).format(
                  FORMATO_FECHA_DDMMYYYY
                )
                : VALOR_VACIO_GUION,
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "E89B03" } },
                font: { color: { rgb: "FFFFFF" } },
                alignment: { horizontal: "center" },
              },
            },
            {
              value: asegurable.solicitud.solicitudEstado.glosa
                ? asegurable.solicitud.solicitudEstado.glosa
                : NO_APLICA,
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "E89B03" } },
                font: { color: { rgb: "FFFFFF" } },
                alignment: { horizontal: "center" },
              },
            },
            {
              value: asegurable.tieneEvaluacion ? "SI" : "NO",
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "E89B03" } },
                font: { color: { rgb: "FFFFFF" } },
                alignment: { horizontal: "center" },
              },
            },
            {
              value: asegurable.incorporado ? "SI" : "NO",
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "E89B03" } },
                font: { color: { rgb: "FFFFFF" } },
                alignment: { horizontal: "center" },
              },
            },
            {
              value: asegurable.observacion,
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "E89B03" } },
                font: { color: { rgb: "FFFFFF" } },
                alignment: { horizontal: "left" },
              },
            },
          ];
          bandera = VALOR_UNO;
          dataAsegurable.push(data);
        } else {
          let data = [
            {
              value: asegurable.fechaEnvioCorreo
                ? moment(asegurable.fechaEnvioCorreo).format("DD/MM/YYYY")
                : "-",
              style: { alignment: { horizontal: "center" } },
            },
            {
              value:
                asegurable.solicitud.aseguradoPoliza.poliza.nroPoliza +
                "-" +
                asegurable.solicitud.aseguradoPoliza.poliza.nroRenovacion,
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: asegurable.solicitud.aseguradoPoliza.poliza.tipoPoliza
                .glosa
                ? asegurable.solicitud.aseguradoPoliza.poliza.tipoPoliza.glosa
                : "-",
              style: { alignment: { horizontal: "center" } },
            },
            {
              value:
                asegurable.rut && asegurable.digitoVerificador
                  ? asegurable.rut + "-" + asegurable.digitoVerificador
                  : "-",
              style: { alignment: { horizontal: "center" } },
            },
            {
              value:
                asegurable.nombre +
                " " +
                asegurable.apellidoPaterno +
                " " +
                asegurable.apellidoMaterno,
              style: { alignment: { horizontal: "left" } },
            },
            {
              value: asegurable.telefonoMovil
                ? asegurable.telefonoMovil
                : "N/A",
              style: {
                alignment: { horizontal: "center" },
              },
            },
            {
              value: asegurable.cargas[VALOR_ZERO]
                ? asegurable.cargas[VALOR_ZERO].rut +
                "-" +
                asegurable.cargas[VALOR_ZERO].digitoVerificador
                : "N/A",
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: asegurable.cargas[VALOR_ZERO]
                ? asegurable.cargas[VALOR_ZERO].nombre +
                " " +
                asegurable.cargas[VALOR_ZERO].apellidoPaterno +
                " " +
                asegurable.cargas[VALOR_ZERO].apellidoMaterno
                : "N/A",
              style: { alignment: { horizontal: "left" } },
            },
            {
              value: asegurable.parentesco ? asegurable.parentesco.glosa : "-",
              style: { alignment: { horizontal: "center" } },
            },
            ...(usuarioSesion.rol.codigo === COD_EJECUTIVO_MANTENCION ||
              usuarioSesion.rol.codigo === COD_EJECUTIVO_COMPLETO
              ? [
                {
                  value: asegurable.fechaNacimiento
                    ? moment(asegurable.fechaNacimiento).format("DD-MM-YYYY")
                    : "N/A",
                  style: { alignment: { horizontal: "center" } },
                },
              ]
              : []),
            {
              value: asegurable.declaraPreexistencia ? "SI" : "NO",
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: asegurable.declaraDeporte ? "SI" : "NO",
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: asegurable.estadoSolicitudIncorporacion
                ? asegurable.estadoSolicitudIncorporacion
                : VALOR_VACIO_GUION,
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: asegurable.solicitud.fechaRecepcion
                ? moment(asegurable.solicitud.fechaRecepcion).format(
                  "DD/MM/YYYY"
                )
                : "-",
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: asegurable.solicitud.solicitudEstado.glosa
                ? asegurable.solicitud.solicitudEstado.glosa
                : "N/A",
              style: {
                alignment: { horizontal: "center" },
              },
            },
            {
              value: asegurable.tieneEvaluacion ? "SI" : "NO",
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: asegurable.incorporado ? "SI" : "NO",
              style: { alignment: { horizontal: "center" } },
            },
            {
              value: asegurable.observacion,
              style: { alignment: { horizontal: "left" } },
            },
          ];
          bandera = VALOR_ZERO;
          dataAsegurable.push(data);
        }
      });

    let dataAsegurableExcel = [
      {
        columns: [
          ENVIO_ACCESO,
          "Póliza",
          "Tipo Póliza",
          "Rut Titular",
          "Nombre Titular",
          "Teléfono Móvil",
          "Rut Asegurable",
          "Nombre Asegurable",
          "Parentesco",
          ...(usuarioSesion.rol.codigo === COD_EJECUTIVO_MANTENCION ||
            usuarioSesion.rol.codigo === COD_EJECUTIVO_COMPLETO
            ? ["Fecha Nacimiento"]
            : []),
          "Declara Preexistencia",
          "Declara Actividad o Deporte Riesgoso",
          ESTADO_ENROLAMIENTO,
          "Envío Solicitud",
          "Estado de Solicitud",
          "Evaluación Suscripción",
          "Incorporado",
          "Observación",
        ],
        data: dataAsegurable,
      },
    ];

    if (this.state.listaAsegurableCarga) {
      this.setState({
        exportarExcel: true,
        dataExport: dataAsegurableExcel,
      });
    }
    this.setState({
      exportarExcel: false,
      listaAsegurableCarga: [],
      cargando: false,
      dataExport: [],
    });
  };

  filtrosSeleccionados = (filtros) => {
    let filtroTemporalPoliza = [];
    let filtroTemporalEstadoSolicitud = [];
    filtros.forEach((filtro) => {
      if (filtro.tipoFiltro === VALOR_UNO) {
        let filtroPoliza = filtro.filtro.split(" ");
        let filtroAuxiliar = {
          nroPoliza: filtroPoliza[VALOR_ZERO].split(SIGNO_GUION)[VALOR_ZERO],
          nroRenovacion: filtroPoliza[VALOR_ZERO].split(SIGNO_GUION)[VALOR_UNO],
        };
        filtroTemporalPoliza.push(filtroAuxiliar);
      }
      if (filtro.tipoFiltro === VALOR_DOS) {
        let filtroAuxiliar = {
          solicitudEstado: {
            glosa: filtro.filtro,
          },
        };
        filtroTemporalEstadoSolicitud.push(filtroAuxiliar);
      }
    });
    this.setState({
      polizaFiltro: filtroTemporalPoliza,
      solicitud: filtroTemporalEstadoSolicitud,
    });
  };

  limpiarFiltroFechaMod = () => {
    this.props.limpiar_filtro_solicitudes();
    this.setState({ asegurablesBusqueda: this.props.asegurablesBusqueda });
  }

  render() {
    return (
      <div className="col-12">
        {this.props.rutContratante ? (
          <div className="row animated fadeIn">
            <div className="col-12 px-5 pb-5">
              <div className="row">
                {/* Filtro */}
                <div className="col-sm-7">
                  <FiltrosAsegurables
                    filtrosSeleccionados={this.filtrosSeleccionados}
                    rutContratante={this.props.rutContratante}
                    dataAsegurado={this.props.asegurables}
                    asegurablesBusqueda={this.state.asegurablesBusqueda}
                    handleSearch={this.props.handleSearch}
                    estadosEnrolamiento={ESTADOS_ENROLAMIENTO}
                    polizas={this.props.polizasFiltro}
                    solicitudes={true}
                    limpiarFiltroFechaMod={this.limpiarFiltroFechaMod}
                  ></FiltrosAsegurables>
                </div>
                <div className="col-sm-3 pt-2">
                  <Dropdown>
                    <Dropdown.Toggle
                      disabled={this.props.rutContratante === VALOR_ZERO}
                      variant="dark"
                      bsPrefix="py-2 btn-dark btn-block rounded"
                      id="dropdown-basic"
                    >
                      Filtrar fecha
                      <i
                        className="fa fa-chevron-down ml-2"
                        aria-hidden="true"
                      ></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="w-100">
                      <div className="form-group up-calendar m-3">
                        <label>Fecha envío asegurable desde</label>
                        <DatePicker
                          autoComplete="off"
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          popperPlacement="bottom-start"
                          dropdownMode="select"
                          id="fechaDesde"
                          name="fechaDesde"
                          selected={this.props.fechasFiltro.fechaDesde}
                          value={moment(
                            this.props.fechasFiltro.fechaDesde
                          ).format("DD/MM/YYYY")}
                          dateFormat="dd/MM/yyyy"
                          placeholderText="DD/MM/YYYY"
                          locale="es"
                          onChange={(value) => {
                            this.props.setFechasFiltro({
                              ...this.props.fechasFiltro,
                              fechaDesde: value,
                            });
                          }}
                          maxDate={this.props.fechasFiltro.fechaHasta}
                          customInput={
                            <MaskedTextInput
                              className="form-control"
                              placeholder="DD/MM/YYYY"
                              mask={MASK_DATE}
                            />
                          }
                        />
                      </div>
                      <div className="form-group up-calendar m-3">
                        <label>Fecha envío asegurable hasta</label>
                        <DatePicker
                          autoComplete="off"
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          popperPlacement="bottom-start"
                          dropdownMode="select"
                          id="fechaHasta"
                          name="fechaHasta"
                          selected={this.props.fechasFiltro.fechaHasta}
                          value={moment(
                            this.props.fechasFiltro.fechaHasta
                          ).format("DD/MM/YYYY")}
                          dateFormat="dd/MM/yyyy"
                          placeholderText="DD/MM/YYYY"
                          locale="es"
                          onChange={(value) => {
                            if (value !== null) {
                              value.setHours(23, 59, 59);
                            }

                            this.props.setFechasFiltro({
                              ...this.props.fechasFiltro,
                              fechaHasta: value,
                            });
                          }}
                          minDate={this.props.fechasFiltro.fechaDesde}
                          customInput={
                            <MaskedTextInput
                              className="form-control"
                              placeholder="DD/MM/YYYY"
                              mask={MASK_DATE}
                            />
                          }
                        />
                      </div>
                      <div className="button-zone text-right pt-3 pb-3">
                        <Dropdown.Item
                          className="btn btn-principal w-25 mr-3 text-center"
                          type="button"
                          onClick={() => {
                            this.props.limpiar_filtro_solicitudes();
                            this.props.listarSolicitudes();
                          }}
                        >
                          Buscar
                        </Dropdown.Item>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="col-sm-2 pt-2">
                  <i
                    className="fa fa-file-excel-o exportarExcelAsegurable"
                    style={{ color: "orange", fontSize: "20px", marginTop: 10 }}
                    aria-hidden="true"
                    onClick={async () => await this.exportarData()}
                  >
                    {" "}
                    Exportar
                  </i>

                  {this.state.exportarExcel && (
                    <div>
                      <ExcelFile
                        filename="listarAsegurableCarga"
                        hideElement="true"
                      >
                        <ExcelSheet
                          dataSet={this.state.dataExport}
                          name="Organization"
                        />
                      </ExcelFile>
                    </div>
                  )}
                </div>
              </div>
              <div className="shadow">
                <Table
                  datos={this.state.asegurablesBusqueda}
                  columnas={this.props.columnDefs}
                  tema={this.props.temaTabla}
                  paginacion={true}
                  paginacionPorPagina={10}
                  paginacionOpciones={{
                    rowsPerPageText: "Asegurables por página:",
                    rangeSeparatorText: "de",
                    noRowsPerPage: true,
                  }}
                />
              </div>
            </div>
            {this.state.cargando ? (
              <Spinner
                claseColor="transparente"
                idSpinner="spinnerTransparente"
                posicionLogo="posicionLogo align-self-center w-100"
              />
            ) : null}
          </div>
        ) : (
          <div></div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  store: state,
});

export default withRouter(connect(mapStateToProps, { limpiar_filtro_solicitudes })(Incorporacion));
