import moment from "moment";
import ServicesFactory from "../config/servicesFactory";
import {
  COD_STATUS_EXITO,
  STATUS_EXITO,
  COD_STATUS_NA,
  ID_TRES,
  ID_CUATRO,
  PARENTESCO_TITULAR,
  NOTIFICACION_PELIGRO,
  ERROR_ASEGURABILIDAD_ENDPOINT,
} from "../utils/Constantes";
import { validarRequisitosAsegurabilidadCotizacion } from "./cotizacion.services";
import { validarEstados } from "./transversales.service";
import { notificacion } from "../app/components/transversales/Notificaciones";

let services = new ServicesFactory();

/*************************
 *  ASEGURABLE  *
 *************************/
export async function buscarAsegurablePorRut(rut) {
  try {
    let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_URL}/asegurable/listarAsegurablePorRut/${rut}`;
    let res = await services.get(url, false);

    return res !== undefined &&
      res !== null &&
      res.status === STATUS_EXITO &&
      res.data.status.codigo === COD_STATUS_EXITO
      ? res.data.objeto
      : null;
  } catch (error) {
    return null;
  }
}

export async function crearSolicitud(dataEnvio) {
  try {
    let res = await services.post(
      `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/asegurable/crearSolicitud/`,
      dataEnvio,
      false
    );
    return res !== undefined &&
      res !== null &&
      res.status === STATUS_EXITO &&
      res.data.status.codigo === COD_STATUS_EXITO
      ? res.data.objeto
      : null;
  } catch (error) {
    return null;
  }
}

export async function finalizarSolicitud(data) {
  try {
    let respuesta = await services.put(
      `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/solicitud/actualizarEstado/`,
      data,
      false
    );
    return respuesta !== undefined &&
      respuesta !== null &&
      respuesta.status === STATUS_EXITO &&
      respuesta.data.status.codigo === COD_STATUS_EXITO
      ? respuesta.data.objeto
      : null;
  } catch (error) {
    return null;
  }
}

export async function obtenerDataWizard(idSolicitud) {
  try {
    let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/flujoEnrolamiento/buscarPorIdSolicitud/${idSolicitud}`;
    let res = await services.get(url, false);

    return res !== undefined &&
      res !== null &&
      res.status === STATUS_EXITO &&
      res.data.status.codigo === COD_STATUS_EXITO
      ? res.data.objeto
      : null;
  } catch (error) {
    return null;
  }
}

export async function guardarDataWizard(data) {
  delete data._id;
  try {
    let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/flujoEnrolamiento/guardarEtapa`;
    let res = await services.post(url, data, false);

    return res !== undefined &&
      res !== null &&
      res.status === STATUS_EXITO &&
      res.data.status.codigo === COD_STATUS_EXITO
      ? res.data.objeto
      : null;
  } catch (error) {
    return null;
  }
}

export async function eliminarDataWizardMongo(idSolicitud) {
  try {
    let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/flujoEnrolamiento/eliminarPorIdSolicitud/${idSolicitud}`;
    let res = await services.put(url, null, false);

    return res !== undefined &&
      res !== null &&
      res.status === STATUS_EXITO &&
      res.data.status.codigo === COD_STATUS_EXITO
      ? res.data.objeto
      : null;
  } catch (error) {
    return null;
  }
}

export async function obtenerPreguntasDeclaraciones(
  idSubProdCondCobertura,
  codCotizacion
) {
  try {
    let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/catalogo/listaPreguntasSeccionCobertura/${idSubProdCondCobertura}/cotizacion/${codCotizacion}`;

    let res = await services.get(url, false);

    return res !== undefined &&
      res !== null &&
      res.status === STATUS_EXITO &&
      res.data.status.codigo === COD_STATUS_EXITO
      ? validarEstados(res.data.objeto)
      : null;
  } catch (error) {
    return null;
  }
}

export async function obtenerErroresCargaMasiva(idGrupo, perfil) {
  try {
    let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/flujoEnrolamiento/buscarLogCargaMasivaPorIdGrupo/${idGrupo}/perfil/${perfil}`;

    let respuesta = await services.get(url, false);

    return respuesta !== undefined &&
      respuesta !== null &&
      respuesta.status === STATUS_EXITO &&
      respuesta.data.status.codigo === COD_STATUS_EXITO
      ? respuesta.data.objeto
      : null;
  } catch (error) {
    return null;
  }
}

export async function ejecutarJobCargaMasiva(data, perfil) {
  try {
    let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/flujoEnrolamiento/ejecutarJobCargaMasivaAsegurable/perfil/${perfil}`;
    let respuesta = await services.post(url, data, false);

    return respuesta !== undefined &&
      respuesta !== null &&
      respuesta.status === STATUS_EXITO &&
      respuesta.data.status.codigo === COD_STATUS_EXITO
      ? respuesta.data.objeto
      : null;
  } catch (error) {
    return null;
  }
}

export async function obtenerEstadoJobCargaMasiva(idJob, perfil) {
  try {
    let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/flujoEnrolamiento/consultarJobCargaMasivaAsegurable/${idJob}/perfil/${perfil}`;
    let respuesta = await services.get(url, false);

    return respuesta !== undefined &&
      respuesta !== null &&
      respuesta.status === STATUS_EXITO &&
      respuesta.data.status.codigo === COD_STATUS_EXITO
      ? respuesta.data.objeto
      : null;
  } catch (error) {
    return null;
  }
}

export async function obtenerInformacionPoliza(rut) {
  try {
    let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/asegurable/buscarPorRut/${rut}`;

    let res = await services.get(url, false);

    return res !== undefined &&
      res !== null &&
      res.status === STATUS_EXITO &&
      res.data.status.codigo === COD_STATUS_EXITO
      ? res.data.objeto
      : null;
  } catch (error) {
    return null;
  }
}

/*************************
 *  CATALOGO  *
 *************************/
export async function getGeneros() {
  try {
    let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/catalogo/listarGenero`;
    let res = await services.get(url, false);
    return res !== undefined &&
      res !== null &&
      res.status === STATUS_EXITO &&
      res.data.status.codigo === COD_STATUS_EXITO
      ? validarEstados(res.data.objeto)
      : [];
  } catch (error) {
    return [];
  }
}

export async function getEstadoCivil() {
  try {
    let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/catalogo/listarEstadoCivil`;
    let res = await services.get(url, false);
    return res !== undefined &&
      res !== null &&
      res.status === STATUS_EXITO &&
      res.data.status.codigo === COD_STATUS_EXITO
      ? validarEstados(res.data.objeto)
      : [];
  } catch (error) {
    return [];
  }
}

export async function getParentesco() {
  try {
    let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/catalogo/listarParentesco`;
    let res = await services.get(url, false);
    return res !== undefined &&
      res !== null &&
      res.status === STATUS_EXITO &&
      res.data.status.codigo === COD_STATUS_EXITO
      ? validarEstados(res.data.objeto)
      : [];
  } catch (error) {
    return [];
  }
}
export async function getParentescoBeneficiario() {
  try {
    let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/catalogo/listarParentescoBeneficiario`;
    let res = await services.get(url, false);
    return res !== undefined &&
      res !== null &&
      res.status === STATUS_EXITO &&
      res.data.status.codigo === COD_STATUS_EXITO
      ? res.data.objeto
      : [];
  } catch (error) {
    return [];
  }
}

export async function obtenerConfiguracionParentesco(poliza, renovacion) {
  try {
    let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/configuracion/parentesco/buscarPorNroPolizaNroRenovacion/${poliza}/${renovacion}`;
    let res = await services.get(url, false);
    return res !== undefined &&
      res !== null &&
      res.status === STATUS_EXITO &&
      res.data.status.codigo === COD_STATUS_EXITO
      ? res.data.objeto
      : [];
  } catch (error) {
    return [];
  }
}

export async function getMotivos() {
  try {
    let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/catalogo/listarMotivo`;
    let res = await services.get(url, false);
    return res !== undefined &&
      res !== null &&
      res.status === STATUS_EXITO &&
      res.data.status.codigo === COD_STATUS_EXITO
      ? validarEstados(res.data.objeto)
      : [];
  } catch (error) {
    return [];
  }
}

export async function getPrevisionSalud() {
  try {
    let url = `${process.env.REACT_APP_BUS_URL_BACKMOVIL}/isapre/listarIsapres`;
    let res = await services.get(url, false, true);
    return res !== undefined &&
      res !== null &&
      res.status === STATUS_EXITO &&
      res.data.status.codigo === COD_STATUS_EXITO
      ? validarEstados(res.data.objeto)
      : [];
  } catch (error) {
    return [];
  }
}

export async function getRegiones() {
  try {
    let url = `${process.env.REACT_APP_BUS_URL_BACKMOVIL}/geografica/listarRegiones`;
    let res = await services.get(url, false, true);

    return res !== undefined &&
      res !== null &&
      res.status === STATUS_EXITO &&
      res.data.status.codigo === COD_STATUS_EXITO
      ? validarEstados(res.data.objeto)
      : [];
  } catch (error) {
    return [];
  }
}

export async function getCiudades() {
  try {
    let url = `${process.env.REACT_APP_BUS_URL_BACKMOVIL}/geografica/listarCiudad`;
    let res = await services.get(url, false, true);

    return res !== undefined &&
      res !== null &&
      res.status === STATUS_EXITO &&
      res.data.status.codigo === COD_STATUS_EXITO
      ? validarEstados(validarDataCiudadesDePago(res.data.objeto))
      : [];
  } catch (error) {
    return [];
  }
}

export async function getComunas() {
  try {
    let url = `${process.env.REACT_APP_BUS_URL_BACKMOVIL}/geografica/listarComuna`;
    let res = await services.get(url, false, true);

    return res !== undefined &&
      res !== null &&
      res.status === STATUS_EXITO &&
      res.data.status.codigo === COD_STATUS_EXITO
      ? validarEstados(res.data.objeto)
      : [];
  } catch (error) {
    return [];
  }
}

export async function getFormasDePago() {
  try {
    let url = `${process.env.REACT_APP_BUS_URL_BACKMOVIL}/banco/listarFormasDePago`;
    let res = await services.get(url, false, true);

    return res !== undefined &&
      res !== null &&
      res.status === STATUS_EXITO &&
      res.data.status.codigo === COD_STATUS_EXITO
      ? validarEstados(validarDataFormasDePago(res.data.objeto))
      : [];
  } catch (error) {
    return [];
  }
}

export async function getInstitucionesBancarias() {
  try {
    let url = `${process.env.REACT_APP_BUS_URL_BACKMOVIL}/banco/listarInstitucionesBancarias`;
    let res = await services.get(url, false, true);

    return res !== undefined &&
      res !== null &&
      res.status === STATUS_EXITO &&
      res.data.status.codigo === COD_STATUS_EXITO
      ? validarEstados(res.data.objeto)
      : [];
  } catch (error) {
    return [];
  }
}

export async function getTiposDeCuentas() {
  try {
    let url = `${process.env.REACT_APP_BUS_URL_BACKMOVIL}/banco/listarTiposDeCuentas`;
    let res = await services.get(url, false, true);

    return res !== undefined &&
      res !== null &&
      res.status === STATUS_EXITO &&
      res.data.status.codigo === COD_STATUS_EXITO
      ? validarEstados(res.data.objeto)
      : [];
  } catch (error) {
    return [];
  }
}

/* ***************************************************** */

export async function getNegociosAsegurable(rut) {
  try {
    let res = await services.get(`/asegurable/buscarNegociosAsegurable/${rut}`);
    return res !== undefined &&
      res !== null &&
      res.status === STATUS_EXITO &&
      res.data.status.codigo === COD_STATUS_EXITO
      ? res.data.objeto
      : [];
  } catch (error) {
    return [];
  }
}

export async function crearAsegurable(data, perfil) {
  try {
    let respuesta = await services.post(
      `/asegurable/crear/perfil/${perfil}`,
      data
    );
    return respuesta !== undefined && respuesta !== null
      ? respuesta.data
      : null;
  } catch (error) {
    return null;
  }
}

export async function listaDeclaracionesSalud() {
  try {
    let res = await services.get("/catalogo/listaDeclaracionesCobertura/96");
    return res !== undefined &&
      res !== null &&
      res.status === STATUS_EXITO &&
      res.data.status.codigo === COD_STATUS_EXITO
      ? validarEstados(res.data.objeto)
      : null;
  } catch (error) {
    return null;
  }
}

export async function listarCargasPorIdAsegurable(idAsegurable, perfil) {
  try {
    let res = await services.get(
      `/asegurable/listarCargasPorIdAsegurable/${idAsegurable}/perfil/${perfil}`
    );
    return res !== undefined && res !== null && res.status === STATUS_EXITO
      ? res.data.objeto
      : [];
  } catch (error) {
    return null;
  }
}

export async function listarPorIdSolicitud(idSolicitud, perfil) {
  try {
    let res = await services.get(
      `/asegurable/listarPorIdSolicitud/${idSolicitud}/perfil/${perfil}`
    );
    return res !== undefined && res !== null && res.status === STATUS_EXITO
      ? res.data.objeto
      : [];
  } catch (error) {
    return null;
  }
}

export async function listarCargasPorRutAsegurable(rutDvAsegurable) {
  try {
    let res = await services.get(
      `/asegurable/listarCargasPorRut/${rutDvAsegurable}`
    );
    return res !== undefined && res !== null && res.status === STATUS_EXITO
      ? res.data.objeto
      : [];
  } catch (error) {
    return null;
  }
}

export async function actualizarAsegurable(data) {
  try {
    let res = await services.post("/asegurable/actualizaAsegurable", data);
    return res !== undefined && res !== null && res.status === STATUS_EXITO
      ? res.data
      : null;
  } catch (error) {
    return null;
  }
}

export async function cerrarEnrolamiento(data) {
  try {
    let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_URL}/asegurable/guardarFormularioEnrolamiento`;
    let res = await services.post(url, data, false);

    return res;
  } catch (error) {
    return null;
  }
}

export async function obtenerGrupoAsegurables(data) {
  try {
    let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/asegurable/listarPorGrupo`;
    let res = await services.post(url, data, false);
    return res !== undefined &&
      res !== null &&
      res.status === STATUS_EXITO &&
      res.data.status.codigo === COD_STATUS_EXITO
      ? res.data.objeto
      : [];
  } catch (error) {
    return [];
  }
}

export async function actualizarAsegurableDelegado(data, perfil) {
  try {
    let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/asegurable/actualizarPersonaNoEnrolada/perfil/${perfil}`;
    let respuesta = await services.put(url, data, false);

    return respuesta !== undefined && respuesta !== null
      ? respuesta.data
      : null;
  } catch (error) {
    return null;
  }
}

export async function enviarMailAsegurable(data, perfil) {
  try {
    let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/asegurable/enviarMailConAcceso/perfil/${perfil}`;

    let respuesta = await services.post(url, data, false);
    return respuesta !== undefined &&
      respuesta !== null &&
      respuesta.data.status.codigo === COD_STATUS_EXITO
      ? respuesta.data
      : null;
  } catch (error) {
    return null;
  }
}

export async function listarMisSolicitudes(data) {
  try {
    let respuesta = await services.post(
      `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/asegurable/listarPorTipoIncorporacion`,
      data,
      false
    );

    if (
      respuesta !== undefined &&
      respuesta !== null &&
      respuesta.status === STATUS_EXITO
    ) {
      if (respuesta.data.status.codigo === COD_STATUS_EXITO)
        return respuesta.data.objeto;
      else if (respuesta.data.status.codigo === COD_STATUS_NA) return [];
      else return null;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
}

export async function obtenerTipoMovimiento() {
  try {
    let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/catalogo/listarTipoMovimiento`;

    let res = await services.get(url, false);

    return res !== undefined &&
      res !== null &&
      res.status === STATUS_EXITO &&
      res.data.status.codigo === COD_STATUS_EXITO
      ? res.data.objeto
      : null;
  } catch (error) {
    return null;
  }
}

export async function eliminarCarga(data) {
  try {
    let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/asegurable/actualizar`;
    let respuesta = await services.post(url, data, false);
    return respuesta !== undefined &&
      respuesta !== null &&
      respuesta.data.status.codigo === COD_STATUS_EXITO
      ? respuesta.data
      : null;
  } catch (error) {
    return null;
  }
}

export async function listarDetallePorTipoIncorporacion(data) {
  try {
    let url = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/asegurable/listarDetallePorTipoIncorporacion`;
    let respuesta = await services.post(url, data, false);

    if (
      respuesta !== undefined &&
      respuesta !== null &&
      respuesta.status === STATUS_EXITO
    ) {
      if (respuesta.data.status.codigo === COD_STATUS_EXITO)
        return respuesta.data.objeto;
      else if (respuesta.data.status.codigo === COD_STATUS_NA) return [];
      else return null;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
}

export async function listarContactoAsegurable(strRut, strToken) {
  try {
    let strRuta = `${process.env.REACT_APP_API_GATEWAY_BACKSUSCRIPCION_DIGITAL}/asegurable/contacto/buscarPorRut/${strRut}`;
    let objRespuesta = await services.get(strRuta, false, null, strToken);

    return objRespuesta !== undefined &&
      objRespuesta !== null &&
      objRespuesta.status === STATUS_EXITO &&
      objRespuesta.data.status.codigo === COD_STATUS_EXITO
      ? objRespuesta.data.objeto
      : null;
  } catch (error) {
    return null;
  }
}

/**************************
 *      VALIDAR DATA      *
 **************************/
function validarDataFormasDePago(data) {
  return data.filter((item) => {
    return item.id === ID_TRES || item.id === ID_CUATRO;
  });
}

function validarDataCiudadesDePago(data) {
  data = data.filter((item) => {
    return item.idRegion !== 0 && item.glosaCiudad !== "[NO ESPECIFICADA]";
  });

  data = data.sort((a, b) =>
    a.glosaCiudad > b.glosaCiudad ? 1 : b.glosaCiudad > a.glosaCiudad ? -1 : 0
  );

  return data;
}

export const validarRequisitosAsegurabilidad = async (lstCoberturas, objAsegurable, blnCarga = false, objParentesco = null) => {
  
  let lstRespRequesitos  = [];
  let lstEnpointErrores  = [];
  let lstNoCumpleErrores = [];
  let intIdParentesco    = blnCarga ? objParentesco.tipoCarga.id : PARENTESCO_TITULAR;
  let objRespuesta       = {
    mostrarError: false,
    modalRequisitosAsegurabilidad: false,
    datosActualesFormulario: objAsegurable,
    tipoPolizas: null,
  };

  await Promise.all(
    lstCoberturas.map(async objCobertura => {
      const objRespuesta = await validarRequisitosAsegurabilidadCotizacion({
        codigoCotizacion: objCobertura.codigoCotizacion,
        idSubProdCondicionado:objCobertura.idSubProdCondicionadoCobertura,
        idParentesco: intIdParentesco,
      });
      lstRespRequesitos.push({respuesta: objRespuesta, cobertura: objCobertura });
    })
  ).then(() => {
    let intEdad = moment().diff(moment(objAsegurable.fechaNacimiento, "DD/MM/YYYY"),"years");

    lstRespRequesitos.forEach(objRespRequesito =>{

      if (objRespRequesito.respuesta.status.codigo !== COD_STATUS_EXITO) {
        lstEnpointErrores.push(objRespRequesito.cobertura);
      }

      if (objRespRequesito.respuesta.status.codigo === COD_STATUS_EXITO) {
        
        const { EdadMinimaIngreso, EdadMaximaIngreso } = objRespRequesito.respuesta.objeto;

        if (intEdad < EdadMinimaIngreso || intEdad > EdadMaximaIngreso) {

          lstNoCumpleErrores.push(objRespRequesito.cobertura);
        }
      }  
    });

    if(lstEnpointErrores.length > 0){
      const strCoberturas = lstEnpointErrores
      .map((objError) => objError.glosa).join(", ");
      objRespuesta.mostrarError = true;
      return notificacion(NOTIFICACION_PELIGRO, `${ERROR_ASEGURABILIDAD_ENDPOINT} ${strCoberturas}`);
    }

    if(lstNoCumpleErrores.length > 0){
      const strCoberturas = lstNoCumpleErrores
      .map((objError) => objError.glosa.toUpperCase()).join(", ");

      objRespuesta.modalRequisitosAsegurabilidad = true;
      objRespuesta.datosActualesFormulario = objAsegurable;
      objRespuesta.tipoPolizas = strCoberturas;
    }
  });
  return objRespuesta; 
}